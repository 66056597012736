import { I18n } from "common/components";
import { TABLE_IDS } from "common/constants";
import { navigateTo } from "modules/common/actions";
import { getTableProps } from "modules/common/selectors";
import { MODULE_PATH } from "modules/paths";
import { SUB_PROJECTS_PATH } from "modules/subProjects/paths";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { getPayloadContent } from "utils/apiUtils";
import { toEpoch } from "utils/dateUtils";
import { successNotify } from "utils/notificationUtils";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { handleAPIRequest } from "../../../utils/http";
import { actions as commonActions } from "../../common/slice";
import { GRAND_PATH } from "../paths";
import { ACTION_TYPES } from "./actions";
import { DeleteBillsList, DeleteFYList, FetchBillsList, FetchExistExpenseList, FetchExpenses, FetchFYList, LSGIDropdownApi, billStatusDropdownApi, editBillsApi, editFyApi, fetchBillsByIdApi, fetchDistrict, fetchFYDropdownDetails, fetchFyById, fetchUlbDropdownDetails, lsgiTypeDropdownApi, projectsDropdownApi, saveExpenseApi, submitBillsApi, submitExpenseApi, submitFYApi, fetchBillsSankhya, fetchBillsSankhyaListAll, fetchTotalFromSankhya } from "./api";
import { actions as sliceActions } from "./slice";

export function* getFetchExpense({ payload }) {
    const { ulb } = payload;
    const { id: lsgiId } = ulb;
    const { year } = payload;
    const { id } = year;

    yield fork(handleAPIRequest, FetchExpenses, { lsgiId: lsgiId, finYearId: id });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_EXPENSE_SUCCESS, ACTION_TYPES.FETCH_EXPENSE_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* getFY() {
    yield call(handleAPIRequest, fetchFYDropdownDetails);
}

export function* getUlb() {
    yield call(handleAPIRequest, fetchUlbDropdownDetails);
}

export function* submitExpense({ payload }) {
    const { fetchExpenseList, ulb, year } = payload;
    let newPayload = {};
    newPayload.lsgiId = ulb;
    newPayload.financialYearId = year;
    newPayload.summaryList = fetchExpenseList;
    yield fork(handleAPIRequest, submitExpenseApi, newPayload);
    const response = yield take([ACTION_TYPES.SUBMIT_EXPENSE_SUCCESS, ACTION_TYPES.SUBMIT_EXPENSE_FAILURE]);
    if (response.type === ACTION_TYPES.SUBMIT_EXPENSE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("submitted", { type: I18n("fetch_expenses_from_sankhya") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${GRAND_PATH.UTILIZATION_APPLICATION.LIST}`));
    }
}
export function* saveExpense({ payload }) {
    const { fetchExpenseList, ulb, year } = payload;
    let newPayload = {};
    newPayload.lsgiId = ulb;
    newPayload.financialYearId = year;
    newPayload.summaryList = fetchExpenseList;
    yield fork(handleAPIRequest, saveExpenseApi, newPayload);
    const response = yield take([ACTION_TYPES.SAVE_EXPENSE_SUCCESS, ACTION_TYPES.SAVE_EXPENSE_FAILURE]);
    if (response.type === ACTION_TYPES.SAVE_EXPENSE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("saved", { type: I18n("fetch_expenses_from_sankhya") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        //yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${GRAND_PATH.UTILIZATION_APPLICATION.LIST}`));
    }
}
export function* fetchExistValues({ payload }) {
    yield fork(handleAPIRequest, FetchExistExpenseList, payload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_EXIST_VALUES_SUCCESS, ACTION_TYPES.FETCH_EXIST_VALUES_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}
export function* fetchFinancialYear({ payload }) {
    yield fork(handleAPIRequest, FetchFYList, { ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_FY_DETAILS_SUCCESS, ACTION_TYPES.FETCH_FY_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}
export function* deleteFY({ payload: id }) {
    yield fork(handleAPIRequest, DeleteFYList, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_FY_SUCCESS, ACTION_TYPES.DELETE_FY_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_FY_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("financial_year") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchFinancialYear, { payload: tablePayload });
    }
}

const formatData = (rest) => {
    const { name, startYear } = rest;
    const { startDate, endDate, description } = rest;
    const data = { name: name, startDate: toEpoch(startDate), endDate: toEpoch(endDate), description: description, startYear: Number(startYear) };
    return data;
};
const formatDataEdit = (action) => {
    const { payload } = action;
    const { id, name } = payload;
    const { startDate, endDate, description } = payload;
    const data = { id: id, name: name, startDate: toEpoch(startDate), endDate: toEpoch(endDate), description: description };
    return data;
};
export function* submitFY(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, editFyApi, formatDataEdit(action));
        const response = yield take([ACTION_TYPES.EDIT_FY_SUCCESS, ACTION_TYPES.EDIT_FY_FAILURE]);
        if (response.type === ACTION_TYPES.EDIT_FY_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("financial_year") }) }));
            yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
            yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${GRAND_PATH.FINANCIAL_YEAR.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, submitFYApi, formatData(rest));
        const response = yield take([ACTION_TYPES.SUBMIT_FY_SUCCESS, ACTION_TYPES.SUBMIT_FY_FAILURE]);
        if (response.type === ACTION_TYPES.SUBMIT_FY_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("financial_year") }) }));
            yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
            yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${GRAND_PATH.FINANCIAL_YEAR.LIST}`));
        }
    }
}

export function* fetchFYById({ payload }) {
    yield call(handleAPIRequest, fetchFyById, payload);
}

export function* fetchBills({ payload }) {
    let projectId = payload.id;
    yield fork(handleAPIRequest, FetchBillsList, { pageNo: 0, pageSize: 5, projectId });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_BILLS_DETAILS_SUCCESS, ACTION_TYPES.FETCH_BILLS_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}
export function* deleteBills({ payload }) {
    let { id, projectId } = payload;
    yield fork(handleAPIRequest, DeleteBillsList, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_BILLS_SUCCESS, ACTION_TYPES.DELETE_BILLS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_BILLS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("bills") }) }));
        yield fork(handleAPIRequest, FetchBillsList, { pageNo: 0, pageSize: 5, projectId });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_BILLS_DETAILS_SUCCESS, ACTION_TYPES.FETCH_BILLS_DETAILS_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES);
    }
}

const formatDatas = (rest) => {
    const { billAmount, districtId, lsgiId, billDate, billIssuedBy, billRemarks, billStatus, billNo, sanctionedAmount, projectId } = rest;
    const data = { billAmount: Number(billAmount), districtId: districtId, lsgiId: lsgiId, billDate: toEpoch(billDate), billIssuedBy, billRemarks, billStatus, billNo, sanctionedAmount: Number(sanctionedAmount), projectId };
    return data;
};

const formatEditData = (rest) => {
    const { id, billAmount, districtId, lsgiId, billDate, billIssuedBy, billRemarks, billStatus, billNo, sanctionedAmount, projectId } = rest;
    const data = { id, billAmount: Number(billAmount), districtId: districtId, lsgiId: lsgiId, billDate: toEpoch(billDate), billIssuedBy, billRemarks, billStatus, billNo, sanctionedAmount: Number(sanctionedAmount), projectId };
    return data;
};

export function* submitBills(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, editBillsApi, formatEditData({ id, ...rest }));
        const response = yield take([ACTION_TYPES.EDIT_BILLS_SUCCESS, ACTION_TYPES.EDIT_BILLS_FAILURE]);
        if (response.type === ACTION_TYPES.EDIT_BILLS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("bills") }) }));
            yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
            yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${SUB_PROJECTS_PATH.PROCUREMENT_LIST.LIST}/${rest.projectId.id}/${"edit"}`));
        }
    } else {
        yield fork(handleAPIRequest, submitBillsApi, formatDatas(rest));
        const response = yield take([ACTION_TYPES.SUBMIT_BILLS_SUCCESS, ACTION_TYPES.SUBMIT_BILLS_FAILURE]);
        if (response.type === ACTION_TYPES.SUBMIT_BILLS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("bills") }) }));
            yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
            yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${SUB_PROJECTS_PATH.PROCUREMENT_LIST.LIST}/${rest.projectId.id}/${"edit"}`));
        }
    }
}

export function* fetchBillsById({ payload }) {
    yield call(handleAPIRequest, fetchBillsByIdApi, payload);
}

export function* lsgiTypeSelectDropdown(action) {
    yield call(handleAPIRequest, lsgiTypeDropdownApi, action.payload);
}
export function* fetchDistrictDropDown(action) {
    yield call(handleAPIRequest, fetchDistrict, action.payload);
}

export function* fetchLSGIDropDown(action) {
    const { lsgiTypeValue: id, districtId } = action.payload;
    yield fork(handleAPIRequest, LSGIDropdownApi, { id, districtId });

    const response = yield take([ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE]);

    if (response.type === ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS) {
        const lsgiListResponse = getPayloadContent(response, []);
        yield put(sliceActions.setLsgiList({ data: lsgiListResponse, id, districtId }));
    }
}
export function* billStatusDropdown() {
    yield call(handleAPIRequest, billStatusDropdownApi);
}
export function* projectsDropdown() {
    yield call(handleAPIRequest, projectsDropdownApi);
}
export function* listBillsFromSankhya({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.FETCH_SANKHYA_BILLS]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchBillsSankhya, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_SUCCESS, ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.FETCH_SANKHYA_BILLS);
}

export function* listAllBillsFromSankhya({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.FETCH_SANKHYA_BILLS]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchBillsSankhyaListAll, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_LIST_ALL_SUCCESS, ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_LIST_ALL_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.FETCH_SANKHYA_BILLS);
}

export function* fetchTotalExpenses({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.FETCH_TOTAL_EXPENSES]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchTotalFromSankhya, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_EXPENSES_FROM_SANKHYA_SUCCESS, ACTION_TYPES.FETCH_EXPENSES_FROM_SANKHYA_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.FETCH_TOTAL_EXPENSES);
}

export default function* fetchExpenseSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_EXPENSE, getFetchExpense),
        takeLatest(ACTION_TYPES.FETCH_FY_LIST, getFY),
        takeLatest(ACTION_TYPES.FETCH_ULB_LIST, getUlb),
        takeLatest(ACTION_TYPES.SAVE_EXPENSE, saveExpense),
        takeLatest(ACTION_TYPES.SUBMIT_EXPENSE, submitExpense),
        takeLatest(ACTION_TYPES.FETCH_FY_DETAILS, fetchFinancialYear),
        takeLatest(ACTION_TYPES.DELETE_FY, deleteFY),
        takeLatest(ACTION_TYPES.FETCH_FY_BY_ID, fetchFYById),
        takeLatest(ACTION_TYPES.SUBMIT_FY, submitFY),
        takeLatest(ACTION_TYPES.FETCH_BILLS_DETAILS, fetchBills),
        takeLatest(ACTION_TYPES.DELETE_BILLS, deleteBills),
        takeLatest(ACTION_TYPES.FETCH_BILLS_BY_ID, fetchBillsById),
        takeLatest(ACTION_TYPES.SUBMIT_BILLS, submitBills),
        takeLatest(ACTION_TYPES.FETCH_EXIST_VALUES, fetchExistValues),
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN, fetchDistrictDropDown),
        takeLatest(ACTION_TYPES.FETCH_LSGI_DROPDOWN, fetchLSGIDropDown),
        takeLatest(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN, lsgiTypeSelectDropdown),
        takeLatest(ACTION_TYPES.BILL_STATUS_DROPDOWN, billStatusDropdown),
        takeLatest(ACTION_TYPES.PROJECTS_DROPDOWN, projectsDropdown),
        takeLatest(ACTION_TYPES.FETCH_BILL_FROM_SANKHYA, listBillsFromSankhya),
        takeLatest(ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_LIST_ALL, listAllBillsFromSankhya),
        takeLatest(ACTION_TYPES.FETCH_EXPENSES_FROM_SANKHYA, fetchTotalExpenses)
    ]);
}

