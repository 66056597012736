import { I18n } from "common/components";
import * as Yup from "yup";
export const isInitialAndFinalWhiteSpace = /^(?! ).*[^ ]$/;

export const visitorsFormSchema = Yup.object({
    name: Yup
        .string()
        .matches(isInitialAndFinalWhiteSpace, I18n("no_white_space_at_beginning_or_end"))
        .required(I18n("is_required", { type: I18n("name") })),
    mobile: Yup
        .string()
        .required(I18n("is_required", { type: I18n("mobile_number") })),
    country: Yup
        .object()
        .required(I18n("is_required", { type: I18n("country") })),
    email: Yup
        .string().email(I18n("invalid_email"))
        .required(I18n("is_required", { type: I18n("email") })),
    productInterest: Yup.
        object().
        required(I18n("is_required", { type: I18n("product_and_solution_interest") }))
});
