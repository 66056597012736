import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const saveTrainingScheduleAPI = (data) => ({
    url: API_URL.TRAINING_SCHEDULE.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_SCHEDULE_FORM_REQUEST, ACTION_TYPES.SAVE_SCHEDULE_FORM_SUCCESS, ACTION_TYPES.SAVE_SCHEDULE_FORM_FAILURE],
        data
    }
});

export const listTrainingScheduleApi = (data) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_SCHEDULE_DETAILS_REQUEST, ACTION_TYPES.LIST_SCHEDULE_DETAILS_SUCCESS, ACTION_TYPES.LIST_SCHEDULE_DETAILS_FAILURE],
            data

        }
    };
};
export const scheduleFilterApi = (data) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.FILTER,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_FILTER_REQUEST,
            ACTION_TYPES.FETCH_FILTER_SUCCESS,
            ACTION_TYPES.FETCH_FILTER_FAILURE],
            data

        }
    };
};


export const fetchCourseIdApi = ({ id }) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.COURSE_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COURSE_ID_REQUEST, ACTION_TYPES.FETCH_COURSE_ID_SUCCESS, ACTION_TYPES.FETCH_COURSE_ID_FAILURE],
            data: { id }
        }
    };
};

export const fetchSessionIdApi = ({ courseId }) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.SESSION_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SESSION_ID_REQUEST, ACTION_TYPES.FETCH_SESSION_ID_SUCCESS, ACTION_TYPES.FETCH_SESSION_ID_FAILURE],
            data: { courseId }
        }
    };
};
export const fetchSessionTypeApi = ({ id }) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.SESSION_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SESSION_TYPE_REQUEST, ACTION_TYPES.FETCH_SESSION_TYPE_SUCCESS, ACTION_TYPES.FETCH_SESSION_TYPE_FAILURE],
            data: { id }
        }
    };
};


export const fetchBatchIdApi = () => {
    return {
        url: API_URL.TRAINING_SCHEDULE.BATCH_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BATCH_ID_REQUEST, ACTION_TYPES.FETCH_BATCH_ID_SUCCESS, ACTION_TYPES.FETCH_BATCH_ID_FAILURE]
        }
    };
};

export const fetchTrainerIdApi = ({ trainingOrganizationId }) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.TRAINER_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TRAINER_ID_REQUEST, ACTION_TYPES.FETCH_TRAINER_ID_SUCCESS, ACTION_TYPES.FETCH_TRAINER_ID_FAILURE],
            data: { trainingOrganizationId }
        }
    };
};

export const fetchTrainingOrganizationApi = () => {
    return {
        url: API_URL.TRAINING_SCHEDULE.TRAINING_ORGANIZATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TRAINING_ORGANIZATION_REQUEST, ACTION_TYPES.FETCH_TRAINING_ORGANIZATION_SUCCESS, ACTION_TYPES.FETCH_TRAINING_ORGANIZATION_FAILURE]
        }
    };
};

export const fetchQuestionIdApi = () => {
    return {
        url: API_URL.TRAINING_SCHEDULE.QUESTIONNAIRE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_QUESTION_ID_REQUEST, ACTION_TYPES.FETCH_QUESTION_ID_SUCCESS, ACTION_TYPES.FETCH_QUESTION_ID_FAILURE]
        }
    };
};

export const deleteScheduleByIdAPI = (id) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.DELETE_SCHEDULE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_SCHEDULE_FORM_REQUEST, ACTION_TYPES.DELETE_SCHEDULE_FORM_SUCCESS, ACTION_TYPES.DELETE_SCHEDULE_FORM_FAILURE]
        }
    };
};

export const editScheduleFormAPI = (data) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.EDIT_SCHEDULE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_SCHEDULE_FORM_REQUEST, ACTION_TYPES.EDIT_SCHEDULE_FORM_SUCCESS, ACTION_TYPES.EDIT_SCHEDULE_FORM_FAILURE],
            data
        }
    };
};

export const editScheduleByIdApi = ({ id }) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.EDIT_SCHEDULE_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.EDIT_SCHEDULE_FORM_BY_ID_REQUEST, ACTION_TYPES.EDIT_SCHEDULE_FORM_BY_ID_SUCCESS, ACTION_TYPES.EDIT_SCHEDULE_FORM_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const filterScheduleBatch = (data) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.FILTER,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FILTER_SCHEDULE_BATCH_REQUEST, ACTION_TYPES.FILTER_SCHEDULE_BATCH_SUCCESS, ACTION_TYPES.FILTER_SCHEDULE_BATCH_FAILURE],
            data
        }
    };
};

export const editBatchFormByIdAPI = ({ id }) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.EDIT_BATCH_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.EDIT_BATCH_FORM_BY_ID_REQUEST, ACTION_TYPES.EDIT_BATCH_FORM_BY_ID_SUCCESS, ACTION_TYPES.EDIT_BATCH_FORM_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const attendanceDetailsApi = (data) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.ATTENDANCE_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_REQUEST, ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_SUCCESS, ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_FAILURE],
            data
        }
    };
};


export const confirmAttendanceApi = (data) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.MARK_ATTENDANCE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.CONFIRM_ATTENDANCE_REQUEST, ACTION_TYPES.CONFIRM_ATTENDANCE_SUCCESS, ACTION_TYPES.CONFIRM_ATTENDANCE_FAILURE],
            data
        }
    };
};


export const activateCheckInByIdApi = (data) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.ACTIVATE_CHECK_IN,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.ACTIVATE_CHECK_IN_REQUEST, ACTION_TYPES.ACTIVATE_CHECK_IN_SUCCESS, ACTION_TYPES.ACTIVATE_CHECK_IN_FAILURE],
            data
        }
    };
};


export const activateCheckOutByIdApi = (data) => {
    return {
        url: API_URL.TRAINING_SCHEDULE.ACTIVATE_CHECK_IN,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.ACTIVATE_CHECK_OUT_REQUEST, ACTION_TYPES.ACTIVATE_CHECK_OUT_SUCCESS, ACTION_TYPES.ACTIVATE_CHECK_OUT_FAILURE],
            data
        }
    };
};
