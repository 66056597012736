import { createSlice } from "@reduxjs/toolkit";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const initialState = {
    approvalBySpmu: {
        requestInProgress: false,
        data: {
            spmuSocialExpertDecision: null,
            spmuEnvExpertDecision: null,
            spmuFinancialExpertDecision: null,
            spmuTechnicalExpertDecision: null,
            spmuSocialExpertRemarks: "",
            spmuEnvExpertRemarks: "",
            spmuFinancialExpertRemarks: "",
            spmuTechnicalExpertRemarks: ""
        }
    },
    previousBySpmu: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.approvalBySpmu = initialState.approvalBySpmu;
        },
        clearPrevious: (state) => {
            state.previousBySpmu = initialState.previousBySpmu;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.APPROVE_SPMU_BY_ID_REQUEST, (state) => {
                    state.approvalBySpmu.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.APPROVE_SPMU_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "approvalBySpmu.requestInProgress", false);
                _.set(state, "approvalBySpmu.data", payload?.content?.length > 0 ? payload?.content[0] : state.approvalBySpmu.data);
            })
            .addCase(ACTION_TYPES.APPROVE_SPMU_BY_ID_FAILURE, (state) => {
                    state.approvalBySpmu.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_SPMU_REQUEST, (state) => {
                    state.previousBySpmu.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_SPMU_SUCCESS, (state, { payload }) => {
                _.set(state, "previousBySpmu.requestInProgress", false);
                _.set(state, "previousBySpmu.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_SPMU_FAILURE, (state) => {
                    state.previousBySpmu.requestInProgress = false;
            });
    }
});

export const { actions, reducer } = slice;
