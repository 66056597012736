import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    SUBMIT_GAP_ANALYSIS: `${STATE_REDUCER_KEY}/SUBMIT_GAP_ANALYSIS`,
    SUBMIT_GAP_ANALYSIS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_GAP_ANALYSIS_REQUEST`,
    SUBMIT_GAP_ANALYSIS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_GAP_ANALYSIS_SUCCESS`,
    SUBMIT_GAP_ANALYSIS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_GAP_ANALYSIS_FAILURE`,

    UPDATE_GAP_ANALYSIS: `${STATE_REDUCER_KEY}/UPDATE_GAP_ANALYSIS`,
    UPDATE_GAP_ANALYSIS_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_GAP_ANALYSIS_REQUEST`,
    UPDATE_GAP_ANALYSIS_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_GAP_ANALYSIS_SUCCESS`,
    UPDATE_GAP_ANALYSIS_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_GAP_ANALYSIS_FAILURE`,

    DELETE_GAP_ANALYSIS: `${STATE_REDUCER_KEY}/DELETE_GAP_ANALYSIS`,
    DELETE_GAP_ANALYSIS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_GAP_ANALYSIS_REQUEST`,
    DELETE_GAP_ANALYSIS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_GAP_ANALYSIS_SUCCESS`,
    DELETE_GAP_ANALYSIS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_GAP_ANALYSIS_FAILURE`,

    CONFIRM_FORM_SUBMIT: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT`,
    CONFIRM_FORM_SUBMIT_REQUEST: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_REQUEST`,
    CONFIRM_FORM_SUBMIT_SUCCESS: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_SUCCESS`,
    CONFIRM_FORM_SUBMIT_FAILURE: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_FAILURE`,

    LIST_GAP_ANALYSIS: `${STATE_REDUCER_KEY}/LIST_GAP_ANALYSIS`,
    LIST_GAP_ANALYSIS_REQUEST: `${STATE_REDUCER_KEY}/LIST_GAP_ANALYSIS_REQUEST`,
    LIST_GAP_ANALYSIS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_GAP_ANALYSIS_SUCCESS`,
    LIST_GAP_ANALYSIS_FAILURE: `${STATE_REDUCER_KEY}/LIST_GAP_ANALYSIS_FAILURE`,

    GAP_ANALYSIS_BY_ID: `${STATE_REDUCER_KEY}/GAP_ANALYSIS_BY_ID`,
    GAP_ANALYSIS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GAP_ANALYSIS_BY_ID_REQUEST`,
    GAP_ANALYSIS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GAP_ANALYSIS_BY_ID_SUCCESS`,
    GAP_ANALYSIS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GAP_ANALYSIS_BY_ID_FAILURE`
};

export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const submitGapAnalysis = createAction(ACTION_TYPES.SUBMIT_GAP_ANALYSIS);
export const confirmGapAnalysis = createAction(ACTION_TYPES.CONFIRM_FORM_SUBMIT);
export const gapAnalysisList = createAction(ACTION_TYPES.LIST_GAP_ANALYSIS);
export const gapAnalysisById = createAction(ACTION_TYPES.GAP_ANALYSIS_BY_ID);
export const deleteGapAnalysis = createAction(ACTION_TYPES.DELETE_GAP_ANALYSIS);
export const updateGapAnalysis = createAction(ACTION_TYPES.UPDATE_GAP_ANALYSIS);
