import { STORAGE_KEYS } from "common/constants";
import { navigateTo } from "modules/common/actions";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import * as API from "./api";
import { postResetPassword } from "./api";
import { getOtpDetails } from "modules/common/selectors";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";

export function* authenticateUserDetails(action) {
    let { userName, password } = action.payload;
    yield fork(handleAPIRequest, API.authenticateUser, { username: userName, password });
    const responseAction = yield take([ACTION_TYPES.AUTHENTICATE_USER_SUCCESS, ACTION_TYPES.AUTHENTICATE_USER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.AUTHENTICATE_USER_SUCCESS) {
        // eslint-disable-next-line camelcase
        const { payload: { access_token, refresh_token } = {} } = responseAction;
        localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, access_token);
        localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, refresh_token);
        yield fork(handleAPIRequest, API.getUserProfile, {});
        const profileResponseAction = yield take([ACTION_TYPES.FETCH_USER_PROFILE_SUCCESS, ACTION_TYPES.FETCH_USER_PROFILE_FAILURE]);
        if (profileResponseAction.type === ACTION_TYPES.FETCH_USER_PROFILE_SUCCESS) {
            yield put(navigateTo("/Dashboard"));
        }
    }
}

export function* postResetP({ payload }) {
    let otpDetails = yield select(getOtpDetails);
    const { retypePassword: newPassword } = payload;
    const { targetNo: emailOrPhoneNo } = otpDetails;
    yield fork(handleAPIRequest, postResetPassword, { newPassword, emailOrPhoneNo });
    const response = yield take([ACTION_TYPES.POST_RESET_PASSWORD_SUCCESS, ACTION_TYPES.POST_RESET_PASSWORD_FAILURE]);
    if (response.type === ACTION_TYPES.POST_RESET_PASSWORD_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("forgot", { type: I18n("password") }) }));
        payload.setSuccess(true);
    }
}
export function* getOTP(action) {
    let payload = action.payload;
    yield call(handleAPIRequest, API.getOtp, { payload });
}

export function* getUserDetails(action) {
    let payload = action.payload;
    yield call(handleAPIRequest, API.getUserProfile, payload);
}
export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.AUTHENTICATE_USER, authenticateUserDetails),
        takeLatest(ACTION_TYPES.POST_RESET_PASSWORD, postResetP),
        takeLatest(ACTION_TYPES.FETCH_USER_PROFILE, getUserDetails)
    ]);
}
