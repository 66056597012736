import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    SET_COMPLAINT_STATUS_DROPDOWN: `${STATE_REDUCER_KEY}/SET_COMPLAINT_STATUS_DROPDOWN`,
    FETCH_COMPLAINT_STATUS_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_DROPDOWN_REQUEST`,
    FETCH_COMPLAINT_STATUS_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_DROPDOWN_SUCCESS`,
    FETCH_COMPLAINT_STATUS_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_DROPDOWN_FAILURE`,

    FETCH_COMPLAINT_MODE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_MODE`,
    FETCH_COMPLAINT_MODE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_MODE_REQUEST`,
    FETCH_COMPLAINT_MODE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_MODE_SUCCESS`,
    FETCH_COMPLAINT_MODE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_MODE_FAILURE`,

    FETCH_COMPLAINT_TYPE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE`,
    FETCH_COMPLAINT_TYPE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE_REQUEST`,
    FETCH_COMPLAINT_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE_SUCCESS`,
    FETCH_COMPLAINT_TYPE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE_FAILURE`,

    FETCH_COMPLAINT_CATEGORY: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY`,
    FETCH_COMPLAINT_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_REQUEST`,
    FETCH_COMPLAINT_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_SUCCESS`,
    FETCH_COMPLAINT_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_FAILURE`,

    FETCH_DISTRICT_LIST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST`,
    FETCH_DISTRICT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_REQUEST`,
    FETCH_DISTRICT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_SUCCESS`,
    FETCH_DISTRICT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_FAILURE`,

    FETCH_WARD_LIST: `${STATE_REDUCER_KEY}/FETCH_WARD_LIST`,
    FETCH_WARD_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_LIST_REQUEST`,
    FETCH_WARD_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_LIST_SUCCESS`,
    FETCH_WARD_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_LIST_FAILURE`,

    FETCH_LSGI: `${STATE_REDUCER_KEY}/FETCH_DISTRICT`,
    FETCH_LSGI_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LSGI_REQUEST`,
    FETCH_LSGI_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LSGI_SUCCESS`,
    FETCH_LSGI_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LSGI_FAILURE`,

    SELECT_LSGI_TYPE_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN`,
    SELECT_LSGI_TYPE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_REQUEST`,
    SELECT_LSGI_TYPE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_SUCCESS`,
    SELECT_LSGI_TYPE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_FAILURE`,

    DOWNLOAD_REPORTS: `${STATE_REDUCER_KEY}/DOWNLOAD_REPORTS`,
    DOWNLOAD_REPORTS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_REPORTS_REQUEST`,
    DOWNLOAD_REPORTS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_REPORTS_SUCCESS`,
    DOWNLOAD_REPORTS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_REPORTS_FAILURE`,

    FETCH_COMPLAINT_LIST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_LIST`,
    FETCH_COMPLAINT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_LIST_REQUEST`,
    FETCH_COMPLAINT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_LIST_SUCCESS`,
    FETCH_COMPLAINT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_LIST_FAILURE`,

    SELECT_PRIORITY_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_PRIORITY_DROPDOWN`,
    SELECT_PRIORITY_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_PRIORITY_DROPDOWN_REQUEST`,
    SELECT_PRIORITY_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_PRIORITY_DROPDOWN_SUCCESS`,
    SELECT_PRIORITY_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_PRIORITY_DROPDOWN_FAILURE`,

    SELECT_COMPLAINT_HANDLER_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_COMPLAINT_HANDLER_DROPDOWN`,
    SELECT_COMPLAINT_HANDLER_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_COMPLAINT_HANDLER_DROPDOWN_REQUEST`,
    SELECT_COMPLAINT_HANDLER_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_COMPLAINT_HANDLER_DROPDOWN_SUCCESS`,
    SELECT_COMPLAINT_HANDLER_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_COMPLAINT_HANDLER_DROPDOWN_FAILURE`,


    SELECT_COMPLAINT_NUMBER_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_COMPLAINT_NUMBER_DROPDOWN`,
    SELECT_COMPLAINT_NUMBER_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_COMPLAINT_NUMBER_DROPDOWN_REQUEST`,
    SELECT_COMPLAINT_NUMBER_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_COMPLAINT_NUMBER_DROPDOWN_SUCCESS`,
    SELECT_COMPLAINT_NUMBER_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_COMPLAINT_NUMBER_DROPDOWN_FAILURE`,

    FETCH_GRIEVANCE_SUMMARY: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY`,
    FETCH_GRIEVANCE_SUMMARY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY_REQUEST`,
    FETCH_GRIEVANCE_SUMMARY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY_SUCCESS`,
    FETCH_GRIEVANCE_SUMMARY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY_FAILURE`,

    FETCH_GRIEVANCE_SUMMARY_WARD: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY_WARD`,
    FETCH_GRIEVANCE_SUMMARY_WARD_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY_WARD_REQUEST`,
    FETCH_GRIEVANCE_SUMMARY_WARD_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY_WARD_SUCCESS`,
    FETCH_GRIEVANCE_SUMMARY_WARD_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY_WARD_FAILURE`,

    FETCH_GRIEVANCE_SUMMARY_ULB: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY_ULB`,
    FETCH_GRIEVANCE_SUMMARY_ULB_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY_ULB_REQUEST`,
    FETCH_GRIEVANCE_SUMMARY_ULB_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY_ULB_SUCCESS`,
    FETCH_GRIEVANCE_SUMMARY_ULB_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_SUMMARY_ULB_FAILURE`,

    FETCH_GRIEVANCE_REPORT_LIST: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_REPORT_LIST`,
    FETCH_GRIEVANCE_REPORT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_REPORT_LIST_REQUEST`,
    FETCH_GRIEVANCE_REPORT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_REPORT_LIST_SUCCESS`,
    FETCH_GRIEVANCE_REPORT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_REPORT_LIST_FAILURE`,

    DOWNLOAD_SUMMARY_REPORTS: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_REPORTS`,
    DOWNLOAD_SUMMARY_REPORTS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_REPORTS_REQUEST`,
    DOWNLOAD_SUMMARY_REPORTS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_REPORTS_SUCCESS`,
    DOWNLOAD_SUMMARY_REPORTS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_REPORTS_FAILURE`,

    DOWNLOAD_LISTING_REPORTS: `${STATE_REDUCER_KEY}/DOWNLOAD_LISTING_REPORTS`,
    DOWNLOAD_LISTING_REPORTS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_LISTING_REPORTS_REQUEST`,
    DOWNLOAD_LISTING_REPORTS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_LISTING_REPORTS_SUCCESS`,
    DOWNLOAD_LISTING_REPORTS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_LISTING_REPORTS_FAILURE`,

    DOWNLOAD_SUMMARY_LSGI_REPORTS: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_LSGI_REPORTS`,
    DOWNLOAD_SUMMARY_LSGI_REPORTS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_LSGI_REPORTS_REQUEST`,
    DOWNLOAD_SUMMARY_LSGI_REPORTS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_LSGI_REPORTS_SUCCESS`,
    DOWNLOAD_SUMMARY_LSGI_REPORTS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_LSGI_REPORTS_FAILURE`,

    DOWNLOAD_SUMMARY_WARD_REPORTS: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_WARD_REPORTS`,
    DOWNLOAD_SUMMARY_WARD_REPORTS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_WARD_REPORTS_REQUEST`,
    DOWNLOAD_SUMMARY_WARD_REPORTS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_WARD_REPORTS_SUCCESS`,
    DOWNLOAD_SUMMARY_WARD_REPORTS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_SUMMARY_WARD_REPORTS_FAILURE`,

    DOWNLOAD_COMPLAINT_LIST: `${STATE_REDUCER_KEY}/DOWNLOAD_COMPLAINT_LIST`,
    DOWNLOAD_COMPLAINT_LIST_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_COMPLAINT_LIST_REQUEST`,
    DOWNLOAD_COMPLAINT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_COMPLAINT_LIST_SUCCESS`,
    DOWNLOAD_COMPLAINT_LIST_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_COMPLAINT_LIST_FAILURE`,

    FETCH_ESCALATION_REPORT: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_REPORT`,
    FETCH_ESCALATION_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_REPORT_REQUEST`,
    FETCH_ESCALATION_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_REPORT_SUCCESS`,
    FETCH_ESCALATION_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_REPORT_FAILURE`,

    FETCH_PENDING_REPORT: `${STATE_REDUCER_KEY}/FETCH_PENDING_REPORT`,
    FETCH_PENDING_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PENDING_REPORT_REQUEST`,
    FETCH_PENDING_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PENDING_REPORT_SUCCESS`,
    FETCH_PENDING_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PENDING_REPORT_FAILURE`,

    FETCH_REOPENED_COMPLAINTS: `${STATE_REDUCER_KEY}/FETCH_REOPENED_COMPLAINTS`,
    FETCH_REOPENED_COMPLAINTS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_REOPENED_COMPLAINTS_REQUEST`,
    FETCH_REOPENED_COMPLAINTS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_REOPENED_COMPLAINTS_SUCCESS`,
    FETCH_REOPENED_COMPLAINTS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_REOPENED_COMPLAINTS_FAILURE`,

    FETCH_TOP_GRIEVANCE_CATEGORY: `${STATE_REDUCER_KEY}/FETCH_TOP_GRIEVANCE_CATEGORY`,
    FETCH_TOP_GRIEVANCE_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOP_GRIEVANCE_CATEGORY_REQUEST`,
    FETCH_TOP_GRIEVANCE_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOP_GRIEVANCE_CATEGORY_SUCCESS`,
    FETCH_TOP_GRIEVANCE_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOP_GRIEVANCE_CATEGORY_FAILURE`,

    DOWNLOAD_ESCALATION_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_ESCALATION_REPORT`,
    DOWNLOAD_ESCALATION_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_ESCALATION_REPORT_REQUEST`,
    DOWNLOAD_ESCALATION_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_ESCALATION_REPORT_SUCCESS`,
    DOWNLOAD_ESCALATION_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_ESCALATION_REPORT_FAILURE`,

    DOWNLOAD_PENDING_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_PENDING_REPORT`,
    DOWNLOAD_PENDING_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_PENDING_REPORT_REQUEST`,
    DOWNLOAD_PENDING_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_PENDING_REPORT_SUCCESS`,
    DOWNLOAD_PENDING_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_PENDING_REPORT_FAILURE`,

    DOWNLOAD_REOPENED_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_REOPENED_REPORT`,
    DOWNLOAD_REOPENED_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_REOPENED_REPORT_REQUEST`,
    DOWNLOAD_REOPENED_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_REOPENED_REPORT_SUCCESS`,
    DOWNLOAD_REOPENED_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_REOPENED_REPORT_FAILURE`,

    DOWNLOAD_TOP_GRIEVANCE_CATEGORY: `${STATE_REDUCER_KEY}/DOWNLOAD_TOP_GRIEVANCE_CATEGORY`,
    DOWNLOAD_TOP_GRIEVANCE_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_TOP_GRIEVANCE_CATEGORY_REQUEST`,
    DOWNLOAD_TOP_GRIEVANCE_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_TOP_GRIEVANCE_CATEGORY_SUCCESS`,
    DOWNLOAD_TOP_GRIEVANCE_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_TOP_GRIEVANCE_CATEGORY_FAILURE`

};

export const setComplaintStatusDropdown = createAction(ACTION_TYPES.SET_COMPLAINT_STATUS_DROPDOWN);
export const setComplaintStatus = createAction(ACTION_TYPES.SET_COMPLAINT_STATUS_DROPDOWN);
export const getComplaintType = createAction(ACTION_TYPES.FETCH_COMPLAINT_TYPE);
export const getComplaintMode = createAction(ACTION_TYPES.FETCH_COMPLAINT_MODE);
export const getComplaintCategory = createAction(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY);
export const getDistricts = createAction(ACTION_TYPES.FETCH_DISTRICT_LIST);
export const getWard = createAction(ACTION_TYPES.FETCH_WARD_LIST);
export const getLSGI = createAction(ACTION_TYPES.FETCH_LSGI);
export const lsgiTypeSelectDropdown = createAction(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN);
export const getDownloadReports = createAction(ACTION_TYPES.DOWNLOAD_REPORTS);
export const listComplaint = createAction(ACTION_TYPES.FETCH_COMPLAINT_LIST);
export const listGrievanceReportList = createAction(ACTION_TYPES.FETCH_GRIEVANCE_REPORT_LIST);
export const listGrievanceSummary = createAction(ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY);
export const listGrievanceSummaryWard = createAction(ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_WARD);
export const listGrievanceSummaryUlb = createAction(ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_ULB);
export const priorityDropdown = createAction(ACTION_TYPES.SELECT_PRIORITY_DROPDOWN);
export const listComplaintHandler = createAction(ACTION_TYPES.SELECT_COMPLAINT_HANDLER_DROPDOWN);
export const listJSONComplaintNumberData = createAction(ACTION_TYPES.SELECT_COMPLAINT_NUMBER_DROPDOWN);
export const getDownloadSummaryReports = createAction(ACTION_TYPES.DOWNLOAD_SUMMARY_REPORTS);
export const getDownloadSummaryLsgiReports = createAction(ACTION_TYPES.DOWNLOAD_SUMMARY_LSGI_REPORTS);
export const getDownloadListingReports = createAction(ACTION_TYPES.DOWNLOAD_LISTING_REPORTS);
export const getDownloadSummaryWardReports = createAction(ACTION_TYPES.DOWNLOAD_SUMMARY_WARD_REPORTS);
export const getDownloadComplaintList = createAction(ACTION_TYPES.DOWNLOAD_COMPLAINT_LIST);
export const listEscalationReport = createAction(ACTION_TYPES.FETCH_ESCALATION_REPORT);
export const listPendingReport = createAction(ACTION_TYPES.FETCH_PENDING_REPORT);
export const listReopenedComplaints = createAction(ACTION_TYPES.FETCH_REOPENED_COMPLAINTS);
export const listTopGrievanceCategory = createAction(ACTION_TYPES.FETCH_TOP_GRIEVANCE_CATEGORY);
export const downloadEscalationReport = createAction(ACTION_TYPES.DOWNLOAD_ESCALATION_REPORT);
export const downloadPendingReport = createAction(ACTION_TYPES.DOWNLOAD_PENDING_REPORT);
export const downloadReopenedReport = createAction(ACTION_TYPES.DOWNLOAD_REOPENED_REPORT);
export const downloadTopGrievanceCategory = createAction(ACTION_TYPES.DOWNLOAD_TOP_GRIEVANCE_CATEGORY);


