import { createSlice } from "@reduxjs/toolkit";
import { getPayloadContent } from "utils/apiUtils";

import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import _ from "lodash";

const STAKEHOLDER_DETAILS = {
    meetingDate: null,
    totalCount: "",
    meetingVenue: "",
    maleCount: "",
    femaleCount: "",
    transgenderCount: "",
    disabledCount: "",
    oldAgeCount: "",
    hksMemberCount: "",
    otherWasteWorkerCount: "",
    kudumbasreeMembersCount: "",
    ragPickersCount: "",
    otherScrapDealerCount: "",
    remarks: "",
    attachmentIds: [],
    swmPlanId: ""
};

const initialState = {
    stakeHoldersById: {
        requestInProgress: false,
        data: {
            ...STAKEHOLDER_DETAILS
        }
    },
    stakeHoldersData: {
        requestInProgress: false,
        data: {
            meetingDate: null,
            meetingVenue: "",
            maleCount: "",
            femaleCount: "",
            transgenderCount: "",
            totalCount: null,
            disabledCount: "",
            oldAgeCount: "",
            hksMemberCount: "",
            otherWasteWorkerCount: "",
            kudumbasreeMembersCount: "",
            ragPickersCount: "",
            otherScrapDealerCount: "",
            remarks: "",
            attachmentIds: [],
            swmPlanId: ""
        }
    },
    openStatus: {
        open: false
    },
    attachmentImages: []
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.stakeHoldersById = initialState.stakeHoldersById;
        },
        setStakeHoldersById: (state, action) => {
            state.stakeHoldersData.data = action.payload;
        },
        resetStakeHoldersById: (state) => {
            state.stakeHoldersData.data = initialState.stakeHoldersData.data;
        },
        setOpenStatus: (state) => {
            state.openStatus.open = true;
        },
        resetOpenStatus: (state) => {
            state.openStatus.open = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.LIST_STAKEHOLDER_CONSULTATION_REQUEST, (state) => {
            state.stakeHoldersById.requestInProgress = true;
        })
            .addCase(ACTION_TYPES.LIST_STAKEHOLDER_CONSULTATION_SUCCESS, (state, action) => {
                state.stakeHoldersById = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.LIST_STAKEHOLDER_CONSULTATION_FAILURE, (state) => {
                state.stakeHoldersById.requestInProgress = false;
                state.error = "Something went wrong";
            })
            .addCase(ACTION_TYPES.STAKEHOLDER_CONSULTATION_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "stakeHoldersById.requestInProgress", false);
                _.set(state, "stakeHoldersById.data", payload);
            })
            .addCase(ACTION_TYPES.SUBMIT_STAKEHOLDER_CONSULTATION_FAILURE, (state, { payload }) => {
                _.set(state, "stakeHoldersById.requestInProgress", false);
                _.set(state, "stakeHoldersById.data", payload);
            });
    }
});

export const { actions, reducer } = slice;
