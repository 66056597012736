import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchAnnualTriggerListApi = (data) => {
    return {
        url: API_URL.GRAND_UTILIZATION.REQUEST_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ANNUAL_TRIGGER_LIST_REQUEST, ACTION_TYPES.FETCH_ANNUAL_TRIGGER_LIST_SUCCESS, ACTION_TYPES.FETCH_ANNUAL_TRIGGER_LIST_FAILURE],
            data
        }
    };
};

export const fetchUtilizationListApi = (data) => {
    return {
        url: API_URL.GRAND_UTILIZATION.UTILIZATION_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_UTILIZATION_LIST_REQUEST, ACTION_TYPES.FETCH_UTILIZATION_LIST_SUCCESS, ACTION_TYPES.FETCH_UTILIZATION_LIST_FAILURE],
            data
        }
    };
};

export const LSGIDropdownApi = (districtId, lsgiTypeId) => {
    return {
        url: API_URL.COMMON.ULB,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE],
            data: { districtId, lsgiTypeId }
        }
    };
};

export const submitProjectDetailsApi = (data) => {
    return {
        url: API_URL.GRAND_UTILIZATION.SUBMIT_PROJECT_DETAILS,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.SUBMIT_PROJECT_DETAILS_REQUEST, ACTION_TYPES.SUBMIT_PROJECT_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_PROJECT_DETAILS_FAILURE],
            data
        }
    };
};

export const fetchAnnualTriggerViewApi = ({ id, fullData = false }) => {
    return {
        url: API_URL.GRAND_UTILIZATION.VIEW,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ANNUAL_TRIGGER_DETAILS_REQUEST, ACTION_TYPES.FETCH_ANNUAL_TRIGGER_DETAILS_SUCCESS, ACTION_TYPES.FETCH_ANNUAL_TRIGGER_DETAILS_FAILURE],
            data: { id, fullData }
        }
    };
};

export const submitToDPMUApi = (data) => {
    return {
        url: API_URL.GRAND_UTILIZATION.SUBMIT_TO_DPMU,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_DPMU_APPLICATION_REQUEST, ACTION_TYPES.SUBMIT_DPMU_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_DPMU_APPLICATION_FAILURE],
            data
        }
    };
};

export const returnULBApi = (data) => {
    return {
        url: API_URL.GRAND_UTILIZATION.RETURN_TO_ULB,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.REJECT_APPLICATION_REQUEST, ACTION_TYPES.REJECT_APPLICATION_SUCCESS, ACTION_TYPES.REJECT_APPLICATION_FAILURE],
            data
        }
    };
};

export const approveByDpmuApi = (data) => {
    return {
        url: API_URL.GRAND_UTILIZATION.APPROVE_DPMU,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.APPROVE_DPMU_APPLICATION_REQUEST, ACTION_TYPES.APPROVE_DPMU_APPLICATION_SUCCESS, ACTION_TYPES.APPROVE_DPMU_APPLICATION_FAILURE],
            data
        }
    };
};

export const submitToSpmuApi = (data) => {
    return {
        url: API_URL.GRAND_UTILIZATION.SUBMIT_TO_SPMU,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_SPMU_APPLICATION_REQUEST, ACTION_TYPES.SUBMIT_SPMU_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_SPMU_APPLICATION_FAILURE],
            data
        }
    };
};

export const approveBySpmuApi = (data) => {
    return {
        url: API_URL.GRAND_UTILIZATION.SPMU_APPROVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.APPROVE_SPMU_APPLICATION_REQUEST, ACTION_TYPES.APPROVE_SPMU_APPLICATION_SUCCESS, ACTION_TYPES.APPROVE_SPMU_APPLICATION_FAILURE],
            data
        }
    };
};

export const districtDropdownApi = () => {
    return {
        url: API_URL.COMMON.DISTRICT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_FAILURE]
        }
    };
};

export const searchULBApi = (data) => {
    return {
        url: API_URL.GRAND_UTILIZATION.UTILIZATION_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ULB_SEARCH_REQUEST, ACTION_TYPES.FETCH_ULB_SEARCH_SUCCESS, ACTION_TYPES.FETCH_ULB_SEARCH_FAILURE],
            data
        }
    };
};
