import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadData } from "utils/apiUtils";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    districtDropdown: {
        requestInProgress: false,
        data: []
    },
    ulbDropdown: {
        requestInProgress: false,
        data: []
    },
    AnnualTriggerView: {
        requestInProgress: true,
        data: {
            projectsListDropDown: [],
            projectsDropDown: "",
            applicationId: "",
            projectsDetailsList: [],
            applicationStatus: "",
            lsgiId: {},
            financialYearId: "",
            applicationDate: "",
            ulbRemarks: "",
            dpmuRemarks: "",
            dpmuRemarksToUlb: "",
            dpmuRemarksToSpmu: "",
            ulbRemarksToDpmu: "",
            eligibleAmount: "",
            suspendedAmount: "",
            approvedAmount: "",
            ulbGrantUtilizationSummary: {}
        }
    },
    open: false
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.AnnualTriggerView.data.projectsDetailsList = initialState.AnnualTriggerView.data.projectsDetailsList;
        },
        setProjectsDropDown: (state, action) => {
            state.AnnualTriggerView.data.projectsDropDown = action?.payload;
            state.AnnualTriggerView.requestInProgress = true;
        },
        clearULB: (state) => {
            state.ulbDropdown = initialState.ulbDropdown;
        },
        clearDistrict: (state) => {
            state.districtDropdown = initialState.districtDropdown;
        },
        setOpen: (state, action) => {
            state.open = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "ulbDropdown.requestInProgress", false);
                _.set(state, "ulbDropdown.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "districtDropdown.requestInProgress", false);
                _.set(state, "districtDropdown.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_ANNUAL_TRIGGER_DETAILS_REQUEST, (state) => {
                state.AnnualTriggerView.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_ANNUAL_TRIGGER_DETAILS_SUCCESS, (state, action) => {
                const newVal = getPayloadData(action);
                _.set(state, "AnnualTriggerView.data", newVal);
                _.set(state, "AnnualTriggerView.data.projectsDropDown", newVal.projectsListDropDown[0]);
            })
            .addCase(ACTION_TYPES.FETCH_ANNUAL_TRIGGER_DETAILS_FAILURE, (state) => {
                state.AnnualTriggerView.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_ANNUAL_TRIGGER_LIST_REQUEST, (state) => {
                state.AnnualTriggerView.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_ANNUAL_TRIGGER_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "AnnualTriggerView.data.projectsDetailsList", payload?.projectsDetailsList);
                _.set(state, "AnnualTriggerView.data.ulbRemarks", payload?.ulbRemarks);
                _.set(state, "AnnualTriggerView.data.dpmuRemarks", payload?.dpmuRemarks);
                _.set(state, "AnnualTriggerView.data.dpmuRemarksToUlb", payload?.dpmuRemarksToUlb);
                _.set(state, "AnnualTriggerView.data.dpmuRemarksToSpmu", payload?.dpmuRemarksToSpmu);
                _.set(state, "AnnualTriggerView.data.ulbRemarksToDpmu", payload?.ulbRemarksToDpmu);
                _.set(state, "AnnualTriggerView.data.eligibleAmount", payload?.eligibleAmount);
                _.set(state, "AnnualTriggerView.data.suspendedAmount", payload?.suspendedAmount);
                _.set(state, "AnnualTriggerView.data.approvedAmount", payload?.approvedAmount);
                _.set(state, "AnnualTriggerView.data.ulbGrantUtilizationSummary", payload?.ulbGrantUtilizationSummary);
                _.set(state, "AnnualTriggerView.requestInProgress", false);
            });
    }
});

export const { actions, reducer } = slice;
