import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    SUBMIT_COMPOSITION_OF_TEAM: `${STATE_REDUCER_KEY}/SUBMIT_COMPOSITION_OF_TEAM`,
    SUBMIT_COMPOSITION_OF_TEAM_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_COMPOSITION_OF_TEAM_REQUEST`,
    SUBMIT_COMPOSITION_OF_TEAM_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_COMPOSITION_OF_TEAM_SUCCESS`,
    SUBMIT_COMPOSITION_OF_TEAM_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_COMPOSITION_OF_TEAM_FAILURE`,

    FETCH_COMPOSITION_OF_TEAM_LIST: `${STATE_REDUCER_KEY}/FETCH_COMPOSITION_OF_TEAM_LIST`,
    FETCH_COMPOSITION_OF_TEAM_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPOSITION_OF_TEAM_LIST_REQUEST`,
    FETCH_COMPOSITION_OF_TEAM_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPOSITION_OF_TEAM_LIST_SUCCESS`,
    FETCH_COMPOSITION_OF_TEAM_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPOSITION_OF_TEAM_LIST_FAILURE`,

    FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID: `${STATE_REDUCER_KEY}/FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID`,
    FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID_REQUEST`,
    FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID_SUCCESS`,
    FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID_FAILURE`,

    UPDATE_COMPOSITION_OF_TEAM: `${STATE_REDUCER_KEY}/UPDATE_COMPOSITION_OF_TEAM`,
    UPDATE_COMPOSITION_OF_TEAM_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_COMPOSITION_OF_TEAM_REQUEST`,
    UPDATE_COMPOSITION_OF_TEAM_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_COMPOSITION_OF_TEAM_SUCCESS`,
    UPDATE_COMPOSITION_OF_TEAM_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_COMPOSITION_OF_TEAM_FAILURE`,

    DELETE_COMPOSITION_OF_TEAM: `${STATE_REDUCER_KEY}/DELETE_COMPOSITION_OF_TEAM`,
    DELETE_COMPOSITION_OF_TEAM_REQUEST: `${STATE_REDUCER_KEY}/DELETE_COMPOSITION_OF_TEAM_REQUEST`,
    DELETE_COMPOSITION_OF_TEAM_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_COMPOSITION_OF_TEAM_SUCCESS`,
    DELETE_COMPOSITION_OF_TEAM_FAILURE: `${STATE_REDUCER_KEY}/DELETE_COMPOSITION_OF_TEAM_FAILURE`,

    CONFIRM_FORM_SUBMIT: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT`,
    CONFIRM_FORM_SUBMIT_REQUEST: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_REQUEST`,
    CONFIRM_FORM_SUBMIT_SUCCESS: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_SUCCESS`,
    CONFIRM_FORM_SUBMIT_FAILURE: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_FAILURE`

};
export const saveCompositionOfTeam = createAction(ACTION_TYPES.SUBMIT_COMPOSITION_OF_TEAM);
export const fetchCompositionOfTeam = createAction(ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST);
export const fetchCompositionOfTeamById = createAction(ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID);
export const updateCompositionOfTeam = createAction(ACTION_TYPES.UPDATE_COMPOSITION_OF_TEAM);
export const deleteCompositionOfTeam = createAction(ACTION_TYPES.DELETE_COMPOSITION_OF_TEAM);
export const confirmComposition = createAction(ACTION_TYPES.CONFIRM_FORM_SUBMIT);

