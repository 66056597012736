import { REACT_TABLE_COMMON_OPTIONS, ROWS_PER_PAGE, TABLE_IDS } from "common/constants";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "modules/common/components/CommonTable";
import { STATE_REDUCER_KEY } from "modules/common/constants";
import { useMemo } from "react";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { listTrainingConductedList, trainingConductedReportDownload } from "./actions";
import { Components, I18n, Icons } from "common/components";
import TrainingConductedFilter from "./TrainingConductedFilter";
import { formatDate, TIME_FORMAT, toEpoch } from "utils/dateUtils";
import moment from "moment";

const { FilterAltRounded, ListAlt, PictureAsPdf } = Icons;
const { Badge } = Components;
const TrainingConducted = () => {
    const dispatch = useDispatch();
    const tableData = useSelector(state => state[STATE_REDUCER_KEY]);
    const { table: { TRAINING_CONDUCTED_REPORT: { filters = {} } = {} } = {} } = tableData;
    let customActions = [];
    customActions.push({
        title: I18n("download_excel"), icon: <ListAlt fontSize="small" />, handleClick: () => dispatch(trainingConductedReportDownload({ type: "xls", filters: filters }))
    });
    customActions.push({ title: I18n("download_pdf"), icon: <PictureAsPdf fontSize="small" />, handleClick: () => dispatch(trainingConductedReportDownload({ type: "pdf", filters: filters })) });
    const toolBarActions = [];
    toolBarActions.push({ key: "download", component: <CustomListMenu customActions={customActions} type="download" /> });

    if (toolBarActions) {
        toolBarActions.push({
            title: "Open Filter", icon: <Badge badgeContent={Object.keys(filters).length} color="primary"><FilterAltRounded fontSize="medium" /></Badge>, key: "customFilter"
        });
    }
    const options = {
        ...REACT_TABLE_COMMON_OPTIONS,
        enableRowSelection: false,
        enableFilters: true,
        toolBarActions: toolBarActions,
        customPagination: {
            rowsPerPageOptions: ROWS_PER_PAGE
        },
        enableRowActions: false
    };

    const columns = useMemo(
        () => [
            // {
            //     id: "lsgiName",
            //     header: I18n("lsgi_name"),
            //     accessorKey: "lsgiName"
            // },
            {
                id: "batchName",
                header: I18n("batch_name"),
                accessorKey: "batchName"
            },
            {
                id: "sessionName",
                header: I18n("session_name"),
                accessorKey: "sessionName"
            },
            {
                id: "trainingDate",
                header: I18n("training_date"),
                accessorKey: "trainingDate"
            },
            {
                id: "trainingConductedExpectedCount",
                header: I18n("expected_count"),
                accessorKey: "trainingConductedExpectedCount"
            },
            {
                id: "trainingConductedActualCount",
                header: I18n("actual_participants"),
                accessorKey: "trainingConductedActualCount"
            },
            {
                id: "feedbackReceivedCount",
                header: I18n("feed_back_recieved_count"),
                accessorKey: "feedbackReceivedCount"
            },
            {
                id: "fromTime",
                header: I18n("from_time"),
                accessorFn: ({ fromTime = toEpoch(moment.now()) }) => formatDate(fromTime, TIME_FORMAT)
            },
            {
                id: "toTime",
                header: I18n("to_time"),
                accessorFn: ({ toTime = toEpoch(moment.now()) }) => formatDate(toTime, TIME_FORMAT)

            },
            {
                id: "venue",
                header: I18n("venue"),
                accessorKey: "venue"
            },
            {
                id: "organizedBy",
                header: I18n("organized_by"),
                accessorKey: "organizedBy"
            },
            {
                id: "trainingMode",
                header: I18n("training_mode"),
                accessorKey: "trainingMode"
            }
        ],
        []
    );

    return (
        <>
            <CommonTable
                columns={columns}
                options={options}
                enableVirtualization={false}
                title={I18n("training_conducted")}
                fetchData={listTrainingConductedList}
                enableCustomTableFilter={true}
                filterComponent={({ setOpen }) => <TrainingConductedFilter onClose={setOpen} />}
                tableId={TABLE_IDS.TRAINING_CONDUCTED_REPORT}
            />
        </>
    );
};

export default TrainingConducted;
