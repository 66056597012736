import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";
export const savePlanAPICall = (data) => {
    return {
        url: API_URL.TRAINING_PLAN.SAVE_PLAN,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SAVE_PLAN_REQUEST, ACTION_TYPES.SAVE_PLAN_SUCCESS, ACTION_TYPES.SAVE_PLAN_FAILURE],
            data
        }
    };
};
export const listPlanAPICall = (data) => {
    return {
        url: API_URL.TRAINING_PLAN.LIST_PLAN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PLAN_DETAILS_REQUEST, ACTION_TYPES.FETCH_PLAN_DETAILS_SUCCESS, ACTION_TYPES.FETCH_PLAN_DETAILS_FAILURE],
            data
        }
    };
};
export const deleteTrainerPlanByIdApi = ({ id }) => {
    return {
        url: API_URL.TRAINING_PLAN.DELETE_PLAN_ID.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID_REQUEST, ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID_SUCCESS, ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID_FAILURE]
        }
    };
};
export const createTrainingPlanApi = ({ id }) => {
    return {
        url: API_URL.TRAINING_PLAN.DELETE_PLAN_ID.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID_REQUEST, ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID_SUCCESS, ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID_FAILURE]
        }
    };
};

export const editTrainingPlanApi = (data) => {
    return {
        url: API_URL.TRAINING_PLAN.EDIT_TRAINING_PLAN,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_PLAN_FORM_REQUEST, ACTION_TYPES.EDIT_PLAN_FORM_SUCCESS, ACTION_TYPES.EDIT_PLAN_FORM_FAILURE],
            data
        }
    };
};

export const editPlanByIdApi = (id) => {
    return {
        url: API_URL.TRAINING_PLAN.EDIT_PLAN_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.EDIT_PLAN_BY_ID_REQUEST, ACTION_TYPES.EDIT_PLAN_BY_ID_SUCCESS, ACTION_TYPES.EDIT_PLAN_BY_ID_FAILURE],
            data: { id }
        }
    };
};
export const fetchTrainingPlanFormById = ({ id }) => {
    return {
        url: API_URL.TRAINING_PLAN.FETCH_NEED_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PLAN_BY_ID_REQUEST, ACTION_TYPES.FETCH_PLAN_BY_ID_SUCCESS, ACTION_TYPES.FETCH_PLAN_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const fetchTrainerNeedIdApi = () => {
    return {
        url: API_URL.TRAINING_PLAN.FETCH_NEED_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TRAINER_NEED_ID_REQUEST, ACTION_TYPES.FETCH_TRAINER_NEED_ID_SUCCESS, ACTION_TYPES.FETCH_TRAINER_NEED_ID_FAILURE]
        }
    };
};

export const fetchTargetApi = () => {
    return {
        url: API_URL.TRAINING_PLAN.TARGET_GROUP,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY_REQUEST, ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY_SUCCESS, ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY_FAILURE]
        }
    };
};

export const fetchCourseApi = () => {
    return {
        url: API_URL.TRAINING_PLAN.COURSE_PLAN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COURSE_PLAN_REQUEST, ACTION_TYPES.FETCH_COURSE_PLAN_SUCCESS, ACTION_TYPES.FETCH_COURSE_PLAN_FAILURE]
        }
    };
};

export const fetchDesignationApi = (targetGroupId, tnaId) => {
    return {
        url: API_URL.TRAINING_PLAN.DESIGNATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PLAN_DESIGNATION_REQUEST, ACTION_TYPES.FETCH_PLAN_DESIGNATION_SUCCESS, ACTION_TYPES.FETCH_PLAN_DESIGNATION_FAILURE],
            data: { targetGroupId, tnaId }
        }
    };
};

export const getFileById = (id) => {
    return {
        url: API_URL.TRAINING_PLAN.GET_UPLOADED_FILE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_FILE_REQUEST, ACTION_TYPES.FETCH_FILE_SUCCESS, ACTION_TYPES.FETCH_FILE_FAILURE],
            data: { id }
        }
    };
};
export const deleteFileById = (id, fileId) => {
    let DELETE_API_URL = API_URL.TRAINING_PLAN.DELETE_FILE_BY_ID;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":file_id", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_FILE_REQUEST, ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_REQUEST]
        }
    };
};
