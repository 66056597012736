import { I18n, Icons, RootBoundary } from "common/components";
import { SHOW_EXAMPLE_MODULE, SHOW_IN_PROGRESS_MODULE } from "common/constants";
import { lazy } from "react";
import App from "../App";
import { routes as adminRoutes } from "../modules/admin/routes";
import { routes as exampleRoutes } from "../modules/example/routes";
import { routes as grievanceRoutes } from "../modules/grievance/routes";
import { routes as tcmRoutes } from "../modules/tcm/routes";
import { routes as userManagement } from "../modules/user-management/routes";
import { routes as reportRoutes } from "../modules/reports/routes";
import { routes as subProjectsRoutes } from "../modules/subProjects/routes";
import { routes as grandManagementRoutes } from "../modules/grantManagement/routes";
import { routes as operationalModuleRoutes } from "../modules/operationalModule/routes";
import { routes as visitorsFormRoutes } from "../modules/addvisitor/addstalldata/routes";
import { MODULE_PATH } from "./paths";
import { RESOURCE_ID } from "./resources";
import { PrivateRoute } from "./user-management/protected-route/protectedRoute";
import VisitorsHome from "./addvisitor/addstalldata/VisitorsHome";

const { Home } = Icons;
const Admin = lazy(() => import("./admin/Home"));
const Grievance = lazy(() => import("./grievance/Home"));
const Example = lazy(() => import("./example/Home"));
const Tcm = lazy(() => import("./tcm/Home"));
const Reports = lazy(() => import("./reports/Home"));
const SubProjects = lazy(() => import("./subProjects/Home"));
const GrandManagement = lazy(() => import("./grantManagement/Home"));
const OperationalModule = lazy(() => import("./operationalModule/Home"));
const Dashboard = lazy(() => import("./dashboard/charts/components/Dashboard"));
// const AdminDashboard = lazy(() => import("./dashboard/charts/components/AdminDashboard"));
const routes =
    [
        {
            path: "/",
            element:
                <PrivateRoute>
                    <App />
                </PrivateRoute>,
            errorElement: <RootBoundary />,
            breadcrumb: I18n("home"),
            icon: <Home />,
            children: [
                {
                    title: I18n("dashboard"),
                    path: "Dashboard",
                    children: [],
                    element: <Dashboard />,
                    errorElement: <RootBoundary />
                },
                {
                    title: I18n("admin"),
                    path: MODULE_PATH.ADMIN,
                    element:
                        <PrivateRoute>
                            <Admin />
                        </PrivateRoute>,
                    children: adminRoutes || [],
                    errorElement: <RootBoundary />,
                    id: RESOURCE_ID.ADMIN.ROUTE
                },
                {
                    title: I18n("grievance"),
                    path: MODULE_PATH.GRIEVANCE,
                    element:
                        <Grievance />,
                    children: grievanceRoutes || [],
                    errorElement: <RootBoundary />,
                    id: RESOURCE_ID.GRIEVANCE.ROUTE
                },
                {
                    title: I18n("grand_management"),
                    path: MODULE_PATH.GRAND_MANAGEMENT,
                    element:
                        <GrandManagement />,
                    children: grandManagementRoutes || [],
                    errorElement: <RootBoundary />,
                    id: RESOURCE_ID.GRANT_MANAGEMENT.ROUTE
                },
                {
                    title: I18n("training"),
                    path: MODULE_PATH.TRAINING,
                    element:
                        <Tcm />,
                    children: tcmRoutes || [],
                    errorElement: <RootBoundary basePath={MODULE_PATH.TRAINING} />,
                    id: RESOURCE_ID.TRAINING.ROUTE
                },
                {
                    title: I18n("sub_projects"),
                    path: MODULE_PATH.SUB_PROJECTS,
                    element:
                        <SubProjects />,
                    children: subProjectsRoutes || [],
                    errorElement: <RootBoundary basePath={MODULE_PATH.SUB_PROJECTS} />,
                    id: RESOURCE_ID.SUB_PROJECT.ROUTE
                },
                {
                    title: I18n("operational_module"),
                    path: MODULE_PATH.OPERATIONAL_MODULE,
                    element:
                        <OperationalModule />,
                    children: operationalModuleRoutes || [],
                    errorElement: <RootBoundary basePath={MODULE_PATH.OPERATIONAL_MODULE} />,
                    id: RESOURCE_ID.OPERATION_MODULE.ROUTE
                }
            ]
        },
        {
            title: I18n("user_management"),
            children: userManagement || []
        },
        {
            title: I18n("data_collection_form"),
            path: "/Royal-Prince-Holdings-World-Police-Summit-2024",
            element: <VisitorsHome />,
            children: visitorsFormRoutes || []
        }
    ];

if (SHOW_EXAMPLE_MODULE) {
    routes[0].children.push(
        {
            title: I18n("example"),
            path: "example",
            element: <Example />,
            children: exampleRoutes || []
        }
    );
}

if (SHOW_IN_PROGRESS_MODULE) {
    routes[0].children.push(
        {
            title: "reports",
            path: MODULE_PATH.REPORTS,
            element:
                <Reports />,
            children: reportRoutes || []
        },
        {
            title: I18n("operational_module"),
            path: MODULE_PATH.OPERATIONAL_MODULE,
            element:
                <OperationalModule />,
            children: operationalModuleRoutes || [],
            errorElement: <RootBoundary />,
            id: RESOURCE_ID.OPERATION_MODULE.ROUTE
        }
    );
}

export { routes };
