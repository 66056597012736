import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const initialState = {
    createGapAnalysis: {
        requestInProgress: false,
        data: {
            districtId: "",
            lsgiId: "",
            version: "",
            recordedDate: ""
        }
    },
    gapAnalysis: {
        requestInProgress: false,
        data: []
    },
    wasteGenerated: {
        requestInProgress: false,
        data: {
            perCapitaWasteGeneration: "",
            perCapitaBioWaste: "",
            perCapitaNonBioWaste: "",
            totalBioWaste: "",
            totalNonBioWaste: "",
            totalSanitary: "",
            totalEWaste: "",
            totalCnD: "",
            totalHazardous: "",
            totalInert: "",
            totalWasteGeneration: "",
            version: ""
        }
    },
    listWasteGenerated: {
        requestInProgress: false,
        data: []
    },
    lsgiDropDownOptions: {
        requestInProgress: false,
        data: []
    },
    createProfileOfLocalBody: {
        requestInProgress: false,
        data: {
            id: "",
            wardCount: "",
            residentialArea: "",
            agricultralArea: "",
            commercialArea: "",
            industrialArea: "",
            vaccantLandArea: "",
            otherArea: "",
            totalArea: 0,
            version: "",
            districtId: "",
            lsgiId: "",
            stateId: "",
            gapAnalysisId: ""
        }
    },
    listProfileOfLocalBody: {
        requestInProgress: false,
        data: []
    },
    nonBioFacilitiesById: {
        requestInProgress: false,
        data: {
            gapAnalysisNonBioFacilitiesDto: []
        }
    },
    totalNonBioFacilities: {
        requestInProgress: false,
        data: {
            totalBldgArea: 0,
            totalWasteCapacity: 0,
            totalBldgCost: 0,
            totalWeighingMachines: 0,
            totalSortingTable: 0,
            totalBailingMachine: 0,
            totalShreddingMachine: 0,
            totalDebuster: 0,
            totalConveyorBelt: 0,
            totalSegregationBoxes: 0,
            totalFireExtinguishers: 0,
            totalWashingMachines: 0,
            totalPelletiser: 0,
            totalOtherEquipments: 0
        }
    },
    listOfTransportation: {
        requestInProgress: false,
        data: {
            gapAnalysisTransportationDto: []
        }
    },
    wasteGeneratorsHousehold: {
        requestInProgress: false,
        data: {
            gapAnalysisHouseHoldWasteGeneratorsDto: []
        }
    },
    wasteGeneratorsInstitutions: {
        requestInProgress: false,
        data: {
            gapAnalysisInstitutionWasteGeneratorsDto: []
        }
    },
    nonBioFacilitiesEquipmentById: {
        requestInProgress: false,
        data: {
            gapAnalysisNonBioFacilityEquipmentsDto: []
        }
    },
    otherNonBioFacilitiesById: {
        requestInProgress: false,
        data: {
            gapAnalysisOtherNonBioFacilitiesDto: []
        }
    },
    forwardLinked: {
        requestInProgress: false,
        data: {
            gapAnalysisForwardLinkedDto: []
        }
    },
    involvementOfHks: {
        requestInProgress: false,
        data: {
            gapAnalysisInvolvementOfHksDto: []
        }
    },
    bioFacilitiesEquipmentById: {
        requestInProgress: false,
        data: {
            gapAnalysisBioFacilityEquipmentsDto: []
        }
    },
    bioFacilitiesById: {
        requestInProgress: false,
        data: {
            gapAnalysisBioFacilitiesDto: []
        }
    },
    listAllStreetSweeping: {
        requestInProgress: false,
        data: []
    },
    CreateStreetSweepingAndDrains: {
        requestInProgress: false,
        data: {
            gapAnalysisId: "",
            version: "",
            totalNationalHighwayLength: "",
            totalStateHighwayLength: "",
            totalOtherPwdRoadLength: "",
            totalUlbRoadLength: "",
            totalUlbOtherRoadLength: "",
            totalRoadLength: "",
            avgRoadLengthSweepedManual: "",
            avgRoadLengthSweepedAutomatic: "",
            avgTotalPersonRoadSweepedManual: "",
            avgTotalPersonRoadSweepedAutomatic: "",
            avgTotalRoadLengthSweepedManual: 0,
            avgTotalRoadLengthSweepedAutomatic: 0,
            gapInRoadSweeping: "",
            totalOpenDrainLength: "",
            totalClosedDrainLength: "",
            totalDrainLength: "",
            avgDrainLengthCleaned: "",
            avgTotalPersonDrainLengthCleaned: "",
            avgTotalDrainCleaned: "",
            gapInDrainCleaning: 0
        }
    },
    createPaymentToStaff: {
        requestInProgress: false,
        data: {
            id: "",
            gapAnalysisId: "",
            version: "",
            year: "",
            totalWagesHksMember: "",
            totalWagesSanitationWorker: "",
            totalWagesContractStaff: "",
            totalWagesHarithasahayasthapanam: "",
            totalExpenseIecAwareness: "",
            totalExpenseIctTechnologyQrcode: "",
            totalAmountConsultants: "",
            totalAmountTraining: "",
            totalAmountFuel: "",
            totalAmountVehicleMaintenance: "",
            totalAmountMedical: "",
            totalAmountPpeUniforms: "",
            totalExpenseElectricity: "",
            totalExpenseWasteRejects: ""
        }
    },
    listAllPaymentToStaff: {
        requestInProgress: false,
        data: []
    },
    landDetails: {
        requestInProgress: false,
        data: {
            gapAnalysisLandDetailsDto: []
        }
    },
    incomeFromWaste: {
        requestInProgress: false,
        data: {
            gapAnalysisId: "",
            version: "",
            year: "",
            totalIncomeBdwWaste: "",
            totalIncomeNbdwWaste: "",
            totalIncomeUserFee: "",
            totalIncomeScrapSale: "",
            totalIncomeWaste: "",
            totalNbdwManaged: "",
            totalBdwManaged: "",
            totalWasteManaged: ""
        }
    },
    listIncomeFromWaste: {
        requestInProgress: false,
        data: []
    },
    wasteProcessedHouseHold: {
        requestInProgress: false,
        data: {
            gapAnalysisWasteProcessedDto: []
        }
    },
    wasteProcessedInstitutions: {
        requestInProgress: false,
        data: {
            gapAnalysisWasteProcessedDto: []
        }
    },
    bdwCommunityLevel: {
        requestInProgress: false,
        data: {
            gapAnalysisId: "",
            totalProcessedPerDay: 0,
            wte: "",
            bioCng: "",
            otherBioFacilities: "",
            owcPlant: "",
            biogasPlant: "",
            windrowPlant: "",
            version: ""
        }
    },
    listAllBdwCommunityLevel: {
        requestInProgress: false,
        data: []
    },
    bdwProcessedPerDay: {
        requestInProgress: false,
        data: {
            totalProcessedPerDay: 0,
            rrfProcessed: "",
            mcfProcessed: "",
            version: "",
            otherProcessed: "",
            quantityOfNbdwRemaining: ""
        }
    },
    listBdwProcessedPerDayData: {
        requestInProgress: false,
        data: []
    },
    population: {
        requestInProgress: false,
        data: {
            gapAnalysisId: "",
            version: "",
            residentialPopulation1991: "",
            residentialPopulation2001: "",
            residentialPopulation2011: "",
            residentialPopulation2023: "",
            residentialPopulation2028: "",
            floatingPopulation: "",
            otherPopulation: "",
            totalPopulation: 0
        }
    },
    listPopulation: {
        requestInProgress: false,
        data: []
    },
    bdwCommunityLevelUlb: {
        requestInProgress: false,
        data: {
            gapAnalysisId: "",
            version: "",
            totalReceivedCommunityLevel: 0,
            receivedWindrowPlant: "",
            receivedBiogasPlant: "",
            receivedWte: "",
            receivedOwc: "",
            receivedBioCng: "",
            receivedOther: "",
            gapReceivedVsCollected: 0
        }
    },
    listBdwCommunityLevelUlb: {
        requestInProgress: false,
        data: []
    },
    nonBioDegradable: {
        requestInProgress: false,
        data: {
            gapAnalysisId: "",
            version: "",
            receivedMcf: "",
            receivedRrf: "",
            totalReceieved: 0,
            gapInReceievdVsCollected: 0
        }
    },
    listNonBioDegradable: {
        requestInProgress: false,
        data: []
    },
    listNonBioFacilitiesType: {
        requestInProgress: false,
        data: []
    },
    bioDegradableWaste: {
        requestInProgress: false,
        data: {
            gapAnalysisBioDegradableWasteDto: []
        }
    },
    nonBioDegradableWaste: {
        requestInProgress: false,
        data: {
            gapAnalysisNonBioDegradableWasteDto: []
        }
    },
    totalHousehold: {
        requestInProgress: false,
        data: []
    },
    totalInstitutions: {
        requestInProgress: false,
        data: []
    },
    nonBioFacilityOwnerShipList: {
        requestInProgress: false,
        data: []
    },
    nonBioFacilityCurrentStatusList: {
        requestInProgress: false,
        data: []
    },
    bioFacilities: {
        requestInProgress: false,
        data: []
    },
    addWardWisePopulation: {
        requestInProgress: false,
        data: {
            wardId: [],
            wardWisePopulation: "",
            gapAnalysisId: ""
        }
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        removeNonbioFacilitiesLevel: (state, { payload: { result } }) => {
            _.set(state, "nonBioFacilitiesById.data.gapAnalysisNonBioFacilitiesDto", result);
        },
        removeBioFacilitiesLevel: (state, { payload: { result } }) => {
            _.set(state, "bioFacilities.data.gapAnalysisBioFacilitiesDto", result);
        },
        removeWardWisePopulation: (state) => {
            state.addWardWisePopulation.data = initialState.addWardWisePopulation;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_GAP_ANALYSIS_SUCCESS, (state, { payload }) => {
                _.set(state, "gapAnalysis.requestInProgress", false);
                _.set(state, "gapAnalysis.data", payload?.content);
            })
            .addCase(ACTION_TYPES.POST_GAP_ANALYSIS_SUCCESS, (state, { payload }) => {
                _.set(state, "createGapAnalysis.requestInProgress", false);
                _.set(state, "createGapAnalysis.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_GAP_ANALYSIS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "createGapAnalysis.requestInProgress", false);
                _.set(state, "createGapAnalysis.data", payload);
            })
            .addCase(ACTION_TYPES.SAVE_WASTE_GENERATED_BY_ID_REQUEST, (state) => {
                _.set(state, "wasteGenerated.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.SAVE_WASTE_GENERATED_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "wasteGenerated.requestInProgress", false);
                _.set(state, "wasteGenerated.data", payload);
            })
            .addCase(ACTION_TYPES.LIST_WASTE_GENERATED_SUCCESS, (state, { payload }) => {
                _.set(state, "listWasteGenerated.requestInProgress", false);
                _.set(state, "listWasteGenerated.data", payload.content);
            })
            .addCase(ACTION_TYPES.SELECT_LSGI_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "lsgiDropDownOptions.requestInProgress", false);
                _.set(state, "lsgiDropDownOptions.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY_SUCCESS, (state, { payload }) => {
                _.set(state, "listProfileOfLocalBody.requestInProgress", false);
                _.set(state, "listProfileOfLocalBody.data", payload?.content);
            })
            .addCase(ACTION_TYPES.NON_BIO_FACILITIES_BY_ID_REQUEST, (state) => {
                state.nonBioFacilitiesById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.NON_BIO_FACILITIES_BY_ID_FAILURE, (state) => {
                state.nonBioFacilitiesById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.NON_BIO_FACILITIES_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "nonBioFacilitiesById.requestInProgress", false);
                _.set(state, "nonBioFacilitiesById.data.gapAnalysisNonBioFacilitiesDto", payload);
            })
            .addCase(ACTION_TYPES.FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY_REQUEST, (state) => {
                state.createProfileOfLocalBody.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY_SUCCESS, (state, { payload }) => {
                _.set(state, "createProfileOfLocalBody.requestInProgress", false);
                _.set(state, "createProfileOfLocalBody.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_TRANSPORTATION_BY_ID_REQUEST, (state) => {
                state.listOfTransportation.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_TRANSPORTATION_BY_ID_SUCCESS, (state, { payload }) => {
                const responseArray = payload;
                responseArray.forEach(item => {
                    item.vehicleCost = item?.totalCapacity * item?.tripsPerDay || 0;
                    item.perDayCapacity = item?.totalCapacity * item?.tripsPerDay || 0;
                });
                _.set(state, "listOfTransportation.requestInProgress", false);
                _.set(state, "listOfTransportation.data.gapAnalysisTransportationDto", payload);
            })
            .addCase(ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD_REQUEST, (state) => {
                state.wasteGeneratorsHousehold.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS, (state, { payload }) => {
                _.set(state, "wasteGeneratorsHousehold.requestInProgress", false);
                _.set(state, "wasteGeneratorsHousehold.data.gapAnalysisHouseHoldWasteGeneratorsDto", payload);
            })
            .addCase(ACTION_TYPES.FETCH_FORWARD_LINKED_BY_ID_REQUEST, (state) => {
                state.forwardLinked.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_FORWARD_LINKED_BY_ID_SUCCESS, (state, { payload }) => {
                const responseArray = payload;
                responseArray.forEach(item => {
                    item.tonnesPerDay = item?.totalTonnes / 365 || 0;
                });
                _.set(state, "forwardLinked.requestInProgress", false);
                _.set(state, "forwardLinked.data.gapAnalysisForwardLinkedDto", payload);
            })
            .addCase(ACTION_TYPES.FETCH_INVOLVEMENT_OF_HKS_BY_ID_REQUEST, (state) => {
                state.involvementOfHks.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_INVOLVEMENT_OF_HKS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "involvementOfHks.requestInProgress", false);
                _.set(state, "involvementOfHks.data.gapAnalysisInvolvementOfHksDto", payload);
            })
            .addCase(ACTION_TYPES.NON_BIO_FACILITIES_EQUIPMENT_BY_ID_REQUEST, (state) => {
                state.nonBioFacilitiesEquipmentById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.NON_BIO_FACILITIES_EQUIPMENT_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "nonBioFacilitiesEquipmentById.requestInProgress", false);
                _.set(state, "nonBioFacilitiesEquipmentById.requestInProgress", false);
                _.set(state, "nonBioFacilitiesEquipmentById.data.gapAnalysisNonBioFacilityEquipmentsDto", payload);
            })
            .addCase(ACTION_TYPES.OTHER_NON_BIO_FACILITIES_ID_REQUEST, (state) => {
                state.otherNonBioFacilitiesById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.OTHER_NON_BIO_FACILITIES_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "otherNonBioFacilitiesById.requestInProgress", false);
                _.set(state, "otherNonBioFacilitiesById.data.gapAnalysisOtherNonBioFacilitiesDto", payload);
            })
            .addCase(ACTION_TYPES.BIO_FACILITIES_BY_ID_REQUEST, (state) => {
                state.bioFacilitiesById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.BIO_FACILITIES_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "bioFacilitiesById.requestInProgress", false);
                _.set(state, "bioFacilitiesById.data.gapAnalysisBioFacilitiesDto", payload);
            })
            .addCase(ACTION_TYPES.BIO_FACILITIES_EQUIPMENT_BY_ID_REQUEST, (state) => {
                state.bioFacilitiesEquipmentById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.BIO_FACILITIES_EQUIPMENT_BY_ID_SUCCESS, (state, { payload }) => {
                const responseArray = payload;
                responseArray.forEach(item => {
                    item.approxTotalCost = item?.count * item?.totalCapacity || 0;
                });
                _.set(state, "bioFacilitiesEquipmentById.requestInProgress", false);
                _.set(state, "bioFacilitiesEquipmentById.data.gapAnalysisBioFacilityEquipmentsDto", payload);
            })
            .addCase(ACTION_TYPES.FETCH_LIST_ALL_STREET_SWEEPING_REQUEST, (state) => {
                _.set(state, "listAllStreetSweeping.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.FETCH_LIST_ALL_STREET_SWEEPING_SUCCESS, (state, { payload }) => {
                _.set(state, "listAllStreetSweeping.requestInProgress", false);
                _.set(state, "listAllStreetSweeping.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_BY_ID_STREET_SWEEPING_REQUEST, (state) => {
                _.set(state, "CreateStreetSweepingAndDrains.requestInProgress", true);

            })
            .addCase(ACTION_TYPES.FETCH_BY_ID_STREET_SWEEPING_SUCCESS, (state, { payload }) => {
                _.set(state, "CreateStreetSweepingAndDrains.requestInProgress", false);
                _.set(state, "CreateStreetSweepingAndDrains.data", payload?.content[0]);
            })
            .addCase(ACTION_TYPES.LAND_DETAILS_BY_ID_REQUEST, (state) => {
                state.landDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.LAND_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "landDetails.requestInProgress", false);
                _.set(state, "landDetails.data.gapAnalysisLandDetailsDto", payload);
            })
            .addCase(ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS_REQUEST, (state) => {
                state.wasteGeneratorsInstitutions.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS_SUCCESS, (state, { payload }) => {
                _.set(state, "wasteGeneratorsInstitutions.requestInProgress", false);
                _.set(state, "wasteGeneratorsInstitutions.data.gapAnalysisInstitutionWasteGeneratorsDto", payload);
            })
            .addCase(ACTION_TYPES.STAFF_PAYMENT_LIST_ALL_SUCCESS, (state, { payload }) => {
                _.set(state, "listAllPaymentToStaff.requestInProgress", false);
                _.set(state, "listAllPaymentToStaff.data", payload?.content);
            })
            .addCase(ACTION_TYPES.STAFF_PAYMENT_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "createPaymentToStaff.requestInProgress", false);
                _.set(state, "createPaymentToStaff.data", payload);
                _.set(state, "createPaymentToStaff.data.year", { name: payload?.year });
            })
            .addCase(ACTION_TYPES.SAVE_INCOME_FROM_WASTE_BY_ID_REQUEST, (state) => {
                _.set(state, "incomeFromWaste.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.SAVE_INCOME_FROM_WASTE_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "incomeFromWaste.requestInProgress", false);
                _.set(state, "incomeFromWaste.data", payload);
                _.set(state, "incomeFromWaste.data.year", { name: payload?.year });
            })
            .addCase(ACTION_TYPES.LIST_INCOME_FROM_WASTE_SUCCESS, (state, { payload }) => {
                _.set(state, "listIncomeFromWaste.requestInProgress", false);
                _.set(state, "listIncomeFromWaste.data", payload.content);
            })
            .addCase(ACTION_TYPES.WASTE_PROCESSED_HOUSE_HOLD_BY_ID_REQUEST, (state) => {
                _.set(state, "wasteProcessedHouseHold.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.WASTE_PROCESSED_HOUSE_HOLD_BY_ID_SUCCESS, (state, { payload }) => {
                const responseArray = payload;
                const sumProperties = ["ulbSupplied", "ownFacility", "functional", "capacityInTpd", "wasteProcessedInTpd", "totalApproxCost"];

                const sumArray = new Array(sumProperties.length).fill(0);

                for (let i = 0; i < Math.min(5, responseArray.length); i++) {
                    for (let j = 0; j < sumProperties.length; j++) {
                        const property = sumProperties[j];
                        sumArray[j] += parseInt(responseArray[i]?.[property] || 0);
                    }
                }

                if (responseArray.length > 4) {
                    responseArray[5] = { ...responseArray[5], ...Object.fromEntries(sumProperties.map((property, i) => [property, sumArray[i] || 0])) };
                } else {
                    responseArray.push(Object.fromEntries(sumProperties.map((property, i) => [property, sumArray[i]])));
                }

                _.set(state, "wasteProcessedHouseHold.requestInProgress", false);
                _.set(state, "wasteProcessedHouseHold.data.gapAnalysisWasteProcessedDto", payload);
            })
            .addCase(ACTION_TYPES.WASTE_PROCESSED_INSTITUTIONS_BY_ID_REQUEST, (state) => {
                _.set(state, "wasteProcessedInstitutions.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.WASTE_PROCESSED_INSTITUTIONS_BY_ID_SUCCESS, (state, { payload }) => {
                const responseArray = payload;
                const sumProperties = ["ulbSupplied", "ownFacility", "functional", "capacityInTpd", "wasteProcessedInTpd", "totalApproxCost"];

                const sumArray = new Array(sumProperties.length).fill(0);

                for (let i = 0; i < Math.min(5, responseArray.length); i++) {
                    for (let j = 0; j < sumProperties.length; j++) {
                        const property = sumProperties[j];
                        sumArray[j] += parseInt(responseArray[i]?.[property] || 0);
                    }
                }

                if (responseArray.length > 4) {
                    responseArray[5] = { ...responseArray[5], ...Object.fromEntries(sumProperties.map((property, i) => [property, sumArray[i] || ""])) };
                } else {
                    responseArray.push(Object.fromEntries(sumProperties.map((property, i) => [property, sumArray[i]])));
                }
                _.set(state, "wasteProcessedInstitutions.requestInProgress", false);
                _.set(state, "wasteProcessedInstitutions.data.gapAnalysisWasteProcessedDto", payload);
            })
            .addCase(ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_ALL_REQUEST, (state) => {
                _.set(state, "listAllBdwCommunityLevel.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_ALL_SUCCESS, (state, { payload }) => {
                _.set(state, "listAllBdwCommunityLevel.requestInProgress", false);
                _.set(state, "listAllBdwCommunityLevel.data", payload?.content);
            })
            .addCase(ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID_REQUEST, (state) => {
                _.set(state, "bdwCommunityLevel.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "bdwCommunityLevel.requestInProgress", false);
                _.set(state, "bdwCommunityLevel.data", payload);
            })
            .addCase(ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_BY_ID_REQUEST, (state) => {
                _.set(state, "bdwProcessedPerDay.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "bdwProcessedPerDay.requestInProgress", false);
                _.set(state, "bdwProcessedPerDay.data", payload);
            })
            .addCase(ACTION_TYPES.LIST_BDW_PROCESSED_PER_DAY_SUCCESS, (state, { payload }) => {
                _.set(state, "listBdwProcessedPerDayData.requestInProgress", false);
                _.set(state, "listBdwProcessedPerDayData.data", payload.content);
            })
            .addCase(ACTION_TYPES.SAVE_POPULATION_BY_ID_REQUEST, (state) => {
                _.set(state, "population.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.SAVE_POPULATION_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "population.requestInProgress", false);
                _.set(state, "population.data", payload);
            })
            .addCase(ACTION_TYPES.LIST_POPULATION_SUCCESS, (state, { payload }) => {
                _.set(state, "listPopulation.requestInProgress", false);
                _.set(state, "listPopulation.data", payload.content);
            })
            .addCase(ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_BY_ID_REQUEST, (state) => {
                _.set(state, "bdwCommunityLevelUlb.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "bdwCommunityLevelUlb.requestInProgress", false);
                _.set(state, "bdwCommunityLevelUlb.data", payload);
            })
            .addCase(ACTION_TYPES.LIST_BDW_COMMUNITY_LEVEL_SUCCESS, (state, { payload }) => {
                _.set(state, "listBdwCommunityLevelUlb.requestInProgress", false);
                _.set(state, "listBdwCommunityLevelUlb.data", payload.content);
            })
            .addCase(ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_BY_ID_REQUEST, (state) => {
                _.set(state, "nonBioDegradable.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "nonBioDegradable.requestInProgress", false);
                _.set(state, "nonBioDegradable.data", payload);
            })
            .addCase(ACTION_TYPES.LIST_NON_BIO_FACILITIES_TYPE_SUCCESS, (state, { payload }) => {
                _.set(state, "listNonBioFacilitiesType.requestInProgress", false);
                _.set(state, "listNonBioFacilitiesType.data", payload?.content);
            })
            .addCase(ACTION_TYPES.LIST_NON_BIO_DEGRADABLE_SUCCESS, (state, { payload }) => {
                _.set(state, "listNonBioDegradable.requestInProgress", false);
                _.set(state, "listNonBioDegradable.data", payload?.content);
            })
            .addCase(ACTION_TYPES.BIO_DEGRADABLE_BY_ID_REQUEST, (state) => {
                state.bioDegradableWaste.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.BIO_DEGRADABLE_BY_ID_SUCCESS, (state, { payload }) => {
                const responseArray = payload;

                const properties = ["count", "quantity"];
                const sums = { households: [0, 0], institutions: [0, 0] };

                for (let i = 0; i <= 2; i++) {
                    for (const property of properties) {
                        sums.households[properties.indexOf(property)] += parseInt(responseArray[i]?.[property] || 0);
                    }
                }

                for (let i = 5; i <= 7; i++) {
                    for (const property of properties) {
                        sums.institutions[properties.indexOf(property)] += parseInt(responseArray[i]?.[property] || 0);
                    }
                }

                if (responseArray?.length > 3) {
                    responseArray[3] = { ...responseArray[3], ...Object.fromEntries(properties.map((prop, idx) => [prop, sums.households[idx] || ""])) };
                    responseArray[8] = { ...responseArray[8], ...Object.fromEntries(properties.map((prop, idx) => [prop, sums.institutions[idx] || ""])) };
                } else {
                    responseArray.push(Object.fromEntries(properties.map((prop, idx) => [prop, sums.households[idx]])));
                }
                _.set(state, "bioDegradableWaste.requestInProgress", false);
                _.set(state, "bioDegradableWaste.data.gapAnalysisBioDegradableWasteDto", payload);
            })
            .addCase(ACTION_TYPES.NON_BIO_DEGRADABLE_BY_ID_REQUEST, (state) => {
                state.nonBioDegradableWaste.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.NON_BIO_DEGRADABLE_BY_ID_SUCCESS, (state, { payload }) => {
                const responseArray = payload;

                const properties = ["count", "quantity"];
                const sums = { households: [0, 0], institutions: [0, 0] };

                for (let i = 0; i <= 1; i++) {
                    for (const property of properties) {
                        sums.households[properties.indexOf(property)] += parseInt(responseArray[i]?.[property] || 0);
                    }
                }

                for (let i = 3; i <= 4; i++) {
                    for (const property of properties) {
                        sums.institutions[properties.indexOf(property)] += parseInt(responseArray[i]?.[property] || 0);
                    }
                }

                if (responseArray?.length > 2) {
                    responseArray[2] = { ...responseArray[2], ...Object.fromEntries(properties.map((prop, idx) => [prop, sums.households[idx] || ""])) };
                    responseArray[5] = { ...responseArray[5], ...Object.fromEntries(properties.map((prop, idx) => [prop, sums.institutions[idx] || ""])) };
                } else {
                    responseArray.push(Object.fromEntries(properties.map((prop, idx) => [prop, sums.households[idx]])));
                }
                _.set(state, "nonBioDegradableWaste.requestInProgress", false);
                _.set(state, "nonBioDegradableWaste.data.gapAnalysisNonBioDegradableWasteDto", payload);
            })
            .addCase(ACTION_TYPES.FETCH_TOTAL_NON_BIO_FACILITIES_SUCCESS, (state, { payload }) => {
                _.set(state, "totalNonBioFacilities.requestInProgress", false);
                _.set(state, "totalNonBioFacilities.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS, (state, { payload }) => {
                _.set(state, "totalHousehold.requestInProgress", false);
                _.set(state, "totalHousehold.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS_SUCCESS, (state, { payload }) => {
                _.set(state, "totalInstitutions.requestInProgress", false);
                _.set(state, "totalInstitutions.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_OWNERSHIP_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "nonBioFacilityOwnerShipList.requestInProgress", false);
                _.set(state, "nonBioFacilityOwnerShipList.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_CURRENT_STATUS_SUCCESS, (state, { payload }) => {
                _.set(state, "nonBioFacilityCurrentStatusList.requestInProgress", false);
                _.set(state, "nonBioFacilityCurrentStatusList.data", payload?.content);
            })
            .addCase(ACTION_TYPES.GET_BIO_FACILITIES_DROPDOWN_REQUEST, (state) => {
                state.bioFacilities.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_BIO_FACILITIES_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "bioFacilities.requestInProgress", false);
                _.set(state, "bioFacilities.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.GET_BIO_FACILITIES_DROPDOWN_FAILURE, (state) => {
                state.bioFacilities.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.BY_ID_WARD_WISE_POPULATION_REQUEST, (state) => {
                state.addWardWisePopulation.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.BY_ID_WARD_WISE_POPULATION_SUCCESS, (state, action) => {
                _.set(state, "addWardWisePopulation.requestInProgress", false);
                _.set(state, "addWardWisePopulation.data", action.payload);
            })
            .addCase(ACTION_TYPES.BY_ID_WARD_WISE_POPULATION_FAILURE, (state) => {
                state.addWardWisePopulation.requestInProgress = false;
            });
    }
});
export const { actions, reducer } = slice;
