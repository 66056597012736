import React, { useEffect } from "react";
import { I18n } from "common/components";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid, useMediaQuery } from "@mui/material";
import PrivacyPolicy from "./PrivacyPolicy";
import palette from "../../../../common/themes/palette.json";

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
        paddingTop: "100px",
        paddingBottom: "100px",
        backgroundColor: `${ palette.palette.secondary.main }`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    },
    pageTitleContent: {
        position: "relative",
        marginTop: "- 6px",
        textAlign: "center"
    },
    headerTitle: {
        marginBottom: "15px",
        fontSize: "48px",
        color: " #fff"
    },
    headerTitleUl: {
        display: "inline-flex",
        paddingLeft: "0",
        listStyleType: "none",
        marginTop: "10px",
        marginBottom: "-5px"
    },
    headerTitleLi: {
        display: "inline-block",
        position: "relative",
        fontSize: "16px",
        paddingRight: "15px",
        marginLeft: "15px"
    },
    copyRight: {
        backgroundColor: `${palette.palette.secondary.main}`,
        textAlign: "center",
        height: "80px",
        fontSize: "var(--text-sub)",
        padding: "",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }


}));
const gridStyle = {
    backgroundColor: `${palette.palette.secondary.main}`,
    overflowY: "scroll",
    height: "100vh"
};
function PrivacyAndPolicy() {
    let date = new Date();
    let year = date.getFullYear();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const width725 = useMediaQuery("(max-width:725px)");
    const classes = useStyles();
    return (
        <div style={width725 ? gridStyle : { backgroundColor: `${palette.palette.secondary.main}` }}>
            <div className={classes.root}>
                <div className='container'>
                    <div className={classes.pageTitleContent}>
                        <h1 className={classes.headerTitle}>{I18n("privacy_policy")}</h1>
                        <ul className={classes.headerTitleUl}>
                            <li>
                                <Link style={{ color: "#fff" }} to='/'>{I18n("home")}</Link>
                            </li>
                            <li style={{ display: "inline-block", position: "relative", fontSize: "16px", paddingRight: "15px", marginLeft: "15px" }}>
                                <Link style={{ color: "#f68820" }} to="/terms-conditions">{I18n("terms_conditions")}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Grid item xs={12} sm={12} md={12} lg={12} style={{ backgroundColor: `${palette.palette.secondary.main}` }}>
                <PrivacyPolicy />
            </Grid>
            <div className={classes.copyRight}>
                <p className={classes.copyRightTxt}>© {year}</p>
            </div >

        </ div >
    );
}

export default PrivacyAndPolicy;
