import { I18n } from "common/components";
import { lazy } from "react";
import { SUB_PROJECTS_PATH as PATH } from "./paths";
import { RESOURCE_ID } from "modules/resources";

const SubProjectReportsWrapper = lazy(() => import("./reports/newSubProjectReports/components/SubProjectReportsWrapper"));
const CurrentStatusReportWrapper = lazy(() => import("./reports/gapAnalysisReports/components/CurrentStatusReportWrapper"));
const HarassmentComplaintForm = lazy(() => import("./labourManagement/components/HarassmentComplaintForm"));
const RecoveryRegisterForm = lazy(() => import("./labourManagement/components/RecoveryRegisterForm"));
const MaternityLeaveForm = lazy(() => import("./labourManagement/components/MaternityLeaveForm"));
const ProjectSocialSafeGuardWrapper = lazy(() => import("./swmSubProjects/components/ProjectSocialSafeGuardWrapper"));
const EnvironmentalSafeguardWrapper = lazy(() => import("./swmSubProjects/components/EnvironmentalSafeguardWrapper"));
const SwmSubProjectReportsWrapper = lazy(() => import("./reports/swmSubProjectReports/components/SwmSubProjectReportsWrapper"));
const IncidentDetailsWrapper = lazy(() => import("./labourManagement/components/IncidentDetailsWrapper"));
const IncidentDetailsForm = lazy(() => import("./labourManagement/components/IncidentDetailsForm"));
const EmployeeVerificationList = lazy(() => import("./labourManagement/components/EmployeeVerificationList"));
const EmployeeAttendanceRegister = lazy(() => import("./labourManagement/components/EmployeeAttendanceRegister"));
const WageRegisterEmployeeWageDetailsList = lazy(() => import("./labourManagement/components/WageRegisterEmployeeWageDetailsList"));
const WageRegisterList = lazy(() => import("./labourManagement/components/WageRegisterList"));
const LabourAddEmployeeList = lazy(() => import("./labourManagement/components/LabourAddEmployeeList"));
const LabourAddEmployee = lazy(() => import("./labourManagement/components/LabourAddEmployee"));
const EmployeeTerminationList = lazy(() => import("./labourManagement/components/EmployeeTerminationList"));
const EmployeeWageDetails = lazy(() => import("./labourManagement/components/EmployeeWageDetailsList"));
const CreatePlanActivity = lazy(() => import("./swmPlan/components/CreatePlanActivity"));
const CreatePlanActivityList = lazy(() => import("./swmPlan/components/CreatePlanActivityList"));
const SwmSubProjects = lazy(() => import("./swmSubProjects/components/SwmSubProjectList"));
const ViewSwmSubProject = lazy(() => import("./swmSubProjects/components/ViewSwmSubProject"));
const SubProjectDetails = lazy(() => import("./swmSubProjects/components/SubProjectDetails"));
const AnnualPlanList = lazy(() => import("./annualPlan/components/AnnualPlanList"));
const CreateAnnualPlan = lazy(() => import("./annualPlan/components/CreateAnnualPlan"));
const AnnualPlanTransferList = lazy(() => import("./annualPlan/components/AnnualPlanTransferList"));
const CreateProjectActivity = lazy(() => import("./swmSubProjects/components/CreateProjectActivity"));
const ProjectMonitoringList = lazy(() => import("./activityMonitoring/components/ProjectMonitoringList"));
const ViewProjectMonitoring = lazy(() => import("./activityMonitoring/components/ViewProjectMonitoring"));
const EditTechnicalForm = lazy(() => import("./activityMonitoring/components/technicalForm/EditTechnicalForm"));
const ProcurementList = lazy(() => import("./procurement/components/ProcurementList"));
const ProcurementActivitiesList = lazy(() => import("./procurement/components/ProcurementActivitiesList"));
const ProcurementActivitiesDetails = lazy(() => import("./procurement/components/ProcurementActivitiesDetails"));
const GapAnalysis = lazy(() => import("./gapAnalysis/components/GapAnalysis"));
const CreateGapAnalysis = lazy(() => import("./gapAnalysis/components/CreateGapAnalysis"));
const GapAnalysisWrapper = lazy(() => import("./gapAnalysis/components/GapAnalysisWrapper"));
const DiscussionAndApproval = lazy(() => import("./swmSubProjects/components/DiscussionAndApproval"));
const SocialSafeguardList = lazy(() => import("./socialSafeGuard/components/SocialSafeguardList"));
const SocialSafeguardForm = lazy(() => import("./socialSafeGuard/components/SocialSafeguardForm"));
const SocialSafeguardWrapper = lazy(() => import("./socialSafeGuard/components/SocialSafeguardWrapper"));
const DprOrFrList = lazy(() => import("./dprTracker/components/DprOrFrList"));
const CreateDprTracker = lazy(() => import("./dprTracker/components/CreateDprTracker"));
const DprWrapper = lazy(() => import("./dprTracker/components/DprWrapper"));
const AddBills = lazy(() => import("../grantManagement/fetchExpense/components/AddBills"));
const LabourProjectList = lazy(() => import("./labourManagement/components/LabourProjectList"));
const ProjectSitesList = lazy(() => import("./labourManagement/components/ProjectSitesList"));
const SiteBasicDetails = lazy(() => import("./labourManagement/components/SiteBasicDetails"));
const LabourSiteDetailsWrapper = lazy(() => import("./labourManagement/components/LabourSiteDetailsWrapper"));
const LandAcquisitionList = lazy(() => import("./landAcquisition/components/LandAcquisitionList"));
const LandAcquisitionForm = lazy(() => import("./landAcquisition/components/LandAcquisitionForm"));
const routes = [
    {
        title: I18n("current_status"),
        path: PATH.GAP_ANALYSIS.LIST,
        id: RESOURCE_ID.SUB_PROJECT.GAP_ANALYSIS.ROUTE,
        children: [
            {
                path: "",
                element: <GapAnalysis />,
                id: RESOURCE_ID.SUB_PROJECT.GAP_ANALYSIS.ACTIONS.LIST
            },
            {
                path: "create",
                element: <CreateGapAnalysis />
            },
            {
                path: ":id/edit",
                element: <CreateGapAnalysis />
            },
            {
                path: ":id/activity",
                element: <GapAnalysisWrapper />
            }
        ]
    },
    {
        title: I18n("plans"),
        children:
            [{
                title: I18n("swm_plan"),
                path: PATH.SWM_PLAN.LIST,
                id: RESOURCE_ID.SUB_PROJECT.PLANS.SWM_PLAN.ROUTE,
                children: [
                    {
                        path: "",
                        element: <CreatePlanActivityList />,
                        id: RESOURCE_ID.SUB_PROJECT.PLANS.SWM_PLAN.ACTIONS.LIST
                    },
                    {
                        path: "create",
                        element: <CreatePlanActivity />
                    },
                    {
                        path: ":id/edit",
                        element: <CreatePlanActivity />
                    },
                    {
                        path: ":id/activities",
                        element: <CreatePlanActivity />
                    }
                ]
            },
            {
                title: I18n("annual_plan"),
                path: PATH.ANNUAL_PLAN.LIST,
                id: RESOURCE_ID.SUB_PROJECT.PLANS.ANNUAL_PLAN.ROUTE,
                children: [
                    {
                        path: "",
                        element: <AnnualPlanList />,
                        id: RESOURCE_ID.SUB_PROJECT.PLANS.ANNUAL_PLAN.ACTIONS.LIST
                    },
                    {
                        path: "create",
                        element: <CreateAnnualPlan />
                    },
                    {
                        path: ":id/edit",
                        element: <CreateAnnualPlan />
                    },
                    {
                        path: ":id/projects-list",
                        element: <AnnualPlanTransferList />
                    }
                ]
            }
            ]
    },
    {
        title: I18n("preparation"),
        path: "",
        children: [
            {
                title: I18n("annual_development_plan"),
                path: PATH.SWM_DISCUSSION_AND_APPROVAL.LIST,
                children: [
                    {
                        path: "",
                        element: <DiscussionAndApproval />,
                        id: RESOURCE_ID.SUB_PROJECT.PREPARATION.DISCUSSION_AND_APPROVAL.LIST
                    }
                ]
            },
            {
                title: I18n("swm_sub_projects"),
                path: PATH.SWM_SUB_PROJECTS.LIST,
                children: [
                    {
                        path: "",
                        element: <SwmSubProjects />,
                        id: RESOURCE_ID.SUB_PROJECT.PREPARATION.SWM_SUB_PROJECTS.LIST
                    },
                    {
                        path: "create",
                        element: <SubProjectDetails />
                    },
                    {
                        path: ":type/create",
                        element: <SubProjectDetails />
                    },
                    {
                        path: ":id/edit",
                        element: <ViewSwmSubProject />
                    },
                    {
                        path: ":id/view",
                        element: <ViewSwmSubProject />
                    },
                    {
                        path: ":id/edit/milestone",
                        element: <CreateProjectActivity />
                    },
                    {
                        path: ":id/edit/milestone-edit",
                        element: <CreateProjectActivity />
                    },
                    {
                        path: ":id/edit/milestone-view",
                        element: <CreateProjectActivity />
                    },
                    {
                        path: ":id/view/milestone-view",
                        element: <CreateProjectActivity />
                    },
                    {
                        path: ":id/edit/:socialId/social-safeguard",
                        element: <ProjectSocialSafeGuardWrapper />
                    },
                    {
                        path: ":id/view/:socialId/social-safeguard",
                        element: <ProjectSocialSafeGuardWrapper />
                    },
                    {
                        path: ":id/edit/:envId/environmental-social-safeguard",
                        element: <EnvironmentalSafeguardWrapper />
                    },
                    {
                        path: ":id/view/:envId/environmental-social-safeguard",
                        element: <EnvironmentalSafeguardWrapper />
                    }
                ]
            },
            {
                title: I18n("dpr_tracker"),
                path: PATH.DPR_TRACKER.LIST,
                id: RESOURCE_ID.SUB_PROJECT.PREPARATION.DPR_TRACKER.ROUTE,
                children: [
                    {
                        path: "",
                        element: <DprOrFrList />,
                        id: RESOURCE_ID.SUB_PROJECT.PREPARATION.DPR_TRACKER.ACTIONS.LIST
                    },
                    {
                        path: "create",
                        element: <CreateDprTracker />
                    },
                    {
                        path: ":id/edit",
                        element: <CreateDprTracker />
                    },
                    {
                        path: ":id/activity",
                        element: <DprWrapper />
                    }
                ]
            }
        ]
    },
    {
        title: I18n("requirement"),
        path: "",
        children: []
    },
    {
        title: I18n("sub_project_procurement"),
        path: PATH.PROCUREMENT_LIST.LIST,
        children: [
            {
                path: "",
                element: <ProcurementList />,
                id: RESOURCE_ID.SUB_PROJECT.PROCUREMENT_LIST
            },
            {
                path: ":id/edit",
                element: <ProcurementActivitiesList />
            },
            {
                path: ":id/edit/:id/project",
                element: <ProcurementActivitiesDetails />
            },
            {
                path: ":id/edit/:id/create",
                element: <AddBills />
            },
            {
                path: ":id/edit/:id/edit",
                element: <AddBills />
            },
            {
                path: ":id/edit/:id/view",
                element: <AddBills />
            }

        ]
    },
    // {
    //     title: I18n("monitoring"),
    //     path: "",
    //     children: [
    {
        title: I18n("sub_project_monitoring"),
        path: PATH.ACTIVITY_MONITORING.LIST,
        id: RESOURCE_ID.SUB_PROJECT.SUB_PROJECT_MONITORING.DETAILS,
        children: [
            {
                path: "",
                element: <ProjectMonitoringList />,
                id: RESOURCE_ID.SUB_PROJECT.SUB_PROJECT_MONITORING.DETAILS
            },
            {
                path: ":id/edit",
                element: <ViewProjectMonitoring />,
                id: RESOURCE_ID.SUB_PROJECT.SUB_PROJECT_MONITORING.PROJECT_ACTIVITY.ACTIONS.EDIT
            },
            {
                path: ":id/view",
                element: <ViewProjectMonitoring />
            },
            {
                path: ":id/edit/technical",
                element: <EditTechnicalForm />
            },
            {
                path: ":id/view/technical",
                element: <EditTechnicalForm />
            }
            //     ]
            // }
        ]
    },
    {
        title: I18n("reports"),
        path: "",
        children: [
            {
                title: I18n("gap_analysis_reports"),
                path: PATH.SUB_PROJECTS_REPORT.LIST,
                id: RESOURCE_ID.SUB_PROJECT.SWM_DATA_REPORT.GAP_ANALYSIS_REPORT.ROUTE,
                children: [
                    {
                        path: "",
                        element: <CurrentStatusReportWrapper />
                    }
                ]
            },
            {
                title: I18n("swm_plan_reports"),
                path: PATH.SWM_PLAN_REPORT.LIST,
                id: RESOURCE_ID.SUB_PROJECT.SWM_DATA_REPORT.SWM_PLAN_REPORT.ROUTE,
                children: [
                    {
                        path: "",
                        element: <SwmSubProjectReportsWrapper />
                    }
                ]
            },
            {
                title: I18n("swm_sub_project_reports"),
                path: PATH.SUB_PLAN_REPORT.LIST,
                id: RESOURCE_ID.SUB_PROJECT.SWM_DATA_REPORT.SWM_SUB_PROJECTS_REPORT.ROUTE,
                children: [
                    {
                        path: "",
                        element: <SubProjectReportsWrapper />
                    }
                ]
            }
        ]
    },
    {
        title: I18n("labour_management"),
        path: PATH.LABOUR_MANAGEMENT.PROJECT_LIST,
        children: [
            {
                path: "",
                element: <LabourProjectList />,
                id: RESOURCE_ID.SUB_PROJECT.LABOUR_MANAGEMENT
            },
            {
                path: ":id/sites",
                element: <ProjectSitesList />
            },
            {
                path: ":id/sites/create",
                element: <SiteBasicDetails />
            },
            {
                path: ":id/sites/edit",
                element: <SiteBasicDetails />
            },
            {
                path: ":id/sites/:id/details",
                element: <LabourSiteDetailsWrapper />
            },
            {
                path: ":id/sites/:id/employee-list",
                element: <LabourAddEmployeeList />
            },
            {
                path: ":id/sites/:ids/employee-list/add-employee",
                element: <LabourAddEmployee />
            },
            {
                path: ":id/sites/:ids/employee-list/edit-employee",
                element: <LabourAddEmployee />
            },
            {
                path: ":id/sites/:ids/employee-list/wage-details",
                element: <EmployeeWageDetails />
            },
            {
                path: ":id/sites/:ids/employee-list/verification",
                element: <EmployeeVerificationList />
            },
            {
                path: ":id/sites/:ids/employee-list/termination-list",
                element: <EmployeeTerminationList />
            },
            {
                path: ":id/sites/:ids/wage-register-list",
                element: <WageRegisterList />
            },
            {
                path: ":id/sites/:ids/wage-register-list/:wageId/wage-register-edit",
                element: <WageRegisterEmployeeWageDetailsList />
            },
            {
                path: ":id/sites/:ids/incidents",
                element: <IncidentDetailsWrapper />
            },
            {
                path: ":id/sites/:ids/incidents/incident-details",
                element: <IncidentDetailsForm />
            },
            {
                path: ":id/sites/:ids/incidents/:workplaceId/incident-details-edit",
                element: <IncidentDetailsForm />
            },
            {
                path: ":id/sites/:ids/incidents/harassment-details",
                element: <HarassmentComplaintForm />
            },
            {
                path: ":id/sites/:ids/incidents/:harassmentId/harassment-details-edit",
                element: <HarassmentComplaintForm />
            },
            {
                path: ":id/sites/:attendanceId/attendance",
                element: <EmployeeAttendanceRegister />
            },
            {
                path: ":id/sites/:ids/incidents/recovery_register_form",
                element: <RecoveryRegisterForm />
            },
            {
                path: ":id/sites/:ids/incidents/maternity_leave_form",
                element: <MaternityLeaveForm />
            },
            {
                path: ":id/sites/:ids/incidents/:recoveryId/edit-recovery",
                element: <RecoveryRegisterForm />
            },
            {
                path: ":id/sites/:ids/incidents/:recoveryId/edit-maternity",
                element: <MaternityLeaveForm />
            },
            {
                path: ":id/sites/:ids/incidents/:recoveryId/view-recovery",
                element: <RecoveryRegisterForm />
            },
            {
                path: ":id/sites/:ids/incidents/:recoveryId/view-maternity",
                element: <MaternityLeaveForm />
            },
            {
                path: ":id/sites/:ids/incidents/:workplaceId/incident-details-view",
                element: <IncidentDetailsForm />
            },
            {
                path: ":id/sites/:ids/incidents/:harassmentId/harassment-details-view",
                element: <HarassmentComplaintForm />
            }
        ]
    },
    {
        title: I18n("social_safeguard"),
        path: PATH.SOCIAL_SAFE_GUARD.LIST,
        children: [
            {
                path: "",
                element: <SocialSafeguardList />,
                id: RESOURCE_ID.SUB_PROJECT.SOCIAL_SAFE_GUARD.LIST
            },
            {
                path: "create",
                element: <SocialSafeguardForm />
            },
            {
                path: ":id/activities",
                element: <SocialSafeguardWrapper />
            }
        ]

    },
    {
        title: I18n("land_acquisitions"),
        path: PATH.LAND_ACQUISITIONS.LIST,
        children: [
            {
                path: "",
                element: <LandAcquisitionList />,
                id: RESOURCE_ID.SUB_PROJECT.LAND_ACQUISITIONS
            },
            {
                path: ":id/create",
                element: <LandAcquisitionForm />
            }
        ]

    }
];

export { routes };
