import { Box, List } from "@mui/material";
import { routes } from "../../routes";
import { STATE_REDUCER_KEY } from "modules/user/constants";
import { USER_ROLE } from "modules/resources";
import { checkUserTypeMenuPermissions } from "utils/permissionUtils";
import { useSelector } from "react-redux";
import DrawerMenu from "./DrawerMenu";
import CloseIcon from "@mui/icons-material/Close";
import Profile from "../header/Profile";
import SwitchRoles from "../header/SwitchRole";

const SideBar = ({ showSidebar, setShowSidebar }) => {

    const depthLevel = 0;
    const { userDetails: { data: { activeProfile: { aciveRole = {} } = {} } } = {} } = useSelector(state => state[STATE_REDUCER_KEY]);
    const currentRole = useSelector(state => state.profileDetails.userDetails.data.activeRole.id);
    const menuItems = checkUserTypeMenuPermissions(routes, [currentRole || USER_ROLE.CUSTOMER || aciveRole.id], currentRole);
    const sidebarDisplay = showSidebar ? "flex" : "none";
    const sidebarPosition = showSidebar ? "absolute" : "static";
    return (

        <List sx={{ display: { xs: sidebarDisplay, md: "flex" }, flexDirection: "column", justifyContent: "space-between", fontSize: "14px", minWidth: "250px", py: 0, position: { xs: sidebarPosition, md: "static" }, left: 0, top: 0, bgcolor: { xs: "secondary.main", md: "secondary.main" }, zIndex: 200, height: { xs: "100vh", md: "calc(100vh - 82px)" } }}>
            <Box sx={{ display: { xs: "block", md: "none" }, p: 3, bgcolor: "secondary.light" }}>
                <CloseIcon sx={{ display: { xs: "block", md: "none" }, color: "white.main", position: "absolute", p: 1, right: "10px", top: "10px" }} onClick={() => {
                    setShowSidebar(!showSidebar);
                }} />
                <Profile />
                <SwitchRoles />
            </Box>
            <List sx={{ pl: 1, pt: 6, flexGrow: 1, overflowX: "hidden", overflowY: "auto" }} >
                {menuItems[0].children.
                    map((item, index) => {
                        return (
                            item.title ? (
                                <DrawerMenu item={item} key={item.value} path="" depth={depthLevel} index={index} setShowSidebar={setShowSidebar} />
                            ) : (<>
                            </>)
                        );
                    }
                    )}
            </List>
        </List >

    );
};

export default SideBar;
