import { I18n } from "common/components";
import { lazy } from "react";
import { OPERATIONAL_MODULE_PATH as PATH } from "./paths";
import { RESOURCE_ID } from "modules/resources";
import WardDetailsAdd from "./wardDetails/components/WardDetailsAdd";
import WardDetailsInfoList from "./wardDetails/components/WardDetailsInfoList";

const TransferStationList = lazy(() => import("./transferStation/components/TransferStationList"));
const TransferStationAdd = lazy(() => import("./transferStation/components/TransferStationAdd"));
const CityLevelDisposalCellInfoList = lazy(() => import("./cityLevelDisposalCellInformation/components/CityLevelDisposalCellInfoList"));
const CityLevelDisposalCellInfoAdd = lazy(() => import("./cityLevelDisposalCellInformation/components/CityLevelDisposalCellInfoAdd"));
const WasteProcessingPlantDetailsList = lazy(() => import("./wasteProcessingPlantDetails/components/WasteProcessingPlantDetailsList"));
const WasteProcessingPlantDetailsAdd = lazy(() => import("./wasteProcessingPlantDetails/components/WasteProcessingPlantDetailsAdd"));
const WasteDisposalRegionalAdd = lazy(() => import("./wasteDisposalAtRegionalSite/components/WasteDisposalRegionalAdd"));
const WasteDisposalRegionalList = lazy(() => import("./wasteDisposalAtRegionalSite/components/WasteDisposalRegionalList"));
const SecondaryTransportationList = lazy(() => import("./secondaryTransportation/components/SecondaryTransportationList"));
const SecondaryTransportationAdd = lazy(() => import("./secondaryTransportation/components/SecondaryTransportationAdd"));
const McfRrfDetailsList = lazy(() => import("./mcfRrfDetailsInformation/components/McfRrfDetailsList"));
const McfRrfDetailsAdd = lazy(() => import("./mcfRrfDetailsInformation/components/McfRrfDetailsAdd"));
const PrimaryCollectionBDWAdd = lazy(() => import("./primaryCollectionBDW/components/PrimaryCollectionBDWAdd"));
const TreatmentAtSourceList = lazy(() => import("./treatmentAtSourceForBDW/components/TreatmentAtSourceList"));
const TreatmentAtSourceAdd = lazy(() => import("./treatmentAtSourceForBDW/components/TreatmentAtSourceAdd"));
const PrimaryCollectionBDWList = lazy(() => import("./primaryCollectionBDW/components/PrimaryCollectionBDWList"));
const WssInfoList = lazy(() => import("./wssInfo/components/WssInfoList"));
const WssInfoAdd = lazy(() => import("./wssInfo/components/WssInfoAdd"));
const DisposalSiteCityLevelList = lazy(() => import("./disposalSiteCityLevel/components/DisposalSiteCityLevelList"));
const DisposalSiteCityLevelAdd = lazy(() => import("./disposalSiteCityLevel/components/DisposalSiteCityLevelAdd"));
const RegionalDisposalSiteCityLevelAdd = lazy(() => import("./regionalDisposalSiteCityLevel/components/RegionalDisposalSiteCityLevelAdd"));
const RegionalDisposalSiteCityLevelList = lazy(() => import("./regionalDisposalSiteCityLevel/components/RegionalDisposalSiteCityLevelList"));
const PrimaryCollectionAdd = lazy(() => import("./primaryCollection/components/PrimaryCollectionAdd"));
const WasteProcessingSiteAdd = lazy(() => import("./Waste Processing/components/WasteProcessingSiteAdd"));
const WasteProcessingSitesList = lazy(() => import("./Waste Processing/components/WasteProcessingSitesList"));
const McfRrfList = lazy(() => import("./McfRrf/components/McfRrfList"));
const McfRrfAdd = lazy(() => import("./McfRrf/components/McfRrfAdd"));
const SecondaryCollectionAgencyList = lazy(() => import("./secondaryCollectionAgency/components/SecondaryCollectionAgencyList"));
const SecondaryCollectionAdd = lazy(() => import("./secondaryCollectionAgency/components/SecondaryCollectionAdd"));
const PrimaryCollectionList = lazy(() => import("./primaryCollection/components/PrimaryCollectionList"));


const routes = [
    {
        title: I18n("master_info"),
        path: PATH.MASTER_INFO,
        id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.ROUTE,
        children: [
            {
                title: I18n("ward_details"),
                path: PATH.WARD_DETAILS.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.WARD_DETAILS.ROUTE,
                children: [
                    {
                        path: "",
                        element: <WardDetailsInfoList />,
                        id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.WARD_DETAILS.ACTIONS.LIST
                    },
                    {
                        path: PATH.WARD_DETAILS.CREATE,
                        element: <WardDetailsAdd />
                    },
                    {
                        path: PATH.WARD_DETAILS.EDIT,
                        element: <WardDetailsAdd />
                    },
                    {
                        path: PATH.WARD_DETAILS.VIEW,
                        element: <WardDetailsAdd />
                    }
                ]
            },
            {
                title: I18n("primary_collection_agency"),
                path: PATH.PRIMARY_COLLECTION.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.PRIMARY_COLLECTION.ROUTE,
                children: [
                    {
                        path: "",
                        element: <PrimaryCollectionList />,
                        id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.PRIMARY_COLLECTION.ACTIONS.LIST
                    },
                    {
                        path: PATH.PRIMARY_COLLECTION.CREATE,
                        element: <PrimaryCollectionAdd />
                    },
                    {
                        path: PATH.PRIMARY_COLLECTION.EDIT,
                        element: <PrimaryCollectionAdd />
                    },
                    {
                        path: PATH.PRIMARY_COLLECTION.VIEW,
                        element: <PrimaryCollectionAdd />
                    }
                ]
            },
            {
                title: I18n("secondary_collection_agency"),
                path: PATH.SECONDARY_COLLECTION.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.SECONDARY_COLLECTION.ROUTE,
                children: [
                    {
                        path: "",
                        element: <SecondaryCollectionAgencyList />,
                        id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.SECONDARY_COLLECTION.ACTIONS.LIST
                    },
                    {
                        path: PATH.SECONDARY_COLLECTION.CREATE,
                        element: <SecondaryCollectionAdd />
                    },
                    {
                        path: ":id/edit",
                        element: <SecondaryCollectionAdd />
                    },
                    {
                        path: ":id/view",
                        element: <SecondaryCollectionAdd />
                    }
                ]
            },
            {
                title: I18n("secondary_transportation"),
                path: PATH.SECONDARY_TRANSPORTATION.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.SECONDARY_TRANSPORTATION.ROUTE,
                children: [
                    {
                        path: "",
                        element: <SecondaryTransportationList />,
                        id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.SECONDARY_TRANSPORTATION.ACTIONS.LIST
                    },
                    {
                        path: PATH.SECONDARY_TRANSPORTATION.CREATE,
                        element: <SecondaryTransportationAdd />
                    },
                    {
                        path: PATH.SECONDARY_TRANSPORTATION.EDIT,
                        element: <SecondaryTransportationAdd />
                    },
                    {
                        path: PATH.SECONDARY_TRANSPORTATION.VIEW,
                        element: <SecondaryTransportationAdd />
                    }
                ]
            },
            {
                title: I18n("mcf_rrf"),
                path: PATH.MCF_RRF.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.MCF_RRF.ROUTE,
                children: [
                    {
                        path: "",
                        element: <McfRrfList />,
                        id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.MCF_RRF.ACTIONS.LIST
                    },
                    {
                        path: PATH.MCF_RRF.CREATE,
                        element: <McfRrfAdd />
                    },
                    {
                        path: PATH.MCF_RRF.EDIT,
                        element: <McfRrfAdd />
                    },
                    {
                        path: PATH.MCF_RRF.VIEW,
                        element: <McfRrfAdd />
                    }
                ]
            },
            {
                title: I18n("waste_processing"),
                path: PATH.WASTE_PROCESSING.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.WASTE_PROCESSING.ROUTE,
                children: [
                    {
                        path: "",
                        element: <WasteProcessingSitesList />,
                        id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.WASTE_PROCESSING.ACTIONS.LIST
                    },
                    {
                        path: PATH.SECONDARY_COLLECTION.CREATE,
                        element: <WasteProcessingSiteAdd />
                    },
                    {
                        path: PATH.SECONDARY_COLLECTION.EDIT,
                        element: <WasteProcessingSiteAdd />
                    },
                    {
                        path: PATH.SECONDARY_COLLECTION.VIEW,
                        element: <WasteProcessingSiteAdd />
                    }
                ]
            },
            {
                title: I18n("city_level_disposal_site"),
                path: PATH.DISPOSAL_CITY_LEVEL.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.DISPOSAL_SITE_CITY_LEVEL.ROUTE,
                children: [
                    {
                        path: "",
                        element: <DisposalSiteCityLevelList />,
                        id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.DISPOSAL_SITE_CITY_LEVEL.ACTIONS.LIST
                    },
                    {
                        path: PATH.DISPOSAL_CITY_LEVEL.CREATE,
                        element: <DisposalSiteCityLevelAdd />
                    },
                    {
                        path: PATH.DISPOSAL_CITY_LEVEL.EDIT,
                        element: <DisposalSiteCityLevelAdd />
                    },
                    {
                        path: PATH.DISPOSAL_CITY_LEVEL.VIEW,
                        element: <DisposalSiteCityLevelAdd />
                    }
                ]
            },
            {
                title: I18n("regional_disposal_site"),
                path: PATH.REGIONAL_DISPOSAL_SITE.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.REGIONAL_DISPOSAL_SITE_CITY_LEVEL.ROUTE,
                children: [
                    {
                        path: "",
                        element: <RegionalDisposalSiteCityLevelList />,
                        id: RESOURCE_ID.OPERATION_MODULE.MASTER_INFO.REGIONAL_DISPOSAL_SITE_CITY_LEVEL.ACTIONS.LIST
                    },
                    {
                        path: PATH.REGIONAL_DISPOSAL_SITE.CREATE,
                        element: <RegionalDisposalSiteCityLevelAdd />
                    },
                    {
                        path: PATH.REGIONAL_DISPOSAL_SITE.EDIT,
                        element: <RegionalDisposalSiteCityLevelAdd />
                    },
                    {
                        path: PATH.REGIONAL_DISPOSAL_SITE.VIEW,
                        element: <RegionalDisposalSiteCityLevelAdd />
                    }
                ]
            }
        ]
    },
    {
        title: I18n("operational_data"),
        path: PATH.OPERATIONAL_DATA,
        id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.WSS_INFO.ROUTE,
        children: [
            {
                title: I18n("wss_info"),
                path: PATH.WSS_INFO.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.WSS_INFO.ROUTE,
                children: [
                    {
                        path: "",
                        element: <WssInfoList />,
                        id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.WSS_INFO.ACTIONS.LIST
                    },
                    {
                        path: PATH.WSS_INFO.CREATE,
                        element: <WssInfoAdd />
                    },
                    {
                        path: PATH.WSS_INFO.EDIT,
                        element: <WssInfoAdd />
                    },
                    {
                        path: PATH.WSS_INFO.VIEW,
                        element: <WssInfoAdd />
                    }
                ]
            },
            {
                title: I18n("treatment_at_source_for_bdw"),
                path: PATH.TREATMENT_AT_SOURCE_FOR_BDW.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.TREATMENT_AT_SOURCE_FOR_BDW.ROUTE,
                children: [
                    {
                        path: "",
                        element: <TreatmentAtSourceList />,
                        id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.TREATMENT_AT_SOURCE_FOR_BDW.ACTIONS.LIST
                    },
                    {
                        path: PATH.TREATMENT_AT_SOURCE_FOR_BDW.CREATE,
                        element: <TreatmentAtSourceAdd />
                    },
                    {
                        path: PATH.TREATMENT_AT_SOURCE_FOR_BDW.EDIT,
                        element: <TreatmentAtSourceAdd />
                    },
                    {
                        path: PATH.TREATMENT_AT_SOURCE_FOR_BDW.VIEW,
                        element: <TreatmentAtSourceAdd />
                    }
                ]
            },
            {
                title: I18n("primary_collection_bdw"),
                path: PATH.PRIMARY_COLLECTION_BDW.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.PRIMARY_COLLECTION_BDW.ROUTE,
                children: [
                    {
                        path: "",
                        element: <PrimaryCollectionBDWList />,
                        id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.PRIMARY_COLLECTION_BDW.ACTIONS.LIST
                    },
                    {
                        path: PATH.PRIMARY_COLLECTION_BDW.CREATE,
                        element: <PrimaryCollectionBDWAdd />
                    },
                    {
                        path: PATH.PRIMARY_COLLECTION_BDW.EDIT,
                        element: <PrimaryCollectionBDWAdd />
                    },
                    {
                        path: PATH.PRIMARY_COLLECTION_BDW.VIEW,
                        element: <PrimaryCollectionBDWAdd />
                    }
                ]
            },
            {
                title: I18n("mcf_rrf_details_information"),
                path: PATH.MCF_RRF_DETAILS.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.MCF_RRF_DETAILS_INFORMATION.ROUTE,
                children: [
                    {
                        path: "",
                        element: <McfRrfDetailsList />,
                        id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.MCF_RRF_DETAILS_INFORMATION.ACTIONS.LIST
                    },
                    {
                        path: PATH.MCF_RRF_DETAILS.CREATE,
                        element: <McfRrfDetailsAdd />
                    },
                    {
                        path: PATH.MCF_RRF_DETAILS.EDIT,
                        element: <McfRrfDetailsAdd />
                    },
                    {
                        path: PATH.MCF_RRF_DETAILS.VIEW,
                        element: <McfRrfDetailsAdd />
                    }
                ]
            },
            {
                title: I18n("transfer_station"),
                path: PATH.TRANSFER_STATION.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.TRANSFER_STATION.ROUTE,
                children: [
                    {
                        path: "",
                        element: <TransferStationList />,
                        id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.TRANSFER_STATION.ACTIONS.LIST
                    },
                    {
                        path: PATH.TRANSFER_STATION.CREATE,
                        element: <TransferStationAdd />
                    },
                    {
                        path: PATH.TRANSFER_STATION.EDIT,
                        element: <TransferStationAdd />
                    },
                    {
                        path: PATH.TRANSFER_STATION.VIEW,
                        element: <TransferStationAdd />
                    }
                ]
            },
            {
                title: I18n("waste_processing_plant_details"),
                path: PATH.WASTE_PROCESSING_PLANT_DETAILS.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.WASTE_PROCESSING_PLANT_DETAILS.ROUTE,
                children: [
                    {
                        path: "",
                        element: <WasteProcessingPlantDetailsList />,
                        id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.WASTE_PROCESSING_PLANT_DETAILS.ACTIONS.LIST
                    },
                    {
                        path: PATH.WASTE_PROCESSING_PLANT_DETAILS.CREATE,
                        element: <WasteProcessingPlantDetailsAdd />
                    },
                    {
                        path: PATH.WASTE_PROCESSING_PLANT_DETAILS.EDIT,
                        element: <WasteProcessingPlantDetailsAdd />
                    },
                    {
                        path: PATH.WASTE_PROCESSING_PLANT_DETAILS.VIEW,
                        element: <WasteProcessingPlantDetailsAdd />
                    }
                ]
            },
            {
                title: I18n("city_level_disposal_cell_information"),
                path: PATH.CITY_LEVEL_DISPOSAL_CELL_INFO.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.CITY_CELL_WASTE_DISPOSAL.ROUTE,
                children: [
                    {
                        path: "",
                        element: <CityLevelDisposalCellInfoList />,
                        id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.CITY_CELL_WASTE_DISPOSAL.ACTIONS.LIST
                    },
                    {
                        path: PATH.CITY_LEVEL_DISPOSAL_CELL_INFO.CREATE,
                        element: <CityLevelDisposalCellInfoAdd />
                    },
                    {
                        path: PATH.CITY_LEVEL_DISPOSAL_CELL_INFO.EDIT,
                        element: <CityLevelDisposalCellInfoAdd />
                    },
                    {
                        path: PATH.CITY_LEVEL_DISPOSAL_CELL_INFO.VIEW,
                        element: <CityLevelDisposalCellInfoAdd />
                    }
                ]
            },
            {
                title: I18n("waste_disposal_regional_site"),
                path: PATH.WASTE_DISPOSAL_AT_REGIONAL_SITE.LIST,
                id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.WASTE_DISPOSAL_AT_REGIONAL_SITE.ROUTE,
                children: [
                    {
                        path: "",
                        element: <WasteDisposalRegionalList />,
                        id: RESOURCE_ID.OPERATION_MODULE.OPERATIONAL_DATA.WASTE_DISPOSAL_AT_REGIONAL_SITE.ACTIONS.LIST
                    },
                    {
                        path: PATH.WASTE_DISPOSAL_AT_REGIONAL_SITE.CREATE,
                        element: <WasteDisposalRegionalAdd />
                    },
                    {
                        path: PATH.WASTE_DISPOSAL_AT_REGIONAL_SITE.EDIT,
                        element: <WasteDisposalRegionalAdd />
                    },
                    {
                        path: PATH.WASTE_DISPOSAL_AT_REGIONAL_SITE.VIEW,
                        element: <WasteDisposalRegionalAdd />
                    }
                ]
            }
        ]
    }
];

export { routes };

