import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchDisposalSiteCityDetailsApi = (data) => {
    return {
        url: API_URL.DISPOSAL_SITE_CITY.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_REQUEST, ACTION_TYPES.FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS, ACTION_TYPES.FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE], data
        }
    };
};

export const disposalSiteCityApi = (data) => {
    return {
        url: API_URL.DISPOSAL_SITE_CITY.GET_BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID_REQUEST, ACTION_TYPES.GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID_SUCCESS, ACTION_TYPES.GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID_FAILURE]
        }
    };
};

export const postDisposalSiteCityDetailsApi = (data) => {
    return {
        url: API_URL.DISPOSAL_SITE_CITY.POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_REQUEST, ACTION_TYPES.POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS, ACTION_TYPES.POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE], data
        }
    };
};

export const patchDisposalSiteCityDetailsApi = (data) => {
    return {
        url: API_URL.DISPOSAL_SITE_CITY.POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_REQUEST, ACTION_TYPES.PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS, ACTION_TYPES.PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE], data
        }
    };
};

export const deleteDisposalSiteCityDetailsApi = (data) => {
    return {
        url: API_URL.DISPOSAL_SITE_CITY.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_REQUEST, ACTION_TYPES.DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_SUCCESS, ACTION_TYPES.DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_FAILURE]
        }
    };
};

export const natureOfAreaApi = (data) => {
    return {
        url: API_URL.DISPOSAL_SITE_CITY.NATURE_OF_AREA,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_NATURE_OF_AREA_DROPDOWN_REQUEST, ACTION_TYPES.GET_NATURE_OF_AREA_DROPDOWN_SUCCESS, ACTION_TYPES.GET_NATURE_OF_AREA_DROPDOWN_FAILURE], data
        }
    };
};
