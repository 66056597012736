import { ACTION_TYPES } from "./actions";
import { fetchDesignationApi, fetchKnowledgeBaseApi, fetchTopicApi, saveTrainingNeedAPI, fetchTargetGroupApi, editNeedFormAPI, deleteNeedFormByIdAPI } from "./api";
import { handleAPIRequest } from "../../../utils/http";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { actions as commonActions } from "modules/common/slice";
import { I18n } from "common/components";
import { successNotify } from "utils/notificationUtils";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "../../paths";
import { TRAINING_PATH as PATH } from "../paths";
import * as api from "./api";
import { setCommonTableData, getPaginationPayload } from "utils/tableUtils";
import { TABLE_IDS } from "common/constants";
import _ from "lodash";

export function* saveTrainingNeed({ payload }) {
    let { id } = payload;
    const newFiles = payload.attachmentPayload?.multimediaList.filter(item => !(item.id));
    if (newFiles && newFiles.length > 0) {
        let attachments = {};
        attachments.multimediaList = newFiles;
        payload.attachmentPayload = attachments;
    } else {
        if (newFiles && newFiles.length === 0) {
            payload = _.omit(payload, ["attachmentPayload"]);
            payload = _.omit(payload, ["attachmentIds"]);
        }
    }
    if (id) {
        yield fork(handleAPIRequest, editNeedFormAPI, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_NEED_FORM_SUCCESS, ACTION_TYPES.EDIT_NEED_FORM_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_NEED_FORM_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("training_need") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.NEED.LIST}`));
        }
    } else {

        yield fork(handleAPIRequest, saveTrainingNeedAPI, payload);
        const responseAction = yield take([ACTION_TYPES.SAVE_TRAINING_SUCCESS, ACTION_TYPES.SAVE_TRAINING_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SAVE_TRAINING_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("training_need") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.NEED.LIST}`));
        }
    }
}
export function* listTrainingNeed({ payload }) {

    yield fork(handleAPIRequest, api.listTrainingNeedApi, payload);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_TRAINING_SUCCESS, ACTION_TYPES.LIST_TRAINING_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_NEED);
}

export function* fetchTargetGroup(action) {
    yield call(handleAPIRequest, fetchTargetGroupApi, action.payload);
}
export function* fetchTopic(action) {
    yield call(handleAPIRequest, fetchTopicApi, action.payload);
}
export function* fetchKnowledgeBase(action) {
    yield call(handleAPIRequest, fetchKnowledgeBaseApi, action.payload);
}

export function* fetchDesignation({ payload: targetGroupId }) {
    yield call(handleAPIRequest, fetchDesignationApi, { targetGroupId });
}

export function* deleteNeedFormById({ payload: id }) {
    yield fork(handleAPIRequest, deleteNeedFormByIdAPI, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_NEED_FORM_BY_ID_SUCCESS, ACTION_TYPES.DELETE_NEED_FORM_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_NEED_FORM_BY_ID_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("need_table_row") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listTrainingNeed, { payload: tablePayload });
    }
}

export function* editNeedFormById({ payload }) {
    yield call(handleAPIRequest, api.editNeedCategoryTypeAPI, payload);
}
export function* fetchFilesById({ payload }) {
    yield call(handleAPIRequest, api.getFileById, payload);
}
export function* deleteFile({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, api.deleteFileById, id, fileId);
}

export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SAVE_TRAINING_NEED, saveTrainingNeed),
        takeLatest(ACTION_TYPES.LIST_TRAINING_NEED, listTrainingNeed),
        takeLatest(ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY, fetchTargetGroup),
        takeLatest(ACTION_TYPES.FETCH_TRAINING_TOPIC, fetchTopic),
        takeLatest(ACTION_TYPES.FETCH_KNOWLEDGE_BASE, fetchKnowledgeBase),
        takeLatest(ACTION_TYPES.DELETE_NEED_FORM_BY_ID, deleteNeedFormById),
        takeLatest(ACTION_TYPES.EDIT_NEED_FORM_CATEGORY, editNeedFormById),
        takeLatest(ACTION_TYPES.FETCH_DESIGNATION, fetchDesignation),
        takeLatest(ACTION_TYPES.FETCH_FILE, fetchFilesById),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFile)

    ]);
}


