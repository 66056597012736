import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadContent } from "utils/apiUtils";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY, DEFAULT_TABLE_ID, COMMON_TABLE_INITIAL_STATE } from "./constants";

const initialState = {
    navigator: {},
    table: {},
    stateDropdown: {
        requestInProgress: false,
        data: []
    },
    districtDropdown: {
        requestInProgress: false,
        data: []
    },
    LSGITypeDropdown: {
        requestInProgress: false,
        data: []
    },
    LSGIDropdown: {
        requestInProgress: false,
        data: {}
    },
    wardDropdown: {
        requestInProgress: false,
        data: {}
    },
    districtID: "",
    lsgiID: "",
    otpVerify: false,
    searchFilter: {},
    otpDetails: {
        req: {
            targetNo: null,
            otpType: null,
            otpProcess: null
        },
        otp: null,
        isVerified: false
    },
    statusModal: {
        isOpen: false,
        title: "",
        content: null,
        showLoader: false
    },
    currentTab: 0,
    otpPopUp: false,
    otpRequestInProgress: false,
    signUpOtp: false,
    signUpOtpRequestInProgress: false
};

const resetTable = (state, action) => {
    const { key = DEFAULT_TABLE_ID, ...rest } = action.payload;
    _.set(state, `table.${key}`, { ...COMMON_TABLE_INITIAL_STATE, ...rest });
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: (state) => {
            state.table = initialState.table;
            state.stateDropdown = initialState.stateDropdown;
            state.districtDropdown = initialState.districtDropdown;
            state.LSGITypeDropdown = initialState.LSGITypeDropdown;
            state.LSGIDropdown = initialState.LSGIDropdown;
            state.wardDropdown = initialState.wardDropdown;
            state.districtID = initialState.districtID;
            state.lsgiID = initialState.lsgiID;
            state.otpVerify = initialState.otpVerify;
            state.searchFilter = initialState.searchFilter;
            state.otpDetails = initialState.otpDetails;
            state.statusModal = initialState.statusModal;
        },
        clearTable: (state, action) => resetTable(state, action),
        initializeTable: (state, action) => {
            const { key = DEFAULT_TABLE_ID, ...rest } = action.payload || {};
            _.set(state, `table.${key}`, { ...COMMON_TABLE_INITIAL_STATE, ...rest, additionalFilters: _.get(state, `table[${key}].additionalFilters`, {}) });
        },
        setPagination: (state, action) => {
            const { key = DEFAULT_TABLE_ID, pagination = {}, reset = false } = action.payload;
            _.set(state, `table.${key}.pagination`, reset ? { ...COMMON_TABLE_INITIAL_STATE.pagination, ...pagination } : { ...COMMON_TABLE_INITIAL_STATE.pagination, ..._.get(state, `table[${key}].pagination`, {}), ...pagination });
        },
        setTableData: (state, action) => {
            const { key = DEFAULT_TABLE_ID, requestInProgress = false, data = [] } = action.payload;
            _.set(state, `table.${key}.data`, data);
            _.set(state, `table.${key}.requestInProgress`, requestInProgress);
        },
        setFilters: (state, action) => {
            const { key = DEFAULT_TABLE_ID, filters = {}, reset = false, clearKey = false, filterKey = "" } = action.payload;
            if (clearKey) {
                _.unset(state, `table.${key}.filters.${filterKey}`);
            } else {
                _.set(state, `table.${key}.filters`, reset ? filters : { ...state.table[key].filters, ...filters });
            }

        },
        setLoading: (state, action) => {
            const { key = DEFAULT_TABLE_ID, requestInProgress = true } = action.payload;
            _.set(state, `table.${key}.requestInProgress`, requestInProgress);
        },
        setAdditionalFilters: (state, action) => {
            const { key = DEFAULT_TABLE_ID, filters = {} } = action.payload;
            _.set(state, `table.${key}.additionalFilters`, filters);
        },
        setRowSelection: (state, action) => {
            const { key = DEFAULT_TABLE_ID, items = {} } = action.payload;
            _.set(state, `table.${key}.rowSelected`, items);
        },
        setNavigator: (state, action) => {
            state.navigator = action.payload;
        },
        setDistrictID: (state, action) => {
            _.set(state, "districtID", action.payload);
        },
        setLsgiTypeID: (state, action) => {
            _.set(state, "lsgiTypeID", action.payload);
        },
        setLsgiID: (state, action) => {
            _.set(state, "lsgiID", action.payload);
        },
        setSearchFilter: (state, action) => {
            state.searchFilter = action.payload;
        },
        setOtpDetails: (state, action) => {
            let { reset, ...rest } = action.payload;
            state.otpDetails = { ...(reset ? initialState.otpDetails : state.otpDetails), ...rest };
        },
        resetOtpDetails: (state) => {
            state.otpDetails = initialState.otpDetails;
        },
        setStatusModal: (state, action) => {
            state.statusModal = { ...state.statusModal, showLoader: true, ...action.payload };
        },
        resetStatusModal: (state) => {
            state.statusModal = initialState.statusModal;
        },
        clearTab: (state) => {
            state.currentTab = 0;
        },
        moveTabGapAnalysis: (state) => {
            state.currentTab = 1;
        },
        moveTabPrePlan: (state) => {
            state.currentTab = 2;
        },
        moveTabSubProject: (state) => {
            state.currentTab = 3;
        },
        moveTabPostPlan: (state) => {
            state.currentTab = 6;
        },
        clearTabProjectMapping: (state) => {
            state.currentTab = 7;
        },
        moveTabMc: (state) => {
            state.currentTab = 11;
        },
        moveTabComments: (state) => {
            state.currentTab = 12;
        },
        moveTabUp: (state) => {
            state.currentTab += 1;
        },
        closeOtpModal: (state) => {
            state.otpPopUp = initialState.otpPopUp;
        },
        closeSignUpModal: (state) => {
            state.signUpOtp = initialState.signUpOtp;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_STATE_DROPDOWN_SUCCESS, (state, action) => {
                state.stateDropdown.requestInProgress = false;
                state.stateDropdown.data = getPayloadContent(action);
            })
            .addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, (state, action) => {
                state.districtDropdown.requestInProgress = false;
                state.districtDropdown.data = getPayloadContent(action);
            })
            .addCase(ACTION_TYPES.FETCH_LSGI_TYPE_DROPDOWN_SUCCESS, (state, action) => {
                state.LSGITypeDropdown.requestInProgress = false;
                state.LSGITypeDropdown.data = getPayloadContent(action);
            })
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, (state, action) => {
                state.LSGIDropdown.requestInProgress = false;
                _.set(state, `LSGIDropdown.data.${state.lsgiTypeID}`, getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.FETCH_WARD_DROPDOWN_SUCCESS, (state, action) => {
                state.wardDropdown.requestInProgress = false;
                _.set(state, `wardDropdown.data.${state.lsgiID}`, getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SET_TAB_STATUS, (state, { payload }) => {
                _.set(state, "currentTab", payload);
            })
            .addCase(ACTION_TYPES.GET_OTP_DETAILS_REQUEST, (state) => {
                _.set(state, "otpRequestInProgress", true);
            })
            .addCase(ACTION_TYPES.GET_OTP_DETAILS_SUCCESS, (state) => {
                _.set(state, "otpPopUp", true);
                _.set(state, "otpRequestInProgress", false);
            })
            .addCase(ACTION_TYPES.GET_OTP_DETAILS_FAILURE, (state) => {
                _.set(state, "otpPopUp", false);
                _.set(state, "otpRequestInProgress", false);
            })
            .addCase(ACTION_TYPES.GET_SIGNUP_OTP_DETAILS_REQUEST, (state) => {
                _.set(state, "signUpOtpRequestInProgress", true);
            })
            .addCase(ACTION_TYPES.GET_SIGNUP_OTP_DETAILS_SUCCESS, (state) => {
                _.set(state, "signUpOtp", true);
                _.set(state, "signUpOtpRequestInProgress", false);
            })
            .addCase(ACTION_TYPES.GET_SIGNUP_OTP_DETAILS_FAILURE, (state) => {
                _.set(state, "signUpOtp", false);
                _.set(state, "signUpOtpRequestInProgress", false);
            });
    }
});

export const { actions, reducer } = slice;
