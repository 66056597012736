import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const getTrainingCertificate = state => state[STATE_REDUCER_KEY];

const certificateForm = (certificate) => certificate.certificateForm;
export const getCertificateForm = flow(getTrainingCertificate, certificateForm);

const tableProps = (certificate) => certificate.tablePagination;
export const getTableProps = flow(getTrainingCertificate, tableProps);

const selectedRows = (certificate) => certificate.table.rowSelection;
export const getSelectedRows = flow(getTrainingCertificate, selectedRows);

const template = (certificate) => certificate.template;
export const getTemplate = flow(getTrainingCertificate, template);

const batch = (certificate) => certificate.batch;
export const getBatch = flow(getTrainingCertificate, batch);

const venueId = (certificate) => certificate.venueId;
export const getVenueId = flow(getTrainingCertificate, venueId);

const lsgi = (certificate) => certificate.lsgi;
export const getLsgiSelectDropdown = flow(getTrainingCertificate, lsgi);

