import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchSourceForBdwListApi = (data) => {
    return {
        url: API_URL.SOURCE_FOR_BDW.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SOURCE_FOR_BDW_DETAILS_REQUEST, ACTION_TYPES.FETCH_SOURCE_FOR_BDW_DETAILS_SUCCESS, ACTION_TYPES.FETCH_SOURCE_FOR_BDW_DETAILS_FAILURE], data
        }
    };
};

export const wardDropdownApi = (data) => {
    return {
        url: API_URL.SOURCE_FOR_BDW.WARD,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_WARD_DROPDOWN_REQUEST, ACTION_TYPES.GET_WARD_DROPDOWN_SUCCESS, ACTION_TYPES.GET_WARD_DROPDOWN_FAILURE],
            data
        }
    };
};

export const postSourceForBdwDetailsApi = (data) => {
    return {
        url: API_URL.SOURCE_FOR_BDW.POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SOURCE_FOR_BDW_DETAILS_REQUEST, ACTION_TYPES.POST_SOURCE_FOR_BDW_DETAILS_SUCCESS, ACTION_TYPES.POST_SOURCE_FOR_BDW_DETAILS_FAILURE], data
        }
    };
};

export const patchSourceForBdwDetailsApi = (data) => {
    return {
        url: API_URL.SOURCE_FOR_BDW.POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_SOURCE_FOR_BDW_DETAILS_REQUEST, ACTION_TYPES.PATCH_SOURCE_FOR_BDW_DETAILS_SUCCESS, ACTION_TYPES.PATCH_SOURCE_FOR_BDW_DETAILS_FAILURE], data
        }
    };
};

export const getSourceForBdwDetailsByIdApi = (data) => {
    return {
        url: API_URL.SOURCE_FOR_BDW.GET_BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_SOURCE_FOR_BDW_DETAILS_BY_ID_REQUEST, ACTION_TYPES.GET_SOURCE_FOR_BDW_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_SOURCE_FOR_BDW_DETAILS_BY_ID_FAILURE]
        }
    };
};

export const deleteSourceForBdwDetailsApi = (data) => {
    return {
        url: API_URL.SOURCE_FOR_BDW.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_SOURCE_FOR_BDW_DETAILS_REQUEST, ACTION_TYPES.DELETE_SOURCE_FOR_BDW_DETAILS_SUCCESS, ACTION_TYPES.DELETE_SOURCE_FOR_BDW_DETAILS_FAILURE]
        }
    };
};
