import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    APPROVE_DPMC: `${STATE_REDUCER_KEY}/APPROVE_DPMC`,
    APPROVE_DPMC_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_DPMC_REQUEST`,
    APPROVE_DPMC_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_DPMC_SUCCESS`,
    APPROVE_DPMC_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_DPMC_FAILURE`,

    FETCH_DPMC_BY_ID: `${STATE_REDUCER_KEY}/FETCH_DPMC_BY_ID`,
    FETCH_DPMC_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DPMC_BY_ID_REQUEST`,
    FETCH_DPMC_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DPMC_BY_ID_SUCCESS`,
    FETCH_DPMC_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DPMC_BY_ID_FAILURE`,

    FETCH_RECOMMENDATION_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_RECOMMENDATION_DROPDOWN`,
    FETCH_RECOMMENDATION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_RECOMMENDATION_DROPDOWN_REQUEST`,
    FETCH_RECOMMENDATION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_RECOMMENDATION_DROPDOWN_SUCCESS`,
    FETCH_RECOMMENDATION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_RECOMMENDATION_DROPDOWN_FAILURE`,

    FETCH_PREVIOUS_DPMC: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_DPMC`,
    FETCH_PREVIOUS_DPMC_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_DPMC_REQUEST`,
    FETCH_PREVIOUS_DPMC_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_DPMC_SUCCESS`,
    FETCH_PREVIOUS_DPMC_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_DPMC_FAILURE`
};

export const approveDpmc = createAction(ACTION_TYPES.APPROVE_DPMC);
export const fetchDpmcById = createAction(ACTION_TYPES.FETCH_DPMC_BY_ID);
export const fetchRecommendationDropdown = createAction(ACTION_TYPES.FETCH_RECOMMENDATION_DROPDOWN);
export const fetchPreviousDpmc = createAction(ACTION_TYPES.FETCH_PREVIOUS_DPMC);
