import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions.js";
import { ACTION_TYPES as TYPES } from "../fiveYearPlan/actions.js";
import { getPayloadContent } from "utils/apiUtils";
import _ from "lodash";

const initialState = {
    lsgiSelectDropdownOption: {
        requestInProgress: false,
        data: []
    },
    districtDropdown: {
        requestInProgress: false,
        data: []
    },
    fetchSWMPlanListDetailsById: {
        requestInProgress: false,
        data: {
            districtId: "",
            lsgiId: "",
            swmPlanId: "",
            swmPlanName: "",
            remarks: "",
            swmPlanVersionId: "",
            attachmentIds: [],
            fiveYearAttachmentIds: [],
            mcResolutionNumber: "",
            mcResolutionDate: null,
            mcRemarks: "",
            dpmuRemarks: "",
            spmuRemarks: ""
        }
    },
    planStatusList: {
        requestInProgress: false,
        data: []
    },
    previousSwmPlanAttachments: {
        requestInProgress: false,
        data: []
    }
};
const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clear: () => initialState,
        clearStatus: (state) => {
            state.planStatusList.data = initialState.planStatusList.data;
        },
        clearValue: (state) => {
            state.fetchSWMPlanListDetails.data = initialState.fetchSWMPlanListDetails.data;
        },
        clearDistrict: (state) => {
            state.districtDropdown.data = initialState.districtDropdown.data;
        },
        clearLsgi: (state) => {
            state.lsgiSelectDropdownOption.data = initialState.lsgiSelectDropdownOption.data;
        },
        clearFetchSWMPlanListDetailsById: (state) => {
            state.fetchSWMPlanListDetailsById.data = initialState.fetchSWMPlanListDetailsById.data;
        },
        clearPreviousSwmPlanAttachments: (state) => {
            state.previousSwmPlanAttachments.data = initialState.previousSwmPlanAttachments.data;
        },
        setRequestInProgress: (state) => {
            state.fetchSWMPlanListDetailsById.requestInProgress = true;
        },
        resetRequestInProgress: (state) => {
            state.fetchSWMPlanListDetailsById.requestInProgress = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "districtDropdown.requestInProgress", false);
                _.set(state, "districtDropdown.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST, (state) => {
                state.lsgiSelectDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, (state, action) => {
                state.lsgiSelectDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE, (state) => {
                state.lsgiSelectDropdownOption.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.LIST_BY_ID_SWM_PLAN_SUCCESS, (state, { payload }) => {
                _.set(state, "fetchSWMPlanListDetailsById.requestInProgress", false);
                _.set(state, "fetchSWMPlanListDetailsById.data", payload);
            })
            .addCase(TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_REQUEST, (state) => {
                state.fetchSWMPlanListDetailsById.requestInProgress = true;
            })
            .addCase(TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "fetchSWMPlanListDetailsById.requestInProgress", false);
                _.set(state, "fetchSWMPlanListDetailsById.data.fiveYearAttachmentIds", payload?.fiveYearAttachmentIds);
            })
            .addCase(TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_FAILURE, (state) => {
                state.fetchSWMPlanListDetailsById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_SWM_PLAN_REVISION_LIST_REQUEST, (state) => {
                state.fetchSWMPlanListDetailsById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_SWM_PLAN_REVISION_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "fetchSWMPlanListDetailsById.requestInProgress", false);
                _.set(state, "fetchSWMPlanListDetailsById.data.attachmentIds", payload?.content[payload?.content?.length - 1]?.attachmentIds || []);
            })
            .addCase(ACTION_TYPES.FETCH_SWM_PLAN_REVISION_LIST_FAILURE, (state) => {
                state.fetchSWMPlanListDetailsById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_REQUEST, (state) => {
                state.previousSwmPlanAttachments.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_SUCCESS, (state, { payload }) => {
                _.set(state, "previousSwmPlanAttachments.requestInProgress", false);
                _.set(state, "previousSwmPlanAttachments.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_FAILURE, (state) => {
                state.previousSwmPlanAttachments.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_STATUS_LIST_SUCCESS, (state, action) => {
                _.set(state, "planStatusList.requestInProgress", false);
                _.set(state, "planStatusList.data", getPayloadContent(action));
            });
    }
});
export const { actions, reducer } = slice;
