export const PLAN_TYPE = {
    PROFILE_OF_LOCAL_BODY: "profileOfLocalBody",
    SWM_FACILITIES_PRESENT: "swmFacilitiesPresent",
    LAND_DETAILS: "LAND_DETAILS",
    POPULATION: "POPULATION",
    TRANSPORTATION: "TRANSPORTATION",
    FORWARD_LINKED: "FORWARD_LINKED",
    INVOLVEMENT_OF_HKS: "INVOLVEMENT_OF_HKS",
    STREET_SWEEPING_AND_DRAINS: "STREET_SWEEPING_AND_DRAINS",
    WASTE_GENERATED: "WASTE_GENERATED",
    WASTE_PROCESSED: "WASTE_PROCESSED",
    WASTE_COLLECTED: "WASTE_COLLECTED",
    WASTE_RECIEVED: "WASTE_RECIEVED",
    WASTE_GENERATORS: "WASTE_GENERATORS",
    PAYMENT_TO_STAFF_AND_EXPENSES_IN_SWM: "PAYMENT_TO_STAFF_AND_EXPENSES_IN_SWM",
    INCOME_FROM_WASTE: "INCOME_FROM_WASTE"
};

export const STATE_REDUCER_KEY = "gapAnalysis";


export const yearsList = [
    { id: 1, name: "2020" },
    { id: 2, name: "2021" },
    { id: 3, name: "2022" },
    { id: 4, name: "2023" },
    { id: 5, name: "2024" }
];


export const typeOfFacilitiesList = [
    { id: 1, name: "MCF" },
    { id: 2, name: "RRF" },
    { id: 3, name: "Others" }

];

export const typeOfForwardLinkageList = [
    { id: 1, name: "CKCL", type: true },
    { id: 2, name: "Other Agency", type: false }
];
