import { createSlice } from "@reduxjs/toolkit";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const APPROVAL_BY_MUNICIPAL_DETAILS = {
    mcResolutionNumber: "",
    mcResolutionDate: null,
    mcRemarks: "",
    swmRevisionId: "",
    swmPlanId: "",
    planId: ""
};

const initialState = {
    approvalByMc: {
        requestInProgress: false,
        data: {
            ...APPROVAL_BY_MUNICIPAL_DETAILS
        }
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.approvalByMc = initialState.approvalByMc;
        }
        },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.APPROVAL_MUNICIPAL_BY_ID_REQUEST, (state) => {
                state.approvalByMc.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.APPROVAL_MUNICIPAL_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "approvalByMc.requestInProgress", false);
                _.set(state, "approvalByMc.data", payload?.content?.length > 0 ? payload?.content[payload?.content?.length - 1] : state.approvalByMc.data);
            })
            .addCase(ACTION_TYPES.APPROVAL_MUNICIPAL_BY_ID_FAILURE, (state) => {
                state.approvalByMc.requestInProgress = false;
            });
    }

});

export const { actions, reducer } = slice;
