import { I18n } from "common/components";
import { lazy } from "react";
import CertificateIssue from "./batch/components/CertificateIssue";
import CertificateList from "./certificate/components/CertificateList";
import TrainingCertificate from "./certificate/components/TrainingCertificate";
import { TRAINING_PATH as PATH } from "./paths";
import { RESOURCE_ID } from "modules/resources";
import TrainingConducted from "./reports/TrainingConducted";
import TrainingScheduled from "./reports/TrainingScheduled";
import TcmSummary from "./reports/TcmSummary";
import TcmParticipants from "./reports/TcmParticipants";
import TcmParticipantsAnalysis from "./reports/TcmParticipantsAnalysis";
//import MarkAttendance from "./schedule/components/MarkAttendance";
const TrainingPlanList = lazy(() => import("./plan/components/TrainingPlanList"));
const TrainingPlanSaveForm = lazy(() => import("./plan/components/TrainingPlanForm"));
const Need = lazy(() => import("./need/components/TrainingNeed"));
const NeedTable = lazy(() => import("./need/components/TrainingTable"));
const CoursePage = lazy(() => import("./course/components/CoursePage"));
const CourseList = lazy(() => import("./course/components/CourseList"));
const VenueForm = lazy(() => import("./venue/components/VenueForm"));
const VenueFormList = lazy(() => import("./venue/components/VenueFormList"));
// const Dashboard = lazy(() => import("./dashboard/components/Dashboard"));
const TrainingBatch = lazy(() => import("./batch/components/TrainingBatch"));
const BatchList = lazy(() => import("./batch/components/BatchList"));
const ViewBatch = lazy(() => import("./batch/components/ViewBatch"));
const AddBatch = lazy(() => import("./batch/components/AddBatch"));
const QuestionnairePage = lazy(() => import("./course/components/QuestionnairePage"));
const TrainingSchedule = lazy(() => import("./schedule/components/TrainingSchedule"));
const ScheduleList = lazy(() => import("./schedule/components/ScheduleList"));
const UserManual = lazy(() => import("./reports/Usermanual"));


const routes = [
    // {
    //     path: "",
    //     element: <Dashboard />
    // },

    {
        title: I18n("training_need"),
        path: PATH.NEED.LIST,
        id: RESOURCE_ID.TRAINING.TRAINING_NEED.LIST,
        children: [
            {
                path: "",
                element: <NeedTable />,
                id: RESOURCE_ID.TRAINING.TRAINING_NEED.LIST
            },
            {
                path: PATH.NEED.SAVE,
                element: <Need />,
                id: RESOURCE_ID.TRAINING.TRAINING_NEED.ADD
            },
            {
                path: `${PATH.NEED.LIST}/:id`,
                element: <Need />,
                id: RESOURCE_ID.TRAINING.TRAINING_NEED.EDIT
            },
            {
                path: `${PATH.NEED.LIST}/:id/view`,
                element: <Need />,
                id: RESOURCE_ID.TRAINING.TRAINING_NEED.VIEW
            }
        ]
    },

    {
        title: I18n("training_course"),
        path: PATH.COURSE.LIST,
        id: RESOURCE_ID.TRAINING.TRAINING_COURSE.LIST,
        children: [
            {
                path: "",
                element: <CourseList />,
                id: RESOURCE_ID.TRAINING.TRAINING_COURSE.LIST

            },
            {
                path: PATH.COURSE.SAVE,
                element: <CoursePage />,
                id: RESOURCE_ID.TRAINING.TRAINING_COURSE.ADD
            },
            {
                path: `${PATH.COURSE.LIST}/:id`,
                element: <CoursePage />,
                id: RESOURCE_ID.TRAINING.TRAINING_COURSE.EDIT
            },
            {
                path: `${PATH.COURSE.LIST}/:id/view`,
                element: <CoursePage />,
                id: RESOURCE_ID.TRAINING.TRAINING_COURSE.VIEW
            },
            {
                path: PATH.COURSE.ADD_QUESTION,
                element: <QuestionnairePage />,
                id: RESOURCE_ID.TRAINING.TRAINING_COURSE.QUESTION
            }
        ]
    },

    {
        title: I18n("plan"),
        path: PATH.PLAN.LIST,
        id: RESOURCE_ID.TRAINING.TRAINING_PLAN.LIST,
        children: [
            {
                path: "",
                element: <TrainingPlanList />,
                id: RESOURCE_ID.TRAINING.TRAINING_PLAN.LIST
            },
            {
                path: PATH.PLAN.SAVE,
                element: <TrainingPlanSaveForm />,
                id: RESOURCE_ID.TRAINING.TRAINING_PLAN.ADD
            },
            {
                path: `${PATH.PLAN.LIST}/:id`,
                element: <TrainingPlanSaveForm />,
                id: RESOURCE_ID.TRAINING.TRAINING_PLAN.EDIT
            },
            {
                path: `${PATH.PLAN.LIST}/:id/view`,
                element: <TrainingPlanSaveForm />,
                id: RESOURCE_ID.TRAINING.TRAINING_PLAN.VIEW
            }
        ]
    },

    {
        title: I18n("training_venue"),
        path: PATH.VENUE.LIST,
        id: RESOURCE_ID.TRAINING.TRAINING_VENUE.LIST,
        children: [
            {
                path: "",
                element: <VenueFormList />,
                id: RESOURCE_ID.TRAINING.TRAINING_VENUE.LIST

            },
            {
                path: PATH.VENUE.SAVE,
                element: <VenueForm />,
                id: RESOURCE_ID.TRAINING.TRAINING_VENUE.ADD
            },
            {
                path: `${PATH.VENUE.LIST}/:id`,
                element: <VenueForm />,
                id: RESOURCE_ID.TRAINING.TRAINING_VENUE.EDIT
            },
            {
                path: `${PATH.VENUE.LIST}/:id/view`,
                element: <VenueForm />,
                id: RESOURCE_ID.TRAINING.TRAINING_VENUE.VIEW
            }
        ]
    },
    {
        title: I18n("training_batch"),
        path: PATH.BATCH.LIST,
        id: RESOURCE_ID.TRAINING.TRAINING_BATCH.LIST,
        children: [
            {
                path: "",
                element: <BatchList />,
                id: RESOURCE_ID.TRAINING.TRAINING_BATCH.LIST
            },
            {
                path: PATH.BATCH.SAVE,
                element: <TrainingBatch />,
                id: RESOURCE_ID.TRAINING.TRAINING_BATCH.ADD
            },
            {
                path: `${PATH.BATCH.LIST}/:id`,
                element: <TrainingBatch />,
                id: RESOURCE_ID.TRAINING.TRAINING_BATCH.EDIT
            },
            {
                path: `${PATH.BATCH.LIST}/:id/view`,
                element: <TrainingBatch />,
                id: RESOURCE_ID.TRAINING.TRAINING_BATCH.VIEW
            },
            {
                path: `${PATH.BATCH.VIEW}/:id`,
                element: <ViewBatch />,
                id: RESOURCE_ID.TRAINING.TRAINING_BATCH.VIEW_PARTICIPANTS
            },
            {
                path: `${PATH.BATCH.ADD}/:id`,
                element: <AddBatch />,
                id: RESOURCE_ID.TRAINING.TRAINING_BATCH.ADD_PARTICIPANTS
            },
            {
                path: `${PATH.BATCH.ISSUE_CERTIFICATE}/:id`,
                element: <CertificateIssue />
            }
        ]
    },


    {
        title: I18n("training_schedule"),
        path: PATH.SCHEDULE.LIST,
        id: RESOURCE_ID.TRAINING.TRAINING_SCHEDULE.LIST,
        children: [
            {
                path: "",
                element: <ScheduleList />,
                id: RESOURCE_ID.TRAINING.TRAINING_SCHEDULE.LIST
            },
            {
                path: PATH.SCHEDULE.SAVE,
                element: <TrainingSchedule />,
                id: RESOURCE_ID.TRAINING.TRAINING_SCHEDULE.ADD
            },
            {
                path: `${PATH.SCHEDULE.LIST}/:id`,
                element: <TrainingSchedule />,
                id: RESOURCE_ID.TRAINING.TRAINING_SCHEDULE.EDIT
            },
            {
                path: `${PATH.SCHEDULE.LIST}/:id/view`,
                element: <TrainingSchedule />,
                id: RESOURCE_ID.TRAINING.TRAINING_SCHEDULE.VIEW
            }
        ]
    },


    // {
    //     path: `${PATH.SCHEDULE.ATTENDANCE}/:id`,
    //     element: <MarkAttendance />
    // },
    {
        title: I18n("training_certificate"),
        path: PATH.CERTIFICATE.LIST,
        id: RESOURCE_ID.TRAINING.TRAINING_CERTIFICATES.LIST,
        children: [
            {
                path: "",
                element: <CertificateList />,
                id: RESOURCE_ID.TRAINING.TRAINING_CERTIFICATES.LIST
            },
            {
                path: PATH.CERTIFICATE.SAVE,
                element: <TrainingCertificate />,
                id: RESOURCE_ID.TRAINING.TRAINING_CERTIFICATES.ADD
            },
            {
                path: `${PATH.CERTIFICATE.LIST}/:id`,
                element: <TrainingCertificate />,
                id: RESOURCE_ID.TRAINING.TRAINING_CERTIFICATES.EDIT
            },
            {
                path: `${PATH.CERTIFICATE.LIST}/:id/view`,
                element: <TrainingCertificate />,
                id: RESOURCE_ID.TRAINING.TRAINING_CERTIFICATES.VIEW
            }
        ]
    },
    {
        title: I18n("reports"),
        id: RESOURCE_ID.TRAINING.TRAINING_REPORTS.ROUTE,
        children: [
            {
                title: I18n("training_conducted"),
                path: PATH.TRAINING_CONDUCTED.LIST,
                id: RESOURCE_ID.TRAINING.TRAINING_REPORTS.TCM_TRAINING_CONDUCTED.LIST,
                children: [
                    {
                        path: "",
                        element: <TrainingConducted />
                    }
                ]
            },
            {
                title: I18n("training_scheduled"),
                path: PATH.TRAINING_SCHEDULED.LIST,
                id: RESOURCE_ID.TRAINING.TRAINING_REPORTS.TCM_TRAINING_SCHEDULED.LIST,
                children: [
                    {
                        path: "",
                        element: <TrainingScheduled />
                    }
                ]
            },
            {
                title: I18n("training_summary"),
                path: PATH.TCM_SUMMARY.LIST,
                id: RESOURCE_ID.TRAINING.TRAINING_REPORTS.TCM_SUMMARY.LIST,
                children: [
                    {
                        path: "",
                        element: <TcmSummary />
                    }
                ]
            },
            {
                title: I18n("participants_registry"),
                path: PATH.TCM_PARTICIPANTS.LIST,
                id: RESOURCE_ID.TRAINING.TRAINING_REPORTS.TCM_PARTICIPANTS.LIST,
                children: [
                    {
                        path: "",
                        element: <TcmParticipants />
                    }
                ]
            },
            {
                title: I18n("training_participants_analysis"),
                path: PATH.TCM_PARTICIPANTS_ANALYSIS.LIST,
                id: RESOURCE_ID.TRAINING.TRAINING_REPORTS.TCM_PARTICIPANTS_ANALYSIS.LIST,
                children: [
                    {
                        path: "",
                        element: <TcmParticipantsAnalysis />
                    }
                ]
            }
        ]
    },
    {
        title: I18n("user_manual"),
        path: PATH.TCM_USER_MANUAL.LIST,
        children: [
            {
                path: "",
                element: <UserManual />,
                id: RESOURCE_ID.TRAINING.USER_MANUAL.LIST
            }
        ]
    }

];

export { routes };
