const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./action");
import _ from "lodash";

const initialState = {
    receiptById: {
        requestInProgress: false,
        data: {
            districtId: "",
            lsgiId: "",
            amount: "",
            govtOrderNum: "",
            asDate: "",
            attachments: ""
        }
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState,
        setReceiptDetailsById: (state, action) => {
            state.receiptById.data = action.payload;
        },
        resetReceiptDetailsById: (state) => {
            state.receiptById.data = initialState.receiptById.data;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.GET_RECEIPT_DETAILS_BY_ID_REQUEST, (state) => {
                state.receiptById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_RECEIPT_DETAILS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "receiptById.requestInProgress", false);
                _.set(state, "receiptById.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_RECEIPT_DETAILS_BY_ID_FAILURE, (state) => {
                state.receiptById.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;

