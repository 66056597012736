import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { TABLE_IDS } from "common/constants";
import { successNotify } from "utils/notificationUtils";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { I18n } from "common/components";
import { MODULE_PATH } from "modules/paths";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";
import { toEpoch } from "utils/dateUtils";
import { API_URL } from "../apiUrls";

const formatData = (payload) => {
    const { date, ...rest } = payload;
    const data = { date: toEpoch(date), ...rest };
    return data;
};

export function* getWasteDisposalRegionalList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.WASTE_DISPOSAL_REGIONAL_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchWasteDisposalRegionalListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_LIST_SUCCESS, ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WASTE_DISPOSAL_REGIONAL_LIST);
}

export function* submitWasteDisposalRegional(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.updateWasteDisposalRegionalApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_WASTE_DISPOSAL_REGIONAL_SUCCESS, ACTION_TYPES.PATCH_WASTE_DISPOSAL_REGIONAL_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_WASTE_DISPOSAL_REGIONAL_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("waste_disposal_regional_site") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.WASTE_DISPOSAL_AT_REGIONAL_SITE.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, Api.submitWasteDisposalRegionalApi, formatData(rest));
        const responseAction = yield take([ACTION_TYPES.POST_WASTE_DISPOSAL_REGIONAL_SUCCESS, ACTION_TYPES.POST_WASTE_DISPOSAL_REGIONAL_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_WASTE_DISPOSAL_REGIONAL_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("waste_disposal_regional_site") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.WASTE_DISPOSAL_AT_REGIONAL_SITE.LIST}`));
        }
    }
}

export function* WasteDisposalRegionalById({ payload }) {
    yield fork(handleAPIRequest, Api.fetchWasteDisposalRegionalByIdApi, payload);
    const responseAction = yield take([ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_SUCCESS, ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_SUCCESS) {
        if (responseAction?.payload?.district && responseAction?.payload?.lsgi) {
            yield call(handleAPIRequest, Api.fetchNameDropdownApi, { lsgiId: responseAction?.payload?.lsgi?.id, pageNo: 0, pageSize: 500 });
        }
    }
}

export function* deleteWasteDisposalRegional({ payload: id }) {
    yield fork(handleAPIRequest, Api.deleteWasteDisposalRegionalApi, id);
    const response = yield take([ACTION_TYPES.DELETE_WASTE_DISPOSAL_REGIONAL_SUCCESS, ACTION_TYPES.DELETE_WASTE_DISPOSAL_REGIONAL_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_WASTE_DISPOSAL_REGIONAL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("waste_disposal_regional_site") }) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.WASTE_DISPOSAL_REGIONAL_LIST]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchWasteDisposalRegionalListApi, { pageNo: 0, pageSize: 5, ...filters });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_LIST_SUCCESS, ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WASTE_DISPOSAL_REGIONAL_LIST);
    }
}

export function* surroundingDropdown({ payload }) {
    yield call(handleAPIRequest, Api.fetchNameDropdownApi, payload);
}

export function* downloadWasteDisposalAtRegional(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { fromDate, toDate } = filters;
    yield put(commonFileDownload({
        url: API_URL.WASTE_DISPOSAL_REGIONAL_SITE.DOWNLOAD,
        data: { type, fromDate, toDate },
        file: { name: fileName, ext: type }
    }));
}
export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_LIST, getWasteDisposalRegionalList),
        takeLatest(ACTION_TYPES.POST_WASTE_DISPOSAL_REGIONAL, submitWasteDisposalRegional),
        takeLatest(ACTION_TYPES.PATCH_WASTE_DISPOSAL_REGIONAL, submitWasteDisposalRegional),
        takeLatest(ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID, WasteDisposalRegionalById),
        takeLatest(ACTION_TYPES.DELETE_WASTE_DISPOSAL_REGIONAL, deleteWasteDisposalRegional),
        takeLatest(ACTION_TYPES.FETCH_NAME_DROPDOWN, surroundingDropdown),
        takeLatest(ACTION_TYPES.DOWNLOAD_WASTE_DISPOSAL_REGIONAL_LIST, downloadWasteDisposalAtRegional)
    ]);
}
