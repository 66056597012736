import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchLandAcquisitionDataApi = (data) => {
    return {
        url: API_URL.LAND_ACQUISITION.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LAND_ACQUISITION_DATA_REQUEST, ACTION_TYPES.FETCH_LAND_ACQUISITION_DATA_SUCCESS, ACTION_TYPES.FETCH_LAND_ACQUISITION_DATA_FAILURE], data
        }
    };
};

export const patchLandAcquisitionApi = (data) => {
    return {
        url: API_URL.LAND_ACQUISITION.PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_LAND_ACQUISITION_DATA_REQUEST, ACTION_TYPES.PATCH_LAND_ACQUISITION_DATA_SUCCESS, ACTION_TYPES.PATCH_LAND_ACQUISITION_DATA_FAILURE],
            data
        }
    };
};
