import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_PRIMARY_COLLECTION_BDW: `${STATE_REDUCER_KEY}/FETCH_PRIMARY_COLLECTION_BDW`,
    FETCH_PRIMARY_COLLECTION_BDW_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PRIMARY_COLLECTION_BDW_REQUEST`,
    FETCH_PRIMARY_COLLECTION_BDW_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PRIMARY_COLLECTION_BDW_SUCCESS`,
    FETCH_PRIMARY_COLLECTION_BDW_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PRIMARY_COLLECTION_BDW_FAILURE`,

    POST_PRIMARY_COLLECTION_BDW: `${STATE_REDUCER_KEY}/POST_PRIMARY_COLLECTION_BDW`,
    POST_PRIMARY_COLLECTION_BDW_REQUEST: `${STATE_REDUCER_KEY}/POST_PRIMARY_COLLECTION_BDW_REQUEST`,
    POST_PRIMARY_COLLECTION_BDW_SUCCESS: `${STATE_REDUCER_KEY}/POST_PRIMARY_COLLECTION_BDW_SUCCESS`,
    POST_PRIMARY_COLLECTION_BDW_FAILURE: `${STATE_REDUCER_KEY}/POST_PRIMARY_COLLECTION_BDW_FAILURE`,

    PATCH_PRIMARY_COLLECTION_BDW: `${STATE_REDUCER_KEY}/PATCH_PRIMARY_COLLECTION_BDW`,
    PATCH_PRIMARY_COLLECTION_BDW_REQUEST: `${STATE_REDUCER_KEY}/PATCH_PRIMARY_COLLECTION_BDW_REQUEST`,
    PATCH_PRIMARY_COLLECTION_BDW_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_PRIMARY_COLLECTION_BDW_SUCCESS`,
    PATCH_PRIMARY_COLLECTION_BDW_FAILURE: `${STATE_REDUCER_KEY}/PATCH_PRIMARY_COLLECTION_BDW_FAILURE`,

    GET_PRIMARY_COLLECTION_BDW_BY_ID: `${STATE_REDUCER_KEY}/GET_PRIMARY_COLLECTION_BDW_BY_ID`,
    GET_PRIMARY_COLLECTION_BDW_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_PRIMARY_COLLECTION_BDW_BY_ID_REQUEST`,
    GET_PRIMARY_COLLECTION_BDW_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_PRIMARY_COLLECTION_BDW_BY_ID_SUCCESS`,
    GET_PRIMARY_COLLECTION_BDW_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_PRIMARY_COLLECTION_BDW_FAILURE`,

    DELETE_PRIMARY_COLLECTION_BDW: `${STATE_REDUCER_KEY}/DELETE_PRIMARY_COLLECTION_BDW`,
    DELETE_PRIMARY_COLLECTION_BDW_REQUEST: `${STATE_REDUCER_KEY}/DELETE_PRIMARY_COLLECTION_BDW_REQUEST`,
    DELETE_PRIMARY_COLLECTION_BDW_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_PRIMARY_COLLECTION_BDW_SUCCESS`,
    DELETE_PRIMARY_COLLECTION_BDW_FAILURE: `${STATE_REDUCER_KEY}/DELETE_PRIMARY_COLLECTION_BDW_FAILURE`,

    DOWNLOAD_PRIMARY_COLLECTION_BDW: `${STATE_REDUCER_KEY}/DOWNLOAD_PRIMARY_COLLECTION_BDW`,
    DOWNLOAD_PRIMARY_COLLECTION_BDW_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_PRIMARY_COLLECTION_BDW_REQUEST`,
    DOWNLOAD_PRIMARY_COLLECTION_BDW_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_PRIMARY_COLLECTION_BDW_SUCCESS`,
    DOWNLOAD_PRIMARY_COLLECTION_BDW_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_PRIMARY_COLLECTION_BDW_FAILURE`
};

export const getPrimaryCollectionBdw = createAction(ACTION_TYPES.FETCH_PRIMARY_COLLECTION_BDW);
export const postPrimaryCollectionBdw = createAction(ACTION_TYPES.POST_PRIMARY_COLLECTION_BDW);
export const patchPrimaryCollectionBdw = createAction(ACTION_TYPES.PATCH_PRIMARY_COLLECTION_BDW);
export const getPrimaryCollectionBdwById = createAction(ACTION_TYPES.GET_PRIMARY_COLLECTION_BDW_BY_ID);
export const deletePrimaryCollection = createAction(ACTION_TYPES.DELETE_PRIMARY_COLLECTION_BDW);
export const downloadPrimaryCollection = createAction(ACTION_TYPES.DOWNLOAD_PRIMARY_COLLECTION_BDW);


