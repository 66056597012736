import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchSecondaryCollectionAgencyApi = (data) => {
    return {
        url: API_URL.SECONDARY_COLLECTION_AGENCY.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SECONDARY_COLLECTION_AGENCY_REQUEST, ACTION_TYPES.FETCH_SECONDARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.FETCH_SECONDARY_COLLECTION_AGENCY_FAILURE], data
        }
    };
};

export const fetchWomenGroupDropdownApi = () => {
    return {
        url: API_URL.SECONDARY_COLLECTION_AGENCY.WOMEN_GROUP_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WOMEN_GROUP_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_WOMEN_GROUP_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_WOMEN_GROUP_DROPDOWN_FAILURE]
        }
    };
};

export const submitSecondaryCollectionAgencyApi = (data) => ({
    url: API_URL.SECONDARY_COLLECTION_AGENCY.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_SECONDARY_COLLECTION_AGENCY_REQUEST, ACTION_TYPES.POST_SECONDARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.POST_SECONDARY_COLLECTION_AGENCY_FAILURE],
        data
    }
});

export const updateSecondaryCollectionAgencyApi = (data) => ({
    url: API_URL.SECONDARY_COLLECTION_AGENCY.SAVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_SECONDARY_COLLECTION_AGENCY_REQUEST, ACTION_TYPES.PATCH_SECONDARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.PATCH_SECONDARY_COLLECTION_AGENCY_FAILURE],
        data
    }
});

export const fetchSecondaryCollectionByIdApi = (id) => {
    return {
        url: API_URL.SECONDARY_COLLECTION_AGENCY.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SECONDARY_COLLECTION_BY_ID_REQUEST, ACTION_TYPES.FETCH_SECONDARY_COLLECTION_BY_ID_SUCCESS, ACTION_TYPES.FETCH_SECONDARY_COLLECTION_BY_ID_FAILURE]
        }
    };
};

export const deleteSecondaryCollectionApi = (id) => ({
    url: API_URL.SECONDARY_COLLECTION_AGENCY.DELETE.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
        types: [ACTION_TYPES.DELETE_SECONDARY_COLLECTION_AGENCY_REQUEST, ACTION_TYPES.DELETE_SECONDARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.DELETE_SECONDARY_COLLECTION_AGENCY_FAILURE]
    }
});

export const fetchAgencyNameDropdownApi = () => {
    return {
        url: API_URL.SECONDARY_COLLECTION_AGENCY.AGENCY_NAME_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.AGENCY_NAME_DROPDOWN_REQUEST, ACTION_TYPES.AGENCY_NAME_DROPDOWN_SUCCESS, ACTION_TYPES.AGENCY_NAME_DROPDOWN_FAILURE]
        }
    };
};
