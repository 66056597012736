import { I18n } from "common/components";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { approveSpmuApi, approveSpmuByIdApi, fetchPreviousSpmuApi, rejectSpmuApi } from "./api";
import { SUB_PROJECTS_PATH as PATH } from "../paths";
import { MODULE_PATH } from "modules/paths";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { API_URL } from "../apiUrls";

const formatData = (payload) => {
    const { planId, ...rest } = payload;
    const data = { planId: Number(planId), ...rest };
    return data;
};

const formatPayload = (payload) => {
    const { planId, ...rest } = payload;
    const data = { planId: Number(planId), ...rest };
    return data;
};

export function* approveSpmu(action) {
    yield fork(handleAPIRequest, approveSpmuApi, formatData(action.payload));
    const responseAction = yield take([ACTION_TYPES.APPROVE_SPMU_SUCCESS, ACTION_TYPES.APPROVE_SPMU_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_SPMU_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approved", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.SWM_PLAN.LIST}`));
    }
}

export function* rejectSpmu(action) {
    yield fork(handleAPIRequest, rejectSpmuApi, formatPayload(action.payload));
    const responseAction = yield take([ACTION_TYPES.REJECT_SPMU_SUCCESS, ACTION_TYPES.REJECT_SPMU_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_SPMU_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("rejected", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.SWM_PLAN.LIST}`));
    }
}

export function* approvalSpmuById({payload}) {
    yield call(handleAPIRequest, approveSpmuByIdApi, payload);
}

export function* getPreviousSpmu({payload}) {
    yield call(handleAPIRequest, fetchPreviousSpmuApi, payload);
}

export function* downloadRemarks(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { swmPlan } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.APPROVE_SPMU.DOWNLOAD,
        data: { type, swmPlan },
        file: { name: fileName, ext: type }
    }));
}

export default function* registerSaga() {
    yield all([
        takeLatest(ACTION_TYPES.APPROVE_SPMU, approveSpmu),
        takeLatest(ACTION_TYPES.REJECT_SPMU, rejectSpmu),
        takeLatest(ACTION_TYPES.APPROVE_SPMU_BY_ID, approvalSpmuById),
        takeLatest(ACTION_TYPES.FETCH_PREVIOUS_SPMU, getPreviousSpmu),
        takeLatest(ACTION_TYPES.DOWNLOAD_REMARKS, downloadRemarks)
    ]);
}
