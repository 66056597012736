import { I18n } from "common/components";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "modules/paths";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ADMIN_PATH } from "../paths";
import { ACTION_TYPES } from "./actions";
import { addressList, deleteIeaContactsApi, deleteOtherDepartmentsApi, filterIeaName, getIeaContactsByIdApi, getOtherDepartmentsByIdApi, ieaContactsTypeDropdownApi, ieaLookupCategoryDropdownApi, listIeaContactsApi, listOtherDepartmentsApi, nameList, otherDepartmentsContactTypeDropdownApi, otherDepartmentsLookupCategoryDropdownApi, patchIeaContactsApi, patchOtherDepartmentsApi, submitIeaContactsApi, submitOtherDepartmentApi } from "./api";
import { actions as commonActions } from "../../common/slice";
import { getTableProps } from "modules/common/selectors";

export function* addOtherDepartmentsDetails(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, patchOtherDepartmentsApi, ({ id, ...rest }));
        const response = yield take([ACTION_TYPES.UPDATE_OTHER_DEPARTMENTS_DETAILS_SUCCESS, ACTION_TYPES.UPDATE_OTHER_DEPARTMENTS_DETAILS_FAILURE]);
        if (response.type === ACTION_TYPES.UPDATE_OTHER_DEPARTMENTS_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("other_departments") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${ADMIN_PATH.OTHER_DEPARTMENT.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, submitOtherDepartmentApi, rest);
        const response = yield take([ACTION_TYPES.SUBMIT_OTHER_DEPARTMENTS_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_OTHER_DEPARTMENTS_DETAILS_FAILURE]);
        if (response.type === ACTION_TYPES.SUBMIT_OTHER_DEPARTMENTS_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("other_departments") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${ADMIN_PATH.OTHER_DEPARTMENT.LIST}`));
        }
    }
}
export function* addIeaContactsDetails(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, patchIeaContactsApi, ({ id, ...rest }));
        const response = yield take([ACTION_TYPES.UPDATE_IEA_CONTACTS_DETAILS_SUCCESS, ACTION_TYPES.UPDATE_IEA_CONTACTS_DETAILS_FAILURE]);
        if (response.type === ACTION_TYPES.UPDATE_IEA_CONTACTS_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("iea") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${ADMIN_PATH.IEA.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, submitIeaContactsApi, rest);
        const response = yield take([ACTION_TYPES.SUBMIT_IEA_CONTACTS_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_IEA_CONTACTS_DETAILS_FAILURE]);
        if (response.type === ACTION_TYPES.SUBMIT_IEA_CONTACTS_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("iea") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${ADMIN_PATH.IEA.LIST}`));
        }
    }
}
export function* otherDepartmentsDropdownContactType(action) {
    yield call(handleAPIRequest, otherDepartmentsContactTypeDropdownApi, action.payload);
}
export function* ieaDropdownContactType(action) {
    yield call(handleAPIRequest, ieaContactsTypeDropdownApi, action.payload);
}
export function* otherDepartmentsDropdownLookupCategory(action) {
    yield call(handleAPIRequest, otherDepartmentsLookupCategoryDropdownApi, action.payload);
}
export function* ieaDropdownLookupCategory({ payload }) {
    yield call(handleAPIRequest, ieaLookupCategoryDropdownApi, payload);
}
export function* getOtherDepartments({ payload: id }) {
    yield call(handleAPIRequest, getOtherDepartmentsByIdApi, id);
}
export function* getIeaContacts({ payload: id }) {
    yield call(handleAPIRequest, getIeaContactsByIdApi, id);
}

export function* listOtherDepartments({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listOtherDepartmentsApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_OTHER_DEPARTMENTS_DETAILS_SUCCESS, ACTION_TYPES.LIST_OTHER_DEPARTMENTS_DETAILS_FAILURE
    ];
    yield* setCommonTableData(COMPARISON_TYPES);
}
export function* deleteOtherDepartments({ payload: id }) {
    yield fork(handleAPIRequest, deleteOtherDepartmentsApi, id);
    const response = yield take([ACTION_TYPES.DELETE_OTHER_DEPARTMENTS_DETAILS_SUCCESS, ACTION_TYPES.DELETE_OTHER_DEPARTMENTS_DETAILS_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_OTHER_DEPARTMENTS_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("other_departments") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listOtherDepartments, { payload: tablePayload });
    }
}
export function* listIeaContacts({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listIeaContactsApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [
        ACTION_TYPES.LIST_IEA_CONTACTS_DETAILS_SUCCESS,
        ACTION_TYPES.LIST_IEA_CONTACTS_DETAILS_FAILURE
    ];
    yield* setCommonTableData(COMPARISON_TYPES);
}
export function* deleteIeaContacts({ payload: id }) {
    yield fork(handleAPIRequest, deleteIeaContactsApi, id);
    const response = yield take([ACTION_TYPES.DELETE_IEA_CONTACTS_DETAILS_SUCCESS, ACTION_TYPES.DELETE_IEA_CONTACTS_DETAILS_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_IEA_CONTACTS_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("iea") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listIeaContacts, { payload: tablePayload });
    }
}
export function* patchOtherDepartments(action) {
    yield call(handleAPIRequest, patchOtherDepartmentsApi, action.payload);
}
export function* patchIeaContacts(action) {
    yield call(handleAPIRequest, patchIeaContactsApi, action.payload);
}
export function* filterIeaNameDropdown({ payload }) {
    yield call(handleAPIRequest, filterIeaName, payload);
}

export function* fetchNameList({ payload }) {
    yield call(handleAPIRequest, nameList, payload);
}
export function* fetchAddressList({ payload }) {
    yield call(handleAPIRequest, addressList, payload);
}
export default function* contactsSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_OTHER_DEPARTMENTS_DETAILS, addOtherDepartmentsDetails),
        takeLatest(ACTION_TYPES.FETCH_OTHER_DEPARTMENTS_BY_ID, getOtherDepartments),
        takeLatest(ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE, otherDepartmentsDropdownContactType),
        takeLatest(ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY, otherDepartmentsDropdownLookupCategory),
        takeLatest(ACTION_TYPES.DELETE_OTHER_DEPARTMENTS_DETAILS, deleteOtherDepartments),
        takeLatest(ACTION_TYPES.LIST_OTHER_DEPARTMENTS_DETAILS, listOtherDepartments),
        takeLatest(ACTION_TYPES.UPDATE_OTHER_DEPARTMENTS_DETAILS, patchOtherDepartments),
        takeLatest(ACTION_TYPES.SUBMIT_IEA_CONTACTS_DETAILS, addIeaContactsDetails),
        takeLatest(ACTION_TYPES.FETCH_IEA_CONTACTS_BY_ID, getIeaContacts),
        takeLatest(ACTION_TYPES.SELECT_IEA_CONTACT_TYPE, ieaDropdownContactType),
        takeLatest(ACTION_TYPES.SELECT_IEA_LOOKUP_CATEGORY, ieaDropdownLookupCategory),
        takeLatest(ACTION_TYPES.DELETE_IEA_CONTACTS_DETAILS, deleteIeaContacts),
        takeLatest(ACTION_TYPES.LIST_IEA_CONTACTS_DETAILS, listIeaContacts),
        takeLatest(ACTION_TYPES.UPDATE_IEA_CONTACTS_DETAILS, patchIeaContacts),
        takeLatest(ACTION_TYPES.FILTER_IEA_NAME, filterIeaNameDropdown),
        takeLatest(ACTION_TYPES.FETCH_NAME_LIST, fetchNameList),
        takeLatest(ACTION_TYPES.FETCH_ADDRESS_LIST, fetchAddressList)
    ]);

}

