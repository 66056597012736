export const API_URL = {
    COMMON: {
        STATE_DROPDOWN: "admin/api/auth/master/state/list/all",
        DISTRICT_DROPDOWN: "admin/api/auth/master/district/list/all",
        LSGI_TYPE: "admin/api/auth/master/lsgi-type/list/all",
        LSGI_DROPDOWN: "admin/api/auth/master/lsgi-details/list/all",
        WARD_DROPDOWN: "admin/api/auth/master/ward/list/all",
        GET_OTP: "notification/api/no-auth/notification/otp/send/:otp_type/:otp_process",
        POST_OTP: "notification/api/no-auth/notification/otp/verify",
        FILE_UPLOAD: "resource/api/auth/multimedia/upload/base-string/v2",
        MULTIMEDIA_LIST: "resource/api/auth/multimedia/list/by-id?ids=",
        LOGOUT_PROFILE: "admin/api/no-auth/idm/user/logout"
    }
};
