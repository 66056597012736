export const ADMIN_PATH = {
    DISTRICT_DETAILS: "organization-setup/district-details",
    DISTRICT_LIST: "organization-setup/district-list",
    LEGISLATIVE: {
        LIST: "organization-setup/legislative"
    },
    IEA: {
        CREATE: "iea-details-list",
        LIST: "external-organization/iea-details-list"
    },
    OTHER_DEPARTMENT: {
        LIST: "external-organization/other-department-list",
        DETAILS: "other-department-list/:id/view"
    },

    LOKSABHA: {
        LIST: "organization-setup/loksabha"
    },
    WARD: {
        LIST: "organization-setup/ward-form",
        TYPE: "organization-setup/ward-form",
        WARD_ESCALATION: "organization-setup/ward-form/:id/escalation"

    },
    LOOK_UP_FORM: {
        LIST: "general-setup/lookup-configuration/lookup-value"
    },
    LOOK_UP_CATEGORY_TYPE: {
        LIST: "general-setup/lookup-configuration/lookup-category-type"

    },
    SHO_NGO: {
        DETAILS: "external-organization/sho-ngo-details",
        LIST: "sho-ngo-list"
    },
    TRAINING_ORGANIZATION: {
        DETAILS: "external-organization/training-organization-details",
        LIST: "training-organization-list"
    },
    VENDOR_CATEGORY: {
        LIST: "miscellaneous/vendor-category",
        CREATE: "create",
        EDIT: ":id/edit",
        DETAILS: ":id/view"
    },
    VENDOR_CONTACT: {
        LIST: "external-organization/vendor-contact",
        CREATE: "create",
        EDIT: ":id/edit",
        DETAILS: ":id/view"
    },
    USER_ROLE_MAPPING: {
        LIST: "users/:userId",
        DATA_ACCESS_LIST: "users/:userId/data-access",
        DATA_ACCESS: "users/:userId/data-access/:roleId",
        CREATE: "users/create",
        EDIT: "users/:userId/data-access/:roleId/create/:id",
        DATA_ACCESS_CREATE: "users/:userId/data-access/:roleId/create"
    },
    RESOURCE_MANAGEMENT: {
        LIST: "role-management/menu-management",
        CREATE: "create",
        DETAILS: ":id/view",
        EDIT: ":id/edit"
    },
    ROLE: {
        RESOURCE: "resource",
        LIST: "role-management/role"
    },
    DESIGNATION_ENTRY: {
        LIST: "miscellaneous/designation-entry",
        CREATE: "create",
        DETAILS: ":id/view",
        EDIT: ":id/edit"
    },
    COMPLAINT: {
        STATUS: "miscellaneous/complaint-status",
        TYPE: "miscellaneous/complaint-type",
        CATEGORY: "miscellaneous/complaint-category",
        COMPLAINT_ESCALATION: "complaint-type/:id/escalation"
    },
    USER: {
        CREATE: "create-profile",
        LIST: "users",
        EDIT: "edit-profile/:id",
        VIEW: "view-profile/:id"
    },
    PROFILE: {
        VIEW: "profile",
        SETTINGS: "settings"
    },
    LSGI: {
        LIST: "organization-setup/lsgi-details",
        CREATE: "organization-setup/create",
        DETAILS: "organization-setup/:id/view",
        EDIT: "organization-setup/:id/edit"
    }

};
