const { STATE_REDUCER_KEY } = require("./constants");
import { flow } from "lodash";

const receiptData = (key) => key[STATE_REDUCER_KEY];

const getReceiptData = (state) => state.receiptById.data;
export const getReceiptDetails = flow(receiptData, getReceiptData);

const inProgress = (state) => state.receiptById?.requestInProgress;
export const requestInProgressSelector = flow(receiptData, inProgress);
