import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./action";

export const fetchMcfRrfDetailsApi = (data) => {
    return {
        url: API_URL.MCF_RRF.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_MCF_RRF_DETAILS_REQUEST, ACTION_TYPES.FETCH_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.FETCH_MCF_RRF_DETAILS_FAILURE], data
        }
    };
};

export const forwardLinkingDropdownApi = (data) => {
    return {
        url: API_URL.MCF_RRF.FORWARD_LINKING,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FORWARD_LINKING_DROPDOWN_REQUEST, ACTION_TYPES.FORWARD_LINKING_DROPDOWN_SUCCESS, ACTION_TYPES.FORWARD_LINKING_DROPDOWN_FAILURE], data
        }
    };
};

export const postMcfRrfDetailsApi = (data) => {
    return {
        url: API_URL.MCF_RRF.POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_MCF_RRF_DETAILS_REQUEST, ACTION_TYPES.POST_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.POST_MCF_RRF_DETAILS_FAILURE], data
        }
    };
};

export const patchMcfRrfDetailsApi = (data) => {
    return {
        url: API_URL.MCF_RRF.POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_MCF_RRF_DETAILS_REQUEST, ACTION_TYPES.PATCH_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.PATCH_MCF_RRF_DETAILS_FAILURE], data
        }
    };
};

export const mcfRrfDetailsByIdApi = (data) => {
    return {
        url: API_URL.MCF_RRF.GET_BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_MCF_RRF_DETAILS_BY_ID_REQUEST, ACTION_TYPES.GET_MCF_RRF_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_MCF_RRF_DETAILS_BY_ID_FAILURE]
        }
    };
};

export const deleteMcfRrfDetailsApi = (data) => {
    return {
        url: API_URL.MCF_RRF.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_MCF_RRF_DETAILS_REQUEST, ACTION_TYPES.DELETE_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.DELETE_MCF_RRF_DETAILS_FAILURE]
        }
    };
};

export const typeDropdownApi = (data) => {
    return {
        url: API_URL.MCF_RRF.TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.TYPE_OF_DEPARTMENT_DROPDOWN_REQUEST, ACTION_TYPES.TYPE_OF_DEPARTMENT_DROPDOWN_SUCCESS, ACTION_TYPES.TYPE_OF_DEPARTMENT_DROPDOWN_FAILURE], data
        }
    };
};

export const mcfRrfNameDropdownApi = (data) => {
    return {
        url: API_URL.MCF_RRF.MCF_RRF_NAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.MCF_RRF_NAME_DROPDOWN_REQUEST, ACTION_TYPES.MCF_RRF_NAME_DROPDOWN_SUCCESS, ACTION_TYPES.MCF_RRF_NAME_DROPDOWN_FAILURE], data
        }
    };
};

