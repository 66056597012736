import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const getTrainingBatch = state => state[STATE_REDUCER_KEY];

const trainingBatchForm = (batch) => batch.trainingBatchForm;
export const getBatchSaveForm = flow(getTrainingBatch, trainingBatchForm);

const addBatchForm = (batch) => batch.addBatchForm;
export const getAddBatchSaveForm = flow(getTrainingBatch, addBatchForm);

const viewBatchForm = (batch) => batch.viewBatchForm;
export const getViewBatchSaveForm = flow(getTrainingBatch, viewBatchForm);

const selectedRows = (batch) => batch.table.rowSelection;
export const getSelectedRows = flow(getTrainingBatch, selectedRows);

const trainingPlanId = (batch) => batch.trainingPlanId;
export const getTrainingPlanId = flow(getTrainingBatch, trainingPlanId);

const targetGroupId = (batch) => batch.targetGroupId;
export const getTargetGroupId = flow(getTrainingBatch, targetGroupId);

const venueId = (batch) => batch.venueId;
export const getVenueId = flow(getTrainingBatch, venueId);

const certificateIssue = (certificate) => certificate.certificateIssue;
export const getCertificateIssueForm = flow(getTrainingBatch, certificateIssue);

const batchId = (batch) => batch.batchId;
export const getBatchId = flow(getTrainingBatch, batchId);

const hostId = (batch) => batch.hostId;
export const getHostId = flow(getTrainingBatch, hostId);

const trainingMode = (batch) => batch.trainingMode;
export const getTrainingMode = flow(getTrainingBatch, trainingMode);

const openModal = (batch) => batch.openModal;
export const getOpen = flow(getTrainingBatch, openModal);

const count = (batch) => batch.count;
export const getCount = flow(getTrainingBatch, count);

const organizedBy = (batch) => batch.organizedBy;
export const getOrganizedBy = flow(getTrainingBatch, organizedBy);


const nameList = (state) => state.nameList.data;
export const fetchNameLists = flow(getTrainingBatch, nameList);

const designationId = (batch) => batch.designationId.data;
export const fetchDesignationList = flow(getTrainingBatch, designationId);

const filterBatchPlanId = (state) => state.filterBatchPlanId.data;
export const filterPlanIdDropdown = flow(getTrainingBatch, filterBatchPlanId);

const filterConfirmation = (state) => state.filterConfirmation.data;
export const filterConfirmationDropdown = flow(getTrainingBatch, filterConfirmation);

const userId = (batch) => batch.userId;
export const getUserId = flow(getTrainingBatch, userId);

const trainingCordinatorId = (batch) => batch.trainingCordinatorId;
export const getTrainingCordinator = flow(getTrainingBatch, trainingCordinatorId);

const designationCategoryDropDown = (batch) => batch.designationCategoryDropDown;
export const getDesignationCategoryDropDown = flow(getTrainingBatch, designationCategoryDropDown);

const ulbList = (batch) => batch.ulbList.data;
export const getLsgiSelectDropdownOption = flow(getTrainingBatch, ulbList);

const districtId = (batch) => batch.districtId.data;
export const getDistrictDropdown = flow(getTrainingBatch, districtId);

const confirmation = (batch) => batch.confirmation.data;
export const getConfirmationList = flow(getTrainingBatch, confirmation);

const thematicArea = (batch) => batch.thematicArea;
export const getThematicArea = flow(getTrainingBatch, thematicArea);

