import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const ulbSubmitApi = (data) => {
  return {
    url: API_URL.ULB.ULB_SUBMIT_DETAILS,
    method: REQUEST_METHOD.POST,
    payload: {
      types: [
        ACTION_TYPES.ADD_ULB_REQUEST,
        ACTION_TYPES.ADD_ULB_SUCCESS,
        ACTION_TYPES.ADD_ULB_FAILURE
      ],
      data
    }
  };
};
export const districtDropdownApi = () => {
  return {
    url: API_URL.COMMON.SELECT_DISTRICT_DROPDOWN,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.SELECT_ULB_DISTRICT_REQUEST,
        ACTION_TYPES.SELECT_ULB_DISTRICT_SUCCESS,
        ACTION_TYPES.SELECT_ULB_DISTRICT_FAILURE
      ],
      data: { dropDown: true }
    }
  };
};
export const lsgiTypeApi = () => {
  return {
    url: API_URL.COMMON.LSGI_TYPE_DROPDOWN,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.SELECT_LSGI_TYPE_REQUEST,
        ACTION_TYPES.SELECT_LSGI_TYPE_SUCCESS,
        ACTION_TYPES.SELECT_LSGI_TYPE_FAILURE
      ],
      data: { dropDown: true }
    }
  };
};

export const getUlbByIdApi = (id) => {
  return {
    url: API_URL.ULB.ULB_GET_BY_ID.replace(":id", id),
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.FETCH_ULB_BY_ID_REQUEST,
        ACTION_TYPES.FETCH_ULB_BY_ID_SUCCESS,
        ACTION_TYPES.FETCH_ULB_BY_ID_FAILURE
      ],
      data: { id }
    }
  };
};
export const deleteStateApi = (id) => {
  return {
    url: API_URL.STATE.DELETE_STATE.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
      types: [
        ACTION_TYPES.DELETE_STATE_DETAILS_REQUEST,
        ACTION_TYPES.DELETE_STATE_DETAILS_SUCCESS,
        ACTION_TYPES.DELETE_STATE_DETAILS_FAILURE
      ]
    }
  };
};
export const deleteUlbApi = (id) => {
  return {
    url: API_URL.ULB.DELETE_ULB.replace(":id, ", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
      types: [
        ACTION_TYPES.DELETE_ULB_DETAILS_REQUEST,
        ACTION_TYPES.DELETE_ULB_DETAILS_SUCCESS,
        ACTION_TYPES.DELETE_ULB_DETAILS_FAILURE
      ]
    }
  };
};
export const listStateApi = (data) => {
  return {
    url: API_URL.STATE.LIST_STATE,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.LIST_STATE_DETAILS_REQUEST,
        ACTION_TYPES.LIST_STATE_DETAILS_SUCCESS,
        ACTION_TYPES.LIST_STATE_DETAILS_FAILURE
      ],
      data
    }
  };
};
export const listUlbApi = (data) => {
  return {
    url: API_URL.ULB.LIST_ULB,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.LIST_ULB_DETAILS_REQUEST,
        ACTION_TYPES.LIST_ULB_DETAILS_SUCCESS,
        ACTION_TYPES.LIST_ULB_DETAILS_FAILURE
      ],
      data
    }
  };
};

export const patchUlbApi = (data) => {
  return {
    url: API_URL.ULB.UPDATE_ULB_DETAILS,
    method: REQUEST_METHOD.PATCH,
    payload: {
      types: [
        ACTION_TYPES.PATCH_ULB_DETAILS_REQUEST,
        ACTION_TYPES.PATCH_ULB_DETAILS_SUCCESS,
        ACTION_TYPES.PATCH_ULB_DETAILS_FAILURE
      ],
      data
    }
  };
};
export const fetchBlockPanchayathApi = ({ districtId, dropDown }) => {
  return {
    url: API_URL.ULB.BLOCK_PANCHAYATH,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.SELECT_BLOCK_PANCHAYATH_REQUEST, ACTION_TYPES.SELECT_BLOCK_PANCHAYATH_SUCCESS, ACTION_TYPES.SELECT_BLOCK_PANCHAYATH_FAILURE],
      data: { districtId, dropDown }
    }
  };
};
export const fetchDistrictPanchayathApi = ({ districtId, dropDown }) => {
  return {
    url: API_URL.ULB.DISTRICT_PANCHAYATH,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.SELECT_DISTRICT_PANCHAYATH_REQUEST, ACTION_TYPES.SELECT_DISTRICT_PANCHAYATH_SUCCESS, ACTION_TYPES.SELECT_BLOCK_PANCHAYATH_FAILURE],
      data: { districtId, dropDown }
    }
  };
};
export const nameList = (data) => {
  return {
    url: API_URL.ULB.FILTER_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.FETCH_NAME_LIST_REQUEST,
        ACTION_TYPES.FETCH_NAME_LIST_SUCCESS,
        ACTION_TYPES.FETCH_NAME_LIST_FAILURE
      ],
      data
    }
  };
};
export const codeList = (data) => {
  return {
    url: API_URL.ULB.FILTER_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.FETCH_CODE_LIST_REQUEST,
        ACTION_TYPES.FETCH_CODE_LIST_SUCCESS,
        ACTION_TYPES.FETCH_CODE_LIST_FAILURE
      ],
      data
    }
  };
};
