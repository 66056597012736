export const HTTP_CONSTANTS = {

  HTTP_HEADERS: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
};


export const REQUEST_METHOD = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
  PATCH: "PATCH",
  DELETE: "DELETE",
  FILE: "FILE"
};

export const STORAGE_KEYS = {
  ACCESS_TOKEN: "ACCESS_TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN"
};

export const PROJECT_PROPS = {
  BRAND: {
    LOGO: "",
    NAME: "KSWMP-MIS - Trois"
  }
};

export const EMPTY_SELECT = { id: "", name: "SELECT" };

export const REACT_TABLE_COMMON_OPTIONS = {
  enableRowActions: true,
  enableRowSelection: true,
  getCanMultiSelect: true,
  enableSelectAll: false,
  enablePagination: false,
  enableGlobalFilter: false,
  enableColumnActions: false,
  enableDensityToggle: false,
  enableHiding: false,
  enableColumnFilterModes: false,
  enableStickyHeader: true,
  positionToolbarAlertBanner: "top",
  enableTopToolbar: false

};

export const SHOW_EXAMPLE_MODULE = false;

export const SHOW_IN_PROGRESS_MODULE = false;

export const ROWS_PER_PAGE = [10, 15, 20];

export const TABLE_IDS = {
  COMPLAINT_HISTORY: "COMPLAINT_HISTORY",
  COMPLAINT_TYPE: "COMPLAINT_TYPE",
  USER_MAPPING: "USER_ROLE_MAPPING",
  USER_MAPPING_UNASSIGN: "USER_ROLE_MAPPING_ASSIGN",
  USER_DATA_ACCESS: "USER_ROLE_DATA_ACCESS",
  OFFICERS_LIST: "OFFICERS_LIST",
  REGISTERED_USERS: "REGISTERED_USERS",
  TRAINING_NEED: "TRAINING_NEED",
  TRAINING_PLAN: "TRAINING_PLAN",
  TRAINING_COURSE: "TRAINING_COURSE",
  TRAINING_BATCH: "TRAINING_BATCH",
  COMPLAINT_REPORT: "COMPLAINT_REPORT",
  TRAINING_VENUE: "TRAINING_VENUE",
  VIEW_BATCH: "VIEW_BATCH",
  ADD_BATCH: "ADD_BATCH",
  TRAINING_SCHEDULE: "TRAINING_SCHEDULE",
  TRAINING_CERTIFICATE: "TRAINING_CERTIFICATE",
  CERTIFICATE_ISSUE: "CERTIFICATE_ISSUE",
  GRIEVANCE_LISTING: "GRIEVANCE_LISTING",
  ACTIVITY_TABLE: "ACTIVITY_TABLE",
  ULB_POPULATION_LIST: "ULB_POPULATION_LIST",
  GAC_COMPUTATION_LIST: "GAC_COMPUTATION_LIST",
  DESIGNATION_ENTRY: "DESIGNATION_ENTRY",
  TECHNICAL_TABLE: "TECHNICAL_TABLE",
  PROCUREMENT_ACTIVITIES: "PROCUREMENT_ACTIVITIES",
  PROJECT_LIST: "PROJECT_LIST",
  FIVE_YEAR_LIST: "FIVE_YEAR_LIST",
  SWM_PLAN_LIST: "SWM_PLAN_LIST",
  ANNUAL_PLAN_LIST: "ANNUAL_PLAN_LIST",
  PROJECT_ACTIVITY_LIST: "PROJECT_ACTIVITY_LIST",
  GAP_ANALYSIS_PROFILE_OF_LOCALBODY: "GAP_ANALYSIS_PROFILE_OF_LOCALBODY",
  GAP_ANALYSIS_PAYMENT_TO_STAFF_AND_EXPENSES: "GAP_ANALYSIS_PAYMENT_TO_STAFF_AND_EXPENSES",
  ESCALATION_REPORT: "ESCALATION_REPORT",
  PENDING_REPORT: "PENDING_REPORT",
  REOPENED_COMPLAINTS: "REOPENED_COMPLAINTS",
  TOP_GRIEVANCE_CATEGORY: "TOP_GRIEVANCE_CATEGORY",
  TRAINING_PARTICIPANTS: "TRAINING_PARTICIPANTS",
  TRAINING_PARTICIPANTS_ANALYSIS: "TRAINING_PARTICIPANTS_ANALYSIS",
  TRAINING_SCHEDULE_REPORT_LIST: "TRAINING_SCHEDULE_REPORT_LIST",
  LIST_OF_INCOME_FROM_WASTE: "LIST_OF_INCOME_FROM_WASTE",
  POPULATION_VS_LAND_AREA: "POPULATION_VS_LAND_AREA",
  POPULATION_VS_HOUSE_HOLD: "POPULATION_VS_HOUSE_HOLD",
  LAND_USE_REPORTS: "LAND_USE_REPORTS",
  NBDW_EQUIPMENTS_REPORT: "NBDW_EQUIPMENTS_REPORT",
  BDW_EQUIPMENTS_REPORT: "BDW_EQUIPMENTS_REPORT",
  NBDW_GENERATED_VS_CAPACITY: "NBDW_GENERATED_VS_CAPACITY",
  BDW_GENERATED_VS_CAPACITY: "BDW_GENERATED_VS_CAPACITY",
  NBDW_FACILITIES_REPORT: "NBDW_FACILITIES_REPORT",
  BDW_FACILITIES_REPORT: "BDW_FACILITIES_REPORT",
  CAPITAL_COST_IN_NBDW_REPORT: "CAPITAL_COST_IN_NBDW_REPORT",
  CAPITAL_COST_IN_BDW_REPORT: "CAPITAL_COST_IN_BDW_REPORT",
  FORWARD_LINKING_REPORT: "FORWARD_LINKING_REPORT",
  INCOME_FROM_BDW_AND_NBDW_REPORT: "INCOME_FROM_BDW_AND_NBDW_REPORT",
  EXPENDITURE_REPORT: "EXPENDITURE_REPORT",
  INCOME_OF_HKS_FROM_BDW_AND_NBDW: "INCOME_OF_HKS_FROM_BDW_AND_NBDW",
  BASE_LINE_TABLE: "BASE_LINE_TABLE",
  VIEW_COMMENT: "VIEW_COMMENT",
  DPR_OR_FR_LIST: "DPR_OR_FR_LIST",
  TRAINING_CONDUCTED_REPORT: "TRAINING_CONDUCTED_REPORT",
  TRAINING_SUMMARY: "TRAINING_SUMMARY",
  WARD_WISE_POPULATION_TABLE: "WARD_WISE_POPULATION_TABLE",
  FETCH_SANKHYA_BILLS: "FETCH_SANKHYA_BILLS",
  RECEIPT_LIST: "RECEIPT_LIST",
  LABOUR_PROJECT_LIST: "LABOUR_PROJECT_LIST",
  PROJECT_SITE_LIST: "PROJECT_SITE_LIST",
  EMPLOYEE_LIST: "EMPLOYEE_LIST",
  GRIEVANCE_REDRESSAL_COMMITTEE_LIST: "GRIEVANCE_REDRESSAL_COMMITTEE_LIST",
  INTERNAL_COMPLAINT_COMMITTEE_LIST: "INTERNAL_COMPLAINT_COMMITTEE_LIST",
  PRIMARY_COLLECTION_LIST: "PRIMARY_COLLECTION_LIST",
  SECONDARY_COLLECTION_LIST: "SECONDARY_COLLECTION_LIST",
  WASTE_PROCESSING_SITES_LIST: "WASTE_PROCESSING_SITES_LIST",
  MCF_RRF_DETAILS: "MCF_RRF_DETAILS",
  DISPOSAL_SITE_CITY: "DISPOSAL_SITE_CITY",
  REGIONAL_DISPOSAL_SITE: "REGIONAL_DISPOSAL_SITE",
  TREATMENT_AT_SOURCE_LIST: "TREATMENT_AT_SOURCE_LIST",
  PRIMARY_COLLECTION_BDW: "PRIMARY_COLLECTION_BDW",
  FETCH_TOTAL_EXPENSES: "FETCH_TOTAL_EXPENSES",
  WSS_INFO_LIST: "WSS_INFO_LIST",
  SECONDARY_TRANSPORTATION: "SECONDARY_TRANSPORTATION",
  MCF_RRF_DETAILS_LIST: "MCF_RRF_DETAILS_LIST",
  WASTE_PROCESSING_PLANT_DETAILS: "WASTE_PROCESSING_PLANT_DETAILS",
  CITY_LEVEL_DISPOSAL_CELL: "CITY_LEVEL_DISPOSAL_CELL",
  WASTE_DISPOSAL_REGIONAL_LIST: "WASTE_DISPOSAL_REGIONAL_LIST",
  TRANSFER_STATION: "TRANSFER_STATION",
  WARD_DETAILS: "WARD_DETAILS",
  WAGE_LIST: "WAGE_LIST",
  TERMINATION_LIST: "TERMINATION_LIST",
  WAGE_REGISTER_LIST: "WAGE_REGISTER_LIST",
  WAGE_REGISTER_EMPLOYEE: "WAGE_REGISTER_EMPLOYEE",
  WORKPLACE_ACCIDENT: "WORKPLACE_ACCIDENT",
  HARASSMENT_COMPLAINTS: "HARASSMENT_COMPLAINTS",
  RECOVERY_REGISTER_LIST: "RECOVERY_REGISTER_LIST",
  MATERNITY_LEAVE_LIST: "MATERNITY_LEAVE_LIST",
  ANNUAL_PLAN_REPORT: "ANNUAL_PLAN_REPORT",
  COST_INTERVENTION_REPORT: "COST_INTERVENTION_REPORT",
  STATUS_SUMMARY_LIST: "STATUS_SUMMARY_LIST",
  COST_INTERVENTION_YEAR_WISE: "COST_INTERVENTION_YEAR_WISE",
  FACILITY_LOCATION: "FACILITY_LOCATION",
  DETAILS_OF_TEAM: "DETAILS_OF_TEAM",
  TEAM_COMPOSITION_REPORT: "TEAM_COMPOSITION_REPORT",
  GAP_ANALYSIS_WASTE_GENERATION: "GAP_ANALYSIS_WASTE_GENERATION",
  LIST_OF_SUB_PROJECT: "LIST_OF_SUB_PROJECT"
};

export const imageURL = `${process.env.REACT_APP_API_URL}/resource/api/auth/multimedia/download?id=`;

export const USER_ROLE_NAME = {
  SPMU_USER_ROLE: "SPMU",
  TSC_USER_ROLE: "TSC",
  SWM_STAFF_USER: "SWM STAFF",
  DYDC_USER_ROLE: "DYDC",
  DPMU_USER_ROLE: "DPMU",
  CONTRACTOR: "CONTRACTOR",
  SPMU_SOCIAL_EXPERT: "SPMU SOCIAL EXPERT",
  SPMU_ENVIRONMENTAL_EXPERT: "SPMU ENVIRONMENTAL EXPERT",
  SPMU_FINANCIAL_EXPERT: "SPMU FINANCIAL EXPERT",
  SPMU_TECHNICAL_EXPERT: "SPMU TECHNICAL EXPERT",
  SPMC_SOCIAL_EXPERT: "SPMC SOCIAL EXPERT",
  SPMC_ENVIRONMENTAL_EXPERT: "SPMC ENVIRONMENTAL EXPERT",
  SPMC_FINANCIAL_EXPERT: "SPMC FINANCIAL EXPERT",
  SPMC_TECHNICAL_EXPERT: "SPMC TECHNICAL EXPERT",
  DPMU_SOCIAL_EXPERT: "DPMU SOCIAL EXPERT",
  DPMU_ENVIRONMENTAL_EXPERT: "DPMU ENVIRONMENTAL EXPERT",
  DPMU_FINANCIAL_EXPERT: "DPMU FINANCIAL EXPERT",
  DPMU_TECHNICAL_EXPERT: "DPMU TECHNICAL EXPERT",
  DPMC_SOCIAL_EXPERT: "DPMC SOCIAL EXPERT",
  DPMC_ENVIRONMENTAL_EXPERT: "DPMC ENVIRONMENTAL EXPERT",
  DPMC_FINANCIAL_EXPERT: "DPMC FINANCIAL EXPERT",
  DPMC_TECHNICAL_EXPERT: "DPMC TECHNICAL EXPERT"
};

export const DPR_STATUS = {
  GENERATE_FS: "GENERATED FS",
  GENERATE_DPR_OR_FR_ID: "GENERATED DPR/FR ID",
  SUB_PROJECT_MAPPED: "SUB PROJECTS MAPPED",
  SUBMIT_TO_DYDC: "SUBMITTED TO DYDC",
  FORWARD_TO_STATE_LEVEL: "FORWARD TO STATE LEVEL",
  SUBMIT_TO_DISTRICT_LEVEL: "SUBMITTED TO DISTRICT LEVEL",
  SUBMIT_TO_PIU_FOR_MC: "SUBMITTED TO PIU FOR MC",
  UPLOADED_DPR_OR_FR: "UPLOADED DPR/FR",
  RETURN_TO_TSC: "RETURNED TO TSC",
  SEND_MC_APPROVED_DPR_TO_DYDC: "SEND MC APPROVED DPR TO DYDC"
};
export const disableBreadCrumbPath = [
  "/admin/users/edit-profile",
  "/admin/users/view-profile",
  "/sub-projects/reports",
  "/sub-projects",
  "/sub-projects/sub-projects-list/annual",
  "/grant-management",
  "/sub-projects/plans",
  "/operational-module",
  "/operational-module/master-info",
  "/operational-module/operational-data",
  "/sub-projects/project-preparation",
  "/admin/organization-setup",
  "/admin",
  "/admin/general-setup",
  "/admin/general-setup/lookup-configuration",
  "/admin/role-management",
  "/admin/external-organization",
  "/admin/miscellaneous",
  "/grievance",
  "/grievance/reports",
  "/grant-management/utilization",
  "/grant-management/allocation",
  "/tcm",
  "/tcm/reports"
];
