import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import { getPayloadContent, getPayloadContentDetails } from "../../../utils/apiUtils";
import { COMMON_TABLE_PAGINATION } from "modules/common/constants";

const initialState = {
    tablePagination: { ...COMMON_TABLE_PAGINATION },
    scheduleForm: {
        requestInProgress: false,
        data: {
            batchId: ""
        }
    },
    attendanceForm: {
        requestInProgress: false,
        data: {
        }
    },
    filterScheduleBatch: {
        requestInProgress: false,
        data: []
    },

    trainerId: [],
    courseId: [],
    sessionId: [],
    sessionType: [],
    trainingOrganizationId: [],
    questionareId: [],
    batchId: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.table = initialState.table;
            state.filterScheduleBatch = initialState.filterScheduleBatch;
        },
        clearBatchId: (state) => {
            state.batchId = initialState.batchId;
        },
        setRowSelectionData: (state, action) => {
            state.scheduleForm.data = action.payload;
        },
        setBatchId: (state, action) => {
            state.batchId = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.SAVE_SCHEDULE_FORM, (state) => {
            _.set(state, "scheduleForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.SAVE_SCHEDULE_FORM_SUCCESS, (state, action) => {
            _.set(state, "scheduleForm.data", action.payload);
            _.set(state, "scheduleForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.LIST_SCHEDULE_DETAILS_REQUEST, (state) => {
            _.set(state, "scheduleForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.LIST_SCHEDULE_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "scheduleForm.data", getPayloadContent(action));
            _.set(state, "scheduleForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.FETCH_BATCH_ID_SUCCESS, (state, { payload }) => {
            _.set(state, "batchId.requestInProgress", false);
            _.set(state, "batchId.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FETCH_COURSE_ID_SUCCESS, (state, action) => {
            let targetGroupList = getPayloadContentDetails(action).targetGroupList;
            let response = [];
            _.forEach(targetGroupList, item => {
                if (item && item.targetGroupId && item.targetGroupId.course) {
                    response.push(item.targetGroupId.course);
                }
            });
            _.set(state, "courseId", response);
        });
        builder.addCase(ACTION_TYPES.FETCH_SESSION_ID_SUCCESS, (state, action) => {
            _.set(state, "sessionId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_SESSION_TYPE_SUCCESS, (state, action) => {
            _.set(state, "sessionType", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_QUESTION_ID_SUCCESS, (state, action) => {
            _.set(state, "questionareId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_TRAINING_ORGANIZATION_SUCCESS, (state, action) => {
            _.set(state, "trainingOrganizationId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_TRAINER_ID_SUCCESS, (state, action) => {
            _.set(state, "trainerId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.EDIT_SCHEDULE_FORM_BY_ID_SUCCESS, (state, action) => {
            _.set(state, "scheduleForm.data", getPayloadContentDetails(action));
        });
        builder.addCase(ACTION_TYPES.DELETE_SCHEDULE_FORM_SUCCESS, (state, action) => {
            _.set(state, "scheduleForm.data", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.EDIT_BATCH_FORM_BY_ID_SUCCESS, (state, action) => {
            _.set(state, "scheduleForm.data", getPayloadContentDetails(action));
        });
        builder.addCase(ACTION_TYPES.CONFIRM_ATTENDANCE_REQUEST, (state) => {
            _.set(state, "scheduleForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.CONFIRM_ATTENDANCE_SUCCESS, (state) => {
            _.set(state, "scheduleForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "scheduleForm.data", getPayloadContent(action));
            _.set(state, "scheduleForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.ACTIVATE_CHECK_IN_SUCCESS, (state, action) => {
            _.set(state, "scheduleForm.requestInProgress", false);
            _.set(state, "scheduleForm.data", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.ACTIVATE_CHECK_IN_REQUEST, (state) => {
            _.set(state, "scheduleForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.ACTIVATE_CHECK_OUT_SUCCESS, (state, action) => {
            _.set(state, "scheduleForm.requestInProgress", false);
            _.set(state, "scheduleForm.data", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.ACTIVATE_CHECK_OUT_REQUEST, (state) => {
            _.set(state, "scheduleForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.FILTER_SCHEDULE_BATCH_SUCCESS, (state, { payload }) => {
            _.set(state, "filterScheduleBatch.requestInProgress", false);
            _.set(state, "filterScheduleBatch.data", payload.content);
        });
    }
});
export const { actions, reducer } = slice;
