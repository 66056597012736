export const STATE_REDUCER_KEY = "subproject/reports";

export const CURRENT_STATUS_REPORT = {
    POPULATION_VS_LAND_AREA: "POPULATION_VS_LAND_AREA",
    POPULATION_VS_HOUSE_HOLD: "POPULATION_VS_HOUSE_HOLD",
    LAND_USE: "LAND_USE",
    NBDW_EQUIPMENTS: "NBDW_EQUIPMENTS",
    BDW_EQUIPMENTS: "BDW_EQUIPMENTS",
    NBDW_GENERATED_AND_CAPACITY: "NBDW_GENERATED_AND_CAPACITY",
    BDW_GENERATED_AND_CAPACITY: "BDW_GENERATED_AND_CAPACITY",
    NBDW_FACILITY: "NBDW_FACILITY",
    BDW_FACILITY: "BDW_FACILITY",
    CAPITAL_COST_IN_NBDW: "CAPITAL_COST_IN_NBDW",
    CAPITAL_COST_IN_BDW: "CAPITAL_COST_IN_BDW",
    FORWARD_LINKING: "FORWARD_LINKING",
    INCOME_FROM_BDW_AND_NBDW: "INCOME_FROM_BDW_AND_NBDW",
    EXPENDITURE: "EXPENDITURE",
    INCOME_FOR_HKS_FROM_NBDW_AND_BDW: "INCOME_FOR_HKS_FROM_NBDW_AND_BDW"
};

export const PLAN_TYPE = {
    APPROVED_PLANS: "APPROVED_PLANS",
    STATUS_SUMMARY: "STATUS_SUMMARY",
    COST_INTERVENTION: "COST_INTERVENTION",
    COST_INTERVENTION_YEAR_WISE: "COST_INTERVENTION_YEAR_WISE",
    TEAM_COMPOSITION: "TEAM_COMPOSITION",
    DETAILS_OF_TEAM: "DETAILS_OF_TEAM",
    GAP_ANALYSIS_OF_WASTE_GENERATION: "GAP_ANALYSIS_OF_WASTE_GENERATION"
};
