import { I18n } from "common/components";
import { TABLE_IDS } from "common/constants";
import { navigateTo } from "modules/common/actions";
import { getTableProps } from "modules/common/selectors";
import { MODULE_PATH } from "modules/paths";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { toEpoch } from "utils/dateUtils";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";

export function* fetchDisposalSiteCityDetails({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.DISPOSAL_SITE_CITY]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchDisposalSiteCityDetailsApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS, ACTION_TYPES.FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.DISPOSAL_SITE_CITY);
}

export function* disposalSiteCityById({ payload }) {
    yield call(handleAPIRequest, Api.disposalSiteCityApi, payload);
}

const formatData = (payload) => {
    const { dateOfCollection, ...rest } = payload;
    const data = { dateOfCollection: toEpoch(dateOfCollection), ...rest };
    return data;
};

export function* submitDisposalSiteCityDetails(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.patchDisposalSiteCityDetailsApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS, ACTION_TYPES.PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("city_level_disposal_site") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.MASTER_INFO}/${PATH.DISPOSAL_CITY_LEVEL.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, Api.postDisposalSiteCityDetailsApi, formatData(rest));
        const responseAction = yield take([ACTION_TYPES.POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS, ACTION_TYPES.POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("city_level_disposal_site") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.MASTER_INFO}/${PATH.DISPOSAL_CITY_LEVEL.LIST}`));
        }
    }
}

export function* deleteDisposalSiteCityDetails({ payload }) {
    yield fork(handleAPIRequest, Api.deleteDisposalSiteCityDetailsApi, payload);
    const action = yield take([ACTION_TYPES.DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_SUCCESS, ACTION_TYPES.DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("deleted_successfully")) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.DISPOSAL_SITE_CITY]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchDisposalSiteCityDetailsApi, { pageNo: 0, pageSize: 5, ...filters, ...payload });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS, ACTION_TYPES.FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.DISPOSAL_SITE_CITY);
    }
}
export function* natureOfArea({ payload }) {
    yield call(handleAPIRequest, Api.natureOfAreaApi, payload);
}

export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS, fetchDisposalSiteCityDetails),
        takeLatest(ACTION_TYPES.GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID, disposalSiteCityById),
        takeLatest(ACTION_TYPES.POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS, submitDisposalSiteCityDetails),
        takeLatest(ACTION_TYPES.PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS, submitDisposalSiteCityDetails),
        takeLatest(ACTION_TYPES.DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS, deleteDisposalSiteCityDetails),
        takeLatest(ACTION_TYPES.GET_NATURE_OF_AREA_DROPDOWN, natureOfArea)
    ]);
}
