export const GRIEVANCE_PATH = {
    COMPLAINT: {
        LIST: "complaints",
        DETAILS: "complaint-details",
        LODGING: "complaint-lodging",
        LIST_MY_APPROVAL: "my-approvals",
        VIEW_MY_APPROVAL: "my-approvals/:id/view",
        GRIEVANCE_SUMMARY: "reports/grievance-summary",
        GRIEVANCE_LISTING: "reports/grievance-listing",
        GRIEVANCE_SUMMARY_ULB: "grievance-summary-ulb",
        GRIEVANCE_SUMMARY_WARD: "grievance-summary-ward",
        ESCALATION_REPORT: "reports/escalation-report",
        PENDING_REPORT: "reports/pending-report",
        REOPENED_COMPLAINTS: "reports/reopened-complaints",
        TOP_GRIEVANCE_CATEGORY: "reports/top-grievance-category",
        USER_MANUAL: "user-manual",
        VIDEO_TUTORIAL: "video-tutorial"

    },
    REGISTER: {
        CREATE: {
            USER: "register-details/create/user",
            OFFICIAL: "register-details/create/official",
            TRAINING_ORG: "register-details/create/training"
        },
        LIST: "register-details",
        EDIT: "register-details/:id",
        DETAILS: "register-details/:id/view"
    }
};
