import { HomeOutlined } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { MODULE_PATH } from "modules/paths";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { I18n } from "..";
import Button from "./Button";

const DATA = {
    STATUS: "something_went_wrong",
    TITLE: "Oops!"
};

const ROOT_PATH = "/";

const generateRoute = (root, path = "") => {
    let existingModule = false;
    for (const key in MODULE_PATH) {
        if (MODULE_PATH[key] === path) {
            existingModule = true;
        }
    }
    return existingModule ? `${root}${path}` : root;
};


const ErrorPage = (props) => {
    const navigate = useNavigate();
    const [details, setDetails] = useState(false);
    const { pathname } = useLocation();
    let path = pathname.split("/")[1];

    let { error: { status, message, statusText } = {}, image, title = DATA.TITLE } = props;
    return (
        <Grid sx={{ display: "flex", minHeight: "600px", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            {image && <img src={image} alt='' height={"300"} width={"60%"} />}
            <Grid sx={{
                textAlign: "center", fontSize: "8rem", letterSpacing: "0.5rem"
            }}>
                {title}
            </Grid>
            <Typography sx={{
                letterSpacing: "0.4rem", fontSize: "1.8rem", color: "grey", textAlign: "center"
            }}>
                {status || I18n(DATA.STATUS)}
            </Typography>
            {((message || statusText) && !status) && <Button variant="outlined" onClick={() => setDetails(!details)}>{details ? I18n("hide") : I18n("show")} {I18n("details")}</Button>}
            {((message || statusText) && details) && < Typography sx={{
                letterSpacing: "0.2rem", fontSize: "0.8rem", color: "grey", textAlign: "center"
            }}>
                {message || statusText}
            </Typography>}
            {(status && !details) && < Typography sx={{
                letterSpacing: "0.2rem", fontSize: "0.8rem", color: "grey", textAlign: "center"
            }}>
                {message || statusText}
            </Typography>}
            <Grid item xs={12} sx={{ bottom: "100px", position: "absolute" }}>
                <Grid sx={{ textAlign: "center" }}>
                    <Typography sx={{
                        fontSize: "1.6rem", letterSpacing: "0.4rem"
                    }}>{I18n("lets_go")}
                        <IconButton aria-label="home" size="large" onClick={() => navigate(generateRoute(ROOT_PATH, path))} color="primary">
                            <HomeOutlined fontSize="inherit" />
                        </IconButton>
                        {I18n("try_again")}
                    </Typography>
                </Grid>
            </Grid>
        </Grid >
    );
};

export default ErrorPage;
