import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { I18n } from "common/components";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "modules/paths";
import { toEpoch } from "utils/dateUtils";
import { successNotify } from "utils/notificationUtils";
import { setCommonTableData } from "utils/tableUtils";
import { handleAPIRequest } from "../../../utils/http";
import { SUB_PROJECTS_PATH } from "../paths";
import { ACTION_TYPES } from "./actions";
import { deleteSubProjectListApi, fetchCategoryTypeApi, fetchEnvCategoryTypeApi, fetchProjectApplicableApi, fetchProjectTypeApi, fetchSubCategoryTwoTypeApi, fetchSubCategoryTypeApi, fetchSubProjectListApi, fetchSubProjectListByIdApi, fetchTrackListApi, postSubProjectApi, updateSubProjectApi } from "./api";
import _ from "lodash";

export function* fetchTrackList(action) {
  yield call(handleAPIRequest, fetchTrackListApi, action.payload);
}

export function* fetchCategoryList(action) {
  yield call(handleAPIRequest, fetchCategoryTypeApi, action.payload);
}

export function* fetchEnvCategoryList(action) {
  yield call(handleAPIRequest, fetchEnvCategoryTypeApi, action.payload);
}

export function* fetchSubCategoryList(action) {
  yield call(handleAPIRequest, fetchSubCategoryTypeApi, action.payload);
}

export function* fetchSubCategoryTwoList(action) {
  yield call(handleAPIRequest, fetchSubCategoryTwoTypeApi, action?.payload);
}

export function* fetchProjectList(action) {
  yield call(handleAPIRequest, fetchProjectTypeApi, action.payload);
}

export function* fetchProjectApplicable(action) {
  yield call(handleAPIRequest, fetchProjectApplicableApi, action.payload);
}

export function* fetchSubProjectList({ payload }) {
  let updatedPayload = { pageNo: payload.pageNo, pageSize: payload.pageSize };
  yield fork(handleAPIRequest, fetchSubProjectListApi, updatedPayload, payload.id);
  const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SUB_PROJECT_LIST_SUCCESS, ACTION_TYPES.FETCH_SUB_PROJECT_LIST_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

export function* fetchSubProjectById({ payload: id }) {
  yield fork(handleAPIRequest, fetchSubProjectListByIdApi, id);
  const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SUB_PROJECT_LIST_BY_ID_SUCCESS, ACTION_TYPES.FETCH_SUB_PROJECT_LIST_BY_ID_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

export function* deleteSubProjectList({ payload: id }) {
  yield fork(handleAPIRequest, deleteSubProjectListApi, id);
  const response = yield take([ACTION_TYPES.DELETE_SUB_PROJECT_LIST_SUCCESS, ACTION_TYPES.DELETE_SUB_PROJECT_LIST_FAILURE]);
  if (response.type === ACTION_TYPES.DELETE_SUB_PROJECT_LIST_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("sub_project") }) }));
    yield call(fetchProjectList);
  }
}

const formatData = (rest) => {
  const { startDate, planId, endDate, categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId } = rest;
  const data = { planId, startDate: toEpoch(startDate), categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, endDate: toEpoch(endDate) };
  if (conceptNoteAvailable !== 1) {
    const newFiles = rest.attachmentPayload?.multimediaList.filter(item => !(item.id));
    if (newFiles && newFiles.length > 0) {
      let attachments = {};
      attachments.multimediaList = newFiles;
      data.attachmentPayload = attachments;
    }
  }
  return data;
};

export function* postSubProject(action) {
  let { payload: { ...rest } = {} } = action;
  yield fork(handleAPIRequest, postSubProjectApi, formatData(rest));
  const responseAction = yield take([ACTION_TYPES.POST_SUB_PROJECT_SUCCESS, ACTION_TYPES.POST_SUB_PROJECT_FAILURE]);
  if (responseAction.type === ACTION_TYPES.POST_SUB_PROJECT_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("sub_project") }) }));
    yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${SUB_PROJECTS_PATH.SUB_PROJECTS_LIST.LIST}/${rest.planId}`));
  }
}

export function* updateSubProject({ payload }) {
  let newPayload = _.omit(payload, "planId", "id");
  yield fork(handleAPIRequest, updateSubProjectApi, newPayload);
  const responseAction = yield take([ACTION_TYPES.UPDATE_SUB_PROJECT_SUCCESS, ACTION_TYPES.UPDATE_SUB_PROJECT_FAILURE]);
  if (responseAction.type === ACTION_TYPES.UPDATE_SUB_PROJECT_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("update", { type: I18n("sub_project") }) }));
    // yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${SUB_PROJECTS_PATH.SUB_PROJECTS_LIST.LIST}`));
  }
}

export default function* testSaga() {
  yield all([
    takeLatest(ACTION_TYPES.FETCH_SUB_PROJECT_LIST, fetchSubProjectList),
    takeLatest(ACTION_TYPES.FETCH_SUB_PROJECT_LIST_BY_ID, fetchSubProjectById),
    takeLatest(ACTION_TYPES.FETCH_TRACK_LIST, fetchTrackList),
    takeLatest(ACTION_TYPES.FETCH_CATEGORY_TYPES, fetchCategoryList),
    takeLatest(ACTION_TYPES.FETCH_ENV_CATEGORY_TYPES, fetchEnvCategoryList),
    takeLatest(ACTION_TYPES.FETCH_SUB_CATEGORY_TYPES, fetchSubCategoryList),
    takeLatest(ACTION_TYPES.FETCH_SUB_CATEGORY_TWO_TYPES, fetchSubCategoryTwoList),
    takeLatest(ACTION_TYPES.FETCH_PROJECT_TYPES, fetchProjectList),
    takeLatest(ACTION_TYPES.POST_SUB_PROJECT, postSubProject),
    takeLatest(ACTION_TYPES.UPDATE_SUB_PROJECT, updateSubProject),
    takeLatest(ACTION_TYPES.FETCH_PROJECT_APPLICABLE, fetchProjectApplicable),
    takeLatest(ACTION_TYPES.DELETE_SUB_PROJECT_LIST, deleteSubProjectList)
  ]);
}
