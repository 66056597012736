import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import { COMMON_TABLE_PAGINATION } from "modules/common/constants";
import { getPayloadContent, getPayloadContentDetails } from "../../../utils/apiUtils";

const initialState = {
    tablePagination: { ...COMMON_TABLE_PAGINATION },
    trainingBatchForm: {
        requestInProgress: false,
        data: {
            employees: []
        }
    },
    addBatchForm: {
        requestInProgress: false,
        data: {
        }
    },
    viewBatchForm: {
        requestInProgress: false,
        data: {
        }
    },
    nameList: {
        requestInProgress: false,
        data: []
    },
    filterBatchPlanId: {
        data: [],
        requestInProgress: false
    },
    filterConfirmation: {
        data: [],
        requestInProgress: false
    },
    designationId: {
        requestInProgress: false,
        data: []
    },
    trainingPlanId: {
        requestInProgress: false,
        data: []
    },
    ulbList: {
        requestInProgress: false,
        data: []
    },
    districtId: {
        requestInProgress: false,
        data: []
    },
    confirmation: {
        requestInProgress: false,
        data: []
    },
    targetGroupId: [],
    hostId: [],
    organizedBy: [],
    venueId: [],
    trainingMode: [],
    openModal: false,
    openAccess: false,
    assignedRoles: true,
    userId: 0,
    roleId: 0,
    batchId: 0,
    count: 0,
    trainingCordinatorId: [],
    thematicArea: []
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.table = initialState.table;
            state.nameList = initialState.nameList;
            state.batchId = initialState.batchId;
            state.filterConfirmation = initialState.filterConfirmation;
        },
        setOpen: (state, action) => {
            state.openModal = action.payload;
            state.openAccess = false;
        },
        clearPlanId: (state) => {
            state.trainingPlanId = initialState.trainingPlanId;
        },
        clearDesignationId: (state) => {
            state.designationId = initialState.designationId;
        },
        setBatchId: (state, action) => {
            state.batchId = action.payload;
        },
        setDesignation: (state, action) => {
            state.designationId = action.payload;
        },
        setConfirmation: (state, action) => {
            state.confirmation = action.payload;
        },
        setCount: (state) => {
            state.count = state.count + 1;
        },
        decrementCount: (state) => {
            state.count = state.count - 1;
        },
        setRowSelectionState: (state, action) => {
            state.trainingBatchForm.rowSelected = action.payload;
        },
        setRowSelectionData: (state, action) => {
            state.trainingBatchForm.data = action.payload;
            state.addBatchForm.data = action.payload;
            state.viewBatchForm.data = action.payload;
        },
        setUserNameJSON: (state, actions = {}) => {
            const { payload: { key, value } } = actions;
            _.set(state, `listJSONDropdownName.${key}`, value);
        },
        clearDistrict: (state) => {
            state.districtId = initialState.districtId;
        },
        clearLsgi: (state) => {
            state.ulbList = initialState.ulbList;
        },
        clearConfirmation: (state) => {
            state.confirmation = initialState.confirmation;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.SAVE_BATCH_FORM, (state) => {
            _.set(state, "trainingBatchForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.SAVE_BATCH_FORM_SUCCESS, (state, action) => {
            _.set(state, "trainingBatchForm.data", action.payload);
            _.set(state, "trainingBatchForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.LIST_BATCH_DETAILS_REQUEST, (state) => {
            _.set(state, "trainingBatchForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.LIST_BATCH_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "trainingBatchForm.data", getPayloadContent(action));
            _.set(state, "trainingBatchForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "trainingBatchForm.data", getPayloadContent(action));
            _.set(state, "trainingBatchForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.FETCH_CERTIFICATE_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "trainingBatchForm.data", getPayloadContent(action));
            _.set(state, "trainingBatchForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.FETCH_ADD_PARTICIPANT_SUCCESS, (state, action) => {
            _.set(state, "addBatchForm.data", getPayloadContent(action));
            _.set(state, "addBatchForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.FETCH_PLAN_ID_SUCCESS, (state, action) => {
            _.set(state, "trainingPlanId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_TARGET_GROUP_SUCCESS, (state, action) => {
            _.set(state, "targetGroupId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_VENUE_ID_SUCCESS, (state, action) => {
            _.set(state, "venueId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_ORGANIZED_BY_SUCCESS, (state, action) => {
            _.set(state, "organizedBy", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_TRAINING_MODE_SUCCESS, (state, action) => {
            _.set(state, "trainingMode", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.EDIT_BATCH_FORM_BY_ID_SUCCESS, (state, action) => {
            _.set(state, "trainingBatchForm.data", getPayloadContentDetails(action));
        });
        builder.addCase(ACTION_TYPES.DELETE_BATCH_FORM_SUCCESS, (state, action) => {
            _.set(state, "trainingBatchForm.data", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.CONFIRM_PARTICIPANT_BY_ID_REQUEST, (state) => {
            _.set(state, "trainingBatchForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.CONFIRM_PARTICIPANT_BY_ID_SUCCESS, (state) => {
            _.set(state, "trainingBatchForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.CERTIFICATE_ISSUE_SUCCESS, (state) => {
            _.set(state, "trainingBatchForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.ADD_PARTICIPANT_BY_ID_REQUEST, (state) => {
            _.set(state, "addBatchForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.ADD_PARTICIPANT_BY_ID_SUCCESS, (state) => {
            _.set(state, "addBatchForm.requestInProgress", true);
        });

        builder.addCase(ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "nameList.requestInProgress", false);
            _.set(state, "nameList.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FETCH_DESIGNATION_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "designationId.requestInProgress", false);
            _.set(state, "designationId.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FILTER_BATCH_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, ".requestInProgress", false);
            _.set(state, "filterBatchPlanId.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FILTER_CONFIRMATION_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "filterConfirmation.requestInProgress", false);
            _.set(state, "filterConfirmation.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FETCH_TRAINING_CORDINATOR_ID_SUCCESS, (state, action) => {
            _.set(state, "trainingCordinatorId", getPayloadContent(action));
        });
        builder
            .addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "districtId.requestInProgress", false);
                _.set(state, "districtId.data", payload.content);
            });
        builder
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "ulbList.requestInProgress", false);
                _.set(state, "ulbList.data", payload.content);
            });
        builder.addCase(ACTION_TYPES.FETCH_CONFIRMATION_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "confirmation.requestInProgress", false);
            _.set(state, "confirmation.data", payload.content);
        });
                builder.addCase(ACTION_TYPES.FETCH_THEMATIC_AREA_DROPDOWN_SUCCESS, (state, action) => {
            _.set(state, "thematicArea", getPayloadContent(action));
        });
    }
});
export const { actions, reducer } = slice;
