import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import DashboardLayout from "layouts/DashboardLayout";
import { actions as commonActions } from "./modules/user/slice";
import "./App.css";
import { useDispatch } from "react-redux";
import Breadcrumb from "common/components/custom/BreadCrumb";


function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.setNavigator(navigate));
  }, []);

  return (
    <Typography className="app" component="div">
      <DashboardLayout >
        <Breadcrumb />
        <Outlet />
      </DashboardLayout >
    </Typography>
  );
}

export default App;
