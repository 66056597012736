import { Typography } from "@mui/material";
import { Components, FormController, I18n, Icons } from "common/components";
import { TABLE_IDS } from "common/constants";
import { Form, withFormik } from "formik";
import { getSearchFilters } from "modules/common/selectors";
import { actions as commonActions } from "modules/common/slice";
import { actions as sliceActions } from "./slice";
import { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { toEpoch } from "utils/dateUtils";
import { getTablePagination } from "utils/tableUtils";
import _ from "lodash";
import { sortByKeys } from "utils/commonUtils";
import { listTrainingParticipantsList, trainingParticipantsBatchNameFilterList, trainingParticipantsFilterList } from "./actions";
import { fetchLsgiDropdown } from "modules/subProjects/swmPlan/actions";
import { getLsgiSelectDropdownOption } from "modules/subProjects/swmPlan/selector";
import { setDesignation, setGender } from "modules/grievance/user/actions";
import { getDesignationList, getGender } from "modules/grievance/user/selectors";
import { getBatchNameLists, getNameLists } from "./selector";

const { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, CustomCardHeader } = Components;
const { Search, RestartAlt, Close } = Icons;

const TcmParticipantsFilter = (props) => {
    const { handleSubmit, onClose, setFieldValue, values, getDropdownFilterList, getDropdownBatchNameFilterList, batchNameFilter, FilterName } = props;
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [cancel, setCancel] = useState(true);
    let endDate = "";
    let startDate = "";

    const handleSearch = () => {
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_PARTICIPANTS);
        if ((start !== "" && start !== null && end === "")) {
            setError("End date required");
        } else if (start && end !== "") {
            dispatch(listTrainingParticipantsList({ pageNo: 0, pageSize }));
            dispatch(commonActions.setSearchFilter(props.values));
            setError("");
            onClose();
        } else {
            dispatch(listTrainingParticipantsList({ pageNo: 0, pageSize }));
            dispatch(commonActions.setSearchFilter(props.values));
            setError("");
            onClose();
        }
    };
    const handleReset = () => {
        dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, reset: true }));
        setFieldValue("trainingName", "");
        setFieldValue("batchName", "");
        setFieldValue("lsgiId", "");
        setFieldValue("startDate", null);
        setFieldValue("endDate", null);
        setFieldValue("gender", "");
        setFieldValue("designation", "");

        setStart("");
        setEnd("");
        dispatch(sliceActions.clearAll());

    };

    const handleStartDate = (val) => {
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_PARTICIPANTS);
        startDate = toEpoch(val);
        setStart(startDate);
        if (startDate?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filterKey: "startDate", clearKey: true }));
            dispatch(listTrainingParticipantsList({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filters: { startDate: startDate } }));
        }
    };

    const handleEndDate = (val) => {
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_PARTICIPANTS);
        endDate = toEpoch(val);
        setEnd(endDate);
        if (endDate?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filterKey: "endDate", clearKey: true }));
            dispatch(listTrainingParticipantsList({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filters: { endDate: endDate } }));
        }
    };

    const handleName = (val) => {
        let filterName = val?.map(i => i.id);
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_PARTICIPANTS);
        if (filterName?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filterKey: "trainingName", clearKey: true }));
            dispatch(listTrainingParticipantsList({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filters: { trainingName: filterName } }));
        }
    };

    const handleBatchName = (val) => {
        let filterName = val?.map(i => i.id);
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_PARTICIPANTS);
        if (filterName?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filterKey: "batchName", clearKey: true }));
            dispatch(listTrainingParticipantsList({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filters: { batchName: filterName } }));
        }
    };

    const handleUlb = (val) => {
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_PARTICIPANTS);
        if (val?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filterKey: "lsgiId", clearKey: true }));
            dispatch(listTrainingParticipantsList({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filters: { lsgiId: val?.id } }));
        }
    };

    const handleGender = (val) => {
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_PARTICIPANTS);
        if (val?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filterKey: "gender", clearKey: true }));
            dispatch(listTrainingParticipantsList({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filters: { gender: val?.id } }));
        }
    };

    const handleDesignation = (val) => {
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_PARTICIPANTS);
        if (val?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filterKey: "designation", clearKey: true }));
            dispatch(listTrainingParticipantsList({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_PARTICIPANTS, filters: { designation: val?.id } }));
        }
    };


    const handleInputChange = (e, key) => {
        if (key === "trainingName") {
            const value = e.trim();
            getDropdownFilterList({ key, value });
        } else {
            const value = e.trim();
            getDropdownBatchNameFilterList({ key, value });
        }
    };

    useEffect(() => {
        dispatch(fetchLsgiDropdown());
        dispatch(setGender());
        dispatch(setDesignation({ pageSize: 200 }));
    }, []);

    useEffect(() => {
        setCancel(_.isEqual(sortByKeys(values), sortByKeys(props.reportFilter)));
    }, [values]);

    return (
        <>
            <Card sx={{ overflow: "visible", maxHeight: "90vh" }}>
                <CardHeader sx={{ borderRadius: "10px 10px 0 0", mb: 2 }} title={<CustomCardHeader title={I18n("filter")} />} />
                <CardContent sx={{ ml: 2, mr: 2 }}>
                    <Form onSubmit={handleSubmit} >
                        <Grid container spacing={1} >
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="date" mode="Date" onChangeFromController={handleStartDate} label={I18n("start_date")} name="startDate" maxDate={props?.values?.endDate} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="date" mode="Date" label={I18n("end_date")} onChangeFromController={handleEndDate} name="endDate" minDate={props?.values?.startDate} />
                                {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="select" label={I18n("training_name")} name="trainingName" onChangeFromController={handleName} onInputChange={(e) => handleInputChange(e, "trainingName")} options={FilterName || []} multiple />
                            </Grid >
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="select" label={I18n("batch_name")} name="batchName" onChangeFromController={handleBatchName} onInputChange={(e) => handleInputChange(e, "batchName")} options={batchNameFilter || []} multiple />
                            </Grid >
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control='select' label={I18n("ulb")} name='lsgiId' options={props.ulbList || []} onChangeFromController={handleUlb} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control='select' label={I18n("gender")} name='gender' options={props.genderLists || []} onChangeFromController={handleGender} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control='select' label={I18n("designation")} name='designation' options={props.designationList || []} onChangeFromController={handleDesignation} />
                            </Grid>
                        </Grid>
                    </Form>
                </CardContent>
                <Divider />
                <CardActions>
                    <Grid item xs={12} container justifyContent="center">
                        <Grid> <Button onClick={() => handleSearch()} startIcon={<Search />} variant="contained">{I18n("search")}</Button> </Grid>
                        <Grid> <Button onClick={() => handleReset()} startIcon={<RestartAlt />} variant="contained">{I18n("reset")}</Button> </Grid>
                        <Grid>{cancel && <Button onClick={() => onClose()} startIcon={<Close />} variant="contained">{I18n("close")}</Button>}</Grid>
                    </Grid>
                </CardActions>
            </Card >
        </>
    );
};
const mapDispatchToProps = (dispatch) => ({
    getDropdownFilterList: ({ key, value }) => {
        if (value.length > 2) {
            dispatch(trainingParticipantsFilterList({ key, value }));
        }
    },
    getDropdownBatchNameFilterList: ({ key, value }) => {
        if (value.length > 2) {
            dispatch(trainingParticipantsBatchNameFilterList({ key, value }));
        }
    }
});

const mapStateToProps = createStructuredSelector({
    reportFilter: getSearchFilters,
    ulbList: getLsgiSelectDropdownOption,
    designationList: getDesignationList,
    genderLists: getGender,
    FilterName: getNameLists,
    batchNameFilter: getBatchNameLists
});

const filter = withFormik({
    enableReinitialize: false,
    mapPropsToValues: (props) => {
        return props.reportFilter;
    },
    handleSubmit: (values, { setSubmitting }) => {
        setSubmitting(false);
    },
    displayName: "TcmParticipantsFilter"
})(TcmParticipantsFilter);

export default connect(mapStateToProps, mapDispatchToProps)(filter);
