export const STATE_REDUCER_KEY = "complaints";
export const TARGET_LEVEL =
    [
        { id: 5, name: "WARD" },
        { id: 4, name: "ULB" },
        { id: 3, name: "DISTRICT" },
        { id: 2, name: "STATE" }
    ];

export const ESC_PAGINATION = {
    pageNo: 0,
    pageSize: 5
};

export const ROWS_PER_PAGE = [
    ESC_PAGINATION.pageSize,
    ESC_PAGINATION.pageSize * 2,
    ESC_PAGINATION.pageSize * 3,
    ESC_PAGINATION.pageSize * 4,
    ESC_PAGINATION.pageSize * 5
];
