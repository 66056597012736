import { all, call, fork, put, takeLatest, take, select } from "redux-saga/effects";
import { setCommonTableData } from "utils/tableUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { fetchWardSabhaListApi, fetchWardSabhaByIdApi, filterWardListApi, postWardSabhaApi, deleteWardSabhaApi, patchWardSabhaApi, completeWardSabhaApi, fetchWard, fetchEnvironmentalSafeguardList } from "./api";
import { successNotify } from "utils/notificationUtils";
import { navigateTo } from "modules/common/actions";
import { I18n } from "common/components";
import { MODULE_PATH } from "modules/paths";
import { SUB_PROJECTS_PATH } from "../paths";
import { actions as commonActions } from "../../common/slice";
import { actions as sliceActions } from "./slice";
import { toEpoch } from "utils/dateUtils";
import { fetchSwmSubProjectById } from "../subProjectsList/saga";
import { getTableProps } from "modules/common/selectors";
import { getPayloadContent } from "utils/apiUtils";

export function* fetchSubProjectList({ payload }) {
  let tableProps = yield select(getTableProps);
  let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
  let updatedPayload = { ...payload };
  yield fork(handleAPIRequest, fetchWardSabhaListApi, { pageNo: updatedPayload?.pageNo, pageSize: updatedPayload?.pageSize, projectId: updatedPayload?.id || updatedPayload?.projectId, ...filters });
  const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WARD_SABHA_LIST_SUCCESS, ACTION_TYPES.FETCH_WARD_SABHA_LIST_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

export function* fetchSubProjectById({ payload: id }) {
  yield fork(handleAPIRequest, fetchWardSabhaByIdApi, id);
  const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WARD_SABHA_BY_ID_SUCCESS, ACTION_TYPES.FETCH_WARD_SABHA_BY_ID_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

const formatData = (rest) => {
  const { dateOfDiscussion, projectId } = rest;
  const data = { ...rest, dateOfDiscussion: toEpoch(dateOfDiscussion), projectId: Number(projectId) };
  return data;
};

export function* postWardSabha(action) {
  let { payload: { ...rest } = {} } = action;
  yield fork(handleAPIRequest, postWardSabhaApi, formatData(rest));
  const responseAction = yield take([ACTION_TYPES.SAVE_WARD_SABHA_SUCCESS, ACTION_TYPES.SAVE_WARD_SABHA_FAILURE]);
  if (responseAction.type === ACTION_TYPES.SAVE_WARD_SABHA_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("ward_sabha_discusion") }) }));
    yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
    yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${SUB_PROJECTS_PATH.SWM_SUB_PROJECTS.LIST}/${Number(rest.projectId)}/edit`));
  }
}

export function* patchWardSabha({ payload }) {
  yield fork(handleAPIRequest, patchWardSabhaApi, payload);
  const responseAction = yield take([ACTION_TYPES.UPDATE_WARD_SABHA_SUCCESS, ACTION_TYPES.UPDATE_WARD_SABHA_FAILURE]);
  if (responseAction.type === ACTION_TYPES.UPDATE_WARD_SABHA_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("ward_sabha_discusion") }) }));
    yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
    yield put(sliceActions.closeModal());
  }
}

export function* deleteWardSabha({ payload }) {
  const { idd, id } = payload;
  yield fork(handleAPIRequest, deleteWardSabhaApi, idd);
  const response = yield take([ACTION_TYPES.DELETE_WARD_SABHA_SUCCESS, ACTION_TYPES.DELETE_WARD_SABHA_FAILURE]);
  if (response.type === ACTION_TYPES.DELETE_WARD_SABHA_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("ward_sabha_discusion") }) }));
    yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
    let tablePayload = { pageNo: 0, pageSize: 5, projectId: id };
    yield call(fetchSubProjectList, { payload: tablePayload });
  }
}

export function* completeWardSabha({ payload: id }) {
  yield fork(handleAPIRequest, completeWardSabhaApi, id.id);
  const response = yield take([ACTION_TYPES.COMPLETE_WARD_SABHA_SUCCESS, ACTION_TYPES.COMPLETE_WARD_SABHA_FAILURE]);
  if (response.type === ACTION_TYPES.COMPLETE_WARD_SABHA_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("completed", { type: I18n("ward_sabha_discusion") }) }));
    yield call(fetchSwmSubProjectById, { payload: id.id });
  }
}

export function* fetchWardList({ payload }) {
  yield call(handleAPIRequest, fetchWard, payload);
}

export function* fetchEnvSafeguradList({ payload }) {
  yield call(handleAPIRequest, fetchEnvironmentalSafeguardList, payload);
}


export function* filterWardList({ payload: { value, key } }) {
  yield fork(handleAPIRequest, filterWardListApi, { key: key, value: value });
  const response = yield take([ACTION_TYPES.FILTER_WARD_LIST_SUCCESS, ACTION_TYPES.FILTER_WARD_LIST_FAILURE]);
  if (response.type === ACTION_TYPES.FILTER_WARD_LIST_SUCCESS) {
    yield put(sliceActions.setWardFilter({ key, value: getPayloadContent(response) }));
  }
}

export default function* testSaga() {
  yield all([
    takeLatest(ACTION_TYPES.FETCH_WARD_SABHA_LIST, fetchSubProjectList),
    takeLatest(ACTION_TYPES.FETCH_WARD_SABHA_BY_ID, fetchSubProjectById),
    takeLatest(ACTION_TYPES.SAVE_WARD_SABHA, postWardSabha),
    takeLatest(ACTION_TYPES.UPDATE_WARD_SABHA, patchWardSabha),
    takeLatest(ACTION_TYPES.DELETE_WARD_SABHA, deleteWardSabha),
    takeLatest(ACTION_TYPES.COMPLETE_WARD_SABHA, completeWardSabha),
    takeLatest(ACTION_TYPES.FETCH_WARD_LIST, fetchWardList),
    takeLatest(ACTION_TYPES.FILTER_WARD_LIST, filterWardList),
    takeLatest(ACTION_TYPES.FETCH_ENV_SAFEGUARD_LIST, fetchEnvSafeguradList)
  ]);
}
