import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchCityLevelDisposalCellApi = (data) => {
    return {
        url: API_URL.CITY_LEVEL_DISPOSAL_CELL.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST, ACTION_TYPES.FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS, ACTION_TYPES.FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE], data
        }
    };
};

export const cityLevelSiteDropDownApi = (data) => {
    return {
        url: API_URL.CITY_LEVEL_DISPOSAL_CELL.CITY_LEVEL_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.CITY_LEVEL_SITE_DROPDOWN_REQUEST, ACTION_TYPES.CITY_LEVEL_SITE_DROPDOWN_SUCCESS, ACTION_TYPES.CITY_LEVEL_SITE_DROPDOWN_FAILURE],
            data
        }
    };
};

export const cityLevelDisposalCellDetailsByIdApi = (data) => {
    return {
        url: API_URL.CITY_LEVEL_DISPOSAL_CELL.BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_REQUEST, ACTION_TYPES.GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_FAILURE]
        }
    };
};

export const postCityLevelDisposalCellDetailsApi = (data) => {
    return {
        url: API_URL.CITY_LEVEL_DISPOSAL_CELL.POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST, ACTION_TYPES.POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS, ACTION_TYPES.POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE], data
        }
    };
};

export const patchCityLevelDisposalCellDetailsApi = (data) => {
    return {
        url: API_URL.CITY_LEVEL_DISPOSAL_CELL.POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST, ACTION_TYPES.PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS, ACTION_TYPES.PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE], data
        }
    };
};

export const deleteCityLevelDisposalCellDetailsApi = (data) => {
    return {
        url: API_URL.CITY_LEVEL_DISPOSAL_CELL.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST, ACTION_TYPES.DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS, ACTION_TYPES.DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE]
        }
    };
};
