import { all, call, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { approveSpmcByIdApi, fetchPreviousSpmcApi } from "./api";


export function* approvalSpmcById({ payload }) {
    yield call(handleAPIRequest, approveSpmcByIdApi, payload);
}

export function* getPreviousSpmc({ payload }) {
    yield call(handleAPIRequest, fetchPreviousSpmcApi, payload);
}

export default function* registerSaga() {
    yield all([
        takeLatest(ACTION_TYPES.APPROVE_SPMC_BY_ID, approvalSpmcById),
        takeLatest(ACTION_TYPES.FETCH_PREVIOUS_SPMC, getPreviousSpmc)
    ]);
}
