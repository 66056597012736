import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
  POST_DPR_PREPARATION: `${STATE_REDUCER_KEY}/POST_DPR_PREPARATION`,
  POST_DPR_PREPARATION_REQUEST: `${STATE_REDUCER_KEY}/POST_DPR_PREPARATION_REQUEST`,
  POST_DPR_PREPARATION_SUCCESS: `${STATE_REDUCER_KEY}/POST_DPR_PREPARATION_SUCCESS`,
  POST_DPR_PREPARATION_FAILURE: `${STATE_REDUCER_KEY}/POST_DPR_PREPARATION_FAILURE`,

  FETCH_DPR_PREPARATION: `${STATE_REDUCER_KEY}/FETCH_DPR_PREPARATION`,
  FETCH_DPR_PREPARATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DPR_PREPARATION_REQUEST`,
  FETCH_DPR_PREPARATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DPR_PREPARATION_SUCCESS`,
  FETCH_DPR_PREPARATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DPR_PREPARATION_FAILURE`,

  PATCH_DPR_PREPARATION: `${STATE_REDUCER_KEY}/PATCH_DPR_PREPARATION`,
  PATCH_DPR_PREPARATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_DPR_PREPARATION_REQUEST`,
  PATCH_DPR_PREPARATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_DPR_PREPARATION_SUCCESS`,
  PATCH_DPR_PREPARATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_DPR_PREPARATION_FAILURE`,

  APPROVE_DPR_PREPARATION: `${STATE_REDUCER_KEY}/APPROVE_DPR_PREPARATION`,
  APPROVE_DPR_PREPARATION_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_DPR_PREPARATION_REQUEST`,
  APPROVE_DPR_PREPARATION_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_DPR_PREPARATION_SUCCESS`,
  APPROVE_DPR_PREPARATION_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_DPR_PREPARATION_FAILURE`,

  POST_NOC_FROM_WB: `${STATE_REDUCER_KEY}/POST_NOC_FROM_WB`,
  POST_NOC_FROM_WB_REQUEST: `${STATE_REDUCER_KEY}/POST_NOC_FROM_WB_REQUEST`,
  POST_NOC_FROM_WB_SUCCESS: `${STATE_REDUCER_KEY}/POST_NOC_FROM_WB_SUCCESS`,
  POST_NOC_FROM_WB_FAILURE: `${STATE_REDUCER_KEY}/POST_NOC_FROM_WB_FAILURE`,

  FETCH_NOC_FROM_WB: `${STATE_REDUCER_KEY}/FETCH_NOC_FROM_WB`,
  FETCH_NOC_FROM_WB_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NOC_FROM_WB_REQUEST`,
  FETCH_NOC_FROM_WB_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NOC_FROM_WB_SUCCESS`,
  FETCH_NOC_FROM_WB_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NOC_FROM_WB_FAILURE`,

  APPROVE_QA_QC_BY_DPMU: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_BY_DPMU`,
  APPROVE_QA_QC_BY_DPMU_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_BY_DPMU_REQUEST`,
  APPROVE_QA_QC_BY_DPMU_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_BY_DPMU_SUCCESS`,
  APPROVE_QA_QC_BY_DPMU_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_BY_DPMU_FAILURE`,

  APPROVE_BY_SPMU: `${STATE_REDUCER_KEY}/APPROVE_BY_SPMU`,
  APPROVE_BY_SPMU_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_BY_SPMU_REQUEST`,
  APPROVE_BY_SPMU_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_BY_SPMU_SUCCESS`,
  APPROVE_BY_SPMU_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_BY_SPMU_FAILURE`,

  APPROVE_BY_MUNICIPAL: `${STATE_REDUCER_KEY}/APPROVE_BY_MUNICIPAL`,
  APPROVE_BY_MUNICIPAL_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_BY_MUNICIPAL_REQUEST`,
  APPROVE_BY_MUNICIPAL_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_BY_MUNICIPAL_SUCCESS`,
  APPROVE_BY_MUNICIPAL_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_BY_MUNICIPAL_FAILURE`,

  PATCH_NOC_FROM_WB: `${STATE_REDUCER_KEY}/PATCH_NOC_FROM_WB`,
  PATCH_NOC_FROM_WB_REQUEST: `${STATE_REDUCER_KEY}/PATCH_NOC_FROM_WB_REQUEST`,
  PATCH_NOC_FROM_WB_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_NOC_FROM_WB_SUCCESS`,
  PATCH_NOC_FROM_WB_FAILURE: `${STATE_REDUCER_KEY}/PATCH_NOC_FROM_WB_FAILURE`,

  POST_TECHNICAL_SANCTION: `${STATE_REDUCER_KEY}/POST_TECHNICAL_SANCTION`,
  POST_TECHNICAL_SANCTION_REQUEST: `${STATE_REDUCER_KEY}/POST_TECHNICAL_SANCTION_REQUEST`,
  POST_TECHNICAL_SANCTION_SUCCESS: `${STATE_REDUCER_KEY}/POST_TECHNICAL_SANCTION_SUCCESS`,
  POST_TECHNICAL_SANCTION_FAILURE: `${STATE_REDUCER_KEY}/POST_TECHNICAL_SANCTION_FAILURE`,

  PATCH_TECHNICAL_SANCTION: `${STATE_REDUCER_KEY}/PATCH_TECHNICAL_SANCTION`,
  PATCH_TECHNICAL_SANCTION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_TECHNICAL_SANCTION_REQUEST`,
  PATCH_TECHNICAL_SANCTION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_TECHNICAL_SANCTION_SUCCESS`,
  PATCH_TECHNICAL_SANCTION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_TECHNICAL_SANCTION_FAILURE`,

  FETCH_TECHNICAL_SANCTION: `${STATE_REDUCER_KEY}/FETCH_TECHNICAL_SANCTION`,
  FETCH_TECHNICAL_SANCTION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TECHNICAL_SANCTION_REQUEST`,
  FETCH_TECHNICAL_SANCTION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TECHNICAL_SANCTION_SUCCESS`,
  FETCH_TECHNICAL_SANCTION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TECHNICAL_SANCTION_FAILURE`,

  POST_FINANCIAL_SANCTION: `${STATE_REDUCER_KEY}/POST_FINANCIAL_SANCTION`,
  POST_FINANCIAL_SANCTION_REQUEST: `${STATE_REDUCER_KEY}/POST_FINANCIAL_SANCTION_REQUEST`,
  POST_FINANCIAL_SANCTION_SUCCESS: `${STATE_REDUCER_KEY}/POST_FINANCIAL_SANCTION_SUCCESS`,
  POST_FINANCIAL_SANCTION_FAILURE: `${STATE_REDUCER_KEY}/POST_FINANCIAL_SANCTION_FAILURE`,

  PATCH_FINANCIAL_SANCTION: `${STATE_REDUCER_KEY}/PATCH_FINANCIAL_SANCTION`,
  PATCH_FINANCIAL_SANCTION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_FINANCIAL_SANCTION_REQUEST`,
  PATCH_FINANCIAL_SANCTION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_FINANCIAL_SANCTION_SUCCESS`,
  PATCH_FINANCIAL_SANCTION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_FINANCIAL_SANCTION_FAILURE`,

  FETCH_FINANCIAL_SANCTION: `${STATE_REDUCER_KEY}/FETCH_FINANCIAL_SANCTION`,
  FETCH_FINANCIAL_SANCTION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FINANCIAL_SANCTION_REQUEST`,
  FETCH_FINANCIAL_SANCTION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FINANCIAL_SANCTION_SUCCESS`,
  FETCH_FINANCIAL_SANCTION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FINANCIAL_SANCTION_FAILURE`,

  FETCH_DPR_LIST: `${STATE_REDUCER_KEY}/FETCH_DPR_LIST`,
  FETCH_DPR_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DPR_LIST_REQUEST`,
  FETCH_DPR_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DPR_LIST_SUCCESS`,
  FETCH_DPR_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DPR_LIST_FAILURE`
};

export const saveDprPreparation = createAction(ACTION_TYPES.POST_DPR_PREPARATION);
export const fetchDprPreparation = createAction(ACTION_TYPES.FETCH_DPR_PREPARATION);
export const updateDprPreparation = createAction(ACTION_TYPES.PATCH_DPR_PREPARATION);
export const approveDprPreparation = createAction(ACTION_TYPES.APPROVE_DPR_PREPARATION);
export const saveNocFromWb = createAction(ACTION_TYPES.POST_NOC_FROM_WB);
export const updateNocFromWb = createAction(ACTION_TYPES.PATCH_NOC_FROM_WB);
export const fetchNocFromWb = createAction(ACTION_TYPES.FETCH_NOC_FROM_WB);
export const approveQaQcByDpmu = createAction(ACTION_TYPES.APPROVE_QA_QC_BY_DPMU);
export const approveByspmu = createAction(ACTION_TYPES.APPROVE_BY_SPMU);
export const approveByMunicipal = createAction(ACTION_TYPES.APPROVE_BY_MUNICIPAL);
export const saveTechnicalSanction = createAction(ACTION_TYPES.POST_TECHNICAL_SANCTION);
export const updateTechnicalSanction = createAction(ACTION_TYPES.PATCH_TECHNICAL_SANCTION);
export const fetchTechnicalSanction = createAction(ACTION_TYPES.FETCH_TECHNICAL_SANCTION);
export const saveFinancialSanction = createAction(ACTION_TYPES.POST_FINANCIAL_SANCTION);
export const updateFinancialSanction = createAction(ACTION_TYPES.PATCH_FINANCIAL_SANCTION);
export const fetchFinancialSanction = createAction(ACTION_TYPES.FETCH_FINANCIAL_SANCTION);
export const fetchDprList = createAction(ACTION_TYPES.FETCH_DPR_LIST);
