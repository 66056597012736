import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { ACTION_TYPES, fetchDprPreparation } from "./actions";
import { toEpoch } from "utils/dateUtils";
import { handleAPIRequest } from "utils/http";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { approveDprPreparationData, getDprPreparationData, getFinancialSanctionByIdApi, getNocFromWbByIdApi, getTechnicalSanctionByIdApi, submitDprPreparationData, submitFinancialSanctionApi, submitNocFromWbApi, submitTechnicalSanctionApi, updateDprPreparationData, updateFinancialSanctionApi, updateNocFromWbApi, updateTechnicalSanctionApi, approveByMunicipal, approveBySpmu, approveQaQcByDpmu, rejectBySpmu, rejectQaQcByDpmu, rejectByMunicipal, fetchDprListApi } from "./api";
import { fetchSwmSubProjectById } from "../subProjectsList/saga";
import { actions as sliceActons } from "../../common/slice";

export function* getDprPreparation({ payload }) {
  yield call(handleAPIRequest, getDprPreparationData, payload);
}
export function* fetchDprList({ payload }) {
  yield fork(handleAPIRequest, fetchDprListApi, payload);
  const responseAction = yield take([ACTION_TYPES.FETCH_DPR_LIST_SUCCESS, ACTION_TYPES.FETCH_DPR_LIST_FAILURE]);
  if (responseAction.type === ACTION_TYPES.FETCH_DPR_LIST_SUCCESS) {
    if (responseAction?.payload?.content?.length > 0) {
      yield put(fetchDprPreparation({ projectId: payload?.projectId }));
    }
  }
}

export function* postNocFromWb({ payload }) {
  const { dateOfReceipt, projectId, ...rest } = payload;
  yield fork(handleAPIRequest, submitNocFromWbApi, { ...rest, projectId, dateOfReceipt: toEpoch(dateOfReceipt) });
  const responseAction = yield take([ACTION_TYPES.POST_NOC_FROM_WB_SUCCESS, ACTION_TYPES.POST_NOC_FROM_WB_FAILURE]);
  if (responseAction.type === ACTION_TYPES.POST_NOC_FROM_WB_SUCCESS) {
    yield call(getDprPreparation, { payload: { projectId: payload.projectId } });
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("noc_from_world_bank") }) }));
  }
}

export function* patchNocFromWb({ payload }) {
  const { dateOfReceipt, projectId, ...rest } = payload;
  yield fork(handleAPIRequest, updateNocFromWbApi, { ...rest, projectId, dateOfReceipt: toEpoch(dateOfReceipt) });
  const responseAction = yield take([ACTION_TYPES.PATCH_NOC_FROM_WB_SUCCESS, ACTION_TYPES.PATCH_NOC_FROM_WB_FAILURE]);
  if (responseAction.type === ACTION_TYPES.PATCH_NOC_FROM_WB_SUCCESS) {
    yield call(getDprPreparation, { payload: { projectId: payload.projectId } });
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("noc_from_world_bank") }) }));
  }
}

export function* fetchNocFromWbById({ payload: id }) {
  yield fork(handleAPIRequest, getNocFromWbByIdApi, id);
}
export function* submitDprPreparation({ payload }) {
  if (payload.dateOfReview) {
    payload.dateOfReview = toEpoch(payload.dateOfReview);
  }
  if (payload.dateOfScreening1) {
    payload.dateOfScreening1 = toEpoch(payload.dateOfScreening1);
  }
  if (payload.dateOfSiteRecommaissance) {
    payload.dateOfSiteRecommaissance = toEpoch(payload.dateOfSiteRecommaissance);
  }
  if (payload.dateOfScreening2) {
    payload.dateOfScreening2 = toEpoch(payload.dateOfScreening2);
  }
  payload.projectId = Number(payload.projectId);
  yield fork(handleAPIRequest, submitDprPreparationData, payload);
  const response = yield take([ACTION_TYPES.POST_DPR_PREPARATION_SUCCESS, ACTION_TYPES.POST_DPR_PREPARATION_FAILURE]);
  if (response.type === ACTION_TYPES.POST_DPR_PREPARATION_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("dpr_preparation") }) }));
    yield call(getDprPreparation, { payload: { projectId: payload.projectId } });
  }
}
export function* updateDprPreparation({ payload }) {
  if (payload.dateOfReview) {
    payload.dateOfReview = toEpoch(payload.dateOfReview);
  }
  if (payload.dateOfScreening1) {
    payload.dateOfScreening1 = toEpoch(payload.dateOfScreening1);
  }
  if (payload.dateOfSiteRecommaissance) {
    payload.dateOfSiteRecommaissance = toEpoch(payload.dateOfSiteRecommaissance);
  }
  if (payload.dateOfScreening2) {
    payload.dateOfScreening2 = toEpoch(payload.dateOfScreening2);
  }
  payload.projectId = Number(payload.projectId);
  yield fork(handleAPIRequest, updateDprPreparationData, payload);
  const response = yield take([ACTION_TYPES.PATCH_DPR_PREPARATION_SUCCESS, ACTION_TYPES.PATCH_DPR_PREPARATION_FAILURE]);
  if (response.type === ACTION_TYPES.PATCH_DPR_PREPARATION_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("dpr_preparation") }) }));
    yield call(getDprPreparation, { payload: { projectId: payload.projectId } });
  }
}

export function* approveDprPreparation({ payload }) {
  yield fork(handleAPIRequest, approveDprPreparationData, payload);
  const response = yield take([ACTION_TYPES.APPROVE_DPR_PREPARATION_SUCCESS, ACTION_TYPES.APPROVE_DPR_PREPARATION_FAILURE]);
  if (response.type === ACTION_TYPES.APPROVE_DPR_PREPARATION_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("completed", { type: I18n("dpr_preparation") }) }));
    yield call(fetchSwmSubProjectById, { payload: payload.projectId });
    yield call(getDprPreparation, { payload: { projectId: payload.projectId } });
  }
}

export function* QaQcByDpmuApprove({ payload }) {
  const { dpmuApprovalDate, projectId, submitType, ...rest } = payload;
  if (submitType === "submit") {
    yield fork(handleAPIRequest, approveQaQcByDpmu, { ...rest, projectId, dpmuApprovalDate: toEpoch(dpmuApprovalDate) });
  } else {
    yield fork(handleAPIRequest, rejectQaQcByDpmu, { ...rest, projectId, dpmuApprovalDate: toEpoch(dpmuApprovalDate) });
  }
  const responseAction = yield take([ACTION_TYPES.APPROVE_QA_QC_BY_DPMU_SUCCESS, ACTION_TYPES.APPROVE_QA_QC_BY_DPMU_FAILURE]);
  if (responseAction.type === ACTION_TYPES.APPROVE_QA_QC_BY_DPMU_SUCCESS) {
    if (submitType === "submit") {

      yield call(fetchSwmSubProjectById, { payload: payload.projectId });
    }
    yield call(fetchSwmSubProjectById, { payload: payload.projectId });
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("qa_qc_by_dpmu") }) }));
  }
}

export function* SpmuApprove({ payload }) {
  const { spmuApprovalDate, projectId, submitType, ...rest } = payload;
  if (submitType === "submit") {
    yield fork(handleAPIRequest, approveBySpmu, { ...rest, projectId, spmuApprovalDate: toEpoch(spmuApprovalDate) });
  } else {
    yield fork(handleAPIRequest, rejectBySpmu, { ...rest, projectId, spmuApprovalDate: toEpoch(spmuApprovalDate) });
  }
  const responseAction = yield take([ACTION_TYPES.APPROVE_BY_SPMU_SUCCESS, ACTION_TYPES.APPROVE_BY_SPMU_FAILURE]);
  if (responseAction.type === ACTION_TYPES.APPROVE_BY_SPMU_SUCCESS) {
    if (submitType === "submit") {
      yield call(fetchSwmSubProjectById, { payload: payload.projectId });
    }
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("spmu_approval") }) }));
  }
}

export function* MunicipalApprove({ payload }) {
  const { mcApprovalDate, projectId, submitType, ...rest } = payload;
  if (submitType === "submit") {
    yield fork(handleAPIRequest, approveByMunicipal, { ...rest, projectId, mcApprovalDate: toEpoch(mcApprovalDate) });
  } else {
    yield fork(handleAPIRequest, rejectByMunicipal, { ...rest, projectId, mcApprovalDate: toEpoch(mcApprovalDate) });
  }
  const responseAction = yield take([ACTION_TYPES.APPROVE_BY_MUNICIPAL_SUCCESS, ACTION_TYPES.APPROVE_BY_MUNICIPAL_FAILURE]);
  if (responseAction.type === ACTION_TYPES.APPROVE_BY_MUNICIPAL_SUCCESS) {
    if (submitType === "submit") {
      yield call(fetchSwmSubProjectById, { payload: payload.projectId });
    }
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("mc_approval") }) }));
  }
}
export function* postTechnicalSanction({ payload }) {
  const { dateOfTs, projectId, ...rest } = payload;
  yield fork(handleAPIRequest, submitTechnicalSanctionApi, { ...rest, projectId, dateOfTs: toEpoch(dateOfTs) });
  const responseAction = yield take([ACTION_TYPES.POST_TECHNICAL_SANCTION_SUCCESS, ACTION_TYPES.POST_TECHNICAL_SANCTION_FAILURE]);
  if (responseAction.type === ACTION_TYPES.POST_TECHNICAL_SANCTION_SUCCESS) {
    // yield call(getDprPreparation, { payload: { projectId: payload.projectId } });
    yield call(handleAPIRequest, getTechnicalSanctionByIdApi, projectId);
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("technical_sanction") }) }));

  }
}

export function* patchTechnicalSanction({ payload }) {
  const { dateOfTs, projectId, ...rest } = payload;
  yield fork(handleAPIRequest, updateTechnicalSanctionApi, { ...rest, projectId, dateOfTs: toEpoch(dateOfTs) });
  const responseAction = yield take([ACTION_TYPES.PATCH_TECHNICAL_SANCTION_SUCCESS, ACTION_TYPES.PATCH_TECHNICAL_SANCTION_FAILURE]);
  if (responseAction.type === ACTION_TYPES.PATCH_TECHNICAL_SANCTION_SUCCESS) {
    // yield call(getDprPreparation, { payload: { projectId: payload.projectId } });
    yield call(handleAPIRequest, getTechnicalSanctionByIdApi, projectId);
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("technical_sanction") }) }));

  }
}

export function* fetchTechnicalSanctionById({ payload: id }) {
  yield fork(handleAPIRequest, getTechnicalSanctionByIdApi, id);
}

export function* fetchFinancialSanctionById({ payload: id }) {
  yield fork(handleAPIRequest, getFinancialSanctionByIdApi, id);
}

export function* postFinancialSanction({ payload }) {
  const { dateOfFs, projectId, ...rest } = payload;
  yield fork(handleAPIRequest, submitFinancialSanctionApi, { ...rest, projectId, dateOfFs: toEpoch(dateOfFs) });
  const responseAction = yield take([ACTION_TYPES.POST_FINANCIAL_SANCTION_SUCCESS, ACTION_TYPES.POST_FINANCIAL_SANCTION_FAILURE]);
  if (responseAction.type === ACTION_TYPES.POST_FINANCIAL_SANCTION_SUCCESS) {
    // yield call(getDprPreparation, { payload: { projectId: payload.projectId } });
    yield call(handleAPIRequest, getFinancialSanctionByIdApi, projectId);
    // yield put(sliceActions.moveTabUp);
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("financial_sanction") }) }));
    yield put(sliceActons.moveTabUp());

  }
}

export function* patchFinancialSanction({ payload }) {
  const { dateOfFs, projectId, ...rest } = payload;
  yield fork(handleAPIRequest, updateFinancialSanctionApi, { ...rest, projectId, dateOfFs: toEpoch(dateOfFs) });
  const responseAction = yield take([ACTION_TYPES.PATCH_FINANCIAL_SANCTION_SUCCESS, ACTION_TYPES.PATCH_FINANCIAL_SANCTION_FAILURE]);
  if (responseAction.type === ACTION_TYPES.PATCH_FINANCIAL_SANCTION_SUCCESS) {
    // yield call(getDprPreparation, { payload: { projectId: payload.projectId } });
    yield call(handleAPIRequest, getFinancialSanctionByIdApi, projectId);
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("financial_sanction") }) }));

  }
}

export default function* testSaga() {
  yield all([
    takeLatest(ACTION_TYPES.APPROVE_QA_QC_BY_DPMU, QaQcByDpmuApprove),
    takeLatest(ACTION_TYPES.APPROVE_BY_SPMU, SpmuApprove),
    takeLatest(ACTION_TYPES.APPROVE_BY_MUNICIPAL, MunicipalApprove),
    takeLatest(ACTION_TYPES.POST_NOC_FROM_WB, postNocFromWb),
    takeLatest(ACTION_TYPES.FETCH_NOC_FROM_WB, fetchNocFromWbById),
    takeLatest(ACTION_TYPES.POST_DPR_PREPARATION, submitDprPreparation),
    takeLatest(ACTION_TYPES.FETCH_DPR_PREPARATION, getDprPreparation),
    takeLatest(ACTION_TYPES.PATCH_DPR_PREPARATION, updateDprPreparation),
    takeLatest(ACTION_TYPES.APPROVE_DPR_PREPARATION, approveDprPreparation),
    takeLatest(ACTION_TYPES.PATCH_NOC_FROM_WB, patchNocFromWb),
    takeLatest(ACTION_TYPES.POST_TECHNICAL_SANCTION, postTechnicalSanction),
    takeLatest(ACTION_TYPES.FETCH_TECHNICAL_SANCTION, fetchTechnicalSanctionById),
    takeLatest(ACTION_TYPES.PATCH_TECHNICAL_SANCTION, patchTechnicalSanction),
    takeLatest(ACTION_TYPES.POST_FINANCIAL_SANCTION, postFinancialSanction),
    takeLatest(ACTION_TYPES.FETCH_FINANCIAL_SANCTION, fetchFinancialSanctionById),
    takeLatest(ACTION_TYPES.PATCH_FINANCIAL_SANCTION, patchFinancialSanction),
    takeLatest(ACTION_TYPES.FETCH_DPR_LIST, fetchDprList)
  ]);
}
