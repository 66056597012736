import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadContentDetails } from "utils/apiUtils";
import { COMMON_TABLE_PAGINATION } from "../sample/constants";

import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
const initialState = {
    tablePagination: { ...COMMON_TABLE_PAGINATION },
    lookupFormDetails: {
        requestInProgress: false,
        data: {
            name: "",
            description: "",
            lookupCategoryId: ""

        }
    },
    lookUpCategoryIdDropDown: {
        requestInProgress: false,
        data: []
    },
    lookUpCategoryTypeDetails: {
        requestInProgress: false,
        data: {
            name: "",
            description: ""

        }
    },
    nameList: {
        requestInProgress: false,
        data: []
    },
    lookUpValueNameList: {
        requestInProgress: false,
        data: []
    }

};


const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.table = initialState.table;
        },
        clearLookUpCategoryDropDown: (state) => {
            state.lookUpCategoryIdDropDown = initialState.lookUpCategoryIdDropDown;
        },
        setPagination: (state, action) => {
            _.set(state, "tablePagination", action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "lookUpCategoryTypeDetails.requestInProgress", false);
                _.set(state, "lookUpCategoryTypeDetails.data", getPayloadContentDetails(action));
            })
            .addCase(ACTION_TYPES.FETCH_LOOKUP_FORM_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "lookupFormDetails.requestInProgress", false);
                _.set(state, "lookupFormDetails.data", payload.content[0]);
            })
            .addCase(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_DROPDOWN_SUCCESS, (state, { payload: { content = [] } = {} }) => {
                _.set(state, "lookUpCategoryIdDropDown.requestInProgress", false);
                _.set(state, "lookUpCategoryIdDropDown.data", content, []);
            }).addCase(ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "nameList.requestInProgress", false);
                _.set(state, "nameList.data", payload.content);
            }).addCase(ACTION_TYPES.FETCH_LOOK_UP_VALUE_NAME_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "lookUpValueNameList.requestInProgress", false);
                _.set(state, "lookUpValueNameList.data", payload.content);
            });
    }
});

export const { actions, reducer } = slice;
