import { REQUEST_METHOD } from "common/constants";
import { API_URL as COMMON_API_URL } from "modules/common/apiURL";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchWardSabhaListApi = (data) => {
  return {
    url: `${API_URL.WARD_SABHA.LIST}`,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_WARD_SABHA_LIST_REQUEST, ACTION_TYPES.FETCH_WARD_SABHA_LIST_SUCCESS, ACTION_TYPES.FETCH_WARD_SABHA_LIST_FAILURE],
      data: data
    }
  };
};

export const fetchWardSabhaByIdApi = (data) => {
  return {
    url: `${API_URL.WARD_SABHA.LIST_BY_ID}`,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_WARD_SABHA_BY_ID_REQUEST, ACTION_TYPES.FETCH_WARD_SABHA_BY_ID_SUCCESS, ACTION_TYPES.FETCH_WARD_SABHA_BY_ID_FAILURE],
      data: { id: data }
    }
  };
};

export const postWardSabhaApi = (data) => {
  return {
    url: `${API_URL.WARD_SABHA.CREATE}`,
    method: REQUEST_METHOD.POST,
    payload: {
      types: [ACTION_TYPES.SAVE_WARD_SABHA_REQUEST, ACTION_TYPES.SAVE_WARD_SABHA_SUCCESS, ACTION_TYPES.SAVE_WARD_SABHA_FAILURE],
      data: data
    }
  };
};

export const patchWardSabhaApi = (data) => {
  return {
    url: `${API_URL.WARD_SABHA.CREATE}`,
    method: REQUEST_METHOD.PATCH,
    payload: {
      types: [ACTION_TYPES.UPDATE_WARD_SABHA_REQUEST, ACTION_TYPES.UPDATE_WARD_SABHA_SUCCESS, ACTION_TYPES.UPDATE_WARD_SABHA_FAILURE],
      data: data
    }
  };
};

export const deleteWardSabhaApi = (id) => {
  return {
    url: `${API_URL.WARD_SABHA.DELETE.replace(":id", id)}`,
    method: REQUEST_METHOD.DELETE,
    payload: {
      types: [ACTION_TYPES.DELETE_WARD_SABHA_REQUEST, ACTION_TYPES.DELETE_WARD_SABHA_SUCCESS, ACTION_TYPES.DELETE_WARD_SABHA_FAILURE]
    }
  };
};

export const completeWardSabhaApi = (id) => {
  return {
    url: `${API_URL.WARD_SABHA.COMPLETED.replace(":id", id)}`,
    method: REQUEST_METHOD.PATCH,
    payload: {
      types: [ACTION_TYPES.COMPLETE_WARD_SABHA_REQUEST, ACTION_TYPES.COMPLETE_WARD_SABHA_SUCCESS, ACTION_TYPES.COMPLETE_WARD_SABHA_FAILURE]
    }
  };
};

export const fetchWard = (data) => {
  return {
    url: `${COMMON_API_URL.COMMON.WARD_DROPDOWN}`,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_WARD_LIST_REQUEST, ACTION_TYPES.FETCH_WARD_LIST_SUCCESS, ACTION_TYPES.FETCH_WARD_LIST_FAILURE],
      data: data
    }
  };
};

export const filterWardListApi = (data) => {
  return {
    url: `${API_URL.WARD_SABHA.FILTER_WARD}`,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FILTER_WARD_LIST_REQUEST, ACTION_TYPES.FILTER_WARD_LIST_SUCCESS, ACTION_TYPES.FILTER_WARD_LIST_FAILURE],
      data: data
    }
  };
};

export const fetchEnvironmentalSafeguardList = (data) => {
  return {
    url: `${API_URL.SWM_SUB_PROJECTS.ENV_SAFEGUARD_LIST}`,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_ENV_SAFEGUARD_LIST_REQUEST, ACTION_TYPES.FETCH_ENV_SAFEGUARD_LIST_SUCCESS, ACTION_TYPES.FETCH_ENV_SAFEGUARD_LIST_FAILURE],
      data: data
    }
  };
};
