import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions.js";

export const userRoleMappingPostAPICall = (data) => {
    return {
        url: API_URL.USER_ROLE_MAPPING.CREATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [
                ACTION_TYPES.SUBMIT_USER_ROLE_MAPPING_DETAILS_REQUEST,
                ACTION_TYPES.SUBMIT_USER_ROLE_MAPPING_DETAILS_SUCCESS,
                ACTION_TYPES.SUBMIT_USER_ROLE_MAPPING_DETAILS_FAILURE
            ],
            data
        }
    };
};
export const userRoleAssignFetchAPICall = (data) => {
    return {
        url: API_URL.USER_ROLE_MAPPING.TABLE_DATA_ASSIGN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_REQUEST,
                ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_SUCCESS,
                ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_FAILURE
            ],
            data
        }
    };
};
export const userRoleUnassignFetchAPICall = (data) => {
    return {
        url: API_URL.USER_ROLE_MAPPING.TABLE_DATA_UNASSIGN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_REQUEST,
                ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_SUCCESS,
                ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_FAILURE
            ],
            data
        }
    };
};
export const userRoleMappingEditCall = (data) => {
    return {
        url: API_URL.USER_ROLE_MAPPING.UNASSIGN,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [
                ACTION_TYPES.EDIT_USER_ROLE_MAPPING_DETAILS_REQUEST,
                ACTION_TYPES.EDIT_USER_ROLE_MAPPING_DETAILS_SUCCESS,
                ACTION_TYPES.EDIT_USER_ROLE_MAPPING_DETAILS_FAILURE
            ],
            data
        }
    };
};

export const userRoleADataAccessFetchAPICall = (data) => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.TABLE_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_USER_ROLE_DATA_ACCESS_DETAILS_REQUEST,
                ACTION_TYPES.FETCH_USER_ROLE_DATA_ACCESS_DETAILS_SUCCESS,
                ACTION_TYPES.FETCH_USER_ROLE_DATA_ACCESS_DETAILS_FAILURE
            ],
            data: { ...data, pageSize: 100 }
        }
    };
};

export const districtDropdownApi = () => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.DISTRICT_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_DISTRICT_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_DISTRICT_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_DISTRICT_DROPDOWN_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};

export const lsgiTypeDropdownApi = (id) => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.LSGI_TYPE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_FAILURE
            ],
            data: { dropDown: true, lsgiType: id }
        }
    };
};
export const lsgiDropdownApi = ({ id, districtId }) => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.LSGI_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_LSGI_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_LSGI_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_LSGI_DROPDOWN_FAILURE
            ],
            data: { dropdown: true, districtId: districtId, lsgiTypeId: id }
        }
    };
};

export const wardDropdownApi = ({ id, districtId }) => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.WARD_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_WARD_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_WARD_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_WARD_DROPDOWN_FAILURE
            ],
            data: { dropDown: true, districtId: districtId, lsgiId: id, pageSize: 200 }
        }
    };
};

export const stateDropdownApi = () => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.STATE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_STATE_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_STATE_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_STATE_DROPDOWN_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};

export const trainingOrganizationDropdownApi = () => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.TRAINING_ORGANIZATION_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_TRAINING_ORGANIZATION_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_TRAINING_ORGANIZATION_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_TRAINING_ORGANIZATION_DROPDOWN_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};

export const vendorDropdownApi = () => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.VENDOR_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_VENDOR_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_VENDOR_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_VENDOR_DROPDOWN_FAILURE
            ],
            data: { dropDown: true, pageSize: 100 }
        }
    };
};

export const rolesLevelDropdownApi = () => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.ROLE_LEVEL_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_ROLE_LEVEL_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_ROLE_LEVEL_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_ROLE_LEVEL_DROPDOWN_FAILURE
            ],
            data: { dropDown: true, pageSize: 100 }
        }
    };
};
export const submitRoleDataAccessPostApi = (data) => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.SUBMIT_DATA_ACCESS,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [
                ACTION_TYPES.SUBMIT_ROLE_DATA_ACCESS_REQUEST,
                ACTION_TYPES.SUBMIT_ROLE_DATA_ACCESS_SUCCESS,
                ACTION_TYPES.SUBMIT_ROLE_DATA_ACCESS_FAILURE
            ],
            data
        }
    };
};
export const dataAccessDeleteCall = ({ targetReferencesId: referenceId, userId: userId, roleId: roleId }) => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.DELETE_DATA_ACCESS,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [
                ACTION_TYPES.DELETE_DATA_ACCESS_DETAILS_REQUEST,
                ACTION_TYPES.DELETE_DATA_ACCESS_DETAILS_SUCCESS,
                ACTION_TYPES.DELETE_DATA_ACCESS_DETAILS_FAILURE
            ],
            data: { userId: userId, targetReferences: referenceId, roleId: roleId }
        }
    };
};
export const userRolesFetchApiCallById = (data) => {
    return {
        url: API_URL.USER_ROLE_MAPPING.TABLE_LIST_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_USER_ROLE_BY_ID_DETAILS_REQUEST,
                ACTION_TYPES.FETCH_USER_ROLE_BY_ID_DETAILS_SUCCESS,
                ACTION_TYPES.FETCH_USER_ROLE_BY_ID_DETAILS_FAILURE
            ],
            data
        }
    };
};

export const userDataAccessEditCall = (data) => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.EDIT_DATA_ACCESS,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [
                ACTION_TYPES.EDIT_DATA_ACCESS_DETAILS_REQUEST,
                ACTION_TYPES.EDIT_DATA_ACCESS_DETAILS_SUCCESS,
                ACTION_TYPES.EDIT_DATA_ACCESS_DETAILS_FAILURE
            ],
            data
        }
    };
};
export const filterRoleMapping = (data) => {
    return {
        url: API_URL.USER_ROLE_MAPPING.FILTER,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FILTER_ROLE_MAPPING_REQUEST,
                ACTION_TYPES.FILTER_ROLE_MAPPING_SUCCESS,
                ACTION_TYPES.FILTER_ROLE_MAPPING_FAILURE
            ],
            data
        }
    };
};
export const ivaDropdownApi = () => {
    return {
        url: API_URL.USER_ROLE_DATA_ACCESS.IVA_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.IVA_DROPDOWN_REQUEST,
                ACTION_TYPES.IVA_DROPDOWN_SUCCESS,
                ACTION_TYPES.IVA_DROPDOWN_FAILURE
            ],
            data: { dropDown: true, pageSize: 100 }
        }
    };
};
