import { ACTION_TYPES } from "./actions";
import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "modules/subProjects/apiUrls";

export const SwmSubProjectsListApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.ALL_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_SWM_SUB_PROJECT_REQUEST, ACTION_TYPES.LIST_SWM_SUB_PROJECT_SUCCESS, ACTION_TYPES.LIST_SWM_SUB_PROJECT_FAILURE],
        data
    }
});

export const groupDiscussionSave = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.WORKING_GROUP_DISCUSSION.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_SWM_SUB_PROJECT_GROUP_DISCUSSION_REQUEST, ACTION_TYPES.POST_SWM_SUB_PROJECT_GROUP_DISCUSSION_SUCCESS, ACTION_TYPES.POST_SWM_SUB_PROJECT_GROUP_DISCUSSION_FAILURE],
        data
    }
});

export const groupDiscussionSaveCommon = (data) => ({
    url: API_URL.DISCUSSIONS_AND_APPROVAL.WORKING_GROUP_DISCUSSION_COMMON.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_WORKING_GROUP_DISCUSSION_COMMON_REQUEST, ACTION_TYPES.SAVE_WORKING_GROUP_DISCUSSION_COMMON_SUCCESS, ACTION_TYPES.SAVE_WORKING_GROUP_DISCUSSION_COMMON_FAILURE],
        data
    }
});

export const groupDiscussionFetch = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.WORKING_GROUP_DISCUSSION.BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_REQUEST, ACTION_TYPES.FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_SUCCESS, ACTION_TYPES.FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_FAILURE],
        data: { projectId: data?.projectId }
    }
});

export const groupDiscussionPatch = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.WORKING_GROUP_DISCUSSION.SAVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_REQUEST, ACTION_TYPES.PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_SUCCESS, ACTION_TYPES.PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_FAILURE],
        data
    }
});

export const deleteSwmSubProjectApi = (id) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.DELETE_PROJECT.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_SWM_SUB_PROJECT_PROJECT_REQUEST, ACTION_TYPES.DELETE_SWM_SUB_PROJECT_PROJECT_SUCCESS, ACTION_TYPES.DELETE_SWM_SUB_PROJECT_PROJECT_FAILURE]
        }
    };
};

export const SwmSubProjectsListActivitiesApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ACTIVITY.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_SWM_SUB_PROJECT_ACTIVITIES_REQUEST, ACTION_TYPES.LIST_SWM_SUB_PROJECT_ACTIVITIES_SUCCESS,
            ACTION_TYPES.LIST_SWM_SUB_PROJECT_ACTIVITIES_FAILURE],
            data
        }
    };
};

export const createProjectsActivity = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ACTIVITY.CREATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.CREATE_ACTIVITY_REQUEST, ACTION_TYPES.CREATE_ACTIVITY_SUCCESS, ACTION_TYPES.CREATE_ACTIVITY_FAILURE],
            data
        }
    };
};

export const projectsActivityUpdate = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ACTIVITY.CREATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_ACTIVITY_REQUEST, ACTION_TYPES.UPDATE_ACTIVITY_SUCCESS, ACTION_TYPES.UPDATE_ACTIVITY_FAILURE],
            data
        }
    };
};

export const swmSubProjectScreening = ({ id }) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.SUBMIT_SCREENING.replace(":id", id),
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.SUBMIT_SWM_SUB_PROJECT_REQUEST, ACTION_TYPES.SUBMIT_SWM_SUB_PROJECT_SUCCESS, ACTION_TYPES.SUBMIT_SWM_SUB_PROJECT_FAILURE]
        }
    };
};

export const fetchProjectActivityByID = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ACTIVITY.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PROJECT_ACTIVITY_BY_ID_REQUEST, ACTION_TYPES.FETCH_PROJECT_ACTIVITY_BY_ID_SUCCESS, ACTION_TYPES.FETCH_PROJECT_ACTIVITY_BY_ID_FAILURE],
            data
        }
    };
};

export const fetchUomList = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ACTIVITY.UOM,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.UOM_LIST_REQUEST, ACTION_TYPES.UOM_LIST_SUCCESS, ACTION_TYPES.UOM_LIST_FAILURE],
            data
        }
    };
};

export const postSwmScreeningDataApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.POST_SCREENING,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SWM_SCREENING_DATA_REQUEST, ACTION_TYPES.POST_SWM_SCREENING_DATA_SUCCESS, ACTION_TYPES.POST_SWM_SCREENING_DATA_FAILURE],
            data
        }
    };
};

export const deleteProjectActivity = (id) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ACTIVITY.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_PROJECT_ACTIVITY_REQUEST, ACTION_TYPES.DELETE_PROJECT_ACTIVITY_SUCCESS, ACTION_TYPES.DELETE_PROJECT_ACTIVITY_FAILURE]
        }
    };
};
export const patchSwmScreeningDataApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.POST_SCREENING,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_SWM_SCREENING_DATA_REQUEST, ACTION_TYPES.PATCH_SWM_SCREENING_DATA_SUCCESS, ACTION_TYPES.PATCH_SWM_SCREENING_DATA_FAILURE],
            data
        }
    };
};

export const getScreeningDataByIdApi = (id) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.GET_SCREENING.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SWM_SCREENING_DATA_REQUEST, ACTION_TYPES.FETCH_SWM_SCREENING_DATA_SUCCESS, ACTION_TYPES.FETCH_SWM_SCREENING_DATA_FAILURE]
        }
    };
};

export const deleteAttachment = (payload) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ACTIVITY.DELETE_ATTACHMENT.replace(":id", Number(payload.id)).replace(":attachmentId", payload.attachmentIds),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_ATTACHMENT_REQUEST, ACTION_TYPES.DELETE_ATTACHMENT_SUCCESS, ACTION_TYPES.DELETE_ATTACHMENT_FAILURE]
        }
    };
};
// vikasana seminar
export const vikasanaSeminarSave = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.VIKASANA_SEMINAR.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR_REQUEST, ACTION_TYPES.POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR_SUCCESS, ACTION_TYPES.POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR_FAILURE],
        data
    }
});

export const vikasanaSeminarFetch = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.VIKASANA_SEMINAR.BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_REQUEST, ACTION_TYPES.FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_SUCCESS, ACTION_TYPES.FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_FAILURE],
        data
    }
});

export const vikasanaSeminarPatch = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.VIKASANA_SEMINAR.SAVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_REQUEST, ACTION_TYPES.PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_SUCCESS, ACTION_TYPES.PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_FAILURE],
        data
    }
});

export const submitHscApprovalApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.SUBMIT_HSC_APPROVAL,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SWM_HSC_APPROVAL_REQUEST, ACTION_TYPES.POST_SWM_HSC_APPROVAL_SUCCESS, ACTION_TYPES.POST_SWM_HSC_APPROVAL_FAILURE],
            data
        }
    };
};

export const rejectHscApprovalApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.REJECT_HSC_APPROVAL,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SWM_HSC_APPROVAL_REQUEST, ACTION_TYPES.POST_SWM_HSC_APPROVAL_SUCCESS, ACTION_TYPES.POST_SWM_HSC_APPROVAL_FAILURE],
            data
        }
    };
};

export const getAdpProcessByIdApi = (payload) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.GET_ADP_PROCESS.replace(":id", payload?.projectId),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SWM_ADP_PROCESS_REQUEST, ACTION_TYPES.FETCH_SWM_ADP_PROCESS_SUCCESS, ACTION_TYPES.FETCH_SWM_ADP_PROCESS_FAILURE]
        }
    };
};

export const getEnvironmentalSafeguardByIdApi = (id) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENV_SAFEGUARD_BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID_REQUEST, ACTION_TYPES.SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID_SUCCESS, ACTION_TYPES.SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID_FAILURE]
        }
    };
};

export const submitMcApprovalApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.SUBMIT_MC_APPROVAL,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SWM_MC_APPROVAL_REQUEST, ACTION_TYPES.POST_SWM_MC_APPROVAL_SUCCESS, ACTION_TYPES.POST_SWM_MC_APPROVAL_FAILURE],
            data
        }
    };
};

export const rejectMcApprovalApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.REJECT_MC_APPROVAL,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SWM_MC_APPROVAL_REQUEST, ACTION_TYPES.POST_SWM_MC_APPROVAL_SUCCESS, ACTION_TYPES.POST_SWM_MC_APPROVAL_FAILURE],
            data
        }
    };
};
export const submitDpcApprovalApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.SUBMIT_DPC_APPROVAL,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SWM_DPC_APPROVAL_REQUEST, ACTION_TYPES.POST_SWM_DPC_APPROVAL_SUCCESS, ACTION_TYPES.POST_SWM_DPC_APPROVAL_FAILURE],
            data
        }
    };
};
export const safeGuardFetch = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.SAFE_GUARD.BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_SAFE_GUARD_REQUEST, ACTION_TYPES.FETCH_SAFE_GUARD_SUCCESS, ACTION_TYPES.FETCH_SAFE_GUARD_FAILURE],
        data
    }
});

export const safeGuardFetchList = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.SAFE_GUARD.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_SOCIAL_SAFEGUARD_REQUEST, ACTION_TYPES.LIST_SOCIAL_SAFEGUARD_SUCCESS, ACTION_TYPES.LIST_SOCIAL_SAFEGUARD_FAILURE],
        data
    }
});

export const postSafeGuardDetails = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.SAFE_GUARD.POST,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_SAFE_GUARD_REQUEST, ACTION_TYPES.POST_SAFE_GUARD_SUCCESS, ACTION_TYPES.POST_SAFE_GUARD_FAILURE],
        data
    }
});
export const patchSafeGuardDetails = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.SAFE_GUARD.POST,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_SAFE_GUARD_REQUEST, ACTION_TYPES.PATCH_SAFE_GUARD_SUCCESS, ACTION_TYPES.PATCH_SAFE_GUARD_FAILURE],
        data
    }
});
export const fetchRemarkList = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.REMARK_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.REMARK_DROPDOWN_REQUEST, ACTION_TYPES.REMARK_DROPDOWN_SUCCESS, ACTION_TYPES.REMARK_DROPDOWN_FAILURE],
            data
        }
    };
};

export const postEnvironmentalSafeguardApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_REQUEST, ACTION_TYPES.POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_SUCCESS, ACTION_TYPES.POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_FAILURE],
            data
        }
    };
};

export const patchEnvironmentalSafeguardApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_REQUEST, ACTION_TYPES.PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_SUCCESS, ACTION_TYPES.PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_FAILURE],
            data
        }
    };
};

export const fetchAnnualPlanYearList = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ANNUAL_PLAN_YEAR_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ANNUAL_PLAN_YEAR_REQUEST, ACTION_TYPES.LIST_ANNUAL_PLAN_YEAR_SUCCESS, ACTION_TYPES.LIST_ANNUAL_PLAN_YEAR_FAILURE],
            data: { id: data }
        }
    };
};

export const vikasanaSeminarCommonSave = (data) => ({
    url: API_URL.DISCUSSIONS_AND_APPROVAL.VIKASANA_SEMINAR_COMMON.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_VIKASANA_SEMINAR_COMMON_REQUEST, ACTION_TYPES.SAVE_VIKASANA_SEMINAR_COMMON_SUCCESS, ACTION_TYPES.SAVE_VIKASANA_SEMINAR_COMMON_FAILURE],
        data
    }
});

export const fetchVikasanaSeminarList = (data) => {
    return {
        url: API_URL.DISCUSSIONS_AND_APPROVAL.VIKASANA_SEMINAR_COMMON.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_VIKASANA_SEMINAR_COMMON_REQUEST, ACTION_TYPES.LIST_ALL_VIKASANA_SEMINAR_COMMON_SUCCESS, ACTION_TYPES.LIST_ALL_VIKASANA_SEMINAR_COMMON_FAILURE],
            data: { annualPlanId: data?.annualPlanYearId?.id, lsgiId: data?.lsgiId }
        }
    };
};

export const fetchVikasanaSeminarById = (data) => {
    return {
        url: API_URL.DISCUSSIONS_AND_APPROVAL.VIKASANA_SEMINAR_COMMON.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.VIKASANA_SEMINAR_COMMON_BY_ID_REQUEST, ACTION_TYPES.VIKASANA_SEMINAR_COMMON_BY_ID_SUCCESS, ACTION_TYPES.VIKASANA_SEMINAR_COMMON_BY_ID_FAILURE],
            data: { id: data?.id, lsgiId: data?.lsgiId }
        }
    };
};

export const fetchWorkingGroupDiscussionList = (data) => {
    return {
        url: API_URL.DISCUSSIONS_AND_APPROVAL.WORKING_GROUP_DISCUSSION_COMMON.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON_REQUEST, ACTION_TYPES.LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON_SUCCESS, ACTION_TYPES.LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON_FAILURE],
            data: { annualPlanId: data?.annualPlanYearId?.id, lsgiId: data?.lsgiId }
        }
    };
};

export const fetchWorkingGroupDiscussionById = (data) => {
    return {
        url: API_URL.DISCUSSIONS_AND_APPROVAL.WORKING_GROUP_DISCUSSION_COMMON.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.WORKING_GROUP_DISCUSSION_COMMON_BY_ID_REQUEST, ACTION_TYPES.WORKING_GROUP_DISCUSSION_COMMON_BY_ID_SUCCESS, ACTION_TYPES.WORKING_GROUP_DISCUSSION_COMMON_BY_ID_FAILURE],
            data: { id: data?.id, lsgiId: data?.lsgiId?.id }
        }
    };
};

export const hscApprovalCommonSave = (data) => ({
    url: API_URL.DISCUSSIONS_AND_APPROVAL.HSC_APPROVAL.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_HSC_APPROVAL_COMMON_REQUEST, ACTION_TYPES.SAVE_HSC_APPROVAL_COMMON_SUCCESS, ACTION_TYPES.SAVE_HSC_APPROVAL_COMMON_FAILURE],
        data
    }
});

export const fetchHscApprovalCommonList = (data) => {
    return {
        url: API_URL.DISCUSSIONS_AND_APPROVAL.HSC_APPROVAL.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_HSC_APPROVAL_COMMON_REQUEST, ACTION_TYPES.LIST_ALL_HSC_APPROVAL_COMMON_SUCCESS, ACTION_TYPES.LIST_ALL_HSC_APPROVAL_COMMON_FAILURE],
            data: { annualPlanId: data?.annualPlanYearId?.id, lsgiId: data?.lsgiId }
        }
    };
};

export const mcApprovalCommonSave = (data) => ({
    url: API_URL.DISCUSSIONS_AND_APPROVAL.MC_APPROVAL.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_MC_APPROVAL_COMMON_REQUEST, ACTION_TYPES.SAVE_MC_APPROVAL_COMMON_SUCCESS, ACTION_TYPES.SAVE_MC_APPROVAL_COMMON_FAILURE],
        data
    }
});

export const dpcApprovalCommonSave = (data) => ({
    url: API_URL.DISCUSSIONS_AND_APPROVAL.DPC_APPROVAL_COMMON.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_DPC_APPROVAL_COMMON_REQUEST, ACTION_TYPES.SAVE_DPC_APPROVAL_COMMON_SUCCESS, ACTION_TYPES.SAVE_DPC_APPROVAL_COMMON_FAILURE],
        data
    }
});

export const fetchDpcApprovalCommonList = (data) => {
    return {
        url: API_URL.DISCUSSIONS_AND_APPROVAL.DPC_APPROVAL_COMMON.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_DPC_APPROVAL_COMMON_REQUEST, ACTION_TYPES.LIST_ALL_DPC_APPROVAL_COMMON_SUCCESS, ACTION_TYPES.LIST_ALL_DPC_APPROVAL_COMMON_FAILURE],
            data: { annualPlanId: data?.annualPlanYearId?.id, lsgiId: data?.lsgiId }
        }
    };
};

export const swmSubProjectScreeningListAll = ({ id }) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.LIST_ALL_GET_SCREENING.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_SWM_SCREENING_REQUEST, ACTION_TYPES.LIST_ALL_SWM_SCREENING_SUCCESS, ACTION_TYPES.LIST_ALL_SWM_SCREENING_FAILURE]
        }
    };
};

export const swmSubProjectGroupDiscussionListAll = ({ projectId }) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.WORKING_GROUP_DISCUSSION.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_GROUP_DISCUSSION_REQUEST, ACTION_TYPES.LIST_ALL_GROUP_DISCUSSION_SUCCESS, ACTION_TYPES.LIST_ALL_GROUP_DISCUSSION_FAILURE],
            data: { projectId: projectId }
        }
    };
};

export const swmVikasanaSeminarListAll = ({ projectId }) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.VIKASANA_SEMINAR.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_VIKASANA_SEMINAR_REQUEST, ACTION_TYPES.LIST_ALL_VIKASANA_SEMINAR_SUCCESS, ACTION_TYPES.LIST_ALL_VIKASANA_SEMINAR_FAILURE],
            data: { projectId: projectId }
        }
    };
};

export const swmAdpProcessListAll = ({ projectId }) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.LIST_ALL_ADP_PROCESS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_APD_PROCESS_REQUEST, ACTION_TYPES.LIST_ALL_APD_PROCESS_SUCCESS, ACTION_TYPES.LIST_ALL_APD_PROCESS_FAILURE],
            data: { projectId: projectId }
        }
    };
};

export const swmFinancialSanctionAll = ({ projectId }) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.LIST_ALL_ADP_PROCESS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_APD_PROCESS_REQUEST, ACTION_TYPES.LIST_ALL_APD_PROCESS_SUCCESS, ACTION_TYPES.LIST_ALL_APD_PROCESS_FAILURE],
            data: { projectId: projectId }
        }
    };
};

export const updateDpcApprovalApi = (data) => {
    return {
        url: API_URL.DISCUSSIONS_AND_APPROVAL.DPC_APPROVAL_COMMON.PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_SWM_DPC_APPROVAL_REQUEST, ACTION_TYPES.PATCH_SWM_DPC_APPROVAL_SUCCESS, ACTION_TYPES.PATCH_SWM_DPC_APPROVAL_FAILURE],
            data
        }
    };
};

export const updateMcApprovalApi = (data) => {
    return {
        url: API_URL.DISCUSSIONS_AND_APPROVAL.MC_APPROVAL.PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_SWM_MC_APPROVAL_REQUEST, ACTION_TYPES.PATCH_SWM_MC_APPROVAL_SUCCESS, ACTION_TYPES.PATCH_SWM_MC_APPROVAL_FAILURE],
            data
        }
    };
};

export const updateHscApprovalApi = (data) => {
    return {
        url: API_URL.DISCUSSIONS_AND_APPROVAL.HSC_APPROVAL.PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_SWM_HSC_APPROVAL_REQUEST, ACTION_TYPES.PATCH_SWM_HSC_APPROVAL_SUCCESS, ACTION_TYPES.PATCH_SWM_HSC_APPROVAL_FAILURE
            ],
            data
        }
    };
};

export const fetchProjectLevelDataListApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.PROJECT_SOCIAL_SAFEGUARD.PROJECT_LEVEL_DATA_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_PROJECT_LEVEL_DATA_REQUEST, ACTION_TYPES.GET_PROJECT_LEVEL_DATA_SUCCESS, ACTION_TYPES.GET_PROJECT_LEVEL_DATA_FAILURE],
            data
        }
    };
};

export const fetchProjectSocialSafeguardStatusDropdownApi = (query = {}) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.PROJECT_SOCIAL_SAFEGUARD.STATUS_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN_REQUEST, ACTION_TYPES.GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN_SUCCESS, ACTION_TYPES.GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const postProjectLevelDataApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.PROJECT_SOCIAL_SAFEGUARD.PROJECT_LEVEL_DATA_POST,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_PROJECT_LEVEL_DATA_REQUEST, ACTION_TYPES.POST_PROJECT_LEVEL_DATA_SUCCESS, ACTION_TYPES.POST_PROJECT_LEVEL_DATA_FAILURE],
        data
    }
});

export const patchProjectLevelDataApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.PROJECT_SOCIAL_SAFEGUARD.PROJECT_LEVEL_DATA_POST,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_PROJECT_LEVEL_DATA_REQUEST, ACTION_TYPES.PATCH_PROJECT_LEVEL_DATA_SUCCESS, ACTION_TYPES.PATCH_PROJECT_LEVEL_DATA_FAILURE],
        data
    }
});

export const fetchSafeguardComplianceApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.PROJECT_SOCIAL_SAFEGUARD.SAFEGUARD_COMPLIANCE_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_SAFEGUARD_COMPLIANCE_REQUEST, ACTION_TYPES.GET_SAFEGUARD_COMPLIANCE_SUCCESS, ACTION_TYPES.GET_SAFEGUARD_COMPLIANCE_FAILURE],
            data
        }
    };
};

export const postSafeguardComplianceApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.PROJECT_SOCIAL_SAFEGUARD.SAFEGUARD_COMPLIANCE_POST,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_SAFEGUARD_COMPLIANCE_REQUEST, ACTION_TYPES.POST_SAFEGUARD_COMPLIANCE_SUCCESS, ACTION_TYPES.POST_SAFEGUARD_COMPLIANCE_FAILURE],
        data
    }
});

export const patchSafeguardComplianceApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.PROJECT_SOCIAL_SAFEGUARD.SAFEGUARD_COMPLIANCE_POST,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_SAFEGUARD_COMPLIANCE_REQUEST, ACTION_TYPES.PATCH_SAFEGUARD_COMPLIANCE_SUCCESS, ACTION_TYPES.PATCH_SAFEGUARD_COMPLIANCE_FAILURE],
        data
    }
});

export const fetchCommunityConsultationApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.PROJECT_SOCIAL_SAFEGUARD.COMMUNITY_CONSULTATION_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_COMMUNITY_CONSULTATION_REQUEST, ACTION_TYPES.GET_COMMUNITY_CONSULTATION_SUCCESS, ACTION_TYPES.GET_COMMUNITY_CONSULTATION_FAILURE],
            data
        }
    };
};

export const postCommunityConsultationApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.PROJECT_SOCIAL_SAFEGUARD.COMMUNITY_CONSULTATION_POST,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_COMMUNITY_CONSULTATION_REQUEST, ACTION_TYPES.POST_COMMUNITY_CONSULTATION_SUCCESS, ACTION_TYPES.POST_COMMUNITY_CONSULTATION_FAILURE],
        data
    }
});

export const patchCommunityConsultationApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.PROJECT_SOCIAL_SAFEGUARD.COMMUNITY_CONSULTATION_POST,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_COMMUNITY_CONSULTATION_REQUEST, ACTION_TYPES.PATCH_COMMUNITY_CONSULTATION_SUCCESS, ACTION_TYPES.PATCH_COMMUNITY_CONSULTATION_FAILURE],
        data
    }
});

export const typeOfActivityDropdownApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.TYPE_OF_ACTIVITY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_TYPE_OF_ACTIVITY_DROP_DOWN_REQUEST, ACTION_TYPES.GET_TYPE_OF_ACTIVITY_DROP_DOWN_SUCCESS, ACTION_TYPES.GET_TYPE_OF_ACTIVITY_DROP_DOWN_FAILURE],
            data
        }
    };
};

export const phaseOneScreeningDropdownApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.PHASE_ONE_SCREENING,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_PHASE_ONE_SCREENING_DROPDOWN_REQUEST, ACTION_TYPES.GET_PHASE_ONE_SCREENING_DROPDOWN_SUCCESS, ACTION_TYPES.GET_PHASE_ONE_SCREENING_DROPDOWN_FAILURE],
            data
        }
    };
};

export const phaseTwoScreeningDropdownApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.PHASE_TWO_SCREENING,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_PHASE_TWO_SCREENING_DROPDOWN_REQUEST, ACTION_TYPES.GET_PHASE_TWO_SCREENING_DROPDOWN_SUCCESS, ACTION_TYPES.GET_PHASE_TWO_SCREENING_DROPDOWN_FAILURE],
            data
        }
    };
};

export const esgScreeningResponsibilityApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.ESG_SCREENING,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN_REQUEST, ACTION_TYPES.GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN_SUCCESS, ACTION_TYPES.GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN_FAILURE],
            data
        }
    };
};

export const environmentalCategoryApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.ENVIRONMENTAL_CATEGORY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN_REQUEST, ACTION_TYPES.GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN_SUCCESS, ACTION_TYPES.GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN_FAILURE],
            data
        }
    };
};

export const esgInstrumentApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.ESG_INSTRUMENT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ESG_INSTRUMENT_DROPDOWN_REQUEST, ACTION_TYPES.GET_ESG_INSTRUMENT_DROPDOWN_SUCCESS, ACTION_TYPES.GET_ESG_INSTRUMENT_DROPDOWN_FAILURE],
            data
        }
    };
};

export const dueDiligenceApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.DUE_DILIGENCE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_DUE_DILIGENCE_DROPDOWN_REQUEST, ACTION_TYPES.GET_DUE_DILIGENCE_DROPDOWN_SUCCESS, ACTION_TYPES.GET_DUE_DILIGENCE_DROPDOWN_FAILURE],
            data
        }
    };
};

export const dueDiligenceTypeApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.DUE_DILIGENCE_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_DUE_DILIGENCE_TYPE_DROPDOWN_REQUEST, ACTION_TYPES.GET_DUE_DILIGENCE_TYPE_DROPDOWN_SUCCESS, ACTION_TYPES.GET_DUE_DILIGENCE_TYPE_DROPDOWN_FAILURE],
            data
        }
    };
};

export const reasonUnderEiaNotificationApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.REASON_UNDER_EIA,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_REASON_UNDER_EIA_DROPDOWN_REQUEST, ACTION_TYPES.GET_REASON_UNDER_EIA_DROPDOWN_SUCCESS, ACTION_TYPES.GET_REASON_UNDER_EIA_DROPDOWN_FAILURE],
            data
        }
    };
};

export const typeDiligenceRequiredApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.TYPE_DILIGENCE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN_REQUEST, ACTION_TYPES.GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN_SUCCESS, ACTION_TYPES.GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN_FAILURE],
            data
        }
    };
};

export const getEsgDocumentPreparationResponsibilityApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.ESG_DOCUMENT_PREPARATION_RESPONSIBILITY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN_REQUEST, ACTION_TYPES.GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN_SUCCESS, ACTION_TYPES.GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN_FAILURE],
            data
        }
    };
};

export const finalEsgPreparedAndSubmittedApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.FINAL_ESG_SUBMITTED,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN_REQUEST, ACTION_TYPES.GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN_SUCCESS, ACTION_TYPES.GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN_FAILURE],
            data
        }
    };
};

export const finalEsgApprovalResponsibilityApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.FINAL_ESG_APPROVAL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN_REQUEST, ACTION_TYPES.GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN_SUCCESS, ACTION_TYPES.GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN_FAILURE],
            data
        }
    };
};

export const responsibilityForEsgSupervisionApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.ESG_SUPERVISION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN_REQUEST, ACTION_TYPES.GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN_SUCCESS, ACTION_TYPES.GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN_FAILURE],
            data
        }
    };
};

export const environmentalProjectDataByIdApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.PROJECT_DATA_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_REQUEST, ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_SUCCESS, ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_FAILURE],
            data
        }
    };
};

export const environmentalProjectDataListApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.PROJECT_DATA_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_LIST_REQUEST, ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_LIST_SUCCESS, ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_LIST_SUCCESS],
            data
        }
    };
};

export const postEnvironmentalProjectDataApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.POST_PROJECT_DATA,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_ENVIRONMENTAL_PROJECT_DATA_REQUEST, ACTION_TYPES.POST_ENVIRONMENTAL_PROJECT_DATA_SUCCESS, ACTION_TYPES.POST_ENVIRONMENTAL_PROJECT_DATA_FAILURE],
        data
    }
});

export const patchEnvironmentalProjectDataApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.PATCH_PROJECT_DATA,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_ENVIRONMENTAL_PROJECT_DATA_REQUEST, ACTION_TYPES.PATCH_ENVIRONMENTAL_PROJECT_DATA_SUCCESS, ACTION_TYPES.PATCH_ENVIRONMENTAL_PROJECT_DATA_FAILURE],
        data
    }
});

export const typesWasteManagedApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.TYPES_OF_WASTE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_TYPES_OF_WASTE_MANAGED_DROPDOWN_REQUEST, ACTION_TYPES.GET_TYPES_OF_WASTE_MANAGED_DROPDOWN_SUCCESS, ACTION_TYPES.GET_TYPES_OF_WASTE_MANAGED_DROPDOWN_FAILURE],
            data
        }
    };
};

export const typeOfManagementOfWasteApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.TYPES_OF_MANAGEMENT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN_REQUEST, ACTION_TYPES.GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN_SUCCESS, ACTION_TYPES.GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN_FAILURE],
            data
        }
    };
};

export const arrangementOfRecyclingApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.ARRANGEMENT_RECYCLE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN_REQUEST, ACTION_TYPES.GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN_SUCCESS, ACTION_TYPES.GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN_FAILURE],
            data
        }
    };
};

export const categoryOfEddIssueApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.CATEGORY_OF_EDD_ISSUE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN_REQUEST, ACTION_TYPES.GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN_SUCCESS, ACTION_TYPES.GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN_FAILURE],
            data
        }
    };
};

export const sourceOfEddIdentificationApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.SOURCE_OF_EDD,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN_REQUEST, ACTION_TYPES.GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN_SUCCESS, ACTION_TYPES.GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN_FAILURE],
            data
        }
    };
};

export const statusOfEddIssueResolutionApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.STATUS_OF_EDD_ISSUE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN_REQUEST, ACTION_TYPES.GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN_SUCCESS, ACTION_TYPES.GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN_FAILURE],
            data
        }
    };
};

export const esgIssueAffectApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.ESG_ISSUE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ESG_ISSUE_AFFECT_DROPDOWN_REQUEST, ACTION_TYPES.GET_ESG_ISSUE_AFFECT_DROPDOWN_SUCCESS, ACTION_TYPES.GET_ESG_ISSUE_AFFECT_DROPDOWN_FAILURE],
            data
        }
    };
};

export const permitsConsentsRequiredApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.PERMIT_CONSENT_REQUIRED,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_PERMITS_CONSENT_REQUIRED_DROPDOWN_REQUEST, ACTION_TYPES.GET_PERMITS_CONSENT_REQUIRED_DROPDOWN_SUCCESS, ACTION_TYPES.GET_PERMITS_CONSENT_REQUIRED_DROPDOWN_FAILURE],
            data
        }
    };
};

export const incidentClassificationApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.INCIDENT_CLASSIFICATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_INCIDENT_CLASSIFICATION_DROPDOWN_REQUEST, ACTION_TYPES.GET_INCIDENT_CLASSIFICATION_DROPDOWN_SUCCESS, ACTION_TYPES.GET_INCIDENT_CLASSIFICATION_DROPDOWN_FAILURE],
            data
        }
    };
};

export const statusOfCompensationApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.STATUS_OF_COMPENSATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_STATUS_OF_COMPENSATION_DROPDOWN_REQUEST, ACTION_TYPES.GET_STATUS_OF_COMPENSATION_DROPDOWN_SUCCESS, ACTION_TYPES.GET_STATUS_OF_COMPENSATION_DROPDOWN_FAILURE],
            data
        }
    };
};

export const incidentImplementationApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.INCIDENT_IMPLEMENTATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN_REQUEST, ACTION_TYPES.GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN_SUCCESS, ACTION_TYPES.GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN_FAILURE],
            data
        }
    };
};

export const attendeeProfileApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.ATTENDEE_PROFILE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ATTENDEE_PROFILE_DROPDOWN_REQUEST, ACTION_TYPES.GET_ATTENDEE_PROFILE_DROPDOWN_SUCCESS, ACTION_TYPES.GET_ATTENDEE_PROFILE_DROPDOWN_FAILURE],
            data
        }
    };
};

export const responsibleAgencyApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.RESPONSIBLE_AGENCY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_RESPONSIBLE_AGENCY_DROPDOWN_REQUEST, ACTION_TYPES.GET_RESPONSIBLE_AGENCY_DROPDOWN_SUCCESS, ACTION_TYPES.GET_RESPONSIBLE_AGENCY_DROPDOWN_FAILURE],
            data
        }
    };
};

export const statusOfPermitsApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.STATUS_OF_PERMITS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_STATUS_OF_PERMIT_DROPDOWN_REQUEST, ACTION_TYPES.GET_STATUS_OF_PERMIT_DROPDOWN_SUCCESS, ACTION_TYPES.GET_STATUS_OF_PERMIT_DROPDOWN_FAILURE],
            data
        }
    };
};

export const postEnvironmentalCompendiumDataApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.POST_COMPENDIUM_DATA,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_ENVIRONMENTAL_COMPENDIUM_DATA_REQUEST, ACTION_TYPES.POST_ENVIRONMENTAL_COMPENDIUM_DATA_SUCCESS, ACTION_TYPES.POST_ENVIRONMENTAL_COMPENDIUM_DATA_FAILURE],
        data
    }
});

export const patchEnvironmentalCompendiumDataApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.PATCH_COMPENDIUM_DATA,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_ENVIRONMENTAL_COMPENDIUM_DATA_REQUEST, ACTION_TYPES.PATCH_ENVIRONMENTAL_COMPENDIUM_DATA_SUCCESS, ACTION_TYPES.PATCH_ENVIRONMENTAL_COMPENDIUM_DATA_FAILURE],
        data
    }
});

export const environmentalCompendiumDataByIdApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.COMPENDIUM_DATA_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_REQUEST, ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_SUCCESS, ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_FAILURE],
            data
        }
    };
};

export const environmentalCompendiumDataListAllApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.ENVIRONMENTAL_SAFEGUARD.COMPENDIUM_DATA_LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_REQUEST, ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_SUCCESS, ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_FAILURE],
            data
        }
    };
};

export const fetchFinancialYearDropDownApi = () => ({
    url: API_URL.SWM_SUB_PROJECTS.SULEKHA_INFORMATION.FINANCIAL_YEAR_DROPDOWN,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.GET_FINANCIAL_YEAR_DROP_DOWN_REQUEST, ACTION_TYPES.GET_FINANCIAL_YEAR_DROP_DOWN_SUCCESS, ACTION_TYPES.GET_FINANCIAL_YEAR_DROP_DOWN_FAILURE],
        data: { yearCodeAvailable: true }
    }
});

export const fetchSulekhaProjectSaveApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.SULEKHA_INFORMATION.SULEKHA_PROJECT_SAVE,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.GET_SULEKHA_PROJECT_SAVE_REQUEST, ACTION_TYPES.GET_SULEKHA_PROJECT_SAVE_SUCCESS, ACTION_TYPES.GET_SULEKHA_PROJECT_SAVE_SUCCESS],
        data
    }
});

export const fetchSulekhaProjectDropDownApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.SULEKHA_INFORMATION.SULEKHA_PROJECT_DROP_DOWN,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.GET_SULEKHA_PROJECT_DROP_DOWN_REQUEST, ACTION_TYPES.GET_SULEKHA_PROJECT_DROP_DOWN_SUCCESS, ACTION_TYPES.GET_SULEKHA_PROJECT_DROP_DOWN_FAILURE],
        data: { ...data, dropDown: true }
    }
});

export const fetchSulekhaProjectListAllApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.SULEKHA_INFORMATION.SULEKHA_PROJECT_LIST_ALL,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_SULEKHA_PROJECT_LIST_ALL_REQUEST, ACTION_TYPES.FETCH_SULEKHA_PROJECT_LIST_ALL_SUCCESS, ACTION_TYPES.FETCH_SULEKHA_PROJECT_LIST_ALL_FAILURE],
        data
    }
});

export const fetchSulekhaProjectApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.SULEKHA_INFORMATION.FETCH_SULEKHA_PROJECT,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_SULEKHA_PROJECT_REQUEST, ACTION_TYPES.FETCH_SULEKHA_PROJECT_SUCCESS, ACTION_TYPES.FETCH_SULEKHA_PROJECT_FAILURE],
        data
    }
});

export const fetchSulekhaProjectByIdApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.SULEKHA_INFORMATION.FETCH_SULEKHA_BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_SULEKHA_PROJECT_BY_ID_REQUEST, ACTION_TYPES.FETCH_SULEKHA_PROJECT_BY_ID_SUCCESS, ACTION_TYPES.FETCH_SULEKHA_PROJECT_BY_ID_FAILURE],
        data
    }
});

export const revertSulekhaProjectApi = (data) => ({
    url: API_URL.SWM_SUB_PROJECTS.SULEKHA_INFORMATION.REVERT_SULEKHA,
    method: REQUEST_METHOD.DELETE,
    payload: {
        types: [ACTION_TYPES.REVERT_SULEKHA_PROJECT_REQUEST, ACTION_TYPES.REVERT_SULEKHA_PROJECT_SUCCESS, ACTION_TYPES.REVERT_SULEKHA_PROJECT_FAILURE],
        data
    }
});

export const getProjectCodeDropdownApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.PROJECT_CODE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_PROJECT_CODE_DROPDOWN_REQUEST, ACTION_TYPES.GET_PROJECT_CODE_DROPDOWN_SUCCESS, ACTION_TYPES.GET_PROJECT_CODE_DROPDOWN_FAILURE],
            data
        }
    };
};

export const postFacilityAndLocation = (data) => {
    let REQUEST = "";
    if (data?.id) {
        REQUEST = REQUEST_METHOD.PATCH;
    } else {
        REQUEST = REQUEST_METHOD.POST;
    }
    return {
        url: API_URL.SWM_SUB_PROJECTS.FACILITY_AND_LOCATION.SAVE,
        method: REQUEST,
        payload: {
            types: [ACTION_TYPES.POST_FACILITIES_AND_LOCATION_REQUEST, ACTION_TYPES.POST_FACILITIES_AND_LOCATION_SUCCESS, ACTION_TYPES.POST_FACILITIES_AND_LOCATION_FAILURE], data
        }
    };
};

export const fetchFacilitiesAndLocationApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.FACILITY_AND_LOCATION.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_FACILITIES_AND_LOCATION_LIST_REQUEST, ACTION_TYPES.FETCH_FACILITIES_AND_LOCATION_LIST_SUCCESS, ACTION_TYPES.FETCH_FACILITIES_AND_LOCATION_LIST_FAILURE], data
        }
    };
};

export const getFacilitiesAndLocationApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.FACILITY_AND_LOCATION.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_FACILITIES_AND_LOCATION_REQUEST, ACTION_TYPES.GET_FACILITIES_AND_LOCATION_SUCCESS, ACTION_TYPES.GET_FACILITIES_AND_LOCATION_FAILURE], data
        }
    };
};

export const deleteFacilityAndLocationApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.FACILITY_AND_LOCATION.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_FACILITIES_AND_LOCATION_REQUEST, ACTION_TYPES.DELETE_FACILITIES_AND_LOCATION_SUCCESS, ACTION_TYPES.DELETE_FACILITIES_AND_LOCATION_FAILURE]
        }
    };
};

export const getApprovalStatusDropdownApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.APPROVAL_STATUS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_APPROVAL_STATUS_DROPDOWN_REQUEST, ACTION_TYPES.GET_APPROVAL_STATUS_DROPDOWN_SUCCESS, ACTION_TYPES.GET_APPROVAL_STATUS_DROPDOWN_FAILURE], data
        }
    };
};

export const getSocialRiskCategorizationApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.SOCIAL_RISK_CATEGORIZATION.SOCIAL_RISK_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SOCIAL_RISK_CATEGORIZATION_DROPDOWN_REQUEST, ACTION_TYPES.SOCIAL_RISK_CATEGORIZATION_DROPDOWN_SUCCESS, ACTION_TYPES.SOCIAL_RISK_CATEGORIZATION_DROPDOWN_FAILURE], data
        }
    };
};

export const saveSocialRiskCategorizationApi = (data) => {
    let REQUEST = "";
    if (data?.id) {
        REQUEST = REQUEST_METHOD.PATCH;
    } else {
        REQUEST = REQUEST_METHOD.POST;
    }
    return {
        url: API_URL.SWM_SUB_PROJECTS.SOCIAL_RISK_CATEGORIZATION.SAVE,
        method: REQUEST,
        payload: {
            types: [ACTION_TYPES.SAVE_SOCIAL_RISK_CATEGORIZATION_REQUEST, ACTION_TYPES.SAVE_SOCIAL_RISK_CATEGORIZATION_SUCCESS, ACTION_TYPES.SAVE_SOCIAL_RISK_CATEGORIZATION_FAILURE], data
        }
    };
};

export const getSocialRiskCategorizationDataApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECTS.SOCIAL_RISK_CATEGORIZATION.GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_SOCIAL_RISK_CATEGORIZATION_REQUEST, ACTION_TYPES.GET_SOCIAL_RISK_CATEGORIZATION_SUCCESS, ACTION_TYPES.GET_SOCIAL_RISK_CATEGORIZATION_FAILURE], data
        }
    };
};
