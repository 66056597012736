import { PictureAsPdf } from "@mui/icons-material";
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Components, FormController, I18n } from "common/components";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { USER_ROLE_NAME } from "common/constants";
import { Form, withFormik } from "formik";
import { getFinancialYearDropdown } from "modules/subProjects/swmSubProjects/actions";
import { getFinancialYearDropDown } from "modules/subProjects/swmSubProjects/selector";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { dateFormat } from "utils/dateUtils";
import { getUserDetails } from "utils/userUtils";
import { downloadGur3Report, fetchGur3Report, quarterDropDownApi } from "../actions";
import { getGur3DataReport, getGur3DataReportReportInProgress, getQuarterDropdown } from "../selectors";
import { actions as sliceActions } from "../slice";
import LoadingCustomOverlay from "modules/common/components/LoadingOverlay";

const { Grid, Card, Box, IconButton, Typography} = Components;

const useStyles = makeStyles(() => ({
    tableContainer: {
        overflowX: "auto",
        maxWidth: "100%"
    },
    table: {
        minWidth: 600,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            fontSize: "18px"
        }
    }
}));

const ReportGUR3 = (props) => {
    const { requestInProgress= true, setFieldValue, financialYear, quarterDropDown, gur3Data, values } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [newPage, setNewPage] = useState(1);
    const { data: { activeRole = {} } = {} } = getUserDetails();

    useEffect(() => {
        setFieldValue("financialYear", "");
        dispatch(getFinancialYearDropdown());
        return () => {
            dispatch(sliceActions.clearGur3Report());
        };
    }, []);

    const handleQuarterDropDown = (e) => {
        setFieldValue("quarter", "");
        dispatch(sliceActions.clearGur3Report());
        dispatch(quarterDropDownApi(e));
    };

    const handleQuarterValue = (e) => {
        dispatch(fetchGur3Report({ pageSize: 10, pageNo: 0, financialYear: values?.financialYear?.name, quarter: e?.name }));
        setNewPage(1);
    };

    const pagination = (event, value) => {
        setNewPage(value);
        dispatch(fetchGur3Report({pageSize: 10, pageNo: value-1, financialYear: values?.financialYear?.name, quarter: values?.quarter?.name}));
    };

    let customActions = [];

    customActions.push({ title: I18n("download_pdf"), icon: <PictureAsPdf fontSize="small" />, handleClick: () => dispatch(downloadGur3Report({financialYear: values?.financialYear?.name, quarter: values?.quarter?.name, type: "pdf"})) });

    return (
        <>
            <Card sx={{ m: 2, boxShadow: "none", border: "1px solid #CFE7DE", overflow: "visible" }}>
                <Grid container >
                        <Grid item xs={12} md={12} sm={12} sx={{ p: 3 }}>
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormController control="select" name="financialYear" options={financialYear || []} label={I18n("financial_year")} onChangeFromController={(e) => handleQuarterDropDown(e)} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormController control="select" name="quarter" options={quarterDropDown || []} label={I18n("quarter")} onChangeFromController={(e) => handleQuarterValue(e)} />
                                    </Grid>
                                </Grid>
                            </Form>
                        </Grid>
                </Grid>
            </Card>
            <Box sx={{ maxHeight: "100%", maxWidth: "100%"}}>
                <LoadingCustomOverlay active={requestInProgress}>
                <Grid item lg={12} md={12} sm={12} sx={{display: "flex", justifyContent: "end", ml: 1}}>
                    <IconButton>
                        <CustomListMenu customActions={customActions} type="download"/>
                    </IconButton>
                </Grid>
                <Grid className={classes.tableContainer} >
                    <TableContainer sx={{maxHeight: {lg: "450px", xl: "600px"}}}>
                        {gur3Data?.content?.length > 0 ?
                        <Table sx={{ p: 1, minWidth: "300px", textAlign: "left" }} size="small" aria-label="a dense table" className={classes.table}>
                            <TableHead sx={{ fontSize: "18px", fontFamily: "Clash Display" }}>
                                <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                    <TableCell sx={{ minWidth: "3em", color: "#FFFF" }}>{I18n("sl_no")}</TableCell>
                                    {activeRole?.name === USER_ROLE_NAME.SPMU_USER_ROLE && <TableCell rowSpan={2} sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("district_name")}</TableCell>}
                                    {(activeRole?.name === USER_ROLE_NAME.DPMU_USER_ROLE || activeRole?.name === USER_ROLE_NAME.SPMU_USER_ROLE) && <TableCell rowSpan={2} sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("ulb_name")}</TableCell>}
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("name_of_the_works_or_procurement_expenditure")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("current_annual_plan")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("wo_or_po_number")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("wo_or_po_date")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("total_value_of_wo_or_po")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("amount_paid_in_reporting_quarter")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("total_amount_paid_during_the_financial_year")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("cumulative_paid_as_at_the_end_of_reporting_month")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("balance_amount_to_be_paid")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("percentage_of_work_done")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("percentage_of_work_pending_to_be_done")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ maxHeight: "20em", overflow: scroll }}>
                                    {gur3Data?.content?.map((data, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell sx={{ minWidth: "1em" }}>{data?.slNo}</TableCell>
                                                {activeRole?.name === USER_ROLE_NAME.SPMU_USER_ROLE && <TableCell sx={{ minWidth: "10em" }}>{data?.districtName}</TableCell>}
                                                {(activeRole?.name === USER_ROLE_NAME.DPMU_USER_ROLE || activeRole?.name === USER_ROLE_NAME.SPMU_USER_ROLE) && <TableCell sx={{ minWidth: "10em" }}>{data?.lsgiName}</TableCell>}
                                                <TableCell sx={{ minWidth: "20em" }}>{data?.workName}</TableCell>
                                                <TableCell sx={{ minWidth: "10em" }}>{data?.currentAnnualPlan ?? "----"}</TableCell>
                                                <TableCell sx={{ minWidth: "10em" }}>{data?.woPoNo ?? "----"}</TableCell>
                                                <TableCell sx={{ minWidth: "10em" }}>{dateFormat(data?.woPoDate) ?? "----"}</TableCell>
                                                <TableCell sx={{ minWidth: "1em" }}>{data?.woPoValue ?? "----"}</TableCell>
                                                <TableCell sx={{ minWidth: "20em" }}>{data?.amountPaidInRepQuarter?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                                <TableCell sx={{ minWidth: "10em" }}>{data?.amountPaidInFyYear?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                                <TableCell sx={{ minWidth: "10em" }}>{data?.cumulativePaidTillDate?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                                <TableCell sx={{ minWidth: "10em" }}>{data?.balanceToPay?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                                <TableCell sx={{ minWidth: "10em" }}>{data?.completedWork?.toFixed(3) ?? "----"}</TableCell>
                                                <TableCell sx={{ minWidth: "10em" }}>{data?.pendingWork?.toFixed(3) ?? "----"}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table> :
                        <Grid xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "20px", marginBottom: "20px", border: "solid #CFE7DE 1px", padding: 3, borderRadius: "12px"}}>
                            <Typography sx={{ fontSize: "14px", fontStyle: "italic", color: "#6B6B6B" }}>{I18n("no_records")}</Typography>
                        </Grid>}
                    </TableContainer>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "10px"}}>
                        <Pagination count={gur3Data?.totalPages} color="primary" page={newPage} onChange={pagination} />
                    </Grid>
                </Grid>
                </LoadingCustomOverlay>
            </Box>
        </>
    );
};
const mapStateToProps = createStructuredSelector({
    financialYear: getFinancialYearDropDown,
    quarterDropDown: getQuarterDropdown,
    gur3Data: getGur3DataReport,
    requestInProgress: getGur3DataReportReportInProgress
});

const ReportGUR3Form = withFormik({})(ReportGUR3);
export default connect(mapStateToProps, null)(ReportGUR3Form);
