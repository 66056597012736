import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const saveFinalPlan = (data) => {
    return {
        url: API_URL.SWM_PLAN_FINALIZE.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_FINAL_PLAN_REQUEST, ACTION_TYPES.SUBMIT_FINAL_PLAN_SUCCESS, ACTION_TYPES.SUBMIT_FINAL_PLAN_FAILURE],
            data
        }
    };
};

export const finalPlanByIdApi = (data) => {
    return {
        url: API_URL.SWM_PLAN_FINALIZE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FINAL_PLAN_BY_ID_REQUEST, ACTION_TYPES.FINAL_PLAN_BY_ID_SUCCESS, ACTION_TYPES.FINAL_PLAN_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const finalPlanGet = (data) => {
    return {
        url: API_URL.SWM_PLAN_FINALIZE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_FINAL_PLAN_BY_ID_REQUEST, ACTION_TYPES.LIST_FINAL_PLAN_BY_ID_SUCCESS, ACTION_TYPES.LIST_FINAL_PLAN_BY_ID_FAILURE],
            data: { draftPlan: data }
        }
    };
};
export const saveSubProject = (data) => {
    return {
        url: API_URL.SWM_PLAN_FINALIZE.SAVE_SUB_PROJECT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [
                ACTION_TYPES.SUBMIT_SUB_PROJECT_REQUEST,
                ACTION_TYPES.SUBMIT_SUB_PROJECT_SUCCESS,
                ACTION_TYPES.SUBMIT_SUB_PROJECT_FAILURE
            ],
            data
        }
    };
};
export const listSubProjectById = (data) => {
    return {
        url: API_URL.SWM_PLAN_FINALIZE.SUB_PROJECT_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_SUB_PROJECT_REQUEST, ACTION_TYPES.LIST_SUB_PROJECT_SUCCESS, ACTION_TYPES.LIST_SUB_PROJECT_FAILURE],
            data
        }
    };
};
export const listFinalSubProject = (data) => {
    return {
        url: API_URL.SWM_PLAN_FINALIZE.SUB_PROJECT_FINAL_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_FINAL_SUB_PROJECT_REQUEST, ACTION_TYPES.LIST_FINAL_SUB_PROJECT_SUCCESS, ACTION_TYPES.LIST_FINAL_SUB_PROJECT_FAILURE],
            data
        }
    };
};

export const deleteFileById = (id, fileId) => {
    let DELETE_API_URL = API_URL.SWM_PLAN_FINALIZE.DELETE_FILE_BY_ID;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":file_id", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [
                ACTION_TYPES.DELETE_FILE_REQUEST,
                ACTION_TYPES.DELETE_FILE_SUCCESS,
                ACTION_TYPES.DELETE_FILE_REQUEST
            ]
        }
    };
};

export const deleteFinalSubProject = (id) => {
    return {
        url: API_URL.SWM_PLAN_FINALIZE.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [
                ACTION_TYPES.DELETE_FINAL_SUB_PROJECT_REQUEST,
                ACTION_TYPES.DELETE_FINAL_SUB_PROJECT_SUCCESS,
                ACTION_TYPES.DELETE_FINAL_SUB_PROJECT_FAILURE
            ]
        }
    };
};

export const subProjectFinalById = (data) => {
    return {
        url: API_URL.SWM_PLAN_FINALIZE.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.LIST_SUB_PROJECT_FINAL_BY_ID_REQUEST,
                ACTION_TYPES.LIST_SUB_PROJECT_FINAL_BY_ID_SUCCESS,
                ACTION_TYPES.LIST_SUB_PROJECT_FINAL_BY_ID_FAILURE
            ],
            data: { id: data }
        }
    };
};
export const updateFinalSubProject = (data) => {
    return {
        url: API_URL.SWM_PLAN_FINALIZE.UPDATE_FINAL_SUB_PROJECT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [
                ACTION_TYPES.UPDATE_FINAL_SUB_PROJECT_REQUEST,
                ACTION_TYPES.UPDATE_FINAL_SUB_PROJECT_SUCCESS,
                ACTION_TYPES.UPDATE_FINAL_SUB_PROJECT_FAILURE
            ], data
        }
    };
};
export const finalSelectedPlanList = (data, id) => {
    return {
        url: `${API_URL.SWM_PLAN_FINALIZE.FINAL_MAPPED_LIST}?swmPlanId=${id}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECTED_PLAN_LIST_REQUEST,
                ACTION_TYPES.SELECTED_PLAN_LIST_SUCCESS,
                ACTION_TYPES.SELECTED_PLAN_LIST_FAILURE
            ], data
        }
    };
};
