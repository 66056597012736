import { put, all, takeLatest, call, select } from "redux-saga/effects";

import { ACTION_TYPES, setUsers, setUsersV2 } from "./actions";
import { testAPICall, testTableAPICall } from "./api";
import { handleAPIRequest } from "../../../utils/http";
import { errorNotify } from "utils/notificationUtils";
import { actions as sliceActions } from "./slice";
import { getSelectedRows, getTableProps } from "./selectors";
import { commonFileDownload } from "modules/common/actions";


export function* fetchUserDetails(action) {
    let age = action.payload;
    yield call(handleAPIRequest, testAPICall, { age });
}
export function* fetchUsers() {
    yield put(sliceActions.setLoading());
    let tableProps = yield select(getTableProps);
    try {
        const response = yield call(testTableAPICall, tableProps);
        yield put(setUsers(response));
    } catch (err) {
        yield put(errorNotify({ title: err.name, message: err?.message }));
        yield put(sliceActions.setError(err.message));

    }

}
export function* fetchUsersV2() {
    yield put(sliceActions.setLoading());
    let tableProps = yield select(getTableProps);
    try {
        const response = yield call(testTableAPICall, tableProps);
        yield put(setUsersV2(response));
    } catch (err) {
        yield put(errorNotify({ title: err.name, message: err?.message }));
        yield put(sliceActions.setError(err.message));

    }

}
export function* assignUser() {
    let selectedRows = yield select(getSelectedRows);
    yield call(handleAPIRequest, testAPICall, { selectedRows });
}

export function* downloadSampleReport(action) {
    const { fileName, ext } = action.payload;
    yield put(commonFileDownload({
        url: "report/api/auth/reports/complaints/list", // url of file
        data: { type: "pdf" }, //query params
        file: { name: fileName, ext } // file name and extension of downloaded file for saving
    }));
}

export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_USER_DETAILS, fetchUserDetails),
        takeLatest(ACTION_TYPES.FETCH_USERS, fetchUsers),
        takeLatest(ACTION_TYPES.FETCH_USERS_V2, fetchUsersV2),
        takeLatest(ACTION_TYPES.ASSIGN_USER, assignUser),
        takeLatest(ACTION_TYPES.DOWNLOAD_SAMPLE_REPORT, downloadSampleReport)
    ]);
}
