import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_WARD_DETAILS_LIST: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_LIST`,
    FETCH_WARD_DETAILS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_LIST_REQUEST`,
    FETCH_WARD_DETAILS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_LIST_SUCCESS`,
    FETCH_WARD_DETAILS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_LIST_FAILURE`,

    FETCH_WARD_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_BY_ID`,
    FETCH_WARD_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_BY_ID_REQUEST`,
    FETCH_WARD_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_BY_ID_SUCCESS`,
    FETCH_WARD_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_BY_ID_FAILURE`,

    POST_WARD_DETAILS: `${STATE_REDUCER_KEY}/POST_WARD_DETAILS`,
    POST_WARD_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_WARD_DETAILS_REQUEST`,
    POST_WARD_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_WARD_DETAILS_SUCCESS`,
    POST_WARD_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_WARD_DETAILS_FAILURE`,

    PATCH_WARD_DETAILS: `${STATE_REDUCER_KEY}/PATCH_WARD_DETAILS`,
    PATCH_WARD_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WARD_DETAILS_REQUEST`,
    PATCH_WARD_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WARD_DETAILS_SUCCESS`,
    PATCH_WARD_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WARD_DETAILS_FAILURE`,

    DELETE_WARD_DETAILS: `${STATE_REDUCER_KEY}/DELETE_WARD_DETAILS`,
    DELETE_WARD_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_WARD_DETAILS_REQUEST`,
    DELETE_WARD_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_WARD_DETAILS_SUCCESS`,
    DELETE_WARD_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_WARD_DETAILS_FAILURE`
};

export const fetchWardDetailsList = createAction(ACTION_TYPES.FETCH_WARD_DETAILS_LIST);
export const fetchWardDetailsById = createAction(ACTION_TYPES.FETCH_WARD_DETAILS_BY_ID);
export const postWardDetails = createAction(ACTION_TYPES.POST_WARD_DETAILS);
export const patchWardDetails = createAction(ACTION_TYPES.PATCH_WARD_DETAILS);
export const deleteWardDetails = createAction(ACTION_TYPES.DELETE_WARD_DETAILS);
