import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_PRIMARY_COLLECTION_AGENCY: `${STATE_REDUCER_KEY}/FETCH_PRIMARY_COLLECTION_AGENCY`,
    FETCH_PRIMARY_COLLECTION_AGENCY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PRIMARY_COLLECTION_AGENCY_REQUEST`,
    FETCH_PRIMARY_COLLECTION_AGENCY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PRIMARY_COLLECTION_AGENCY_SUCCESS`,
    FETCH_PRIMARY_COLLECTION_AGENCY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PRIMARY_COLLECTION_AGENCY_FAILURE`,

    WOMEN_WORK_GROUP_DROPDOWN: `${STATE_REDUCER_KEY}/WOMEN_WORK_GROUP_DROPDOWN`,
    WOMEN_WORK_GROUP_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/WOMEN_WORK_GROUP_DROPDOWN_REQUEST`,
    WOMEN_WORK_GROUP_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/WOMEN_WORK_GROUP_DROPDOWN_SUCCESS`,
    WOMEN_WORK_GROUP_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/WOMEN_WORK_GROUP_DROPDOWN_FAILURE`,

    POST_PRIMARY_COLLECTION_AGENCY: `${STATE_REDUCER_KEY}/POST_PRIMARY_COLLECTION_AGENCY`,
    POST_PRIMARY_COLLECTION_AGENCY_REQUEST: `${STATE_REDUCER_KEY}/POST_PRIMARY_COLLECTION_AGENCY_REQUEST`,
    POST_PRIMARY_COLLECTION_AGENCY_SUCCESS: `${STATE_REDUCER_KEY}/POST_PRIMARY_COLLECTION_AGENCY_SUCCESS`,
    POST_PRIMARY_COLLECTION_AGENCY_FAILURE: `${STATE_REDUCER_KEY}/POST_PRIMARY_COLLECTION_AGENCY_FAILURE`,

    PATCH_PRIMARY_COLLECTION_AGENCY: `${STATE_REDUCER_KEY}/PATCH_PRIMARY_COLLECTION_AGENCY`,
    PATCH_PRIMARY_COLLECTION_AGENCY_REQUEST: `${STATE_REDUCER_KEY}/PATCH_PRIMARY_COLLECTION_AGENCY_REQUEST`,
    PATCH_PRIMARY_COLLECTION_AGENCY_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_PRIMARY_COLLECTION_AGENCY_SUCCESS`,
    PATCH_PRIMARY_COLLECTION_AGENCY_FAILURE: `${STATE_REDUCER_KEY}/PATCH_PRIMARY_COLLECTION_AGENCY_FAILURE`,

    GET_PRIMARY_COLLECTION_AGENCY_BY_ID: `${STATE_REDUCER_KEY}/GET_PRIMARY_COLLECTION_AGENCY_BY_ID`,
    GET_PRIMARY_COLLECTION_AGENCY_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_PRIMARY_COLLECTION_AGENCY_BY_ID_REQUEST`,
    GET_PRIMARY_COLLECTION_AGENCY_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_PRIMARY_COLLECTION_AGENCY_BY_ID_SUCCESS`,
    GET_PRIMARY_COLLECTION_AGENCY_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_PRIMARY_COLLECTION_AGENCY_FAILURE`,

    DELETE_PRIMARY_COLLECTION_AGENCY: `${STATE_REDUCER_KEY}/DELETE_PRIMARY_COLLECTION_AGENCY`,
    DELETE_PRIMARY_COLLECTION_AGENCY_REQUEST: `${STATE_REDUCER_KEY}/DELETE_PRIMARY_COLLECTION_AGENCY_REQUEST`,
    DELETE_PRIMARY_COLLECTION_AGENCY_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_PRIMARY_COLLECTION_AGENCY_SUCCESS`,
    DELETE_PRIMARY_COLLECTION_AGENCY_FAILURE: `${STATE_REDUCER_KEY}/DELETE_PRIMARY_COLLECTION_AGENCY_FAILURE`,

    AGENCY_NAME_DROPDOWN: `${STATE_REDUCER_KEY}/AGENCY_NAME_DROPDOWN`,
    AGENCY_NAME_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/AGENCY_NAME_DROPDOWN_REQUEST`,
    AGENCY_NAME_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/AGENCY_NAME_DROPDOWN_SUCCESS`,
    AGENCY_NAME_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/AGENCY_NAME_DROPDOWN_FAILURE`
};

export const getPrimaryCollectionAgency = createAction(ACTION_TYPES.FETCH_PRIMARY_COLLECTION_AGENCY);
export const womenGroupDropdown = createAction(ACTION_TYPES.WOMEN_WORK_GROUP_DROPDOWN);
export const postPrimaryCollectionAgency = createAction(ACTION_TYPES.POST_PRIMARY_COLLECTION_AGENCY);
export const patchPrimaryCollectionAgency = createAction(ACTION_TYPES.PATCH_PRIMARY_COLLECTION_AGENCY);
export const getPrimaryCollectionAgencyById = createAction(ACTION_TYPES.GET_PRIMARY_COLLECTION_AGENCY_BY_ID);
export const deletePrimaryCollectionAgencyB = createAction(ACTION_TYPES.DELETE_PRIMARY_COLLECTION_AGENCY);
export const agencyNameDropdown = createAction(ACTION_TYPES.AGENCY_NAME_DROPDOWN);


