import { createSlice } from "@reduxjs/toolkit";
import { getPayloadContent } from "utils/apiUtils";

import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import _ from "lodash";

const POST_PLAN_DETAILS = {
    meetingDate: null,
    meetingVenue: "",
    maleCount: "",
    femaleCount: "",
    transgenderCount: "",
    disabledCount: "",
    oldAgeCount: "",
    hksMemberCount: "",
    otherWasteWorkerCount: "",
    kudumbasreeMembersCount: "",
    ragPickersCount: "",
    otherScrapDealerCount: "",
    remarks: "",
    attachmentIds: [],
    swmPlanId: "",
    totalCount: ""
};

const initialState = {
    postPlanById: {
        requestInProgress: false,
        data: {
            ...POST_PLAN_DETAILS
        }
    },
    postPlan: {
        requestInProgress: false,
        data: {
            meetingDate: null,
            meetingVenue: "",
            maleCount: "",
            femaleCount: "",
            transgenderCount: "",
            disabledCount: "",
            oldAgeCount: "",
            hksMemberCount: "",
            otherWasteWorkerCount: "",
            kudumbasreeMembersCount: "",
            ragPickersCount: "",
            otherScrapDealerCount: "",
            remarks: "",
            attachmentIds: [],
            swmPlanId: "",
            totalCount: ""
        }
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.postPlan = initialState.postPlan;
        },
        setPostPlanById: (state, action) => {
            state.postPlanById.data = action.payload;
        },
        resetPostPlanById: (state) => {
            state.postPlanById.data = initialState.postPlanById.data;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_REQUEST, (state) => {
            state.postPlan.requestInProgress = true;
        })
            .addCase(ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_SUCCESS, (state, action) => {
                state.postPlan = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_FAILURE, (state) => {
                state.postPlan.requestInProgress = false;
                state.error = "Something went wrong";
            })
            .addCase(ACTION_TYPES.POST_PLAN_CONSULTATION_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "postPlan.requestInProgress", false);
                _.set(state, "postPlan.data", payload);
            })
            .addCase(ACTION_TYPES.SUBMIT_POST_PLAN_CONSULTATION_FAILURE, (state, { payload }) => {
                _.set(state, "postPlan.requestInProgress", false);
                _.set(state, "postPlan.data", payload);
            });
    }
});

export const { actions, reducer } = slice;
