import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    FETCH_DISTRICT_LIST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST`,
    FETCH_DISTRICT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_REQUEST`,
    FETCH_DISTRICT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_SUCCESS`,
    FETCH_DISTRICT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_FAILURE`,

    FETCH_COMPLAINT_CATEGORY: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY`,
    FETCH_COMPLAINT_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_REQUEST`,
    FETCH_COMPLAINT_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_SUCCESS`,
    FETCH_COMPLAINT_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_FAILURE`,

    FETCH_COMPLAINT_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DETAILS_BY_ID`,
    FETCH_COMPLAINT_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DETAILS_BY_ID_REQUEST`,
    FETCH_COMPLAINT_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DETAILS_BY_ID_SUCCESS`,
    FETCH_COMPLAINT_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DETAILS_BY_ID_FAILURE`,

    FETCH_COMPLAINT_DETAILS_BY: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DETAILS_BY`,
    FETCH_COMPLAINT_DETAILS_BY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DETAILS_BY_REQUEST`,
    FETCH_COMPLAINT_DETAILS_BY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DETAILS_BY_SUCCESS`,
    FETCH_COMPLAINT_DETAILS_BY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DETAILS_BY_FAILURE`,

    FETCH_COMPLAINT_MODE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_MODE`,
    FETCH_COMPLAINT_MODE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_MODE_REQUEST`,
    FETCH_COMPLAINT_MODE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_MODE_SUCCESS`,
    FETCH_COMPLAINT_MODE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_MODE_FAILURE`,

    FETCH_COMPLAINT_TYPE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE`,
    FETCH_COMPLAINT_TYPE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE_REQUEST`,
    FETCH_COMPLAINT_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE_SUCCESS`,
    FETCH_COMPLAINT_TYPE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE_FAILURE`,

    FETCH_ORGANIZATION: `${STATE_REDUCER_KEY}/FETCH_ORGANIZATION`,
    FETCH_ORGANIZATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ORGANIZATION_REQUEST`,
    FETCH_ORGANIZATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ORGANIZATION_SUCCESS`,
    FETCH_ORGANIZATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ORGANIZATION_FAILURE`,

    FETCH_ORGANIZATION_TYPE: `${STATE_REDUCER_KEY}/FETCH_ORGANIZATION_TYPE`,
    FETCH_ORGANIZATION_REQUEST_TYPE: `${STATE_REDUCER_KEY}/FETCH_ORGANIZATION_REQUEST_TYPE`,
    FETCH_ORGANIZATION_SUCCESS_TYPE: `${STATE_REDUCER_KEY}/FETCH_ORGANIZATION_SUCCESS_TYPE`,
    FETCH_ORGANIZATION_FAILURE_TYPE: `${STATE_REDUCER_KEY}/FETCH_ORGANIZATION_FAILURE_TYPE`,

    SUBMIT_COMPLAINT_FORM: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_FORM`,
    SUBMIT_COMPLAINT_FORM_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_FORM_REQUEST`,
    SUBMIT_COMPLAINT_FORM_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_FORM_SUCCESS`,
    SUBMIT_COMPLAINT_FORM_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_FORM_FAILURE`,

    ADD_COMPLAINT_FORM: `${STATE_REDUCER_KEY}/ADD_COMPLAINT_FORM`,
    ADD_COMPLAINT_FORM_REQUEST: `${STATE_REDUCER_KEY}/ADD_COMPLAINT_FORM_REQUEST`,
    ADD_COMPLAINT_FORM_SUCCESS: `${STATE_REDUCER_KEY}/ADD_COMPLAINT_FORM_SUCCESS`,
    ADD_COMPLAINT_FORM_FAILURE: `${STATE_REDUCER_KEY}/ADD_COMPLAINT_FORM_FAILURE`,
    SUBMIT_COMPLAINT_STATUS: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_STATUS`,

    UPDATE_COMPLAINT_BY_ID: `${STATE_REDUCER_KEY}/UPDATE_COMPLAINT_BY_ID`,
    UPDATE_COMPLAINT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_COMPLAINT_BY_ID_REQUEST`,
    UPDATE_COMPLAINT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_COMPLAINT_BY_ID_SUCCESS`,
    UPDATE_COMPLAINT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_COMPLAINT_BY_ID_FAILURE`,

    LIST_COMPLAINT_FORM: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_FORM`,
    LIST_COMPLAINT_FORM_REQUEST: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_FORM_REQUEST`,
    LIST_COMPLAINT_FORM_SUCCESS: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_FORM_SUCCESS`,
    LIST_COMPLAINT_FORM_FAILURE: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_FORM_FAILURE`,

    LIST_COMPLAINT_UNASSIGNED: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_UNASSIGNED`,
    LIST_COMPLAINT_UNASSIGNED_REQUEST: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_UNASSIGNED_REQUEST`,
    LIST_COMPLAINT_UNASSIGNED_SUCCESS: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_UNASSIGNED_SUCCESS`,
    LIST_COMPLAINT_UNASSIGNED_FAILURE: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_UNASSIGNED_FAILURE`,

    LIST_COMPLAINT_ASSIGNED: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_ASSIGNED`,
    LIST_COMPLAINT_ASSIGNED_REQUEST: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_ASSIGNED_REQUEST`,
    LIST_COMPLAINT_ASSIGNED_SUCCESS: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_ASSIGNED_SUCCESS`,
    LIST_COMPLAINT_ASSIGNED_FAILURE: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_ASSIGNED_FAILURE`,

    LIST_COMPLAINT_CREATED: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_CREATED`,
    LIST_COMPLAINT_CREATED_REQUEST: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_CREATED_REQUEST`,
    LIST_COMPLAINT_CREATED_SUCCESS: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_CREATED_SUCCESS`,
    LIST_COMPLAINT_CREATED_FAILURE: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_CREATED_FAILURE`,

    DELETE_COMPLAINT_FORM: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_FORM`,
    DELETE_COMPLAINT_FORM_REQUEST: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_FORM_REQUEST`,
    DELETE_COMPLAINT_FORM_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_FORM_SUCCESS`,
    DELETE_COMPLAINT_FORM_FAILURE: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_FORM_FAILURE`,

    FETCH_WARD_DETAILS: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS`,
    FETCH_WARD_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_REQUEST`,
    FETCH_WARD_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_SUCCESS`,
    FETCH_WARD_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_FAILURE`,

    FETCH_FILE: `${STATE_REDUCER_KEY}/FETCH_FILE`,
    FETCH_FILE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FILE_REQUEST`,
    FETCH_FILE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FILE_SUCCESS`,
    FETCH_FILE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FILE_FAILURE`,

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    FETCH_COMPLAINT_HISTORY: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_HISTORY`,
    FETCH_COMPLAINT_HISTORY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_HISTORY_REQUEST`,
    FETCH_COMPLAINT_HISTORY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_HISTORY_SUCCESS`,
    FETCH_COMPLAINT_HISTORY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_HISTORY_FAILURE`,

    FILTER_COMPLAINT_LIST: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_LIST`,
    FILTER_COMPLAINT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_LIST_REQUEST`,
    FILTER_COMPLAINT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_LIST_SUCCESS`,
    FILTER_COMPLAINT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_LIST_FAILURE`,

    FETCH_COMPLAINT_STATUS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS`,
    FETCH_COMPLAINT_STATUS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_REQUEST`,
    FETCH_COMPLAINT_STATUS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_SUCCESS`,
    FETCH_COMPLAINT_STATUS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_FAILURE`,

    UPDATE_COMPLAINT_ACTION: `${STATE_REDUCER_KEY}/UPDATE_COMPLAINT_ACTION`,
    UPDATE_COMPLAINT_ACTION_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_COMPLAINT_ACTION_REQUEST`,
    UPDATE_COMPLAINT_ACTION_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_COMPLAINT_ACTION_SUCCESS`,
    UPDATE_COMPLAINT_ACTION_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_COMPLAINT_ACTION_FAILURE`,

    DELETE_ACTION_FILE: `${STATE_REDUCER_KEY}/DELETE_ACTION_FILE`,
    DELETE_ACTION_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_ACTION_FILE_REQUEST`,
    DELETE_ACTION_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_ACTION_FILE_SUCCESS`,
    DELETE_ACTION_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_ACTION_FILE_FAILURE`,

    FETCH_OFFICERS_LIST: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST`,
    FETCH_OFFICERS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST_REQUEST`,
    FETCH_OFFICERS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST_SUCCESS`,
    FETCH_OFFICERS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST_FAILURE`,

    REASSIGN_OFFICER: `${STATE_REDUCER_KEY}/REASSIGN_OFFICER`,
    REASSIGN_OFFICER_REQUEST: `${STATE_REDUCER_KEY}/REASSIGN_OFFICER_REQUEST`,
    REASSIGN_OFFICER_SUCCESS: `${STATE_REDUCER_KEY}/REASSIGN_OFFICER_SUCCESS`,
    REASSIGN_OFFICER_FAILURE: `${STATE_REDUCER_KEY}/REASSIGN_OFFICER_FAILURE`,

    RESOLVE_COMPLAINT: `${STATE_REDUCER_KEY}/RESOLVE_COMPLAINT`,
    RESOLVE_COMPLAINT_REQUEST: `${STATE_REDUCER_KEY}/RESOLVE_COMPLAINT_REQUEST`,
    RESOLVE_COMPLAINT_SUCCESS: `${STATE_REDUCER_KEY}/RESOLVE_COMPLAINT_SUCCESS`,
    RESOLVE_COMPLAINT_FAILURE: `${STATE_REDUCER_KEY}/RESOLVE_COMPLAINT_FAILURE`,

    FETCH_MY_APPROVAL: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL`,
    FETCH_MY_APPROVAL_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL_REQUEST`,
    FETCH_MY_APPROVAL_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL_SUCCESS`,
    FETCH_MY_APPROVAL_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL_FAILURE`,

    REJECT_COMPLAINT: `${STATE_REDUCER_KEY}/REJECT_COMPLAINT`,
    REJECT_COMPLAINT_REQUEST: `${STATE_REDUCER_KEY}/REJECT_COMPLAINT_REQUEST`,
    REJECT_COMPLAINT_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_COMPLAINT_SUCCESS`,
    REJECT_COMPLAINT_FAILURE: `${STATE_REDUCER_KEY}/REJECT_COMPLAINT_FAILURE`,

    FETCH_COMPLAINT_APPROVAL_DETAILS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_APPROVAL_DETAILS`,
    FETCH_COMPLAINT_APPROVAL_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_APPROVAL_DETAILS_REQUEST`,
    FETCH_COMPLAINT_APPROVAL_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_APPROVAL_DETAILS_SUCCESS`,
    FETCH_COMPLAINT_APPROVAL_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_APPROVAL_DETAILS_FAILURE`,

    PROCESS_MY_APPROVAL: `${STATE_REDUCER_KEY}/PROCESS_MY_APPROVAL`,
    PROCESS_MY_APPROVAL_REQUEST: `${STATE_REDUCER_KEY}/PROCESS_MY_APPROVAL_REQUEST`,
    PROCESS_MY_APPROVAL_SUCCESS: `${STATE_REDUCER_KEY}/PROCESS_MY_APPROVAL_SUCCESS`,
    PROCESS_MY_APPROVAL_FAILURE: `${STATE_REDUCER_KEY}/PROCESS_MY_APPROVAL_FAILURE`,


    FETCH_MY_APPROVAL_REJECTED: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL_REJECTED`,
    FETCH_MY_APPROVAL_REJECTED_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL_REJECTED_REQUEST`,
    FETCH_MY_APPROVAL_REJECTED_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL_REJECTED_SUCCESS`,
    FETCH_MY_APPROVAL_REJECTED_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL_REJECTED_FAILURE`,


    SUBMIT_REMARK_FORM: `${STATE_REDUCER_KEY}/SUBMIT_REMARK_FORM`,
    SUBMIT_REMARK_FORM_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_REMARK_FORM_REQUEST`,
    SUBMIT_REMARK_FORM_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_REMARK_FORM_SUCCESS`,
    SUBMIT_REMARK_FORM_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_REMARK_FORM_FAILURE`,

    SUBMIT_CLOSE_FORM: `${STATE_REDUCER_KEY}/SUBMIT_CLOSE_FORM`,
    SUBMIT_CLOSE_FORM_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_CLOSE_FORM_REQUEST`,
    SUBMIT_CLOSE_FORM_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_CLOSE_FORM_SUCCESS`,
    SUBMIT_CLOSE_FORM_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_CLOSE_FORM_FAILURE`,

    SUBMIT_WITHDRAW_FORM: `${STATE_REDUCER_KEY}/SUBMIT_WITHDRAW_FORM`,
    SUBMIT_WITHDRAW_FORM_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_WITHDRAW_FORM_REQUEST`,
    SUBMIT_WITHDRAW_FORM_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_WITHDRAW_FORM_SUCCESS`,
    SUBMIT_WITHDRAW_FORM_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_WITHDRAW_FORM_FAILURE`,

    FETCH_MY_APPROVAL_APPROVED: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL_APPROVED`,
    FETCH_MY_APPROVAL_APPROVED_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL_APPROVED_REQUEST`,
    FETCH_MY_APPROVAL_APPROVED_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL_APPROVED_SUCCESS`,
    FETCH_MY_APPROVAL_APPROVED_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MY_APPROVAL_APPROVED_FAILURE`,

    FETCH_USER: `${STATE_REDUCER_KEY}/FETCH_USER`,
    FETCH_USER_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_REQUEST`,
    FETCH_USER_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_SUCCESS`,
    FETCH_USER_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_FAILURE`,

    CREATE_USER: `${STATE_REDUCER_KEY}/CREATE_USER`,
    CREATE_USER_REQUEST: `${STATE_REDUCER_KEY}/CREATE_USER_REQUEST`,
    CREATE_USER_SUCCESS: `${STATE_REDUCER_KEY}/CREATE_USER_SUCCESS`,
    CREATE_USER_FAILURE: `${STATE_REDUCER_KEY}/CREATE_USER_FAILURE`,

    FILTER_COMPLAINT_LISTS: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_LISTS`,
    FILTER_COMPLAINT_LISTS_REQUEST: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_LISTS_REQUEST`,
    FILTER_COMPLAINT_LISTS_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_LISTS_SUCCESS`,
    FILTER_COMPLAINT_LISTS_FAILURE: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_LISTS_FAILURE`,

    MOBILE_NO_DROPDOWN: `${STATE_REDUCER_KEY}/MOBILE_NO_DROPDOWN`,
    MOBILE_NO_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/MOBILE_NO_DROPDOWN_REQUEST`,
    MOBILE_NO_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/MOBILE_NO_DROPDOWN_SUCCESS`,
    MOBILE_NO_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/MOBILE_NO_DROPDOWN_FAILURE`

};

export const getDistricts = createAction(ACTION_TYPES.FETCH_DISTRICT_LIST);
export const getWard = createAction(ACTION_TYPES.FETCH_WARD_DETAILS);
export const getOrganization = createAction(ACTION_TYPES.FETCH_ORGANIZATION);
export const getOrganizationType = createAction(ACTION_TYPES.FETCH_ORGANIZATION_TYPE);
export const getComplaintMode = createAction(ACTION_TYPES.FETCH_COMPLAINT_MODE);
export const getComplaintCategory = createAction(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY);
export const getComplaintType = createAction(ACTION_TYPES.FETCH_COMPLAINT_TYPE);
export const addComplaint = createAction(ACTION_TYPES.ADD_COMPLAINT_FORM);
export const editComplaint = createAction(ACTION_TYPES.UPDATE_COMPLAINT_BY_ID);
export const submitComplaintForm = createAction(ACTION_TYPES.SUBMIT_COMPLAINT_FORM);
export const postComplaintStatus = createAction(ACTION_TYPES.SUBMIT_COMPLAINT_STATUS);
export const deleteComplaintForm = createAction(ACTION_TYPES.DELETE_COMPLAINT_FORM);
export const listComplaintForm = createAction(ACTION_TYPES.LIST_COMPLAINT_FORM);
export const getComplaintValuesById = createAction(ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_ID);
export const getComplaintValuesBy = createAction(ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY);
export const getFile = createAction(ACTION_TYPES.FETCH_FILE);
export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const fetchComplaintHistory = createAction(ACTION_TYPES.FETCH_COMPLAINT_HISTORY);
export const filterComplaint = createAction(ACTION_TYPES.FILTER_COMPLAINT_LIST);
export const listComplaintStatus = createAction(ACTION_TYPES.FETCH_COMPLAINT_STATUS);
export const updateComplaintAction = createAction(ACTION_TYPES.UPDATE_COMPLAINT_ACTION);
export const deleteActionFile = createAction(ACTION_TYPES.DELETE_ACTION_FILE);
export const fetchOfficersList = createAction(ACTION_TYPES.FETCH_OFFICERS_LIST);
export const addReassignOfficer = createAction(ACTION_TYPES.REASSIGN_OFFICER);
export const addResolveStatus = createAction(ACTION_TYPES.RESOLVE_COMPLAINT);
export const rejectComplaint = createAction(ACTION_TYPES.REJECT_COMPLAINT);
export const listMyApprovalPending = createAction(ACTION_TYPES.FETCH_MY_APPROVAL);
export const getComplaintApprovalDetailsById = createAction(ACTION_TYPES.FETCH_COMPLAINT_APPROVAL_DETAILS);
export const processMyApproval = createAction(ACTION_TYPES.PROCESS_MY_APPROVAL);
export const listMyApprovalRejected = createAction(ACTION_TYPES.FETCH_MY_APPROVAL_REJECTED);
export const submitRemarkForm = createAction(ACTION_TYPES.SUBMIT_REMARK_FORM);
export const submitCloseForm = createAction(ACTION_TYPES.SUBMIT_CLOSE_FORM);
export const submitWithdrawForm = createAction(ACTION_TYPES.SUBMIT_WITHDRAW_FORM);
export const listMyApprovalApproved = createAction(ACTION_TYPES.FETCH_MY_APPROVAL_APPROVED);
export const fetchUser = createAction(ACTION_TYPES.FETCH_USER);
export const createNewUser = createAction(ACTION_TYPES.CREATE_USER);
export const listComplaintUnassigned = createAction(ACTION_TYPES.LIST_COMPLAINT_UNASSIGNED);
export const listComplaintAssigned = createAction(ACTION_TYPES.LIST_COMPLAINT_ASSIGNED);
export const listComplaintCreated = createAction(ACTION_TYPES.LIST_COMPLAINT_CREATED);
export const filterComplaints = createAction(ACTION_TYPES.FILTER_COMPLAINT_LISTS);
export const mobileNoDropdown = createAction(ACTION_TYPES.MOBILE_NO_DROPDOWN);
