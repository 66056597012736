import _ from "lodash";
import { ACTION_TYPES } from "./actions";

const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");

const initialState = {
    Gur1: {
        requestInProgress: false,
        data: []
    },
    QuarterDropDown: {
        requestInProgress: false,
        data: []
    },
    projectDetails: {
        requestInProgress: false,
        data: []
    },
    gur2Report: {
        requestInProgress: false,
        data: []
    },
    incentiveGrantData: {
        requestInProgress: false,
        data: []
    },
    incentiveGrantPresentStatusData: {
        requestInProgress: false,
        data: []
    },
    gur3Data: {
        requestInProgress: false,
        data: []
    },
    gur2CumulativeToDate: {
        requestInProgress: false,
        data: []
    },
    grantAllocationReport: {
        requestInProgress: false,
        data: []
    },
    projectDetailsTotal: {
        requestInProgress: false,
        data: []
    },
    gur1Total: {
        requestInProgress: false,
        data: []
    },
    gur2ReportTotal: {
        requestInProgress: false,
        data: []
    },
    gur2CumulativeToDateTotal: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clearQuarter: (state) => {
            state.QuarterDropDown = initialState.QuarterDropDown;
        },
        clearGur1: (state) => {
            state.Gur1 = initialState.Gur1;
        },
        clearProjectDetails: (state) => {
            state.projectDetails = initialState.projectDetails;
        },
        clearGur2Report: (state) => {
            state.gur2Report = initialState.gur2Report;
        },
        clearIncentiveGrantPresentStatusData: (state) => {
            state.incentiveGrantPresentStatusData = initialState.incentiveGrantPresentStatusData;
        },
        clearGur3Report: (state) => {
            state.gur3Data = initialState.gur3Data;
        },
        clearGur2CumulativeToDate: (state) => {
            state.gur2CumulativeToDate = initialState.gur2CumulativeToDate;
        },
        clearGrantAllocation: (state) => {
            state.grantAllocationReport = initialState.grantAllocationReport;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_QUARTER_DROP_DOWN_REQUEST, (state) => {
                _.set(state, "QuarterDropDown.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.FETCH_QUARTER_DROP_DOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "QuarterDropDown.requestInProgress", false);
                _.set(state, "QuarterDropDown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_QUARTER_DROP_DOWN_FAILURE, (state) => {
                _.set(state, "QuarterDropDown.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.FETCH_GUR_REPORT_1_REQUEST, (state) => {
                _.set(state, "Gur1.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.FETCH_GUR_REPORT_1_SUCCESS, (state, { payload }) => {
                _.set(state, "Gur1.requestInProgress", false);
                _.set(state, "Gur1.data", payload);
                _.set(state, "gur1Total.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_GUR_REPORT_1_FAILURE, (state) => {
                _.set(state, "Gur1.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.PROJECT_DETAILS_REPORT_REQUEST, (state) => {
                _.set(state, "projectDetails.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.PROJECT_DETAILS_REPORT_SUCCESS, (state, { payload }) => {
                _.set(state, "projectDetails.requestInProgress", false);
                _.set(state, "projectDetails.data", payload);
                _.set(state, "projectDetailsTotal.data", payload?.content);
            })
            .addCase(ACTION_TYPES.PROJECT_DETAILS_REPORT_FAILURE, (state) => {
                _.set(state, "projectDetails.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.FETCH_GUR2_REPORT_REQUEST, (state) => {
                _.set(state, "gur2Report.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.FETCH_GUR2_REPORT_SUCCESS, (state, { payload }) => {
                _.set(state, "gur2Report.requestInProgress", false);
                _.set(state, "gur2Report.data", payload);
                _.set(state, "gur2ReportTotal.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_GUR2_REPORT_FAILURE, (state) => {
                _.set(state, "gur2Report.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.FETCH_INCENTIVE_GRANT_REPORT_REQUEST, (state) => {
                _.set(state, "incentiveGrantData.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.FETCH_INCENTIVE_GRANT_REPORT_SUCCESS, (state, { payload }) => {
                _.set(state, "incentiveGrantData.requestInProgress", false);
                _.set(state, "incentiveGrantData.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_INCENTIVE_GRANT_REPORT_FAILURE, (state) => {
                _.set(state, "incentiveGrantData.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_REQUEST, (state) => {
                _.set(state, "incentiveGrantPresentStatusData.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_SUCCESS, (state, { payload }) => {
                _.set(state, "incentiveGrantPresentStatusData.requestInProgress", false);
                _.set(state, "incentiveGrantPresentStatusData.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_FAILURE, (state) => {
                _.set(state, "incentiveGrantPresentStatusData.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.FETCH_GUR3_REPORT_REQUEST, (state) => {
                _.set(state, "gur3Data.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.FETCH_GUR3_REPORT_SUCCESS, (state, { payload }) => {
                _.set(state, "gur3Data.requestInProgress", false);
                _.set(state, "gur3Data.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_GUR3_REPORT_FAILURE, (state) => {
                _.set(state, "gur3Data.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_REQUEST, (state) => {
                _.set(state, "gur2CumulativeToDate.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_SUCCESS, (state, { payload }) => {
                _.set(state, "gur2CumulativeToDate.requestInProgress", false);
                _.set(state, "gur2CumulativeToDate.data", payload);
                _.set(state, "gur2CumulativeToDateTotal.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_FAILURE, (state) => {
                _.set(state, "gur2CumulativeToDate.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.FETCH_GRANT_ALLOCATION_REQUEST, (state) => {
                _.set(state, "grantAllocationReport.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.FETCH_GRANT_ALLOCATION_SUCCESS, (state, { payload }) => {
                _.set(state, "grantAllocationReport.requestInProgress", false);
                _.set(state, "grantAllocationReport.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_GRANT_ALLOCATION_FAILURE, (state) => {
                _.set(state, "grantAllocationReport.requestInProgress", false);
            });
    }
});

export const { actions, reducer } = slice;
