import { USER_ROLE_NAME } from "common/constants";
import React from "react";
import { getUserDetails } from "utils/userUtils";
import { REPORTS } from "../constants";
import GrandAllocationReport from "./GrantAllocationReport";
import ProjectDetailsReport from "./ProjectDetailsReport";
import ReportGUR1 from "./ReportGUR1";
import ReportGur1DistrictLevel from "./ReportGur1DistrictLevel";
import ReportGur1StateLevel from "./ReportGur1StateLevel";
import ReportGUR2 from "./ReportGUR2";
import ReportGur3 from "./ReportGur3";
import UlbAccessToIncentiveDelayReport from "./UlbAccessToIncentiveDelayReport";
import ULBAccessToIncentiveGrant from "./ULBAccessToIncentiveGrant";
import ProjectDetailsReportDistrictLevel from "./ProjectDetailsReportDistrictLevel";
import ProjectDetailsReportUlbLevel from "./ProjectDetailsReportUlbLevel";
import ReportGur2UlbLevel from "./ReportGur2UlbLevel";
import IncentiveGrantAccessedAndUtilizedUlbLevel from "./IncentiveGrantAccessedAndUtilizedUlbLevel";

const GMMReportSubWrapper = (props) => {
    let { reports } = props;
    const { data: { activeRole = {} } = {} } = getUserDetails();

    return (
        <>
            {reports === REPORTS.GUR_1 && activeRole?.name === USER_ROLE_NAME.SWM_STAFF_USER ? (
                <ReportGUR1 />
            ) : reports === REPORTS.GUR_1 && activeRole?.name === USER_ROLE_NAME.DPMU_USER_ROLE ? (
                <ReportGur1DistrictLevel />
            ) : reports === REPORTS.GUR_1 && activeRole?.name === USER_ROLE_NAME.SPMU_USER_ROLE ? (
                <ReportGur1StateLevel />
            ) : (
                ""
            )}
            {reports === REPORTS.GUR_2 && activeRole?.name === USER_ROLE_NAME.SWM_STAFF_USER ? (
                <ReportGur2UlbLevel />
            ) : reports === REPORTS.GUR_2 && activeRole?.name === USER_ROLE_NAME.DPMU_USER_ROLE ? (
                <ReportGUR2 />
            ) : reports === REPORTS.GUR_2 && activeRole?.name === USER_ROLE_NAME.SPMU_USER_ROLE ? (
                <ReportGUR2 />
            ) : (
                ""
            )}
            {reports === REPORTS.GUR_3 && <ReportGur3 />}
            {reports === REPORTS.ULB_ACCESS_TO_INCENTIVE_GRANT && <ULBAccessToIncentiveGrant />}
            {reports === REPORTS.ULB_ACCESS_TO_INCENTIVE_GRANT_DELAY_REPORT && <UlbAccessToIncentiveDelayReport />}
            {reports === REPORTS.GRANT_ALLOCATION_REPORT && <GrandAllocationReport />}
            {/* {reports === REPORTS.PROJECT_DETAILS_REPORT && <ProjectDetailsReport />} */}
            {reports === REPORTS.PROJECT_DETAILS_REPORT && activeRole?.name === USER_ROLE_NAME.SWM_STAFF_USER ? (
                <ProjectDetailsReportUlbLevel />
            ) : reports === REPORTS.PROJECT_DETAILS_REPORT && activeRole?.name === USER_ROLE_NAME.DPMU_USER_ROLE ? (
                <ProjectDetailsReportDistrictLevel />
            ) : reports === REPORTS.PROJECT_DETAILS_REPORT && activeRole?.name === USER_ROLE_NAME.SPMU_USER_ROLE ? (
                <ProjectDetailsReport />
            ) : (
                ""
            )}
            {reports === REPORTS.INCENTIVE_GRANT_ACCESSED_AND_UTILIZED && activeRole?.name === USER_ROLE_NAME.SWM_STAFF_USER ? (
                <IncentiveGrantAccessedAndUtilizedUlbLevel />
            ) : reports === REPORTS.INCENTIVE_GRANT_ACCESSED_AND_UTILIZED && activeRole?.name === USER_ROLE_NAME.DPMU_USER_ROLE ? (
                <IncentiveGrantAccessedAndUtilizedUlbLevel />
            ) : reports === REPORTS.INCENTIVE_GRANT_ACCESSED_AND_UTILIZED && activeRole?.name === USER_ROLE_NAME.SPMU_USER_ROLE ? (
                <IncentiveGrantAccessedAndUtilizedUlbLevel />
            ) : (
                ""
            )}
        </>
    );
};

export default GMMReportSubWrapper;

