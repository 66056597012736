import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    APPROVE_SPMU: `${STATE_REDUCER_KEY}/APPROVE_SPMU`,
    APPROVE_SPMU_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_SPMU_REQUEST`,
    APPROVE_SPMU_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_SPMU_SUCCESS`,
    APPROVE_SPMU_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_SPMU_FAILURE`,

    REJECT_SPMU: `${STATE_REDUCER_KEY}/REJECT_SPMU`,
    REJECT_SPMU_REQUEST: `${STATE_REDUCER_KEY}/REJECT_SPMU_REQUEST`,
    REJECT_SPMU_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_SPMU_SUCCESS`,
    REJECT_SPMU_FAILURE: `${STATE_REDUCER_KEY}/REJECT_SPMU_FAILURE`,

    APPROVE_SPMU_BY_ID: `${STATE_REDUCER_KEY}/APPROVE_SPMU_BY_ID`,
    APPROVE_SPMU_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_SPMU_BY_ID_REQUEST`,
    APPROVE_SPMU_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_SPMU_BY_ID_SUCCESS`,
    APPROVE_SPMU_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_SPMU_BY_ID_FAILURE`,

    FETCH_PREVIOUS_SPMU: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_SPMU`,
    FETCH_PREVIOUS_SPMU_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_SPMU_REQUEST`,
    FETCH_PREVIOUS_SPMU_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_SPMU_SUCCESS`,
    FETCH_PREVIOUS_SPMU_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_SPMU_FAILURE`,

    DOWNLOAD_REMARKS: `${STATE_REDUCER_KEY}/DOWNLOAD_REMARKS`,
    DOWNLOAD_REMARKS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_REMARKS_REQUEST`,
    DOWNLOAD_REMARKS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_REMARKS_SUCCESS`,
    DOWNLOAD_REMARKS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_REMARKS_FAILURE`
};

export const rejectSpmu = createAction(ACTION_TYPES.REJECT_SPMU);
export const approveSpmu = createAction(ACTION_TYPES.APPROVE_SPMU);
export const approveSpmuById = createAction(ACTION_TYPES.APPROVE_SPMU_BY_ID);
export const fetchPreviousSpmu = createAction(ACTION_TYPES.FETCH_PREVIOUS_SPMU);
export const downloadRemarks = createAction(ACTION_TYPES.DOWNLOAD_REMARKS);
