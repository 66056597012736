import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { successNotify } from "utils/notificationUtils";
import { navigateTo } from "modules/common/actions";
import { I18n } from "common/components";
import { MODULE_PATH } from "modules/paths";
import { GRAND_PATH as PATH } from "../paths";

export function* districts() {
    yield call(handleAPIRequest, Api.fetchDistricts);
}

export function* fetchGrandAllocationList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchGrandAllocationListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GRAND_ALLOCATION_LIST_SUCCESS, ACTION_TYPES.FETCH_GRAND_ALLOCATION_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

function* fetchIEADropDownFn() {
    yield call(handleAPIRequest, Api.ieaDropdownApi);
}

function* fetchULBDropDownFn({ payload }) {
    yield call(handleAPIRequest, Api.ulbDropdownApi, payload);
}

export function* fetchSearchUlb({ payload }) {
    yield call(handleAPIRequest, Api.searchULBApi, payload);
}

export function* fetchGrandAllocationDataView({ payload: id }) {
    yield call(handleAPIRequest, Api.fetchGrandAllocationView, { id });
}

export function* ListIva({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchIvaListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_IVA_LIST_SUCCESS, ACTION_TYPES.FETCH_IVA_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}
export function* SubmitRemarks({ payload }) {
    yield call(handleAPIRequest, Api.submitIvaRemarksApi, payload);
}
export function* fetchCriteria() {
    yield fork(handleAPIRequest, Api.fetchEligibilityCriteriaApi);
}

export function* getPlanView({ payload }) {
    yield fork(handleAPIRequest, Api.fetchPlanViewApi, payload);
    const LOOK_UP = [ACTION_TYPES.FETCH_PLAN_VIEW_SUCCESS, ACTION_TYPES.FETCH_PLAN_VIEW_FAILURE];
    yield* setCommonTableData(LOOK_UP);
}

export function* fetchGroupedCriteriaFn() {
    yield fork(handleAPIRequest, Api.fetchGroupedCriteriaApi);
}

export function* fetchCriteriaOptionFn() {
    yield fork(handleAPIRequest, Api.fetchCriteriaOptionApi);
}

export function* listCeiling({ payload }) {
    yield call(handleAPIRequest, Api.fetchCeilingListApi, payload);
}

export function* submitDpmuApplication({ payload }) {
    yield fork(handleAPIRequest, Api.submitToDPMUApi, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_DPMU_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_DPMU_APPLICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_DPMU_APPLICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("submit_success", { type: I18n("application_submit") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.REQUEST.LIST}`));
    }
}

export function* submitSpmuApplication({ payload }) {
    yield fork(handleAPIRequest, Api.submitToSpmuApi, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_SPMU_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_SPMU_APPLICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_SPMU_APPLICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("submit_success", { type: I18n("application_submit") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.REQUEST.LIST}`));
    }
}

export function* submitIvaApplication({ payload }) {
    yield fork(handleAPIRequest, Api.submitToIvaApi, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_IVA_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_IVA_APPLICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_IVA_APPLICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("submit_success", { type: I18n("application_submit") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.REQUEST.LIST}`));
    }
}

export function* spmuApprove({ payload }) {
    yield fork(handleAPIRequest, Api.approveBySpmuApi, payload);
    const responseAction = yield take([ACTION_TYPES.APPROVE_SPMU_APPLICATION_SUCCESS, ACTION_TYPES.APPROVE_SPMU_APPLICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_SPMU_APPLICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approve_success", { type: I18n("application_approved") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.REQUEST.LIST}`));
    }
}

export function* spmuReject({ payload }) {
    yield fork(handleAPIRequest, Api.rejectBySpmuApi, payload);
    const responseAction = yield take([ACTION_TYPES.REJECT_SPMU_APPLICATION_SUCCESS, ACTION_TYPES.REJECT_SPMU_APPLICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_SPMU_APPLICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("reject_success", { type: I18n("application_rejected") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.REQUEST.LIST}`));
    }
}

export function* dpmuReject({ payload }) {
    yield fork(handleAPIRequest, Api.rejectByDpmuApi, payload);
    const responseAction = yield take([ACTION_TYPES.REJECT_DPMU_APPLICATION_SUCCESS, ACTION_TYPES.REJECT_DPMU_APPLICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_DPMU_APPLICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("reject_success", { type: I18n("application_rejected") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.REQUEST.LIST}`));
    }
}

export function* ivaVerify({ payload }) {
    const { grantList = [], remarks = "", ceilingId = {}, id } = payload;
    const newPayload = {};
    const newList = grantList.map(item => item.grantAllocation).flat().map(item => {
        if (item.ivaAttachmentPayload && item.ivaAttachmentPayload.length === 0) {
            delete item.ivaAttachmentPayload;
            delete item.attachmentPayload;
            return item;
        } else {
            let ivaAttachmentPayload = {};
            ivaAttachmentPayload.multimediaList = item.ivaAttachmentPayload;
            delete item.ivaAttachmentPayload;
            delete item.attachmentPayload;
            return { ivaAttachmentPayload: ivaAttachmentPayload, ...item };
        }
    });
    newPayload.ulbGrantApplicationDetailsList = newList;
    newPayload.applicationId = id;
    newPayload.ceilingId = ceilingId;
    newPayload.remarks = remarks;
    yield fork(handleAPIRequest, Api.ivaVerifyApi, newPayload);
    const responseAction = yield take([ACTION_TYPES.IVA_VERIFY_SUCCESS, ACTION_TYPES.IVA_VERIFY_FAILURE]);
    if (responseAction.type === ACTION_TYPES.IVA_VERIFY_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("verified_success", { type: I18n("application_verified") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.REQUEST.LIST}`));
    }
}

export function* submitApplicationFn({ payload }) {
    const { grantList = [], ceilingId = {}, lsgiId = {}, districtId = {}, remarks = "" } = payload;
    let newPayload = {};
    const newList = grantList.map(item => item.grantAllocation).flat().map(item => {
        if (item.attachmentPayload && item.attachmentPayload.length === 0) {
            delete item.attachmentPayload;
            delete item.ivaAttachmentPayload;
            return item;
        } else {
            let attachmentPayload = {};
            if (item.attachmentPayload) {
                attachmentPayload.multimediaList = item.attachmentPayload;
                delete item.attachmentPayload;
                delete item.ivaAttachmentPayload;
                return { attachmentPayload: attachmentPayload, ...item };
            } else {
                return item;
            }
        }
    });
    newPayload.ulbGrantApplicationDetailsList = newList;
    newPayload.lsgiId = lsgiId;
    newPayload.districtId = districtId;
    newPayload.ulbRemarks = remarks;
    newPayload.ceilingId = ceilingId;
    if (payload.id) {
        newPayload.id = payload.id;
        yield fork(handleAPIRequest, Api.updateApplicationApi, newPayload);
        const responseAction = yield take([ACTION_TYPES.UPDATE_APPLICATION_SUCCESS, ACTION_TYPES.UPDATE_APPLICATION_FAILURE]);
        if (responseAction.type === ACTION_TYPES.UPDATE_APPLICATION_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("update_success", { type: I18n("application_updated") }) }));
            yield call(handleAPIRequest, Api.fetchGrandAllocationView, { id: payload?.id });
        }
    } else {
        yield fork(handleAPIRequest, Api.submitApplicationApi, newPayload);
        const responseAction = yield take([ACTION_TYPES.SUBMIT_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_APPLICATION_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_APPLICATION_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("reject_success", { type: I18n("application_submit") }) }));
            yield call(fetchGrandAllocationDataView, { payload: responseAction?.payload?.id });
        }
    }
}

export function* deleteUlbFileFn({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, Api.deleteUlbFileApi, id, fileId);
}

export function* deleteIvaFileFn({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, Api.deleteIvaFileApi, id, fileId);
}

export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_LIST, districts),
        takeLatest(ACTION_TYPES.FETCH_GRAND_ALLOCATION_LIST, fetchGrandAllocationList),
        takeLatest(ACTION_TYPES.FETCH_IEA_DROPDOWN, fetchIEADropDownFn),
        takeLatest(ACTION_TYPES.FETCH_ULB_DROPDOWN, fetchULBDropDownFn),
        takeLatest(ACTION_TYPES.FETCH_ULB_SEARCH, fetchSearchUlb),
        takeLatest(ACTION_TYPES.FETCH_GRAND_ALLOCATION_DETAILS, fetchGrandAllocationDataView),
        takeLatest(ACTION_TYPES.FETCH_IVA_LIST, ListIva),
        takeLatest(ACTION_TYPES.SUBMIT_IVA_REMARKS, SubmitRemarks),
        takeLatest(ACTION_TYPES.FETCH_ELIGIBILITY_CRITERIA, fetchCriteria),
        takeLatest(ACTION_TYPES.FETCH_PLAN_VIEW, getPlanView),
        takeLatest(ACTION_TYPES.FETCH_GROUPED_CRITERIA, fetchGroupedCriteriaFn),
        takeLatest(ACTION_TYPES.FETCH_CRITERIA_OPTIONS, fetchCriteriaOptionFn),
        takeLatest(ACTION_TYPES.FETCH_CEILING_LIST, listCeiling),
        takeLatest(ACTION_TYPES.SUBMIT_DPMU_APPLICATION, submitDpmuApplication),
        takeLatest(ACTION_TYPES.SUBMIT_SPMU_APPLICATION, submitSpmuApplication),
        takeLatest(ACTION_TYPES.SUBMIT_IVA_APPLICATION, submitIvaApplication),
        takeLatest(ACTION_TYPES.APPROVE_SPMU_APPLICATION, spmuApprove),
        takeLatest(ACTION_TYPES.REJECT_SPMU_APPLICATION, spmuReject),
        takeLatest(ACTION_TYPES.REJECT_DPMU_APPLICATION, dpmuReject),
        takeLatest(ACTION_TYPES.IVA_VERIFY, ivaVerify),
        takeLatest(ACTION_TYPES.SUBMIT_APPLICATION, submitApplicationFn),
        takeLatest(ACTION_TYPES.DELETE_ULB_FILE, deleteUlbFileFn),
        takeLatest(ACTION_TYPES.DELETE_IVA_FILE, deleteIvaFileFn)
    ]);
}
