import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const saveGapAnalysis = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.ATTACHMENT_SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_GAP_ANALYSIS_REQUEST, ACTION_TYPES.SUBMIT_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.SUBMIT_GAP_ANALYSIS_FAILURE],
            data
        }
    };
};

export const updateGapAnalysisApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.ATTACHMENT_SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_GAP_ANALYSIS_REQUEST, ACTION_TYPES.UPDATE_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.UPDATE_GAP_ANALYSIS_FAILURE],
            data
        }
    };
};

export const deleteFileById = (id, fileId) => {
    let DELETE_API_URL = API_URL.GAP_ANALYSIS.DELETE_FILE_BY_ID;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":file_id", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_FILE_REQUEST, ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_REQUEST]
        }
    };
};

export const confirmGapAnalysisApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.CONFIRM_UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.CONFIRM_FORM_SUBMIT_REQUEST, ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS, ACTION_TYPES.CONFIRM_FORM_SUBMIT_FAILURE],
            data
        }
    };
};

export const listGapAnalysis = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.ATTACHMENT_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_GAP_ANALYSIS_REQUEST, ACTION_TYPES.LIST_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.LIST_GAP_ANALYSIS_FAILURE],
            data: { swmPlanId: data }
        }
    };
};

export const deleteGapAnalysis = (id) => {
    return {
        url: API_URL.GAP_ANALYSIS.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_GAP_ANALYSIS_REQUEST, ACTION_TYPES.DELETE_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.DELETE_GAP_ANALYSIS_FAILURE]
        }
    };
};

export const gapAnalysisById = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.ATTACHMENT_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GAP_ANALYSIS_BY_ID_REQUEST, ACTION_TYPES.GAP_ANALYSIS_BY_ID_SUCCESS, ACTION_TYPES.GAP_ANALYSIS_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};
