import { createSlice } from "@reduxjs/toolkit";
import { getPayloadContent, getPayloadContentDetails, getPayloadData } from "utils/apiUtils";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import _ from "lodash";

const initialState = {
    fetchExpenses: {
        requestInProgress: false,
        data: {
            ulb: "",
            year: ""
        }
    },
    ulbDropdownOption: {
        requestInProgress: false,
        data: []
    },
    projectsDropdownsOption: {
        requestInProgress: false,
        data: []
    },
    fyDropdownOption: {
        requestInProgress: false,
        data: {}
    },
    fetchExpenseList: {
        requestInProgress: false,
        data: {
        }
    },
    fetchExpenseExistList: {
        requestInProgress: false,
        data: {
        }
    },
    fetchSanKhyaButton: true,
    financialYearDetails: {
        requestInProgress: false,
        data: {
            startDate: "",
            endDate: "",
            name: "",
            description: "",
            startYear: ""
        }
    },
    billDetails: {
        requestInProgress: false,
        data: {
            districtId: "",
            lsgiId: "",
            projectId: "",
            billDate: "",
            billNo: "",
            billIssuedBy: "",
            billStatus: "",
            billRemarks: "",
            billAmount: "",
            sanctionedAmount: ""
        }
    },

    lsgiDropdownOption: {
        requestInProgress: false,
        data: {}
    },
    lsgiTypeDropdownOption: {
        requestInProgress: false,
        data: {}
    },
    districtDropdownOption: {
        requestInProgress: false,
        data: {}
    },
    billStatusDropdownOption: {
        requestInProgress: false,
        data: {}
    },
    fetchSankhyaBillListAll: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clear: () => initialState,
        clearValue: (state) => {
            state.fetchExpenseList.data = initialState.fetchExpenseList.data;
        },
        setUlbValue: (state, action) => {
            state.fetchExpenses.data.ulb = action?.payload;
        },
        setFetchButton: (state, action) => {
            state.fetchSanKhyaButton = action?.payload;
        },
        setLsgiList: (state, data) => {
            let { data: lsgiList = [], id: lsgiTypeId, districtId } = data.payload;
            state.lsgiDropdownOption = {
                requestInProgress: false,
                [districtId]: {
                    [lsgiTypeId]: lsgiList
                }
            };

        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_ULB_LIST_REQUEST, (state) => {
            state.ulbDropdownOption.requestInProgress = true;
        })
            .addCase(ACTION_TYPES.FETCH_ULB_LIST_SUCCESS, (state, action) => {
                state.ulbDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_ULB_LIST_FAILURE, (state) => {
                state.ulbDropdownOption.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_FY_LIST_REQUEST, (state) => {
                state.fyDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_FY_LIST_SUCCESS, (state, action) => {
                const newVal = getPayloadContent(action);
                state.fetchExpenses.data.year = newVal[0];
                state.fyDropdownOption = {
                    requestInProgress: false,
                    data: newVal
                };
            })
            .addCase(ACTION_TYPES.FETCH_FY_LIST_FAILURE, (state) => {
                state.fyDropdownOption.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_EXPENSE_REQUEST, (state) => {
                state.fetchSanKhyaButton = false;
                state.fetchExpenseList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EXPENSE_SUCCESS, (state, action) => {
                state.fetchSanKhyaButton = false;
                state.fetchExpenseList = {
                    requestInProgress: false,
                    data: getPayloadData(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_EXPENSE_FAILURE, (state) => {
                state.fetchExpenseList.requestInProgress = false;
                state.fetchSanKhyaButton = true;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_EXIST_VALUES_REQUEST, (state) => {
                state.fetchExpenseExistList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EXIST_VALUES_SUCCESS, (state, action) => {
                state.fetchExpenseExistList = {
                    requestInProgress: false,
                    data: getPayloadData(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_EXIST_VALUES_FAILURE, (state) => {
                state.fetchExpenseExistList.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_FY_BY_ID_REQUEST, (state) => {
                state.financialYearDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_FY_BY_ID_SUCCESS, (state, action) => {
                state.financialYearDetails = {
                    requestInProgress: false,
                    data: getPayloadContentDetails(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_FY_BY_ID_FAILURE, (state) => {
                state.financialYearDetails.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_REQUEST, (state) => {
                state.lsgiTypeDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_SUCCESS, (state, action) => {
                state.lsgiTypeDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            }).addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_FAILURE, (state) => {
                state.lsgiTypeDropdownOption.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST, (state) => {
                state.lsgiDropdownOption.requestInProgress = true;
            }).addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, (state, action) => {
                state.lsgiDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            }).addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE, (state) => {
                state.lsgiDropdownOption.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_REQUEST, (state) => {
                state.districtDropdownOption.requestInProgress = true;
            }).addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, (state, action) => {
                state.districtDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            }).addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_FAILURE, (state) => {
                state.districtDropdownOption.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.BILL_STATUS_DROPDOWN_REQUEST, (state) => {
                state.billStatusDropdownOption.requestInProgress = true;
            }).addCase(ACTION_TYPES.BILL_STATUS_DROPDOWN_SUCCESS, (state, action) => {
                state.billStatusDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            }).addCase(ACTION_TYPES.BILL_STATUS_DROPDOWN_FAILURE, (state) => {
                state.billStatusDropdownOption.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_BILLS_BY_ID_REQUEST, (state) => {
                state.billDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_BILLS_BY_ID_SUCCESS, (state, action) => {
                state.billDetails = {
                    requestInProgress: false,
                    data: getPayloadContentDetails(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_BILLS_BY_ID_FAILURE, (state) => {
                state.billDetails.requestInProgress = false;
                state.error = "Something went wrong";
            })
            .addCase(ACTION_TYPES.PROJECTS_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "projectsDropdownsOption.requestInProgress", false);
                _.set(state, "projectsDropdownsOption.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_LIST_ALL_REQUEST, (state) => {
                _.set(state, "fetchSankhyaBillListAll.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_LIST_ALL_SUCCESS, (state, { payload }) => {
                _.set(state, "fetchSankhyaBillListAll.requestInProgress", false);
                _.set(state, "fetchSankhyaBillListAll.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_LIST_ALL_FAILURE, (state) => {
                _.set(state, "fetchSankhyaBillListAll.requestInProgress", false);
            });
    }
});

export const { actions, reducer } = slice;
