const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./actions");
import _ from "lodash";

const initialState = {
    district: {
        requestInProgress: false,
        data: []
    },
    ulb: {
        requestInProgress: false,
     data: []
    },
    baselineById: {
        requestInProgress: false,
        data: {
            lsgiId: "",
            remarks: "",
            dateOfCompletion: "",
            attachments: ""
        }
    },
    baselineUlbDetails: {
        requestInProgress: false,
        data: []
    },
    baselineContent: {
        requestInProgress: false,
        data: []
    },
    schemeCyclicDetails: {
        requestInProgress: false,
        data: {
            ulbSchemeCycleActivitiesDto: [],
            completed: false,
            remarks: ""
        }
    },
    safeguardDetails: {
        requestInProgress: false,
        data: {
            ulbSafeguardComplianceDto: []
        }
    },
    socialSafeGuard: {
        requestInProgress: false,
        data: {
            lsgiId: "",
            districtId: ""
        }
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: ()=>initialState,
        clear: (state) => {
            state.district = initialState.district;
            state.ulb = initialState.ulb;
            state.baselineById = initialState.baselineById;
            state.baselineUlbDetails = initialState.baselineUlbDetails;
            state.baselineContent = initialState.baselineContent;
            state.schemeCyclicDetails = initialState.schemeCyclicDetails;
            state.safeguardDetails = initialState.safeguardDetails;
        },
        clearUlb: (state) => {
            state.ulb = initialState.ulb;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.GET_DISTRICT_DROP_DOWN_REQUEST, (state) => {
                state.district.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_DISTRICT_DROP_DOWN_SUCCESS, (state, action) => {
                _.set(state, "district.requestInProgress", false);
                _.set(state, "district.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.GET_DISTRICT_DROP_DOWN_FAILURE, (state) => {
                state.district.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_ULB_DROP_DOWN_REQUEST, (state) => {
                state.ulb.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_ULB_DROP_DOWN_SUCCESS, (state, action) => {
                _.set(state, "ulb.requestInProgress", false);
                _.set(state, "ulb.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.GET_ULB_DROP_DOWN_FAILURE, (state) => {
                state.ulb.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_BASE_LINE_DATA_REQUEST, (state) => {
                state.baselineById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_BASE_LINE_DATA_SUCCESS, (state, action) => {
                _.set(state, "baselineById.requestInProgress", false);
                _.set(state, "baselineById.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_BASE_LINE_DATA_FAILURE, (state) => {
                state.baselineById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_BASELINE_DATA_ULB_DETAILS_REQUEST, (state) => {
                state.baselineUlbDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_BASELINE_DATA_ULB_DETAILS_SUCCESS, (state, action) => {
                _.set(state, "baselineUlbDetails.requestInProgress", false);
                _.set(state, "baselineUlbDetails.data", action.payload.content[0]);
            })
            .addCase(ACTION_TYPES.GET_BASELINE_DATA_ULB_DETAILS_FAILURE, (state) => {
                state.baselineUlbDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_BASELINE_CONTENTS_DETAILS_REQUEST, (state) => {
                state.baselineContent.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_BASELINE_CONTENTS_DETAILS_SUCCESS, (state, action) => {
                _.set(state, "baselineContent.requestInProgress", false);
                _.set(state, "baselineContent.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.GET_BASELINE_CONTENTS_DETAILS_FAILURE, (state) => {
                state.baselineContent.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_SCHEME_CYCLIC_DETAILS_REQUEST, (state) => {
                state.schemeCyclicDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_SCHEME_CYCLIC_DETAILS_SUCCESS, (state, {payload}) => {
                _.set(state, "schemeCyclicDetails.requestInProgress", false);
                _.set(state, "schemeCyclicDetails.data.ulbSchemeCycleActivitiesDto", payload);
            })
            .addCase(ACTION_TYPES.GET_SCHEME_CYCLIC_DETAILS_FAILURE, (state) => {
                state.schemeCyclicDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_SAFEGUARD_COMPLAINTS_DETAILS_REQUEST, (state) => {
                state.safeguardDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_SAFEGUARD_COMPLAINTS_DETAILS_SUCCESS, (state, {payload}) => {
                _.set(state, "safeguardDetails.requestInProgress", false);
                _.set(state, "safeguardDetails.data.ulbSafeguardComplianceDto", payload);
            })
            .addCase(ACTION_TYPES.GET_SAFEGUARD_COMPLAINTS_DETAILS_FAILURE, (state) => {
                state.safeguardDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_SUCCESS, (state, { payload }) => {
                _.set(state, "socialSafeGuard.requestInProgress", false);
                _.set(state, "socialSafeGuard.data", payload);
            });
    }
});

export const { reducer, actions } = slice;

