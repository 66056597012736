export const API_URL = {
    COMMON: {
        DISTRICT: "admin/api/auth/master/district/list/all",
        ULB: "admin/api/auth/master/lsgi-details/list/all",
        ULB_FILTER: "admin/api/auth/master/lsgi-details/filter-list",
        IEA_LIST: "admin/api/auth/master/iea/list/all"
    },
    GRAND_ALLOCATION: {
        FUND_LIST: "gms/api/auth/gms/total-fund-allocation/list/all",
        FUND_UPDATE: "gms/api/auth/gms/total-fund-allocation/save",
        ULB_LIST: "gms/api/auth/gms/ulb-population-details/list/all",
        ULB_CREATE: "gms/api/auth/gms/ulb-population-details/save",
        ULB_UPDATE: "gms/api/auth/gms/ulb-population-details/save",
        ULB_DELETE: "",
        ULB_BY_ID: "gms/api/auth/gms/ulb-population-details/list/all",
        GAC_LIST: "gms/api/auth/gms/grant/ceiling/list/all",
        GAC_COMPUTE: "gms/api/auth/gms/grant/ceiling/save",
        CENSUS_YEAR: "gms/api/auth/gms/census-year/list/all",
        TOTAL_POPULATION: "gms/api/auth/gms/ulb-population-details/list/total-population"
    },
    RECEIPT_GENERATE: {
        LIST: "gms/api/auth/gms/receipt-generator/list/all",
        POST: "gms/api/auth/gms/receipt-generator/save",
        GET: "gms/api/auth/gms/receipt-generator/list/by-id",
        DELETE: "gms/api/auth/gms/receipt-generator/delete/:id",
        ADD_ALL: "gms/api/auth/gms/receipt-generator/addAll"
    },
    GRAND_REQUEST: {
        REQUEST_LIST: "gms/api/auth/gms/grant/application/list/all",
        VIEW: "gms/api/auth/gms/grant/application/list/by-id/",
        CRITERIA_LIST: "gms/api/auth/gms/grant-eligibility-criteria/list/all",
        GROUPED_CRITERIA: "gms/api/auth/gms/grant/group/criterias/list/all",
        CRITERIA_OPTION: "gms/api/auth/gms/criteria-options/list/all",
        SUBMIT: "gms/api/auth/gms/grant/application/save",
        UPDATE: "gms/api/auth/gms/grant/application/update",
        SUBMIT_TO_DPMU: "gms/api/auth/gms/grant/application/submit",
        SUBMIT_TO_IVA: "gms/api/auth/gms/grant/application/request-iva-verification",
        SUBMIT_TO_SPMU: "gms/api/auth/gms/grant/application/submit-to-spmu",
        SPMU_APPROVE: "gms/api/auth/gms/grant/application/approve-by-spmu",
        SPMU_REJECT: "gms/api/auth/gms/grant/application/reject-by-spmu",
        DPMU_REJECT: "gms/api/auth/gms/grant/application/reject-by-dpmu",
        IVA_VERIFY: "gms/api/auth/gms/grant/application/iva-verified",
        DELETE_ULB_FILE: "gms/api/auth/gms/grant/application/delete/:id/criteria-attachment/:fileId",
        DELETE_IVA_FILE: "gms/api/auth/gms/grant/application/delete/:id/iva-attachment/:fileId"
    },
    IVA: {
        LIST: "admin/api/auth/master/ward/list/all",
        REMARKS: "notification/api/no-auth/notification/otp/verify",
        LIST_CRITERIA: "gms/api/auth/gms/grant-eligibility-criteria/list/all"
    },
    PLAN_VIEW: {
        LIST: ""
    },
    GRAND_UTILIZATION: {
        REQUEST_LIST: "gms/api/auth/gms/grant/utilization/application/projects/list/by-id",
        VIEW: "gms/api/auth/gms/grant/utilization/application/list/by-id",
        APPROVE_DPMU: "gms/api/auth/gms/grant/utilization/application/approve/by-dpmu",
        SUBMIT_TO_DPMU: "gms/api/auth/gms/grant/utilization/application/submit/to-dpmu",
        RETURN_TO_ULB: "gms/api/auth/gms/grant/utilization/application/reject",
        SUBMIT_PROJECT_DETAILS: "gms/api/auth/gms/grant/utilization/application/projects/update",
        UTILIZATION_LIST: "gms/api/auth/gms/grant/utilization/application/list/all",
        SPMU_APPROVE: "gms/api/auth/gms/grant/utilization/application/approve/by-spmu",
        SUBMIT_TO_SPMU: "gms/api/auth/gms/grant/utilization/application/submit/to-spmu"
    },
    FETCH_EXPENSES: {
        LIST: "gms/api/auth/gms/grant/utilization/fetch-from-sankhya",
        ULB_LIST: "admin/api/auth/master/lsgi-details/list/all",
        FY_LIST: "gms/api/auth/gms/financial-year/list/all",
        SAVE: "gms/api/auth/gms/grant/utilization/summary/save",
        SUBMIT: "gms/api/auth/gms/grant/utilization/application/save",
        LIST_EXIST: "gms/api/auth/gms/grant/utilization/summary/list/by/:id/:idFy"
    },
    FINANCIAL_YEAR: {
        LIST: "gms/api/auth/gms/financial-year/list/all",
        DELETE: "gms/api/auth/gms/financial-year/delete/:id",
        SUBMIT: "gms/api/auth/gms/financial-year/save",
        FETCH_BY_ID: "gms/api/auth/gms/financial-year/list/all",
        EDIT: "gms/api/auth/gms/financial-year/save"

    },
    BILLS: {
        LIST: "gms/api/auth/gms/grant-utilization-bills/list/all",
        DELETE: "gms/api/auth/gms/grant-utilization-bills/delete/:id",
        SUBMIT: "gms/api/auth/gms/grant-utilization-bills/save",
        FETCH_BY_ID: "gms/api/auth/gms/grant-utilization-bills/list/all",
        EDIT: "gms/api/auth/gms/grant-utilization-bills/save",
        DISTRICTS: "admin/api/auth/master/district/list/all",
        LSGI: "admin/api/auth/master/lsgi-details/list/all",
        LSGI_TYPES: "admin/api/auth/master/lsgi-type/list/all",
        BILL_STATUS: "gms/api/auth/gms/bill-status/list/all",
        PROJECTS: "spm/api/auth/spm/sub-project/preparation-list/list/all",
        FETCH_BILLS_FROM_SANKHYA: "spm/api/auth/spm/sub-project/sankhya/project/fetch-bill",
        LIST_ALL_FETCH_BILL_FROM_SANKHYA: "spm/api/auth/spm/sub-project/sankhya/project/bill-detail/list/all",
        FETCH_TOTAL_EXPENSES_FROM_SANKHYA: "spm/api/auth/spm/sub-project/sankhya/lsgi-wise/project/bills"
    }

};
