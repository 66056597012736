import React from "react";
import { Components, Icons } from "common/components";
import { Tooltip } from "@mui/material";
import Brouchure from "../assets/brouchure.pdf";

const Brochure = () => {
    let { Card, Grid, IconButton } = Components;
    const { CloudDownload } = Icons;
    const handleDownload = () => {
        const a = document.createElement("a");
        a.href = Brouchure;
        a.download = "Brouchure";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    return (
        <Card sx={{ m: 2, p: 2, overflow: "visible", height: "80vh", width: "90%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "20px" }} >
            <Grid><label htmlFor="" style={{ fontSize: "20px" }}>Thank You For Sharing Your Feedback..!</label></Grid>
            <Grid >
                <Tooltip title="Download Brouchure">
                    <IconButton onClick={handleDownload}>
                        <CloudDownload fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid><label htmlFor="">Click here to Download the Brouchure</label></Grid>
        </Card>
    );
};

export default Brochure;
