import React from "react";
import { I18n } from "common/components";

const PrivacyPolicy = () => {

    return (
        <div style={{ backgroundColor: "#FFFF" }}>
            <div style={{ marginLeft: "100px", marginRight: "100px" }}>
                <h2>{I18n("privacy_policy")}</h2>
                <p> {I18n("privacy_policy_body")}</p>
                <h3>  {I18n("information_we_collect")} </h3>
                <p> {I18n("information_we_collect_body")}</p>
                <ul>
                    <li>{I18n("information_we_collect_list_01")}</li>
                    <li>{I18n("information_we_collect_list_02")}</li>
                    <li>{I18n("information_we_collect_list_03")}</li>
                    <li>  {I18n("information_we_collect_list_04")}</li>
                </ul>
            </div>
            <div style={{ marginLeft: "100px", marginRight: "100px" }}>
                <h3>{I18n("information_we_share")}</h3>
                <p>{I18n("information_we_share_body")}</p>
                <p>{I18n("information_we_share_body_02")}</p>
                <ul>
                    <li>{I18n("information_we_share_list_01")}</li>
                    <li>{I18n("information_we_share_list_02")}</li>
                    <li>{I18n("information_we_share_list_03")}</li>
                </ul>
            </div>
            <div style={{ marginLeft: "100px", marginRight: "100px" }}>
                <h3>{I18n("information_security")}</h3>
                <p>{I18n("information_security_body")}</p>
            </div>
            <div className="terms-content" style={{ marginLeft: "100px", marginRight: "100px" }} >
                <h3>{I18n("changes")}</h3>
                <p>{I18n("changes_body")}</p>
            </div>
            <div className="terms-content" style={{ marginLeft: "100px", marginRight: "100px" }}>
                <h3> {I18n("contact_us")}</h3>
                <p>{I18n("contact_us_body")}</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
