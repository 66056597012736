import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    SUBMIT_USER_DETAILS: `${STATE_REDUCER_KEY}/SUBMIT_USER_DETAILS`,
    SUBMIT_USER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_USER_DETAILS_REQUEST`,
    SUBMIT_USER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_USER_DETAILS_SUCCESS`,
    SUBMIT_USER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_USER_DETAILS_FAILURE`,

    FETCH_USER_TYPES: `${STATE_REDUCER_KEY}/FETCH_USER_TYPES`,
    FETCH_USER_TYPES_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_TYPES_REQUEST`,
    FETCH_USER_TYPES_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_TYPES_SUCCESS`,
    FETCH_USER_TYPES_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_TYPES_FAILURE`,

    FETCH_USER_DETAILS: `${STATE_REDUCER_KEY}/FETCH_USER_DETAILS`,
    FETCH_USER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_DETAILS_REQUEST`,
    FETCH_USER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_DETAILS_SUCCESS`,
    FETCH_USER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_DETAILS_FAILURE`,

    FETCH_USERS: `${STATE_REDUCER_KEY}/FETCH_USERS`,
    FETCH_USERS_V2: `${STATE_REDUCER_KEY}/FETCH_USERS_V2`,
    SET_USERS: `${STATE_REDUCER_KEY}/SET_USERS`,
    SET_USERS_V2: `${STATE_REDUCER_KEY}/SET_USERS_v2`,
    ASSIGN_USER: `${STATE_REDUCER_KEY}/ASSIGN_USER`,

    FETCH_CHECK_DETAILS: `${STATE_REDUCER_KEY}/FETCH_USER_DETAILS`,
    FETCH_CHECK_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CHECK_DETAILS_REQUEST`,
    FETCH_CHECK_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CHECK_DETAILS_SUCCESS`,
    FETCH_CHECK_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CHECK_DETAILS_FAILURE`,

    DOWNLOAD_SAMPLE_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_SAMPLE_REPORT`

};

export const submitUserDetails = createAction(ACTION_TYPES.SUBMIT_USER_DETAILS);

export const fetchUserTypes = createAction(ACTION_TYPES.FETCH_USER_TYPES);

export const getUserDetails = createAction(ACTION_TYPES.FETCH_USER_DETAILS);

export const loadUsers = createAction(ACTION_TYPES.FETCH_USERS);

export const loadUsersV2 = createAction(ACTION_TYPES.FETCH_USERS_V2);

export const setUsers = createAction(ACTION_TYPES.SET_USERS);

export const setUsersV2 = createAction(ACTION_TYPES.SET_USERS_V2);

export const assignUser = createAction(ACTION_TYPES.ASSIGN_USER);

export const checkUser = createAction(ACTION_TYPES.FETCH_CHECK_DETAILS);

export const downloadSampleReport = createAction(ACTION_TYPES.DOWNLOAD_SAMPLE_REPORT);
