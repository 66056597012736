import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchUserTypesApi = () => ({
    url: API_URL.EXAMPLE.USER_TYPES,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_USER_TYPES_REQUEST, ACTION_TYPES.FETCH_USER_TYPES_SUCCESS, ACTION_TYPES.FETCH_USER_TYPES_FAILURE]
    }
});

export const testAPICall = (query) => {
    return {
        url: API_URL.USER.USER_DETAILS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_USER_DETAILS_REQUEST, ACTION_TYPES.FETCH_USER_DETAILS_SUCCESS, ACTION_TYPES.FETCH_USER_DETAILS_FAILURE],
            query
        }
    };
};

export const testTableAPICall = ({ pageIndex, count, pageSize }) => {
    let start = pageIndex * pageSize;
    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
        fetch(`http://jsonplaceholder.typicode.com/photos?_start=${start}&_limit=${pageSize}&count=${count}&pageIndex=${pageIndex}`)
            .then(response => response.json())
            .then(json => resolve(json))
            .catch(err => reject(err));

    });
};
