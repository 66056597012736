import { PictureAsPdf } from "@mui/icons-material";
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Components, I18n } from "common/components";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { withFormik } from "formik";
import LoadingCustomOverlay from "modules/common/components/LoadingOverlay";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { downloadGrantAllocationReport, fetchGrantAllocationReport } from "../actions";
import { getGrantAllocationReport, getGrantAllocationReportProgress } from "../selectors";
import { actions as sliceActions } from "../slice";

const { Grid, Box, IconButton } = Components;

const useStyles = makeStyles(() => ({
    tableContainer: {
        overflowX: "auto",
        maxWidth: "100%"
    },
    table: {
        minWidth: 600,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            fontSize: "18px"
        }
    }
}));

const GrandAllocationReport = (props) => {

    const { grantAllocation, requestInProgress=true } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [newPage, setNewPage] = useState(1);

    useEffect(() => {
        dispatch(fetchGrantAllocationReport({pageNo: 0, pageSize: 10}));
        return () => {
            dispatch(sliceActions.clearGrantAllocation());
        };
    }, []);

    const pagination = (event, value) => {
        setNewPage(value);
        dispatch(fetchGrantAllocationReport({pageSize: 10, pageNo: value-1}));
    };

    let customActions = [];

    customActions.push({ title: I18n("download_pdf"), icon: <PictureAsPdf fontSize="small" />, handleClick: () => dispatch(downloadGrantAllocationReport({ type: "pdf" })) });
    return (
        <Box sx={{ maxHeight: "100%", maxWidth: "100%"}}>
            <LoadingCustomOverlay active={requestInProgress}>
            <Grid item lg={12} md={12} sm={12} sx={{display: "flex", justifyContent: "end", ml: 1}}>
                <IconButton>
                    <CustomListMenu customActions={customActions} type="download"/>
                </IconButton>
            </Grid>
            <Grid className={classes.tableContainer} >
                <TableContainer sx={{ maxHeight: {lg: "550px", xl: "600px"} }}>
                    <Table sx={{ p: 1, minWidth: "300px", textAlign: "left" }} size="small" aria-label="a dense table" className={classes.table}>
                        <TableHead sx={{ fontSize: "18px", fontFamily: "Clash Display" }}>
                            <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                <TableCell sx={{ color: "#FFFF" }} rowSpan={3}>{I18n("sl_no")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} rowSpan={3}>{I18n("district")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} rowSpan={3}>{I18n("ulb")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} rowSpan={3}>{I18n("status")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} rowSpan={3}>2011 Population</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} colSpan={4}>{I18n("share_of_the_ulb_in_component_2_outlay_based_on_2011_census_population")}</TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                <TableCell sx={{ color: "#FFFF" }}>{I18n("in_fund_available_to_all_ulbs")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }}>{I18n("in_fund_earmarked_for_muncipalities_only")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} rowSpan={2}>{I18n("in_overall_outlay_for_component_2")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} rowSpan={2}>{I18n("rs_crores")}</TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                <TableCell sx={{ color: "#FFFF" }}>80%</TableCell>
                                <TableCell sx={{ color: "#FFFF" }}>20%</TableCell>
                            </TableRow>
                        </TableHead>
                         <TableBody sx={{ maxHeight: "20em", overflow: scroll }}>
                                {grantAllocation?.content?.map((data, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell sx={{ minWidth: "1em" }}>{data?.slNo}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.districtName}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.lsgiName ?? "----"}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.lsgiType ?? "----"}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.population?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.eightyPerFund ?? "----"}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.twentyPerFund}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.overall ?? "----"}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.totFund?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                        </TableRow>
                                        );
                                    })}
                            </TableBody>

                    </Table>
                </TableContainer>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "10px"}}>
                    <Pagination count={grantAllocation?.totalPages} color="primary" page={newPage} onChange={pagination} />
                </Grid>
            </Grid>
            </LoadingCustomOverlay>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    grantAllocation: getGrantAllocationReport,
    requestInProgress: getGrantAllocationReportProgress
});

const GrandAllocationReportForm = withFormik({})(GrandAllocationReport);
export default connect(mapStateToProps, null)(GrandAllocationReportForm);
