import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    POST_GAP_ANALYSIS: `${STATE_REDUCER_KEY}/POST_GAP_ANALYSIS`,
    POST_GAP_ANALYSIS_REQUEST: `${STATE_REDUCER_KEY}/POST_GAP_ANALYSIS_REQUEST`,
    POST_GAP_ANALYSIS_SUCCESS: `${STATE_REDUCER_KEY}/POST_GAP_ANALYSIS_SUCCESS`,
    POST_GAP_ANALYSIS_FAILURE: `${STATE_REDUCER_KEY}/POST_GAP_ANALYSIS_FAILURE`,

    FETCH_GAP_ANALYSIS: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS`,
    FETCH_GAP_ANALYSIS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS_REQUEST`,
    FETCH_GAP_ANALYSIS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS_SUCCESS`,
    FETCH_GAP_ANALYSIS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS_FAILURE`,

    FETCH_GAP_ANALYSIS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS_BY_ID`,
    FETCH_GAP_ANALYSIS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS_BY_ID_REQUEST`,
    FETCH_GAP_ANALYSIS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS_BY_ID_SUCCESS`,
    FETCH_GAP_ANALYSIS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS_BY_ID_FAILURE`,

    SAVE_WASTE_GENERATED_BY_ID: `${STATE_REDUCER_KEY}/SAVE_WASTE_GENERATED_BY_ID`,
    SAVE_WASTE_GENERATED_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/SAVE_WASTE_GENERATED_BY_ID_REQUEST`,
    SAVE_WASTE_GENERATED_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_WASTE_GENERATED_BY_ID_SUCCESS`,
    SAVE_WASTE_GENERATED_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/SAVE_WASTE_GENERATED_BY_ID_FAILURE`,

    SAVE_WASTE_GENERATED: `${STATE_REDUCER_KEY}/SAVE_WASTE_GENERATED`,
    SAVE_WASTE_GENERATED_REQUEST: `${STATE_REDUCER_KEY}/SAVE_WASTE_GENERATED_REQUEST`,
    SAVE_WASTE_GENERATED_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_WASTE_GENERATED_SUCCESS`,
    SAVE_WASTE_GENERATED_FAILURE: `${STATE_REDUCER_KEY}/SAVE_WASTE_GENERATED_FAILURE`,

    UPDATE_WASTE_GENERATED: `${STATE_REDUCER_KEY}/UPDATE_WASTE_GENERATED`,
    UPDATE_WASTE_GENERATED_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_WASTE_GENERATED_REQUEST`,
    UPDATE_WASTE_GENERATED_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_WASTE_GENERATED_SUCCESS`,
    UPDATE_WASTE_GENERATED_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_WASTE_GENERATED_FAILURE`,

    LIST_WASTE_GENERATED: `${STATE_REDUCER_KEY}/LIST_WASTE_GENERATED`,
    LIST_WASTE_GENERATED_REQUEST: `${STATE_REDUCER_KEY}/LIST_WASTE_GENERATED_REQUEST`,
    LIST_WASTE_GENERATED_SUCCESS: `${STATE_REDUCER_KEY}/LIST_WASTE_GENERATED_SUCCESS`,
    LIST_WASTE_GENERATED_FAILURE: `${STATE_REDUCER_KEY}/LIST_WASTE_GENERATED_FAILURE`,

    POST_PROFILE_OF_LOCAL_BODY: `${STATE_REDUCER_KEY}/POST_PROFILE_OF_LOCAL_BODY`,
    POST_PROFILE_OF_LOCAL_BODY_REQUEST: `${STATE_REDUCER_KEY}/POST_PROFILE_OF_LOCAL_BODY_REQUEST`,
    POST_PROFILE_OF_LOCAL_BODY_SUCCESS: `${STATE_REDUCER_KEY}/POST_PROFILE_OF_LOCAL_BODY_SUCCESS`,
    POST_PROFILE_OF_LOCAL_BODY_FAILURE: `${STATE_REDUCER_KEY}/POST_PROFILE_OF_LOCAL_BODY_FAILURE`,

    SELECT_LSGI_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_LSGI_DROPDOWN`,
    SELECT_LSGI_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_LSGI_DROPDOWN_REQUEST`,
    SELECT_LSGI_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_LSGI_DROPDOWN_SUCCESS`,
    SELECT_LSGI_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_LSGI_DROPDOWN_FAILURE`,

    FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY: `${STATE_REDUCER_KEY}/FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY`,
    FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY_REQUEST`,
    FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY_SUCCESS`,
    FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY_FAILURE`,

    FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY: `${STATE_REDUCER_KEY}/FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY`,
    FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY_REQUEST`,
    FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY_SUCCESS`,
    FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY_FAILURE`,

    NON_BIO_FACILITIES_BY_ID: `${STATE_REDUCER_KEY}/NON_BIO_FACILITIES_BY_ID`,
    NON_BIO_FACILITIES_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/NON_BIO_FACILITIES_BY_ID_REQUEST`,
    NON_BIO_FACILITIES_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/NON_BIO_FACILITIES_BY_ID_SUCCESS`,
    NON_BIO_FACILITIES_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/NON_BIO_FACILITIES_BY_ID_FAILURE`,

    UPDATE_NON_BIO_FACILITIES: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_FACILITIES`,
    UPDATE_NON_BIO_FACILITIES_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_FACILITIES_REQUEST`,
    UPDATE_NON_BIO_FACILITIES_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_FACILITIES_SUCCESS`,
    UPDATE_NON_BIO_FACILITIES_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_FACILITIES_FAILURE`,

    FETCH_TRANSPORTATION_BY_ID: `${STATE_REDUCER_KEY}/FETCH_TRANSPORTATION_BY_ID`,
    FETCH_TRANSPORTATION_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TRANSPORTATION_BY_ID_REQUEST`,
    FETCH_TRANSPORTATION_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TRANSPORTATION_BY_ID_SUCCESS`,
    FETCH_TRANSPORTATION_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TRANSPORTATION_BY_ID_FAILURE`,

    POST_TRANSPORTATION: `${STATE_REDUCER_KEY}/POST_TRANSPORTATION`,
    POST_TRANSPORTATION_REQUEST: `${STATE_REDUCER_KEY}/POST_TRANSPORTATION_REQUEST`,
    POST_TRANSPORTATION_SUCCESS: `${STATE_REDUCER_KEY}/POST_TRANSPORTATION_SUCCESS`,
    POST_TRANSPORTATION_FAILURE: `${STATE_REDUCER_KEY}/POST_TRANSPORTATION_FAILURE`,

    FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD: `${STATE_REDUCER_KEY}/FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD`,
    FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD_REQUEST`,
    FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS`,
    FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD_FAILURE`,

    FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS: `${STATE_REDUCER_KEY}/FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS`,
    FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS_REQUEST`,
    FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS_SUCCESS`,
    FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS_FAILURE`,

    POST_WASTE_GENERATORS_HOUSE_HOLD: `${STATE_REDUCER_KEY}/POST_WASTE_GENERATORS_HOUSE_HOLD`,
    POST_WASTE_GENERATORS_HOUSE_HOLD_REQUEST: `${STATE_REDUCER_KEY}/POST_WASTE_GENERATORS_HOUSE_HOLD_REQUEST`,
    POST_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS: `${STATE_REDUCER_KEY}/POST_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS`,
    POST_WASTE_GENERATORS_HOUSE_HOLD_FAILURE: `${STATE_REDUCER_KEY}/POST_WASTE_GENERATORS_HOUSE_HOLD_FAILURE`,

    POST_WASTE_GENERATORS_INSTITUTIONS: `${STATE_REDUCER_KEY}/POST_WASTE_GENERATORS_INSTITUTIONS`,
    POST_WASTE_GENERATORS_INSTITUTIONS_REQUEST: `${STATE_REDUCER_KEY}/POST_WASTE_GENERATORS_INSTITUTIONS_REQUEST`,
    POST_WASTE_GENERATORS_INSTITUTIONS_SUCCESS: `${STATE_REDUCER_KEY}/POST_WASTE_GENERATORS_INSTITUTIONS_SUCCESS`,
    POST_WASTE_GENERATORS_INSTITUTIONS_FAILURE: `${STATE_REDUCER_KEY}/POST_WASTE_GENERATORS_INSTITUTIONS_FAILURE`,

    UPDATE_NON_BIO_FACILITIES_EQUIPMENT: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_FACILITIES_EQUIPMENT`,
    UPDATE_NON_BIO_FACILITIES_EQUIPMENT_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_FACILITIES_EQUIPMENT_REQUEST`,
    UPDATE_NON_BIO_FACILITIES_EQUIPMENT_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_FACILITIES_EQUIPMENT_SUCCESS`,
    UPDATE_NON_BIO_FACILITIES_EQUIPMENT_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_FACILITIES_EQUIPMENT_FAILURE`,

    NON_BIO_FACILITIES_EQUIPMENT_BY_ID: `${STATE_REDUCER_KEY}/NON_BIO_FACILITIES_EQUIPMENT_BY_ID`,
    NON_BIO_FACILITIES_EQUIPMENT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/NON_BIO_FACILITIES_EQUIPMENT_BY_ID_REQUEST`,
    NON_BIO_FACILITIES_EQUIPMENT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/NON_BIO_FACILITIES_EQUIPMENT_BY_ID_SUCCESS`,
    NON_BIO_FACILITIES_EQUIPMENT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/NON_BIO_FACILITIES_EQUIPMENT_BY_ID_FAILURE`,

    FETCH_FORWARD_LINKED_BY_ID: `${STATE_REDUCER_KEY}/FETCH_FORWARD_LINKED_BY_ID`,
    FETCH_FORWARD_LINKED_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FORWARD_LINKED_BY_ID_REQUEST`,
    FETCH_FORWARD_LINKED_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FORWARD_LINKED_BY_ID_SUCCESS`,
    FETCH_FORWARD_LINKED_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FORWARD_LINKED_BY_ID_FAILURE`,

    POST_FORWARD_LINKED_BY_ID: `${STATE_REDUCER_KEY}/POST_FORWARD_LINKED_BY_ID`,
    POST_FORWARD_LINKED_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/POST_FORWARD_LINKED_BY_ID_REQUEST`,
    POST_FORWARD_LINKED_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/POST_FORWARD_LINKED_BY_ID_SUCCESS`,
    POST_FORWARD_LINKED_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/POST_FORWARD_LINKED_BY_ID_FAILURE`,

    FETCH_INVOLVEMENT_OF_HKS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_INVOLVEMENT_OF_HKS_BY_ID`,
    FETCH_INVOLVEMENT_OF_HKS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_INVOLVEMENT_OF_HKS_BY_ID_REQUEST`,
    FETCH_INVOLVEMENT_OF_HKS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_INVOLVEMENT_OF_HKS_BY_ID_SUCCESS`,
    FETCH_INVOLVEMENT_OF_HKS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_INVOLVEMENT_OF_HKS_BY_ID_FAILURE`,

    POST_INVOLVEMENT_OF_HKS_BY_ID: `${STATE_REDUCER_KEY}/POST_INVOLVEMENT_OF_HKS_BY_ID`,
    POST_INVOLVEMENT_OF_HKS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/POST_INVOLVEMENT_OF_HKS_BY_ID_REQUEST`,
    POST_INVOLVEMENT_OF_HKS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/POST_INVOLVEMENT_OF_HKS_BY_ID_SUCCESS`,
    POST_INVOLVEMENT_OF_HKS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/POST_INVOLVEMENT_OF_HKS_BY_ID_FAILURE`,

    BIO_FACILITIES_BY_ID: `${STATE_REDUCER_KEY}/BIO_FACILITIES_BY_ID`,
    BIO_FACILITIES_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/BIO_FACILITIES_BY_ID_REQUEST`,
    BIO_FACILITIES_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/BIO_FACILITIES_BY_ID_SUCCESS`,
    BIO_FACILITIES_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/BIO_FACILITIES_BY_ID_FAILURE`,

    UPDATE_BIO_FACILITIES: `${STATE_REDUCER_KEY}/UPDATE_BIO_FACILITIES`,
    UPDATE_BIO_FACILITIES_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_BIO_FACILITIES_REQUEST`,
    UPDATE_BIO_FACILITIES_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_BIO_FACILITIES_SUCCESS`,
    UPDATE_BIO_FACILITIES_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_BIO_FACILITIES_FAILURE`,

    UPDATE_BIO_FACILITIES_EQUIPMENT: `${STATE_REDUCER_KEY}/UPDATE_BIO_FACILITIES_EQUIPMENT`,
    UPDATE_BIO_FACILITIES_EQUIPMENT_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_BIO_FACILITIES_EQUIPMENT_REQUEST`,
    UPDATE_BIO_FACILITIES_EQUIPMENT_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_BIO_FACILITIES_EQUIPMENT_SUCCESS`,
    UPDATE_BIO_FACILITIES_EQUIPMENT_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_BIO_FACILITIES_EQUIPMENT_FAILURE`,

    BIO_FACILITIES_EQUIPMENT_BY_ID: `${STATE_REDUCER_KEY}/BIO_FACILITIES_EQUIPMENT_BY_ID`,
    BIO_FACILITIES_EQUIPMENT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/BIO_FACILITIES_EQUIPMENT_BY_ID_REQUEST`,
    BIO_FACILITIES_EQUIPMENT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/BIO_FACILITIES_EQUIPMENT_BY_ID_SUCCESS`,
    BIO_FACILITIES_EQUIPMENT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/BIO_FACILITIES_EQUIPMENT_BY_ID_FAILURE`,

    FETCH_LIST_ALL_STREET_SWEEPING: `${STATE_REDUCER_KEY}/FETCH_LIST_ALL_STREET_SWEEPING`,
    FETCH_LIST_ALL_STREET_SWEEPING_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LIST_ALL_STREET_SWEEPING_REQUEST`,
    FETCH_LIST_ALL_STREET_SWEEPING_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LIST_ALL_STREET_SWEEPING_SUCCESS`,
    FETCH_LIST_ALL_STREET_SWEEPING_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LIST_ALL_STREET_SWEEPING_FAILURE`,

    FETCH_BY_ID_STREET_SWEEPING: `${STATE_REDUCER_KEY}/FETCH_BY_ID_STREET_SWEEPING`,
    FETCH_BY_ID_STREET_SWEEPING_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BY_ID_STREET_SWEEPING_REQUEST`,
    FETCH_BY_ID_STREET_SWEEPING_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BY_ID_STREET_SWEEPING_SUCCESS`,
    FETCH_BY_ID_STREET_SWEEPING_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BY_ID_STREET_SWEEPING_FAILURE`,

    POST_STREET_SWEEPING_AND_DRAINS: `${STATE_REDUCER_KEY}/POST_STREET_SWEEPING_AND_DRAINS`,
    POST_STREET_SWEEPING_AND_DRAINS_REQUEST: `${STATE_REDUCER_KEY}/POST_STREET_SWEEPING_AND_DRAINS_REQUEST`,
    POST_STREET_SWEEPING_AND_DRAINS_SUCCESS: `${STATE_REDUCER_KEY}/POST_STREET_SWEEPING_AND_DRAINS_SUCCESS`,
    POST_STREET_SWEEPING_AND_DRAINS_FAILURE: `${STATE_REDUCER_KEY}/POST_STREET_SWEEPING_AND_DRAINS_FAILURE`,

    UPDATE_LAND_DETAILS: `${STATE_REDUCER_KEY}/UPDATE_LAND_DETAILS`,
    UPDATE_LAND_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_LAND_DETAILS_REQUEST`,
    UPDATE_LAND_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_LAND_DETAILS_SUCCESS`,
    UPDATE_LAND_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_LAND_DETAILS_FAILURE`,

    LAND_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/LAND_DETAILS_BY_ID`,
    LAND_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/LAND_DETAILS_BY_ID_REQUEST`,
    LAND_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/LAND_DETAILS_BY_ID_SUCCESS`,
    LAND_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/LAND_DETAILS_BY_ID_FAILURE`,

    STAFF_PAYMENT_BY_ID: `${STATE_REDUCER_KEY}/STAFF_PAYMENT_BY_ID`,
    STAFF_PAYMENT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/STAFF_PAYMENT_BY_ID_REQUEST`,
    STAFF_PAYMENT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/STAFF_PAYMENT_BY_ID_SUCCESS`,
    STAFF_PAYMENT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/STAFF_PAYMENT_BY_ID_FAILURE`,

    STAFF_PAYMENT_LIST_ALL: `${STATE_REDUCER_KEY}/STAFF_PAYMENT_LIST_ALL`,
    STAFF_PAYMENT_LIST_ALL_REQUEST: `${STATE_REDUCER_KEY}/STAFF_PAYMENT_LIST_ALL_REQUEST`,
    STAFF_PAYMENT_LIST_ALL_SUCCESS: `${STATE_REDUCER_KEY}/STAFF_PAYMENT_LIST_ALL_SUCCESS`,
    STAFF_PAYMENT_LIST_ALL_FAILURE: `${STATE_REDUCER_KEY}/STAFF_PAYMENT_LIST_ALL_FAILURE`,

    SAVE_STAFF_PAYMENT: `${STATE_REDUCER_KEY}/SAVE_STAFF_PAYMENT`,
    SAVE_STAFF_PAYMENT_REQUEST: `${STATE_REDUCER_KEY}/SAVE_STAFF_PAYMENT_REQUEST`,
    SAVE_STAFF_PAYMENT_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_STAFF_PAYMENT_SUCCESS`,
    SAVE_STAFF_PAYMENT_FAILURE: `${STATE_REDUCER_KEY}/SAVE_STAFF_PAYMENT_FAILURE`,

    PATCH_STAFF_PAYMENT: `${STATE_REDUCER_KEY}/PATCH_STAFF_PAYMENT`,
    PATCH_STAFF_PAYMENT_REQUEST: `${STATE_REDUCER_KEY}/PATCH_STAFF_PAYMENT_REQUEST`,
    PATCH_STAFF_PAYMENT_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_STAFF_PAYMENT_SUCCESS`,
    PATCH_STAFF_PAYMENT_FAILURE: `${STATE_REDUCER_KEY}/PATCH_STAFF_PAYMENT_FAILURE`,

    PATCH_OTHER_NON_BIO_FACILITIES: `${STATE_REDUCER_KEY}/PATCH_OTHER_NON_BIO_FACILITIES`,
    PATCH_OTHER_NON_BIO_FACILITIES_REQUEST: `${STATE_REDUCER_KEY}/PATCH_OTHER_NON_BIO_FACILITIES_REQUEST`,
    PATCH_OTHER_NON_BIO_FACILITIES_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_OTHER_NON_BIO_FACILITIES_SUCCESS`,
    PATCH_OTHER_NON_BIO_FACILITIES_FAILURE: `${STATE_REDUCER_KEY}/PATCH_OTHER_NON_BIO_FACILITIES_FAILURE`,

    OTHER_NON_BIO_FACILITIES_ID: `${STATE_REDUCER_KEY}/OTHER_NON_BIO_FACILITIES_ID`,
    OTHER_NON_BIO_FACILITIES_ID_REQUEST: `${STATE_REDUCER_KEY}/OTHER_NON_BIO_FACILITIES_ID_REQUEST`,
    OTHER_NON_BIO_FACILITIES_ID_SUCCESS: `${STATE_REDUCER_KEY}/OTHER_NON_BIO_FACILITIES_ID_SUCCESS`,
    OTHER_NON_BIO_FACILITIES_ID_FAILURE: `${STATE_REDUCER_KEY}/OTHER_NON_BIO_FACILITIES_ID_FAILURE`,

    WASTE_PROCESSED_HOUSE_HOLD_BY_ID: `${STATE_REDUCER_KEY}/WASTE_PROCESSED_HOUSE_HOLD_BY_ID`,
    WASTE_PROCESSED_HOUSE_HOLD_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/WASTE_PROCESSED_HOUSE_HOLD_BY_ID_REQUEST`,
    WASTE_PROCESSED_HOUSE_HOLD_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/WASTE_PROCESSED_HOUSE_HOLD_BY_ID_SUCCESS`,
    WASTE_PROCESSED_HOUSE_HOLD_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/WASTE_PROCESSED_HOUSE_HOLD_BY_ID_FAILURE`,

    PATCH_WASTE_PROCESSED_HOUSE_HOLD: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSED_HOUSE_HOLD`,
    PATCH_WASTE_PROCESSED_HOUSE_HOLD_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSED_HOUSE_HOLD_REQUEST`,
    PATCH_WASTE_PROCESSED_HOUSE_HOLD_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSED_HOUSE_HOLD_SUCCESS`,
    PATCH_WASTE_PROCESSED_HOUSE_HOLD_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSED_HOUSE_HOLD_FAILURE`,

    SAVE_INCOME_FROM_WASTE_BY_ID: `${STATE_REDUCER_KEY}/SAVE_INCOME_FROM_WASTE_BY_ID`,
    SAVE_INCOME_FROM_WASTE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/SAVE_INCOME_FROM_WASTE_BY_ID_REQUEST`,
    SAVE_INCOME_FROM_WASTE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_INCOME_FROM_WASTE_BY_ID_SUCCESS`,
    SAVE_INCOME_FROM_WASTE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/SAVE_INCOME_FROM_WASTE_BY_ID_FAILURE`,

    SAVE_INCOME_FROM_WASTE: `${STATE_REDUCER_KEY}/SAVE_INCOME_FROM_WASTE`,
    SAVE_INCOME_FROM_WASTE_REQUEST: `${STATE_REDUCER_KEY}/SAVE_INCOME_FROM_WASTE_REQUEST`,
    SAVE_INCOME_FROM_WASTE_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_INCOME_FROM_WASTE_SUCCESS`,
    SAVE_INCOME_FROM_WASTE_FAILURE: `${STATE_REDUCER_KEY}/SAVE_INCOME_FROM_WASTE_FAILURE`,

    UPDATE_INCOME_FROM_WASTE: `${STATE_REDUCER_KEY}/UPDATE_INCOME_FROM_WASTE`,
    UPDATE_INCOME_FROM_WASTE_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_INCOME_FROM_WASTE_REQUEST`,
    UPDATE_INCOME_FROM_WASTE_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_INCOME_FROM_WASTE_SUCCESS`,
    UPDATE_INCOME_FROM_WASTE_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_INCOME_FROM_WASTE_FAILURE`,

    LIST_INCOME_FROM_WASTE: `${STATE_REDUCER_KEY}/LIST_INCOME_FROM_WASTE`,
    LIST_INCOME_FROM_WASTE_REQUEST: `${STATE_REDUCER_KEY}/LIST_INCOME_FROM_WASTE_REQUEST`,
    LIST_INCOME_FROM_WASTE_SUCCESS: `${STATE_REDUCER_KEY}/LIST_INCOME_FROM_WASTE_SUCCESS`,
    LIST_INCOME_FROM_WASTE_FAILURE: `${STATE_REDUCER_KEY}/LIST_INCOME_FROM_WASTE_FAILURE`,

    WASTE_PROCESSED_INSTITUTIONS_BY_ID: `${STATE_REDUCER_KEY}/WASTE_PROCESSED_INSTITUTIONS_BY_ID`,
    WASTE_PROCESSED_INSTITUTIONS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/WASTE_PROCESSED_INSTITUTIONS_BY_ID_REQUEST`,
    WASTE_PROCESSED_INSTITUTIONS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/WASTE_PROCESSED_INSTITUTIONS_BY_ID_SUCCESS`,
    WASTE_PROCESSED_INSTITUTIONS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/WASTE_PROCESSED_INSTITUTIONS_BY_ID_FAILURE`,

    PATCH_WASTE_PROCESSED_INSTITUTIONS: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSED_INSTITUTIONS`,
    PATCH_WASTE_PROCESSED_INSTITUTIONS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSED_INSTITUTIONS_REQUEST`,
    PATCH_WASTE_PROCESSED_INSTITUTIONS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSED_INSTITUTIONS_SUCCESS`,
    PATCH_WASTE_PROCESSED_INSTITUTIONS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSED_INSTITUTIONS_FAILURE`,

    BDW_COMMUNITY_ULB_LEVEL_LIST_ALL: `${STATE_REDUCER_KEY}/BDW_COMMUNITY_ULB_LEVEL_LIST_ALL`,
    BDW_COMMUNITY_ULB_LEVEL_LIST_ALL_REQUEST: `${STATE_REDUCER_KEY}/BDW_COMMUNITY_ULB_LEVEL_LIST_ALL_REQUEST`,
    BDW_COMMUNITY_ULB_LEVEL_LIST_ALL_SUCCESS: `${STATE_REDUCER_KEY}/BDW_COMMUNITY_ULB_LEVEL_LIST_ALL_SUCCESS`,
    BDW_COMMUNITY_ULB_LEVEL_LIST_ALL_FAILURE: `${STATE_REDUCER_KEY}/BDW_COMMUNITY_ULB_LEVEL_LIST_ALL_FAILURE`,

    BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID: `${STATE_REDUCER_KEY}/BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID`,
    BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID_REQUEST`,
    BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID_SUCCESS`,
    BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID_FAILURE`,

    POST_BDW_COMMUNITY_ULB_LEVEL_LIST: `${STATE_REDUCER_KEY}/POST_BDW_COMMUNITY_ULB_LEVEL_LIST`,
    POST_BDW_COMMUNITY_ULB_LEVEL_LIST_REQUEST: `${STATE_REDUCER_KEY}/POST_BDW_COMMUNITY_ULB_LEVEL_LIST_REQUEST`,
    POST_BDW_COMMUNITY_ULB_LEVEL_LIST_SUCCESS: `${STATE_REDUCER_KEY}/POST_BDW_COMMUNITY_ULB_LEVEL_LIST_SUCCESS`,
    POST_BDW_COMMUNITY_ULB_LEVEL_LIST_FAILURE: `${STATE_REDUCER_KEY}/POST_BDW_COMMUNITY_ULB_LEVEL_LIST_FAILURE`,

    PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST: `${STATE_REDUCER_KEY}/PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST`,
    PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_REQUEST: `${STATE_REDUCER_KEY}/PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_REQUEST`,
    PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_SUCCESS`,
    PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_FAILURE: `${STATE_REDUCER_KEY}/PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_FAILURE`,

    SAVE_BDW_PROCESSED_PER_DAY: `${STATE_REDUCER_KEY}/SAVE_BDW_PROCESSED_PER_DAY`,
    SAVE_BDW_PROCESSED_PER_DAY_REQUEST: `${STATE_REDUCER_KEY}/SAVE_BDW_PROCESSED_PER_DAY_REQUEST`,
    SAVE_BDW_PROCESSED_PER_DAY_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_BDW_PROCESSED_PER_DAY_SUCCESS`,
    SAVE_BDW_PROCESSED_PER_DAY_FAILURE: `${STATE_REDUCER_KEY}/SAVE_BDW_PROCESSED_PER_DAY_FAILURE`,

    SAVE_BDW_PROCESSED_PER_DAY_BY_ID: `${STATE_REDUCER_KEY}/SAVE_BDW_PROCESSED_PER_DAY_BY_ID`,
    SAVE_BDW_PROCESSED_PER_DAY_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/SAVE_BDW_PROCESSED_PER_DAY_BY_ID_REQUEST`,
    SAVE_BDW_PROCESSED_PER_DAY_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_BDW_PROCESSED_PER_DAY_BY_ID_SUCCESS`,
    SAVE_BDW_PROCESSED_PER_DAY_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/SAVE_BDW_PROCESSED_PER_DAY_BY_ID_FAILURE`,

    UPDATE_BDW_PROCESSED_PER_DAY: `${STATE_REDUCER_KEY}/UPDATE_BDW_PROCESSED_PER_DAY`,
    UPDATE_BDW_PROCESSED_PER_DAY_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_BDW_PROCESSED_PER_DAY_REQUEST`,
    UPDATE_BDW_PROCESSED_PER_DAY_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_BDW_PROCESSED_PER_DAY_SUCCESS`,
    UPDATE_BDW_PROCESSED_PER_DAY_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_BDW_PROCESSED_PER_DAY_FAILURE`,

    LIST_BDW_PROCESSED_PER_DAY: `${STATE_REDUCER_KEY}/LIST_BDW_PROCESSED_PER_DAY`,
    LIST_BDW_PROCESSED_PER_DAY_REQUEST: `${STATE_REDUCER_KEY}/LIST_BDW_PROCESSED_PER_DAY_REQUEST`,
    LIST_BDW_PROCESSED_PER_DAY_SUCCESS: `${STATE_REDUCER_KEY}/LIST_BDW_PROCESSED_PER_DAY_SUCCESS`,
    LIST_BDW_PROCESSED_PER_DAY_FAILURE: `${STATE_REDUCER_KEY}/LIST_BDW_PROCESSED_PER_DAY_FAILURE`,

    SAVE_POPULATION: `${STATE_REDUCER_KEY}/SAVE_POPULATION`,
    SAVE_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/SAVE_POPULATION_REQUEST`,
    SAVE_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_POPULATION_SUCCESS`,
    SAVE_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/SAVE_POPULATION_FAILURE`,

    SAVE_POPULATION_BY_ID: `${STATE_REDUCER_KEY}/SAVE_POPULATION_BY_ID`,
    SAVE_POPULATION_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/SAVE_POPULATION_BY_ID_REQUEST`,
    SAVE_POPULATION_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_POPULATION_BY_ID_SUCCESS`,
    SAVE_POPULATION_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/SAVE_POPULATION_BY_ID_FAILURE`,

    UPDATE_POPULATION: `${STATE_REDUCER_KEY}/UPDATE_POPULATION`,
    UPDATE_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_POPULATION_REQUEST`,
    UPDATE_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_POPULATION_SUCCESS`,
    UPDATE_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_POPULATION_FAILURE`,

    LIST_POPULATION: `${STATE_REDUCER_KEY}/LIST_POPULATION`,
    LIST_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/LIST_POPULATION_REQUEST`,
    LIST_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/LIST_POPULATION_SUCCESS`,
    LIST_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/LIST_POPULATION_FAILURE`,

    SAVE_BDW_COMMUNITY_LEVEL: `${STATE_REDUCER_KEY}/SAVE_BDW_COMMUNITY_LEVEL`,
    SAVE_BDW_COMMUNITY_LEVEL_REQUEST: `${STATE_REDUCER_KEY}/SAVE_BDW_COMMUNITY_LEVEL_REQUEST`,
    SAVE_BDW_COMMUNITY_LEVEL_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_BDW_COMMUNITY_LEVEL_SUCCESS`,
    SAVE_BDW_COMMUNITY_LEVEL_FAILURE: `${STATE_REDUCER_KEY}/SAVE_BDW_COMMUNITY_LEVEL_FAILURE`,

    SAVE_BDW_COMMUNITY_LEVEL_BY_ID: `${STATE_REDUCER_KEY}/SAVE_BDW_COMMUNITY_LEVEL_BY_ID`,
    SAVE_BDW_COMMUNITY_LEVEL_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/SAVE_BDW_COMMUNITY_LEVEL_BY_ID_REQUEST`,
    SAVE_BDW_COMMUNITY_LEVEL_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_BDW_COMMUNITY_LEVEL_BY_ID_SUCCESS`,
    SAVE_BDW_COMMUNITY_LEVEL_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/SAVE_BDW_COMMUNITY_LEVEL_BY_ID_FAILURE`,

    UPDATE_BDW_COMMUNITY_LEVEL: `${STATE_REDUCER_KEY}/UPDATE_BDW_COMMUNITY_LEVEL`,
    UPDATE_BDW_COMMUNITY_LEVEL_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_BDW_COMMUNITY_LEVEL_REQUEST`,
    UPDATE_BDW_COMMUNITY_LEVEL_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_BDW_COMMUNITY_LEVEL_SUCCESS`,
    UPDATE_BDW_COMMUNITY_LEVEL_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_BDW_COMMUNITY_LEVEL_FAILURE`,

    LIST_BDW_COMMUNITY_LEVEL: `${STATE_REDUCER_KEY}/LIST_BDW_COMMUNITY_LEVEL`,
    LIST_BDW_COMMUNITY_LEVEL_REQUEST: `${STATE_REDUCER_KEY}/LIST_BDW_COMMUNITY_LEVEL_REQUEST`,
    LIST_BDW_COMMUNITY_LEVEL_SUCCESS: `${STATE_REDUCER_KEY}/LIST_BDW_COMMUNITY_LEVEL_SUCCESS`,
    LIST_BDW_COMMUNITY_LEVEL_FAILURE: `${STATE_REDUCER_KEY}/LIST_BDW_COMMUNITY_LEVEL_FAILURE`,

    SAVE_NON_BIO_DEGRADABLE: `${STATE_REDUCER_KEY}/SAVE_NON_BIO_DEGRADABLE`,
    SAVE_NON_BIO_DEGRADABLE_REQUEST: `${STATE_REDUCER_KEY}/SAVE_NON_BIO_DEGRADABLE_REQUEST`,
    SAVE_NON_BIO_DEGRADABLE_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_NON_BIO_DEGRADABLE_SUCCESS`,
    SAVE_NON_BIO_DEGRADABLE_FAILURE: `${STATE_REDUCER_KEY}/SAVE_NON_BIO_DEGRADABLE_FAILURE`,

    SAVE_NON_BIO_DEGRADABLE_BY_ID: `${STATE_REDUCER_KEY}/SAVE_NON_BIO_DEGRADABLE_BY_ID`,
    SAVE_NON_BIO_DEGRADABLE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/SAVE_NON_BIO_DEGRADABLE_BY_ID_REQUEST`,
    SAVE_NON_BIO_DEGRADABLE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_NON_BIO_DEGRADABLE_BY_ID_SUCCESS`,
    SAVE_NON_BIO_DEGRADABLE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/SAVE_NON_BIO_DEGRADABLE_BY_ID_FAILURE`,

    UPDATE_NON_BIO_DEGRADABLE: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_DEGRADABLE`,
    UPDATE_NON_BIO_DEGRADABLE_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_DEGRADABLE_REQUEST`,
    UPDATE_NON_BIO_DEGRADABLE_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_DEGRADABLE_SUCCESS`,
    UPDATE_NON_BIO_DEGRADABLE_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_DEGRADABLE_FAILURE`,

    LIST_NON_BIO_DEGRADABLE: `${STATE_REDUCER_KEY}/LIST_NON_BIO_DEGRADABLE`,
    LIST_NON_BIO_DEGRADABLE_REQUEST: `${STATE_REDUCER_KEY}/LIST_NON_BIO_DEGRADABLE_REQUEST`,
    LIST_NON_BIO_DEGRADABLE_SUCCESS: `${STATE_REDUCER_KEY}/LIST_NON_BIO_DEGRADABLE_SUCCESS`,
    LIST_NON_BIO_DEGRADABLE_FAILURE: `${STATE_REDUCER_KEY}/LIST_NON_BIO_DEGRADABLE_FAILURE`,

    LIST_NON_BIO_FACILITIES_TYPE: `${STATE_REDUCER_KEY}/LIST_NON_BIO_FACILITIES_TYPE`,
    LIST_NON_BIO_FACILITIES_TYPE_REQUEST: `${STATE_REDUCER_KEY}/LIST_NON_BIO_FACILITIES_TYPE_REQUEST`,
    LIST_NON_BIO_FACILITIES_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/LIST_NON_BIO_FACILITIES_TYPE_SUCCESS`,
    LIST_NON_BIO_FACILITIES_TYPE_FAILURE: `${STATE_REDUCER_KEY}/LIST_NON_BIO_FACILITIES_TYPE_FAILURE`,

    BIO_DEGRADABLE_BY_ID: `${STATE_REDUCER_KEY}/BIO_DEGRADABLE_BY_ID`,
    BIO_DEGRADABLE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/BIO_DEGRADABLE_BY_ID_REQUEST`,
    BIO_DEGRADABLE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/BIO_DEGRADABLE_BY_ID_SUCCESS`,
    BIO_DEGRADABLE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/BIO_DEGRADABLE_BY_ID_FAILURE`,

    UPDATE_BIO_DEGRADABLE: `${STATE_REDUCER_KEY}/UPDATE_BIO_DEGRADABLE`,
    UPDATE_BIO_DEGRADABLE_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_BIO_DEGRADABLE_REQUEST`,
    UPDATE_BIO_DEGRADABLE_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_BIO_DEGRADABLE_SUCCESS`,
    UPDATE_BIO_DEGRADABLE_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_BIO_DEGRADABLE_FAILURE`,

    NON_BIO_DEGRADABLE_BY_ID: `${STATE_REDUCER_KEY}/NON_BIO_DEGRADABLE_BY_ID`,
    NON_BIO_DEGRADABLE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/NON_BIO_DEGRADABLE_BY_ID_REQUEST`,
    NON_BIO_DEGRADABLE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/NON_BIO_DEGRADABLE_BY_ID_SUCCESS`,
    NON_BIO_DEGRADABLE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/NON_BIO_DEGRADABLE_BY_ID_FAILURE`,

    UPDATE_NON_BIO_DEGRADABLE_LIST: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_DEGRADABLE_LIST`,
    UPDATE_NON_BIO_DEGRADABLE_LIST_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_DEGRADABLE_LIST_REQUEST`,
    UPDATE_NON_BIO_DEGRADABLE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_DEGRADABLE_LIST_SUCCESS`,
    UPDATE_NON_BIO_DEGRADABLE_LIST_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_NON_BIO_DEGRADABLE_LIST_FAILURE`,

    SUBMIT_NON_BIO_FACILITIES: `${STATE_REDUCER_KEY}/SUBMIT_NON_BIO_FACILITIES`,
    SUBMIT_NON_BIO_FACILITIES_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_NON_BIO_FACILITIES_REQUEST`,
    SUBMIT_NON_BIO_FACILITIES_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_NON_BIO_FACILITIES_SUCCESS`,
    SUBMIT_NON_BIO_FACILITIES_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_NON_BIO_FACILITIES_FAILURE`,

    FETCH_TOTAL_NON_BIO_FACILITIES: `${STATE_REDUCER_KEY}/FETCH_TOTAL_NON_BIO_FACILITIES`,
    FETCH_TOTAL_NON_BIO_FACILITIES_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOTAL_NON_BIO_FACILITIES_REQUEST`,
    FETCH_TOTAL_NON_BIO_FACILITIES_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_NON_BIO_FACILITIES_SUCCESS`,
    FETCH_TOTAL_NON_BIO_FACILITIES_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOTAL_NON_BIO_FACILITIES_FAILURE`,

    FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD: `${STATE_REDUCER_KEY}/FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD`,
    FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD_REQUEST`,
    FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS`,
    FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD_FAILURE`,

    FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS`,
    FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS_REQUEST`,
    FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS_SUCCESS`,
    FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS_FAILURE`,

    FETCH_CURRENT_STATUS: `${STATE_REDUCER_KEY}/FETCH_CURRENT_STATUS`,
    FETCH_CURRENT_STATUS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CURRENT_STATUS_REQUEST`,
    FETCH_CURRENT_STATUS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CURRENT_STATUS_SUCCESS`,
    FETCH_CURRENT_STATUS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CURRENT_STATUS_FAILURE`,

    FETCH_OWNERSHIP_LIST: `${STATE_REDUCER_KEY}/FETCH_OWNERSHIP_LIST`,
    FETCH_OWNERSHIP_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_OWNERSHIP_LIST_REQUEST`,
    FETCH_OWNERSHIP_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_OWNERSHIP_LIST_SUCCESS`,
    FETCH_OWNERSHIP_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_OWNERSHIP_LIST_FAILURE`,

    GET_BIO_FACILITIES_DROPDOWN: `${STATE_REDUCER_KEY}/GET_BIO_FACILITIES_DROPDOWN`,
    GET_BIO_FACILITIES_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_BIO_FACILITIES_DROPDOWN_REQUEST`,
    GET_BIO_FACILITIES_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_BIO_FACILITIES_DROPDOWN_SUCCESS`,
    GET_BIO_FACILITIES_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_BIO_FACILITIES_DROPDOWN_FAILURE`,

    FETCH_WARD_WISE_POPULATION: `${STATE_REDUCER_KEY}/FETCH_WARD_WISE_POPULATION`,
    FETCH_WARD_WISE_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_WISE_POPULATION_REQUEST`,
    FETCH_WARD_WISE_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_WISE_POPULATION_SUCCESS`,
    FETCH_WARD_WISE_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_WISE_POPULATION_FAILURE`,

    SAVE_WARD_WISE_POPULATION: `${STATE_REDUCER_KEY}/SAVE_WARD_WISE_POPULATION`,
    SAVE_WARD_WISE_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/SAVE_WARD_WISE_POPULATION_REQUEST`,
    SAVE_WARD_WISE_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_WARD_WISE_POPULATION_SUCCESS`,
    SAVE_WARD_WISE_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/SAVE_WARD_WISE_POPULATION_FAILURE`,

    PATCH_WARD_WISE_POPULATION: `${STATE_REDUCER_KEY}/PATCH_WARD_WISE_POPULATION`,
    PATCH_WARD_WISE_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WARD_WISE_POPULATION_REQUEST`,
    PATCH_WARD_WISE_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WARD_WISE_POPULATION_SUCCESS`,
    PATCH_WARD_WISE_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WARD_WISE_POPULATION_FAILURE`,

    BY_ID_WARD_WISE_POPULATION: `${STATE_REDUCER_KEY}/BY_ID_WARD_WISE_POPULATION`,
    BY_ID_WARD_WISE_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/BY_ID_WARD_WISE_POPULATION_REQUEST`,
    BY_ID_WARD_WISE_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/BY_ID_WARD_WISE_POPULATION_SUCCESS`,
    BY_ID_WARD_WISE_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/BY_ID_WARD_WISE_POPULATION_FAILURE`,

    DELETE_WARD_WISE_POPULATION: `${STATE_REDUCER_KEY}/DELETE_WARD_WISE_POPULATION`,
    DELETE_WARD_WISE_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/DELETE_WARD_WISE_POPULATION_REQUEST`,
    DELETE_WARD_WISE_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_WARD_WISE_POPULATION_SUCCESS`,
    DELETE_WARD_WISE_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/DELETE_WARD_WISE_POPULATION_FAILURE`,

    DOWNLOAD_CURRENT_STATUS_LIST: `${STATE_REDUCER_KEY}/DOWNLOAD_CURRENT_STATUS_LIST`,
    DOWNLOAD_CURRENT_STATUS_LIST_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_CURRENT_STATUS_LIST_REQUEST`,
    DOWNLOAD_CURRENT_STATUS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_CURRENT_STATUS_LIST_SUCCESS`,
    DOWNLOAD_CURRENT_STATUS_LIST_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_CURRENT_STATUS_LIST_FAILURE`

};

export const postGapAnalysisVersion = createAction(ACTION_TYPES.POST_GAP_ANALYSIS);
export const fetchGapAnalysisVersion = createAction(ACTION_TYPES.FETCH_GAP_ANALYSIS);
export const fetchGapAnalysisVersionById = createAction(ACTION_TYPES.FETCH_GAP_ANALYSIS_BY_ID);
export const saveWasteGenerated = createAction(ACTION_TYPES.SAVE_WASTE_GENERATED);
export const updateWasteGenerated = createAction(ACTION_TYPES.UPDATE_WASTE_GENERATED);
export const wasteGeneratedById = createAction(ACTION_TYPES.SAVE_WASTE_GENERATED_BY_ID);
export const listWasteGenerated = createAction(ACTION_TYPES.LIST_WASTE_GENERATED);
export const postProfileOFLocalBody = createAction(ACTION_TYPES.POST_PROFILE_OF_LOCAL_BODY);
export const selectLsgiDropdown = createAction(ACTION_TYPES.SELECT_LSGI_DROPDOWN);
export const fetchListAllProfileOfLocalBody = createAction(ACTION_TYPES.FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY);
export const fetchProfileOfLocalbodyById = createAction(ACTION_TYPES.FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY);
export const nonBioFacilitiesListById = createAction(ACTION_TYPES.NON_BIO_FACILITIES_BY_ID);
export const updateNonBioFacilitiesList = createAction(ACTION_TYPES.UPDATE_NON_BIO_FACILITIES);
export const fetchTransportationById = createAction(ACTION_TYPES.FETCH_TRANSPORTATION_BY_ID);
export const postTransportation = createAction(ACTION_TYPES.POST_TRANSPORTATION);
export const fetchWasteGeneratorsHouseholdById = createAction(ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD);
export const fetchWasteGeneratorsInstitutions = createAction(ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS);
export const postWasteGeneratorsHouseholdById = createAction(ACTION_TYPES.POST_WASTE_GENERATORS_HOUSE_HOLD);
export const postWasteGeneratorsInstitutionsById = createAction(ACTION_TYPES.POST_WASTE_GENERATORS_INSTITUTIONS);
export const nonBioFacilitiesEquipmentListById = createAction(ACTION_TYPES.NON_BIO_FACILITIES_EQUIPMENT_BY_ID);
export const updateNonBioFacilitiesEquipments = createAction(ACTION_TYPES.UPDATE_NON_BIO_FACILITIES_EQUIPMENT);
export const fetchForwardLinkedById = createAction(ACTION_TYPES.FETCH_FORWARD_LINKED_BY_ID);
export const postForwardLinked = createAction(ACTION_TYPES.POST_FORWARD_LINKED_BY_ID);
export const fetchInvolvementOfHksById = createAction(ACTION_TYPES.FETCH_INVOLVEMENT_OF_HKS_BY_ID);
export const postInvolvementOfHks = createAction(ACTION_TYPES.POST_INVOLVEMENT_OF_HKS_BY_ID);
export const bioFacilitiesListById = createAction(ACTION_TYPES.BIO_FACILITIES_BY_ID);
export const updateBioFacilitiesList = createAction(ACTION_TYPES.UPDATE_BIO_FACILITIES);
export const bioFacilitiesEquipmentListById = createAction(ACTION_TYPES.BIO_FACILITIES_EQUIPMENT_BY_ID);
export const updateBioFacilitiesEquipments = createAction(ACTION_TYPES.UPDATE_BIO_FACILITIES_EQUIPMENT);
export const listAllStreetSweeping = createAction(ACTION_TYPES.FETCH_LIST_ALL_STREET_SWEEPING);
export const postStreetSweeping = createAction(ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS);
export const fetchStreetSweepingById = createAction(ACTION_TYPES.FETCH_BY_ID_STREET_SWEEPING);
export const landDetailsById = createAction(ACTION_TYPES.LAND_DETAILS_BY_ID);
export const updateLandDetails = createAction(ACTION_TYPES.UPDATE_LAND_DETAILS);
export const fetchStaffPaymentById = createAction(ACTION_TYPES.STAFF_PAYMENT_BY_ID);
export const fetchStaffPaymentListAll = createAction(ACTION_TYPES.STAFF_PAYMENT_LIST_ALL);
export const postStaffPayment = createAction(ACTION_TYPES.SAVE_STAFF_PAYMENT);
export const patchStaffPayment = createAction(ACTION_TYPES.PATCH_STAFF_PAYMENT);
export const fetchOtherNonBioFacilitiesById = createAction(ACTION_TYPES.OTHER_NON_BIO_FACILITIES_ID);
export const patchOtherNonBioFacilitiesById = createAction(ACTION_TYPES.PATCH_OTHER_NON_BIO_FACILITIES);
export const fetchWasteProcessedHouseHoldById = createAction(ACTION_TYPES.WASTE_PROCESSED_HOUSE_HOLD_BY_ID);
export const patchWasteProcessedHouseHoldById = createAction(ACTION_TYPES.PATCH_WASTE_PROCESSED_HOUSE_HOLD);
export const saveIncomeFromWaste = createAction(ACTION_TYPES.SAVE_INCOME_FROM_WASTE);
export const updateIncomeFromWaste = createAction(ACTION_TYPES.UPDATE_INCOME_FROM_WASTE);
export const incomeFromWasteById = createAction(ACTION_TYPES.SAVE_INCOME_FROM_WASTE_BY_ID);
export const listIncomeFromWaste = createAction(ACTION_TYPES.LIST_INCOME_FROM_WASTE);
export const fetchWasteProcessedInstitutions = createAction(ACTION_TYPES.WASTE_PROCESSED_INSTITUTIONS_BY_ID);
export const patchWasteProcessedInstitutions = createAction(ACTION_TYPES.PATCH_WASTE_PROCESSED_INSTITUTIONS);
export const fetchBdwCommunityOrUlbLevelListAll = createAction(ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_ALL);
export const fetchBdwCommunityOrUlbLevelListById = createAction(ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID);
export const postBdwCommunityOrUlbLevelList = createAction(ACTION_TYPES.POST_BDW_COMMUNITY_ULB_LEVEL_LIST);
export const patchBdwCommunityOrUlbLevelList = createAction(ACTION_TYPES.PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST);
export const saveBdwProcessedPerDay = createAction(ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY);
export const updateBdwProcessedPerDay = createAction(ACTION_TYPES.UPDATE_BDW_PROCESSED_PER_DAY);
export const bdwProcessedPerDayById = createAction(ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_BY_ID);
export const listBdwProcessedPerDay = createAction(ACTION_TYPES.LIST_BDW_PROCESSED_PER_DAY);
export const savePopulation = createAction(ACTION_TYPES.SAVE_POPULATION);
export const updatePopulation = createAction(ACTION_TYPES.UPDATE_POPULATION);
export const populationById = createAction(ACTION_TYPES.SAVE_POPULATION_BY_ID);
export const listPopulation = createAction(ACTION_TYPES.LIST_POPULATION);
export const saveBdwCommunityLevelUlb = createAction(ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL);
export const updateBdwCommunityLevelUlb = createAction(ACTION_TYPES.UPDATE_BDW_COMMUNITY_LEVEL);
export const bdwCommunityLevelUlbById = createAction(ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_BY_ID);
export const listBdwCommunityLevelUlb = createAction(ACTION_TYPES.LIST_BDW_COMMUNITY_LEVEL);
export const saveNonBioDegradable = createAction(ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE);
export const updateNonBioDegradable = createAction(ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE);
export const nonBioDegradableById = createAction(ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_BY_ID);
export const listNonBioDegradable = createAction(ACTION_TYPES.LIST_NON_BIO_DEGRADABLE);
export const bioDegradableById = createAction(ACTION_TYPES.BIO_DEGRADABLE_BY_ID);
export const updateBioDegradable = createAction(ACTION_TYPES.UPDATE_BIO_DEGRADABLE);
export const nonBioDegradableListById = createAction(ACTION_TYPES.NON_BIO_DEGRADABLE_BY_ID);
export const updateNonBioDegradableList = createAction(ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_LIST);
export const submitNonBioFacilitiesList = createAction(ACTION_TYPES.SUBMIT_NON_BIO_FACILITIES);
export const fetchNonBioFacilitiesTotal = createAction(ACTION_TYPES.FETCH_TOTAL_NON_BIO_FACILITIES);
export const fetchHouseHoldTotal = createAction(ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD);
export const fetchInstitutionsTotal = createAction(ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS);
export const fetchNonBioFacilitiesType = createAction(ACTION_TYPES.LIST_NON_BIO_FACILITIES_TYPE);
export const fetchCurrentStatus = createAction(ACTION_TYPES.FETCH_CURRENT_STATUS);
export const fetchOwnerShipList = createAction(ACTION_TYPES.FETCH_OWNERSHIP_LIST);
export const getBioFacilitiesDropDown = createAction(ACTION_TYPES.GET_BIO_FACILITIES_DROPDOWN);
export const fetchWardWisePopulation = createAction(ACTION_TYPES.FETCH_WARD_WISE_POPULATION);
export const saveWardWisePopulation = createAction(ACTION_TYPES.SAVE_WARD_WISE_POPULATION);
export const patchWardWisePopulation = createAction(ACTION_TYPES.PATCH_WARD_WISE_POPULATION);
export const byIdWardWisePopulation = createAction(ACTION_TYPES.BY_ID_WARD_WISE_POPULATION);
export const deleteWardWisePopulation = createAction(ACTION_TYPES.DELETE_WARD_WISE_POPULATION);
export const downloadCurrentStatusList = createAction(ACTION_TYPES.DOWNLOAD_CURRENT_STATUS_LIST);


