import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    SUBMIT_LOOKUP_FORM_VALUES: `${STATE_REDUCER_KEY}/SUBMIT_LOOKUP_FORM_VALUES`,
    SUBMIT_LOOKUP_FORM_VALUES_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_LOOKUP_FORM_VALUES_REQUEST`,
    SUBMIT_LOOKUP_FORM_VALUES_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_LOOKUP_FORM_VALUES_SUCCESS`,
    SUBMIT_LOOKUP_FORM_VALUES_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_LOOKUP_FORM_VALUES_FAILURE`,


    SUBMIT_CATEGORY_TYPE_VALUES: `${STATE_REDUCER_KEY}/SUBMIT_CATEGORY_TYPE_VALUES`,
    SUBMIT_CATEGORY_TYPE_VALUES_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_CATEGORY_TYPE_VALUES_REQUEST`,
    SUBMIT_CATEGORY_TYPE_VALUES_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_CATEGORY_TYPE_VALUES_SUCCESS`,
    SUBMIT_CATEGORY_TYPE_VALUES_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_CATEGORY_TYPE_VALUES_FAILURE`,

    FETCH_LOOKUP_FORM_LIST: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_FORM_LIST`,
    FETCH_LOOKUP_FORM_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_FORM_LIST_REQUEST`,
    FETCH_LOOKUP_FORM_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_FORM_LIST_SUCCESS`,
    FETCH_LOOKUP_FORM_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_FORM_LIST_FAILURE`,

    FETCH_LOOKUP_FORM_BY_ID: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_FORM_BY_ID`,
    FETCH_LOOKUP_FORM_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_FORM_BY_ID_REQUEST`,
    FETCH_LOOKUP_FORM_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_FORM_BY_ID_SUCCESS`,
    FETCH_LOOKUP_FORM_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_FORM_BY_ID_FAILURE`,

    DELETE_LOOKUP_FORM_BY_ID: `${STATE_REDUCER_KEY}/DELETE_LOOKUP_FORM_BY_ID`,
    DELETE_LOOKUP_FORM_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/DELETE_LOOKUP_FORM_BY_ID_REQUEST`,
    DELETE_LOOKUP_FORM_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_LOOKUP_FORM_BY_ID_SUCCESS`,
    DELETE_LOOKUP_FORM_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/DELETE_LOOKUP_FORM_BY_ID_FAILURE`,

    FETCH_LOOKUP_CATEGORY_BY_ID: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_BY_ID`,
    FETCH_LOOKUP_CATEGORY_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_BY_ID_REQUEST`,
    FETCH_LOOKUP_CATEGORY_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_BY_ID_SUCCESS`,
    FETCH_LOOKUP_CATEGORY_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_BY_ID_FAILURE`,

    CREATE_LOOKUP_FORM: `${STATE_REDUCER_KEY}/CREATE_LOOKUP_FORM`,
    CREATE_LOOKUP_FORM_REQUEST: `${STATE_REDUCER_KEY}/CREATE_LOOKUP_FORM_REQUEST`,
    CREATE_LOOKUP_FORM_SUCCESS: `${STATE_REDUCER_KEY}/CREATE_LOOKUP_FORM_SUCCESS`,
    CREATE_LOOKUP_FORM_FAILURE: `${STATE_REDUCER_KEY}/CREATE_LOOKUP_FORM_FAILURE`,

    EDIT_LOOKUP_FORM: `${STATE_REDUCER_KEY}/EDIT_LOOKUP_FORM`,
    EDIT_LOOKUP_FORM_REQUEST: `${STATE_REDUCER_KEY}/EDIT_LOOKUP_FORM_REQUEST`,
    EDIT_LOOKUP_FORM_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_LOOKUP_FORM_SUCCESS`,
    EDIT_LOOKUP_FORM_FAILURE: `${STATE_REDUCER_KEY}/EDIT_LOOKUP_FORM_FAILURE`,

    FETCH_LOOKUP_CATEGORY_ID_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_ID_DROPDOWN`,
    FETCH_LOOKUP_CATEGORY_ID_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_ID_DROPDOWN_REQUEST`,
    FETCH_LOOKUP_CATEGORY_ID_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_ID_DROPDOWN_SUCCESS`,
    FETCH_LOOKUP_CATEGORY_ID_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_ID_DROPDOWN_FAILURE`,


    FETCH_LOOKUP_CATEGORY_TYPE_LIST: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_TYPE_LIST`,
    FETCH_LOOKUP_CATEGORY_TYPE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_TYPE_LIST_REQUEST`,
    FETCH_LOOKUP_CATEGORY_TYPE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_TYPE_LIST_SUCCESS`,
    FETCH_LOOKUP_CATEGORY_TYPE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_TYPE_LIST_FAILURE`,


    DELETE_LOOKUP_CATEGORY_TYPE_LIST: `${STATE_REDUCER_KEY}/DELETE_LOOKUP_CATEGORY_TYPE_LIST`,
    DELETE_LOOKUP_CATEGORY_TYPE_LIST_REQUEST: `${STATE_REDUCER_KEY}/DELETE_LOOKUP_CATEGORY_TYPE_LIST_REQUEST`,
    DELETE_LOOKUP_CATEGORY_TYPE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_LOOKUP_CATEGORY_TYPE_LIST_SUCCESS`,
    DELETE_LOOKUP_CATEGORY_TYPE_LIST_FAILURE: `${STATE_REDUCER_KEY}/DELETE_LOOKUP_CATEGORY_TYPE_LIST_FAILURE`,

    CREATE_LOOKUP_CATEGORY_TYPE: `${STATE_REDUCER_KEY}/CREATE_LOOKUP_CATEGORY_TYPE`,
    CREATE_LOOKUP_CATEGORY_TYPE_REQUEST: `${STATE_REDUCER_KEY}/CREATE_LOOKUP_CATEGORY_TYPE_REQUEST`,
    CREATE_LOOKUP_CATEGORY_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/CREATE_LOOKUP_CATEGORY_TYPE_SUCCESS`,
    CREATE_LOOKUP_CATEGORY_TYPE_FAILURE: `${STATE_REDUCER_KEY}/CREATE_LOOKUP_CATEGORY_TYPE_FAILURE`,

    EDIT_LOOKUP_CATEGORY_TYPE: `${STATE_REDUCER_KEY}/EDIT_LOOKUP_CATEGORY_TYPE`,
    EDIT_LOOKUP_CATEGORY_TYPE_REQUEST: `${STATE_REDUCER_KEY}/EDIT_LOOKUP_CATEGORY_TYPE_REQUEST`,
    EDIT_LOOKUP_CATEGORY_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_LOOKUP_CATEGORY_TYPE_SUCCESS`,
    EDIT_LOOKUP_CATEGORY_TYPE_FAILURE: `${STATE_REDUCER_KEY}/EDIT_LOOKUP_CATEGORY_TYPE_FAILURE`,

    FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
    FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
    FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
    FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,

    FETCH_LOOK_UP_VALUE_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_LOOK_UP_VALUE_NAME_LIST`,
    FETCH_LOOK_UP_VALUE_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOOK_UP_VALUE_NAME_LIST_REQUEST`,
    FETCH_LOOK_UP_VALUE_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOOK_UP_VALUE_NAME_LIST_SUCCESS`,
    FETCH_LOOK_UP_VALUE_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOOK_UP_VALUE_NAME_LIST_FAILURE`
};

export const loadLookUpList = createAction(ACTION_TYPES.FETCH_LOOKUP_FORM_LIST);

export const getLookupFormValuesById = createAction(ACTION_TYPES.FETCH_LOOKUP_FORM_BY_ID);

export const getlookUpCategoryIdDropDown = createAction(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_DROPDOWN);

export const submitLookupFormValues = createAction(ACTION_TYPES.SUBMIT_LOOKUP_FORM_VALUES);

export const deleteLookUpForm = createAction(ACTION_TYPES.DELETE_LOOKUP_FORM_BY_ID);

export const loadLookupCategoryTypeList = createAction(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_TYPE_LIST);

export const submitCategoryTypeValues = createAction(ACTION_TYPES.SUBMIT_CATEGORY_TYPE_VALUES);

export const deleteLookUpCategoryType = createAction(ACTION_TYPES.DELETE_LOOKUP_CATEGORY_TYPE_LIST);

export const getLookupCategoryValuesById = createAction(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_BY_ID);

export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);

export const getLookUpValueNameList = createAction(ACTION_TYPES.FETCH_LOOK_UP_VALUE_NAME_LIST);
