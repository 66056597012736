import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchComplaintCategory = () => {
    return {
        url: API_URL.COMPLAINT.COMPLAINT_CATEGORY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_FAILURE],
            data: { dropdown: true }
        }
    };
};

//complaint type
export const listComplaintType = (data) => {
    return {
        url: API_URL.COMPLAINT.COMPLAINT_TYPE_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_COMPLAINT_TYPE_FORM_REQUEST, ACTION_TYPES.LIST_COMPLAINT_TYPE_FORM_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_TYPE_FORM_FAILURE],
            data
        }
    };
};
export const submitComplaintType = (data) => {
    return {
        url: API_URL.COMPLAINT.SUBMIT_TYPE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_COMPLAINT_TYPE_REQUEST, ACTION_TYPES.SUBMIT_COMPLAINT_TYPE_SUCCESS, ACTION_TYPES.SUBMIT_COMPLAINT_TYPE_FAILURE],
            data
        }
    };
};
export const fetchComplaintTypeById = (id) => {
    return {
        url: API_URL.COMPLAINT.COMPLAINT_TYPE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_TYPE_BY_ID_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_TYPE_BY_ID_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_TYPE_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const deleteComplaintType = (id) => {
    return {
        url: API_URL.COMPLAINT.COMPLAINT_TYPE_DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_COMPLAINT_TYPE_REQUEST, ACTION_TYPES.DELETE_COMPLAINT_TYPE_SUCCESS, ACTION_TYPES.DELETE_COMPLAINT_TYPE_FAILURE]
        }
    };
};
export const editComplaintType = (data) => {
    return {
        url: API_URL.COMPLAINT.COMPLAINT_TYPE_EDIT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_COMPLAINT_TYPE_REQUEST, ACTION_TYPES.EDIT_COMPLAINT_TYPE_SUCCESS, ACTION_TYPES.EDIT_COMPLAINT_TYPE_FAILURE],
            data
        }
    };
};

//complaint category
export const listComplaintCategory = (data) => {
    return {
        url: API_URL.COMPLAINT.COMPLAINT_CATEGORY_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_COMPLAINT_CATEGORY_FORM_REQUEST, ACTION_TYPES.LIST_COMPLAINT_CATEGORY_FORM_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_CATEGORY_FORM_FAILURE],
            data
        }
    };
};
export const submitComplaintCategory = (data) => {
    return {
        url: API_URL.COMPLAINT.SUBMIT_COMPLAINT_CATEGORY,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_COMPLAINT_CATEGORY_REQUEST, ACTION_TYPES.SUBMIT_COMPLAINT_CATEGORY_SUCCESS, ACTION_TYPES.SUBMIT_COMPLAINT_CATEGORY_FAILURE],
            data
        }
    };
};
export const fetchComplaintCategoryById = (id) => {
    return {
        url: API_URL.COMPLAINT.COMPLAINT_CATEGORY_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_BY_ID_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_BY_ID_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const deleteComplaintCategory = (id) => {
    return {
        url: API_URL.COMPLAINT.COMPLAINT_CATEGORY_DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_COMPLAINT_CATEGORY_REQUEST, ACTION_TYPES.DELETE_COMPLAINT_CATEGORY_SUCCESS, ACTION_TYPES.DELETE_COMPLAINT_CATEGORY_FAILURE]
        }
    };
};
export const editComplaintCategory = (data) => {
    return {
        url: API_URL.COMPLAINT.COMPLAINT_CATEGORY_EDIT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_COMPLAINT_CATEGORY_REQUEST, ACTION_TYPES.EDIT_COMPLAINT_CATEGORY_SUCCESS, ACTION_TYPES.EDIT_COMPLAINT_CATEGORY_FAILURE],
            data
        }
    };
};

//complaint status
export const submitComplaintStatus = (data) => {
    return {
        url: API_URL.COMPLAINT.STATUS_SUBMIT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_COMPLAINT_STATUS_REQUEST, ACTION_TYPES.SUBMIT_COMPLAINT_STATUS_SUCCESS, ACTION_TYPES.SUBMIT_COMPLAINT_STATUS_FAILURE],
            data
        }
    };
};

export const listComplaintStatus = (data) => {
    return {
        url: API_URL.COMPLAINT.STATUS_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_COMPLAINT_STATUS_FORM_REQUEST, ACTION_TYPES.LIST_COMPLAINT_STATUS_FORM_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_STATUS_FORM_FAILURE],
            data
        }
    };
};

export const fetchComplaintStatusById = (id) => {
    return {
        url: API_URL.COMPLAINT.STATUS_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_STATUS_BY_ID_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_STATUS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_STATUS_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const deleteComplaintStatus = (id) => {
    return {
        url: API_URL.COMPLAINT.STATUS_DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_COMPLAINT_STATUS_REQUEST, ACTION_TYPES.DELETE_COMPLAINT_STATUS_SUCCESS, ACTION_TYPES.DELETE_COMPLAINT_STATUS_FAILURE]
        }
    };
};
export const editComplaintStatus = (data) => {
    return {
        url: API_URL.COMPLAINT.STATUS_EDIT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_COMPLAINT_STATUS_REQUEST, ACTION_TYPES.EDIT_COMPLAINT_STATUS_SUCCESS, ACTION_TYPES.EDIT_COMPLAINT_STATUS_FAILURE],
            data
        }
    };
};

export const fetchEscalationDropDownApi = () => {
    return {
        url: API_URL.COMPLAINT_ESCALATION.ESCALATION_UNIT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ESCALATION_UNIT_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_ESCALATION_UNIT_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_ESCALATION_UNIT_DROPDOWN_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const fetchTargetRoleLevelDropDownApi = () => {
    return {
        url: API_URL.COMPLAINT_ESCALATION.TARGET_ROLE_LEVEL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TARGET_LEVEL_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_TARGET_LEVEL_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_TARGET_LEVEL_DROPDOWN_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const fetchOfficersListApi = (params) => {
    return {
        url: API_URL.COMPLAINT_ESCALATION.USER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_OFFICERS_LIST_REQUEST, ACTION_TYPES.FETCH_OFFICERS_LIST_SUCCESS, ACTION_TYPES.FETCH_OFFICERS_LIST_FAILURE],
            data: { userType: 1, ...params }
        }
    };
};

export const submitEscalationApi = (data) => {
    return {
        url: API_URL.COMPLAINT_ESCALATION.SUBMIT_ESCALATION,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_ESCALATION_REQUEST, ACTION_TYPES.SUBMIT_ESCALATION_SUCCESS, ACTION_TYPES.SUBMIT_ESCALATION_FAILURE],
            data
        }
    };
};

export const updateEscalationApi = (data) => {
    return {
        url: API_URL.COMPLAINT_ESCALATION.SUBMIT_ESCALATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_ESCALATION_REQUEST, ACTION_TYPES.UPDATE_ESCALATION_SUCCESS, ACTION_TYPES.UPDATE_ESCALATION_FAILURE],
            data
        }
    };
};

export const fetchEscalationLevelApi = ({ payload }) => {
    return {
        url: API_URL.COMPLAINT_ESCALATION.GET_ESCALATION_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID_REQUEST, ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID_SUCCESS, ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID_FAILURE],
            data: { ...payload }
        }
    };
};
export const deleteEscalationApi = (id) => {
    return {
        url: API_URL.COMPLAINT_ESCALATION.DELETE_ESCALATION_BY_ID.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_ESCALATION_REQUEST, ACTION_TYPES.DELETE_ESCALATION_SUCCESS, ACTION_TYPES.DELETE_ESCALATION_FAILURE]
        }
    };
};

export const filterApi = (data) => {
    return {
        url: API_URL.COMPLAINT.FILTER_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FILTER_COMPLAINT_TYPE_REQUEST,
                ACTION_TYPES.FILTER_COMPLAINT_TYPE_SUCCESS,
                ACTION_TYPES.FILTER_COMPLAINT_TYPE_FAILURE
            ],
            data
        }
    };
};

export const filterCategoryDropdownApi = (data) => {
    return {
        url: API_URL.COMPLAINT.CATEGORY_FILTER,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_FILTER_CATEGORY_REQUEST,
                ACTION_TYPES.FETCH_FILTER_CATEGORY_SUCCESS,
                ACTION_TYPES.FETCH_FILTER_CATEGORY_FAILURE
            ],
            data
        }
    };
};
