import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchLookUpFormList = (data) => {
    return {
        url: API_URL.LOOKUP.LOOKUP_FORM_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LOOKUP_FORM_LIST_REQUEST, ACTION_TYPES.FETCH_LOOKUP_FORM_LIST_SUCCESS, ACTION_TYPES.FETCH_LOOKUP_FORM_LIST_FAILURE],
            data
        }
    };
};
export const fetchLookUpFormCategoryIdDropDown = () => {
    return {
        url: API_URL.LOOKUP.LOOKUP_FORM_CATEGORY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_DROPDOWN_FAILURE],
            data: { dropDown: true, pageSize: 300 }
        }
    };
};

export const fetchLookUpFormById = ({ id }) => {
    return {
        url: API_URL.LOOKUP.LOOKUP_FORM_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LOOKUP_FORM_BY_ID_REQUEST, ACTION_TYPES.FETCH_LOOKUP_FORM_BY_ID_SUCCESS, ACTION_TYPES.FETCH_LOOKUP_FORM_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const fetchLookUpCategoryById = ({ id }) => {
    return {
        url: API_URL.LOOKUP.LOOKUP_CATEGORY_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LOOKUP_CATEGORY_BY_ID_REQUEST, ACTION_TYPES.FETCH_LOOKUP_CATEGORY_BY_ID_SUCCESS, ACTION_TYPES.FETCH_LOOKUP_CATEGORY_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const deleteLookUpFormById = ({ id }) => {
    return {
        url: API_URL.LOOKUP.DELETE_LOOKUP_FORM.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_LOOKUP_FORM_BY_ID_REQUEST, ACTION_TYPES.DELETE_LOOKUP_FORM_BY_ID_SUCCESS, ACTION_TYPES.DELETE_LOOKUP_FORM_BY_ID_FAILURE]
        }
    };
};

export const createLookUpform = (data) => {
    return {
        url: API_URL.LOOKUP.CREATE_LOOKUP_FORM,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.CREATE_LOOKUP_FORM_REQUEST, ACTION_TYPES.CREATE_LOOKUP_FORM_SUCCESS, ACTION_TYPES.CREATE_LOOKUP_FORM_FAILURE],
            data
        }
    };
};

export const editLookUpform = (data) => {
    return {
        url: API_URL.LOOKUP.EDIT_LOOKUP_FORM,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_LOOKUP_FORM_REQUEST, ACTION_TYPES.EDIT_LOOKUP_FORM_SUCCESS, ACTION_TYPES.EDIT_LOOKUP_FORM_FAILURE],
            data
        }
    };
};


export const fetchLookUpCategoryTypeList = (data) => {
    return {
        url: API_URL.LOOKUP.LOOKUP_CATEGORY_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LOOKUP_CATEGORY_TYPE_LIST_REQUEST, ACTION_TYPES.FETCH_LOOKUP_CATEGORY_TYPE_LIST_SUCCESS, ACTION_TYPES.FETCH_LOOKUP_CATEGORY_TYPE_LIST_FAILURE],
            data
        }
    };
};


export const deleteLookUpCategoryType = ({ id }) => {
    return {
        url: API_URL.LOOKUP.DELETE_LOOKUP_CATEGORY.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_LOOKUP_CATEGORY_TYPE_LIST_REQUEST, ACTION_TYPES.DELETE_LOOKUP_CATEGORY_TYPE_LIST_SUCCESS, ACTION_TYPES.DELETE_LOOKUP_CATEGORY_TYPE_LIST_FAILURE]
        }
    };
};

export const editLookUpCategoryType = (data) => {
    return {
        url: API_URL.LOOKUP.EDIT_LOOKUP_CATEGORY,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_LOOKUP_CATEGORY_TYPE_REQUEST, ACTION_TYPES.EDIT_LOOKUP_CATEGORY_TYPE_SUCCESS, ACTION_TYPES.EDIT_LOOKUP_CATEGORY_TYPE_FAILURE],
            data
        }
    };
};

export const createLookUpCategoryType = (data) => {
    return {
        url: API_URL.LOOKUP.CREATE_LOOKUP_CATEGORY,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.CREATE_LOOKUP_CATEGORY_TYPE_REQUEST, ACTION_TYPES.CREATE_LOOKUP_CATEGORY_TYPE_SUCCESS, ACTION_TYPES.CREATE_LOOKUP_CATEGORY_TYPE_FAILURE],
            data
        }
    };
};

export const nameList = (data) => {
    return {
        url: API_URL.LOOKUP.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_NAME_LIST_REQUEST,
                ACTION_TYPES.FETCH_NAME_LIST_SUCCESS,
                ACTION_TYPES.FETCH_NAME_LIST_FAILURE
            ],
            data
        }
    };
};
export const lookUpValueNameList = (data) => {
    return {
        url: API_URL.LOOKUP.LOOK_UP_VALUE_FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_LOOK_UP_VALUE_NAME_LIST_REQUEST,
                ACTION_TYPES.FETCH_LOOK_UP_VALUE_NAME_LIST_SUCCESS,
                ACTION_TYPES.FETCH_LOOK_UP_VALUE_NAME_LIST_FAILURE
            ],
            data
        }
    };
};
