import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const getGrandManagementReport = (state) => state[STATE_REDUCER_KEY];

const quarterDropdown = (state) => state.QuarterDropDown.data;
export const getQuarterDropdown = flow(getGrandManagementReport, quarterDropdown);

const Gur1Report = (state) => state.Gur1.data;
export const getGur1Report = flow(getGrandManagementReport, Gur1Report);

const Gur1ReportRequestInProgress = (state) => state.Gur1.requestInProgress;
export const getGur1ReportRequestInProgress = flow(getGrandManagementReport, Gur1ReportRequestInProgress);

const ProjectDetails = (state) => state.projectDetails.data;
export const getProjectDetails = flow(getGrandManagementReport, ProjectDetails);

const RequestInProgress = (state) => state.projectDetails.requestInProgress;
export const getRequestInProgress = flow(getGrandManagementReport, RequestInProgress);

const Gur2Report = (state) => state.gur2Report.data;
export const getGur2Report = flow(getGrandManagementReport, Gur2Report);

const RequestInProgressGur2 = (state) => state.gur2Report.requestInProgress;
export const getRequestInProgressGur2 = flow(getGrandManagementReport, RequestInProgressGur2);

const IncentiveGrantReport = (state) => state.incentiveGrantData.data;
export const getIncentiveGrantReport = flow(getGrandManagementReport, IncentiveGrantReport);

const IncentiveGrantReportInProgress = (state) => state.incentiveGrantData.requestInProgress;
export const getIncentiveGrantReportInProgress = flow(getGrandManagementReport, IncentiveGrantReportInProgress);

const IncentiveGrantPresentStatusReport = (state) => state.incentiveGrantPresentStatusData.data;
export const getIncentiveGrantPresentStatusReport = flow(getGrandManagementReport, IncentiveGrantPresentStatusReport);

const IncentiveGrantPresentStatusReportInProgress = (state) => state.incentiveGrantPresentStatusData.requestInProgress;
export const getIncentiveGrantPresentStatusReportInProgress = flow(getGrandManagementReport, IncentiveGrantPresentStatusReportInProgress);

const Gur3DataReport = (state) => state.gur3Data.data;
export const getGur3DataReport = flow(getGrandManagementReport, Gur3DataReport);

const Gur3DataReportReportInProgress = (state) => state.gur3Data.requestInProgress;
export const getGur3DataReportReportInProgress = flow(getGrandManagementReport, Gur3DataReportReportInProgress);

const Gur2CumulativeToDate = (state) => state.gur2CumulativeToDate.data;
export const getGur2CumulativeToDate = flow(getGrandManagementReport, Gur2CumulativeToDate);

const GetGur2CumulativeToDateInProgress = (state) => state.gur2CumulativeToDate.requestInProgress;
export const getGetGur2CumulativeToDateInProgress = flow(getGrandManagementReport, GetGur2CumulativeToDateInProgress);

const GrantAllocationReport = (state) => state.grantAllocationReport.data;
export const getGrantAllocationReport = flow(getGrandManagementReport, GrantAllocationReport);

const GrantAllocationReportProgress = (state) => state.grantAllocationReport.requestInProgress;
export const getGrantAllocationReportProgress = flow(getGrandManagementReport, GrantAllocationReportProgress);

const projectDetailsTotal = (state) => state.projectDetailsTotal.data;
export const getProjectDetailsTotal = flow(getGrandManagementReport, projectDetailsTotal);

const gur1Total = (state) => state.gur1Total.data;
export const getGur1TotalTotal = flow(getGrandManagementReport, gur1Total);

const gur2ReportTotal = (state) => state.gur2ReportTotal.data;
export const getGur2ReportTotal = flow(getGrandManagementReport, gur2ReportTotal);

const gur2CumulativeToDateTotal = (state) => state.gur2CumulativeToDateTotal.data;
export const getGur2CumulativeToDateTotal = flow(getGrandManagementReport, gur2CumulativeToDateTotal);
