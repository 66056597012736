import { InputLabel } from "@mui/material";
import { Components, Icons } from "common/components";
import { imageURL } from "common/constants";
import React, { useEffect, useState } from "react";

const FileUploadCommon = ({ isMandatory = false, label = "", setBlobArray, viewOnly = false, imageUrl = [], edit, fileLength = 5, setIdx, name = "fileUpload" }) => {
    const [attachment, setAttachment] = useState([]);
    const [file, setFile] = useState([]);
    const { Grid, Box, Card, Stack, IconButton, Tooltip } = Components;
    const { AddCircleOutline, CloseOutlined, PictureAsPdfTwoTone } = Icons;

    useEffect(() => {
        if (imageUrl.length > 0) {
            setFile(imageUrl.map(item => {
                let newUrl = { id: item.id, idx: item.id, fileType: item.contentType, url: imageURL + item.id };
                return newUrl;
            }));
            setAttachment([]);
        }
    }, [edit, imageUrl]);


    function uploadSingleFile(e) {
        let files = e.target.files;
        let idx = new Date();
        setFile([...file, { idx: idx, fileType: files[0].type, url: URL.createObjectURL(e.target.files[0]) }]);
        const currentFile = files[0];
        let reader = new FileReader();

        reader.addEventListener("load", () => {
            const regex = /data:.*base64,/;
            let base64EncodedData = reader.result.replace(regex, "");
            let regexAll = (/\.[0-9a-z]+$/i)[0];
            let fileExtn = files[0].name.replace(regexAll, "");
            setAttachment([...attachment, {
                idx: idx,
                base64EncodedData: base64EncodedData,
                fileExtn: fileExtn.split(".").pop(),
                contentType: files[0].type,
                fileSizeKb: files[0].size,
                resourceName: files[0].name,
                resourceDescription: "complaints"
            }]);
        });
        reader.readAsDataURL(currentFile);
    }

    useEffect(() => {
        setBlobArray(attachment);
    }, [attachment]);

    const downloadFile = (item) => {
        window.open(`${item}`, "_blank");
    };

    function deleteFile(e) {
        if (e.id) {
            setIdx(e.id);
        }
        setFile(file.filter((item) => item.idx !== e.idx));
        setAttachment(attachment.filter(item => item.idx !== e.idx));
    }

    return (
        <>
            <InputLabel htmlFor={name}>{label} {isMandatory && <span style={{ color: "red", fontSize: "14px" }}> *</span>}</InputLabel>
            <Card sx={{ backgroundColor: "#fff", width: "100%", p: "5px", display: "flex", alignItems: "center", boxShadow: "none", border: "1px solid #CFE7DE", overflow: "scroll" }}>
                <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                    <Stack direction={{ sm: "row" }} spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="center" >
                        {file.length > 0 &&
                            file.map((item) => {
                                return (
                                    <Grid key={item.url} sx={{ width: "100px", border: "1px dashed #CFE7DE", display: "flex", justifyContent: "center", borderRadius: "10px" }}>
                                        <Grid>
                                            {viewOnly ? <IconButton
                                                inverted
                                                onClick={() => deleteFile(item)} sx={{ position: "relative", top: "10px", float: "right", backgroundColor: "#f9e6e2", paddingLeft: "5", paddingRight: "5", borderRadius: "5px" }}
                                                shape="circular" >
                                                <CloseOutlined sx={{ margin: "1px", fontSize: "12px", color: "red" }} />
                                            </IconButton> :
                                                ""}
                                            <Grid item sx={{ cursor: "pointer" }}>
                                                {item.fileType === "application/pdf" ? <PictureAsPdfTwoTone style={{ fontSize: "xxx-large" }} color="primary" onClick={() => downloadFile(item.url)} />
                                                    : <img style={{ objectFit: "fill" }} src={item.url} width={"100%"} height={"60px"} onClick={() => downloadFile(item.url)} />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Stack>

                </Box>

                <Stack direction="row" >
                    <Grid sx={{ p: "32px" }}>
                        {viewOnly && file.length < fileLength && <label htmlFor={name} >
                            <Tooltip title="Upload" >

                                <AddCircleOutline size="large" sx={{
                                    cursor: "pointer"
                                }} />
                            </Tooltip>
                            <input
                                id={name}
                                style={{ display: "none" }}
                                type={"file"}
                                accept=".png,.jpg,.jpeg,.pdf"
                                direction={{ xs: "column", sm: "row" }}
                                disabled={file.length >= fileLength}
                                onChange={uploadSingleFile}

                            />
                        </label>}
                    </Grid>
                </Stack>
            </Card>
        </>);
};

export default FileUploadCommon;
