import { all, takeLatest, call, fork, take, put, select } from "redux-saga/effects";

import { ACTION_TYPES, getULBPopulationById } from "./actions";
import {
    fetchDistricts, editULBPopulation, addULBPopulation, deleteULBPopulation, fetchWards, fetchULBPopulationById,
    fetchULBPopulation, fetchGACComputation, searchULBApi, fetchCensusYearApi, fetchTotalFundApi, computeGACCeilingApi, fetchTotalPopulationApi, updateTotalFundApi, totalFundById, totalFundDropDownApi
} from "./api";
import { handleAPIRequest } from "../../../utils/http";
import { successNotify } from "utils/notificationUtils";
import { navigateTo } from "modules/common/actions";
import { I18n } from "common/components";
import { MODULE_PATH } from "../../paths";
import { GRAND_PATH as PATH } from "../paths";
import { getTableProps } from "modules/common/selectors";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { actions as commonActions } from "modules/common/slice";
import { TABLE_IDS } from "common/constants";

export function* districts() {
    yield call(handleAPIRequest, fetchDistricts);
}

export function* ulb({ payload }) {
    let { districtId, lsgiTypeId } = payload;
    yield call(handleAPIRequest, fetchWards, districtId, lsgiTypeId);
}


export function* submitULBPopulation({ payload }) {
    if (payload.id) {
        yield fork(handleAPIRequest, editULBPopulation, payload);
        const responseAction = yield take([ACTION_TYPES.UPDATE_ULB_POPULATION_SUCCESS, ACTION_TYPES.UPDATE_ULB_POPULATION_FAILURE]);
        if (responseAction.type === ACTION_TYPES.UPDATE_ULB_POPULATION_SUCCESS) {
            yield put(getULBPopulationById(payload.id));
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("ulb_population") }) }));
            yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.ULB_POPULATION.LIST}`));
        }
    } else {

        yield fork(handleAPIRequest, addULBPopulation, payload);
        const responseAction = yield take([ACTION_TYPES.SUBMIT_ULB_POPULATION_SUCCESS, ACTION_TYPES.SUBMIT_ULB_POPULATION_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_ULB_POPULATION_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("ulb_population") }) }));
            yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.ULB_POPULATION.LIST}`));
        }
    }
}

export function* listULBPopulation({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.ULB_POPULATION_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchULBPopulation, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_ULB_POPULATION_SUCCESS, ACTION_TYPES.FETCH_ULB_POPULATION_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.ULB_POPULATION_LIST);
}

export function* listULBPopulationById({ payload }) {
    yield call(handleAPIRequest, fetchULBPopulationById, payload);
}

export function* deleteULB({ payload: id }) {
    yield fork(handleAPIRequest, deleteULBPopulation, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_ULB_POPULATION_SUCCESS, ACTION_TYPES.DELETE_ULB_POPULATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_ULB_POPULATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("ulb_population") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listULBPopulation, { payload: tablePayload });
    }
}

export function* fetchSearchUlb({ payload }) {
    yield call(handleAPIRequest, searchULBApi, payload);
}

export function* computeGACCeiling({ payload: { totalFund = {}, year = {} } }) {
    let newPayload = {
        fundAllocationId: {
            id: totalFund.id,
            fundInInr: totalFund.name
        },
        censusYearId: year,
        remarks: "testing",
        ceilingDate: Date.now(),
        ceilingUptoDate: Date.now()
    };
    yield fork(handleAPIRequest, computeGACCeilingApi, newPayload);
    const responseAction = yield take([ACTION_TYPES.COMPUTE_GAC_CEILING_SUCCESS, ACTION_TYPES.COMPUTE_GAC_CEILING_FAILURE]);
    if (responseAction.type === ACTION_TYPES.COMPUTE_GAC_CEILING_SUCCESS) {
        const { payload: { allocationDetails = [] } = {} } = responseAction;
        yield put(commonActions.setTableData({ data: allocationDetails }));
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("gac_computation") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.GAC_COMPUTATION.LIST}`));
    }
}

export function* listGACComputation({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchGACComputation, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GAC_COMPUTATION_SUCCESS, ACTION_TYPES.FETCH_GAC_COMPUTATION_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* fetchPopulation({ payload }) {
    yield call(handleAPIRequest, fetchTotalPopulationApi, payload);
}

export function* listCensusYear() {
    yield call(handleAPIRequest, fetchCensusYearApi);
}

export function* listTotalFund({ payload }) {
    yield fork(handleAPIRequest, fetchTotalFundApi, payload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_TOTAL_FUND_SUCCESS, ACTION_TYPES.FETCH_TOTAL_FUND_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* totalFundDropdown({ payload }) {
    yield fork(handleAPIRequest, totalFundDropDownApi, payload);
}

export function* submitTotalFund({ payload }) {
    yield fork(handleAPIRequest, updateTotalFundApi, payload);
    const response = yield take([ACTION_TYPES.UPDATE_TOTAL_FUND_SUCCESS, ACTION_TYPES.UPDATE_TOTAL_FUND_FAILURE]);
    if (response.type === ACTION_TYPES.UPDATE_TOTAL_FUND_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("total_fund") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.TOTAL_FUND.LIST}`));
    }
}
export function* getTotalFundById({ payload }) {
    yield call(handleAPIRequest, totalFundById, payload);
}

export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_LIST, districts),
        takeLatest(ACTION_TYPES.FETCH_ULB_DETAILS, ulb),
        takeLatest(ACTION_TYPES.SUBMIT_ULB_POPULATION, submitULBPopulation),
        takeLatest(ACTION_TYPES.UPDATE_ULB_POPULATION, submitULBPopulation),
        takeLatest(ACTION_TYPES.FETCH_ULB_POPULATION, listULBPopulation),
        takeLatest(ACTION_TYPES.DELETE_ULB_POPULATION, deleteULB),
        takeLatest(ACTION_TYPES.FETCH_ULB_POPULATION_BY_ID, listULBPopulationById),
        takeLatest(ACTION_TYPES.COMPUTE_GAC_CEILING, computeGACCeiling),
        takeLatest(ACTION_TYPES.FETCH_GAC_COMPUTATION, listGACComputation),
        takeLatest(ACTION_TYPES.FETCH_ULB_SEARCH, fetchSearchUlb),
        takeLatest(ACTION_TYPES.FETCH_CENSUS_YEAR, listCensusYear),
        takeLatest(ACTION_TYPES.FETCH_TOTAL_FUND, listTotalFund),
        takeLatest(ACTION_TYPES.FETCH_TOTAL_POPULATION, fetchPopulation),
        takeLatest(ACTION_TYPES.UPDATE_TOTAL_FUND, submitTotalFund),
        takeLatest(ACTION_TYPES.FETCH_TOTAL_FUND_BY_ID, getTotalFundById),
        takeLatest(ACTION_TYPES.FETCH_TOTAL_FUND_DROPDOWN, totalFundDropdown)

    ]);
}
