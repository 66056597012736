import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    SAVE_TRAINING_NEED: `${STATE_REDUCER_KEY}/SAVE_TRAINING_NEED`,
    SAVE_TRAINING_REQUEST: `${STATE_REDUCER_KEY}/SAVE_TRAINING_REQUEST`,
    SAVE_TRAINING_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_TRAINING_SUCCESS`,
    SAVE_TRAINING_FAILURE: `${STATE_REDUCER_KEY}/SAVE_TRAINING_FAILURE`,

    LIST_TRAINING_NEED: `${STATE_REDUCER_KEY}/LIST_TRAINING_NEED`,
    LIST_TRAINING_REQUEST: `${STATE_REDUCER_KEY}/LIST_TRAINING_REQUEST`,
    LIST_TRAINING_SUCCESS: `${STATE_REDUCER_KEY}/LIST_TRAINING_SUCCESS`,
    LIST_TRAINING_FAILURE: `${STATE_REDUCER_KEY}/LIST_TRAINING_FAILURE`,

    FETCH_TARGET_GROUP_CATEGORY: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP_CATEGORY`,
    FETCH_TARGET_GROUP_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP_CATEGORY_REQUEST`,
    FETCH_TARGET_GROUP_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP_CATEGORY_SUCCESS`,
    FETCH_TARGET_GROUP_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP_CATEGORY_FAILURE`,

    FETCH_TRAINING_TOPIC: `${STATE_REDUCER_KEY}/FETCH_TRAINING_TOPIC`,
    FETCH_TRAINING_TOPIC_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TRAINING_TOPIC_REQUEST`,
    FETCH_TRAINING_TOPIC_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TRAINING_TOPIC_SUCCESS`,
    FETCH_TRAINING_TOPIC_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TRAINING_TOPIC_FAILURE`,

    FETCH_KNOWLEDGE_BASE: `${STATE_REDUCER_KEY}/FETCH_KNOWLEDGE_BASE`,
    FETCH_KNOWLEDGE_BASE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_KNOWLEDGE_BASE_REQUEST`,
    FETCH_KNOWLEDGE_BASE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_KNOWLEDGE_BASE_SUCCESS`,
    FETCH_KNOWLEDGE_BASE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_KNOWLEDGE_BASE_FAILURE`,


    DELETE_NEED_FORM_BY_ID: `${STATE_REDUCER_KEY}/DELETE_NEED_FORM_BY_ID`,
    DELETE_NEED_FORM_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/DELETE_NEED_FORM_BY_ID_REQUEST`,
    DELETE_NEED_FORM_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_NEED_FORM_BY_ID_SUCCESS`,
    DELETE_NEED_FORM_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/DELETE_NEED_FORM_BY_ID_FAILURE`,

    EDIT_NEED_FORM: `${STATE_REDUCER_KEY}/EDIT_NEED_FORM`,
    EDIT_NEED_FORM_REQUEST: `${STATE_REDUCER_KEY}/EDIT_NEED_FORM_REQUEST`,
    EDIT_NEED_FORM_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_NEED_FORM_SUCCESS`,
    EDIT_NEED_FORM_FAILURE: `${STATE_REDUCER_KEY}/EDIT_NEED_FORM_FAILURE`,


    EDIT_NEED_FORM_CATEGORY: `${STATE_REDUCER_KEY}/EDIT_NEED_FORM_CATEGORY`,
    EDIT_NEED_FORM_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/EDIT_NEED_FORM_CATEGORY_REQUEST`,
    EDIT_NEED_FORM_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_NEED_FORM_CATEGORY_SUCCESS`,
    EDIT_NEED_FORM_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/EDIT_NEED_FORM_CATEGORY_FAILURE`,

    FETCH_DESIGNATION: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION`,
    FETCH_DESIGNATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_REQUEST`,
    FETCH_DESIGNATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_SUCCESS`,
    FETCH_DESIGNATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_FAILURE`,

    EDIT_TNA_MATRIX: `${STATE_REDUCER_KEY}/EDIT_TNA_MATRIX`,
    EDIT_TNA_MATRIX_REQUEST: `${STATE_REDUCER_KEY}/EDIT_TNA_MATRIX_REQUEST`,
    EDIT_TNA_MATRIX_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_TNA_MATRIX_SUCCESS`,
    EDIT_TNA_MATRIX_FAILURE: `${STATE_REDUCER_KEY}/EDIT_TNA_MATRIX_FAILURE`,

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    FETCH_FILE: `${STATE_REDUCER_KEY}/FETCH_FILE`,
    FETCH_FILE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FILE_REQUEST`,
    FETCH_FILE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FILE_SUCCESS`,
    FETCH_FILE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FILE_FAILURE`


};
export const saveTrainingNeedDetails = createAction(ACTION_TYPES.SAVE_TRAINING_NEED);
export const loadTrainingNeed = createAction(ACTION_TYPES.LIST_TRAINING_NEED);
export const getTargetGroupCategory = createAction(ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY);
export const deleteNeedForm = createAction(ACTION_TYPES.DELETE_NEED_FORM_BY_ID);
export const editNeedForm = createAction(ACTION_TYPES.EDIT_NEED_FORM);
export const editNeedFormByCategory = createAction(ACTION_TYPES.EDIT_NEED_FORM_CATEGORY);
export const getTrainingTopic = createAction(ACTION_TYPES.FETCH_TRAINING_TOPIC);
export const fetchKnowledgeBase = createAction(ACTION_TYPES.FETCH_KNOWLEDGE_BASE);
export const fetchDesignation = createAction(ACTION_TYPES.FETCH_DESIGNATION);
export const editTnaMatrix = createAction(ACTION_TYPES.EDIT_TNA_MATRIX);
export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const getFile = createAction(ACTION_TYPES.FETCH_FILE);

