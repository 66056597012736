import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    LIST_FIVE_YEAR_PLAN_PROJECT: `${STATE_REDUCER_KEY}/LIST_FIVE_YEAR_PLAN_PROJECT`,
    LIST_FIVE_YEAR_PLAN_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/LIST_FIVE_YEAR_PLAN_PROJECT_REQUEST`,
    LIST_FIVE_YEAR_PLAN_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_FIVE_YEAR_PLAN_PROJECT_SUCCESS`,
    LIST_FIVE_YEAR_PLAN_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/LIST_FIVE_YEAR_PLAN_PROJECT_FAILURE`,

    DELETE_FIVE_YEAR_PLAN_PROJECT: `${STATE_REDUCER_KEY}/DELETE_FIVE_YEAR_PLAN_PROJECT`,
    DELETE_FIVE_YEAR_PLAN_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FIVE_YEAR_PLAN_PROJECT_REQUEST`,
    DELETE_FIVE_YEAR_PLAN_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FIVE_YEAR_PLAN_PROJECT_SUCCESS`,
    DELETE_FIVE_YEAR_PLAN_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FIVE_YEAR_PLAN_PROJECT_FAILURE`,

    LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID: `${STATE_REDUCER_KEY}/LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID`,
    LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_REQUEST`,
    LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_SUCCESS`,
    LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_FAILURE`,

    FETCH_LSGI_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN`,
    FETCH_LSGI_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_REQUEST`,
    FETCH_LSGI_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_SUCCESS`,
    FETCH_LSGI_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_FAILURE`,

    FETCH_DISTRICT_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN`,
    FETCH_DISTRICT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_REQUEST`,
    FETCH_DISTRICT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_SUCCESS`,
    FETCH_DISTRICT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_FAILURE`,

    SUBMIT_FIVE_YEAR_PLAN: `${STATE_REDUCER_KEY}/SUBMIT_FIVE_YEAR_PLAN`,
    SUBMIT_FIVE_YEAR_PLAN_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_FIVE_YEAR_PLAN_REQUEST`,
    SUBMIT_FIVE_YEAR_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_FIVE_YEAR_PLAN_SUCCESS`,
    SUBMIT_FIVE_YEAR_PLAN_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_FIVE_YEAR_PLAN_FAILURE`,

    UPDATE_FIVE_YEAR_PLAN: `${STATE_REDUCER_KEY}/UPDATE_FIVE_YEAR_PLAN`,
    UPDATE_FIVE_YEAR_PLAN_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_FIVE_YEAR_PLAN_REQUEST`,
    UPDATE_FIVE_YEAR_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_FIVE_YEAR_PLAN_SUCCESS`,
    UPDATE_FIVE_YEAR_PLAN_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_FIVE_YEAR_PLAN_FAILURE`,

    SUBMITTED_TO_MUNICIPAL: `${STATE_REDUCER_KEY}/SUBMITTED_TO_MUNICIPAL`,
    SUBMITTED_TO_MUNICIPAL_REQUEST: `${STATE_REDUCER_KEY}/SUBMITTED_TO_MUNICIPAL_REQUEST`,
    SUBMITTED_TO_MUNICIPAL_SUCCESS: `${STATE_REDUCER_KEY}/SUBMITTED_TO_MUNICIPAL_SUCCESS`,
    SUBMITTED_TO_MUNICIPAL_FAILURE: `${STATE_REDUCER_KEY}/SUBMITTED_TO_MUNICIPAL_FAILURE`,

    APPROVE_SPMU: `${STATE_REDUCER_KEY}/APPROVE_SPMU`,
    APPROVE_SPMU_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_SPMU_REQUEST`,
    APPROVE_SPMU_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_SPMU_SUCCESS`,
    APPROVE_SPMU_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_SPMU_FAILURE`,

    REJECT_SPMU: `${STATE_REDUCER_KEY}/REJECT_SPMU`,
    REJECT_SPMU_REQUEST: `${STATE_REDUCER_KEY}/REJECT_SPMU_REQUEST`,
    REJECT_SPMU_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_SPMU_SUCCESS`,
    REJECT_SPMU_FAILURE: `${STATE_REDUCER_KEY}/REJECT_SPMU_FAILURE`,

    APPROVE_DPMU: `${STATE_REDUCER_KEY}/APPROVE_DPMU`,
    APPROVE_DPMU_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_DPMU_REQUEST`,
    APPROVE_DPMU_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_DPMU_SUCCESS`,
    APPROVE_DPMU_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_DPMU_FAILURE`,

    REJECT_DPMU: `${STATE_REDUCER_KEY}/REJECT_DPMU`,
    REJECT_DPMU_REQUEST: `${STATE_REDUCER_KEY}/REJECT_DPMU_REQUEST`,
    REJECT_DPMU_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_DPMU_SUCCESS`,
    REJECT_DPMU_FAILURE: `${STATE_REDUCER_KEY}/REJECT_DPMU_FAILURE`,

    APPROVE_MUNICIPAL: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL`,
    APPROVE_MUNICIPAL_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL_REQUEST`,
    APPROVE_MUNICIPAL_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL_SUCCESS`,
    APPROVE_MUNICIPAL_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL_FAILURE`,

    REJECT_MUNICIPAL: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL`,
    REJECT_MUNICIPAL_REQUEST: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL_REQUEST`,
    REJECT_MUNICIPAL_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL_SUCCESS`,
    REJECT_MUNICIPAL_FAILURE: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL_FAILURE`,

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    FETCH_SUB_PROJECT_LIST: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST`,
    FETCH_SUB_PROJECT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST_REQUEST`,
    FETCH_SUB_PROJECT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST_SUCCESS`,
    FETCH_SUB_PROJECT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST_FAILURE`,

    FETCH_FIVE_YEAR_LIST: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_LIST`,
    FETCH_FIVE_YEAR_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_LIST_REQUEST`,
    FETCH_FIVE_YEAR_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_LIST_SUCCESS`,
    FETCH_FIVE_YEAR_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_LIST_FAILURE`,

    SUBMIT_PROJECT_LIST: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_LIST`,
    SUBMIT_PROJECT_LIST_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_LIST_REQUEST`,
    SUBMIT_PROJECT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_LIST_SUCCESS`,
    SUBMIT_PROJECT_LIST_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_LIST_FAILURE`,

    FETCH_STATUS_LIST: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST`,
    FETCH_STATUS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST_REQUEST`,
    FETCH_STATUS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST_SUCCESS`,
    FETCH_STATUS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST_FAILURE`,

    FETCH_SWM_PLAN_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_SWM_PLAN_DROPDOWN`,
    FETCH_SWM_PLAN_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SWM_PLAN_DROPDOWN_REQUEST`,
    FETCH_SWM_PLAN_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SWM_PLAN_DROPDOWN_SUCCESS`,
    FETCH_SWM_PLAN_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SWM_PLAN_DROPDOWN_FAILURE`,

    CEILED_AMOUNT: `${STATE_REDUCER_KEY}/CEILED_AMOUNT`,
    CEILED_AMOUNT_REQUEST: `${STATE_REDUCER_KEY}/CEILED_AMOUNT_REQUEST`,
    CEILED_AMOUNT_SUCCESS: `${STATE_REDUCER_KEY}/CEILED_AMOUNT_SUCCESS`,
    CEILED_AMOUNT_FAILURE: `${STATE_REDUCER_KEY}/CEILED_AMOUNT_FAILURE`,

    SUBMIT_FINAL_ATTACHMENT: `${STATE_REDUCER_KEY}/SUBMIT_FINAL_ATTACHMENT`,
    SUBMIT_FINAL_ATTACHMENT_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_FINAL_ATTACHMENT_REQUEST`,
    SUBMIT_FINAL_ATTACHMENT_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_FINAL_ATTACHMENT_SUCCESS`,
    SUBMIT_FINAL_ATTACHMENT_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_FINAL_ATTACHMENT_FAILURE`

};
export const fetchFiveYearPlanList = createAction(ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT);
export const fetchFiveYearPlanListById = createAction(ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID);
export const fetchDistrictDropDown = createAction(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN);
export const fetchLsgiDropdown = createAction(ACTION_TYPES.FETCH_LSGI_DROPDOWN);
export const submitFiveYearPlan = createAction(ACTION_TYPES.SUBMIT_FIVE_YEAR_PLAN);
export const updateFiveYearPlan = createAction(ACTION_TYPES.UPDATE_FIVE_YEAR_PLAN);
export const ulbSubmittedToMc = createAction(ACTION_TYPES.SUBMITTED_TO_MUNICIPAL);
export const approveSpmu = createAction(ACTION_TYPES.APPROVE_SPMU);
export const rejectSpmu = createAction(ACTION_TYPES.REJECT_SPMU);
export const approveDpmu = createAction(ACTION_TYPES.APPROVE_DPMU);
export const rejectDpmu = createAction(ACTION_TYPES.REJECT_DPMU);
export const approveMunicipal = createAction(ACTION_TYPES.APPROVE_MUNICIPAL);
export const rejectMunicipal = createAction(ACTION_TYPES.REJECT_MUNICIPAL);
export const deleteFiveYearplan = createAction(ACTION_TYPES.DELETE_FIVE_YEAR_PLAN_PROJECT);
export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const listFiveYearProject = createAction(ACTION_TYPES.FETCH_FIVE_YEAR_LIST);
export const listSubProjects = createAction(ACTION_TYPES.FETCH_SUB_PROJECT_LIST);
export const submitProjectList = createAction(ACTION_TYPES.SUBMIT_PROJECT_LIST);
export const fetchStatusList = createAction(ACTION_TYPES.FETCH_STATUS_LIST);
export const fetchSwmPlanDropDown = createAction(ACTION_TYPES.FETCH_SWM_PLAN_DROPDOWN);
export const getCeiledAmount = createAction(ACTION_TYPES.CEILED_AMOUNT);
export const submitFinalAttachment = createAction(ACTION_TYPES.SUBMIT_FINAL_ATTACHMENT);
