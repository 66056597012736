import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { successNotify } from "utils/notificationUtils";
import { ACTION_TYPES, getEscalationLevel } from "./actions";
import { fetchComplaintCategory, submitComplaintCategory, editComplaintType, submitComplaintStatus, fetchComplaintTypeById, deleteComplaintType, submitComplaintType, fetchComplaintCategoryById, editComplaintCategory, listComplaintCategory, deleteComplaintCategory, listComplaintStatus, editComplaintStatus, deleteComplaintStatus, fetchComplaintStatusById } from "./api";
import * as api from "./api";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "../../paths";
import { ADMIN_PATH as PATH } from "../paths";
import { I18n } from "common/components";
import { actions as commonActions } from "../../common/slice";
import { getAdditionalFilters, getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { TABLE_IDS } from "common/constants";
import { ESC_PAGINATION } from "./constants";
import _ from "lodash";
import { getTableProps } from "modules/common/selectors";
import { getPayloadContent } from "utils/apiUtils";
import { actions as sliceActions } from "./slice";

//list complaint type
export function* listComplaintTypeForm({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listComplaintType, { ...filters, ...payload });
    const COMPLAINT_TYPE = [ACTION_TYPES.LIST_COMPLAINT_TYPE_FORM_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_TYPE_FORM_FAILURE];
    yield* setCommonTableData(COMPLAINT_TYPE);
}

export function* complaintCategory() {
    yield call(handleAPIRequest, fetchComplaintCategory);
}
//submit complaint type
export function* submitComplaintTypeData({ payload }) {
    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, editComplaintType, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_COMPLAINT_TYPE_SUCCESS, ACTION_TYPES.EDIT_COMPLAINT_TYPE_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_COMPLAINT_TYPE_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("complaint_type") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.COMPLAINT.TYPE}`));
        }
    } else {
        yield fork(handleAPIRequest, submitComplaintType, payload);
        const responseAction = yield take([ACTION_TYPES.SUBMIT_COMPLAINT_TYPE_SUCCESS, ACTION_TYPES.SUBMIT_COMPLAINT_TYPE_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_COMPLAINT_TYPE_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("complaint_type") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.COMPLAINT.TYPE}`));
        }
    }
}
//delete complaint type
export function* deleteComplaintTypeData({ payload: id }) {
    yield fork(handleAPIRequest, deleteComplaintType, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_COMPLAINT_TYPE_SUCCESS, ACTION_TYPES.DELETE_COMPLAINT_TYPE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_COMPLAINT_TYPE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("complaint_type") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listComplaintTypeForm, { payload: tablePayload });
    }
}

//list complaint category
export function* listComplaintCategoryForm({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listComplaintCategory, { ...filters, ...payload });
    const COMPLAINT_CATEGORY = [ACTION_TYPES.LIST_COMPLAINT_CATEGORY_FORM_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_CATEGORY_FORM_FAILURE];
    yield* setCommonTableData(COMPLAINT_CATEGORY);
}

//submit complaint category
export function* submitComplaintCategoryData({ payload }) {
    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, editComplaintCategory, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_COMPLAINT_CATEGORY_SUCCESS, ACTION_TYPES.EDIT_COMPLAINT_CATEGORY_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_COMPLAINT_CATEGORY_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("complaint_category") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.COMPLAINT.CATEGORY}`));
        }
    } else {
        yield fork(handleAPIRequest, submitComplaintCategory, payload);
        const responseAction = yield take([ACTION_TYPES.SUBMIT_COMPLAINT_CATEGORY_SUCCESS, ACTION_TYPES.SUBMIT_COMPLAINT_CATEGORY_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_COMPLAINT_CATEGORY_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("complaint_category") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.COMPLAINT.CATEGORY}`));
        }
    }
}
//delete complaint category
export function* deleteComplaintCategoryData({ payload: id }) {
    yield fork(handleAPIRequest, deleteComplaintCategory, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_COMPLAINT_CATEGORY_SUCCESS, ACTION_TYPES.DELETE_COMPLAINT_CATEGORY_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_COMPLAINT_CATEGORY_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("complaint_category") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listComplaintCategoryForm, { payload: tablePayload });

    }
}

export function* filterComplaint({ payload: { value, key } = {} }) {
    let newPayload = {};
    _.set(newPayload, "key", key);
    _.set(newPayload, "value", value);
    yield call(handleAPIRequest, api.filterApi, { ...newPayload });
}

//list complaint Status
export function* listComplaintStatusForm({ payload }) {
    yield fork(handleAPIRequest, listComplaintStatus, payload);
    const COMPLAINT_STATUS = [ACTION_TYPES.LIST_COMPLAINT_STATUS_FORM_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_STATUS_FORM_FAILURE];
    yield* setCommonTableData(COMPLAINT_STATUS);
}

//submit complaint Status
export function* submitComplaintStatusData({ payload }) {
    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, editComplaintStatus, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_COMPLAINT_STATUS_SUCCESS, ACTION_TYPES.EDIT_COMPLAINT_STATUS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_COMPLAINT_STATUS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("complaint_status") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.COMPLAINT.STATUS}`));
        }
    } else {
        yield fork(handleAPIRequest, submitComplaintStatus, payload);
        const responseAction = yield take([ACTION_TYPES.SUBMIT_COMPLAINT_STATUS_SUCCESS, ACTION_TYPES.SUBMIT_COMPLAINT_STATUS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_COMPLAINT_STATUS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("complaint_status") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.COMPLAINT.STATUS}`));
        }
    }
}
//delete complaint Status
export function* deleteComplaintStatusData({ payload: id }) {
    yield fork(handleAPIRequest, deleteComplaintStatus, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_COMPLAINT_STATUS_SUCCESS, ACTION_TYPES.DELETE_COMPLAINT_STATUS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_COMPLAINT_STATUS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("complaint_status") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listComplaintStatusForm, { payload: tablePayload });
    }
}

export function* fetchComplaintTypeDataById({ payload }) {
    yield call(handleAPIRequest, fetchComplaintTypeById, payload);
}
export function* fetchComplaintCategoryDataById({ payload }) {
    yield call(handleAPIRequest, fetchComplaintCategoryById, payload);
}
export function* fetchComplaintStatusDataById({ payload }) {
    yield call(handleAPIRequest, fetchComplaintStatusById, payload);
}
export function* fetchEscalationUnitFn() {
    yield call(handleAPIRequest, api.fetchEscalationDropDownApi);
}
export function* fetchTargetLevelFn() {
    yield call(handleAPIRequest, api.fetchTargetRoleLevelDropDownApi);
}

export function* fetchOfficersList(action) {
    let additionalFilters = yield* getAdditionalFilters(TABLE_IDS.OFFICERS_LIST);
    yield fork(handleAPIRequest, api.fetchOfficersListApi, { ...action.payload, ...additionalFilters });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_OFFICERS_LIST_SUCCESS, ACTION_TYPES.FETCH_OFFICERS_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.OFFICERS_LIST);
}
export function* submitEscalationFn({ payload }) {
    let getPayload = { complaintTypeId: payload.complaintTypeId?.id, ...ESC_PAGINATION };
    if (!payload.id) {
        yield fork(handleAPIRequest, api.submitEscalationApi, payload);
        const responseAction = yield take([ACTION_TYPES.SUBMIT_ESCALATION_SUCCESS, ACTION_TYPES.SUBMIT_ESCALATION_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_ESCALATION_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("complaint_escalation") }) }));
            yield put(getEscalationLevel(getPayload));
        }
    } else {
        yield fork(handleAPIRequest, api.updateEscalationApi, payload);
        const responseAction = yield take([ACTION_TYPES.UPDATE_ESCALATION_SUCCESS, ACTION_TYPES.UPDATE_ESCALATION_FAILURE]);
        if (responseAction.type === ACTION_TYPES.UPDATE_ESCALATION_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("complaint_escalation") }) }));
            yield put(getEscalationLevel(getPayload));
        }
    }

}
export function* fetchEscalationLevelFn(payload) {
    yield call(handleAPIRequest, api.fetchEscalationLevelApi, payload);
}
export function* deleteEscalationFn({ payload: { id, categoryId } = {} }) {
    yield fork(handleAPIRequest, api.deleteEscalationApi, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_ESCALATION_SUCCESS, ACTION_TYPES.DELETE_ESCALATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_ESCALATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("complaint_escalation") }) }));
        yield put(getEscalationLevel(categoryId));
    }
}
export function* listFilterCategory({ payload: { value, key } = {} }) {
    let newPayload = {};
    _.set(newPayload, `${key}`, value);
    yield fork(handleAPIRequest, api.filterCategoryDropdownApi, { key: key, value: value });
    const response = yield take([ACTION_TYPES.FETCH_FILTER_CATEGORY_SUCCESS, ACTION_TYPES.FETCH_FILTER_CATEGORY_FAILURE]);
    if (response.type === ACTION_TYPES.FETCH_FILTER_CATEGORY_SUCCESS) {
        yield put(sliceActions.setCategoryName({ key, value: getPayloadContent(response) }));
    }
}

export default function* testSaga() {
    yield all([
        //type
        takeLatest(ACTION_TYPES.SUBMIT_COMPLAINT_TYPE, submitComplaintTypeData),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_TYPE_BY_ID, fetchComplaintTypeDataById),
        takeLatest(ACTION_TYPES.DELETE_COMPLAINT_TYPE, deleteComplaintTypeData),
        takeLatest(ACTION_TYPES.LIST_COMPLAINT_TYPE_FORM, listComplaintTypeForm),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY, complaintCategory),

        //category
        takeLatest(ACTION_TYPES.SUBMIT_COMPLAINT_CATEGORY, submitComplaintCategoryData),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_BY_ID, fetchComplaintCategoryDataById),
        takeLatest(ACTION_TYPES.DELETE_COMPLAINT_CATEGORY, deleteComplaintCategoryData),
        takeLatest(ACTION_TYPES.LIST_COMPLAINT_CATEGORY_FORM, listComplaintCategoryForm),
        //status
        takeLatest(ACTION_TYPES.SUBMIT_COMPLAINT_STATUS, submitComplaintStatusData),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_STATUS_BY_ID, fetchComplaintStatusDataById),
        takeLatest(ACTION_TYPES.DELETE_COMPLAINT_STATUS, deleteComplaintStatusData),
        takeLatest(ACTION_TYPES.LIST_COMPLAINT_STATUS_FORM, listComplaintStatusForm),

        takeLatest(ACTION_TYPES.SET_ESCALATION_UNIT_DROPDOWN, fetchEscalationUnitFn),
        takeLatest(ACTION_TYPES.SET_TARGET_LEVEL_DROPDOWN, fetchTargetLevelFn),
        takeLatest(ACTION_TYPES.FETCH_OFFICERS_LIST, fetchOfficersList),
        takeLatest(ACTION_TYPES.SUBMIT_ESCALATION, submitEscalationFn),
        takeLatest(ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID, fetchEscalationLevelFn),
        takeLatest(ACTION_TYPES.DELETE_ESCALATION, deleteEscalationFn),
        takeLatest(ACTION_TYPES.FETCH_FILTER_CATEGORY, listFilterCategory),
        takeLatest(ACTION_TYPES.FILTER_COMPLAINT_TYPE, filterComplaint)

    ]);
}
