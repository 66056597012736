import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const getSWMPlanActivityDetails = (state) => state[STATE_REDUCER_KEY];

const lsgiSelectDropdownOption = (state) => state.lsgiSelectDropdownOption.data;
export const getLsgiSelectDropdownOption = flow(getSWMPlanActivityDetails, lsgiSelectDropdownOption);

const fetchSWMPlanListDetailsById = (state) => state.fetchSWMPlanListDetailsById.data;
export const getFetchSWMPlanListDetailsById = flow(getSWMPlanActivityDetails, fetchSWMPlanListDetailsById);

const districtDropdown = (state) => state.districtDropdown.data;
export const getDistrictDropdown = flow(getSWMPlanActivityDetails, districtDropdown);

const progress = (state) => state.fetchSWMPlanListDetailsById.requestInProgress;
export const getRequestInProgress = flow(getSWMPlanActivityDetails, progress);

const progressModal = (state) => state.previousSwmPlanAttachments.requestInProgress;
export const getRequestInProgressModal = flow(getSWMPlanActivityDetails, progressModal);

const statusList = (state) => state.planStatusList.data;
export const getStatusLists = flow(getSWMPlanActivityDetails, statusList);

const previousSwmPlanAttachmentsById = (state) => state.previousSwmPlanAttachments.data;
export const getPreviousSwmPlanAttachmentsById = flow(getSWMPlanActivityDetails, previousSwmPlanAttachmentsById);
