const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./actions");
import _ from "lodash";

const initialState = {
    primaryCollectionBdwDetails: {
        requestInProgress: false,
        data: {
            district: "",
            lsgi: "",
            ward: "",
            householdToBeCovered: "",
            householdCovered: "",
            householdWasteCollected: "",
            establishmentToBeCovered: "",
            establishmentCovered: "",
            establishmentWasteCollected: "",
            binsToBeCovered: "",
            binsCovered: "",
            binsWasteCollected: "",
            marketsToBeCovered: "",
            marketsCovered: "",
            marketWasteCollected: "",
            roadCovered: "",
            date: ""
        }
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.GET_PRIMARY_COLLECTION_BDW_BY_ID_REQUEST, (state) => {
                state.primaryCollectionBdwDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_PRIMARY_COLLECTION_BDW_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "primaryCollectionBdwDetails.requestInProgress", false);
                _.set(state, "primaryCollectionBdwDetails.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_PRIMARY_COLLECTION_BDW_BY_ID_FAILURE, (state) => {
                state.primaryCollectionBdwDetails.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;

