import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchWasteProcessingPlantApi = (data) => {
    return {
        url: API_URL.WASTE_PROCESSING_PLANT_DETAILS.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST, ACTION_TYPES.FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS, ACTION_TYPES.FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE], data
        }
    };
};

export const wasteProcessingSiteDropDownApi = (data) => {
    return {
        url: API_URL.WASTE_PROCESSING_PLANT_DETAILS.WASTE_PROCESSING,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.WASTE_PROCESSING_SITE_DROPDOWN_REQUEST, ACTION_TYPES.WASTE_PROCESSING_SITE_DROPDOWN_SUCCESS, ACTION_TYPES.WASTE_PROCESSING_SITE_DROPDOWN_FAILURE],
            data
        }
    };
};

export const wasteProcessingPlantDetailsByIdApi = (data) => {
    return {
        url: API_URL.WASTE_PROCESSING_PLANT_DETAILS.BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_REQUEST, ACTION_TYPES.GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_FAILURE]
        }
    };
};

export const postWasteProcessingDetailsApi = (data) => {
    return {
        url: API_URL.WASTE_PROCESSING_PLANT_DETAILS.POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST, ACTION_TYPES.POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS, ACTION_TYPES.POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE], data
        }
    };
};

export const patchWasteProcessingDetailsApi = (data) => {
    return {
        url: API_URL.WASTE_PROCESSING_PLANT_DETAILS.POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST, ACTION_TYPES.PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS, ACTION_TYPES.PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE], data
        }
    };
};

export const deleteWasteProcessingDetailsApi = (data) => {
    return {
        url: API_URL.WASTE_PROCESSING_PLANT_DETAILS.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST, ACTION_TYPES.DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS, ACTION_TYPES.DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE]
        }
    };
};
