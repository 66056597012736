import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import { getPayloadContent } from "utils/apiUtils";
import _ from "lodash";

const initialState = {
  subProjectAddDetails: {
    requestInProgress: false,
    data: {
      districtId: "",
      lsgiId: "",
      projectCode: "",
      projectName: "",
      startDate: null,
      endDate: null,
      trackId: "",
      conceptNoteAvailable: "",
      estimateAmount: "",
      feasibilityStudyDone: "",
      categoryId: "",
      subCategoryId: "",
      subCategoryId2: "",
      typeId: "",
      envCategoryId: "",
      briefDescription: "",
      longDescription: "",
      assignedPhysicalWards: [],
      assignedServiceWards: [],
      attachmentIds: [],
      finalListProjectId: "",
      annualPlanId: "",
      natureOfWork: "",
      annualPlanYear: ""
    }
  },
  natureOfWork: {
    requestInProgress: false,
    data: []
  },
  trackList: {
    requestInProgress: false,
    data: []
  },
  categoryList: {
    requestInProgress: false,
    data: []
  },
  subCategoryList: {
    requestInProgress: false,
    data: []
  },
  subEnvCategoryList: {
    requestInProgress: false,
    data: []
  },
  subCategoryTwoList: {
    requestInProgress: false,
    data: []
  },
  subProjectList: {
    requestInProgress: false,
    data: []
  },
  projectConceptNote: {
    requestInProgress: false,
    data: []
  },
  subProjectMainList: {
    requestInProgress: false,
    data: []
  },
  annualPlanDropDownOptions: {
    requestInProgress: false,
    data: []
  },
  projectListDropDownOptions: {
    requestInProgress: false,
    data: []
  },
  subProjectSave: {
    requestInProgress: false
  }
};

const slice = createSlice({
  initialState,
  name: STATE_REDUCER_KEY,
  reducers: {
    clearAll: () => initialState,
    clear: (state) => {
      state.subProjectAddDetails = initialState.subProjectAddDetails;
    },
    setSubProject: (state, action) => {
      state.subProjectAddDetails.data = action.payload;
    },
    resetSubProject: (state) => {
      state.subProjectAddDetails.data = initialState.subProjectAddDetails.data;
    },
    subCategoryTwoListClear: (state) => {
      state.subCategoryTwoList.data = initialState.subCategoryTwoList.data;
    },
    categoryListClear: (state) => {
      state.categoryList.data = initialState.categoryList.data;
    },
    setSubProjectSave: (state) => {
      state.subProjectSave.requestInProgress = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ACTION_TYPES.FETCH_TRACK_LIST_REQUEST, (state) => {
        state.trackList.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.FETCH_TRACK_LIST_SUCCESS, (state, action) => {
        _.set(state, "trackList.requestInProgress", false);
        _.set(state, "trackList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_TRACK_LIST_FAILURE, (state) => {
        state.trackList.requestInProgress = false;
      })
      .addCase(ACTION_TYPES.FETCH_CATEGORY_TYPES_REQUEST, (state) => {
        state.categoryList.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.FETCH_CATEGORY_TYPES_SUCCESS, (state, action) => {
        _.set(state, "subCategoryTwoList.requestInProgress", false);
        _.set(state, "subCategoryTwoList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_CATEGORY_TYPES_FAILURE, (state) => {
        state.subCategoryTwoList.requestInProgress = false;
      })
      .addCase(ACTION_TYPES.FETCH_ENV_CATEGORY_TYPES_SUCCESS, (state, action) => {
        _.set(state, "subEnvCategoryList.requestInProgress", false);
        _.set(state, "subEnvCategoryList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_SUB_CATEGORY_TYPES_SUCCESS, (state, action) => {
        _.set(state, "subCategoryList.requestInProgress", false);
        _.set(state, "subCategoryList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_SUB_CATEGORY_TWO_TYPES_SUCCESS, (state, action) => {
        _.set(state, "categoryList.requestInProgress", false);
        _.set(state, "categoryList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_PROJECT_TYPES_SUCCESS, (state, action) => {
        _.set(state, "subProjectList.requestInProgress", false);
        _.set(state, "subProjectList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_PROJECT_APPLICABLE_SUCCESS, (state, action) => {
        _.set(state, "projectConceptNote.requestInProgress", false);
        _.set(state, "projectConceptNote.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_SUB_PROJECT_LIST_SUCCESS, (state, action) => {
        _.set(state, "subProjectMainList.requestInProgress", false);
        _.set(state, "subProjectMainList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_SUB_PROJECT_LIST_BY_ID_SUCCESS, (state, { payload }) => {
        _.set(state, "subProjectAddDetails.requestInProgress", false);
        _.set(state, "subProjectAddDetails.data", payload);
      })
      .addCase(ACTION_TYPES.FETCH_SWM_SUB_PROJECT_BY_ID_SUCCESS, (state, { payload }) => {
        _.set(state, "subProjectAddDetails.requestInProgress", false);
        _.set(state, "subProjectAddDetails.data", { ...state.subProjectAddDetails.data, ...payload });
        _.set(state, "subProjectAddDetails.data.annualPlanYear", payload?.annualPlanId?.fromYear !== undefined ? (payload?.annualPlanId?.fromYear + "-" + payload?.annualPlanId?.toYear) : "Not Applicable");

      })
      .addCase(ACTION_TYPES.FETCH_ANNUAL_PLAN_SUCCESS, (state, action) => {
        _.set(state, "annualPlanDropDownOptions.requestInProgress", false);
        _.set(state, "annualPlanDropDownOptions.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_PROJECT_LIST_SUCCESS, (state, action) => {
        _.set(state, "projectListDropDownOptions.requestInProgress", false);
        _.set(state, "projectListDropDownOptions.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_NATURE_OF_WORK_LIST_SUCCESS, (state, action) => {
        _.set(state, "natureOfWork.requestInProgress", false);
        _.set(state, "natureOfWork.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.POST_SUB_PROJECT_SUCCESS, (state) => {
        _.set(state, "subProjectSave.requestInProgress", false);
      });
  }
});

export const { actions, reducer } = slice;
