import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {


    DELETE_COURSE_DETAILS: `${STATE_REDUCER_KEY}/DELETE_COURSE_DETAILS`,
    DELETE_COURSE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_COURSE_DETAILS_REQUEST`,
    DELETE_COURSE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_COURSE_DETAILS_SUCCESS`,
    DELETE_COURSE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_COURSE_DETAILS_FAILURE`,

    SAVE_COURSE_DETAILS: `${STATE_REDUCER_KEY}/SAVE_COURSE_DETAILS`,
    SAVE_COURSE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SAVE_COURSE_DETAILS_REQUEST`,
    SAVE_COURSE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_COURSE_DETAILS_SUCCESS`,
    SAVE_COURSE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SAVE_COURSE_DETAILS_FAILURE`,

    EDIT_COURSE_BY_ID: `${STATE_REDUCER_KEY}/EDIT_COURSE_BY_ID`,
    EDIT_COURSE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/EDIT_COURSE_BY_ID_REQUEST`,
    EDIT_COURSE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_COURSE_BY_ID_SUCCESS`,
    EDIT_COURSE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/EDIT_COURSE_BY_ID_FAILURE`,

    EDIT_COURSE_DETAILS: `${STATE_REDUCER_KEY}/EDIT_COURSE_DETAILS`,
    EDIT_COURSE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/EDIT_COURSE_DETAILS_REQUEST`,
    EDIT_COURSE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_COURSE_DETAILS_SUCCESS`,
    EDIT_COURSE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/EDIT_COURSE_DETAILS_FAILURE`,

    LIST_COURSE_DETAILS: `${STATE_REDUCER_KEY}/LIST_COURSE_DETAILS`,
    LIST_COURSE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/LIST_COURSE_DETAILS_REQUEST`,
    LIST_COURSE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_COURSE_DETAILS_SUCCESS`,
    LIST_COURSE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/LIST_COURSE_DETAILS_FAILURE`,

    SAVE_QUESTION_DETAILS: `${STATE_REDUCER_KEY}/SAVE_QUESTION_DETAILS`,
    SAVE_QUESTION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SAVE_QUESTION_DETAILS_REQUEST`,
    SAVE_QUESTION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_QUESTION_DETAILS_SUCCESS`,
    SAVE_QUESTION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SAVE_QUESTION_DETAILS_FAILURE`,

    FETCH_SESSION_ID: `${STATE_REDUCER_KEY}/FETCH_SESSION_ID`,
    FETCH_SESSION_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SESSION_ID_REQUEST`,
    FETCH_SESSION_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SESSION_ID_SUCCESS`,
    FETCH_SESSION_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SESSION_ID_FAILURE`,

    FETCH_COURSE_ID: `${STATE_REDUCER_KEY}/FETCH_COURSE_ID`,
    FETCH_COURSE_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COURSE_ID_REQUEST`,
    FETCH_COURSE_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COURSE_ID_SUCCESS`,
    FETCH_COURSE_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COURSE_ID_FAILURE`,

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    FETCH_FILE: `${STATE_REDUCER_KEY}/FETCH_FILE`,
    FETCH_FILE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FILE_REQUEST`,
    FETCH_FILE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FILE_SUCCESS`,
    FETCH_FILE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FILE_FAILURE`,

    FETCH_COURSE_LIST: `${STATE_REDUCER_KEY}/FETCH_COURSE_LIST`,
    FETCH_COURSE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COURSE_LIST_REQUEST`,
    FETCH_COURSE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COURSE_LIST_SUCCESS`,
    FETCH_COURSE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COURSE_LIST_FAILURE`


};

export const saveCourseDetails = createAction(ACTION_TYPES.SAVE_COURSE_DETAILS);
export const listCourseDetails = createAction(ACTION_TYPES.LIST_COURSE_DETAILS);
export const deleteCourseDetails = createAction(ACTION_TYPES.DELETE_COURSE_DETAILS);
export const editCourseDetails = createAction(ACTION_TYPES.EDIT_COURSE_DETAILS);
export const editCourseById = createAction(ACTION_TYPES.EDIT_COURSE_BY_ID);
export const saveQuestionDetails = createAction(ACTION_TYPES.SAVE_QUESTION_DETAILS);
export const fetchSessionId = createAction(ACTION_TYPES.FETCH_SESSION_ID);
export const fetchCourseId = createAction(ACTION_TYPES.FETCH_COURSE_ID);
export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const getFile = createAction(ACTION_TYPES.FETCH_FILE);
export const getCourseList = createAction(ACTION_TYPES.FETCH_COURSE_LIST_SUCCESS);


