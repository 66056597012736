import { I18n } from "common/components";
import { TABLE_IDS } from "common/constants";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { getTableProps } from "modules/common/selectors";
import { MODULE_PATH } from "modules/paths";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { toEpoch } from "utils/dateUtils";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";
import { wardDropdownApi } from "../treatmentAtSourceForBDW/api";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { API_URL } from "../apiUrls";

const formatData = (payload) => {
    const { date, ...rest } = payload;
    const data = { date: toEpoch(date), ...rest };
    return data;
};

export function* fetchPrimaryCollectionBdw({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.PRIMARY_COLLECTION_BDW]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchPrimaryCollectionBdwApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PRIMARY_COLLECTION_BDW_SUCCESS, ACTION_TYPES.FETCH_PRIMARY_COLLECTION_BDW_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.PRIMARY_COLLECTION_BDW);
}

export function* submitPrimaryCollectionBdw(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.patchPrimaryCollectionBdwApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_PRIMARY_COLLECTION_BDW_SUCCESS, ACTION_TYPES.PATCH_PRIMARY_COLLECTION_BDW_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_PRIMARY_COLLECTION_BDW_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("primary_collection_bdw") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.PRIMARY_COLLECTION_BDW.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, Api.postPrimaryCollectionBdwApi, formatData(rest));
        const responseAction = yield take([ACTION_TYPES.POST_PRIMARY_COLLECTION_BDW_SUCCESS, ACTION_TYPES.POST_PRIMARY_COLLECTION_BDW_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_PRIMARY_COLLECTION_BDW_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("primary_collection_bdw") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.PRIMARY_COLLECTION_BDW.LIST}`));
        }
    }
}

export function* primaryCollectionBdwById({ payload }) {
    yield fork(handleAPIRequest, Api.primaryCollectionBdwByIdApi, payload);
    const responseAction = yield take([ACTION_TYPES.GET_PRIMARY_COLLECTION_BDW_BY_ID_SUCCESS, ACTION_TYPES.GET_PRIMARY_COLLECTION_BDW_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.GET_PRIMARY_COLLECTION_BDW_BY_ID_SUCCESS) {
        if (responseAction?.payload?.district && responseAction?.payload?.lsgi) {
            yield call(handleAPIRequest, wardDropdownApi, { lsgiId: responseAction?.payload?.lsgi?.id, pageNo: 0, pageSize: 500 });
        }
    }
}

export function* deletePrimaryCollectionBdw({ payload }) {
    yield fork(handleAPIRequest, Api.deletePrimaryCollectionBdwApi, payload);
    const action = yield take([ACTION_TYPES.DELETE_PRIMARY_COLLECTION_BDW_SUCCESS, ACTION_TYPES.DELETE_PRIMARY_COLLECTION_BDW_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_PRIMARY_COLLECTION_BDW_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("deleted_successfully")) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.REGIONAL_DISPOSAL_SITE]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchPrimaryCollectionBdwApi, { pageNo: 0, pageSize: 5, ...filters });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PRIMARY_COLLECTION_BDW_SUCCESS, ACTION_TYPES.FETCH_PRIMARY_COLLECTION_BDW_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.PRIMARY_COLLECTION_BDW);
    }
}

export function* downloadPrimaryCollectionBdw(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { fromDate, toDate } = filters;
    yield put(commonFileDownload({
        url: API_URL.PRIMARY_COLLECTION_BDW.DOWNLOAD,
        data: { type, fromDate, toDate },
        file: { name: fileName, ext: type }
    }));
}

export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_PRIMARY_COLLECTION_BDW, fetchPrimaryCollectionBdw),
        takeLatest(ACTION_TYPES.POST_PRIMARY_COLLECTION_BDW, submitPrimaryCollectionBdw),
        takeLatest(ACTION_TYPES.PATCH_PRIMARY_COLLECTION_BDW, submitPrimaryCollectionBdw),
        takeLatest(ACTION_TYPES.GET_PRIMARY_COLLECTION_BDW_BY_ID, primaryCollectionBdwById),
        takeLatest(ACTION_TYPES.DELETE_PRIMARY_COLLECTION_BDW, deletePrimaryCollectionBdw),
        takeLatest(ACTION_TYPES.DOWNLOAD_PRIMARY_COLLECTION_BDW, downloadPrimaryCollectionBdw)
    ]);
}
