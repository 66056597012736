import React, { useEffect } from "react";
import { Components, FormController, I18n } from "common/components";
import { Form, withFormik } from "formik";
import { createStructuredSelector } from "reselect";
import { connect, useDispatch } from "react-redux";
import "./style.css";
import { addVisitorsForm, listCountry, listProductInterest } from "../actions";
const { Card, CardHeader, Divider, CardContent, CustomCardHeader, Grid, Button } = Components;
import { visitorsFormSchema as validationSchema } from "../validate";
import { getCountryData, getProductInterest, getSuccessData } from "../selector";
import { actions as sliceActions } from "../slice";
import { useNavigate } from "react-router-dom";
import Logo from "./royal.png";
import { Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
function AddDetailsForm(props) {
    let { handleSubmit, success, errors, setFieldValue } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleChange = (e) => {
        setFieldValue("mobile", e);
    };

    useEffect(() => {
        dispatch(listCountry());
        dispatch(listProductInterest());
    }, []);

    useEffect(() => {
        if (success) {
            dispatch(sliceActions.setStatusDetails(false));
            navigate("/Royal-Prince-Holdings-World-Police-Summit-2024/brouchure");
        }
    }, [success]);

    return (
        <Card sx={{ overflow: "scroll", width: "90%", margin: "2.5rem" }} className="cardstyle">
            <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="img-sec"><img src={Logo} alt="" height={"80px"} style={{ margin: "10px" }} />
                <img src="https://d1sr9z1pdl3mb7.cloudfront.net/wp-content/uploads/2023/11/10135739/WPS-Banner-Event-Listing-1024x768.png
        " alt="" height={"100px"} style={{ margin: "10px" }} />
            </Grid>
            <Form onSubmit={handleSubmit}>
                <CardHeader sx={{ borderRadius: "10px 10px 0 0", mb: 2 }} title={<CustomCardHeader sx={{ fontFamily: "Raleway" }} title={I18n("please_fill_the_form")} />} />
                <Divider />
                <CardContent className="card-content" sx={{ ml: 2, mr: 2 }}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6} lg={4}>
                            <FormController control='input' name='name' label={I18n("name")} labelStyle={{ color: "black", fontFamily: "Raleway" }} isMandatory={true} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormController control='input' name='designation' label={I18n("designation")} labelStyle={{ color: "black", fontFamily: "Raleway" }} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormController control="input" label={I18n("company_name")} name="companyName" labelStyle={{ color: "black", fontFamily: "Raleway" }} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormController control="select" label={I18n("country")} name="country" labelStyle={{ color: "black", fontFamily: "Raleway" }} options={props.countryData || []} isMandatory={true} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <label htmlFor="" style={{ color: "black", fontFamily: "Raleway", fontSize: "13px" }}>{I18n("phone_number")}<span style={{ color: "red", fontSize: "14px" }}> *</span> </label>
                            <PhoneInput
                                country={"us"}
                                onChange={handleChange}
                                name="mobile"
                            />
                            <Typography variant="p" sx={{ color: "red", mt: 1, lineHeight: 0, fontSize: "15px" }}>{errors?.mobile}</Typography>                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormController control="input" label={I18n("email")} name="email" labelStyle={{ color: "black", fontFamily: "Raleway" }} isMandatory={true} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormController control="input" label={I18n("company_website")} name="website" labelStyle={{ color: "black", fontFamily: "Raleway" }} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <label htmlFor="" style={{ color: "black", fontFamily: "Raleway", fontSize: "13px" }}>{I18n("address")} </label>
                            <FormController control='textarea' name='address' labelStyle={{ color: "black", fontFamily: "Raleway" }} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <label htmlFor="" style={{ color: "black", fontFamily: "Raleway", fontSize: "13px" }}>{I18n("requirements_or_feed_back")} </label>
                            <FormController control='textarea' name='remark' labelStyle={{ color: "black", fontFamily: "Raleway" }} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormController control="select" label={I18n("product_and_solution_interest")} name="productInterest" labelStyle={{ color: "black", fontFamily: "Raleway" }} options={props.productInterest || []} isMandatory={true} />
                        </Grid>
                        {props?.values?.productInterest?.name === "Others" && <Grid item xs={12} md={6} lg={4}>
                            <FormController control="textarea" label={I18n("details")} name="otherDetails" labelStyle={{ color: "black", fontFamily: "Raleway" }} />
                        </Grid>}
                        <Grid Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <Button variant="contained" type="submit" sx={{
                                backgroundColor: "#78441c", height: "40px", "&:hover": {
                                    backgroundColor: "#21130d"
                                }
                            }} className="btn"> {I18n("submit")} </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Form>
        </Card>
    );
}
const mapStateToProps = createStructuredSelector({
    success: getSuccessData,
    countryData: getCountryData,
    productInterest: getProductInterest
});

const mapDispatchToProps = (dispatch) => ({
    submitValues: (values) => {
        dispatch(addVisitorsForm(values));
    }
});

const AddDetailsForms = withFormik({
    enableReinitialize: true,
    validationSchema,
    mapPropsToValues: () => {
        return {
            name: "",
            designation: "",
            companyName: "",
            mobile: "",
            email: "",
            website: "",
            address: "",
            country: "",
            productInterest: "",
            otherDetails: ""
        };
    },
    handleSubmit: (values, { props }) => {
        props.submitValues(values);
    },
    displayName: "AddDetailsForm"
})(AddDetailsForm);

export default connect(mapStateToProps, mapDispatchToProps)(AddDetailsForms);
