import { Form, withFormik } from "formik";
import LoadingCustomOverlay from "modules/common/components/LoadingOverlay";
import { wardDropdown } from "modules/operationalModule/treatmentAtSourceForBDW/actions";
import { wardDropdownSelector } from "modules/operationalModule/treatmentAtSourceForBDW/selector";
import { USER_ROLE } from "modules/resources";
import { districtDropdown, ulbDropdown } from "modules/subProjects/socialSafeGuard/actions";
import { getDistrictInfo, getUlbInfo } from "modules/subProjects/socialSafeGuard/selectors";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { numericInputKey } from "utils/commonUtils";
import { getUserDetails } from "utils/userUtils";
import { Components, FormController, I18n } from "../../../../common/components";
import { fetchWardDetailsById, patchWardDetails, postWardDetails } from "../actions";
import { requestInProgressSelector, wardDetailsSelector } from "../selector";
import { actions as sliceActions } from "../slice";
import { WardDetailsSchema } from "../validate";

const { Button, Grid, Card, CardContent, CardActions, CardHeader, Typography, Divider } = Components;

export const WardDetailsAdd = (props) => {
    const { handleSubmit, requestInProgress = true, districtDropDown, ulbDropDown, ward } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);
    const { data: { activeRole = {} } = {} } = getUserDetails();
    const disableField = activeRole.id === USER_ROLE.SWM_STAFF ? false : true;

    useEffect(() => {
        if (pathname.includes("/view")) {
            setView(true);
        }
        if (pathname.includes("/edit")) {
            setEdit(true);
        }
        if (id) {
            dispatch(fetchWardDetailsById(id));
        }
        dispatch(districtDropdown());
        return () => {
            dispatch(sliceActions.clearAll());
        };
    }, []);

    const handleChange = (value) => {
        props.setFieldValue("lsgi", "");
        props.setFieldValue("ward", "");
        dispatch(ulbDropdown({districtId: value.id}));
    };

    const handleClick = (value) => {
        props.setFieldValue("ward", "");
        dispatch(wardDropdown({lsgiId: value.id, pageNo: 0, pageSize: 500}));
    };

    return (
        <>
            <Card sx={{ m: 2, overflow: "visible" }} >
                <Form onSubmit={handleSubmit} >
                    <LoadingCustomOverlay active={requestInProgress} >
                    <CardHeader title={I18n("ward_details")} component={Typography} />
                    <Divider />
                    <CardContent sx={{ ml: 2, mr: 2 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={6} lg={6}>
                                <FormController control="select" label={I18n("district")} name="district" options={districtDropDown || []} onChangeFromController={(value) => handleChange(value)} isMandatory={true} disabled={disableField || view || edit}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <FormController control="select" label={I18n("ulb")} name="lsgi" options={ulbDropDown || []} isMandatory={true} disabled={disableField || view || edit} onChangeFromController={(value) => handleClick(value)}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <FormController control="select" label={I18n("name_of_ward")} name="ward" options={ward || []} disabled={disableField || view} isMandatory={true} />
                            </Grid>
                            {id && <Grid item xs={12} md={6} lg={6}>
                                <FormController control="input" label={I18n("ward_no")} name="siteCode" value={props?.wardInfo?.ward?.wardNumber } disabled={true} />
                            </Grid>}
                            <Grid item xs={12} md={6} lg={6}>
                                <FormController control="input" label={I18n("area_of_ward")} name="wardArea" disabled={disableField || view} isMandatory={true} onKeyPress={numericInputKey}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                    <FormController control="input" label={I18n("population_ward")} name="population" disabled={disableField || view} isMandatory={true} onKeyPress={numericInputKey} digitsOnly={true} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <FormController control="input" label={I18n("total_road_length")} name="totalRoadLength" disabled={disableField || view} isMandatory={true} onKeyPress={numericInputKey}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <FormController control="input" label={I18n("no_of_household_and_establishment")} name="noOfHouseHold" disabled={disableField || view} isMandatory={true} onKeyPress={numericInputKey} digitsOnly={true}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <FormController control="input" label={I18n("no_of_hh_where_treating_machinery")} name="houseHoldWithTreatingMachinery" disabled={disableField || view} isMandatory={true} onKeyPress={numericInputKey} digitsOnly={true}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <FormController control="input" label={I18n("capacity_of_machinery")} name="machineryCapacity" disabled={disableField || view} isMandatory={true} onKeyPress={numericInputKey}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Grid sx={{ mb: 2 }} container justifyContent="center">
                            {!view && <Button type="submit" >
                                {id ? I18n("update") : I18n("save")}
                            </Button>}
                        </Grid>
                    </CardActions>
                    </LoadingCustomOverlay>
                </Form>
            </Card>
        </ >
    );
};

const mapStateToProps = createStructuredSelector({
    districtDropDown: getDistrictInfo,
    ulbDropDown: getUlbInfo,
    wardInfo: wardDetailsSelector,
    requestInProgress: requestInProgressSelector,
    ward: wardDropdownSelector
});

const mapDispatchToProps = (dispatch) => ({
    submit: (data, hasValues) => {
        hasValues?dispatch(patchWardDetails(data)): dispatch(postWardDetails(data));
    }
});

const WardDetailsAddForm = withFormik({
    enableReinitialize: true,
    validationSchema: WardDetailsSchema,
    mapPropsToValues: (props) => props.wardInfo,
    handleSubmit: (values, {props: {submit, wardInfo}}) => {
        submit(values, wardInfo?.id ? true : false);
    },
    displayName: "WardDetailsAddForm"
})(WardDetailsAdd);

export default connect(mapStateToProps, mapDispatchToProps)(WardDetailsAddForm);

