import { I18n } from "common/components";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { successNotify } from "utils/notificationUtils";
import { setCommonTableData } from "utils/tableUtils";
import { ACTION_TYPES } from "./actions";
import { actions as commonActions } from "../../common/slice";
import { actions as sliceActons } from "../../common/slice";
import { confirmCompositionApi, deleteCompositionOfTeamApi, fetchCompositionOfTeamApi, fetchCompositionOfTeamByIdApi, saveCompositionOfTeamApi, updateCompositionOfTeamApi } from "./api";
import { listByIdSWMPlanApi } from "../swmPlan/api";
import { actions as sliceActions } from "./slice";


export function* submitCompositionOfTeam({ payload }) {
    yield put(sliceActions.setDetails(payload));
    yield fork(handleAPIRequest, saveCompositionOfTeamApi, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_COMPOSITION_OF_TEAM_SUCCESS, ACTION_TYPES.SUBMIT_COMPOSITION_OF_TEAM_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_COMPOSITION_OF_TEAM_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("composition_of_team") }) }));
        yield put(sliceActions.resetDetails());
    }
}
export function* listCompositionOfTeam({ payload }) {
    let updatedPayload = { pageNo: payload.pageNo, pageSize: payload.pageSize };
    yield fork(handleAPIRequest, fetchCompositionOfTeamApi, updatedPayload, payload.id);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_SUCCESS, ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}
export function* updateCompositionOfTeam({ payload }) {
    yield fork(handleAPIRequest, updateCompositionOfTeamApi, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_COMPOSITION_OF_TEAM_SUCCESS, ACTION_TYPES.UPDATE_COMPOSITION_OF_TEAM_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_COMPOSITION_OF_TEAM_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("composition_of_team") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let updatedPayload = { pageNo: 0, pageSize: 5 };
        yield fork(handleAPIRequest, fetchCompositionOfTeamApi, updatedPayload, Number(payload.swmPlanId));
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_SUCCESS, ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES);
    }
}

export function* listCompositionOfTeamById({ payload: id }) {
    yield call(handleAPIRequest, fetchCompositionOfTeamByIdApi, id);
}

export function* deleteCompositionOfTeam({ payload }) {
    yield fork(handleAPIRequest, deleteCompositionOfTeamApi, payload.id);
    const response = yield take([ACTION_TYPES.DELETE_COMPOSITION_OF_TEAM_SUCCESS, ACTION_TYPES.DELETE_COMPOSITION_OF_TEAM_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_COMPOSITION_OF_TEAM_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("composition_of_team") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let updatedPayload = { pageNo: 0, pageSize: 5 };
        yield fork(handleAPIRequest, fetchCompositionOfTeamApi, updatedPayload, Number(payload.swmPlanId));
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_SUCCESS, ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES);
    }
}
export function* confirmCompositionOfTeam({ payload }) {
    yield fork(handleAPIRequest, confirmCompositionApi, payload);
    const response = yield take([ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS, ACTION_TYPES.CONFIRM_FORM_SUBMIT_FAILURE]);
    if (response.type === ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("composition_of_team") }) }));
        const { swmPlanId } = payload;
        yield call(handleAPIRequest, listByIdSWMPlanApi, swmPlanId);
        yield put(sliceActons.moveTabUp());
    }
}

export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_COMPOSITION_OF_TEAM, submitCompositionOfTeam),
        takeLatest(ACTION_TYPES.UPDATE_COMPOSITION_OF_TEAM, updateCompositionOfTeam),
        takeLatest(ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST, listCompositionOfTeam),
        takeLatest(ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID, listCompositionOfTeamById),
        takeLatest(ACTION_TYPES.DELETE_COMPOSITION_OF_TEAM, deleteCompositionOfTeam),
        takeLatest(ACTION_TYPES.CONFIRM_FORM_SUBMIT, confirmCompositionOfTeam)
    ]);
}
