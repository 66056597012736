import { Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Components, I18n } from "common/components";
const { Grid, Box } = Components;

const useStyles = makeStyles(() => ({
    tableContainer: {
        overflowX: "auto",
        maxWidth: "100%"
    },
    table: {
        minWidth: 600,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            fontSize: "18px"
        }
    }
}));

const UlbAccessToIncentiveDelayReport = () => {

    const classes = useStyles();
    return (
        <Box sx={{ maxHeight: "480px", maxWidth: "100%", overflowY: "auto", overflowX: "auto" }}>
            {/* <LoadingCustomOverlay active={requestInProgress}> */}
            <Grid className={classes.tableContainer} >
                <TableContainer >
                    <Table sx={{ p: 1, minWidth: "300px", textAlign: "left" }} size="small" aria-label="a dense table" className={classes.table}>
                        <TableHead sx={{ fontSize: "18px", fontFamily: "Clash Display" }}>
                            <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                <TableCell sx={{ color: "#FFFF" }} rowSpan={2}>{I18n("sl_no")}</TableCell>
                                <TableCell sx={{ minWidth: "10em", color: "#FFFF" }} rowSpan={2}>{I18n("ulb_name")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF" }} rowSpan={2}>{I18n("present_stage_access_to_incentive_grant")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF" }} colSpan={3}>{I18n("incentive_grant_recieved")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF" }} rowSpan={2}>{I18n("reason_for_delay")}</TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                <TableCell sx={{ color: "#FFFF" }}>40%</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >40%</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >20%</TableCell>
                            </TableRow>
                        </TableHead>

                    </Table>
                </TableContainer>
            </Grid>
            {/* </LoadingCustomOverlay> */}
        </Box>
    );
};

export default UlbAccessToIncentiveDelayReport;
