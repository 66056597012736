
import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    FETCH_DISTRICT_LIST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST`,
    FETCH_DISTRICT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_REQUEST`,
    FETCH_DISTRICT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_SUCCESS`,
    FETCH_DISTRICT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_FAILURE`,

    FETCH_LEGISLATIVE_DATA: `${STATE_REDUCER_KEY}/FETCH_LEGISLATIVE_DATA`,
    FETCH_LEGISLATIVE_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LEGISLATIVE_DATA_REQUEST`,
    FETCH_LEGISLATIVE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LEGISLATIVE_DATA_SUCCESS`,
    FETCH_LEGISLATIVE_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LEGISLATIVE_DATA_FAILURE`,

    FETCH_LEGISLATIVE_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_LEGISLATIVE_DETAILS_BY_ID`,
    FETCH_LEGISLATIVE_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LEGISLATIVE_DETAILS_BY_ID_REQUEST`,
    FETCH_LEGISLATIVE_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LEGISLATIVE_DETAILS_BY_ID_SUCCESS`,
    FETCH_LEGISLATIVE_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LEGISLATIVE_DETAILS_BY_ID_FAILURE`,

    ADD_LEGISLATIVE_DATA: `${STATE_REDUCER_KEY}/ADD_LEGISLATIVE_DATA`,
    ADD_LEGISLATIVE_DATA_REQUEST: `${STATE_REDUCER_KEY}/ADD_LEGISLATIVE_DATA_REQUEST`,
    ADD_LEGISLATIVE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/ADD_LEGISLATIVE_DATA_SUCCESS`,
    ADD_LEGISLATIVE_DATA_FAILURE: `${STATE_REDUCER_KEY}/ADD_LEGISLATIVE_DATA_FAILURE`,

    EDIT_LEGISLATIVE_DATA: `${STATE_REDUCER_KEY}/EDIT_LEGISLATIVE_DATA`,
    EDIT_LEGISLATIVE_DATA_REQUEST: `${STATE_REDUCER_KEY}/EDIT_LEGISLATIVE_DATA_REQUEST`,
    EDIT_LEGISLATIVE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_LEGISLATIVE_DATA_SUCCESS`,
    EDIT_LEGISLATIVE_DATA_FAILURE: `${STATE_REDUCER_KEY}/EDIT_LEGISLATIVE_DATA_FAILURE`,

    DELETE_LEGISLATIVE_DATA: `${STATE_REDUCER_KEY}/DELETE_LEGISLATIVE_DATA`,
    DELETE_LEGISLATIVE_DATA_REQUEST: `${STATE_REDUCER_KEY}/DELETE_LEGISLATIVE_DATA_REQUEST`,
    DELETE_LEGISLATIVE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_LEGISLATIVE_DATA_SUCCESS`,
    DELETE_LEGISLATIVE_DATA_FAILURE: `${STATE_REDUCER_KEY}/DELETE_LEGISLATIVE_DATA_FAILURE`,

    SUBMIT_LEGISLATIVE_DATA: `${STATE_REDUCER_KEY}/SUBMIT_LEGISLATIVE_DATA`,
    SUBMIT_LEGISLATIVE_DATA_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_LEGISLATIVE_DATA_REQUEST`,
    SUBMIT_LEGISLATIVE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_LEGISLATIVE_DATA_SUCCESS`,
    SUBMIT_LEGISLATIVE_DATA_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_LEGISLATIVE_DATA_FAILURE`,

    FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
    FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
    FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
    FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,

    FETCH_CODE_LIST: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST`,
    FETCH_CODE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_REQUEST`,
    FETCH_CODE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_SUCCESS`,
    FETCH_CODE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_FAILURE`
};

export const getDistrictDetails = createAction(ACTION_TYPES.FETCH_DISTRICT_LIST);

export const submitLegislativeDetails = createAction(ACTION_TYPES.SUBMIT_LEGISLATIVE_DATA);

export const getLegislativeValuesById = createAction(ACTION_TYPES.FETCH_LEGISLATIVE_DETAILS_BY_ID);

export const getLegislativeDetails = createAction(ACTION_TYPES.FETCH_LEGISLATIVE_DATA);

export const deleteLegislativeDetails = createAction(ACTION_TYPES.DELETE_LEGISLATIVE_DATA);

export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);
export const getCodeList = createAction(ACTION_TYPES.FETCH_CODE_LIST);
