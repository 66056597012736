import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

  UPDATE_PROCUREMENT: `${STATE_REDUCER_KEY}/UPDATE_PROCUREMENT`,
  UPDATE_PROCUREMENT_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_PROCUREMENT_REQUEST`,
  UPDATE_PROCUREMENT_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_PROCUREMENT_SUCCESS`,
  UPDATE_PROCUREMENT_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_PROCUREMENT_FAILURE`,

  SAVE_PROCUREMENT_ACTIVITIES: `${STATE_REDUCER_KEY}/SAVE_PROCUREMENT_ACTIVITIES`,
  SAVE_PROCUREMENT_ACTIVITIES_REQUEST: `${STATE_REDUCER_KEY}/SAVE_PROCUREMENT_ACTIVITIES_REQUEST`,
  SAVE_PROCUREMENT_ACTIVITIES_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_PROCUREMENT_ACTIVITIES_SUCCESS`,
  SAVE_PROCUREMENT_ACTIVITIES_FAILURE: `${STATE_REDUCER_KEY}/SAVE_PROCUREMENT_ACTIVITIES_FAILURE`,

  SELECT_REVIEW_TYPE: `${STATE_REDUCER_KEY}/SELECT_REVIEW_TYPE`,
  SELECT_REVIEW_TYPE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_REVIEW_TYPE_REQUEST`,
  SELECT_REVIEW_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_REVIEW_TYPE_SUCCESS`,
  SELECT_REVIEW_TYPE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_REVIEW_TYPE_FAILURE`,

  SELECT_MARKET_APPROACH: `${STATE_REDUCER_KEY}/SELECT_MARKET_APPROACH`,
  SELECT_MARKET_APPROACH_REQUEST: `${STATE_REDUCER_KEY}/SELECT_MARKET_APPROACH_REQUEST`,
  SELECT_MARKET_APPROACH_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_MARKET_APPROACH_SUCCESS`,
  SELECT_MARKET_APPROACH_FAILURE: `${STATE_REDUCER_KEY}/SELECT_MARKET_APPROACH_FAILURE`,

  SELECT_PROCESS_STATUS: `${STATE_REDUCER_KEY}/SELECT_PROCESS_STATUS`,
  SELECT_PROCESS_STATUS_REQUEST: `${STATE_REDUCER_KEY}/SELECT_PROCESS_STATUS_REQUEST`,
  SELECT_PROCESS_STATUS_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_PROCESS_STATUS_SUCCESS`,
  SELECT_PROCESS_STATUS_FAILURE: `${STATE_REDUCER_KEY}/SELECT_PROCESS_STATUS_FAILURE`,

  SELECT_PROCUREMENT_METHOD: `${STATE_REDUCER_KEY}/SELECT_PROCUREMENT_METHOD`,
  SELECT_PROCUREMENT_METHOD_REQUEST: `${STATE_REDUCER_KEY}SELECT_PROCUREMENT_METHOD_REQUEST`,
  SELECT_PROCUREMENT_METHOD_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_PROCUREMENT_METHOD_SUCCESS`,
  SELECT_PROCUREMENT_METHOD_FAILURE: `${STATE_REDUCER_KEY}/SELECT_PROCUREMENT_METHOD_FAILURE`,

  SELECT_CATEGORY: `${STATE_REDUCER_KEY}/SELECT_CATEGORY`,
  SELECT_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/SELECT_CATEGORY_REQUEST`,
  SELECT_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_CATEGORY_SUCCESS`,
  SELECT_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/SELECT_CATEGORY_FAILURE`,

  LIST_PROCUREMENT_ACTIVITIES: `${STATE_REDUCER_KEY}/LIST_PROCUREMENT_ACTIVITIES`,
  LIST_PROCUREMENT_ACTIVITIES_REQUEST: `${STATE_REDUCER_KEY}/LIST_PROCUREMENT_ACTIVITIES_REQUEST`,
  LIST_PROCUREMENT_ACTIVITIES_SUCCESS: `${STATE_REDUCER_KEY}/LIST_PROCUREMENT_ACTIVITIES_SUCCESS`,
  LIST_PROCUREMENT_ACTIVITIES_FAILURE: `${STATE_REDUCER_KEY}/LIST_PROCUREMENT_ACTIVITIES_FAILURE`,

  LIST_PROCUREMENT_ACTIVITIES_BY_ID: `${STATE_REDUCER_KEY}/LIST_PROCUREMENT_ACTIVITIES_BY_ID`,
  LIST_PROCUREMENT_ACTIVITIES_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/LIST_PROCUREMENT_ACTIVITIES_BY_ID_REQUEST`,
  LIST_PROCUREMENT_ACTIVITIES_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/LIST_PROCUREMENT_ACTIVITIES_BY_ID_SUCCESS`,
  LIST_PROCUREMENT_ACTIVITIES_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/LIST_PROCUREMENT_ACTIVITIES_BY_ID_FAILURE`,

  FETCH_PROJECT_LISTING: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LISTING`,
  FETCH_PROJECT_LISTING_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LISTING_REQUEST`,
  FETCH_PROJECT_LISTING_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LISTING_SUCCESS`,
  FETCH_PROJECT_LISTING_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LISTING_FAILURE`,

  PROJECT_LISTING_BY_ID: `${STATE_REDUCER_KEY}/PROJECT_LISTING_BY_ID`,
  PROJECT_LISTING_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/PROJECT_LISTING_BY_ID_REQUEST`,
  PROJECT_LISTING_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/PROJECT_LISTING_BY_ID_SUCCESS`,
  PROJECT_LISTING_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/PROJECT_LISTING_BY_ID_FAILURE`,

  PROCUREMENT_ACTIVITIES_LISTING_BY_ID: `${STATE_REDUCER_KEY}/PROCUREMENT_ACTIVITIES_LISTING_BY_ID`,
  PROCUREMENT_ACTIVITIES_LISTING_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/PROCUREMENT_ACTIVITIES_LISTING_BY_ID_REQUEST`,
  PROCUREMENT_ACTIVITIES_LISTING_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/PROCUREMENT_ACTIVITIES_LISTING_BY_ID_SUCCESS`,
  PROCUREMENT_ACTIVITIES_LISTING_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/PROCUREMENT_ACTIVITIES_LISTING_BY_ID_FAILURE`,

  SELECT_VENDOR: `${STATE_REDUCER_KEY}/SELECT_VENDOR`,
  SELECT_VENDOR_REQUEST: `${STATE_REDUCER_KEY}/SELECT_VENDOR_REQUEST`,
  SELECT_VENDOR_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_VENDOR_SUCCESS`,
  SELECT_VENDOR_FAILURE: `${STATE_REDUCER_KEY}/SELECT_VENDOR_FAILURE`,

  UPDATE_IMPORTED_DATA: `${STATE_REDUCER_KEY}/UPDATE_IMPORTED_DATA`,
  UPDATE_IMPORTED_DATA_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_IMPORTED_DATA_REQUEST`,
  UPDATE_IMPORTED_DATA_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_IMPORTED_DATA_SUCCESS`,
  UPDATE_IMPORTED_DATA_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_IMPORTED_DATA_FAILURE`,

  UPDATE_PROCUREMENT_ACTIVITIES: `${STATE_REDUCER_KEY}/UPDATE_PROCUREMENT_ACTIVITIES`,
  UPDATE_PROCUREMENT_ACTIVITIES_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_PROCUREMENT_ACTIVITIES_REQUEST`,
  UPDATE_PROCUREMENT_ACTIVITIES_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_PROCUREMENT_ACTIVITIES_SUCCESS`,
  UPDATE_PROCUREMENT_ACTIVITIES_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_PROCUREMENT_ACTIVITIES_FAILURE`

};

export const procurementActivitiesList = createAction(ACTION_TYPES.LIST_PROCUREMENT_ACTIVITIES);
export const selectCategory = createAction(ACTION_TYPES.SELECT_CATEGORY);
export const selectVendor = createAction(ACTION_TYPES.SELECT_VENDOR);
export const selectProcessStatus = createAction(ACTION_TYPES.SELECT_PROCESS_STATUS);
export const selectProcurementMethod = createAction(ACTION_TYPES.SELECT_PROCUREMENT_METHOD);
export const selectMarketApproach = createAction(ACTION_TYPES.SELECT_MARKET_APPROACH);
export const selectReviewType = createAction(ACTION_TYPES.SELECT_REVIEW_TYPE);
export const saveProcurementActivities = createAction(ACTION_TYPES.SAVE_PROCUREMENT_ACTIVITIES);
export const updateProcurementActivities = createAction(ACTION_TYPES.UPDATE_PROCUREMENT);
export const procurementActivitiesById = createAction(ACTION_TYPES.LIST_PROCUREMENT_ACTIVITIES_BY_ID);
export const projectListing = createAction(ACTION_TYPES.FETCH_PROJECT_LISTING);
export const projectListingById = createAction(ACTION_TYPES.PROJECT_LISTING_BY_ID);
export const procurementActivitiesListById = createAction(ACTION_TYPES.PROCUREMENT_ACTIVITIES_LISTING_BY_ID);
export const updateImportedData = createAction(ACTION_TYPES.UPDATE_IMPORTED_DATA);
export const updateProcurementActivityList = createAction(ACTION_TYPES.UPDATE_PROCUREMENT_ACTIVITIES);
