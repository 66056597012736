import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchWardDetailsApi = (data) => {
    return {
        url: API_URL.WARD_DETAILS.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WARD_DETAILS_LIST_REQUEST, ACTION_TYPES.FETCH_WARD_DETAILS_LIST_SUCCESS, ACTION_TYPES.FETCH_WARD_DETAILS_LIST_FAILURE], data
        }
    };
};

export const postWardDetailsApi = (data) => ({
    url: API_URL.WARD_DETAILS.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_WARD_DETAILS_REQUEST, ACTION_TYPES.POST_WARD_DETAILS_SUCCESS, ACTION_TYPES.POST_WARD_DETAILS_FAILURE],
        data
    }
});

export const patchWardDetailsApi = (data) => ({
    url: API_URL.WARD_DETAILS.SAVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_WARD_DETAILS_REQUEST, ACTION_TYPES.PATCH_WARD_DETAILS_SUCCESS, ACTION_TYPES.PATCH_WARD_DETAILS_FAILURE],
        data
    }
});

export const fetchWardDetailsByIdByIdApi = (id) => {
    return {
        url: API_URL.WARD_DETAILS.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WARD_DETAILS_BY_ID_REQUEST, ACTION_TYPES.FETCH_WARD_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_WARD_DETAILS_BY_ID_FAILURE]
        }
    };
};

export const deleteWardDetailsApi = (id) => ({
    url: API_URL.WARD_DETAILS.DELETE.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
        types: [ACTION_TYPES.DELETE_WARD_DETAILS_REQUEST, ACTION_TYPES.DELETE_WARD_DETAILS_SUCCESS, ACTION_TYPES.DELETE_WARD_DETAILS_FAILURE]
    }
});
