
export const USER_ROLE = {
    CUSTOMER: 1000,
    SPMU: 1001,
    DPMU: 1002,
    IVA: 1003,
    JHI: 1004,
    HI: 1005,
    WARD_MEMBER: 1006,
    ULB_SECRETARY: 1007,
    MUNICIPAL_CHAIRMAN_MAYOR: 1008,
    SWM_STAFF: 1009,
    CALL_CENTRE_AGENT: 1010,
    SUPER_ADMIN: 1011,
    TRAINING_CORDINATOR: 1012,
    TRAINER: 1013,
    TSC: 1015
};

export const RESOURCE_ID = {
    ADMIN: {
        ROUTE: 1,
        ORGANIZATION: {
            ROUTE: 11,
            DISTRICT: 112,
            LEGISLATIVE: 113,
            LOKSABHA: 114,
            ULB: 115,
            WARD: {
                LIST: 116,
                ESCALATION: 1161
            }
        },
        GENERAL_SETUP: {
            ROUTE: 12,
            LOOKUP_CONFIGURATION: {
                ROUTE: 120,
                LOOKUP: {
                    ROUTE: 1200,
                    CATEGORY: {
                        LIST: 121,
                        ACTIONS: {
                            ADD: 1211,
                            EDIT: 1212,
                            DELETE: 1213
                        }
                    },
                    VALUE: {
                        LIST: 122,
                        ACTIONS: {
                            ADD: 1221,
                            EDIT: 1222,
                            DELETE: 1223
                        }
                    }
                }
            }
        },
        ROLE_MANAGEMENT: {
            ROUTE: 13,
            ROLES: {
                LIST: 131,
                ACTIONS: {
                    ADD: 1311,
                    VIEW: 1312,
                    ROLE: 1313,
                    EDIT: 1314,
                    DELETE: 1315
                }
            },
            MENU_MANAGEMENT: {
                LIST: 132,
                ACTIONS: {
                    ADD: 1321,
                    EDIT: 1322,
                    DELETE: 1323,
                    VIEW: 1324
                }
            }
        },
        USER_REGISTER: {
            LIST: 141,
            ACTIONS: {
                ADD: 1411,
                VIEW: 1412,
                ROLE_MAPPING: 1413,
                EDIT: 1414,
                DELETE: 1415
            }
        },
        EXTERNAL_ORGANIZATION: {
            ROUTE: 15,
            OTHER_DEPARTMENT: {
                LIST: 151,
                ACTIONS: {
                    ADD: 1511,
                    EDIT: 1512,
                    DELETE: 1513,
                    VIEW: 1514
                }
            },
            IEA: {
                LIST: 152,
                ACTIONS: {
                    ADD: 1521,
                    EDIT: 1522,
                    DELETE: 1523,
                    VIEW: 1524
                }
            },
            VENDORS: {
                LIST: 153,
                ACTIONS: {
                    ADD: 1531,
                    EDIT: 1532,
                    DELETE: 1534,
                    VIEW: 1535
                }
            },
            SHO_NGO: {
                LIST: 154,
                ACTIONS: {
                    ADD: 1541,
                    EDIT: 1542,
                    DELETE: 1543,
                    VIEW: 1544
                }
            },
            TRAINING_ORGANIZATION: {
                LIST: 155,
                ACTIONS: {
                    ADD: 1551,
                    EDIT: 1552,
                    DELETE: 1553,
                    VIEW: 1554
                }
            }
        },
        MISCELLANEOUS: {
            ROUTE: 16,
            VENDOR_CATEGORY: {
                LIST: 161,
                ACTIONS: {
                    ADD: 1611,
                    EDIT: 1612,
                    DELETE: 1613,
                    VIEW: 1614
                }
            },
            DESIGNATION: {
                LIST: 162,
                ACTIONS: {
                    ADD: 1621,
                    EDIT: 1622,
                    DELETE: 1623,
                    VIEW: 1624
                }
            },
            COMPLAINT_STATUS: {
                LIST: 163,
                ACTIONS: {
                    ADD: 1631,
                    VIEW: 1632,
                    EDIT: 1633,
                    DELETE: 1634
                }
            },
            COMPLAINT_CATEGORY: {
                LIST: 164,
                ACTIONS: {
                    ADD: 1641,
                    EDIT: 1642,
                    DELETE: 1643,
                    VIEW: 1644
                }
            },
            COMPLAINT_TYPE: {
                LIST: 165,
                ACTIONS: {
                    ADD: 1651,
                    EDIT: 1652,
                    DELETE: 1653,
                    VIEW: 1654,
                    ESCALATION: 1655
                }

            }
        }
    },
    GRIEVANCE: {
        ROUTE: 2,
        COMPLAINTS: {
            LIST: 211,
            ACTIONS: {
                ASSIGNED_TO_ME: 2111,
                CREATED_BY_ME: 2112,
                ALL: 2113,
                UNASSIGNED: 2114,
                ADD: 2115
            }
        },
        MY_APPROVAL: {
            LIST: 212,
            ACTIONS: {
                PENDING: 2121,
                APPROVE: 2122,
                REJECT: 2123
            }
        },
        USER_MANUAL: {
            LIST: 213
        },
        VIDEO_TUTORIAL: {
            LIST: 214
        },
        REPORT: {
            ROUTE: 210,
            GRIEVANCE_SUMMARY: {
                ROUTE: 2101,
                ACTIONS: {
                    LSGI: 21011,
                    WARD: 21012,
                    DISTRICT: 21013
                }
            },
            GRIEVANCE_LISTING: {
                LIST: 2102
            },
            COMPLAINT_REPORT: {
                LIST: 2103,
                DOWNLOAD: 2104
            },
            ESCALATION_REPORT: {
                LIST: 2105
            },
            PENDING_REPORT: {
                LIST: 2106
            },
            REOPENED_COMPLAINTS: {
                LIST: 2107
            },
            TOP_GRIEVANCE_CATEGORY: {
                LIST: 2108
            }

        }
    },
    GRANT_MANAGEMENT: {
        ROUTE: 3,
        FINANCIAL_YEAR: {
            LIST: 311,
            ACTIONS: {
                ADD: 3111,
                EDIT: 3112,
                DELETE: 3113,
                VIEW: 3114
            }
        },
        ULB_POPULATION: {
            LIST: 312,
            ACTIONS: {
                ADD: 3121,
                EDIT: 3122,
                VIEW: 3123
            }
        },
        ALLOCATION: {
            ROUTE: 310,
            TOTAL_FUND: {
                LIST: 313,
                ADD: 3131,
                VIEW: 3132
            },
            GAC_COMPUTATION: 321,
            REQUEST: {
                LIST: 331,
                ADD: 3311,
                EDIT: 3312,
                VIEW: 3313
            }
        },
        UTILIZATION: {
            ROUTE: 3100,
            BILLS: {
                LIST: 314,
                ADD: 3141,
                EDIT: 3142,
                DELETE: 3143,
                VIEW: 3144
            },
            FETCH_EXPENSES: 324,
            REQUEST: {
                LIST: 334,
                EDIT: 3341
            }
        },
        RECEIPT: 315,
        REPORTS: 316
    },
    TRAINING: {
        ROUTE: 4,
        TRAINING_NEED: {
            LIST: 411,
            ADD: 4111,
            EDIT: 4112,
            DELETE: 4113,
            VIEW: 4114
        },
        TRAINING_PLAN: {
            LIST: 421,
            ADD: 4211,
            EDIT: 4212,
            DELETE: 4213,
            VIEW: 4214
        },
        TRAINING_VENUE: {
            LIST: 431,
            ADD: 4311,
            EDIT: 4312,
            DELETE: 4313,
            VIEW: 4314
        },
        TRAINING_COURSE: {
            LIST: 441,
            ADD: 4411,
            EDIT: 4412,
            DELETE: 4413,
            VIEW: 4414,
            QUESTION: 4415
        },
        TRAINING_BATCH: {
            LIST: 451,
            ADD: 4511,
            EDIT: 4512,
            DELETE: 4513,
            VIEW: 4514,
            ADD_PARTICIPANTS: 4515,
            VIEW_PARTICIPANTS: 4516
        },
        TRAINING_SCHEDULE: {
            LIST: 461,
            ADD: 4611,
            EDIT: 4612,
            DELETE: 4613,
            VIEW: 4614
        },
        TRAINING_CERTIFICATES: {
            LIST: 471,
            ADD: 4711,
            EDIT: 4712,
            DELETE: 4713,
            VIEW: 4714
        },
        TRAINING_REPORTS: {
            ROUTE: 481,
            TCM_TRAINING_CONDUCTED: {
                LIST: 4811
            },
            TCM_TRAINING_SCHEDULED: {
                LIST: 4812
            },
            TCM_SUMMARY: {
                LIST: 4813
            },
            TCM_PARTICIPANTS: {
                LIST: 4814
            },
            TCM_PARTICIPANTS_ANALYSIS: {
                LIST: 4815
            }

        },
        USER_MANUAL: {
            LIST: 482
        }
    },
    REPORT: {
        LIST: 511,
        DOWNLOAD: 5111
    },
    SUB_PROJECT: {
        ROUTE: 6,
        PLANS: {
            ROUTE: 61,
            SWM_PLAN: {
                ROUTE: 611,
                ACTIONS: {
                    ADD: 6112,
                    DELETE: 6113,
                    ACTIVITIES: 6114,
                    EDIT: 6115,
                    LIST: 6116
                }
            },
            ANNUAL_PLAN: {
                ROUTE: 613,
                ACTIONS: {
                    ADD: 6131,
                    DELETE: 6132,
                    EDIT: 6133,
                    LIST: 6134
                }
            }
        },
        GAP_ANALYSIS: {
            ROUTE: 612,
            ACTIONS: {
                ADD: 6121,
                ACTIVITY: 6122,
                EDIT: 6123,
                LIST: 6124
            }
        },
        PREPARATION: {
            ROUTE: 614,
            SWM_SUB_PROJECTS: {
                LIST: 6141
            },
            DISCUSSION_AND_APPROVAL: {
                LIST: 61411
            },
            DPR_TRACKER: {
                ROUTE: 61412,
                ACTIONS: {
                    EDIT: 614121,
                    DELETE: 614122,
                    LIST: 614123
                }
            }
        },
        SOCIAL_SAFE_GUARD: {
            LIST: 618
        },
        SWM_DATA_REPORT: {
            ROUTE: 6144,
            GAP_ANALYSIS_REPORT: {
                ROUTE: 61444
                //may be need in future
                // POPULATION_VS_LAND_AREA: {
                //     LIST: 614441
                // },
                // POPULATION_VS_HOUSE_HOLD: {
                //     LIST: 614442
                // },
                // LAND_USE: {
                //     LIST: 614443
                // },
                // NBDW_EQUIPMENTS_REPORT: {
                //     LIST: 614444
                // },
                // BDW_EQUIPMENTS_REPORT: {
                //     LIST: 614445
                // },
                // NBDW_GENERATED_VS_CAPACITY_REPORT: {
                //     LIST: 614446
                // },
                // BDW_GENERATED_VS_CAPACITY_REPORT: {
                //     LIST: 614447
                // },
                // NBDW_FACILITY_REPORT: {
                //     LIST: 614448
                // },
                // BDW_FACILITY_REPORT: {
                //     LIST: 614449
                // },
                // CAPITAL_COST_IN_NBDW_REPORT: {
                //     LIST: 614450
                // },
                // CAPITAL_COST_IN_BDW_REPORT: {
                //     LIST: 614451
                // },
                // FORWARD_LINKING_REPORT: {
                //     LIST: 614452
                // },
                // INCOME_FROM_BDW_AND_NBDW: {
                //     LIST: 614453
                // },
                // EXPENDITURE_REPORT: {
                //     LIST: 614454
                // },
                // INCOME_OF_HKS_FROM_NBDW_AND_BDW: {
                //     LIST: 614455
                // }

            },
            SWM_PLAN_REPORT: {
                ROUTE: 61445
            },
            SWM_SUB_PROJECTS_REPORT: {
                ROUTE: 61446
            }
        },
        // MONITORING: {
        //     ROUTE: 6142,
        SUB_PROJECT_MONITORING: {
            DETAILS: 615,
            PROJECT_ACTIVITY: {
                DETAILS: 6150,
                ACTIONS: {
                    ADD: 6151,
                    EDIT: 6152,
                    CREATE_SCHEDULE: 6153,
                    DELETE: 6154
                }
            },
            PAYMENT_SCHEDULE: {
                DETAILS: 616,
                ACTIONS: {
                    ADD: 6161,
                    EDIT: 6162
                }
            },
            TECHNICAL: {
                DETAILS: 617,
                ACTIONS: {
                    EDIT: 6171
                }
            }
        },
        PROCUREMENT_LIST: 62,
        LABOUR_MANAGEMENT: 80,
        LAND_ACQUISITIONS: 63
    },
    OPERATION_MODULE: {
        ROUTE: 7,
        MASTER_INFO: {
            ROUTE: 72,
            PRIMARY_COLLECTION: {
                ROUTE: 701,
                ACTIONS: {
                    ADD: 7011,
                    EDIT: 7012,
                    DELETE: 7013,
                    VIEW: 7014,
                    LIST: 7015
                }
            },
            SECONDARY_COLLECTION: {
                ROUTE: 702,
                ACTIONS: {
                    ADD: 7021,
                    EDIT: 7022,
                    DELETE: 7023,
                    VIEW: 7024,
                    LIST: 7025
                }
            },
            MCF_RRF: {
                ROUTE: 703,
                ACTIONS: {
                    ADD: 7031,
                    EDIT: 7032,
                    DELETE: 7033,
                    VIEW: 7034,
                    LIST: 7035
                }
            },
            WASTE_PROCESSING: {
                ROUTE: 704,
                ACTIONS: {
                    ADD: 7041,
                    EDIT: 7042,
                    DELETE: 7043,
                    VIEW: 7044,
                    LIST: 7045
                }
            },
            DISPOSAL_SITE_CITY_LEVEL: {
                ROUTE: 705,
                ACTIONS: {
                    ADD: 7051,
                    EDIT: 7052,
                    DELETE: 7053,
                    VIEW: 7054,
                    LIST: 7055
                }
            },
            REGIONAL_DISPOSAL_SITE_CITY_LEVEL: {
                ROUTE: 706,
                ACTIONS: {
                    ADD: 7061,
                    EDIT: 7062,
                    DELETE: 7063,
                    VIEW: 7064,
                    LIST: 7065
                }
            },
            SECONDARY_TRANSPORTATION: {
                ROUTE: 712,
                ACTIONS: {
                    ADD: 7121,
                    EDIT: 7122,
                    DELETE: 7123,
                    VIEW: 7124,
                    LIST: 7125
                }
            },
            WARD_DETAILS: {
                ROUTE: 716,
                ACTIONS: {
                    ADD: 7161,
                    EDIT: 7162,
                    DELETE: 7163,
                    VIEW: 7164,
                    LIST: 7165
                }
            }
        },
        OPERATIONAL_DATA: {
            ROUTE: 71,
            WSS_INFO: {
                ROUTE: 707,
                ACTIONS: {
                    ADD: 7071,
                    EDIT: 7072,
                    DELETE: 7073,
                    VIEW: 7074,
                    LIST: 7075
                }
            },
            TREATMENT_AT_SOURCE_FOR_BDW: {
                ROUTE: 708,
                ACTIONS: {
                    ADD: 7081,
                    EDIT: 7082,
                    DELETE: 7083,
                    VIEW: 7084,
                    LIST: 7085
                }
            },
            TRANSFER_STATION: {
                ROUTE: 709,
                ACTIONS: {
                    ADD: 7091,
                    EDIT: 7092,
                    DELETE: 7093,
                    VIEW: 7094,
                    LIST: 7095
                }
            },
            PRIMARY_COLLECTION_BDW: {
                ROUTE: 710,
                ACTIONS: {
                    ADD: 7101,
                    EDIT: 7102,
                    DELETE: 7103,
                    VIEW: 7104,
                    LIST: 7105
                }
            },
            MCF_RRF_DETAILS_INFORMATION: {
                ROUTE: 711,
                ACTIONS: {
                    ADD: 7111,
                    EDIT: 7112,
                    DELETE: 7113,
                    VIEW: 7114,
                    LIST: 7115
                }
            },
            WASTE_PROCESSING_PLANT_DETAILS: {
                ROUTE: 713,
                ACTIONS: {
                    ADD: 7131,
                    EDIT: 7132,
                    DELETE: 7133,
                    VIEW: 7134,
                    LIST: 7135
                }
            },
            CITY_CELL_WASTE_DISPOSAL: {
                ROUTE: 714,
                ACTIONS: {
                    ADD: 7141,
                    EDIT: 7142,
                    DELETE: 7143,
                    VIEW: 7144,
                    LIST: 7145
                }
            },
            WASTE_DISPOSAL_AT_REGIONAL_SITE: {
                ROUTE: 715,
                ACTIONS: {
                    ADD: 7151,
                    EDIT: 7152,
                    DELETE: 7153,
                    VIEW: 7154,
                    LIST: 7155
                }
            }
        }
    },
    TEST: {
        HOME: "TEST_HOME",
        PROFILE: "TEST_PROFILE",
        SETTINGS: "TEST_SETTINGS"
    }
};

export const RESOURCE_PERMISSIONS = {
    [RESOURCE_ID.ADMIN.ROUTE]: [USER_ROLE.SUPER_ADMIN],
    [RESOURCE_ID.TEST.HOME]: [USER_ROLE.CUSTOMER],
    [RESOURCE_ID.TEST.PROFILE]: [USER_ROLE.CUSTOMER],
    [RESOURCE_ID.TEST.SETTINGS]: [USER_ROLE.CUSTOMER],
    [RESOURCE_ID.TRAINING.ROUTE]: [USER_ROLE.SUPER_ADMIN],
    [RESOURCE_ID.GRIEVANCE.MY_APPROVAL.ROUTE]: [USER_ROLE.CUSTOMER]
};
