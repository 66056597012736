import { all, takeLatest, select, fork, call, take, put } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { SocialSafeGuardTableDataApi, getBaselineContentApi, getBaselineDataFormApi, getBaselineDataUlbDetailsApi, getDistrictDropdownApi, getSafeguardComplaintsApi, getSchemeCyclicDetailsApi, getUlbDropdownApi, patchBaselineDataApi, patchSafeguardComplaintsApi, patchSocialSchemeCyclicApi, postBaselineDataApi, postSafeguardComplaintsApi, postSocialSafeguardApi, postSocialSchemeCyclicApi } from "./api";
import { ACTION_TYPES } from "./actions";
import { getTableProps } from "modules/common/selectors";
import { setCommonTableData } from "utils/tableUtils";
import { TABLE_IDS } from "common/constants";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { toEpoch } from "utils/dateUtils";
import { API_URL } from "../apiUrls";

export function* fetchSocialSafeguardTableData({ payload }) {
    let tableProps = yield select(getTableProps);
    let {[TABLE_IDS.BASE_LINE_TABLE]: { filters = {}}={}} = tableProps;
    yield fork(handleAPIRequest, SocialSafeGuardTableDataApi, {...filters, ...payload});
    const COMPARISON_TYPES = ([ACTION_TYPES.FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_SUCCESS, ACTION_TYPES.FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_FAILURE]);
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.BASE_LINE_TABLE);
}

export function* getDistrict({ payload }) {
    yield call(handleAPIRequest, getDistrictDropdownApi, payload);
}

export function* getUlb({ payload }) {
    yield call(handleAPIRequest, getUlbDropdownApi, payload);
}

const formatData = (payload) => {
        const { dateOfCompletion, completed, remarks, attachmentIds, lsgiId} = payload;
        const data = { dateOfCompletion: toEpoch(dateOfCompletion), completed: completed, lsgiId: lsgiId, remarks: remarks, attachmentIds: attachmentIds };
        return data;
};

export function* postBaselineDataForm({ payload }) {
    yield fork(handleAPIRequest, postBaselineDataApi, formatData(payload));
    const responseActions = yield take([ACTION_TYPES.POST_BASE_LINE_DATA_SUCCESS, ACTION_TYPES.POST_BASE_LINE_DATA_FAILURE]);
    if (responseActions.type === ACTION_TYPES.POST_BASE_LINE_DATA_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("added_successfully")) }));
        yield call(handleAPIRequest, getBaselineDataFormApi, {lsgiId: payload.lsgiId.id});
    }
}

export function* patchBaselineDataForm({ payload }) {
    yield fork(handleAPIRequest, patchBaselineDataApi, formatData(payload));
    const responseActions = yield take([ACTION_TYPES.PATCH_BASE_LINE_DATA_SUCCESS, ACTION_TYPES.PATCH_BASE_LINE_DATA_FAILURE]);
    if (responseActions.type === ACTION_TYPES.PATCH_BASE_LINE_DATA_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("updated_successfully")) }));
        yield call(handleAPIRequest, getBaselineDataFormApi, {lsgiId: payload.lsgiId.id});
    }
}

export function* getBaselineForm({ payload }) {
    yield call(handleAPIRequest, getBaselineDataFormApi, payload);
}

export function* socialSafeguardForm({ payload }) {
         yield fork(handleAPIRequest, postSocialSafeguardApi, payload);
        const responseAction = yield take([ACTION_TYPES.POST_SOCIAL_SAFEGUARD_DETAILS_SUCCESS, ACTION_TYPES.POST_SOCIAL_SAFEGUARD_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_SOCIAL_SAFEGUARD_DETAILS_SUCCESS) {
            yield put(successNotify({ title: (I18n("success")), message: (I18n("added_successfully")) }));
            yield put(navigateTo("/sub-projects/social-safeguard"));
        }
}

export function* getBaselineDataUlbInfo({ payload }) {
    yield call(handleAPIRequest, getBaselineDataUlbDetailsApi, payload);
}

export function* getBaselineContent({ payload }) {
    yield fork(handleAPIRequest, getBaselineContentApi, payload);
    const responseAction = yield take([ACTION_TYPES.GET_BASELINE_CONTENTS_DETAILS_SUCCESS, ACTION_TYPES.GET_BASELINE_CONTENTS_DETAILS_FAILURE]);
    if (responseAction.payload.content.length > 0) {
        yield call(handleAPIRequest, getBaselineDataFormApi, payload);
    }
}

export function* getSchemeCyclicData({ payload }) {
    yield call(handleAPIRequest, getSchemeCyclicDetailsApi, payload);
}

export function* socialSchemeCyclicForm({ payload }) {
    const { ulbSchemeCycleActivitiesDto, lsgiId } = payload;
    const newPayload = ulbSchemeCycleActivitiesDto.map((item) => ({
        ...item,
        proposedDate: toEpoch(item?.proposedDate),
        actualDate: toEpoch(item?.actualDate),
        lsgiId: lsgiId
    }));
    yield fork(handleAPIRequest, postSocialSchemeCyclicApi, { ulbSchemeCycleActivitiesDto: newPayload });
    const responseActions = yield take([ACTION_TYPES.POST_SCHEME_CYCLIC_DATA_SUCCESS, ACTION_TYPES.POST_SCHEME_CYCLIC_DATA_FAILURE]);
    if (responseActions.type === ACTION_TYPES.POST_SCHEME_CYCLIC_DATA_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("updated_successfully")) }));
        yield call(handleAPIRequest, getSchemeCyclicDetailsApi, {id: lsgiId.id});
    }

}

export function* patchSocialSchemeCyclicForm({ payload }) {
    const { ulbSchemeCycleActivitiesDto, lsgiId } = payload;
    const newPayload = ulbSchemeCycleActivitiesDto.map((item) => ({
        ...item,
        proposedDate: toEpoch(item?.proposedDate),
        actualDate: toEpoch(item?.actualDate),
        lsgiId: lsgiId
    }));
    yield fork(handleAPIRequest, patchSocialSchemeCyclicApi, { ulbSchemeCycleActivitiesDto: newPayload });
    const responseActions = yield take([ACTION_TYPES.PATCH_SCHEME_CYCLIC_DATA_SUCCESS, ACTION_TYPES.PATCH_SCHEME_CYCLIC_DATA_FAILURE]);
    if (responseActions.type === ACTION_TYPES.PATCH_SCHEME_CYCLIC_DATA_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("updated_successfully")) }));
        yield call(handleAPIRequest, getSchemeCyclicDetailsApi, {id: lsgiId.id});
    }
}

export function* SafeguardComplaints({ payload }) {
    yield call(handleAPIRequest, getSafeguardComplaintsApi, payload);
}

export function* postSafeguardComplaintsForm({ payload }) {
    const { ulbSafeguardComplianceDto, lsgiId } = payload;
    const newPayload = ulbSafeguardComplianceDto.map((item) => ({
        ...item,
        proposedDate: toEpoch(item?.proposedDate),
        actualDate: toEpoch(item?.actualDate),
        lsgiId: lsgiId
    }));
    yield fork(handleAPIRequest, postSafeguardComplaintsApi, { ulbSafeguardComplianceDto: newPayload});
    const responseActions = yield take([ACTION_TYPES.POST_SAFEGUARD_COMPLAINTS_DATA_SUCCESS, ACTION_TYPES.POST_SAFEGUARD_COMPLAINTS_DATA_FAILURE]);
    if (responseActions.type === ACTION_TYPES.POST_SAFEGUARD_COMPLAINTS_DATA_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("updated_successfully")) }));
        yield call(handleAPIRequest, getSafeguardComplaintsApi, {id: lsgiId.id});
    }
}

export function* patchSafeguardComplaintsForm({ payload }) {
    const { ulbSafeguardComplianceDto, lsgiId } = payload;
    const newPayload = ulbSafeguardComplianceDto.map((item) => ({
        ...item,
        proposedDate: toEpoch(item?.proposedDate),
        actualDate: toEpoch(item?.actualDate),
        lsgiId: lsgiId
    }));
    yield fork(handleAPIRequest, patchSafeguardComplaintsApi, { ulbSafeguardComplianceDto: newPayload });
    const responseActions = yield take([ACTION_TYPES.PATCH_SAFEGUARD_COMPLAINTS_DATA_SUCCESS, ACTION_TYPES.PATCH_SAFEGUARD_COMPLAINTS_DATA_FAILURE]);
    if (responseActions.type === ACTION_TYPES.PATCH_SAFEGUARD_COMPLAINTS_DATA_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("updated_successfully")) }));
        yield call(handleAPIRequest, getSafeguardComplaintsApi, {id: lsgiId.id});
    }
}

export function* downloadSocialSafeguard(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.SOCIAL_SAFEGUARD.DOWNLOAD,
        data: { type },
        file: { name: fileName, ext: type }
    }));
}

export default function* socialSafeguardSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_SOCIAL_SAFEGUARD_TABLE_DATA, fetchSocialSafeguardTableData),
        takeLatest(ACTION_TYPES.GET_DISTRICT_DROP_DOWN, getDistrict),
        takeLatest(ACTION_TYPES.GET_ULB_DROP_DOWN, getUlb),
        takeLatest(ACTION_TYPES.POST_BASE_LINE_DATA, postBaselineDataForm),
        takeLatest(ACTION_TYPES.PATCH_BASE_LINE_DATA, patchBaselineDataForm),
        takeLatest(ACTION_TYPES.GET_BASE_LINE_DATA, getBaselineForm),
        takeLatest(ACTION_TYPES.POST_SOCIAL_SAFEGUARD_DETAILS, socialSafeguardForm),
        takeLatest(ACTION_TYPES.GET_BASELINE_DATA_ULB_DETAILS, getBaselineDataUlbInfo),
        takeLatest(ACTION_TYPES.GET_BASELINE_CONTENTS_DETAILS, getBaselineContent),
        takeLatest(ACTION_TYPES.GET_SCHEME_CYCLIC_DETAILS, getSchemeCyclicData),
        takeLatest(ACTION_TYPES.POST_SCHEME_CYCLIC_DATA, socialSchemeCyclicForm),
        takeLatest(ACTION_TYPES.PATCH_SCHEME_CYCLIC_DATA, patchSocialSchemeCyclicForm),
        takeLatest(ACTION_TYPES.GET_SAFEGUARD_COMPLAINTS_DETAILS, SafeguardComplaints),
        takeLatest(ACTION_TYPES.POST_SAFEGUARD_COMPLAINTS_DATA, postSafeguardComplaintsForm),
        takeLatest(ACTION_TYPES.PATCH_SAFEGUARD_COMPLAINTS_DATA, patchSafeguardComplaintsForm),
        takeLatest(ACTION_TYPES.DOWNLOAD_SOCIAL_SAFEGUARD_LIST, downloadSocialSafeguard)
    ]);
}
