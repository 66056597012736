import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const genderDropdownApi = () => {
    return {
        url: API_URL.REGISTER.GENDER_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_GENDER_REQUEST,
                ACTION_TYPES.FETCH_GENDER_SUCCESS,
                ACTION_TYPES.FETCH_GENDER_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};

export const registerPostAPICall = (data) => {
    return {
        url: API_URL.REGISTER.CREATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_REGISTER_DETAILS_REQUEST, ACTION_TYPES.SUBMIT_REGISTER_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_REGISTER_DETAILS_FAILURE],
            data
        }
    };
};
export const registerFetchAPICall = (data) => {
    return {
        url: API_URL.REGISTER.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_REGISTER_DETAILS_REQUEST, ACTION_TYPES.FETCH_REGISTER_DETAILS_SUCCESS, ACTION_TYPES.FETCH_REGISTER_DETAILS_FAILURE],
            data
        }
    };
};
export const registerDeleteCall = (id) => {
    return {
        url: API_URL.REGISTER.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_REGISTER_DETAILS_REQUEST, ACTION_TYPES.DELETE_REGISTER_DETAILS_SUCCESS, ACTION_TYPES.DELETE_REGISTER_DETAILS_FAILURE]
        }
    };
};
export const updateUserProfileApi = (data) => {
    return {
        url: API_URL.REGISTER.EDIT_PROFILE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_USER_PROFILE_REQUEST, ACTION_TYPES.UPDATE_USER_PROFILE_SUCCESS, ACTION_TYPES.UPDATE_USER_PROFILE_FAILURE],
            data
        }
    };
};
export const registerFetchApiCallById = (id) => {
    return {
        url: API_URL.REGISTER.PROFILE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_REGISTER_DETAILS_BY_ID_REQUEST, ACTION_TYPES.FETCH_REGISTER_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_REGISTER_DETAILS_BY_ID_FAILURE],
            data: { id }
        }
    };
};
export const fetchOrganizationApi = () => {
    return {
        url: API_URL.REGISTER.ORGANIZATION_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ORGANIZATION_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_ORGANIZATION_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_ORGANIZATION_DROPDOWN_FAILURE],
            data: { dropDown: true, pageSize: 100 }
        }
    };
};

export const fetchDesignationApi = (payload) => {
    return {
        url: API_URL.REGISTER.DESIGNATION_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN_FAILURE],
            data: { ...payload }
        }
    };
};

export const fetchUserTypeApi = () => {
    return {
        url: API_URL.REGISTER.USER_TYPE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_USER_TYPE_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_USER_TYPE_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_USER_TYPE_DROPDOWN_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const fetchLoginTypeApi = () => {
    return {
        url: API_URL.REGISTER.LOGIN_TYPE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LOGIN_TYPE_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_LOGIN_TYPE_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_LOGIN_TYPE_DROPDOWN_FAILURE],
            data: { dropDown: true }
        }
    };
};
export const signUpApi = (data) => {
    return {
        url: API_URL.REGISTER.SIGN_UP,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SIGN_UP_REQUEST, ACTION_TYPES.SIGN_UP_SUCCESS, ACTION_TYPES.SIGN_UP_FAILURE],
            data
        }
    };
};

export const checkAvailabilityApi = (userName) => {
    return {
        url: API_URL.REGISTER.CHECK_AVAILABILITY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.CHECK_AVAILABILITY_REQUEST, ACTION_TYPES.CHECK_AVAILABILITY_SUCCESS, ACTION_TYPES.CHECK_AVAILABILITY_FAILURE],
            data: { userName }
        }
    };
};
export const searchDropdownApi = (data) => {
    return {
        url: API_URL.REGISTER.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_SEARCH_DATA_REQUEST,
                ACTION_TYPES.FETCH_SEARCH_DATA_SUCCESS,
                ACTION_TYPES.FETCH_SEARCH_DATA_FAILURE
            ],
            data
        }
    };
};

export const firstNameDropdownApi = (data) => {
    return {
        url: API_URL.REGISTER.FIRST_NAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_SEARCH_FIRST_NAMES_REQUEST,
                ACTION_TYPES.FETCH_SEARCH_FIRST_NAMES_SUCCESS,
                ACTION_TYPES.FETCH_SEARCH_FIRST_NAMES_FAILURE
            ],
            data
        }
    };
};
export const uploadProfileImagApi = ({ userId, ...rest }) => {
    return {
        url: API_URL.REGISTER.UPLOAD_PROFILE_IMAGE.replace(":userId", userId).concat("?type=1"),
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPLOAD_PROFILE_IMAGE_REQUEST, ACTION_TYPES.UPLOAD_PROFILE_IMAGE_SUCCESS, ACTION_TYPES.UPLOAD_PROFILE_IMAGE_FAILURE],
            data: rest
        }
    };
};

export const uploadSignatureImagApi = ({ userId, ...rest }) => {
    return {
        url: API_URL.REGISTER.UPLOAD_PROFILE_IMAGE.replace(":userId", userId).concat("?type=2"),
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPLOAD_SIGNATURE_IMAGE_REQUEST, ACTION_TYPES.UPLOAD_SIGNATURE_IMAGE_SUCCESS, ACTION_TYPES.UPLOAD_SIGNATURE_IMAGE_FAILURE],
            data: rest
        }
    };
};

export const postChangePassword = (data) => {
    return {
        url: API_URL.REGISTER.CHANGE_PASSWORD,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_CHANGE_PASSWORD_REQUEST, ACTION_TYPES.POST_CHANGE_PASSWORD_SUCCESS, ACTION_TYPES.POST_CHANGE_PASSWORD_FAILURE], data
        }
    };
};

export const fetchRolesDropdownApi = (data) => {
    return {
        url: API_URL.REGISTER.ROLES_DROP_DOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.ROLES_DROPDOWN_REQUEST, ACTION_TYPES.ROLES_DROPDOWN_SUCCESS, ACTION_TYPES.ROLES_DROPDOWN_FAILURE], data
        }
    };
};
