import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchComplaintCategory = () => {
    return {
        url: API_URL.COMMON.COMPLAINT_CATEGORY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_FAILURE],
            data: { dropdown: true }
        }
    };
};
export const fetchComplaintType = (categoryId) => {
    return {
        url: API_URL.COMMON.COMPLAINT_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_TYPE_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_TYPE_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_TYPE_FAILURE],
            data: { categoryId }
        }
    };
};
export const fetchComplaintMode = (data) => {
    return {
        url: API_URL.COMMON.COMPLAINT_MODE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_MODE_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_MODE_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_MODE_FAILURE],
            data
        }
    };
};
export const listComplaint = (data) => {
    return {
        url: API_URL.COMPLAINT.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_COMPLAINT_FORM_REQUEST, ACTION_TYPES.LIST_COMPLAINT_FORM_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_FORM_FAILURE],
            data
        }
    };
};

export const listComplaintAssigned = (data) => {
    return {
        url: API_URL.COMPLAINT.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_COMPLAINT_ASSIGNED_REQUEST, ACTION_TYPES.LIST_COMPLAINT_ASSIGNED_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_ASSIGNED_FAILURE],
            data
        }
    };
};

export const listComplaintUnassignedApi = (data) => {
    return {
        url: API_URL.COMPLAINT.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_COMPLAINT_UNASSIGNED_REQUEST, ACTION_TYPES.LIST_COMPLAINT_UNASSIGNED_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_UNASSIGNED_FAILURE],
            data
        }
    };
};

export const listComplaintCreated = (data) => {
    return {
        url: API_URL.COMPLAINT.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_COMPLAINT_CREATED_REQUEST, ACTION_TYPES.LIST_COMPLAINT_CREATED_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_CREATED_FAILURE],
            data
        }
    };
};
export const fetchComplaintsById = (id) => {
    return {
        url: API_URL.COMPLAINT.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_ID_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_ID_FAILURE],
            data: { id }
        }
    };
};
export const fetchComplaintsByForm = ({ id, fullData }) => {
    return {
        url: API_URL.COMPLAINT.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_FAILURE],
            data: { id, fullData }
        }
    };
};
export const editComplaint = (data) => {
    return {
        url: API_URL.COMPLAINT.UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_COMPLAINT_BY_ID_REQUEST, ACTION_TYPES.UPDATE_COMPLAINT_BY_ID_SUCCESS, ACTION_TYPES.UPDATE_COMPLAINT_BY_ID_FAILURE],
            data
        }
    };
};

export const addComplaint = (data) => {
    return {
        url: API_URL.COMPLAINT.ADD,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.ADD_COMPLAINT_FORM_REQUEST, ACTION_TYPES.ADD_COMPLAINT_FORM_SUCCESS, ACTION_TYPES.ADD_COMPLAINT_FORM_FAILURE],
            data
        }
    };
};
export const deleteComplaint = (id) => {
    return {
        url: API_URL.COMPLAINT.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_COMPLAINT_FORM_REQUEST, ACTION_TYPES.DELETE_COMPLAINT_FORM_SUCCESS, ACTION_TYPES.DELETE_COMPLAINT_FORM_FAILURE]
        }
    };
};
export const fetchOrganization = (districtId, lsgiTypeId) => {

    return {
        url: API_URL.COMMON.CONCERNED_ORGANIZATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ORGANIZATION_REQUEST, ACTION_TYPES.FETCH_ORGANIZATION_SUCCESS, ACTION_TYPES.FETCH_ORGANIZATION_FAILURE],
            data: { districtId, lsgiTypeId }
        }
    };
};
export const fetchOrganizationType = () => {
    return {
        url: API_URL.COMMON.ORGANIZATION_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ORGANIZATION_REQUEST_TYPE, ACTION_TYPES.FETCH_ORGANIZATION_SUCCESS_TYPE, ACTION_TYPES.FETCH_ORGANIZATION_FAILURE_TYPE]
        }
    };
};
export const fetchDistricts = () => {
    return {
        url: API_URL.DISTRICT.LIST_DISTRICT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISTRICT_LIST_REQUEST, ACTION_TYPES.FETCH_DISTRICT_LIST_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_LIST_FAILURE]
        }
    };
};

export const fetchWards = (data) => {
    return {
        url: API_URL.COMMON.WARD,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WARD_DETAILS_REQUEST, ACTION_TYPES.FETCH_WARD_DETAILS_SUCCESS, ACTION_TYPES.FETCH_WARD_DETAILS_FAILURE],
            data: { ...data, dropDown: true }
        }
    };
};

export const getFileById = (id) => {
    return {
        url: API_URL.COMPLAINT.GET_UPLOADED_FILE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_FILE_REQUEST, ACTION_TYPES.FETCH_FILE_SUCCESS, ACTION_TYPES.FETCH_FILE_FAILURE],
            data: { id }
        }
    };
};
export const deleteFileById = (id, fileId) => {
    let DELETE_API_URL = API_URL.COMPLAINT.DELETE_FILE_BY_ID;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":file_id", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_FILE_REQUEST, ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_REQUEST]
        }
    };
};
export const fetchComplaintHistoryApi = (params) => {
    return {
        url: API_URL.COMPLAINT.LIST_ACTION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_HISTORY_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_HISTORY_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_HISTORY_FAILURE],
            data: { ...params }
        }
    };
};

export const fetchComplaintStatusApi = () => {
    return {
        url: API_URL.COMMON.STATUS_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_STATUS_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_STATUS_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_STATUS_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const updateComplaintActionApi = (data) => {
    return {
        url: API_URL.COMPLAINT.UPDATE_ACTION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_COMPLAINT_ACTION_REQUEST, ACTION_TYPES.UPDATE_COMPLAINT_ACTION_SUCCESS, ACTION_TYPES.UPDATE_COMPLAINT_ACTION_FAILURE],
            data
        }
    };
};
export const filterApi = (data) => {
    return {
        url: API_URL.COMPLAINT.FILTER,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FILTER_COMPLAINT_LIST_REQUEST,
                ACTION_TYPES.FILTER_COMPLAINT_LIST_SUCCESS,
                ACTION_TYPES.FILTER_COMPLAINT_LIST_FAILURE
            ],
            data
        }
    };
};
export const deleteActionFileById = (id, fileId) => {
    let DELETE_API_URL = API_URL.COMPLAINT.DELETE_ACTION_FILE_BY_ID;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":file_id", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_ACTION_FILE_REQUEST, ACTION_TYPES.DELETE_ACTION_FILE_SUCCESS, ACTION_TYPES.DELETE_ACTION_FILE_FAILURE]
        }
    };
};

export const fetchOfficersListApi = (params) => {
    return {
        url: API_URL.COMPLAINT.USER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_OFFICERS_LIST_REQUEST, ACTION_TYPES.FETCH_OFFICERS_LIST_SUCCESS, ACTION_TYPES.FETCH_OFFICERS_LIST_FAILURE],
            data: { ...params }
        }
    };
};

export const addReassignOfficerApi = (params) => {
    return {
        url: API_URL.COMPLAINT.REASSIGN,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.REASSIGN_OFFICER_REQUEST, ACTION_TYPES.REASSIGN_OFFICER_SUCCESS, ACTION_TYPES.REASSIGN_OFFICER_FAILURE],
            data: { ...params }
        }
    };
};

export const addResolveStatusApi = (params) => {
    return {
        url: API_URL.COMPLAINT.RESOLVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.RESOLVE_COMPLAINT_REQUEST, ACTION_TYPES.RESOLVE_COMPLAINT_SUCCESS, ACTION_TYPES.RESOLVE_COMPLAINT_FAILURE],
            data: { ...params }
        }
    };
};

export const addRejectStatusApi = (params) => {
    return {
        url: API_URL.COMPLAINT.REJECT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.REJECT_COMPLAINT_REQUEST, ACTION_TYPES.REJECT_COMPLAINT_SUCCESS, ACTION_TYPES.REJECT_COMPLAINT_FAILURE],
            data: { ...params }
        }
    };
};

export const listMyApproval = (data) => {
    return {
        url: API_URL.COMPLAINT.LIST_MY_APPROVAL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_MY_APPROVAL_REQUEST, ACTION_TYPES.FETCH_MY_APPROVAL_SUCCESS, ACTION_TYPES.FETCH_MY_APPROVAL_FAILURE],
            data
        }
    };
};

export const addRemark = (data) => {
    return {
        url: API_URL.COMPLAINT.ADD_REMARK,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_REMARK_FORM_REQUEST, ACTION_TYPES.SUBMIT_REMARK_FORM_SUCCESS, ACTION_TYPES.SUBMIT_REMARK_FORM_FAILURE],
            data
        }
    };
};
export const fetchComplaintApprovalDetailsApi = (id) => {
    return {
        url: API_URL.COMPLAINT.MY_APPROVAL_DETAILS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_APPROVAL_DETAILS_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_APPROVAL_DETAILS_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_APPROVAL_DETAILS_FAILURE],
            data: { id }
        }
    };
};

export const processMyApprovalApi = (data) => {
    return {
        url: API_URL.COMPLAINT.PROCESS_MY_APPROVAL,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.PROCESS_MY_APPROVAL_REQUEST, ACTION_TYPES.PROCESS_MY_APPROVAL_SUCCESS, ACTION_TYPES.PROCESS_MY_APPROVAL_FAILURE],
            data
        }
    };
};

export const addCloseForm = (data) => {
    return {
        url: API_URL.COMPLAINT.ADD_CLOSE_FORM,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_CLOSE_FORM_REQUEST, ACTION_TYPES.SUBMIT_CLOSE_FORM_SUCCESS, ACTION_TYPES.SUBMIT_CLOSE_FORM_FAILURE],
            data
        }
    };
};

export const listRejectedMyApprovalApi = (data) => {
    return {
        url: API_URL.COMPLAINT.LIST_MY_APPROVAL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_MY_APPROVAL_REJECTED_REQUEST, ACTION_TYPES.FETCH_MY_APPROVAL_REJECTED_SUCCESS, ACTION_TYPES.FETCH_MY_APPROVAL_REJECTED_FAILURE],
            data
        }
    };
};

export const addWithdrawForm = (data) => {
    return {
        url: API_URL.COMPLAINT.ADD_WITHDRAW,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_WITHDRAW_FORM_REQUEST, ACTION_TYPES.SUBMIT_WITHDRAW_FORM_SUCCESS, ACTION_TYPES.SUBMIT_WITHDRAW_FORM_FAILURE],
            data
        }
    };
};
export const listApprovedMyApprovalApi = (data) => {
    return {
        url: API_URL.COMPLAINT.LIST_MY_APPROVAL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_MY_APPROVAL_APPROVED_REQUEST, ACTION_TYPES.FETCH_MY_APPROVAL_APPROVED_SUCCESS, ACTION_TYPES.FETCH_MY_APPROVAL_APPROVED_FAILURE],
            data
        }
    };
};

export const fetchUserApi = (data) => {
    return {
        url: API_URL.REGISTER.PROFILE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_USER_REQUEST, ACTION_TYPES.FETCH_USER_SUCCESS, ACTION_TYPES.FETCH_USER_FAILURE],
            data
        }
    };
};

export const createUserApi = (data) => {
    return {
        url: API_URL.REGISTER.CREATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.CREATE_USER_REQUEST, ACTION_TYPES.CREATE_USER_SUCCESS, ACTION_TYPES.CREATE_USER_FAILURE],
            data
        }
    };
};

export const listComplaints = (data) => {
    return {
        url: API_URL.COMPLAINT.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FILTER_COMPLAINT_LISTS_REQUEST, ACTION_TYPES.FILTER_COMPLAINT_LISTS_SUCCESS, ACTION_TYPES.FILTER_COMPLAINT_LISTS_FAILURE],
            data
        }
    };
};

export const fetchMobileDropdownApi = (data) => {
    return {
        url: API_URL.COMPLAINT.MOBILE_NO,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.MOBILE_NO_DROPDOWN_REQUEST, ACTION_TYPES.MOBILE_NO_DROPDOWN_SUCCESS, ACTION_TYPES.MOBILE_NO_DROPDOWN_FAILURE], data
        }
    };
};
