import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_DISTRICT_LIST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST`,
    FETCH_DISTRICT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_REQUEST`,
    FETCH_DISTRICT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_SUCCESS`,
    FETCH_DISTRICT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_FAILURE`,

    FETCH_IEA_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_IEA_DROPDOWN`,
    FETCH_IEA_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_IEA_DROPDOWN_REQUEST`,
    FETCH_IEA_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_IEA_DROPDOWN_SUCCESS`,
    FETCH_IEA_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_IEA_DROPDOWN_FAILURE`,

    FETCH_ULB_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_ULB_DROPDOWN`,
    FETCH_ULB_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ULB_DROPDOWN_REQUEST`,
    FETCH_ULB_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ULB_DROPDOWN_SUCCESS`,
    FETCH_ULB_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ULB_DROPDOWN_FAILURE`,

    FETCH_ULB_SEARCH: `${STATE_REDUCER_KEY}/FETCH_ULB_SEARCH`,
    FETCH_ULB_SEARCH_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ULB_SEARCH_REQUEST`,
    FETCH_ULB_SEARCH_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ULB_SEARCH_SUCCESS`,
    FETCH_ULB_SEARCH_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ULB_SEARCH_FAILURE`,

    FETCH_GRAND_ALLOCATION_LIST: `${STATE_REDUCER_KEY}/FETCH_GRAND_ALLOCATION_LIST`,
    FETCH_GRAND_ALLOCATION_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GRAND_ALLOCATION_LIST_REQUEST`,
    FETCH_GRAND_ALLOCATION_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GRAND_ALLOCATION_LIST_SUCCESS`,
    FETCH_GRAND_ALLOCATION_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GRAND_ALLOCATION_LIST_FAILURE`,

    FETCH_GRAND_ALLOCATION_DETAILS: `${STATE_REDUCER_KEY}/FETCH_GRAND_ALLOCATION_DETAILS`,
    FETCH_GRAND_ALLOCATION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GRAND_ALLOCATION_DETAILS_REQUEST`,
    FETCH_GRAND_ALLOCATION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GRAND_ALLOCATION_DETAILS_SUCCESS`,
    FETCH_GRAND_ALLOCATION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GRAND_ALLOCATION_DETAILS_FAILURE`,

    FETCH_IVA_LIST: `${STATE_REDUCER_KEY}/FETCH_IVA_LIST`,
    FETCH_IVA_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_IVA_LIST_REQUEST`,
    FETCH_IVA_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_IVA_LIST_SUCCESS`,
    FETCH_IVA_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_IVA_LIST_FAILURE`,

    SUBMIT_IVA_REMARKS: `${STATE_REDUCER_KEY}/SUBMIT_IVA_REMARKS`,
    SUBMIT_IVA_REMARKS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_IVA_REMARKS_REQUEST`,
    SUBMIT_IVA_REMARKS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_IVA_REMARKS_SUCCESS`,
    SUBMIT_IVA_REMARKS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_IVA_REMARKS_FAILURE`,

    FETCH_IVA_BY_ID: `${STATE_REDUCER_KEY}/FETCH_IVA_BY_ID`,
    FETCH_IVA_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_IVA_BY_ID_REQUEST`,
    FETCH_IVA_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_IVA_BY_ID_SUCCESS`,
    FETCH_IVA_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_IVA_BY_ID_FAILURE`,

    FETCH_ELIGIBILITY_CRITERIA: `${STATE_REDUCER_KEY}/FETCH_ELIGIBILITY_CRITERIA`,
    FETCH_ELIGIBILITY_CRITERIA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ELIGIBILITY_CRITERIA_REQUEST`,
    FETCH_ELIGIBILITY_CRITERIA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ELIGIBILITY_CRITERIA_SUCCESS`,
    FETCH_ELIGIBILITY_CRITERIA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ELIGIBILITY_CRITERIA_FAILURE`,

    FETCH_PLAN_VIEW: `${STATE_REDUCER_KEY}/FETCH_PLAN_VIEW`,
    FETCH_PLAN_VIEW_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PLAN_VIEW_REQUEST`,
    FETCH_PLAN_VIEW_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PLAN_VIEW_SUCCESS`,
    FETCH_PLAN_VIEW_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PLAN_VIEW_FAILURE`,

    FETCH_GROUPED_CRITERIA: `${STATE_REDUCER_KEY}/FETCH_GROUPED_CRITERIA`,
    FETCH_GROUPED_CRITERIA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GROUPED_CRITERIA_REQUEST`,
    FETCH_GROUPED_CRITERIA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GROUPED_CRITERIA_SUCCESS`,
    FETCH_GROUPED_CRITERIA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GROUPED_CRITERIA_FAILURE`,

    FETCH_CRITERIA_OPTIONS: `${STATE_REDUCER_KEY}/FETCH_CRITERIA_OPTIONS`,
    FETCH_CRITERIA_OPTIONS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CRITERIA_OPTIONS_REQUEST`,
    FETCH_CRITERIA_OPTIONS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CRITERIA_OPTIONS_SUCCESS`,
    FETCH_CRITERIA_OPTIONS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CRITERIA_OPTIONS_FAILURE`,

    FETCH_CEILING_LIST: `${STATE_REDUCER_KEY}/FETCH_CEILING_LIST`,
    FETCH_CEILING_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CEILING_LIST_REQUEST`,
    FETCH_CEILING_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CEILING_LIST_SUCCESS`,
    FETCH_CEILING_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CEILING_LIST_FAILURE`,

    SUBMIT_DPMU_APPLICATION: `${STATE_REDUCER_KEY}/SUBMIT_DPMU_APPLICATION`,
    SUBMIT_DPMU_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_DPMU_APPLICATION_REQUEST`,
    SUBMIT_DPMU_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_DPMU_APPLICATION_SUCCESS`,
    SUBMIT_DPMU_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_DPMU_APPLICATION_FAILURE`,

    SUBMIT_SPMU_APPLICATION: `${STATE_REDUCER_KEY}/SUBMIT_SPMU_APPLICATION`,
    SUBMIT_SPMU_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_SPMU_APPLICATION_REQUEST`,
    SUBMIT_SPMU_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_SPMU_APPLICATION_SUCCESS`,
    SUBMIT_SPMU_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_SPMU_APPLICATION_FAILURE`,

    SUBMIT_IVA_APPLICATION: `${STATE_REDUCER_KEY}/SUBMIT_IVA_APPLICATION`,
    SUBMIT_IVA_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_IVA_APPLICATION_REQUEST`,
    SUBMIT_IVA_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_IVA_APPLICATION_SUCCESS`,
    SUBMIT_IVA_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_IVA_APPLICATION_FAILURE`,

    APPROVE_SPMU_APPLICATION: `${STATE_REDUCER_KEY}/APPROVE_SPMU_APPLICATION`,
    APPROVE_SPMU_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_SPMU_APPLICATION_REQUEST`,
    APPROVE_SPMU_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_SPMU_APPLICATION_SUCCESS`,
    APPROVE_SPMU_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_SPMU_APPLICATION_FAILURE`,

    REJECT_SPMU_APPLICATION: `${STATE_REDUCER_KEY}/REJECT_SPMU_APPLICATION`,
    REJECT_SPMU_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/REJECT_SPMU_APPLICATION_REQUEST`,
    REJECT_SPMU_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_SPMU_APPLICATION_SUCCESS`,
    REJECT_SPMU_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/REJECT_SPMU_APPLICATION_FAILURE`,

    REJECT_DPMU_APPLICATION: `${STATE_REDUCER_KEY}/REJECT_DPMU_APPLICATION`,
    REJECT_DPMU_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/REJECT_DPMU_APPLICATION_REQUEST`,
    REJECT_DPMU_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_DPMU_APPLICATION_SUCCESS`,
    REJECT_DPMU_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/REJECT_DPMU_APPLICATION_FAILURE`,

    IVA_VERIFY: `${STATE_REDUCER_KEY}/IVA_VERIFY`,
    IVA_VERIFY_REQUEST: `${STATE_REDUCER_KEY}/IVA_VERIFY_REQUEST`,
    IVA_VERIFY_SUCCESS: `${STATE_REDUCER_KEY}/IVA_VERIFY_SUCCESS`,
    IVA_VERIFY_FAILURE: `${STATE_REDUCER_KEY}/IVA_VERIFY_FAILURE`,

    SUBMIT_APPLICATION: `${STATE_REDUCER_KEY}/SUBMIT_APPLICATION`,
    SUBMIT_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_APPLICATION_REQUEST`,
    SUBMIT_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_APPLICATION_SUCCESS`,
    SUBMIT_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_APPLICATION_FAILURE`,

    UPDATE_APPLICATION: `${STATE_REDUCER_KEY}/UPDATE_APPLICATION`,
    UPDATE_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_APPLICATION_REQUEST`,
    UPDATE_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_APPLICATION_SUCCESS`,
    UPDATE_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_APPLICATION_FAILURE`,

    DELETE_ULB_FILE: `${STATE_REDUCER_KEY}/DELETE_ULB_FILE`,
    DELETE_ULB_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_ULB_FILE_REQUEST`,
    DELETE_ULB_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_ULB_FILE_SUCCESS`,
    DELETE_ULB_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_ULB_FILE_FAILURE`,

    DELETE_IVA_FILE: `${STATE_REDUCER_KEY}/DELETE_IVA_FILE`,
    DELETE_IVA_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_IVA_FILE_REQUEST`,
    DELETE_IVA_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_IVA_FILE_SUCCESS`,
    DELETE_IVA_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_IVA_FILE_FAILURE`
};

export const getDistricts = createAction(ACTION_TYPES.FETCH_DISTRICT_LIST);
export const getIEAList = createAction(ACTION_TYPES.FETCH_IEA_DROPDOWN);
export const getULBList = createAction(ACTION_TYPES.FETCH_ULB_DROPDOWN);
export const filterSearchUlb = createAction(ACTION_TYPES.FETCH_ULB_SEARCH);
export const getGrandAllocationList = createAction(ACTION_TYPES.FETCH_GRAND_ALLOCATION_LIST);
export const grandAllocationView = createAction(ACTION_TYPES.FETCH_GRAND_ALLOCATION_DETAILS);
export const fetchIvaList = createAction(ACTION_TYPES.FETCH_IVA_LIST);
export const submitIvaRemarks = createAction(ACTION_TYPES.SUBMIT_IVA_REMARKS);
export const fetchIvaById = createAction(ACTION_TYPES.FETCH_IVA_BY_ID);
export const fetchEligibilityCriteria = createAction(ACTION_TYPES.FETCH_ELIGIBILITY_CRITERIA);
export const fetchPlanView = createAction(ACTION_TYPES.FETCH_PLAN_VIEW);
export const fetchGroupedCriteria = createAction(ACTION_TYPES.FETCH_GROUPED_CRITERIA);
export const fetchCriteriaOptions = createAction(ACTION_TYPES.FETCH_CRITERIA_OPTIONS);
export const fetchCeilingList = createAction(ACTION_TYPES.FETCH_CEILING_LIST);
export const submitDpmuApplication = createAction(ACTION_TYPES.SUBMIT_DPMU_APPLICATION);
export const submitSpmuApplication = createAction(ACTION_TYPES.SUBMIT_SPMU_APPLICATION);
export const submitIvaApplicationFn = createAction(ACTION_TYPES.SUBMIT_IVA_APPLICATION);
export const spmuApproveFn = createAction(ACTION_TYPES.APPROVE_SPMU_APPLICATION);
export const spmuRejectFn = createAction(ACTION_TYPES.REJECT_SPMU_APPLICATION);
export const dpmuRejectFn = createAction(ACTION_TYPES.REJECT_DPMU_APPLICATION);
export const ivaVerifyFn = createAction(ACTION_TYPES.IVA_VERIFY);
export const submitApplication = createAction(ACTION_TYPES.SUBMIT_APPLICATION);
export const updateApplication = createAction(ACTION_TYPES.UPDATE_APPLICATION);
export const deleteUlbFile = createAction(ACTION_TYPES.DELETE_ULB_FILE);
export const deleteIvaFile = createAction(ACTION_TYPES.DELETE_IVA_FILE);
