const { STATE_REDUCER_KEY } = require("./constants");
import { flow } from "lodash";

const socialSafeGuard = (key) => key[STATE_REDUCER_KEY];

const getDistrict = (state) => state.district.data;
export const getDistrictInfo = flow(socialSafeGuard, getDistrict);

const getUlb = (state) => state.ulb.data;
export const getUlbInfo = flow(socialSafeGuard, getUlb);

const getBaselineData = (state) => state.baselineById.data;
export const getBaselineDataInfo = flow(socialSafeGuard, getBaselineData);

const getBaseLineDataUlbDetails = (state) => state.baselineUlbDetails.data;
export const getBaseLineDataUlbDetailsInfo = flow(socialSafeGuard, getBaseLineDataUlbDetails);

const getBaseLineContent = (state) => state.baselineContent.data;
export const getBaseLineContentInfo = flow(socialSafeGuard, getBaseLineContent);

const getSchemeCyclicDetails = (state) => state.schemeCyclicDetails.data;
export const getSchemeCyclicDetailsInfo = flow(socialSafeGuard, getSchemeCyclicDetails);

const progress = (state) => state.schemeCyclicDetails.requestInProgress;
export const getProgress = flow(socialSafeGuard, progress);

const getSafeguardDetails = (state) => state.safeguardDetails.data;
export const getSafeguardDetailsInfo = flow(socialSafeGuard, getSafeguardDetails);

const progressSafeguard = (state) => state.safeguardDetails.requestInProgress;
export const getProgressSafeguard = flow(socialSafeGuard, progressSafeguard);

const progressBaselineById = (state) => state.baselineById.requestInProgress;
export const getProgressBaselineById = flow(socialSafeGuard, progressBaselineById);

