import { I18n } from "common/components";
import EscalationReport from "modules/reports/complaintReports/components/EscalationReport";
import PendingReport from "modules/reports/complaintReports/components/PendingReport";
import ReopenedComplaints from "modules/reports/complaintReports/components/ReopenedComplaints";
import TopGrievanceCategory from "modules/reports/complaintReports/components/TopGrievanceCategory";
import { RESOURCE_ID } from "modules/resources";
import { lazy } from "react";
import { GRIEVANCE_PATH as PATH } from "./paths";

const CreateComplaint = lazy(() => import("./complaint/components/ComplaintForm"));
// const Dashboard = lazy(() => import("../dashboard/charts/components/Dashboard"));
const MyApprovalWrapperList = lazy(() => import("./complaint/components/MyApprovalWrapper"));
const ComplaintsViewForm = lazy(() => import("./complaint/components/ComplaintsViewForm"));
const MyComplaintWrapperList = lazy(() => import("./complaint/components/MyComplaintWrapper"));
const GrievanceListingReport = lazy(() => import("modules/reports/complaintReports/components/GrievanceListingReport"));
const GrievanceSummary = lazy(() => import("modules/reports/complaintReports/components/GrievanceSummary"));
const UlbWiseList = lazy(() => import("modules/reports/complaintReports/components/UlbWiseList"));
const WardWiseList = lazy(() => import("modules/reports/complaintReports/components/WardWiseList"));
const UserManual = lazy(() => import("modules/grievance/complaint/components/UserManual"));
const VideoTutorial = lazy(() => import("./complaint/components/VideoTutorial"));

const routes = [
    // {
    //     title: I18n("complaints_dashboard"),
    //     path: "complaints-dashboard",
    //     children: [],
    //     element: <Dashboard />
    // },
    {
        title: I18n("complaints"),
        path: PATH.COMPLAINT.LIST,
        children: [
            {
                path: "",
                element: <MyComplaintWrapperList />,
                id: RESOURCE_ID.GRIEVANCE.COMPLAINTS.ACTIONS.ASSIGNED_TO_ME
            },
            {
                path: "",
                element: <MyComplaintWrapperList />,
                id: RESOURCE_ID.GRIEVANCE.COMPLAINTS.ACTIONS.ALL
            },
            {
                path: "",
                element: <MyComplaintWrapperList />,
                id: RESOURCE_ID.GRIEVANCE.COMPLAINTS.ACTIONS.CREATED_BY_ME
            },
            {
                path: "",
                element: <MyComplaintWrapperList />,
                id: RESOURCE_ID.GRIEVANCE.COMPLAINTS.ACTIONS.UNASSIGNED
            },
            {
                path: ":id/edit",
                element: <CreateComplaint />
            },
            {
                path: "create",
                element: <CreateComplaint />
            },
            {
                path: ":id/view",
                element: <ComplaintsViewForm />
            }
        ]
    },
    {
        path: PATH.COMPLAINT.DETAILS,
        element: <CreateComplaint />
    },
    {
        path: `${PATH.COMPLAINT.DETAILS}/:id`,
        element: <CreateComplaint />
    },
    {
        title: I18n("my_approvals"),
        path: PATH.COMPLAINT.LIST_MY_APPROVAL,
        // id: RESOURCE_ID.GRIEVANCE.MY_APPROVAL.LIST,
        children: [
            {
                path: "",
                element: <MyApprovalWrapperList />,
                id: RESOURCE_ID.GRIEVANCE.MY_APPROVAL.ACTIONS.APPROVE

            },
            {
                path: "",
                element: <MyApprovalWrapperList />,
                id: RESOURCE_ID.GRIEVANCE.MY_APPROVAL.ACTIONS.PENDING

            },
            {
                path: "",
                element: <MyApprovalWrapperList />,
                id: RESOURCE_ID.GRIEVANCE.MY_APPROVAL.ACTIONS.REJECT

            },
            {
                path: ":id/view",
                element: <ComplaintsViewForm />
            }
        ]
    },
    {
        title: I18n("user_manual"),
        path: PATH.COMPLAINT.USER_MANUAL,
        children: [
            {
                path: "",
                element: <UserManual />,
                id: RESOURCE_ID.GRIEVANCE.USER_MANUAL.LIST
            }
        ]
    },
    {
        title: I18n("video_tutorial"),
        path: PATH.COMPLAINT.VIDEO_TUTORIAL,
        children: [
            {
                path: "",
                element: <VideoTutorial />,
                id: RESOURCE_ID.GRIEVANCE.VIDEO_TUTORIAL.LIST
            }
        ]
    },
    {
        title: I18n("reports"),
        id: RESOURCE_ID.GRIEVANCE.REPORT.ROUTE,
        children: [{
            title: I18n("grievance_summary"),
            id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_SUMMARY.ROUTE,
            children: [
                {
                    title: I18n("district_wise"),
                    path: PATH.COMPLAINT.GRIEVANCE_SUMMARY,
                    id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_SUMMARY.ACTIONS.DISTRICT,
                    children: [
                        {
                            path: "",
                            element: <GrievanceSummary />,
                            id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_SUMMARY.ACTIONS.DISTRICT
                        },
                        {
                            path: ":id/ulb",
                            element: <UlbWiseList />,
                            id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_SUMMARY.ACTIONS.DISTRICT
                        },
                        {
                            path: ":id/ulb/:id/ward",
                            element: <WardWiseList />,
                            id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_SUMMARY.ACTIONS.DISTRICT
                        }
                    ]
                },
                {
                    title: I18n("ulb_wise"),
                    path: PATH.COMPLAINT.GRIEVANCE_SUMMARY_ULB,
                    id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_SUMMARY.ACTIONS.LSGI,
                    children: [
                        {
                            path: "",
                            element: <UlbWiseList />,
                            id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_SUMMARY.ACTIONS.LSGI
                        },
                        {
                            path: ":id/ward",
                            element: <WardWiseList />,
                            id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_SUMMARY.ACTIONS.LSGI
                        }
                    ]
                },
                {
                    title: I18n("ward_wise"),
                    path: PATH.COMPLAINT.GRIEVANCE_SUMMARY_WARD,
                    id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_SUMMARY.ACTIONS.WARD,
                    children: [
                        {
                            path: "",
                            element: <WardWiseList />,
                            id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_SUMMARY.ACTIONS.WARD
                        }
                    ]
                }
            ]
        },
        {
            title: I18n("grievance_listing"),
            path: PATH.COMPLAINT.GRIEVANCE_LISTING,
            id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_LISTING.LIST,
            children: [
                {
                    path: "",
                    element: <GrievanceListingReport />,
                    id: RESOURCE_ID.GRIEVANCE.REPORT.GRIEVANCE_LISTING.LIST
                }
            ]
        },
        {
            title: I18n("escalation_report"),
            path: PATH.COMPLAINT.ESCALATION_REPORT,
            children: [
                {
                    path: "",
                    element: <EscalationReport />,
                    id: RESOURCE_ID.GRIEVANCE.REPORT.ESCALATION_REPORT.LIST
                }
            ]
        },
        {
            title: I18n("pending_reports"),
            path: PATH.COMPLAINT.PENDING_REPORT,
            children: [
                {
                    path: "",
                    element: <PendingReport />,
                    id: RESOURCE_ID.GRIEVANCE.REPORT.PENDING_REPORT.LIST
                }
            ]
        },
        {
            title: I18n("reopened_complaints"),
            path: PATH.COMPLAINT.REOPENED_COMPLAINTS,
            children: [
                {
                    path: "",
                    element: <ReopenedComplaints />,
                    id: RESOURCE_ID.GRIEVANCE.REPORT.REOPENED_COMPLAINTS.LIST
                }
            ]
        },
        {
            title: I18n("top_grievance_category"),
            path: PATH.COMPLAINT.TOP_GRIEVANCE_CATEGORY,
            children: [
                {
                    path: "",
                    element: <TopGrievanceCategory />,
                    id: RESOURCE_ID.GRIEVANCE.REPORT.TOP_GRIEVANCE_CATEGORY.LIST
                }
            ]
        }
        ]
    }

];

export { routes };
