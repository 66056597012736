import { I18n } from "common/components";
import { TABLE_IDS } from "common/constants";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { getTableProps } from "modules/common/selectors";
import { MODULE_PATH } from "modules/paths";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { toEpoch } from "utils/dateUtils";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { API_URL } from "../apiUrls";

const formatData = (payload) => {
    const { date, ...rest } = payload;
    const data = { date: toEpoch(date), ...rest };
    return data;
};

export function* fetchCityLevelDisposalCell({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.CITY_LEVEL_DISPOSAL_CELL]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchCityLevelDisposalCellApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS, ACTION_TYPES.FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.CITY_LEVEL_DISPOSAL_CELL);
}

export function* cityLevelSiteDropDown({ payload }) {
    yield call(handleAPIRequest, Api.cityLevelSiteDropDownApi, payload);
}

export function* cityLevelDisposalCellDetailsById({ payload }) {
    yield fork(handleAPIRequest, Api.cityLevelDisposalCellDetailsByIdApi, payload);
    const responseAction = yield take([ACTION_TYPES.GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_SUCCESS) {
        if (responseAction?.payload?.district && responseAction?.payload?.lsgi) {
            yield call(handleAPIRequest, Api.cityLevelSiteDropDownApi, { lsgiId: responseAction?.payload?.lsgi?.id, pageNo: 0, pageSize: 500 });
        }
    }
}

export function* submitCityLevelDisposalCellDetails(action) {
    let { payload: { id } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.patchCityLevelDisposalCellDetailsApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS, ACTION_TYPES.PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("city_level_disposal_cell_information") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.CITY_LEVEL_DISPOSAL_CELL_INFO.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, Api.postCityLevelDisposalCellDetailsApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS, ACTION_TYPES.POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("city_level_disposal_cell_information") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.CITY_LEVEL_DISPOSAL_CELL_INFO.LIST}`));
        }
    }
}

export function* deleteCityLevelDisposalCellDetails({ payload }) {
    yield fork(handleAPIRequest, Api.deleteCityLevelDisposalCellDetailsApi, payload);
    const action = yield take([ACTION_TYPES.DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS, ACTION_TYPES.DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("city_level_disposal_cell_information") }) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.CITY_LEVEL_DISPOSAL_CELL]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchCityLevelDisposalCellApi, { pageNo: 0, pageSize: 5, ...filters, ...payload });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS, ACTION_TYPES.FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.CITY_LEVEL_DISPOSAL_CELL);
    }
}

export function* downloadCityLevelDisposalCell(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { fromDate, toDate } = filters;
    yield put(commonFileDownload({
        url: API_URL.CITY_LEVEL_DISPOSAL_CELL.DOWNLOAD,
        data: { type, fromDate, toDate },
        file: { name: fileName, ext: type }
    }));
}
export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS, fetchCityLevelDisposalCell),
        takeLatest(ACTION_TYPES.CITY_LEVEL_SITE_DROPDOWN, cityLevelSiteDropDown),
        takeLatest(ACTION_TYPES.GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID, cityLevelDisposalCellDetailsById),
        takeLatest(ACTION_TYPES.POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS, submitCityLevelDisposalCellDetails),
        takeLatest(ACTION_TYPES.PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS, submitCityLevelDisposalCellDetails),
        takeLatest(ACTION_TYPES.DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS, deleteCityLevelDisposalCellDetails),
        takeLatest(ACTION_TYPES.DOWNLOAD_CITY_LEVEL_DISPOSAL_CELL_DETAILS, downloadCityLevelDisposalCell)
    ]);
}
