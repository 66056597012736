import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const initialState = {
  wardSabhaAddDetails: {
    requestInProgress: false,
    data: {
      ulb: "",
      projects: "",
      wardId: "",
      meetingAgenda: "",
      completed: "",
      dateOfDiscussion: "",
      remarks: "",
      attachmentIds: [],
      maleCount: "",
      kudumbasreeMembersCount: "",
      oldAgeCount: "",
      disabledCount: "",
      hksCount: "",
      stCount: "",
      scCount: "",
      otherWasteWorkersCount: "",
      femaleCount: "",
      transCount: "",
      otherScrapDealersCount: "",
      ragPickersCount: "",
      totalParticipantCount: "",
      momAttachmentIds: [],
      photoAttachmentIds: []
    },
    envList: []
  },
  wardList: {
    requestInProgress: false,
    data: []
  },
  modalOpen: false,
  wardFilter: {}
};

const slice = createSlice({
  initialState,
  name: STATE_REDUCER_KEY,
  reducers: {
    clearAll: () => initialState,
    clear: (state) => {
      state.subProjectAddDetails = initialState.subProjectAddDetails;
    },
    clearSwmSubProject: (state) => {
      state.swmSubProject = initialState.swmSubProject;
    },
    openModal: (state) => {
      state.modalOpen = true;
    },
    closeModal: (state) => {
      state.modalOpen = false;
    },
    setWardFilter: (state, { payload: { key, value } }) => {
      _.set(state, `wardFilter.${key}`, value);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ACTION_TYPES.FETCH_WARD_SABHA_BY_ID_SUCCESS, (state, { payload }) => {
        _.set(state, "wardSabhaAddDetails.requestInProgress", false);
        _.set(state, "wardSabhaAddDetails.data", payload);
      })
      .addCase(ACTION_TYPES.SAVE_WARD_SABHA_FAILURE, (state, { payload }) => {
        _.set(state, "wardSabhaAddDetails.requestInProgress", false);
        _.set(state, "wardSabhaAddDetails.data", payload);
      })
      .addCase(ACTION_TYPES.FETCH_WARD_LIST_SUCCESS, (state, { payload }) => {
        _.set(state, "wardList.requestInProgress", false);
        _.set(state, "wardList.data", payload.content);
      })
      .addCase(ACTION_TYPES.FETCH_ENV_SAFEGUARD_LIST_SUCCESS, (state, { payload }) => {
        _.set(state, "wardList.requestInProgress", false);
        _.set(state, "wardList.envList", payload?.content);
      });
  }
});

export const { actions, reducer } = slice;
