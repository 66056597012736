import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const initialState = {
    mcfRrfDetailsById: {
        requestInProgress: false,
        data: {
            id: "",
            districtId: "",
            lsgiId: "",
            placeName: "",
            code: "",
            nbdwReceived: "",
            plasticWaste: "",
            wasteShredded: "",
            disposal: "",
            date: "",
            inertWaste: "",
            income: "",
            type: ""
        }
    },
    typeDropdown: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        setMcfRrfDetailsById: (state, action) => {
            state.mcfRrfDetailsById.data = action.payload;
        },
        clearMcfRrfDetailsById: (state) => {
            state.mcfRrfDetailsById.data = initialState.mcfRrfDetailsById.data;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_MCF_RRF_DETAILS_BY_ID_REQUEST, (state) => {
                state.mcfRrfDetailsById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_MCF_RRF_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "mcfRrfDetailsById.requestInProgress", false);
                _.set(state, "mcfRrfDetailsById.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_MCF_RRF_DETAILS_BY_ID_FAILURE, (state) => {
                state.mcfRrfDetailsById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_TYPE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "typeDropdown.requestInProgress", false);
                _.set(state, "typeDropdown.data", payload?.content);
            });
    }

});

export const { actions, reducer } = slice;
