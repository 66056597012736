import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    LIST_STAKEHOLDER_CONSULTATION: `${STATE_REDUCER_KEY}/LIST_STAKEHOLDER_CONSULTATION`,
    LIST_STAKEHOLDER_CONSULTATION_REQUEST: `${STATE_REDUCER_KEY}/LIST_STAKEHOLDER_CONSULTATION_REQUEST`,
    LIST_STAKEHOLDER_CONSULTATION_SUCCESS: `${STATE_REDUCER_KEY}/LIST_STAKEHOLDER_CONSULTATION_SUCCESS`,
    LIST_STAKEHOLDER_CONSULTATION_FAILURE: `${STATE_REDUCER_KEY}/LIST_STAKEHOLDER_CONSULTATION_FAILURE`,

    SUBMIT_STAKEHOLDER_CONSULTATION: `${STATE_REDUCER_KEY}/SUBMIT_STAKEHOLDER_CONSULTATION`,
    SUBMIT_STAKEHOLDER_CONSULTATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_STAKEHOLDER_CONSULTATION_REQUEST`,
    SUBMIT_STAKEHOLDER_CONSULTATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_STAKEHOLDER_CONSULTATION_SUCCESS`,
    SUBMIT_STAKEHOLDER_CONSULTATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_STAKEHOLDER_CONSULTATION_FAILURE`,

    STAKEHOLDER_CONSULTATION_BY_ID: `${STATE_REDUCER_KEY}/STAKEHOLDER_CONSULTATION_BY_ID`,
    STAKEHOLDER_CONSULTATION_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/STAKEHOLDER_CONSULTATION_BY_ID_REQUEST`,
    STAKEHOLDER_CONSULTATION_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/STAKEHOLDER_CONSULTATION_BY_ID_SUCCESS`,
    STAKEHOLDER_CONSULTATION_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/STAKEHOLDER_CONSULTATION_BY_ID_FAILURE`,

    UPDATE_STAKEHOLDER_CONSULTATION: `${STATE_REDUCER_KEY}/UPDATE_STAKEHOLDER_CONSULTATION`,
    UPDATE_STAKEHOLDER_CONSULTATION_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_STAKEHOLDER_CONSULTATION_REQUEST`,
    UPDATE_STAKEHOLDER_CONSULTATION_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_STAKEHOLDER_CONSULTATION_SUCCESS`,
    UPDATE_STAKEHOLDER_CONSULTATION_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_STAKEHOLDER_CONSULTATION_FAILURE`,

    DELETE_STAKEHOLDER_CONSULTATION: `${STATE_REDUCER_KEY}/DELETE_STAKEHOLDER_CONSULTATION`,
    DELETE_STAKEHOLDER_CONSULTATION_REQUEST: `${STATE_REDUCER_KEY}/DELETE_STAKEHOLDER_CONSULTATION_REQUEST`,
    DELETE_STAKEHOLDER_CONSULTATION_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_STAKEHOLDER_CONSULTATION_SUCCESS`,
    DELETE_STAKEHOLDER_CONSULTATION_FAILURE: `${STATE_REDUCER_KEY}/DELETE_STAKEHOLDER_CONSULTATION_FAILURE`,

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    CONFIRM_FORM_SUBMIT: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT`,
    CONFIRM_FORM_SUBMIT_REQUEST: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_REQUEST`,
    CONFIRM_FORM_SUBMIT_SUCCESS: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_SUCCESS`,
    CONFIRM_FORM_SUBMIT_FAILURE: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_FAILURE`
};

export const stakeHolderList = createAction(ACTION_TYPES.LIST_STAKEHOLDER_CONSULTATION);
export const postStakeHolder = createAction(ACTION_TYPES.SUBMIT_STAKEHOLDER_CONSULTATION);
export const stakeholderById = createAction(ACTION_TYPES.STAKEHOLDER_CONSULTATION_BY_ID);
export const deleteStakeHolder = createAction(ACTION_TYPES.DELETE_STAKEHOLDER_CONSULTATION);
export const updateStakeHolder = createAction(ACTION_TYPES.UPDATE_STAKEHOLDER_CONSULTATION);
export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const confirmStakeholder = createAction(ACTION_TYPES.CONFIRM_FORM_SUBMIT);
