import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { ACTION_TYPES } from "./actions";
import { handleAPIRequest } from "utils/http";
import { deleteFileById, deleteStakeholder, listStakeholder, stakeholderById, submitStakeholder, updateGapAnalysis, updateStakeholder } from "./api";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { setCommonTableData } from "utils/tableUtils";
import { actions as commonActions } from "../../common/slice";
import { actions as sliceActons } from "../../common/slice";
import { toEpoch } from "utils/dateUtils";
import { listByIdSWMPlanApi } from "../swmPlan/api";
import { actions as sliceActions } from "./slice";


const formatData = (rest) => {
    const { disabledCount, femaleCount, hksMemberCount, kudumbasreeMembersCount, maleCount, oldAgeCount, otherScrapDealerCount, otherWasteWorkerCount, ragPickersCount, transgenderCount, meetingDate, attachmentIds, remarks, meetingVenue, swmPlanId, totalCount } = rest;
    const data = { disabledCount: Number(disabledCount), femaleCount: Number(femaleCount), hksMemberCount: Number(hksMemberCount), kudumbasreeMembersCount: Number(kudumbasreeMembersCount), maleCount: Number(maleCount), swmPlanId: Number(swmPlanId), oldAgeCount: Number(oldAgeCount), otherScrapDealerCount: Number(otherScrapDealerCount), otherWasteWorkerCount: Number(otherWasteWorkerCount), ragPickersCount: Number(ragPickersCount), transgenderCount: Number(transgenderCount), meetingDate: toEpoch(meetingDate), attachmentIds, remarks, prePlan: true, meetingVenue, totalCount: Number(totalCount) };
    return data;
};

const formatEditData = (payload) => {
    const { totalCount, disabledCount, femaleCount, hksMemberCount, kudumbasreeMembersCount, maleCount, oldAgeCount, otherScrapDealerCount, otherWasteWorkerCount, ragPickersCount, transgenderCount, meetingDate, attachmentIds, remarks, meetingVenue, id, swmPlanId } = payload;
    const data = { totalCount: Number(totalCount), disabledCount: Number(disabledCount), femaleCount: Number(femaleCount), hksMemberCount: Number(hksMemberCount), kudumbasreeMembersCount: Number(kudumbasreeMembersCount), maleCount: Number(maleCount), id: Number(id), oldAgeCount: Number(oldAgeCount), otherScrapDealerCount: Number(otherScrapDealerCount), otherWasteWorkerCount: Number(otherWasteWorkerCount), ragPickersCount: Number(ragPickersCount), transgenderCount: Number(transgenderCount), swmPlanId: Number(swmPlanId), meetingDate: toEpoch(meetingDate), attachmentIds, remarks, prePlan: true, meetingVenue };
    return data;
};
export function* listStakeholderForm({ payload }) {
    let updatedPayload = {
        pageNo: payload.pageNo, pageSize: payload.pageSize, prePlan: true
    };
    yield fork(handleAPIRequest, listStakeholder, updatedPayload, payload.id);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_STAKEHOLDER_CONSULTATION_SUCCESS, ACTION_TYPES.LIST_STAKEHOLDER_CONSULTATION_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* submitStakeholderConsultation(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, submitStakeholder, formatData(rest));
    yield put(sliceActions.setStakeHoldersById(formatData(rest)));
    const responseAction = yield take([ACTION_TYPES.SUBMIT_STAKEHOLDER_CONSULTATION_SUCCESS, ACTION_TYPES.SUBMIT_STAKEHOLDER_CONSULTATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_STAKEHOLDER_CONSULTATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("pre_plan_consultation") }) }));
        yield put(sliceActions.resetStakeHoldersById());
    }
}

export function* updateStakeholderConsultation({ payload }) {
    if (payload.attachmentIds?.length === 0) {
        delete payload.attachmentIds;
    }
    yield fork(handleAPIRequest, updateStakeholder, formatEditData(payload));
    const responseAction = yield take([ACTION_TYPES.UPDATE_STAKEHOLDER_CONSULTATION_SUCCESS, ACTION_TYPES.UPDATE_STAKEHOLDER_CONSULTATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_STAKEHOLDER_CONSULTATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("pre_plan_consultation") }) }));
        yield put(sliceActions.resetOpenStatus());
        let updatedPayload = {
            pageNo: 0, pageSize: 5, prePlan: true
        };
        yield fork(handleAPIRequest, listStakeholder, updatedPayload, Number(payload.swmPlanId));
        const COMPARISON_TYPES = [ACTION_TYPES.LIST_STAKEHOLDER_CONSULTATION_SUCCESS, ACTION_TYPES.LIST_STAKEHOLDER_CONSULTATION_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES);
    }
}

export function* deleteStakeholderForm({ payload }) {
    yield fork(handleAPIRequest, deleteStakeholder, payload.id);
    const response = yield take([ACTION_TYPES.DELETE_STAKEHOLDER_CONSULTATION_SUCCESS, ACTION_TYPES.DELETE_STAKEHOLDER_CONSULTATION_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_STAKEHOLDER_CONSULTATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("pre_plan_consultation") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let updatedPayload = { pageNo: 0, pageSize: 5 };
        yield fork(handleAPIRequest, listStakeholder, updatedPayload, Number(payload.swmPlanId));
        const COMPARISON_TYPES = [ACTION_TYPES.LIST_STAKEHOLDER_CONSULTATION_SUCCESS, ACTION_TYPES.LIST_STAKEHOLDER_CONSULTATION_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES);
    }
}

export function* stakeholderGetById({ payload: id }) {
    yield call(handleAPIRequest, stakeholderById, id);
}

export function* deleteFile({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, deleteFileById, id, fileId);
    const response = yield take([ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_FILE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: "file" }) }));
    }
}

export function* confirmStakeholder({ payload }) {
    yield fork(handleAPIRequest, updateGapAnalysis, payload);
    const response = yield take([ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS, ACTION_TYPES.CONFIRM_FORM_SUBMIT_FAILURE]);
    if (response.type === ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("pre_plan_consultation") }) }));
        const { swmPlanId } = payload;
        yield call(handleAPIRequest, listByIdSWMPlanApi, swmPlanId);
        yield put(sliceActons.moveTabUp());
    }
}

export default function* testSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_STAKEHOLDER_CONSULTATION, submitStakeholderConsultation),
        takeLatest(ACTION_TYPES.UPDATE_STAKEHOLDER_CONSULTATION, updateStakeholderConsultation),
        takeLatest(ACTION_TYPES.DELETE_STAKEHOLDER_CONSULTATION, deleteStakeholderForm),
        takeLatest(ACTION_TYPES.LIST_STAKEHOLDER_CONSULTATION, listStakeholderForm),
        takeLatest(ACTION_TYPES.STAKEHOLDER_CONSULTATION_BY_ID, stakeholderGetById),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFile),
        takeLatest(ACTION_TYPES.CONFIRM_FORM_SUBMIT, confirmStakeholder)
    ]);
}
