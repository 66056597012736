import { all, takeLatest, call, fork, take, put, select } from "redux-saga/effects";
import { ACTION_TYPES } from "./actions";
import { handleAPIRequest } from "../../../utils/http";
import { fetchCategoryDropdownDetails, fetchLookupCategoryIdDropdown, vendorCategoryDeleteCall, vendorCategoryEditCall, vendorCategoryFetchAPICall, vendorCategoryFetchApiCallById, vendorCategoryPostAPICall, vendorContactDeleteCall, vendorContactEditCall, vendorContactFetchAPICall, vendorContactFetchApiCallById, vendorContactPostAPICall, vendorContactTypeDropdown, fetchContactDropdownName, fetchContactDropdownGstIn, filterCategoryName } from "./api";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { navigateTo } from "modules/common/actions";
import { actions as commonActions } from "../../common/slice";
import { MODULE_PATH } from "modules/paths";
import { ADMIN_PATH } from "../paths";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
// import { getTableProps } from "modules/common/selectors";
import { getTableProps } from "modules/common/selectors";

export function* submitVendorCategoryDetails({ payload }) {
    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, vendorCategoryEditCall, payload);
        const response = yield take([ACTION_TYPES.EDIT_VENDOR_CATEGORY_DETAILS_SUCCESS, ACTION_TYPES.EDIT_VENDOR_CATEGORY_DETAILS_FAILURE]);
        if (response.type === ACTION_TYPES.EDIT_VENDOR_CATEGORY_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("vendor_category") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${ADMIN_PATH.VENDOR_CATEGORY.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, vendorCategoryPostAPICall, payload);
        const response = yield take([ACTION_TYPES.SUBMIT_VENDOR_CATEGORY_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_VENDOR_CATEGORY_DETAILS_FAILURE]);
        if (response.type === ACTION_TYPES.SUBMIT_VENDOR_CATEGORY_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("vendor_category") }) }));

            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${ADMIN_PATH.VENDOR_CATEGORY.LIST}`));
        }
    }
}

export function* fetchVendorCategoryDetails({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, vendorCategoryFetchAPICall, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_SUCCESS, ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* deleteVendorCategoryList({ payload: id }) {
    yield fork(handleAPIRequest, vendorCategoryDeleteCall, id);
    const response = yield take([ACTION_TYPES.DELETE_VENDOR_CATEGORY_DETAILS_SUCCESS, ACTION_TYPES.DELETE_VENDOR_CATEGORY_DETAILS_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_VENDOR_CATEGORY_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("vendor_category") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchVendorCategoryDetails, { payload: tablePayload });
    }
}

export function* fetchVendorCategoryDetailsById({ payload }) {
    yield call(handleAPIRequest, vendorCategoryFetchApiCallById, payload);
}

const formatArray = (data) => {
    const d = data?.map((item) => (item.id));
    return d;
};

export function* submitVendorContactDetails(action) {
    let { payload: { id, categoryReferences, ...rest } = {} } = action;
    let categoryId = formatArray(categoryReferences);
    rest.categoryId = categoryId;
    if (id) {
        yield fork(handleAPIRequest, vendorContactEditCall, { id, ...rest });
        const response = yield take([ACTION_TYPES.EDIT_VENDOR_CONTACT_DETAILS_SUCCESS, ACTION_TYPES.EDIT_VENDOR_CONTACT_DETAILS_FAILURE]);
        if (response.type === ACTION_TYPES.EDIT_VENDOR_CONTACT_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("vendor_contact") }) }));
            yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${ADMIN_PATH.VENDOR_CONTACT.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, vendorContactPostAPICall, rest);
        const response = yield take([ACTION_TYPES.SUBMIT_VENDOR_CONTACT_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_VENDOR_CONTACT_DETAILS_FAILURE]);
        if (response.type === ACTION_TYPES.SUBMIT_VENDOR_CONTACT_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("vendor_contact") }) }));
            yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${ADMIN_PATH.VENDOR_CONTACT.LIST}`));
        }
    }
}

export function* fetchVendorContactDetails({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, vendorContactFetchAPICall, { fullListOption: false, ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_SUCCESS, ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* deleteVendorContactList({ payload }) {
    let id = payload;
    yield fork(handleAPIRequest, vendorContactDeleteCall, id);
    const response = yield take([ACTION_TYPES.DELETE_VENDOR_CONTACT_DETAILS_SUCCESS, ACTION_TYPES.DELETE_VENDOR_CONTACT_DETAILS_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_VENDOR_CONTACT_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("vendors") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchVendorContactDetails, { payload: tablePayload });
    }
}
export function* fetchVendorContactDetailsById({ payload: id }) {
    yield call(handleAPIRequest, vendorContactFetchApiCallById, id);
}

export function* fetchUserContactTypeDropdown() {
    yield call(handleAPIRequest, vendorContactTypeDropdown);
}

export function* lookupCategoryIdDropdown() {
    yield call(handleAPIRequest, fetchLookupCategoryIdDropdown);
}

export function* fetchCategoryDropdown() {
    yield call(handleAPIRequest, fetchCategoryDropdownDetails);
}

export function* filterContactName({ payload }) {
    yield call(handleAPIRequest, fetchContactDropdownName, payload);
}
export function* filterContactGstIn({ payload }) {
    yield call(handleAPIRequest, fetchContactDropdownGstIn, payload);
}
export function* filterCategoryNameDropdown({ payload }) {
    yield call(handleAPIRequest, filterCategoryName, payload);
}

export default function* vendorDetailSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_VENDOR_CATEGORY_DETAILS, submitVendorCategoryDetails),
        takeLatest(ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS, fetchVendorCategoryDetails),
        takeLatest(ACTION_TYPES.DELETE_VENDOR_CATEGORY_DETAILS, deleteVendorCategoryList),
        takeLatest(ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_BY_ID, fetchVendorCategoryDetailsById),
        takeLatest(ACTION_TYPES.SUBMIT_VENDOR_CONTACT_DETAILS, submitVendorContactDetails),
        takeLatest(ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS, fetchVendorContactDetails),
        takeLatest(ACTION_TYPES.DELETE_VENDOR_CONTACT_DETAILS, deleteVendorContactList),
        takeLatest(ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_BY_ID, fetchVendorContactDetailsById),
        takeLatest(ACTION_TYPES.FETCH_USER_CONTACT_TYPE, fetchUserContactTypeDropdown),
        takeLatest(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID, lookupCategoryIdDropdown),
        takeLatest(ACTION_TYPES.FETCH_CATEGORY, fetchCategoryDropdown),
        takeLatest(ACTION_TYPES.LIST_VENDOR_CONTACT_NAME, filterContactName),
        takeLatest(ACTION_TYPES.LIST_VENDOR_CONTACT_GST, filterContactGstIn),
        takeLatest(ACTION_TYPES.FILTER_VENDOR_CATEGORY_NAME, filterCategoryNameDropdown)
    ]);
}

