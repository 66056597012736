import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import { COMMON_TABLE_PAGINATION } from "modules/common/constants";
import { getPayloadContent, getPayloadContentDetails } from "utils/apiUtils";


const initialState = {
    tablePagination: { ...COMMON_TABLE_PAGINATION },
    trainingVenueForm: {
        requestInProgress: false,
        data: {}
    },
    venueId: {
        requestInProgress: false,
        data: []
    },
    district: []
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.table = initialState.table;
        },
        clearVenueId: (state) => {
            state.venueId = initialState.venueId;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.CREATE_VENUE_FORM_REQUEST, (state) => {
            _.set(state, "trainingVenueForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.CREATE_VENUE_FORM_SUCCESS, (state, action) => {
            _.set(state, "trainingVenueForm.data", action.payload);
            _.set(state, "trainingVenueForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, (state, action) => {
            _.set(state, "district", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.LIST_VENUE_FORM_REQUEST, (state) => {
            _.set(state, "trainingVenueForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.LIST_VENUE_FORM_SUCCESS, (state, action) => {
            _.set(state, "trainingVenueForm.data", getPayloadContent(action));
            _.set(state, "trainingVenueForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.DELETE_VENUE_FORM_BY_ID_SUCCESS, (state, action) => {
            _.set(state, "trainingVenueForm.data", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.EDIT_VENUE_FORM_BY_ID_SUCCESS, (state, action) => {
            _.set(state, "trainingVenueForm.data", getPayloadContentDetails(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_VENUE_ID_SUCCESS, (state, { payload }) => {
            _.set(state, "venueId.requestInProgress", false);
            _.set(state, "venueId.data", payload.content);
        });
    }
});
export const { actions, reducer } = slice;

