import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    LIST_SWM_SUB_PROJECT: `${STATE_REDUCER_KEY}/LIST_SWM_SUB_PROJECT`,
    LIST_SWM_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/LIST_SWM_SUB_PROJECT_REQUEST`,
    LIST_SWM_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_SWM_SUB_PROJECT_SUCCESS`,
    LIST_SWM_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/LIST_SWM_SUB_PROJECT_FAILURE`,

    DELETE_SWM_SUB_PROJECT_PROJECT: `${STATE_REDUCER_KEY}/DELETE_SWM_SUB_PROJECT`,
    DELETE_SWM_SUB_PROJECT_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/DELETE_SWM_SUB_PROJECT_REQUEST`,
    DELETE_SWM_SUB_PROJECT_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_SWM_SUB_PROJECT_SUCCESS`,
    DELETE_SWM_SUB_PROJECT_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/DELETE_SWM_SUB_PROJECT_FAILURE`,

    LIST_SWM_SUB_PROJECT_ACTIVITIES: `${STATE_REDUCER_KEY}/LIST_SWM_SUB_PROJECT_ACTIVITIES`,
    LIST_SWM_SUB_PROJECT_ACTIVITIES_REQUEST: `${STATE_REDUCER_KEY}/LIST_SWM_SUB_PROJECT_ACTIVITIES_REQUEST`,
    LIST_SWM_SUB_PROJECT_ACTIVITIES_SUCCESS: `${STATE_REDUCER_KEY}/LIST_SWM_SUB_PROJECT_ACTIVITIES_SUCCESS`,
    LIST_SWM_SUB_PROJECT_ACTIVITIES_FAILURE: `${STATE_REDUCER_KEY}/LIST_SWM_SUB_PROJECT_ACTIVITIES_FAILURE`,

    SUBMIT_SWM_SUB_PROJECT: `${STATE_REDUCER_KEY}/SUBMIT_SWM_SUB_PROJECT`,
    SUBMIT_SWM_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_SWM_SUB_PROJECT_REQUEST`,
    SUBMIT_SWM_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_SWM_SUB_PROJECT_SUCCESS`,
    SUBMIT_SWM_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_SWM_SUB_PROJECT_FAILURE`,

    POST_SWM_SCREENING_DATA: `${STATE_REDUCER_KEY}/POST_SWM_SCREENING_DATA`,
    POST_SWM_SCREENING_DATA_REQUEST: `${STATE_REDUCER_KEY}/POST_SWM_SCREENING_DATA_REQUEST`,
    POST_SWM_SCREENING_DATA_SUCCESS: `${STATE_REDUCER_KEY}/POST_SWM_SCREENING_DATA_SUCCESS`,
    POST_SWM_SCREENING_DATA_FAILURE: `${STATE_REDUCER_KEY}/POST_SWM_SCREENING_DATA_FAILURE`,

    FETCH_SWM_SCREENING_DATA: `${STATE_REDUCER_KEY}/FETCH_SWM_SCREENING_DATA`,
    FETCH_SWM_SCREENING_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SWM_SCREENING_DATA_REQUEST`,
    FETCH_SWM_SCREENING_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SWM_SCREENING_DATA_SUCCESS`,
    FETCH_SWM_SCREENING_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SWM_SCREENING_DATA_FAILURE`,

    PATCH_SWM_SCREENING_DATA: `${STATE_REDUCER_KEY}/PATCH_SWM_SCREENING_DATA`,
    PATCH_SWM_SCREENING_DATA_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SWM_SCREENING_DATA_REQUEST`,
    PATCH_SWM_SCREENING_DATA_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SWM_SCREENING_DATA_SUCCESS`,
    PATCH_SWM_SCREENING_DATA_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SWM_SCREENING_DATA_FAILURE`,

    CREATE_ACTIVITY: `${STATE_REDUCER_KEY}/CREATE_ACTIVITY`,
    CREATE_ACTIVITY_REQUEST: `${STATE_REDUCER_KEY}/CREATE_ACTIVITY_REQUEST`,
    CREATE_ACTIVITY_SUCCESS: `${STATE_REDUCER_KEY}/CREATE_ACTIVITY_SUCCESS`,
    CREATE_ACTIVITY_FAILURE: `${STATE_REDUCER_KEY}/CREATE_ACTIVITY_FAILURE`,

    UPDATE_ACTIVITY: `${STATE_REDUCER_KEY}/UPDATE_ACTIVITY`,
    UPDATE_ACTIVITY_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_ACTIVITY_REQUEST`,
    UPDATE_ACTIVITY_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_ACTIVITY_SUCCESS`,
    UPDATE_ACTIVITY_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_ACTIVITY_FAILURE`,

    FETCH_PROJECT_ACTIVITY_BY_ID: `${STATE_REDUCER_KEY}/FETCH_PROJECT_ACTIVITY_DETAILS_BY_ID`,
    FETCH_PROJECT_ACTIVITY_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_ACTIVITY_DETAILS_BY_ID_REQUEST`,
    FETCH_PROJECT_ACTIVITY_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROJECT_ACTIVITY_DETAILS_BY_ID_SUCCESS`,
    FETCH_PROJECT_ACTIVITY_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROJECT_ACTIVITY_DETAILS_BY_ID_FAILURE`,

    UOM_LIST: `${STATE_REDUCER_KEY}/UOM_LIST`,
    UOM_LIST_REQUEST: `${STATE_REDUCER_KEY}/UOM_LIST_REQUEST`,
    UOM_LIST_SUCCESS: `${STATE_REDUCER_KEY}/UOM_LIST_SUCCESS`,
    UOM_LIST_FAILURE: `${STATE_REDUCER_KEY}/UOM_LIST_FAILURE`,

    DELETE_PROJECT_ACTIVITY: `${STATE_REDUCER_KEY}/DELETE_PROJECT_ACTIVITY`,
    DELETE_PROJECT_ACTIVITY_REQUEST: `${STATE_REDUCER_KEY}/DELETE_PROJECT_ACTIVITY_REQUEST`,
    DELETE_PROJECT_ACTIVITY_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_PROJECT_ACTIVITY_SUCCESS`,
    DELETE_PROJECT_ACTIVITY_FAILURE: `${STATE_REDUCER_KEY}/DELETE_PROJECT_ACTIVITY_FAILURE`,

    DELETE_ATTACHMENT: `${STATE_REDUCER_KEY}/DELETE_ATTACHMENT`,
    DELETE_ATTACHMENT_REQUEST: `${STATE_REDUCER_KEY}/DELETE_ATTACHMENT_REQUEST`,
    DELETE_ATTACHMENT_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_ATTACHMENT_SUCCESS`,
    DELETE_ATTACHMENT_FAILURE: `${STATE_REDUCER_KEY}/DELETE_ATTACHMENT_FAILURE`,

    POST_SWM_SUB_PROJECT_GROUP_DISCUSSION: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_GROUP_DISCUSSION`,
    POST_SWM_SUB_PROJECT_GROUP_DISCUSSION_REQUEST: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_GROUP_DISCUSSION_REQUEST`,
    POST_SWM_SUB_PROJECT_GROUP_DISCUSSION_SUCCESS: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_GROUP_DISCUSSION_SUCCESS`,
    POST_SWM_SUB_PROJECT_GROUP_DISCUSSION_FAILURE: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_GROUP_DISCUSSION_FAILURE`,

    FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION`,
    FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_REQUEST`,
    FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_SUCCESS`,
    FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_FAILURE`,

    PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION`,
    PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_REQUEST`,
    PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_SUCCESS`,
    PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_FAILURE`,

    POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR`,
    POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR_REQUEST: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR_REQUEST`,
    POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR_SUCCESS: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR_SUCCESS`,
    POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR_FAILURE: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR_FAILURE`,

    FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR`,
    FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_REQUEST`,
    FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_SUCCESS`,
    FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_FAILURE`,

    PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR`,
    PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_REQUEST`,
    PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_SUCCESS`,
    PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_FAILURE`,

    POST_SWM_HSC_APPROVAL: `${STATE_REDUCER_KEY}/POST_SWM_HSC_APPROVAL`,
    POST_SWM_HSC_APPROVAL_REQUEST: `${STATE_REDUCER_KEY}/POST_SWM_HSC_APPROVAL_REQUEST`,
    POST_SWM_HSC_APPROVAL_SUCCESS: `${STATE_REDUCER_KEY}/POST_SWM_HSC_APPROVAL_SUCCESS`,
    POST_SWM_HSC_APPROVAL_FAILURE: `${STATE_REDUCER_KEY}/POST_SWM_HSC_APPROVAL_FAILURE`,

    POST_SWM_DPC_APPROVAL: `${STATE_REDUCER_KEY}/POST_SWM_DPC_APPROVAL`,
    POST_SWM_DPC_APPROVAL_REQUEST: `${STATE_REDUCER_KEY}/POST_SWM_DPC_APPROVAL_REQUEST`,
    POST_SWM_DPC_APPROVAL_SUCCESS: `${STATE_REDUCER_KEY}/POST_SWM_DPC_APPROVAL_SUCCESS`,
    POST_SWM_DPC_APPROVAL_FAILURE: `${STATE_REDUCER_KEY}/POST_SWM_DPC_APPROVAL_FAILURE`,

    FETCH_SWM_ADP_PROCESS: `${STATE_REDUCER_KEY}/FETCH_SWM_ADP_PROCESS`,
    FETCH_SWM_ADP_PROCESS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SWM_ADP_PROCESS_REQUEST`,
    FETCH_SWM_ADP_PROCESS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SWM_ADP_PROCESS_SUCCESS`,
    FETCH_SWM_ADP_PROCESS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SWM_ADP_PROCESS_FAILURE`,

    POST_SWM_MC_APPROVAL: `${STATE_REDUCER_KEY}/POST_SWM_MC_APPROVAL`,
    POST_SWM_MC_APPROVAL_REQUEST: `${STATE_REDUCER_KEY}/POST_SWM_MC_APPROVAL_REQUEST`,
    POST_SWM_MC_APPROVAL_SUCCESS: `${STATE_REDUCER_KEY}/POST_SWM_MC_APPROVAL_SUCCESS`,
    POST_SWM_MC_APPROVAL_FAILURE: `${STATE_REDUCER_KEY}/POST_SWM_MC_APPROVAL_FAILURE`,

    FETCH_SAFE_GUARD: `${STATE_REDUCER_KEY}/FETCH_SAFE_GUARD`,
    FETCH_SAFE_GUARD_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SAFE_GUARD_REQUEST`,
    FETCH_SAFE_GUARD_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SAFE_GUARD_SUCCESS`,
    FETCH_SAFE_GUARD_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SAFE_GUARD_FAILURE`,

    POST_SAFE_GUARD: `${STATE_REDUCER_KEY}/POST_SAFE_GUARD`,
    POST_SAFE_GUARD_REQUEST: `${STATE_REDUCER_KEY}/POST_SAFE_GUARD_REQUEST`,
    POST_SAFE_GUARD_SUCCESS: `${STATE_REDUCER_KEY}/POST_SAFE_GUARD_SUCCESS`,
    POST_SAFE_GUARD_FAILURE: `${STATE_REDUCER_KEY}/POST_SAFE_GUARD_FAILURE`,

    PATCH_SAFE_GUARD: `${STATE_REDUCER_KEY}/PATCH_SAFE_GUARD`,
    PATCH_SAFE_GUARD_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SAFE_GUARD_REQUEST`,
    PATCH_SAFE_GUARD_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SAFE_GUARD_SUCCESS`,
    PATCH_SAFE_GUARD_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SAFE_GUARD_FAILURE`,

    REMARK_DROPDOWN: `${STATE_REDUCER_KEY}/REMARK_DROPDOWN`,
    REMARK_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/REMARK_DROPDOWN_REQUEST`,
    REMARK_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/REMARK_DROPDOWN_SUCCESS`,
    REMARK_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/REMARK_DROPDOWN_FAILURE`,

    POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD`,
    POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_REQUEST: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_REQUEST`,
    POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_SUCCESS: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_SUCCESS`,
    POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_FAILURE: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_FAILURE`,

    PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD`,
    PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_REQUEST`,
    PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_SUCCESS`,
    PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_FAILURE`,

    SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID: `${STATE_REDUCER_KEY}/SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID`,
    SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID_REQUEST`,
    SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID_SUCCESS`,
    SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID_FAILURE`,

    LIST_SOCIAL_SAFEGUARD: `${STATE_REDUCER_KEY}/LIST_SOCIAL_SAFEGUARD`,
    LIST_SOCIAL_SAFEGUARD_REQUEST: `${STATE_REDUCER_KEY}/LIST_SOCIAL_SAFEGUARD_REQUEST`,
    LIST_SOCIAL_SAFEGUARD_SUCCESS: `${STATE_REDUCER_KEY}/LIST_SOCIAL_SAFEGUARD_SUCCESS`,
    LIST_SOCIAL_SAFEGUARD_FAILURE: `${STATE_REDUCER_KEY}/LIST_SOCIAL_SAFEGUARD_FAILURE`,

    LIST_ANNUAL_PLAN_YEAR: `${STATE_REDUCER_KEY}/LIST_ANNUAL_PLAN_YEAR`,
    LIST_ANNUAL_PLAN_YEAR_REQUEST: `${STATE_REDUCER_KEY}/LIST_ANNUAL_PLAN_YEAR_REQUEST`,
    LIST_ANNUAL_PLAN_YEAR_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ANNUAL_PLAN_YEAR_SUCCESS`,
    LIST_ANNUAL_PLAN_YEAR_FAILURE: `${STATE_REDUCER_KEY}/LIST_ANNUAL_PLAN_YEAR_FAILURE`,

    SAVE_VIKASANA_SEMINAR_COMMON: `${STATE_REDUCER_KEY}/SAVE_VIKASANA_SEMINAR_COMMON`,
    SAVE_VIKASANA_SEMINAR_COMMON_REQUEST: `${STATE_REDUCER_KEY}/SAVE_VIKASANA_SEMINAR_COMMON_REQUEST`,
    SAVE_VIKASANA_SEMINAR_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_VIKASANA_SEMINAR_COMMON_SUCCESS`,
    SAVE_VIKASANA_SEMINAR_COMMON_FAILURE: `${STATE_REDUCER_KEY}/SAVE_VIKASANA_SEMINAR_COMMON_FAILURE`,

    LIST_ALL_VIKASANA_SEMINAR_COMMON: `${STATE_REDUCER_KEY}/LIST_ALL_VIKASANA_SEMINAR_COMMON`,
    LIST_ALL_VIKASANA_SEMINAR_COMMON_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_VIKASANA_SEMINAR_COMMON_REQUEST`,
    LIST_ALL_VIKASANA_SEMINAR_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_VIKASANA_SEMINAR_COMMON_SUCCESS`,
    LIST_ALL_VIKASANA_SEMINAR_COMMON_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_VIKASANA_SEMINAR_COMMON_FAILURE`,

    VIKASANA_SEMINAR_COMMON_BY_ID: `${STATE_REDUCER_KEY}/VIKASANA_SEMINAR_COMMON_BY_ID`,
    VIKASANA_SEMINAR_COMMON_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/VIKASANA_SEMINAR_COMMON_BY_ID_REQUEST`,
    VIKASANA_SEMINAR_COMMON_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/VIKASANA_SEMINAR_COMMON_BY_ID_SUCCESS`,
    VIKASANA_SEMINAR_COMMON_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/VIKASANA_SEMINAR_COMMON_BY_ID_FAILURE`,

    PATCH_VIKASANA_SEMINAR_COMMON: `${STATE_REDUCER_KEY}/PATCH_VIKASANA_SEMINAR_COMMON`,
    PATCH_VIKASANA_SEMINAR_COMMON_REQUEST: `${STATE_REDUCER_KEY}/PATCH_VIKASANA_SEMINAR_COMMON_REQUEST`,
    PATCH_VIKASANA_SEMINAR_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_VIKASANA_SEMINAR_COMMON_SUCCESS`,
    PATCH_VIKASANA_SEMINAR_COMMON_FAILURE: `${STATE_REDUCER_KEY}/PATCH_VIKASANA_SEMINAR_COMMON_FAILURE`,

    SAVE_WORKING_GROUP_DISCUSSION_COMMON: `${STATE_REDUCER_KEY}/SAVE_WORKING_GROUP_DISCUSSION_COMMON`,
    SAVE_WORKING_GROUP_DISCUSSION_COMMON_REQUEST: `${STATE_REDUCER_KEY}/SAVE_WORKING_GROUP_DISCUSSION_COMMON_REQUEST`,
    SAVE_WORKING_GROUP_DISCUSSION_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_WORKING_GROUP_DISCUSSION_COMMON_SUCCESS`,
    SAVE_WORKING_GROUP_DISCUSSION_COMMON_FAILURE: `${STATE_REDUCER_KEY}/SAVE_WORKING_GROUP_DISCUSSION_COMMON_FAILURE`,

    LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON: `${STATE_REDUCER_KEY}/LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON`,
    LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON_REQUEST`,
    LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON_SUCCESS`,
    LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON_FAILURE`,

    WORKING_GROUP_DISCUSSION_COMMON_BY_ID: `${STATE_REDUCER_KEY}/WORKING_GROUP_DISCUSSION_COMMON_BY_ID`,
    WORKING_GROUP_DISCUSSION_COMMON_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/WORKING_GROUP_DISCUSSION_COMMON_BY_ID_REQUEST`,
    WORKING_GROUP_DISCUSSION_COMMON_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/WORKING_GROUP_DISCUSSION_COMMON_BY_ID_SUCCESS`,
    WORKING_GROUP_DISCUSSION_COMMON_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/WORKING_GROUP_DISCUSSION_COMMON_BY_ID_FAILURE`,

    SAVE_HSC_APPROVAL_COMMON: `${STATE_REDUCER_KEY}/SAVE_HSC_APPROVAL_COMMON`,
    SAVE_HSC_APPROVAL_COMMON_REQUEST: `${STATE_REDUCER_KEY}/SAVE_HSC_APPROVAL_COMMON_REQUEST`,
    SAVE_HSC_APPROVAL_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_HSC_APPROVAL_COMMON_SUCCESS`,
    SAVE_HSC_APPROVAL_COMMON_FAILURE: `${STATE_REDUCER_KEY}/SAVE_HSC_APPROVAL_COMMON_FAILURE`,

    PATCH_HSC_APPROVAL_COMMON: `${STATE_REDUCER_KEY}/PATCH_HSC_APPROVAL_COMMON`,
    PATCH_HSC_APPROVAL_COMMON_REQUEST: `${STATE_REDUCER_KEY}/PATCH_HSC_APPROVAL_COMMON_REQUEST`,
    PATCH_HSC_APPROVAL_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_HSC_APPROVAL_COMMON_SUCCESS`,
    PATCH_HSC_APPROVAL_COMMON_FAILURE: `${STATE_REDUCER_KEY}/PATCH_HSC_APPROVAL_COMMON_FAILURE`,

    LIST_ALL_HSC_APPROVAL_COMMON: `${STATE_REDUCER_KEY}/LIST_ALL_HSC_APPROVAL_COMMON`,
    LIST_ALL_HSC_APPROVAL_COMMON_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_HSC_APPROVAL_COMMON_REQUEST`,
    LIST_ALL_HSC_APPROVAL_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_HSC_APPROVAL_COMMON_SUCCESS`,
    LIST_ALL_HSC_APPROVAL_COMMON_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_HSC_APPROVAL_COMMON_FAILURE`,

    LIST_ALL_MC_APPROVAL_COMMON: `${STATE_REDUCER_KEY}/LIST_ALL_MC_APPROVAL_COMMON`,
    LIST_ALL_MC_APPROVAL_COMMON_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_MC_APPROVAL_COMMON_REQUEST`,
    LIST_ALL_MC_APPROVAL_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_MC_APPROVAL_COMMON_SUCCESS`,
    LIST_ALL_MC_APPROVAL_COMMON_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_MC_APPROVAL_COMMON_FAILURE`,

    SAVE_MC_APPROVAL_COMMON: `${STATE_REDUCER_KEY}/SAVE_MC_APPROVAL_COMMON`,
    SAVE_MC_APPROVAL_COMMON_REQUEST: `${STATE_REDUCER_KEY}/SAVE_MC_APPROVAL_COMMON_REQUEST`,
    SAVE_MC_APPROVAL_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_MC_APPROVAL_COMMON_SUCCESS`,
    SAVE_MC_APPROVAL_COMMON_FAILURE: `${STATE_REDUCER_KEY}/SAVE_MC_APPROVAL_COMMON_FAILURE`,

    SAVE_DPC_APPROVAL_COMMON: `${STATE_REDUCER_KEY}/SAVE_DPC_APPROVAL_COMMON`,
    SAVE_DPC_APPROVAL_COMMON_REQUEST: `${STATE_REDUCER_KEY}/SAVE_DPC_APPROVAL_COMMON_REQUEST`,
    SAVE_DPC_APPROVAL_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_DPC_APPROVAL_COMMON_SUCCESS`,
    SAVE_DPC_APPROVAL_COMMON_FAILURE: `${STATE_REDUCER_KEY}/SAVE_DPC_APPROVAL_COMMON_FAILURE`,

    LIST_ALL_DPC_APPROVAL_COMMON: `${STATE_REDUCER_KEY}/LIST_ALL_DPC_APPROVAL_COMMON`,
    LIST_ALL_DPC_APPROVAL_COMMON_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_DPC_APPROVAL_COMMON_REQUEST`,
    LIST_ALL_DPC_APPROVAL_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_DPC_APPROVAL_COMMON_SUCCESS`,
    LIST_ALL_DPC_APPROVAL_COMMON_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_DPC_APPROVAL_COMMON_FAILURE`,

    LIST_ALL_SWM_SCREENING: `${STATE_REDUCER_KEY}/LIST_ALL_SWM_SCREENING`,
    LIST_ALL_SWM_SCREENING_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_SWM_SCREENING_REQUEST`,
    LIST_ALL_SWM_SCREENING_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_SWM_SCREENING_SUCCESS`,
    LIST_ALL_SWM_SCREENING_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_SWM_SCREENING_FAILURE`,

    LIST_ALL_GROUP_DISCUSSION: `${STATE_REDUCER_KEY}/LIST_ALL_GROUP_DISCUSSION`,
    LIST_ALL_GROUP_DISCUSSION_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_GROUP_DISCUSSION_REQUEST`,
    LIST_ALL_GROUP_DISCUSSION_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_GROUP_DISCUSSION_SUCCESS`,
    LIST_ALL_GROUP_DISCUSSION_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_GROUP_DISCUSSION_FAILURE`,

    LIST_ALL_VIKASANA_SEMINAR: `${STATE_REDUCER_KEY}/LIST_ALL_VIKASANA_SEMINAR`,
    LIST_ALL_VIKASANA_SEMINAR_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_VIKASANA_SEMINAR_REQUEST`,
    LIST_ALL_VIKASANA_SEMINAR_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_VIKASANA_SEMINAR_SUCCESS`,
    LIST_ALL_VIKASANA_SEMINAR_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_VIKASANA_SEMINAR_FAILURE`,

    LIST_ALL_APD_PROCESS: `${STATE_REDUCER_KEY}/LIST_ALL_APD_PROCESS`,
    LIST_ALL_APD_PROCESS_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_APD_PROCESS_REQUEST`,
    LIST_ALL_APD_PROCESS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_APD_PROCESS_SUCCESS`,
    LIST_ALL_APD_PROCESS_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_APD_PROCESS_FAILURE`,

    LIST_ALL_FINANCIAL_SANCTION: `${STATE_REDUCER_KEY}/LIST_ALL_FINANCIAL_SANCTION`,
    LIST_ALL_FINANCIAL_SANCTION_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_FINANCIAL_SANCTION_REQUEST`,
    LIST_ALL_FINANCIAL_SANCTION_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_FINANCIAL_SANCTION_SUCCESS`,
    LIST_ALL_FINANCIAL_SANCTION_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_FINANCIAL_SANCTION_FAILURE`,

    PATCH_SWM_MC_APPROVAL: `${STATE_REDUCER_KEY}/PATCH_SWM_MC_APPROVAL`,
    PATCH_SWM_MC_APPROVAL_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SWM_MC_APPROVAL_REQUEST`,
    PATCH_SWM_MC_APPROVAL_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SWM_MC_APPROVAL_SUCCESS`,
    PATCH_SWM_MC_APPROVAL_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SWM_MC_APPROVAL_FAILURE`,

    PATCH_SWM_HSC_APPROVAL: `${STATE_REDUCER_KEY}/PATCH_SWM_HSC_APPROVAL`,
    PATCH_SWM_HSC_APPROVAL_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SWM_HSC_APPROVAL_REQUEST`,
    PATCH_SWM_HSC_APPROVAL_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SWM_HSC_APPROVAL_SUCCESS`,
    PATCH_SWM_HSC_APPROVAL_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SWM_HSC_APPROVAL_FAILURE`,

    PATCH_SWM_DPC_APPROVAL: `${STATE_REDUCER_KEY}/PATCH_SWM_DPC_APPROVAL`,
    PATCH_SWM_DPC_APPROVAL_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SWM_DPC_APPROVAL_REQUEST`,
    PATCH_SWM_DPC_APPROVAL_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SWM_DPC_APPROVAL_SUCCESS`,
    PATCH_SWM_DPC_APPROVAL_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SWM_DPC_APPROVAL_FAILURE`,

    PATCH_MC_APPROVAL_COMMON: `${STATE_REDUCER_KEY}/PATCH_MC_APPROVAL_COMMON`,
    PATCH_MC_APPROVAL_COMMON_REQUEST: `${STATE_REDUCER_KEY}/PATCH_MC_APPROVAL_COMMON_REQUEST`,
    PATCH_MC_APPROVAL_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_MC_APPROVAL_COMMON_SUCCESS`,
    PATCH_MC_APPROVAL_COMMON_FAILURE: `${STATE_REDUCER_KEY}/PATCH_MC_APPROVAL_COMMON_FAILURE`,

    PATCH_DPC_APPROVAL_COMMON: `${STATE_REDUCER_KEY}/PATCH_DPC_APPROVAL_COMMON`,
    PATCH_DPC_APPROVAL_COMMON_REQUEST: `${STATE_REDUCER_KEY}/PATCH_DPC_APPROVAL_COMMON_REQUEST`,
    PATCH_DPC_APPROVAL_COMMON_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_DPC_APPROVAL_COMMON_SUCCESS`,
    PATCH_DPC_APPROVAL_COMMON_FAILURE: `${STATE_REDUCER_KEY}/PATCH_DPC_APPROVAL_COMMON_FAILURE`,

    GET_PROJECT_LEVEL_DATA: `${STATE_REDUCER_KEY}/GET_PROJECT_LEVEL_DATA`,
    GET_PROJECT_LEVEL_DATA_REQUEST: `${STATE_REDUCER_KEY}/GET_PROJECT_LEVEL_DATA_REQUEST`,
    GET_PROJECT_LEVEL_DATA_SUCCESS: `${STATE_REDUCER_KEY}/GET_PROJECT_LEVEL_DATA_SUCCESS`,
    GET_PROJECT_LEVEL_DATA_FAILURE: `${STATE_REDUCER_KEY}/GET_PROJECT_LEVEL_DATA_FAILURE`,

    GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN: `${STATE_REDUCER_KEY}/GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN`,
    GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN_REQUEST`,
    GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN_SUCCESS`,
    GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN_FAILURE`,

    POST_PROJECT_LEVEL_DATA: `${STATE_REDUCER_KEY}/POST_PROJECT_LEVEL_DATA`,
    POST_PROJECT_LEVEL_DATA_REQUEST: `${STATE_REDUCER_KEY}/POST_PROJECT_LEVEL_DATA_REQUEST`,
    POST_PROJECT_LEVEL_DATA_SUCCESS: `${STATE_REDUCER_KEY}/POST_PROJECT_LEVEL_DATA_SUCCESS`,
    POST_PROJECT_LEVEL_DATA_FAILURE: `${STATE_REDUCER_KEY}/POST_PROJECT_LEVEL_DATA_FAILURE`,

    PATCH_PROJECT_LEVEL_DATA: `${STATE_REDUCER_KEY}/PATCH_PROJECT_LEVEL_DATA`,
    PATCH_PROJECT_LEVEL_DATA_REQUEST: `${STATE_REDUCER_KEY}/PATCH_PROJECT_LEVEL_DATA_REQUEST`,
    PATCH_PROJECT_LEVEL_DATA_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_PROJECT_LEVEL_DATA_SUCCESS`,
    PATCH_PROJECT_LEVEL_DATA_FAILURE: `${STATE_REDUCER_KEY}/PATCH_PROJECT_LEVEL_DATA_FAILURE`,

    GET_SAFEGUARD_COMPLIANCE: `${STATE_REDUCER_KEY}/GET_SAFEGUARD_COMPLIANCE`,
    GET_SAFEGUARD_COMPLIANCE_REQUEST: `${STATE_REDUCER_KEY}/GET_SAFEGUARD_COMPLIANCE_REQUEST`,
    GET_SAFEGUARD_COMPLIANCE_SUCCESS: `${STATE_REDUCER_KEY}/GET_SAFEGUARD_COMPLIANCE_SUCCESS`,
    GET_SAFEGUARD_COMPLIANCE_FAILURE: `${STATE_REDUCER_KEY}/GET_SAFEGUARD_COMPLIANCE_FAILURE`,

    POST_SAFEGUARD_COMPLIANCE: `${STATE_REDUCER_KEY}/POST_SAFEGUARD_COMPLIANCE`,
    POST_SAFEGUARD_COMPLIANCE_REQUEST: `${STATE_REDUCER_KEY}/POST_SAFEGUARD_COMPLIANCE_REQUEST`,
    POST_SAFEGUARD_COMPLIANCE_SUCCESS: `${STATE_REDUCER_KEY}/POST_SAFEGUARD_COMPLIANCE_SUCCESS`,
    POST_SAFEGUARD_COMPLIANCE_FAILURE: `${STATE_REDUCER_KEY}/POST_SAFEGUARD_COMPLIANCE_FAILURE`,

    PATCH_SAFEGUARD_COMPLIANCE: `${STATE_REDUCER_KEY}/PATCH_SAFEGUARD_COMPLIANCE`,
    PATCH_SAFEGUARD_COMPLIANCE_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SAFEGUARD_COMPLIANCE_REQUEST`,
    PATCH_SAFEGUARD_COMPLIANCE_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SAFEGUARD_COMPLIANCE_SUCCESS`,
    PATCH_SAFEGUARD_COMPLIANCE_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SAFEGUARD_COMPLIANCE_FAILURE`,

    GET_COMMUNITY_CONSULTATION: `${STATE_REDUCER_KEY}/GET_COMMUNITY_CONSULTATION`,
    GET_COMMUNITY_CONSULTATION_REQUEST: `${STATE_REDUCER_KEY}/GET_COMMUNITY_CONSULTATION_REQUEST`,
    GET_COMMUNITY_CONSULTATION_SUCCESS: `${STATE_REDUCER_KEY}/GET_COMMUNITY_CONSULTATION_SUCCESS`,
    GET_COMMUNITY_CONSULTATION_FAILURE: `${STATE_REDUCER_KEY}/GET_COMMUNITY_CONSULTATION_FAILURE`,

    POST_COMMUNITY_CONSULTATION: `${STATE_REDUCER_KEY}/POST_COMMUNITY_CONSULTATION`,
    POST_COMMUNITY_CONSULTATION_REQUEST: `${STATE_REDUCER_KEY}/POST_COMMUNITY_CONSULTATION_REQUEST`,
    POST_COMMUNITY_CONSULTATION_SUCCESS: `${STATE_REDUCER_KEY}/POST_COMMUNITY_CONSULTATION_SUCCESS`,
    POST_COMMUNITY_CONSULTATION_FAILURE: `${STATE_REDUCER_KEY}/POST_COMMUNITY_CONSULTATION_FAILURE`,

    PATCH_COMMUNITY_CONSULTATION: `${STATE_REDUCER_KEY}/PATCH_COMMUNITY_CONSULTATION`,
    PATCH_COMMUNITY_CONSULTATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_COMMUNITY_CONSULTATION_REQUEST`,
    PATCH_COMMUNITY_CONSULTATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_COMMUNITY_CONSULTATION_SUCCESS`,
    PATCH_COMMUNITY_CONSULTATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_COMMUNITY_CONSULTATION_FAILURE`,

    GET_TYPE_OF_ACTIVITY_DROP_DOWN: `${STATE_REDUCER_KEY}/GET_TYPE_OF_ACTIVITY_DROP_DOWN`,
    GET_TYPE_OF_ACTIVITY_DROP_DOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_TYPE_OF_ACTIVITY_DROP_DOWN_REQUEST`,
    GET_TYPE_OF_ACTIVITY_DROP_DOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_TYPE_OF_ACTIVITY_DROP_DOWN_SUCCESS`,
    GET_TYPE_OF_ACTIVITY_DROP_DOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_TYPE_OF_ACTIVITY_DROP_DOWN_FAILURE`,

    GET_PHASE_ONE_SCREENING_DROPDOWN: `${STATE_REDUCER_KEY}/GET_PHASE_ONE_SCREENING_DROPDOWN`,
    GET_PHASE_ONE_SCREENING_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_PHASE_ONE_SCREENING_DROPDOWN_REQUEST`,
    GET_PHASE_ONE_SCREENING_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_PHASE_ONE_SCREENING_DROPDOWN_SUCCESS`,
    GET_PHASE_ONE_SCREENING_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_PHASE_ONE_SCREENING_DROPDOWN_FAILURE`,

    GET_PHASE_TWO_SCREENING_DROPDOWN: `${STATE_REDUCER_KEY}/GET_PHASE_TWO_SCREENING_DROPDOWN`,
    GET_PHASE_TWO_SCREENING_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_PHASE_TWO_SCREENING_DROPDOWN_REQUEST`,
    GET_PHASE_TWO_SCREENING_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_PHASE_TWO_SCREENING_DROPDOWN_SUCCESS`,
    GET_PHASE_TWO_SCREENING_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_PHASE_TWO_SCREENING_DROPDOWN_FAILURE`,

    GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN: `${STATE_REDUCER_KEY}/GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN`,
    GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN_REQUEST`,
    GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN_SUCCESS`,
    GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN_FAILURE`,

    GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN: `${STATE_REDUCER_KEY}/GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN`,
    GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN_REQUEST`,
    GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN_SUCCESS`,
    GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN_FAILURE`,

    GET_ESG_INSTRUMENT_DROPDOWN: `${STATE_REDUCER_KEY}/GET_ESG_INSTRUMENT_DROPDOWN`,
    GET_ESG_INSTRUMENT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_ESG_INSTRUMENT_DROPDOWN_REQUEST`,
    GET_ESG_INSTRUMENT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_ESG_INSTRUMENT_DROPDOWN_SUCCESS`,
    GET_ESG_INSTRUMENT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_ESG_INSTRUMENT_DROPDOWN_FAILURE`,

    GET_DUE_DILIGENCE_DROPDOWN: `${STATE_REDUCER_KEY}/GET_DUE_DILIGENCE_DROPDOWN`,
    GET_DUE_DILIGENCE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_DUE_DILIGENCE_DROPDOWN_REQUEST`,
    GET_DUE_DILIGENCE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_DUE_DILIGENCE_DROPDOWN_SUCCESS`,
    GET_DUE_DILIGENCE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_DUE_DILIGENCE_DROPDOWN_FAILURE`,

    GET_DUE_DILIGENCE_TYPE_DROPDOWN: `${STATE_REDUCER_KEY}/GET_DUE_DILIGENCE_TYPE_DROPDOWN`,
    GET_DUE_DILIGENCE_TYPE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_DUE_DILIGENCE_TYPE_DROPDOWN_REQUEST`,
    GET_DUE_DILIGENCE_TYPE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_DUE_DILIGENCE_TYPE_DROPDOWN_SUCCESS`,
    GET_DUE_DILIGENCE_TYPE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_DUE_DILIGENCE_TYPE_DROPDOWN_FAILURE`,

    GET_REASON_UNDER_EIA_DROPDOWN: `${STATE_REDUCER_KEY}/GET_REASON_UNDER_EIA_DROPDOWN`,
    GET_REASON_UNDER_EIA_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_REASON_UNDER_EIA_DROPDOWN_REQUEST`,
    GET_REASON_UNDER_EIA_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_REASON_UNDER_EIA_DROPDOWN_SUCCESS`,
    GET_REASON_UNDER_EIA_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_REASON_UNDER_EIA_DROPDOWN_FAILURE`,

    GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN: `${STATE_REDUCER_KEY}/GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN`,
    GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN_REQUEST`,
    GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN_SUCCESS`,
    GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN_FAILURE`,

    GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN: `${STATE_REDUCER_KEY}/GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN`,
    GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN_REQUEST`,
    GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN_SUCCESS`,
    GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN_FAILURE`,

    GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN: `${STATE_REDUCER_KEY}/GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN`,
    GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN_REQUEST`,
    GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN_SUCCESS`,
    GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN_FAILURE`,

    GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN: `${STATE_REDUCER_KEY}/GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN`,
    GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN_REQUEST`,
    GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN_SUCCESS`,
    GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN_FAILURE`,

    GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN: `${STATE_REDUCER_KEY}/GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN`,
    GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN_REQUEST`,
    GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN_SUCCESS`,
    GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN_FAILURE`,

    GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID`,
    GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_REQUEST`,
    GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_SUCCESS`,
    GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_FAILURE`,

    GET_ENVIRONMENTAL_PROJECT_DATA_LIST: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_PROJECT_DATA_LIST`,
    GET_ENVIRONMENTAL_PROJECT_DATA_LIST_REQUEST: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_PROJECT_DATA_LIST_REQUEST`,
    GET_ENVIRONMENTAL_PROJECT_DATA_LIST_SUCCESS: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_PROJECT_DATA_LIST_SUCCESS`,
    GET_ENVIRONMENTAL_PROJECT_DATA_LIST_FAILURE: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_PROJECT_DATA_LIST_FAILURE`,

    POST_ENVIRONMENTAL_PROJECT_DATA: `${STATE_REDUCER_KEY}/POST_ENVIRONMENTAL_PROJECT_DATA`,
    POST_ENVIRONMENTAL_PROJECT_DATA_REQUEST: `${STATE_REDUCER_KEY}/POST_ENVIRONMENTAL_PROJECT_DATA_REQUEST`,
    POST_ENVIRONMENTAL_PROJECT_DATA_SUCCESS: `${STATE_REDUCER_KEY}/POST_ENVIRONMENTAL_PROJECT_DATA_SUCCESS`,
    POST_ENVIRONMENTAL_PROJECT_DATA_FAILURE: `${STATE_REDUCER_KEY}/POST_ENVIRONMENTAL_PROJECT_DATA_FAILURE`,

    PATCH_ENVIRONMENTAL_PROJECT_DATA: `${STATE_REDUCER_KEY}/PATCH_ENVIRONMENTAL_PROJECT_DATA`,
    PATCH_ENVIRONMENTAL_PROJECT_DATA_REQUEST: `${STATE_REDUCER_KEY}/PATCH_ENVIRONMENTAL_PROJECT_DATA_REQUEST`,
    PATCH_ENVIRONMENTAL_PROJECT_DATA_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_ENVIRONMENTAL_PROJECT_DATA_SUCCESS`,
    PATCH_ENVIRONMENTAL_PROJECT_DATA_FAILURE: `${STATE_REDUCER_KEY}/PATCH_ENVIRONMENTAL_PROJECT_DATA_FAILURE`,

    GET_TYPES_OF_WASTE_MANAGED_DROPDOWN: `${STATE_REDUCER_KEY}/GET_TYPES_OF_WASTE_MANAGED_DROPDOWN`,
    GET_TYPES_OF_WASTE_MANAGED_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_TYPES_OF_WASTE_MANAGED_DROPDOWN_REQUEST`,
    GET_TYPES_OF_WASTE_MANAGED_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_TYPES_OF_WASTE_MANAGED_DROPDOWN_SUCCESS`,
    GET_TYPES_OF_WASTE_MANAGED_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_TYPES_OF_WASTE_MANAGED_DROPDOWN_FAILURE`,

    GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN: `${STATE_REDUCER_KEY}/GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN`,
    GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN_REQUEST`,
    GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN_SUCCESS`,
    GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN_FAILURE`,

    GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN: `${STATE_REDUCER_KEY}/GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN`,
    GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN_REQUEST`,
    GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN_SUCCESS`,
    GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN_FAILURE`,

    GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN: `${STATE_REDUCER_KEY}/GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN`,
    GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN_REQUEST`,
    GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN_SUCCESS`,
    GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN_FAILURE`,

    GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN: `${STATE_REDUCER_KEY}/GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN`,
    GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN_REQUEST`,
    GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN_SUCCESS`,
    GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN_FAILURE`,

    GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN: `${STATE_REDUCER_KEY}/GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN`,
    GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN_REQUEST`,
    GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN_SUCCESS`,
    GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN_FAILURE`,

    GET_ESG_ISSUE_AFFECT_DROPDOWN: `${STATE_REDUCER_KEY}/GET_ESG_ISSUE_AFFECT_DROPDOWN`,
    GET_ESG_ISSUE_AFFECT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_ESG_ISSUE_AFFECT_DROPDOWN_REQUEST`,
    GET_ESG_ISSUE_AFFECT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_ESG_ISSUE_AFFECT_DROPDOWN_SUCCESS`,
    GET_ESG_ISSUE_AFFECT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_ESG_ISSUE_AFFECT_DROPDOWN_FAILURE`,

    GET_PERMITS_CONSENT_REQUIRED_DROPDOWN: `${STATE_REDUCER_KEY}/GET_PERMITS_CONSENT_REQUIRED_DROPDOWN`,
    GET_PERMITS_CONSENT_REQUIRED_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_PERMITS_CONSENT_REQUIRED_DROPDOWN_REQUEST`,
    GET_PERMITS_CONSENT_REQUIRED_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_PERMITS_CONSENT_REQUIRED_DROPDOWN_SUCCESS`,
    GET_PERMITS_CONSENT_REQUIRED_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_PERMITS_CONSENT_REQUIRED_DROPDOWN_FAILURE`,

    GET_INCIDENT_CLASSIFICATION_DROPDOWN: `${STATE_REDUCER_KEY}/GET_INCIDENT_CLASSIFICATION_DROPDOWN`,
    GET_INCIDENT_CLASSIFICATION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_INCIDENT_CLASSIFICATION_DROPDOWN_REQUEST`,
    GET_INCIDENT_CLASSIFICATION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_INCIDENT_CLASSIFICATION_DROPDOWN_SUCCESS`,
    GET_INCIDENT_CLASSIFICATION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_INCIDENT_CLASSIFICATION_DROPDOWN_FAILURE`,

    GET_STATUS_OF_COMPENSATION_DROPDOWN: `${STATE_REDUCER_KEY}/GET_STATUS_OF_COMPENSATION_DROPDOWN`,
    GET_STATUS_OF_COMPENSATION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_STATUS_OF_COMPENSATION_DROPDOWN_REQUEST`,
    GET_STATUS_OF_COMPENSATION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_STATUS_OF_COMPENSATION_DROPDOWN_SUCCESS`,
    GET_STATUS_OF_COMPENSATION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_STATUS_OF_COMPENSATION_DROPDOWN_FAILURE`,

    GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN: `${STATE_REDUCER_KEY}/GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN`,
    GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN_REQUEST`,
    GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN_SUCCESS`,
    GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN_FAILURE`,

    GET_ATTENDEE_PROFILE_DROPDOWN: `${STATE_REDUCER_KEY}/GET_ATTENDEE_PROFILE_DROPDOWN`,
    GET_ATTENDEE_PROFILE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_ATTENDEE_PROFILE_DROPDOWN_REQUEST`,
    GET_ATTENDEE_PROFILE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_ATTENDEE_PROFILE_DROPDOWN_SUCCESS`,
    GET_ATTENDEE_PROFILE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_ATTENDEE_PROFILE_DROPDOWN_FAILURE`,

    GET_RESPONSIBLE_AGENCY_DROPDOWN: `${STATE_REDUCER_KEY}/GET_RESPONSIBLE_AGENCY_DROPDOWN`,
    GET_RESPONSIBLE_AGENCY_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_RESPONSIBLE_AGENCY_DROPDOWN_REQUEST`,
    GET_RESPONSIBLE_AGENCY_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_RESPONSIBLE_AGENCY_DROPDOWN_SUCCESS`,
    GET_RESPONSIBLE_AGENCY_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_RESPONSIBLE_AGENCY_DROPDOWN_FAILURE`,

    GET_STATUS_OF_PERMIT_DROPDOWN: `${STATE_REDUCER_KEY}/GET_STATUS_OF_PERMIT_DROPDOWN`,
    GET_STATUS_OF_PERMIT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_STATUS_OF_PERMIT_DROPDOWN_REQUEST`,
    GET_STATUS_OF_PERMIT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_STATUS_OF_PERMIT_DROPDOWN_SUCCESS`,
    GET_STATUS_OF_PERMIT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_STATUS_OF_PERMIT_DROPDOWN_FAILURE`,

    POST_ENVIRONMENTAL_COMPENDIUM_DATA: `${STATE_REDUCER_KEY}/POST_ENVIRONMENTAL_COMPENDIUM_DATA`,
    POST_ENVIRONMENTAL_COMPENDIUM_DATA_REQUEST: `${STATE_REDUCER_KEY}/POST_ENVIRONMENTAL_COMPENDIUM_DATA_REQUEST`,
    POST_ENVIRONMENTAL_COMPENDIUM_DATA_SUCCESS: `${STATE_REDUCER_KEY}/POST_ENVIRONMENTAL_COMPENDIUM_DATA_SUCCESS`,
    POST_ENVIRONMENTAL_COMPENDIUM_DATA_FAILURE: `${STATE_REDUCER_KEY}/POST_ENVIRONMENTAL_COMPENDIUM_DATA_FAILURE`,

    PATCH_ENVIRONMENTAL_COMPENDIUM_DATA: `${STATE_REDUCER_KEY}/PATCH_ENVIRONMENTAL_COMPENDIUM_DATA`,
    PATCH_ENVIRONMENTAL_COMPENDIUM_DATA_REQUEST: `${STATE_REDUCER_KEY}/PATCH_ENVIRONMENTAL_COMPENDIUM_DATA_REQUEST`,
    PATCH_ENVIRONMENTAL_COMPENDIUM_DATA_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_ENVIRONMENTAL_COMPENDIUM_DATA_SUCCESS`,
    PATCH_ENVIRONMENTAL_COMPENDIUM_DATA_FAILURE: `${STATE_REDUCER_KEY}/PATCH_ENVIRONMENTAL_COMPENDIUM_DATA_FAILURE`,

    GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID`,
    GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_REQUEST`,
    GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_SUCCESS`,
    GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_FAILURE`,

    GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL`,
    GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_REQUEST: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_REQUEST`,
    GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_SUCCESS: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_SUCCESS`,
    GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_FAILURE: `${STATE_REDUCER_KEY}/GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_FAILURE`,

    GET_FINANCIAL_YEAR_DROP_DOWN: `${STATE_REDUCER_KEY}/GET_FINANCIAL_YEAR_DROP_DOWN`,
    GET_FINANCIAL_YEAR_DROP_DOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_FINANCIAL_YEAR_DROP_DOWN_REQUEST`,
    GET_FINANCIAL_YEAR_DROP_DOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_FINANCIAL_YEAR_DROP_DOWN_SUCCESS`,
    GET_FINANCIAL_YEAR_DROP_DOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_FINANCIAL_YEAR_DROP_DOWN_FAILURE`,

    GET_SULEKHA_PROJECT_SAVE: `${STATE_REDUCER_KEY}/GET_SULEKHA_PROJECT_SAVE`,
    GET_SULEKHA_PROJECT_SAVE_REQUEST: `${STATE_REDUCER_KEY}/GET_SULEKHA_PROJECT_SAVE_REQUEST`,
    GET_SULEKHA_PROJECT_SAVE_SUCCESS: `${STATE_REDUCER_KEY}/GET_SULEKHA_PROJECT_SAVE_SUCCESS`,
    GET_SULEKHA_PROJECT_SAVE_FAILURE: `${STATE_REDUCER_KEY}/GET_SULEKHA_PROJECT_SAVE_FAILURE`,

    GET_SULEKHA_PROJECT_DROP_DOWN: `${STATE_REDUCER_KEY}/GET_SULEKHA_PROJECT_DROP_DOWN`,
    GET_SULEKHA_PROJECT_DROP_DOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_SULEKHA_PROJECT_DROP_DOWN_REQUEST`,
    GET_SULEKHA_PROJECT_DROP_DOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_SULEKHA_PROJECT_DROP_DOWN_SUCCESS`,
    GET_SULEKHA_PROJECT_DROP_DOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_SULEKHA_PROJECT_DROP_DOWN_FAILURE`,

    FETCH_SULEKHA_PROJECT: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT`,
    FETCH_SULEKHA_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT_REQUEST`,
    FETCH_SULEKHA_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT_SUCCESS`,
    FETCH_SULEKHA_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT_FAILURE`,

    FETCH_SULEKHA_PROJECT_LIST_ALL: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT_LIST_ALL`,
    FETCH_SULEKHA_PROJECT_LIST_ALL_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT_LIST_ALL_REQUEST`,
    FETCH_SULEKHA_PROJECT_LIST_ALL_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT_LIST_ALL_SUCCESS`,
    FETCH_SULEKHA_PROJECT_LIST_ALL_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT_LIST_ALL_FAILURE`,

    FETCH_SULEKHA_PROJECT_BY_ID: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT_BY_ID`,
    FETCH_SULEKHA_PROJECT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT_BY_ID_REQUEST`,
    FETCH_SULEKHA_PROJECT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT_BY_ID_SUCCESS`,
    FETCH_SULEKHA_PROJECT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SULEKHA_PROJECT_BY_ID_FAILURE`,

    REVERT_SULEKHA_PROJECT: `${STATE_REDUCER_KEY}/REVERT_SULEKHA_PROJECT`,
    REVERT_SULEKHA_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/REVERT_SULEKHA_PROJECT_REQUEST`,
    REVERT_SULEKHA_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/REVERT_SULEKHA_PROJECT_SUCCESS`,
    REVERT_SULEKHA_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/REVERT_SULEKHA_PROJECT_FAILURE`,

    DOWNLOAD_SWM_SUB_PROJECT: `${STATE_REDUCER_KEY}/DOWNLOAD_SWM_SUB_PROJECT`,
    DOWNLOAD_SWM_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_SWM_SUB_PROJECT_REQUEST`,
    DOWNLOAD_SWM_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_SWM_SUB_PROJECT_SUCCESS`,
    DOWNLOAD_SWM_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_SWM_SUB_PROJECT_FAILURE`,

    GET_PROJECT_CODE_DROPDOWN: `${STATE_REDUCER_KEY}/GET_PROJECT_CODE_DROPDOWN`,
    GET_PROJECT_CODE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_PROJECT_CODE_DROPDOWN_REQUEST`,
    GET_PROJECT_CODE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_PROJECT_CODE_DROPDOWN_SUCCESS`,
    GET_PROJECT_CODE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_PROJECT_CODE_DROPDOWN_FAILURE`,

    POST_FACILITIES_AND_LOCATION: `${STATE_REDUCER_KEY}/POST_FACILITIES_AND_LOCATION`,
    POST_FACILITIES_AND_LOCATION_REQUEST: `${STATE_REDUCER_KEY}/POST_FACILITIES_AND_LOCATION_REQUEST`,
    POST_FACILITIES_AND_LOCATION_SUCCESS: `${STATE_REDUCER_KEY}/POST_FACILITIES_AND_LOCATION_SUCCESS`,
    POST_FACILITIES_AND_LOCATION_FAILURE: `${STATE_REDUCER_KEY}/POST_FACILITIES_AND_LOCATION_FAILURE`,

    FETCH_FACILITIES_AND_LOCATION_LIST: `${STATE_REDUCER_KEY}/FETCH_FACILITIES_AND_LOCATION_LIST`,
    FETCH_FACILITIES_AND_LOCATION_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FACILITIES_AND_LOCATION_LIST_REQUEST`,
    FETCH_FACILITIES_AND_LOCATION_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FACILITIES_AND_LOCATION_LIST_SUCCESS`,
    FETCH_FACILITIES_AND_LOCATION_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FACILITIES_AND_LOCATION_LIST_FAILURE`,

    GET_FACILITIES_AND_LOCATION: `${STATE_REDUCER_KEY}/GET_FACILITIES_AND_LOCATION`,
    GET_FACILITIES_AND_LOCATION_REQUEST: `${STATE_REDUCER_KEY}/GET_FACILITIES_AND_LOCATION_REQUEST`,
    GET_FACILITIES_AND_LOCATION_SUCCESS: `${STATE_REDUCER_KEY}/GET_FACILITIES_AND_LOCATION_SUCCESS`,
    GET_FACILITIES_AND_LOCATION_FAILURE: `${STATE_REDUCER_KEY}/GET_FACILITIES_AND_LOCATION_FAILURE`,

    DELETE_FACILITIES_AND_LOCATION: `${STATE_REDUCER_KEY}/DELETE_FACILITIES_AND_LOCATION`,
    DELETE_FACILITIES_AND_LOCATION_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FACILITIES_AND_LOCATION_REQUEST`,
    DELETE_FACILITIES_AND_LOCATION_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FACILITIES_AND_LOCATION_SUCCESS`,
    DELETE_FACILITIES_AND_LOCATION_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FACILITIES_AND_LOCATION_FAILURE`,

    GET_APPROVAL_STATUS_DROPDOWN: `${STATE_REDUCER_KEY}/GET_APPROVAL_STATUS_DROPDOWN`,
    GET_APPROVAL_STATUS_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_APPROVAL_STATUS_DROPDOWN_REQUEST`,
    GET_APPROVAL_STATUS_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_APPROVAL_STATUS_DROPDOWN_SUCCESS`,
    GET_APPROVAL_STATUS_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_APPROVAL_STATUS_DROPDOWN_FAILURE`,

    SOCIAL_RISK_CATEGORIZATION_DROPDOWN: `${STATE_REDUCER_KEY}/SOCIAL_RISK_CATEGORIZATION_DROPDOWN`,
    SOCIAL_RISK_CATEGORIZATION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SOCIAL_RISK_CATEGORIZATION_DROPDOWN_REQUEST`,
    SOCIAL_RISK_CATEGORIZATION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SOCIAL_RISK_CATEGORIZATION_DROPDOWN_SUCCESS`,
    SOCIAL_RISK_CATEGORIZATION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SOCIAL_RISK_CATEGORIZATION_DROPDOWN_FAILURE`,

    SAVE_SOCIAL_RISK_CATEGORIZATION: `${STATE_REDUCER_KEY}/SAVE_SOCIAL_RISK_CATEGORIZATION`,
    SAVE_SOCIAL_RISK_CATEGORIZATION_REQUEST: `${STATE_REDUCER_KEY}/SAVE_SOCIAL_RISK_CATEGORIZATION_REQUEST`,
    SAVE_SOCIAL_RISK_CATEGORIZATION_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_SOCIAL_RISK_CATEGORIZATION_SUCCESS`,
    SAVE_SOCIAL_RISK_CATEGORIZATION_FAILURE: `${STATE_REDUCER_KEY}/SAVE_SOCIAL_RISK_CATEGORIZATION_FAILURE`,

    GET_SOCIAL_RISK_CATEGORIZATION: `${STATE_REDUCER_KEY}/GET_SOCIAL_RISK_CATEGORIZATION`,
    GET_SOCIAL_RISK_CATEGORIZATION_REQUEST: `${STATE_REDUCER_KEY}/GET_SOCIAL_RISK_CATEGORIZATION_REQUEST`,
    GET_SOCIAL_RISK_CATEGORIZATION_SUCCESS: `${STATE_REDUCER_KEY}/GET_SOCIAL_RISK_CATEGORIZATION_SUCCESS`,
    GET_SOCIAL_RISK_CATEGORIZATION_FAILURE: `${STATE_REDUCER_KEY}/GET_SOCIAL_RISK_CATEGORIZATION_FAILURE`

};
export const fetchSwmSubProjectsList = createAction(ACTION_TYPES.LIST_SWM_SUB_PROJECT);
export const deleteSwmSubProjects = createAction(ACTION_TYPES.DELETE_SWM_SUB_PROJECT_PROJECT);
export const fetchSwmSubProjectsListActivities = createAction(ACTION_TYPES.LIST_SWM_SUB_PROJECT_ACTIVITIES);
export const createProjectsActivity = createAction(ACTION_TYPES.CREATE_ACTIVITY);
export const updateProjectsActivity = createAction(ACTION_TYPES.UPDATE_ACTIVITY);
export const getProjectActivityById = createAction(ACTION_TYPES.FETCH_PROJECT_ACTIVITY_BY_ID);
export const getUomList = createAction(ACTION_TYPES.UOM_LIST);
export const deleteProjectActivity = createAction(ACTION_TYPES.DELETE_PROJECT_ACTIVITY);
export const deleteAttachment = createAction(ACTION_TYPES.DELETE_ATTACHMENT);
export const submitSwmProject = createAction(ACTION_TYPES.SUBMIT_SWM_SUB_PROJECT);
export const saveSwmScreening = createAction(ACTION_TYPES.POST_SWM_SCREENING_DATA);
export const updateSwmScreening = createAction(ACTION_TYPES.PATCH_SWM_SCREENING_DATA);
export const fetchSwmScreeningById = createAction(ACTION_TYPES.FETCH_SWM_SCREENING_DATA);
export const saveGroupDiscussion = createAction(ACTION_TYPES.POST_SWM_SUB_PROJECT_GROUP_DISCUSSION);
export const fetchGroupDiscussion = createAction(ACTION_TYPES.FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION);
export const saveHscApproval = createAction(ACTION_TYPES.POST_SWM_HSC_APPROVAL);
export const fetchAdpProcess = createAction(ACTION_TYPES.FETCH_SWM_ADP_PROCESS);
export const saveMcApproval = createAction(ACTION_TYPES.POST_SWM_MC_APPROVAL);
export const updateGroupDiscussion = createAction(ACTION_TYPES.PATCH_SWM_SUB_PROJECT_GROUP_DISCUSSION);
export const saveVikasanaSeminar = createAction(ACTION_TYPES.POST_SWM_SUB_PROJECT_VIKASANA_SEMINAR);
export const fetchVikasanaSeminar = createAction(ACTION_TYPES.FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR);
export const updateVikasanaSeminar = createAction(ACTION_TYPES.PATCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR);
export const saveDpcApproval = createAction(ACTION_TYPES.POST_SWM_DPC_APPROVAL);
export const fetchSafeGuard = createAction(ACTION_TYPES.FETCH_SAFE_GUARD);
export const postSafeGuard = createAction(ACTION_TYPES.POST_SAFE_GUARD);
export const updateSafeGuard = createAction(ACTION_TYPES.PATCH_SAFE_GUARD);
export const remarkDropdown = createAction(ACTION_TYPES.REMARK_DROPDOWN);
export const saveEnvironmentalSafeguard = createAction(ACTION_TYPES.POST_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD);
export const updateEnvironmentalSafeguard = createAction(ACTION_TYPES.PATCH_SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD);
export const fetchEnvironmentSafeguardByid = createAction(ACTION_TYPES.SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID);
export const listSocialSafeGuard = createAction(ACTION_TYPES.LIST_SOCIAL_SAFEGUARD);
export const listAnnualPlanYear = createAction(ACTION_TYPES.LIST_ANNUAL_PLAN_YEAR);
export const saveWorkingGroupDiscussionCommon = createAction(ACTION_TYPES.SAVE_WORKING_GROUP_DISCUSSION_COMMON);
export const saveVikasanaSeminarCommon = createAction(ACTION_TYPES.SAVE_VIKASANA_SEMINAR_COMMON);
export const listAllVikasanaSeminarCommon = createAction(ACTION_TYPES.LIST_ALL_VIKASANA_SEMINAR_COMMON);
export const vikasanaSeminarCommonById = createAction(ACTION_TYPES.VIKASANA_SEMINAR_COMMON_BY_ID);
export const updateVikasanaSeminarCommon = createAction(ACTION_TYPES.PATCH_VIKASANA_SEMINAR_COMMON);
export const listAllWorkingGroupDiscussion = createAction(ACTION_TYPES.LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON);
export const workingGroupDiscussionById = createAction(ACTION_TYPES.WORKING_GROUP_DISCUSSION_COMMON_BY_ID);
export const saveHscApprovalCommon = createAction(ACTION_TYPES.SAVE_HSC_APPROVAL_COMMON);
export const listAllHscApprovalCommon = createAction(ACTION_TYPES.LIST_ALL_HSC_APPROVAL_COMMON);
export const hscApprovalCommonById = createAction(ACTION_TYPES.HSC_APPROVAL_COMMON_BY_ID);
export const mcApprovalCommonById = createAction(ACTION_TYPES.MC_APPROVAL_COMMON_BY_ID);
export const listAllMcApprovalCommon = createAction(ACTION_TYPES.LIST_ALL_MC_APPROVAL_COMMON);
export const saveMcApprovalCommon = createAction(ACTION_TYPES.SAVE_MC_APPROVAL_COMMON);
export const saveDpcApprovalCommon = createAction(ACTION_TYPES.SAVE_DPC_APPROVAL_COMMON);
export const listAllDpcApprovalCommon = createAction(ACTION_TYPES.LIST_ALL_DPC_APPROVAL_COMMON);
export const listAllSwmSubProjectScreening = createAction(ACTION_TYPES.LIST_ALL_SWM_SCREENING);
export const listAllSwmGroupDiscussion = createAction(ACTION_TYPES.LIST_ALL_GROUP_DISCUSSION);
export const listAllSwmVikasanaSeminar = createAction(ACTION_TYPES.LIST_ALL_VIKASANA_SEMINAR);
export const listAllApdProcess = createAction(ACTION_TYPES.LIST_ALL_APD_PROCESS);
export const listAllFinancial = createAction(ACTION_TYPES.LIST_ALL_FINANCIAL_SANCTION);
export const updateMcApproval = createAction(ACTION_TYPES.PATCH_SWM_MC_APPROVAL);
export const updateHscApproval = createAction(ACTION_TYPES.PATCH_SWM_HSC_APPROVAL);
export const updateDpcApproval = createAction(ACTION_TYPES.PATCH_SWM_DPC_APPROVAL);
export const updateMcApprovalCommon = createAction(ACTION_TYPES.PATCH_MC_APPROVAL_COMMON);
export const updateDpcApprovalCommon = createAction(ACTION_TYPES.PATCH_DPC_APPROVAL_COMMON);
export const getProjectLevelData = createAction(ACTION_TYPES.GET_PROJECT_LEVEL_DATA);
export const getProjectSocialSafeguardStatusDropdown = createAction(ACTION_TYPES.GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN);
export const postProjectLevelData = createAction(ACTION_TYPES.POST_PROJECT_LEVEL_DATA);
export const patchProjectLevelData = createAction(ACTION_TYPES.PATCH_PROJECT_LEVEL_DATA);
export const getSafeguardCompliance = createAction(ACTION_TYPES.GET_SAFEGUARD_COMPLIANCE);
export const postSafeguardCompliance = createAction(ACTION_TYPES.POST_SAFEGUARD_COMPLIANCE);
export const patchSafeguardCompliance = createAction(ACTION_TYPES.PATCH_SAFEGUARD_COMPLIANCE);
export const getCommunityConsultation = createAction(ACTION_TYPES.GET_COMMUNITY_CONSULTATION);
export const postCommunityConsultation = createAction(ACTION_TYPES.POST_COMMUNITY_CONSULTATION);
export const patchCommunityConsultation = createAction(ACTION_TYPES.PATCH_COMMUNITY_CONSULTATION);
export const getTypeofActivity = createAction(ACTION_TYPES.GET_TYPE_OF_ACTIVITY_DROP_DOWN);
export const getPhaseOneScreening = createAction(ACTION_TYPES.GET_PHASE_ONE_SCREENING_DROPDOWN);
export const getPhaseTwoScreening = createAction(ACTION_TYPES.GET_PHASE_TWO_SCREENING_DROPDOWN);
export const getEsgScreeningResponsibility = createAction(ACTION_TYPES.GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN);
export const getEnvironmentalCategory = createAction(ACTION_TYPES.GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN);
export const getEsgInstrument = createAction(ACTION_TYPES.GET_ESG_INSTRUMENT_DROPDOWN);
export const getDueDiligence = createAction(ACTION_TYPES.GET_DUE_DILIGENCE_DROPDOWN);
export const getDueDiligenceType = createAction(ACTION_TYPES.GET_DUE_DILIGENCE_TYPE_DROPDOWN);
export const getReasonUnderEia = createAction(ACTION_TYPES.GET_REASON_UNDER_EIA_DROPDOWN);
export const getTypeDueDiligenceRequired = createAction(ACTION_TYPES.GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN);
export const getEsgDocumentPreparationResponsibility = createAction(ACTION_TYPES.GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN);
export const getFinalEsgPreparedAndSubmitted = createAction(ACTION_TYPES.GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN);
export const getFinalEsgApprovalResponsibility = createAction(ACTION_TYPES.GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN);
export const getResponsibilityForEsgSupervision = createAction(ACTION_TYPES.GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN);
export const getEnvironmentalProjectDataById = createAction(ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID);
export const postEnvironmentalProjectData = createAction(ACTION_TYPES.POST_ENVIRONMENTAL_PROJECT_DATA);
export const patchEnvironmentalProjectData = createAction(ACTION_TYPES.PATCH_ENVIRONMENTAL_PROJECT_DATA);
export const getTypesOfWasteManaged = createAction(ACTION_TYPES.GET_TYPES_OF_WASTE_MANAGED_DROPDOWN);
export const getTypeOfManagementOfWaste = createAction(ACTION_TYPES.GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN);
export const getArrangementOfRecycling = createAction(ACTION_TYPES.GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN);
export const getCategoryOfEddIssue = createAction(ACTION_TYPES.GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN);
export const getSourceOfEddIdentification = createAction(ACTION_TYPES.GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN);
export const getStatusOfEddIssueResolution = createAction(ACTION_TYPES.GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN);
export const getEnvironmentalProjectDataList = createAction(ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_LIST);
export const getEsgIssueAffect = createAction(ACTION_TYPES.GET_ESG_ISSUE_AFFECT_DROPDOWN);
export const getPermitsConsentsRequired = createAction(ACTION_TYPES.GET_PERMITS_CONSENT_REQUIRED_DROPDOWN);
export const getIncidentClassification = createAction(ACTION_TYPES.GET_INCIDENT_CLASSIFICATION_DROPDOWN);
export const getStatusOfCompensation = createAction(ACTION_TYPES.GET_STATUS_OF_COMPENSATION_DROPDOWN);
export const getIncidentImplementation = createAction(ACTION_TYPES.GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN);
export const getAttendeeProfile = createAction(ACTION_TYPES.GET_ATTENDEE_PROFILE_DROPDOWN);
export const responsibleAgency = createAction(ACTION_TYPES.GET_RESPONSIBLE_AGENCY_DROPDOWN);
export const getStatusOfPermit = createAction(ACTION_TYPES.GET_STATUS_OF_PERMIT_DROPDOWN);
export const postEnvironmentalCompendiumData = createAction(ACTION_TYPES.POST_ENVIRONMENTAL_COMPENDIUM_DATA);
export const patchEnvironmentalCompendiumData = createAction(ACTION_TYPES.PATCH_ENVIRONMENTAL_COMPENDIUM_DATA);
export const getEnvironmentalCompendiumDataById = createAction(ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID);
export const getEnvironmentalCompendiumListAll = createAction(ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL);
export const getFinancialYearDropdown = createAction(ACTION_TYPES.GET_FINANCIAL_YEAR_DROP_DOWN);
export const getSulekhaProjectDropDown = createAction(ACTION_TYPES.GET_SULEKHA_PROJECT_DROP_DOWN);
export const saveSulekhaProject = createAction(ACTION_TYPES.GET_SULEKHA_PROJECT_SAVE);
export const fetchSulekhaProject = createAction(ACTION_TYPES.FETCH_SULEKHA_PROJECT);
export const fetchSulekhaProjectListAll = createAction(ACTION_TYPES.FETCH_SULEKHA_PROJECT_LIST_ALL);
export const fetchSulekhaProjectById = createAction(ACTION_TYPES.FETCH_SULEKHA_PROJECT_BY_ID);
export const revertSulekhaProject = createAction(ACTION_TYPES.REVERT_SULEKHA_PROJECT);
export const downloadSwmSubProject = createAction(ACTION_TYPES.DOWNLOAD_SWM_SUB_PROJECT);
export const getProjectCodeDropdown = createAction(ACTION_TYPES.GET_PROJECT_CODE_DROPDOWN);
export const postFacilitiesAndLocation = createAction(ACTION_TYPES.POST_FACILITIES_AND_LOCATION);
export const fetchFacilitiesAndLocation = createAction(ACTION_TYPES.FETCH_FACILITIES_AND_LOCATION_LIST);
export const getFacilitiesAndLocation = createAction(ACTION_TYPES.GET_FACILITIES_AND_LOCATION);
export const deleteFacilitiesAndLocation = createAction(ACTION_TYPES.DELETE_FACILITIES_AND_LOCATION);
export const getApprovalStatusDropdown = createAction(ACTION_TYPES.GET_APPROVAL_STATUS_DROPDOWN);
export const getSocialRiskCategorization = createAction(ACTION_TYPES.SOCIAL_RISK_CATEGORIZATION_DROPDOWN);
export const saveSocialRiskCategorization = createAction(ACTION_TYPES.SAVE_SOCIAL_RISK_CATEGORIZATION);
export const getSocialRiskCategorizationData = createAction(ACTION_TYPES.GET_SOCIAL_RISK_CATEGORIZATION);
