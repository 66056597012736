import React, { lazy } from "react";
import CheckboxDetails from "./form/components/CheckboxDetails";

const Form = lazy(() => import("./form/components/UserDetails"));
const SampleTable = lazy(() => import("./form/components/DashboardTable"));
const VirtualisedTable = lazy(() => import("./form/components/VirtualisedTable"));

const routes = [
    {
        title: "form",
        path: "form",
        element: <Form />
    },
    {
        title: "Example Table",
        path: "example-table",
        element: <SampleTable />
    },
    {
        path: "example-table/create",
        element: <Form />
    },
    {
        title: "Example Table Virtualised",
        path: "example-table-virtualised",
        element: <VirtualisedTable />
    },
    {
        title: "Example Table check",
        path: "example-table-check",
        element: <CheckboxDetails/>
    }
];

export { routes };
