import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadContentDetails, getPayloadContent } from "utils/apiUtils";

import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const LSGI_DETAILS = {
  name: "",
  lsgiTypeId: "",
  districtId: "",
  blockPanchayathId: "",
  districtPanchayathId: "",
  code: ""
};

const initialState = {
  lsgiDetails: {
    requestInProgress: false,
    data: {
      ...LSGI_DETAILS
    }
  },
  districtDropdown: {
    requestInProgress: false,
    data: []
  },
  lsgiTypeDropdown: {
    requestInProgress: false,
    data: []
  },
  blockPanchayathDropdown: {
    requestInProgress: false,
    data: []
  },
  districtPanchayathDropdown: {
    requestInProgress: false,
    data: []
  },
  updateResourceManagement: {
    requestInProgress: false,
    data: []
  },
  nameList: {
    requestInProgress: false,
    data: []
  },
  codeList: {
    requestInProgress: false,
    data: []
  }
};

const slice = createSlice({
  initialState,
  name: STATE_REDUCER_KEY,
  reducers: {
    clearAll: () => initialState,
    clear: (state) => {
      state.lsgiDetails = initialState.lsgiDetails;
      state.nameList = initialState.nameList;
      state.codeList = initialState.codeList;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ACTION_TYPES.SELECT_LSGI_TYPE_REQUEST, (state) => {
        state.lsgiTypeDropdown.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.SELECT_LSGI_TYPE_SUCCESS, (state, action) => {
        _.set(state, "lsgiTypeDropdown.requestInProgress", false);
        _.set(state, "lsgiTypeDropdown.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.SELECT_LSGI_TYPE_FAILURE, (state) => {
        state.lsgiTypeDropdown.requestInProgress = false;
      })
      .addCase(ACTION_TYPES.SELECT_ULB_DISTRICT_REQUEST, (state) => {
        state.districtDropdown.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.SELECT_ULB_DISTRICT_SUCCESS, (state, action) => {
        _.set(state, "districtDropdown.requestInProgress", false);
        _.set(state, "districtDropdown.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.SELECT_ULB_DISTRICT_FAILURE, (state) => {
        state.districtDropdown.requestInProgress = false;
      })
      .addCase(ACTION_TYPES.SELECT_BLOCK_PANCHAYATH_REQUEST, (state) => {
        state.blockPanchayathDropdown.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.SELECT_BLOCK_PANCHAYATH_SUCCESS, (state, action) => {
        _.set(state, "blockPanchayathDropdown.requestInProgress", false);
        _.set(state, "blockPanchayathDropdown.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.SELECT_BLOCK_PANCHAYATH_FAILURE, (state) => {
        state.blockPanchayathDropdown.requestInProgress = false;
      })
      .addCase(ACTION_TYPES.SELECT_DISTRICT_PANCHAYATH_REQUEST, (state) => {
        state.districtPanchayathDropdown.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.SELECT_DISTRICT_PANCHAYATH_SUCCESS, (state, action) => {
        _.set(state, "districtPanchayathDropdown.requestInProgress", false);
        _.set(state, "districtPanchayathDropdown.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.SELECT_DISTRICT_PANCHAYATH_FAILURE, (state) => {
        state.districtPanchayathDropdown.requestInProgress = false;
      })
      .addCase(ACTION_TYPES.FETCH_ULB_BY_ID_REQUEST, (state) => {
        state.lsgiDetails.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.FETCH_ULB_BY_ID_SUCCESS, (state, action) => {
        let data = getPayloadContentDetails(action);
        _.set(state, "lsgiDetails.requestInProgress", false);
        _.set(state, "lsgiDetails.data", data);
      })
      .addCase(ACTION_TYPES.FETCH_ULB_BY_ID_FAILURE, (state) => {
        state.lsgiDetails.requestInProgress = false;
      }).addCase(ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, (state, { payload }) => {
        _.set(state, "nameList.requestInProgress", false);
        _.set(state, "nameList.data", payload.content);
      }).addCase(ACTION_TYPES.FETCH_CODE_LIST_SUCCESS, (state, { payload }) => {
        _.set(state, "codeList.requestInProgress", false);
        _.set(state, "codeList.data", payload.content);
      });
  }
});

export const { actions, reducer } = slice;
