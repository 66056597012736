import { I18n } from "common/components";
import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { successNotify } from "utils/notificationUtils";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { actions as commonActions } from "../../common/slice";
import { SUB_PROJECTS_PATH as PATH } from "../paths";
import { deleteAnnualPlanApi, fetchDistricts, listByIdAnnualPlanApi, listAnnualPlanApi, lsgiDropdownApi, submitAnnualPlanApi, updateAnnualPlanApi, deleteFileById, ulbSubmittedToMcApi, approveSpmuApi, rejectSpmuApi, approveQaQcApi, rejectQaQcApi, approveMunicipalApi, rejectMunicipalApi, listFiveYearApi, listAnnualProjectApi, assignAnnualProjectApi, planStatusList, fetchFiveYearPlanApi } from "./api";
import { MODULE_PATH } from "modules/paths";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { toEpoch } from "utils/dateUtils";
import { ACTION_TYPES } from "./actions";
import { TABLE_IDS } from "common/constants";
import { API_URL } from "../apiUrls";

export function* lsgiDropdown(action) {
    yield call(handleAPIRequest, lsgiDropdownApi, action.payload);
}

const formatData = (rest) => {
    const { fiveYearPlan, districtId, lsgiId, planCode, planName, remarks, toYear, fromYear, attachmentIds } = rest;
    const data = { fiveYearPlan, districtId: districtId, lsgiId: lsgiId, planCode, planName, remarks, toYear: Number(toYear), fromYear: Number(fromYear), attachmentIds };
    return data;
};

const formatEditData = (action) => {
    const { payload } = action;
    const { fiveYearPlan, districtId, lsgiId, planCode, planName, remarks, toYear, fromYear, attachmentIds, id } = payload;
    const data = { fiveYearPlan, districtId: districtId, lsgiId: lsgiId, planCode, planName, remarks, toYear: Number(toYear), fromYear: Number(fromYear), attachmentIds, id };
    return data;
};

export function* submitAnnualPlan(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, updateAnnualPlanApi, formatEditData(action));
        const responseAction = yield take([ACTION_TYPES.EDIT_ANNUAL_PLAN_SUCCESS, ACTION_TYPES.EDIT_ANNUAL_PLAN_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_ANNUAL_PLAN_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("annual_plan") }) }));
            yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.ANNUAL_PLAN.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, submitAnnualPlanApi, formatData(rest));
        const responseAction = yield take([ACTION_TYPES.SUBMIT_ANNUAL_PLAN_SUCCESS, ACTION_TYPES.SUBMIT_ANNUAL_PLAN_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_ANNUAL_PLAN_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("annual_plan") }) }));
            yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.ANNUAL_PLAN.LIST}`));
        }
    }
}
export function* listAnnualPlan({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.ANNUAL_PLAN_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listAnnualPlanApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_ANNUAL_PLAN_SUCCESS, ACTION_TYPES.LIST_ANNUAL_PLAN_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.ANNUAL_PLAN_LIST);
}
export function* listByIdAnnualPlan({ payload }) {
    yield call(handleAPIRequest, listByIdAnnualPlanApi, payload);
}
export function* deleteAnnualPlan({ payload: id }) {
    yield fork(handleAPIRequest, deleteAnnualPlanApi, id);
    const response = yield take([ACTION_TYPES.DELETE_ANNUAL_PLAN_SUCCESS, ACTION_TYPES.DELETE_ANNUAL_PLAN_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_ANNUAL_PLAN_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("annual_plan") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listAnnualPlan, { payload: tablePayload });
    }
}

export function* deleteFileFn({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, deleteFileById, id, fileId);
    const response = yield take([ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_FILE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: "file" }) }));
    }
}

export function* districts() {
    yield call(handleAPIRequest, fetchDistricts);
}

export function* getFiveYearPlan({ payload }) {
    yield call(handleAPIRequest, fetchFiveYearPlanApi, payload);
}

export function* ulbSubmittedToMc({ payload }) {
    yield fork(handleAPIRequest, ulbSubmittedToMcApi, payload);
    const responseAction = yield take([ACTION_TYPES.ULB_SUBMITTED_TO_MC_SUCCESS, ACTION_TYPES.ULB_SUBMITTED_TO_MC_FAILURE]);
    if (responseAction.type === ACTION_TYPES.ULB_SUBMITTED_TO_MC_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("creat", { type: I18n("annual_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.ANNUAL_PLAN.LIST}`));
    }
}

const formatDpmuData = (rest) => {
    const { councilResolutionNumber, date, dpmuRemarks, planId } = rest;
    const data = { planId: Number(planId), date: toEpoch(date), councilResolutionNumber, dpmuRemarks };
    return data;
};
export function* approveQaQc(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, approveQaQcApi, formatDpmuData(rest));
    const responseAction = yield take([ACTION_TYPES.APPROVE_QA_QC_SUCCESS, ACTION_TYPES.APPROVE_QA_QC_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_QA_QC_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approved", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.ANNUAL_PLAN.LIST}`));
    }
}
export function* rejectQaQc(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, rejectQaQcApi, formatDpmuData(rest));
    const responseAction = yield take([ACTION_TYPES.REJECT_QA_QC_SUCCESS, ACTION_TYPES.REJECT_QA_QC_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_QA_QC_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("rejected", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.ANNUAL_PLAN.LIST}`));
    }
}

const formatSpmuData = (rest) => {
    const { spmuRemarks, planId } = rest;
    const data = { planId: Number(planId), spmuRemarks };
    return data;
};
export function* approveSpmu(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, approveSpmuApi, formatSpmuData(rest));
    const responseAction = yield take([ACTION_TYPES.APPROVE_SPMU_SUCCESS, ACTION_TYPES.APPROVE_SPMU_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_SPMU_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approved", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.ANNUAL_PLAN.LIST}`));
    }
}
export function* rejectSpmu(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, rejectSpmuApi, formatSpmuData(rest));
    const responseAction = yield take([ACTION_TYPES.REJECT_SPMU_SUCCESS, ACTION_TYPES.REJECT_SPMU_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_SPMU_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("rejected", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.ANNUAL_PLAN.LIST}`));
    }
}

const formatMunicipalData = (rest) => {
    const { mcResolutionNumber, mcResolutionDate, mcRemarks, planId } = rest;
    const data = { planId: Number(planId), mcResolutionDate: toEpoch(mcResolutionDate), mcResolutionNumber, mcRemarks };
    return data;
};
export function* approveMunicipal(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, approveMunicipalApi, formatMunicipalData(rest));
    const responseAction = yield take([ACTION_TYPES.APPROVE_MUNICIPAL_SUCCESS, ACTION_TYPES.APPROVE_MUNICIPAL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_MUNICIPAL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approved", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.ANNUAL_PLAN.LIST}`));
    }
}
export function* rejectMunicipal(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, rejectMunicipalApi, formatMunicipalData(rest));
    const responseAction = yield take([ACTION_TYPES.REJECT_MUNICIPAL_SUCCESS, ACTION_TYPES.REJECT_MUNICIPAL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_MUNICIPAL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("rejected", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.ANNUAL_PLAN.LIST}`));
    }
}

export function* listFiveYearProject({ payload }) {
    let updatedPayload = { isProjectsExclude: true, fiveYearPlanApproved: true, ...payload };
    yield call(handleAPIRequest, listFiveYearApi, updatedPayload);
}

export function* listAnnualPlanProject({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield call(handleAPIRequest, listAnnualProjectApi, { ...filters, ...payload });
}

export function* submitAssignedProjects({ payload }) {
    yield fork(handleAPIRequest, assignAnnualProjectApi, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_PROJECT_LIST_SUCCESS, ACTION_TYPES.SUBMIT_PROJECT_LIST_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_PROJECT_LIST_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("submitted", { type: I18n("assigned_annual_project") }) }));
        yield call(handleAPIRequest, listByIdAnnualPlanApi, payload.annualPlanId);
    }
}
export function* fetchPlanStatusList({ payload }) {
    yield call(handleAPIRequest, planStatusList, payload);
}

export function* downloadAnnualPlan(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, planStatus } = filters;
    yield put(commonFileDownload({
        url: API_URL.ANNUAL_PLAN.DOWNLOAD,
        data: { type, districtId, lsgiId, planStatus },
        file: { name: fileName, ext: type }
    }));
}

export default function* AnnualPlanActivitySaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_LSGI_DROPDOWN, lsgiDropdown),
        takeLatest(ACTION_TYPES.SUBMIT_ANNUAL_PLAN, submitAnnualPlan),
        takeLatest(ACTION_TYPES.LIST_ANNUAL_PLAN, listAnnualPlan),
        takeLatest(ACTION_TYPES.LIST_BY_ID_ANNUAL_PLAN, listByIdAnnualPlan),
        takeLatest(ACTION_TYPES.DELETE_ANNUAL_PLAN, deleteAnnualPlan),
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN, districts),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFileFn),
        takeLatest(ACTION_TYPES.ULB_SUBMITTED_TO_MC, ulbSubmittedToMc),
        takeLatest(ACTION_TYPES.APPROVE_QA_QC, approveQaQc),
        takeLatest(ACTION_TYPES.REJECT_QA_QC, rejectQaQc),
        takeLatest(ACTION_TYPES.APPROVE_SPMU, approveSpmu),
        takeLatest(ACTION_TYPES.REJECT_SPMU, rejectSpmu),
        takeLatest(ACTION_TYPES.APPROVE_MUNICIPAL, approveMunicipal),
        takeLatest(ACTION_TYPES.REJECT_MUNICIPAL, rejectMunicipal),
        takeLatest(ACTION_TYPES.FETCH_FIVE_YEAR_LIST, listFiveYearProject),
        takeLatest(ACTION_TYPES.FETCH_ANNUAL_PLAN_LIST, listAnnualPlanProject),
        takeLatest(ACTION_TYPES.SUBMIT_PROJECT_LIST, submitAssignedProjects),
        takeLatest(ACTION_TYPES.FETCH_STATUS_LIST, fetchPlanStatusList),
        takeLatest(ACTION_TYPES.FETCH_FIVE_YEAR_PLAN_DROPDOWN, getFiveYearPlan),
        takeLatest(ACTION_TYPES.DOWNLOAD_ANNUAL_PLAN_LIST, downloadAnnualPlan)
    ]);
}
