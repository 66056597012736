import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    APPROVE_MUNICIPAL: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL`,
    APPROVE_MUNICIPAL_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL_REQUEST`,
    APPROVE_MUNICIPAL_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL_SUCCESS`,
    APPROVE_MUNICIPAL_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL_FAILURE`,

    REJECT_MUNICIPAL: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL`,
    REJECT_MUNICIPAL_REQUEST: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL_REQUEST`,
    REJECT_MUNICIPAL_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL_SUCCESS`,
    REJECT_MUNICIPAL_FAILURE: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL_FAILURE`,

    APPROVAL_MUNICIPAL_BY_ID: `${STATE_REDUCER_KEY}/APPROVAL_MUNICIPAL_BY_ID`,
    APPROVAL_MUNICIPAL_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/APPROVAL_MUNICIPAL_BY_ID_REQUEST`,
    APPROVAL_MUNICIPAL_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/APPROVAL_MUNICIPAL_BY_ID_SUCCESS`,
    APPROVAL_MUNICIPAL_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/APPROVAL_MUNICIPAL_BY_ID_FAILURE`
};

export const rejectMunicipal = createAction(ACTION_TYPES.REJECT_MUNICIPAL);
export const approveMunicipal = createAction(ACTION_TYPES.APPROVE_MUNICIPAL);
export const approveMunicipalById = createAction(ACTION_TYPES.APPROVAL_MUNICIPAL_BY_ID);
