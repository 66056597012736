import { I18n } from "common/components";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { confirmGapAnalysisApi, deleteFileById, deleteGapAnalysis, gapAnalysisById, listGapAnalysis, saveGapAnalysis, updateGapAnalysisApi } from "./api";
import { setCommonTableData } from "utils/tableUtils";
import { actions as commonActions } from "../../common/slice";
import { actions as sliceActons } from "../../common/slice";
import { listByIdSWMPlanApi } from "../swmPlan/api";

export function* submitGapAnalysis({ payload }) {
    yield fork(handleAPIRequest, saveGapAnalysis, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.SUBMIT_GAP_ANALYSIS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_GAP_ANALYSIS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("gap_analysis") }) }));
    }
}

export function* updateGapAnalysis({ payload }) {
    yield fork(handleAPIRequest, updateGapAnalysisApi, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.UPDATE_GAP_ANALYSIS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_GAP_ANALYSIS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("gap_analysis") }) }));
        yield call(handleAPIRequest, listGapAnalysis, payload?.swmPlanId);
    }
}

export function* listGapAnalysisForm({ payload }) {
    yield fork(handleAPIRequest, listGapAnalysis, payload);
}

export function* deleteGapAnalysisForm({ payload }) {
    yield fork(handleAPIRequest, deleteGapAnalysis, payload.id);
    const response = yield take([ACTION_TYPES.DELETE_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.DELETE_GAP_ANALYSIS_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_GAP_ANALYSIS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("gap_analysis") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let updatedPayload = { pageNo: 0, pageSize: 5 };
        yield fork(handleAPIRequest, listGapAnalysis, updatedPayload, Number(payload.swmPlanId));
        const COMPARISON_TYPES = [ACTION_TYPES.LIST_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.LIST_GAP_ANALYSIS_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES);
    }
}

export function* gapAnalysisGetById({ payload: id }) {
    yield call(handleAPIRequest, gapAnalysisById, id);
}

export function* deleteFileFn({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, deleteFileById, id, fileId);
    const response = yield take([ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_FILE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: "file" }) }));
    }
}

export function* confirmGapAnalysis({ payload }) {
    yield fork(handleAPIRequest, confirmGapAnalysisApi, payload);
    const response = yield take([ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS, ACTION_TYPES.CONFIRM_FORM_SUBMIT_FAILURE]);
    if (response.type === ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("gap_analysis") }) }));
        const { swmPlanId } = payload;
        yield call(handleAPIRequest, listByIdSWMPlanApi, swmPlanId);
        yield put(sliceActons.moveTabUp());
    }
}

export default function* registerSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_GAP_ANALYSIS, submitGapAnalysis),
        takeLatest(ACTION_TYPES.UPDATE_GAP_ANALYSIS, updateGapAnalysis),
        takeLatest(ACTION_TYPES.DELETE_GAP_ANALYSIS, deleteGapAnalysisForm),
        takeLatest(ACTION_TYPES.LIST_GAP_ANALYSIS, listGapAnalysisForm),
        takeLatest(ACTION_TYPES.GAP_ANALYSIS_BY_ID, gapAnalysisGetById),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFileFn),
        takeLatest(ACTION_TYPES.CONFIRM_FORM_SUBMIT, confirmGapAnalysis)
    ]);
}
