import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_LAND_ACQUISITION_DATA: `${STATE_REDUCER_KEY}/FETCH_LAND_ACQUISITION_DATA`,
    FETCH_LAND_ACQUISITION_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LAND_ACQUISITION_DATA_REQUEST`,
    FETCH_LAND_ACQUISITION_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LAND_ACQUISITION_DATA_SUCCESS`,
    FETCH_LAND_ACQUISITION_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LAND_ACQUISITION_DATA_FAILURE`,

    PATCH_LAND_ACQUISITION_DATA: `${STATE_REDUCER_KEY}/PATCH_LAND_ACQUISITION_DATA`,
    PATCH_LAND_ACQUISITION_DATA_REQUEST: `${STATE_REDUCER_KEY}/PATCH_LAND_ACQUISITION_DATA_REQUEST`,
    PATCH_LAND_ACQUISITION_DATA_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_LAND_ACQUISITION_DATA_SUCCESS`,
    PATCH_LAND_ACQUISITION_DATA_FAILURE: `${STATE_REDUCER_KEY}/PATCH_LAND_ACQUISITION_DATA_FAILURE`
};

export const fetchLandAcquisitionData = createAction(ACTION_TYPES.FETCH_LAND_ACQUISITION_DATA);
export const patchLandAcquisitionData = createAction(ACTION_TYPES.PATCH_LAND_ACQUISITION_DATA);


