import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_MCF_RRF_DETAILS: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS`,
    FETCH_MCF_RRF_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS_REQUEST`,
    FETCH_MCF_RRF_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS_SUCCESS`,
    FETCH_MCF_RRF_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS_FAILURE`,

    FORWARD_LINKING_DROPDOWN: `${STATE_REDUCER_KEY}/FORWARD_LINKING_DROPDOWN`,
    FORWARD_LINKING_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FORWARD_LINKING_DROPDOWN_REQUEST`,
    FORWARD_LINKING_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FORWARD_LINKING_DROPDOWN_SUCCESS`,
    FORWARD_LINKING_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FORWARD_LINKING_DROPDOWN_FAILURE`,

    POST_MCF_RRF_DETAILS: `${STATE_REDUCER_KEY}/POST_MCF_RRF_DETAILS`,
    POST_MCF_RRF_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_MCF_RRF_DETAILS_REQUEST`,
    POST_MCF_RRF_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_MCF_RRF_DETAILS_SUCCESS`,
    POST_MCF_RRF_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_MCF_RRF_DETAILS_FAILURE`,

    PATCH_MCF_RRF_DETAILS: `${STATE_REDUCER_KEY}/PATCH_MCF_RRF_DETAILS`,
    PATCH_MCF_RRF_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_MCF_RRF_DETAILS_REQUEST`,
    PATCH_MCF_RRF_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_MCF_RRF_DETAILS_SUCCESS`,
    PATCH_MCF_RRF_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_MCF_RRF_DETAILS_FAILURE`,

    GET_MCF_RRF_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/GET_MCF_RRF_DETAILS_BY_ID`,
    GET_MCF_RRF_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_MCF_RRF_DETAILS_BY_ID_REQUEST`,
    GET_MCF_RRF_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_MCF_RRF_DETAILS_BY_ID_SUCCESS`,
    GET_MCF_RRF_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_MCF_RRF_DETAILS_FAILURE`,

    DELETE_MCF_RRF_DETAILS: `${STATE_REDUCER_KEY}/DELETE_MCF_RRF_DETAILS`,
    DELETE_MCF_RRF_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_MCF_RRF_DETAILS_REQUEST`,
    DELETE_MCF_RRF_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_MCF_RRF_DETAILS_SUCCESS`,
    DELETE_MCF_RRF_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_MCF_RRF_DETAILS_FAILURE`,

    TYPE_OF_DEPARTMENT_DROPDOWN: `${STATE_REDUCER_KEY}/TYPE_OF_DEPARTMENT_DROPDOWN`,
    TYPE_OF_DEPARTMENT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/TYPE_OF_DEPARTMENT_DROPDOWN_REQUEST`,
    TYPE_OF_DEPARTMENT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/TYPE_OF_DEPARTMENT_DROPDOWN_SUCCESS`,
    TYPE_OF_DEPARTMENT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/TYPE_OF_DEPARTMENT_DROPDOWN_FAILURE`,

    MCF_RRF_NAME_DROPDOWN: `${STATE_REDUCER_KEY}/MCF_RRF_NAME_DROPDOWN`,
    MCF_RRF_NAME_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/MCF_RRF_NAME_DROPDOWN_REQUEST`,
    MCF_RRF_NAME_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/MCF_RRF_NAME_DROPDOWN_SUCCESS`,
    MCF_RRF_NAME_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/MCF_RRF_NAME_DROPDOWN_FAILURE`
};

export const getMcfRrfDetails = createAction(ACTION_TYPES.FETCH_MCF_RRF_DETAILS);
export const getForwardLinking = createAction(ACTION_TYPES.FORWARD_LINKING_DROPDOWN);
export const postMcfRrfDetails = createAction(ACTION_TYPES.POST_MCF_RRF_DETAILS);
export const patchMcfRrfDetails = createAction(ACTION_TYPES.PATCH_MCF_RRF_DETAILS);
export const getMcfRrfDetailsById = createAction(ACTION_TYPES.GET_MCF_RRF_DETAILS_BY_ID);
export const deleteMcfRrfDetails = createAction(ACTION_TYPES.DELETE_MCF_RRF_DETAILS);
export const getTypeOfDepartment = createAction(ACTION_TYPES.TYPE_OF_DEPARTMENT_DROPDOWN);
export const getMcfRrfNameDropdown = createAction(ACTION_TYPES.MCF_RRF_NAME_DROPDOWN);


