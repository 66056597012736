import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    SUBMIT_PAGINATION_DETAILS: `${STATE_REDUCER_KEY}/SUBMIT_PAGINATION_DETAILS`,
    SUBMIT_PAGINATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_PAGINATION_REQUEST`,
    SUBMIT_PAGINATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_PAGINATION_SUCCESS`,
    SUBMIT_PAGINATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_PAGINATION_FAILURE`,

    SUBMIT_VENDOR_CATEGORY_DETAILS: `${STATE_REDUCER_KEY}/SUBMIT_VENDOR_CATEGORY_DETAILS`,
    SUBMIT_VENDOR_CATEGORY_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_VENDOR_CATEGORY_DETAILS_REQUEST`,
    SUBMIT_VENDOR_CATEGORY_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_VENDOR_CATEGORY_DETAILS_SUCCESS`,
    SUBMIT_VENDOR_CATEGORY_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_VENDOR_CATEGORY_DETAILS_FAILURE`,

    FETCH_VENDOR_CATEGORY_DETAILS: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CATEGORY_DETAILS`,
    FETCH_VENDOR_CATEGORY_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CATEGORY_DETAILS_REQUEST`,
    FETCH_VENDOR_CATEGORY_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CATEGORY_DETAILS_SUCCESS`,
    FETCH_VENDOR_CATEGORY_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CATEGORY_DETAILS_FAILURE`,

    DELETE_VENDOR_CATEGORY_DETAILS: `${STATE_REDUCER_KEY}/DELETE_VENDOR_CATEGORY_DETAILS`,
    DELETE_VENDOR_CATEGORY_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_VENDOR_CATEGORY_DETAILS_REQUEST`,
    DELETE_VENDOR_CATEGORY_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_VENDOR_CATEGORY_DETAILS_SUCCESS`,
    DELETE_VENDOR_CATEGORY_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_VENDOR_CATEGORY_DETAILS_FAILURE`,

    EDIT_VENDOR_CATEGORY_DETAILS: `${STATE_REDUCER_KEY}/EDIT_VENDOR_CATEGORY_DETAILS`,
    EDIT_VENDOR_CATEGORY_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/EDIT_VENDOR_CATEGORY_DETAILS_REQUEST`,
    EDIT_VENDOR_CATEGORY_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_VENDOR_CATEGORY_DETAILS_SUCCESS`,
    EDIT_VENDOR_CATEGORY_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/EDIT_VENDOR_CATEGORY_DETAILS_FAILURE`,

    FETCH_VENDOR_CATEGORY_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CATEGORY_DETAILS_BY_ID`,
    FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_REQUEST`,
    FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_SUCCESS`,
    FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_FAILURE`,

    SUBMIT_VENDOR_CONTACT_DETAILS: `${STATE_REDUCER_KEY}/SUBMIT_VENDOR_CONTACT_DETAILS`,
    SUBMIT_VENDOR_CONTACT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_VENDOR_CONTACT_DETAILS_REQUEST`,
    SUBMIT_VENDOR_CONTACT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_VENDOR_CONTACT_DETAILS_SUCCESS`,
    SUBMIT_VENDOR_CONTACT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_VENDOR_CONTACT_DETAILS_FAILURE`,


    FETCH_VENDOR_CONTACT_DETAILS: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CONTACT_DETAILS`,
    FETCH_VENDOR_CONTACT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CONTACT_DETAILS_REQUEST`,
    FETCH_VENDOR_CONTACT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CONTACT_DETAILS_SUCCESS`,
    FETCH_VENDOR_CONTACT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CONTACT_DETAILS_FAILURE`,

    DELETE_VENDOR_CONTACT_DETAILS: `${STATE_REDUCER_KEY}/DELETE_VENDOR_CONTACT_DETAILS`,
    DELETE_VENDOR_CONTACT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_VENDOR_CONTACT_DETAILS_REQUEST`,
    DELETE_VENDOR_CONTACT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_VENDOR_CONTACT_DETAILS_SUCCESS`,
    DELETE_VENDOR_CONTACT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_VENDOR_CONTACT_DETAILS_FAILURE`,

    EDIT_VENDOR_CONTACT_DETAILS: `${STATE_REDUCER_KEY}/EDIT_VENDOR_CONTACT_DETAILS`,
    EDIT_VENDOR_CONTACT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/EDIT_VENDOR_CONTACT_DETAILS_REQUEST`,
    EDIT_VENDOR_CONTACT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_VENDOR_CONTACT_DETAILS_SUCCESS`,
    EDIT_VENDOR_CONTACT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/EDIT_VENDOR_CONTACT_DETAILS_FAILURE`,

    FETCH_VENDOR_CONTACT_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CONTACT_DETAILS_BY_ID`,
    FETCH_VENDOR_CONTACT_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CONTACT_DETAILS_BY_ID_REQUEST`,
    FETCH_VENDOR_CONTACT_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CONTACT_DETAILS_BY_ID_SUCCESS`,
    FETCH_VENDOR_CONTACT_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_VENDOR_CONTACT_DETAILS_BY_ID_FAILURE`,

    FETCH_USER_CONTACT_TYPE: `${STATE_REDUCER_KEY}/FETCH_USER_CONTACT_TYPE`,
    FETCH_USER_CONTACT_TYPE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_CONTACT_TYPE_REQUEST`,
    FETCH_USER_CONTACT_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_CONTACT_TYPE_SUCCESS`,
    FETCH_USER_CONTACT_TYPE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_CONTACT_TYPE_FAILURE`,

    FETCH_LOOKUP_CATEGORY_ID: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_ID`,
    FETCH_LOOKUP_CATEGORY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_ID_REQUEST`,
    FETCH_LOOKUP_CATEGORY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_ID_SUCCESS`,
    FETCH_LOOKUP_CATEGORY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_ID_FAILURE`,

    FETCH_CATEGORY: `${STATE_REDUCER_KEY}/FETCH_CATEGORY`,
    FETCH_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CATEGORY_REQUEST`,
    FETCH_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CATEGORY_SUCCESS`,
    FETCH_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CATEGORY_FAILURE`,

    LIST_VENDOR_CONTACT_NAME: `${STATE_REDUCER_KEY}/LIST_VENDOR_CONTACT_NAME`,
    LIST_VENDOR_CONTACT_NAME_REQUEST: `${STATE_REDUCER_KEY}/LIST_VENDOR_CONTACT_NAME_REQUEST`,
    LIST_VENDOR_CONTACT_NAME_SUCCESS: `${STATE_REDUCER_KEY}/LIST_VENDOR_CONTACT_NAME_SUCCESS`,
    LIST_VENDOR_CONTACT_NAME_FAILURE: `${STATE_REDUCER_KEY}/LIST_VENDOR_CONTACT_NAME_FAILURE`,

    LIST_VENDOR_CONTACT_GST: `${STATE_REDUCER_KEY}/LIST_VENDOR_CONTACT_GST`,
    LIST_VENDOR_CONTACT_GST_REQUEST: `${STATE_REDUCER_KEY}/LIST_VENDOR_CONTACT_GST_REQUEST`,
    LIST_VENDOR_CONTACT_GST_SUCCESS: `${STATE_REDUCER_KEY}/LIST_VENDOR_CONTACT_GST_SUCCESS`,
    LIST_VENDOR_CONTACT_GST_FAILURE: `${STATE_REDUCER_KEY}/LIST_VENDOR_CONTACT_GST_FAILURE`,

    FILTER_VENDOR_CONTACT: `${STATE_REDUCER_KEY}/FILTER_VENDOR_CONTACT`,
    FILTER_VENDOR_CONTACT_REQUEST: `${STATE_REDUCER_KEY}/FILTER_VENDOR_CONTACT_REQUEST`,
    FILTER_VENDOR_CONTACT_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_VENDOR_CONTACT_SUCCESS`,
    FILTER_VENDOR_CONTACT_FAILURE: `${STATE_REDUCER_KEY}/FILTER_VENDOR_CONTACT_FAILURE`,

    FILTER_VENDOR_CATEGORY_NAME: `${STATE_REDUCER_KEY}/FILTER_VENDOR_CATEGORY_NAME`,
    FILTER_VENDOR_CATEGORY_NAME_REQUEST: `${STATE_REDUCER_KEY}/FILTER_VENDOR_CATEGORY_NAME_REQUEST`,
    FILTER_VENDOR_CATEGORY_NAME_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_VENDOR_CATEGORY_NAME_SUCCESS`,
    FILTER_VENDOR_CATEGORY_NAME_FAILURE: `${STATE_REDUCER_KEY}/FILTER_VENDOR_CATEGORY_NAME_FAILURE`
};

export const fetchCategoryDropdown = createAction(ACTION_TYPES.FETCH_CATEGORY);
export const lookupCategoryIdDropdown = createAction(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID);
export const fetchUserContactTypeDropdown = createAction(ACTION_TYPES.FETCH_USER_CONTACT_TYPE);
export const fetchVendorCategoryDetailsById = createAction(ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_BY_ID);
export const fetchVendorCategoryDetails = createAction(ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS);
export const submitVendorCategoryDetails = createAction(ACTION_TYPES.SUBMIT_VENDOR_CATEGORY_DETAILS);
export const setPagination = createAction(ACTION_TYPES.SUBMIT_PAGINATION_DETAILS);
export const deleteVendorCategoryList = createAction(ACTION_TYPES.DELETE_VENDOR_CATEGORY_DETAILS);
export const editVendorCategoryList = createAction(ACTION_TYPES.EDIT_VENDOR_CATEGORY_DETAILS);
export const fetchVendorContactDetailsById = createAction(ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_BY_ID);
export const fetchVendorContactDetails = createAction(ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS);
export const submitVendorContactDetails = createAction(ACTION_TYPES.SUBMIT_VENDOR_CONTACT_DETAILS);
export const deleteVendorContactList = createAction(ACTION_TYPES.DELETE_VENDOR_CONTACT_DETAILS);
export const editVendorContactList = createAction(ACTION_TYPES.EDIT_VENDOR_CONTACT_DETAILS);
export const listVendorContactName = createAction(ACTION_TYPES.LIST_VENDOR_CONTACT_NAME);
export const listVendorContactGstIn = createAction(ACTION_TYPES.LIST_VENDOR_CONTACT_GST);
export const filterVendorContact = createAction(ACTION_TYPES.FILTER_VENDOR_CONTACT);
export const filterCategoryName = createAction(ACTION_TYPES.FILTER_VENDOR_CATEGORY_NAME);
