import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchWssInfoListApi = (data) => {
    return {
        url: API_URL.WSS_INFO.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WSS_INFO_LIST_REQUEST, ACTION_TYPES.FETCH_WSS_INFO_LIST_SUCCESS, ACTION_TYPES.FETCH_WSS_INFO_LIST_FAILURE], data
        }
    };
};

export const submitWssInfoApi = (data) => ({
    url: API_URL.WSS_INFO.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_WSS_INFO_REQUEST, ACTION_TYPES.POST_WSS_INFO_SUCCESS, ACTION_TYPES.POST_WSS_INFO_FAILURE],
        data
    }
});

export const updateWssInfoApi = (data) => ({
    url: API_URL.WSS_INFO.SAVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_WSS_INFO_REQUEST, ACTION_TYPES.PATCH_WSS_INFO_SUCCESS, ACTION_TYPES.PATCH_WSS_INFO_FAILURE],
        data
    }
});

export const fetchWssInfoByIdApi = (id) => {
    return {
        url: API_URL.WSS_INFO.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WSS_INFO_BY_ID_REQUEST, ACTION_TYPES.FETCH_WSS_INFO_BY_ID_SUCCESS, ACTION_TYPES.FETCH_WSS_INFO_BY_ID_FAILURE]
        }
    };
};

export const deleteWssInfoApi = (id) => ({
    url: API_URL.WSS_INFO.DELETE.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
        types: [ACTION_TYPES.DELETE_WSS_INFO_REQUEST, ACTION_TYPES.DELETE_WSS_INFO_SUCCESS, ACTION_TYPES.DELETE_WSS_INFO_FAILURE]
    }
});

export const fetchWardNameDropdownApi = (query = {}) => {
    return {
        url: API_URL.WSS_INFO.WARD_NAME_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WARD_NAME_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_WARD_NAME_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_WARD_NAME_DROPDOWN_FAILURE],
            data: query
        }
    };
};
