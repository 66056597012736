import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
  FETCH_WARD_STATE_DETAILS: `${STATE_REDUCER_KEY}/FETCH_WARD_STATE_DETAILS`,
  FETCH_WARD_STATE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_STATE_DETAILS_REQUEST`,
  FETCH_WARD_STATE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_STATE_DETAILS_SUCCESS`,
  FETCH_WARD_STATE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_STATE_DETAILS_FAILURE`,

  FETCH_WARD_DETAILS: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS`,
  FETCH_WARD_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_REQUEST`,
  FETCH_WARD_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_SUCCESS`,
  FETCH_WARD_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_FAILURE`,

  FETCH_WARD_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_BY_ID`,
  FETCH_WARD_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_BY_ID_REQUEST`,
  FETCH_WARD_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_BY_ID_SUCCESS`,
  FETCH_WARD_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_DETAILS_BY_ID_FAILURE`,

  ADD_WARD_DETAILS: `${STATE_REDUCER_KEY}/ADD_WARD_DETAILS`,
  ADD_WARD_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/ADD_WARD_DETAILS_REQUEST`,
  ADD_WARD_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/ADD_WARD_DETAILS_SUCCESS`,
  ADD_WARD_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/ADD_WARD_DETAILS_FAILURE`,

  EDIT_WARD_DETAILS: `${STATE_REDUCER_KEY}/EDIT_WARD_DETAILS`,
  EDIT_WARD_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/EDIT_WARD_DETAILS_REQUEST`,
  EDIT_WARD_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_WARD_DETAILS_SUCCESS`,
  EDIT_WARD_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/EDIT_WARD_DETAILS_FAILURE`,

  DELETE_WARD_DETAILS: `${STATE_REDUCER_KEY}/DELETE_WARD_DETAILS`,
  DELETE_WARD_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_WARD_DETAILS_REQUEST`,
  DELETE_WARD_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_WARD_DETAILS_SUCCESS`,
  DELETE_WARD_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_WARD_DETAILS_FAILURE`,

  SUBMIT_WARD_DATA: `${STATE_REDUCER_KEY}/SUBMIT_WARD_DATA`,
  SUBMIT_WARD_DATA_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_WARD_FORM_DATA_REQUEST`,
  SUBMIT_WARD_DATA_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_WARD_DATA_SUCCESS`,
  SUBMIT_WARD_DATA_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_WARD_DATA_FAILURE`,

  FETCH_LSGI_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN`,
  FETCH_LSGI_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_REQUEST`,
  FETCH_LSGI_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_SUCCESS`,
  FETCH_LSGI_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_FAILURE`,

  FETCH_WARD_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_WARD_NAME_LIST`,
  FETCH_WARD_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_NAME_LIST_REQUEST`,
  FETCH_WARD_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_NAME_LIST_SUCCESS`,
  FETCH_WARD_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_NAME_LIST_FAILURE`,

  FETCH_WARD_NUMBER_LIST: `${STATE_REDUCER_KEY}/FETCH_WARD_NUMBER_LIST`,
  FETCH_WARD_NUMBER_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_NUMBER_LIST_REQUEST`,
  FETCH_WARD_NUMBER_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_NUMBER_LIST_SUCCESS`,
  FETCH_WARD_NUMBER_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_NUMBER_LIST_FAILURE`,

  FETCH_WARD_CODE_LIST: `${STATE_REDUCER_KEY}/FETCH_WARD_CODE_LIST`,
  FETCH_WARD_CODE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_CODE_LIST_REQUEST`,
  FETCH_WARD_CODE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_CODE_LIST_SUCCESS`,
  FETCH_WARD_CODE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_CODE_LIST_FAILURE`,

  SELECT_LSGI_TYPE_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN`,
  SELECT_LSGI_TYPE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_REQUEST`,
  SELECT_LSGI_TYPE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_SUCCESS`,
  SELECT_LSGI_TYPE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_FAILURE`,

  SELECT_WARD_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_WARD_DROPDOWN`,
  SELECT_WARD_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_WARD_DROPDOWN_REQUEST`,
  SELECT_WARD_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_WARD_DROPDOWN_SUCCESS`,
  SELECT_WARD_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_WARD_DROPDOWN_FAILURE`,

  SET_ESCALATION_UNIT_DROPDOWN: `${STATE_REDUCER_KEY}/SET_ESCALATION_UNIT_DROPDOWN`,
  FETCH_ESCALATION_UNIT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_UNIT_DROPDOWN_REQUEST`,
  FETCH_ESCALATION_UNIT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_UNIT_DROPDOWN_SUCCESS`,
  FETCH_ESCALATION_UNIT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_UNIT_DROPDOWN_FAILURE`,

  SUBMIT_ESCALATION: `${STATE_REDUCER_KEY}/SUBMIT_ESCALATION`,
  SUBMIT_ESCALATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_ESCALATION_REQUEST`,
  SUBMIT_ESCALATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_ESCALATION_SUCCESS`,
  SUBMIT_ESCALATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_ESCALATION_FAILURE`,

  FETCH_ESCALATION_LEVEL_BY_ID: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_LEVEL_BY_ID`,
  FETCH_ESCALATION_LEVEL_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_LEVEL_BY_ID_REQUEST`,
  FETCH_ESCALATION_LEVEL_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_LEVEL_BY_ID_SUCCESS`,
  FETCH_ESCALATION_LEVEL_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_LEVEL_BY_ID_FAILURE`,

  DELETE_ESCALATION: `${STATE_REDUCER_KEY}/DELETE_ESCALATION`,
  DELETE_ESCALATION_REQUEST: `${STATE_REDUCER_KEY}/DELETE_ESCALATION_REQUEST`,
  DELETE_ESCALATION_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_ESCALATION_SUCCESS`,
  DELETE_ESCALATION_FAILURE: `${STATE_REDUCER_KEY}/DELETE_ESCALATION_FAILURE`,

  FETCH_OFFICERS_LIST: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST`,
  FETCH_OFFICERS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST_REQUEST`,
  FETCH_OFFICERS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST_SUCCESS`,
  FETCH_OFFICERS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST_FAILURE`,

  SET_TARGET_LEVEL_DROPDOWN: `${STATE_REDUCER_KEY}/SET_TARGET_LEVEL_DROPDOWN`,
  FETCH_TARGET_LEVEL_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TARGET_LEVEL_DROPDOWN_REQUEST`,
  FETCH_TARGET_LEVEL_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TARGET_LEVEL_DROPDOWN_SUCCESS`,
  FETCH_TARGET_LEVEL_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TARGET_LEVEL_DROPDOWN_FAILURE`,

  UPDATE_ESCALATION: `${STATE_REDUCER_KEY}/UPDATE_ESCALATION`,
  UPDATE_ESCALATION_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_ESCALATION_REQUEST`,
  UPDATE_ESCALATION_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_ESCALATION_SUCCESS`,
  UPDATE_ESCALATION_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_ESCALATION_FAILURE`,

  REASSIGN_OFFICER: `${STATE_REDUCER_KEY}/REASSIGN_OFFICER`,
  REASSIGN_OFFICER_REQUEST: `${STATE_REDUCER_KEY}/REASSIGN_OFFICER_REQUEST`,
  REASSIGN_OFFICER_SUCCESS: `${STATE_REDUCER_KEY}/REASSIGN_OFFICER_SUCCESS`,
  REASSIGN_OFFICER_FAILURE: `${STATE_REDUCER_KEY}/REASSIGN_OFFICER_FAILURE`,

  FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
  FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
  FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
  FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,

  FETCH_ROLE_DATA: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA`,
  FETCH_ROLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_REQUEST`,
  FETCH_ROLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_SUCCESS`,
  FETCH_ROLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_FAILURE`

};

export const getStateDetails = createAction(
  ACTION_TYPES.FETCH_WARD_STATE_DETAILS
);
export const getWardDetails = createAction(
  ACTION_TYPES.FETCH_WARD_DETAILS
);
export const submitWardDetails = createAction(ACTION_TYPES.SUBMIT_WARD_DATA);
export const deleteWardDetails = createAction(
  ACTION_TYPES.DELETE_WARD_DETAILS
);
export const fetchLSGIDropDown = createAction(ACTION_TYPES.FETCH_LSGI_DROPDOWN);
export const getWardValuesById = createAction(ACTION_TYPES.FETCH_WARD_DETAILS_BY_ID);
export const getWardNameList = createAction(ACTION_TYPES.FETCH_WARD_NAME_LIST);
export const getWardNumberList = createAction(ACTION_TYPES.FETCH_WARD_NUMBER_LIST);
export const getWardCodeList = createAction(ACTION_TYPES.FETCH_WARD_CODE_LIST);
export const lsgiTypeSelectDropdown = createAction(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN);
export const getWardDropdown = createAction(ACTION_TYPES.SELECT_WARD_DROPDOWN);
export const setEscalationUnitDropDown = createAction(ACTION_TYPES.SET_ESCALATION_UNIT_DROPDOWN);
export const submitEscalation = createAction(ACTION_TYPES.SUBMIT_ESCALATION);
export const getEscalationLevel = createAction(ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID);
export const deleteEscalation = createAction(ACTION_TYPES.DELETE_ESCALATION);
export const fetchOfficersList = createAction(ACTION_TYPES.FETCH_OFFICERS_LIST);
export const setTargetLevelDropDown = createAction(ACTION_TYPES.SET_TARGET_LEVEL_DROPDOWN);
export const addReassignOfficer = createAction(ACTION_TYPES.REASSIGN_OFFICER);
export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);
export const getRoles = createAction(ACTION_TYPES.FETCH_ROLE_DATA);
