import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { inrFormatter } from "utils/commonUtils";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    districtDropdown: {
        requestInProgress: false,
        data: []
    },
    ieaDropdown: {
        requestInProgress: false,
        data: []
    },
    LSGIDropdown: {
        requestInProgress: false,
        data: []
    },
    GrandRequestView: {
        requestInProgress: false,
        data: {
            grantList: [],
            lsgiId: "",
            remarks: "",
            districtId: {},
            ceilingId: "",
            ulbRemarks: "",
            ivaRemarks: "",
            spmuRemarks: "",
            dpmuRemarksToUlb: "",
            dpmuIvaRemark: "",
            dpmuSpmuRemark: "",
            ulbGrantAllocation: {},
            ieaId: "",
            applicationStatus: {},
            id: "",
            error: true
        }
    },
    eligibilityCriteria: {
        requestInProgress: false,
        data: []
    },
    criteriaOption: {
        requestInProgress: false,
        data: []
    },
    ivaRemarks: {
        requestInProgress: false,
        data: {
            compliance: true,
            remarks: ""
        }
    },
    ceilingList: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.ivaRemarks = initialState.ivaRemarks;
            state.ceilingList = initialState.ceilingList;
        },
        clearULB: (state) => {
            state.LSGIDropdown = initialState.LSGIDropdown;
        },
        clearDistrict: (state) => {
            state.districtDropdown = initialState.districtDropdown;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_DISTRICT_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "districtDropdown.requestInProgress", false);
            _.set(state, "districtDropdown.data", payload.content);
        }).addCase(ACTION_TYPES.FETCH_ULB_DROPDOWN_SUCCESS, (state, { payload }) => {
            _.set(state, "LSGIDropdown.requestInProgress", false);
            _.set(state, "LSGIDropdown.data", payload.content);
        }).addCase(ACTION_TYPES.FETCH_IEA_DROPDOWN_SUCCESS, (state, { payload }) => {
            _.set(state, "ieaDropdown.requestInProgress", false);
            _.set(state, "ieaDropdown.data", payload.content);
        }).addCase(ACTION_TYPES.FETCH_ULB_SEARCH_SUCCESS, (state, { payload }) => {
            _.set(state, "LSGIDropdown.requestInProgress", false);
            _.set(state, "LSGIDropdown.data", payload.content);
        }).addCase(ACTION_TYPES.FETCH_ELIGIBILITY_CRITERIA_SUCCESS, (state, { payload }) => {
            _.set(state, "eligibilityCriteria.requestInProgress", false);
            _.set(state, "eligibilityCriteria.data", payload.content);
        }).addCase(ACTION_TYPES.FETCH_GROUPED_CRITERIA_REQUEST, (state) => {
            _.set(state, "GrandRequestView.requestInProgress", true);
        }).addCase(ACTION_TYPES.FETCH_GROUPED_CRITERIA_SUCCESS, (state, { payload }) => {
            const newPayload = Object.entries(payload).map(([name, obj]) => {
                const criteria = obj.map(item => {
                    let newObj = {};
                    newObj.criteriaId = item;
                    return newObj;
                });
                return { name, grantAllocation: criteria };
            });
            _.set(state, "GrandRequestView.data.grantList", newPayload);
            _.set(state, "GrandRequestView.requestInProgress", false);
        }).addCase(ACTION_TYPES.FETCH_GRAND_ALLOCATION_DETAILS_REQUEST, (state) => {
            _.set(state, "GrandRequestView.requestInProgress", true);
        }).addCase(ACTION_TYPES.FETCH_GRAND_ALLOCATION_DETAILS_FAILURE, (state) => {
            _.set(state, "GrandRequestView.requestInProgress", false);
        }).addCase(ACTION_TYPES.FETCH_GRAND_ALLOCATION_DETAILS_SUCCESS, (state, { payload }) => {
            const newPayload = Object.entries(payload.detailsMap).map(([name, obj]) => ({ name, grantAllocation: obj }));
            _.set(state, "GrandRequestView.data.grantList", newPayload);
            _.set(state, "GrandRequestView.data.lsgiId", payload.lsgiId);
            _.set(state, "GrandRequestView.data.id", payload.id);
            _.set(state, "GrandRequestView.data.districtId", payload.districtId);
            _.set(state, "GrandRequestView.data.ulbGrantAllocation", payload.ulbGrantAllocation);
            _.set(state, "GrandRequestView.data.dpmuIvaRemark", payload?.dpmuRemarksToIva);
            _.set(state, "GrandRequestView.data.dpmuSpmuRemark", payload?.dpmuRemarksToSpmu);
            _.set(state, "GrandRequestView.data.ieaId", payload.ieaId);
            _.set(state, "GrandRequestView.data.ulbRemarks", payload?.ulbRemarks);
            _.set(state, "GrandRequestView.data.spmuRemarks", payload?.spmuRemarks);
            _.set(state, "GrandRequestView.data.dpmuRemarksToUlb", payload?.dpmuRemarksToUlb);
            _.set(state, "GrandRequestView.data.ivaRemarks", payload?.ivaRemarks);
            _.set(state, "GrandRequestView.data.applicationStatus", payload.applicationStatus);
            _.set(state, "GrandRequestView.data.ceilingId", { name: inrFormatter(payload.ceilingId.totalGrantceiled, 3), ...payload.ceilingId });
            _.set(state, "GrandRequestView.requestInProgress", false);
        }).addCase(ACTION_TYPES.FETCH_CRITERIA_OPTIONS_SUCCESS, (state, { payload }) => {
            _.set(state, "criteriaOption.data", payload.content);
            _.set(state, "criteriaOption.requestInProgress", false);

        }).addCase(ACTION_TYPES.FETCH_CEILING_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "ceilingList.requestInProgress", false);
            const newPayload = payload.content?.map(item => {
                let format = {
                    name: inrFormatter(item.totalGrantceiled, 3),
                    ...item
                };
                return format;
            });
            _.set(state, "ceilingList.data", newPayload);
        })
            .addCase(ACTION_TYPES.UPDATE_APPLICATION_SUCCESS, (state) => {
                _.set(state, "GrandRequestView.data.error", false);
            })
            .addCase(ACTION_TYPES.SUBMIT_APPLICATION_SUCCESS, (state) => {
                _.set(state, "GrandRequestView.data.error", false);
            });
    }
});

export const { actions, reducer } = slice;
