const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./actions");
import _ from "lodash";

const initialState = {
    modeOfLoading: {
        requestInProgress: false,
        data: []
    },
    secondaryTransportationDetails: {
        requestInProgress: false,
        data: {
            district: "",
            lsgi: "",
            nameOfAgency: "",
            location: "",
            latitude: "",
            longitude: "",
            womenGroup: "",
            total: "",
            male: "",
            female: "",
            transgender: "",
            sc: "",
            st: "",
            disabled: "",
            seniorCitizen: "",
            modeOfLoading: "",
            modeOfUnloading: "",
            modeOfTransportation: "",
            typeOfVehicles: "",
            gpsFacilityDetails: ""
        }
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.MODE_OF_LOADING_DROPDOWN_REQUEST, (state) => {
                state.modeOfLoading.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.MODE_OF_LOADING_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "modeOfLoading.requestInProgress", false);
                _.set(state, "modeOfLoading.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.MODE_OF_LOADING_DROPDOWN_FAILURE, (state) => {
                state.modeOfLoading.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_REQUEST, (state) => {
                state.secondaryTransportationDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "secondaryTransportationDetails.requestInProgress", false);
                _.set(state, "secondaryTransportationDetails.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_FAILURE, (state) => {
                state.secondaryTransportationDetails.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;

