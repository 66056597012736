export const STATE_REDUCER_KEY = "grant-management-report";

export const REPORTS = {
    GUR_1: "GUR 1",
    GUR_2: "GUR 2",
    GUR_3: "GUR 3",
    ULB_ACCESS_TO_INCENTIVE_GRANT: "ULB ACCESS TO INCENTIVE GRANT",
    ULB_ACCESS_TO_INCENTIVE_GRANT_DELAY_REPORT: "ULB ACCESS TO INCENTIVE GRANT DELAY REPORT",
    GRANT_ALLOCATION_REPORT: "GRANT ALLOCATION REPORT",
    PROJECT_DETAILS_REPORT: "PROJECT DETAILS REPORT",
    INCENTIVE_GRANT_ACCESSED_AND_UTILIZED: "INCENTIVE GRANT ACCESSED AND UTILIZED"
};
