const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./actions");
import _ from "lodash";

const initialState = {
    wasteDisposalRegionalById: {
        requestInProgress: false,
        data: {
            id: "",
            lsgi: "",
            district: "",
            regionalSite: "",
            date: "",
            wasteReceived: "",
            cumulativeWaste: ""
        }
    },
    nameDropdown: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({

    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_REQUEST, (state) => {
                state.wasteDisposalRegionalById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "wasteDisposalRegionalById.requestInProgress", false);
                _.set(state, "wasteDisposalRegionalById.data", action?.payload);
            })
            .addCase(ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_FAILURE, (state) => {
                state.wasteDisposalRegionalById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_NAME_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "nameDropdown.requestInProgress", false);
                _.set(state, "nameDropdown.data", action?.payload?.content);
            });
    }

});

export const { reducer, actions } = slice;
