import { flow } from "lodash";

const { STATE_REDUCER_KEY } = require("./constants");

const getVisitorsForm = (state) => state[STATE_REDUCER_KEY];

const visitorsForm = (details) => details.visitorsForm.data;
export const getVisitorsFormData = flow(getVisitorsForm, visitorsForm);

const success = (details) => details.success.data;
export const getSuccessData = flow(getVisitorsForm, success);

const country = (details) => details.countryList.data;
export const getCountryData = flow(getVisitorsForm, country);

const productInterest = (details) => details.productInterest.data;
export const getProductInterest = flow(getVisitorsForm, productInterest);
