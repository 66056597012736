import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {


    CREATE_VENUE_FORM: `${STATE_REDUCER_KEY}/CREATE_VENUE_FORM`,
    CREATE_VENUE_FORM_REQUEST: `${STATE_REDUCER_KEY}/CREATE_VENUE_FORM_REQUEST`,
    CREATE_VENUE_FORM_SUCCESS: `${STATE_REDUCER_KEY}/CREATE_VENUE_FORM_SUCCESS`,
    CREATE_VENUE_FORM_FAILURE: `${STATE_REDUCER_KEY}/CREATE_VENUE_FORM_FAILURE`,

    FETCH_DISTRICT_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN`,
    FETCH_DISTRICT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_REQUEST`,
    FETCH_DISTRICT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_SUCCESS`,
    FETCH_DISTRICT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_FAILURE`,

    LIST_VENUE_FORM: `${STATE_REDUCER_KEY}/LIST_VENUE_FORM`,
    LIST_VENUE_FORM_REQUEST: `${STATE_REDUCER_KEY}/LIST_VENUE_FORM_REQUEST`,
    LIST_VENUE_FORM_SUCCESS: `${STATE_REDUCER_KEY}/LIST_VENUE_FORM_SUCCESS`,
    LIST_VENUE_FORM_FAILURE: `${STATE_REDUCER_KEY}/LIST_VENUE_FORM_FAILURE`,

    DELETE_VENUE_FORM_BY_ID: `${STATE_REDUCER_KEY}/DELETE_VENUE_FORM_BY_ID`,
    DELETE_VENUE_FORM_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/DELETE_VENUE_FORM_BY_ID_REQUEST`,
    DELETE_VENUE_FORM_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_VENUE_FORM_BY_ID_SUCCESS`,
    DELETE_VENUE_FORM_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/DELETE_VENUE_FORM_BY_ID_FAILURE`,

    EDIT_VENUE_FORM: `${STATE_REDUCER_KEY}/EDIT_VENUE_FORM`,
    EDIT_VENUE_FORM_REQUEST: `${STATE_REDUCER_KEY}/EDIT_VENUE_FORM_REQUEST`,
    EDIT_VENUE_FORM_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_VENUE_FORM_SUCCESS`,
    EDIT_VENUE_FORM_FAILURE: `${STATE_REDUCER_KEY}/EDIT_VENUE_FORM_FAILURE`,


    EDIT_VENUE_FORM_BY_ID: `${STATE_REDUCER_KEY}/EDIT_VENUE_FORM_BY_ID`,
    EDIT_VENUE_FORM_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/EDIT_VENUE_FORM_BY_ID_REQUEST`,
    EDIT_VENUE_FORM_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_VENUE_FORM_BY_ID_SUCCESS`,
    EDIT_VENUE_FORM_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/EDIT_VENUE_FORM_BY_ID_FAILURE`,

    FETCH_VENUE_ID: `${STATE_REDUCER_KEY}/FETCH_VENUE_ID`,
    FETCH_VENUE_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_VENUE_ID_REQUEST`,
    FETCH_VENUE_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_VENUE_ID_SUCCESS`,
    FETCH_VENUE_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_VENUE_ID_FAILURE`


};

export const saveVenueDetails = createAction(ACTION_TYPES.CREATE_VENUE_FORM);

export const fetchDistrictDetails = createAction(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN);

export const listVenueForm = createAction(ACTION_TYPES.LIST_VENUE_FORM);

export const editVenueForm = createAction(ACTION_TYPES.EDIT_VENUE_FORM_BY_ID);

export const editVenueFormById = createAction(ACTION_TYPES.EDIT_VENUE_FORM_BY_ID);

export const deleteVenueFormById = createAction(ACTION_TYPES.DELETE_VENUE_FORM_BY_ID);

export const fetchVenue = createAction(ACTION_TYPES.FETCH_VENUE_ID);

