import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const approveDpmcApi = (data) => {
    return {
        url: API_URL.APPROVE_DPMC.PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.APPROVE_DPMC_REQUEST, ACTION_TYPES.APPROVE_DPMC_SUCCESS, ACTION_TYPES.APPROVE_DPMC_FAILURE],
            data
        }
    };
};

export const fetchDpmcByIdApi = (data) => {
    return {
        url: API_URL.APPROVE_DPMC.GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DPMC_BY_ID_REQUEST, ACTION_TYPES.FETCH_DPMC_BY_ID_SUCCESS, ACTION_TYPES.FETCH_DPMC_BY_ID_FAILURE],
            data
        }
    };
};

export const fetchRecommendationDropdownApi = (query = {}) => {
    return {
        url: API_URL.APPROVE_DPMC.DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_RECOMMENDATION_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_RECOMMENDATION_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_RECOMMENDATION_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchPreviousDpmcApi = (data) => {
    return {
        url: API_URL.APPROVE_DPMC.GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PREVIOUS_DPMC_REQUEST, ACTION_TYPES.FETCH_PREVIOUS_DPMC_SUCCESS, ACTION_TYPES.FETCH_PREVIOUS_DPMC_FAILURE],
            data
        }
    };
};
