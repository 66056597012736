import { all, takeLatest, call, put } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";

import { ACTION_TYPES } from "./actions";

import { fetchGur3Api, fetchGurReport1Api, fetchProjectDetailsApi, fetchQuarterDropDownApi, grantAllocationReportApi, gur2CumulativeToDateReportApi, gur2ReportApi, incentiveGrantPresentStatusReportApi, incentiveGrantReportApi } from "./api";
import { commonFileDownload } from "modules/common/actions";
import { API_URL } from "./apiUrls";

export function* fetchQuarterDropDown({ payload }) {
    let { name } = payload;
    yield call(handleAPIRequest, fetchQuarterDropDownApi, name);
}

export function* fetchGurReport1({ payload }) {
    yield call(handleAPIRequest, fetchGurReport1Api, payload);
}

export function* fetchProjectDetails({ payload }) {
    yield call(handleAPIRequest, fetchProjectDetailsApi, payload);
}

export function* downloadPrimaryCollectionBdw(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.PROJECT_DETAILS_DOWNLOAD,
        data: { type },
        file: { name: fileName, ext: type }
    }));
}

export function* gur2Report({ payload }) {
    yield call(handleAPIRequest, gur2ReportApi, payload);
}

export function* downloadGur2Report(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { financialYear } = action.payload;
    const { quarter } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.GUR2_DOWNLOAD,
        data: { type, financialYear, quarter },
        file: { name: fileName, ext: type }
    }));
}

export function* incentiveGrantReport({ payload }) {
    yield call(handleAPIRequest, incentiveGrantReportApi, payload);
}

export function* downloadIncentiveReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.INCENTIVE_GRANT_REPORT_DOWNLOAD,
        data: { type },
        file: { name: fileName, ext: type }
    }));
}

export function* incentiveGrantPresentStatusReport({ payload }) {
    yield call(handleAPIRequest, incentiveGrantPresentStatusReportApi, payload);
}

export function* downloadIncentivePresentStatusReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.INCENTIVE_GRANT_PRESENT_REPORT_DOWNLOAD,
        data: { type },
        file: { name: fileName, ext: type }
    }));
}

export function* gur3Report({ payload }) {
    yield call(handleAPIRequest, fetchGur3Api, payload);
}

export function* downloadGur3Report(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { financialYear } = action.payload;
    const { quarter } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.GUR3_DOWNLOAD,
        data: { type, financialYear, quarter },
        file: { name: fileName, ext: type }
    }));
}

export function* gur2CumulativeToDateReport({ payload }) {
    yield call(handleAPIRequest, gur2CumulativeToDateReportApi, payload);
}

export function* downloadGur2CumulativeToDateReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { financialYear } = action.payload;
    const { quarter } = action.payload;
    let url = "";
    if (quarter) {
        url = API_URL.GUR2_YEAR_TO_DATE_DOWNLOAD;
    } else {
        url = API_URL.GUR2_CUMULATIVE_TO_DATE_DOWNLOAD;
    }
    yield put(commonFileDownload({
        url: url,
        data: { type, financialYear, quarter },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadGur1Report(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { financialYear } = action.payload;
    const { quarter } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.GUR1_DOWNLOAD,
        data: { type, financialYear, quarter },
        file: { name: fileName, ext: type }
    }));
}

export function* grantAllocationReport({ payload }) {
    yield call(handleAPIRequest, grantAllocationReportApi, payload);
}

export function* downloadGrantAllocationReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.GRANT_ALLOCATION_REPORT_DOWNLOAD,
        data: { type },
        file: { name: fileName, ext: type }
    }));
}

export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_QUARTER_DROP_DOWN, fetchQuarterDropDown),
        takeLatest(ACTION_TYPES.FETCH_GUR_REPORT_1, fetchGurReport1),
        takeLatest(ACTION_TYPES.PROJECT_DETAILS_REPORT, fetchProjectDetails),
        takeLatest(ACTION_TYPES.DOWNLOAD_PROJECT_DETAILS_REPORT, downloadPrimaryCollectionBdw),
        takeLatest(ACTION_TYPES.FETCH_GUR2_REPORT, gur2Report),
        takeLatest(ACTION_TYPES.DOWNLOAD_GUR2_REPORT, downloadGur2Report),
        takeLatest(ACTION_TYPES.FETCH_INCENTIVE_GRANT_REPORT, incentiveGrantReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_INCENTIVE_GRANT_REPORT, downloadIncentiveReport),
        takeLatest(ACTION_TYPES.FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT, incentiveGrantPresentStatusReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_INCENTIVE_GRANT_PRESENT_STATUS, downloadIncentivePresentStatusReport),
        takeLatest(ACTION_TYPES.FETCH_GUR3_REPORT, gur3Report),
        takeLatest(ACTION_TYPES.DOWNLOAD_GUR3_REPORT, downloadGur3Report),
        takeLatest(ACTION_TYPES.FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT, gur2CumulativeToDateReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_GUR2_CUMULATIVE_OR_YEAR_REPORT, downloadGur2CumulativeToDateReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_GUR1_REPORT, downloadGur1Report),
        takeLatest(ACTION_TYPES.FETCH_GRANT_ALLOCATION, grantAllocationReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_GRANT_ALLOCATION_REPORT, downloadGrantAllocationReport)
    ]);
}
