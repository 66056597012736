import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "./apiUrls";
import { ACTION_TYPES } from "./actions";

export const addVisitorsFormSave = (data) => {
    return {
        url: API_URL.VISITORS_FORM.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.ADD_VISITORS_FORM_REQUEST, ACTION_TYPES.ADD_VISITORS_FORM_SUCCESS, ACTION_TYPES.ADD_VISITORS_FORM_FAILURE],
            data
        }
    };
};

export const listCountry = () => {
    return {
        url: API_URL.LIST_COUNTRY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_COUNTRY_REQUEST, ACTION_TYPES.LIST_COUNTRY_SUCCESS, ACTION_TYPES.LIST_COUNTRY_FAILURE],
            data: { dropDown: true, pageSize: 200 }
        }
    };
};

export const listProductInterest = () => {
    return {
        url: API_URL.PRODUCT_INTEREST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.PRODUCT_INTEREST_REQUEST, ACTION_TYPES.PRODUCT_INTEREST_SUCCESS, ACTION_TYPES.PRODUCT_INTEREST_FAILURE],
            data: { dropDown: true, pageSize: 200 }
        }
    };
};
