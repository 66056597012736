import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_MCF_RRF_DETAILS_LIST: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS_LIST`,
    FETCH_MCF_RRF_DETAILS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS_LIST_REQUEST`,
    FETCH_MCF_RRF_DETAILS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS_LIST_SUCCESS`,
    FETCH_MCF_RRF_DETAILS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS_LIST_FAILURE`,

    FETCH_MCF_RRF_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS_BY_ID`,
    FETCH_MCF_RRF_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS_BY_ID_REQUEST`,
    FETCH_MCF_RRF_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS_BY_ID_SUCCESS`,
    FETCH_MCF_RRF_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MCF_RRF_DETAILS_BY_ID_FAILURE`,

    POST_MCF_RRF_DETAILS: `${STATE_REDUCER_KEY}/POST_MCF_RRF_DETAILS`,
    POST_MCF_RRF_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_MCF_RRF_DETAILS_REQUEST`,
    POST_MCF_RRF_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_MCF_RRF_DETAILS_SUCCESS`,
    POST_MCF_RRF_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_MCF_RRF_DETAILS_FAILURE`,

    PATCH_MCF_RRF_DETAILS: `${STATE_REDUCER_KEY}/PATCH_MCF_RRF_DETAILS`,
    PATCH_MCF_RRF_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_MCF_RRF_DETAILS_REQUEST`,
    PATCH_MCF_RRF_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_MCF_RRF_DETAILS_SUCCESS`,
    PATCH_MCF_RRF_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_MCF_RRF_DETAILS_FAILURE`,

    DELETE_MCF_RRF_DETAILS: `${STATE_REDUCER_KEY}/DELETE_MCF_RRF_DETAILS`,
    DELETE_MCF_RRF_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_MCF_RRF_DETAILS_REQUEST`,
    DELETE_MCF_RRF_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_MCF_RRF_DETAILS_SUCCESS`,
    DELETE_MCF_RRF_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_MCF_RRF_DETAILS_FAILURE`,

    FETCH_TYPE_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_TYPE_DROPDOWN`,
    FETCH_TYPE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TYPE_DROPDOWN_REQUEST`,
    FETCH_TYPE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TYPE_DROPDOWN_SUCCESS`,
    FETCH_TYPE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TYPE_DROPDOWN_FAILURE`,

    DOWNLOAD_MCF_RRF_DETAILS_LIST: `${STATE_REDUCER_KEY}/DOWNLOAD_MCF_RRF_DETAILS_LIST`,
    DOWNLOAD_MCF_RRF_DETAILS_LIST_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_MCF_RRF_DETAILS_LIST_REQUEST`,
    DOWNLOAD_MCF_RRF_DETAILS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_MCF_RRF_DETAILS_LIST_SUCCESS`,
    DOWNLOAD_MCF_RRF_DETAILS_LIST_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_MCF_RRF_DETAILS_LIST_FAILURE`
};

export const fetchMcfRrfDetailsList = createAction(ACTION_TYPES.FETCH_MCF_RRF_DETAILS_LIST);
export const fetchMcfRrfDetailsById = createAction(ACTION_TYPES.FETCH_MCF_RRF_DETAILS_BY_ID);
export const postMcfRrfDetails = createAction(ACTION_TYPES.POST_MCF_RRF_DETAILS);
export const patchMcfRrfDetails = createAction(ACTION_TYPES.PATCH_MCF_RRF_DETAILS);
export const deleteMcfRrfDetails = createAction(ACTION_TYPES.DELETE_MCF_RRF_DETAILS);
export const fetchTypeDropdown = createAction(ACTION_TYPES.FETCH_TYPE_DROPDOWN);
export const downloadMcfRrfDetailsList = createAction(ACTION_TYPES.DOWNLOAD_MCF_RRF_DETAILS_LIST);
