import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadContent, getPayloadContentDetails } from "utils/apiUtils";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    userRegistrationDetails: {
        requestInProgress: false,
        data: {
            userName: "",
            userPassword: "",
            confirmPassword: "",
            firstName: "",
            middleName: "",
            lastName: "",
            address: "",
            genderId: "",
            userEffectiveFrom: null,
            userEffectiveTo: null,
            typeOfUser: "",
            activeProfile: {
                mobileNo: "",
                alternateNo: "",
                lsgiType: "",
                displayName: "",
                lsgi: "",
                ward: "",
                district: "",
                profileEffectiveFrom: null,
                profileEffectiveTo: null,
                designation: "",
                emailId: "",
                trainingOrganizationId: "",
                loginType: "",
                userType: "",
                imageId: ""
            }
        }
    },
    signUpForm: {
        requestInProgress: false,
        confirm: false,
        data: {
            userName: "",
            userPassword: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            genderId: "",
            emailId: "",
            mobileNo: "",
            activeProfile: {
                loginType: "",
                userType: ""
            }
        }
    },
    checkAvailability: {
        checkInProgress: false,
        availability: true,
        error: ""
    },
    genderDropdownOptions: {
        data: [],
        requestInProgress: false
    },
    districtDropdownOptions: {
        data: [],
        requestInProgress: false
    },
    LSGITypeDropdownOptions: {
        data: [],
        requestInProgress: false
    },
    LSGIDropdownOptions: {
        data: [],
        requestInProgress: false
    },
    wardDropdownOptions: {
        data: [],
        requestInProgress: false
    },
    organizationDropdownOptions: {
        data: [],
        requestInProgress: false
    },
    designationDropdownOptions: {
        data: [],
        requestInProgress: false
    },
    loginTypeDropdownOptions: {
        data: [],
        requestInProgress: false
    },
    userTypeDropdownOptions: {
        data: [],
        requestInProgress: false
    },
    listJSONDropdown: {},
    listJSONDropdownName: {},
    modal: {
        openProfileModal: false,
        openSignatureModal: false
    },
    cropData: null,
    filterFields: {
        district: ""
    },
    verified: {
        SMS: {},
        EMAIL: {}
    },
    changePassword: {
        requestInProgress: false,
        data: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }
    },
    rolesName: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clearVerification: (state) => {
            state.verified = initialState.verified;
        },
        clear: (state) => {
            state.changePassword = initialState.changePassword;
            state.checkAvailability = initialState.checkAvailability;
            state.userRegistrationDetails = initialState.userRegistrationDetails;
        },
        clearUserRegisterById: (state) => {
            state.userRegistrationDetails = initialState.userRegistrationDetails;
        },
        setRegistrationDetails: (state, action) => {
            state.userRegistrationDetails.data = action.payload;
        },
        setUserType: (state, action) => {
            state.typeOfUser = action.payload;
        },
        clearCheckAvailability: (state) => {
            state.checkAvailability = initialState.checkAvailability;
        },
        setOpenProfileModal: (state, action) => {
            state.modal.openProfileModal = action.payload;
        },
        setOpenSignatureModal: (state, action) => {
            state.modal.openSignatureModal = action.payload;
        },
        setCropData: (state, action) => {
            state.cropData = action.payload;
        },
        setJSON: (state, { payload: { key, value } = {} }) => {
            _.set(state, `listJSONDropdown.${key}`, value);
        },
        setFirstNameJSON: (state, { payload: { key, value } = {} }) => {
            _.set(state, `listJSONDropdownName.${key}`, value);
        },
        setVerified: (state, action) => {
            state.verified = { ...state.verified, ...action.payload };
        },
        clearSignUpForm: (state) => {
            state.signUpForm = initialState.signUpForm;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_GENDER_REQUEST, (state) => {
            state.genderDropdownOptions.requestInProgress = true;
        })
            .addCase(ACTION_TYPES.FETCH_GENDER_SUCCESS, (state, action) => {
                state.genderDropdownOptions = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_GENDER_FAILURE, (state) => {
                state.genderDropdownOptions.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_REGISTER_DETAILS_BY_ID_REQUEST, (state) => {
                state.userRegistrationDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_REGISTER_DETAILS_BY_ID_SUCCESS, (state, action) => {
                let payloadData = getPayloadContentDetails(action);
                _.set(state, "userRegistrationDetails.requestInProgress", false);
                _.set(state, "userRegistrationDetails.data", payloadData);

            })
            .addCase(ACTION_TYPES.FETCH_REGISTER_DETAILS_BY_ID_FAILURE, (state) => {
                state.userRegistrationDetails.requestInProgress = false;
                state.error = "Something went wrong";
            })
            .addCase(ACTION_TYPES.FETCH_ORGANIZATION_DROPDOWN_REQUEST, (state) => {
                state.wardDropdownOptions.organizationDropdownOptions = false;
            })
            .addCase(ACTION_TYPES.FETCH_ORGANIZATION_DROPDOWN_SUCCESS, (state, action) => {
                state.organizationDropdownOptions = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN_REQUEST, (state) => {
                state.designationDropdownOptions.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN_SUCCESS, (state, action) => {
                state.designationDropdownOptions = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_USER_TYPE_DROPDOWN_REQUEST, (state) => {
                state.userTypeDropdownOptions.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_USER_TYPE_DROPDOWN_SUCCESS, (state, action) => {
                state.userTypeDropdownOptions = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_LOGIN_TYPE_DROPDOWN_REQUEST, (state) => {
                state.loginTypeDropdownOptions.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_LOGIN_TYPE_DROPDOWN_SUCCESS, (state, action) => {
                state.loginTypeDropdownOptions = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.CHECK_AVAILABILITY_REQUEST, (state) => {
                state.checkAvailability.checkInProgress = true;
                state.checkAvailability.error = "";
            })
            .addCase(ACTION_TYPES.CHECK_AVAILABILITY_SUCCESS, (state, action) => {
                state.checkAvailability.checkInProgress = false;
                if (action.payload) {
                    state.checkAvailability.error = "User Name not available";
                }
                state.checkAvailability.availability = action.payload;
            })
            .addCase(ACTION_TYPES.CHECK_AVAILABILITY_FAILURE, (state) => {
                state.checkAvailability.checkInProgress = false;
            });
        builder.addCase(ACTION_TYPES.SIGN_UP_SUCCESS, (state) => {
            // _.set(state, "signUpForm.requestInProgress", false);
            // _.set(state, "signUpForm.confirm", payload.content[0]);
            state.signUpForm.confirm = true;
        })
        .addCase(ACTION_TYPES.ROLES_DROPDOWN_REQUEST, (state) => {
            state.rolesName.requestInProgress = true;
        })
        .addCase(ACTION_TYPES.ROLES_DROPDOWN_SUCCESS, (state, action) => {
            _.set(state, "rolesName.requestInProgress", false);
            _.set(state, "rolesName.data", action.payload.content);
        })
        .addCase(ACTION_TYPES.ROLES_DROPDOWN_FAILURE, (state) => {
            state.rolesName.requestInProgress = false;
        });
    }
});

export const { actions, reducer } = slice;
