import { all, takeLatest, take, call, put, fork } from "redux-saga/effects";
import { ACTION_TYPES } from "./actions";
import { fetchTargetApi, editPlanByIdApi, fetchCourseApi, fetchDesignationApi, savePlanAPICall, fetchTrainerNeedIdApi, deleteTrainerPlanByIdApi, editTrainingPlanApi, fetchTrainingPlanFormById } from "./api";
import { handleAPIRequest } from "../../../utils/http";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { actions as commonActions } from "modules/common/slice";
import { navigateTo } from "modules/common/actions";
import { TRAINING_PATH as PATH } from "../paths";
import { MODULE_PATH } from "../../paths";
import * as api from "./api";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { TABLE_IDS } from "common/constants";
import _ from "lodash";


export function* listTrainingPlanFn({ payload }) {

    yield fork(handleAPIRequest, api.listPlanAPICall, payload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PLAN_DETAILS_SUCCESS, ACTION_TYPES.FETCH_PLAN_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_PLAN);
}
export function* deleteTrainerPlanByIdFn({ payload: id }) {
    yield fork(handleAPIRequest, deleteTrainerPlanByIdApi, { id });
    const responseAction = yield take([ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID_SUCCESS, ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("training_plan") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listTrainingPlanFn, { payload: tablePayload });
    }
}
export function* saveTrainingPlan({ payload }) {
    let { id } = payload;
    const newFiles = payload.attachmentPayload?.multimediaList.filter(item => !(item.id));
    if (newFiles && newFiles.length > 0) {
        let attachments = {};
        attachments.multimediaList = newFiles;
        payload.attachmentPayload = attachments;
    } else {
        if (newFiles && newFiles.length === 0) {
            payload = _.omit(payload, ["attachmentPayload"]);
            payload = _.omit(payload, ["attachmentIds"]);
        }
    }

    if (id) {
        yield fork(handleAPIRequest, editTrainingPlanApi, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_PLAN_FORM_SUCCESS, ACTION_TYPES.EDIT_PLAN_FORM_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_PLAN_FORM_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("training_plan") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.PLAN.LIST}`));
        }
    } else {

        yield fork(handleAPIRequest, savePlanAPICall, payload);
        const responseAction = yield take([ACTION_TYPES.SAVE_PLAN_SUCCESS, ACTION_TYPES.SAVE_PLAN_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SAVE_PLAN_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("training_plan") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.PLAN.LIST}`));
        }
    }
}

export function* fetchPlanByIdFn({ payload: id }) {
    yield call(handleAPIRequest, fetchTrainingPlanFormById, { id });
}

export function* fetchTrainerNeedId(action) {
    yield call(handleAPIRequest, fetchTrainerNeedIdApi, action.payload);
}

export function* fetchTargetGroup(action) {
    yield call(handleAPIRequest, fetchTargetApi, action.payload);
}
export function* fetchDesignation({ payload }) {
    const { targetGroupId, tnaId } = payload;
    yield call(handleAPIRequest, fetchDesignationApi, targetGroupId, tnaId);
}
export function* fetchCourse(action) {
    yield call(handleAPIRequest, fetchCourseApi, action.payload);
}
export function* editTrainingPlanById({ payload }) {
    yield call(handleAPIRequest, editPlanByIdApi, payload);
}

export function* fetchFilesById({ payload }) {
    yield call(handleAPIRequest, api.getFileById, payload);
}
export function* deleteFile({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, api.deleteFileById, id, fileId);
}
export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SAVE_PLAN_DETAILS, saveTrainingPlan),
        takeLatest(ACTION_TYPES.FETCH_PLAN_DETAILS, listTrainingPlanFn),
        takeLatest(ACTION_TYPES.FETCH_TRAINER_NEED_ID, fetchTrainerNeedId),
        takeLatest(ACTION_TYPES.FETCH_PLAN_BY_ID, fetchPlanByIdFn),
        takeLatest(ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID, deleteTrainerPlanByIdFn),
        takeLatest(ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY, fetchTargetGroup),
        takeLatest(ACTION_TYPES.FETCH_PLAN_DESIGNATION, fetchDesignation),
        takeLatest(ACTION_TYPES.FETCH_COURSE_PLAN, fetchCourse),
        takeLatest(ACTION_TYPES.EDIT_PLAN_BY_ID, editTrainingPlanById),
        takeLatest(ACTION_TYPES.FETCH_FILE, fetchFilesById),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFile)
    ]);
}
