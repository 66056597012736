export const TRAINING_PATH = {

    PLAN: {
        LIST: "planList",
        SAVE: "planSave"
    },
    NEED: {
        LIST: "needList",
        SAVE: "needSave"
    },
    VENUE: {
        LIST: "venueList",
        SAVE: "venueSave"
    },
    BATCH: {
        LIST: "batchList",
        SAVE: "batchSave",
        VIEW: "viewParticipants",
        ADD: "addBatch",
        ISSUE_CERTIFICATE: "issueCertificate"
    },
    COURSE: {
        LIST: "courseList",
        SAVE: "courseAdd",
        ADD_QUESTION: "addQuestionnaire",
        QUESTION_LIST: "questionList"
    },
    SCHEDULE: {
        LIST: "listSchedule",
        SAVE: "scheduleSave",
        ATTENDANCE: "markAttendance"
    },
    CERTIFICATE: {
        LIST: "listCertificate",
        SAVE: "saveCertificate"

    },
    TRAINING_CONDUCTED: {
        LIST: "reports/trainingConducted"
    },
    TRAINING_SCHEDULED: {
        LIST: "reports/trainingScheduled"
    },
    TCM_SUMMARY: {
        LIST: "reports/trainingSummary"
    },
    TCM_PARTICIPANTS: {
        LIST: "reports/trainingParticipants"
    },
    TCM_PARTICIPANTS_ANALYSIS: {
        LIST: "reports/trainingParticipantsAnalysis"
    },
    TCM_USER_MANUAL: {
        LIST: "user-manual"
    }

};
