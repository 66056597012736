import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const saveCertificateAPI = (data) => ({
    url: API_URL.TRAINING_CERTIFICATE.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_CERTIFICATE_DETAILS_REQUEST, ACTION_TYPES.SAVE_CERTIFICATE_DETAILS_SUCCESS, ACTION_TYPES.SAVE_CERTIFICATE_DETAILS_FAILURE],
        data
    }
});

export const listTrainingCertificateApi = (data) => {
    return {
        url: API_URL.TRAINING_CERTIFICATE.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_CERTIFICATE_DETAILS_REQUEST, ACTION_TYPES.LIST_CERTIFICATE_DETAILS_SUCCESS, ACTION_TYPES.LIST_CERTIFICATE_DETAILS_FAILURE],
            data
        }
    };
};

export const fetchBatchApi = () => {
    return {
        url: API_URL.TRAINING_CERTIFICATE.BATCH,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BATCH_REQUEST, ACTION_TYPES.FETCH_BATCH_SUCCESS, ACTION_TYPES.FETCH_BATCH_FAILURE]

        }
    };
};

export const fetchTemplateApi = () => {
    return {
        url: API_URL.TRAINING_CERTIFICATE.TEMPLATE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TEMPLATE_REQUEST, ACTION_TYPES.FETCH_TEMPLATE_SUCCESS, ACTION_TYPES.FETCH_TEMPLATE_FAILURE]
        }
    };
};

export const fetchDesignationApi = () => {
    return {
        url: API_URL.TRAINING_CERTIFICATE.DESIGNATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DESIGNATION_REQUEST, ACTION_TYPES.FETCH_DESIGNATION_SUCCESS, ACTION_TYPES.FETCH_DESIGNATION_FAILURE]
        }
    };
};

export const fetchVenueApi = () => {
    return {
        url: API_URL.TRAINING_CERTIFICATE.VENUE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_VENUE_REQUEST, ACTION_TYPES.FETCH_VENUE_SUCCESS, ACTION_TYPES.FETCH_VENUE_FAILURE]
        }
    };
};

export const deleteCertificateByIdAPI = (id) => {
    return {
        url: API_URL.TRAINING_CERTIFICATE.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_CERTIFICATE_REQUEST, ACTION_TYPES.DELETE_CERTIFICATE_SUCCESS, ACTION_TYPES.DELETE_CERTIFICATE_FAILURE]
        }
    };
};

export const editCertificateAPI = (data) => {
    return {
        url: API_URL.TRAINING_CERTIFICATE.EDIT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_CERTIFICATE_REQUEST, ACTION_TYPES.EDIT_CERTIFICATE_SUCCESS, ACTION_TYPES.EDIT_CERTIFICATE_FAILURE],
            data
        }
    };
};

export const editCertificateByIdApi = (id) => {
    return {
        url: API_URL.TRAINING_CERTIFICATE.EDIT_CERTIFICATE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.EDIT_CERTIFICATE_BY_ID_REQUEST, ACTION_TYPES.EDIT_CERTIFICATE_BY_ID_SUCCESS, ACTION_TYPES.EDIT_CERTIFICATE_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const lsgiDropdownApi = () => ({
    url: API_URL.TRAINING_CERTIFICATE.LIST_ULB_DROPDOWN,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [
            ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST,
            ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS,
            ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE
        ]
    }
});
