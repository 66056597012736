import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { I18n } from "common/components";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { actions as commonActions } from "../../common/slice";
import { ACTION_TYPES } from "./actions";
import { createLookUpCategoryType, createLookUpform, deleteLookUpCategoryType, deleteLookUpFormById, editLookUpCategoryType, editLookUpform, fetchLookUpCategoryById, fetchLookUpCategoryTypeList, fetchLookUpFormById, fetchLookUpFormCategoryIdDropDown, fetchLookUpFormList, lookUpValueNameList, nameList } from "./api";
import { navigateTo } from "modules/common/actions";
import { ADMIN_PATH as PATH } from "../paths";
import { MODULE_PATH } from "../../paths";
import { setCommonTableData, getPaginationPayload } from "../../../utils/tableUtils";
import { getTableProps } from "modules/common/selectors";


export function* fetchLookUpFormListFn({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchLookUpFormList, { ...filters, ...payload });
    const LOOK_UP = [ACTION_TYPES.FETCH_LOOKUP_FORM_LIST_SUCCESS, ACTION_TYPES.FETCH_LOOKUP_FORM_LIST_FAILURE];
    yield* setCommonTableData(LOOK_UP);
}
export function* fetchLookUpFormByIdFn({ payload: id }) {
    yield call(handleAPIRequest, fetchLookUpFormById, { id });
}
export function* fetchLookUpCategoryByIdFn({ payload: id }) {
    yield call(handleAPIRequest, fetchLookUpCategoryById, { id });
}
export function* fetchLookUpFormCategoryIdFn() {
    yield call(handleAPIRequest, fetchLookUpFormCategoryIdDropDown);
}

export function* deleteLookUpFormByIdFn({ payload }) {
    const { id } = payload;
    yield fork(handleAPIRequest, deleteLookUpFormById, { id });
    const responseAction = yield take([ACTION_TYPES.DELETE_LOOKUP_FORM_BY_ID_SUCCESS, ACTION_TYPES.DELETE_LOOKUP_FORM_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_LOOKUP_FORM_BY_ID_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("look_up") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchLookUpFormListFn, { payload: tablePayload });
    }
}

export function* submitLookUpFormValueSagaFn({ payload }) {
    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, editLookUpform, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_LOOKUP_FORM_SUCCESS, ACTION_TYPES.EDIT_LOOKUP_CATEGORY_TYPE_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_LOOKUP_FORM_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("look_up") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.LOOK_UP_FORM.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, createLookUpform, payload);
        const responseAction = yield take([ACTION_TYPES.CREATE_LOOKUP_FORM_SUCCESS, ACTION_TYPES.CREATE_LOOKUP_FORM_FAILURE]);
        if (responseAction.type === ACTION_TYPES.CREATE_LOOKUP_FORM_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("look_up") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.LOOK_UP_FORM.LIST}`));
        }

    }
}
export function* fetchLookUpCategoryTypeListFn({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchLookUpCategoryTypeList, { ...filters, ...payload });
    const LOOKUP_CATEGORY = [ACTION_TYPES.FETCH_LOOKUP_CATEGORY_TYPE_LIST_SUCCESS, ACTION_TYPES.FETCH_LOOKUP_CATEGORY_TYPE_LIST_FAILURE];
    yield* setCommonTableData(LOOKUP_CATEGORY);
}

export function* submitCategoryTypeValuesFn({ payload }) {
    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, editLookUpCategoryType, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_LOOKUP_CATEGORY_TYPE_SUCCESS, ACTION_TYPES.EDIT_LOOKUP_CATEGORY_TYPE_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_LOOKUP_CATEGORY_TYPE_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("lookup_category") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.LOOK_UP_CATEGORY_TYPE.LIST}`));
        }

    } else {
        yield fork(handleAPIRequest, createLookUpCategoryType, payload);
        const responseAction = yield take([ACTION_TYPES.CREATE_LOOKUP_CATEGORY_TYPE_SUCCESS, ACTION_TYPES.CREATE_LOOKUP_CATEGORY_TYPE_FAILURE]);
        if (responseAction.type === ACTION_TYPES.CREATE_LOOKUP_CATEGORY_TYPE_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("lookup_category") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.LOOK_UP_CATEGORY_TYPE.LIST}`));
        }

    }
}

export function* deleteLookUpCategoryTypeFn({ payload }) {
    const { id } = payload;
    yield fork(handleAPIRequest, deleteLookUpCategoryType, { id });
    const responseAction = yield take([ACTION_TYPES.DELETE_LOOKUP_CATEGORY_TYPE_LIST_SUCCESS, ACTION_TYPES.DELETE_LOOKUP_CATEGORY_TYPE_LIST_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_LOOKUP_CATEGORY_TYPE_LIST_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("lookup_category") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchLookUpCategoryTypeListFn, { payload: tablePayload });
    }
}
export function* fetchNameList({ payload }) {
    yield call(handleAPIRequest, nameList, payload);
}
export function* fetchLookUpValueNameList({ payload }) {
    yield call(handleAPIRequest, lookUpValueNameList, payload);
}

export default function* lookupSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_LOOKUP_FORM_LIST, fetchLookUpFormListFn),
        takeLatest(ACTION_TYPES.FETCH_LOOKUP_FORM_BY_ID, fetchLookUpFormByIdFn),
        takeLatest(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_BY_ID, fetchLookUpCategoryByIdFn),
        takeLatest(ACTION_TYPES.DELETE_LOOKUP_FORM_BY_ID, deleteLookUpFormByIdFn),
        takeLatest(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_DROPDOWN, fetchLookUpFormCategoryIdFn),
        takeLatest(ACTION_TYPES.SUBMIT_LOOKUP_FORM_VALUES, submitLookUpFormValueSagaFn),
        takeLatest(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_TYPE_LIST, fetchLookUpCategoryTypeListFn),
        takeLatest(ACTION_TYPES.DELETE_LOOKUP_CATEGORY_TYPE_LIST, deleteLookUpCategoryTypeFn),
        takeLatest(ACTION_TYPES.SUBMIT_CATEGORY_TYPE_VALUES, submitCategoryTypeValuesFn),
        takeLatest(ACTION_TYPES.FETCH_NAME_LIST, fetchNameList),
        takeLatest(ACTION_TYPES.FETCH_LOOK_UP_VALUE_NAME_LIST, fetchLookUpValueNameList)
    ]);
}
