import { createSlice } from "@reduxjs/toolkit";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const DRAFT_PLAN_DETAILS = {
    draftSwmPlanId: "",
    draftSwmPlanName: "",
    remarks: "",
    dataList: {},
    attachmentPayload: { multimediaList: [] }
};

const initialState = {
    draftPlanById: {
        requestInProgress: false,
        data: {
            ...DRAFT_PLAN_DETAILS
        }
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.draftPlanById = initialState.draftPlanById;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.DRAFT_PLAN_BY_ID_SUCCESS, (state, { payload }) => {
                payload.content.map((name) => {
                    _.set(state, "draftPlanById.data.draftSwmPlanId", name?.draftSwmPlanId);
                    _.set(state, "draftPlanById.data.draftSwmPlanName", name?.draftSwmPlanName);
                    _.set(state, "draftPlanById.data.remarks", name?.remarks);
                    _.set(state, "draftPlanById.requestInProgress", false);
                });
            })
            .addCase(ACTION_TYPES.LIST_DRAFT_PLAN_BY_ID_SUCCESS, (state, { payload }) => {
                let response = [];
                payload.content?.map((name) => {
                    response.push(name);
                });
                _.set(state, "draftPlanById.data.dataList", response);
            });
    }

});

export const { actions, reducer } = slice;
