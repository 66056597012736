import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const approveSpmcByIdApi = (data) => {
    return {
        url: API_URL.APPROVE_DPMC.GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.APPROVE_SPMC_BY_ID_REQUEST, ACTION_TYPES.APPROVE_SPMC_BY_ID_SUCCESS, ACTION_TYPES.APPROVE_SPMC_BY_ID_FAILURE],
            data
        }
    };
};

export const fetchPreviousSpmcApi = (data) => {
    return {
        url: API_URL.APPROVE_DPMC.GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PREVIOUS_SPMC_REQUEST, ACTION_TYPES.FETCH_PREVIOUS_SPMC_SUCCESS, ACTION_TYPES.FETCH_PREVIOUS_SPMC_FAILURE],
            data
        }
    };
};
