import { store } from "app/store";

export const getUserDetails = () => {
    let { profileDetails: { userDetails = {} } = {} } = store.getState();
    return userDetails || {};
};

export const getActiveUserType = () => {
    let { data: { activeProfile: { userType = {} } = {} } = {} } = getUserDetails();
    return userType || {};
};

export const getActiveUserId = () => {
    let { data: { activeProfile: { userId } = {} } = {} } = getUserDetails();
    return userId;
};

export const getActiveRoleId = () => {
    let { data: { activeRole: { id } = {} } = {} } = getUserDetails();
    return id;
};
