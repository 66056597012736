import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    AUTHENTICATE_USER: `${STATE_REDUCER_KEY}/AUTHENTICATE_USER`,
    AUTHENTICATE_USER_REQUEST: `${STATE_REDUCER_KEY}/AUTHENTICATE_USER_REQUEST`,
    AUTHENTICATE_USER_SUCCESS: `${STATE_REDUCER_KEY}/AUTHENTICATE_USER_SUCCESS`,
    AUTHENTICATE_USER_FAILURE: `${STATE_REDUCER_KEY}/AUTHENTICATE_USER_FAILURE`,

    FETCH_USER_PROFILE: `${STATE_REDUCER_KEY}/FETCH_USER_PROFILE`,
    FETCH_USER_PROFILE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_PROFILE_REQUEST`,
    FETCH_USER_PROFILE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_PROFILE_SUCCESS`,
    FETCH_USER_PROFILE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_PROFILE_FAILURE`,

    GET_USER_DETAILS: `${STATE_REDUCER_KEY}/GET_USER_DETAILS`,
    GET_USER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/GET_USER_DETAILS_REQUEST`,
    GET_USER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/GET_USER_DETAILS_SUCCESS`,
    GET_USER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/GET_USER_DETAILS_FAILURE`,

    POST_AUTHENTICATION_DETAILS: `${STATE_REDUCER_KEY}/POST_USER_DETAILS`,
    POST_AUTHENTICATION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_USER_DETAILS_REQUEST`,
    POST_AUTHENTICATION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_USER_DETAILS_SUCCESS`,
    POST_AUTHENTICATION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_USER_DETAILS_FAILURE`,

    POST_OTP: `${STATE_REDUCER_KEY}/POST_OTP`,
    POST_OTP_REQUEST: `${STATE_REDUCER_KEY}/POST_OTP_REQUEST`,
    POST_OTP_SUCCESS: `${STATE_REDUCER_KEY}/POST_OTP_SUCCESS`,
    POST_OTP_FAILURE: `${STATE_REDUCER_KEY}/POST_OTP_FAILURE`,

    POST_RESET_PASSWORD: `${STATE_REDUCER_KEY}/POST_RESET_PASSWORD`,
    POST_RESET_PASSWORD_REQUEST: `${STATE_REDUCER_KEY}/POST_RESET_PASSWORD_REQUEST`,
    POST_RESET_PASSWORD_SUCCESS: `${STATE_REDUCER_KEY}/POST_RESET_PASSWORD_SUCCESS`,
    POST_RESET_PASSWORD_FAILURE: `${STATE_REDUCER_KEY}/POST_RESET_PASSWORD_FAILURE`,

    GET_OTP: `${STATE_REDUCER_KEY}/GET_OTP`,
    GET_OTP_REQUEST: `${STATE_REDUCER_KEY}/GET_OTP_REQUEST`,
    GET_OTP_SUCCESS: `${STATE_REDUCER_KEY}/GET_OTP_SUCCESS`,
    GET_OTP_FAILURE: `${STATE_REDUCER_KEY}/GET_OTP_FAILURE`,

    SET_OTP_DETAILS: `${STATE_REDUCER_KEY}/SET_OTP_DETAILS`

};

export const authenticateUserDetails = createAction(
    ACTION_TYPES.AUTHENTICATE_USER
);
export const getUserDetails = createAction(
    ACTION_TYPES.GET_USER_DETAILS
);
export const postAuthDetails = createAction(
    ACTION_TYPES.POST_AUTHENTICATION_DETAILS
);
export const postOtp = createAction(
    ACTION_TYPES.POST_OTP
);
export const postResetPassword = createAction(
    ACTION_TYPES.POST_RESET_PASSWORD
);
export const getOtp = createAction(
    ACTION_TYPES.GET_OTP
);
export const setOtp = createAction(ACTION_TYPES.SET_OTP_DETAILS);

export const fetchUserDetails = createAction(ACTION_TYPES.FETCH_USER_PROFILE);
