import { I18n } from "common/components";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { approveMunicipalApi, approveMunicipalById, rejectMunicipalApi } from "./api";
import { toEpoch } from "utils/dateUtils";

const formatData = (rest) => {
    const { val } = rest;
    const data = { planId: Number(val.planId), mcResolutionDate: toEpoch(val.mcResolutionDate), mcResolutionNumber: val.mcResolutionNumber, mcRemarks: val.mcRemarks };
    return data;
};

const formatGet = (rest) => {
    const { val } = rest;
    const data = { swmPlanId: Number(val.planId), revision: Number(val.revision) };
    return data;
};

export function* approveMunicipal(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, approveMunicipalApi, formatData(rest));
    const responseAction = yield take([ACTION_TYPES.APPROVE_MUNICIPAL_SUCCESS, ACTION_TYPES.APPROVE_MUNICIPAL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_MUNICIPAL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approved", { type: I18n("draft_plan") }) }));
        yield call(handleAPIRequest, approveMunicipalById, formatGet(rest));
    }
}

export function* rejectMunicipal(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, rejectMunicipalApi, formatData(rest));
    const responseAction = yield take([ACTION_TYPES.REJECT_MUNICIPAL_SUCCESS, ACTION_TYPES.REJECT_MUNICIPAL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_MUNICIPAL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("rejected", { type: I18n("draft_plan") }) }));
    }
}

export function* approvalDetailsById({ payload }) {
    yield call(handleAPIRequest, approveMunicipalById, payload);
}

export default function* registerSaga() {
    yield all([
        takeLatest(ACTION_TYPES.APPROVE_MUNICIPAL, approveMunicipal),
        takeLatest(ACTION_TYPES.REJECT_MUNICIPAL, rejectMunicipal),
        takeLatest(ACTION_TYPES.APPROVAL_MUNICIPAL_BY_ID, approvalDetailsById)
    ]);
}
