import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import { COMMON_TABLE_PAGINATION } from "modules/common/constants";
import { getPayloadContent } from "utils/apiUtils";


const initialState = {
    tablePagination: { ...COMMON_TABLE_PAGINATION },
    trainingPlanForm: {
        requestInProgress: false,
        data: {}
    },
    tnaId: [],
    tableFilter: {},
    searchKey: {},
    tnaName: [],
    targetGroupId: [],
    designationId: [],
    course: [],
    attachmentPayload: { multimediaList: [] },
    filterBatchName: {
        data: [],
        requestInProgress: false
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.table = initialState.table;
        },
        setAttachImages: (state, action) => {
            state.attachmentImages.push(action.payload);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.SAVE_PLAN_REQUEST, (state) => {
            _.set(state, "trainingPlanForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.SAVE_PLAN_SUCCESS, (state, action) => {
            _.set(state, "trainingPlanForm.data", action.payload);
            _.set(state, "trainingPlanForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.FETCH_PLAN_DETAILS_REQUEST, (state) => {
            _.set(state, "trainingPlanForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.FETCH_PLAN_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "trainingPlanForm.data", getPayloadContent(action));
            _.set(state, "trainingPlanForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.FETCH_TRAINER_NEED_ID_SUCCESS, (state, action) => {
            _.set(state, "tnaId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY_SUCCESS, (state, action) => {
            _.set(state, "targetGroupId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_COURSE_PLAN_SUCCESS, (state, action) => {
            _.set(state, "course", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_PLAN_DESIGNATION_SUCCESS, (state, action) => {
            _.set(state, "designationId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.EDIT_PLAN_BY_ID_REQUEST, (state) => {
            _.set(state, "trainingPlanForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.EDIT_PLAN_BY_ID_SUCCESS, (state, action) => {
            _.set(state, "trainingPlanForm.requestInProgress", false);
            _.set(state, "trainingPlanForm.data", action.payload);
            if (action.payload.attachmentPayload) {
                _.set(state, "attachmentImages", action.payload.attachmentPayload?.multimediaList);
            }
        });
        builder.addCase(ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID_SUCCESS, (state, action) => {
            _.set(state, "trainingPlanForm.data", getPayloadContent(action));
        });
    }
});

export const { actions, reducer } = slice;
