import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_LSGI_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN`,
    FETCH_LSGI_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_REQUEST`,
    FETCH_LSGI_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_SUCCESS`,
    FETCH_LSGI_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_FAILURE`,

    FETCH_DISTRICT_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN`,
    FETCH_DISTRICT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_REQUEST`,
    FETCH_DISTRICT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_SUCCESS`,
    FETCH_DISTRICT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_FAILURE`,

    SUBMIT_PROJECT_DETAILS: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_DETAILS`,
    SUBMIT_PROJECT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_DETAILS_REQUEST`,
    SUBMIT_PROJECT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_DETAILS_SUCCESS`,
    SUBMIT_PROJECT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_DETAILS_FAILURE`,

    FETCH_ANNUAL_TRIGGER_LIST: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_TRIGGER_LIST`,
    FETCH_ANNUAL_TRIGGER_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_TRIGGER_LIST_REQUEST`,
    FETCH_ANNUAL_TRIGGER_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_TRIGGER_LIST_SUCCESS`,
    FETCH_ANNUAL_TRIGGER_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_TRIGGER_LIST_FAILURE`,

    FETCH_ANNUAL_TRIGGER_DETAILS: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_TRIGGER_DETAILS`,
    FETCH_ANNUAL_TRIGGER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_TRIGGER_DETAILS_REQUEST`,
    FETCH_ANNUAL_TRIGGER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_TRIGGER_DETAILS_SUCCESS`,
    FETCH_ANNUAL_TRIGGER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_TRIGGER_DETAILS_FAILURE`,

    SUBMIT_DPMU_APPLICATION: `${STATE_REDUCER_KEY}/SUBMIT_DPMU_APPLICATION`,
    SUBMIT_DPMU_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_DPMU_APPLICATION_REQUEST`,
    SUBMIT_DPMU_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_DPMU_APPLICATION_SUCCESS`,
    SUBMIT_DPMU_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_DPMU_APPLICATION_FAILURE`,

    SUBMIT_SPMU_APPLICATION: `${STATE_REDUCER_KEY}/SUBMIT_SPMU_APPLICATION`,
    SUBMIT_SPMU_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_SPMU_APPLICATION_REQUEST`,
    SUBMIT_SPMU_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_SPMU_APPLICATION_SUCCESS`,
    SUBMIT_SPMU_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_SPMU_APPLICATION_FAILURE`,

    FETCH_UTILIZATION_LIST: `${STATE_REDUCER_KEY}/FETCH_UTILIZATION_LIST`,
    FETCH_UTILIZATION_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_UTILIZATION_LIST_REQUEST`,
    FETCH_UTILIZATION_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_UTILIZATION_LIST_SUCCESS`,
    FETCH_UTILIZATION_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_UTILIZATION_LIST_FAILURE`,

    APPROVE_DPMU_APPLICATION: `${STATE_REDUCER_KEY}/APPROVE_DPMU_APPLICATION`,
    APPROVE_DPMU_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_DPMU_APPLICATION_REQUEST`,
    APPROVE_DPMU_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_DPMU_APPLICATION_SUCCESS`,
    APPROVE_DPMU_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_DPMU_APPLICATION_FAILURE`,

    APPROVE_SPMU_APPLICATION: `${STATE_REDUCER_KEY}/APPROVE_SPMU_APPLICATION`,
    APPROVE_SPMU_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_SPMU_APPLICATION_REQUEST`,
    APPROVE_SPMU_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_SPMU_APPLICATION_SUCCESS`,
    APPROVE_SPMU_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_SPMU_APPLICATION_FAILURE`,

    REJECT_APPLICATION: `${STATE_REDUCER_KEY}/REJECT_APPLICATION`,
    REJECT_APPLICATION_REQUEST: `${STATE_REDUCER_KEY}/REJECT_APPLICATION_REQUEST`,
    REJECT_APPLICATION_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_APPLICATION_SUCCESS`,
    REJECT_APPLICATION_FAILURE: `${STATE_REDUCER_KEY}/REJECT_APPLICATION_FAILURE`,

    FETCH_ULB_SEARCH: `${STATE_REDUCER_KEY}/FETCH_ULB_SEARCH`,
    FETCH_ULB_SEARCH_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ULB_SEARCH_REQUEST`,
    FETCH_ULB_SEARCH_SUCCESS: `${STATE_REDUCER_KEY}/ FETCH_ULB_SEARCH_SUCCESS`,
    FETCH_ULB_SEARCH_FAILURE: `${STATE_REDUCER_KEY}/ FETCH_ULB_SEARCH_FAILURE`
};

export const getAnnualTriggerList = createAction(ACTION_TYPES.FETCH_ANNUAL_TRIGGER_LIST);
export const getUtilizationList = createAction(ACTION_TYPES.FETCH_UTILIZATION_LIST);
export const annualTriggerView = createAction(ACTION_TYPES.FETCH_ANNUAL_TRIGGER_DETAILS);
export const fetchLSGIDropDown = createAction(ACTION_TYPES.FETCH_LSGI_DROPDOWN);
export const fetchDistrictDropDown = createAction(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN);
export const submitProjectDetails = createAction(ACTION_TYPES.SUBMIT_PROJECT_DETAILS);
export const filterSearchUlb = createAction(ACTION_TYPES.FETCH_ULB_SEARCH);
export const submitDpmuApplication = createAction(ACTION_TYPES.SUBMIT_DPMU_APPLICATION);
export const submitSpmuApplication = createAction(ACTION_TYPES.SUBMIT_SPMU_APPLICATION);
export const approveDpmuApplication = createAction(ACTION_TYPES.APPROVE_DPMU_APPLICATION);
export const approveSpmuApplication = createAction(ACTION_TYPES.APPROVE_SPMU_APPLICATION);
export const rejectApplication = createAction(ACTION_TYPES.REJECT_APPLICATION);
