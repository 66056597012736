import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { toEpoch } from "utils/dateUtils";

export function* fetchLandAcquisitionData({ payload }) {
    yield call(handleAPIRequest, Api.fetchLandAcquisitionDataApi, payload);
}

export function* patchLandAcquisition({ payload }) {
    let { landAcquisitionsDto, id } = payload;
    landAcquisitionsDto = landAcquisitionsDto?.map(obj => {
        let newObj = {
            ...obj,
            subProjectId: id,
            proposedDate: toEpoch(obj.proposedDate),
            actualDate: toEpoch(obj.actualDate)
        };
        return newObj;
    });
    yield fork(handleAPIRequest, Api.patchLandAcquisitionApi, { landAcquisitionsDto: landAcquisitionsDto });
    const responseAction = yield take([ACTION_TYPES.PATCH_LAND_ACQUISITION_DATA_SUCCESS, ACTION_TYPES.PATCH_LAND_ACQUISITION_DATA_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_LAND_ACQUISITION_DATA_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
        yield call(handleAPIRequest, Api.fetchLandAcquisitionDataApi, { subProjectId: id });
    }
}

export default function* landAcquisitionSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_LAND_ACQUISITION_DATA, fetchLandAcquisitionData),
        takeLatest(ACTION_TYPES.PATCH_LAND_ACQUISITION_DATA, patchLandAcquisition)
    ]);
}
