import { I18n } from "common/components";
import { Yup } from "utils/yupUtils";

export const floatRegex = /^[+-]?([0-9]*[.])?[0-9]+$/;

export const WardDetailsSchema = Yup.object({
    district: Yup.object()
        .required(I18n("is_required", { type: I18n("district") })),
    lsgi: Yup.object()
        .required(I18n("is_required", { type: I18n("ulb") })),
    ward: Yup.object()
        .required(I18n("is_required", { type: I18n("name_of_ward") })),
    wardArea: Yup.string()
        .required(I18n("is_required", { type: I18n("area_of_ward") }))
        .matches(floatRegex, (I18n("invalid_area_of_ward"))),
    population: Yup.string()
        .required(I18n("is_required", { type: I18n("population_ward") })),
    totalRoadLength: Yup.string()
        .required(I18n("is_required", { type: I18n("total_road_length") }))
        .matches(floatRegex, (I18n("invalid_total_road_length"))),
    noOfHouseHold: Yup.string()
        .required(I18n("is_required", { type: I18n("no_of_household_and_establishment") })),
    houseHoldWithTreatingMachinery: Yup.string()
        .required(I18n("is_required", { type: I18n("no_of_hh_where_treating_machinery") })),
    machineryCapacity: Yup.string()
        .required(I18n("is_required", { type: I18n("capacity_of_machinery") }))
});


