import { flow } from "lodash";

const { STATE_REDUCER_KEY } = require("./constants");

const getCommon = (state) => state[STATE_REDUCER_KEY];

const tableData = (common) => common.tableData;
export const getTableDataOfKey = flow(getCommon, tableData);

const tableRowSelection = (common) => common.tableRowSelection;
export const getRowSelectedByKey = flow(getCommon, tableRowSelection);

const tableRequestInProgress = (common) => common.tableRequestInProgress;
export const getRowTableState = flow(getCommon, tableRequestInProgress);

const tableFilters = (common) => common.tableFilters;
export const getTableFiltersByKey = flow(getCommon, tableFilters);

const tablePagination = (common) => common.tablePagination;
export const getTablePaginationByKey = flow(getCommon, tablePagination);

const table = (common) => common.table;
export const getTableProps = flow(getCommon, table);

const stateDropdown = (common) => common.stateDropdown.data;
export const getStateDropdown = flow(getCommon, stateDropdown);

const districtDropdown = (common) => common.districtDropdown.data;
export const getDistrictDropdown = flow(getCommon, districtDropdown);

const lsgiDropdown = (common) => common.LSGIDropdown.data;
export const getLSGIDropdown = flow(getCommon, lsgiDropdown);

const wardDropdown = (common) => common.wardDropdown.data;
export const getWardDropdown = flow(getCommon, wardDropdown);

const lsgiTypeDropdown = (common) => common.LSGITypeDropdown.data;
export const getLSGITypeDropdown = flow(getCommon, lsgiTypeDropdown);


const districtID = (common) => common.districtID;
export const getDistrictId = flow(getCommon, districtID);

const lsgiId = (common) => common.lsgiId;
export const getLsgiId = flow(getCommon, lsgiId);

const otpDetails = (state) => state.otpDetails.req;
export const getOtpDetails = flow(getCommon, otpDetails);

const searchFilter = (common) => common.searchFilter;
export const getSearchFilters = flow(getCommon, searchFilter);

