import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";
import { getPayloadContent } from "utils/apiUtils";

const initialState = {
  createProcurementActivity: {
    requestInProgress: false,
    data: {
      id: "",
      processStatus: "",
      procurementCategory: "",
      procurementMethod: "",
      marketApproach: "",
      amount: "",
      reviewType: "",
      vendor: "",
      activityName: ""
    }
  },
  procurementActivities: {
    requestInProgress: false,
    data: {
      processStatus: "",
      procurementCategory: "",
      procurementMethod: "",
      marketApproach: "",
      amount: "",
      reviewType: "",
      vendor: "",
      activityName: "",
      activityQuestionnaire: [],
      envCategoryId: "",
      totalAmountPaid: "",
      bidAttachments: [],
      loaAttachments: [],
      contractAttachments: [],
      woOrPoNumber: "",
      woOrPoDate: null,
      woPoValue: ""
    }
  },
  processStatusDropdownOptions: {
    data: [],
    requestInProgress: false
  },
  procurementCategoryDropdownOptions: {
    requestInProgress: false,
    data: []
  },
  marketApproachDropdownOptions: {
    requestInProgress: false,
    data: []
  },
  procurementMethodDropdownOptions: {
    requestInProgress: false,
    data: []
  },
  reviewTypeDropdownOptions: {
    requestInProgress: false,
    data: []
  },
  vendorDropdownOptions: {
    requestInProgress: false,
    data: []
  },
  createProcurementActivitySaveDisable: {
    disabled: false
  }
};

const slice = createSlice({
  initialState,
  name: STATE_REDUCER_KEY,
  reducers: {
    clearAll: () => initialState,
    openModal: (state) => {
      state.modalOpen = true;
    },
    clear: (state) => {
      state.technicalForm = initialState.technicalForm;
    },
    closeModal: (state) => {
      state.modalOpen = false;
    },
    clearCreateProcurementActivity: (state) => {
      state.createProcurementActivity = initialState.createProcurementActivity;
      state.createProcurementActivitySaveDisable = initialState.createProcurementActivitySaveDisable;
    },
    disableOnSuccess: (state) => {
      state.createProcurementActivitySaveDisable.disabled = true;
    },
    enableOnSuccess: (state) => {
      state.createProcurementActivitySaveDisable.disabled = false;
    },
    setCreateProcurementActivity: (state, action) => {
      state.createProcurementActivity.data = action.payload;
    },
    resetCreateProcurementActivity: (state) => {
      state.createProcurementActivity.data = initialState.createProcurementActivity.data;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ACTION_TYPES.SELECT_PROCESS_STATUS_SUCCESS, (state, action) => {
        state.processStatusDropdownOptions = { requestInProgress: false, data: getPayloadContent(action) };
      })
      .addCase(ACTION_TYPES.SELECT_CATEGORY_SUCCESS, (state, action) => {
        state.procurementCategoryDropdownOptions = { requestInProgress: false, data: getPayloadContent(action) };
      })
      .addCase(ACTION_TYPES.SELECT_MARKET_APPROACH_SUCCESS, (state, action) => {
        state.marketApproachDropdownOptions = { requestInProgress: false, data: getPayloadContent(action) };
      })
      .addCase(ACTION_TYPES.SELECT_PROCUREMENT_METHOD_SUCCESS, (state, action) => {
        state.procurementMethodDropdownOptions = { requestInProgress: false, data: getPayloadContent(action) };
      })
      .addCase(ACTION_TYPES.SELECT_REVIEW_TYPE_SUCCESS, (state, action) => {
        state.reviewTypeDropdownOptions = { requestInProgress: false, data: getPayloadContent(action) };
      })
      .addCase(ACTION_TYPES.SELECT_VENDOR_SUCCESS, (state, action) => {
        state.vendorDropdownOptions = { requestInProgress: false, data: getPayloadContent(action) };
      })
      .addCase(ACTION_TYPES.PROCUREMENT_ACTIVITIES_LISTING_BY_ID_SUCCESS, (state, { payload }) => {
        _.set(state, "procurementActivities.requestInProgress", false);
        _.set(state, "procurementActivities.data", payload);
        _.set(state, "createProcurementActivity.data", payload);

      })
      .addCase(ACTION_TYPES.PROJECT_LISTING_BY_ID_SUCCESS, (state, { payload }) => {
        _.set(state, "procurementActivities.requestInProgress", false);
        _.set(state, "procurementActivities.data", payload);
      });
  }
});

export const { actions, reducer } = slice;
