import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const getTrainingSchedule = state => state[STATE_REDUCER_KEY];

const scheduleForm = (schedule) => schedule.scheduleForm;
export const getScheduleForm = flow(getTrainingSchedule, scheduleForm);

const courseId = (schedule) => schedule.courseId;
export const getCourseId = flow(getTrainingSchedule, courseId);

const sessionId = (schedule) => schedule.sessionId;
export const getSessionId = flow(getTrainingSchedule, sessionId);

const sessionType = (schedule) => schedule.sessionType;
export const getSessionType = flow(getTrainingSchedule, sessionType);

const trainerId = (schedule) => schedule.trainerId;
export const getTrainerId = flow(getTrainingSchedule, trainerId);

const questionareId = (schedule) => schedule.questionareId;
export const getQuestionName = flow(getTrainingSchedule, questionareId);

const batchId = (state) => state.batchId.data;
export const getBatchId = flow(getTrainingSchedule, batchId);

const trainingOrganizationId = (schedule) => schedule.trainingOrganizationId;
export const getTrainingOrganization = flow(getTrainingSchedule, trainingOrganizationId);

const attendanceForm = (schedule) => schedule.viewBatchForm;
export const getAttendanceSaveForm = flow(getTrainingSchedule, attendanceForm);

const filterScheduleBatch = (schedule) => schedule.filterScheduleBatch.data;
export const filterScheduleDropdown = flow(getTrainingSchedule, filterScheduleBatch);
