import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_LSGI_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN`,
    FETCH_LSGI_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_REQUEST`,
    FETCH_LSGI_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_SUCCESS`,
    FETCH_LSGI_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_FAILURE`,

    SUBMIT_SWM_PLAN: `${STATE_REDUCER_KEY}/SUBMIT_SWM_PLAN`,
    SUBMIT_SWM_PLAN_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_SWM_PLAN_REQUEST`,
    SUBMIT_SWM_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_SWM_PLAN_SUCCESS`,
    SUBMIT_SWM_PLAN_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_SWM_PLAN_FAILURE`,

    LIST_SWM_PLAN: `${STATE_REDUCER_KEY}/LIST_SWM_PLAN`,
    LIST_SWM_PLAN_REQUEST: `${STATE_REDUCER_KEY}/LIST_SWM_PLAN_REQUEST`,
    LIST_SWM_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/LIST_SWM_PLAN_SUCCESS`,
    LIST_SWM_PLAN_FAILURE: `${STATE_REDUCER_KEY}/LIST_SWM_PLAN_FAILURE`,

    LIST_BY_ID_SWM_PLAN: `${STATE_REDUCER_KEY}/LIST_BY_ID_SWM_PLAN`,
    LIST_BY_ID_SWM_PLAN_REQUEST: `${STATE_REDUCER_KEY}/LIST_BY_ID_SWM_PLAN_REQUEST`,
    LIST_BY_ID_SWM_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/LIST_BY_ID_SWM_PLAN_SUCCESS`,
    LIST_BY_ID_SWM_PLAN_FAILURE: `${STATE_REDUCER_KEY}/LIST_BY_ID_SWM_PLAN_FAILURE`,

    EDIT_SWM_PLAN: `${STATE_REDUCER_KEY}/EDIT_SWM_PLAN`,
    EDIT_SWM_PLAN_REQUEST: `${STATE_REDUCER_KEY}/EDIT_SWM_PLAN_REQUEST`,
    EDIT_SWM_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_SWM_PLAN_SUCCESS`,
    EDIT_SWM_PLAN_FAILURE: `${STATE_REDUCER_KEY}/EDIT_SWM_PLAN_FAILURE`,

    DELETE_SWM_PLAN: `${STATE_REDUCER_KEY}/DELETE_SWM_PLAN`,
    DELETE_SWM_PLAN_REQUEST: `${STATE_REDUCER_KEY}/DELETE_SWM_PLAN_REQUEST`,
    DELETE_SWM_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_SWM_PLAN_SUCCESS`,
    DELETE_SWM_PLAN_FAILURE: `${STATE_REDUCER_KEY}/DELETE_SWM_PLAN_FAILURE`,

    FETCH_DISTRICT_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN`,
    FETCH_DISTRICT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_REQUEST`,
    FETCH_DISTRICT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_SUCCESS`,
    FETCH_DISTRICT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_FAILURE`,

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    UPDATE_SWM_PLAN_ATTACHMENT: `${STATE_REDUCER_KEY}/UPDATE_SWM_PLAN_ATTACHMENT`,
    UPDATE_SWM_PLAN_ATTACHMENT_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_SWM_PLAN_ATTACHMENT_REQUEST`,
    UPDATE_SWM_PLAN_ATTACHMENT_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_SWM_PLAN_ATTACHMENT_SUCCESS`,
    UPDATE_SWM_PLAN_ATTACHMENT_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_SWM_PLAN_ATTACHMENT_FAILURE`,

    ULB_SUBMITTED_TO_MC: `${STATE_REDUCER_KEY}/ULB_SUBMITTED_TO_MC`,
    ULB_SUBMITTED_TO_MC_REQUEST: `${STATE_REDUCER_KEY}/ULB_SUBMITTED_TO_MC_REQUEST`,
    ULB_SUBMITTED_TO_MC_SUCCESS: `${STATE_REDUCER_KEY}/ULB_SUBMITTED_TO_MC_SUCCESS`,
    ULB_SUBMITTED_TO_MC_FAILURE: `${STATE_REDUCER_KEY}/ULB_SUBMITTED_TO_MC_FAILURE`,

    FETCH_STATUS_LIST: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST`,
    FETCH_STATUS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST_REQUEST`,
    FETCH_STATUS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST_SUCCESS`,
    FETCH_STATUS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST_FAILURE`,

    FETCH_SWM_PLAN_REVISION_LIST: `${STATE_REDUCER_KEY}/FETCH_SWM_PLAN_REVISION_LIST`,
    FETCH_SWM_PLAN_REVISION_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SWM_PLAN_REVISION_LIST_REQUEST`,
    FETCH_SWM_PLAN_REVISION_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SWM_PLAN_REVISION_LIST_SUCCESS`,
    FETCH_SWM_PLAN_REVISION_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SWM_PLAN_REVISION_LIST_FAILURE`,

    FETCH_PREVIOUS: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS`,
    FETCH_PREVIOUS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_REQUEST`,
    FETCH_PREVIOUS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_SUCCESS`,
    FETCH_PREVIOUS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_FAILURE`,

    DOWNLOAD_SWM_PLAN: `${STATE_REDUCER_KEY}/DOWNLOAD_SWM_PLAN`,
    DOWNLOAD_SWM_PLAN_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_SWM_PLAN_REQUEST`,
    DOWNLOAD_SWM_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_SWM_PLAN_SUCCESS`,
    DOWNLOAD_SWM_PLAN_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_SWM_PLAN_FAILURE`
};

export const fetchDistrictDropDown = createAction(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN);
export const fetchLsgiDropdown = createAction(ACTION_TYPES.FETCH_LSGI_DROPDOWN);
export const submitSWMPlanActivity = createAction(ACTION_TYPES.SUBMIT_SWM_PLAN);
export const fetchSWMPlanList = createAction(ACTION_TYPES.LIST_SWM_PLAN);
export const fetchSWMPlanListById = createAction(ACTION_TYPES.LIST_BY_ID_SWM_PLAN);
export const updateSWMPlan = createAction(ACTION_TYPES.EDIT_SWM_PLAN);
export const deleteSWMPlan = createAction(ACTION_TYPES.DELETE_SWM_PLAN);
export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const updateSWMPlanAttachment = createAction(ACTION_TYPES.UPDATE_SWM_PLAN_ATTACHMENT);
export const ulbSubmittedToMc = createAction(ACTION_TYPES.ULB_SUBMITTED_TO_MC);
export const fetchStatusList = createAction(ACTION_TYPES.FETCH_STATUS_LIST);
export const fetchSwmPlanRevisionList = createAction(ACTION_TYPES.FETCH_SWM_PLAN_REVISION_LIST);
export const fetchPrevious = createAction(ACTION_TYPES.FETCH_PREVIOUS);
export const downloadSwmPlan = createAction(ACTION_TYPES.DOWNLOAD_SWM_PLAN);

