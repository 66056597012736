import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const rejectQaQcApi = (data) => {
    return {
        url: API_URL.APPROVE_QA_QC.REJECT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.REJECT_QA_QC_REQUEST, ACTION_TYPES.REJECT_QA_QC_SUCCESS, ACTION_TYPES.REJECT_QA_QC_FAILURE],
            data
        }
    };
};

export const approveQaQcApi = (data) => {
    return {
        url: API_URL.APPROVE_QA_QC.APPROVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.APPROVE_QA_QC_REQUEST, ACTION_TYPES.APPROVE_QA_QC_SUCCESS, ACTION_TYPES.APPROVE_QA_QC_FAILURE],
            data
        }
    };
};

export const approveDpmuByIdApi = (data) => {
    return {
        url: API_URL.APPROVE_DPMC.GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.APPROVE_QA_QC_BY_ID_REQUEST, ACTION_TYPES.APPROVE_QA_QC_BY_ID_SUCCESS, ACTION_TYPES.APPROVE_QA_QC_BY_ID_FAILURE],
            data
        }
    };
};

export const fetchPreviousDpmuApi = (data) => {
    return {
        url: API_URL.APPROVE_DPMC.GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PREVIOUS_DPMU_REQUEST, ACTION_TYPES.FETCH_PREVIOUS_DPMU_SUCCESS, ACTION_TYPES.FETCH_PREVIOUS_DPMU_FAILURE],
            data
        }
    };
};
