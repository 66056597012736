import { flow } from "lodash";

const { STATE_REDUCER_KEY } = require("./constants");

const getData = (state) => state[STATE_REDUCER_KEY];

const projectActivity = (state) => state.projectActivity;
export const getProjectActivityDetails = flow(getData, projectActivity);

const uom = (state) => state.uomDropdown;
export const getUomDetails = flow(getData, uom);

const Screening = (state) => state.screening.data;
export const getScreening = flow(getData, Screening);

const adpProcess = (state) => state.adpProcess.data;
export const getAdpProcess = flow(getData, adpProcess);

const groupDiscussion = (state) => state.groupDiscussion.data;
export const getGroupDiscussion = flow(getData, groupDiscussion);

const groupDiscussionCommon = (state) => state.groupDiscussionCommon.data;
export const getGroupDiscussionCommon = flow(getData, groupDiscussionCommon);

const vikasanaSeminar = (state) => state.vikasanaSeminar.data;
export const getVikasaSeminar = flow(getData, vikasanaSeminar);

const safeGuard = (state) => state.safeGuardReport.data;
export const getSafeGuard = flow(getData, safeGuard);

const remark = (state) => state.remarkDropdown.data;
export const getRemarkList = flow(getData, remark);

const environmentalSafeguard = (state) => state.environmentalSafeguard.data;
export const getEnvironmentalSafeGuard = flow(getData, environmentalSafeguard);

const safeGuardList = (state) => state.safeGuardReportList.data;
export const getSafeGuardList = flow(getData, safeGuardList);

const annualPlanYearList = (state) => state.annualPlanYearList.data;
export const getAnnualPlanYearList = flow(getData, annualPlanYearList);

const annualPlanYearId = (state) => state.annualPlanYearId.data;
export const getAnnualPlanYearId = flow(getData, annualPlanYearId);

const vikasanaSeminarCommon = (state) => state.vikasanaSeminarCommonDetails.data;
export const getVikasanaSeminarCommon = flow(getData, vikasanaSeminarCommon);

const vikasanaSeminarCommonList = (state) => state.vikasanaSeminarCommonList.data;
export const getVikasanaSeminarCommonList = flow(getData, vikasanaSeminarCommonList);

const workingGroupDiscussionList = (state) => state.workingGroupDiscussionCommonList.data;
export const getWorkingGroupDiscussionList = flow(getData, workingGroupDiscussionList);

const hscApprovalCommon = (state) => state.adpCommon.data;
export const getHscApprovalCommon = flow(getData, hscApprovalCommon);

const hscApprovalCommonList = (state) => state.adpCommonList.data;
export const getHscApprovalCommonList = flow(getData, hscApprovalCommonList);

const swmScreeningList = (state) => state.swmScreeningList.data;
export const getSwmScreeningList = flow(getData, swmScreeningList);

const progress = (state) => state.projectLevelDataList.requestInProgress;
export const getRequestInProgressSelector = flow(getData, progress);

const projectLevelData = (state) => state.projectLevelDataList.data;
export const projectLevelDataListSelector = flow(getData, projectLevelData);

const projectSocialSafeguardDropdown = (state) => state.projectSocialSafeguardDropdown.data;
export const projectSocialSafeguardDropdownSelector = flow(getData, projectSocialSafeguardDropdown);

const progressSafeguardCompliance = (state) => state.safeguardComplianceList.requestInProgress;
export const getRequestInProgressSafeguardComplianceSelector = flow(getData, progressSafeguardCompliance);

const safeguardCompliance = (state) => state.safeguardComplianceList.data;
export const safeguardComplianceListSelector = flow(getData, safeguardCompliance);

const progressCommunityConsultationList = (state) => state.communityConsultationList.requestInProgress;
export const getRequestInProgressCommunityConsultationListSelector = flow(getData, progressCommunityConsultationList);

const communityConsultationList = (state) => state.communityConsultationList.data;
export const communityConsultationListListSelector = flow(getData, communityConsultationList);

const typeOfActivityDropdown = (state) => state.typeOfActivityDropdown.data;
export const typeOfActivityDropdownSelector = flow(getData, typeOfActivityDropdown);

const phaseOneScreeningDropdown = (state) => state.phaseOneScreening.data;
export const phaseOneScreeningDropdownSelector = flow(getData, phaseOneScreeningDropdown);

const phaseTwoScreeningDropdown = (state) => state.phaseTwoScreening.data;
export const phaseTwoScreeningDropdownSelector = flow(getData, phaseTwoScreeningDropdown);

const esgScreeningDropdown = (state) => state.esgScreening.data;
export const esgScreeningDropdownSelector = flow(getData, esgScreeningDropdown);

const environmentalCategoryDropdown = (state) => state.environmentalCategory.data;
export const environmentalCategoryDropdownSelector = flow(getData, environmentalCategoryDropdown);

const esgInstrumentDropdown = (state) => state.esgInstrument.data;
export const esgInstrumentDropdownSelector = flow(getData, esgInstrumentDropdown);

const dueDiligenceDropdown = (state) => state.dueDiligence.data;
export const dueDiligenceDropdownSelector = flow(getData, dueDiligenceDropdown);

const dueDiligenceTypeDropdown = (state) => state.dueDiligenceType.data;
export const dueDiligenceTypeDropdownSelector = flow(getData, dueDiligenceTypeDropdown);

const reasonUnderEiaDropdown = (state) => state.reasonUnderEia.data;
export const reasonUnderEiaDropdownSelector = flow(getData, reasonUnderEiaDropdown);

const typeOfDueDiligenceDropdown = (state) => state.typeOfDueDiligence.data;
export const typeOfDueDiligenceDropdownSelector = flow(getData, typeOfDueDiligenceDropdown);

const esgDocumentPreparationDropdown = (state) => state.esgDocumentPreparation.data;
export const esgDocumentPreparationDropdownSelector = flow(getData, esgDocumentPreparationDropdown);

const finalEsgPreparedAndSubmittedDropdown = (state) => state.finalEsgPreparedAndSubmitted.data;
export const finalEsgPreparedAndSubmittedDropdownSelector = flow(getData, finalEsgPreparedAndSubmittedDropdown);

const finalEsgApprovalResponsibilityDropdown = (state) => state.finalEsgApprovalResponsibility.data;
export const finalEsgApprovalResponsibilityDropdownSelector = flow(getData, finalEsgApprovalResponsibilityDropdown);

const responsibilityForEsgSupervisionDropdown = (state) => state.responsibilityForEsgSupervision.data;
export const responsibilityForEsgSupervisionDropdownSelector = flow(getData, responsibilityForEsgSupervisionDropdown);

const projectDataById = (state) => state.projectDataById.data;
export const projectDataByIdSelector = flow(getData, projectDataById);

const projectDataList = (state) => state.projectDataList.data;
export const projectDataListSelector = flow(getData, projectDataList);

const projectDataListRequestInProgress = (state) => state.projectDataList.requestInProgress;
export const projectDataListRequestSelector = flow(getData, projectDataListRequestInProgress);

const typesOfWasteManagedDropdown = (state) => state.typesOfWasteManaged.data;
export const typesOfWasteManagedDropdownSelector = flow(getData, typesOfWasteManagedDropdown);

const typeOfManagementDropdown = (state) => state.typeOfManagement.data;
export const typeOfManagementDropdownSelector = flow(getData, typeOfManagementDropdown);

const arrangementRecycleDropdown = (state) => state.arrangementRecycle.data;
export const arrangementRecycleDropdownSelector = flow(getData, arrangementRecycleDropdown);

const categoryOfEddIssueDropdown = (state) => state.categoryOfEddIssue.data;
export const categoryOfEddIssueDropdownSelector = flow(getData, categoryOfEddIssueDropdown);

const sourceOfEddIdentificationDropdown = (state) => state.sourceOfEddIdentification.data;
export const sourceOfEddIdentificationDropdownSelector = flow(getData, sourceOfEddIdentificationDropdown);

const statusOfEddIssueResolutionDropdown = (state) => state.statusOfEddIssueResolution.data;
export const statusOfEddIssueResolutionDropdownSelector = flow(getData, statusOfEddIssueResolutionDropdown);

const esgIssueAffectDropdown = (state) => state.esgIssueAffect.data;
export const esgIssueAffectDropdownSelector = flow(getData, esgIssueAffectDropdown);

const permitConsentRequiredDropdown = (state) => state.permitConsentRequired.data;
export const permitConsentRequiredDropdownSelector = flow(getData, permitConsentRequiredDropdown);

const incidentClassificationDropdown = (state) => state.incidentClassification.data;
export const incidentClassificationDropdownSelector = flow(getData, incidentClassificationDropdown);

const statusOfCompensationDropdown = (state) => state.statusOfCompensation.data;
export const statusOfCompensationDropdownSelector = flow(getData, statusOfCompensationDropdown);

const incidentImplementationDropdown = (state) => state.incidentImplementation.data;
export const incidentImplementationDropdownSelector = flow(getData, incidentImplementationDropdown);

const attendeeProfileDropdown = (state) => state.attendeeProfile.data;
export const attendeeProfileDropdownSelector = flow(getData, attendeeProfileDropdown);

const responsibleAgencyDropdown = (state) => state.responsibleAgency.data;
export const responsibleAgencyDropdownSelector = flow(getData, responsibleAgencyDropdown);

const statusPermitDropdown = (state) => state.statusPermit.data;
export const statusPermitDropdownSelector = flow(getData, statusPermitDropdown);

const compendiumDataById = (state) => state.compendiumDataById.data;
export const compendiumDataByIdSelector = flow(getData, compendiumDataById);

const compendiumDataList = (state) => state.compendiumDataList.data;
export const compendiumDataListSelector = flow(getData, compendiumDataList);

const compendiumDataListRequest = (state) => state.compendiumDataList.requestInProgress;
export const compendiumDataListRequestSelector = flow(getData, compendiumDataListRequest);

const financialYearDropDown = (state) => state.financialYearDropDown.data;
export const getFinancialYearDropDown = flow(getData, financialYearDropDown);

const sulekhaProjectDropDown = (state) => state.sulekhaProjectListDropDown.data;
export const getSulekhaProjectDropDown = flow(getData, sulekhaProjectDropDown);

const sulekhaProjectListAll = (state) => state.sulekhaProjectListAll.data;
export const getSulekhaProjectListAll = flow(getData, sulekhaProjectListAll);

const sulekhaProjectById = (state) => state.sulekhaProjectById.data;
export const getSulekhaProjectById = flow(getData, sulekhaProjectById);

const projectCode = (state) => state.projectCodeDropdown.data;
export const projectCodeDropdownSelector = flow(getData, projectCode);

const facilitiesAndLocation = (state) => state.facilitiesAndLocation.data;
export const facilitiesAndLocationSelector = flow(getData, facilitiesAndLocation);

const facilitiesAndLocationProgress = (state) => state.facilitiesAndLocation.requestInProgress;
export const facilitiesAndLocationProgressSelector = flow(getData, facilitiesAndLocationProgress);

const approvalStatus = (state) => state.approvalStatus.data;
export const approvalStatusSelector = flow(getData, approvalStatus);

const socialRiskCategorization = (state) => state.socialRiskCategorization.data;
export const socialRiskCategorizationSelector = flow(getData, socialRiskCategorization);

const socialRiskCategorizationData = (state) => state.socialRiskCategorizationData.data;
export const socialRiskCategorizationDataSelector = flow(getData, socialRiskCategorizationData);
