const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./action");
import _ from "lodash";

const initialState = {
    forwardLinking: {
        requestInProgress: false,
        data: []
    },
    mcfRrfDetails: {
        requestInProgress: false,
        data: {
            lsgiId: "",
            districtId: "",
            typeOf: "",
            name: "",
            location: "",
            latitude: "",
            longitude: "",
            total: "",
            male: "",
            female: "",
            transgender: "",
            sc: "",
            st: "",
            disabled: "",
            womenGroup: "",
            seniorCitizen: "",
            capacity: "",
            serviceArea: "",
            facility: "",
            siteArea: "",
            buildingArea: "",
            numOfFloors: "",
            typeOfBuilding: "",
            typeOfAccess: "",
            widthOfRoad: "",
            accessAll: "",
            storageArea: "",
            company: "",
            detailsOfMaterialsStored: "",
            inputMaterialsDetails: "",
            outputMaterialsDetails: "",
            waterAvailability: "",
            electricityAvailability: "",
            sanitationFacilities: "",
            frequencyOfHousekeeping: "",
            officersInCharge: "",
            designation: "",
            contactNo: "",
            issuesIfAny: "",
            additionsProposed: "",
            listOfMachineries: "",
            fireFightingSystem: "",
            date: ""
        }
    },
    type: {
        requestInProgress: false,
        data: []
    },
    mcfRrfName: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FORWARD_LINKING_DROPDOWN_REQUEST, (state) => {
                state.forwardLinking.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FORWARD_LINKING_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "forwardLinking.requestInProgress", false);
                _.set(state, "forwardLinking.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.FORWARD_LINKING_DROPDOWN_FAILURE, (state) => {
                state.forwardLinking.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_MCF_RRF_DETAILS_BY_ID_REQUEST, (state) => {
                state.mcfRrfDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_MCF_RRF_DETAILS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "mcfRrfDetails.requestInProgress", false);
                _.set(state, "mcfRrfDetails.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_MCF_RRF_DETAILS_BY_ID_FAILURE, (state) => {
                state.mcfRrfDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.TYPE_OF_DEPARTMENT_DROPDOWN_REQUEST, (state) => {
                state.type.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.TYPE_OF_DEPARTMENT_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "type.requestInProgress", false);
                _.set(state, "type.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.TYPE_OF_DEPARTMENT_DROPDOWN_FAILURE, (state) => {
                state.type.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.MCF_RRF_NAME_DROPDOWN_REQUEST, (state) => {
                state.mcfRrfName.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.MCF_RRF_NAME_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "mcfRrfName.requestInProgress", false);
                _.set(state, "mcfRrfName.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.MCF_RRF_NAME_DROPDOWN_FAILURE, (state) => {
                state.mcfRrfName.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;

