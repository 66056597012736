import { InputLabel } from "@mui/material";
import { Components, I18n, Icons } from "common/components";
import { imageURL } from "common/constants";
import { ErrorMessage, Field } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import TextError from "./TextError";
import { fetchMultimediaList, fileUpload } from "modules/common/api";
import { getRequestParams } from "utils/http";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { confirmDialog } from "utils/notificationUtils";
import { useStyles } from "./style";

const CustomFileUpload = ({ isMandatory = false, label = "", fileLength = 5, name = "attachmentIds", disabled = false, mode = "", fileSizeMB }) => {
  const { Grid, Box, Card, Stack, IconButton, Tooltip } = Components;
  const { AddCircleOutline, CloseOutlined, PictureAsPdfTwoTone, TextSnippet } = Icons;
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [files, setFiles] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [formikForm, setFormikForm] = useState();
  const [fileIds, setFileIds] = useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    return () => {
      setLoading(true);
      setFiles([]);
      setAttachment([]);
      setFormikForm();
      setFileIds([]);
    };
  }, []);
  const uploadFile = (e, form) => {
    let maxSize;
    if (fileSizeMB) {
      if (mode === "xls") {
        maxSize = fileSizeMB * 1024 * 1024; // 5MB maximum file size
      } else {
        maxSize = fileSizeMB * 1024 * 1024; // 5MB maximum file size
      }
    } else {
      if (mode === "xls") {
        maxSize = 50 * 1024 * 1024; // 50MB maximum file size
      } else {
        maxSize = 10 * 1024 * 1024; // 10MB maximum file size
      }
    }
    setFormikForm(form);
    const targetFile = e.target.files[0];
    if (fileSizeMB) {
      if (mode === "xls") {
        if (targetFile.size > maxSize) {
          dispatch(confirmDialog({ title: I18n("file_size_exceeds_the_maximum_allowed_size_of_5MB"), showDenyButton: false }));
          setLoader(false);
        } else {
          setLoader(true);
        }
      } else {
        if (targetFile.size > maxSize) {
          dispatch(confirmDialog({ title: I18n("file_size_exceeds_the_maximum_allowed_size_of_5MB"), showDenyButton: false }));
          setLoader(false);
        } else {
          setLoader(true);
        }
      }
    } else {
      if (mode === "xls") {
        if (targetFile.size > maxSize) {
          dispatch(confirmDialog({ title: I18n("file_size_exceeds_the_maximum_allowed_size_of_50MB"), showDenyButton: false }));
          setLoader(false);
        } else {
          setLoader(true);
        }
      } else {
        if (targetFile.size > maxSize) {
          dispatch(confirmDialog({ title: I18n("file_size_exceeds_the_maximum_allowed_size_of_10MB"), showDenyButton: false }));
          setLoader(false);
        } else {
          setLoader(true);
        }
      }
    }

    let idx = new Date();
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const regex = /data:.*base64,/;
      const base64EncodedData = reader.result.replace(regex, "");
      const regexAll = (/\.[0-9a-z]+$/i)[0];
      const fileExtn = targetFile.name.replace(regexAll, "");
      setAttachment([
        {
          idx: idx,
          base64EncodedData: base64EncodedData,
          fileExtn: fileExtn.split(".").pop(),
          contentType: targetFile.type,
          fileSizeKb: targetFile.size,
          resourceName: targetFile.name,
          resourceDescription: "complaints"
        }
      ]);
    });
    reader.readAsDataURL(targetFile);
  };

  useEffect(() => {
    if (attachment?.length > 0) {
      const { method, url, payload } = fileUpload({ multimediaList: attachment });
      const { api, config, baseURL, data } = getRequestParams({ url, data: payload.data, method });
      api(url, { config, baseURL, data }).then((res) => {
        const ids = res?.data?.payLoad ? res.data.payLoad : [];
        if (!_.isEmpty(ids)) {
          const newFileIds = [...fileIds, ids];
          formikForm?.setFieldValue(name, newFileIds);
          setFileIds(newFileIds);
        }
      });
    }
  }, [attachment]);

  useEffect(() => {
    if (fileIds?.length > 0) {
      let idx = fileIds.map(function (obj) {
        return obj.id;
      });
      const { method, url, payload } = fetchMultimediaList(idx);
      const { api, config, baseURL, data } = getRequestParams({ url, data: payload.data, method });
      api(url, { config, baseURL, data }).then((res) => {
        const urls = res?.data?.payLoad ? res.data.payLoad : [];
        if (urls.length > 0) {
          setFiles(urls?.map(item => {
            let newUrl = { id: item.id, idx: item.id, fileType: item.contentType, url: imageURL + item.id };
            return newUrl;
          }));
        }
      }).finally(() => {
        setLoader(false);
      });
    } else {
      setFiles([]);
    }
  }, [fileIds]);

  function deleteFile(e) {
    const newFileIds = fileIds.filter((item) => item.id !== e.id);
    formikForm?.setFieldValue(name, newFileIds);
    setFileIds(newFileIds);
  }

  const downloadFile = (item) => {
    window.open(`${item}`, "_blank");
  };

  const loadFiles = (form) => {
    if (loading && form?.field?.value && !_.isEqual(_.sortBy(fileIds), _.sortBy(form?.field?.value))) {
      setFormikForm(form.form);
      setFileIds(form.field.value);
      setLoading(false);
    }

    return files.length > 0 &&
      files.map((item) => {
        return (
          <Grid key={item.url} sx={{ width: "100px", border: "1px dashed #CFE7DE", display: "flex", justifyContent: "center", borderRadius: "10px" }}>
            <Grid>
              {!disabled ? <IconButton
                inverted
                onClick={() => deleteFile(item)} sx={{ position: "relative", top: "10px", float: "right", backgroundColor: "#f9e6e2", paddingLeft: "5", paddingRight: "5", borderRadius: "5px" }}
                shape="circular" >
                <CloseOutlined sx={{ margin: "1px", fontSize: "12px", color: "red" }} />
              </IconButton> :
                ""}
              <Grid item sx={{ cursor: "pointer" }}>
                {item.fileType === "application/pdf" ?
                  (<PictureAsPdfTwoTone style={{ fontSize: "xxx-large" }} color="primary" onClick={() => downloadFile(item.url)} />)
                  : item.fileType === "application/vnd.ms-excel" ?
                    (<TextSnippet style={{ fontSize: "xxx-large" }} color="primary" onClick={() => downloadFile(item.url)} />) :
                    (<img style={{ objectFit: "fill" }} src={item.url} width={"100%"} height={"60px"} onClick={() => downloadFile(item.url)} />)}
              </Grid>
            </Grid>
          </Grid>
        );
      });
  };

  return (
    <div style={{ position: "relative" }}>
      <InputLabel className={classes.label} >{label} {isMandatory && <span style={{ color: "red", fontSize: "14px" }}> *</span>}</InputLabel>
      < Field as='select' name={name} >
        {
          (form) => {
            if (mode === "pdf" || mode === "jpeg" || mode === "png" || mode === "xls" || mode === "jpg") {
              return (
                <>
                  <Card sx={{ backgroundColor: "#fff", width: "100%", p: "5px", display: "flex", alignItems: "center", boxShadow: "none", border: "1px solid #CFE7DE", overflow: "auto" }}>
                    <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                      <Stack direction={{ sm: "row" }} spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="center" padding={"16px"}>
                        {loadFiles(form)}
                      </Stack>
                    </Box>
                    <Stack direction="row" >
                      <Grid sx={{ p: "16px" }}>
                        {!disabled && files.length < fileLength && <label htmlFor={name} >

                          {loader && <ClipLoader color="#36d7b7" active={loader} />}
                          {!loader && <Tooltip title="Upload" >

                            <AddCircleOutline size="large" sx={{
                              cursor: "pointer"
                            }} />
                          </Tooltip>}
                          <input
                            id={name}
                            style={{ display: "none" }}
                            type="file"
                            accept={mode === "pdf" ? ".pdf" : mode === "jpeg" ? ".jpeg" : mode === "png" ? ".png" : mode === "xls" ? ".xls" : mode === "kml" ? ".kml" : ".jpg"}
                            direction={{ xs: "column", sm: "row" }}
                            onChange={(event) => {
                              uploadFile(event, form.form);
                            }}
                          />
                        </label>}
                      </Grid>
                    </Stack>
                  </Card>
                </>
              );
            } else if (mode === "pdf_jpeg") {
              return (
                <>
                  <Card sx={{ backgroundColor: "#fff", width: "100%", p: "5px", display: "flex", alignItems: "center", boxShadow: "none", border: "1px solid #CFE7DE", overflow: "auto" }}>
                    <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                      <Stack direction={{ sm: "row" }} spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="center" padding={"16px"} >
                        {loadFiles(form)}
                      </Stack>
                    </Box>
                    <Stack direction="row" >
                      <Grid sx={{ p: "16px" }}>
                        {!disabled && files.length < fileLength && <label htmlFor={name} >

                          {loader && <ClipLoader color="#36d7b7" active={loader} />}
                          {!loader && <Tooltip title="Upload" >

                            <AddCircleOutline size="large" sx={{
                              cursor: "pointer"
                            }} />
                          </Tooltip>}
                          <input
                            id={name}
                            style={{ display: "none" }}
                            type="file"
                            accept=".jpg,.jpeg,.pdf"
                            direction={{ xs: "column", sm: "row" }}
                            onChange={(event) => {
                              uploadFile(event, form.form);
                            }}
                          />
                        </label>}
                      </Grid>
                    </Stack>
                  </Card>
                </>
              );
            } else {
              return (
                <>
                  <Card sx={{ backgroundColor: "#fff", width: "100%", p: "5px", display: "flex", alignItems: "center", boxShadow: "none", border: "1px solid #CFE7DE", overflow: "auto" }}>
                    <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                      <Stack direction={{ sm: "row" }} spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="center" padding={"16px"} >
                        {loadFiles(form)}
                      </Stack>
                    </Box>
                    <Stack direction="row" >
                      <Grid sx={{ p: "16px" }}>
                        {!disabled && files.length < fileLength && <label htmlFor={name} >

                          {loader && <ClipLoader color="#36d7b7" active={loader} />}
                          {!loader && <Tooltip title="Upload" >

                            <AddCircleOutline size="large" sx={{
                              cursor: "pointer"
                            }} />
                          </Tooltip>}
                          <input
                            id={name}
                            style={{ display: "none" }}
                            type="file"
                            accept=".png,.jpg,.jpeg,.pdf,.xls"
                            direction={{ xs: "column", sm: "row" }}
                            onChange={(event) => {
                              uploadFile(event, form.form);
                            }}
                          />
                        </label>}
                      </Grid>
                    </Stack>
                  </Card>
                </>
              );
            }
          }
        }
      </Field >
      <ErrorMessage component={TextError} name={name} />

    </div>
  );
};

export default CustomFileUpload;
