const { STATE_REDUCER_KEY } = require("./constants");
import { flow } from "lodash";

const wardDetails = (key) => key[STATE_REDUCER_KEY];

const wardDetailsById = (state) => state.wardDetails.data;
export const wardDetailsSelector = flow(wardDetails, wardDetailsById);

const inProgress = (state) => state.wardDetails?.requestInProgress;
export const requestInProgressSelector = flow(wardDetails, inProgress);
