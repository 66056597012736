import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const getWardSabha = (state) => state[STATE_REDUCER_KEY];

const wardSabhaForm = (state) => state.wardSabhaAddDetails.data;
export const getWardSabhaForm = flow(getWardSabha, wardSabhaForm);

const wardList = (state) => state.wardList.data;
export const getWardList = flow(getWardSabha, wardList);

const filterWardList = (state) => state.wardFilter.name;
export const getFilterWardList = flow(getWardSabha, filterWardList);

const envList = (state) => state.wardList.envList;
export const getEnvList = flow(getWardSabha, envList);
