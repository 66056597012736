import { Typography } from "@mui/material";
import { Components, FormController, I18n, Icons } from "common/components";
import { TABLE_IDS } from "common/constants";
import { Form, withFormik } from "formik";
import { getSearchFilters } from "modules/common/selectors";
import { actions as commonActions } from "modules/common/slice";
import { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { toEpoch } from "utils/dateUtils";
import { getTablePagination } from "utils/tableUtils";
import { getComplaintCategory, getComplaintMode, setComplaintStatus, lsgiTypeSelectDropdown, priorityDropdown, listEscalationReport } from "../actions";
import { getComplaintCategoryDropdown, getComplaintsModeDropdown, getComplaintStatusDropdownOptions, getListComplaintNumber } from "../selectors";
import { fetchDistrictDropDown, fetchLsgiDropdown } from "modules/subProjects/swmPlan/actions";
import { getDistrictDropdown, getLsgiSelectDropdownOption } from "modules/subProjects/swmPlan/selector";
import { getWardName } from "modules/subProjects/wardSabha/actions";
import { getWardList } from "modules/subProjects/wardSabha/selector";
import { getComplaintType } from "modules/grievance/complaint/actions";
import { actions as sliceActions } from "modules/grievance/complaint/slice";
import { getComplaintTypeDropdown } from "modules/grievance/complaint/selectors";
import _ from "lodash";
import { sortByKeys } from "utils/commonUtils";

const { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, CustomCardHeader } = Components;
const { Search, RestartAlt, Close } = Icons;
const EscalationReportFilter = (props) => {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [cancel, setCancel] = useState(true);

    let endDate = "";
    let startDate = "";
    const { handleSubmit, onClose, setFieldValue, ComplaintsModeDropdown, ComplaintCategoryDropdown, values } = props;

    let { pageSize } = getTablePagination(TABLE_IDS.ESCALATION_REPORT);
    const handleSearch = () => {
        if ((start !== "" && start !== null && end === "")) {
            setError("End date required");
        } else if (start && end !== "") {
            dispatch(listEscalationReport({ pageNo: 0, pageSize }));
            dispatch(commonActions.setSearchFilter(props.values));
            setError("");
            onClose();
        } else {
            dispatch(listEscalationReport({ pageNo: 0, pageSize }));
            dispatch(commonActions.setSearchFilter(props.values));
            setError("");
            onClose();
        }
    };
    const handleReset = () => {
        dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, reset: true }));
        setFieldValue("complaintCategory", "");
        setFieldValue("districtId", "");
        setFieldValue("lsgiId", "");
        setFieldValue("wardLists", "");
        setFieldValue("lsgiTypes", "");
        setFieldValue("startDate", null);
        setStart("");
        setFieldValue("endDate", null);
        setEnd("");
        setFieldValue("complaintMode", "");
        setFieldValue("complaintStatus", "");
        setFieldValue("typeId", "");
        dispatch(sliceActions.clearAll());

    };
    useEffect(() => {
        dispatch(setComplaintStatus());
        dispatch(getComplaintMode());
        dispatch(getComplaintCategory());
        dispatch(fetchDistrictDropDown());
        dispatch(priorityDropdown());
        dispatch(lsgiTypeSelectDropdown());
    }, []);

    const handleDistrict = (val) => {
        dispatch(fetchLsgiDropdown(val.id));
        dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filterKey: "lsgiId", clearKey: true }));
        setFieldValue("lsgiId", "");
        if (val.id) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filters: { districtId: val.id } }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filterKey: "districtId", clearKey: true }));
        }
    };

    const handleLsgi = (val) => {
        dispatch(getWardName({ pageSize: 200, lsgiId: val?.id }));
        let filterLSGI = val?.id;
        if (filterLSGI.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filterKey: "lsgiId", clearKey: true }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filters: { lsgiId: filterLSGI } }));
        }
    };

    const handleWardList = (val) => {
        let ward = val?.map(i => i.id);
        if (ward.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filterKey: "wardLists", clearKey: true }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filters: { wardId: ward } }));
        }
    };

    const handleComplaintMode = (val) => {
        let complaintMode = val?.id;

        if (complaintMode.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filterKey: "complaintMode", clearKey: true }));
            dispatch(listEscalationReport({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filters: { complaintMode: complaintMode } }));
        }
    };
    const handleComplaintCategory = (val) => {
        let complaintCategory = val?.id;
        dispatch(getComplaintType(val?.id));
        props.setFieldValue("typeId", "");

        if (complaintCategory.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filterKey: "complaintCategory", clearKey: true }));
            dispatch(listEscalationReport({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filters: { complaintCategory: complaintCategory } }));
        }
    };

    const handleComplaintType = (val) => {
        let complaintType = val?.id;

        if (complaintType.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filterKey: "complaintType", clearKey: true }));
            dispatch(listEscalationReport({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filters: { complaintType: complaintType } }));
        }
    };

    const handleStartDate = (val) => {
        startDate = toEpoch(val);
        setStart(startDate);
        if (startDate?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filterKey: "startDate", clearKey: true }));
            dispatch(listEscalationReport({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filters: { startDate: startDate } }));
        }
    };

    useEffect(() => {
        setCancel(_.isEqual(sortByKeys(values), sortByKeys(props.reportFilter)));
    }, [values]);

    const handleEndDate = (val) => {
        endDate = toEpoch(val);
        setEnd(endDate);
        if (endDate?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filterKey: "endDate", clearKey: true }));
            dispatch(listEscalationReport({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.ESCALATION_REPORT, filters: { endDate: endDate } }));
        }
    };

    return (
        <>
            <Card sx={{ overflow: "visible", maxHeight: "90vh" }}>
                <CardHeader sx={{ borderRadius: "10px 10px 0 0", mb: 2 }} title={<CustomCardHeader title={I18n("filter")} />} />
                <CardContent sx={{ ml: 2, mr: 2 }}>
                    <Form onSubmit={handleSubmit} >
                        <Grid container spacing={1} >
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="date" mode="Date" onChangeFromController={handleStartDate} label={I18n("start_date")} name="startDate" maxDate={props?.values?.endDate} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="date" mode="Date" label={I18n("end_date")} onChangeFromController={handleEndDate} name="endDate" minDate={props?.values?.startDate} />
                                {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="select" label={I18n("complaint_mode")} name="complaintMode" onChangeFromController={handleComplaintMode} options={ComplaintsModeDropdown} />
                            </Grid >
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="select" label={I18n("complaint_category")} name="complaintCategory" onChangeFromController={handleComplaintCategory} options={ComplaintCategoryDropdown} />
                            </Grid >
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control='select' label={I18n("complaint_type")} name='typeId' options={props.complaintTypeDropDown || []} onChangeFromController={handleComplaintType} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control='select' label={I18n("district")} name='districtId' onChangeFromController={handleDistrict} options={props.districtDropdown || []} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control='select' label={I18n("ulb")} name='lsgiId' onChangeFromController={handleLsgi}
                                    options={props.ulbList || []} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="select" label={I18n("ward")} name="wardLists" onChangeFromController={handleWardList} options={props.wardNameList || []} multiple />
                            </Grid >
                        </Grid>
                    </Form>
                </CardContent>
                <Divider />
                <CardActions>
                    <Grid item xs={12} container justifyContent="center">
                        <Grid> <Button onClick={() => handleSearch()} startIcon={<Search />} variant="contained">{I18n("search")}</Button> </Grid>
                        <Grid> <Button onClick={() => handleReset()} startIcon={<RestartAlt />} variant="contained">{I18n("reset")}</Button> </Grid>
                        <Grid>{cancel && <Button onClick={() => onClose()} startIcon={<Close />} variant="contained">{I18n("close")}</Button>}</Grid>
                    </Grid>
                </CardActions>
            </Card >
        </>
    );
};
const mapDispatchToProps = () => ({
});

const mapStateToProps = createStructuredSelector({
    ComplaintStatusDropdownOptions: getComplaintStatusDropdownOptions,
    ComplaintsModeDropdown: getComplaintsModeDropdown,
    ComplaintCategoryDropdown: getComplaintCategoryDropdown,
    wardNameList: getWardList,
    listComplaintNumber: getListComplaintNumber,
    reportFilter: getSearchFilters,
    districtDropdown: getDistrictDropdown,
    ulbList: getLsgiSelectDropdownOption,
    complaintTypeDropDown: getComplaintTypeDropdown

});

const filter = withFormik({
    enableReinitialize: false,
    mapPropsToValues: (props) => {
        return props.reportFilter;
    },
    handleSubmit: (values, { setSubmitting }) => {
        setSubmitting(false);
    },
    displayName: "EscalationReportFilter"
})(EscalationReportFilter);

export default connect(mapStateToProps, mapDispatchToProps)(filter);
