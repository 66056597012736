import { I18n } from "common/components";
import React, { lazy } from "react";
import { GRAND_PATH as PATH } from "./paths";
import { RESOURCE_ID } from "modules/resources";
import ListReceipt from "./receiptGeneration/components/ListReceipt";
import CreateReceipt from "./receiptGeneration/components/CreateReceipt";
import GMMReportWrapper from "./gmmReports/Components/GMMReportWrapper";

const TotalFundUpdate = lazy(() => import("../grantManagement/grandAllocationCeiling/components/TotalFundUpdate"));
const TotalFund = lazy(() => import("../grantManagement/grandAllocationCeiling/components/TotalFund"));
const ULBPopulationList = lazy(() => import("./grandAllocationCeiling/components/ListULBPopulation"));
const ULBPopulation = lazy(() => import("./grandAllocationCeiling/components/ULBPopulationForm"));
const GACComputationList = lazy(() => import("./grandAllocationCeiling/components/ListGACComputation"));
const FetchExpenseList = lazy(() => import("./fetchExpense/components/FetchExpenseList"));
const ListFinancialYear = lazy(() => import("./fetchExpense/components/ListFinancialYear"));
const AddFinancialYear = lazy(() => import("./fetchExpense/components/AddFinancialYear"));
// const ListBills = lazy(() => import("./fetchExpense/components/ListBills"));
// const AddBills = lazy(() => import("./fetchExpense/components/AddBills"));
const AllocationsList = lazy(() => import("./grandRequests/components/GrantRequestList"));
const GrandAllocationView = lazy(() => import("./grandRequests/components/GrantRequestView"));
// const IvaList = lazy(() => import("./grandAllocation/components/IvaList"));
// const IvaRemarks = lazy(() => import("./grandAllocation/components/IvaRemarks"));
// const PlanView = lazy(() => import("./grandAllocation/components/PlanView"));
const AnnualTriggerView = lazy(() => import("./annualTriggerReview/components/AnnualTriggerView"));
const AnnualTriggerList = lazy(() => import("./annualTriggerReview/components/AnnualTriggerList"));

const routes = [
    {
        title: I18n("financial_year"),
        path: PATH.FINANCIAL_YEAR.LIST,
        id: RESOURCE_ID.GRANT_MANAGEMENT.FINANCIAL_YEAR.LIST,
        children: [
            {
                path: "",
                element: <ListFinancialYear />,
                id: RESOURCE_ID.GRANT_MANAGEMENT.FINANCIAL_YEAR.LIST
            },
            {
                path: PATH.FINANCIAL_YEAR.CREATE,
                element: <AddFinancialYear />,
                id: RESOURCE_ID.GRANT_MANAGEMENT.FINANCIAL_YEAR.ACTIONS.ADD
            },
            {
                path: PATH.FINANCIAL_YEAR.EDIT,
                element: <AddFinancialYear />,
                id: RESOURCE_ID.GRANT_MANAGEMENT.FINANCIAL_YEAR.ACTIONS.EDIT
            },
            {
                path: PATH.FINANCIAL_YEAR.VIEW,
                element: <AddFinancialYear />,
                id: RESOURCE_ID.GRANT_MANAGEMENT.FINANCIAL_YEAR.ACTIONS.VIEW
            }
        ]
    },

    {
        title: I18n("ulb_population"),
        path: PATH.ULB_POPULATION.LIST,
        id: RESOURCE_ID.GRANT_MANAGEMENT.ULB_POPULATION.LIST,
        children: [
            {
                path: "",
                element: <ULBPopulationList />,
                id: RESOURCE_ID.GRANT_MANAGEMENT.ULB_POPULATION.LIST
            },
            {
                path: PATH.ULB_POPULATION.CREATE,
                element: <ULBPopulation />,
                id: RESOURCE_ID.GRANT_MANAGEMENT.ULB_POPULATION.ACTIONS.ADD
            },
            {
                path: PATH.ULB_POPULATION.EDIT,
                element: <ULBPopulation />,
                id: RESOURCE_ID.GRANT_MANAGEMENT.ULB_POPULATION.ACTIONS.EDIT
            },
            {
                path: PATH.ULB_POPULATION.VIEW,
                element: <ULBPopulation />,
                id: RESOURCE_ID.GRANT_MANAGEMENT.ULB_POPULATION.ACTIONS.VIEW
            }
        ]
    },

    {
        title: I18n("allocation"),
        id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.ROUTE,
        children: [
            {
                title: I18n("total_fund"),
                path: PATH.TOTAL_FUND.LIST,
                id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.TOTAL_FUND.LIST,
                children: [
                    {
                        path: "",
                        element: <TotalFund />,
                        id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.TOTAL_FUND.LIST
                    },
                    {
                        path: PATH.TOTAL_FUND.CREATE,
                        element: <TotalFundUpdate />,
                        id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.TOTAL_FUND.ADD
                    },
                    {
                        path: PATH.TOTAL_FUND.VIEW,
                        element: <TotalFundUpdate />,
                        id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.TOTAL_FUND.VIEW
                    }
                ]
            },

            {
                title: I18n("gac_computation"),
                path: PATH.GAC_COMPUTATION.LIST,
                id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.GAC_COMPUTATION,
                children: [{
                    path: "",
                    element: <GACComputationList />,
                    id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.GAC_COMPUTATION
                }]
            },
            {
                title: I18n("request"),
                path: PATH.REQUEST.LIST,
                id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.REQUEST.LIST,
                children: [
                    {
                        path: "",
                        element: <AllocationsList />,
                        id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.REQUEST.LIST
                    },
                    {
                        path: PATH.REQUEST.CREATE,
                        element: <GrandAllocationView />,
                        id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.REQUEST.ADD
                    },
                    {
                        path: PATH.REQUEST.VIEW,
                        element: <GrandAllocationView />,
                        id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.REQUEST.VIEW
                    },
                    {
                        path: PATH.REQUEST.EDIT,
                        element: <GrandAllocationView />,
                        id: RESOURCE_ID.GRANT_MANAGEMENT.ALLOCATION.REQUEST.EDIT
                    }
                ]
            }

        ]
    },
    {
        title: I18n("receipt_generator"),
        path: PATH.RECEIPT.LIST,
        id: RESOURCE_ID.GRANT_MANAGEMENT.RECEIPT,
        children: [
            {
                path: "",
                element: <ListReceipt/>,
                id: RESOURCE_ID.GRANT_MANAGEMENT.RECEIPT
            },
            {
                path: PATH.RECEIPT.CREATE,
                element: <CreateReceipt />
            },
            {
                path: PATH.RECEIPT.UPDATE,
                element: <CreateReceipt />
            },
            {
                path: PATH.RECEIPT.VIEW,
                element: <CreateReceipt />
            }
        ]
    },
    {
        title: I18n("utilization"),
        id: RESOURCE_ID.GRANT_MANAGEMENT.UTILIZATION.ROUTE,
        children: [
            // {
            //     title: I18n("bills"),
            //     path: PATH.BILLS.LIST,
            //     id: RESOURCE_ID.GRANT_MANAGEMENT.UTILIZATION.BILLS.LIST,
            //     children: [
            //         {
            //             path: "",
            //             element: <ListBills />,
            //             id: RESOURCE_ID.GRANT_MANAGEMENT.UTILIZATION.BILLS.LIST
            //         },
            //         {
            //             path: PATH.BILLS.CREATE,
            //             element: <AddBills />,
            //             id: RESOURCE_ID.GRANT_MANAGEMENT.UTILIZATION.BILLS.ADD
            //         },
            //         {
            //             path: PATH.BILLS.EDIT,
            //             element: <AddBills />,
            //             id: RESOURCE_ID.GRANT_MANAGEMENT.UTILIZATION.BILLS.EDIT
            //         },
            //         {
            //             path: PATH.BILLS.VIEW,
            //             element: <AddBills />,
            //             id: RESOURCE_ID.GRANT_MANAGEMENT.UTILIZATION.BILLS.VIEW
            //         }
            //     ]
            // },
            {
                title: I18n("fetch_expenses"),
                path: PATH.FETCH_EXPENSE.LIST,
                id: RESOURCE_ID.GRANT_MANAGEMENT.UTILIZATION.FETCH_EXPENSES,
                children: [
                    {
                        path: "",
                        element: <FetchExpenseList />,
                        id: RESOURCE_ID.GRANT_MANAGEMENT.UTILIZATION.FETCH_EXPENSES
                    }
                ]
            },

            {
                title: I18n("at_request"),
                path: PATH.UTILIZATION_APPLICATION.LIST,
                id: RESOURCE_ID.GRANT_MANAGEMENT.UTILIZATION.REQUEST.LIST,
                children: [
                    {
                        path: "",
                        element: <AnnualTriggerList />,
                        id: RESOURCE_ID.GRANT_MANAGEMENT.UTILIZATION.FETCH_EXPENSES
                    }
                ]
            },
            {
                path: PATH.UTILIZATION_APPLICATION.VIEW,
                element: <AnnualTriggerView />
            }
        ]
    },
    {
        title: I18n("reports"),
        path: PATH.REPORTS.LIST,
        id: RESOURCE_ID.GRANT_MANAGEMENT.REPORTS,
        children: [
            {
                path: "",
                element: <GMMReportWrapper />,
                id: RESOURCE_ID.GRANT_MANAGEMENT.REPORTS
            }
        ]
    }
    // {
    //     title: I18n("iva"),
    //     path: PATH.IVA.LIST,
    //     element: <IvaList />
    // },
    // {
    //     path: PATH.IVA.REMARK,
    //     element: <IvaRemarks />
    // },
    // {
    //     title: I18n("plan_view"),
    //     path: PATH.PLAN_VIEW.ULB_LIST,
    //     element: <PlanView />
    // }
];

export { routes };
