import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    //complaint status
    SUBMIT_COMPLAINT_STATUS: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_STATUS`,
    SUBMIT_COMPLAINT_STATUS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_STATUS_REQUEST`,
    SUBMIT_COMPLAINT_STATUS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_STATUS_SUCCESS`,
    SUBMIT_COMPLAINT_STATUS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_STATUS_FAILURE`,

    LIST_COMPLAINT_STATUS_FORM: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_STATUS_FORM`,
    LIST_COMPLAINT_STATUS_FORM_REQUEST: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_STATUS_FORM_REQUEST`,
    LIST_COMPLAINT_STATUS_FORM_SUCCESS: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_STATUS_FORM_SUCCESS`,
    LIST_COMPLAINT_STATUS_FORM_FAILURE: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_STATUS_FORM_FAILURE`,

    DELETE_COMPLAINT_STATUS: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_STATUS`,
    DELETE_COMPLAINT_STATUS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_STATUS_REQUEST`,
    DELETE_COMPLAINT_STATUS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_STATUS_SUCCESS`,
    DELETE_COMPLAINT_STATUS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_STATUS_FAILURE`,

    EDIT_COMPLAINT_STATUS: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_STATUS`,
    EDIT_COMPLAINT_STATUS_REQUEST: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_STATUS_REQUEST`,
    EDIT_COMPLAINT_STATUS_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_STATUS_SUCCESS`,
    EDIT_COMPLAINT_STATUS_FAILURE: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_STATUS_FAILURE`,

    FETCH_COMPLAINT_STATUS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_DETAILS_BY_ID`,
    FETCH_COMPLAINT_STATUS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_DETAILS_BY_ID_REQUEST`,
    FETCH_COMPLAINT_STATUS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_DETAILS_BY_ID_SUCCESS`,
    FETCH_COMPLAINT_STATUS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_DETAILS_BY_ID_FAILURE`,

    //complaint type
    SUBMIT_COMPLAINT_TYPE: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_TYPE`,
    SUBMIT_COMPLAINT_TYPE_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_TYPE_REQUEST`,
    SUBMIT_COMPLAINT_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_TYPE_SUCCESS`,
    SUBMIT_COMPLAINT_TYPE_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_TYPE_FAILURE`,

    FETCH_COMPLAINT_CATEGORY: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY`,
    FETCH_COMPLAINT_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_REQUEST`,
    FETCH_COMPLAINT_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_SUCCESS`,
    FETCH_COMPLAINT_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_FAILURE`,

    LIST_COMPLAINT_TYPE_FORM: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_TYPE_FORM`,
    LIST_COMPLAINT_TYPE_FORM_REQUEST: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_TYPE_FORM_REQUEST`,
    LIST_COMPLAINT_TYPE_FORM_SUCCESS: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_TYPE_FORM_SUCCESS`,
    LIST_COMPLAINT_TYPE_FORM_FAILURE: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_TYPE_FORM_FAILURE`,

    DELETE_COMPLAINT_TYPE: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_TYPE`,
    DELETE_COMPLAINT_TYPE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_TYPE_REQUEST`,
    DELETE_COMPLAINT_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_TYPE_SUCCESS`,
    DELETE_COMPLAINT_TYPE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_TYPE_FAILURE`,

    EDIT_COMPLAINT_TYPE: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_TYPE`,
    EDIT_COMPLAINT_TYPE_REQUEST: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_TYPE_REQUEST`,
    EDIT_COMPLAINT_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_TYPE_SUCCESS`,
    EDIT_COMPLAINT_TYPE_FAILURE: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_TYPE_FAILURE`,

    FETCH_COMPLAINT_TYPE_BY_ID: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE_DETAILS_BY_ID`,
    FETCH_COMPLAINT_TYPE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE_DETAILS_BY_ID_REQUEST`,
    FETCH_COMPLAINT_TYPE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE_DETAILS_BY_ID_SUCCESS`,
    FETCH_COMPLAINT_TYPE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_TYPE_DETAILS_BY_ID_FAILURE`,

    //complaint category
    SUBMIT_COMPLAINT_CATEGORY: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_CATEGORY`,
    SUBMIT_COMPLAINT_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_FORM_REQUEST`,
    SUBMIT_COMPLAINT_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_CATEGORY_SUCCESS`,
    SUBMIT_COMPLAINT_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_CATEGORY_FAILURE`,

    LIST_COMPLAINT_CATEGORY_FORM: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_CATEGORY_FORM`,
    LIST_COMPLAINT_CATEGORY_FORM_REQUEST: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_CATEGORY_FORM_REQUEST`,
    LIST_COMPLAINT_CATEGORY_FORM_SUCCESS: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_CATEGORY_FORM_SUCCESS`,
    LIST_COMPLAINT_CATEGORY_FORM_FAILURE: `${STATE_REDUCER_KEY}/LIST_COMPLAINT_CATEGORY_FORM_FAILURE`,

    DELETE_COMPLAINT_CATEGORY: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_CATEGORY`,
    DELETE_COMPLAINT_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_CATEGORY_REQUEST`,
    DELETE_COMPLAINT_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_CATEGORY_SUCCESS`,
    DELETE_COMPLAINT_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/DELETE_COMPLAINT_CATEGORY_FAILURE`,

    EDIT_COMPLAINT_CATEGORY: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_CATEGORY`,
    EDIT_COMPLAINT_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_CATEGORY_REQUEST`,
    EDIT_COMPLAINT_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_CATEGORY_SUCCESS`,
    EDIT_COMPLAINT_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/EDIT_COMPLAINT_CATEGORY_FAILURE`,

    FETCH_COMPLAINT_CATEGORY_BY_ID: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_DETAILS_BY_ID`,
    FETCH_COMPLAINT_CATEGORY_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_DETAILS_BY_ID_REQUEST`,
    FETCH_COMPLAINT_CATEGORY_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_DETAILS_BY_ID_SUCCESS`,
    FETCH_COMPLAINT_CATEGORY_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_DETAILS_BY_ID_FAILURE`,

    SET_ESCALATION_UNIT_DROPDOWN: `${STATE_REDUCER_KEY}/SET_ESCALATION_UNIT_DROPDOWN`,
    FETCH_ESCALATION_UNIT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_UNIT_DROPDOWN_REQUEST`,
    FETCH_ESCALATION_UNIT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_UNIT_DROPDOWN_SUCCESS`,
    FETCH_ESCALATION_UNIT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_UNIT_DROPDOWN_FAILURE`,

    SET_TARGET_LEVEL_DROPDOWN: `${STATE_REDUCER_KEY}/SET_TARGET_LEVEL_DROPDOWN`,
    FETCH_TARGET_LEVEL_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TARGET_LEVEL_DROPDOWN_REQUEST`,
    FETCH_TARGET_LEVEL_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TARGET_LEVEL_DROPDOWN_SUCCESS`,
    FETCH_TARGET_LEVEL_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TARGET_LEVEL_DROPDOWN_FAILURE`,

    FETCH_OFFICERS_LIST: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST`,
    FETCH_OFFICERS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST_REQUEST`,
    FETCH_OFFICERS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST_SUCCESS`,
    FETCH_OFFICERS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_OFFICERS_LIST_FAILURE`,

    SUBMIT_ESCALATION: `${STATE_REDUCER_KEY}/SUBMIT_ESCALATION`,
    SUBMIT_ESCALATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_ESCALATION_REQUEST`,
    SUBMIT_ESCALATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_ESCALATION_SUCCESS`,
    SUBMIT_ESCALATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_ESCALATION_FAILURE`,

    UPDATE_ESCALATION: `${STATE_REDUCER_KEY}/UPDATE_ESCALATION`,
    UPDATE_ESCALATION_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_ESCALATION_REQUEST`,
    UPDATE_ESCALATION_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_ESCALATION_SUCCESS`,
    UPDATE_ESCALATION_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_ESCALATION_FAILURE`,

    FETCH_ESCALATION_LEVEL_BY_ID: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_LEVEL_BY_ID`,
    FETCH_ESCALATION_LEVEL_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_LEVEL_BY_ID_REQUEST`,
    FETCH_ESCALATION_LEVEL_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_LEVEL_BY_ID_SUCCESS`,
    FETCH_ESCALATION_LEVEL_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ESCALATION_LEVEL_BY_ID_FAILURE`,

    DELETE_ESCALATION: `${STATE_REDUCER_KEY}/DELETE_ESCALATION`,
    DELETE_ESCALATION_REQUEST: `${STATE_REDUCER_KEY}/DELETE_ESCALATION_REQUEST`,
    DELETE_ESCALATION_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_ESCALATION_SUCCESS`,
    DELETE_ESCALATION_FAILURE: `${STATE_REDUCER_KEY}/DELETE_ESCALATION_FAILURE`,

    FILTER_COMPLAINT_TYPE: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_TYPE`,
    FILTER_COMPLAINT_TYPE_REQUEST: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_TYPE_REQUEST`,
    FILTER_COMPLAINT_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_TYPE_SUCCESS`,
    FILTER_COMPLAINT_TYPE_FAILURE: `${STATE_REDUCER_KEY}/FILTER_COMPLAINT_TYPE_FAILURE`,

    FETCH_FILTER_CATEGORY: `${STATE_REDUCER_KEY}/FETCH_FILTER_CATEGORY`,
    FETCH_FILTER_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FILTER_CATEGORY_REQUEST`,
    FETCH_FILTER_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FILTER_CATEGORY_SUCCESS`,
    FETCH_FILTER_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FILTER_CATEGORY_FAILURE`
};


export const postComplaintStatus = createAction(ACTION_TYPES.SUBMIT_COMPLAINT_STATUS);
export const listComplaintStatusForm = createAction(ACTION_TYPES.LIST_COMPLAINT_STATUS_FORM);
export const deleteComplaintStatus = createAction(ACTION_TYPES.DELETE_COMPLAINT_STATUS);
export const getComplaintStatusById = createAction(ACTION_TYPES.FETCH_COMPLAINT_STATUS_BY_ID);

export const postComplaintType = createAction(ACTION_TYPES.SUBMIT_COMPLAINT_TYPE);
export const listComplaintTypeForm = createAction(ACTION_TYPES.LIST_COMPLAINT_TYPE_FORM);
export const getComplaintCategory = createAction(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY);
export const deleteComplaintType = createAction(ACTION_TYPES.DELETE_COMPLAINT_TYPE);
export const getComplaintTypeById = createAction(ACTION_TYPES.FETCH_COMPLAINT_TYPE_BY_ID);

export const postComplaintCategory = createAction(ACTION_TYPES.SUBMIT_COMPLAINT_CATEGORY);
export const listComplaintCategoryForm = createAction(ACTION_TYPES.LIST_COMPLAINT_CATEGORY_FORM);
export const deleteComplaintCategory = createAction(ACTION_TYPES.DELETE_COMPLAINT_CATEGORY);
export const getComplaintCategoryById = createAction(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_BY_ID);

export const setEscalationUnitDropDown = createAction(ACTION_TYPES.SET_ESCALATION_UNIT_DROPDOWN);
export const setTargetLevelDropDown = createAction(ACTION_TYPES.SET_TARGET_LEVEL_DROPDOWN);
export const fetchOfficersList = createAction(ACTION_TYPES.FETCH_OFFICERS_LIST);
export const submitEscalation = createAction(ACTION_TYPES.SUBMIT_ESCALATION);
export const getEscalationLevel = createAction(ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID);
export const deleteEscalation = createAction(ACTION_TYPES.DELETE_ESCALATION);
export const listFilterCategory = createAction(ACTION_TYPES.FETCH_FILTER_CATEGORY);
export const filterComplaintType = createAction(ACTION_TYPES.FILTER_COMPLAINT_TYPE);
