import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";


export const fetchDistricts = () => {
    return {
        url: API_URL.DISTRICT.LIST_DISTRICT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISTRICT_LIST_REQUEST, ACTION_TYPES.FETCH_DISTRICT_LIST_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_LIST_FAILURE]
        }
    };
};
export const fetchLoksabhas = (data) => {
    return {
        url: API_URL.LOKSABHA.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LOKSABHA_DATA_REQUEST, ACTION_TYPES.FETCH_LOKSABHA_DATA_SUCCESS, ACTION_TYPES.FETCH_LOKSABHA_DATA_FAILURE],
            data
        }
    };
};
export const fetchLoksabhasById = ({ id }) => {
    return {
        url: API_URL.LOKSABHA.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LOKSABHA_DETAILS_BY_ID_REQUEST, ACTION_TYPES.FETCH_LOKSABHA_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_LOKSABHA_DETAILS_BY_ID_FAILURE],
            data: { id }
        }
    };
};
export const addLoksabha = (data) => {
    return {
        url: API_URL.LOKSABHA.CREATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.ADD_LOKSABHA_DATA_REQUEST, ACTION_TYPES.ADD_LOKSABHA_DATA_SUCCESS, ACTION_TYPES.ADD_LOKSABHA_DATA_FAILURE],
            data
        }
    };
};
export const editLoksabha = (data) => {
    return {
        url: API_URL.LOKSABHA.EDIT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_LOKSABHA_DATA_REQUEST, ACTION_TYPES.EDIT_LOKSABHA_DATA_SUCCESS, ACTION_TYPES.EDIT_LOKSABHA_DATA_FAILURE],
            data
        }
    };
};
export const deleteLoksabha = ({ id }) => {
    return {
        url: API_URL.LOKSABHA.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_LOKSABHA_DATA_REQUEST, ACTION_TYPES.DELETE_LOKSABHA_DATA_SUCCESS, ACTION_TYPES.DELETE_LOKSABHA_DATA_FAILURE]
        }
    };
};
export const nameList = (data) => {
    return {
        url: API_URL.LOKSABHA.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_NAME_LIST_REQUEST,
                ACTION_TYPES.FETCH_NAME_LIST_SUCCESS,
                ACTION_TYPES.FETCH_NAME_LIST_FAILURE
            ],
            data
        }
    };
};
export const codeList = (data) => {
    return {
        url: API_URL.LOKSABHA.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_CODE_LIST_REQUEST,
                ACTION_TYPES.FETCH_CODE_LIST_SUCCESS,
                ACTION_TYPES.FETCH_CODE_LIST_FAILURE
            ],
            data
        }
    };
};
