import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";
import { REQUEST_METHOD } from "common/constants";

export const listProjectApi = (data) => ({
  url: API_URL.PROCUREMENT.PROJECT_LIST,
  method: REQUEST_METHOD.GET,
  payload: {
    types: [ACTION_TYPES.FETCH_PROJECT_LISTING_REQUEST, ACTION_TYPES.FETCH_PROJECT_LISTING_SUCCESS, ACTION_TYPES.FETCH_PROJECT_LISTING_FAILURE],
    data
  }
});

export const postProcurementActivity = (data) => {
  return {
    url: `${API_URL.PROCUREMENT.SAVE}`,
    method: REQUEST_METHOD.POST,
    payload: {
      types: [ACTION_TYPES.SAVE_PROCUREMENT_ACTIVITIES_REQUEST, ACTION_TYPES.SAVE_PROCUREMENT_ACTIVITIES_SUCCESS, ACTION_TYPES.SAVE_PROCUREMENT_ACTIVITIES_FAILURE],
      data: data
    }
  };
};

export const projectListingByIdApi = (data) => {
  return {
    url: API_URL.PROCUREMENT.PROJECT_LISTING_BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.PROJECT_LISTING_BY_ID_REQUEST, ACTION_TYPES.PROJECT_LISTING_BY_ID_SUCCESS, ACTION_TYPES.PROJECT_LISTING_BY_ID_FAILURE],
      data: { id: data }
    }
  };
};

export const procurementListingByIdApi = (data) => {
  return {
    url: API_URL.PROCUREMENT.PROCUREMENT_LISTING_BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.PROCUREMENT_ACTIVITIES_LISTING_BY_ID_REQUEST, ACTION_TYPES.PROCUREMENT_ACTIVITIES_LISTING_BY_ID_SUCCESS, ACTION_TYPES.PROCUREMENT_ACTIVITIES_LISTING_BY_ID_FAILURE],
      data: { id: data }
    }
  };
};

export const fetchProcessStatusApi = () => {
  return {
    url: API_URL.PROCUREMENT.PROCESS_STATUS,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.SELECT_PROCESS_STATUS_REQUEST, ACTION_TYPES.SELECT_PROCESS_STATUS_SUCCESS, ACTION_TYPES.SELECT_PROCESS_STATUS_FAILURE],
      data: { dropDown: true }
    }
  };
};
export const fetchProcurementMethodApi = () => {
  return {
    url: API_URL.PROCUREMENT.PROCUREMENT_METHOD,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.SELECT_PROCUREMENT_METHOD_REQUEST, ACTION_TYPES.SELECT_PROCUREMENT_METHOD_SUCCESS, ACTION_TYPES.SELECT_PROCUREMENT_METHOD_FAILURE],
      data: { dropDown: true }
    }
  };
};

export const fetchReviewTypeApi = () => {
  return {
    url: API_URL.PROCUREMENT.REVIEW_TYPE,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.SELECT_REVIEW_TYPE_REQUEST, ACTION_TYPES.SELECT_REVIEW_TYPE_SUCCESS, ACTION_TYPES.SELECT_REVIEW_TYPE_FAILURE],
      data: { dropDown: true }
    }
  };
};

export const fetchMarketApproachApi = () => {
  return {
    url: API_URL.PROCUREMENT.MARKET_APPROACH,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.SELECT_MARKET_APPROACH_REQUEST, ACTION_TYPES.SELECT_MARKET_APPROACH_SUCCESS, ACTION_TYPES.SELECT_MARKET_APPROACH_FAILURE],
      data: { dropDown: true }
    }
  };
};

export const fetchProcurementCategoryApi = () => {
  return {
    url: API_URL.PROCUREMENT.PROCUREMENT_CATEGORY,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.SELECT_CATEGORY_REQUEST, ACTION_TYPES.SELECT_CATEGORY_SUCCESS, ACTION_TYPES.SELECT_CATEGORY_FAILURE],
      data: { dropDown: true }
    }
  };
};

export const fetchVendorApi = () => {
  return {
    url: API_URL.PROCUREMENT.VENDOR,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.SELECT_VENDOR_REQUEST, ACTION_TYPES.SELECT_VENDOR_SUCCESS, ACTION_TYPES.SELECT_VENDOR_FAILURE],
      data: { dropDown: true, pageSize: 500 }
    }
  };
};

export const procurementActivitiesListApi = (data) => {
  return {
    url: API_URL.PROCUREMENT.PROCUREMENT_ACTIVITIES,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.LIST_PROCUREMENT_ACTIVITIES_REQUEST, ACTION_TYPES.LIST_PROCUREMENT_ACTIVITIES_SUCCESS, ACTION_TYPES.LIST_PROCUREMENT_ACTIVITIES_FAILURE],
      data
    }
  };
};

export const updateImortedDataApi = (data) => {
  return {
    url: `${API_URL.PROCUREMENT.SAVE}`,
    method: REQUEST_METHOD.PATCH,
    payload: {
      types: [ACTION_TYPES.UPDATE_IMPORTED_DATA_REQUEST, ACTION_TYPES.UPDATE_IMPORTED_DATA_SUCCESS, ACTION_TYPES.UPDATE_IMPORTED_DATA_FAILURE],
      data: data
    }
  };
};

export const patchProcurementActivity = (data) => {
  return {
    url: `${API_URL.PROCUREMENT.SAVE}`,
    method: REQUEST_METHOD.PATCH,
    payload: {
      types: [ACTION_TYPES.UPDATE_PROCUREMENT_ACTIVITIES_REQUEST, ACTION_TYPES.UPDATE_PROCUREMENT_ACTIVITIES_SUCCESS, ACTION_TYPES.UPDATE_PROCUREMENT_ACTIVITIES_FAILURE],
      data: data
    }
  };
};
