import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { COMPLAINT_STATUS_TYPE, STATE_REDUCER_KEY } from "./constants";
import { getPayloadContent, getPayloadContentDetails } from "utils/apiUtils";
import { DATE_TIME_FORMAT, formatDate } from "utils/dateUtils";

const initialState = {
    complaintDetails: {
        requestInProgress: false,
        data: {
            complaintNo: "",
            complaintRegisteredBy: "",
            mobileNo: "",
            email: "",
            complaintMode: "",
            categoryId: "",
            typeId: "",
            complaintDescription: "",
            districtId: "",
            lsgiId: "",
            lsgiTypeId: "",
            wardId: "",
            complaintDateTime: "",
            complaintLocation: "",
            attachmentRemarks: "",
            attachmentIds: [],
            actionsApprovalTablePayload: []
        }
    },
    complaintActions: {
        requestInProgress: false,
        isVisible: false,
        data: {
            complaintId: "",
            officerRemarks: "",
            currentStatus: "",
            complaintPriority: "",
            complaintAssignedTo: "",
            remark: "",
            officerAttachmentIds: []
        }
    },
    officerStatus: {
        requestInProgress: false,
        data: {
            complaintId: "",
            remarks: "",
            operationType: ""
        }
    },
    complaintActionList: {
        requestInProgress: false,
        data: []
    },
    files: {
        requestInProgress: false,
        data: []
    },
    districtDropdown: {
        requestInProgress: false,
        data: []
    },
    organizationTypeDropdown: {
        requestInProgress: false,
        data: []
    },
    organizationDropdown: {
        requestInProgress: false,
        data: []
    },
    complaintsModeDropdown: {
        requestInProgress: false,
        data: []
    },
    complaintCategoryDropdown: {
        requestInProgress: false,
        data: []
    },
    complaintTypeDropdown: {
        requestInProgress: false,
        data: []
    },
    complaintCategory: {
        requestInProgress: false,
        data: []
    },
    complaintStatus: {
        requestInProgress: false,
        data: []
    },
    wards: {
        requestInProgress: false,
        data: []
    },
    complaintStatusDropdown: {
        data: [],
        requestInProgress: false
    },
    complaintTypeDetails: {
        requestInProgress: false,
        data: {
            name: "",
            description: "",
            categoryId: ""
        }
    },
    filters: {
        complaintNo: []
    },
    officersList: {
        requestInProgress: false,
        data: [],
        rowSelection: {},
        rowSelectionState: {},
        additionalFilters: {}
    },
    searchFields: {
        requestInProgress: false,
        data: {
            stateId: { id: 1, name: "Kerala" },
            districtId: "",
            lsgiType: "",
            lsgiId: "",
            wardId: "",
            remarks: ""
        }
    },
    complaintRemarks: {
        requestInProgress: false,
        data: {
            remarks: ""
        }
    },
    complaintClosed: {
        requestInProgress: false,
        data: {
            remarks: "",
            customerRating: ""
        }
    },
    complaintWithdraw: {
        requestInProgress: false,
        data: {
            remarks: ""
        }
    },
    open: false,
    openComplaint: false,
    openWithdraw: false,
    operationType: "",
    toggleComplaintAction: true,
    customerActions: {
        isWithDrawal: true,
        isReopen: true,
        isClose: true
    },
    attachmentImages: [],
    actionImages: [],
    complaintApprovalDetails: {
        requestInProgress: false,
        data: {
            complaintId: "",
            approverRemarks: "",
            approvalStatus: "",
            approvedTime: "",
            requestedUser: {
                firstName: ""
            }
        }
    },
    myApprovalActions: { isProcessed: false },
    myApprovalSelectedRow: [],
    createUser: {
        requestInProgress: false,
        confirm: false,
        success: false,
        data: {
            userName: "",
            userPassword: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            genderId: "",
            emailId: "",
            mobileNo: "",
            activeProfile: {
                loginType: "",
                userType: ""
            }
        }
    },
    mobileNo: {
        requestInProgress: false,
        data: []
    }

};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.complaintDetails = initialState.complaintDetails;
            state.complaintActions = initialState.complaintActions;
            state.complaintActionList = initialState.complaintActionList;
            state.officerStatus = initialState.officerStatus;
        },
        clearFilter: (state) => {
            state.filters = initialState.filters.complaintNo;
        },
        clearCloseData: (state) => {
            state.complaintClosed = initialState.complaintClosed;
        },
        setRegistrationDetails: (state, action) => {
            state.complaintTypeDetails.data = action.payload;
        },
        setRowSelectionState: (state, { payload = {} }) => {
            _.set(state, "officersList.rowSelectionState", payload);
        },
        setAdditionalFilter: (state, action) => {
            _.set(state, "officersList.additionalFilters", action.payload);
        },
        setRowSelection: (state, { payload: { userId } = {} }) => {
            _.set(state, "officersList.rowSelection", userId);
            _.set(state, "complaintDetails.data.complaintRegisteredBy", userId);
            _.set(state, "createUser.success", true);
        },
        setComplaintDetails: (state, { payload: { key, value } = {} }) => {
            if (key === "openFilter") {
                _.set(state, "createUser.success", false);
                _.set(state, "createUser.confirm", false);
            } else {
                _.set(state, `complaintDetails.data.${key}`, value);
            }
        },
        clearFilterTable: (state) => {
            state.officersList = initialState.officersList;
        },
        setOperationType: (state, action) => {
            state.operationType = action.payload;
        },
        setOpen: (state, action) => {
            state.open = action.payload;
            state.openClosed = false;
            state.openWithdraw = false;
        },
        setOpenComplaint: (state, action) => {
            state.openComplaint = action.payload;
            state.open = false;
            state.openWithdraw = false;
        },
        setOpenWithdraw: (state, action) => {
            state.openWithdraw = action.payload;
            state.open = false;
            state.openClosed = false;
        },
        setAttachImages: (state, action) => {
            state.attachmentImages.push(action.payload);
        },
        removeAttachImages: (state, action) => {
            state.attachmentImages = action.payload;
        },
        setActionImages: (state, action) => {
            state.actionImages.push(action.payload);
        },
        removeActionImages: (state, action) => {
            state.actionImages = action.payload;
        },
        setEditTabIndex: (state, action) => {
            state.commonTab.selected = action.payload;
        },
        setMyApprovalsData: (state, action) => {
            state.myApprovalSelectedRow = action.payload;
        },
        clearMobileDropdown: (state) => {
            state.mobileNo = initialState.mobileNo;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_DISTRICT_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "districtDropdown.requestInProgress", false);
            _.set(state, "districtDropdown.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FETCH_ORGANIZATION_SUCCESS_TYPE, (state, { payload }) => {
            _.set(state, "organizationTypeDropdown.requestInProgress", false);
            _.set(state, "organizationTypeDropdown.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FETCH_ORGANIZATION_SUCCESS, (state, { payload }) => {
            _.set(state, "organizationDropdown.requestInProgress", false);
            _.set(state, "organizationDropdown.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FETCH_WARD_DETAILS_SUCCESS, (state, { payload }) => {
            _.set(state, "wards.requestInProgress", false);
            _.set(state, "wards.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FILTER_COMPLAINT_LIST_SUCCESS, (state, action) => {
            _.set(state, "filters.complaintNo", getPayloadContent(action));
        });
        builder
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_REQUEST, (state) => {
                state.complaintDetails.requestInProgress = true;
                state.complaintActions.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_SUCCESS, (state, action) => {
                _.set(state, "complaintActions.requestInProgress", false);
                state.toggleComplaintAction = true;
                action.payload.lsgiTypeId = { id: action.payload?.lsgiId?.lsgiTypeId?.id, name: action.payload.lsgiId?.lsgiTypeId?.name };
                _.set(state, "complaintDetails.requestInProgress", false);
                _.set(state, "complaintDetails.data", action.payload);
                _.set(state, "complaintDetails.data.complaintRegisteredBy", action.payload?.complaintRegisteredBy);
                _.set(state, "complaintDetails.data.mobileNo", action.payload?.complaintRegisteredBy?.activeProfile?.mobileNo);
                _.set(state, "complaintDetails.data.email", action.payload?.complaintRegisteredBy?.activeProfile?.emailId);
                if (action.payload.officerAttachmentIds) {
                    _.set(state, "attachmentImages", action.payload?.officerAttachmentIds);
                }
                if (action.payload.currentHandlerActions && !_.isEmpty(action.payload.currentHandlerActions)) {
                    _.set(state, "complaintActions.isVisible", true);
                    _.set(state, "complaintActions.data", action.payload.currentHandlerActions);
                    _.set(state, "complaintActions.data.complaintPriority", action.payload.complaintPriority);
                    let attachments = _.get(action, "payload.currentHandlerActions.officerAttachments");
                    if (attachments) {
                        _.set(state, "actionImages", attachments.flat().map(e => {
                            return { id: e };
                        }));
                    }
                    let complaintStatusId = _.get(action, "payload.complaintStatus.id");
                    if (complaintStatusId === COMPLAINT_STATUS_TYPE.RESOLVED.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.CLOSED.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.REJECTED.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.REASSIGNED.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.WITHDRAWN.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.REJECT_REQUESTED.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.RESOLVE_REQUESTED.id) {
                        state.toggleComplaintAction = false;
                    }
                }
                if (!action.payload.currentHandlerActions) {
                    _.set(state, "complaintActions.isVisible", false);
                }
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_FAILURE, (state) => {
                state.complaintDetails.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_ID_REQUEST, (state) => {
                state.complaintDetails.requestInProgress = true;
                state.complaintActions.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "complaintActions.requestInProgress", false);
                state.toggleComplaintAction = true;
                action.payload.lsgiTypeId = { id: action.payload?.lsgiId?.lsgiTypeId?.id, name: action.payload.lsgiId?.lsgiTypeId?.name };
                _.set(state, "complaintDetails.requestInProgress", false);
                _.set(state, "complaintDetails.data", action.payload);
                _.set(state, "complaintDetails.data.complaintRegisteredBy", action.payload?.complaintRegisteredBy);
                _.set(state, "complaintDetails.data.mobileNo", action.payload?.complaintRegisteredBy?.activeProfile?.mobileNo);
                _.set(state, "complaintDetails.data.email", action.payload?.complaintRegisteredBy?.activeProfile?.emailId);
                // _.set(state, "fetchFiveYearPlanListById.data.handlerActionList", action.payload?.handlerActionList);
                // _.set(state, "fetchFiveYearPlanListById.data.complaintApprovalDetails", action.payload?.complaintApprovalDetails);
                _.set(state, "fetchFiveYearPlanListById.data.actionsApprovalTablePayload", action.payload?.actionsApprovalTablePayload);
                if (action.payload.attachmentIds) {
                    _.set(state, "attachmentImages", action.payload?.attachmentIds);
                }
                if (action.payload.currentHandlerActions && !_.isEmpty(action.payload.currentHandlerActions)) {
                    _.set(state, "complaintActions.isVisible", true);
                    _.set(state, "complaintActions.data", action.payload.currentHandlerActions);
                    _.set(state, "complaintActions.data.complaintPriority", action.payload.complaintPriority);
                    let attachments = _.get(action, "payload.currentHandlerActions.officerAttachments");
                    if (attachments) {
                        _.set(state, "actionImages", attachments.flat().map(e => {
                            return { id: e };
                        }));
                    }
                    let complaintStatusId = _.get(action, "payload.complaintStatus.id");
                    if (complaintStatusId === COMPLAINT_STATUS_TYPE.RESOLVED.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.CLOSED.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.REJECTED.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.REASSIGNED.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.WITHDRAWN.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.REJECT_REQUESTED.id ||
                        complaintStatusId === COMPLAINT_STATUS_TYPE.RESOLVE_REQUESTED.id) {
                        state.toggleComplaintAction = false;
                    }
                }
                if (!action.payload.currentHandlerActions) {
                    _.set(state, "complaintActions.isVisible", false);
                }
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_ID_FAILURE, (state) => {
                state.complaintDetails.requestInProgress = false;
            });
        builder.addCase(ACTION_TYPES.FETCH_COMPLAINT_MODE_SUCCESS, (state, { payload }) => {
            _.set(state, "complaintsModeDropdown.requestInProgress", false);
            _.set(state, "complaintsModeDropdown.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FETCH_COMPLAINT_TYPE_SUCCESS, (state, { payload }) => {
            _.set(state, "complaintTypeDropdown.requestInProgress", false);
            _.set(state, "complaintTypeDropdown.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_SUCCESS, (state, { payload }) => {
            _.set(state, "complaintCategoryDropdown.requestInProgress", false);
            _.set(state, "complaintCategoryDropdown.data", payload.content);
        })
            .addCase(ACTION_TYPES.FETCH_OFFICERS_LIST_REQUEST, (state) => {
                state.officersList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_OFFICERS_LIST_SUCCESS, (state, { payload }) => {
                state.showTable = true;
                state.officersList.requestInProgress = false;
                state.officersList.data = payload.content;
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_STATUS_SUCCESS, (state, { payload }) => {
                _.set(state, "complaintStatusDropdown.requestInProgress", false);
                _.set(state, "complaintStatusDropdown.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_APPROVAL_DETAILS_REQUEST, (state) => {
                _.set(state, "complaintApprovalDetails.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.FETCH_USER_SUCCESS, (state, { payload }) => {
                if (payload.totalRecords === 0) {
                    _.set(state, "createUser.confirm", true);
                } else {
                    _.set(state, "createUser.confirm", false);
                }
            })
            .addCase(ACTION_TYPES.CREATE_USER_SUCCESS, (state, { payload }) => {
                _.set(state, "createUser.confirm", false);
                _.set(state, "createUser.success", true);
                _.set(state, "complaintDetails.data.complaintRegisteredBy", { id: payload.userId, name: payload.userName });
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_APPROVAL_DETAILS_SUCCESS, (state, action) => {
                let payload = getPayloadContentDetails(action);
                if (_.get(payload, "approvalStatus") !== "PENDING_APPROVAL") {
                    _.set(state, "myApprovalActions.isProcessed", true);
                } else {
                    _.set(state, "myApprovalActions.isProcessed", false);
                }
                _.set(state, "complaintApprovalDetails.data", payload);
                let time = _.get(state, "complaintApprovalDetails.data.approvedTime");
                let requestedTime = _.get(state, "complaintApprovalDetails.data.requestedTime");
                _.set(state, "complaintApprovalDetails.data.approvedTime", formatDate(time, DATE_TIME_FORMAT));
                _.set(state, "complaintApprovalDetails.data.requestedTime", formatDate(requestedTime, DATE_TIME_FORMAT));
            })
            .addCase(ACTION_TYPES.MOBILE_NO_DROPDOWN_REQUEST, (state) => {
                state.mobileNo.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.MOBILE_NO_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "mobileNo.requestInProgress", false);
                _.set(state, "mobileNo.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.MOBILE_NO_DROPDOWN_FAILURE, (state) => {
                state.mobileNo.requestInProgress = false;
            });
    }
});

export const { actions, reducer } = slice;
