import { PictureAsPdf } from "@mui/icons-material";
import { IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Components, I18n } from "common/components";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { withFormik } from "formik";
import LoadingCustomOverlay from "modules/common/components/LoadingOverlay";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { downloadProjectDetails, projectDetailsReport } from "../actions";
import { getProjectDetails, getProjectDetailsTotal, getRequestInProgress } from "../selectors";
import { actions as sliceActions } from "../slice";
const { Grid, Box } = Components;

const useStyles = makeStyles(() => ({
    tableContainer: {
        overflowX: "auto",
        maxWidth: "100%"
    },
    table: {
        minWidth: 600,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            fontSize: "18px"
        }
    }
}));

const ProjectDetailsReport = (props) => {

    const { requestInProgress = true, projectDetails } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [newPage, setNewPage] = useState(1);

    let projectDataUpdated = [...projectDetails];
    let total = projectDataUpdated?.pop(-1);

    useEffect(() => {
        dispatch(projectDetailsReport());
        return () => {
            dispatch(sliceActions.clearProjectDetails());
        };
    }, []);

    const pagination = (event, value) => {
        setNewPage(value);
        dispatch(projectDetailsReport({pageSize: 10, pageNo: value-1}));
    };

    let customActions = [];

    customActions.push({ title: I18n("download_pdf"), icon: <PictureAsPdf fontSize="small" />, handleClick: () => dispatch(downloadProjectDetails({ type: "pdf" })) });

    return (
        <Box sx={{ maxHeight: "100%", maxWidth: "100%"}}>
            <LoadingCustomOverlay active={requestInProgress}>
            <Grid className={classes.tableContainer} >
                <Grid item lg={12} md={12} sm={12} sx={{display: "flex", justifyContent: "end", ml: 1}}>
                    <IconButton>
                        <CustomListMenu customActions={customActions} type="download"/>
                    </IconButton>
                </Grid>
                <TableContainer sx={{maxHeight: {lg: "550px", xl: "600px"}}}>
                    <Table sx={{ p: 1, minWidth: "300px", textAlign: "left" }} size="small" aria-label="a dense table" className={classes.table}>
                        <TableHead sx={{ fontSize: "18px", fontFamily: "Clash Display" }}>
                            <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                <TableCell sx={{ minWidth: "4em", color: "#FFFF" }} >{I18n("sl_no")}</TableCell>
                                <TableCell sx={{ minWidth: "8em", color: "#FFFF" }} >{I18n("district")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >{I18n("total_project_count")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >{I18n("project_amount")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >{I18n("dpc_approved_amount")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >{I18n("fs_amount")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >{I18n("percentage_of_variation_in_dpc_approved_and_fs_amount")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >{I18n("remarks")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ maxHeight: "20em", overflow: scroll }}>
                            {projectDataUpdated?.map((data, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell sx={{ minWidth: "1em" }}>{data?.slNo}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.districtName}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.totalProjectCount ?? "----"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.totalProjectAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.dpcApprovedAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.fsAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.percentageVariation?.toFixed(3) ?? "----"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}></TableCell>
                                    </TableRow>
                                );
                            })}
                            {total &&
                                <TableRow>
                                    <TableCell colSpan={3} >{I18n("total")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em" }}>{total?.projectAmountSum ?? "---"}</TableCell>
                                    <TableCell sx={{ minWidth: "10em" }}>{total?.dpcApprovedAmountSum ?? "---"}</TableCell>
                                    <TableCell sx={{ minWidth: "10em" }}>{total?.fsAmountSum ?? "---"}</TableCell>
                                    <TableCell sx={{ minWidth: "10em" }}></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "10px"}}>
                    <Pagination count={props?.projectData?.totalPages} color="primary" page={newPage} onChange={pagination} />
                </Grid>
            </Grid>
            </LoadingCustomOverlay>
        </Box>
    );
};


const mapStateToProps = createStructuredSelector({
    projectData: getProjectDetails,
    requestInProgress: getRequestInProgress,
    projectDetails: getProjectDetailsTotal
});

const ProjectDetailsReportForm = withFormik({})(ProjectDetailsReport);
export default connect(mapStateToProps, null)(ProjectDetailsReportForm);
