import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

  PROJECT_ACTIVITIES_LIST: `${STATE_REDUCER_KEY}/PROJECT_ACTIVITIES_LIST`,
  PROJECT_ACTIVITIES_LIST_REQUEST: `${STATE_REDUCER_KEY}/PROJECT_ACTIVITIES_LIST_REQUEST`,
  PROJECT_ACTIVITIES_LIST_SUCCESS: `${STATE_REDUCER_KEY}/PROJECT_ACTIVITIES_LIST_SUCCESS`,
  PROJECT_ACTIVITIES_LIST_FAILURE: `${STATE_REDUCER_KEY}/PROJECT_ACTIVITIES_LIST_FAILURE`,

  POST_PROJECT_ACTIVITY: `${STATE_REDUCER_KEY}/POST_PROJECT_ACTIVITY`,
  POST_PROJECT_ACTIVITY_REQUEST: `${STATE_REDUCER_KEY}/POST_PROJECT_ACTIVITY_REQUEST`,
  POST_PROJECT_ACTIVITY_SUCCESS: `${STATE_REDUCER_KEY}/POST_PROJECT_ACTIVITY_SUCCESS`,
  POST_PROJECT_ACTIVITY_FAILURE: `${STATE_REDUCER_KEY}/POST_PROJECT_ACTIVITY_FAILURE`,

  PATCH_PROJECT_ACTIVITY: `${STATE_REDUCER_KEY}/PATCH_PROJECT_ACTIVITY`,
  PATCH_PROJECT_ACTIVITY_REQUEST: `${STATE_REDUCER_KEY}/PATCH_PROJECT_ACTIVITY_REQUEST`,
  PATCH_PROJECT_ACTIVITY_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_PROJECT_ACTIVITY_SUCCESS`,
  PATCH_PROJECT_ACTIVITY_FAILURE: `${STATE_REDUCER_KEY}/PATCH_PROJECT_ACTIVITY_FAILURE`,

  DELETE_PROJECT_ACTIVITY: `${STATE_REDUCER_KEY}/DELETE_PROJECT_ACTIVITY`,
  DELETE_PROJECT_ACTIVITY_REQUEST: `${STATE_REDUCER_KEY}/DELETE_PROJECT_ACTIVITY_REQUEST`,
  DELETE_PROJECT_ACTIVITY_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_PROJECT_ACTIVITY_SUCCESS`,
  DELETE_PROJECT_ACTIVITY_FAILURE: `${STATE_REDUCER_KEY}/DELETE_PROJECT_ACTIVITY_FAILURE`,

  FETCH_CONTRACTOR_LIST: `${STATE_REDUCER_KEY}/FETCH_CONTRACTOR_LIST`,
  FETCH_CONTRACTOR_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CONTRACTOR_LIST_REQUEST`,
  FETCH_CONTRACTOR_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CONTRACTOR_LIST_SUCCESS`,
  FETCH_CONTRACTOR_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CONTRACTOR_LIST_FAILURE`,

  TECHNICAL_TABLE_LIST: `${STATE_REDUCER_KEY}/TECHNICAL_TABLE_LIST`,
  TECHNICAL_TABLE_LIST_REQUEST: `${STATE_REDUCER_KEY}/TECHNICAL_TABLE_LIST_REQUEST`,
  TECHNICAL_TABLE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TECHNICAL_TABLE_LIST_SUCCESS`,
  TECHNICAL_TABLE_LIST_FAILURE: `${STATE_REDUCER_KEY}/TECHNICAL_TABLE_LIST_FAILURE`,

  PROJECT_MONITORING_LIST: `${STATE_REDUCER_KEY}/PROJECT_MONITORING_LIST`,
  PROJECT_MONITORING_LIST_REQUEST: `${STATE_REDUCER_KEY}/PROJECT_MONITORING_LIST_REQUEST`,
  PROJECT_MONITORING_LIST_SUCCESS: `${STATE_REDUCER_KEY}/PROJECT_MONITORING_LIST_SUCCESS`,
  PROJECT_MONITORING_LIST_FAILURE: `${STATE_REDUCER_KEY}/PROJECT_MONITORING_LIST_FAILURE`,

  DELETE_PROJECT_MONITORING: `${STATE_REDUCER_KEY}/DELETE_PROJECT_MONITORING`,
  DELETE_PROJECT_MONITORING_REQUEST: `${STATE_REDUCER_KEY}/DELETE_PROJECT_MONITORING_REQUEST`,
  DELETE_PROJECT_MONITORING_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_PROJECT_MONITORING_SUCCESS`,
  DELETE_PROJECT_MONITORING_FAILURE: `${STATE_REDUCER_KEY}/DELETE_PROJECT_MONITORING_FAILURE`,

  PATCH_TECHNICAL_FORM: `${STATE_REDUCER_KEY}/PATCH_TECHNICAL_FORM`,
  PATCH_TECHNICAL_FORM_REQUEST: `${STATE_REDUCER_KEY}/PATCH_TECHNICAL_FORM_REQUEST`,
  PATCH_TECHNICAL_FORM_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_TECHNICAL_FORM_SUCCESS`,
  PATCH_TECHNICAL_FORM_FAILURE: `${STATE_REDUCER_KEY}/PATCH_TECHNICAL_FORM_FAILURE`,

  TECHNICAL_FORM_BY_ID: `${STATE_REDUCER_KEY}/TECHNICAL_FORM_BY_ID`,
  TECHNICAL_FORM_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/TECHNICAL_FORM_BY_ID_REQUEST`,
  TECHNICAL_FORM_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/TECHNICAL_FORM_BY_ID_SUCCESS`,
  TECHNICAL_FORM_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/TECHNICAL_FORM_BY_ID_FAILURE`,

  SUBMIT_PAYMENT_SCHEDULE: `${STATE_REDUCER_KEY}/SUBMIT_PAYMENT_SCHEDULE`,
  SUBMIT_PAYMENT_SCHEDULE_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_PAYMENT_SCHEDULE_REQUEST`,
  SUBMIT_PAYMENT_SCHEDULE_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_PAYMENT_SCHEDULE_SUCCESS`,
  SUBMIT_PAYMENT_SCHEDULE_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_PAYMENT_SCHEDULE_FAILURE`,

  LIST_PAYMENT_SCHEDULE: `${STATE_REDUCER_KEY}/LIST_PAYMENT_SCHEDULE`,
  LIST_PAYMENT_SCHEDULE_REQUEST: `${STATE_REDUCER_KEY}/LIST_PAYMENT_SCHEDULE_REQUEST`,
  LIST_PAYMENT_SCHEDULE_SUCCESS: `${STATE_REDUCER_KEY}/LIST_PAYMENT_SCHEDULE_SUCCESS`,
  LIST_PAYMENT_SCHEDULE_FAILURE: `${STATE_REDUCER_KEY}/LIST_PAYMENT_SCHEDULE_FAILURE`,

  LIST_PAYMENT_SCHEDULE_BY_ID: `${STATE_REDUCER_KEY}/LIST_PAYMENT_SCHEDULE_BY_ID`,
  LIST_PAYMENT_SCHEDULE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/LIST_PAYMENT_SCHEDULE_BY_ID_REQUEST`,
  LIST_PAYMENT_SCHEDULE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/LIST_PAYMENT_SCHEDULE_BY_ID_SUCCESS`,
  LIST_PAYMENT_SCHEDULE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/LIST_PAYMENT_SCHEDULE_BY_ID_FAILURE`,

  UPDATE_PAYMENT_SCHEDULE: `${STATE_REDUCER_KEY}/UPDATE_PAYMENT_SCHEDULE`,
  UPDATE_PAYMENT_SCHEDULE_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_PAYMENT_SCHEDULE_REQUEST`,
  UPDATE_PAYMENT_SCHEDULE_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_PAYMENT_SCHEDULE_SUCCESS`,
  UPDATE_PAYMENT_SCHEDULE_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_TPAYMENT_SCHEDULE_FAILURE`,

  PROJECT_ACTIVITIES_LIST_BY_ID: `${STATE_REDUCER_KEY}/PROJECT_ACTIVITIES_LIST_BY_ID`,
  PROJECT_ACTIVITIES_LIST_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/PROJECT_ACTIVITIES_LIST_BY_ID_REQUEST`,
  PROJECT_ACTIVITIES_LIST_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/PROJECT_ACTIVITIES_LIST_BY_ID_SUCCESS`,
  PROJECT_ACTIVITIES_LIST_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/PROJECT_ACTIVITIES_LIST_BY_ID_FAILURE`,

  UPDATE_PROJECT_ACTIVITY: `${STATE_REDUCER_KEY}/UPDATE_PROJECT_ACTIVITY`,
  UPDATE_PROJECT_ACTIVITY_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_PROJECT_ACTIVITY_REQUEST`,
  UPDATE_PROJECT_ACTIVITY_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_PROJECT_ACTIVITY_SUCCESS`,
  UPDATE_PROJECT_ACTIVITY_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_PROJECT_ACTIVITY_FAILURE`,

  CONTRACTOR_LIST_BY_ID: `${STATE_REDUCER_KEY}/CONTRACTOR_LIST_BY_ID`,
  CONTRACTOR_LIST_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/CONTRACTOR_LIST_BY_ID_REQUEST`,
  CONTRACTOR_LIST_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/CONTRACTOR_LIST_BY_ID_SUCCESS`,
  CONTRACTOR_LIST_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/CONTRACTOR_LIST_BY_ID_FAILURE`,

  SELECT_ACTIVITY_NAME: `${STATE_REDUCER_KEY}/SELECT_ACTIVITY_NAME`,
  SELECT_ACTIVITY_NAME_REQUEST: `${STATE_REDUCER_KEY}/SELECT_ACTIVITY_NAME_REQUEST`,
  SELECT_ACTIVITY_NAME_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_ACTIVITY_NAME_SUCCESS`,
  SELECT_ACTIVITY_NAME_FAILURE: `${STATE_REDUCER_KEY}/SELECT_ACTIVITY_NAME_FAILURE`,

  LIST_TECHNICAL_ALL: `${STATE_REDUCER_KEY}/LIST_TECHNICAL_ALL`,
  LIST_TECHNICAL_ALL_REQUEST: `${STATE_REDUCER_KEY}/LIST_TECHNICAL_ALL_REQUEST`,
  LIST_TECHNICAL_ALL_SUCCESS: `${STATE_REDUCER_KEY}/LIST_TECHNICAL_ALL_SUCCESS`,
  LIST_TECHNICAL_ALL_FAILURE: `${STATE_REDUCER_KEY}/LIST_TECHNICAL_ALL_FAILURE`,

  PAYMENT_SCHEDULE_BY_ID: `${STATE_REDUCER_KEY}/PAYMENT_SCHEDULE_BY_ID`,
  PAYMENT_SCHEDULE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/PAYMENT_SCHEDULE_BY_ID_REQUEST`,
  PAYMENT_SCHEDULE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/PAYMENT_SCHEDULE_BY_ID_SUCCESS`,
  PAYMENT_SCHEDULE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/PAYMENT_SCHEDULE_BY_ID_FAILURE`,

  TECHNICAL_FORM_VIEW: `${STATE_REDUCER_KEY}/TECHNICAL_FORM_VIEW`,
  TECHNICAL_FORM_VIEW_REQUEST: `${STATE_REDUCER_KEY}/TECHNICAL_FORM_VIEW_REQUEST`,
  TECHNICAL_FORM_VIEW_SUCCESS: `${STATE_REDUCER_KEY}/TECHNICAL_FORM_VIEW_SUCCESS`,
  TECHNICAL_FORM_VIEW_FAILURE: `${STATE_REDUCER_KEY}/TECHNICAL_FORM_VIEW_FAILURE`

};

export const fetchTechnicalTableList = createAction(ACTION_TYPES.TECHNICAL_TABLE_LIST);
export const projectMonitoringList = createAction(ACTION_TYPES.PROJECT_MONITORING_LIST);
export const updateTechnicalForm = createAction(ACTION_TYPES.PATCH_TECHNICAL_FORM);
export const fetchTechnicalDataById = createAction(ACTION_TYPES.TECHNICAL_FORM_BY_ID);
export const getProjectActivitesList = createAction(ACTION_TYPES.PROJECT_ACTIVITIES_LIST);
export const postProjectActivity = createAction(ACTION_TYPES.POST_PROJECT_ACTIVITY);
export const patchProjectActivity = createAction(ACTION_TYPES.PATCH_PROJECT_ACTIVITY);
export const deleteProjectActivity = createAction(ACTION_TYPES.DELETE_PROJECT_ACTIVITY);
export const fetchContractorList = createAction(ACTION_TYPES.FETCH_CONTRACTOR_LIST);
export const submitPaymentSchedule = createAction(ACTION_TYPES.SUBMIT_PAYMENT_SCHEDULE);
export const listPaymentSchedule = createAction(ACTION_TYPES.LIST_PAYMENT_SCHEDULE);
export const updatePaymentSchedule = createAction(ACTION_TYPES.UPDATE_PAYMENT_SCHEDULE);
export const paymentScheduleById = createAction(ACTION_TYPES.LIST_PAYMENT_SCHEDULE_BY_ID);
export const ProjectActivitesListById = createAction(ACTION_TYPES.PROJECT_ACTIVITIES_LIST_BY_ID);
export const updateProjectActivity = createAction(ACTION_TYPES.UPDATE_PROJECT_ACTIVITY);
export const deleteProjectMonitoring = createAction(ACTION_TYPES.DELETE_PROJECT_MONITORING);
export const contractorListById = createAction(ACTION_TYPES.CONTRACTOR_LIST_BY_ID);
export const selectActivityName = createAction(ACTION_TYPES.SELECT_ACTIVITY_NAME);
export const listTechnicalAll = createAction(ACTION_TYPES.LIST_TECHNICAL_ALL);
export const paymentScheduleData = createAction(ACTION_TYPES.PAYMENT_SCHEDULE_BY_ID);
export const fetchTechnicalView = createAction(ACTION_TYPES.TECHNICAL_FORM_VIEW);
