import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { I18n } from "common/components";
import { fetchUserDetails } from "modules/user-management/register/actions";
import { useNavigate } from "react-router-dom";

const SwitchRoles = () => {
    const navigate = useNavigate();
    const userProfile = useSelector(state => state.profileDetails.userDetails.data);
    const activeRole = {
        value: userProfile.activeRole.id,
        label: userProfile.activeRole.name
    };
    const [select, setSelect] = useState(activeRole);
    const roles = userProfile.roles.map(item => {
        return { value: item.id, label: item.name };
    });
    const dispatch = useDispatch();
    const handleChange = (e) => {
        select && setSelect({ value: e.target.value });
        dispatch(fetchUserDetails({ roleIdToSwitch: e.target.value }));
        navigate("/Dashboard");
    };

    return (
        <Box sx={{ display: "flex", alignItems: "center", color: "white.main" }}>
            <Typography sx={{ fontSize: "18px" }}>{I18n("active_role")}</Typography>
            <Box sx={{ position: "relative", ml: 2 }}>
                <Select sx={{ bgcolor: "white.main", border: 0, color: "secondary.main", fontSize: "16px", borderRadius: "10px", maxWidth: "250px", height: "40px", minWidth: "180px" }} variant="standard" value={select.value} onChange={handleChange} placeholder="Select one">
                    {roles.map((item) => (
                        <MenuItem sx={{ height: "30px", fontSize: "14px" }} key={item} value={item.value} >
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </Box >
    );
};

export default SwitchRoles;
