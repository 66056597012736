import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const saveTrainingNeedAPI = (data) => ({
    url: API_URL.TRAINING_NEED.SAVE_NEED,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_TRAINING_REQUEST, ACTION_TYPES.SAVE_TRAINING_SUCCESS, ACTION_TYPES.SAVE_TRAINING_FAILURE],
        data
    }
});

export const listTrainingNeedApi = (data) => {
    return {
        url: API_URL.TRAINING_NEED.LIST_NEED,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_TRAINING_REQUEST, ACTION_TYPES.LIST_TRAINING_SUCCESS, ACTION_TYPES.LIST_TRAINING_FAILURE],
            data
        }
    };
};

export const fetchTargetGroupApi = () => {
    return {
        url: API_URL.TRAINING_NEED.TARGET_GROUP,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY_REQUEST, ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY_SUCCESS, ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY_FAILURE]
        }
    };
};

export const fetchKnowledgeBaseApi = () => {
    return {
        url: API_URL.TRAINING_NEED.KNOWLEDGE_BASE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_KNOWLEDGE_BASE_REQUEST, ACTION_TYPES.FETCH_KNOWLEDGE_BASE_SUCCESS, ACTION_TYPES.FETCH_KNOWLEDGE_BASE_FAILURE]
        }
    };
};

export const fetchDesignationApi = ({ targetGroupId }) => {
    return {
        url: API_URL.TRAINING_NEED.DESIGNATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DESIGNATION_REQUEST, ACTION_TYPES.FETCH_DESIGNATION_SUCCESS, ACTION_TYPES.FETCH_DESIGNATION_FAILURE],
            data: { targetGroupId }
        }
    };
};
export const fetchTopicApi = () => {
    return {
        url: API_URL.TRAINING_NEED.TOPIC,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TRAINING_TOPIC_REQUEST, ACTION_TYPES.FETCH_TRAINING_TOPIC_SUCCESS, ACTION_TYPES.FETCH_TRAINING_TOPIC_FAILURE]
        }
    };
};

export const deleteNeedFormByIdAPI = (id) => {
    return {
        url: API_URL.TRAINING_NEED.DELETE_NEED.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_NEED_FORM_BY_ID_REQUEST, ACTION_TYPES.DELETE_NEED_FORM_BY_ID_SUCCESS, ACTION_TYPES.DELETE_NEED_FORM_BY_ID_FAILURE]
        }
    };
};

export const editNeedFormAPI = (data) => {
    return {
        url: API_URL.TRAINING_NEED.EDIT_NEED,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_NEED_FORM_REQUEST, ACTION_TYPES.EDIT_NEED_FORM_SUCCESS, ACTION_TYPES.EDIT_NEED_FORM_FAILURE],
            data
        }
    };
};


export const editNeedCategoryTypeAPI = (id) => {
    return {
        url: API_URL.TRAINING_NEED.EDIT_NEED_CATEGORY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.EDIT_NEED_FORM_CATEGORY_REQUEST, ACTION_TYPES.EDIT_NEED_FORM_CATEGORY_SUCCESS, ACTION_TYPES.EDIT_NEED_FORM_CATEGORY_FAILURE],
            data: { id }
        }
    };
};

export const getFileById = (id) => {
    return {
        url: API_URL.TRAINING_NEED.GET_UPLOADED_FILE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_FILE_REQUEST, ACTION_TYPES.FETCH_FILE_SUCCESS, ACTION_TYPES.FETCH_FILE_FAILURE],
            data: { id }
        }
    };
};
export const deleteFileById = (id, fileId) => {
    let DELETE_API_URL = API_URL.TRAINING_NEED.DELETE_FILE_BY_ID;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":file_id", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_FILE_REQUEST, ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_REQUEST]
        }
    };
};
