import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const listDesignation = (data) => {
    return {
        url: API_URL.DESIGNATION_ENTRY.LIST_DESIGNATION_ENTRY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_DESIGNATION_ENTRY_REQUEST, ACTION_TYPES.LIST_DESIGNATION_ENTRY_SUCCESS, ACTION_TYPES.LIST_DESIGNATION_ENTRY_FAILURE],
            data
        }
    };
};
export const updateDesignation = (data) => {
    return {
        url: API_URL.DESIGNATION_ENTRY.UPDATE_DESIGNATION_ENTRY,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_DESIGNATION_ENTRY_REQUEST, ACTION_TYPES.UPDATE_DESIGNATION_ENTRY_SUCCESS, ACTION_TYPES.UPDATE_DESIGNATION_ENTRY_FAILURE],
            data
        }
    };
};
export const submitDesignation = (data) => {
    return {
        url: API_URL.DESIGNATION_ENTRY.SUBMIT_DESIGNATION_ENTRY,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_DESIGNATION_ENTRY_REQUEST, ACTION_TYPES.SUBMIT_DESIGNATION_ENTRY_SUCCESS, ACTION_TYPES.SUBMIT_DESIGNATION_ENTRY_FAILURE],
            data
        }
    };
};
export const deleteDesignation = (id) => {
    return {
        url: API_URL.DESIGNATION_ENTRY.DELETE_DESIGNATION_ENTRY.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_DESIGNATION_ENTRY_REQUEST, ACTION_TYPES.DELETE_DESIGNATION_ENTRY_SUCCESS, ACTION_TYPES.DELETE_DESIGNATION_ENTRY_FAILURE]
        }
    };
};
export const designationGetById = (id) => {
    return {
        url: API_URL.DESIGNATION_ENTRY.DESIGNATION_ENTRY_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.DESIGNATION_ENTRY_BY_ID_REQUEST, ACTION_TYPES.DESIGNATION_ENTRY_BY_ID_SUCCESS, ACTION_TYPES.DESIGNATION_ENTRY_BY_ID_FAILURE],
            data: { id }
        }
    };
};
export const nameList = (data) => {
    return {
        url: API_URL.DESIGNATION_ENTRY.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ ACTION_TYPES.FETCH_NAME_LIST_REQUEST, ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, ACTION_TYPES.FETCH_NAME_LIST_FAILURE ],
            data
        }
    };
};
