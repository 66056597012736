export const API_URL = {
    COMMON: {
        LIST_ULB_DROPDOWN: "admin/api/auth/master/lsgi-details/list/all"
    },
    SWM_PLAN: {
        SUBMIT_SWM_PLAN: "spm/api/auth/spm/swm-plan/save",
        LIST_SWM_PLAN: "spm/api/auth/spm/swm-plan/list/all",
        DELETE_SWM_PLAN: "spm/api/auth/spm/swm-plan/delete/:id",
        EDIT_SWM_PLAN: "spm/api/auth/spm/swm-plan/save",
        LIST_SWM_PLAN_BY_ID: "spm/api/auth/spm/swm-plan/list/by-id",
        LIST_SWM_PLAN_REVISION_BY_SWM_PLAN_ID: "spm/api/auth/spm/swm-plan-revision/list/all",
        DELETE_FILE_BY_ID: "spm/api/auth/spm/swm-plan/delete/:id/attachment/:file_id",
        SUBMITTED_TO_MC: "spm/api/auth/spm/swm-plan/mc/submit",
        STATUS_LIST: "spm/api/auth/spm/ulb-swm-plan-status/list/all",
        FINAL_ATTACHMENT: "spm/api/auth/spm/swm-plan/final-plan/save",
        DOWNLOAD: "report/api/auth/swm-plan/reports/list/export"
    },
    DISTRICT: { LIST_DISTRICT: "admin/api/auth/master/district/list/all" },
    COMPOSITION_OF_TEAM: {
        LIST_COMPOSITION_OF_TEAM: "spm/api/auth/spm/swm-plan/team-composition/list/all",
        SAVE_COMPOSITION_OF_TEAM: "spm/api/auth/spm/swm-plan/team-composition/add-member",
        LIST_COMPOSITION_OF_TEAM_BY_ID: "spm/api/auth/spm/swm-plan/team-composition/list/all",
        UPDATE_COMPOSITION_OF_TEAM: "spm/api/auth/spm/swm-plan/team-composition/update-member",
        DELETE_COMPOSITION_OF_TEAM: "spm/api/auth/spm/swm-plan/team-composition/delete/:id",
        CONFIRM_UPDATE: "spm/api/auth/spm/swm-plan/activity/update"
    },
    GAP_ANALYSIS: {
        SAVE_VERSION: "spm/api/auth/spm/swm-plan/gap-analysis/save",
        DELETE_FILE_BY_ID: "spm/api/auth/spm/swm-plan/gap-analysis/delete/:id/user-attachment/:file_id",
        CONFIRM_UPDATE: "spm/api/auth/spm/swm-plan/activity/update",
        LIST: "spm/api/auth/spm/swm-plan/gap-analysis/list/all",
        ATTACHMENT_LIST: "spm/api/auth/spm/swm-plan/gap-analysis-attachment/list/all",
        ATTACHMENT_BY_ID: "spm/api/auth/spm/swm-plan/gap-analysis-attachment/list/by-id",
        ATTACHMENT_SAVE: "spm/api/auth/spm/swm-plan/gap-analysis-attachment/save",
        DELETE: "spm/api/auth/spm/swm-plan/gap-analysis/delete/:id",
        BY_ID: "spm/api/auth/spm/swm-plan/gap-analysis/list/by-id",
        UPDATE: "spm/api/auth/spm/swm-plan/gap-analysis/save",
        LSGI_DROPDOWN: "admin/api/auth/master/lsgi-details/list/all",
        DOWNLOAD: "report/api/auth/swm-plan/reports/current-status/list/export",
        PROFILE_OF_LOCAL_BODY: {
            SAVE_LOCAL_BODY: "spm/api/auth/spm/gap-analysis/ulb-profile/save",
            LIST_ALL: "spm/api/auth/spm/gap-analysis/ulb-profile/list/all",
            UPDATE_LOCAL_BODY: "spm/api/auth/spm/gap-analysis/ulb-profile/save",
            BY_ID: "spm/api/auth/spm/gap-analysis/ulb-profile/list/by-id"
        },
        TRANSPORTATION: {
            BY_ID: "spm/api/auth/spm/gap-analysis/transportation/list/by-id",
            SAVE: "spm/api/auth/spm/gap-analysis/transportation/save"
        },
        WASTE_GENERATED: {
            SAVE: "spm/api/auth/spm/gap-analysis/waste-generated/save",
            UPDATE: "spm/api/auth/spm/gap-analysis/waste-generated/save",
            BY_ID: "spm/api/auth/spm/gap-analysis/waste-generated/list/by-id",
            LIST: "spm/api/auth/spm/gap-analysis/waste-generated/list/all"
        },
        NON_BIO_FACILITIES: {
            BY_ID: "spm/api/auth/spm/gap-analysis/non-bio-facilities/list/by-id",
            SAVE: "spm/api/auth/spm/gap-analysis/non-bio-facilities/save",
            TOTAL: "spm/api/auth/spm/gap-analysis/non-bio-facilities/total-count",
            NON_BIO_FACILITIES_TYPE: "spm/api/auth/spm/non-bio-facility-types/list/all",
            CURRENT_STATUS: "spm/api/auth/spm/current-status/list/all",
            OWNERSHIP_LIST: "spm/api/auth/spm/ownership/list/all"
        },
        WASTE_GENERATORS: {
            BY_ID_HOUSE_HOLD: "spm/api/auth/spm/gap-analysis/house-hold/waste-generators/list/by-id",
            SAVE_HOUSE_HOLD: "spm/api/auth/spm/gap-analysis/house-hold/waste-generators/save",
            BY_ID_INSTITUTIONS: "spm/api/auth/spm/gap-analysis/institution/waste-generators/list/by-id",
            SAVE_INSTITUTIONS: "spm/api/auth/spm/gap-analysis/institution/waste-generators/save",
            TOTAL_HOUSE_HOLD: "spm/api/auth/spm/gap-analysis/house-hold/waste-generators/total-count",
            TOTAL_INSTITUTIONS: "spm/api/auth/spm/gap-analysis/institution/waste-generators/total-count"

        },
        NON_BIO_FACILITIE_EQUIPMENT: {
            BY_ID: "spm/api/auth/spm/gap-analysis/non-bio-facility/equipments/list/by-id",
            UPDATE: "spm/api/auth/spm/gap-analysis/non-bio-facility/equipments/save"
        },
        FORWARD_LINKED: {
            BY_ID: "spm/api/auth/spm/gap-analysis/forward-linked/list/by-id",
            SAVE: "spm/api/auth/spm/gap-analysis/forward-linked/save"
        },
        INVOLVEMENT_OF_HKS: {
            SAVE: "spm/api/auth/spm/gap-analysis/hks-involvement/save",
            BY_ID: "spm/api/auth/spm/gap-analysis/hks-involvement/list/by-id"
        },
        BIO_FACILITY_EQUIPMENT: {
            BY_ID: "spm/api/auth/spm/gap-analysis/bio-facility/equipments/list/by-id",
            UPDATE: "spm/api/auth/spm/gap-analysis/bio-facility/equipments/save"
        },
        BIO_FACILITIES: {
            BY_ID: "spm/api/auth/spm/gap-analysis/bio-facilities/list/by-id",
            UPDATE: "spm/api/auth/spm/gap-analysis/bio-facilities/save",
            TYPES_DROP_DOWN: "spm/api/auth/spm/swm/bio-facilities/list/all"
        },
        LAND_DETAILS: {
            BY_ID: "spm/api/auth/spm/gap-analysis/land-details/list/by-id",
            UPDATE: "spm/api/auth/spm/gap-analysis/land-details/save"
        },
        STREET_SWEEPING_AND_DRAINS: {
            LIST_ALL: "spm/api/auth/spm/gap-analysis/street-sweeping-drains/list/all",
            SAVE: "spm/api/auth/spm/gap-analysis/street-sweeping-drains/save",
            UPDATE: "spm/api/auth/spm/gap-analysis/street-sweeping-drains/save "
        },
        STAFF_PAYMENTS: {
            LIST_ALL: "spm/api/auth/spm/gap-analysis/staff-payments/list/all",
            SAVE: "spm/api/auth/spm/gap-analysis/staff-payments/save",
            UPDATE: "spm/api/auth/spm/gap-analysis/staff-payments/save",
            BY_ID: "spm/api/auth/spm/gap-analysis/staff-payments/list/by-id"
        },
        OTHER_NO_BIO_FACILITIES: {
            UPDATE: "spm/api/auth/spm/gap-analysis/other-non-bio-facilities/save",
            BY_ID: "spm/api/auth/spm/gap-analysis/other-non-bio-facilities/list/by-id"
        },
        WASTE_PROCESSED_HOUSE_HOLD: {
            BY_ID: "spm/api/auth/spm/gap-analysis/waste-processed/household/list/by-id",
            UPDATE: "spm/api/auth/spm/gap-analysis/waste-processed/household/save"
        },
        WASTE_PROCESSED_INSTITUTIONS: {
            BY_ID: "spm/api/auth/spm/gap-analysis/waste-processed/institutional/list/by-id",
            UPDATE: "spm/api/auth/spm/gap-analysis/waste-processed/institutional/save"
        },
        INCOME_FROM_WASTE: {
            SAVE: "spm/api/auth/spm/gap-analysis/waste-income/save",
            UPDATE: "spm/api/auth/spm/gap-analysis/waste-income/save",
            BY_ID: "spm/api/auth/spm/gap-analysis/waste-income/list/by-id",
            LIST: "spm/api/auth/spm/gap-analysis/waste-income/list/all"
        },
        BDW: {
            SAVE: "spm/api/auth/spm/gap-analysis/waste-processed/bdw/save",
            BY_ID: "spm/api/auth/spm/gap-analysis/waste-processed/bdw/list/by-id",
            LIST_ALL: "spm/api/auth/spm/gap-analysis/waste-processed/bdw/list/all",
            UPDATE: "spm/api/auth/spm/gap-analysis/waste-processed/bdw/save"
        },
        BDW_PROCESSED_PER_DAY: {
            SAVE: "spm/api/auth/spm/gap-analysis/waste-processed/nbdw/save",
            UPDATE: "spm/api/auth/spm/gap-analysis/waste-processed/nbdw/save",
            BY_ID: "spm/api/auth/spm/gap-analysis/waste-processed/nbdw/list/by-id",
            LIST: "spm/api/auth/spm/gap-analysis/waste-processed/nbdw/list/all"
        },
        POPULATION: {
            SAVE: "spm/api/auth/spm/gap-analysis/population/save",
            UPDATE: "spm/api/auth/spm/gap-analysis/population/save",
            BY_ID: "spm/api/auth/spm/gap-analysis/population/list/by-id",
            LIST: "spm/api/auth/spm/gap-analysis/population/list/all",
            WARD_WISE_LIST_ALL: "spm/api/auth/spm/gap-analysis/ward-wise/population/list/all",
            SAVE_WARD_WISE_DATA: "spm/api/auth/spm/gap-analysis/ward-wise/population/save",
            WARD_WISE_BY_ID: "spm/api/auth/spm/gap-analysis/ward-wise/population/list/by-id",
            PATCH_WARD_WISE_DATA: "spm/api/auth/spm/gap-analysis/ward-wise/population/update",
            DELETE_WARD_WISE_DATA: "spm/api/auth/spm/gap-analysis/ward-wise/population/delete/:id"
        },
        BDW_COMMUNITY_LEVEL: {
            SAVE: "spm/api/auth/spm/gap-analysis/waste-collected/bdw/save",
            UPDATE: "spm/api/auth/spm/gap-analysis/waste-collected/bdw/save",
            BY_ID: "spm/api/auth/spm/gap-analysis/waste-collected/bdw/list/by-id",
            LIST: "spm/api/auth/spm/gap-analysis/waste-collected/bdw/list/all"
        },
        NON_BIO_DEGRADABLE: {
            SAVE: "spm/api/auth/spm/gap-analysis/waste-collected/nbdw/save",
            UPDATE: "spm/api/auth/spm/gap-analysis/waste-collected/nbdw/save",
            BY_ID: "spm/api/auth/spm/gap-analysis/waste-collected/nbdw/list/by-id",
            LIST: "spm/api/auth/spm/gap-analysis/waste-collected/nbdw/list/all"
        },
        BIO_DEGRADABLE: {
            BY_ID: "spm/api/auth/spm/gap-analysis/waste-collected/biodegradable/by-id",
            UPDATE: "spm/api/auth/spm/gap-analysis/waste-collected/biodegradable/save"
        },
        NON_BIO_DEGRADABLE_LIST: {
            BY_ID: "spm/api/auth/spm/gap-analysis/waste-collected/non-biodegradable/by-id",
            SAVE: "spm/api/auth/spm/gap-analysis/waste-collected/non-biodegradable/save"
        },
        REPORT: {
            DOWNLOAD_POPULATION_VS_LAND_AREA: "report/api/auth/gap-analysis/reports/population-against-land-area/report/list",
            POPULATION_VS_LAND_AREA: "report/api/auth/gap-analysis/reports/population-against-land-area/list",
            POPULATION_VS_HOUSE_HOLD: "report/api/auth/gap-analysis/reports/population-vs-household/list",
            DOWNLOAD_POPULATION_VS_HOUSE_HOLD: "report/api/auth/gap-analysis/reports/population-vs-household/report/list",
            LAND_USE: "report/api/auth/gap-analysis/reports/land-in-use/list",
            DOWNLOAD_LAND_USE: "report/api/auth/gap-analysis/reports/land-in-use/report/list",
            NBDW_EQUIPMENTS_REPORT: "report/api/auth/gap-analysis/reports/non-bio-facility-equipments/list",
            DOWNLOAD_NBDW_EQUIPMENTS_REPORT: "report/api/auth/gap-analysis/reports/non-bio-facility-equipments/report/list",
            BDW_EQUIPMENTS_REPORT: "report/api/auth/gap-analysis/reports/bio-facility-equipments/list",
            DOWNLOAD_BDW_EQUIPMENTS_REPORT: "report/api/auth/gap-analysis/reports/bio-facility-equipments/report/list",
            NBDW_GENERATED_VS_CAPACITY: "report/api/auth/gap-analysis/reports/nbdw-generated-vs-capacity/list",
            DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT: "report/api/auth/gap-analysis/reports/nbdw-generated-vs-capacity/report/list",
            BDW_GENERATED_VS_CAPACITY: "report/api/auth/gap-analysis/reports/bdw-generated-vs-capacity/list",
            DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT: "report/api/auth/gap-analysis/reports/bdw-generated-vs-capacity/report/list",
            NBDW_FACILITY_REPORT: "report/api/auth/gap-analysis/reports/nbdw-facilities/list",
            DOWNLOAD_NBDW_FACILITY_REPORT: "report/api/auth/gap-analysis/reports/nbdw-facilities/report/list",
            BDW_FACILITY_REPORT: "report/api/auth/gap-analysis/reports/bdw-facilities/list",
            DOWNLOAD_BDW_FACILITY_REPORT: "report/api/auth/gap-analysis/reports/bdw-facilities/report/list",
            CAPITAL_COST_IN_NBDW_REPORT: "report/api/auth/gap-analysis/reports/nbdw-capital-costing/list",
            DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT: "report/api/auth/gap-analysis/reports/nbdw-capital-costing/report/list",
            CAPITAL_COST_IN_BDW_REPORT: "report/api/auth/gap-analysis/reports/bdw-capital-costing/list",
            DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT: "report/api/auth/gap-analysis/reports/bdw-capital-costing/report/list",
            FORWARD_LINKING_REPORT: "report/api/auth/gap-analysis/reports/forwardLinking/list",
            DOWNLOAD_FORWARD_LINKING_REPORT: "report/api/auth/gap-analysis/reports/forwardLinking/report/list",
            INCOME_FROM_BDW_AND_NBDW_REPORT: "report/api/auth/gap-analysis/reports/income_from_bdw_and_nbdw/list",
            DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT: "report/api/auth/gap-analysis/reports/income_from_bdw_and_nbdw/report/list",
            EXPENDITURE_REPORT: "report/api/auth/gap-analysis/reports/bdw-and-nbdw-expenditure/list",
            DOWNLOAD_EXPENDITURE_REPORT: "report/api/auth/gap-analysis/reports/bdw-and-nbdw-expenditure/report/list",
            INCOME_FOR_HKS_FROM_BDW_AND_NBDW_REPORT: "",
            DOWNLOAD_INCOME_FOR_HKS_FROM_BDW_AND_NBDW_REPORT: ""

        }
    },
    STAKEHOLDER_CONSULTATION: {
        SAVE: "spm/api/auth/spm/swm-plan/stakeholder-consultation/save",
        LIST: "spm/api/auth/spm/swm-plan/stakeholder-consultation/list/all",
        UPDATE: "spm/api/auth/spm/swm-plan/stakeholder-consultation/save",
        BY_ID: "spm/api/auth/spm/swm-plan/stakeholder-consultation/list/by-id",
        DELETE: "spm/api/auth/spm/swm-plan/stakeholder-consultation/delete/:id",
        DELETE_FILE_BY_ID: "spm/api/auth/spm/swm-plan/stakeholder-consultation/delete/:id/user-attachment/:file_id",
        CONFIRM_UPDATE: "spm/api/auth/spm/swm-plan/activity/update"
    },
    DRAFT_PLAN: {
        SAVE: "spm/api/auth/spm/swm-plan/draft-plan/save",
        UPDATE: "spm/api/auth/spm/swm-plan/draft-plan/save",
        LIST: "spm/api/auth/spm/swm-plan/draft-plan/list/all",
        DELETE: "spm/api/auth/spm/swm-plan/draft-plan/delete/:id",
        CONFIRM_UPDATE: "spm/api/auth/spm/swm-plan/activity/update",
        BY_ID: "spm/api/auth/spm/swm-plan/draft-plan/list/all",
        LIST_BY_ID: "spm/api/auth/spm/swm-plan/draft-plan/list/by-id"
    },
    LABOUR_MANAGEMENT: {
        PROJECT_LIST: "spm/api/auth/spm/project-procurement/activities/list/all",
        PROJECT_SITES_LIST: "spm/api/auth/llm/site-details/list/all",
        NATURE_OF_WORK_DROPDOWN: "spm/api/auth/llm/site-work-type/list/all",
        WAGE_PERIOD_DROPDOWN: "spm/api/auth/llm/wage-period/list/all",
        DAY_OF_HOLIDAY_DROPDOWN: "admin/api/auth/master/days/list/all",
        DISTRICT_DROPDOWN: "admin/api/auth/master/district/list/all",
        LSGI_DROPDOWN: "admin/api/auth/master/lsgi-details/list/all",
        BASIC_DETAILS_BY_ID: "spm/api/auth/llm/site-details/list/by-id",
        POST_BASIC_DETAILS: "spm/api/auth/llm/site-details/save",
        POST_PROJECT_MANAGER_DETAILS: "spm/api/auth/llm/site-details/site-manager/save",
        PROJECT_MANAGER_BY_ID: "spm/api/auth/llm/site-details/site-manager/list/by-id",
        PROJECT_MANAGER_LIST: "spm/api/auth/llm/site-details/site-manager/list/all",
        MINIMUM_WAGES_GET: "spm/api/auth/llm/site-details/site-wages/list/all",
        MINIMUM_WAGES_POST: "spm/api/auth/llm/site-details/site-wages/save",
        MINIMUM_WAGES_GET_BY_ID: "spm/api/auth/llm/site-details/site-wages/list/by-id",
        REGISTRATION_NUMBER_GET: "spm/api/auth/llm/site-details/employee-license/list/by-siteid",
        EMPLOYEE_LIST: "spm/api/auth/llm/employee-general-details/list/all",
        GENDER_DROPDOWN: "admin/api/auth/master/gender/list/all",
        NATIONALITY_DROPDOWN: "spm/api/auth/llm/index-employee-nationality/list/all",
        EDUCATION_DROPDOWN: "spm/api/auth/llm/index-employee-educational-level/list/all",
        DESIGNATION_DROPDOWN: "spm/api/auth/llm/index-employee-designation/list/all",
        CATEGORY_DROPDOWN: "spm/api/auth/llm/index-employee-catagory/list/all",
        EMPLOYMENT_DROPDOWN: "spm/api/auth/llm/index-employee-type-of-employment/list/all",
        SUB_CONTRACT_DROPDOWN: "spm/api/auth/llm/site-work-type/list/all",
        MARITAL_RADIO_BUTTON: "spm/api/auth/llm/index-employee-marital-status/list/all",
        POST_EMPLOYEE: "spm/api/auth/llm/employee-general-details/save",
        EMPLOYEE_BY_ID: "spm/api/auth/llm/employee-general-details/list/by-id",
        DELETE_EMPLOYEE: "spm/api/auth/llm/employee-general-details/delete/:id",
        REGISTRATION_NUMBER_GET_BY_ID: "spm/api/auth/llm/site-details/employee-license/list/by-id",
        REGISTRATION_NUMBER_PATCH: "spm/api/auth/llm/site-details/employee-license/save",
        BASIC_FACILITIES_BY_ID: "spm/api/auth/llm/site-details/site-basic-facilities/list/by-id",
        BASIC_FACILITIES_PATCH: "spm/api/auth/llm/site-details/site-basic-facilities/save",
        GRIEVANCE_REDRESSAL_LIST: "spm/api/auth/llm/site-details/site-grievance-committee/list/all",
        GRIEVANCE_REDRESSAL_POST: "spm/api/auth/llm/site-details/site-grievance-committee/save",
        GRIEVANCE_REDRESSAL_BY_ID: "spm/api/auth/llm/site-details/site-grievance-committee/list/by-id",
        NOTICE_BY_ID: "spm/api/auth/llm/site-details/site-notice-board/list/by-id",
        NOTICE_PATCH: "spm/api/auth/llm/site-details/site-notice-board/save",
        INTERNAL_COMPLAINT_COMMITTEE_LIST: "spm/api/auth/llm/site-details/site-complaint-committee/list/all",
        INTERNAL_COMPLAINT_COMMITTEE_SAVE: "spm/api/auth/llm/site-details/site-complaint-committee/save",
        INTERNAL_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN: "spm/api/auth/llm/index-complaint-committee-designation/list/all",
        INTERNAL_COMPLAINT_COMMITTEE_BY_ID: "spm/api/auth/llm/site-details/site-complaint-committee/list/by-id",
        WAGE_LIST: "spm/api/auth/llm/employee-wage-details/list/all",
        WAGE_SAVE: "spm/api/auth/llm/employee-wage-details/save",
        WAGE_BY_ID: "spm/api/auth/llm/employee-wage-details/list/by-id/:id",
        DELETE_WAGE_DETAILS: "spm/api/auth/llm/employee-wage-details/soft-delete/:id",
        EMPLOYEE_TERMINATION: "spm/api/auth/llm/employee-general-details/employee/termination",
        SITE_WAGE_REGISTER_LIST: "spm/api/auth/llm/wage-register/project-site/list/all",
        SITE_WAGE_REGISTER_POST: "spm/api/auth/llm/wage-register/project-site/save",
        SITE_WAGE_REGISTER_EMPLOYEE_LIST: "spm/api/auth/llm/wage-register/employee/list/all",
        SITE_WAGE_REGISTER_EMPLOYEE_PATCH: "spm/api/auth/llm/wage-register/employee/save",
        SITE_WAGE_BY_ID: "spm/api/auth/llm/wage-register/employee/list/by-id/:id",
        CARD_COUNT_IN_WAGE_REGISTER: "spm/api/auth/llm/wage-register/project-site/summary/:id",
        DELETE_WAGE_REGISTER: "spm/api/auth/llm/wage-register/project-site/soft-delete/:id",
        DOWNLOAD_EMPLOYEE_PAYSLIP: "report/api/auth/llm/reports/wage-register/report/list",
        EMPLOYEE_VERIFICATION_LIST: "spm/api/auth/llm/employee-verification/list/all",
        VERIFICATION_SAVE: "spm/api/auth/llm/employee-verification/save",
        WORKPLACE_ACCIDENT_LIST: "spm/api/auth/llm/employee-workplace-incidents/list/all",
        EMPLOYEE_NAME_DROPDOWN: "spm/api/auth/llm/employee-general-details/list/all?dropDown=true",
        EMPLOYEE_DETAILS_BY_ID: "spm/api/auth/llm/employee-general-details/get-by-id",
        FATAL_DROPDOWN: "spm/api/auth/llm/index-yes-or-no/list/all",
        POST_WORKPLACE_ACCIDENT: "spm/api/auth/llm/employee-workplace-incidents/save",
        GET_WORKPLACE_ACCIDENT_BY_ID: "spm/api/auth/llm/employee-workplace-incidents/list/by-id",
        PATCH_WORKPLACE_ACCIDENT_BY_ID: "spm/api/auth/llm/employee-workplace-incidents/save",
        FETCH_HARASSMENT_DETAILS: "spm/api/auth/llm/harassment-complaint/list/all",
        SUBMIT_NOTICE_RESPONDENT: "spm/api/auth/llm/harassment-complaint/documents/save",
        GET_ACCORDION_BY_ID: "spm/api/auth/llm/harassment-complaint/documents/list/by-id/:id",
        POST_HARASSMENT_COMPLAINTS: "spm/api/auth/llm/harassment-complaint/save",
        GET_HARASSMENT_BY_ID: "spm/api/auth/llm/harassment-complaint/list/by-id/:id",
        PATCH_ACCORDION: "spm/api/auth/llm/harassment-complaint/documents/save",
        PATCH_HARASSMENT_DETAILS: "spm/api/auth/llm/harassment-complaint/save",
        EMPLOYEE_ATTENDANCE_POST: "spm/api/auth/llm/employee/attendance-register/employee-attendance/register-on-date",
        CARD_COUNT_IN_ATTENDANCE_REGISTER: "report/api/auth/reports/employee/attendance-register/card",
        EMPLOYEE_ATTENDANCE_LIST: "spm/api/auth/llm/employee/attendance-register/list/all",
        TYPE_DROPDOWN: "spm/api/auth/llm/attendance-type/list/all?dropDown=true",
        PATCH_EMPLOYEE_ATTENDANCE: "spm/api/auth/llm/employee/attendance-register/save",
        ATTENDANCE_BY_ID: "spm/api/auth/llm/employee/attendance-register/list/by-id",
        DOWNLOAD_ATTENDANCE_REPORT: "report/api/auth/reports/attendance-register/report/download",
        RECOVERY_REGISTER_LIST: "spm/api/auth/llm/recovery-register/list/all",
        EMPLOYEE_DROPDOWN_LIST: "spm/api/auth/llm/employee-general-details/list/all?dropDown=true",
        RECOVERY_TYPE_DROPDOWN_LIST: "spm/api/auth/llm/recovery-types/list/all?dropDown=true",
        RECOVERY_REGISTER_POST: "spm/api/auth/llm/recovery-register/save",
        INCIDENT_EMPLOYEE_DETAIL: "spm/api/auth/llm/employee-general-details/get-by-id",
        RECOVERY_DETAIL_BY_ID: "spm/api/auth/llm/recovery-register/list/by-id/:id",
        CAUSED_ISSUE_DROPDOWN_LIST: "spm/api/auth/llm/index-yes-or-no/list/all",
        MATERNITY_TYPE_DROPDOWN_LIST: "spm/api/auth/llm/index-types-of-maternity/list/all",
        MATERNITY_LEAVE_LIST: "spm/api/auth/llm/maternity-leave/list/all",
        MATERNITY_DETAIL_BY_ID: "spm/api/auth/llm/maternity-leave/list/by-id",
        MATERNITY_REGISTER_POST: "spm/api/auth/llm/maternity-leave/save",
        DOWNLOAD_RECOVERY_REGISTER: "report/api/auth/llm/reports/recovery-register/report/list",
        DELETE_SITE_DETAILS: "spm/api/auth/llm/site-details/delete/:id",
        VERIFICATION_STATUS: "spm/api/auth/llm/verification-status/list/all?dropDown=true",
        WAGE_VERIFICATION: "spm/api/auth/llm/site-details/site-wages/verify",
        EVENTS_TRACKED_MIN_WAGE: "spm/api/auth/llm/site-details/site-wages/events-tracked/:id",
        BASIC_FACILITY_VERIFICATION: "spm/api/auth/llm/site-details/site-basic-facilities/verify",
        EVENTS_TRACKED_BASIC_FACILITY: "spm/api/auth/llm/site-details/site-basic-facilities/events-tracked/:id",
        WAGE_DETAILS_VERIFICATION: "spm/api/auth/llm/employee-wage-details/verify",
        EVENTS_TRACKED_WAGE_DETAILS: "spm/api/auth/llm/employee-wage-details/events-tracked/:id",
        WAGE_REGISTER_VERIFICATION: "spm/api/auth/llm/wage-register/project-site/verify",
        EVENTS_TRACKED_WAGE_REGISTER: "spm/api/auth/llm/wage-register/project-site/events-tracked/:id",
        ATTENDANCE_REGISTER_VERIFICATION: "spm/api/auth/llm/employee/attendance-register/verify",
        EVENTS_TRACKED_ATTENDANCE_REGISTER: "spm/api/auth/llm/employee/attendance-register/events-tracked/employeeId/:id/entryDate/:date",
        MATERNITY_VERIFICATION: "spm/api/auth/llm/maternity-leave/verify",
        EVENTS_TRACKED_MATERNITY: "spm/api/auth/llm/maternity-leave/events-tracked/:id",
        RECOVERY_VERIFICATION: "spm/api/auth/llm//recovery-register/verify",
        EVENTS_TRACKED_RECOVERY: "spm/api/auth/llm/recovery-register/events-tracked/:id",
        WORKPLACE_VERIFICATION: "spm/api/auth/llm/employee-workplace-incidents/verify",
        EVENTS_TRACKED_WORKPLACE: "spm/api/auth/llm/employee-workplace-incidents/events-tracked/:id",
        HARASSMENT_VERIFICATION: "spm/api/auth/llm/harrasment-complaint/verify",
        EVENTS_TRACKED_HARASSMENT: "spm/api/auth/llm/harrasment-complaint/events-tracked/:id",
        DPMU_REMARKS_NOTICE: "spm/api/auth/llm/site-details/site-notice-board/add-remarks",
        DPMU_REMARKS_GRIEVANCE: "spm/api/auth/llm/site-details/site-grievance-committee/add-remarks",
        DPMU_REMARKS_COMPLAINT: "spm/api/auth/llm/site-details/site-complaint-committee/add-remarks",
        DPMU_REMARKS_EMPLOYEE: "spm/api/auth/llm/employee-general-details/add-remarks"
    },
    SUB_PROJECT: {
        SUB_PROJECT_LIST: "spm/api/auth/spm/swm-plan/sub-project/list/all",
        SUB_PROJECT_LIST_BY_ID: "spm/api/auth/spm/swm-plan/sub-project/list/by-id",
        SELECT_TRACK_LIST: "spm/api/auth/spm/project-track/list/all",
        SELECT_CATEGORY_TYPE: "spm/api/auth/spm/project-category/list/all",
        SELECT_ENV_CATEGORY_TYPE: "spm/api/auth/spm/project-env-category/list/all",
        SELECT_SUB_CATEGORY_TYPE: "spm/api/auth/spm/project-sub-category/list/all",
        SELECT_SUB_CATEGORY_TWO_TYPE: "spm/api/auth/spm/project-sub-category-2/list/all",
        SELECT_PROJECT_TYPE: "spm/api/auth/spm/project-types/list/all",
        POST_SUB_PROJECT: "spm/api/auth/spm/swm-plan/sub-project/save",
        DELET_SUB_PROJECT_LIST: "spm/api/auth/spm/swm-plan/sub-project/delete/:id",
        SELECT_PROJECT_APPLICABLE: "spm/api/auth/spm/project-doc-applicability/list/all",
        SUB_PROJECT_DRAFT_LIST: "spm/api/auth/spm/swm-plan/draft-plan/project-list/list/all",
        POST_SUB_PROJECT_DRAFT: "spm/api/auth/spm/swm-plan/draft-plan/project-list/save",
        SELECT_ANNUAL_PLAN: "spm/api/auth/spm/annual-plan/list/all",
        SELECT_PROJECT_LIST: "spm/api/auth/spm/annual-plan/projects/list/all",
        NATURE_OF_WORK_LIST_ALL: "spm/api/auth/spm/nature-of-work/list/all?dropDown=true"
    },
    SWM_PLAN_FINALIZE: {
        SAVE: "spm/api/auth/spm/swm-plan/sub-project/final/add-sub-project",
        LIST: "spm/api/auth/spm/swm-plan/list/all",
        DELETE: "spm/api/auth/spm/swm-plan/sub-project/final/delete/:id",
        SAVE_SUB_PROJECT: "spm/api/auth/spm/swm-plan/sub-project/final/add-new",
        FETCH_BY_ID: "spm/api/auth/spm/swm-plan/list/all",
        SUB_PROJECT_LIST: "spm/api/auth/spm/swm-plan/sub-project/list/all",
        SUB_PROJECT_LIST_BY_ID: "spm/api/auth/spm/swm-plan/sub-project/list/all",
        SUB_PROJECT_FINAL_LIST: "spm/api/auth/spm/swm-plan/sub-project/final/list/all",
        BY_ID: "spm/api/auth/spm/swm-plan/sub-project/final/list/by-id",
        UPDATE_FINAL_SUB_PROJECT: "spm/api/auth/spm/swm-plan/sub-project/final/update",
        SWM_PLAN_LIST: "spm/api/auth/spm/swm-plan/finalize",
        DELETE_FILE_BY_ID: "spm/api/auth/spm/swm-plan/sub-project/final/delete/:id/concept-note-attachment",
        FINAL_MAPPED_LIST: "spm/api/auth/spm/swm-plan/sub-project/final/list/all"
    },
    FIVE_YEAR_PLAN: {
        ALL_LIST: "spm/api/auth/spm/five-year-plan/list/all",
        BY_ID: "spm/api/auth/spm/five-year-plan/list/by-id",
        SAVE: "spm/api/auth/spm/five-year-plan/save",
        UPDATE: "spm/api/auth/spm/five-year-plan/save",
        SUBMITTED_TO_MC: "spm/api/auth/spm/five-year-plan/dpmu/submit",
        SPMU_APPROVE: "spm/api/auth/spm/five-year-plan/spmu/approve",
        SPMU_REJECT: "spm/api/auth/spm/five-year-plan/spmu/reject",
        DPMU_APPROVE: "spm/api/auth/spm/five-year-plan/dpmu/approve",
        DPMU_REJECT: "spm/api/auth/spm/five-year-plan/dpmu/reject",
        MUNICIPAL_APPROVE: "spm/api/auth/spm/five-year-plan/municipal-council/approve",
        MUNICIPAL_REJECT: "spm/api/auth/spm/five-year-plan/municipal-council/reject",
        DELETE_FIVE_YEAR: "spm/api/auth/spm/five-year-plan/delete/:id",
        DELETE_FILE_BY_ID: "spm/api/auth/spm/five-year-plan/delete/:id/attachment/:file_id",
        ASSIGN_PROJECT: "spm/api/auth/spm/five-year-plan/assign-projects",
        FIVE_YEAR_PROJECTS: "spm/api/auth/spm/five-year-plan/projects/list/all",
        SUB_PROJECTS: "spm/api/auth/spm/swm-plan/sub-project/final/list/all",
        STATUS_LIST: "spm/api/auth/spm/ulb-swm-plan-status/list/all",
        SWM_PLAN: "spm/api/auth/spm/swm-plan/list/all",
        CEILED_AMOUNT: "gms/api/auth/gms/grant/ceiling/list/all"
    },
    APPROVE_MUNICIPAL: {
        APPROVE: "spm/api/auth/spm/swm-plan/municipal-council/approve",
        REJECT: "spm/api/auth/spm/five-year-plan/municipal-council/reject",
        BY_ID: "spm/api/auth/spm/swm-plan-revision/list/all"
    },
    APPROVE_DPMC: {
        DROPDOWN: "spm/api/auth/spm/dpr-approval-status/list/all?dropdown=true",
        PATCH: "spm/api/auth/spm/swm-plan/approval-details/save",
        GET: "spm/api/auth/spm/swm-plan-revision/list/all"
    },
    APPROVE_QA_QC: {
        APPROVE: "spm/api/auth/spm/five-year-plan/dpmu/approve",
        REJECT: "spm/api/auth/spm/five-year-plan/dpmu/reject",
        BY_ID: "spm/api/auth/spm/swm-plan-revision/list/all"
    },
    APPROVE_SPMU: {
        APPROVE: "spm/api/auth/spm/swm-plan/spmu/approve",
        REJECT: "spm/api/auth/spm/swm-plan/spmu/reject",
        DOWNLOAD: "report/api/auth/swm-plan/reports/revision/comments/export"
    },
    DISCUSSIONS_AND_APPROVAL: {
        WORKING_GROUP_DISCUSSION_COMMON: {
            SAVE: "spm/api/auth/spm/sub-project/annual-plan/working-group/discussions/save",
            LIST_ALL: "spm/api/auth/spm/sub-project/annual-plan/working-group/discussions/list/all",
            BY_ID: "spm/api/auth/spm/sub-project/annual-plan/working-group/discussions/list/by-id"
        },
        VIKASANA_SEMINAR_COMMON: {
            SAVE: "spm/api/auth/spm/sub-project/annual-plan/vikasana-seminars/save",
            LIST_ALL: "spm/api/auth/spm/sub-project/annual-plan/vikasana-seminars/list/all",
            BY_ID: "spm/api/auth/spm/sub-project/annual-plan/vikasana-seminars/list/by-id"
        },
        HSC_APPROVAL: {
            SAVE: "spm/api/auth/spm/sub-project/annual-plan/adp-process/hsc-consent/approval",
            LIST_ALL: "spm/api/auth/spm/sub-project/annual-plan/adp-process/list/all",
            PATCH: "spm/api/auth/spm/sub-project/adp-process/save"
        },
        MC_APPROVAL: {
            SAVE: "spm/api/auth/spm/sub-project/annual-plan/adp-process/municipal-council/approve",
            LIST_ALL: "spm/api/auth/spm/sub-project/annual-plan/adp-process/list/all",
            BY_ID: "spm/api/auth/spm/sub-project/annual-plan/adp-process/list/by-id",
            PATCH: "spm/api/auth/spm/sub-project/adp-process/save"
        },
        DPC_APPROVAL_COMMON: {
            SAVE: "spm/api/auth/spm/sub-project/annual-plan/adp-process/district-planning-committe/approve",
            LIST_ALL: "spm/api/auth/spm/sub-project/annual-plan/adp-process/list/all",
            PATCH: "spm/api/auth/spm/sub-project/adp-process/save"
        }
    },
    SWM_SUB_PROJECTS: {
        PROJECT_CODE_DROPDOWN: "spm/api/auth/spm/sub-project/preparation-list/filter-list",
        APPROVAL_STATUS: "spm/api//auth/spm/approval-status/list/all?dropDown=true",
        ALL_LIST: "spm/api/auth/spm/sub-project/preparation-list/list/all",
        SOCIAL_RISK_CATEGORIZATION: {
            SAVE: "spm/api/auth/spm/sub-project/social-risk/compliance/save",
            SOCIAL_RISK_DROPDOWN: "spm/api/auth/spm/swm/social-risk-catogarization-status/list/all",
            GET: "spm/api/auth/spm/sub-project/social-risk/compliance/list/all"
        },
        WORKING_GROUP_DISCUSSION: {
            SAVE: "spm/api/auth/spm/sub-project/working-group/discussions/save",
            BY_ID: "spm/api/auth/spm/sub-project/working-group/discussions/list/by-id",
            LIST_ALL: "spm/api/auth/spm/sub-project/working-group/discussions/list/all"
        },
        DELETE_PROJECT: "spm/api/auth/spm/sub-project/preparation-list/delete/:id",
        POST_SUB_PROJECT: "spm/api/auth/spm/sub-project/preparation-list/save",
        SUB_PROJECT_BY_ID: "spm/api/auth/spm/sub-project/preparation-list/list/by-id",
        SUBMIT_SCREENING: "spm/api/auth/spm/sub-project/screening-committee/submit/:id",
        POST_SCREENING: "spm/api/auth/spm/sub-project/screening/save",
        SUBMIT_HSC_APPROVAL: "spm/api/auth/spm/sub-project/adp-process/hsc-consent/approval",
        SUBMIT_DPC_APPROVAL: "spm/api/auth/spm/sub-project/adp-process/district-planning-committe/approve",
        REJECT_HSC_APPROVAL: "spm/api/auth/spm/sub-project/adp-process/hsc-consent/reject",
        GET_ADP_PROCESS: "spm/api/auth/spm/sub-project/adp-process/list/by-id?projectId=:id",
        SUBMIT_MC_APPROVAL: "spm/api/auth/spm/sub-project/adp-process/municipal-council/approve",
        REJECT_MC_APPROVAL: "spm/api/auth/spm/sub-project/adp-process/municipal-council/reject",
        GET_SCREENING: "spm/api/auth/spm/sub-project/screening/list/by-id?projectId=:id",
        ENV_SAFEGUARD_BY_ID: "spm/api/auth/spm/sub-project/environment-safeguards/list/by-id?projectId=:id",
        ENV_SAFEGUARD_LIST: "spm/api/auth/spm/sub-project/environment-safeguards/list/all?projectId=:id",
        LIST_ALL_GET_SCREENING: "spm/api/auth/spm/sub-project/screening/list/all?projectId=:id",
        LIST_ALL_ADP_PROCESS: "spm/api/auth/spm/sub-project/adp-process/list/all",
        ACTIVITY: {
            LIST: "spm/api/auth/spm/sub-project/milestones/list/by-projectId",
            BY_ID: "spm/api/auth/spm/sub-project/milestones/list/by-id",
            CREATE: "spm/api/auth/spm/sub-project/milestones/save",
            DELETE: "spm/api/auth/spm/sub-project/milestones/delete/:id",
            UOM: "spm/api/auth/spm/uom/list/all",
            DELETE_ATTACHMENT: "spm/api/auth/spm/sub-project/activities/delete/:id/attachment/:attachmentId"
        },
        VIKASANA_SEMINAR: {
            SAVE: "spm/api/auth/spm/sub-project/vikasana-seminars/save",
            BY_ID: "spm/api/auth/spm/sub-project/vikasana-seminars/list/by-id",
            LIST_ALL: "spm/api/auth/spm/sub-project/vikasana-seminars/list/all"
        },
        SAFE_GUARD: {
            POST: "spm/api/auth/spm/sub-project/social-safeguards/save",
            LIST: "spm/api/auth/spm/sub-project/social-safeguards/list/all",
            BY_ID: "spm/api/auth/spm/sub-project/social-safeguards/list/by-id"

        },
        REMARK_LIST: "admin/api/auth/master/remarks/list/all",
        ENVIRONMENTAL_SAFEGUARD: {
            TYPE_OF_ACTIVITY: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=201",
            PHASE_ONE_SCREENING: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=202",
            PHASE_TWO_SCREENING: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=203",
            ESG_SCREENING: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=204",
            ENVIRONMENTAL_CATEGORY: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=205",
            ESG_INSTRUMENT: "admin/api/auth/master/lookup/list/all?dropDown=true",
            DUE_DILIGENCE: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=203",
            DUE_DILIGENCE_TYPE: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=208",
            REASON_UNDER_EIA: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=209",
            TYPE_DILIGENCE: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=210",
            ESG_DOCUMENT_PREPARATION_RESPONSIBILITY: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=204",
            FINAL_ESG_SUBMITTED: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=212&lookupCategoryId=203",
            FINAL_ESG_APPROVAL: "admin/api/auth/master/lookup/list/all?dropDown=true",
            ESG_SUPERVISION: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=204",
            PROJECT_DATA_BY_ID: "spm/api/auth/spm/sub-project/environmental-safeguards/project-data/list/by-id",
            PROJECT_DATA_LIST: "spm/api/auth/spm/sub-project/environmental-safeguards/project-data/list/all",
            POST_PROJECT_DATA: "spm/api/auth/spm/sub-project/environmental-safeguards/project-data/save",
            PATCH_PROJECT_DATA: "spm/api/auth/spm/sub-project/environmental-safeguards/project-data/save",

            TYPES_OF_WASTE: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=301",
            TYPES_OF_MANAGEMENT: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=302",
            ARRANGEMENT_RECYCLE: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=306",
            CATEGORY_OF_EDD_ISSUE: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=303",
            SOURCE_OF_EDD: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=304",
            STATUS_OF_EDD_ISSUE: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=305",
            ESG_ISSUE: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=307",
            PERMIT_CONSENT_REQUIRED: "spm/api/auth/spm/permits/consents/list/all",
            INCIDENT_CLASSIFICATION: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=311",
            STATUS_OF_COMPENSATION: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=312",
            INCIDENT_IMPLEMENTATION: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=313",
            ATTENDEE_PROFILE: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=314",
            RESPONSIBLE_AGENCY: "spm/api/auth/spm/authority/list/all",
            STATUS_OF_PERMITS: "admin/api/auth/master/lookup/list/all?dropDown=true&lookupCategoryId=310",
            POST_COMPENDIUM_DATA: "spm/api/auth/spm/sub-project/environmental-safeguards/compendium/save",
            PATCH_COMPENDIUM_DATA: "spm/api/auth/spm/sub-project/environmental-safeguards/compendium/save",
            COMPENDIUM_DATA_BY_ID: "spm/api/auth/spm/sub-project/environmental-safeguards/compendium/list/by-id",
            COMPENDIUM_DATA_LIST_ALL: "spm/api/auth/spm/sub-project/environmental-safeguards/compendium/list/all"
        },
        ANNUAL_PLAN_YEAR_LIST: "spm/api/auth/spm/annual-plan/annual-plan-year/list/all/by-id",
        PROJECT_SOCIAL_SAFEGUARD: {
            PROJECT_LEVEL_DATA_LIST: "spm/api/auth/spm/sub-project/social-safeguards/projectlevel-data/list/by-id",
            STATUS_DROPDOWN: "spm/api/auth/spm/swm/social-safeguard-activity-status/list/all?dropDown=true",
            PROJECT_LEVEL_DATA_POST: "spm/api/auth/spm/sub-project/social-safeguards/projectlevel-data/save",
            SAFEGUARD_COMPLIANCE_LIST: "spm/api/auth/spm/sub-project/social-safeguards/compliance/list/by-id",
            SAFEGUARD_COMPLIANCE_POST: "spm/api/auth/spm/sub-project/social-safeguards/compliance/save",
            COMMUNITY_CONSULTATION_LIST: "spm/api/auth/spm/sub-project/social-safeguards/subproject-scheme-cycle/list/by-id",
            COMMUNITY_CONSULTATION_POST: "spm/api/auth/spm/sub-project/social-safeguards/subproject-scheme-cycle/save"
        },
        SULEKHA_INFORMATION: {
            FINANCIAL_YEAR_DROPDOWN: "gms/api/auth/gms/financial-year/list/all",
            SULEKHA_PROJECT_SAVE: "spm/api/auth/spm/sub-project/sulekha/project/fetch/list",
            SULEKHA_PROJECT_DROP_DOWN: "spm/api/auth/spm/sub-project/sulekha/project/list",
            SULEKHA_PROJECT_LIST_ALL: "spm/api/auth/spm/sub-project/sulekha/project/detail/list/all",
            FETCH_SULEKHA_PROJECT: "spm/api/auth/spm/sub-project/sulekha/project/detail",
            FETCH_SULEKHA_BY_ID: "spm/api/auth/spm/sub-project/sulekha/project/detail/list/by-id",
            REVERT_SULEKHA: "spm/api/auth/spm/sub-project/sulekha/project/detail/revert"
        },
        DOWNLOAD: "report/api/auth/swm-plan/reports/sub-projects/export",
        FACILITY_AND_LOCATION: {
            SAVE: "spm/api/auth/spm/sub-project/facilities/save",
            LIST: "spm/api/auth/spm/sub-project/facilities/list/all",
            BY_ID: "spm/api/auth/spm/sub-project/facilities/list/by-id",
            DELETE: "spm/api/auth/spm/sub-project/facilities/delete/:id"
        }
    },
    ANNUAL_PLAN: {
        SAVE: "spm/api/auth/spm/annual-plan/save",
        DELETE_FILE_BY_ID: "spm/api/auth/spm/annual-plan/delete/:id/attachment/:file_id",
        LIST: "spm/api/auth/spm/annual-plan/list/all",
        DELETE: "spm/api/auth/spm/annual-plan/delete/:id",
        UPDATE: "spm/api/auth/spm/annual-plan/save",
        BY_ID: "spm/api/auth/spm/annual-plan/list/by-id",
        SUBMITTED_TO_MC: "spm/api/auth/spm/annual-plan/dpmu/submit",
        DPMU_APPROVE: "spm/api/auth/spm/annual-plan/dpmu/approve",
        DPMU_REJECT: "spm/api/auth/spm/annual-plan/dpmu/reject",
        SPMU_APPROVE: "spm/api/auth/spm/annual-plan/spmu/approve",
        SPMU_REJECT: "spm/api/auth/spm/annual-plan/spmu/reject",
        MUNICIPAL_APPROVE: "spm/api/auth/spm/annual-plan/muncipal-council/approve",
        MUNICIPAL_REJECT: "spm/api/auth/spm/annual-plan/muncipal-council/reject",
        FIVE_YEAR_PROJECTS: "spm/api/auth/spm/five-year-plan/projects/list/all",
        ANNUAL_PROJECTS: "spm/api/auth/spm/annual-plan/projects/list/all",
        ASSIGN_PROJECT: "spm/api/auth/spm/annual-plan/assign-projects",
        STATUS_LIST: "spm/api/auth/spm/ulb-swm-plan-status/list/all",
        FIVE_YEAR_PLAN: "spm/api/auth/spm/five-year-plan/list/all",
        DOWNLOAD: "report/api/auth/swm-plan/reports/annual-plan/list/export"
    },
    UPLOAD_ANNUAL_PLAN: {
        UPLOAD: "spm/api/auth/spm/annual-plan/save",
        REJECT: "spm/api/auth/spm/annual-plan/spmu/reject",
        BY_ID: "spm/api/auth/spm/annual-plan/list/by-id"
    },
    WARD_SABHA: {
        LIST: "spm/api/auth/spm/sub-project/ward-sabha/discussions/list/all",
        CREATE: "spm/api/auth/spm/sub-project/ward-sabha/discussions/save",
        LIST_BY_ID: "spm/api/auth/spm/sub-project/ward-sabha/discussions/list/by-id",
        DELETE: "spm/api/auth/spm/sub-project/ward-sabha/discussions/delete/:id",
        COMPLETED: "spm/api/auth/spm/sub-project/ward-sabha/process-flow/update/:id",
        FILTER_WARD: "spm/api/auth/spm/sub-project/ward-sabha/discussions/filter-list"
    },

    DPR_FLOW: {
        NOC: {
            SAVE_UPDATE: "spm/api/auth/spm/sub-project/wb/noc-comments/save",
            GET_BY_ID: "spm/api/auth/spm/sub-project/wb/noc-comments/list/all?projectId=:id"
        },
        DPR_PREPARATION: {
            SAVE: "spm/api/auth/spm/sub-project/dpr-list/save",
            BY_ID: "spm/api/auth/spm/sub-project/dpr-list/list/by-id",
            SUBMITTED_TO_MC: "spm/api/auth/spm/sub-project/dpr-list/dpmu/submit"
        },
        TECHNICAL_SANCTION: {
            SAVE_UPDATE: "spm/api/auth/spm/sub-project/tc/ts-comments/save",
            GET_BY_ID: "spm/api/auth/spm/sub-project/tc/ts-comments/list/all?projectId=:id"
        },
        FINANCIAL_SANCTION: {
            SAVE_UPDATE: "spm/api/auth/spm/sub-project/fs/from-pd/save",
            GET_BY_ID: "spm/api/auth/spm/sub-project/fs/from-pd/list/all?projectId=:id"
        },
        QA_QC_BY_DPMU: {
            REJECT: "spm/api/auth/spm/sub-project/dpr-list/dpmu/modify",
            APPROVE: "spm/api/auth/spm/sub-project/dpr-list/dpmu/approve"
        },
        SPMU: {
            REJECT: "spm/api/auth/spm/sub-project/dpr-list/spmu/reject",
            APPROVE: "spm/api/auth/spm/sub-project/dpr-list/spmu/approve"
        },
        MUNICIPAL: {
            REJECT: "spm/api/auth/spm/sub-project/dpr-list/municipal-council/reject",
            APPROVE: "spm/api/auth/spm/sub-project/dpr-list/municipal-council/approve"
        },
        DPR: {
            LIST: "spm/api/auth/spm/sub-project/dpr-list/list/all"
        }
    },
    ACTIVITY_MONITORING: {
        LIST: "spm/api/auth/spm/project-monitoring/preparation-list/list/all",
        DELETE: "spm/api/auth/spm/sub-project/preparation-list/delete/:id",
        TECHNICAL: {
            LIST: "spm/api/auth/spm/project-monitoring/activities/contract/details/list/all",
            SAVE: "spm/api/auth/spm/project-monitoring/activities/work-progress/save",
            BY_ID: "spm/api/auth/spm/project-monitoring/activities/contract/details/list/by-id",
            VIEW: "spm/api/auth/spm/project-monitoring/activities/work-progress/list/all",
            LIST_ALL: "spm/api/auth/spm/project-monitoring/activities/contract/list/all",
            ACTIVITY_NAME: "spm/api/auth/spm/project-procurement/activities/list/all"
        },
        PROJECTACTIVITY: {
            LIST: "spm/api/auth/spm/project-procurement/activities/list/all",
            SAVE: "spm/api/auth/spm/project-monitoring/activities/contract/save",
            UPDATE: "spm/api/auth/spm/project-monitoring/activities/contract/details/save",
            BY_ID: "spm/api/auth/spm/sub-project/project-activity/list/by-id",
            DELETE: "spm/api/auth/spm/project-monitoring/activities/contract/delete/:id",
            LIST_BY_ID: "spm/api/auth/spm/project-monitoring/activities/contract/details/list/all",
            CONTRACTOR_BY_ID: "spm/api/auth/spm/project-procurement/activities/details/list/by-id"
        },
        PAYMENT_SCHEDULE: {
            LIST: "spm/api/auth/spm/project-monitoring/payment/schedule/list/all",
            SAVE: "spm/api/auth/spm/project-monitoring/payment/schedule/save",
            UPDATE: "spm/api/auth/spm/project-monitoring/payment/schedule/save",
            BY_ID: "spm/api/auth/spm/sub-project/preparation-list/list/by-id",
            EDIT_DATA: "spm/api/auth/spm/project-monitoring/payment/schedule/list/by-id"
        }
    },
    PROCUREMENT: {
        PROCUREMENT_ACTIVITIES: "spm/api/auth/spm/project-procurement/activities/list/all",
        SAVE: "spm/api/auth/spm/project-procurement/activities/save",
        PROJECT_LIST: "spm/api/auth/spm/project-monitoring/preparation-list/list/all",
        PROJECT_LISTING_BY_ID: "spm/api/auth/spm/sub-project/preparation-list/list/by-id",
        PROCESS_STATUS: "spm/api/auth/spm/procurement/process-status/list/all",
        PROCUREMENT_METHOD: "spm/api/auth/spm/procurement-method/list/all",
        PROCUREMENT_CATEGORY: "spm/api/auth/spm/procurement-category/list/all",
        MARKET_APPROACH: "spm/api/auth/spm/market-approach/list/all",
        REVIEW_TYPE: "spm/api/auth/spm/review-type/list/all",
        VENDOR: "admin/api/auth/master/vendor/list/all",
        PROCUREMENT_LISTING_BY_ID: "spm/api/auth/spm/project-procurement/activities/details/list/by-id"
    },
    SOCIAL_SAFEGUARD: {
        LIST: "spm/api/auth/spm/social-safeguard/list/all",
        DISTRICT: "admin/api/auth/master/district/list/all",
        ULB: "admin/api/auth/master/lsgi-details/list/all",
        BASELINE_DATA_POST: "spm/api/auth/spm/social-safeguard/baseline-data/save",
        BASELINE_DATA_PATCH: "spm/api/auth/spm/social-safeguard/baseline-data/save",
        BASELINE_DATA_GET: "spm/api/auth/spm/social-safeguard/baseline-data/list/by-id",
        SOCIAL_SAFEGUARD_POST: "spm/api/auth/spm/social-safeguard/save",
        ULB_DETAILS: "admin/api/auth/master/lsgi-details/list/all",
        BASELINE_CONTENT: "spm/api/auth/spm/social-safeguard/baseline-data/list/all?lsgiId=:id",
        SCHEME_CYCLIC_GET: "spm/api/auth/spm/social-safeguard/scheme-cycle-activities/list/by-id",
        SCHEME_CYCLIC_POST: "spm/api/auth/spm/social-safeguard/scheme-cycle-activities/save",
        SCHEME_CYCLIC_PATCH: "spm/api/auth/spm/social-safeguard/scheme-cycle-activities/save",
        SAFEGUARD_COMPLAINTS_GET: "spm/api/auth/spm/social-safeguard/ulb-safeguard-complaints/list/by-id",
        SAFEGUARD_POST: "spm/api/auth/spm/social-safeguard/ulb-safeguard-complaints/save",
        SAFEGUARD_PATCH: "spm/api/auth/spm/social-safeguard/ulb-safeguard-complaints/save",
        DOWNLOAD: "report/api/auth/spm/reports/social-safeguards/report/list"
    },
    LAND_ACQUISITION: {
        LIST: "spm/api/auth/spm/land-acquisitions/list/all",
        PATCH: "spm/api/auth/spm/land-acquisitions/save"
    },
    SWM_PLAN_REPORT: {
        ANNUAL_PLAN: {
            DOWNLOAD: "report/api/auth/swm-plan/reports/approved-plans/list/export",
            LIST: "report/api/auth/swm-plan/reports/table-view/list"
        },
        COST_INTERVENTION: {
            LIST: "report/api/auth/swm-plan/reports/project-cost/summary/table-view",
            DOWNLOAD: "report/api/auth/swm-plan/reports/project-cost/summary/export"
        },
        STATUS_SUMMARY: {
            LIST: "report/api/auth/swm-plan/reports/status-summary/table-view",
            DOWNLOAD: "report/api/auth/swm-plan/reports/status-summary/export"
        },
        COST_INTERVENTION_YEAR_WISE: {
            LIST: "report/api/auth/swm-plan/reports/cost-interventions/summary/table-view",
            DOWNLOAD: "report/api/auth/swm-plan/reports/cost-interventions/year-wise/summary/export"
        }
    },
    SWM_SUB_PROJECT_REPORT: {
        DETAILS_OF_TEAM: {
            LIST: "report/api/auth/swm-plan/reports/details-of-team/table-view",
            DOWNLOAD: "report/api/auth/swm-plan/reports/details-of-team/export"
        },
        TEAM_COMPOSITION: {
            LIST: "report/api/auth/swm-plan/reports/status-of-team-composition/table-view",
            DOWNLOAD: "report/api/auth/swm-plan/reports/status-of-team-composition/export"
        },
        GAP_ANALYSIS: {
            LIST: "report/api/auth/swm-plan/reports/gap-analysis/waste-generation/table-view",
            DOWNLOAD: "report/api/auth/swm-plan/reports/gap-analysis/waste-generation/export"
        }
    }
};
