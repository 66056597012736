import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadContent, getPayloadData } from "utils/apiUtils";

import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const SHO_NGO_DETAILS = {
    name: "",
    description: "",
    address: "",
    contactList: []
};
const TRAINING_ORGANIZATION_DETAILS = {
    name: "",
    description: "",
    address: "",
    contactList: []
};

const initialState = {
    shoNgoGetById: {
        requestInProgress: false,
        data: {
            ...SHO_NGO_DETAILS
        }
    },
    lookupCategoryDropdown: {
        requestInProgress: false,
        data: []
    },
    contactTypeDropdown: {
        requestInProgress: false,
        data: []
    },
    updateShoNgo: {
        requestInProgress: false,
        data: []
    },
    updateTrainingOrganization: {
        requestInProgress: false,
        data: []
    },
    trainingOrganizationContactsById: {
        requestInProgress: false,
        data: {
            ...TRAINING_ORGANIZATION_DETAILS
        }
    },
    filterShoNgoName: {
        requestInProgress: false,
        data: []
    },
    nameList: {
        requestInProgress: false,
        data: []
    },
    addressList: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.trainingOrganizationContactsById = initialState.trainingOrganizationContactsById;
            state.shoNgoGetById = initialState.shoNgoGetById;
            state.filterShoNgoName = initialState.filterShoNgoName;
            state.nameList = initialState.nameList;
            state.addressList = initialState.addressList;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_CONTACT_TYPE_REQUEST, (state) => {
                state.contactTypeDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_CONTACT_TYPE_SUCCESS, (state, action) => {
                _.set(state, "contactTypeDropdown.requestInProgress", false);
                _.set(state, "contactTypeDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.FETCH_CONTACT_TYPE_FAILURE, (state) => {
                state.contactTypeDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_REQUEST, (state) => {
                state.lookupCategoryDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_SUCCESS, (state, action) => {
                _.set(state, "lookupCategoryDropdown.requestInProgress", false);
                _.set(state, "lookupCategoryDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_FAILURE, (state) => {
                state.lookupCategoryDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.SHO_NGO_CONTACTS_BY_ID_REQUEST, (state) => {
                state.shoNgoGetById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SHO_NGO_CONTACTS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "shoNgoGetById.requestInProgress", false);
                _.set(state, "shoNgoGetById.data", getPayloadData(action));
            })
            .addCase(ACTION_TYPES.SHO_NGO_CONTACTS_BY_ID_FAILURE, (state) => {
                state.shoNgoGetById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.TRAINING_ORGANIZATION_BY_ID_REQUEST, (state) => {
                state.trainingOrganizationContactsById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.TRAINING_ORGANIZATION_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "trainingOrganizationContactsById.requestInProgress", false);
                _.set(state, "trainingOrganizationContactsById.data", getPayloadData(action));
            })
            .addCase(ACTION_TYPES.TRAINING_ORGANIZATION_BY_ID_FAILURE, (state) => {
                state.trainingOrganizationContactsById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FILTER_SHO_NGO_NAME_SUCCESS, (state, { payload }) => {
                _.set(state, "filterShoNgoName.requestInProgress", false);
                _.set(state, "filterShoNgoName.data", payload.content);
            }).addCase(ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "nameList.requestInProgress", false);
                _.set(state, "nameList.data", payload.content);
            }).addCase(ACTION_TYPES.FETCH_ADDRESS_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "addressList.requestInProgress", false);
                _.set(state, "addressList.data", payload.content);
            });
    }
});

export const { actions, reducer } = slice;
