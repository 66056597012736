import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_ULB_LIST: `${STATE_REDUCER_KEY}/FETCH_ULB_LIST`,
    FETCH_ULB_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ULB_LIST_REQUEST`,
    FETCH_ULB_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ULB_LIST_SUCCESS`,
    FETCH_ULB_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ULB_LIST_FAILURE`,

    FETCH_FY_LIST: `${STATE_REDUCER_KEY}/FETCH_FY_LIST`,
    FETCH_FY_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FY_LIST_REQUEST`,
    FETCH_FY_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FY_LIST_SUCCESS`,
    FETCH_FY_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FY_LIST_FAILURE`,

    FETCH_EXPENSE: `${STATE_REDUCER_KEY}/FETCH_EXPENSE`,
    FETCH_EXPENSE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EXPENSE_REQUEST`,
    FETCH_EXPENSE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EXPENSE_SUCCESS`,
    FETCH_EXPENSE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EXPENSE_FAILURE`,

    SAVE_EXPENSE: `${STATE_REDUCER_KEY}/SAVE_EXPENSE`,
    SAVE_EXPENSE_REQUEST: `${STATE_REDUCER_KEY}/SAVE_EXPENSE_REQUEST`,
    SAVE_EXPENSE_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_EXPENSE_SUCCESS`,
    SAVE_EXPENSE_FAILURE: `${STATE_REDUCER_KEY}/SAVE_EXPENSE_FAILURE`,

    SUBMIT_EXPENSE: `${STATE_REDUCER_KEY}/SUBMIT_EXPENSE`,
    SUBMIT_EXPENSE_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_EXPENSE_REQUEST`,
    SUBMIT_EXPENSE_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_EXPENSE_SUCCESS`,
    SUBMIT_EXPENSE_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_EXPENSE_FAILURE`,

    FETCH_FY_DETAILS: `${STATE_REDUCER_KEY}/FETCH_FY_DETAILS`,
    FETCH_FY_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FY_DETAILS_REQUEST`,
    FETCH_FY_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FY_DETAILS_SUCCESS`,
    FETCH_FY_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FY_DETAILS_FAILURE`,

    FETCH_EXIST_VALUES: `${STATE_REDUCER_KEY}/FETCH_EXIST_VALUES`,
    FETCH_EXIST_VALUES_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EXIST_VALUES_REQUEST`,
    FETCH_EXIST_VALUES_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EXIST_VALUES_SUCCESS`,
    FETCH_EXIST_VALUES_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EXIST_VALUES_FAILURE`,

    DELETE_FY: `${STATE_REDUCER_KEY}/DELETE_FY`,
    DELETE_FY_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FY_REQUEST`,
    DELETE_FY_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FY_SUCCESS`,
    DELETE_FY_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FY_FAILURE`,

    SUBMIT_FY: `${STATE_REDUCER_KEY}/SUBMIT_FY`,
    SUBMIT_FY_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_FY_REQUEST`,
    SUBMIT_FY_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_FY_SUCCESS`,
    SUBMIT_FY_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_FY_FAILURE`,

    FETCH_FY_BY_ID: `${STATE_REDUCER_KEY}/FETCH_FY_BY_ID`,
    FETCH_FY_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FY_BY_ID_REQUEST`,
    FETCH_FY_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FY_BY_ID_SUCCESS`,
    FETCH_FY_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FY_BY_ID_FAILURE`,

    EDIT_FY: `${STATE_REDUCER_KEY}/EDIT_FY`,
    EDIT_FY_REQUEST: `${STATE_REDUCER_KEY}/EDIT_FY_REQUEST`,
    EDIT_FY_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_FY_SUCCESS`,
    EDIT_FY_FAILURE: `${STATE_REDUCER_KEY}/EDIT_FY_FAILURE`,

    FETCH_BILLS_DETAILS: `${STATE_REDUCER_KEY}/FETCH_BILLS_DETAILS`,
    FETCH_BILLS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BILLS_DETAILS_REQUEST`,
    FETCH_BILLS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BILLS_DETAILS_SUCCESS`,
    FETCH_BILLS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BILLS_DETAILS_FAILURE`,

    DELETE_BILLS: `${STATE_REDUCER_KEY}/DELETE_BILLS`,
    DELETE_BILLS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_BILLS_REQUEST`,
    DELETE_BILLS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_BILLS_SUCCESS`,
    DELETE_BILLS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_BILLS_FAILURE`,

    SUBMIT_BILLS: `${STATE_REDUCER_KEY}/SUBMIT_BILLS`,
    SUBMIT_BILLS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_BILLS_REQUEST`,
    SUBMIT_BILLS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_BILLS_SUCCESS`,
    SUBMIT_BILLS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_BILLS_FAILURE`,

    FETCH_BILLS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_BILLS_BY_ID`,
    FETCH_BILLS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BILLS_BY_ID_REQUEST`,
    FETCH_BILLS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BILLS_BY_ID_SUCCESS`,
    FETCH_BILLS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BILLS_BY_ID_FAILURE`,

    EDIT_BILLS: `${STATE_REDUCER_KEY}/EDIT_BILLS`,
    EDIT_BILLS_REQUEST: `${STATE_REDUCER_KEY}/EDIT_BILLS_REQUEST`,
    EDIT_BILLS_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_BILLS_SUCCESS`,
    EDIT_BILLS_FAILURE: `${STATE_REDUCER_KEY}/EDIT_BILLS_FAILURE`,

    FETCH_DISTRICT_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN`,
    FETCH_DISTRICT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_REQUEST`,
    FETCH_DISTRICT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_SUCCESS`,
    FETCH_DISTRICT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_FAILURE`,

    FETCH_LSGI_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN`,
    FETCH_LSGI_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_REQUEST`,
    FETCH_LSGI_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_SUCCESS`,
    FETCH_LSGI_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_FAILURE`,

    SELECT_LSGI_TYPE_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN`,
    SELECT_LSGI_TYPE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_REQUEST`,
    SELECT_LSGI_TYPE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_SUCCESS`,
    SELECT_LSGI_TYPE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_FAILURE`,

    BILL_STATUS_DROPDOWN: `${STATE_REDUCER_KEY}/BILL_STATUS_DROPDOWN`,
    BILL_STATUS_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/BILL_STATUS_DROPDOWN_REQUEST`,
    BILL_STATUS_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/BILL_STATUS_DROPDOWN_SUCCESS`,
    BILL_STATUS_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/BILL_STATUS_DROPDOWN_FAILURE`,

    PROJECTS_DROPDOWN: `${STATE_REDUCER_KEY}/PROJECTS_DROPDOWN`,
    PROJECTS_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/PROJECTS_DROPDOWN_REQUEST`,
    PROJECTS_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/PROJECTS_DROPDOWN_SUCCESS`,
    PROJECTS_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/PROJECTS_DROPDOWN_FAILURE`,

    FETCH_BILL_FROM_SANKHYA: `${STATE_REDUCER_KEY}/FETCH_BILL_FROM_SANKHYA`,
    FETCH_BILL_FROM_SANKHYA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BILL_FROM_SANKHYA_REQUEST`,
    FETCH_BILL_FROM_SANKHYA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BILL_FROM_SANKHYA_SUCCESS`,
    FETCH_BILL_FROM_SANKHYA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BILL_FROM_SANKHYA_FAILURE`,

    FETCH_BILL_FROM_SANKHYA_LIST_ALL: `${STATE_REDUCER_KEY}/FETCH_BILL_FROM_SANKHYA_LIST_ALL`,
    FETCH_BILL_FROM_SANKHYA_LIST_ALL_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BILL_FROM_SANKHYA_LIST_ALL_REQUEST`,
    FETCH_BILL_FROM_SANKHYA_LIST_ALL_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BILL_FROM_SANKHYA_LIST_ALL_SUCCESS`,
    FETCH_BILL_FROM_SANKHYA_LIST_ALL_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BILL_FROM_SANKHYA_LIST_ALL_FAILURE`,

    FETCH_EXPENSES_FROM_SANKHYA: `${STATE_REDUCER_KEY}/FETCH_EXPENSES_FROM_SANKHYA`,
    FETCH_EXPENSES_FROM_SANKHYA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EXPENSES_FROM_SANKHYA_REQUEST`,
    FETCH_EXPENSES_FROM_SANKHYA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EXPENSES_FROM_SANKHYA_SUCCESS`,
    FETCH_EXPENSES_FROM_SANKHYA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EXPENSES_FROM_SANKHYA_FAILURE`
};

export const getUlb = createAction(ACTION_TYPES.FETCH_ULB_LIST);
export const getFY = createAction(ACTION_TYPES.FETCH_FY_LIST);
export const getFetchExpense = createAction(ACTION_TYPES.FETCH_EXPENSE);
export const saveExpense = createAction(ACTION_TYPES.SAVE_EXPENSE);
export const submitExpense = createAction(ACTION_TYPES.SUBMIT_EXPENSE);
export const fetchFinancialYear = createAction(ACTION_TYPES.FETCH_FY_DETAILS);
export const deleteFY = createAction(ACTION_TYPES.DELETE_FY);
export const submitFY = createAction(ACTION_TYPES.SUBMIT_FY);
export const fetchFYById = createAction(ACTION_TYPES.FETCH_FY_BY_ID);
export const editFY = createAction(ACTION_TYPES.EDIT_FY);
export const fetchBills = createAction(ACTION_TYPES.FETCH_BILLS_DETAILS);
export const deleteBills = createAction(ACTION_TYPES.DELETE_BILLS);
export const submitBills = createAction(ACTION_TYPES.SUBMIT_BILLS);
export const fetchBillsById = createAction(ACTION_TYPES.FETCH_BILLS_BY_ID);
export const editBills = createAction(ACTION_TYPES.EDIT_BILLS);
export const fetchExistValues = createAction(ACTION_TYPES.FETCH_EXIST_VALUES);
export const fetchDistrictDropDown = createAction(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN);
export const fetchLSGIDropDown = createAction(ACTION_TYPES.FETCH_LSGI_DROPDOWN);
export const lsgiTypeSelectDropdown = createAction(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN);
export const billStatusDropdown = createAction(ACTION_TYPES.BILL_STATUS_DROPDOWN);
export const projectsDropdown = createAction(ACTION_TYPES.PROJECTS_DROPDOWN);
export const fetchBillFromSankhya = createAction(ACTION_TYPES.FETCH_BILL_FROM_SANKHYA);
export const fetchListAllBillFromSankhya = createAction(ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_LIST_ALL);
export const fetchExpensesFromSankhya = createAction(ACTION_TYPES.FETCH_EXPENSES_FROM_SANKHYA);
