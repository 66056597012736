import { TABLE_IDS } from "common/constants";
import { commonFileDownload } from "modules/common/actions";
import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { setCommonTableData } from "utils/tableUtils";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";
import * as api from "./api";


export function* listTrainingParticipants({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.TRAINING_PARTICIPANTS]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listTrainingParticipantsApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.TRAINING_PARTICIPANTS_LIST_SUCCESS, ACTION_TYPES.TRAINING_PARTICIPANTS_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_PARTICIPANTS);
}

export function* listTrainingParticipantsAnalysis({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.TRAINING_PARTICIPANTS_ANALYSIS]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listTrainingParticipantsAnalysisApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.TRAINING_PARTICIPANTS_ANALYSIS_LIST_SUCCESS, ACTION_TYPES.TRAINING_PARTICIPANTS_ANALYSIS_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_PARTICIPANTS_ANALYSIS);
}

export function* listTrainingParticipantsDownload(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, startDate, endDate, wardId, trainingName, batchName, gender, designation } = filters;
    yield put(commonFileDownload({
        url: API_URL.TRAINING_PARTICIPANTS.DOWNLOAD,
        data: { type, startDate, endDate, lsgiId, districtId, wardId, trainingName, batchName, designation, gender },
        file: { name: fileName, ext: type }
    }));
}

export function* listTrainingParticipantsAnalysisDownload(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { startDate, endDate } = filters;
    yield put(commonFileDownload({
        url: API_URL.TRAINING_PARTICIPANTS_ANALYSIS.DOWNLOAD,
        data: { type, startDate, endDate },
        file: { name: fileName, ext: type }
    }));
}

export function* listTrainingSchedule({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listTrainingScheduleReportApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.TRAINING_SCHEDULE_LIST_SUCCESS, ACTION_TYPES.TRAINING_SCHEDULE_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST);
}

export function* listTrainingScheduleDownload(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.TRAINING_SCHEDULE_REPORT.DOWNLOAD,
        data: { ...filters, ...action.payload },
        file: { name: fileName, ext: type }
    }));
}

export function* listTrainingFilter({ payload }) {
    yield call(handleAPIRequest, api.listTrainingFilterApi, payload);

}

export function* listBatchNameTrainingFilter({ payload }) {
    yield call(handleAPIRequest, api.listBatchNameTrainingFilterApi, payload);

}

export function* listParticipantsTrainingFilter({ payload }) {
    yield call(handleAPIRequest, api.listParticipantsTrainingFilterApi, payload);

}

export function* listParticipantsBatchNameTrainingFilter({ payload }) {
    yield call(handleAPIRequest, api.listParticipantsBatchNameTrainingFilterApi, payload);

}

export function* listTrainingConducted({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.TRAINING_CONDUCTED_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listTrainingConductedReportApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.TRAINING_CONDUCTED_LIST_SUCCESS, ACTION_TYPES.TRAINING_CONDUCTED_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_CONDUCTED_REPORT);
}

export function* listConductedBatchNameTrainingFilter({ payload }) {
    yield call(handleAPIRequest, api.listConductedBatchNameTrainingFilterApi, payload);

}

export function* listConductedTrainingModeFilter({ payload }) {
    yield call(handleAPIRequest, api.listConductedTrainingModeFilterApi, payload);

}

export function* listTrainingConductedDownload(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { lsgiId, trainingMode, batchName } = filters;
    yield put(commonFileDownload({
        url: API_URL.TRAINING_CONDUCTED_REPORT.DOWNLOAD,
        data: { type, lsgiId, trainingMode, batchName },
        file: { name: fileName, ext: type }
    }));
}

export function* listTrainingSummary({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.TRAINING_SUMMARY]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listTrainingSummaryReportApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.TRAINING_SUMMARY_LIST_SUCCESS, ACTION_TYPES.TRAINING_SUMMARY_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_SUMMARY);
}

export function* listTrainingNameFilterForSummary({ payload }) {
    yield call(handleAPIRequest, api.listTrainingNameFilterApiForSummary, payload);

}

export function* listTrainingSummaryReportDownload(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { trainingPlanName, startDate, endDate } = filters;
    yield put(commonFileDownload({
        url: API_URL.TRAINING_SUMMARY_REPORT.DOWNLOAD,
        data: { type, trainingPlanName, startDate, endDate },
        file: { name: fileName, ext: type }
    }));
}

export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.TRAINING_PARTICIPANTS_LIST, listTrainingParticipants),
        takeLatest(ACTION_TYPES.TRAINING_PARTICIPANTS_LIST_DOWNLOAD, listTrainingParticipantsDownload),
        takeLatest(ACTION_TYPES.TRAINING_PARTICIPANTS_ANALYSIS_LIST, listTrainingParticipantsAnalysis),
        takeLatest(ACTION_TYPES.TRAINING_PARTICIPANTS_ANALYSIS_LIST_DOWNLOAD, listTrainingParticipantsAnalysisDownload),
        takeLatest(ACTION_TYPES.TRAINING_SCHEDULE_LIST, listTrainingSchedule),
        takeLatest(ACTION_TYPES.TRAINING_SCHEDULE_LIST_DOWNLOAD, listTrainingScheduleDownload),
        takeLatest(ACTION_TYPES.TRAINING_FILTER_TRAINING_NAME_LIST, listTrainingFilter),
        takeLatest(ACTION_TYPES.TRAINING_FILTER_BATCH_NAME_LIST, listBatchNameTrainingFilter),
        takeLatest(ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST, listParticipantsTrainingFilter),
        takeLatest(ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST, listParticipantsBatchNameTrainingFilter),
        takeLatest(ACTION_TYPES.TRAINING_CONDUCTED_LIST, listTrainingConducted),
        takeLatest(ACTION_TYPES.TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST, listConductedBatchNameTrainingFilter),
        takeLatest(ACTION_TYPES.TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST, listConductedTrainingModeFilter),
        takeLatest(ACTION_TYPES.TRAINING_CONDUCTED_LIST_DOWNLOAD, listTrainingConductedDownload),
        takeLatest(ACTION_TYPES.TRAINING_SUMMARY_LIST, listTrainingSummary),
        takeLatest(ACTION_TYPES.TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST, listTrainingNameFilterForSummary),
        takeLatest(ACTION_TYPES.TRAINING_SUMMARY_REPORT_DOWNLOAD, listTrainingSummaryReportDownload)

    ]);
}
