import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES, dprTrackerById, listComments, listFinalSubprojectList, listSubprojectList } from "./actions";
import { TABLE_IDS } from "common/constants";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import * as api from "./api";
import { getTableProps } from "modules/common/selectors";
import { successNotify } from "utils/notificationUtils";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { I18n } from "common/components";
import { actions as commonActions } from "../../common/slice";
import _ from "lodash";
import { toEpoch } from "utils/dateUtils";
import { getUserDetails } from "utils/userUtils";
import { API_URL } from "./apiUrl";
import { actions as sliceActions } from "./slice";

export function* listDprTracker(action) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.DPR_OR_FR_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.fetchDprTrackerList, { ...filters, ...action.payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_DPR_TRACKER_SUCCESS, ACTION_TYPES.LIST_DPR_TRACKER_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.DPR_OR_FR_LIST);
}

export function* saveDprTracker(action) {
    const { payload } = action;
    yield fork(handleAPIRequest, api.postDprTracker, payload);
    const responseAction = yield take([ACTION_TYPES.SAVE_DPR_TRACKER_SUCCESS, ACTION_TYPES.SAVE_DPR_TRACKER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_DPR_TRACKER_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("dpr_tracker") }) }));
        yield put(navigateTo(-1));
    }
}

export function* patchDprTracker(action) {
    const { payload } = action;
    yield fork(handleAPIRequest, api.patchDprTracker, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_DPR_TRACKER_SUCCESS, ACTION_TYPES.PATCH_DPR_TRACKER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_DPR_TRACKER_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("dpr_tracker") }) }));
        yield put(navigateTo(-1));
    }
}

export function* fetchDprTrackerById({ payload: id }) {
    yield call(handleAPIRequest, api.dprTrackerById, id);
}

export function* deleteDprTracker(action) {
    const { payload } = action;
    yield fork(handleAPIRequest, api.deleteDprTracker, payload?.id);
    const responseAction = yield take([ACTION_TYPES.DELETE_DPR_TRACKER_SUCCESS, ACTION_TYPES.DELETE_DPR_TRACKER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_DPR_TRACKER_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("deleted", { type: I18n("dpr_tracker") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listDprTracker, { payload: tablePayload });
    }
}


export function* documentTypeList() {
    yield call(handleAPIRequest, api.fetchDocumentType);
}

export function* workTypeList() {
    yield call(handleAPIRequest, api.fetchWorkType);
}

export function* esmfCategoryList() {
    yield call(handleAPIRequest, api.fetchEsmfCategory);
}

export function* dprCategoryList() {
    yield call(handleAPIRequest, api.fetchDprCategory);
}

export function* fetchSubProjectListDprTracker({ payload }) {
    yield fork(handleAPIRequest, api.fetchSubProjectListAll, { ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_SUBPROJECT_FULL_DATA_SUCCESS, ACTION_TYPES.LIST_SUBPROJECT_FULL_DATA_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* saveSubProjectMapping({ payload }) {
    yield fork(handleAPIRequest, api.postSubProjectMapping, payload);
    const responseAction = yield take([ACTION_TYPES.POST_SUBPROJECT_MAPPING_SUCCESS, ACTION_TYPES.POST_SUBPROJECT_MAPPING_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_SUBPROJECT_MAPPING_SUCCESS) {
        yield put(listFinalSubprojectList({ isProjectsExclude: true, lsgiId: payload?.lsgiId, dprTrackerId: payload?.dprTrackerId }));
        yield put(listSubprojectList({ isProjectsExclude: true, lsgiId: payload?.lsgiId }));
        yield put(successNotify({ title: I18n("success"), message: I18n("mapped", { type: I18n("sub_projects") }) }));
    }
}

export function* fetchFinalSubProjectListDprTracker({ payload }) {
    yield fork(handleAPIRequest, api.fetchFinalSubProjectListAll, { ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_FINAL_SUBPROJECT_MAPPING_SUCCESS, ACTION_TYPES.LIST_FINAL_SUBPROJECT_MAPPING_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* fetchFinalSubProjectById({ payload: id }) {
    yield call(handleAPIRequest, api.finalSubProjectById, id);
}

export function* patchFinalSubProject({ payload }) {
    let omittedPayload = _.omit(payload, "updateNeeded");
    let updatedPayload = { updateNeeded: true, ...omittedPayload };
    yield fork(handleAPIRequest, api.patchFinalSubProject, updatedPayload);
    const responseAction = yield take([ACTION_TYPES.PATCH_FINAL_SUBPROJECT_MAPPING_SUCCESS, ACTION_TYPES.PATCH_FINAL_SUBPROJECT_MAPPING_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_FINAL_SUBPROJECT_MAPPING_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("sub_projects") }) }));
    }
}

export function* saveCostDetails({ payload }) {
    let id = payload?.dprTrackerId;
    yield fork(handleAPIRequest, api.saveCostDetails, payload);
    const responseAction = yield take([ACTION_TYPES.SAVE_COST_DETAILS_SUCCESS, ACTION_TYPES.SAVE_COST_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_COST_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("save", { type: I18n("cost_details") }) }));
        yield put(dprTrackerById({ id: id }));
    }
}

export function* patchCostDetails({ payload }) {
    let id = payload?.dprTrackerId;
    yield fork(handleAPIRequest, api.patchCostDetails, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_COST_DETAILS_SUCCESS, ACTION_TYPES.PATCH_COST_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_COST_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("cost_details") }) }));
        yield put(dprTrackerById({ id: id }));

    }
}

export function* fetchCostDetails({ payload: id }) {
    yield call(handleAPIRequest, api.costDetailsById, id);
}

export function* fetchCostDetailsListAll({ payload }) {
    yield fork(handleAPIRequest, api.fetchCostDetailsListAll, { ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_ALL_COST_DETAILS_SUCCESS, ACTION_TYPES.LIST_ALL_COST_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* listCommentsPage({ payload }) {
    let updatedPayload = {
        pageNo: payload.pageNo, pageSize: payload.pageSize
    };
    yield fork(handleAPIRequest, api.fetchCommentList, updatedPayload, payload.id);
    const COMPARISON_TYPES = [ACTION_TYPES.VIEW_COMMENT_SUCCESS, ACTION_TYPES.VIEW_COMMENT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.VIEW_COMMENT);
}

export function* saveComment({ payload }) {
    const { data: { activeRole = {} } = {} } = getUserDetails();
    let id = payload?.dprTrackerId;
    let { commentedDate, authority, ...rest } = payload;
    authority = activeRole?.name;
    commentedDate = toEpoch(commentedDate);
    yield put(sliceActions.setAddCommentById({ commentedDate, authority, ...rest }));
    yield fork(handleAPIRequest, api.saveComment, { commentedDate, authority, ...rest });
    const responseAction = yield take([ACTION_TYPES.ADD_COMMENT_SUCCESS, ACTION_TYPES.ADD_COMMENT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.ADD_COMMENT_SUCCESS) {
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let updatedPayload = { pageNo: 0, pageSize: 5 };
        yield fork(handleAPIRequest, api.fetchCommentList, updatedPayload, payload.dprTrackerId);
        const COMPARISON_TYPES = [ACTION_TYPES.VIEW_COMMENT_SUCCESS, ACTION_TYPES.VIEW_COMMENT_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.VIEW_COMMENT);
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("comment") }) }));
        yield put(listComments(id));
        yield put(sliceActions.resetAddCommentById());

    }
}

export function* deleteComment(action) {
    const { payload } = action;
    yield fork(handleAPIRequest, api.deleteComment, payload?.id);
    const responseAction = yield take([ACTION_TYPES.DELETE_COMMENT_SUCCESS, ACTION_TYPES.DELETE_COMMENT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_COMMENT_SUCCESS) {
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let updatedPayload = { pageNo: 0, pageSize: 5 };
        yield fork(handleAPIRequest, api.fetchCommentList, updatedPayload, payload?.dprTrackerId);
        const COMPARISON_TYPES = [ACTION_TYPES.VIEW_COMMENT_SUCCESS, ACTION_TYPES.VIEW_COMMENT_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.VIEW_COMMENT);
        yield put(successNotify({ title: I18n("success"), message: I18n("deleted", { type: I18n("comment") }) }));
    }
}

export function* updateComment({ payload }) {
    yield fork(handleAPIRequest, api.updateComment, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_COMMENT_SUCCESS, ACTION_TYPES.UPDATE_COMMENT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_COMMENT_SUCCESS) {
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let updatedPayload = { pageNo: 0, pageSize: 5 };
        yield fork(handleAPIRequest, api.fetchCommentList, updatedPayload, payload.dprTrackerId);
        const COMPARISON_TYPES = [ACTION_TYPES.VIEW_COMMENT_SUCCESS, ACTION_TYPES.VIEW_COMMENT_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.VIEW_COMMENT);
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("comment") }) }));
    }
}

export function* submitToDistrictLevel({ payload }) {
    let id = payload;
    yield fork(handleAPIRequest, api.patchSubmitToDistrictLevel, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_TO_DISTRICT_LEVEL_SUCCESS, ACTION_TYPES.SUBMIT_TO_DISTRICT_LEVEL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_TO_DISTRICT_LEVEL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("creat", { type: I18n("district_level") }) }));
        yield put(dprTrackerById({ id: id }));

    }
}

export function* submitDpmcDecision({ payload }) {
    yield fork(handleAPIRequest, api.submitDpmcDecision, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_DPMC_DESICION_SUCCESS, ACTION_TYPES.SUBMIT_DPMC_DESICION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_DPMC_DESICION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("creat", { type: I18n("decisions") }) }));
    }
}

export function* listApprovalStatus() {
    yield call(handleAPIRequest, api.fetchApprovalStatus);
}

export function* fetchDpmcDecisionListAll({ payload: id }) {
    yield call(handleAPIRequest, api.fetchDpmcDecisionListAll, id);
}

export function* fetchCommentList({ payload: id }) {
    yield call(handleAPIRequest, api.fetchCommentLists, { dprTrackerId: id });
}

export function* saveResponseDetails({ payload }) {
    yield fork(handleAPIRequest, api.saveResponseDetails, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_RESPONSE_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_RESPONSE_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_RESPONSE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("response_details") }) }));
    }
}

export function* fetchCommentById({ payload: id }) {
    yield call(handleAPIRequest, api.fetchCommentById, id);
}

export function* submitToStateLevel({ payload }) {
    let id = payload;
    yield fork(handleAPIRequest, api.patchSubmitToStateLevel, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_TO_STATE_LEVEL_SUCCESS, ACTION_TYPES.SUBMIT_TO_STATE_LEVEL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_TO_STATE_LEVEL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("creat", { type: I18n("state_level") }) }));
        yield put(dprTrackerById({ id: id }));
    }
}

export function* submitToPui({ payload }) {
    let id = payload;
    yield fork(handleAPIRequest, api.patchSubmitToPui, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_TO_PUI_SUCCESS, ACTION_TYPES.SUBMIT_TO_PUI_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_TO_PUI_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("creat", { type: I18n("piu") }) }));
        yield put(dprTrackerById({ id: id }));

    }
}

export function* submitToDyDc({ payload }) {
    let id = payload;
    yield fork(handleAPIRequest, api.patchSubmitToDyDc, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_TO_DYDC_SUCCESS, ACTION_TYPES.SUBMIT_TO_DYDC_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_TO_DYDC_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("creat", { type: I18n("dydc") }) }));
        yield put(dprTrackerById({ id: id }));

    }
}

export function* returnToTsc({ payload }) {
    let id = payload;
    yield fork(handleAPIRequest, api.patchReturnToTsc, payload);
    const responseAction = yield take([ACTION_TYPES.RETURN_TO_TSC_SUCCESS, ACTION_TYPES.RETURN_TO_TSC_FAILURE]);
    if (responseAction.type === ACTION_TYPES.RETURN_TO_TSC_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("creat", { type: I18n("to_tsc") }) }));
        yield put(dprTrackerById({ id: id }));
    }
}

export function* downloadGenerateFsSaga({ payload }) {
    yield put(commonFileDownload({
        url: API_URL.DPR_TRACKER.DOWNLOAD_GENERATE_FS,
        data: { type: "pdf", dprTrackerId: payload?.id },
        file: { name: "download", ext: "pdf" }
    }));
}

export function* saveDprSubmitToMc({ payload }) {
    let id = payload.id;
    let { mcApprovalDate, ...rest } = payload;
    mcApprovalDate = toEpoch(mcApprovalDate);
    yield fork(handleAPIRequest, api.saveDprMcApproval, { mcApprovalDate, ...rest });
    const responseAction = yield take([ACTION_TYPES.SUBMIT_MC_APPROVAL_SUCCESS, ACTION_TYPES.SUBMIT_MC_APPROVAL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_MC_APPROVAL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("mc_approval") }) }));
        yield put(dprTrackerById({ id: id }));
    }
}

export function* listDprTrackerStatus() {
    yield call(handleAPIRequest, api.fetchDprTrackerStatus);
}

export function* listCommentCategoryList() {
    yield call(handleAPIRequest, api.fetchCommentCategoryList);
}

export function* listAllGenerateFs({ payload: id }) {
    yield call(handleAPIRequest, api.listAllGenerateFS, id);
}

export function* generateFsSaga({ payload }) {
    const updatedPayload = {
        ...payload,
        references: payload.references?.map(reference => ({
            ...reference,
            date: toEpoch(reference?.date)
        }))
    };
    yield fork(handleAPIRequest, api.generateFs, updatedPayload);
    const responseAction = yield take([ACTION_TYPES.GENERATE_FS_SUCCESS, ACTION_TYPES.GENERATE_FS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.GENERATE_FS_SUCCESS) {
        yield call(downloadGenerateFsSaga, { payload: { id: payload.dprTrackerId } });
        yield put(dprTrackerById({ id: payload.dprTrackerId }));
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("generate_fs") }) }));
    }
}

export function* downloadDprFrList(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, documentType, typeId, esmfCategory, dprCategory, dprStatus } = filters;
    yield put(commonFileDownload({
        url: API_URL.DPR_TRACKER.DOWNLOAD,
        data: { type, districtId, lsgiId, documentType, typeId, esmfCategory, dprCategory, dprStatus },
        file: { name: fileName, ext: type }
    }));
}

export default function* testSaga() {
    yield all([
        takeLatest(ACTION_TYPES.LIST_DPR_TRACKER, listDprTracker),
        takeLatest(ACTION_TYPES.SAVE_DPR_TRACKER, saveDprTracker),
        takeLatest(ACTION_TYPES.PATCH_DPR_TRACKER, patchDprTracker),
        takeLatest(ACTION_TYPES.DPR_TRACKER_BY_ID, fetchDprTrackerById),
        takeLatest(ACTION_TYPES.DELETE_DPR_TRACKER, deleteDprTracker),
        takeLatest(ACTION_TYPES.LIST_DOCUMENT_TYPE, documentTypeList),
        takeLatest(ACTION_TYPES.LIST_WORK_TYPE, workTypeList),
        takeLatest(ACTION_TYPES.LIST_ESMF_CATEGORY, esmfCategoryList),
        takeLatest(ACTION_TYPES.LIST_DPR_CATEGORY, dprCategoryList),
        takeLatest(ACTION_TYPES.LIST_SUBPROJECT_FULL_DATA, fetchSubProjectListDprTracker),
        takeLatest(ACTION_TYPES.POST_SUBPROJECT_MAPPING, saveSubProjectMapping),
        takeLatest(ACTION_TYPES.LIST_FINAL_SUBPROJECT_MAPPING, fetchFinalSubProjectListDprTracker),
        takeLatest(ACTION_TYPES.BY_ID_FINAL_SUBPROJECT_MAPPING, fetchFinalSubProjectById),
        takeLatest(ACTION_TYPES.PATCH_FINAL_SUBPROJECT_MAPPING, patchFinalSubProject),
        takeLatest(ACTION_TYPES.SAVE_COST_DETAILS, saveCostDetails),
        takeLatest(ACTION_TYPES.PATCH_COST_DETAILS, patchCostDetails),
        takeLatest(ACTION_TYPES.BY_ID_COST_DETAILS, fetchCostDetails),
        takeLatest(ACTION_TYPES.LIST_ALL_COST_DETAILS, fetchCostDetailsListAll),
        takeLatest(ACTION_TYPES.ADD_COMMENT, saveComment),
        takeLatest(ACTION_TYPES.VIEW_COMMENT, listCommentsPage),
        takeLatest(ACTION_TYPES.DELETE_COMMENT, deleteComment),
        takeLatest(ACTION_TYPES.BY_ID_COMMENT, fetchCommentById),
        takeLatest(ACTION_TYPES.UPDATE_COMMENT, updateComment),
        takeLatest(ACTION_TYPES.SUBMIT_TO_DISTRICT_LEVEL, submitToDistrictLevel),
        takeLatest(ACTION_TYPES.LIST_APPROVAL_STATUS, listApprovalStatus),
        takeLatest(ACTION_TYPES.SUBMIT_DPMC_DESICION, submitDpmcDecision),
        takeLatest(ACTION_TYPES.LIST_ALL_DPMC_DESICION, fetchDpmcDecisionListAll),
        takeLatest(ACTION_TYPES.LIST_ALL_COMMENT_TABLE, fetchCommentList),
        takeLatest(ACTION_TYPES.SUBMIT_RESPONSE_DETAILS, saveResponseDetails),
        takeLatest(ACTION_TYPES.SUBMIT_TO_STATE_LEVEL, submitToStateLevel),
        takeLatest(ACTION_TYPES.SUBMIT_TO_PUI, submitToPui),
        takeLatest(ACTION_TYPES.SUBMIT_TO_DYDC, submitToDyDc),
        takeLatest(ACTION_TYPES.RETURN_TO_TSC, returnToTsc),
        takeLatest(ACTION_TYPES.SUBMIT_MC_APPROVAL, saveDprSubmitToMc),
        takeLatest(ACTION_TYPES.LIST_DPR_TRACKER_STATUS, listDprTrackerStatus),
        takeLatest(ACTION_TYPES.ADD_COMMENT_CATEGORY_LIST, listCommentCategoryList),
        takeLatest(ACTION_TYPES.GENERATE_FS, generateFsSaga),
        takeLatest(ACTION_TYPES.DOWNLOAD_GENERATE_FS, downloadGenerateFsSaga),
        takeLatest(ACTION_TYPES.LIST_ALL_GENERATE_FS, listAllGenerateFs),
        takeLatest(ACTION_TYPES.DOWNLOAD_DPR_FR_LIST, downloadDprFrList)
    ]);
}

