import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { fetchVenueDetailsAPI, fetchDistrictDropDownDetailsAPI, editVenueFormAPI, editVenueFormByIdAPI, deleteVenueFormByIdAPI } from "./api";
import { all, call, fork, put, take, select, takeLatest } from "redux-saga/effects";
import { actions as commonActions } from "../../common/slice";
import { I18n } from "common/components";
import { successNotify } from "utils/notificationUtils";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "../../paths";
import * as api from "./api";
import { TABLE_IDS } from "common/constants";
import { TRAINING_PATH as PATH } from "../paths";
import { getTableProps } from "modules/common/selectors";

export function* fetchVenueDetails({ payload }) {
    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, editVenueFormAPI, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_VENUE_FORM_SUCCESS, ACTION_TYPES.EDIT_VENUE_FORM_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_VENUE_FORM_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("training_venue") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.VENUE.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, fetchVenueDetailsAPI, payload);
        const responseAction = yield take([ACTION_TYPES.CREATE_VENUE_FORM_SUCCESS, ACTION_TYPES.CREATE_VENUE_FORM_FAILURE]);
        if (responseAction.type === ACTION_TYPES.CREATE_VENUE_FORM_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("saved") }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.VENUE.LIST}`));
        }
    }
}

export function* fetchDistrictDropDownDetails({ payload: id }) {
    yield call(handleAPIRequest, fetchDistrictDropDownDetailsAPI, { id });
}

export function* listTrainingVenue({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.TRAINING_VENUE]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listTrainingVenueAPI, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_VENUE_FORM_SUCCESS, ACTION_TYPES.LIST_VENUE_FORM_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_VENUE);
}

export function* deleteVenueFormById({ payload: id }) {
    yield fork(handleAPIRequest, deleteVenueFormByIdAPI, { id });
    const responseAction = yield take([ACTION_TYPES.DELETE_VENUE_FORM_BY_ID_SUCCESS, ACTION_TYPES.DELETE_VENUE_FORM_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_VENUE_FORM_BY_ID_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("training_venue") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listTrainingVenue, { payload: tablePayload });
    }
}

export function* editVenueFormById({ payload: id }) {
    yield call(handleAPIRequest, editVenueFormByIdAPI, { id });
}

export function* fetchVenueId({ payload: id }) {
    yield call(handleAPIRequest, api.fetchVenueIdApi, { id });
}

export default function* venueSaga() {
    yield all([
        takeLatest(ACTION_TYPES.CREATE_VENUE_FORM, fetchVenueDetails),
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN, fetchDistrictDropDownDetails),
        takeLatest(ACTION_TYPES.LIST_VENUE_FORM, listTrainingVenue),
        takeLatest(ACTION_TYPES.DELETE_VENUE_FORM_BY_ID, deleteVenueFormById),
        takeLatest(ACTION_TYPES.EDIT_VENUE_FORM_BY_ID, editVenueFormById),
        takeLatest(ACTION_TYPES.FETCH_VENUE_ID, fetchVenueId)
    ]);
}
