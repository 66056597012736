import { Outlet } from "react-router-dom";

const VisitorsHome = () => {
    return (
        <div style={{ backgroundColor: "#f5efe0", padding: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Outlet />
        </div>
    );
};

export default VisitorsHome;
