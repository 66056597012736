import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const submitOtherDepartmentApi = (data) => {
    return {
        url: API_URL.OTHER_DEPARTMENTS.SUBMIT_OTHER_DEPARTMENTS_DETAILS,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [
                ACTION_TYPES.SUBMIT_OTHER_DEPARTMENTS_DETAILS_REQUEST,
                ACTION_TYPES.SUBMIT_OTHER_DEPARTMENTS_DETAILS_SUCCESS,
                ACTION_TYPES.SUBMIT_OTHER_DEPARTMENTS_DETAILS_FAILURE
            ],
            data
        }
    };
};
export const otherDepartmentsContactTypeDropdownApi = () => {
    return {
        url: API_URL.OTHER_DEPARTMENTS.SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_REQUEST,
                ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_SUCCESS,
                ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};
export const otherDepartmentsLookupCategoryDropdownApi = () => {
    return {
        url: API_URL.OTHER_DEPARTMENTS.SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_REQUEST,
                ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_SUCCESS,
                ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_FAILURE
            ],
            data: { dropDown: true, pageSize: 300 }
        }
    };
};
export const getOtherDepartmentsByIdApi = (id) => {
    return {
        url: API_URL.OTHER_DEPARTMENTS.GET_OTHER_DEPARTMENTS_BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_OTHER_DEPARTMENTS_BY_ID_REQUEST,
                ACTION_TYPES.FETCH_OTHER_DEPARTMENTS_BY_ID_SUCCESS,
                ACTION_TYPES.FETCH_OTHER_DEPARTMENTS_BY_ID_FAILURE
            ]
        }
    };
};
export const deleteOtherDepartmentsApi = (id) => {
    return {
        url: API_URL.OTHER_DEPARTMENTS.DELETE_OTHER_DEPARTMENTS_DETAILS.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [
                ACTION_TYPES.DELETE_OTHER_DEPARTMENTS_DETAILS_REQUEST,
                ACTION_TYPES.DELETE_OTHER_DEPARTMENTS_DETAILS_SUCCESS,
                ACTION_TYPES.DELETE_OTHER_DEPARTMENTS_DETAILS_FAILURE
            ]
        }
    };
};
export const listOtherDepartmentsApi = (data) => {
    return {
        url: API_URL.OTHER_DEPARTMENTS.LIST_OTHER_DEPARTMENTS_DETAILS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.LIST_OTHER_DEPARTMENTS_DETAILS_REQUEST,
                ACTION_TYPES.LIST_OTHER_DEPARTMENTS_DETAILS_SUCCESS,
                ACTION_TYPES.LIST_OTHER_DEPARTMENTS_DETAILS_FAILURE
            ],
            data
        }
    };
};
export const patchOtherDepartmentsApi = (data) => {
    return {
        url: API_URL.OTHER_DEPARTMENTS.UPDATE_OTHER_DEPARTMENTS_DETAILS,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [
                ACTION_TYPES.UPDATE_OTHER_DEPARTMENTS_DETAILS_REQUEST,
                ACTION_TYPES.UPDATE_OTHER_DEPARTMENTS_DETAILS_SUCCESS,
                ACTION_TYPES.UPDATE_OTHER_DEPARTMENTS_DETAILS_FAILURE
            ],
            data
        }
    };
};
export const submitIeaContactsApi = (data) => {
    return {
        url: API_URL.IEA_CONTACTS.SUBMIT_IEA_CONTACTS,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [
                ACTION_TYPES.SUBMIT_IEA_CONTACTS_DETAILS_REQUEST,
                ACTION_TYPES.SUBMIT_IEA_CONTACTS_DETAILS_SUCCESS,
                ACTION_TYPES.SUBMIT_IEA_CONTACTS_DETAILS_FAILURE
            ],
            data
        }
    };
};
export const ieaContactsTypeDropdownApi = () => {
    return {
        url: API_URL.IEA_CONTACTS.SELECT_IEA_CONTACT_TYPE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_IEA_CONTACT_TYPE_REQUEST,
                ACTION_TYPES.SELECT_IEA_CONTACT_TYPE_SUCCESS,
                ACTION_TYPES.SELECT_IEA_CONTACT_TYPE_FAILURE
            ],
            data: { dropDown: true, pageSize: 300 }
        }
    };
};
export const ieaLookupCategoryDropdownApi = () => {
    return {
        url: API_URL.IEA_CONTACTS.SELECT_IEA_LOOKUP_CATEGORY_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_IEA_LOOKUP_CATEGORY_REQUEST,
                ACTION_TYPES.SELECT_IEA_LOOKUP_CATEGORY_SUCCESS,
                ACTION_TYPES.SELECT_IEA_LOOKUP_CATEGORY_FAILURE
            ],
            data: { dropDown: true, pageSize: 300 }
        }
    };
};
export const getIeaContactsByIdApi = (id) => {
    return {
        url: API_URL.IEA_CONTACTS.GET_IEA_CONTACTS_BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_IEA_CONTACTS_BY_ID_REQUEST,
                ACTION_TYPES.FETCH_IEA_CONTACTS_BY_ID_SUCCESS,
                ACTION_TYPES.FETCH_IEA_CONTACTS_BY_ID_FAILURE
            ]
        }
    };
};
export const deleteIeaContactsApi = (id) => {
    return {
        url: API_URL.IEA_CONTACTS.DELETE_IEA_CONTACTS.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [
                ACTION_TYPES.DELETE_IEA_CONTACTS_DETAILS_REQUEST,
                ACTION_TYPES.DELETE_IEA_CONTACTS_DETAILS_SUCCESS,
                ACTION_TYPES.DELETE_IEA_CONTACTS_DETAILS_FAILURE
            ]
        }
    };
};
export const listIeaContactsApi = (data) => {
    return {
        url: API_URL.IEA_CONTACTS.LIST_IEA_CONTACTS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.LIST_IEA_CONTACTS_DETAILS_REQUEST,
                ACTION_TYPES.LIST_IEA_CONTACTS_DETAILS_SUCCESS,
                ACTION_TYPES.LIST_IEA_CONTACTS_DETAILS_FAILURE
            ],
            data
        }
    };
};
export const patchIeaContactsApi = (data) => {
    return {
        url: API_URL.IEA_CONTACTS.UPDATE_IEA_CONTACTS,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [
                ACTION_TYPES.UPDATE_IEA_CONTACTS_DETAILS_REQUEST,
                ACTION_TYPES.UPDATE_IEA_CONTACTS_DETAILS_SUCCESS,
                ACTION_TYPES.UPDATE_IEA_CONTACTS_DETAILS_FAILURE
            ],
            data
        }
    };
};
export const filterIeaName = (data) => {
    return {
        url: API_URL.IEA_CONTACTS.FILTER_IEA_NAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FILTER_IEA_NAME_REQUEST,
                ACTION_TYPES.FILTER_IEA_NAME_SUCCESS,
                ACTION_TYPES.FILTER_IEA_NAME_FAILURE
            ],
            data: data
        }
    };
};

export const nameList = (data) => {
    return {
        url: API_URL.OTHER_DEPARTMENTS.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_NAME_LIST_REQUEST,
                ACTION_TYPES.FETCH_NAME_LIST_SUCCESS,
                ACTION_TYPES.FETCH_NAME_LIST_FAILURE
            ],
            data
        }
    };
};
export const addressList = (data) => {
    return {
        url: API_URL.OTHER_DEPARTMENTS.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_ADDRESS_LIST_REQUEST,
                ACTION_TYPES.FETCH_ADDRESS_LIST_SUCCESS,
                ACTION_TYPES.FETCH_ADDRESS_LIST_FAILURE
            ],
            data
        }
    };
};
