import { createSlice } from "@reduxjs/toolkit";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const initialState = {
    draftPlanById: {
        requestInProgress: false,
        data: {
            draftSwmPlanId: "",
            draftSwmPlanName: "",
            remarks: "",
            assignedProjects: [],
            cruxList: [],
            attachmentIds: []
        }
    },
    listDraftPlan: {
        requestInProgress: false,
        data: {
            draftSwmPlanId: "",
            draftSwmPlanName: "",
            remarks: "",
            attachmentIds: []
        }
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.draftPlanById = initialState.draftPlanById;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.LIST_DRAFT_PLAN_BY_ID_REQUEST, (state) => {
                state.draftPlanById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.LIST_DRAFT_PLAN_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "draftPlanById.requestInProgress", false);
                _.set(state, "draftPlanById.data", payload.payLoad);
                _.set(state, "draftPlanById.data.assignedProjects", payload.payLoad?.assignedProjects);
                _.set(state, "draftPlanById.data.cruxList", payload.payLoad?.cruxList);
            })
            .addCase(ACTION_TYPES.LIST_DRAFT_PLAN_BY_ID_FAILURE, (state) => {
                state.draftPlanById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.LIST_DRAFT_PLAN_SUCCESS, (state, { payload }) => {
                _.set(state, "listDraftPlan.requestInProgress", false);
                _.set(state, "listDraftPlan.data", payload.content[0]);
            });
    }

});

export const { actions, reducer } = slice;
