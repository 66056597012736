import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES, getEscalationLevel } from "./actions";
import { actions as commonActions } from "../../common/slice";
import { addWard, deleteEscalationApi, deleteWard, editWard, fetchEscalationDropDownApi, fetchEscalationLevelApi, fetchOfficersListApi, fetchStates, fetchWards, fetchWardsById, LSGIDropdownApi, lsgiTypeDropdownApi, wardCodeList, wardDropdownApi, wardNameList, wardNumberList, submitEscalationApi, nameList, fetchRoles } from "./api";
import { navigateTo } from "modules/common/actions";
import { ADMIN_PATH as PATH } from "../paths";
import { MODULE_PATH } from "../../paths";
import { getAdditionalFilters, getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { getTableProps } from "modules/common/selectors";
import { getPayloadContent } from "utils/apiUtils";
import { actions as sliceActions } from "./slice";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { TABLE_IDS } from "common/constants";

export function* fetchStateDetails() {
  yield call(handleAPIRequest, fetchStates);
}
export function* fetchLSGIDropDown(action) {
  const { id, districtId } = action.payload;
  yield fork(handleAPIRequest, LSGIDropdownApi, { id, districtId });

  const response = yield take([ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE]);

  if (response.type === ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS) {
    const lsgiListResponse = getPayloadContent(response, []);
    yield put(sliceActions.setLsgiList({ data: lsgiListResponse, id, districtId }));
  }
}
export function* getWardDropdown(action) {
  const { id, districtId, pageNo, pageSize } = action.payload;
  yield fork(handleAPIRequest, wardDropdownApi, { id, districtId, pageNo, pageSize });
  const response = yield take([ACTION_TYPES.SELECT_WARD_DROPDOWN_SUCCESS, ACTION_TYPES.SELECT_WARD_DROPDOWN_FAILURE]);
  if (response.type === ACTION_TYPES.SELECT_WARD_DROPDOWN_SUCCESS) {
    const wardListResponse = getPayloadContent(response, []);
    yield put(sliceActions.setWardList({ data: wardListResponse, id, districtId }));
  }
}
export function* fetchWardDetails({ payload }) {
  let tableProps = yield select(getTableProps);
  let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
  yield fork(handleAPIRequest, fetchWards, { pageNo: payload?.pageNo, pageSize: payload?.pageSize, lsgiId: payload?.id, ...filters });
  const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WARD_DETAILS_SUCCESS, ACTION_TYPES.FETCH_WARD_DETAILS_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}
export function* submitWardValueDetails({ payload }) {
  let { id } = payload;
  if (id) {
    yield fork(handleAPIRequest, editWard, payload);
    const responseAction = yield take([ACTION_TYPES.EDIT_WARD_DETAILS_SUCCESS, ACTION_TYPES.EDIT_WARD_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.EDIT_WARD_DETAILS_SUCCESS) {
      yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.WARD.LIST}`));
    }
  } else {
    yield fork(handleAPIRequest, addWard, payload);
    const responseAction = yield take([ACTION_TYPES.ADD_WARD_DETAILS_SUCCESS, ACTION_TYPES.ADD_WARD_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.ADD_WARD_DETAILS_SUCCESS) {
      yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.WARD.LIST}`));
    }
  }
}

export function* deleteWardDetails({ payload }) {
  const { id } = payload;
  yield fork(handleAPIRequest, deleteWard, { id });
  const responseAction = yield take([ACTION_TYPES.DELETE_WARD_DETAILS_SUCCESS, ACTION_TYPES.DELETE_WARD_DETAILS_FAILURE]);
  if (responseAction.type === ACTION_TYPES.DELETE_WARD_DETAILS_SUCCESS) {
    yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
    const tablePayload = yield* getPaginationPayload();
    yield call(fetchWardDetails, { payload: tablePayload });
  }
}
export function* fetchWardFormById({ payload: id }) {
  yield call(handleAPIRequest, fetchWardsById, { id });
}
export function* fetchWardNameList({ payload }) {
  yield call(handleAPIRequest, wardNameList, payload);
}
export function* fetchWardNumberList({ payload }) {
  yield call(handleAPIRequest, wardNumberList, payload);
}
export function* fetchWardCodeList({ payload }) {
  yield call(handleAPIRequest, wardCodeList, payload);
}
export function* lsgiTypeSelectDropdown(action) {
  yield call(handleAPIRequest, lsgiTypeDropdownApi, action.payload);
}
export function* fetchEscalationUnitFn() {
  yield call(handleAPIRequest, fetchEscalationDropDownApi);
}
export function* deleteEscalationFn({ payload: { id } = {} }) {
  yield fork(handleAPIRequest, deleteEscalationApi, id);
  const responseAction = yield take([ACTION_TYPES.DELETE_ESCALATION_SUCCESS, ACTION_TYPES.DELETE_ESCALATION_FAILURE]);
  if (responseAction.type === ACTION_TYPES.DELETE_ESCALATION_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("complaint_escalation") }) }));
    yield put(getEscalationLevel());
  }
}
export function* fetchEscalationLevelFn(payload) {
  yield call(handleAPIRequest, fetchEscalationLevelApi, payload);
}
export function* fetchOfficersList(action) {
  const { pageSize, roleName, targetReferenceId, pageNo } = action.payload;
  let additionalFilters = yield* getAdditionalFilters(TABLE_IDS.OFFICERS_LIST);
  let tableProps = yield select(getTableProps);
  let { [TABLE_IDS.OFFICERS_LIST]: { filters = {} } = {} } = tableProps;
  yield fork(handleAPIRequest, fetchOfficersListApi, { pageNo, pageSize, roleName, targetReferenceId, ...additionalFilters, ...filters });
  const COMPARISON_TYPES = [ACTION_TYPES.FETCH_OFFICERS_LIST_SUCCESS, ACTION_TYPES.FETCH_OFFICERS_LIST_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.OFFICERS_LIST);
}
export function* submitEscalationFn({ payload }) {
  yield fork(handleAPIRequest, submitEscalationApi, payload);
  const responseAction = yield take([ACTION_TYPES.SUBMIT_ESCALATION_SUCCESS, ACTION_TYPES.SUBMIT_ESCALATION_FAILURE]);
  if (responseAction.type === ACTION_TYPES.SUBMIT_ESCALATION_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("ward_escalation") }) }));
    yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.WARD.LIST}`));
  }
}
export function* fetchNameList({ payload }) {
  yield call(handleAPIRequest, nameList, payload);
}
export function* fetchRolesData({ payload }) {
  let tableProps = yield select(getTableProps);
  let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
  yield fork(handleAPIRequest, fetchRoles, { ...filters, ...payload });
  const COMPARISON_TYPES = [ACTION_TYPES.FETCH_ROLE_DATA_SUCCESS, ACTION_TYPES.FETCH_ROLE_DATA_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

export default function* sampleSaga() {
  yield all([
    takeLatest(ACTION_TYPES.FETCH_WARD_STATE_DETAILS, fetchStateDetails),
    takeLatest(ACTION_TYPES.FETCH_WARD_DETAILS, fetchWardDetails),
    takeLatest(ACTION_TYPES.SUBMIT_WARD_DATA, submitWardValueDetails),
    takeLatest(ACTION_TYPES.DELETE_WARD_DETAILS, deleteWardDetails),
    takeLatest(ACTION_TYPES.FETCH_WARD_DETAILS_BY_ID, fetchWardFormById),
    takeLatest(ACTION_TYPES.FETCH_WARD_NAME_LIST, fetchWardNameList),
    takeLatest(ACTION_TYPES.FETCH_WARD_CODE_LIST, fetchWardCodeList),
    takeLatest(ACTION_TYPES.FETCH_WARD_NUMBER_LIST, fetchWardNumberList),
    takeLatest(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN, lsgiTypeSelectDropdown),
    takeLatest(ACTION_TYPES.FETCH_LSGI_DROPDOWN, fetchLSGIDropDown),
    takeLatest(ACTION_TYPES.SELECT_WARD_DROPDOWN, getWardDropdown),
    takeLatest(ACTION_TYPES.SET_ESCALATION_UNIT_DROPDOWN, fetchEscalationUnitFn),
    takeLatest(ACTION_TYPES.DELETE_ESCALATION, deleteEscalationFn),
    takeLatest(ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID, fetchEscalationLevelFn),
    takeLatest(ACTION_TYPES.SUBMIT_ESCALATION, submitEscalationFn),
    takeLatest(ACTION_TYPES.FETCH_OFFICERS_LIST, fetchOfficersList),
    takeLatest(ACTION_TYPES.FETCH_NAME_LIST, fetchNameList),
    takeLatest(ACTION_TYPES.FETCH_ROLE_DATA, fetchRolesData)
  ]);
}
