import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    userDetails: {
        requestInProgress: false,
        data:
        {
            username: "",
            password: "",
            captcha: "",
            captchaCode: ""
        },
        requestStatus: ""
    },
    otpDetails: {}

};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clear: (state) => {
            state.userDetails = initialState.userDetails;
        },
        setLoginFormData: (state, action) => {
            _.set(state, "login.data.username", action.payload);
        },
        clearOtpDetails: (state) => {
            state.otpDetails = initialState.otpDetails;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.GET_USER_DETAILS_REQUEST, (state) => {
            state.userDetails.requestInProgress = true;
        }).addCase(ACTION_TYPES.GET_USER_DETAILS_FAILURE, (state) => {
            state.userDetails.requestInProgress = false;
        }).addCase(ACTION_TYPES.GET_USER_DETAILS_SUCCESS, (state, actions) => {
            state.userDetails.requestInProgress = false;
            state.userDetails.data = actions.payload;
        });
        builder.addCase(ACTION_TYPES.SET_OTP_DETAILS, (state, actions) => {
            state.otpDetails = actions.payload;
        });
    }
});

export const { reducer, actions } = slice;
