import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";
import { REQUEST_METHOD } from "common/constants";
import { API_URL as COMMON_API_URL } from "modules/common/apiURL";

export const fetchProjectActivitiesListApi = (data) => {
  return {
    url: `${API_URL.ACTIVITY_MONITORING.PROJECTACTIVITY.LIST}`,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.PROJECT_ACTIVITIES_LIST_REQUEST, ACTION_TYPES.PROJECT_ACTIVITIES_LIST_SUCCESS, ACTION_TYPES.PROJECT_ACTIVITIES_LIST_FAILURE],
      data: data
    }
  };
};

export const postProjectActivity = (data) => {
  return {
    url: `${API_URL.ACTIVITY_MONITORING.PROJECTACTIVITY.SAVE}`,
    method: REQUEST_METHOD.POST,
    payload: {
      types: [ACTION_TYPES.POST_PROJECT_ACTIVITY_REQUEST, ACTION_TYPES.POST_PROJECT_ACTIVITY_SUCCESS, ACTION_TYPES.POST_PROJECT_ACTIVITY_FAILURE],
      data: data
    }
  };
};

export const patchProjectActivity = (data) => {
  return {
    url: `${API_URL.ACTIVITY_MONITORING.PROJECTACTIVITY.LIST}`,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.PATCH_PROJECT_ACTIVITY_REQUEST, ACTION_TYPES.PATCH_PROJECT_ACTIVITY_SUCCESS, ACTION_TYPES.PATCH_PROJECT_ACTIVITY_FAILURE],
      data: data
    }
  };
};

export const deleteProjectActivity = (data) => {
  return {
    url: `${API_URL.ACTIVITY_MONITORING.PROJECTACTIVITY.DELETE.replace(":id", data)}`,
    method: REQUEST_METHOD.DELETE,
    payload: {
      types: [ACTION_TYPES.POST_PROJECT_ACTIVITY_REQUEST, ACTION_TYPES.DELETE_PROJECT_ACTIVITY_SUCCESS, ACTION_TYPES.DELETE_PROJECT_ACTIVITY_FAILURE]
    }
  };
};

export const fetchContractorList = (data) => {
  return {
    url: `${COMMON_API_URL.COMMON.WARD_DROPDOWN}`,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_CONTRACTOR_LIST_REQUEST, ACTION_TYPES.FETCH_CONTRACTOR_LIST_SUCCESS, ACTION_TYPES.FETCH_CONTRACTOR_LIST_FAILURE],
      data: data
    }
  };
};

export const technicalTableList = (data) => ({
  url: API_URL.ACTIVITY_MONITORING.TECHNICAL.LIST,
  method: REQUEST_METHOD.GET,
  payload: {
    types: [ACTION_TYPES.TECHNICAL_TABLE_LIST_REQUEST, ACTION_TYPES.TECHNICAL_TABLE_LIST_SUCCESS, ACTION_TYPES.TECHNICAL_TABLE_LIST_FAILURE],
    data: data
  }
});

export const projectMonitoringList = (data) => ({
  url: API_URL.ACTIVITY_MONITORING.LIST,
  method: REQUEST_METHOD.GET,
  payload: {
    types: [ACTION_TYPES.PROJECT_MONITORING_LIST_REQUEST, ACTION_TYPES.PROJECT_MONITORING_LIST_SUCCESS, ACTION_TYPES.PROJECT_MONITORING_LIST_FAILURE],
    data
  }
});

export const projectMonitoringDelete = (id) => {
  return {
    url: API_URL.ACTIVITY_MONITORING.DELETE.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
      types: [ACTION_TYPES.DELETE_PROJECT_MONITORING_REQUEST, ACTION_TYPES.DELETE_PROJECT_MONITORING_SUCCESS, ACTION_TYPES.DELETE_PROJECT_MONITORING_FAILURE]
    }
  };
};

export const updateTechnicalForm = (data) => ({
  url: API_URL.ACTIVITY_MONITORING.TECHNICAL.SAVE,
  method: REQUEST_METHOD.POST,
  payload: {
    types: [ACTION_TYPES.PATCH_TECHNICAL_FORM_REQUEST, ACTION_TYPES.PATCH_TECHNICAL_FORM_SUCCESS, ACTION_TYPES.PATCH_TECHNICAL_FORM_FAILURE],
    data
  }
});

export const fetchTechnicalFormById = (data) => {
  return {
    url: API_URL.ACTIVITY_MONITORING.TECHNICAL.BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.TECHNICAL_FORM_BY_ID_REQUEST, ACTION_TYPES.TECHNICAL_FORM_BY_ID_SUCCESS, ACTION_TYPES.TECHNICAL_FORM_BY_ID_FAILURE],
      data
    }
  };
};

export const fetchTechnicalViewForm = (data) => {
  return {
    url: API_URL.ACTIVITY_MONITORING.TECHNICAL.VIEW,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.TECHNICAL_FORM_VIEW_REQUEST, ACTION_TYPES.TECHNICAL_FORM_VIEW_SUCCESS, ACTION_TYPES.TECHNICAL_FORM_VIEW_FAILURE],
      data
    }
  };
};

export const paymentScheduleList = (data, id) => ({
  url: `${API_URL.ACTIVITY_MONITORING.PAYMENT_SCHEDULE.LIST}?projectId=${id}`,
  method: REQUEST_METHOD.GET,
  payload: {
    types: [ACTION_TYPES.LIST_PAYMENT_SCHEDULE_REQUEST, ACTION_TYPES.LIST_PAYMENT_SCHEDULE_SUCCESS, ACTION_TYPES.LIST_PAYMENT_SCHEDULE_FAILURE],
    data
  }
});

export const paymentScheduleSave = (data) => ({
  url: API_URL.ACTIVITY_MONITORING.PAYMENT_SCHEDULE.SAVE,
  method: REQUEST_METHOD.POST,
  payload: {
    types: [ACTION_TYPES.SUBMIT_PAYMENT_SCHEDULE_REQUEST, ACTION_TYPES.SUBMIT_PAYMENT_SCHEDULE_SUCCESS, ACTION_TYPES.SUBMIT_PAYMENT_SCHEDULE_FAILURE],
    data
  }
});

export const paymentScheduleUpdate = (data) => ({
  url: API_URL.ACTIVITY_MONITORING.PAYMENT_SCHEDULE.UPDATE,
  method: REQUEST_METHOD.PATCH,
  payload: {
    types: [ACTION_TYPES.UPDATE_PAYMENT_SCHEDULE_REQUEST, ACTION_TYPES.UPDATE_PAYMENT_SCHEDULE_SUCCESS, ACTION_TYPES.UPDATE_PAYMENT_SCHEDULE_FAILURE],
    data
  }
});

export const paymentScheduleByIdApi = (data) => {
  return {
    url: API_URL.ACTIVITY_MONITORING.PAYMENT_SCHEDULE.BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.LIST_PAYMENT_SCHEDULE_BY_ID_REQUEST, ACTION_TYPES.LIST_PAYMENT_SCHEDULE_BY_ID_SUCCESS, ACTION_TYPES.LIST_PAYMENT_SCHEDULE_BY_ID_FAILURE],
      data: { id: data }
    }
  };
};

export const projectActivitiesListById = (data) => {
  return {
    url: `${API_URL.ACTIVITY_MONITORING.PROJECTACTIVITY.LIST_BY_ID}`,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.PROJECT_ACTIVITIES_LIST_BY_ID_REQUEST, ACTION_TYPES.PROJECT_ACTIVITIES_LIST_BY_ID_SUCCESS, ACTION_TYPES.PROJECT_ACTIVITIES_LIST_BY_ID_FAILURE],
      data: data
    }
  };
};

export const projectActivityUpdate = (data) => ({
  url: API_URL.ACTIVITY_MONITORING.PROJECTACTIVITY.UPDATE,
  method: REQUEST_METHOD.POST,
  payload: {
    types: [ACTION_TYPES.UPDATE_PROJECT_ACTIVITY_REQUEST, ACTION_TYPES.UPDATE_PROJECT_ACTIVITY_SUCCESS, ACTION_TYPES.UPDATE_PROJECT_ACTIVITY_FAILURE],
    data
  }
});

export const contractorListByIdApi = (data) => {
  return {
    url: API_URL.ACTIVITY_MONITORING.PROJECTACTIVITY.CONTRACTOR_BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.CONTRACTOR_LIST_BY_ID_REQUEST, ACTION_TYPES.CONTRACTOR_LIST_BY_ID_SUCCESS, ACTION_TYPES.CONTRACTOR_LIST_BY_ID_FAILURE],
      data: { id: data }
    }
  };
};

export const fetchActivityNameApi = (data) => {
  return {
    url: API_URL.ACTIVITY_MONITORING.TECHNICAL.ACTIVITY_NAME,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.SELECT_ACTIVITY_NAME_REQUEST, ACTION_TYPES.SELECT_ACTIVITY_NAME_SUCCESS, ACTION_TYPES.SELECT_ACTIVITY_NAME_FAILURE],
      data
    }
  };
};

export const fetchTechnicalListAll = (data) => {
  return {
    url: API_URL.ACTIVITY_MONITORING.TECHNICAL.LIST_ALL,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.LIST_TECHNICAL_ALL_REQUEST, ACTION_TYPES.LIST_TECHNICAL_ALL_SUCCESS, ACTION_TYPES.LIST_TECHNICAL_ALL_FAILURE],
      data
    }
  };
};

export const paymentScheduleDataApi = (data) => {
  return {
    url: API_URL.ACTIVITY_MONITORING.PAYMENT_SCHEDULE.EDIT_DATA,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.PAYMENT_SCHEDULE_BY_ID_REQUEST, ACTION_TYPES.PAYMENT_SCHEDULE_BY_ID_SUCCESS, ACTION_TYPES.PAYMENT_SCHEDULE_BY_ID_FAILURE],
      data: { id: data }
    }
  };
};
