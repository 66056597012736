import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions.js";
import { getPayloadContent, getPayloadContentDetails } from "utils/apiUtils";
import _ from "lodash";

const initialState = {
    dataAccessRoleLevelDropdown: {
        data: []
    },
    userRoleListAdditionalFilters: {
        data: {}
    },
    userRoleMappingLists: {
        requestInProgress: false,
        data: [],
        rowSelected: []
    },
    userRoleMappingDetails: {
        requestInProgress: false,
        data: {
            roleMappings: [
                {
                    effectiveFrom: ""
                }
            ]
        }
    },
    userRoleDataAccessList: {
        requestInProgress: false,
        data: {}
    },
    userRoleListById: {
        requestInProgress: false,
        data: {}
    },
    userRoleDataAccessDetails: {
        requestInProgress: false,
        data: {
            stateName: "",
            district: "",
            ward: "",
            listLsgi: "",
            lsgi: "",
            trainingOrganization: "",
            vendor: "",
            iva: ""
        }
    },

    open: false,
    openAccess: false,
    userId: 0,
    assignedRoles: true,
    roleId: 0,

    districtSelectDropdownOption: {
        requestInProgress: false,
        data: []
    },
    lsgiSelectDropdownOption: {
        requestInProgress: false,
        data: []
    },
    wardSelectDropdownOption: {
        requestInProgress: false,
        data: []
    },
    stateSelectDropdownOption: {
        requestInProgress: false,
        data: []
    },
    trainingOrganizationDropdownOption: {
        requestInProgress: false,
        data: []
    },
    vendorSelectDropdownOption: {
        requestInProgress: false,
        data: []
    },
    lsgiTypeDropdownOption: {
        requestInProgress: false,
        data: []
    },
    ivaDropdownOption: {
        requestInProgress: false,
        data: []
    },
    listRoleMappingName: {}
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.listRoleMappingName = initialState.listRoleMappingName;
        },
        clearRoleListAdditionalFilters: (state) => {
            state.userRoleListAdditionalFilters = initialState.userRoleListAdditionalFilters.data;
        },
        setRowSelectionState: (state, action) => {
            state.userRoleMappingLists.rowSelected = action.payload;
        },
        setRowSelectionData: (state, action) => {
            state.userRoleMappingDetails.data.roleMappings = action.payload;
        },
        setRoleListAdditionalFilters: (state, action) => {
            state.userRoleListAdditionalFilters.data = action.payload;
        },
        setOpen: (state, action) => {
            state.open = action.payload;
            state.openAccess = false;
        },
        setOpenAccess: (state, action) => {
            state.openAccess = action.payload;
            state.open = false;
        },
        setAssignedRoles: (state) => {
            state.assignedRoles = !state.assignedRoles;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        setRoleId: (state, action) => {
            state.roleId = action.payload;
        },
        setLsgiList: (state, data) => {
            let { data: lsgiList = [], id: lsgiTypeId, districtId } = data.payload;
            state.lsgiSelectDropdownOption = {
                requestInProgress: false,
                [districtId]: {
                    [lsgiTypeId]: lsgiList
                }
            };
            state.wardSelectDropdownOption = {
                requestInProgress: false
            };

        },
        setWardList: (state, data) => {
            let { data: wardList = [], id: lsgiId, districtId } = data.payload;
            state.wardSelectDropdownOption = {
                requestInProgress: false,
                [districtId]: {
                    [lsgiId]: wardList
                }
            };
        },
        setRoleMappingName: (state, { payload: { key, value } = {} }) => {
            _.set(state, `listRoleMappingName.${key}`, value);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_REQUEST, (state) => {
            state.userRoleMappingLists.requestInProgress = true;
        }).addCase(ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_SUCCESS, (state, action) => {
            state.userRoleMappingLists = {
                requestInProgress: false,
                data: getPayloadContent(action)
            };
        })
            .addCase(ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_FAILURE, (state) => {
                state.userRoleMappingLists.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID_REQUEST, (state) => {
                state.userRoleMappingLists.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID_SUCCESS, (state, action) => {
                state.userRoleMappingLists = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID_FAILURE, (state) => {
                state.userRoleMappingLists.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_USER_ROLE_DATA_ACCESS_DETAILS_REQUEST, (state) => {
                state.userRoleDataAccessList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_USER_ROLE_DATA_ACCESS_DETAILS_SUCCESS, (state, action) => {
                state.userRoleDataAccessList = {
                    requestInProgress: false,
                    data: getPayloadContentDetails(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_USER_ROLE_DATA_ACCESS_DETAILS_FAILURE, (state) => {
                state.userRoleDataAccessList.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.SELECT_DISTRICT_DROPDOWN_REQUEST, (state) => {
                state.districtSelectDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_DISTRICT_DROPDOWN_SUCCESS, (state, action) => {
                state.districtSelectDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.SELECT_DISTRICT_DROPDOWN_FAILURE, (state) => {
                state.districtSelectDropdownOption.requestInProgress = false;
            })

            .addCase(ACTION_TYPES.SELECT_LSGI_DROPDOWN_REQUEST, (state) => {
                state.lsgiSelectDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_LSGI_DROPDOWN_SUCCESS, (state, action) => {
                state.lsgiSelectDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.SELECT_LSGI_DROPDOWN_FAILURE, (state) => {
                state.lsgiSelectDropdownOption.requestInProgress = false;
            })

            .addCase(ACTION_TYPES.SELECT_WARD_DROPDOWN_REQUEST, (state) => {
                state.wardSelectDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_WARD_DROPDOWN_SUCCESS, (state, action) => {
                state.wardSelectDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.SELECT_WARD_DROPDOWN_FAILURE, (state) => {
                state.wardSelectDropdownOption.requestInProgress = false;
            })

            .addCase(ACTION_TYPES.SELECT_STATE_DROPDOWN_REQUEST, (state) => {
                state.stateSelectDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_STATE_DROPDOWN_SUCCESS, (state, action) => {
                state.stateSelectDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.SELECT_STATE_DROPDOWN_FAILURE, (state) => {
                state.stateSelectDropdownOption.requestInProgress = false;
            })

            .addCase(ACTION_TYPES.SELECT_TRAINING_ORGANIZATION_DROPDOWN_REQUEST, (state) => {
                state.trainingOrganizationDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_TRAINING_ORGANIZATION_DROPDOWN_SUCCESS, (state, action) => {
                state.trainingOrganizationDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.SELECT_TRAINING_ORGANIZATION_DROPDOWN_FAILURE, (state) => {
                state.trainingOrganizationDropdownOption.requestInProgress = false;
            })

            .addCase(ACTION_TYPES.SELECT_VENDOR_DROPDOWN_REQUEST, (state) => {
                state.vendorSelectDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_VENDOR_DROPDOWN_SUCCESS, (state, action) => {
                state.vendorSelectDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.SELECT_VENDOR_DROPDOWN_FAILURE, (state) => {
                state.vendorSelectDropdownOption.requestInProgress = false;
            }).addCase(ACTION_TYPES.SELECT_ROLE_LEVEL_DROPDOWN_REQUEST, (state) => {
                state.dataAccessRoleLevelDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_ROLE_LEVEL_DROPDOWN_SUCCESS, (state, action) => {
                state.dataAccessRoleLevelDropdown = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.SELECT_ROLE_LEVEL_DROPDOWN_FAILURE, (state) => {
                state.dataAccessRoleLevelDropdown.requestInProgress = false;
            }).addCase(ACTION_TYPES.FETCH_USER_ROLE_BY_ID_DETAILS_REQUEST, (state) => {
                state.userRoleListById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_USER_ROLE_BY_ID_DETAILS_SUCCESS, (state, action) => {
                state.userRoleListById = {
                    requestInProgress: false,
                    data: getPayloadContentDetails(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_USER_ROLE_BY_ID_DETAILS_FAILURE, (state) => {
                state.userRoleListById.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_REQUEST, (state) => {
                state.lsgiTypeDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_SUCCESS, (state, action) => {
                state.lsgiTypeDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_FAILURE, (state) => {
                state.lsgiTypeDropdownOption.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.IVA_DROPDOWN_REQUEST, (state) => {
                state.ivaDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.IVA_DROPDOWN_SUCCESS, (state, action) => {
                state.ivaDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.IVA_DROPDOWN_FAILURE, (state) => {
                state.ivaDropdownOption.requestInProgress = false;
            });
    }
});

export const { actions, reducer } = slice;

