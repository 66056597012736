import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const initialState = {
    wasteProcessingById: {
        requestInProgress: false,
        data: {
            id: "",
            districtId: "",
            lsgiId: "",
            name: "",
            siteCode: "",
            location: "",
            latitude: "",
            longitude: "",
            womenGroup: "",
            buildUpArea: "",
            serviceArea: "",
            facility: "",
            siteArea: "",
            date: null,
            total: "",
            male: "",
            female: "",
            transgender: "",
            sc: "",
            st: "",
            disabled: "",
            seniorCitizen: "",
            capacity: "",
            typeOfAccess: "",
            widthOfRoad: "",
            wasteStorageArea: "",
            biProductStorage: "",
            otherStorage: "",
            leachateManagement: "",
            odourManagement: "",
            flyManagement: "",
            methodsOfTreatment: "",
            capacityOfMethods: "",
            inputMaterialsDetails: "",
            outputMaterialsDetails: "",
            company: "",
            waterAvailability: "",
            electricityAvailability: "",
            sanitationFacilities: "",
            housekeeping: "",
            officersInCharge: "",
            designation: "",
            contactNo: "",
            issues: "",
            additions: ""
        }
    },
    siteNameDropdown: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        setWasteProcessingById: (state, action) => {
            state.wasteProcessingById.data = action.payload;
        },
        clearWasteProcessingById: (state) => {
            state.wasteProcessingById.data = initialState.wasteProcessingById.data;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_WASTE_PROCESSING_BY_ID_REQUEST, (state) => {
                state.wasteProcessingById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_WASTE_PROCESSING_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "wasteProcessingById.requestInProgress", false);
                _.set(state, "wasteProcessingById.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_WASTE_PROCESSING_BY_ID_FAILURE, (state) => {
                state.wasteProcessingById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_SITE_NAME_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "siteNameDropdown.requestInProgress", false);
                _.set(state, "siteNameDropdown.data", payload?.content);
            });
    }

});

export const { actions, reducer } = slice;
