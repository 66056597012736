const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./actions");
import _ from "lodash";

const initialState = {
    womenGroup: {
        requestInProgress: false,
        data: []
    },
    primaryCollectionDetails: {
        requestInProgress: false,
        data: {
            districtId: "",
            lsgiId: "",
            nameOfAgency: "",
            location: "",
            womenGroup: "",
            collectionPerDay: "",
            area: "",
            storageFacility: "",
            segregationFacility: "",
            collectionDate: "",
            total: "",
            male: "",
            female: "",
            transgender: "",
            sc: "",
            st: "",
            disabled: "",
            seniorCitizen: "",
            longitude: "",
            latitude: ""
        }
    },
    agencyName: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState,
        setPrimaryCollectionDetailsById: (state, action) => {
            state.primaryCollectionDetails.data = action.payload;
        },
        resetPrimaryCollectionDetailsById: (state) => {
            state.primaryCollectionDetails.data = initialState.primaryCollectionDetails.data;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.WOMEN_WORK_GROUP_DROPDOWN_REQUEST, (state) => {
                state.womenGroup.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.WOMEN_WORK_GROUP_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "womenGroup.requestInProgress", false);
                _.set(state, "womenGroup.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.WOMEN_WORK_GROUP_DROPDOWN_FAILURE, (state) => {
                state.womenGroup.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_PRIMARY_COLLECTION_AGENCY_BY_ID_REQUEST, (state) => {
                state.primaryCollectionDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_PRIMARY_COLLECTION_AGENCY_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "primaryCollectionDetails.requestInProgress", false);
                _.set(state, "primaryCollectionDetails.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_PRIMARY_COLLECTION_AGENCY_BY_ID_FAILURE, (state) => {
                state.primaryCollectionDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.AGENCY_NAME_DROPDOWN_REQUEST, (state) => {
                state.agencyName.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.AGENCY_NAME_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "agencyName.requestInProgress", false);
                _.set(state, "agencyName.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.AGENCY_NAME_DROPDOWN_FAILURE, (state) => {
                state.agencyName.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;

