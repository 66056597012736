import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_CONTACT_TYPE: `${STATE_REDUCER_KEY}/FETCH_CONTACT_TYPE`,
    FETCH_CONTACT_TYPE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CONTACT_TYPE_REQUEST`,
    FETCH_CONTACT_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CONTACT_TYPE_SUCCESS`,
    FETCH_CONTACT_TYPE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CONTACT_TYPE_FAILURE`,

    FETCH_LOOKUP_CATEGORY: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY`,
    FETCH_LOOKUP_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_REQUEST`,
    FETCH_LOOKUP_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_SUCCESS`,
    FETCH_LOOKUP_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOOKUP_CATEGORY_FAILURE`,

    SUBMIT_SHO_NGO_CONTACTS: `${STATE_REDUCER_KEY}/SUBMIT_SHO_NGO_CONTACTS`,
    SUBMIT_SHO_NGO_CONTACTS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_SHO_NGO_CONTACTS_REQUEST`,
    SUBMIT_SHO_NGO_CONTACTS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_SHO_NGO_CONTACTS_SUCCESS`,
    SUBMIT_SHO_NGO_CONTACTS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_SHO_NGO_CONTACTS_FAILURE`,

    UPDATE_SHO_NGO: `${STATE_REDUCER_KEY}/UPDATE_SHO_NGO`,
    UPDATE_SHO_NGO_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_SHO_NGO_REQUEST`,
    UPDATE_SHO_NGO_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_SHO_NGO_SUCCESS`,
    UPDATE_SHO_NGO_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_SHO_NGO_FAILURE`,

    LIST_SHO_NGO_CONTACTS: `${STATE_REDUCER_KEY}/LIST_SHO_NGO_CONTACTS`,
    LIST_SHO_NGO_CONTACTS_REQUEST: `${STATE_REDUCER_KEY}/LIST_SHO_NGO_CONTACTS_REQUEST`,
    LIST_SHO_NGO_CONTACTS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_SHO_NGO_CONTACTS_SUCCESS`,
    LIST_SHO_NGO_CONTACTS_FAILURE: `${STATE_REDUCER_KEY}/LIST_SHO_NGO_CONTACTS_FAILURE`,

    DELETE_SHO_NGO_CONTACTS: `${STATE_REDUCER_KEY}/DELETE_SHO_NGO_CONTACTS`,
    DELETE_SHO_NGO_CONTACTS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_SHO_NGO_CONTACTS_REQUEST`,
    DELETE_SHO_NGO_CONTACTS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_SHO_NGO_CONTACTS_SUCCESS`,
    DELETE_SHO_NGO_CONTACTS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_SHO_NGO_CONTACTS_FAILURE`,

    SHO_NGO_CONTACTS_BY_ID: `${STATE_REDUCER_KEY}/SHO_NGO_CONTACTS_BY_ID`,
    SHO_NGO_CONTACTS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/SHO_NGO_CONTACTS_BY_ID_REQUEST`,
    SHO_NGO_CONTACTS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/SHO_NGO_CONTACTS_BY_ID_SUCCESS`,
    SHO_NGO_CONTACTS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/SHO_NGO_CONTACTS_BY_ID_FAILURE`,

    LIST_TRAINING_ORGANIZATION: `${STATE_REDUCER_KEY}/LIST_TRAINING_ORGANIZATION`,
    LIST_TRAINING_ORGANIZATION_REQUEST: `${STATE_REDUCER_KEY}/LIST_TRAINING_ORGANIZATION_REQUEST`,
    LIST_TRAINING_ORGANIZATION_SUCCESS: `${STATE_REDUCER_KEY}/LIST_TRAINING_ORGANIZATION_SUCCESS`,
    LIST_TRAINING_ORGANIZATION_FAILURE: `${STATE_REDUCER_KEY}/LIST_TRAINING_ORGANIZATION_FAILURE`,

    DELETE_TRAINING_ORGANIZATION: `${STATE_REDUCER_KEY}/DELETE_TRAINING_ORGANIZATION`,
    DELETE_TRAINING_ORGANIZATION_REQUEST: `${STATE_REDUCER_KEY}/DELETE_TRAINING_ORGANIZATION_REQUEST`,
    DELETE_TRAINING_ORGANIZATION_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_TRAINING_ORGANIZATION_SUCCESS`,
    DELETE_TRAINING_ORGANIZATION_FAILURE: `${STATE_REDUCER_KEY}/DELETE_TRAINING_ORGANIZATION_FAILURE`,

    UPDATE_TRAINING_ORGANIZATION: `${STATE_REDUCER_KEY}/UPDATE_TRAINING_ORGANIZATION`,
    UPDATE_TRAINING_ORGANIZATION_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_TRAINING_ORGANIZATION_REQUEST`,
    UPDATE_TRAINING_ORGANIZATION_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_TRAINING_ORGANIZATION_SUCCESS`,
    UPDATE_TRAINING_ORGANIZATION_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_TRAINING_ORGANIZATION_FAILURE`,

    SUBMIT_TRAINING_ORGANIZATION: `${STATE_REDUCER_KEY}/SUBMIT_TRAINING_ORGANIZATION`,
    SUBMIT_TRAINING_ORGANIZATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_TRAINING_ORGANIZATION_REQUEST`,
    SUBMIT_TRAINING_ORGANIZATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_TRAINING_ORGANIZATION_SUCCESS`,
    SUBMIT_TRAINING_ORGANIZATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_TRAINING_ORGANIZATION_FAILURE`,

    TRAINING_ORGANIZATION_BY_ID: `${STATE_REDUCER_KEY}/TRAINING_ORGANIZATION_BY_ID`,
    TRAINING_ORGANIZATION_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_ORGANIZATION_BY_ID_REQUEST`,
    TRAINING_ORGANIZATION_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_ORGANIZATION_BY_ID_SUCCESS`,
    TRAINING_ORGANIZATION_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_ORGANIZATION_BY_ID_FAILURE`,

    FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
    FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
    FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
    FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,

    FETCH_ADDRESS_LIST: `${STATE_REDUCER_KEY}/FETCH_ADDRESS_LIST`,
    FETCH_ADDRESS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ADDRESS_LIST_REQUEST`,
    FETCH_ADDRESS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ADDRESS_LIST_SUCCESS`,
    FETCH_ADDRESS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ADDRESS_LIST_FAILURE`,

    FILTER_SHO_NGO_NAME: `${STATE_REDUCER_KEY}FILTER_SHO_NGO_NAME`,
    FILTER_SHO_NGO_NAME_REQUEST: `${STATE_REDUCER_KEY}FILTER_SHO_NGO_NAME_REQUEST`,
    FILTER_SHO_NGO_NAME_SUCCESS: `${STATE_REDUCER_KEY}FILTER_SHO_NGO_NAME_SUCCESS`,
    FILTER_SHO_NGO_NAME_FAILURE: `${STATE_REDUCER_KEY}FILTER_SHO_NGO_NAME_FAILURE`
};

export const getContactType = createAction(ACTION_TYPES.FETCH_CONTACT_TYPE);
export const getLookupCategory = createAction(ACTION_TYPES.FETCH_LOOKUP_CATEGORY);
export const updateShoAndNgo = createAction(ACTION_TYPES.UPDATE_SHO_NGO);
export const postShoAndNgo = createAction(ACTION_TYPES.SUBMIT_SHO_NGO_CONTACTS);
export const deleteShoAndNgo = createAction(ACTION_TYPES.DELETE_SHO_NGO_CONTACTS);
export const listShoAndNgo = createAction(ACTION_TYPES.LIST_SHO_NGO_CONTACTS);
export const shoNgoContactsById = createAction(ACTION_TYPES.SHO_NGO_CONTACTS_BY_ID);
export const listTrainingOrganization = createAction(ACTION_TYPES.LIST_TRAINING_ORGANIZATION);
export const deleteTrainingOrganization = createAction(ACTION_TYPES.DELETE_TRAINING_ORGANIZATION);
export const updateTrainingOrganization = createAction(ACTION_TYPES.UPDATE_TRAINING_ORGANIZATION);
export const postTrainingOrganization = createAction(ACTION_TYPES.SUBMIT_TRAINING_ORGANIZATION);
export const trainingOrganizationById = createAction(ACTION_TYPES.TRAINING_ORGANIZATION_BY_ID);
export const filterShoNgoName = createAction(ACTION_TYPES.FILTER_SHO_NGO_NAME);
export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);
export const getAddressList = createAction(ACTION_TYPES.FETCH_ADDRESS_LIST);
