import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    SAVE_SCHEDULE_FORM: `${STATE_REDUCER_KEY}/SAVE_SCHEDULE_FORM`,
    SAVE_SCHEDULE_FORM_REQUEST: `${STATE_REDUCER_KEY}/SAVE_SCHEDULE_FORM_REQUEST`,
    SAVE_SCHEDULE_FORM_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_SCHEDULE_FORM_SUCCESS`,
    SAVE_SCHEDULE_FORM_FAILURE: `${STATE_REDUCER_KEY}/SAVE_SCHEDULE_FORM_FAILURE`,

    LIST_SCHEDULE_DETAILS: `${STATE_REDUCER_KEY}/LIST_SCHEDULE_DETAILS`,
    LIST_SCHEDULE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/LIST_SCHEDULE_DETAILS_REQUEST`,
    LIST_SCHEDULE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_SCHEDULE_DETAILS_SUCCESS`,
    LIST_SCHEDULE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/LIST_SCHEDULE_DETAILS_FAILURE`,

    FETCH_COURSE_ID: `${STATE_REDUCER_KEY}/FETCH_COURSE_ID`,
    FETCH_COURSE_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COURSE_ID_REQUEST`,
    FETCH_COURSE_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COURSE_ID_SUCCESS`,
    FETCH_COURSE_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COURSE_ID_FAILURE`,

    FETCH_SESSION_ID: `${STATE_REDUCER_KEY}/FETCH_SESSION_ID`,
    FETCH_SESSION_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SESSION_ID_REQUEST`,
    FETCH_SESSION_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SESSION_ID_SUCCESS`,
    FETCH_SESSION_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SESSION_ID_FAILURE`,

    FETCH_SESSION_TYPE: `${STATE_REDUCER_KEY}/FETCH_SESSION_TYPE`,
    FETCH_SESSION_TYPE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SESSION_TYPE_REQUEST`,
    FETCH_SESSION_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SESSION_TYPE_SUCCESS`,
    FETCH_SESSION_TYPE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SESSION_TYPE_FAILURE`,


    FETCH_TRAINING_ORGANIZATION: `${STATE_REDUCER_KEY}/FETCH_TRAINING_ORGANIZATION`,
    FETCH_TRAINING_ORGANIZATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TRAINING_ORGANIZATION_REQUEST`,
    FETCH_TRAINING_ORGANIZATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TRAINING_ORGANIZATION_SUCCESS`,
    FETCH_TRAINING_ORGANIZATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TRAINING_ORGANIZATION_FAILURE`,

    FETCH_BATCH_ID: `${STATE_REDUCER_KEY}/FETCH_BATCH_ID`,
    FETCH_BATCH_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BATCH_ID_REQUEST`,
    FETCH_BATCH_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BATCH_ID_SUCCESS`,
    FETCH_BATCH_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BATCH_ID_FAILURE`,

    FETCH_TRAINER_ID: `${STATE_REDUCER_KEY}/FETCH_TRAINER_ID`,
    FETCH_TRAINER_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TRAINER_ID_REQUEST`,
    FETCH_TRAINER_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TRAINER_ID_SUCCESS`,
    FETCH_TRAINER_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TRAINER_ID_FAILURE`,


    DELETE_SCHEDULE_FORM: `${STATE_REDUCER_KEY}/DELETE_SCHEDULE_FORM`,
    DELETE_SCHEDULE_FORM_REQUEST: `${STATE_REDUCER_KEY}/DELETE_SCHEDULE_FORM_REQUEST`,
    DELETE_SCHEDULE_FORM_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_SCHEDULE_FORM_SUCCESS`,
    DELETE_SCHEDULE_FORM_FAILURE: `${STATE_REDUCER_KEY}/DELETE_SCHEDULE_FORM_FAILURE`,

    EDIT_SCHEDULE_FORM: `${STATE_REDUCER_KEY}/EDIT_SCHEDULE_FORM`,
    EDIT_SCHEDULE_FORM_REQUEST: `${STATE_REDUCER_KEY}/EDIT_SCHEDULE_FORM_REQUEST`,
    EDIT_SCHEDULE_FORM_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_SCHEDULE_FORM_SUCCESS`,
    EDIT_SCHEDULE_FORM_FAILURE: `${STATE_REDUCER_KEY}/EDIT_SCHEDULE_FORM_FAILURE`,

    EDIT_SCHEDULE_FORM_BY_ID: `${STATE_REDUCER_KEY}/EDIT_SCHEDULE_FORM_BY_ID`,
    EDIT_SCHEDULE_FORM_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/EDIT_SCHEDULE_FORM_BY_ID_REQUEST`,
    EDIT_SCHEDULE_FORM_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_SCHEDULE_FORM_BY_ID_SUCCESS`,
    EDIT_SCHEDULE_FORM_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/EDIT_SCHEDULE_FORM_BY_ID_FAILURE`,

    FETCH_QUESTION_ID: `${STATE_REDUCER_KEY}/FETCH_QUESTION_ID`,
    FETCH_QUESTION_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_QUESTION_ID_REQUEST`,
    FETCH_QUESTION_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_QUESTION_ID_SUCCESS`,
    FETCH_QUESTION_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_QUESTION_ID_FAILURE`,


    FILTER_SCHEDULE_BATCH: `${STATE_REDUCER_KEY}/FILTER_SCHEDULE_BATCH`,
    FILTER_SCHEDULE_BATCH_REQUEST: `${STATE_REDUCER_KEY}/FILTER_SCHEDULE_BATCH_REQUEST`,
    FILTER_SCHEDULE_BATCH_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_SCHEDULE_BATCH_SUCCESS`,
    FILTER_SCHEDULE_BATCH_FAILURE: `${STATE_REDUCER_KEY}/FILTER_SCHEDULE_BATCH_FAILURE`,

    FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
    FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
    FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
    FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,


    CONFIRM_ATTENDANCE: `${STATE_REDUCER_KEY}/CONFIRM_ATTENDANCE`,
    CONFIRM_ATTENDANCE_REQUEST: `${STATE_REDUCER_KEY}/CONFIRM_ATTENDANCE_REQUEST`,
    CONFIRM_ATTENDANCE_SUCCESS: `${STATE_REDUCER_KEY}/CONFIRM_ATTENDANCE_SUCCESS`,
    CONFIRM_ATTENDANCE_FAILURE: `${STATE_REDUCER_KEY}/CONFIRM_ATTENDANCE_FAILURE`,

    EDIT_BATCH_FORM_BY_ID: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM_BY_ID`,
    EDIT_BATCH_FORM_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM_BY_ID_REQUEST`,
    EDIT_BATCH_FORM_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM_BY_ID_SUCCESS`,
    EDIT_BATCH_FORM_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM_BY_ID_FAILURE`,

    FETCH_PARTICIPANTS_DETAILS: `${STATE_REDUCER_KEY}/FETCH_PARTICIPANTS_DETAILS`,
    FETCH_PARTICIPANTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PARTICIPANTS_DETAILS_REQUEST`,
    FETCH_PARTICIPANTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PARTICIPANTS_DETAILS_SUCCESS`,
    FETCH_PARTICIPANTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PARTICIPANTS_DETAILS_FAILURE`,

    FETCH_FILTER: `${STATE_REDUCER_KEY}/FETCH_FILTER`,
    FETCH_FILTER_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FILTER_REQUEST`,
    FETCH_FILTER_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FILTER_SUCCESS`,
    FETCH_FILTER_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FILTER_FAILURE`,

    ACTIVATE_CHECK_IN: `${STATE_REDUCER_KEY}/ACTIVATE_CHECK_IN`,
    ACTIVATE_CHECK_IN_REQUEST: `${STATE_REDUCER_KEY}/ACTIVATE_CHECK_IN_REQUEST`,
    ACTIVATE_CHECK_IN_SUCCESS: `${STATE_REDUCER_KEY}/ACTIVATE_CHECK_IN_SUCCESS`,
    ACTIVATE_CHECK_IN_FAILURE: `${STATE_REDUCER_KEY}/ACTIVATE_CHECK_IN_FAILURE`,

    ACTIVATE_CHECK_OUT: `${STATE_REDUCER_KEY}/ACTIVATE_CHECK_OUT`,
    ACTIVATE_CHECK_OUT_REQUEST: `${STATE_REDUCER_KEY}/ACTIVATE_CHECK_OUT_REQUEST`,
    ACTIVATE_CHECK_OUT_SUCCESS: `${STATE_REDUCER_KEY}/ACTIVATE_CHECK_OUT_SUCCESS`,
    ACTIVATE_CHECK_OUT_FAILURE: `${STATE_REDUCER_KEY}/ACTIVATE_CHECK_OUT_FAILURE`
};
export const saveScheduleDetails = createAction(ACTION_TYPES.SAVE_SCHEDULE_FORM);
export const listScheduleForm = createAction(ACTION_TYPES.LIST_SCHEDULE_DETAILS);
export const fetchCourseId = createAction(ACTION_TYPES.FETCH_COURSE_ID);
export const fetchSessionId = createAction(ACTION_TYPES.FETCH_SESSION_ID);
export const fetchSessionType = createAction(ACTION_TYPES.FETCH_SESSION_TYPE);
export const fetchTrainingOrganization = createAction(ACTION_TYPES.FETCH_TRAINING_ORGANIZATION);
export const fetchBatchId = createAction(ACTION_TYPES.FETCH_BATCH_ID);
export const fetchTrainerId = createAction(ACTION_TYPES.FETCH_TRAINER_ID);
export const deleteScheduleForm = createAction(ACTION_TYPES.DELETE_SCHEDULE_FORM);
export const editScheduleForm = createAction(ACTION_TYPES.EDIT_SCHEDULE_FORM);
export const editScheduleById = createAction(ACTION_TYPES.EDIT_SCHEDULE_FORM_BY_ID);
export const fetchQuestionDropDown = createAction(ACTION_TYPES.FETCH_QUESTION_ID);
export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);
export const editBatchFormById = createAction(ACTION_TYPES.EDIT_BATCH_FORM_BY_ID);
export const confirmAttendance = createAction(ACTION_TYPES.CONFIRM_ATTENDANCE);
export const fetchParticipantDetails = createAction(ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS);
export const fetchFilter = createAction(ACTION_TYPES.FETCH_FILTER);
export const activateCheckIn = createAction(ACTION_TYPES.ACTIVATE_CHECK_IN);
export const activateCheckOut = createAction(ACTION_TYPES.ACTIVATE_CHECK_OUT);
export const filterScheduleBatch = createAction(ACTION_TYPES.FILTER_SCHEDULE_BATCH);

