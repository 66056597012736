export const API_URL = {
  USER_MANAGEMENT: {
    AUTH_TOKEN: "admin/api/no-auth/generate/token",
    USER_PROFILE: "admin/api/auth/idm/user/login",
    POST_OTP: "notification/api/no-auth/notification/otp/verify",
    GET_OTP: "notification/api/no-auth/notification/otp/send/:otp_type/:otp_process",
    RESET_PASSWORD: "admin/api/no-auth/idm/user/reset/password"
  },
  ROLE: {
    CREATE: "",
    EDIT: "",
    DELETE: "",
    GET: "",
    LIST: "",
    BY_ID: "",
    LEVELS: ""
  }
};
