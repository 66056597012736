import { I18n } from "common/components";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { getDraftPlanRemarksApi, saveDraftPlanRemarksApi } from "./api";
import { draftPlanGet } from "../draftPlan/api";

export function* submitDraftPlanRemarks({ payload }) {
    yield fork(handleAPIRequest, saveDraftPlanRemarksApi, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_DRAFT_REMARKS_SUCCESS, ACTION_TYPES.SUBMIT_DRAFT_REMARKS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_DRAFT_REMARKS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("gap_analysis") }) }));
    }
}

export function* listDraftPlan({ payload }) {
    let updatedPayload = { pageNo: payload?.pageNo, pageSize: payload?.pageSize, swmPlanId: payload?.id };
    yield fork(handleAPIRequest, getDraftPlanRemarksApi, updatedPayload);
    const responseAction = yield take([ACTION_TYPES.GET_DRAFT_REMARKS_LIST_SUCCESS, ACTION_TYPES.GET_DRAFT_REMARKS_LIST_FAILURE]);
    if (responseAction.type === ACTION_TYPES.GET_DRAFT_REMARKS_LIST_SUCCESS) {
        if (responseAction?.payload?.content?.length > 0) {
            yield call(handleAPIRequest, draftPlanGet, payload?.id);
        }
    }
}

export default function* registerSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_DRAFT_REMARKS, submitDraftPlanRemarks),
        takeLatest(ACTION_TYPES.GET_DRAFT_REMARKS_LIST, listDraftPlan)
    ]);
}
