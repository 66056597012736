import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    FETCH_BATCH_REPORT: `${STATE_REDUCER_KEY}/FETCH_BATCH_REPORT`,
    FETCH_BATCH_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BATCH_REPORT_REQUEST`,
    FETCH_BATCH_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BATCH_REPORT_SUCCESS`,
    FETCH_BATCH_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BATCH_REPORT_FAILURE`

};

export const listBatchReport = createAction(ACTION_TYPES.FETCH_BATCH_REPORT);
