import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_RECEIPT_LIST: `${STATE_REDUCER_KEY}/FETCH_RECEIPT_LIST`,
    FETCH_RECEIPT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_RECEIPT_LIST_REQUEST`,
    FETCH_RECEIPT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_RECEIPT_LIST_SUCCESS`,
    FETCH_RECEIPT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_RECEIPT_LIST_FAILURE`,

    POST_RECEIPT_DETAILS: `${STATE_REDUCER_KEY}/POST_RECEIPT_DETAILS`,
    POST_RECEIPT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_RECEIPT_DETAILS_REQUEST`,
    POST_RECEIPT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_RECEIPT_DETAILS_SUCCESS`,
    POST_RECEIPT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_RECEIPT_DETAILS_FAILURE`,

    PATCH_RECEIPT_DETAILS: `${STATE_REDUCER_KEY}/PATCH_RECEIPT_DETAILS`,
    PATCH_RECEIPT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_RECEIPT_DETAILS_REQUEST`,
    PATCH_RECEIPT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_RECEIPT_DETAILS_SUCCESS`,
    PATCH_RECEIPT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_RECEIPT_DETAILS_FAILURE`,

    GET_RECEIPT_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/GET_RECEIPT_DETAILS_BY_ID`,
    GET_RECEIPT_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_RECEIPT_DETAILS_BY_ID_REQUEST`,
    GET_RECEIPT_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_RECEIPT_DETAILS_BY_ID_SUCCESS`,
    GET_RECEIPT_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_RECEIPT_DETAILS_BY_ID_FAILURE`,

    DELETE_RECEIPT_DETAILS: `${STATE_REDUCER_KEY}/DELETE_RECEIPT_DETAILS`,
    DELETE_RECEIPT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_RECEIPT_DETAILS_REQUEST`,
    DELETE_RECEIPT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_RECEIPT_DETAILS_SUCCESS`,
    DELETE_RECEIPT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_RECEIPT_DETAILS_FAILURE`
};

export const getReceipt = createAction(ACTION_TYPES.FETCH_RECEIPT_LIST);
export const postReceipt = createAction(ACTION_TYPES.POST_RECEIPT_DETAILS);
export const patchReceipt = createAction(ACTION_TYPES.PATCH_RECEIPT_DETAILS);
export const getReceiptById = createAction(ACTION_TYPES.GET_RECEIPT_DETAILS_BY_ID);
export const deleteReceiptDetails = createAction(ACTION_TYPES.DELETE_RECEIPT_DETAILS);


