import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    reportFilter: {},
    complaintStatusReport: {
        requestInProgress: false,
        data: {
            TOTAL: 0,
            NEW: 0,
            CLOSED: 0,
            RESOLVED: 0
        }
    },
    complaintStatusDistrictReport: {
        requestInProgress: false,
        data: { datasets: [], labels: [] }
    },
    complaintStatusCategoryReport: {
        requestInProgress: false,
        data: { datasets: [], labels: [] }
    }
};

const slice = createSlice({
    initialState: initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_COMPLAINT_STATUS_REPORT_REQUEST, (state) => {
            state.complaintStatusReport.requestInProgress = true;
        }).addCase(ACTION_TYPES.FETCH_COMPLAINT_STATUS_REPORT_FAILURE, (state) => {
            state.complaintStatusReport.requestInProgress = false;
        }).addCase(ACTION_TYPES.FETCH_COMPLAINT_STATUS_REPORT_SUCCESS, (state, { payload }) => {
            _.set(state, "complaintStatusReport.requestInProgress", false);
            _.set(state, "complaintStatusReport.data", payload);
        })

            .addCase(ACTION_TYPES.FETCH_COMPLAINT_DISTRICT_REPORT_REQUEST, (state) => {
                state.complaintStatusDistrictReport.requestInProgress = true;
            }).addCase(ACTION_TYPES.FETCH_COMPLAINT_DISTRICT_REPORT_FAILURE, (state) => {
                state.complaintStatusDistrictReport.requestInProgress = false;
            }).addCase(ACTION_TYPES.FETCH_COMPLAINT_DISTRICT_REPORT_SUCCESS, (state, { payload }) => {
                _.set(state, "complaintStatusDistrictReport.requestInProgress", false);
                _.set(state, "complaintStatusDistrictReport.data", payload);
            })

            .addCase(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REPORT_REQUEST, (state) => {
                state.complaintStatusCategoryReport.requestInProgress = true;
            }).addCase(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REPORT_FAILURE, (state) => {
                state.complaintStatusCategoryReport.requestInProgress = false;
            }).addCase(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REPORT_SUCCESS, (state, { payload }) => {
                _.set(state, "complaintStatusCategoryReport.requestInProgress", false);
                _.set(state, "complaintStatusCategoryReport.data", payload);
            });
    }
});

export const { actions, reducer } = slice;
