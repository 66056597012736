export const API_URL = {
  COMMON: {
    SELECT_DISTRICT_DROPDOWN: "admin/api/auth/master/district/list/all",
    LSGI_TYPE_DROPDOWN: "admin/api/auth/master/lsgi-type/list/all",
    CONTACT_TYPE: "admin/api/auth/master/contact-type/list/all",
    LOOKUP_CATEGORY: "admin/api/auth/master/lookup-category/list/all",
    WARD: "admin/api/auth/master/ward/list/all"
  },
  STATE: {
    STATE_SUBMIT_DETAILS: "stateSubmit",
    STATE_GET_BY_ID: "getStateById",
    DELETE_STATE: "deleteState:id",
    LIST_STATE: "listState",
    UPDATE_STATE_DETAILS: "updateStateDetails"
  },
  ULB: {
    ULB_SUBMIT_DETAILS: "admin/api/auth/master/lsgi-details/save",
    ULB_GET_BY_ID: "admin/api/auth/master/lsgi-details/list/all",
    LIST_ULB: "admin/api/auth/master/lsgi-details/list/all",
    UPDATE_ULB_DETAILS: "admin/api/auth/master/ulb-details/save",
    BLOCK_PANCHAYATH: "admin/api/auth/master/block-panchayath/list/all",
    DISTRICT_PANCHAYATH: "admin/api/auth/master/district-panchayath/list/all",
    FILTER_LIST: "admin/api/auth/master/lsgi-details/filter-list"
  },
  OTHER_DEPARTMENTS: {
    SUBMIT_OTHER_DEPARTMENTS_DETAILS: "admin/api/auth/master/other-departments/save",
    GET_OTHER_DEPARTMENTS_BY_ID: "admin/api/auth/master/other-departments/list/:id",
    DELETE_OTHER_DEPARTMENTS_DETAILS: "admin/api/auth/master/other-departments/delete/:id",
    LIST_OTHER_DEPARTMENTS_DETAILS: "admin/api/auth/master/other-departments/list/all",
    UPDATE_OTHER_DEPARTMENTS_DETAILS: "admin/api/auth/master/other-departments/save",
    SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_DROPDOWN: "admin/api/auth/master/contact-type/list/all",
    SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_DROPDOWN: "admin/api/auth/master/lookup-category/list/all",
    FILTER_LIST: "admin/api/auth/master/other-departments/filter-list"

  },
  IEA_CONTACTS: {
    SUBMIT_IEA_CONTACTS: "admin/api/auth/master/iea/save",
    GET_IEA_CONTACTS_BY_ID: "admin/api/auth/master/iea/list/:id",
    DELETE_IEA_CONTACTS: "admin/api/auth/master/iea/delete/:id",
    LIST_IEA_CONTACTS: "admin/api/auth/master/iea/list/all",
    UPDATE_IEA_CONTACTS: "admin/api/auth/master/iea/save",
    SELECT_IEA_CONTACT_TYPE_DROPDOWN: "admin/api/auth/master/contact-type/list/all",
    SELECT_IEA_LOOKUP_CATEGORY_DROPDOWN: "admin/api/auth/master/lookup-category/list/all",
    FILTER_IEA: "admin/api/auth/master/iea/filter-list",
    FILTER_IEA_NAME: "admin/api/auth/master/iea/filter-list"
  },
  USER: {
    USER_DETAILS: "testURL"
  },
  LEGISLATIVE: {
    CREATE: "admin/api/auth/master/legislative-assembly-constituencies/save",
    EDIT: "admin/api/auth/master/legislative-assembly-constituencies/save",
    DELETE: "admin/api/auth/master/legislative-assembly-constituencies/delete/:id",
    GET: "admin/api/auth/master/legislative-assembly-constituencies ",
    LIST: "admin/api/auth/master/legislative-assembly-constituencies/list/all",
    BY_ID: "admin/api/auth/master/legislative-assembly-constituencies/list/all",
    FILTER_LIST: "admin/api/auth/master/legislative-assembly-constituencies/filter-list"
  },
  VENDOR_DETAILS: {
    VENDOR_CATEGORY_CREATE: "admin/api/auth/master/vendor-category/save",
    VENDOR_CATEGORY_TABLE_DATA: "admin/api/auth/master/vendor-category/list/all",
    VENDOR_CATEGORY_DELETE: "admin/api/auth/master/vendor-category/delete/:id",
    VENDOR_CATEGORY_EDIT: "admin/api/auth/master/vendor-category/save",
    VENDOR_CATEGORY_TABLE_DATA_BY_ID: "admin/api/auth/master/vendor-category/list/all",

    VENDOR_CONTACT_CREATE: "admin/api/auth/master/vendor/save",
    VENDOR_CONTACT_TABLE_DATA: "admin/api/auth/master/vendor/list/all",
    VENDOR_CONTACT_DELETE: "admin/api/auth/master/vendor/delete/:id",
    VENDOR_CONTACT_EDIT: "admin/api/auth/master/vendor/save",
    VENDOR_CONTACT_TABLE_DATA_BY_ID: "admin/api/auth/master/vendor/list/:id",
    VENDOR_CONTACT_CATEGORY_DROPDOWN: "admin/api/auth/master/vendor-category/list/all",
    VENDOR_CONTACT_TYPE_DROPDOWN: "admin/api/auth/master/contact-type/list/all",
    LOOKUP_CATEGORY_ID_TYPE_DROPDOWN: "admin/api/auth/master/lookup-category/list/all",
    LIST_VENDOR_CONTACT_NAME: "admin/api/auth/master/vendor/filter-list",
    LIST_VENDOR_CONTACT_GST: "admin/api/auth/master/vendor/filter-list",
    FILTER_CATEGORY_NAME: "admin/api/auth/master/vendor-category/filter-list"
  },
  LOOKUP: {
    LOOKUP_CATEGORY_ID_TYPE_DROPDOWN: "admin/api/auth/master/lookup-category/list/all",
    LOOKUP_FORM_LIST: "admin/api/auth/master/lookup/list/all",
    LOOKUP_FORM_CATEGORY_ID: "admin/api/auth/master/lookup-category/list/all",
    LOOKUP_FORM_BY_ID: "admin/api/auth/master/lookup/list/all",
    CREATE_LOOKUP_FORM: "admin/api/auth/master/lookup/save",
    EDIT_LOOKUP_FORM: "admin/api/auth/master/lookup/save",
    DELETE_LOOKUP_FORM: "admin/api/auth/master/lookup/delete/:id",

    LOOKUP_CATEGORY_LIST: "admin/api/auth/master/lookup-category/list/all",
    LOOKUP_CATEGORY_BY_ID: "admin/api/auth/master/lookup-category/list/all",
    CREATE_LOOKUP_CATEGORY: "admin/api/auth/master/lookup-category/save",
    EDIT_LOOKUP_CATEGORY: "admin/api/auth/master/lookup-category/save",
    DELETE_LOOKUP_CATEGORY: "admin/api/auth/master/lookup-category/delete/:id",
    FILTER_LIST: "admin/api/auth/master/lookup-category/filter-list",
    LOOK_UP_VALUE_FILTER_LIST: "admin/api/auth/master/lookup/filter-list"

  },
  LOKSABHA: {
    CREATE: "admin/api/auth/master/loksabha-assembly-constituencies/save",
    EDIT: "admin/api/auth/master/loksabha-assembly-constituencies/save",
    DELETE: "admin/api/auth/master/loksabha-assembly-constituencies/delete/:id",
    GET: "admin/api/auth/master/loksabha-assembly-constituencies",
    LIST: "admin/api/auth/master/loksabha-assembly-constituencies/list/all",
    BY_ID: "admin/api/auth/master/loksabha-assembly-constituencies/list/all",
    FILTER_LIST: "admin/api/auth/master/loksabha-assembly-constituencies/filter-list"
  },
  SHO_NGO_TOM: {
    LIST_SHO_NGO: "admin/api/auth/master/sho-ngo/list/all",
    SUBMIT_SHO_NGO: "admin/api/auth/master/sho-ngo/save",
    DELETE_SHO_NGO_FORM: "admin/api/auth/master/sho-ngo/delete/:id",
    UPDATE_SHO_NGO_FORM: "admin/api/auth/master/sho-ngo/save",
    SHO_NGO_BY_ID: "admin/api/auth/master/sho-ngo/list/:id",
    SUBMIT_TRAINING_ORGANIZATION: "admin/api/auth/master/training-organization/save",
    LIST_TRAINING_ORGANIZATION: "admin/api/auth/master/training-organization/list/all",
    UPDATE_TRAINING_ORGANIZATION: "admin/api/auth/master/training-organization/save",
    DELETE_TRAINING_ORGANIZATION_FORM: "admin/api/auth/master/training-organization/delete/:id",
    TRAINING_ORGANIZATION_BY_ID: "admin/api/auth/master/training-organization/list/:id",
    FILTER_SHO_NGO_NAME: "admin/api/auth/master/sho-ngo/filter-list",
    FILTER_LIST: "admin/api/auth/master/training-organization/filter-list"
  },
  WARD: {
    CREATE: "admin/api/auth/master/ward/save",
    EDIT: "admin/api/auth/master/ward/save",
    DELETE: "admin/api/auth/master/ward/delete/:id",
    GET: "admin/api/auth/master/ward",
    LIST: "admin/api/auth/master/ward/list/all",
    BY_ID: "admin/api/auth/master/ward/list/all",
    FILTER_LIST: "admin/api/auth/master/ward/filter-list"
  },
  DISTRICT: {
    CREATE_DISTRICT: "admin/api/auth/master/district/save",
    EDIT_DISTRICT: "admin/api/auth/master/district/save",
    DELETE_DISTRICT: "admin/api/auth/master/district/delete/:id",
    GET_DISTRICT: "admin/api/auth/master/district",
    LIST_DISTRICT: "admin/api/auth/master/district/list/all",
    STATE_LIST: "",
    FILTER_LIST: "admin/api/auth/master/district/filter-list"
  },
  USER_ROLE_MAPPING: {
    CREATE: "admin/api/auth/idm/user/role/assign",
    TABLE_DATA_ASSIGN: "admin/api/auth/idm/user/role/list/all",
    UNASSIGN: "admin/api/auth/idm/user/role/delete",
    TABLE_DATA_UNASSIGN: "admin/api/auth/idm/user/role/list/all",
    TABLE_LIST_BY_ID: "admin/api/auth/idm/user/role/list/all",
    FILTER: "admin/api/auth/idm/user/role/list/all"
  },
  USER_ROLE_DATA_ACCESS: {
    TABLE_LIST: "admin/api/auth/idm/user/data-access-mapping/list/all",
    COUNTRY_DROPDOWN: "admin/api/auth/master/country/list/all",
    DISTRICT_DROPDOWN: "admin/api/auth/master/district/list/all",
    LSGI_DROPDOWN: "admin/api/auth/master/lsgi-details/list/all",
    LSGI_TYPE_DROPDOWN: "admin/api/auth/master/lsgi-type/list/all",
    WARD_DROPDOWN: "admin/api/auth/master/ward/list/all",
    STATE_DROPDOWN: "admin/api/auth/master/state/list/all",
    TRAINING_ORGANIZATION_DROPDOWN: "admin/api/auth/master/training-organization/list/all",
    VENDOR_DROPDOWN: "admin/api/auth/master/vendor/list/all",
    SUBMIT_DATA_ACCESS: "admin/api/auth/idm/user/data-access-mapping/save",
    ROLE_LEVEL_DROPDOWN: "admin/api/auth/idm/role-level/list/all",
    DELETE_DATA_ACCESS: "admin/api/auth/idm/user/data-access-mapping/delete/target-reference",
    EDIT_DATA_ACCESS: "admin/api/auth/idm/user/data-access-mapping/save",
    IVA_DROPDOWN: "admin/api/auth/master/iea/list/all"
  },
  RESOURCE_MANAGEMENT: {
    LIST_RESOURCE_MANAGEMENT: "admin/api/auth/idm/resources/list/all",
    SUBMIT_RESOURCE_MANAGEMENT: "admin/api/auth/idm/resources/save",
    DELETE_RESOURCE_MANAGEMENT: "admin/api/auth/idm/resources/delete",
    UPDATE_RESOURCE_MANAGEMENT: "admin/api/auth/idm/resources/save",
    RESOURCE_MANAGEMENT_BY_ID: "admin/api/auth/idm/resources/list/all",
    FETCH_MODULE: "admin/api/auth/idm/application-modules/list/all",
    ROLE_RESOURCE: "admin/api/auth/idm/resources/list/all/menu",
    FETCH_PARENT: "admin/api/auth/idm/resources/list/all",
    FILTER_NAME: "admin/api/auth/idm/resources/filter-list",
    ROLES_CREATE: "admin/api/auth/idm/roles-resource-mapping/assign",
    ROLE_CHECKED: "admin/api/auth/idm/roles-resource-mapping/list/all",
    DOWNLOAD_ROLES: "report/api/auth/reports/roles/list"
  },

  ROLE: {
    CREATE: "admin/api/auth/idm/roles/save",
    EDIT: "admin/api/auth/idm/roles/save",
    DELETE: "admin/api/auth/idm/roles/delete/:id",
    GET: "",
    LIST: "admin/api/auth/idm/roles/list/all",
    BY_ID: "admin/api/auth/idm/roles/list/all",
    LEVELS: "admin/api/auth/idm/role-level/list/all?dropDown=true",
    FILTER_ROLE_NAME: "admin/api/auth/idm/roles/filter-list",
    FILTER_LIST: "admin/api/auth/idm/roles/filter-list"
  },
  DESIGNATION_ENTRY: {
    LIST_DESIGNATION_ENTRY: "admin/api/auth/master/designation/list/all",
    SUBMIT_DESIGNATION_ENTRY: "admin/api/auth/master/designation/save",
    DELETE_DESIGNATION_ENTRY: "admin/api/auth/master/designation/delete/:id",
    UPDATE_DESIGNATION_ENTRY: "admin/api/auth/master/designation/save",
    DESIGNATION_ENTRY_BY_ID: "admin/api/auth/master/designation/list/all",
    FILTER_LIST: "admin/api/auth/master/designation/filter-list",
    DOWNLOAD_DESIGNATION: "report/api/auth/reports/designation/list"

  },
  COMPLAINT: {
    CATEGORY_FILTER: "grm/api/auth/grm/complaint-categories/filter-list",
    COMPLAINT_CATEGORY: "grm/api/auth/grm/complaint-categories/list/all",
    SUBMIT_COMPLAINT_CATEGORY: "grm/api/auth/grm/complaint-categories/save",
    COMPLAINT_CATEGORY_LIST: "grm/api/auth/grm/complaint-categories/list/all",
    COMPLAINT_CATEGORY_DELETE: "grm/api/auth/grm/complaint-categories/delete/:id",
    COMPLAINT_CATEGORY_EDIT: "grm/api/auth/grm/complaint-categories/save",
    COMPLAINT_CATEGORY_BY_ID: "grm/api/auth/grm/complaint-categories/list/all",

    STATUS_SUBMIT: "grm/api/auth/grm/complaint-status/save",
    STATUS_LIST: "grm/api/auth/grm/complaint-status/list/all",
    STATUS_DELETE: "grm/api/auth/grm/complaint-status/delete/:id",
    STATUS_EDIT: "grm/api/auth/grm/complaint-status/save",
    STATUS_BY_ID: "grm/api/auth/grm/complaint-status/list/all",

    SUBMIT_TYPE: "grm/api/auth/grm/complaint-type/save",
    FILTER_TYPE: "grm/api/auth/grm/complaint-type/filter-list",
    COMPLAINT_TYPE_LIST: "grm/api/auth/grm/complaint-type/list/all",
    COMPLAINT_TYPE_DELETE: "grm/api/auth/grm/complaint-type/delete/:id",
    COMPLAINT_TYPE_EDIT: "grm/api/auth/grm/complaint-type/save",
    COMPLAINT_TYPE_BY_ID: "grm/api/auth/grm/complaint-type/list/all"
  },
  COMPLAINT_ESCALATION: {
    USER_LIST: "admin/api/auth/idm/user/list/all",
    ESCALATION_UNIT: "grm/api/auth/grm/escalation-unit/list/all",
    TARGET_ROLE_LEVEL: "admin/api/auth/idm/role-level/list/all",
    SUBMIT_ESCALATION: "grm/api/auth/grm/complaints/escalation-matrix/save",
    GET_ESCALATION_BY_ID: "grm/api/auth/grm/complaints/escalation-matrix/list/all",
    DELETE_ESCALATION_BY_ID: "grm/api/auth/grm/complaint-escalation-matrix/delete/:id"
  },
  WARD_ESCALATION: {
    USER_LIST: "admin/api/auth/idm/user/data-access-mapping/list/all",
    SUBMIT_ESCALATION: "grm/api/auth/grm/complaints/ward-escalation-matrix/save",
    ESCALATION_UNIT: "grm/api/auth/grm/escalation-unit/list/all",
    TARGET_ROLE_LEVEL: "admin/api/auth/idm/role-level/list/all",
    GET_ESCALATION_BY_ID: "grm/api/auth/grm/complaints/ward-escalation-matrix/list/all",
    DELETE_ESCALATION_BY_ID: "grm/api/auth/grm/complaints/ward-escalation-matrix/delete/:id"
  }

};
