import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_DISTRICT_LIST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST`,
    FETCH_DISTRICT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_REQUEST`,
    FETCH_DISTRICT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_SUCCESS`,
    FETCH_DISTRICT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_FAILURE`,

    FETCH_ULB_DETAILS: `${STATE_REDUCER_KEY}/FETCH_ULB_DETAILS`,
    FETCH_ULB_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ULB_DETAILS_REQUEST`,
    FETCH_ULB_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ULB_DETAILS_SUCCESS`,
    FETCH_ULB_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ULB_DETAILS_FAILURE`,

    FETCH_TOTAL_FUND: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND`,
    FETCH_TOTAL_FUND_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_REQUEST`,
    FETCH_TOTAL_FUND_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_SUCCESS`,
    FETCH_TOTAL_FUND_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_FAILURE`,

    UPDATE_TOTAL_FUND: `${STATE_REDUCER_KEY}/UPDATE_TOTAL_FUND`,
    UPDATE_TOTAL_FUND_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_TOTAL_FUND_REQUEST`,
    UPDATE_TOTAL_FUND_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_TOTAL_FUND_SUCCESS`,
    UPDATE_TOTAL_FUND_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_TOTAL_FUND_FAILURE`,

    SUBMIT_ULB_POPULATION: `${STATE_REDUCER_KEY}/SUBMIT_ULB_POPULATION`,
    SUBMIT_ULB_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_ULB_POPULATION_REQUEST`,
    SUBMIT_ULB_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_ULB_POPULATION_SUCCESS`,
    SUBMIT_ULB_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_ULB_POPULATION_FAILURE`,

    FETCH_ULB_POPULATION: `${STATE_REDUCER_KEY}/FETCH_ULB_POPULATION`,
    FETCH_ULB_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ULB_POPULATION_REQUEST`,
    FETCH_ULB_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ULB_POPULATION_SUCCESS`,
    FETCH_ULB_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ULB_POPULATION_FAILURE`,

    FETCH_ULB_POPULATION_BY_ID: `${STATE_REDUCER_KEY}/FETCH_ULB_POPULATION_BY_ID`,
    FETCH_ULB_POPULATION_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ULB_POPULATION_BY_ID_REQUEST`,
    FETCH_ULB_POPULATION_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ULB_POPULATION_BY_ID_SUCCESS`,
    FETCH_ULB_POPULATION_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ULB_POPULATION_BY_ID_FAILURE`,

    UPDATE_ULB_POPULATION: `${STATE_REDUCER_KEY}/UPDATE_ULB_POPULATION`,
    UPDATE_ULB_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_ULB_POPULATION_REQUEST`,
    UPDATE_ULB_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_ULB_POPULATION_SUCCESS`,
    UPDATE_ULB_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_ULB_POPULATION_FAILURE`,

    DELETE_ULB_POPULATION: `${STATE_REDUCER_KEY}/DELETE_ULB_POPULATION`,
    DELETE_ULB_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/DELETE_ULB_POPULATION_REQUEST`,
    DELETE_ULB_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_ULB_POPULATION_SUCCESS`,
    DELETE_ULB_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/DELETE_ULB_POPULATION_FAILURE`,

    COMPUTE_GAC_CEILING: `${STATE_REDUCER_KEY}/COMPUTE_GAC_CEILING`,
    COMPUTE_GAC_CEILING_REQUEST: `${STATE_REDUCER_KEY}/COMPUTE_GAC_CEILING_REQUEST`,
    COMPUTE_GAC_CEILING_SUCCESS: `${STATE_REDUCER_KEY}/COMPUTE_GAC_CEILING_SUCCESS`,
    COMPUTE_GAC_CEILING_FAILURE: `${STATE_REDUCER_KEY}/COMPUTE_GAC_CEILING_FAILURE`,

    FETCH_GAC_COMPUTATION: `${STATE_REDUCER_KEY}/FETCH_GAC_COMPUTATION`,
    FETCH_GAC_COMPUTATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GAC_COMPUTATION_REQUEST`,
    FETCH_GAC_COMPUTATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GAC_COMPUTATION_SUCCESS`,
    FETCH_GAC_COMPUTATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GAC_COMPUTATION_FAILURE`,

    FETCH_ULB_SEARCH: `${STATE_REDUCER_KEY}/FETCH_ULB_SEARCH`,
    FETCH_ULB_SEARCH_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ULB_SEARCH_REQUEST`,
    FETCH_ULB_SEARCH_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ULB_SEARCH_SUCCESS`,
    FETCH_ULB_SEARCH_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ULB_SEARCH_FAILURE`,

    FETCH_CENSUS_YEAR: `${STATE_REDUCER_KEY}/FETCH_CENSUS_YEAR`,
    FETCH_CENSUS_YEAR_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CENSUS_YEAR_REQUEST`,
    FETCH_CENSUS_YEAR_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CENSUS_YEAR_SUCCESS`,
    FETCH_CENSUS_YEAR_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CENSUS_YEAR_FAILURE`,

    FETCH_TOTAL_POPULATION: `${STATE_REDUCER_KEY}/FETCH_TOTAL_POPULATION`,
    FETCH_TOTAL_POPULATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOTAL_POPULATION_REQUEST`,
    FETCH_TOTAL_POPULATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_POPULATION_SUCCESS`,
    FETCH_TOTAL_POPULATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOTAL_POPULATION_FAILURE`,

    FETCH_TOTAL_FUND_BY_ID: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_BY_ID`,
    FETCH_TOTAL_FUND_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_BY_ID_REQUEST`,
    FETCH_TOTAL_FUND_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_BY_ID_SUCCESS`,
    FETCH_TOTAL_FUND_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_BY_ID_FAILURE`,

    FETCH_TOTAL_FUND_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_DROPDOWN`,
    FETCH_TOTAL_FUND_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_DROPDOWN_REQUEST`,
    FETCH_TOTAL_FUND_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_DROPDOWN_SUCCESS`,
    FETCH_TOTAL_FUND_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_DROPDOWN_FAILURE`

};

export const getDistricts = createAction(ACTION_TYPES.FETCH_DISTRICT_LIST);
export const getULB = createAction(ACTION_TYPES.FETCH_ULB_DETAILS);
export const getULBPopulation = createAction(ACTION_TYPES.FETCH_ULB_POPULATION);
export const addULBPopulation = createAction(ACTION_TYPES.SUBMIT_ULB_POPULATION);
export const editULBPopulation = createAction(ACTION_TYPES.UPDATE_ULB_POPULATION);
export const deleteULBPopulation = createAction(ACTION_TYPES.DELETE_ULB_POPULATION);
export const getULBPopulationById = createAction(ACTION_TYPES.FETCH_ULB_POPULATION_BY_ID);
export const computeGAC = createAction(ACTION_TYPES.COMPUTE_GAC_CEILING);
export const getGACComputation = createAction(ACTION_TYPES.FETCH_GAC_COMPUTATION);
export const filterSearchUlb = createAction(ACTION_TYPES.FETCH_ULB_SEARCH);
export const getCensusYear = createAction(ACTION_TYPES.FETCH_CENSUS_YEAR);
export const getTotalFund = createAction(ACTION_TYPES.FETCH_TOTAL_FUND);
export const updateTotalFund = createAction(ACTION_TYPES.UPDATE_TOTAL_FUND);
export const getTotalPopulation = createAction(ACTION_TYPES.FETCH_TOTAL_POPULATION);
export const getTotalFundById = createAction(ACTION_TYPES.FETCH_TOTAL_FUND_BY_ID);
export const fetchTotalFundDropdown = createAction(ACTION_TYPES.FETCH_TOTAL_FUND_DROPDOWN);
