
import { API_URL as GRM_API_URL } from "modules/grievance/apiUrls";

export const API_URL = {
    ...GRM_API_URL,
    COMPLAINT_REPORT: {
        DOWNLOAD_REPORTS: "report/api/auth/reports/complaints/list",
        PRIORITY: "admin/api/auth/master/priority/list/all",
        DOWNLOAD_SUMMARY_REPORTS: "report/api/auth/reports/complaints/districts-wise/summary",
        DOWNLOAD_LISTING_REPORTS: "report/api/auth/reports/complaints/detailed/list",
        DOWNLOAD_SUMMARY_LSGI_REPORTS: "report/api/auth/reports/complaints/lsgi-wise/summary",
        DOWNLOAD_SUMMARY_WARD_REPORTS: "report/api/auth/reports/complaints/ward-wise/summary",
        DOWNLOAD_COMPLAINT_LIST: "report/api/auth/reports/complaints/list",
        DOWNLOAD_ESCALATION_REPORT: "report/api/auth/reports/complaints/escalation/list",
        DOWNLOAD_PENDING_REPORT: "report/api/auth/reports/complaints/pending/list",
        DOWNLOAD_REOPENED_REPORT: "report/api/auth/reports/complaints/reopened/list",
        DOWNLOAD_TOP_GRIEVANCE_CATEGORY: "report/api/auth/reports/complaints/category-wise/list"

    }
};


