import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";
import { getPayloadContent } from "utils/apiUtils";

const initialState = {
    lsgiSelectDropdownOption: {
        requestInProgress: false,
        data: []
    },
    districtDropdown: {
        requestInProgress: false,
        data: []
    },
    swmPlanDropdownOption: {
        requestInProgress: false,
        data: []
    },
    fetchFiveYearPlanListById: {
        requestInProgress: false,
        data: {
            districtId: "",
            swmPlan: "",
            lsgiId: "",
            planCode: "",
            planName: "",
            remarks: "",
            fromYear: "",
            toYear: "",
            assignedProjects: [],
            cruxList: [],
            attachmentIds: [],
            totalGrantceiled: ""
        }
    },
    approveMunicipalById: {
        requestInProgress: false,
        data: {
            mcResolutionNumber: "",
            mcResolutionDate: "",
            mcRemarks: ""
        }
    },
    approveDpmuById: {
        requestInProgress: false,
        data: {
            dpmuRemarks: ""
        }
    },
    approveSpmuById: {
        requestInProgress: false,
        data: {
            spmuRemarks: ""
        }
    },
    subProjectListDetails: {
        requestInProgress: false,
        data: {}
    },
    fiveYearProjectNewDetails: {
        requestInProgress: false,
        data: {}
    },
    planStatusList: {
        requestInProgress: false,
        data: []
    },
    fiveYearList: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clear: () => initialState,
        clearValue: (state) => {
            state.fetchFiveYearPlanListById.data = initialState.fetchFiveYearPlanListById.data;
        },
        clearStatus: (state) => {
            state.planStatusList.data = initialState.planStatusList.data;
        },
        clearModal: (state) => {
            state.approveDpmuById.data = initialState.approveDpmuById.data;
            state.approveSpmuById.data = initialState.approveSpmuById.data;
            state.approveMunicipalById.data = initialState.approveMunicipalById.data;
        },
        clearDistrict: (state) => {
            state.districtDropdown.data = initialState.districtDropdown.data;
        },
        clearLsgi: (state) => {
            state.lsgiSelectDropdownOption.data = initialState.lsgiSelectDropdownOption.data;
        },
        setFetchFiveYearPlanListById: (state, action) => {
            state.fetchFiveYearPlanListById.data = action.payload;
        },
        resetFetchFiveYearPlanListById: (state) => {
            state.fetchFiveYearPlanListById.data = initialState.fetchFiveYearPlanListById.data;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "districtDropdown.requestInProgress", false);
                _.set(state, "districtDropdown.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST, (state) => {
                state.lsgiSelectDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_SWM_PLAN_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "swmPlanDropdownOption.requestInProgress", false);
                _.set(state, "swmPlanDropdownOption.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, (state, action) => {
                state.lsgiSelectDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE, (state) => {
                state.lsgiSelectDropdownOption.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_REQUEST, (state) => {
                state.fetchFiveYearPlanListById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "fetchFiveYearPlanListById.data", payload);
                _.set(state, "fetchFiveYearPlanListById.data.assignedProjects", payload?.assignedProjects);
                _.set(state, "fetchFiveYearPlanListById.data.cruxList", payload?.cruxList);
                _.set(state, "fetchFiveYearPlanListById.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_FAILURE, (state) => {
                state.fetchFiveYearPlanListById.requestInProgress = false;
                state.error = "Something went wrong";
            })
            .addCase(ACTION_TYPES.FETCH_SUB_PROJECT_LIST_SUCCESS, (state, action) => {
                _.set(state, "subProjectListDetails.requestInProgress", false);
                _.set(state, "subProjectListDetails.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.FETCH_FIVE_YEAR_LIST_SUCCESS, (state, action) => {
                _.set(state, "fiveYearProjectNewDetails.requestInProgress", false);
                _.set(state, "fiveYearProjectNewDetails.data", getPayloadContent(action));
            }).addCase(ACTION_TYPES.FETCH_STATUS_LIST_SUCCESS, (state, action) => {
                _.set(state, "planStatusList.requestInProgress", false);
                _.set(state, "planStatusList.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.CEILED_AMOUNT_SUCCESS, (state, { payload }) => {
                _.set(state, "fetchFiveYearPlanListById.requestInProgress", false);
                _.set(state, "fetchFiveYearPlanListById.data.totalGrantceiled", payload?.content[0]?.totalGrantceiled);
            });
    }
});
export const { actions, reducer } = slice;
