import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    SUBMIT_USER_ROLE_MAPPING_DETAILS: `${STATE_REDUCER_KEY}/SUBMIT_USER_ROLE_MAPPING_DETAILS`,
    SUBMIT_USER_ROLE_MAPPING_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_USER_ROLE_MAPPING_DETAILS_REQUEST`,
    SUBMIT_USER_ROLE_MAPPING_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_USER_ROLE_MAPPING_DETAILS_SUCCESS`,
    SUBMIT_USER_ROLE_MAPPING_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_USER_ROLE_MAPPING_DETAILS_FAILURE`,

    FETCH_USER_ROLE_MAPPING_DETAILS: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_MAPPING_DETAILS`,
    FETCH_USER_ROLE_MAPPING_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_MAPPING_DETAILS_REQUEST`,
    FETCH_USER_ROLE_MAPPING_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_MAPPING_DETAILS_SUCCESS`,
    FETCH_USER_ROLE_MAPPING_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_MAPPING_DETAILS_FAILURE`,

    EDIT_USER_ROLE_MAPPING_DETAILS: `${STATE_REDUCER_KEY}/EDIT_USER_ROLE_MAPPING_DETAILS`,
    EDIT_USER_ROLE_MAPPING_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/EDIT_USER_ROLE_MAPPING_DETAILS_REQUEST`,
    EDIT_USER_ROLE_MAPPING_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_USER_ROLE_MAPPING_DETAILS_SUCCESS`,
    EDIT_USER_ROLE_MAPPING_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/EDIT_USER_ROLE_MAPPING_DETAILS_FAILURE`,

    FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID`,
    FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID_REQUEST`,
    FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID_SUCCESS`,
    FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID_FAILURE`,

    SET_USER_ID: `${STATE_REDUCER_KEY}/SET_USER_ID`,
    SET_USER_ID_REQUEST: `${STATE_REDUCER_KEY}/SET_USER_ID_REQUEST`,
    SET_USER_ID_SUCCESS: `${STATE_REDUCER_KEY}/SET_USER_ID_SUCCESS`,
    SET_USER_ID_FAILURE: `${STATE_REDUCER_KEY}/SET_USER_ID_FAILURE`,

    FETCH_USER_ROLE_DATA_ACCESS_DETAILS: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_DATA_ACCESS_DETAILS`,
    FETCH_USER_ROLE_DATA_ACCESS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_DATA_ACCESS_DETAILS_REQUEST`,
    FETCH_USER_ROLE_DATA_ACCESS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_DATA_ACCESS_DETAILS_SUCCESS`,
    FETCH_USER_ROLE_DATA_ACCESS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_DATA_ACCESS_DETAILS_FAILURE`,

    SELECT_DISTRICT_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_DISTRICT_DROPDOWN`,
    SELECT_DISTRICT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_DISTRICT_DROPDOWN_REQUEST`,
    SELECT_DISTRICT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_DISTRICT_DROPDOWN_SUCCESS`,
    SELECT_DISTRICT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_DISTRICT_DROPDOWN_FAILURE`,

    SELECT_LSGI_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_LSGI_DROPDOWN`,
    SELECT_LSGI_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_LSGI_DROPDOWN_REQUEST`,
    SELECT_LSGI_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_LSGI_DROPDOWN_SUCCESS`,
    SELECT_LSGI_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_LSGI_DROPDOWN_FAILURE`,

    SELECT_WARD_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_WARD_DROPDOWN`,
    SELECT_WARD_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_WARD_DROPDOWN_REQUEST`,
    SELECT_WARD_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_WARD_DROPDOWN_SUCCESS`,
    SELECT_WARD_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_WARD_DROPDOWN_FAILURE`,

    SELECT_STATE_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_STATE_DROPDOWN`,
    SELECT_STATE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_STATE_DROPDOWN_REQUEST`,
    SELECT_STATE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_STATE_DROPDOWN_SUCCESS`,
    SELECT_STATE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_STATE_DROPDOWN_FAILURE`,

    SELECT_TRAINING_ORGANIZATION_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_TRAINING_ORGANIZATION_DROPDOWN`,
    SELECT_TRAINING_ORGANIZATION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_TRAINING_ORGANIZATION_DROPDOWN_REQUEST`,
    SELECT_TRAINING_ORGANIZATION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_TRAINING_ORGANIZATION_DROPDOWN_SUCCESS`,
    SELECT_TRAINING_ORGANIZATION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_TRAINING_ORGANIZATION_DROPDOWN_FAILURE`,

    SELECT_VENDOR_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_VENDOR_DROPDOWN`,
    SELECT_VENDOR_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_VENDOR_DROPDOWN_REQUEST`,
    SELECT_VENDOR_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_VENDOR_DROPDOWN_SUCCESS`,
    SELECT_VENDOR_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_VENDOR_DROPDOWN_FAILURE`,

    SELECT_ROLE_LEVEL_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_ROLE_LEVEL_DROPDOWN`,
    SELECT_ROLE_LEVEL_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_ROLE_LEVEL_DROPDOWN_REQUEST`,
    SELECT_ROLE_LEVEL_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_ROLE_LEVEL_DROPDOWN_SUCCESS`,
    SELECT_ROLE_LEVEL_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_ROLE_LEVEL_DROPDOWN_FAILURE`,

    SELECT_LSGI_TYPE_DROPDOWN: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN`,
    SELECT_LSGI_TYPE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_REQUEST`,
    SELECT_LSGI_TYPE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_SUCCESS`,
    SELECT_LSGI_TYPE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_DROPDOWN_FAILURE`,

    SUBMIT_ROLE_DATA_ACCESS: `${STATE_REDUCER_KEY}/SUBMIT_ROLE_DATA_ACCESS`,
    SUBMIT_ROLE_DATA_ACCESS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_ROLE_DATA_ACCESS_REQUEST`,
    SUBMIT_ROLE_DATA_ACCESS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_ROLE_DATA_ACCESS_SUCCESS`,
    SUBMIT_ROLE_DATA_ACCESS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_ROLE_DATA_ACCESS_FAILURE`,

    DELETE_DATA_ACCESS_DETAILS: `${STATE_REDUCER_KEY}/DELETE_DATA_ACCESS_DETAILS`,
    DELETE_DATA_ACCESS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_DATA_ACCESS_DETAILS_REQUEST`,
    DELETE_DATA_ACCESS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_DATA_ACCESS_DETAILS_SUCCESS`,
    DELETE_DATA_ACCESS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_DATA_ACCESS_DETAILS_FAILURE`,

    FETCH_USER_ROLE_BY_ID_DETAILS: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_BY_ID_DETAILS`,
    FETCH_USER_ROLE_BY_ID_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_BY_ID_DETAILS_REQUEST`,
    FETCH_USER_ROLE_BY_ID_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_BY_ID_DETAILS_SUCCESS`,
    FETCH_USER_ROLE_BY_ID_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_BY_ID_DETAILS_FAILURE`,

    EDIT_DATA_ACCESS_DETAILS: `${STATE_REDUCER_KEY}/EDIT_DATA_ACCESS_DETAILS`,
    EDIT_DATA_ACCESS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/EDIT_DATA_ACCESS_DETAILS_REQUEST`,
    EDIT_DATA_ACCESS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_DATA_ACCESS_DETAILS_SUCCESS`,
    EDIT_DATA_ACCESS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/EDIT_DATA_ACCESS_DETAILS_FAILURE`,

    FILTER_ROLE_MAPPING: `${STATE_REDUCER_KEY}/FILTER_ROLE_MAPPING`,
    FILTER_ROLE_MAPPING_REQUEST: `${STATE_REDUCER_KEY}/FILTER_ROLE_MAPPING_REQUEST`,
    FILTER_ROLE_MAPPING_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_ROLE_MAPPING_SUCCESS`,
    FILTER_ROLE_MAPPING_FAILURE: `${STATE_REDUCER_KEY}/FILTER_ROLE_MAPPING_FAILURE`,

    IVA_DROPDOWN: `${STATE_REDUCER_KEY}/IVA_DROPDOWN`,
    IVA_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/IVA_DROPDOWN_REQUEST`,
    IVA_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/IVA_DROPDOWN_SUCCESS`,
    IVA_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/IVA_DROPDOWN_FAILURE`
};

export const submitUserRoleMappingDetails = createAction(ACTION_TYPES.SUBMIT_USER_ROLE_MAPPING_DETAILS);
export const editUserRoleMappingList = createAction(ACTION_TYPES.EDIT_USER_ROLE_MAPPING_DETAILS);
export const fetchMappedUserRoleDetails = createAction(ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_BY_ID);
export const setUserId = createAction(ACTION_TYPES.SET_USER_ID);
export const fetchUserRoles = createAction(ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS);
export const fetchUserRolesDataAccess = createAction(ACTION_TYPES.FETCH_USER_ROLE_DATA_ACCESS_DETAILS);
export const selectDistrictDropdown = createAction(ACTION_TYPES.SELECT_DISTRICT_DROPDOWN);
export const selectLsgiDropdown = createAction(ACTION_TYPES.SELECT_LSGI_DROPDOWN);
export const selectWardDropdown = createAction(ACTION_TYPES.SELECT_WARD_DROPDOWN);
export const selectTrainingOrganizationDropdown = createAction(ACTION_TYPES.SELECT_TRAINING_ORGANIZATION_DROPDOWN);
export const selectVendorDropdown = createAction(ACTION_TYPES.SELECT_VENDOR_DROPDOWN);
export const selectStateDropdown = createAction(ACTION_TYPES.SELECT_STATE_DROPDOWN);
export const submitRoleDataAccess = createAction(ACTION_TYPES.SUBMIT_ROLE_DATA_ACCESS);
export const roleLevelDataAccessDropdown = createAction(ACTION_TYPES.SELECT_ROLE_LEVEL_DROPDOWN);
export const deleteRoleDataAccess = createAction(ACTION_TYPES.DELETE_DATA_ACCESS_DETAILS);
export const fetchUserRolesById = createAction(ACTION_TYPES.FETCH_USER_ROLE_BY_ID_DETAILS);
export const lsgiTypeSelectDropdown = createAction(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN);
export const editDataAccessList = createAction(ACTION_TYPES.EDIT_DATA_ACCESS_DETAILS);
export const listFilterRoleMapping = createAction(ACTION_TYPES.FILTER_ROLE_MAPPING);
export const ivaDropdown = createAction(ACTION_TYPES.IVA_DROPDOWN);
