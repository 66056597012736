import { REACT_TABLE_COMMON_OPTIONS, ROWS_PER_PAGE, TABLE_IDS } from "common/constants";
import { useDispatch, useSelector } from "react-redux";
import { Components, I18n, Icons } from "../../../../common/components";
import { downloadPendingReport, listPendingReport } from "../actions";
import CommonTable from "modules/common/components/CommonTable";
import { STATE_REDUCER_KEY } from "modules/common/constants";
import { useMemo } from "react";
import { DATE_FORMAT, formatDate } from "utils/dateUtils";
import CustomListMenu from "common/components/custom/CustomListMenu";
import PendingReportFilter from "./PendingReportFilter";

const { ListAlt, PictureAsPdf, FilterAltRounded } = Icons;
const { Badge } = Components;
const PendingReport = () => {
    const dispatch = useDispatch();
    const tableData = useSelector(state => state[STATE_REDUCER_KEY]);
    const { table: { PENDING_REPORT: { filters = {} } = {} } = {} } = tableData;
    let customActions = [];
    customActions.push({
        title: I18n("download_excel"), icon: <ListAlt fontSize="small" />, handleClick: () => dispatch(downloadPendingReport({ type: "xls", filters: filters }))
    });
    customActions.push({ title: I18n("download_pdf"), icon: <PictureAsPdf fontSize="small" />, handleClick: () => dispatch(downloadPendingReport({ type: "pdf", filters: filters })) });
    const toolBarActions = [];
    toolBarActions.push({ key: "download", component: <CustomListMenu customActions={customActions} type="download" /> });

    if (toolBarActions) {
        toolBarActions.push({
            title: "Open Filter", icon: <Badge badgeContent={Object.keys(filters).length} color="primary"><FilterAltRounded fontSize="medium" /></Badge>, key: "customFilter"
        });
    }
    const options = {
        ...REACT_TABLE_COMMON_OPTIONS,
        enableRowSelection: false,
        enableFilters: true,
        toolBarActions: toolBarActions,
        customPagination: {
            rowsPerPageOptions: ROWS_PER_PAGE
        },
        enableRowActions: false
    };

    const columns = useMemo(
        () => [
            {
                id: "complainerName",
                header: I18n("complainer_name"),
                accessorKey: "complainer"
            },
            {
                id: "phoneNumber",
                header: I18n("phone_number"),
                accessorKey: "phone"
            },
            {
                id: "complaintNo",
                header: I18n("complaint_no"),
                accessorKey: "complaintNo"
            },
            {
                id: "complaintDate",
                header: I18n("date_of_complaint"),
                accessorFn: (row) => formatDate(row?.complaintDate, DATE_FORMAT)
            },
            {
                id: "complaintCategory",
                header: I18n("complaint_category"),
                accessorKey: "complaintCategory"
            },
            {
                id: "complaintType",
                header: I18n("complaint_type"),
                accessorKey: "complaintType"
            },
            {
                id: "complaintstatus",
                header: I18n("status"),
                accessorKey: "complaintstatus"
            },
            {
                id: "modeOfComplaint",
                header: I18n("mode_of_complaint"),
                accessorKey: "complaintModeName"
            },
            {
                id: "currentlyWithOfficer",
                header: I18n("currently_with_officer"),
                accessorKey: "officer"
            },
            {
                id: "resolutionDate",
                header: I18n("resolution_date"),
                accessorKey: "resolutionDate"
            },
            {
                id: "pendingDays",
                header: I18n("pending_days"),
                accessorKey: "pendingDays"
            },
            {
                id: "ward",
                header: I18n("ward"),
                accessorKey: "wardName"
            },
            {
                id: "ulb",
                header: I18n("ulb"),
                accessorKey: "lsgiName"
            },
            {
                id: "district",
                header: I18n("district"),
                accessorKey: "districtName"
            }

        ],
        []
    );

    return (
        <>
            <CommonTable
                columns={columns}
                options={options}
                enableVirtualization={false}
                title={I18n("pending_reports")}
                fetchData={listPendingReport}
                enableCustomTableFilter={true}
                filterComponent={({ setOpen }) => <PendingReportFilter onClose={setOpen} />}
                tableId={TABLE_IDS.PENDING_REPORT}
            />
        </>
    );
};

export default PendingReport;
