
import { I18n } from "common/components";
import { STORAGE_KEYS } from "common/constants";
import { getNavigator } from "modules/user/selectors";
import { all, call, fork, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { infoNotify, successNotify } from "utils/notificationUtils";
import { ACTION_TYPES, navigateTo } from "./actions";
import { districtDropdownApi, LSGIDropdownApi, stateDropdownApi, wardDropdownApi, LSGITypeDropdownApi, getOtpDetailsApi, postOtpDetailsApi, commonDownloadFile as commonDownloadFileAPI, getSignupOtpDetails, getLogoutUser } from "./api";
import { OTP_PROCESS, SMS } from "./constants";
import { getDistrictId, getOtpDetails } from "./selectors";
import { actions as commonActions } from "../common/slice";
import _ from "lodash";
import { downloadFileAsync } from "utils/commonUtils";

function* navigateToFn({ payload = "/" }) {
    const navigate = yield select(getNavigator);
    if (navigate instanceof Function) {
        yield navigate(payload);
    } else {
        // eslint-disable-next-line no-console
        console.error("navigate function not found");
    }

}
function* refreshFn() {
    const navigate = yield select(getNavigator);
    yield navigate(0);
}
function* fetchStateDropDownFn() {
    yield call(handleAPIRequest, stateDropdownApi);
}
function* fetchDistrictDropDownFn(action) {
    yield call(handleAPIRequest, districtDropdownApi, action?.payload);
}
function* fetchLSGITypeDropDownFn(action) {
    yield call(handleAPIRequest, LSGITypeDropdownApi, action?.payload);
}
function* fetchLSGIDropDownFn(action) {
    const districtId = yield select(getDistrictId);
    yield call(handleAPIRequest, LSGIDropdownApi, { lsgiTypeId: action?.payload, districtId });
}
function* fetchWardDropDownFn(action) {
    yield call(handleAPIRequest, wardDropdownApi, action?.payload);
}

export function* logout() {
    yield fork(handleAPIRequest, getLogoutUser, {});
    const responseAction = yield take([ACTION_TYPES.LOG_OUT_USER_SUCCESS, ACTION_TYPES.LOG_OUT_USER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.LOG_OUT_USER_SUCCESS) {
        yield put(navigateTo("/login"));
    }
}

function* logoutUser() {
    localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
    yield navigateToFn({ payload: "/" });
    // yield put(navigateTo("/"));
}

export function* getOtp({ payload }) {
    const { values: { targetNo = "" } = {}, location } = payload;
    if (location.pathname === "/reset-password") {
        yield put(commonActions.setOtpDetails({ req: { targetNo, otpType: SMS(targetNo) ? "SMS" : "EMAIL", otpProcess: "OTP_PASSWORD_RESET" }, reset: true }));
        yield fork(handleAPIRequest, getOtpDetailsApi, { targetNo, otpType: SMS(targetNo) ? "SMS" : "EMAIL", otpProcess: "OTP_PASSWORD_RESET" });
    } else if (location.pathname === "/sign-up") {
        yield put(commonActions.setOtpDetails({ req: { targetNo, otpType: SMS(targetNo) ? "SMS" : "EMAIL", otpProcess: "CITIZEN_SIGN_UP" }, reset: true }));
        yield fork(handleAPIRequest, getOtpDetailsApi, { targetNo, otpType: SMS(targetNo) ? "SMS" : "EMAIL", otpProcess: "CITIZEN_SIGN_UP" });
    }
}

export function* getSignupOtp({ payload }) {
    const { targetNo, otpProcess = OTP_PROCESS } = payload;
    yield put(commonActions.setOtpDetails({ req: { targetNo, otpType: SMS(targetNo) ? "SMS" : "EMAIL", otpProcess }, reset: true }));
    yield fork(handleAPIRequest, getSignupOtpDetails, { targetNo, otpType: SMS(targetNo) ? "SMS" : "EMAIL", otpProcess });
}

export function* resendOtp() {
    let otpDetails = yield select(getOtpDetails);
    const { targetNo } = otpDetails;
    yield* getSignupOtp({ payload: { targetNo } });
}

export function* postOtp({ payload }) {
    const { OTP: otp } = payload;
    if (_.isEmpty(otp)) {
        yield put(infoNotify({
            title: I18n("required"), message: I18n("is_required", { type: I18n("otp") })
        }));
    } else {
        let otpDetails = yield select(getOtpDetails);
        yield fork(handleAPIRequest, postOtpDetailsApi, { otp, ...otpDetails });
        const response = yield take([ACTION_TYPES.POST_OTP_DETAILS_SUCCESS, ACTION_TYPES.POST_OTP_DETAILS_FAILURE]);
        if (response.type === ACTION_TYPES.POST_OTP_DETAILS_SUCCESS) {
            yield put(commonActions.setOtpDetails({ isVerified: true }));
            yield put(successNotify({ title: I18n("successfully"), message: I18n("verified") }));
        }
    }
}

export function* commonDownloadFile({ payload }) {
    let { url, file, data = {} } = payload;
    yield put(commonActions.setStatusModal({ isOpen: true, content: I18n("downloading_file", { file: I18n(file.name || "download") }) }));
    yield fork(handleAPIRequest, commonDownloadFileAPI, { url, data });
    const response = yield take([ACTION_TYPES.COMMON_DOWNLOAD_FILE_SUCCESS, ACTION_TYPES.COMMON_DOWNLOAD_FILE_FAILURE]);
    if (response.type === ACTION_TYPES.COMMON_DOWNLOAD_FILE_SUCCESS) {
        yield call(downloadFileAsync, { response: response.payload, file });
        yield put(commonActions.resetStatusModal());
        yield put(successNotify({ title: I18n("successfully"), message: I18n("downloaded") }));
    } else if (response.type === ACTION_TYPES.COMMON_DOWNLOAD_FILE_FAILURE) {
        yield put(commonActions.resetStatusModal());
    }
}

export default function* commonSaga() {
    yield all([
        yield takeLatest(ACTION_TYPES.LOG_OUT, logoutUser),
        yield takeLatest(ACTION_TYPES.LOG_OUT_USER, logout),
        yield takeEvery(ACTION_TYPES.NAVIGATE_TO, navigateToFn),
        yield takeEvery(ACTION_TYPES.REFRESH_CURRENT_PATH, refreshFn),
        yield takeLatest(ACTION_TYPES.FETCH_STATE_DROPDOWN, fetchStateDropDownFn),
        yield takeLatest(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN, fetchDistrictDropDownFn),
        yield takeLatest(ACTION_TYPES.FETCH_LSGI_TYPE_DROPDOWN, fetchLSGITypeDropDownFn),
        yield takeLatest(ACTION_TYPES.FETCH_LSGI_DROPDOWN, fetchLSGIDropDownFn),
        yield takeLatest(ACTION_TYPES.FETCH_WARD_DROPDOWN, fetchWardDropDownFn),
        yield takeLatest(ACTION_TYPES.GET_OTP_DETAILS, getOtp),
        yield takeLatest(ACTION_TYPES.GET_SIGNUP_OTP_DETAILS, getSignupOtp),
        yield takeLatest(ACTION_TYPES.POST_OTP_DETAILS, postOtp),
        yield takeLatest(ACTION_TYPES.RESEND_OTP, resendOtp),
        yield takeLatest(ACTION_TYPES.COMMON_DOWNLOAD_FILE, commonDownloadFile)
    ]);
}
