import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";


export const submitPostPlan = (data) => {
    return {
        url: API_URL.STAKEHOLDER_CONSULTATION.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_POST_PLAN_CONSULTATION_REQUEST, ACTION_TYPES.SUBMIT_POST_PLAN_CONSULTATION_SUCCESS, ACTION_TYPES.SUBMIT_POST_PLAN_CONSULTATION_FAILURE],
            data
        }
    };
};

export const updatePostPlan = (data) => {
    return {
        url: API_URL.STAKEHOLDER_CONSULTATION.UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_POST_PLAN_CONSULTATION_REQUEST, ACTION_TYPES.UPDATE_POST_PLAN_CONSULTATION_SUCCESS, ACTION_TYPES.UPDATE_POST_PLAN_CONSULTATION_FAILURE],
            data
        }
    };
};

export const listPostPlan = (data, id) => {
    return {
        url: `${API_URL.STAKEHOLDER_CONSULTATION.LIST}?swmPlanId=${id}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_REQUEST, ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_SUCCESS, ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_FAILURE],
            data
        }
    };
};

export const deletePostPlan = (id) => {
    return {
        url: API_URL.STAKEHOLDER_CONSULTATION.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_POST_PLAN_CONSULTATION_REQUEST, ACTION_TYPES.DELETE_POST_PLAN_CONSULTATION_SUCCESS, ACTION_TYPES.DELETE_POST_PLAN_CONSULTATION_FAILURE]
        }
    };
};

export const postPlanById = (data) => {
    return {
        url: API_URL.STAKEHOLDER_CONSULTATION.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.POST_PLAN_CONSULTATION_BY_ID_REQUEST, ACTION_TYPES.POST_PLAN_CONSULTATION_BY_ID_SUCCESS, ACTION_TYPES.POST_PLAN_CONSULTATION_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const deleteFileById = (id, fileId) => {
    let DELETE_API_URL = API_URL.STAKEHOLDER_CONSULTATION.DELETE_FILE_BY_ID;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":file_id", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_FILE_REQUEST, ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_REQUEST]
        }
    };
};

export const confirmPostPlan = (data) => {
    return {
        url: API_URL.STAKEHOLDER_CONSULTATION.CONFIRM_UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.CONFIRM_FORM_SUBMIT_REQUEST, ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS, ACTION_TYPES.CONFIRM_FORM_SUBMIT_FAILURE],
            data
        }
    };
};
