import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS: `${STATE_REDUCER_KEY}/FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS`,
    FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_REQUEST`,
    FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS`,
    FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE`,

    GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID: `${STATE_REDUCER_KEY}/GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID`,
    GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID_REQUEST`,
    GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID_SUCCESS`,
    GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_DISPOSAL_SITE_CITY_LEVEL_FAILURE`,

    POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS: `${STATE_REDUCER_KEY}/POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS`,
    POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_REQUEST`,
    POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS`,
    POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE`,

    PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS: `${STATE_REDUCER_KEY}/PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS`,
    PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_REQUEST`,
    PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_SUCCESS`,
    PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS_FAILURE`,

    DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS: `${STATE_REDUCER_KEY}/DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS`,
    DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_REQUEST`,
    DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_SUCCESS`,
    DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS_FAILURE`,

    GET_NATURE_OF_AREA_DROPDOWN: `${STATE_REDUCER_KEY}/GET_NATURE_OF_AREA_DROPDOWN`,
    GET_NATURE_OF_AREA_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_NATURE_OF_AREA_DROPDOWN_REQUEST`,
    GET_NATURE_OF_AREA_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_NATURE_OF_AREA_DROPDOWN_SUCCESS`,
    GET_NATURE_OF_AREA_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_NATURE_OF_AREA_DROPDOWN_FAILURE`
};

export const fetchDisposalSiteCityDetails = createAction(ACTION_TYPES.FETCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS);
export const getDisposalSiteCityDetailsById = createAction(ACTION_TYPES.GET_DISPOSAL_SITE_CITY_LEVEL_BY_ID);
export const postDisposalSiteCityDetails = createAction(ACTION_TYPES.POST_DISPOSAL_SITE_CITY_LEVEL_DETAILS);
export const patchDisposalSiteCityDetails = createAction(ACTION_TYPES.PATCH_DISPOSAL_SITE_CITY_LEVEL_DETAILS);
export const deleteDisposalSiteCityDetails = createAction(ACTION_TYPES.DELETE_DISPOSAL_SITE_CITY_LEVEL_DETAILS_DETAILS);
export const natureOfAreaDropDown = createAction(ACTION_TYPES.GET_NATURE_OF_AREA_DROPDOWN);


