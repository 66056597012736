import { flow } from "lodash";

const { STATE_REDUCER_KEY } = require("./constants");

const getComplaintReportDetails = (state) => state[STATE_REDUCER_KEY];

const complaintStatusDropdownOptions = (state) => state.complaintStatusDropdownOptions.data;
export const getComplaintStatusDropdownOptions = flow(getComplaintReportDetails, complaintStatusDropdownOptions);

const complaintsModeDropdown = (state) => state.complaintsModeDropdown.data;
export const getComplaintsModeDropdown = flow(getComplaintReportDetails, complaintsModeDropdown);

const complaintTypeDropdown = (state) => state.complaintTypeDropdown.data;
export const getComplaintTypeDropdown = flow(getComplaintReportDetails, complaintTypeDropdown);

const complaintCategoryDropdown = (state) => state.complaintCategoryDropdown.data;
export const getComplaintCategoryDropdown = flow(getComplaintReportDetails, complaintCategoryDropdown);

const districtsDropdown = (state) => state.districtsDropdown.data;
export const getDistrictsDropdown = flow(getComplaintReportDetails, districtsDropdown);

const wardDropdowns= (state) => state.wardDropdowns;
export const getWardDropdowns = flow(getComplaintReportDetails, wardDropdowns);

const lsgiDropdowns = (state) => state.lsgiDropdowns;
export const getLsgiDropdowns = flow(getComplaintReportDetails, lsgiDropdowns);

const lsgiTypeDropdown = (state) => state.lsgiTypeDropdownOption.data;
export const getLsgiTypeDropdownOption = flow(getComplaintReportDetails, lsgiTypeDropdown);

const complaintReport = (state) => state.complaintReportList.data;
export const getComplaintReport = flow(getComplaintReportDetails, complaintReport);

const priorityDropdown = (state) => state.priorityDropdownOption.data;
export const getPriorityDropdownOption = flow(getComplaintReportDetails, priorityDropdown);

const listComplaintHandler= (state) => state.listComplaintHandler;
export const getListComplaintHandler = flow(getComplaintReportDetails, listComplaintHandler);

const listComplaintNumber = (state) => state.listComplaintNumber;
export const getListComplaintNumber = flow(getComplaintReportDetails, listComplaintNumber);
