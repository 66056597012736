import React, { useState } from "react";
import PropTypes from "prop-types";
import { Components, I18n } from "common/components";
import GMMReportSubWrapper from "./GMMReportSubWrapper";
import { REPORTS } from "../constants";

const { Tabs, Card, Box, Typography, Tab } = Components;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`grant-management-report-tabpanel-${index}`}
            aria-labelledby={`grant-management-report-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: { xs: 0, md: 3 } }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `grant-management-report-${index}`,
        "aria-controls": `grant-management-report-tabpanel-${index}`
    };
}

const GMMReportWrapper = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Card sx={{ m: 2 }}>
                <Box sx={{ width: "100%", padding: "30px 0" }}>
                    <Box sx={{ p: 1 }}>
                        <>
                            <Tabs value={value} onChange={handleChange} scrollButtons="auto" variant="scrollable"
                                aria-label="UserGroup Tabs"
                                sx={{
                                    "& button": { borderRadius: 2, backgroundColor: "#009A9326" },
                                    ".MuiButtonBase-root": { textTransform: "none", mx: 0.5 },
                                    ".Mui-disabled": { backgroundColor: "#00000012", color: "#00000061" },
                                    ".Mui-selected": { backgroundColor: "#009A93", color: "#FFFF !important" }
                                }}
                                TabIndicatorProps={{ style: { display: "none" } }}>
                                < Tab label={I18n("grand_utilization_request_report_1")} {...a11yProps(0)} />
                                < Tab label={I18n("grand_utilization_request_report_2")} {...a11yProps(1)} />
                                < Tab label={I18n("grand_utilization_request_report_3")} {...a11yProps(2)} />
                                < Tab label={I18n("ulb_access_to_incentive_grant_present_status")} {...a11yProps(3)} />
                                < Tab label={I18n("grant_allocation_report")} {...a11yProps(4)} />
                                < Tab label={I18n("project_details_report")} {...a11yProps(5)} />
                                < Tab label={I18n("details_of_incentive_grant_accessed_and_utilized")} {...a11yProps(7)} />
                            </Tabs>
                        </>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <GMMReportSubWrapper reports={REPORTS.GUR_1} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <GMMReportSubWrapper reports={REPORTS.GUR_2} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <GMMReportSubWrapper reports={REPORTS.GUR_3} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <GMMReportSubWrapper reports={REPORTS.ULB_ACCESS_TO_INCENTIVE_GRANT} />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <GMMReportSubWrapper reports={REPORTS.GRANT_ALLOCATION_REPORT} />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <GMMReportSubWrapper reports={REPORTS.PROJECT_DETAILS_REPORT} />
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        <GMMReportSubWrapper reports={REPORTS.INCENTIVE_GRANT_ACCESSED_AND_UTILIZED} />
                    </TabPanel>
                </Box>
            </Card >
        </>
    );
};

export default GMMReportWrapper;
