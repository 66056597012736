import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchTransferStationInformationApi = (data) => {
    return {
        url: API_URL.TRANSFER_STATION.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TRANSFER_STATION_DETAILS_REQUEST, ACTION_TYPES.FETCH_TRANSFER_STATION_DETAILS_SUCCESS, ACTION_TYPES.FETCH_TRANSFER_STATION_DETAILS_FAILURE], data
        }
    };
};

export const typeOfWasteDropdownApi = (data) => {
    return {
        url: API_URL.TRANSFER_STATION.TYPE_OF_WASTE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.TYPE_OF_WASTE_DROPDOWN_REQUEST, ACTION_TYPES.TYPE_OF_WASTE_DROPDOWN_SUCCESS, ACTION_TYPES.TYPE_OF_WASTE_DROPDOWN_FAILURE],
            data
        }
    };
};

export const mcfRrfSiteNameDropdownApi = (data) => {
    return {
        url: API_URL.TRANSFER_STATION.MCF_RRF_SITE_NAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_MCF_RRF_SITE_NAME_DROPDOWN_REQUEST, ACTION_TYPES.GET_MCF_RRF_SITE_NAME_DROPDOWN_SUCCESS, ACTION_TYPES.GET_MCF_RRF_SITE_NAME_DROPDOWN_FAILURE],
            data
        }
    };
};

export const transferStationByIdApi = (data) => {
    return {
        url: API_URL.TRANSFER_STATION.BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_TRANSFER_STATION_INFORMATION_BY_ID_REQUEST, ACTION_TYPES.GET_TRANSFER_STATION_INFORMATION_BY_ID_SUCCESS, ACTION_TYPES.GET_TRANSFER_STATION_INFORMATION_BY_ID_FAILURE]
        }
    };
};

export const postTransferStationApi = (data) => {
    return {
        url: API_URL.TRANSFER_STATION.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_TRANSFER_STATION_INFORMATION_REQUEST, ACTION_TYPES.POST_TRANSFER_STATION_INFORMATION_SUCCESS, ACTION_TYPES.POST_TRANSFER_STATION_INFORMATION_FAILURE], data
        }
    };
};

export const patchTransferStationApi = (data) => {
    return {
        url: API_URL.TRANSFER_STATION.SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_TRANSFER_STATION_INFORMATION_REQUEST, ACTION_TYPES.PATCH_TRANSFER_STATION_INFORMATION_SUCCESS, ACTION_TYPES.PATCH_TRANSFER_STATION_INFORMATION_FAILURE], data
        }
    };
};

export const deleteTransferStationApi = (data) => {
    return {
        url: API_URL.TRANSFER_STATION.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_TRANSFER_STATION_INFORMATION_REQUEST, ACTION_TYPES.DELETE_TRANSFER_STATION_INFORMATION_SUCCESS, ACTION_TYPES.DELETE_TRANSFER_STATION_INFORMATION_FAILURE]
        }
    };
};

export const fromDistrictDropdownApi = (data) => {
    return {
        url: API_URL.TRANSFER_STATION.FROM_DISTRICT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_FROM_DISTRICT_DROPDOWN_REQUEST, ACTION_TYPES.GET_FROM_DISTRICT_DROPDOWN_SUCCESS, ACTION_TYPES.GET_FROM_DISTRICT_DROPDOWN_FAILURE],
            data
        }
    };
};

export const fromLsgiDropdownApi = (data) => {
    return {
        url: API_URL.TRANSFER_STATION.FROM_LSGI,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_FROM_LSGI_DROPDOWN_REQUEST, ACTION_TYPES.GET_FROM_LSGI_DROPDOWN_SUCCESS, ACTION_TYPES.GET_FROM_LSGI_DROPDOWN_FAILURE],
            data
        }
    };
};
