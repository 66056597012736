import { all, call, fork, select, takeLatest, take, put } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { ACTION_TYPES } from "./actions";
import { setCommonTableData } from "utils/tableUtils";
import { getTableProps } from "modules/common/selectors";
import { TABLE_IDS } from "common/constants";
import {
    deleteEmployeeDetailsApi, fetchBasicDetailsByIdApi, fetchCategoryDropdownApi, fetchDayOfHolidayDropdownApi, fetchDesignationDropdownApi, fetchDistrictDropdownApi, fetchEducationLevelDropdownApi, fetchEmployeeDetailsApi, fetchEmployeeListApi, fetchEmploymentDropdownApi, fetchGenderDropdownApi, fetchMaritalStatusRadioButtonApi, fetchMinimumWagesApi, fetchMinimumWagesByIdApi, fetchNationalityDropdownApi, fetchNatureOfWorkDropdownApi, fetchProjectManagerByIdApi, fetchProjectManagerListApi, fetchRegistrationNumberApi, fetchSubContractDropdownApi, fetchUlbDropdownApi,
    fetchWagePeriodDropdownApi, fetchWorkmanRadioButtonApi, listProjectApi, listProjectSitesApi, patchEmployeeApi, postMinimumWagesApi, submitBasicDetailsApi,
    submitEmployeeApi, submitProjectManagerDetailsApi, updateProjectManagerDetailsApi, fetchBasicFacilitiesByIdApi, fetchGrievanceCommitteeByIdApi, fetchGrievanceCommitteeListApi, fetchRegistrationNumberByIdApi, patchBasicFacilitiesApi, patchGrievanceCommitteeApi, patchRegistrationNumberApi, postGrievanceCommitteeApi, updateBasicDetailsApi, fetchNoticeBoardByIdApi, patchNoticeBoardApi, fetchInternalComplaintCommitteeListApi,
    fetchComplaintCommitteeDesignationDropdownApi, fetchInternalComplaintCommitteeByIdApi, postComplaintCommitteeApi, patchComplaintCommitteeApi,
    fetchWageDetailsApi, getWageDetailsByIdApi, deleteWageDetailsApi, updateWageDetailsApi, submitWageDetailsApi, postEmployeeTerminatedProcessApi, fetchSiteWageRegisterApi, postSiteWageRegisterApi, fetchSiteWageRegisterEmployeeApi, getSiteWageRegisterEmployeeDetailsApi, patchSiteWageEmployeeDetailsApi, getCardCountInWageRegisterApi, deleteWageRegisterDetailsApi, fetchEmployeeVerificationListApi, submitEmployeeVerificationApi,
    getWorkplaceAccidentListApi, getEmployeeNameDropdownApi, getEmployeeDetailsByIdApi, fetchFatalDropdownApi, saveWorkplaceAccidentDetailsApi, fetchWorkplaceAccidentDetailsByIdApi, updateWorkplaceAccidentDetailsApi, fetchHarassmentDetailApi, submitNoticeRespondentApi, submitResponseRespondentApi, submitComplaintSettlementApi, submitHearingApi, getAccordionDetailsApi, postHarassmentComplaintApi, getResponseAccordionApi, getComplaintAccordionDetailsApi, getHearingAccordionDetailsApi,
    getHarassmentComplaintByIdApi, patchNoticeRespondentApi, patchResponseRespondentApi, patchComplaintSettlementApi, patchHearingApi,
    patchHarassmentComplaintApi, postEmployeeAttendanceApi, getCardCountInAttendanceRegisterApi, fetchEmployeeAttendanceListApi, getAttendanceTypeDropdownApi, patchEmployeeAttendanceDetailsApi, getEmployeeAttendanceByIdApi,
    listRecoveryRegisterApi, listMaternityLeaveApi, getEmployeeDropdownApi, getRecoveryDropdownApi, postRecoveryRegisterApi, getIncidentEmployeeDetailApi, getRecoveryDetailApi, getCausedIssueDropdownApi, getMaternityTypesDropdownApi, getMaternityDetailApi, postMaternityRegisterApi, patchRecoveryRegisterApi, patchMaternityDetailApi,
    deleteProjectSiteDetailsApi, getVerificationStatusApi, patchWageVerificationApi, fetchEventsTrackedInMinWageApi,
    patchBasicFacilitiesVerificationApi, fetchEventsTrackedInBasicFacilitiesApi, patchWageDetailsVerificationApi, fetchEventsTrackedInWageDetailsApi, patchWageRegisterVerificationApi, fetchEventsTrackedInWageRegisterApi, patchAttendanceRegisterVerificationApi, fetchEventsTrackedInAttendanceRegisterApi, patchMaternityLeaveVerificationApi, fetchEventsTrackedInMaternityApi, patchRecoveryRegisterVerificationApi, fetchEventsTrackedInRecoveryRegisterApi, patchWorkPlaceAccidentVerificationApi, fetchEventsTrackedInWorkplaceAccidentApi, patchHarassmentComplaintVerificationApi, fetchEventsTrackedInHarassmentComplaintApi, patchDpmuRemarksApi,
    patchGrievanceCommitteeDpmuRemarksApi,
    patchComplaintCommitteeDpmuRemarksApi,
    patchEmployeeDpmuRemarksApi
} from "./api";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { toEpoch } from "utils/dateUtils";
import { actions as sliceActions } from "./slice";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "modules/paths";
import { SUB_PROJECTS_PATH as PATH } from "../paths";
import { API_URL } from "../apiUrls";
import _ from "lodash";

const formatData = (rest) => {
    const { name, projectId, houseNo, streetNo, town, districtId, lsgiId, pincode, natureOfWork, commencementDate, wagePeriod, holiday, isWeekoffWageConsider, adolescentRestHours, adolescentWorkHours,
        adultWorkHours, pName, id } = rest;
    const data = { name, projectId: projectId, houseNo: houseNo, streetNo: streetNo, town, districtId, lsgiId, pincode: Number(pincode), natureOfWork, commencementDate: toEpoch(commencementDate), wagePeriod, holiday, isWeekoffWageConsider, adolescentRestHours: Number(adolescentRestHours), adolescentWorkHours: Number(adolescentWorkHours), adultWorkHours: Number(adultWorkHours), projectName: pName, id };
    return data;
};

const formatDataEmployee = (rest) => {
    const { siteId, employeeCode, firstName, surname, gender, fatherName, maritalStatus, dateOfBirth, presentAddress, permanentAddress, nationality, educationalLevel, dateOfJoin, designation, catagory, typeOfEmployment, workmanInterstate, setOfwork, mobile, esic, basicRate, daRate, overTimeRate, lwf, identification, acNum, confirmAcNum, bankName, ifscNum, uan, panNum, aadhaarNum, shram, rationCardNum, photo, bank, permanentCheckbox, naEsic, naLwf, naAadhaar, registrationDate, attachments } = rest;
    const data = { siteId, employeeCode, firstName, surname, gender, fatherName, maritalStatus: maritalStatus, dateOfBirth: toEpoch(dateOfBirth), presentAddress, permanentAddress: permanentCheckbox === true ? "" : permanentAddress, nationality, educationalLevel, dateOfJoin: toEpoch(dateOfJoin), designation, catagory, typeOfEmployment, workmanInterstate: workmanInterstate, setOfwork, mobile, esic: naEsic === true ? "" : esic, basicRate, daRate, overTimeRate, lwf: naLwf === true ? "" : lwf, identification, acNum, confirmAcNum, bankName, ifscNum, uan, panNum, aadhaarNum: naAadhaar === true ? "" : aadhaarNum, shram, rationCardNum, photo, bank, permanentCheckbox, naEsic, naLwf, naAadhaar, registrationDate: toEpoch(registrationDate), attachments };
    return data;
};

const formatPatchDataEmployee = (rest) => {
    const { siteId, id, employeeCode, firstName, surname, gender, fatherName, maritalStatus, dateOfBirth, presentAddress, permanentAddress, nationality, educationalLevel, dateOfJoin, designation, catagory, typeOfEmployment, workmanInterstate, setOfwork, mobile, esic, basicRate, daRate, overTimeRate, lwf, identification, acNum, confirmAcNum, bankName, ifscNum, uan, panNum, aadhaarNum, shram, rationCardNum, photo, bank, permanentCheckbox, naEsic, naLwf, naAadhaar, attachments } = rest;
    const data = { siteId, id, employeeCode, firstName, surname, gender, fatherName, maritalStatus: maritalStatus, dateOfBirth: toEpoch(dateOfBirth), presentAddress, permanentAddress: permanentCheckbox === true ? "" : permanentAddress, nationality, educationalLevel, dateOfJoin: toEpoch(dateOfJoin), designation, catagory, typeOfEmployment, workmanInterstate: workmanInterstate, setOfwork, mobile, esic: naEsic === true ? "" : esic, basicRate, daRate, overTimeRate, lwf: naLwf === true ? "" : lwf, identification, acNum, confirmAcNum, bankName, ifscNum, uan, panNum, aadhaarNum: naAadhaar === true ? "" : aadhaarNum, shram, rationCardNum, photo, bank, permanentCheckbox, naEsic, naLwf, naAadhaar, attachments };
    return data;
};

const formatWage = (rest) => {
    const { siteId, dateFrom, mbHighlySkilled, mbSkilled, mbSemiSkilled, mbUnSkilled, daHighlySkilled, daSkilled, daSemiSkilled, daUnSkilled, otHighlySkilled, otSkilled, otSemiSkilled, otUnSkilled } = rest;
    const minimumBasic = { highlySkilled: mbHighlySkilled, skilled: mbSkilled, semiSkilled: mbSemiSkilled, unSkilled: mbUnSkilled };
    const dailyAllowance = { highlySkilled: daHighlySkilled, skilled: daSkilled, semiSkilled: daSemiSkilled, unSkilled: daUnSkilled };
    const overTime = { highlySkilled: otHighlySkilled, skilled: otSkilled, semiSkilled: otSemiSkilled, unSkilled: otUnSkilled };
    const data = { siteId, dateFrom: toEpoch(dateFrom), minimumBasic: minimumBasic, dailyAllowance: dailyAllowance, overTime: overTime };
    return data;
};

const formatRegNo = (rest) => {
    const { id, siteId, licenseId, licenseName, licenseNumber, commencedOnDate, expiryDate } = rest;
    const data = { id, siteId, licenseId, licenseName, licenseNumber, commencedOnDate: toEpoch(commencedOnDate), expiryDate: toEpoch(expiryDate) };
    return data;
};

const formatWageData = (payload) => {
    const { startDate, ...rest } = payload;
    const data = { startDate: toEpoch(startDate), ...rest };
    return data;
};

const formatTerminationData = (payload) => {
    const { exitDate, reason, employee } = payload;
    const data = { exitDate: toEpoch(exitDate), reasonForExit: reason, id: employee.id };
    return data;
};

const formatSiteWage = (payload) => {
    const { startDate, endDate, projectSite } = payload;
    const data = { startDate: toEpoch(startDate), endDate: toEpoch(endDate), projectSite };
    return data;
};

const formatAttendanceData = (payload) => {
    const { inTime, outTime, ...rest } = payload;
    const data = { inTime: toEpoch(inTime), outTime: toEpoch(outTime), ...rest };
    return data;
};

const formatRecoveryData = (payload) => {
    const newPayload = _.omitBy(payload, (value, key) => key === "showCausedIssue" && (_.isNil(value) || _.isEmpty(value)));
    const { dateTime, ...rest } = newPayload;
    const data = { dateTime: toEpoch(dateTime), ...rest };

    return data;

};

const formatMaternityData = (payload) => {
    const { startDate, endDate, ...rest } = payload;
    const data = { startDate: toEpoch(startDate), endDate: toEpoch(endDate), ...rest };
    return data;
};

export function* getProjectList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.LABOUR_PROJECT_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listProjectApi, { pageSize: 5, pageNo: 0, ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PROJECT_LIST_SUCCESS, ACTION_TYPES.FETCH_PROJECT_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.LABOUR_PROJECT_LIST);
}

export function* getProjectSitesList({ payload }) {
    let updatedPayload = { pageNo: payload.pageNo, pageSize: payload.pageSize, projectId: payload.id };
    yield fork(handleAPIRequest, listProjectSitesApi, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PROJECT_SITE_LIST_SUCCESS, ACTION_TYPES.FETCH_PROJECT_SITE_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.PROJECT_SITE_LIST);
}

export function* natureOfWorkDropdown({ payload }) {
    yield call(handleAPIRequest, fetchNatureOfWorkDropdownApi, payload);
}

export function* wagePeriodDropdown({ payload }) {
    yield call(handleAPIRequest, fetchWagePeriodDropdownApi, payload);
}

export function* dayOfHolidayDropdown({ payload }) {
    yield call(handleAPIRequest, fetchDayOfHolidayDropdownApi, payload);
}

export function* districtDropdown({ payload }) {
    yield call(handleAPIRequest, fetchDistrictDropdownApi, payload);
}

export function* ulbDropdown({ payload }) {
    yield call(handleAPIRequest, fetchUlbDropdownApi, payload);
}

export function* basicDetailsById({ payload }) {
    yield call(handleAPIRequest, fetchBasicDetailsByIdApi, payload);
}

export function* submitBasicDetails(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, submitBasicDetailsApi, formatData(rest));
    yield put(sliceActions.setBasicDetailsById(formatData(rest)));
    const responseAction = yield take([ACTION_TYPES.POST_BASIC_DETAILS_SUCCESS, ACTION_TYPES.POST_BASIC_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_BASIC_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("basic_details") }) }));
        yield put(sliceActions.clearBasicDetailsById());
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.LABOUR_MANAGEMENT.PROJECT_LIST}/${rest?.projectId}/${PATH.LABOUR_MANAGEMENT.PROJECT_SITE_LIST}`));
    }
}

export function* updateBasicDetails(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, updateBasicDetailsApi, formatData(rest));
    const responseAction = yield take([ACTION_TYPES.PATCH_BASIC_DETAILS_SUCCESS, ACTION_TYPES.PATCH_BASIC_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_BASIC_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("basic_details") }) }));
        yield call(handleAPIRequest, fetchBasicDetailsByIdApi, { id: rest?.id });
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.LABOUR_MANAGEMENT.PROJECT_LIST}/${rest?.projectId}/${PATH.LABOUR_MANAGEMENT.PROJECT_SITE_LIST}`));
    }
}

export function* projectManagerDetailsById({ payload }) {
    yield call(handleAPIRequest, fetchProjectManagerByIdApi, payload);
}

export function* submitProjectManagerDetails(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, submitProjectManagerDetailsApi, rest);
    const responseAction = yield take([ACTION_TYPES.POST_PROJECT_MANAGER_DETAILS_SUCCESS, ACTION_TYPES.POST_PROJECT_MANAGER_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_PROJECT_MANAGER_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("added", { type: I18n("project_manager_details") }) }));
        yield call(handleAPIRequest, fetchProjectManagerListApi, { id: rest?.id });
    }
}

export function* getProjectManagerList({ payload }) {
    yield call(handleAPIRequest, fetchProjectManagerListApi, payload);
}

export function* updateProjectManagerDetails(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, updateProjectManagerDetailsApi, rest);
    const responseAction = yield take([ACTION_TYPES.PATCH_PROJECT_MANAGER_DETAILS_SUCCESS, ACTION_TYPES.PATCH_PROJECT_MANAGER_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_PROJECT_MANAGER_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("project_manager_details") }) }));
        yield call(handleAPIRequest, fetchProjectManagerListApi, { id: rest?.id });
    }
}

export function* getMinimumWages({ payload }) {
    yield call(handleAPIRequest, fetchMinimumWagesApi, payload);
}

export function* getMinimumWagesById({ payload }) {
    yield call(handleAPIRequest, fetchMinimumWagesByIdApi, payload);
}

export function* submitMinimumWages(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, postMinimumWagesApi, formatWage(rest));
    const responseAction = yield take([ACTION_TYPES.POST_MINIMUM_WAGE_SUCCESS, ACTION_TYPES.POST_MINIMUM_WAGE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_MINIMUM_WAGE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("added", { type: I18n("minimum_wages") }) }));
        yield call(handleAPIRequest, fetchMinimumWagesApi, { siteId: rest?.siteId });
    }
}

export function* getRegistrationNumber({ payload }) {
    yield call(handleAPIRequest, fetchRegistrationNumberApi, payload);
}

export function* fetchEmployee({ payload }) {
    let tableProps = yield select(getTableProps);
    if (tableProps.EMPLOYEE_LIST) {
        let { [TABLE_IDS.EMPLOYEE_LIST]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, fetchEmployeeListApi, { pageNo: payload.pageNo, pageSize: payload.pageSize, siteId: payload.id, isActive: true, ...filters });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_EMPLOYEE_LIST_SUCCESS, ACTION_TYPES.FETCH_EMPLOYEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.EMPLOYEE_LIST);
    } else {
        let { [TABLE_IDS.TERMINATION_LIST]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, fetchEmployeeListApi, { pageNo: payload.pageNo, pageSize: payload.pageSize, siteId: payload.id, isActive: false, ...filters });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_EMPLOYEE_LIST_SUCCESS, ACTION_TYPES.FETCH_EMPLOYEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TERMINATION_LIST);
    }
}

export function* genderDropdown({ payload }) {
    yield call(handleAPIRequest, fetchGenderDropdownApi, payload);
}

export function* nationalityDropdown({ payload }) {
    yield call(handleAPIRequest, fetchNationalityDropdownApi, payload);
}

export function* educationLevelDropdown({ payload }) {
    yield call(handleAPIRequest, fetchEducationLevelDropdownApi, payload);
}

export function* designationDropdown({ payload }) {
    yield call(handleAPIRequest, fetchDesignationDropdownApi, payload);
}

export function* categoryDropdown({ payload }) {
    yield call(handleAPIRequest, fetchCategoryDropdownApi, payload);
}

export function* employmentDropdown({ payload }) {
    yield call(handleAPIRequest, fetchEmploymentDropdownApi, payload);
}

export function* subContractDropdown({ payload }) {
    yield call(handleAPIRequest, fetchSubContractDropdownApi, payload);
}

export function* workmanRadioButton({ payload }) {
    yield call(handleAPIRequest, fetchWorkmanRadioButtonApi, payload);
}

export function* employeeDetails({ payload }) {
    yield call(handleAPIRequest, fetchEmployeeDetailsApi, payload);
}

export function* maritalStatusRadioButton({ payload }) {
    yield call(handleAPIRequest, fetchMaritalStatusRadioButtonApi, payload);
}

export function* submitEmployee(action) {
    let { payload: { idParam, ids, ...rest } = {} } = action;
    yield fork(handleAPIRequest, submitEmployeeApi, formatDataEmployee(rest));
    yield put(sliceActions.setEmployeeDetails(formatDataEmployee(rest)));
    const responseAction = yield take([ACTION_TYPES.POST_EMPLOYEE_DETAILS_SUCCESS, ACTION_TYPES.POST_EMPLOYEE_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_EMPLOYEE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("employee_details") }) }));
        yield put(sliceActions.resetEmployeeDetails());
        yield put(navigateTo("/sub-projects/project-list/" + idParam + "/sites/" + ids + "/employee-list"));
    }
}

export function* patchEmployee(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, patchEmployeeApi, formatPatchDataEmployee(rest));
    const responseAction = yield take([ACTION_TYPES.PATCH_EMPLOYEE_DETAILS_SUCCESS, ACTION_TYPES.PATCH_EMPLOYEE_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_EMPLOYEE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("employee_details") }) }));
        yield call(handleAPIRequest, fetchEmployeeDetailsApi, { id: rest?.id });
        yield put(navigateTo("/sub-projects/project-list/" + rest.idParam + "/sites/" + rest.ids + "/employee-list"));
    }
}

export function* deleteEmployeeDetails({ payload }) {
    yield fork(handleAPIRequest, deleteEmployeeDetailsApi, payload.id);
    const action = yield take([ACTION_TYPES.DELETE_EMPLOYEE_DETAILS_SUCCESS, ACTION_TYPES.DELETE_EMPLOYEE_DETAILS_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_EMPLOYEE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: (I18n("delete")), message: (I18n("deleted_successfully")) }));
        yield fork(handleAPIRequest, fetchEmployeeListApi, { pageSize: 5, pageNo: 0, siteId: payload?.siteId });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_EMPLOYEE_LIST_SUCCESS, ACTION_TYPES.FETCH_EMPLOYEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.EMPLOYEE_LIST);
    }
}

export function* getRegistrationNumberById({ payload }) {
    yield call(handleAPIRequest, fetchRegistrationNumberByIdApi, payload);
}

export function* submitRegistrationNumber(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, patchRegistrationNumberApi, formatRegNo(rest));
    const responseAction = yield take([ACTION_TYPES.PATCH_REGISTRATION_NUMBER_SUCCESS, ACTION_TYPES.PATCH_REGISTRATION_NUMBER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_REGISTRATION_NUMBER_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("registration_number") }) }));
        yield call(handleAPIRequest, fetchRegistrationNumberApi, { id: rest?.siteId });
    }
}

export function* getBasicFacilitiesById({ payload }) {
    yield call(handleAPIRequest, fetchBasicFacilitiesByIdApi, payload);
}

export function* updateBasicFacilities(action) {
    let { payload: { ...rest } = {} } = action;
    const data = { id: rest?.id, canteen: rest?.canteen, restrooms: rest?.restrooms, drinkingWater: rest?.drinkingWater, creches: rest?.creches, firstaid: rest?.firstaid };
    yield fork(handleAPIRequest, patchBasicFacilitiesApi, data);
    const responseAction = yield take([ACTION_TYPES.PATCH_BASIC_FACILITIES_SUCCESS, ACTION_TYPES.PATCH_BASIC_FACILITIES_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_BASIC_FACILITIES_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("basic_facilities") }) }));
        yield call(handleAPIRequest, fetchBasicFacilitiesByIdApi, { id: rest?.id });
    }
}

export function* getGrievanceCommitteeList({ payload }) {
    let updatedPayload = { pageNo: payload.pageNo, pageSize: payload.pageSize, siteId: payload.id };
    yield fork(handleAPIRequest, fetchGrievanceCommitteeListApi, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.GRIEVANCE_REDRESSAL_COMMITTEE_LIST);
}

export function* getGrievanceCommitteeById({ payload }) {
    yield call(handleAPIRequest, fetchGrievanceCommitteeByIdApi, payload);
}

export function* submitGrievanceCommittee(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, postGrievanceCommitteeApi, rest);
    yield put(sliceActions.setGrievanceCommitteeById(rest));
    const responseAction = yield take([ACTION_TYPES.POST_GRIEVANCE_COMMITTEE_SUCCESS, ACTION_TYPES.POST_GRIEVANCE_COMMITTEE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_GRIEVANCE_COMMITTEE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("member") }) }));
        yield put(sliceActions.clearGrievanceCommitteeById());
        yield fork(handleAPIRequest, fetchGrievanceCommitteeListApi, { pageNo: 0, pageSize: 5, siteId: rest?.siteId });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.GRIEVANCE_REDRESSAL_COMMITTEE_LIST);
    }
}

export function* updateGrievanceCommittee(action) {
    let { payload: { ...rest } = {} } = action;
    const data = { id: rest?.id, siteId: rest?.siteId, firstName: rest?.firstName, lastName: rest?.lastName, attachments: rest?.attachments };
    yield fork(handleAPIRequest, patchGrievanceCommitteeApi, data);
    const responseAction = yield take([ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_SUCCESS, ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("member") }) }));
        yield call(handleAPIRequest, fetchGrievanceCommitteeByIdApi, { id: rest?.id });
        yield fork(handleAPIRequest, fetchGrievanceCommitteeListApi, { pageNo: 0, pageSize: 5, siteId: rest?.siteId });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.GRIEVANCE_REDRESSAL_COMMITTEE_LIST);
    }
}

export function* getNoticeBoardById({ payload }) {
    yield call(handleAPIRequest, fetchNoticeBoardByIdApi, payload);
}

export function* updateNoticeBoard(action) {
    let { payload: { ...rest } = {} } = action;
    const data = { id: rest?.id, minimumWagesAct: rest?.minimumWagesAct, paymentOfWagesAct: rest?.paymentOfWagesAct, employeeCompensationAct: rest?.employeeCompensationAct, recsAct: rest?.recsAct, contractLabourAct: rest?.contractLabourAct, childAndAdolescentLabourAct: rest?.childAndAdolescentLabourAct, maternityBenefitAct: rest?.maternityBenefitAct, attachmentIds: rest?.attachmentIds };
    yield fork(handleAPIRequest, patchNoticeBoardApi, data);
    const responseAction = yield take([ACTION_TYPES.PATCH_NOTICE_BOARD_SUCCESS, ACTION_TYPES.PATCH_NOTICE_BOARD_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_NOTICE_BOARD_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("notice_board") }) }));
        yield call(handleAPIRequest, fetchNoticeBoardByIdApi, { id: rest?.id });
    }
}

export function* getInternalComplaintCommitteeList({ payload }) {
    let updatedPayload = { pageNo: payload.pageNo, pageSize: payload.pageSize, siteId: payload.id };
    yield fork(handleAPIRequest, fetchInternalComplaintCommitteeListApi, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_SUCCESS, ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.INTERNAL_COMPLAINT_COMMITTEE_LIST);
}

export function* getComplaintCommitteeDesignationDropdown({ payload }) {
    yield call(handleAPIRequest, fetchComplaintCommitteeDesignationDropdownApi, payload);
}

export function* getComplaintCommitteeById({ payload }) {
    yield call(handleAPIRequest, fetchInternalComplaintCommitteeByIdApi, payload);
}

export function* submitComplaintCommittee(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, postComplaintCommitteeApi, rest);
    yield put(sliceActions.setComplaintCommitteeById(rest));
    const responseAction = yield take([ACTION_TYPES.POST_COMPLAINT_COMMITTEE_MEMBER_SUCCESS, ACTION_TYPES.POST_COMPLAINT_COMMITTEE_MEMBER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_COMPLAINT_COMMITTEE_MEMBER_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("member") }) }));
        yield put(sliceActions.clearComplaintCommitteeById());
        yield fork(handleAPIRequest, fetchInternalComplaintCommitteeListApi, { pageNo: 0, pageSize: 5, siteId: rest?.siteId });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_SUCCESS, ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.INTERNAL_COMPLAINT_COMMITTEE_LIST);
    }
}

export function* updateComplaintCommittee(action) {
    let { payload: { ...rest } = {} } = action;
    const data = { id: rest?.id, siteId: rest?.siteId, firstName: rest?.firstName, lastName: rest?.lastName, contact: rest?.contact, designation: rest?.designation, attachments: rest?.attachments };
    yield fork(handleAPIRequest, patchComplaintCommitteeApi, data);
    const responseAction = yield take([ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_MEMBER_SUCCESS, ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_MEMBER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_MEMBER_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("member") }) }));
        yield fork(handleAPIRequest, fetchInternalComplaintCommitteeListApi, { pageNo: 0, pageSize: 5, siteId: rest?.siteId });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_SUCCESS, ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.INTERNAL_COMPLAINT_COMMITTEE_LIST);
    }
}

export function* wageDetails({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.WAGE_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchWageDetailsApi, { ...filters, EmployeeId: payload?.id, pageNo: payload?.pageNo, pageSize: payload?.pageSize });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WAGE_DETAILS_LIST_SUCCESS, ACTION_TYPES.FETCH_WAGE_DETAILS_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WAGE_LIST);
}

export function* submitWageDetailsOfEmployee(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, updateWageDetailsApi, formatWageData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_WAGE_DETAILS_SUCCESS, ACTION_TYPES.PATCH_WAGE_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_WAGE_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("wage_details") }) }));
            let tableProps = yield select(getTableProps);
            let { [TABLE_IDS.WAGE_LIST]: { filters = {} } = {} } = tableProps;
            yield fork(handleAPIRequest, fetchWageDetailsApi, { ...filters, EmployeeId: rest?.employee?.id, pageNo: 0, pageSize: 5 });
            const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WAGE_DETAILS_LIST_SUCCESS, ACTION_TYPES.FETCH_WAGE_DETAILS_LIST_FAILURE];
            yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WAGE_LIST);
        }
    } else {
        yield fork(handleAPIRequest, submitWageDetailsApi, formatWageData(rest));
        const responseAction = yield take([ACTION_TYPES.POST_WAGE_DETAILS_SUCCESS, ACTION_TYPES.POST_WAGE_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_WAGE_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("wage_details_added_successfully") }));
            let tableProps = yield select(getTableProps);
            let { [TABLE_IDS.WAGE_LIST]: { filters = {} } = {} } = tableProps;
            yield fork(handleAPIRequest, fetchWageDetailsApi, { ...filters, EmployeeId: rest?.employee?.id, pageNo: 0, pageSize: 5 });
            const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WAGE_DETAILS_LIST_SUCCESS, ACTION_TYPES.FETCH_WAGE_DETAILS_LIST_FAILURE];
            yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WAGE_LIST);
        }
    }
}

export function* getWageDetailsById({ payload }) {
    yield call(handleAPIRequest, getWageDetailsByIdApi, payload);
}

export function* deleteWageDetails({ payload }) {
    yield fork(handleAPIRequest, deleteWageDetailsApi, payload.id);
    const action = yield take([ACTION_TYPES.DELETE_WAGE_DETAILS_SUCCESS, ACTION_TYPES.DELETE_WAGE_DETAILS_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_WAGE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: (I18n("delete")), message: (I18n("deleted_successfully")) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.WAGE_LIST]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, fetchWageDetailsApi, { ...filters, EmployeeId: payload?.employeeIds, pageNo: 0, pageSize: 5 });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WAGE_DETAILS_LIST_SUCCESS, ACTION_TYPES.FETCH_WAGE_DETAILS_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WAGE_LIST);
    }
}

export function* fetchEmployeeVerificationList({ payload }) {
    yield call(handleAPIRequest, fetchEmployeeVerificationListApi, payload);
}

export function* submitEmployeeVerification({ payload }) {
    let { employeeVerificationDto } = payload;
    employeeVerificationDto = employeeVerificationDto?.map(obj => {
        let newObj = {
            ...obj,
            submissionDate: toEpoch(obj.submissionDate)
        };
        if (obj?.attachmentIds?.length === 0) {
            newObj = _.omit(newObj, ["attachmentIds"]);
        }
        return newObj;
    });
    yield fork(handleAPIRequest, submitEmployeeVerificationApi, { employeeVerificationDto: employeeVerificationDto });
    const responseAction = yield take([ACTION_TYPES.PATCH_EMPLOYEE_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_EMPLOYEE_VERIFICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_EMPLOYEE_VERIFICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("uploaded_successfully") }));
    }
}

export function* postEmployeeTermination({ payload }) {
    yield fork(handleAPIRequest, postEmployeeTerminatedProcessApi, formatTerminationData(payload));
    const responseAction = yield take([ACTION_TYPES.POST_EMPLOYEE_TERMINATION_PROCESS_SUCCESS, ACTION_TYPES.POST_EMPLOYEE_TERMINATION_PROCESS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_EMPLOYEE_TERMINATION_PROCESS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("employee_terminated_successfully") }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.EMPLOYEE_LIST]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, fetchEmployeeListApi, { pageSize: 5, pageNo: 0, siteId: payload?.siteId, ...filters });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_EMPLOYEE_LIST_SUCCESS, ACTION_TYPES.FETCH_EMPLOYEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.EMPLOYEE_LIST);
    }
}

export function* siteWageRegister({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.WAGE_REGISTER_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchSiteWageRegisterApi, { ...filters, siteId: payload?.id, pageNo: payload?.pageNo, pageSize: payload?.pageSize, year: payload?.year });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_LIST_SUCCESS, ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WAGE_REGISTER_LIST);
}

export function* postSiteWageRegister({ payload }) {
    yield fork(handleAPIRequest, postSiteWageRegisterApi, formatSiteWage(payload));
    const responseAction = yield take([ACTION_TYPES.POST_SITE_WAGE_REGISTER_SUCCESS, ACTION_TYPES.POST_SITE_WAGE_REGISTER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_SITE_WAGE_REGISTER_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("new_record_added_successfully") }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.WAGE_REGISTER_LIST]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, fetchSiteWageRegisterApi, { ...filters, siteId: payload?.projectSite?.id, pageNo: 0, pageSize: 5 });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_LIST_SUCCESS, ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WAGE_REGISTER_LIST);
    }
}

export function* siteWageRegisterEmployee({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.WAGE_REGISTER_EMPLOYEE]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchSiteWageRegisterEmployeeApi, { ...filters, wageRegisterId: payload?.id, pageNo: payload?.pageNo, pageSize: payload?.pageSize });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_SUCCESS, ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WAGE_REGISTER_EMPLOYEE);
}

export function* getSiteWageRegisterEmployeeDetailsById({ payload }) {
    yield call(handleAPIRequest, getSiteWageRegisterEmployeeDetailsApi, payload);
}

export function* patchSiteWageEmployeeDetails({ payload }) {
    yield fork(handleAPIRequest, patchSiteWageEmployeeDetailsApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_SITE_WAGE_EMPLOYEE_DETAILS_SUCCESS, ACTION_TYPES.PATCH_SITE_WAGE_EMPLOYEE_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_SITE_WAGE_EMPLOYEE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("wage_details_added_successfully") }));
        yield call(handleAPIRequest, getCardCountInWageRegisterApi, { id: payload?.wageRegisterProjectSite?.id });
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.WAGE_REGISTER_EMPLOYEE]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, fetchSiteWageRegisterEmployeeApi, { ...filters, wageRegisterId: payload?.wageRegisterProjectSite?.id, pageNo: 0, pageSize: 5 });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_SUCCESS, ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WAGE_REGISTER_EMPLOYEE);
    }
}

export function* getCardCountInWageRegister({ payload }) {
    yield call(handleAPIRequest, getCardCountInWageRegisterApi, payload);
}

export function* deleteWageRegisterDetails({ payload }) {
    yield fork(handleAPIRequest, deleteWageRegisterDetailsApi, payload.id);
    const action = yield take([ACTION_TYPES.DELETE_WAGE_REGISTER_SUCCESS, ACTION_TYPES.DELETE_WAGE_REGISTER_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_WAGE_REGISTER_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("wage_details_deleted_successfully") }));
        yield fork(handleAPIRequest, fetchSiteWageRegisterApi, { siteId: payload?.siteId, pageNo: 0, pageSize: 5 });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_LIST_SUCCESS, ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WAGE_REGISTER_LIST);
    }
}

export function* downloadEmployeePayslip(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { wageRegisterId } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.LABOUR_MANAGEMENT.DOWNLOAD_EMPLOYEE_PAYSLIP,
        data: { type, wageRegisterId },
        file: { name: fileName, ext: type }
    }));
}

export function* getWorkplaceAccidentList({ payload }) {
    yield fork(handleAPIRequest, getWorkplaceAccidentListApi, { siteId: payload?.id, pageNo: payload.pageNo, pageSize: payload?.pageSize });
    const COMPARISON_TYPES = [ACTION_TYPES.WORKPLACE_ACCIDENT_DETAILS_LIST_SUCCESS, ACTION_TYPES.WORKPLACE_ACCIDENT_DETAILS_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WORKPLACE_ACCIDENT);
}

export function* getEmployeeNameDropdown({ payload }) {
    yield call(handleAPIRequest, getEmployeeNameDropdownApi, payload);
}

export function* getEmployeeDetailsById({ payload }) {
    yield call(handleAPIRequest, getEmployeeDetailsByIdApi, payload);
}

export function* fetchFatalDropdown({ payload }) {
    yield call(handleAPIRequest, fetchFatalDropdownApi, payload);
}

const formatWorkplaceAccidentDetails = (payload) => {
    const { dateTime, ...rest } = payload;
    const data = { dateTime: toEpoch(dateTime), ...rest };
    return data;
};

export function* postWorkplaceAccidentDetails(action) {
    let { payload: { id } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, updateWorkplaceAccidentDetailsApi, formatWorkplaceAccidentDetails(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_DETAILS_SUCCESS, ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("workplace_accident_details") }) }));
            yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.LABOUR_MANAGEMENT.PROJECT_LIST}/${action?.payload.projectId}/${PATH.LABOUR_MANAGEMENT.PROJECT_SITE_LIST}/${action?.payload.projectSite?.id}/${PATH.LABOUR_MANAGEMENT.INCIDENTS}`));
        }
    } else {
        yield fork(handleAPIRequest, saveWorkplaceAccidentDetailsApi, formatWorkplaceAccidentDetails(action.payload));
        const responseAction = yield take([ACTION_TYPES.POST_WORKPLACE_ACCIDENT_DETAILS_SUCCESS, ACTION_TYPES.POST_WORKPLACE_ACCIDENT_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_WORKPLACE_ACCIDENT_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("workplace_accident_details_added_successfully") }) }));
            yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.LABOUR_MANAGEMENT.PROJECT_LIST}/${action?.payload.projectId}/${PATH.LABOUR_MANAGEMENT.PROJECT_SITE_LIST}/${action?.payload.projectSite?.id}/${PATH.LABOUR_MANAGEMENT.INCIDENTS}`));
        }
    }
}

export function* getWorkplaceAccidentDetailsById({ payload }) {
    yield call(handleAPIRequest, fetchWorkplaceAccidentDetailsByIdApi, payload);
}

export function* fetchHarassmentDetail({ payload }) {
    yield fork(handleAPIRequest, fetchHarassmentDetailApi, { siteId: payload?.id, pageNo: payload.pageNo, pageSize: payload?.pageSize });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_HARASSMENT_DETAILS_LIST_SUCCESS, ACTION_TYPES.FETCH_HARASSMENT_DETAILS_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.HARASSMENT_COMPLAINTS);
}

const formatNoticeRespondent = (payload) => {
    const { submissionDate, ...rest } = payload;
    const data = { submissionDate: toEpoch(submissionDate), ...rest };
    return data;
};
export function* postNoticeRespondent(action) {
    let { payload: { id } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, patchNoticeRespondentApi, formatNoticeRespondent(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_NOTICE_RESPONDENT_SUCCESS, ACTION_TYPES.PATCH_NOTICE_RESPONDENT_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_NOTICE_RESPONDENT_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("notice_respondent_updated_successfully") }));
            yield call(handleAPIRequest, getAccordionDetailsApi, responseAction?.payload?.id);
        }
    } else {
        yield fork(handleAPIRequest, submitNoticeRespondentApi, formatNoticeRespondent(action?.payload));
        const responseAction = yield take([ACTION_TYPES.SUBMIT_NOTICE_RESPONDENT_SUCCESS, ACTION_TYPES.SUBMIT_NOTICE_RESPONDENT_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_NOTICE_RESPONDENT_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("notice_respondent_added_successfully") }));
            yield call(handleAPIRequest, getAccordionDetailsApi, responseAction?.payload?.id);
        }
    }
}

export function* postResponseRespondent(action) {
    let { payload: { id } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, patchResponseRespondentApi, formatNoticeRespondent(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_RESPONSE_FROM_RESPONDENT_SUCCESS, ACTION_TYPES.PATCH_RESPONSE_FROM_RESPONDENT_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_RESPONSE_FROM_RESPONDENT_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("response_respondent_updated_successfully") }));
            yield call(handleAPIRequest, getResponseAccordionApi, responseAction?.payload?.id);
        }
    } else {
        yield fork(handleAPIRequest, submitResponseRespondentApi, formatNoticeRespondent(action?.payload));
        const responseAction = yield take([ACTION_TYPES.SUBMIT_RESPONSE_FROM_RESPONDENT_SUCCESS, ACTION_TYPES.SUBMIT_RESPONSE_FROM_RESPONDENT_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_RESPONSE_FROM_RESPONDENT_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("response_respondent_added_successfully") }));
            yield call(handleAPIRequest, getResponseAccordionApi, responseAction?.payload?.id);
        }
    }
}

export function* postComplaintSettlement(action) {
    let { payload: { id } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, patchComplaintSettlementApi, formatNoticeRespondent(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_COMPLAINT_SETTLEMENT_SUCCESS, ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_MEMBER_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_COMPLAINT_SETTLEMENT_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("complaint_settlement_updated_successfully") }));
            yield call(handleAPIRequest, getComplaintAccordionDetailsApi, responseAction?.payload?.id);
        }
    } else {
        yield fork(handleAPIRequest, submitComplaintSettlementApi, formatNoticeRespondent(action?.payload));
        const responseAction = yield take([ACTION_TYPES.SUBMIT_COMPLAINT_SETTLEMENT_SUCCESS, ACTION_TYPES.SUBMIT_COMPLAINT_SETTLEMENT_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_COMPLAINT_SETTLEMENT_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("complaint_settlement_added_successfully") }));
            yield call(handleAPIRequest, getComplaintAccordionDetailsApi, responseAction?.payload?.id);
        }
    }
}

export function* postHearing(action) {
    let { payload: { id } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, patchHearingApi, formatNoticeRespondent(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_HEARING_DETAILS_SUCCESS, ACTION_TYPES.PATCH_HEARING_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_HEARING_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("hearing_details_updated_successfully") }));
            yield call(handleAPIRequest, getHearingAccordionDetailsApi, responseAction?.payload?.id);
        }
    } else {
        yield fork(handleAPIRequest, submitHearingApi, formatNoticeRespondent(action?.payload));
        const responseAction = yield take([ACTION_TYPES.SUBMIT_HEARING_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_HEARING_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_HEARING_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("hearing_details_added_successfully") }));
            yield call(handleAPIRequest, getHearingAccordionDetailsApi, responseAction?.payload?.id);
        }
    }
}

export function* getAccordionDetails({ payload }) {
    yield call(handleAPIRequest, getAccordionDetailsApi, payload);
}

export function* getResponseAccordion({ payload }) {
    yield call(handleAPIRequest, getResponseAccordionApi, payload);
}
export function* getComplaintAccordionDetails({ payload }) {
    yield call(handleAPIRequest, getComplaintAccordionDetailsApi, payload);
}
export function* getHearingAccordionDetails({ payload }) {
    yield call(handleAPIRequest, getHearingAccordionDetailsApi, payload);
}
const formatHarassmentDate = (payload) => {
    const { dateOfReceipt, dateTime, ...rest } = payload;
    const data = { dateTime: toEpoch(dateTime), dateOfReceipt: toEpoch(dateOfReceipt), ...rest };
    return data;
};
export function* postHarassmentComplaint(action) {
    let { payload: { id } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, patchHarassmentComplaintApi, formatHarassmentDate(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_HARASSMENT_COMPLAINTS_SUCCESS, ACTION_TYPES.PATCH_HARASSMENT_COMPLAINTS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_HARASSMENT_COMPLAINTS_SUCCESS) {
            yield put(sliceActions.setCurrentTab(1));
            yield put(successNotify({ title: I18n("success"), message: I18n("harassment_complaint_updated_successfully") }));
            yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.LABOUR_MANAGEMENT.PROJECT_LIST}/${action?.payload.projectId}/${PATH.LABOUR_MANAGEMENT.PROJECT_SITE_LIST}/${action?.payload.site?.id}/${PATH.LABOUR_MANAGEMENT.INCIDENTS}`));
        }
    } else {
        yield fork(handleAPIRequest, postHarassmentComplaintApi, formatHarassmentDate(action?.payload));
        const responseAction = yield take([ACTION_TYPES.POST_HARASSMENT_COMPLAINTS_SUCCESS, ACTION_TYPES.POST_HARASSMENT_COMPLAINTS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_HARASSMENT_COMPLAINTS_SUCCESS) {
            yield put(sliceActions.setCurrentTab(1));
            yield put(successNotify({ title: I18n("success"), message: I18n("harassment_complaint_added_successfully") }));
            yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.LABOUR_MANAGEMENT.PROJECT_LIST}/${action?.payload.projectId}/${PATH.LABOUR_MANAGEMENT.PROJECT_SITE_LIST}/${action?.payload.site?.id}/${PATH.LABOUR_MANAGEMENT.INCIDENTS}`));
        }
    }
}

export function* getHarassmentById({ payload }) {
    yield call(handleAPIRequest, getHarassmentComplaintByIdApi, payload);
}
export function* postEmployeeAttendance({ payload }) {
    yield fork(handleAPIRequest, postEmployeeAttendanceApi, payload);
    const responseAction = yield take([ACTION_TYPES.POST_EMPLOYEE_ATTENDANCE_SUCCESS, ACTION_TYPES.POST_EMPLOYEE_ATTENDANCE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_EMPLOYEE_ATTENDANCE_SUCCESS) {
        yield call(handleAPIRequest, getCardCountInAttendanceRegisterApi, payload);
        yield call(handleAPIRequest, fetchEmployeeAttendanceListApi, { pageSize: 5, pageNo: 0, ...payload });
    }
}

export function* getCardCountInAttendanceRegister({ payload }) {
    yield call(handleAPIRequest, getCardCountInAttendanceRegisterApi, payload);
}

export function* fetchEmployeeAttendanceList({ payload }) {
    yield call(handleAPIRequest, fetchEmployeeAttendanceListApi, payload);
}

export function* getAttendanceTypeDropdown({ payload }) {
    yield call(handleAPIRequest, getAttendanceTypeDropdownApi, payload);
}

export function* patchEmployeeAttendanceDetails({ payload }) {
    yield fork(handleAPIRequest, patchEmployeeAttendanceDetailsApi, formatAttendanceData(payload));
    const responseAction = yield take([ACTION_TYPES.PATCH_EMPLOYEE_ATTENDANCE_SUCCESS, ACTION_TYPES.PATCH_EMPLOYEE_ATTENDANCE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_EMPLOYEE_ATTENDANCE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
        yield call(handleAPIRequest, fetchEmployeeAttendanceListApi, { pageSize: 5, pageNo: 0, entryDate: payload.entryDate, siteId: payload.siteId });
        yield put(sliceActions.clearSetNewPage());
        yield call(handleAPIRequest, getCardCountInAttendanceRegisterApi, { entryDate: payload.entryDate, siteId: payload.siteId });
    }
}

export function* getEmployeeAttendanceById({ payload }) {
    yield call(handleAPIRequest, getEmployeeAttendanceByIdApi, payload);
}

export function* downloadAttendanceReport(action) {
    let { fileName, startDate, endDate, siteId } = action.payload;
    startDate = toEpoch(startDate);
    endDate = toEpoch(endDate);
    yield put(commonFileDownload({
        url: API_URL.LABOUR_MANAGEMENT.DOWNLOAD_ATTENDANCE_REPORT,
        data: { startDate, endDate, siteId },
        file: { name: fileName }
    }));
}

export function* getRecoveryRegisterList({ payload }) {
    yield fork(handleAPIRequest, listRecoveryRegisterApi, { pageSize: payload.pageSize, pageNo: payload.pageNo, siteId: payload?.id });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_RECOVERY_REGISTER_LIST_SUCCESS, ACTION_TYPES.FETCH_RECOVERY_REGISTER_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.RECOVERY_REGISTER_LIST);
}

export function* downloadRecoveryRegister(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { siteId } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.LABOUR_MANAGEMENT.DOWNLOAD_RECOVERY_REGISTER,
        data: { type, siteId },
        file: { name: fileName, ext: type }
    }));
}

export function* getMaternityLeaveList({ payload }) {
    yield fork(handleAPIRequest, listMaternityLeaveApi, { pageSize: payload?.pageSize, pageNo: payload?.pageNo, siteId: payload?.id });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_MATERNITY_LEAVE_LIST_SUCCESS, ACTION_TYPES.FETCH_MATERNITY_LEAVE_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.MATERNITY_LEAVE_LIST);
}

export function* fetchEmployeeDropdown({ payload }) {
    yield call(handleAPIRequest, getEmployeeDropdownApi, payload);
}

export function* fetchRecoveryDropdown({ payload }) {
    yield call(handleAPIRequest, getRecoveryDropdownApi, payload);
}

export function* postRecoveryDetails({ payload }) {
    yield fork(handleAPIRequest, postRecoveryRegisterApi, formatRecoveryData(payload));
    const responseAction = yield take([ACTION_TYPES.POST_RECOVERY_DETAILS_SUCCESS, ACTION_TYPES.POST_RECOVERY_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_RECOVERY_DETAILS_SUCCESS) {
        yield put(sliceActions.setCurrentTab(2));
        yield put(successNotify({ title: I18n("success"), message: I18n("new_record_added_successfully") }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.LABOUR_MANAGEMENT.PROJECT_LIST}/${payload?.projectId}/${PATH.LABOUR_MANAGEMENT.PROJECT_SITE_LIST}/${payload?.site?.id}/${PATH.LABOUR_MANAGEMENT.INCIDENTS}`));
    }
}

export function* fetchIncidentEmployeeDetails({ payload }) {
    yield call(handleAPIRequest, getIncidentEmployeeDetailApi, payload);
}

export function* fetchRecoveryDetailById({ payload }) {
    yield call(handleAPIRequest, getRecoveryDetailApi, payload);
}

export function* fetchCausedIssueDropdown({ payload }) {
    yield call(handleAPIRequest, getCausedIssueDropdownApi, payload);
}

export function* fetchMaternityTypesDropdown({ payload }) {
    yield call(handleAPIRequest, getMaternityTypesDropdownApi, payload);
}

export function* fetchMaternityDetailById({ payload }) {
    yield call(handleAPIRequest, getMaternityDetailApi, payload);
}

export function* postMaternityDetails({ payload }) {
    yield fork(handleAPIRequest, postMaternityRegisterApi, formatMaternityData(payload));
    const responseAction = yield take([ACTION_TYPES.POST_MATERNITY_DETAILS_SUCCESS, ACTION_TYPES.POST_MATERNITY_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_MATERNITY_DETAILS_SUCCESS) {
        yield put(sliceActions.setCurrentTab(3));
        yield put(successNotify({ title: I18n("success"), message: I18n("new_record_added_successfully") }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.LABOUR_MANAGEMENT.PROJECT_LIST}/${payload?.projectId}/${PATH.LABOUR_MANAGEMENT.PROJECT_SITE_LIST}/${payload?.projectSite?.id}/${PATH.LABOUR_MANAGEMENT.INCIDENTS}`));
    }
}

export function* patchRecoveryDetails({ payload }) {
    yield fork(handleAPIRequest, patchRecoveryRegisterApi, formatRecoveryData(payload));
    const responseAction = yield take([ACTION_TYPES.PATCH_RECOVERY_DETAILS_SUCCESS, ACTION_TYPES.PATCH_RECOVERY_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_RECOVERY_DETAILS_SUCCESS) {
        yield put(sliceActions.setCurrentTab(2));
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.LABOUR_MANAGEMENT.PROJECT_LIST}/${payload?.projectId}/${PATH.LABOUR_MANAGEMENT.PROJECT_SITE_LIST}/${payload?.site?.id}/${PATH.LABOUR_MANAGEMENT.INCIDENTS}`));
    }
}

export function* patchMaternityDetail({ payload }) {
    yield fork(handleAPIRequest, patchMaternityDetailApi, formatMaternityData(payload));
    const responseAction = yield take([ACTION_TYPES.PATCH_MATERNITY_DETAILS_SUCCESS, ACTION_TYPES.PATCH_MATERNITY_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_MATERNITY_DETAILS_SUCCESS) {
        yield put(sliceActions.setCurrentTab(3));
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.LABOUR_MANAGEMENT.PROJECT_LIST}/${payload?.projectId}/${PATH.LABOUR_MANAGEMENT.PROJECT_SITE_LIST}/${payload?.projectSite?.id}/${PATH.LABOUR_MANAGEMENT.INCIDENTS}`));
    }
}

export function* deleteProjectSite({ payload }) {
    yield fork(handleAPIRequest, deleteProjectSiteDetailsApi, payload.id);
    const action = yield take([ACTION_TYPES.DELETE_PROJECT_SITE_DETAILS_SUCCESS, ACTION_TYPES.DELETE_PROJECT_SITE_DETAILS_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_PROJECT_SITE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("project_site_deleted_successfully") }));
        yield fork(handleAPIRequest, listProjectSitesApi, { pageSize: 5, pageNo: 0, projectId: payload?.projectId });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PROJECT_SITE_LIST_SUCCESS, ACTION_TYPES.FETCH_PROJECT_SITE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.PROJECT_SITE_LIST);
    }
}

export function* getVerificationStatus({ payload }) {
    yield call(handleAPIRequest, getVerificationStatusApi, payload);
}

export function* patchWageVerification({ payload }) {
    yield fork(handleAPIRequest, patchWageVerificationApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_WAGE_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_WAGE_VERIFICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_WAGE_VERIFICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
    }
}

export function* fetchEventsTrackedInMinWage({ payload }) {
    yield call(handleAPIRequest, fetchEventsTrackedInMinWageApi, payload);
}

export function* patchBasicFacilitiesVerification({ payload }) {
    yield fork(handleAPIRequest, patchBasicFacilitiesVerificationApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_BASIC_FACILITIES_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_BASIC_FACILITIES_VERIFICATION_SUCCESS]);
    if (responseAction.type === ACTION_TYPES.PATCH_BASIC_FACILITIES_VERIFICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
    }
}

export function* fetchEventsTrackedInBasicFacilities({ payload }) {
    yield call(handleAPIRequest, fetchEventsTrackedInBasicFacilitiesApi, payload);
}

export function* patchWageDetailsVerification({ payload }) {
    yield fork(handleAPIRequest, patchWageDetailsVerificationApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_WAGE_DETAILS_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_WAGE_DETAILS_VERIFICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_WAGE_DETAILS_VERIFICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
    }
}

export function* fetchEventsTrackedInWageDetails({ payload }) {
    yield call(handleAPIRequest, fetchEventsTrackedInWageDetailsApi, payload);
}

export function* patchWageRegisterVerification({ payload }) {
    yield fork(handleAPIRequest, patchWageRegisterVerificationApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_WAGE_REGISTER_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_WAGE_REGISTER_VERIFICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_WAGE_REGISTER_VERIFICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
    }
}

export function* fetchEventsTrackedInWageRegister({ payload }) {
    yield call(handleAPIRequest, fetchEventsTrackedInWageRegisterApi, payload);
}

export function* patchWageAttendanceRegisterVerification({ payload }) {
    yield fork(handleAPIRequest, patchAttendanceRegisterVerificationApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_ATTENDANCE_REGISTER_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_ATTENDANCE_REGISTER_VERIFICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_ATTENDANCE_REGISTER_VERIFICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
    }
}

export function* fetchEventsTrackedInAttendanceRegister({ payload }) {
    yield call(handleAPIRequest, fetchEventsTrackedInAttendanceRegisterApi, payload);
}

export function* patchMaternityLeaveVerification({ payload }) {
    yield fork(handleAPIRequest, patchMaternityLeaveVerificationApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_MATERNITY_LEAVE_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_MATERNITY_LEAVE_VERIFICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_MATERNITY_LEAVE_VERIFICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
    }
}

export function* fetchEventsTrackedInMaternity({ payload }) {
    yield call(handleAPIRequest, fetchEventsTrackedInMaternityApi, payload);
}

export function* patchRecoveryRegisterVerification({ payload }) {
    yield fork(handleAPIRequest, patchRecoveryRegisterVerificationApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_RECOVERY_REGISTER_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_RECOVERY_REGISTER_VERIFICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_RECOVERY_REGISTER_VERIFICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
    }
}

export function* fetchEventsTrackedInRecoveryRegister({ payload }) {
    yield call(handleAPIRequest, fetchEventsTrackedInRecoveryRegisterApi, payload);
}

export function* patchWorkPlaceAccidentVerification({ payload }) {
    yield fork(handleAPIRequest, patchWorkPlaceAccidentVerificationApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_VERIFICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_VERIFICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
    }
}

export function* fetchEventsTrackedInWorkplaceAccident({ payload }) {
    yield call(handleAPIRequest, fetchEventsTrackedInWorkplaceAccidentApi, payload);
}

export function* patchHarassmentComplaintVerification({ payload }) {
    yield fork(handleAPIRequest, patchHarassmentComplaintVerificationApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_HARASSMENT_COMPLAINT_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_HARASSMENT_COMPLAINT_VERIFICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_HARASSMENT_COMPLAINT_VERIFICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
    }
}

export function* fetchEventsTrackedInHarassmentComplaint({ payload }) {
    yield call(handleAPIRequest, fetchEventsTrackedInHarassmentComplaintApi, payload);
}

export function* patchDpmuRemarks({ payload }) {
    yield fork(handleAPIRequest, patchDpmuRemarksApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_NOTICE_BOARD_DPMU_REMARKS_SUCCESS, ACTION_TYPES.PATCH_NOTICE_BOARD_DPMU_REMARKS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_NOTICE_BOARD_DPMU_REMARKS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
    }
}

export function* patchGrievanceCommitteeDpmuRemarks({ payload }) {
    yield fork(handleAPIRequest, patchGrievanceCommitteeDpmuRemarksApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_SUCCESS, ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
        yield fork(handleAPIRequest, fetchGrievanceCommitteeListApi, { pageNo: 0, pageSize: 5, siteId: payload?.siteId });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.GRIEVANCE_REDRESSAL_COMMITTEE_LIST);
    }
}

export function* patchComplaintCommitteeDpmuRemarks({ payload }) {
    yield fork(handleAPIRequest, patchComplaintCommitteeDpmuRemarksApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_SUCCESS, ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
        yield fork(handleAPIRequest, fetchInternalComplaintCommitteeListApi, { pageNo: 0, pageSize: 5, siteId: payload?.siteId });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_SUCCESS, ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.INTERNAL_COMPLAINT_COMMITTEE_LIST);
    }
}

export function* patchEmployeeDpmuRemarks({ payload }) {
    yield fork(handleAPIRequest, patchEmployeeDpmuRemarksApi, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_EMPLOYEE_DPMU_REMARKS_SUCCESS, ACTION_TYPES.PATCH_EMPLOYEE_DPMU_REMARKS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_EMPLOYEE_DPMU_REMARKS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated_successfully") }));
        yield fork(handleAPIRequest, fetchEmployeeListApi, { pageNo: 0, pageSize: 5, siteId: payload.siteId, isActive: true });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_EMPLOYEE_LIST_SUCCESS, ACTION_TYPES.FETCH_EMPLOYEE_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.EMPLOYEE_LIST);
    }
}

export default function* testSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_PROJECT_LIST, getProjectList),
        takeLatest(ACTION_TYPES.FETCH_PROJECT_SITE_LIST, getProjectSitesList),
        takeLatest(ACTION_TYPES.FETCH_NATURE_OF_WORK_DROPDOWN, natureOfWorkDropdown),
        takeLatest(ACTION_TYPES.FETCH_WAGE_PERIOD_DROPDOWN, wagePeriodDropdown),
        takeLatest(ACTION_TYPES.FETCH_DAY_OF_HOLIDAY_DROPDOWN, dayOfHolidayDropdown),
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN, districtDropdown),
        takeLatest(ACTION_TYPES.FETCH_ULB_DROPDOWN, ulbDropdown),
        takeLatest(ACTION_TYPES.FETCH_BASIC_DETAILS_BY_ID, basicDetailsById),
        takeLatest(ACTION_TYPES.POST_BASIC_DETAILS, submitBasicDetails),
        takeLatest(ACTION_TYPES.FETCH_PROJECT_MANAGER_DETAILS_BY_ID, projectManagerDetailsById),
        takeLatest(ACTION_TYPES.POST_PROJECT_MANAGER_DETAILS, submitProjectManagerDetails),
        takeLatest(ACTION_TYPES.FETCH_PROJECT_MANAGER_LIST, getProjectManagerList),
        takeLatest(ACTION_TYPES.PATCH_PROJECT_MANAGER_DETAILS, updateProjectManagerDetails),
        takeLatest(ACTION_TYPES.FETCH_MINIMUM_WAGE, getMinimumWages),
        takeLatest(ACTION_TYPES.FETCH_MINIMUM_WAGE_BY_ID, getMinimumWagesById),
        takeLatest(ACTION_TYPES.POST_MINIMUM_WAGE, submitMinimumWages),
        takeLatest(ACTION_TYPES.FETCH_REGISTRATION_NUMBER, getRegistrationNumber),
        takeLatest(ACTION_TYPES.FETCH_EMPLOYEE_LIST, fetchEmployee),
        takeLatest(ACTION_TYPES.FETCH_GENDER_DROPDOWN, genderDropdown),
        takeLatest(ACTION_TYPES.FETCH_NATIONALITY_DROPDOWN, nationalityDropdown),
        takeLatest(ACTION_TYPES.FETCH_EDUCATION_LEVEL_DROPDOWN, educationLevelDropdown),
        takeLatest(ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN, designationDropdown),
        takeLatest(ACTION_TYPES.FETCH_CATEGORY_DROPDOWN, categoryDropdown),
        takeLatest(ACTION_TYPES.FETCH_EMPLOYMENT_DROPDOWN, employmentDropdown),
        takeLatest(ACTION_TYPES.FETCH_SUB_CONTRACTOR_DROPDOWN, subContractDropdown),
        takeLatest(ACTION_TYPES.FETCH_WORKMAN_RADIO_BUTTON, workmanRadioButton),
        takeLatest(ACTION_TYPES.EMPLOYEE_DETAILS_BY_ID, employeeDetails),
        takeLatest(ACTION_TYPES.FETCH_MARITAL_STATUS_RADIO_BUTTON, maritalStatusRadioButton),
        takeLatest(ACTION_TYPES.POST_EMPLOYEE_DETAILS, submitEmployee),
        takeLatest(ACTION_TYPES.PATCH_EMPLOYEE_DETAILS, patchEmployee),
        takeLatest(ACTION_TYPES.DELETE_EMPLOYEE_DETAILS, deleteEmployeeDetails),
        takeLatest(ACTION_TYPES.FETCH_REGISTRATION_NUMBER_BY_ID, getRegistrationNumberById),
        takeLatest(ACTION_TYPES.PATCH_REGISTRATION_NUMBER, submitRegistrationNumber),
        takeLatest(ACTION_TYPES.FETCH_BASIC_FACILITIES_BY_ID, getBasicFacilitiesById),
        takeLatest(ACTION_TYPES.PATCH_BASIC_FACILITIES, updateBasicFacilities),
        takeLatest(ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST, getGrievanceCommitteeList),
        takeLatest(ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_BY_ID, getGrievanceCommitteeById),
        takeLatest(ACTION_TYPES.POST_GRIEVANCE_COMMITTEE, submitGrievanceCommittee),
        takeLatest(ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE, updateGrievanceCommittee),
        takeLatest(ACTION_TYPES.PATCH_BASIC_DETAILS, updateBasicDetails),
        takeLatest(ACTION_TYPES.FETCH_NOTICE_BOARD_BY_ID, getNoticeBoardById),
        takeLatest(ACTION_TYPES.PATCH_NOTICE_BOARD, updateNoticeBoard),
        takeLatest(ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST, getInternalComplaintCommitteeList),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN, getComplaintCommitteeDesignationDropdown),
        takeLatest(ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID, getComplaintCommitteeById),
        takeLatest(ACTION_TYPES.POST_COMPLAINT_COMMITTEE_MEMBER, submitComplaintCommittee),
        takeLatest(ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_MEMBER, updateComplaintCommittee),
        takeLatest(ACTION_TYPES.FETCH_WAGE_DETAILS_LIST, wageDetails),
        takeLatest(ACTION_TYPES.POST_WAGE_DETAILS, submitWageDetailsOfEmployee),
        takeLatest(ACTION_TYPES.PATCH_WAGE_DETAILS, submitWageDetailsOfEmployee),
        takeLatest(ACTION_TYPES.GET_WAGE_DETAILS_BY_ID, getWageDetailsById),
        takeLatest(ACTION_TYPES.DELETE_WAGE_DETAILS, deleteWageDetails),
        takeLatest(ACTION_TYPES.POST_EMPLOYEE_TERMINATION_PROCESS, postEmployeeTermination),
        takeLatest(ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_LIST, siteWageRegister),
        takeLatest(ACTION_TYPES.POST_SITE_WAGE_REGISTER, postSiteWageRegister),
        takeLatest(ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST, siteWageRegisterEmployee),
        takeLatest(ACTION_TYPES.GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID, getSiteWageRegisterEmployeeDetailsById),
        takeLatest(ACTION_TYPES.PATCH_SITE_WAGE_EMPLOYEE_DETAILS, patchSiteWageEmployeeDetails),
        takeLatest(ACTION_TYPES.GET_CARD_COUNT_IN_WAGE_REGISTER, getCardCountInWageRegister),
        takeLatest(ACTION_TYPES.DELETE_WAGE_REGISTER, deleteWageRegisterDetails),
        takeLatest(ACTION_TYPES.DOWNLOAD_EMPLOYEE_PAYSLIP, downloadEmployeePayslip),
        takeLatest(ACTION_TYPES.FETCH_EMPLOYEE_VERIFICATION, fetchEmployeeVerificationList),
        takeLatest(ACTION_TYPES.PATCH_EMPLOYEE_VERIFICATION, submitEmployeeVerification),
        takeLatest(ACTION_TYPES.WORKPLACE_ACCIDENT_DETAILS_LIST, getWorkplaceAccidentList),
        takeLatest(ACTION_TYPES.GET_EMPLOYEE_NAME_WITH_CODE, getEmployeeNameDropdown),
        takeLatest(ACTION_TYPES.GET_EMPLOYEE_DETAILS_BY_ID, getEmployeeDetailsById),
        takeLatest(ACTION_TYPES.GET_FATAL_DROPDOWN, fetchFatalDropdown),
        takeLatest(ACTION_TYPES.POST_WORKPLACE_ACCIDENT_DETAILS, postWorkplaceAccidentDetails),
        takeLatest(ACTION_TYPES.GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID, getWorkplaceAccidentDetailsById),
        takeLatest(ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_DETAILS, postWorkplaceAccidentDetails),
        takeLatest(ACTION_TYPES.FETCH_HARASSMENT_DETAILS_LIST, fetchHarassmentDetail),
        takeLatest(ACTION_TYPES.SUBMIT_NOTICE_RESPONDENT, postNoticeRespondent),
        takeLatest(ACTION_TYPES.SUBMIT_RESPONSE_FROM_RESPONDENT, postResponseRespondent),
        takeLatest(ACTION_TYPES.SUBMIT_COMPLAINT_SETTLEMENT, postComplaintSettlement),
        takeLatest(ACTION_TYPES.SUBMIT_HEARING_DETAILS, postHearing),
        takeLatest(ACTION_TYPES.ACCORDION_DETAILS_BY_ID, getAccordionDetails),
        takeLatest(ACTION_TYPES.POST_HARASSMENT_COMPLAINTS, postHarassmentComplaint),
        takeLatest(ACTION_TYPES.RESPONSE_ACCORDION_BY_ID, getResponseAccordion),
        takeLatest(ACTION_TYPES.COMPLAINT_ACCORDION_BY_ID, getComplaintAccordionDetails),
        takeLatest(ACTION_TYPES.HEARING_ACCORDION_BY_ID, getHearingAccordionDetails),
        takeLatest(ACTION_TYPES.HARASSMENT_COMPLAINT_BY_ID, getHarassmentById),
        takeLatest(ACTION_TYPES.PATCH_HARASSMENT_COMPLAINTS, postHarassmentComplaint),
        takeLatest(ACTION_TYPES.POST_EMPLOYEE_ATTENDANCE, postEmployeeAttendance),
        takeLatest(ACTION_TYPES.GET_CARD_COUNT_IN_ATTENDANCE_REGISTER, getCardCountInAttendanceRegister),
        takeLatest(ACTION_TYPES.FETCH_EMPLOYEE_ATTENDANCE_LIST, fetchEmployeeAttendanceList),
        takeLatest(ACTION_TYPES.GET_ATTENDANCE_TYPE_DROPDOWN, getAttendanceTypeDropdown),
        takeLatest(ACTION_TYPES.PATCH_EMPLOYEE_ATTENDANCE, patchEmployeeAttendanceDetails),
        takeLatest(ACTION_TYPES.GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID, getEmployeeAttendanceById),
        takeLatest(ACTION_TYPES.DOWNLOAD_ATTENDANCE_REPORT, downloadAttendanceReport),
        takeLatest(ACTION_TYPES.FETCH_RECOVERY_REGISTER_LIST, getRecoveryRegisterList),
        takeLatest(ACTION_TYPES.DOWNLOAD_RECOVERY_REGISTER, downloadRecoveryRegister),
        takeLatest(ACTION_TYPES.FETCH_EMPLOYEE_DROPDOWN, fetchEmployeeDropdown),
        takeLatest(ACTION_TYPES.FETCH_RECOVERY_TYPE_DROPDOWN, fetchRecoveryDropdown),
        takeLatest(ACTION_TYPES.POST_RECOVERY_DETAILS, postRecoveryDetails),
        takeLatest(ACTION_TYPES.INCIDENCE_EMPLOYEE_DETAILS_BY_ID, fetchIncidentEmployeeDetails),
        takeLatest(ACTION_TYPES.RECOVERY_DETAILS_BY_ID, fetchRecoveryDetailById),
        takeLatest(ACTION_TYPES.FETCH_CAUSED_ISSUE_DROPDOWN, fetchCausedIssueDropdown),
        takeLatest(ACTION_TYPES.FETCH_MATERNITY_TYPE_DROPDOWN, fetchMaternityTypesDropdown),
        takeLatest(ACTION_TYPES.FETCH_MATERNITY_LEAVE_LIST, getMaternityLeaveList),
        takeLatest(ACTION_TYPES.MATERNITY_DETAILS_BY_ID, fetchMaternityDetailById),
        takeLatest(ACTION_TYPES.POST_MATERNITY_DETAILS, postMaternityDetails),
        takeLatest(ACTION_TYPES.PATCH_RECOVERY_DETAILS, patchRecoveryDetails),
        takeLatest(ACTION_TYPES.PATCH_MATERNITY_DETAILS, patchMaternityDetail),
        takeLatest(ACTION_TYPES.DELETE_PROJECT_SITE_DETAILS, deleteProjectSite),
        takeLatest(ACTION_TYPES.GET_VERIFICATION_STATUS_DROPDOWN, getVerificationStatus),
        takeLatest(ACTION_TYPES.PATCH_WAGE_VERIFICATION, patchWageVerification),
        takeLatest(ACTION_TYPES.FETCH_EVENTS_TRACKED_MINIMUM_WAGE, fetchEventsTrackedInMinWage),
        takeLatest(ACTION_TYPES.PATCH_BASIC_FACILITIES_VERIFICATION, patchBasicFacilitiesVerification),
        takeLatest(ACTION_TYPES.FETCH_EVENTS_TRACKED_BASIC_FACILITIES, fetchEventsTrackedInBasicFacilities),
        takeLatest(ACTION_TYPES.PATCH_WAGE_DETAILS_VERIFICATION, patchWageDetailsVerification),
        takeLatest(ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_DETAILS, fetchEventsTrackedInWageDetails),
        takeLatest(ACTION_TYPES.PATCH_WAGE_REGISTER_VERIFICATION, patchWageRegisterVerification),
        takeLatest(ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_REGISTER, fetchEventsTrackedInWageRegister),
        takeLatest(ACTION_TYPES.PATCH_ATTENDANCE_REGISTER_VERIFICATION, patchWageAttendanceRegisterVerification),
        takeLatest(ACTION_TYPES.FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER, fetchEventsTrackedInAttendanceRegister),
        takeLatest(ACTION_TYPES.PATCH_MATERNITY_LEAVE_VERIFICATION, patchMaternityLeaveVerification),
        takeLatest(ACTION_TYPES.FETCH_EVENTS_TRACKED_MATERNITY_LEAVE, fetchEventsTrackedInMaternity),
        takeLatest(ACTION_TYPES.PATCH_RECOVERY_REGISTER_VERIFICATION, patchRecoveryRegisterVerification),
        takeLatest(ACTION_TYPES.FETCH_EVENTS_TRACKED_RECOVERY_REGISTER, fetchEventsTrackedInRecoveryRegister),
        takeLatest(ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_VERIFICATION, patchWorkPlaceAccidentVerification),
        takeLatest(ACTION_TYPES.FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT, fetchEventsTrackedInWorkplaceAccident),
        takeLatest(ACTION_TYPES.PATCH_HARASSMENT_COMPLAINT_VERIFICATION, patchHarassmentComplaintVerification),
        takeLatest(ACTION_TYPES.FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT, fetchEventsTrackedInHarassmentComplaint),
        takeLatest(ACTION_TYPES.PATCH_NOTICE_BOARD_DPMU_REMARKS, patchDpmuRemarks),
        takeLatest(ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS, patchGrievanceCommitteeDpmuRemarks),
        takeLatest(ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS, patchComplaintCommitteeDpmuRemarks),
        takeLatest(ACTION_TYPES.PATCH_EMPLOYEE_DPMU_REMARKS, patchEmployeeDpmuRemarks)
    ]);
}
