import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    FETCH_APPROVAL_STATUS_REPORT: `${STATE_REDUCER_KEY}/FETCH_APPROVAL_STATUS_REPORT`,
    FETCH_APPROVAL_STATUS_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_APPROVAL_STATUS_REPORT_REQUEST`,
    FETCH_APPROVAL_STATUS_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_APPROVAL_STATUS_REPORT_SUCCESS`,
    FETCH_APPROVAL_STATUS_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_APPROVAL_STATUS_REPORT_FAILURE`,

    FETCH_COMPLAINT_STATUS_REPORT: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_REPORT`,
    FETCH_COMPLAINT_STATUS_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_REPORT_REQUEST`,
    FETCH_COMPLAINT_STATUS_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_REPORT_SUCCESS`,
    FETCH_COMPLAINT_STATUS_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_STATUS_REPORT_FAILURE`,

    FETCH_COMPLAINT_DISTRICT_REPORT: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DISTRICT_REPORT`,
    FETCH_COMPLAINT_DISTRICT_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DISTRICT_REPORT_REQUEST`,
    FETCH_COMPLAINT_DISTRICT_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DISTRICT_REPORT_SUCCESS`,
    FETCH_COMPLAINT_DISTRICT_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_DISTRICT_REPORT_FAILURE`,

    FETCH_COMPLAINT_CATEGORY_REPORT: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_REPORT`,
    FETCH_COMPLAINT_CATEGORY_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_REPORT_REQUEST`,
    FETCH_COMPLAINT_CATEGORY_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_REPORT_SUCCESS`,
    FETCH_COMPLAINT_CATEGORY_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_CATEGORY_REPORT_FAILURE`
};

export const listComplaintStatusReport = createAction(ACTION_TYPES.FETCH_COMPLAINT_STATUS_REPORT);
export const listApprovalStatusReport = createAction(ACTION_TYPES.FETCH_APPROVAL_STATUS_REPORT);
export const listComplaintDistrictReport = createAction(ACTION_TYPES.FETCH_COMPLAINT_DISTRICT_REPORT);
export const listComplaintCategoryReport = createAction(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REPORT);
