import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const rejectMunicipalApi = (data) => {
    return {
        url: API_URL.APPROVE_MUNICIPAL.REJECT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.REJECT_MUNICIPAL_REQUEST, ACTION_TYPES.REJECT_MUNICIPAL_SUCCESS, ACTION_TYPES.REJECT_MUNICIPAL_FAILURE],
            data
        }
    };
};

export const approveMunicipalApi = (data) => {
    return {
        url: API_URL.APPROVE_MUNICIPAL.APPROVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.APPROVE_MUNICIPAL_REQUEST, ACTION_TYPES.APPROVE_MUNICIPAL_SUCCESS, ACTION_TYPES.APPROVE_MUNICIPAL_FAILURE],
            data
        }
    };
};

export const approveMunicipalById = (data) => {
    return {
        url: API_URL.APPROVE_MUNICIPAL.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.APPROVAL_MUNICIPAL_BY_ID_REQUEST, ACTION_TYPES.APPROVAL_MUNICIPAL_BY_ID_SUCCESS, ACTION_TYPES.APPROVAL_MUNICIPAL_BY_ID_FAILURE],
            data
        }
    };
};
