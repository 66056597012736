const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./actions");
import _ from "lodash";

const initialState = {
    wardDropdown: {
        requestInProgress: false,
        data: []
    },
    sourceForBdwDetails: {
        requestInProgress: false,
        data: {
            district: "",
            lsgi: "",
            ward: "",
            noOfHousehold: "",
            wasteGenerated: "",
            wasteProcessed: "",
            consumptionOfBiogas: "",
            saleOrUseOfCompost: "",
            noOfExistingBiogas: "",
            capacityOfBiogasPlants: "",
            totalBiogasPlants: "",
            utilizationOfCompost: "",
            compostIncome: "",
            utilizationOfBiogas: "",
            biogasIncome: "",
            collectionDate: ""
        }
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.GET_WARD_DROPDOWN_REQUEST, (state) => {
                state.wardDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_WARD_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "wardDropdown.requestInProgress", false);
                _.set(state, "wardDropdown.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.GET_WARD_DROPDOWN_FAILURE, (state) => {
                state.wardDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_SOURCE_FOR_BDW_DETAILS_BY_ID_REQUEST, (state) => {
                state.sourceForBdwDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_SOURCE_FOR_BDW_DETAILS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "sourceForBdwDetails.requestInProgress", false);
                _.set(state, "sourceForBdwDetails.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_SOURCE_FOR_BDW_DETAILS_BY_ID_FAILURE, (state) => {
                state.sourceForBdwDetails.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;

