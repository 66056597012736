import { STATE_REDUCER_KEY } from "./constants";
import { createAction } from "@reduxjs/toolkit";

export const ACTION_TYPES = {
    LIST_DPR_TRACKER: `${STATE_REDUCER_KEY}/LIST_DPR_TRACKER`,
    LIST_DPR_TRACKER_REQUEST: `${STATE_REDUCER_KEY}/LIST_DPR_TRACKER_REQUEST`,
    LIST_DPR_TRACKER_SUCCESS: `${STATE_REDUCER_KEY}/LIST_DPR_TRACKER_SUCCESS`,
    LIST_DPR_TRACKER_FAILURE: `${STATE_REDUCER_KEY}/LIST_DPR_TRACKER_FAILURE`,

    SAVE_DPR_TRACKER: `${STATE_REDUCER_KEY}/SAVE_DPR_TRACKER`,
    SAVE_DPR_TRACKER_REQUEST: `${STATE_REDUCER_KEY}/SAVE_DPR_TRACKER_REQUEST`,
    SAVE_DPR_TRACKER_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_DPR_TRACKER_SUCCESS`,
    SAVE_DPR_TRACKER_FAILURE: `${STATE_REDUCER_KEY}/SAVE_DPR_TRACKER_FAILURE`,

    PATCH_DPR_TRACKER: `${STATE_REDUCER_KEY}/PATCH_DPR_TRACKER`,
    PATCH_DPR_TRACKER_REQUEST: `${STATE_REDUCER_KEY}/PATCH_DPR_TRACKER_REQUEST`,
    PATCH_DPR_TRACKER_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_DPR_TRACKER_SUCCESS`,
    PATCH_DPR_TRACKER_FAILURE: `${STATE_REDUCER_KEY}/PATCH_DPR_TRACKER_FAILURE`,

    DELETE_DPR_TRACKER: `${STATE_REDUCER_KEY}/DELETE_DPR_TRACKER`,
    DELETE_DPR_TRACKER_REQUEST: `${STATE_REDUCER_KEY}/DELETE_DPR_TRACKER_REQUEST`,
    DELETE_DPR_TRACKER_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_DPR_TRACKER_SUCCESS`,
    DELETE_DPR_TRACKER_FAILURE: `${STATE_REDUCER_KEY}/DELETE_DPR_TRACKER_FAILURE`,

    DPR_TRACKER_BY_ID: `${STATE_REDUCER_KEY}/DPR_TRACKER_BY_ID`,
    DPR_TRACKER_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/DPR_TRACKER_BY_ID_REQUEST`,
    DPR_TRACKER_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/DPR_TRACKER_BY_ID_SUCCESS`,
    DPR_TRACKER_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/DPR_TRACKER_BY_ID_FAILURE`,

    LIST_DOCUMENT_TYPE: `${STATE_REDUCER_KEY}/LIST_DOCUMENT_TYPE`,
    LIST_DOCUMENT_TYPE_REQUEST: `${STATE_REDUCER_KEY}/LIST_DOCUMENT_TYPE_REQUEST`,
    LIST_DOCUMENT_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/LIST_DOCUMENT_TYPE_SUCCESS`,
    LIST_DOCUMENT_TYPE_FAILURE: `${STATE_REDUCER_KEY}/LIST_DOCUMENT_TYPE_FAILURE`,

    LIST_WORK_TYPE: `${STATE_REDUCER_KEY}/LIST_WORK_TYPE`,
    LIST_WORK_TYPE_REQUEST: `${STATE_REDUCER_KEY}/LIST_WORK_TYPE_REQUEST`,
    LIST_WORK_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/LIST_WORK_TYPE_SUCCESS`,
    LIST_WORK_TYPE_FAILURE: `${STATE_REDUCER_KEY}/LIST_WORK_TYPE_FAILURE`,

    LIST_ESMF_CATEGORY: `${STATE_REDUCER_KEY}/LIST_ESMF_CATEGORY`,
    LIST_ESMF_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/LIST_ESMF_CATEGORY_REQUEST`,
    LIST_ESMF_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ESMF_CATEGORY_SUCCESS`,
    LIST_ESMF_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/LIST_ESMF_CATEGORY_FAILURE`,

    LIST_DPR_CATEGORY: `${STATE_REDUCER_KEY}/LIST_DPR_CATEGORY`,
    LIST_DPR_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/LIST_DPR_CATEGORY_REQUEST`,
    LIST_DPR_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/LIST_DPR_CATEGORY_SUCCESS`,
    LIST_DPR_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/LIST_DPR_CATEGORY_FAILURE`,

    LIST_SUBPROJECT_FULL_DATA: `${STATE_REDUCER_KEY}/LIST_SUBPROJECT_FULL_DATA`,
    LIST_SUBPROJECT_FULL_DATA_REQUEST: `${STATE_REDUCER_KEY}/LIST_SUBPROJECT_FULL_DATA_REQUEST`,
    LIST_SUBPROJECT_FULL_DATA_SUCCESS: `${STATE_REDUCER_KEY}/LIST_SUBPROJECT_FULL_DATA_SUCCESS`,
    LIST_SUBPROJECT_FULL_DATA_FAILURE: `${STATE_REDUCER_KEY}/LIST_SUBPROJECT_FULL_DATA_FAILURE`,

    POST_SUBPROJECT_MAPPING: `${STATE_REDUCER_KEY}/POST_SUBPROJECT_MAPPING`,
    POST_SUBPROJECT_MAPPING_REQUEST: `${STATE_REDUCER_KEY}/POST_SUBPROJECT_MAPPING_REQUEST`,
    POST_SUBPROJECT_MAPPING_SUCCESS: `${STATE_REDUCER_KEY}/POST_SUBPROJECT_MAPPING_SUCCESS`,
    POST_SUBPROJECT_MAPPING_FAILURE: `${STATE_REDUCER_KEY}/POST_SUBPROJECT_MAPPING_FAILURE`,

    LIST_FINAL_SUBPROJECT_MAPPING: `${STATE_REDUCER_KEY}/LIST_FINAL_SUBPROJECT_MAPPING`,
    LIST_FINAL_SUBPROJECT_MAPPING_REQUEST: `${STATE_REDUCER_KEY}/LIST_FINAL_SUBPROJECT_MAPPING_REQUEST`,
    LIST_FINAL_SUBPROJECT_MAPPING_SUCCESS: `${STATE_REDUCER_KEY}/LIST_FINAL_SUBPROJECT_MAPPING_SUCCESS`,
    LIST_FINAL_SUBPROJECT_MAPPING_FAILURE: `${STATE_REDUCER_KEY}/LIST_FINAL_SUBPROJECT_MAPPING_FAILURE`,

    BY_ID_FINAL_SUBPROJECT_MAPPING: `${STATE_REDUCER_KEY}/BY_ID_FINAL_SUBPROJECT_MAPPING`,
    BY_ID_FINAL_SUBPROJECT_MAPPING_REQUEST: `${STATE_REDUCER_KEY}/BY_ID_FINAL_SUBPROJECT_MAPPING_REQUEST`,
    BY_ID_FINAL_SUBPROJECT_MAPPING_SUCCESS: `${STATE_REDUCER_KEY}/BY_ID_FINAL_SUBPROJECT_MAPPING_SUCCESS`,
    BY_ID_FINAL_SUBPROJECT_MAPPING_FAILURE: `${STATE_REDUCER_KEY}/BY_ID_FINAL_SUBPROJECT_MAPPING_FAILURE`,

    PATCH_FINAL_SUBPROJECT_MAPPING: `${STATE_REDUCER_KEY}/PATCH_FINAL_SUBPROJECT_MAPPING`,
    PATCH_FINAL_SUBPROJECT_MAPPING_REQUEST: `${STATE_REDUCER_KEY}/PATCH_FINAL_SUBPROJECT_MAPPING_REQUEST`,
    PATCH_FINAL_SUBPROJECT_MAPPING_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_FINAL_SUBPROJECT_MAPPING_SUCCESS`,
    PATCH_FINAL_SUBPROJECT_MAPPING_FAILURE: `${STATE_REDUCER_KEY}/PATCH_FINAL_SUBPROJECT_MAPPING_FAILURE`,

    SAVE_COST_DETAILS: `${STATE_REDUCER_KEY}/SAVE_COST_DETAILS`,
    SAVE_COST_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SAVE_COST_DETAILS_REQUEST`,
    SAVE_COST_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_COST_DETAILS_SUCCESS`,
    SAVE_COST_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SAVE_COST_DETAILS_FAILURE`,

    PATCH_COST_DETAILS: `${STATE_REDUCER_KEY}/PATCH_COST_DETAILS`,
    PATCH_COST_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_COST_DETAILS_REQUEST`,
    PATCH_COST_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_COST_DETAILS_SUCCESS`,
    PATCH_COST_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_COST_DETAILS_FAILURE`,

    BY_ID_COST_DETAILS: `${STATE_REDUCER_KEY}/BY_ID_COST_DETAILS`,
    BY_ID_COST_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/BY_ID_COST_DETAILS_REQUEST`,
    BY_ID_COST_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/BY_ID_COST_DETAILS_SUCCESS`,
    BY_ID_COST_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/BY_ID_COST_DETAILS_FAILURE`,

    LIST_ALL_COST_DETAILS: `${STATE_REDUCER_KEY}/LIST_ALL_COST_DETAILS`,
    LIST_ALL_COST_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_COST_DETAILS_REQUEST`,
    LIST_ALL_COST_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_COST_DETAILS_SUCCESS`,
    LIST_ALL_COST_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_COST_DETAILS_FAILURE`,

    ADD_COMMENT: `${STATE_REDUCER_KEY}/ADD_COMMENT`,
    ADD_COMMENT_REQUEST: `${STATE_REDUCER_KEY}/ADD_COMMENT_REQUEST`,
    ADD_COMMENT_SUCCESS: `${STATE_REDUCER_KEY}/ADD_COMMENT_SUCCESS`,
    ADD_COMMENT_FAILURE: `${STATE_REDUCER_KEY}/ADD_COMMENT_FAILURE`,

    VIEW_COMMENT: `${STATE_REDUCER_KEY}/VIEW_COMMENT`,
    VIEW_COMMENT_REQUEST: `${STATE_REDUCER_KEY}/VIEW_COMMENT_REQUEST`,
    VIEW_COMMENT_SUCCESS: `${STATE_REDUCER_KEY}/VIEW_COMMENT_SUCCESS`,
    VIEW_COMMENT_FAILURE: `${STATE_REDUCER_KEY}/VIEW_COMMENT_FAILURE`,

    DELETE_COMMENT: `${STATE_REDUCER_KEY}/DELETE_COMMENT`,
    DELETE_COMMENT_REQUEST: `${STATE_REDUCER_KEY}/DELETE_COMMENT_REQUEST`,
    DELETE_COMMENT_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_COMMENT_SUCCESS`,
    DELETE_COMMENT_FAILURE: `${STATE_REDUCER_KEY}/DELETE_COMMENT_FAILURE`,

    BY_ID_COMMENT: `${STATE_REDUCER_KEY}/BY_ID_COMMENT`,
    BY_ID_COMMENT_REQUEST: `${STATE_REDUCER_KEY}/BY_ID_COMMENT_REQUEST`,
    BY_ID_COMMENT_SUCCESS: `${STATE_REDUCER_KEY}/BY_ID_COMMENT_SUCCESS`,
    BY_ID_COMMENT_FAILURE: `${STATE_REDUCER_KEY}/BY_ID_COMMENT_FAILURE`,

    UPDATE_COMMENT: `${STATE_REDUCER_KEY}/UPDATE_COMMENT`,
    UPDATE_COMMENT_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_COMMENT_REQUEST`,
    UPDATE_COMMENT_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_COMMENT_SUCCESS`,
    UPDATE_COMMENT_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_COMMENT_FAILURE`,

    SUBMIT_TO_DISTRICT_LEVEL: `${STATE_REDUCER_KEY}/SUBMIT_TO_DISTRICT_LEVEL`,
    SUBMIT_TO_DISTRICT_LEVEL_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_TO_DISTRICT_LEVEL_REQUEST`,
    SUBMIT_TO_DISTRICT_LEVEL_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_TO_DISTRICT_LEVEL_SUCCESS`,
    SUBMIT_TO_DISTRICT_LEVEL_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_TO_DISTRICT_LEVEL_FAILURE`,

    LIST_APPROVAL_STATUS: `${STATE_REDUCER_KEY}/LIST_APPROVAL_STATUS`,
    LIST_APPROVAL_STATUS_REQUEST: `${STATE_REDUCER_KEY}/LIST_APPROVAL_STATUS_REQUEST`,
    LIST_APPROVAL_STATUS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_APPROVAL_STATUS_SUCCESS`,
    LIST_APPROVAL_STATUS_FAILURE: `${STATE_REDUCER_KEY}/LIST_APPROVAL_STATUS_FAILURE`,

    SUBMIT_DPMC_DESICION: `${STATE_REDUCER_KEY}/SUBMIT_DPMC_DESICION`,
    SUBMIT_DPMC_DESICION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_DPMC_DESICION_REQUEST`,
    SUBMIT_DPMC_DESICION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_DPMC_DESICION_SUCCESS`,
    SUBMIT_DPMC_DESICION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_DPMC_DESICION_FAILURE`,

    LIST_ALL_DPMC_DESICION: `${STATE_REDUCER_KEY}/LIST_ALL_DPMC_DESICION`,
    LIST_ALL_DPMC_DESICION_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_DPMC_DESICION_REQUEST`,
    LIST_ALL_DPMC_DESICION_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_DPMC_DESICION_SUCCESS`,
    LIST_ALL_DPMC_DESICION_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_DPMC_DESICION_FAILURE`,

    LIST_ALL_COMMENT_TABLE: `${STATE_REDUCER_KEY}/LIST_ALL_COMMENT_TABLE`,
    LIST_ALL_COMMENT_TABLE_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_COMMENT_TABLE_REQUEST`,
    LIST_ALL_COMMENT_TABLE_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_COMMENT_TABLE_SUCCESS`,
    LIST_ALL_COMMENT_TABLE_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_COMMENT_TABLE_FAILURE`,

    SUBMIT_RESPONSE_DETAILS: `${STATE_REDUCER_KEY}/SUBMIT_RESPONSE_DETAILS`,
    SUBMIT_RESPONSE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_RESPONSE_DETAILS_REQUEST`,
    SUBMIT_RESPONSE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_RESPONSE_DETAILS_SUCCESS`,
    SUBMIT_RESPONSE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_RESPONSE_DETAILS_FAILURE`,

    SUBMIT_TO_STATE_LEVEL: `${STATE_REDUCER_KEY}/SUBMIT_TO_STATE_LEVEL`,
    SUBMIT_TO_STATE_LEVEL_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_TO_STATE_LEVEL_REQUEST`,
    SUBMIT_TO_STATE_LEVEL_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_TO_STATE_LEVEL_SUCCESS`,
    SUBMIT_TO_STATE_LEVEL_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_TO_STATE_LEVEL_FAILURE`,

    SUBMIT_TO_PUI: `${STATE_REDUCER_KEY}/SUBMIT_TO_PUI`,
    SUBMIT_TO_PUI_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_TO_PUI_REQUEST`,
    SUBMIT_TO_PUI_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_TO_PUI_SUCCESS`,
    SUBMIT_TO_PUI_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_TO_PUI_FAILURE`,

    SUBMIT_TO_DYDC: `${STATE_REDUCER_KEY}/SUBMIT_TO_DYDC`,
    SUBMIT_TO_DYDC_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_TO_DYDC_REQUEST`,
    SUBMIT_TO_DYDC_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_TO_DYDC_SUCCESS`,
    SUBMIT_TO_DYDC_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_TO_DYDC_FAILURE`,

    RETURN_TO_TSC: `${STATE_REDUCER_KEY}/RETURN_TO_TSC`,
    RETURN_TO_TSC_REQUEST: `${STATE_REDUCER_KEY}/RETURN_TO_TSC_REQUEST`,
    RETURN_TO_TSC_SUCCESS: `${STATE_REDUCER_KEY}/RETURN_TO_TSC_SUCCESS`,
    RETURN_TO_TSC_FAILURE: `${STATE_REDUCER_KEY}/RETURN_TO_TSC_FAILURE`,

    SUBMIT_MC_APPROVAL: `${STATE_REDUCER_KEY}/SUBMIT_MC_APPROVAL`,
    SUBMIT_MC_APPROVAL_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_MC_APPROVAL_REQUEST`,
    SUBMIT_MC_APPROVAL_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_MC_APPROVAL_SUCCESS`,
    SUBMIT_MC_APPROVAL_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_MC_APPROVAL_FAILURE`,

    LIST_DPR_TRACKER_STATUS: `${STATE_REDUCER_KEY}/LIST_DPR_TRACKER_STATUS`,
    LIST_DPR_TRACKER_STATUS_REQUEST: `${STATE_REDUCER_KEY}/LIST_DPR_TRACKER_STATUS_REQUEST`,
    LIST_DPR_TRACKER_STATUS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_DPR_TRACKER_STATUS_SUCCESS`,
    LIST_DPR_TRACKER_STATUS_FAILURE: `${STATE_REDUCER_KEY}/LIST_DPR_TRACKER_STATUS_FAILURE`,

    ADD_COMMENT_CATEGORY_LIST: `${STATE_REDUCER_KEY}/ADD_COMMENT_CATEGORY_LIST`,
    ADD_COMMENT_CATEGORY_LIST_REQUEST: `${STATE_REDUCER_KEY}/ADD_COMMENT_CATEGORY_LIST_REQUEST`,
    ADD_COMMENT_CATEGORY_LIST_SUCCESS: `${STATE_REDUCER_KEY}/ADD_COMMENT_CATEGORY_LIST_SUCCESS`,
    ADD_COMMENT_CATEGORY_LIST_FAILURE: `${STATE_REDUCER_KEY}/ADD_COMMENT_CATEGORY_LIST_FAILURE`,

    GENERATE_FS: `${STATE_REDUCER_KEY}/GENERATE_FS`,
    GENERATE_FS_REQUEST: `${STATE_REDUCER_KEY}/GENERATE_FS_REQUEST`,
    GENERATE_FS_SUCCESS: `${STATE_REDUCER_KEY}/GENERATE_FS_SUCCESS`,
    GENERATE_FS_FAILURE: `${STATE_REDUCER_KEY}/GENERATE_FS_FAILURE`,

    DOWNLOAD_GENERATE_FS: `${STATE_REDUCER_KEY}/DOWNLOAD_GENERATE_FS`,
    DOWNLOAD_GENERATE_FS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_GENERATE_FS_REQUEST`,
    DOWNLOAD_GENERATE_FS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_GENERATE_FS_SUCCESS`,
    DOWNLOAD_GENERATE_FS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_GENERATE_FS_FAILURE`,

    LIST_ALL_GENERATE_FS: `${STATE_REDUCER_KEY}/LIST_ALL_GENERATE_FS`,
    LIST_ALL_GENERATE_FS_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALL_GENERATE_FS_REQUEST`,
    LIST_ALL_GENERATE_FS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALL_GENERATE_FS_SUCCESS`,
    LIST_ALL_GENERATE_FS_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALL_GENERATE_FS_FAILURE`,

    DOWNLOAD_DPR_FR_LIST: `${STATE_REDUCER_KEY}/DOWNLOAD_DPR_FR_LIST`,
    DOWNLOAD_DPR_FR_LIST_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_DPR_FR_LIST_REQUEST`,
    DOWNLOAD_DPR_FR_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_DPR_FR_LIST_SUCCESS`,
    DOWNLOAD_DPR_FR_LIST_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_DPR_FR_LIST_FAILURE`

};

export const listDprTracker = createAction(ACTION_TYPES.LIST_DPR_TRACKER);
export const saveDprTracker = createAction(ACTION_TYPES.SAVE_DPR_TRACKER);
export const patchDprTracker = createAction(ACTION_TYPES.PATCH_DPR_TRACKER);
export const dprTrackerById = createAction(ACTION_TYPES.DPR_TRACKER_BY_ID);
export const deleteDprTracker = createAction(ACTION_TYPES.DELETE_DPR_TRACKER);
export const listDocumentType = createAction(ACTION_TYPES.LIST_DOCUMENT_TYPE);
export const listWorkType = createAction(ACTION_TYPES.LIST_WORK_TYPE);
export const listEsmfCategory = createAction(ACTION_TYPES.LIST_ESMF_CATEGORY);
export const listDprCategory = createAction(ACTION_TYPES.LIST_DPR_CATEGORY);
export const listSubprojectList = createAction(ACTION_TYPES.LIST_SUBPROJECT_FULL_DATA);
export const postSubProjectMapping = createAction(ACTION_TYPES.POST_SUBPROJECT_MAPPING);
export const listFinalSubprojectList = createAction(ACTION_TYPES.LIST_FINAL_SUBPROJECT_MAPPING);
export const subprojectListById = createAction(ACTION_TYPES.BY_ID_FINAL_SUBPROJECT_MAPPING);
export const patchFinalSubProject = createAction(ACTION_TYPES.PATCH_FINAL_SUBPROJECT_MAPPING);
export const saveCostDetails = createAction(ACTION_TYPES.SAVE_COST_DETAILS);
export const patchCostDetails = createAction(ACTION_TYPES.PATCH_COST_DETAILS);
export const costDetailsById = createAction(ACTION_TYPES.BY_ID_COST_DETAILS);
export const listAllCostDetails = createAction(ACTION_TYPES.LIST_ALL_COST_DETAILS);
export const addComment = createAction(ACTION_TYPES.ADD_COMMENT);
export const viewComment = createAction(ACTION_TYPES.VIEW_COMMENT);
export const deleteComment = createAction(ACTION_TYPES.DELETE_COMMENT);
export const commentById = createAction(ACTION_TYPES.BY_ID_COMMENT);
export const updateComment = createAction(ACTION_TYPES.UPDATE_COMMENT);
export const submitToDistrictLevel = createAction(ACTION_TYPES.SUBMIT_TO_DISTRICT_LEVEL);
export const listApprovalStatus = createAction(ACTION_TYPES.LIST_APPROVAL_STATUS);
export const submitDpmcDecision = createAction(ACTION_TYPES.SUBMIT_DPMC_DESICION);
export const listDpmcDecision = createAction(ACTION_TYPES.LIST_ALL_DPMC_DESICION);
export const listComments = createAction(ACTION_TYPES.LIST_ALL_COMMENT_TABLE);
export const saveResponseDetails = createAction(ACTION_TYPES.SUBMIT_RESPONSE_DETAILS);
export const submitToStateLevel = createAction(ACTION_TYPES.SUBMIT_TO_STATE_LEVEL);
export const submitToPui = createAction(ACTION_TYPES.SUBMIT_TO_PUI);
export const submitToDydc = createAction(ACTION_TYPES.SUBMIT_TO_DYDC);
export const returnToTsc = createAction(ACTION_TYPES.RETURN_TO_TSC);
export const saveDprMcApproval = createAction(ACTION_TYPES.SUBMIT_MC_APPROVAL);
export const listDprTrackerStatus = createAction(ACTION_TYPES.LIST_DPR_TRACKER_STATUS);
export const listCommentCategoryList = createAction(ACTION_TYPES.ADD_COMMENT_CATEGORY_LIST);
export const generateFs = createAction(ACTION_TYPES.GENERATE_FS);
export const downloadGenerateFs = createAction(ACTION_TYPES.DOWNLOAD_GENERATE_FS);
export const listAllGenerateFs = createAction(ACTION_TYPES.LIST_ALL_GENERATE_FS);
export const downloadDprFrList = createAction(ACTION_TYPES.DOWNLOAD_DPR_FR_LIST);
