export const SUB_PROJECTS_PATH = {
    SWM_PLAN: {
        LIST: "plans/swm-plan-details",
        CREATE: "swm-plan-details/create"
    },
    FIVE_YEAR_PLAN: {
        LIST: "five-year-plan",
        CREATE: "five-year-plan/create"
    },
    ANNUAL_PLAN: {
        LIST: "plans/annual-plan",
        CREATE: "annual-plan/create"
    },
    SWM_SUB_PROJECTS: {
        LIST: "project-preparation/sub-projects-list",
        CREATE: "sub-projects/create"
    },
    SWM_DISCUSSION_AND_APPROVAL: {
        LIST: "project-preparation/annual-development-plan"
    },
    DPR_TRACKER: {
        LIST: "project-preparation/dpr-tracker",
        CREATE: "dpr-tracker/create"
    },
    ACTIVITY_MONITORING: {
        LIST: "sub-project-monitoring"
    },
    PROCUREMENT_LIST: {
        LIST: "sub-project-procurement"
    },
    GAP_ANALYSIS: {
        LIST: "current-status",
        CREATE: "current-status/create"
    },
    SUB_PROJECTS_REPORT: {
        LIST: "reports/current-status-reports"
    },
    SWM_DATA_REPORT: {
        POPULATION_VS_LAND_AREA: {
            LIST: "reports/gap-analysis-reports/population-vs-land-area"
        },
        POPULATION_VS_HOUSE_HOLD: {
            LIST: "reports/gap-analysis-reports/population-vs-house-hold"
        },
        LAND_USE: {
            LIST: "reports/gap-analysis-reports/land-use"
        },
        NBDW_EQUIPMENTS_REPORT: {
            LIST: "reports/gap-analysis-reports/nbdw-equipments-report"
        },
        BDW_EQUIPMENTS_REPORT: {
            LIST: "reports/gap-analysis-reports/bdw-equipments-report"
        },
        NBDW_GENERATED_AND_CAPACITY_REPORT: {
            LIST: "reports/gap-analysis-reports/nbdw-generated-and-capacity-report"
        },
        BDW_GENERATED_AND_CAPACITY_REPORT: {
            LIST: "reports/gap-analysis-reports/bdw-generated-and-capacity-report"
        },
        NBDW_FACILITY_REPORT: {
            LIST: "reports/gap-analysis-reports/nbdw-facility-report"
        },
        BDW_FACILITY_REPORT: {
            LIST: "reports/gap-analysis-reports/bdw-facility-report"
        },
        CAPITAL_COST_IN_NBDW_REPORT: {
            LIST: "reports/gap-analysis-reports/capital-cost-in-nbdw-report"
        },
        CAPITAL_COST_IN_BDW_REPORT: {
            LIST: "reports/gap-analysis-reports/capital-cost-in-bdw-report"
        },
        FORWARD_LINKING_REPORT: {
            LIST: "reports/gap-analysis-reports/forward-linking-report"
        },
        INCOME_FROM_BDW_AND_NBDW: {
            LIST: "reports/gap-analysis-reports/income-from-bdw-and-nbdw-report"
        },
        EXPENDITURE: {
            LIST: "reports/gap-analysis-reports/expenditure-report"
        },
        INCOME_FOR_HKS_FROM_NBDW_AND_BDW: {
            LIST: "reports/gap-analysis-reports/income-for-hks"
        }
    },
    SOCIAL_SAFE_GUARD: {
        LIST: "social-safeguard",
        CREATE: "social-safeguard/create",
        VIEW: "social-safeguard/baseline-data/:id/view",
        ACTIVITIES: "social-safeguard/:id/activities"
    },
    LABOUR_MANAGEMENT: {
        PROJECT_LIST: "project-list",
        PROJECT_SITE_LIST: "sites",
        PROJECT_SITE_ADD: "project-site-add",
        PROJECT_SITE_ADD_DETAILS: "project-site-add-details",
        INCIDENTS: "incidents"
    },
    LAND_ACQUISITIONS: {
        LIST: "land_acquisitions_list"
    },
    SUB_PLAN_REPORT: {
        LIST: "reports/swm-sub-project-reports"
    },
    SWM_PLAN_REPORT: {
        LIST: "reports/swm-plan-reports"
    }
};
