import { all, fork, put, take, takeLatest, call } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { ACTION_TYPES } from "./actions";
import { I18n } from "common/components";
import { handleAPIRequest } from "utils/http";
import { addVisitorsFormSave, listCountry, listProductInterest } from "./api";

// import _ from "lodash";
export function* saveVisitorsForm({ payload }) {
    yield fork(handleAPIRequest, addVisitorsFormSave, payload);
    const responseAction = yield take([ACTION_TYPES.ADD_VISITORS_FORM_SUCCESS, ACTION_TYPES.ADD_VISITORS_FORM_FAILURE]);
    if (responseAction.type === ACTION_TYPES.ADD_VISITORS_FORM_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: "Added Successfully" }));

    }
}

export function* fetchCountry() {
    yield call(handleAPIRequest, listCountry);
}

export function* fetchProductInterest() {
    yield call(handleAPIRequest, listProductInterest);
}

export default function* detailSaga() {
    yield all([
        takeLatest(ACTION_TYPES.ADD_VISITORS_FORM, saveVisitorsForm),
        takeLatest(ACTION_TYPES.LIST_COUNTRY, fetchCountry),
        takeLatest(ACTION_TYPES.PRODUCT_INTEREST, fetchProductInterest)
    ]);
}
