import { Avatar, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { STATE_REDUCER_KEY } from "modules/user/constants";

const Profile = () => {
    const { userDetails: { data: { activeProfile: { imageId = "" } = {}, firstName = "", lastName = "" } } = {} } = useSelector(state => state[STATE_REDUCER_KEY]);
    return (
        <>
            <Box sx={{ display: "flex"}}>
                    <Avatar sx={{ height: {xs: "87px", md: "48px"}, width: {xs: "87px", md: "48px"} }} src={imageId ? `${process.env.REACT_APP_API_URL}/resource/api/auth/multimedia/download?id=${imageId}` : ""}></Avatar>
                    <Typography sx={{
                        fontSize: "18px", color: "#fff", alignSelf: "center", pl: 1, fontWeight: 400,
                        whiteSpace: "break-spaces", width: "8rem", textAlign: "center", fontFamily: "Clash Display"
                    }}>{`${firstName} ${lastName}`}</Typography>
                </Box>
        </>
    );
};

export default Profile;
