import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    SAVE_BATCH_FORM: `${STATE_REDUCER_KEY}/SAVE_BATCH_FORM`,
    SAVE_BATCH_FORM_REQUEST: `${STATE_REDUCER_KEY}/SAVE_BATCH_FORM_REQUEST`,
    SAVE_BATCH_FORM_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_BATCH_FORM_SUCCESS`,
    SAVE_BATCH_FORM_FAILURE: `${STATE_REDUCER_KEY}/SAVE_BATCH_FORM_FAILURE`,

    LIST_BATCH_DETAILS: `${STATE_REDUCER_KEY}/LIST_BATCH_DETAILS`,
    LIST_BATCH_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/LIST_BATCH_DETAILS_REQUEST`,
    LIST_BATCH_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_BATCH_DETAILS_SUCCESS`,
    LIST_BATCH_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/LIST_BATCH_DETAILS_FAILURE`,

    FETCH_TARGET_GROUP: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP`,
    FETCH_TARGET_GROUP_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP_REQUEST`,
    FETCH_TARGET_GROUP_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP_SUCCESS`,
    FETCH_TARGET_GROUP_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP_FAILURE`,

    FETCH_PLAN_ID: `${STATE_REDUCER_KEY}/FETCH_PLAN_ID`,
    FETCH_PLAN_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PLAN_ID_REQUEST`,
    FETCH_PLAN_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PLAN_ID_SUCCESS`,
    FETCH_PLAN_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PLAN_ID_FAILURE`,

    FETCH_TRAINING_MODE: `${STATE_REDUCER_KEY}/FETCH_TRAINING_MODE`,
    FETCH_TRAINING_MODE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TRAINING_MODE_REQUEST`,
    FETCH_TRAINING_MODE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TRAINING_MODE_SUCCESS`,
    FETCH_TRAINING_MODE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TRAINING_MODE_FAILURE`,

    FETCH_VENUE_ID: `${STATE_REDUCER_KEY}/FETCH_VENUE_ID`,
    FETCH_VENUE_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_VENUE_ID_REQUEST`,
    FETCH_VENUE_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_VENUE_ID_SUCCESS`,
    FETCH_VENUE_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_VENUE_ID_FAILURE`,

    FETCH_HOST_ID: `${STATE_REDUCER_KEY}/FETCH_HOST_ID`,
    FETCH_HOST_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_HOST_ID_REQUEST`,
    FETCH_HOST_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_HOST_ID_SUCCESS`,
    FETCH_HOST_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_HOST_ID_FAILURE`,


    DELETE_BATCH_FORM: `${STATE_REDUCER_KEY}/DELETE_BATCH_FORM`,
    DELETE_BATCH_FORM_REQUEST: `${STATE_REDUCER_KEY}/DELETE_BATCH_FORM_REQUEST`,
    DELETE_BATCH_FORM_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_BATCH_FORM_SUCCESS`,
    DELETE_BATCH_FORM_FAILURE: `${STATE_REDUCER_KEY}/DELETE_BATCH_FORM_FAILURE`,

    EDIT_BATCH_FORM: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM`,
    EDIT_BATCH_FORM_REQUEST: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM_REQUEST`,
    EDIT_BATCH_FORM_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM_SUCCESS`,
    EDIT_BATCH_FORM_FAILURE: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM_FAILURE`,


    EDIT_BATCH_FORM_BY_ID: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM_BY_ID`,
    EDIT_BATCH_FORM_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM_BY_ID_REQUEST`,
    EDIT_BATCH_FORM_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM_BY_ID_SUCCESS`,
    EDIT_BATCH_FORM_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/EDIT_BATCH_FORM_BY_ID_FAILURE`,

    FETCH_PARTICIPANTS_DETAILS: `${STATE_REDUCER_KEY}/FETCH_PARTICIPANTS_DETAILS`,
    FETCH_PARTICIPANTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PARTICIPANTS_DETAILS_REQUEST`,
    FETCH_PARTICIPANTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PARTICIPANTS_DETAILS_SUCCESS`,
    FETCH_PARTICIPANTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PARTICIPANTS_DETAILS_FAILURE`,

    FETCH_ADD_PARTICIPANT: `${STATE_REDUCER_KEY}/FETCH_ADD_PARTICIPANT`,
    FETCH_ADD_PARTICIPANT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ADD_PARTICIPANT_REQUEST`,
    FETCH_ADD_PARTICIPANT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ADD_PARTICIPANT_SUCCESS`,
    FETCH_ADD_PARTICIPANT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ADD_PARTICIPANT_FAILURE`,

    FETCH_USER_ROLE_BY_ID_DETAILS: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_BY_ID_DETAILS`,
    FETCH_USER_ROLE_BY_ID_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_BY_ID_DETAILS_REQUEST`,
    FETCH_USER_ROLE_BY_ID_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_BY_ID_DETAILS_SUCCESS`,
    FETCH_USER_ROLE_BY_ID_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_BY_ID_DETAILS_FAILURE`,

    CONFIRM_PARTICIPANT_BY_ID: `${STATE_REDUCER_KEY}/CONFIRM_PARTICIPANT_BY_ID`,
    CONFIRM_PARTICIPANT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/CONFIRM_PARTICIPANT_BY_ID_REQUEST`,
    CONFIRM_PARTICIPANT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/CONFIRM_PARTICIPANT_BY_ID_SUCCESS`,
    CONFIRM_PARTICIPANT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/CONFIRM_PARTICIPANT_BY_ID_FAILURE`,


    ADD_PARTICIPANT_BY_ID: `${STATE_REDUCER_KEY}/ADD_PARTICIPANT_BY_ID`,
    ADD_PARTICIPANT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/ADD_PARTICIPANT_BY_ID_REQUEST`,
    ADD_PARTICIPANT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/ADD_PARTICIPANT_BY_ID_SUCCESS`,
    ADD_PARTICIPANT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/ADD_PARTICIPANT_BY_ID_FAILURE`,

    FETCH_CERTIFICATE_DETAILS: `${STATE_REDUCER_KEY}/FETCH_CERTIFICATE_DETAILS`,
    FETCH_CERTIFICATE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CERTIFICATE_DETAILS_REQUEST`,
    FETCH_CERTIFICATE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CERTIFICATE_DETAILS_SUCCESS`,
    FETCH_CERTIFICATE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CERTIFICATE_DETAILS_FAILURE`,


    CERTIFICATE_ISSUE: `${STATE_REDUCER_KEY}/CERTIFICATE_ISSUE`,
    CERTIFICATE_ISSUE_REQUEST: `${STATE_REDUCER_KEY}/CERTIFICATE_ISSUE_REQUEST`,
    CERTIFICATE_ISSUE_SUCCESS: `${STATE_REDUCER_KEY}/CERTIFICATE_ISSUE_SUCCESS`,
    CERTIFICATE_ISSUE_FAILURE: `${STATE_REDUCER_KEY}/CERTIFICATE_ISSUE_FAILURE`,

    FETCH_ORGANIZED_BY: `${STATE_REDUCER_KEY}/FETCH_ORGANIZED_BY`,
    FETCH_ORGANIZED_BY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ORGANIZED_BY_REQUEST`,
    FETCH_ORGANIZED_BY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ORGANIZED_BY_SUCCESS`,
    FETCH_ORGANIZED_BY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ORGANIZED_BY_FAILURE`,

    DELETE_BATCH_PARTICIPANT: `${STATE_REDUCER_KEY}/DELETE_BATCH_PARTICIPANT`,
    DELETE_BATCH_PARTICIPANT_REQUEST: `${STATE_REDUCER_KEY}/DELETE_BATCH_PARTICIPANT_REQUEST`,
    DELETE_BATCH_PARTICIPANT_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_BATCH_PARTICIPANT_SUCCESS`,
    DELETE_BATCH_PARTICIPANT_FAILURE: `${STATE_REDUCER_KEY}/DELETE_BATCH_PARTICIPANT_FAILURE`,


    FILTER_BATCH_NAME: `${STATE_REDUCER_KEY}/FILTER_BATCH_NAME`,
    FILTER_BATCH_NAME_REQUEST: `${STATE_REDUCER_KEY}/FILTER_BATCH_NAME_REQUEST`,
    FILTER_BATCH_NAME_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_BATCH_NAME_SUCCESS`,
    FILTER_BATCH_NAME_FAILURE: `${STATE_REDUCER_KEY}/FILTER_BATCH_NAME_FAILURE`,

    FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
    FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
    FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
    FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,

    FETCH_ROLE_DATA: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA`,
    FETCH_ROLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_REQUEST`,
    FETCH_ROLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_SUCCESS`,
    FETCH_ROLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_FAILURE`,

    FETCH_DESIGNATION_LIST: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_LIST`,
    FETCH_DESIGNATION_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_LIST_REQUEST`,
    FETCH_DESIGNATION_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_LIST_SUCCESS`,
    FETCH_DESIGNATION_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_LIST_FAILURE`,

    FILTER_BATCH_LIST: `${STATE_REDUCER_KEY}FILTER_BATCH_LIST`,
    FILTER_BATCH_LIST_REQUEST: `${STATE_REDUCER_KEY}FILTER_BATCH_LIST_REQUEST`,
    FILTER_BATCH_LIST_SUCCESS: `${STATE_REDUCER_KEY}FILTER_BATCH_LIST_SUCCESS`,
    FILTER_BATCH_LIST_FAILURE: `${STATE_REDUCER_KEY}FILTER_BATCH_LIST_FAILURE`,


    FETCH_TRAINING_CORDINATOR_ID: `${STATE_REDUCER_KEY}FETCH_TRAINING_CORDINATOR_ID`,
    FETCH_TRAINING_CORDINATOR_ID_REQUEST: `${STATE_REDUCER_KEY}FETCH_TRAINING_CORDINATOR_ID_REQUEST`,
    FETCH_TRAINING_CORDINATOR_ID_SUCCESS: `${STATE_REDUCER_KEY}FETCH_TRAINING_CORDINATOR_ID_SUCCESS`,
    FETCH_TRAINING_CORDINATOR_ID_FAILURE: `${STATE_REDUCER_KEY}FETCH_TRAINING_CORDINATOR_ID_FAILURE`,

    FETCH_LSGI_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN`,
    FETCH_LSGI_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_REQUEST`,
    FETCH_LSGI_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_SUCCESS`,
    FETCH_LSGI_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_FAILURE`,

    FETCH_DISTRICT_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN`,
    FETCH_DISTRICT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_REQUEST`,
    FETCH_DISTRICT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_SUCCESS`,
    FETCH_DISTRICT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_FAILURE`,

    VIEW_PARTICIPANTS_LIST_DOWNLOAD: `${STATE_REDUCER_KEY}/VIEW_PARTICIPANTS_LIST_DOWNLOAD`,
    VIEW_PARTICIPANTS_LIST_DOWNLOAD_REQUEST: `${STATE_REDUCER_KEY}/VIEW_PARTICIPANTS_LIST_DOWNLOAD_REQUEST`,
    VIEW_PARTICIPANTS_LIST_DOWNLOAD_SUCCESS: `${STATE_REDUCER_KEY}/VIEW_PARTICIPANTS_LIST_DOWNLOAD_SUCCESS`,
    VIEW_PARTICIPANTS_LIST_DOWNLOAD_FAILURE: `${STATE_REDUCER_KEY}/VIEW_PARTICIPANTS_LIST_DOWNLOAD_FAILURE`,

    FETCH_CONFIRMATION_LIST: `${STATE_REDUCER_KEY}/FETCH_CONFIRMATION_LIST`,
    FETCH_CONFIRMATION_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CONFIRMATION_LIST_REQUEST`,
    FETCH_CONFIRMATION_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CONFIRMATION_LIST_SUCCESS`,
    FETCH_CONFIRMATION_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CONFIRMATION_LIST_FAILURE`,

    FILTER_CONFIRMATION_LIST: `${STATE_REDUCER_KEY}/FILTER_CONFIRMATION_LIST`,
    FILTER_CONFIRMATION_LIST_REQUEST: `${STATE_REDUCER_KEY}/FILTER_CONFIRMATION_LIST_REQUEST`,
    FILTER_CONFIRMATION_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_CONFIRMATION_LIST_SUCCESS`,
    FILTER_CONFIRMATION_LIST_FAILURE: `${STATE_REDUCER_KEY}/FILTER_CONFIRMATION_LIST_FAILURE`,

    FETCH_THEMATIC_AREA_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_THEMATIC_AREA_DROPDOWN`,
    FETCH_THEMATIC_AREA_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_THEMATIC_AREA_DROPDOWN_REQUEST`,
    FETCH_THEMATIC_AREA_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_THEMATIC_AREA_DROPDOWN_SUCCESS`,
    FETCH_THEMATIC_AREA_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_THEMATIC_AREA_DROPDOWN_FAILURE`

};
export const saveBatchDetails = createAction(ACTION_TYPES.SAVE_BATCH_FORM);
export const listBatchForm = createAction(ACTION_TYPES.LIST_BATCH_DETAILS);
export const fetchTargetGroup = createAction(ACTION_TYPES.FETCH_TARGET_GROUP);
export const fetchPlanId = createAction(ACTION_TYPES.FETCH_PLAN_ID);
export const fetchTrainingMode = createAction(ACTION_TYPES.FETCH_TRAINING_MODE);
export const fetchVenue = createAction(ACTION_TYPES.FETCH_VENUE_ID);
export const fetchHost = createAction(ACTION_TYPES.FETCH_HOST_ID);
export const deleteBatchForm = createAction(ACTION_TYPES.DELETE_BATCH_FORM);
export const editBatchForm = createAction(ACTION_TYPES.EDIT_BATCH_FORM);
export const editBatchFormById = createAction(ACTION_TYPES.EDIT_BATCH_FORM_BY_ID);
export const fetchParticipantDetails = createAction(ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS);
export const fetchAddParticipant = createAction(ACTION_TYPES.FETCH_ADD_PARTICIPANT);
export const fetchUserRolesById = createAction(ACTION_TYPES.FETCH_USER_ROLE_BY_ID_DETAILS);
export const confirmParticipants = createAction(ACTION_TYPES.CONFIRM_PARTICIPANT_BY_ID);
export const addParticipants = createAction(ACTION_TYPES.ADD_PARTICIPANT_BY_ID);
export const fetchCertificateDetails = createAction(ACTION_TYPES.FETCH_CERTIFICATE_DETAILS);
export const issueCertificate = createAction(ACTION_TYPES.CERTIFICATE_ISSUE);
export const fetchOrganizedBy = createAction(ACTION_TYPES.FETCH_ORGANIZED_BY);
export const deleteParticipant = createAction(ACTION_TYPES.DELETE_BATCH_PARTICIPANT);
export const filterBatchName = createAction(ACTION_TYPES.FILTER_BATCH_NAME);
export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);
export const getDesignationList = createAction(ACTION_TYPES.FETCH_DESIGNATION_LIST);
export const getRoles = createAction(ACTION_TYPES.FETCH_ROLE_DATA);
export const filterBatchList = createAction(ACTION_TYPES.FILTER_BATCH_LIST);
export const fetchTrainingCordinatorId = createAction(ACTION_TYPES.FETCH_TRAINING_CORDINATOR_ID);
export const fetchDistrictDropDown = createAction(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN);
export const fetchLsgiDropdown = createAction(ACTION_TYPES.FETCH_LSGI_DROPDOWN);
export const viewParticipantsDownload = createAction(ACTION_TYPES.VIEW_PARTICIPANTS_LIST_DOWNLOAD);
export const fetchConfirmationList = createAction(ACTION_TYPES.FETCH_CONFIRMATION_LIST);
export const filterConfirmationList = createAction(ACTION_TYPES.FILTER_CONFIRMATION_LIST);
export const fetchThematicAreaDropDown = createAction(ACTION_TYPES.FETCH_THEMATIC_AREA_DROPDOWN);
