import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";
import { REQUEST_METHOD } from "common/constants";

export const fetchBatchDetailsApi = () => ({
    url: API_URL.DASHBOARD.BATCH,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_BATCH_REPORT_REQUEST, ACTION_TYPES.FETCH_BATCH_REPORT_SUCCESS, ACTION_TYPES.FETCH_BATCH_REPORT_FAILURE]
    }
});
