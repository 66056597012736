import { ACTION_TYPES } from "./actions";
import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "modules/subProjects/apiUrls";

export const fiveYearPlanList = (data) => ({
    url: API_URL.FIVE_YEAR_PLAN.ALL_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_REQUEST, ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_SUCCESS, ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_FAILURE],
        data
    }
});
export const fetchDistricts = () => {
    return {
        url: API_URL.DISTRICT.LIST_DISTRICT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_FAILURE]
        }
    };
};
export const fetchSwmPlanApi = (data) => {
    return {
        url: API_URL.FIVE_YEAR_PLAN.SWM_PLAN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SWM_PLAN_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_SWM_PLAN_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_SWM_PLAN_DROPDOWN_FAILURE],
            data: { dropDown: true, lsgiId: data }
        }
    };
};
export const lsgiDropdownApi = (districtId) => ({
    url: API_URL.COMMON.LIST_ULB_DROPDOWN,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE],
        data: { districtId }
    }
});
export const fiveYearPlanListById = (id) => ({
    url: API_URL.FIVE_YEAR_PLAN.BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_REQUEST, ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_SUCCESS, ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID_FAILURE],
        data: { id }
    }
});
export const ceiledAmountApi = (data) => ({
    url: API_URL.FIVE_YEAR_PLAN.CEILED_AMOUNT,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.CEILED_AMOUNT_REQUEST, ACTION_TYPES.CEILED_AMOUNT_SUCCESS, ACTION_TYPES.CEILED_AMOUNT_FAILURE],
        data: { lsgiId: data }
    }
});
export const submitFiveYearPlanApi = (data) => ({
    url: API_URL.FIVE_YEAR_PLAN.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SUBMIT_FIVE_YEAR_PLAN_REQUEST, ACTION_TYPES.SUBMIT_FIVE_YEAR_PLAN_SUCCESS, ACTION_TYPES.SUBMIT_FIVE_YEAR_PLAN_FAILURE],
        data
    }
});
export const updateFiveYearPlanApi = (data) => ({
    url: API_URL.FIVE_YEAR_PLAN.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_FIVE_YEAR_PLAN_REQUEST, ACTION_TYPES.UPDATE_FIVE_YEAR_PLAN_SUCCESS, ACTION_TYPES.UPDATE_FIVE_YEAR_PLAN_FAILURE],
        data
    }
});
export const ulbSubmittedToMcApi = (data) => ({
    url: API_URL.FIVE_YEAR_PLAN.SUBMITTED_TO_MC,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SUBMITTED_TO_MUNICIPAL_REQUEST, ACTION_TYPES.SUBMITTED_TO_MUNICIPAL_SUCCESS, ACTION_TYPES.SUBMITTED_TO_MUNICIPAL_FAILURE],
        data
    }
});
export const rejectSpmuApi = (data) => {
    return {
        url: API_URL.FIVE_YEAR_PLAN.SPMU_REJECT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.REJECT_SPMU_REQUEST, ACTION_TYPES.REJECT_SPMU_SUCCESS, ACTION_TYPES.REJECT_SPMU_FAILURE],
            data
        }
    };
};
export const approveSpmuApi = (data) => {
    return {
        url: API_URL.FIVE_YEAR_PLAN.SPMU_APPROVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.APPROVE_SPMU_REQUEST, ACTION_TYPES.APPROVE_SPMU_SUCCESS, ACTION_TYPES.APPROVE_SPMU_FAILURE],
            data
        }
    };
};
export const approveDpmuApi = (data) => {
    return {
        url: API_URL.FIVE_YEAR_PLAN.DPMU_APPROVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.APPROVE_DPMU_REQUEST, ACTION_TYPES.APPROVE_DPMU_SUCCESS, ACTION_TYPES.APPROVE_DPMU_FAILURE],
            data
        }
    };
}; export const rejectDpmuApi = (data) => {
    return {
        url: API_URL.FIVE_YEAR_PLAN.DPMU_REJECT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.REJECT_DPMU_REQUEST, ACTION_TYPES.REJECT_DPMU_SUCCESS, ACTION_TYPES.REJECT_DPMU_FAILURE],
            data
        }
    };
};
export const approveMunicipalApi = (data) => {
    return {
        url: API_URL.FIVE_YEAR_PLAN.MUNICIPAL_APPROVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.APPROVE_MUNICIPAL_REQUEST, ACTION_TYPES.APPROVE_MUNICIPAL_SUCCESS, ACTION_TYPES.APPROVE_MUNICIPAL_FAILURE],
            data
        }
    };
}; export const rejectMunicipalApi = (data) => {
    return {
        url: API_URL.FIVE_YEAR_PLAN.MUNICIPAL_REJECT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.REJECT_MUNICIPAL_REQUEST, ACTION_TYPES.REJECT_MUNICIPAL_SUCCESS, ACTION_TYPES.REJECT_MUNICIPAL_FAILURE],
            data
        }
    };
};
export const deleteFiveYearPlanApi = (id) => {
    return {
        url: API_URL.FIVE_YEAR_PLAN.DELETE_FIVE_YEAR.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_FIVE_YEAR_PLAN_PROJECT_REQUEST, ACTION_TYPES.DELETE_FIVE_YEAR_PLAN_PROJECT_SUCCESS, ACTION_TYPES.DELETE_FIVE_YEAR_PLAN_PROJECT_FAILURE]
        }
    };
};
export const deleteFileById = (id, fileId) => {
    let DELETE_API_URL = API_URL.FIVE_YEAR_PLAN.DELETE_FILE_BY_ID;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":file_id", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_FILE_REQUEST, ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_REQUEST]
        }
    };
};
export const listFiveYearApi = (data) => ({
    url: API_URL.FIVE_YEAR_PLAN.FIVE_YEAR_PROJECTS,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_FIVE_YEAR_LIST_REQUEST, ACTION_TYPES.FETCH_FIVE_YEAR_LIST_SUCCESS, ACTION_TYPES.FETCH_FIVE_YEAR_LIST_FAILURE],
        data
    }
});
export const listSubProjectApi = (data) => ({
    url: API_URL.FIVE_YEAR_PLAN.SUB_PROJECTS,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_SUB_PROJECT_LIST_REQUEST, ACTION_TYPES.FETCH_SUB_PROJECT_LIST_SUCCESS, ACTION_TYPES.FETCH_SUB_PROJECT_LIST_FAILURE],
        data
    }
});
export const assignFiveYearProjectApi = (data) => {
    return {
        url: API_URL.FIVE_YEAR_PLAN.ASSIGN_PROJECT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_PROJECT_LIST_REQUEST, ACTION_TYPES.SUBMIT_PROJECT_LIST_SUCCESS, ACTION_TYPES.SUBMIT_PROJECT_LIST_FAILURE],
            data
        }
    };
};
export const planStatusList = () => {
    return {
        url: API_URL.FIVE_YEAR_PLAN.STATUS_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_STATUS_LIST_REQUEST,
                ACTION_TYPES.FETCH_STATUS_LIST_SUCCESS,
                ACTION_TYPES.FETCH_STATUS_LIST_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};

export const submitFinalAttachmentApi = (data) => {
    return {
        url: API_URL.SWM_PLAN.FINAL_ATTACHMENT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.SUBMIT_FINAL_ATTACHMENT_REQUEST, ACTION_TYPES.SUBMIT_FINAL_ATTACHMENT_SUCCESS, ACTION_TYPES.SUBMIT_FINAL_ATTACHMENT_FAILURE],
            data
        }
    };
};
