import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS`,
    FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST`,
    FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS`,
    FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE`,

    WASTE_PROCESSING_SITE_DROPDOWN: `${STATE_REDUCER_KEY}/WASTE_PROCESSING_SITE_DROPDOWN`,
    WASTE_PROCESSING_SITE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/WASTE_PROCESSING_SITE_DROPDOWN_REQUEST`,
    WASTE_PROCESSING_SITE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/WASTE_PROCESSING_SITE_DROPDOWN_SUCCESS`,
    WASTE_PROCESSING_SITE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/WASTE_PROCESSING_SITE_DROPDOWN_FAILURE`,

    GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID`,
    GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_REQUEST`,
    GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_SUCCESS`,
    GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_FAILURE`,

    POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS: `${STATE_REDUCER_KEY}/POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS`,
    POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST`,
    POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS`,
    POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE`,

    PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS`,
    PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST`,
    PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS`,
    PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE`,

    DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS: `${STATE_REDUCER_KEY}/DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS`,
    DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST`,
    DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS`,
    DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE`,

    DOWNLOAD_WASTE_PROCESSING_PLANT_DETAILS_DETAILS: `${STATE_REDUCER_KEY}/DOWNLOAD_WASTE_PROCESSING_PLANT_DETAILS_DETAILS`,
    DOWNLOAD_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_REQUEST`,
    DOWNLOAD_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS`,
    DOWNLOAD_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE`
};

export const fetchWasteProcessingPlantDetails = createAction(ACTION_TYPES.FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS);
export const wasteProcessingSiteDropdown = createAction(ACTION_TYPES.WASTE_PROCESSING_SITE_DROPDOWN);
export const getWasteProcessingPlantDetailsById = createAction(ACTION_TYPES.GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID);
export const postWasteProcessingPlantDetails = createAction(ACTION_TYPES.POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS);
export const patchWasteProcessingPlantDetails = createAction(ACTION_TYPES.PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS);
export const deleteWasteProcessingDetails = createAction(ACTION_TYPES.DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS);
export const downloadWasteProcessingPlantDetails = createAction(ACTION_TYPES.DOWNLOAD_WASTE_PROCESSING_PLANT_DETAILS_DETAILS);


