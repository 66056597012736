import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    SUBMIT_OTHER_DEPARTMENTS_DETAILS: `${STATE_REDUCER_KEY}/SUBMIT_OTHER_DEPARTMENTS_DETAILS`,
    SUBMIT_OTHER_DEPARTMENTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_OTHER_DEPARTMENTS_DETAILS_REQUEST`,
    SUBMIT_OTHER_DEPARTMENTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_OTHER_DEPARTMENTS_DETAILS_SUCCESS`,
    SUBMIT_OTHER_DEPARTMENTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_OTHER_DEPARTMENTS_DETAILS_FAILURE`,

    FETCH_OTHER_DEPARTMENTS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_OTHER_DEPARTMENTS_BY_ID`,
    FETCH_OTHER_DEPARTMENTS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_OTHER_DEPARTMENTS_BY_ID_REQUEST`,
    FETCH_OTHER_DEPARTMENTS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_OTHER_DEPARTMENTS_BY_ID_SUCCESS`,
    FETCH_OTHER_DEPARTMENTS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_OTHER_DEPARTMENTS_BY_ID_FAILURE`,

    DELETE_OTHER_DEPARTMENTS_DETAILS: `${STATE_REDUCER_KEY}/DELETE_OTHER_DEPARTMENTS_DETAILS`,
    DELETE_OTHER_DEPARTMENTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_OTHER_DEPARTMENTS_DETAILS_REQUEST`,
    DELETE_OTHER_DEPARTMENTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_OTHER_DEPARTMENTS_DETAILS_SUCCESS`,
    DELETE_OTHER_DEPARTMENTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_OTHER_DEPARTMENTS_DETAILS_FAILURE`,

    LIST_OTHER_DEPARTMENTS_DETAILS: `${STATE_REDUCER_KEY}/LIST_OTHER_DEPARTMENTS_DETAILS`,
    LIST_OTHER_DEPARTMENTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/LIST_OTHER_DEPARTMENTS_DETAILS_REQUEST`,
    LIST_OTHER_DEPARTMENTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_OTHER_DEPARTMENTS_DETAILS_SUCCESS`,
    LIST_OTHER_DEPARTMENTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/LIST_OTHER_DEPARTMENTS_DETAILS_FAILURE`,

    UPDATE_OTHER_DEPARTMENTS_DETAILS: `${STATE_REDUCER_KEY}/UPDATE_OTHER_DEPARTMENTS_DETAILS`,
    UPDATE_OTHER_DEPARTMENTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_OTHER_DEPARTMENTS_DETAILS_REQUEST`,
    UPDATE_OTHER_DEPARTMENTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_OTHER_DEPARTMENTS_DETAILS_SUCCESS`,
    UPDATE_OTHER_DEPARTMENTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_OTHER_DEPARTMENTS_DETAILS_FAILURE`,

    SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE: `${STATE_REDUCER_KEY}/SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE`,
    SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_REQUEST`,
    SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_SUCCESS`,
    SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_FAILURE`,

    SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY: `${STATE_REDUCER_KEY}/SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY`,
    SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_REQUEST`,
    SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_SUCCESS`,
    SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_FAILURE`,

    SUBMIT_IEA_CONTACTS_DETAILS: `${STATE_REDUCER_KEY}/SUBMIT_IEA_CONTACTS_DETAILS`,
    SUBMIT_IEA_CONTACTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_IEA_CONTACTS_DETAILS_REQUEST`,
    SUBMIT_IEA_CONTACTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_IEA_CONTACTS_DETAILS_SUCCESS`,
    SUBMIT_IEA_CONTACTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_IEA_CONTACTS_DETAILS_FAILURE`,

    FETCH_IEA_CONTACTS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_IEA_CONTACTS_BY_ID`,
    FETCH_IEA_CONTACTS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_IEA_CONTACTS_BY_ID_REQUEST`,
    FETCH_IEA_CONTACTS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_IEA_CONTACTS_BY_ID_SUCCESS`,
    FETCH_IEA_CONTACTS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_IEA_CONTACTS_BY_ID_FAILURE`,

    DELETE_IEA_CONTACTS_DETAILS: `${STATE_REDUCER_KEY}/DELETE_IEA_CONTACTS_DETAILS`,
    DELETE_IEA_CONTACTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_IEA_CONTACTS_DETAILS_REQUEST`,
    DELETE_IEA_CONTACTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_IEA_CONTACTS_DETAILS_SUCCESS`,
    DELETE_IEA_CONTACTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_IEA_CONTACTS_DETAILS_FAILURE`,

    LIST_IEA_CONTACTS_DETAILS: `${STATE_REDUCER_KEY}/LIST_IEA_CONTACTS_DETAILS`,
    LIST_IEA_CONTACTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/LIST_IEA_CONTACTS_DETAILS_REQUEST`,
    LIST_IEA_CONTACTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_IEA_CONTACTS_DETAILS_SUCCESS`,
    LIST_IEA_CONTACTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/LIST_IEA_CONTACTS_DETAILS_FAILURE`,

    UPDATE_IEA_CONTACTS_DETAILS: `${STATE_REDUCER_KEY}/UPDATE_IEA_CONTACTS_DETAILS`,
    UPDATE_IEA_CONTACTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_IEA_CONTACTS_DETAILS_REQUEST`,
    UPDATE_IEA_CONTACTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_IEA_CONTACTS_DETAILS_SUCCESS`,
    UPDATE_IEA_CONTACTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_IEA_CONTACTS_DETAILS_FAILURE`,

    SELECT_IEA_CONTACT_TYPE: `${STATE_REDUCER_KEY}/SELECT_IEA_CONTACT_TYPE`,
    SELECT_IEA_CONTACT_TYPE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_IEA_CONTACT_TYPE_REQUEST`,
    SELECT_IEA_CONTACT_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_IEA_CONTACT_TYPE_SUCCESS`,
    SELECT_IEA_CONTACT_TYPE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_IEA_CONTACT_TYPE_FAILURE`,

    SELECT_IEA_LOOKUP_CATEGORY: `${STATE_REDUCER_KEY}/SELECT_IEA_LOOKUP_CATEGORY`,
    SELECT_IEA_LOOKUP_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/SELECT_IEA_LOOKUP_CATEGORY_REQUEST`,
    SELECT_IEA_LOOKUP_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_IEA_LOOKUP_CATEGORY_SUCCESS`,
    SELECT_IEA_LOOKUP_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/SELECT_IEA_LOOKUP_CATEGORY_FAILURE`,

    FILTER_IEA_NAME: `${STATE_REDUCER_KEY}/FILTER_IEA_NAME`,
    FILTER_IEA_NAME_REQUEST: `${STATE_REDUCER_KEY}/FILTER_IEA_NAME_REQUEST`,
    FILTER_IEA_NAME_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_IEA_NAME_SUCCESS`,
    FILTER_IEA_NAME_FAILURE: `${STATE_REDUCER_KEY}/FILTER_IEA_NAME_FAILURE`,

    FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
    FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
    FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
    FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,

    FETCH_ADDRESS_LIST: `${STATE_REDUCER_KEY}/FETCH_ADDRESS_LIST`,
    FETCH_ADDRESS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ADDRESS_LIST_REQUEST`,
    FETCH_ADDRESS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ADDRESS_LIST_SUCCESS`,
    FETCH_ADDRESS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ADDRESS_LIST_FAILURE`

};

export const submitOtherDepartmentsDetails = createAction(ACTION_TYPES.SUBMIT_OTHER_DEPARTMENTS_DETAILS);

export const getOtherDepartmentsById = createAction(ACTION_TYPES.FETCH_OTHER_DEPARTMENTS_BY_ID);

export const deleteOtherDepartmentsDetails = createAction(ACTION_TYPES.DELETE_OTHER_DEPARTMENTS_DETAILS);

export const listOtherDepartmentsDetails = createAction(ACTION_TYPES.LIST_OTHER_DEPARTMENTS_DETAILS);

export const updateOtherDepartmentsDetails = createAction(ACTION_TYPES.UPDATE_OTHER_DEPARTMENTS_DETAILS);

export const getContactTypeDropdownOption = createAction(ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE);

export const getLookupCategoryDropdownOption = createAction(ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY);

export const submitIeaContactsDetails = createAction(ACTION_TYPES.SUBMIT_IEA_CONTACTS_DETAILS);

export const getIeaContactsById = createAction(ACTION_TYPES.FETCH_IEA_CONTACTS_BY_ID);

export const deleteIeaContactsDetails = createAction(ACTION_TYPES.DELETE_IEA_CONTACTS_DETAILS);

export const listIeaContactsDetails = createAction(ACTION_TYPES.LIST_IEA_CONTACTS_DETAILS);

export const updateIeaContactsDetails = createAction(ACTION_TYPES.UPDATE_IEA_CONTACTS_DETAILS);

export const getIeaContactTypeDropdownOption = createAction(ACTION_TYPES.SELECT_IEA_CONTACT_TYPE);

export const getIeaLookupCategoryDropdownOption = createAction(ACTION_TYPES.SELECT_IEA_LOOKUP_CATEGORY);

export const filterIeaName = createAction(ACTION_TYPES.FILTER_IEA_NAME);
export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);

export const getAddressList = createAction(ACTION_TYPES.FETCH_ADDRESS_LIST);
