
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { ACTION_TYPES } from "./actions";
import { approveDpmuApi, approveMunicipalApi, approveSpmuApi, assignFiveYearProjectApi, ceiledAmountApi, deleteFileById, deleteFiveYearPlanApi, fetchDistricts, fetchSwmPlanApi, fiveYearPlanList, fiveYearPlanListById, listFiveYearApi, listSubProjectApi, lsgiDropdownApi, planStatusList, rejectDpmuApi, rejectMunicipalApi, rejectSpmuApi, submitFinalAttachmentApi, submitFiveYearPlanApi, ulbSubmittedToMcApi, updateFiveYearPlanApi } from "./api";
import { navigateTo } from "modules/common/actions";
import { infoNotify, successNotify } from "utils/notificationUtils";
import { MODULE_PATH } from "modules/paths";
import { SUB_PROJECTS_PATH as PATH } from "../paths";
import { I18n } from "common/components";
import { actions as commonActions } from "../../common/slice";
import { toEpoch } from "utils/dateUtils";
import { TABLE_IDS } from "common/constants";
import _ from "lodash";
import { fetchSwmPlanRevisionListApi } from "../swmPlan/api";
import { actions as sliceActions } from "./slice";


const formatData = (rest) => {
    const { swmPlan, districtId, lsgiId, planCode, planName, remarks, toYear, fromYear, attachmentIds } = rest;
    const data = { swmPlan, districtId: districtId, lsgiId: lsgiId, planCode, planName, remarks, toYear: Number(toYear), fromYear: Number(fromYear), attachmentIds };
    return data;
};

const formatEditData = (action) => {
    const { payload } = action;
    const { districtId, lsgiId, planCode, planName, remarks, toYear, fromYear, attachmentIds, swmPlanId, id } = payload;
    const data = { swmPlanId, districtId: districtId, lsgiId: lsgiId, planCode, planName, remarks, toYear: Number(toYear), fromYear: Number(fromYear), attachmentIds, id };
    return data;
};

export function* listFiveYearPlan({ payload }) {
    let updatedPayload = { swmPlan: payload?.id, pageNo: payload?.pageNo, pageSize: payload?.pageSize };
    yield fork(handleAPIRequest, fiveYearPlanList, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_SUCCESS, ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.FIVE_YEAR_LIST);
}

export function* submitFiveYearPlan(action) {
    let { payload: { id, ...rest } = {} } = action;
    yield put(sliceActions.setFetchFiveYearPlanListById(formatData(rest)));
    yield fork(handleAPIRequest, submitFiveYearPlanApi, formatData(rest));
    const responseAction = yield take([ACTION_TYPES.SUBMIT_FIVE_YEAR_PLAN_SUCCESS, ACTION_TYPES.SUBMIT_FIVE_YEAR_PLAN_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_FIVE_YEAR_PLAN_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("five_year_plan") }) }));
        yield call(listFiveYearPlan, { payload: id });
        yield put(sliceActions.resetFetchFiveYearPlanListById());
    }
}

export function* updateFiveYearPlan(action) {
    let { payload: { swmPlan } = {} } = action;
    yield fork(handleAPIRequest, updateFiveYearPlanApi, formatEditData(action));
    yield put(sliceActions.setFetchFiveYearPlanListById(formatEditData(action)));
    const responseAction = yield take([ACTION_TYPES.UPDATE_FIVE_YEAR_PLAN_SUCCESS, ACTION_TYPES.UPDATE_FIVE_YEAR_PLAN_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_FIVE_YEAR_PLAN_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("five_year_plan") }) }));
        yield fork(handleAPIRequest, fiveYearPlanList, { swmPlan: swmPlan, pageSize: 5, pageNo: 0 });
        const COMPARISON_TYPES = [ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_SUCCESS, ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.FIVE_YEAR_LIST);
        yield put(sliceActions.resetFetchFiveYearPlanListById());
    }
}

export function* deleteFiveYearPlan({ payload: id }) {
    yield fork(handleAPIRequest, deleteFiveYearPlanApi, id);
    const response = yield take([ACTION_TYPES.DELETE_FIVE_YEAR_PLAN_PROJECT_SUCCESS, ACTION_TYPES.DELETE_FIVE_YEAR_PLAN_PROJECT_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_FIVE_YEAR_PLAN_PROJECT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("five_year_plan") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listFiveYearPlan, { payload: tablePayload });
    }
}

export function* lsgiDropdown(action) {
    yield call(handleAPIRequest, lsgiDropdownApi, action.payload);
}
export function* districts() {
    yield call(handleAPIRequest, fetchDistricts);
}

export function* getSwmPlan({ payload }) {
    yield call(handleAPIRequest, fetchSwmPlanApi, payload);
}

export function* listFiveYearPlanById({ payload }) {
    yield call(handleAPIRequest, fiveYearPlanListById, payload);
}

export function* ceiledAmount({ payload }) {
    yield call(handleAPIRequest, ceiledAmountApi, payload);
}

export function* ulbSubmittedToMc({ payload }) {
    yield fork(handleAPIRequest, ulbSubmittedToMcApi, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMITTED_TO_MUNICIPAL_SUCCESS, ACTION_TYPES.SUBMITTED_TO_MUNICIPAL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMITTED_TO_MUNICIPAL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("creat", { type: I18n("five_year_plan") }) }));
    }
}

const formatSpmuData = (rest) => {
    const { spmuRemarks, planId } = rest;
    const data = { planId: Number(planId), spmuRemarks };
    return data;
};
export function* approveSpmu(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, approveSpmuApi, formatSpmuData(rest));
    const responseAction = yield take([ACTION_TYPES.APPROVE_SPMU_SUCCESS, ACTION_TYPES.APPROVE_SPMU_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_SPMU_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approved", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.FIVE_YEAR_PLAN.LIST}`));
    }
}
export function* rejectSpmu(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, rejectSpmuApi, formatSpmuData(rest));
    const responseAction = yield take([ACTION_TYPES.REJECT_SPMU_SUCCESS, ACTION_TYPES.REJECT_SPMU_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_SPMU_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("rejected", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.FIVE_YEAR_PLAN.LIST}`));
    }
}

const formatDpmuData = (rest) => {
    const { councilResolutionNumber, date, dpmuRemarks, planId } = rest;
    const data = { planId: Number(planId), date: toEpoch(date), councilResolutionNumber, dpmuRemarks };
    return data;
};
export function* approveDpmu(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, approveDpmuApi, formatDpmuData(rest));
    const responseAction = yield take([ACTION_TYPES.APPROVE_DPMU_SUCCESS, ACTION_TYPES.APPROVE_DPMU_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_DPMU_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approved", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.FIVE_YEAR_PLAN.LIST}`));
    }
}
export function* rejectDpmu(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, rejectDpmuApi, formatDpmuData(rest));
    const responseAction = yield take([ACTION_TYPES.REJECT_DPMU_SUCCESS, ACTION_TYPES.REJECT_DPMU_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_DPMU_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("rejected", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.FIVE_YEAR_PLAN.LIST}`));
    }
}

const formatMunicipalData = (rest) => {
    const { mcResolutionNumber, mcResolutionDate, mcRemarks, planId } = rest;
    const data = { planId: Number(planId), mcResolutionDate: toEpoch(mcResolutionDate), mcResolutionNumber, mcRemarks };
    return data;
};
export function* approveMunicipal(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, approveMunicipalApi, formatMunicipalData(rest));
    const responseAction = yield take([ACTION_TYPES.APPROVE_MUNICIPAL_SUCCESS, ACTION_TYPES.APPROVE_MUNICIPAL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_MUNICIPAL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approved", { type: I18n("draft_plan") }) }));
    }
}
export function* rejectMunicipal(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, rejectMunicipalApi, formatMunicipalData(rest));
    const responseAction = yield take([ACTION_TYPES.REJECT_MUNICIPAL_SUCCESS, ACTION_TYPES.REJECT_MUNICIPAL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_MUNICIPAL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("rejected", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.FIVE_YEAR_PLAN.LIST}`));
    }
}
export function* deleteFileFn({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, deleteFileById, id, fileId);
    const response = yield take([ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_FILE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: "file" }) }));
    }
}
export function* listFiveYearProject({ payload }) {
    yield call(handleAPIRequest, listFiveYearApi, payload);
}

export function* listSubProject({ payload }) {
    let updatedPayload = { swmPlanApproved: true, isProjectsExclude: true, ...payload };
    yield call(handleAPIRequest, listSubProjectApi, updatedPayload);
}
export function* submitAssignedProjects({ payload }) {
    yield fork(handleAPIRequest, assignFiveYearProjectApi, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_PROJECT_LIST_SUCCESS, ACTION_TYPES.SUBMIT_PROJECT_LIST_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_PROJECT_LIST_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("submitted", { type: I18n("assigned_five_year_project") }) }));
        yield put(commonActions.moveTabUp());
        yield call(handleAPIRequest, fiveYearPlanListById, payload.fiveYearPlanId);
        if (!_.isEmpty(responseAction?.payload)) {
            yield put(infoNotify({ title: I18n("success"), message: I18n("Total projects cost mapped is greater than the ceiled amount"), dismissAfter: 4500 }));
        }
    }
}
export function* fetchPlanStatusList({ payload }) {
    yield call(handleAPIRequest, planStatusList, payload);
}
const formatFinalAttachmentData = (rest) => {
    const { swmPlanAttachmentIds, swmPlanId } = rest;
    const data = { swmPlanAttachmentIds, swmPlanId: swmPlanId };
    return data;
};
export function* submitFinalAttachment(action) {
    let { payload: { ...rest } = {} } = action;
    let { swmPlanAttachmentIds } = rest;
    if (!_.isEmpty(swmPlanAttachmentIds)) {
        yield fork(handleAPIRequest, submitFinalAttachmentApi, formatFinalAttachmentData(rest));
    }
    const responseAction = yield take([ACTION_TYPES.SUBMIT_FINAL_ATTACHMENT_SUCCESS, ACTION_TYPES.SUBMIT_FINAL_ATTACHMENT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_FINAL_ATTACHMENT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("final_plan") }) }));
        yield call(handleAPIRequest, fetchSwmPlanRevisionListApi, { swmPlanId: action.payload.swmPlanId });
    }
}

export default function* fiveYearPlanSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_FIVE_YEAR_PLAN, submitFiveYearPlan),
        takeLatest(ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT, listFiveYearPlan),
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN, districts),
        takeLatest(ACTION_TYPES.FETCH_LSGI_DROPDOWN, lsgiDropdown),
        takeLatest(ACTION_TYPES.LIST_FIVE_YEAR_PLAN_PROJECT_BY_ID, listFiveYearPlanById),
        takeLatest(ACTION_TYPES.SUBMITTED_TO_MUNICIPAL, ulbSubmittedToMc),
        takeLatest(ACTION_TYPES.APPROVE_SPMU, approveSpmu),
        takeLatest(ACTION_TYPES.REJECT_SPMU, rejectSpmu),
        takeLatest(ACTION_TYPES.REJECT_DPMU, rejectDpmu),
        takeLatest(ACTION_TYPES.APPROVE_DPMU, approveDpmu),
        takeLatest(ACTION_TYPES.REJECT_MUNICIPAL, rejectMunicipal),
        takeLatest(ACTION_TYPES.APPROVE_MUNICIPAL, approveMunicipal),
        takeLatest(ACTION_TYPES.DELETE_FIVE_YEAR_PLAN_PROJECT, deleteFiveYearPlan),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFileFn),
        takeLatest(ACTION_TYPES.FETCH_FIVE_YEAR_LIST, listFiveYearProject),
        takeLatest(ACTION_TYPES.FETCH_SUB_PROJECT_LIST, listSubProject),
        takeLatest(ACTION_TYPES.SUBMIT_PROJECT_LIST, submitAssignedProjects),
        takeLatest(ACTION_TYPES.FETCH_STATUS_LIST, fetchPlanStatusList),
        takeLatest(ACTION_TYPES.FETCH_SWM_PLAN_DROPDOWN, getSwmPlan),
        takeLatest(ACTION_TYPES.CEILED_AMOUNT, ceiledAmount),
        takeLatest(ACTION_TYPES.SUBMIT_FINAL_ATTACHMENT, submitFinalAttachment),
        takeLatest(ACTION_TYPES.UPDATE_FIVE_YEAR_PLAN, updateFiveYearPlan)
    ]);
}
