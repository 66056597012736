import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_SOURCE_FOR_BDW_DETAILS: `${STATE_REDUCER_KEY}/FETCH_SOURCE_FOR_BDW_DETAILS`,
    FETCH_SOURCE_FOR_BDW_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SOURCE_FOR_BDW_DETAILS_REQUEST`,
    FETCH_SOURCE_FOR_BDW_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SOURCE_FOR_BDW_DETAILS_SUCCESS`,
    FETCH_SOURCE_FOR_BDW_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SOURCE_FOR_BDW_DETAILS_FAILURE`,

    GET_WARD_DROPDOWN: `${STATE_REDUCER_KEY}/GET_WARD_DROPDOWN`,
    GET_WARD_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_WARD_DROPDOWN_REQUEST`,
    GET_WARD_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_WARD_DROPDOWN_SUCCESS`,
    GET_WARD_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_WARD_DROPDOWN_FAILURE`,

    POST_SOURCE_FOR_BDW_DETAILS: `${STATE_REDUCER_KEY}/POST_SOURCE_FOR_BDW_DETAILS`,
    POST_SOURCE_FOR_BDW_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_SOURCE_FOR_BDW_DETAILS_REQUEST`,
    POST_SOURCE_FOR_BDW_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_SOURCE_FOR_BDW_DETAILS_SUCCESS`,
    POST_SOURCE_FOR_BDW_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_SOURCE_FOR_BDW_DETAILS_FAILURE`,

    PATCH_SOURCE_FOR_BDW_DETAILS: `${STATE_REDUCER_KEY}/PATCH_SOURCE_FOR_BDW_DETAILS`,
    PATCH_SOURCE_FOR_BDW_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SOURCE_FOR_BDW_DETAILS_REQUEST`,
    PATCH_SOURCE_FOR_BDW_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SOURCE_FOR_BDW_DETAILS_SUCCESS`,
    PATCH_SOURCE_FOR_BDW_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SOURCE_FOR_BDW_DETAILS_FAILURE`,

    GET_SOURCE_FOR_BDW_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/GET_SOURCE_FOR_BDW_DETAILS_BY_ID`,
    GET_SOURCE_FOR_BDW_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_SOURCE_FOR_BDW_DETAILS_BY_ID_REQUEST`,
    GET_SOURCE_FOR_BDW_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_SOURCE_FOR_BDW_DETAILS_BY_ID_SUCCESS`,
    GET_SOURCE_FOR_BDW_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_SOURCE_FOR_BDW_DETAILS_FAILURE`,

    DELETE_SOURCE_FOR_BDW_DETAILS: `${STATE_REDUCER_KEY}/DELETE_SOURCE_FOR_BDW_DETAILS`,
    DELETE_SOURCE_FOR_BDW_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_SOURCE_FOR_BDW_DETAILS_REQUEST`,
    DELETE_SOURCE_FOR_BDW_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_SOURCE_FOR_BDW_DETAILS_SUCCESS`,
    DELETE_SOURCE_FOR_BDW_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_SOURCE_FOR_BDW_DETAILS_FAILURE`,

    DOWNLOAD_SOURCE_FOR_BDW_DETAILS: `${STATE_REDUCER_KEY}/DOWNLOAD_SOURCE_FOR_BDW_DETAILS`,
    DOWNLOAD_SOURCE_FOR_BDW_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_SOURCE_FOR_BDW_DETAILS_REQUEST`,
    DOWNLOAD_SOURCE_FOR_BDW_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_SOURCE_FOR_BDW_DETAILS_SUCCESS`,
    DOWNLOAD_SOURCE_FOR_BDW_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_SOURCE_FOR_BDW_DETAILS_FAILURE`
};

export const fetchSourceForBdwList = createAction(ACTION_TYPES.FETCH_SOURCE_FOR_BDW_DETAILS);
export const wardDropdown = createAction(ACTION_TYPES.GET_WARD_DROPDOWN);
export const postSourceForBdwDetails = createAction(ACTION_TYPES.POST_SOURCE_FOR_BDW_DETAILS);
export const patchSourceForBdwDetails = createAction(ACTION_TYPES.PATCH_SOURCE_FOR_BDW_DETAILS);
export const getSourceForBdwDetailsById = createAction(ACTION_TYPES.GET_SOURCE_FOR_BDW_DETAILS_BY_ID);
export const deleteSourceForBdwDetails = createAction(ACTION_TYPES.DELETE_SOURCE_FOR_BDW_DETAILS);
export const downloadSourceForBdwDetails = createAction(ACTION_TYPES.DOWNLOAD_SOURCE_FOR_BDW_DETAILS);


