import { I18n } from "common/components";
import _ from "lodash";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { getPayloadContent } from "utils/apiUtils";
import { successNotify } from "utils/notificationUtils";
import { getAdditionalFilters, getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { dataAccessDeleteCall, districtDropdownApi, filterRoleMapping, ivaDropdownApi, lsgiDropdownApi, lsgiTypeDropdownApi, rolesLevelDropdownApi, stateDropdownApi, submitRoleDataAccessPostApi, trainingOrganizationDropdownApi, userDataAccessEditCall, userRoleADataAccessFetchAPICall, userRoleMappingEditCall, userRoleMappingPostAPICall, userRolesFetchApiCallById, userRoleUnassignFetchAPICall, vendorDropdownApi, wardDropdownApi } from "./api";
import { getRoleSelectedRows, getUserRoleListAdditionalFilter } from "./selectors";
import { actions as sliceActions } from "./slice";
import { actions as commonActions } from "../../common/slice";
import { TABLE_IDS } from "common/constants";
import { actions as commonSlice } from "modules/common/slice";
import { getTableProps } from "modules/common/selectors";

const formatValue = ({ userId, rolesId = [] }) => {
    let roleMappings = [];
    _.forEach(rolesId, (id) => {
        roleMappings.push({ userId: { userId: userId }, roleId: { id } });
    });
    return { roleMappings };
};

export function* fetchUserRoles(action) {
    let additionalFilters = yield* getAdditionalFilters(TABLE_IDS.USER_MAPPING);
    let userRoleListAdditionalFilter = yield select(getUserRoleListAdditionalFilter);
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.USER_MAPPING]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, userRoleUnassignFetchAPICall, { ...action.payload, ...additionalFilters, ...filters, ...userRoleListAdditionalFilter });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_SUCCESS, ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.USER_MAPPING);
}

export function* submitUserRoleMappingDetails(action) {
    let { payload } = action;
    yield fork(handleAPIRequest, userRoleMappingPostAPICall, payload);
    const response = yield take([ACTION_TYPES.SUBMIT_USER_ROLE_MAPPING_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_USER_ROLE_MAPPING_DETAILS_FAILURE]);
    if (response.type === ACTION_TYPES.SUBMIT_USER_ROLE_MAPPING_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("user_role_mapping") }) }));
        yield put(sliceActions.setOpen(false));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        yield put(commonSlice.setRowSelection({ key: TABLE_IDS.USER_MAPPING, items: {} }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchUserRoles, { payload: tablePayload });
    }
}

export function* editUserRoleMappingList({ payload }) {
    const roles = yield select(getRoleSelectedRows);
    const rolesId = roles.map(item => item?.roleId?.id);
    yield fork(handleAPIRequest, userRoleMappingEditCall, formatValue({ userId: payload, rolesId }));
    const response = yield take([ACTION_TYPES.EDIT_USER_ROLE_MAPPING_DETAILS_SUCCESS, ACTION_TYPES.EDIT_USER_ROLE_MAPPING_DETAILS_FAILURE]);
    if (response.type === ACTION_TYPES.EDIT_USER_ROLE_MAPPING_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("unassign_success", { type: I18n("user_role_mapping") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        yield put(commonSlice.setRowSelection({ key: TABLE_IDS.USER_MAPPING, items: {} }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchUserRoles, { payload: tablePayload });
    }
}
const formatForDataAccess = (values) => {
    let { userId, roleId, ...rest } = values;
    let targetReferenceId = [];
    _.forEach(rest, (obj) => {
        if (obj instanceof Array) {
            _.forEach(obj, (ele) => targetReferenceId.push(ele?.id));
        }
    });
    return { targetReferenceId, userId, roleId };
};
export function* fetchUserRolesDataAccess(action) {
    let additionalFilters = yield* getAdditionalFilters(TABLE_IDS.USER_DATA_ACCESS);
    yield fork(handleAPIRequest, userRoleADataAccessFetchAPICall, { ...action.payload, ...additionalFilters });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_USER_ROLE_DATA_ACCESS_DETAILS_SUCCESS, ACTION_TYPES.FETCH_USER_ROLE_DATA_ACCESS_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.USER_DATA_ACCESS);
}
export function* districtDropdown(action) {
    yield call(handleAPIRequest, districtDropdownApi, action.payload);
}

export function* lsgiDropdown(action) {
    const { id, districtId } = action.payload;
    yield fork(handleAPIRequest, lsgiDropdownApi, { id, districtId });

    const response = yield take([ACTION_TYPES.SELECT_LSGI_DROPDOWN_SUCCESS, ACTION_TYPES.SELECT_LSGI_DROPDOWN_FAILURE]);

    if (response.type === ACTION_TYPES.SELECT_LSGI_DROPDOWN_SUCCESS) {
        const lsgiListResponse = getPayloadContent(response, []);
        yield put(sliceActions.setLsgiList({ data: lsgiListResponse, id, districtId }));
    }
}
export function* wardDropdown(action) {
    const { id, districtId } = action.payload;
    yield fork(handleAPIRequest, wardDropdownApi, { id, districtId });
    const response = yield take([ACTION_TYPES.SELECT_WARD_DROPDOWN_SUCCESS, ACTION_TYPES.SELECT_WARD_DROPDOWN_FAILURE]);
    if (response.type === ACTION_TYPES.SELECT_WARD_DROPDOWN_SUCCESS) {
        const wardListResponse = getPayloadContent(response, []);
        yield put(sliceActions.setWardList({ data: wardListResponse, id, districtId }));
    }
}
export function* stateDropdown(action) {
    yield call(handleAPIRequest, stateDropdownApi, action.payload);
}
export function* lsgiTypeSelectDropdown(action) {
    yield call(handleAPIRequest, lsgiTypeDropdownApi, action.payload);
}
export function* trainingOrganizationDropdown(action) {
    yield call(handleAPIRequest, trainingOrganizationDropdownApi, action.payload);
}
export function* vendorDropdown(action) {
    yield call(handleAPIRequest, vendorDropdownApi, action.payload);
}

export function* fetchUserRolesById({ payload }) {
    yield call(handleAPIRequest, userRolesFetchApiCallById, payload);
}
export function* roleLevelDataAccessDropdown(action) {
    yield call(handleAPIRequest, rolesLevelDropdownApi, action.payload);
}
export function* ivaDropdown(action) {
    yield call(handleAPIRequest, ivaDropdownApi, action.payload);
}

export function* deleteRoleDataAccess({ payload }) {
    yield fork(handleAPIRequest, dataAccessDeleteCall, payload);
    const response = yield take([ACTION_TYPES.DELETE_DATA_ACCESS_DETAILS_SUCCESS, ACTION_TYPES.DELETE_DATA_ACCESS_DETAILS_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_DATA_ACCESS_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("data_access") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchUserRolesDataAccess, { payload: tablePayload });
    }
}
export function* addRoleDataAccess(action) {

    let { payload: { id, ...rest } = {} } = action;
    let editId = id ? id : null;
    if (editId) {
        yield fork(handleAPIRequest, userDataAccessEditCall, formatForDataAccess(rest));

        const response = yield take([ACTION_TYPES.EDIT_DATA_ACCESS_DETAILS_SUCCESS, ACTION_TYPES.EDIT_DATA_ACCESS_DETAILS_SUCCESS]);
        if (response.type === ACTION_TYPES.EDIT_DATA_ACCESS_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("data_access") }) }));
            yield put(sliceActions.setOpen(false));
            yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
            const tablePayload = yield* getPaginationPayload();
            yield call(fetchUserRolesDataAccess, { payload: tablePayload });
        }
    } else {
        yield fork(handleAPIRequest, submitRoleDataAccessPostApi, formatForDataAccess(rest));
        const response = yield take([ACTION_TYPES.SUBMIT_ROLE_DATA_ACCESS_SUCCESS, ACTION_TYPES.SUBMIT_ROLE_DATA_ACCESS_FAILURE]);
        if (response.type === ACTION_TYPES.SUBMIT_ROLE_DATA_ACCESS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("data_access") }) }));
            yield put(sliceActions.setOpen(false));
            yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
            const tablePayload = yield* getPaginationPayload();
            yield call(fetchUserRolesDataAccess, { payload: tablePayload });
        }
    }
}
export function* listFilterRoleMapping({ payload: { value, key, tableData, userId } = {} }) {
    let newPayload = {};
    _.set(newPayload, `${key}`, value);
    yield fork(handleAPIRequest, filterRoleMapping, { roleName: value, assignedRoles: tableData, userId: userId, dropDown: true });
    const response = yield take([ACTION_TYPES.FILTER_ROLE_MAPPING_SUCCESS, ACTION_TYPES.FILTER_ROLE_MAPPING_FAILURE]);
    if (response.type === ACTION_TYPES.FILTER_ROLE_MAPPING_SUCCESS) {
        yield put(sliceActions.setRoleMappingName({ key, value: getPayloadContent(response) }));
    }
}
export default function* userRoleMappingSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_USER_ROLE_MAPPING_DETAILS, submitUserRoleMappingDetails),
        takeLatest(ACTION_TYPES.EDIT_USER_ROLE_MAPPING_DETAILS, editUserRoleMappingList),
        takeLatest(ACTION_TYPES.FETCH_USER_ROLE_MAPPING_DETAILS, fetchUserRoles),
        takeLatest(ACTION_TYPES.FETCH_USER_ROLE_DATA_ACCESS_DETAILS, fetchUserRolesDataAccess),
        takeLatest(ACTION_TYPES.SELECT_DISTRICT_DROPDOWN, districtDropdown),
        takeLatest(ACTION_TYPES.SELECT_LSGI_DROPDOWN, lsgiDropdown),
        takeLatest(ACTION_TYPES.SELECT_WARD_DROPDOWN, wardDropdown),
        takeLatest(ACTION_TYPES.SELECT_STATE_DROPDOWN, stateDropdown),
        takeLatest(ACTION_TYPES.SELECT_STATE_DROPDOWN, trainingOrganizationDropdown),
        takeLatest(ACTION_TYPES.SELECT_STATE_DROPDOWN, vendorDropdown),
        takeLatest(ACTION_TYPES.SUBMIT_ROLE_DATA_ACCESS, addRoleDataAccess),
        takeLatest(ACTION_TYPES.SELECT_ROLE_LEVEL_DROPDOWN, roleLevelDataAccessDropdown),
        takeLatest(ACTION_TYPES.DELETE_DATA_ACCESS_DETAILS, deleteRoleDataAccess),
        takeLatest(ACTION_TYPES.FETCH_USER_ROLE_BY_ID_DETAILS, fetchUserRolesById),
        takeLatest(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN, lsgiTypeSelectDropdown),
        takeLatest(ACTION_TYPES.FILTER_ROLE_MAPPING, listFilterRoleMapping),
        takeLatest(ACTION_TYPES.IVA_DROPDOWN, ivaDropdown)
    ]);
}

