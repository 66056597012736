import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const initialState = {
  projectActivityCreate: {
    requestInProgress: false,
    data: {
      activityName: "",
      vendor: ""
    }
  },
  modalOpen: false,
  contractorList: {
    requestInProgress: false,
    data: []
  },
  technicalForm: {
    requestInProgress: false,
    data: {
      work: "",
      scheduledStartDate: "",
      scheduledEndDate: "",
      uom: "",
      scheduledQuantity: "",
      previousCompletionStatus: "",
      recordedDate: "",
      completedQuantity: 0
    },
    viewList: []
  },
  paymentSchedule: {
    requestInProgress: false,
    data: {
      milestoneId: "",
      milestoneTitle: "",
      plannedDate: "",
      amount: "",
      scheduledDescription: "",
      vendor: ""
    }
  },
  activityNameDropdown: {
    requestInProgress: false,
    data: []
  },
  listTechnicalAll: {
    requestInProgress: false,
    data: {
      vendor: ""
    }
  }
};

const slice = createSlice({
  initialState,
  name: STATE_REDUCER_KEY,
  reducers: {
    clearAll: () => initialState,
    openModal: (state) => {
      state.modalOpen = true;
    },
    clear: (state) => {
      state.technicalForm = initialState.technicalForm;
    },
    closeModal: (state) => {
      state.modalOpen = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(ACTION_TYPES.FETCH_CONTRACTOR_LIST_SUCCESS, (state, { payload }) => {
      _.set(state, "contractorList.requestInProgress", false);
      _.set(state, "contractorList.data", payload.content);
    });
    builder.addCase(ACTION_TYPES.TECHNICAL_FORM_BY_ID_SUCCESS, (state, { payload }) => {
      _.set(state, "technicalForm.requestInProgress", false);
      _.set(state, "technicalForm.data", payload);
    })
      .addCase(ACTION_TYPES.LIST_PAYMENT_SCHEDULE_BY_ID_SUCCESS, (state, { payload }) => {
        _.set(state, "paymentSchedule.requestInProgress", false);
        _.set(state, "paymentSchedule.data", payload);
      })
      .addCase(ACTION_TYPES.CONTRACTOR_LIST_BY_ID_SUCCESS, (state, { payload }) => {
        _.set(state, "technicalForm.requestInProgress", false);
        _.set(state, "technicalForm.data", payload);
        _.set(state, "listTechnicalAll.requestInProgress", false);
        _.set(state, "listTechnicalAll.data.vendor", payload?.vendor?.name);
      })
      .addCase(ACTION_TYPES.SELECT_ACTIVITY_NAME_SUCCESS, (state, { payload }) => {
        _.set(state, "activityNameDropdown.requestInProgress", false);
        _.set(state, "activityNameDropdown.data", payload.content);
      })
      .addCase(ACTION_TYPES.PAYMENT_SCHEDULE_BY_ID_SUCCESS, (state, { payload }) => {
        _.set(state, "paymentSchedule.requestInProgress", false);
        _.set(state, "paymentSchedule.data", payload);
      })
      .addCase(ACTION_TYPES.TECHNICAL_FORM_VIEW_SUCCESS, (state, { payload }) => {
        _.set(state, "technicalForm.requestInProgress", false);
        _.set(state, "technicalForm.viewList", payload?.content);
      });
  }
});

export const { actions, reducer } = slice;
