import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { ACTION_TYPES } from "./action";
import * as Api from "./api";
import { TABLE_IDS } from "common/constants";
import { toEpoch } from "utils/dateUtils";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { navigateTo } from "modules/common/actions";
import { actions as sliceActions } from "./slice";

export function* fetchReceiptList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.RECEIPT_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchReceiptListApi, { ...filters, pageSize: 5, pageNo: 0, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_RECEIPT_LIST_SUCCESS, ACTION_TYPES.FETCH_RECEIPT_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.RECEIPT_LIST);
}

const formatData = (payload) => {
    const { asDate, lsgiId, ...rest } = payload;
    const data = { lsgiIds: lsgiId, receiptGeneratorDto: { asDate: toEpoch(asDate), ...rest } };
    return data;
};

const formatDataForEdit = (payload) => {
    const { asDate, ...rest } = payload;
    const data = { asDate: toEpoch(asDate), ...rest };
    return data;
};

export function* postReceiptDataForm({ payload }) {
    yield fork(handleAPIRequest, Api.postReceiptDataApi, formatData(payload));
    yield put(sliceActions.setReceiptDetailsById(formatData(payload)));
    const responseActions = yield take([ACTION_TYPES.POST_RECEIPT_DETAILS_SUCCESS, ACTION_TYPES.POST_RECEIPT_DETAILS_FAILURE]);
    if (responseActions.type === ACTION_TYPES.POST_RECEIPT_DETAILS_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("added_successfully")) }));
        yield put(sliceActions.resetReceiptDetailsById());
        yield put(navigateTo("/grant-management/receipt"));
    }
}

export function* patchReceiptDataForm({ payload }) {
    yield fork(handleAPIRequest, Api.patchReceiptDataApi, formatDataForEdit(payload));
    yield put(sliceActions.setReceiptDetailsById(formatDataForEdit(payload)));
    const responseActions = yield take([ACTION_TYPES.PATCH_RECEIPT_DETAILS_SUCCESS, ACTION_TYPES.PATCH_RECEIPT_DETAILS_FAILURE]);
    if (responseActions.type === ACTION_TYPES.PATCH_RECEIPT_DETAILS_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("updated_successfully")) }));
        yield put(sliceActions.resetReceiptDetailsById());
        yield put(navigateTo("/grant-management/receipt"));
    }
}

export function* getReceptDetails({ payload }) {
    yield call(handleAPIRequest, Api.getReceptDetailsApi, payload);
}

export function* deleteReceipt({ payload }) {
    yield fork(handleAPIRequest, Api.deleteReceiptApi, payload);
    const action = yield take([ACTION_TYPES.DELETE_RECEIPT_DETAILS_SUCCESS, ACTION_TYPES.DELETE_RECEIPT_DETAILS_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_RECEIPT_DETAILS_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("deleted_successfully")) }));
        yield fork(handleAPIRequest, Api.fetchReceiptListApi, { pageSize: 5, pageNo: 0 });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_RECEIPT_LIST_SUCCESS, ACTION_TYPES.FETCH_RECEIPT_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.RECEIPT_LIST);
    }
}

export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_RECEIPT_LIST, fetchReceiptList),
        takeLatest(ACTION_TYPES.POST_RECEIPT_DETAILS, postReceiptDataForm),
        takeLatest(ACTION_TYPES.PATCH_RECEIPT_DETAILS, patchReceiptDataForm),
        takeLatest(ACTION_TYPES.GET_RECEIPT_DETAILS_BY_ID, getReceptDetails),
        takeLatest(ACTION_TYPES.DELETE_RECEIPT_DETAILS, deleteReceipt)

    ]);
}
