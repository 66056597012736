import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadContentDetails, getPayloadContent } from "utils/apiUtils";

import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const RESOURCE_MANAGEMENT_DETAILS = {
    name: "",
    description: "",
    resourceId: "",
    parentId: "",
    isParent: false,
    moduleId: "",
    groupType: "",
    showCheckbox: false
};

const initialState = {
    resourceManagementGetById: {
        requestInProgress: false,
        data: {
            ...RESOURCE_MANAGEMENT_DETAILS
        }
    },
    moduleIdDropdown: {
        requestInProgress: false,
        data: []
    },
    parentIdDropdown: {
        requestInProgress: false,
        data: []
    },
    updateResourceManagement: {
        requestInProgress: false,
        data: []
    },
    filterMenuName: {
        requestInProgress: false,
        data: []
    }

};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.resourceManagementGetById = initialState.resourceManagementGetById;
            state.table = initialState.table;
            state.filterReset = initialState.filterReset;
            state.filterMenuName = initialState.filterMenuName;
        },
        setIsParent: (state) => {
            _.set(state, "resourceManagementGetById.data.isParent", !state.resourceManagementGetById.data.isParent);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_MODULE_ID_REQUEST, (state) => {
                state.moduleIdDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_MODULE_ID_SUCCESS, (state, action) => {
                _.set(state, "moduleIdDropdown.requestInProgress", false);
                _.set(state, "moduleIdDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.FETCH_MODULE_ID_FAILURE, (state) => {
                state.moduleIdDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_PARENT_ID_REQUEST, (state) => {
                state.parentIdDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_PARENT_ID_SUCCESS, (state, action) => {
                _.set(state, "parentIdDropdown.requestInProgress", false);
                _.set(state, "parentIdDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.FETCH_PARENT_ID_FAILURE, (state) => {
                state.parentIdDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.RESOURCE_MANAGEMENT_CONTACTS_BY_ID_REQUEST, (state) => {
                state.resourceManagementGetById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.RESOURCE_MANAGEMENT_CONTACTS_BY_ID_SUCCESS, (state, action) => {
                let data = getPayloadContentDetails(action);
                _.set(data, "resourceId", data.id);
                _.set(state, "resourceManagementGetById.requestInProgress", false);
                _.set(state, "resourceManagementGetById.data", data);
            })
            .addCase(ACTION_TYPES.RESOURCE_MANAGEMENT_CONTACTS_BY_ID_FAILURE, (state) => {
                state.resourceManagementGetById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FILTER_MENU_NAME_SUCCESS, (state, { payload }) => {
                _.set(state, "filterMenuName.requestInProgress", false);
                _.set(state, "filterMenuName.data", payload.content);
            });
    }
});

export const { actions, reducer } = slice;
