import { TABLE_IDS } from "common/constants";
import { commonFileDownload } from "modules/common/actions";
import { getTableProps } from "modules/common/selectors";
import { all, fork, put, select, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { setCommonTableData } from "utils/tableUtils";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";
import { fetchAnnualPlanReportApi, fetchCostInterventionListApi, fetchCostInterventionYearWiseApi, fetchDetailsOfTeamApi, fetchGapAnalysisOfWasteGenerationApi, fetchStatusSummaryReportApi, fetchTeamCompositionApi, listBdwEquipmentsReport, listBdwFacilityReport, listBdwWasteGeneratedVsCapacityReport, listCapitalCostInBdwReport, listCapitalCostInNbdwReport, listExpenditureReport, listForwardLinkingReport, listIncomeForHksFromBdwAndNbdwReport, listIncomeFromBdwAndNbdwReport, listLandUse, listNbdwEquipmentsReport, listNbdwFacilityReport, listNbdwWasteGeneratedVsCapacityReport, listPopulationVsHouseHold, listPopulationVsLandArea } from "./api";

export function* fetchPopulationVsLandArea({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.POPULATION_VS_LAND_AREA]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listPopulationVsLandArea, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_POPULATION_VS_LAND_AREA_REPORT_SUCCESS, ACTION_TYPES.LIST_POPULATION_VS_LAND_AREA_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.POPULATION_VS_LAND_AREA);
}

export function* fetchPopulationVsHouseHold({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.POPULATION_VS_HOUSE_HOLD]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listPopulationVsHouseHold, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_POPULATION_VS_HOUSE_HOLD_REPORT_SUCCESS, ACTION_TYPES.LIST_POPULATION_VS_HOUSE_HOLD_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.POPULATION_VS_HOUSE_HOLD);
}

export function* fetchLandUse({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.LAND_USE_REPORTS]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listLandUse, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_LAND_USE_SUCCESS, ACTION_TYPES.LIST_LAND_USE_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.LAND_USE_REPORTS);
}

export function* fetchNbdwEquipmentsReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.NBDW_EQUIPMENTS_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listNbdwEquipmentsReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_NBDW_EQUIPMENTS_REPORT_SUCCESS, ACTION_TYPES.LIST_NBDW_EQUIPMENTS_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.NBDW_EQUIPMENTS_REPORT);
}

export function* fetchBdwEquipmentsReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.BDW_EQUIPMENTS_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listBdwEquipmentsReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_BDW_EQUIPMENTS_REPORT_SUCCESS, ACTION_TYPES.LIST_BDW_EQUIPMENTS_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.BDW_EQUIPMENTS_REPORT);
}

export function* fetchNbdwGeneratedVsCapacityReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.NBDW_GENERATED_VS_CAPACITY]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listNbdwWasteGeneratedVsCapacityReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_NBDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS, ACTION_TYPES.LIST_NBDW_GENERATED_VS_CAPACITY_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.NBDW_GENERATED_VS_CAPACITY);
}

export function* fetchBdwGeneratedVsCapacityReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.BDW_GENERATED_VS_CAPACITY]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listBdwWasteGeneratedVsCapacityReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_BDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS, ACTION_TYPES.LIST_BDW_GENERATED_VS_CAPACITY_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.BDW_GENERATED_VS_CAPACITY);
}

export function* fetchNbdwFacilityReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.NBDW_FACILITIES_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listNbdwFacilityReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_NBDW_FACILITIES_REPORT_SUCCESS, ACTION_TYPES.LIST_NBDW_FACILITIES_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.NBDW_FACILITIES_REPORT);
}

export function* fetchBdwFacilityReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.BDW_FACILITIES_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listBdwFacilityReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_BDW_FACILITIES_REPORT_SUCCESS, ACTION_TYPES.LIST_BDW_FACILITIES_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.BDW_FACILITIES_REPORT);
}

export function* fetchCapitalCostInNbdw({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.CAPITAL_COST_IN_NBDW_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listCapitalCostInNbdwReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_CAPITAL_COST_IN_NBDW_REPORT_SUCCESS, ACTION_TYPES.LIST_CAPITAL_COST_IN_NBDW_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.CAPITAL_COST_IN_NBDW_REPORT);
}

export function* fetchCapitalCostInBdw({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.CAPITAL_COST_IN_BDW_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listCapitalCostInBdwReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_CAPITAL_COST_IN_BDW_REPORT_SUCCESS, ACTION_TYPES.LIST_CAPITAL_COST_IN_BDW_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.CAPITAL_COST_IN_BDW_REPORT);
}

export function* fetchForwardLinkingReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.FORWARD_LINKING_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listForwardLinkingReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_FORWARD_LINKING_REPORT_SUCCESS, ACTION_TYPES.LIST_FORWARD_LINKING_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.FORWARD_LINKING_REPORT);
}

export function* fetchIncomeFromBdwAndNbdwReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.INCOME_FROM_BDW_AND_NBDW_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listIncomeFromBdwAndNbdwReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_INCOME_FROM_BDW_AND_NBDW_REPORT_SUCCESS, ACTION_TYPES.LIST_INCOME_FROM_BDW_AND_NBDW_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.INCOME_FROM_BDW_AND_NBDW_REPORT);
}

export function* fetchExpenditureReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.EXPENDITURE_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listExpenditureReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_EXPENDITURE_REPORT_SUCCESS, ACTION_TYPES.LIST_EXPENDITURE_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.EXPENDITURE_REPORT);
}

export function* fetchIncomeOfHksFromBdwAndNbdwReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.INCOME_OF_HKS_FROM_BDW_AND_NBDW]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listIncomeForHksFromBdwAndNbdwReport, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_SUCCESS, ACTION_TYPES.LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.INCOME_OF_HKS_FROM_BDW_AND_NBDW);
}

export function* downloadPopulationVsLandArea(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_POPULATION_VS_LAND_AREA,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadPopulationVsHousehold(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_POPULATION_VS_HOUSE_HOLD,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadLandUse(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_LAND_USE,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadNbdwEquipments(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_NBDW_EQUIPMENTS_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadBdwEquipments(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_BDW_EQUIPMENTS_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadNbdwGeneratedvsCapacity(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadBdwGeneratedvsCapacity(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadNbdwFacilityReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_NBDW_FACILITY_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadBdwFacilityReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_BDW_FACILITY_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadCapitalCostInNbdwReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadCapitalCostInBdwReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadForwardLinkingReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_FORWARD_LINKING_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadIncomeFromBdwAndNbdwReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadExpenditureReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_EXPENDITURE_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* downloadIncomeOfHksFromBdwAndNbdwReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.REPORT.DOWNLOAD_INCOME_FOR_HKS_FROM_BDW_AND_NBDW_REPORT,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* fetchAnnualPlanReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.ANNUAL_PLAN_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchAnnualPlanReportApi, { ...filters, ...filters, pageNo: payload?.pageNo, pageSize: 10 });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_ANNUAL_PLAN_REPORT_SUCCESS, ACTION_TYPES.FETCH_ANNUAL_PLAN_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.ANNUAL_PLAN_REPORT);
}

export function* downloadAnnualPlanReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId, planStatusId } = filters;
    yield put(commonFileDownload({
        url: API_URL.SWM_SUB_PROJECT_REPORT.ANNUAL_PLAN.DOWNLOAD,
        data: { type, lsgiId, districtId, lsgiTypeId, planStatusId },
        file: { name: fileName, ext: type }
    }));
}

export function* fetchCostInterventionList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.COST_INTERVENTION_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchCostInterventionListApi, { ...filters, pageNo: payload?.pageNo, pageSize: 10 });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_SUCCESS, ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.COST_INTERVENTION_REPORT);
}

export function* downloadCostInterventionReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.SWM_SUB_PROJECT_REPORT.COST_INTERVENTION.DOWNLOAD,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* fetchStatusSummaryReport({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.STATUS_SUMMARY_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchStatusSummaryReportApi, { ...filters, ...filters, pageNo: payload?.pageNo, pageSize: 10 });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_STATUS_SUMMARY_LIST_SUCCESS, ACTION_TYPES.FETCH_STATUS_SUMMARY_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.STATUS_SUMMARY_LIST);
}

export function* downloadStatusSummaryReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId, planStatusId } = filters;
    yield put(commonFileDownload({
        url: API_URL.SWM_SUB_PROJECT_REPORT.STATUS_SUMMARY.DOWNLOAD,
        data: { type, lsgiId, districtId, lsgiTypeId, planStatusId },
        file: { name: fileName, ext: type }
    }));
}

export function* fetchCostInterventionYearWiseList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.COST_INTERVENTION_YEAR_WISE]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchCostInterventionYearWiseApi, { ...filters, ...filters, pageNo: payload?.pageNo, pageSize: 10 });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_YEAR_WISE_SUCCESS, ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_YEAR_WISE_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.COST_INTERVENTION_YEAR_WISE);
}

export function* downloadCostInterventionYearWiseReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, lsgiTypeId } = filters;
    yield put(commonFileDownload({
        url: API_URL.SWM_SUB_PROJECT_REPORT.COST_INTERVENTION_YEAR_WISE.DOWNLOAD,
        data: { type, lsgiId, districtId, lsgiTypeId },
        file: { name: fileName, ext: type }
    }));
}

export function* fetchDetailsOfTeam({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.DETAILS_OF_TEAM]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchDetailsOfTeamApi, { ...filters, ...filters, pageNo: payload?.pageNo, pageSize: 10 });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_DETAILS_OF_TEAM_REPORT_SUCCESS, ACTION_TYPES.FETCH_DETAILS_OF_TEAM_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.DETAILS_OF_TEAM);
}

export function* downloadDetailsOfTeam(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId } = filters;
    yield put(commonFileDownload({
        url: API_URL.SWM_SUB_PROJECT_REPORT.DETAILS_OF_TEAM.DOWNLOAD,
        data: { type, lsgiId, districtId },
        file: { name: fileName, ext: type }
    }));
}

export function* fetchTeamComposition({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.TEAM_COMPOSITION_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchTeamCompositionApi, { ...filters, ...filters, pageNo: payload?.pageNo, pageSize: 10 });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_TEAM_COMPOSITION_REPORT_SUCCESS, ACTION_TYPES.FETCH_TEAM_COMPOSITION_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TEAM_COMPOSITION_REPORT);
}

export function* downloadTeamComposition(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, activityStatus } = filters;
    yield put(commonFileDownload({
        url: API_URL.SWM_SUB_PROJECT_REPORT.TEAM_COMPOSITION.DOWNLOAD,
        data: { type, lsgiId, districtId, activityStatus },
        file: { name: fileName, ext: type }
    }));
}

export function* fetchGapAnalysisOfWasteGeneration({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.GAP_ANALYSIS_WASTE_GENERATION]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchGapAnalysisOfWasteGenerationApi, { ...filters, ...filters, pageNo: payload?.pageNo, pageSize: 10 });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_SUCCESS, ACTION_TYPES.FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.GAP_ANALYSIS_WASTE_GENERATION);
}

export function* downloadGapAnalysisOfWasteGeneration(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId } = filters;
    yield put(commonFileDownload({
        url: API_URL.SWM_SUB_PROJECT_REPORT.GAP_ANALYSIS.DOWNLOAD,
        data: { type, lsgiId, districtId },
        file: { name: fileName, ext: type }
    }));
}

export default function* gapAnalysisReportSaga() {
    yield all([
        takeLatest(ACTION_TYPES.LIST_POPULATION_VS_LAND_AREA_REPORT, fetchPopulationVsLandArea),
        takeLatest(ACTION_TYPES.DOWNLOAD_POPULATION_VS_LAND_AREA_REPORT, downloadPopulationVsLandArea),
        takeLatest(ACTION_TYPES.LIST_POPULATION_VS_HOUSE_HOLD_REPORT, fetchPopulationVsHouseHold),
        takeLatest(ACTION_TYPES.DOWNLOAD_POPULATION_VS_HOUSE_HOLD_REPORT, downloadPopulationVsHousehold),
        takeLatest(ACTION_TYPES.LIST_LAND_USE, fetchLandUse),
        takeLatest(ACTION_TYPES.DOWNLOAD_LAND_USE_REPORT, downloadLandUse),
        takeLatest(ACTION_TYPES.LIST_NBDW_EQUIPMENTS_REPORT, fetchNbdwEquipmentsReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_NBDW_EQUIPMENTS_REPORT, downloadNbdwEquipments),
        takeLatest(ACTION_TYPES.LIST_BDW_EQUIPMENTS_REPORT, fetchBdwEquipmentsReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_BDW_EQUIPMENTS_REPORT, downloadBdwEquipments),
        takeLatest(ACTION_TYPES.LIST_NBDW_GENERATED_VS_CAPACITY_REPORT, fetchNbdwGeneratedVsCapacityReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT, downloadNbdwGeneratedvsCapacity),
        takeLatest(ACTION_TYPES.LIST_BDW_GENERATED_VS_CAPACITY_REPORT, fetchBdwGeneratedVsCapacityReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT, downloadBdwGeneratedvsCapacity),
        takeLatest(ACTION_TYPES.LIST_NBDW_FACILITIES_REPORT, fetchNbdwFacilityReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_NBDW_FACILITIES_REPORT, downloadNbdwFacilityReport),
        takeLatest(ACTION_TYPES.LIST_BDW_FACILITIES_REPORT, fetchBdwFacilityReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_BDW_FACILITIES_REPORT, downloadBdwFacilityReport),
        takeLatest(ACTION_TYPES.LIST_CAPITAL_COST_IN_NBDW_REPORT, fetchCapitalCostInNbdw),
        takeLatest(ACTION_TYPES.DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT, downloadCapitalCostInNbdwReport),
        takeLatest(ACTION_TYPES.LIST_CAPITAL_COST_IN_BDW_REPORT, fetchCapitalCostInBdw),
        takeLatest(ACTION_TYPES.DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT, downloadCapitalCostInBdwReport),
        takeLatest(ACTION_TYPES.LIST_FORWARD_LINKING_REPORT, fetchForwardLinkingReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_FORWARD_LINKING_REPORT, downloadForwardLinkingReport),
        takeLatest(ACTION_TYPES.LIST_INCOME_FROM_BDW_AND_NBDW_REPORT, fetchIncomeFromBdwAndNbdwReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT, downloadIncomeFromBdwAndNbdwReport),
        takeLatest(ACTION_TYPES.LIST_EXPENDITURE_REPORT, fetchExpenditureReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_EXPENDITURE_REPORT, downloadExpenditureReport),
        takeLatest(ACTION_TYPES.LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT, fetchIncomeOfHksFromBdwAndNbdwReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT, downloadIncomeOfHksFromBdwAndNbdwReport),
        takeLatest(ACTION_TYPES.FETCH_ANNUAL_PLAN_REPORT, fetchAnnualPlanReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_ANNUAL_PLAN_REPORT, downloadAnnualPlanReport),
        takeLatest(ACTION_TYPES.FETCH_COST_INTERVENTION_LIST, fetchCostInterventionList),
        takeLatest(ACTION_TYPES.DOWNLOAD_COST_INTERVENTION_REPORT, downloadCostInterventionReport),
        takeLatest(ACTION_TYPES.FETCH_STATUS_SUMMARY_LIST, fetchStatusSummaryReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_STATUS_SUMMARY_REPORT, downloadStatusSummaryReport),
        takeLatest(ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_YEAR_WISE, fetchCostInterventionYearWiseList),
        takeLatest(ACTION_TYPES.DOWNLOAD_COST_INTERVENTION_LIST_YEAR_WISE, downloadCostInterventionYearWiseReport),
        takeLatest(ACTION_TYPES.FETCH_DETAILS_OF_TEAM_REPORT, fetchDetailsOfTeam),
        takeLatest(ACTION_TYPES.DOWNLOAD_DETAILS_OF_TEAM_REPORT, downloadDetailsOfTeam),
        takeLatest(ACTION_TYPES.FETCH_TEAM_COMPOSITION_REPORT, fetchTeamComposition),
        takeLatest(ACTION_TYPES.DOWNLOAD_TEAM_COMPOSITION_REPORT, downloadTeamComposition),
        takeLatest(ACTION_TYPES.FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT, fetchGapAnalysisOfWasteGeneration),
        takeLatest(ACTION_TYPES.DOWNLOAD_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT, downloadGapAnalysisOfWasteGeneration)
    ]);
}
