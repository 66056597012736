import { createSlice } from "@reduxjs/toolkit";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import _ from "lodash";
import { getPayloadContent } from "utils/apiUtils";

const WARD_DETAILS = {
  code: "",
  name: "",
  wardNumber: ""
};

const initialState = {
  wardDetails: {
    requestInProgress: false,
    data: {
      ...WARD_DETAILS
    }
  },
  wardCategory: {
    requestInProgress: false,
    data: {
      escalationList: [
        {
          escalationTargetLevel: "",
          escalationUserId: "",
          escalationUnit: "",
          escalationThreshold: ""
        }
      ]
    }
  },
  LSGIDropdown: {
    requestInProgress: false,
    data: {}
  },
  wardNameList: {
    requestInProgress: false,
    data: []
  },
  wardNumberList: {
    requestInProgress: false,
    data: []
  },
  wardCodeList: {
    requestInProgress: false,
    data: []
  },
  lsgiTypeDropdownOption: {
    requestInProgress: false,
    data: []
  },
  lsgiDropdowns: {
    requestInProgress: false,
    data: []
  },
  wardSelectDropdownOption: {
    requestInProgress: false,
    data: []
  },
  targetLevelDetails: { targetLevel: {}, levelIndex: -1, officerIndex: 0 },
  searchFields: {
    requestInProgress: false,
    data: {
      escalationTargetLevel: "",
      escalationUserId: "",
      escalationUnit: "",
      escalationThreshold: "",
      complaintTypeId: "",
      stateId: { id: 1, name: "Kerala" },
      districtId: "",
      lsgiType: "",
      lsgiId: "",
      wardId: "",
      roleName: ""
    }
  },
  officersList: {
    requestInProgress: false,
    data: [],
    rowSelection: {},
    rowSelectionState: {},
    additionalFilters: {}
  },
  escalationUnitDropDown: {
    requestInProgress: false,
    data: []
  },
  targetRoleLevelDropDown: {
    requestInProgress: false,
    data: []
  },
  nameList: {
    requestInProgress: false,
    data: []
  }
};

const slice = createSlice({
  name: STATE_REDUCER_KEY,
  initialState: initialState,
  reducers: {
    clearAll: () => initialState,
    clear: (state) => {
      state.wardCodeList = initialState.wardCodeList;
      state.wardNumberList = initialState.wardNumberList;
      state.wardNameList = initialState.wardNameList;
      state.nameList = initialState.nameList;
    },
    clearWard: (state) => {
      state.wardCategory.data.escalationList = initialState.wardCategory.data.escalationList;
    },
    setLsgiList: (state, data) => {
      let { data: lsgiList = [], id: lsgiTypeId, districtId } = data.payload;
      state.lsgiDropdowns = {
        requestInProgress: false,
        [districtId]: {
          [lsgiTypeId]: lsgiList
        }
      };

      state.wardDropdowns = {
        requestInProgress: false
      };

    },
    clearFilterTable: (state) => {
      state.officersList = initialState.officersList;
    },
    setLevel: (state, { payload: { index } } = {}) => {
      _.set(state, "targetLevelDetails.levelIndex", index);
    },
    setArrayValue: (state, { payload: { value, index, fieldKey } = {} }) => {
      _.set(state, `wardCategory.data.escalationList[${index}].${fieldKey}`, value);
      if (fieldKey === "escalationTargetLevel") {
        _.set(state, `wardCategory.data.escalationList[${index}].escalationUserId`, "");
        _.set(state, `wardCategory.data.escalationList[${index}].escalationThreshold`, "");
        _.set(state, `wardCategory.data.escalationList[${index}].escalationUnit`, "");
      }
    },
    setFullLevel: (state, { payload: { index } } = {}) => {
      _.set(state, "targetLevelDetails.officerIndex", index);
    },
    setRowSelection: (state, { payload: { escalationUserId } = {} }) => {
      let offIdx = _.get(state, "targetLevelDetails.officerIndex", 0);
      _.set(state, "officersList.rowSelection", escalationUserId);
      _.set(state, `wardCategory.data.escalationList[${offIdx}].escalationUserId`, escalationUserId.userId);
      _.set(state, `wardCategory.data.escalationList[${offIdx}].escalationLevel`, offIdx + 1);
      _.set(state, `wardCategory.data.escalationList[${offIdx}].escalationThreshold`, "");
      _.set(state, `wardCategory.data.escalationList[${offIdx}].escalationUnit`, "");
    },
    removeEscalationLevel: (state, { payload: { result } }) => {
      _.set(state, "wardCategory.data.escalationList", result);
    },
    setWardList: (state, data) => {
      let { data: wardList = [], id: lsgiId, districtId } = data.payload;
      state.wardSelectDropdownOption = {
        requestInProgress: false,
        [districtId]: {
          [lsgiId]: wardList
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(ACTION_TYPES.FETCH_WARD_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
      _.set(state, "wardDetails.requestInProgress", false);
      _.set(state, "wardDetails.data", payload.content[0]);
    })
      .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, (state, { payload }) => {
        _.set(state, "LSGIDropdown.requestInProgress", false);
        _.set(state, "LSGIDropdown.data", payload.content);
      }).addCase(ACTION_TYPES.FETCH_WARD_NAME_LIST_SUCCESS, (state, { payload }) => {
        _.set(state, "wardNameList.requestInProgress", false);
        _.set(state, "wardNameList.data", payload.content);
      }).addCase(ACTION_TYPES.FETCH_WARD_NUMBER_LIST_SUCCESS, (state, { payload }) => {
        _.set(state, "wardNumberList.requestInProgress", false);
        _.set(state, "wardNumberList.data", payload.content);
      }).addCase(ACTION_TYPES.FETCH_WARD_CODE_LIST_SUCCESS, (state, { payload }) => {
        _.set(state, "wardCodeList.requestInProgress", false);
        _.set(state, "wardCodeList.data", payload.content);
      }).addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_REQUEST, (state) => {
        state.lsgiTypeDropdownOption.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_SUCCESS, (state, action) => {
        state.lsgiTypeDropdownOption = {
          requestInProgress: false,
          data: getPayloadContent(action)
        };
      })
      .addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_FAILURE, (state) => {
        state.lsgiTypeDropdownOption.requestInProgress = false;
        state.error = "Something went wrong";
      }).addCase(ACTION_TYPES.SELECT_WARD_DROPDOWN_REQUEST, (state) => {
        state.wardSelectDropdownOption.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.SELECT_WARD_DROPDOWN_SUCCESS, (state, action) => {
        state.wardSelectDropdownOption = {
          requestInProgress: false,
          data: getPayloadContent(action)
        };
      })
      .addCase(ACTION_TYPES.SELECT_WARD_DROPDOWN_FAILURE, (state) => {
        state.wardSelectDropdownOption.requestInProgress = false;
      })
      .addCase(ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID_REQUEST, (state) => {
        state.wardCategory.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID_SUCCESS, (state, action) => {
        _.set(state, "wardCategory.requestInProgress", false);
        _.set(state, "wardCategory.data.escalationList", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID_FAILURE, (state) => {
        state.wardCategory.requestInProgress = false;
      })
      .addCase(ACTION_TYPES.FETCH_ESCALATION_UNIT_DROPDOWN_REQUEST, (state) => {
        state.escalationUnitDropDown.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.FETCH_ESCALATION_UNIT_DROPDOWN_SUCCESS, (state, action) => {
        state.escalationUnitDropDown.requestInProgress = false;
        state.escalationUnitDropDown.data = getPayloadContent(action);
      })
      .addCase(ACTION_TYPES.FETCH_TARGET_LEVEL_DROPDOWN_REQUEST, (state) => {
        state.targetRoleLevelDropDown.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.FETCH_TARGET_LEVEL_DROPDOWN_SUCCESS, (state, action) => {
        state.targetRoleLevelDropDown.requestInProgress = false;
        state.targetRoleLevelDropDown.data = getPayloadContent(action);
      })
      .addCase(ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, (state, { payload }) => {
        _.set(state, "nameList.requestInProgress", false);
        _.set(state, "nameList.data", payload.content);
      });
  }
});

export const { reducer, actions } = slice;
