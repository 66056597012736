import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    ADD_VISITORS_FORM: `${STATE_REDUCER_KEY}/ADD_VISITORS_FORM`,
    ADD_VISITORS_FORM_REQUEST: `${STATE_REDUCER_KEY}/ADD_VISITORS_FORM_REQUEST`,
    ADD_VISITORS_FORM_SUCCESS: `${STATE_REDUCER_KEY}/ADD_VISITORS_FORM_SUCCESS`,
    ADD_VISITORS_FORM_FAILURE: `${STATE_REDUCER_KEY}/ADD_VISITORS_FORM_FAILURE`,

    LIST_COUNTRY: `${STATE_REDUCER_KEY}/LIST_COUNTRY`,
    LIST_COUNTRY_REQUEST: `${STATE_REDUCER_KEY}/LIST_COUNTRY_REQUEST`,
    LIST_COUNTRY_SUCCESS: `${STATE_REDUCER_KEY}/LIST_COUNTRY_SUCCESS`,
    LIST_COUNTRY_FAILURE: `${STATE_REDUCER_KEY}/LIST_COUNTRY_FAILURE`,

    PRODUCT_INTEREST: `${STATE_REDUCER_KEY}/PRODUCT_INTEREST`,
    PRODUCT_INTEREST_REQUEST: `${STATE_REDUCER_KEY}/PRODUCT_INTEREST_REQUEST`,
    PRODUCT_INTEREST_SUCCESS: `${STATE_REDUCER_KEY}/PRODUCT_INTEREST_SUCCESS`,
    PRODUCT_INTEREST_FAILURE: `${STATE_REDUCER_KEY}/PRODUCT_INTEREST_FAILURE`

};

export const addVisitorsForm = createAction(ACTION_TYPES.ADD_VISITORS_FORM);
export const listCountry = createAction(ACTION_TYPES.LIST_COUNTRY);
export const listProductInterest = createAction(ACTION_TYPES.PRODUCT_INTEREST);
