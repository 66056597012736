
import { handleAPIRequest } from "../../../utils/http";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { TABLE_IDS } from "common/constants";
import { actions as commonActions } from "../../common/slice";
import { I18n } from "common/components";
import { successNotify } from "utils/notificationUtils";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "../../paths";
import { TRAINING_PATH as PATH } from "../paths";
import { ACTION_TYPES } from "./actions";
import { deleteCourseByIdAPI, submitQuestionDetailsAPI, submitCourseDetailsAPI, editCourseDetailsAPI, updateCourseByIdAPI } from "./api";
import * as api from "./api";
import _ from "lodash";
import { getTableProps } from "modules/common/selectors";

export function* submitCourseDetails({ payload }) {
    let { id } = payload;
    const newFiles = payload.attachmentPayload?.multimediaList.filter(item => !(item.id));
    if (newFiles && newFiles.length > 0) {
        let attachments = {};
        attachments.multimediaList = newFiles;
        payload.attachmentPayload = attachments;
    } else {
        if (newFiles && newFiles.length === 0) {
            payload = _.omit(payload, ["attachmentPayload"]);
            payload = _.omit(payload, ["attachmentIds"]);
        }
    }

    if (id) {
        yield fork(handleAPIRequest, editCourseDetailsAPI, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_COURSE_DETAILS_SUCCESS, ACTION_TYPES.EDIT_COURSE_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_COURSE_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("training_course") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.COURSE.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, submitCourseDetailsAPI, payload);
        const responseAction = yield take([ACTION_TYPES.SAVE_COURSE_DETAILS_SUCCESS, ACTION_TYPES.SAVE_COURSE_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SAVE_COURSE_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("training_course") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.COURSE.LIST}`));
        }

    }
}

export function* listCourseDetails({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.TRAINING_COURSE]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listCourseDetailsAPI, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_COURSE_DETAILS_SUCCESS, ACTION_TYPES.LIST_COURSE_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_COURSE);
}

export function* deleteCourseById({ payload: id }) {
    yield fork(handleAPIRequest, deleteCourseByIdAPI, { id });
    const responseAction = yield take([ACTION_TYPES.DELETE_COURSE_DETAILS_SUCCESS, ACTION_TYPES.DELETE_COURSE_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_COURSE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("training_course") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listCourseDetails, { payload: tablePayload });
    }
}
export function* updateCourseById({ payload }) {
    yield call(handleAPIRequest, updateCourseByIdAPI, payload);
}

export function* submitQuestionDetails({ payload }) {
    yield fork(handleAPIRequest, submitQuestionDetailsAPI, payload);
    const responseAction = yield take([ACTION_TYPES.SAVE_QUESTION_DETAILS_SUCCESS, ACTION_TYPES.SAVE_QUESTION_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_QUESTION_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("saved") }));
        yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.COURSE.LIST}`));
    }

}
export function* fetchSessionId({ payload: courseId }) {
    yield call(handleAPIRequest, api.fetchSessionIdApi, { courseId });
}

export function* fetchCourseId({ payload: id }) {
    yield call(handleAPIRequest, api.fetchCourseIdApi, { id });
}

export function* fetchFilesById({ payload }) {
    yield call(handleAPIRequest, api.getFileById, payload);
}
export function* deleteFile({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, api.deleteFileById, id, fileId);
}
export function* fetchcourseDetails() {
    yield call(handleAPIRequest, api.fetchcourseDetails);
}

export default function* courseSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SAVE_COURSE_DETAILS, submitCourseDetails),
        takeLatest(ACTION_TYPES.EDIT_COURSE_BY_ID, updateCourseById),
        takeLatest(ACTION_TYPES.LIST_COURSE_DETAILS, listCourseDetails),
        takeLatest(ACTION_TYPES.DELETE_COURSE_DETAILS, deleteCourseById),
        takeLatest(ACTION_TYPES.SAVE_QUESTION_DETAILS, submitQuestionDetails),
        takeLatest(ACTION_TYPES.FETCH_SESSION_ID, fetchSessionId),
        takeLatest(ACTION_TYPES.FETCH_COURSE_ID, fetchCourseId),
        takeLatest(ACTION_TYPES.FETCH_FILE, fetchFilesById),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFile),
        takeLatest(ACTION_TYPES.FETCH_COURSE_LIST, fetchcourseDetails)
    ]);
}
