export const API_URL = {
    DPR_TRACKER: {
        WORK_TYPE: {
            LIST: "spm/api/auth/spm/work-type/list/all"
        },
        DOCUMENT_TYPE: {
            LIST: "spm/api/auth/spm/document-type/list/all"
        },
        ESMF_CATEGORY: {
            LIST: "spm/api/auth/spm/esmf-category/list/all"
        },
        DPR_CATEGORY: {
            LIST: "spm/api/auth/spm/dpr-category/list/all"
        },
        SAVE_DPR_TRACKER: "spm/api/auth/spm/dpr-tracker-list/save",
        PATCH_DPR_TRACKER: "spm/api/auth/spm/dpr-tracker-list/save",
        DPR_TRACKER_BY_ID: "spm/api/auth/spm/dpr-tracker-list/by-id",
        FINAL_SUB_PROJECT_BY_ID: "spm/api/auth/spm/sub-project/preparation-list/list/by-id",
        DELETE_DPR_TRACKER: "spm/api/auth/spm/dpr-tracker-list/delete/:id",
        DPR_TRACKER_LIST_LL: "spm/api/auth/spm/dpr-tracker-list/all",
        SAVE_SUB_PROJECT_MAPPING: "spm/api/auth/spm/dpr-tracker-list/project/final/add-project",
        SUB_PROJECT_LIST_ALL: "spm/api/auth/spm/sub-project/preparation-list/list/all",
        FINAL_SUB_PROJECT_LIST_ALL: "spm/api/auth/spm/dpr-tracker-list/final/project-list/all",
        FINAL_SUB_PROJECT_PATCH: "spm/api/auth/spm/sub-project/preparation-list/save",
        SAVE_COST_DETAILS: "spm/api/auth/spm/dpr-tracker-list/cost-details/save",
        COST_DETAILS_BY_ID: "spm/api/auth/spm/dpr-tracker-list/cost-details/list/all",
        UPDATE_COST_DETAILS: "spm/api/auth/spm/dpr-tracker-list/cost-details/update",
        COST_DETAILS_LIST_ALL: "spm/api/auth/spm/dpr-tracker-list/cost-details/list/all",
        SAVE_COMMENT: "spm/api/auth/spm/dpr-tracker-list/comment-details/save",
        LIST_COMMENT: "spm/api/auth/spm/dpr-tracker-list/comment-details/list/all",
        DELETE_COMMENT: "spm/api/auth/spm/dpr-tracker-list/comment-details/delete/:id",
        COMMENT_BY_ID: "spm/api/auth/spm/dpr-tracker-list/comment-details/by-id",
        UPDATE_COMMENT: "spm/api/auth/spm/dpr-tracker-list/comment-details/update",
        SUBMIT_TO_DISTRICT_LEVEL: "spm/api/auth/spm/dpr-tracker-list/district-level/submit",
        LIST_APPROVAL_STATUS: "spm/api/auth/spm/dpr-approval-status/list/all",
        SUBMIT_DPMC_DECISION: "spm/api/auth/spm/dpr-tracker-list/approval-details/save",
        LIST_DPMC_DECISION: "spm/api/auth/spm/dpr-tracker-list/approval-details/list/all",
        SAVE_RESPONSE: "spm/api/auth/spm/dpr-tracker-list/comment-details/update",
        SUBMIT_TO_STATE_LEVEL: "spm/api/auth/spm/dpr-tracker-list/state-level/submit",
        SUBMIT_TO_PUI: "spm/api/auth/spm/dpr-tracker-list/piu/submit",
        SUBMIT_TO_DYDC: "spm/api/auth/spm/dpr-tracker-list/dydc/submit",
        RETURN_TO_TSC: "spm/api/auth/spm/dpr-tracker-list/tsc/return",
        SAVE_DPR_MC_APPROVAL: "spm/api/auth/spm/dpr-tracker-list/save",
        LIST_DPR_TRACKER_STATUS: "spm/api/auth/spm/dpr-status/list/all",
        CATEGORY_LIST_IN_COMMENT: "spm/api/auth/spm/dpr-category/list/all",
        GENERATE_FS: "spm/api/auth/spm/dpr-tracker-list/generate-fs-data/save",
        DOWNLOAD_GENERATE_FS: "spm/api/auth/spm/dpr-tracker-list/generate-fs",
        LIST_ALL_GENERATE_FS: "spm/api/auth/spm/dpr-tracker-list/generate-fs-data/all",
        DOWNLOAD: "report/api/auth/swm-plan/reports/dpr-fr/list/export"
    }
};

