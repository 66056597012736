import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";

const initialState = {
    projectActivity: {
        requestInProgress: false,
        data: {
            milestoneId: "",
            milestoneName: "",
            targetDate: ""
        }
    },
    uomDropdown: {
        requestInProgress: false,
        data: []
    },
    screening: {
        requestInProgress: false,
        data: {
            dateOfScreening: "",
            remarks: "",
            attachmentIds: [],
            projectId: "",
            defaultRemarks: ""
        }
    },
    adpProcess: {
        requestInProgress: false,
        data: {
            projectId: "",
            mcInitialApprovalDate: "",
            mcRemarks: "",
            mcAttachmentIds: [],
            mcInitialApproval: false,
            hscConsentDate: "",
            hscRemarks: "",
            hscAttachmentIds: [],
            hscConsentReceived: false,
            dpcApproved: false,
            dpcApprovalDate: "",
            dpcApprovedAmount: "",
            dpcRemarks: "",
            dpcAttachmentIds: []
        }
    },
    groupDiscussion: {
        requestInProgress: false,
        data: {
            dateOfCompletion: "",
            remarks: "",
            attachmentIds: [],
            completed: false
        }
    },
    groupDiscussionCommon: {
        requestInProgress: false,
        data: {
            annualPlanId: "",
            dateOfCompletion: "",
            remarks: "",
            attachmentIds: [],
            completed: false,
            lsgiId: ""
        }
    },
    vikasanaSeminar: {
        requestInProgress: false,
        data: {
            dateOfCompletion: "",
            seminarAgenda: "",
            minutesOfMeeting: "",
            attachmentIds: "",
            photoAttachmentIds: "",
            completed: false
        }
    },
    safeGuardReportList: {
        data: []
    },
    safeGuardReport: {
        requestInProgress: false,
        data: {
            remarks: "",
            attachmentIds: []
        }
    },
    remarkDropdown: {
        requestInProgress: false,
        data: []
    },
    environmentalSafeguard: {
        requestInProgress: false,
        data: {
            envCategoryId: "",
            dateOfScreening1: "",
            dateOfScreening2: "",
            dateOfReview: "",
            dateOfSiteRecommaissance: "",
            esmfAgencyName: "",
            esmfAgencyMobileNo: "",
            esmfAgencyEmail: "",
            esmfAgencyOtherInfo: "",
            attachmentIds: []
        }
    },
    annualPlanYearList: {
        requestInProgress: false,
        data: []
    },
    annualPlanYearId: {
        data: []
    },
    vikasanaSeminarCommonDetails: {
        requestInProgress: false,
        data: {
            dateOfCompletion: "",
            seminarAgenda: "",
            minutesOfMeeting: "",
            attachmentId: null,
            photoAttachmentIds: null,
            completed: false,
            lsgiId: ""
        }
    },
    vikasanaSeminarCommonList: {
        requestInProgress: false,
        data: []
    },
    workingGroupDiscussionCommonList: {
        requestInProgress: false,
        data: []
    },
    adpCommon: {
        requestInProgress: false,
        data: {
            annualPlanId: "",
            projectId: "",
            mcInitialApprovalDate: "",
            mcRemarks: "",
            mcAttachmentIds: [],
            mcInitialApproval: false,
            hscConsentDate: "",
            hscRemarks: "",
            hscAttachmentIds: [],
            hscConsentReceived: false,
            dpcApproved: false,
            dpcApprovalDate: "",
            dpcApprovedAmount: "",
            dpcRemarks: "",
            dpcAttachmentIds: [],
            lsgiId: ""
        }
    },
    adpCommonList: {
        requestInProgress: false,
        data: []
    },
    swmScreeningList: {
        requestInProgress: false,
        data: []
    },
    projectLevelDataList: {
        requestInProgress: false,
        data: {
            subProjectSwmSocialSafeguardProjectlevelDataDto: [null]
        }
    },
    projectSocialSafeguardDropdown: {
        requestInProgress: false,
        data: []
    },
    safeguardComplianceList: {
        requestInProgress: false,
        data: {
            subProjectSwmSocialSafeguardComplianceDto: []
        }
    },
    communityConsultationList: {
        requestInProgress: false,
        data: {
            subProjectSwmSocialSafeguardCommunityConsultationDto: []
        }
    },
    typeOfActivityDropdown: {
        requestInProgress: false,
        data: []
    },
    phaseOneScreening: {
        requestInProgress: false,
        data: []
    },
    phaseTwoScreening: {
        requestInProgress: false,
        data: []
    },
    esgScreening: {
        requestInProgress: false,
        data: []
    },
    environmentalCategory: {
        requestInProgress: false,
        data: []
    },
    esgInstrument: {
        requestInProgress: false,
        data: []
    },
    dueDiligence: {
        requestInProgress: false,
        data: []
    },
    dueDiligenceType: {
        requestInProgress: false,
        data: []
    },
    reasonUnderEia: {
        requestInProgress: false,
        data: []
    },
    typeOfDueDiligence: {
        requestInProgress: false,
        data: []
    },
    esgDocumentPreparation: {
        requestInProgress: false,
        data: []
    },
    finalEsgPreparedAndSubmitted: {
        requestInProgress: false,
        data: []
    },
    finalEsgApprovalResponsibility: {
        requestInProgress: false,
        data: []
    },
    responsibilityForEsgSupervision: {
        requestInProgress: false,
        data: []
    },
    projectDataById: {
        requestInProgress: false,
        data: {
            projectId: "",
            typeOfActivity: [],
            typeOfActivityId: "",
            dateOfEsgScreening: "",
            dateOfUpdatedEsgScreening: "",
            esgScreeningResponsibility: [],
            reasonEiaNotification: [],
            esgDocPrepResponsibility: [],
            esgDocPrepAgencyName: "",
            esgDocPrepAgencyContNum: "",
            esgDocPrepAgencyEmail: "",
            dateOfEsgInstrumentPrepInitiation: "",
            dateOfFirstConsultation: "",
            dateOfSecondConsultation: "",
            dateOfDisclosureOfDraftEsgDoc: "",
            disclosedDraftFinalEsgDoc: [],
            finalEsgApprovalDate: "",
            dateOfDisclosureOfFinalEsgDoc: "",
            disclosedFinalEsgDoc: [],
            dateOfEsgAndEshsInBidDoc: "",
            dateOfReviewOfEsmpAndEshsInBidDoc: "",
            dateOfFloating: "",
            dateOfImpleInitiation: "",
            impleEndsIn: null,
            responsibilityForEsgSupervision: [],
            dateOfFirstQuarterlyReportSubmitted: "",
            firstEsgQuarterlyReportDoc: [],
            dateOfLatestQuarterlyReportSubmitted: "",
            latestEsgQuarterlyReportDoc: [],
            noOfQuarterlyReportSubmittedTillDate: ""
        }
    },
    projectDataList: {
        requestInProgress: false,
        data: {
            projectId: ""
        }
    },
    typesOfWasteManaged: {
        requestInProgress: false,
        data: []
    },
    typeOfManagement: {
        requestInProgress: false,
        data: []
    },
    arrangementRecycle: {
        requestInProgress: false,
        data: []
    },
    categoryOfEddIssue: {
        requestInProgress: false,
        data: []
    },
    sourceOfEddIdentification: {
        requestInProgress: false,
        data: []
    },
    statusOfEddIssueResolution: {
        requestInProgress: false,
        data: []
    },
    esgIssueAffect: {
        requestInProgress: false,
        data: []
    },
    permitConsentRequired: {
        requestInProgress: false,
        data: []
    },
    incidentClassification: {
        requestInProgress: false,
        data: []
    },
    statusOfCompensation: {
        requestInProgress: false,
        data: []
    },
    incidentImplementation: {
        requestInProgress: false,
        data: []
    },
    attendeeProfile: {
        requestInProgress: false,
        data: []
    },
    responsibleAgency: {
        requestInProgress: false,
        data: []
    },
    statusPermit: {
        requestInProgress: false,
        data: []
    },
    compendiumDataById: {
        requestInProgress: false,
        data: {
            arrangementOfRecycling: [],
            typesOfWasteManaged: [],
            typesOfManagement: [],
            dueDiligence: [],
            facilityFunded: [],
            associatedFacility: "",
            progressOfWork: "",
            permitsConsents: [],
            incidents: [],
            training: []
        }
    },
    compendiumDataList: {
        requestInProgress: false,
        data: []
    },
    financialYearDropDown: {
        requestInProgress: false,
        data: []
    },
    sulekhaProjectListDropDown: {
        requestInProgress: false,
        data: []
    },
    sulekhaProjectListAll: {
        requestInProgress: false,
        data: []
    },
    sulekhaProjectById: {
        requestInProgress: false,
        data: {
            projectNo: "",
            projectSlNo: "",
            projectNameEng: "",
            projectNameMal: "",
            projectType: "",
            lbCode: "",
            lbName: "",
            lbType: "",
            category: "",
            sectorType: "",
            sector: "",
            subSector: "",
            implemtationOfficerCode: "",
            implemtationOfficerDesig: "",
            implemtationOfficerMode: "",
            spmProjectId: "",
            yearId: "",
            microSectorDetails: [],
            resourceDetails: [],
            projectActivity: [],
            projectAim: []
        }
    },
    saveSulekhaForDropDown: {
        requestInProgress: false
    },
    mappingSulekha: {
        requestInProgress: false
    },
    projectCodeDropdown: {
        requestInProgress: false,
        data: []
    },
    facilitiesAndLocation: {
        requestInProgress: false,
        data: {
            facilityName: "",
            assignedPhysicalWards: "",
            latitude: "",
            longitude: ""
        }
    },
    approvalStatus: {
        requestInProgress: false,
        data: []
    },
    socialRiskCategorization: {
        requestInProgress: false,
        data: []
    },
    socialRiskCategorizationData: {
        requestInProgress: false,
        data: []
    }
};
const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clear: () => initialState,
        clearValue: (state) => {
            state.projectActivity = initialState.projectActivity;
            state.uomDropdown = initialState.uomDropdown;
        },
        clearAnnualPlanYear: (state) => {
            state.annualPlanYearId.data = initialState.annualPlanYearId.data;
        },
        setAnnualPlanYear: (state, payload) => {
            state.annualPlanYearId.data = payload?.payload;
        },
        clearVikasanaSeminarCommonList: (state) => {
            state.vikasanaSeminarCommonList = initialState.vikasanaSeminarCommonList;
        },
        clearVikasanaSeminar: (state) => {
            state.vikasanaSeminarCommonDetails = initialState.vikasanaSeminarCommonDetails;
        },
        clearWorkingGroupDiscussionCommonList: (state) => {
            state.workingGroupDiscussionCommonList = initialState.workingGroupDiscussionCommonList;
        },
        clearWorkingGroupDiscussionCommonDetails: (state) => {
            state.groupDiscussionCommon = initialState.groupDiscussionCommon;
        },
        clearApproval: (state) => {
            state.adpCommon = initialState.adpCommon;
        },
        clearProjectLevelDataList: (state) => {
            state.projectLevelDataList = initialState.projectLevelDataList;
        },
        clearSafeguardComplianceList: (state) => {
            state.safeguardComplianceList = initialState.safeguardComplianceList;
        },
        clearCommunityConsultationList: (state) => {
            state.communityConsultationList = initialState.communityConsultationList.data;
        },
        clearSulekhaProjectListDropDown: (state) => {
            state.communityConsultationList = initialState.communityConsultationList.data;
        },
        clearSulekhaById: (state) => {
            state.sulekhaProjectById = initialState.sulekhaProjectById;
        },
        clearProjectCode: (state) => {
            state.projectCodeDropdown = initialState.projectCodeDropdown;
        },
        clearSocialRisk: (state) => {
            state.socialRiskCategorizationData = initialState.socialRiskCategorizationData;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_PROJECT_ACTIVITY_BY_ID_SUCCESS, (state, { payload }) => {
            _.set(state, "projectActivity.requestInProgress", false);
            _.set(state, "projectActivity.data", payload);
        });
        builder.addCase(ACTION_TYPES.UOM_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "uomDropdown.requestInProgress", false);
            _.set(state, "uomDropdown.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.REMARK_DROPDOWN_SUCCESS, (state, { payload }) => {
            _.set(state, "remarkDropdown.requestInProgress", false);
            _.set(state, "remarkDropdown.data", payload.content);
        });
        builder
            .addCase(ACTION_TYPES.FETCH_SWM_SCREENING_DATA_SUCCESS, (state, { payload }) => {
                _.set(state, "screening.requestInProgress", false);
                _.set(state, "screening.data", { ...state.screening.data, ...payload });
            });
        builder
            .addCase(ACTION_TYPES.FETCH_SWM_ADP_PROCESS_SUCCESS, (state, { payload }) => {
                _.set(state, "adpProcess.requestInProgress", false);
                _.set(state, "adpProcess.data", { ...state.adpProcess.data, ...payload });
            });
        builder
            .addCase(ACTION_TYPES.FETCH_SWM_SUB_PROJECT_GROUP_DISCUSSION_SUCCESS, (state, { payload }) => {
                _.set(state, "groupDiscussion.requestInProgress", false);
                _.set(state, "groupDiscussion.data", { ...state.groupDiscussion.data, ...payload });
            });
        builder
            .addCase(ACTION_TYPES.FETCH_SWM_SUB_PROJECT_VIKASANA_SEMINAR_SUCCESS, (state, { payload }) => {
                _.set(state, "vikasanaSeminar.requestInProgress", false);
                _.set(state, "vikasanaSeminar.data", { ...state.vikasanaSeminar.data, ...payload });
            });
        builder
            .addCase(ACTION_TYPES.FETCH_SAFE_GUARD_SUCCESS, (state, { payload }) => {
                _.set(state, "safeGuardReport.requestInProgress", false);
                _.set(state, "safeGuardReport.data", { ...state.safeGuardReport.data, ...payload });
            });
        builder
            .addCase(ACTION_TYPES.LIST_SOCIAL_SAFEGUARD_SUCCESS, (state, { payload }) => {
                _.set(state, "safeGuardReportList.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "environmentalSafeguard.requestInProgress", false);
                _.set(state, "environmentalSafeguard.data", payload);
            });
        builder
            .addCase(ACTION_TYPES.LIST_ANNUAL_PLAN_YEAR_SUCCESS, (state, { payload }) => {
                _.set(state, "annualPlanYearList.data", payload);
            });
        builder
            .addCase(ACTION_TYPES.LIST_ALL_VIKASANA_SEMINAR_COMMON_SUCCESS, (state, { payload }) => {
                _.set(state, "vikasanaSeminarCommonList.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.VIKASANA_SEMINAR_COMMON_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "vikasanaSeminarCommonDetails.data", payload);
                _.set(state, "vikasanaSeminarCommonDetails.data.attachmentId", payload?.attachmentIds ? payload?.attachmentIds : null);
            });
        builder
            .addCase(ACTION_TYPES.LIST_ALL_WORKING_GROUP_DISCUSSION_COMMON_SUCCESS, (state, { payload }) => {
                _.set(state, "workingGroupDiscussionCommonList.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.WORKING_GROUP_DISCUSSION_COMMON_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "groupDiscussionCommon.data", payload);
            });
        builder
            .addCase(ACTION_TYPES.LIST_ALL_HSC_APPROVAL_COMMON_SUCCESS, (state, { payload }) => {
                _.set(state, "adpCommon.data", payload?.content.length > 0 ? payload?.content[0] : state?.adpCommon?.data);
            });
        builder
            .addCase(ACTION_TYPES.LIST_ALL_MC_APPROVAL_COMMON_SUCCESS, (state, { payload }) => {
                _.set(state, "adpCommon.data", payload?.content.length > 0 ? payload?.content[0] : state?.adpCommon?.data);
            });
        builder
            .addCase(ACTION_TYPES.LIST_ALL_DPC_APPROVAL_COMMON_SUCCESS, (state, { payload }) => {
                _.set(state, "adpCommon.data", payload?.content.length > 0 ? payload?.content[0] : state?.adpCommon?.data);
            });
        builder
            .addCase(ACTION_TYPES.LIST_ALL_SWM_SCREENING_SUCCESS, (state, { payload }) => {
                _.set(state, "swmScreeningList.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_PROJECT_LEVEL_DATA_REQUEST, (state) => {
                state.projectLevelDataList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_PROJECT_LEVEL_DATA_SUCCESS, (state, { payload }) => {
                _.set(state, "projectLevelDataList.requestInProgress", false);
                _.set(state, "projectLevelDataList.data.subProjectSwmSocialSafeguardProjectlevelDataDto", payload);
            })
            .addCase(ACTION_TYPES.GET_PROJECT_LEVEL_DATA_FAILURE, (state) => {
                state.projectLevelDataList.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_PROJECT_SOCIAL_SAFEGUARD_STATUS_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "projectSocialSafeguardDropdown.requestInProgress", false);
                _.set(state, "projectSocialSafeguardDropdown.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_SAFEGUARD_COMPLIANCE_REQUEST, (state) => {
                state.safeguardComplianceList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_SAFEGUARD_COMPLIANCE_SUCCESS, (state, { payload }) => {
                _.set(state, "safeguardComplianceList.requestInProgress", false);
                _.set(state, "safeguardComplianceList.data.subProjectSwmSocialSafeguardComplianceDto", payload);
            })
            .addCase(ACTION_TYPES.GET_SAFEGUARD_COMPLIANCE_FAILURE, (state) => {
                state.safeguardComplianceList.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_COMMUNITY_CONSULTATION_REQUEST, (state) => {
                state.communityConsultationList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_COMMUNITY_CONSULTATION_SUCCESS, (state, { payload }) => {
                _.set(state, "communityConsultationList.requestInProgress", false);
                _.set(state, "communityConsultationList.data.subProjectSwmSocialSafeguardCommunityConsultationDto", payload);
            })
            .addCase(ACTION_TYPES.GET_COMMUNITY_CONSULTATION_FAILURE, (state) => {
                state.communityConsultationList.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_TYPE_OF_ACTIVITY_DROP_DOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "typeOfActivityDropdown.requestInProgress", false);
                _.set(state, "typeOfActivityDropdown.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_PHASE_ONE_SCREENING_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "phaseOneScreening.requestInProgress", false);
                _.set(state, "phaseOneScreening.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_PHASE_TWO_SCREENING_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "phaseTwoScreening.requestInProgress", false);
                _.set(state, "phaseTwoScreening.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_ESG_SCREENING_RESPONSIBILITY_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "esgScreening.requestInProgress", false);
                _.set(state, "esgScreening.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_ESG_ENVIRONMENTAL_CATEGORY_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "environmentalCategory.requestInProgress", false);
                _.set(state, "environmentalCategory.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_ESG_INSTRUMENT_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "esgInstrument.requestInProgress", false);
                _.set(state, "esgInstrument.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_DUE_DILIGENCE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "dueDiligence.requestInProgress", false);
                _.set(state, "dueDiligence.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_DUE_DILIGENCE_TYPE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "dueDiligenceType.requestInProgress", false);
                _.set(state, "dueDiligenceType.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_REASON_UNDER_EIA_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "reasonUnderEia.requestInProgress", false);
                _.set(state, "reasonUnderEia.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_TYPE_DUE_DILIGENCE_REQUIRED_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "typeOfDueDiligence.requestInProgress", false);
                _.set(state, "typeOfDueDiligence.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_ESG_DOCUMENT_PREPARATION_RESPONSIBILITY_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "esgDocumentPreparation.requestInProgress", false);
                _.set(state, "esgDocumentPreparation.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_FINAL_ESG_PREPARED_AND_SUBMITTED_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "finalEsgPreparedAndSubmitted.requestInProgress", false);
                _.set(state, "finalEsgPreparedAndSubmitted.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_FINAL_ESG_APPROVAL_RESPONSIBILITY_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "finalEsgApprovalResponsibility.requestInProgress", false);
                _.set(state, "finalEsgApprovalResponsibility.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_RESPONSIBILITY_FOR_ESG_SUPERVISION_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "responsibilityForEsgSupervision.requestInProgress", false);
                _.set(state, "responsibilityForEsgSupervision.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_REQUEST, (state) => {
                state.projectDataById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "projectDataById.requestInProgress", false);
                _.set(state, "projectDataById.data", payload);
            })
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_BY_ID_FAILURE, (state) => {
                state.projectDataById.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_LIST_REQUEST, (state) => {
                state.projectDataList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "projectDataList.requestInProgress", false);
                _.set(state, "projectDataList.data", payload.content[0]);
            })
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_PROJECT_DATA_LIST_FAILURE, (state) => {
                state.projectDataList.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_TYPES_OF_WASTE_MANAGED_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "typesOfWasteManaged.requestInProgress", false);
                _.set(state, "typesOfWasteManaged.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_TYPE_OF_MANAGEMENT_WASTE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "typeOfManagement.requestInProgress", false);
                _.set(state, "typeOfManagement.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_ARRANGEMENT_OF_RECYCLING_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "arrangementRecycle.requestInProgress", false);
                _.set(state, "arrangementRecycle.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_CATEGORY_OF_EDD_ISSUE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "categoryOfEddIssue.requestInProgress", false);
                _.set(state, "categoryOfEddIssue.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_SOURCE_OF_EDD_ISSUE_IDENTIFICATION_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "sourceOfEddIdentification.requestInProgress", false);
                _.set(state, "sourceOfEddIdentification.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_STATUS_OF_EDD_ISSUE_RESOLUTION_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "statusOfEddIssueResolution.requestInProgress", false);
                _.set(state, "statusOfEddIssueResolution.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_ESG_ISSUE_AFFECT_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "esgIssueAffect.requestInProgress", false);
                _.set(state, "esgIssueAffect.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_PERMITS_CONSENT_REQUIRED_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "permitConsentRequired.requestInProgress", false);
                _.set(state, "permitConsentRequired.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_INCIDENT_CLASSIFICATION_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "incidentClassification.requestInProgress", false);
                _.set(state, "incidentClassification.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_STATUS_OF_COMPENSATION_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "statusOfCompensation.requestInProgress", false);
                _.set(state, "statusOfCompensation.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_INCIDENT_IMPLEMENTATION_ACTION_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "incidentImplementation.requestInProgress", false);
                _.set(state, "incidentImplementation.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_ATTENDEE_PROFILE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "attendeeProfile.requestInProgress", false);
                _.set(state, "attendeeProfile.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_RESPONSIBLE_AGENCY_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "responsibleAgency.requestInProgress", false);
                _.set(state, "responsibleAgency.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_STATUS_OF_PERMIT_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "statusPermit.requestInProgress", false);
                _.set(state, "statusPermit.data", payload?.content);
            });
        builder
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_REQUEST, (state) => {
                state.compendiumDataById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "compendiumDataById.requestInProgress", false);
                _.set(state, "compendiumDataById.data", payload);
            })
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_BY_ID_FAILURE, (state) => {
                state.compendiumDataById.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_REQUEST, (state) => {
                state.compendiumDataList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_SUCCESS, (state, { payload }) => {
                _.set(state, "compendiumDataList.requestInProgress", false);
                _.set(state, "compendiumDataList.data", payload.content[0]);
            })
            .addCase(ACTION_TYPES.GET_ENVIRONMENTAL_COMPENDIUM_DATA_LIST_ALL_FAILURE, (state) => {
                state.compendiumDataList.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_FINANCIAL_YEAR_DROP_DOWN_REQUEST, (state) => {
                state.financialYearDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_FINANCIAL_YEAR_DROP_DOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "financialYearDropDown.requestInProgress", false);
                _.set(state, "financialYearDropDown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.GET_FINANCIAL_YEAR_DROP_DOWN_FAILURE, (state) => {
                state.financialYearDropDown.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_SULEKHA_PROJECT_DROP_DOWN_REQUEST, (state) => {
                state.sulekhaProjectListDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_SULEKHA_PROJECT_DROP_DOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "sulekhaProjectListDropDown.requestInProgress", false);
                _.set(state, "sulekhaProjectListDropDown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.GET_SULEKHA_PROJECT_DROP_DOWN_FAILURE, (state) => {
                state.sulekhaProjectListDropDown.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.FETCH_SULEKHA_PROJECT_LIST_ALL_REQUEST, (state) => {
                state.sulekhaProjectListAll.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_SULEKHA_PROJECT_LIST_ALL_SUCCESS, (state, { payload }) => {
                _.set(state, "sulekhaProjectListAll.requestInProgress", false);
                _.set(state, "sulekhaProjectListAll.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_SULEKHA_PROJECT_LIST_ALL_FAILURE, (state) => {
                state.sulekhaProjectListAll.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.FETCH_SULEKHA_PROJECT_BY_ID_REQUEST, (state) => {
                state.sulekhaProjectById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_SULEKHA_PROJECT_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "sulekhaProjectById.requestInProgress", false);
                _.set(state, "sulekhaProjectById.data", payload);
                _.set(state, "sulekhaProjectById.data.implemtationOfficerCode", payload?.implemtationOfficerCode + " , " + payload?.implemtationOfficerDesig);
                _.set(state, "sulekhaProjectById.data.microSectorDetails", payload?.microSectorDetails);
                _.set(state, "sulekhaProjectById.data.projectActivity", payload?.projectActivities);
                _.set(state, "sulekhaProjectById.data.projectAim", payload?.projectAimDetails);
                _.set(state, "sulekhaProjectById.data.resourceDetails", payload?.fundDetails);
            })
            .addCase(ACTION_TYPES.FETCH_SULEKHA_PROJECT_BY_ID_FAILURE, (state) => {
                state.sulekhaProjectById.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_SULEKHA_PROJECT_SAVE_REQUEST, (state) => {
                state.saveSulekhaForDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_SULEKHA_PROJECT_SAVE_SUCCESS, (state) => {
                _.set(state, "saveSulekhaForDropDown.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.GET_SULEKHA_PROJECT_SAVE_FAILURE, (state) => {
                state.saveSulekhaForDropDown.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.FETCH_SULEKHA_PROJECT_REQUEST, (state) => {
                state.mappingSulekha.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_SULEKHA_PROJECT_SUCCESS, (state) => {
                _.set(state, "mappingSulekha.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.FETCH_SULEKHA_PROJECT_FAILURE, (state) => {
                state.mappingSulekha.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_PROJECT_CODE_DROPDOWN_REQUEST, (state) => {
                state.projectCodeDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_PROJECT_CODE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "projectCodeDropdown.requestInProgress", false);
                _.set(state, "projectCodeDropdown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.GET_PROJECT_CODE_DROPDOWN_FAILURE, (state) => {
                state.projectCodeDropdown.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_FACILITIES_AND_LOCATION_REQUEST, (state) => {
                state.facilitiesAndLocation.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_FACILITIES_AND_LOCATION_SUCCESS, (state, { payload }) => {
                _.set(state, "facilitiesAndLocation.requestInProgress", false);
                _.set(state, "facilitiesAndLocation.data", payload);
            })
            .addCase(ACTION_TYPES.GET_FACILITIES_AND_LOCATION_FAILURE, (state) => {
                state.facilitiesAndLocation.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_APPROVAL_STATUS_DROPDOWN_REQUEST, (state) => {
                state.approvalStatus.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_APPROVAL_STATUS_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "approvalStatus.requestInProgress", false);
                _.set(state, "approvalStatus.data", payload?.content);
            })
            .addCase(ACTION_TYPES.GET_APPROVAL_STATUS_DROPDOWN_FAILURE, (state) => {
                state.approvalStatus.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.SOCIAL_RISK_CATEGORIZATION_DROPDOWN_REQUEST, (state) => {
                state.socialRiskCategorization.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SOCIAL_RISK_CATEGORIZATION_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "socialRiskCategorization.requestInProgress", false);
                _.set(state, "socialRiskCategorization.data", payload?.content);
            })
            .addCase(ACTION_TYPES.SOCIAL_RISK_CATEGORIZATION_DROPDOWN_FAILURE, (state) => {
                state.socialRiskCategorization.requestInProgress = false;
            });
        builder
            .addCase(ACTION_TYPES.GET_SOCIAL_RISK_CATEGORIZATION_REQUEST, (state) => {
                state.socialRiskCategorizationData.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_SOCIAL_RISK_CATEGORIZATION_SUCCESS, (state, { payload }) => {
                _.set(state, "socialRiskCategorizationData.requestInProgress", false);
                _.set(state, "socialRiskCategorizationData.data", payload?.content[0]);
            })
            .addCase(ACTION_TYPES.GET_SOCIAL_RISK_CATEGORIZATION_FAILURE, (state) => {
                state.socialRiskCategorizationData.requestInProgress = false;
            });
    }
});
export const { actions, reducer } = slice;
