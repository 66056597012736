import { createSlice } from "@reduxjs/toolkit";
import { ACTION_TYPES } from "modules/user-management/register/actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    userDetails: {
        data: {},
        requestInProgress: false
    },
    navigator: ""
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        setNavigator: (state, action) => {
            state.navigator = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_USER_PROFILE_REQUEST, (state) => {
                state.userDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_USER_PROFILE_SUCCESS, (state, action) => {
                state.userDetails.requestInProgress = false;
                state.userDetails.data = action.payload;
            });
    }
});

export const { actions, reducer } = slice;
