import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchStates = () => {
  return {
    url: API_URL.DISTRICT.STATE_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_DISTRICT_STATE_DETAILS_REQUEST, ACTION_TYPES.FETCH_DISTRICT_STATE_DETAILS_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_STATE_DETAILS_FAILURE]
    }
  };
};
export const fetchDistricts = (data) => {
  return {
    url: API_URL.DISTRICT.LIST_DISTRICT,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_DISTRICT_DETAILS_REQUEST, ACTION_TYPES.FETCH_DISTRICT_DETAILS_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_DETAILS_FAILURE],
      data
    }
  };
};
export const addDistrict = (data) => {
  return {
    url: API_URL.DISTRICT.CREATE_DISTRICT,
    method: REQUEST_METHOD.POST,
    payload: {
      types: [ACTION_TYPES.ADD_DISTRICT_DETAILS_REQUEST, ACTION_TYPES.ADD_DISTRICT_DETAILS_SUCCESS, ACTION_TYPES.ADD_DISTRICT_DETAILS_FAILURE],
      data
    }
  };
};
export const editDistrict = (data) => {
  return {
    url: API_URL.DISTRICT.EDIT_DISTRICT,
    method: REQUEST_METHOD.PATCH,
    payload: {
      types: [ACTION_TYPES.EDIT_DISTRICT_DETAILS_REQUEST, ACTION_TYPES.EDIT_DISTRICT_DETAILS_SUCCESS, ACTION_TYPES.EDIT_DISTRICT_DETAILS_FAILURE],
      data
    }
  };
};
export const deleteDistrict = ({ id }) => {
  return {
    url: API_URL.DISTRICT.DELETE_DISTRICT.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
      types: [ACTION_TYPES.DELETE_DISTRICT_DETAILS_REQUEST, ACTION_TYPES.DELETE_DISTRICT_DETAILS_SUCCESS, ACTION_TYPES.DELETE_DISTRICT_DETAILS_FAILURE]
    }
  };
};

export const nameList = (data) => {
  return {
    url: API_URL.DISTRICT.FILTER_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.FETCH_NAME_LIST_REQUEST,
        ACTION_TYPES.FETCH_NAME_LIST_SUCCESS,
        ACTION_TYPES.FETCH_NAME_LIST_FAILURE
      ],
      data
    }
  };
};
export const codeList = (data) => {
  return {
    url: API_URL.DISTRICT.FILTER_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.FETCH_CODE_LIST_REQUEST,
        ACTION_TYPES.FETCH_CODE_LIST_SUCCESS,
        ACTION_TYPES.FETCH_CODE_LIST_FAILURE
      ],
      data
    }
  };
};
