export const STATE_REDUCER_KEY = "common";

export const COMMON_TABLE_PAGINATION = { pageNo: 0, totalRecords: 0, pageSize: 5, totalPages: 1 };

export const COMMON_TABLE_INITIAL_STATE = {
    requestInProgress: false,
    data: [],
    pagination: COMMON_TABLE_PAGINATION,
    filters: {}
};

export const DEFAULT_TABLE_ID = "DEFAULT_TABLE";

export const OTP_PROCESS = "CITIZEN_SIGN_UP";

export const EMAIL = (string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(string);

export const SMS = (number) => !/\D/.test(number);
export const LSGI_TYPE_DROPDOWN =
    [
        { id: 3, name: "Corporation" },
        { id: 4, name: "Municipality" }
    ];

export const APPLICATION_STATUS = {
    DRAFTED_BY_ULB: 1,
    SUBMITTED_TO_DPMU: 2,
    RETURNED_TO_ULB: 3,
    SUBMITTED_TO_IVA: 4,
    IVA_VERIFIED: 5,
    SUBMITTED_TO_SPMU: 6,
    SPMU_APPROVED: 7,
    SPMU_REJECTED: 8,
    APPROVED_BY_DPMU: 9,
    RE_SUBMITTED_TO_DPMU: 10,
    REJECTION_ACCEPTED_BY_ULB: 11
};

export const ACTIVITY_STATUS = {
    COMPOSITION_OF_TEAM: 1,
    ASSESSMENT_AND_GAP_ANALYSIS: 2,
    PRE_PLAN_CONSULTATION: 3,
    DRAFT_PLAN_PREPARATION: 4,
    POST_PLAN_CONSULTATION: 5,
    SWM_PLAN_FINALIZATION: 6
};
