import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const statusDropdownApi = () => {
    return {
        url: API_URL.COMMON.STATUS_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_COMPLAINT_STATUS_DROPDOWN_REQUEST,
                ACTION_TYPES.FETCH_COMPLAINT_STATUS_DROPDOWN_SUCCESS,
                ACTION_TYPES.FETCH_COMPLAINT_STATUS_DROPDOWN_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};
export const fetchComplaintType = () => {
    return {
        url: API_URL.COMMON.COMPLAINT_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_TYPE_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_TYPE_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_TYPE_FAILURE],
            data: { dropDown: true, pageSize: 200 }
        }
    };
};
export const fetchComplaintMode = () => {
    return {
        url: API_URL.COMMON.COMPLAINT_MODE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_MODE_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_MODE_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_MODE_FAILURE],
            data: { dropDown: true }
        }
    };
};
export const fetchComplaintCategory = () => {
    return {
        url: API_URL.COMMON.COMPLAINT_CATEGORY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_FAILURE],
            data: { dropdown: true }
        }
    };
};
export const fetchDistricts = () => {
    return {
        url: API_URL.REGISTER.DISTRICT_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISTRICT_LIST_REQUEST, ACTION_TYPES.FETCH_DISTRICT_LIST_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_LIST_FAILURE],
            data: { dropdown: true }
        }
    };
};

export const fetchWards = (id) => {
    return {
        url: API_URL.COMMON.WARD,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WARD_LIST_REQUEST, ACTION_TYPES.FETCH_WARD_LIST_SUCCESS, ACTION_TYPES.FETCH_WARD_LIST_FAILURE],
            data: { dropDown: true, lsgiId: id }
        }
    };
};
export const fetchLSGIList = ({ id, districtId }) => {
    return {
        url: API_URL.REGISTER.LSGI_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LSGI_REQUEST, ACTION_TYPES.FETCH_LSGI_SUCCESS, ACTION_TYPES.FETCH_LSGI_FAILURE],
            data: { dropdown: true, districtId: districtId, lsgiTypeId: id }
        }
    };
};
export const lsgiTypeDropdownApi = (id) => {
    return {
        url: API_URL.COMMON.ORGANIZATION_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_FAILURE
            ],
            data: { dropDown: true, lsgiType: id }
        }
    };
};
export const downloadReportsApi = (data) => {
    return {
        url: API_URL.COMPLAINT_REPORT.DOWNLOAD_REPORTS,
        method: REQUEST_METHOD.FILE,
        payload: {
            types: [
                ACTION_TYPES.DOWNLOAD_REPORTS_REQUEST,
                ACTION_TYPES.DOWNLOAD_REPORTS_SUCCESS,
                ACTION_TYPES.DOWNLOAD_REPORTS_FAILURE
            ],
            data
        }
    };
};
export const priorityDropdownApi = () => {
    return {
        url: API_URL.COMPLAINT_REPORT.PRIORITY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_PRIORITY_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_PRIORITY_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_PRIORITY_DROPDOWN_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};
export const complaintHandlerDropdownApi = (data) => {
    return {
        url: API_URL.REGISTER.FIRST_NAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_COMPLAINT_HANDLER_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_COMPLAINT_HANDLER_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_COMPLAINT_HANDLER_DROPDOWN_FAILURE
            ],
            data
        }
    };
};
export const complaintNumberDropdownApi = (data) => {
    return {
        url: API_URL.COMPLAINT.FILTER,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_COMPLAINT_NUMBER_DROPDOWN_REQUEST,
                ACTION_TYPES.SELECT_COMPLAINT_NUMBER_DROPDOWN_SUCCESS,
                ACTION_TYPES.SELECT_COMPLAINT_NUMBER_DROPDOWN_FAILURE
            ],
            data
        }
    };
};
export const fetchComplaintListApi = (data) => {
    return {
        url: API_URL.COMPLAINT.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_COMPLAINT_LIST_REQUEST,
                ACTION_TYPES.FETCH_COMPLAINT_LIST_SUCCESS,
                ACTION_TYPES.FETCH_COMPLAINT_LIST_FAILURE
            ],
            data
        }
    };
};
export const fetchGrievanceSummaryApi = (data) => {
    return {
        url: API_URL.COMPLAINT.GRIEVANCE_SUMMARY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_REQUEST, ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_FAILURE],
            data
        }
    };
};
export const fetchGrievanceListingApi = (data) => {
    return {
        url: API_URL.COMPLAINT.GRIEVANCE_LISTING,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GRIEVANCE_REPORT_LIST_REQUEST, ACTION_TYPES.FETCH_GRIEVANCE_REPORT_LIST_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_REPORT_LIST_FAILURE],
            data
        }
    };
};
export const fetchGrievanceSummaryUlbApi = (data) => {
    return {
        url: API_URL.COMPLAINT.GRIEVANCE_SUMMARY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_ULB_REQUEST, ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_ULB_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_ULB_FAILURE],
            data
        }
    };
};
export const fetchGrievanceSummaryWardApi = (data) => {
    return {
        url: API_URL.COMPLAINT.GRIEVANCE_SUMMARY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_WARD_REQUEST, ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_WARD_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_WARD_FAILURE],
            data
        }
    };
};

export const fetchEscalationReportListApi = (data) => {
    return {
        url: API_URL.COMPLAINT.ESCALATION_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ESCALATION_REPORT_REQUEST, ACTION_TYPES.FETCH_ESCALATION_REPORT_SUCCESS, ACTION_TYPES.FETCH_ESCALATION_REPORT_FAILURE],
            data
        }
    };
};

export const fetchPendingReportListApi = (data) => {
    return {
        url: API_URL.COMPLAINT.PENDING_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PENDING_REPORT_REQUEST, ACTION_TYPES.FETCH_PENDING_REPORT_SUCCESS, ACTION_TYPES.FETCH_PENDING_REPORT_FAILURE],
            data
        }
    };
};

export const fetchReopenComplaintListApi = (data) => {
    return {
        url: API_URL.COMPLAINT.REOPEN_COMPLAINT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_REOPENED_COMPLAINTS_REQUEST, ACTION_TYPES.FETCH_REOPENED_COMPLAINTS_SUCCESS, ACTION_TYPES.FETCH_REOPENED_COMPLAINTS_FAILURE],
            data
        }
    };
};

export const fetchTopGrievanceCategoryListApi = (data) => {
    return {
        url: API_URL.COMPLAINT.TOP_GRIEVANCE_CATEGORY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TOP_GRIEVANCE_CATEGORY_REQUEST, ACTION_TYPES.FETCH_TOP_GRIEVANCE_CATEGORY_SUCCESS, ACTION_TYPES.FETCH_TOP_GRIEVANCE_CATEGORY_FAILURE],
            data
        }
    };
};
