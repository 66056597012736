export const API_URL = {
    TRAINING_PLAN: {
        SAVE_PLAN: "tcm/api/auth/tcm/training_plan/save",
        LIST_PLAN: "tcm/api/auth/tcm/training_plan/list/all",
        FETCH_NEED_ID: "tcm/api/auth/tcm/training_need/list/all?dropDown=true&pageSize=200",
        COURSE_PLAN: "tcm/api/auth/tcm/course_master/list/all?dropDown=true&pageSize=200",
        DESIGNATION: "tcm/api/auth/tcm/tna_matrix/list/all?dropDown=true&pageSize=200",
        TARGET_GROUP: "tcm/api/auth/tcm/target_group_category/list/all?dropDown=true&pageSize=200",
        DELETE_PLAN_ID: "tcm/api/auth/tcm/training_plan/delete/:id",
        EDIT_TRAINING_PLAN: "tcm/api/auth/tcm/training_plan/save",
        EDIT_PLAN_BY_ID: "tcm/api/auth/tcm/training_plan/list/by-id",
        GET_UPLOADED_FILE_BY_ID: "resource/api/auth/multimedia/download",
        DELETE_FILE_BY_ID: "tcm/api/auth/tcm/training_plan/delete/:id/user-attachment/:file_id"
    },
    TRAINING_NEED: {
        SAVE_NEED: "tcm/api/auth/tcm/training_need/save",
        LIST_NEED: "tcm/api/auth/tcm/training_need/list/all",
        DELETE_NEED: "tcm/api/auth/tcm/training_need/delete/:id",
        EDIT_NEED: "tcm/api/auth/tcm/training_need/save",
        EDIT_NEED_CATEGORY: "tcm/api/auth/tcm/training_need/list/by-id",
        TARGET_GROUP: "tcm/api/auth/tcm/target_group_category/list/all?dropDown=true",
        TOPIC: "tcm/api/auth/tcm/training_topic/list/all?dropDown=true&pageSize=200",
        KNOWLEDGE_BASE: "tcm/api/auth/tcm/knowledge_base/list/all?dropDown=true",
        DESIGNATION: "admin/api/auth/master/designation/list/all?dropDown=true&pageSize=200",
        GET_UPLOADED_FILE_BY_ID: "resource/api/auth/multimedia/download",
        DELETE_FILE_BY_ID: "tcm/api/auth/tcm/training_need/delete/:id/user-attachment/:file_id"
    },
    TRAINING_VENUE: {
        SAVE_VENUE: "tcm/api/auth/tcm/training_venue/save",
        LIST_VENUE: "tcm/api/auth/tcm/training_venue/list/all",
        DISTRICT_DROPDOWN: "admin/api/auth/master/district/list/all?dropDown=true",
        DELETE_VENUE: "tcm/api/auth/tcm/training_venue/delete/:id",
        EDIT_VENUE: "tcm/api/auth/tcm/training_venue/save",
        EDIT_VENUE_ID: "tcm/api/auth/tcm/training_venue/list/all",
        VENUE_DROPDOWN: "tcm/api/auth/tcm/training_venue/list/all?dropDown=true&pageSize=200"
    },
    TRAINING_COURSE: {
        SAVE_COURSE: "tcm/api/auth/tcm/course_master/save",
        LIST_COURSE: "tcm/api/auth/tcm/course_master/list/all",
        DELETE_COURSE: "tcm/api/auth/tcm/course_master/delete/:id",
        EDIT_COURSE: "tcm/api/auth/tcm/course_master/save",
        VIEW_COURSE: "tcm/api/auth/tcm/course_master/save",
        EDIT_COURSE_ID: "tcm/api/auth/tcm/course_master/list/by-id",
        SAVE_QUESTION: "tcm/api/auth/tcm/question-master/save",
        EDIT_QUESTION: "tcm/api/auth/tcm/question-master/save",
        COURSE_ID: "tcm/api/auth/tcm/course_master/list/all?dropDown=true&pageSize=200",
        SESSION_ID: "tcm/api/auth/tcm/course_session/list/all",
        GET_UPLOADED_FILE_BY_ID: "resource/api/auth/multimedia/download",
        DELETE_FILE_BY_ID: "tcm/api/auth/tcm/course_master/delete/:id/user-attachment/:file_id",
        COURSE_FILTER: "tcm/api/auth/tcm/course_master/list/all?dropDown=true&id="
    },
    TRAINING_BATCH: {
        SAVE_BATCH: "tcm/api/auth/tcm/training_batch/save",
        LIST_BATCH: "tcm/api/auth/tcm/training_batch/list/all",
        DELETE_BATCH: "tcm/api/auth/tcm/training_batch/delete/:id",
        EDIT_BATCH: "tcm/api/auth/tcm/training_batch/save",
        EDIT_BATCH_ID: "tcm/api/auth/tcm/training_batch/list/all",
        TARGET_GROUP: "tcm/api/auth/tcm/course_plan/list/all?dropDown=true",
        VENUE: "tcm/api/auth/tcm/training_venue/list/all?dropDown=true&pageSize=200",
        HOST: "admin/api/auth/master/lsgi-details/list/all?dropDown=true&pageSize=200",
        TRAINING_MODE: "tcm/api/auth/tcm/training_mode/list/all?dropDown=true",
        PLAN_ID: "tcm/api/auth/tcm/training_plan/list/all?dropDown=true&pageSize=200",
        ADD: "admin/api/auth/idm/user/list/all?designationIds=1",
        VIEW: "tcm/api/auth/tcm/employee_batch/list/all",
        ADD_BATCH: "tcm/api/auth/tcm/employee_batch/list/users-batch",
        CONFIRM_PARTICIPANT: "tcm/api/auth/tcm/employee_batch/confirm",
        ADD_PARTICIPANT: "tcm/api/auth/tcm/employee_batch/add",
        CERTIFICATE_DETAILS: "tcm/api/auth/tcm/employee_batch/list/all?confirmation=2",
        CONFIRM_CERTIFICATE_ISSUE: "tcm/api/auth/tcm/employee_batch/issue-certificate",
        ORGANIZED_BY: "admin/api/auth/master/training-organization/list/all?dropDown=true&pageSize=200",
        DELETE_PARTICIPANT: "tcm/api/auth/tcm/employee_batch/delete/:id",
        FILTER_LIST: "admin/api/auth/idm/user/filter-list",
        FILTER_NAME_LIST: "admin/api/auth/idm/user/filter-list",
        FILTER_DESIGNATION: "admin/api/auth/master/designation/list/all?dropDown=true&pageSize=200",
        TRAINING_CORDINATOR: "admin/api/auth/idm/user/role/list/all?dropDown=true&roleId=1012&pageSize=200",
        LIST_DISTRICT: "admin/api/auth/master/district/list/all?dropDown=true",
        LIST_ULB_DROPDOWN: "admin/api/auth/master/lsgi-details/list/all",
        DOWNLOAD: "report/api/auth/reports/training/participantList",
        LIST_CONFIRMATION: "tcm/api/auth/tcm/confirmation_status/list/all",
        FILTER_CONFIRMATION_LIST: "report/api/auth/reports/training/participantList?batchId=3&type=pdf&confirmation=2",
        FETCH_THEMATIC_AREA: "tcm/api/auth/tcm/thematic_area/list/all"
    },
    TRAINING_SCHEDULE: {
        SAVE: "tcm/api/auth/tcm/training_schedule/save",
        LIST: "tcm/api/auth/tcm/training_schedule/list/by-batch",
        DELETE_SCHEDULE: "tcm/api/auth/tcm/training_schedule/delete/:id",
        EDIT_SCHEDULE: "tcm/api/auth/tcm/training_schedule/save",
        COURSE_ID: "tcm/api/auth/tcm/training_batch/list/all",
        EDIT_SCHEDULE_ID: "tcm/api/auth/tcm/training_schedule/list/all",
        SESSION_ID: "tcm/api/auth/tcm/course_session/list/all",
        BATCH_ID: "tcm/api/auth/tcm/training_batch/list/all?dropDown=true&pageSize=200",
        TRAINER_ID: "admin/api/auth/idm/user/role/list/all?dropDown=true&roleId=1013&pageSize=200",
        TRAINING_ORGANIZATION: "admin/api/auth/master/training-organization/list/all?dropDown=true&pageSize=200",
        QUESTIONNAIRE: "tcm/api/auth/tcm/question-master/list/all?dropDown=true&pageSize=200",
        FILTER_LIST: "tcm/api/auth/tcm/training_schedule/list/all",
        EDIT_BATCH_ID: "tcm/api/auth/tcm/training_batch/list/all",
        SESSION_TYPE: "tcm/api/auth/tcm/session-type/list/all?dropDown=true",
        ATTENDANCE_LIST: "tcm/api/auth/tcm/employee_batch/list/users-schedule",
        MARK_ATTENDANCE: "tcm/api/auth/tcm/employee_schedule/save-by-host",
        FILTER: "tcm/api/auth/tcm/training_schedule/list/by-batch",
        ACTIVATE_CHECK_IN: "tcm/api/auth/tcm/schedule_activation/save"

    },

    TRAINING_CERTIFICATE: {
        SAVE: "tcm/api/auth/tcm/certificate_batch/save",
        LIST: "tcm/api/auth/tcm/certificate_batch/list/all",
        DELETE: "tcm/api/auth/tcm/certificate_batch/delete/:id",
        EDIT: "tcm/api/auth/tcm/certificate_batch/save",
        BATCH: "tcm/api/auth/tcm/training_batch/list/all?dropDown=true&pageSize=200",
        VENUE: "tcm/api/auth/tcm/training_venue/list/all?dropDown=true&pageSize=200",
        TEMPLATE: "tcm/api/auth/tcm/certificate_template/list/all?dropDown=true",
        DESIGNATION: "admin/api/auth/master/designation/list/all?dropDown=true&pageSize=200",
        EDIT_CERTIFICATE_BY_ID: "tcm/api/auth/tcm/certificate_batch/list/all",
        UPLOAD_PROFILE_IMAGE: "tcm/api/auth/tcm/user/:userId/biometric",
        LIST_ULB_DROPDOWN: "admin/api/auth/master/lsgi-details/list/all?dropDown=true"
    },
    DASHBOARD: {
        BATCH: "report/api/auth/reports/training-status/card"

    },
    TRAINING_PARTICIPANTS: {
        LIST: "report/api/auth/reports/training-participants/table-view/list",
        DOWNLOAD: "report/api/auth/reports/training-participants/list",
        TRAINING_LIST_FILTER: "report/api/auth/reports/training-participants/filter-list"

    },
    TRAINING_PARTICIPANTS_ANALYSIS: {
        LIST: "report/api/auth/reports/training-participants-analysis/table-view/list",
        DOWNLOAD: "report/api/auth/reports/training-participants-analysis/list"
    },
    TRAINING_SCHEDULE_REPORT: {
        LIST: "report/api/auth/reports/training-schedule/table-view/list",
        DOWNLOAD: "report/api/auth/reports/training-schedule/list",
        TRAINING_LIST_FILTER: "report/api/auth/reports/training-schedule/filter-list"
    },
    TRAINING_CONDUCTED_REPORT: {
        LIST: "report/api/auth/reports/training-conducted/table-view/list",
        DOWNLOAD: "report/api/auth/reports/training-conducted/list",
        TRAINING_LIST_FILTER: "report/api/auth/reports/training-conducted/filter-list"
    },
    TRAINING_SUMMARY_REPORT: {
        LIST: "report/api/auth/reports/training-summary/table-view/list",
        DOWNLOAD: "report/api/auth/reports/training-summary/list",
        TRAINING_LIST_FILTER: "report/api/auth/reports/training-summary/filter-list"
    }
};
