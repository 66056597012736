import { commonFileDownload, navigateTo } from "modules/common/actions";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { actions as commonActions } from "../../common/slice";
import { ADMIN_PATH as PATH } from "../paths";
import { ACTION_TYPES } from "./actions";
import { addRole, checkedRoleTreeItems, createRoleTree, deleteRole, editRole, fetchRoleByID, fetchRoleLevel, fetchRoleResource, fetchRoles, filterRoleName, nameList, updateRoleTree } from "./api";
import { MODULE_PATH } from "../../paths";
import { I18n } from "../../../common/components";
import { successNotify } from "../../../utils/notificationUtils";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { getTableProps } from "modules/common/selectors";
import { API_URL } from "../apiUrls";

export function* fetchRolesData({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchRoles, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_ROLE_DATA_SUCCESS, ACTION_TYPES.FETCH_ROLE_DATA_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* fetchRoleById({ payload: id }) {
    yield call(handleAPIRequest, fetchRoleByID, { id });
}
export function* fetchRoleLevelData() {
    yield call(handleAPIRequest, fetchRoleLevel);
}
export function* submitRoleData({ payload }) {
    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, editRole, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_ROLE_DATA_SUCCESS, ACTION_TYPES.EDIT_ROLE_DATA_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_ROLE_DATA_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("role") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.ROLE.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, addRole, payload);
        const responseAction = yield take([ACTION_TYPES.ADD_ROLE_DATA_SUCCESS, ACTION_TYPES.ADD_ROLE_DATA_FAILURE]);
        if (responseAction.type === ACTION_TYPES.ADD_ROLE_DATA_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("role") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.ROLE.LIST}`));
        }
    }
}
export function* deleteRoleData({ payload: id }) {

    yield fork(handleAPIRequest, deleteRole, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_ROLE_DATA_SUCCESS, ACTION_TYPES.DELETE_ROLE_DATA_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_ROLE_DATA_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("role") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchRolesData, { payload: tablePayload });
    }
}
export function* roleResource() {
    yield call(handleAPIRequest, fetchRoleResource);
}

export function* filterRoleNameDropdown({ payload }) {
    yield call(handleAPIRequest, filterRoleName, payload);
}

export function* fetchNameList({ payload }) {
    yield call(handleAPIRequest, nameList, payload);
}

export function* createRoleTreeData({ payload }) {
    yield fork(handleAPIRequest, createRoleTree, payload);
    const responseAction = yield take([ACTION_TYPES.CREATE_ROLE_TREE_SUCCESS, ACTION_TYPES.CREATE_ROLE_TREE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.CREATE_ROLE_TREE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("role") }) }));
        yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.ROLE.LIST}`));
    }
}
export function* updateRoleTreeData({ payload }) {
    yield fork(handleAPIRequest, updateRoleTree, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_ROLE_TREE_SUCCESS, ACTION_TYPES.UPDATE_ROLE_TREE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_ROLE_TREE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("role") }) }));
        yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.ROLE.LIST}`));
    }
}
export function* fetchCheckedRoleItems(data) {
    yield call(handleAPIRequest, checkedRoleTreeItems, data.payload);
}
export function* getDownloadRoles(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { name } = filters;
    yield put(commonFileDownload({
        url: API_URL.RESOURCE_MANAGEMENT.DOWNLOAD_ROLES,
        data: { type, roleName: name },
        file: { name: fileName, ext: type }
    }));
}
export default function* sampleSaga() {
    yield all([takeLatest(ACTION_TYPES.FETCH_ROLE_DATA, fetchRolesData),
    takeLatest(ACTION_TYPES.FETCH_ROLE_BY_ID, fetchRoleById),
    takeLatest(ACTION_TYPES.SUBMIT_ROLE_DATA, submitRoleData),
    takeLatest(ACTION_TYPES.DELETE_ROLE_DATA, deleteRoleData),
    takeLatest(ACTION_TYPES.FETCH_ROLE_LEVEL, fetchRoleLevelData),
    takeLatest(ACTION_TYPES.FETCH_ROLE_RESOURCES, roleResource),
    takeLatest(ACTION_TYPES.FILTER_ROLE_NAME, filterRoleNameDropdown),
    takeLatest(ACTION_TYPES.FETCH_NAME_LIST, fetchNameList),
    takeLatest(ACTION_TYPES.CREATE_ROLE_TREE, createRoleTreeData),
    takeLatest(ACTION_TYPES.UPDATE_ROLE_TREE, updateRoleTreeData),
    takeLatest(ACTION_TYPES.CHECKED_ITEM_ROLE_TREE, fetchCheckedRoleItems),
    takeLatest(ACTION_TYPES.DOWNLOAD_ROLES_LIST, getDownloadRoles)
    ]);
}
