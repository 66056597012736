import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
  SET_STATE_DROPDOWN: `${STATE_REDUCER_KEY}/SET_STATE_DROPDOWN`,
  SET_DISTRICT_DROPDOWN: `${STATE_REDUCER_KEY}/SET_DISTRICT_DROPDOWN`,
  SET_CATEGORY_DROPDOWN: `${STATE_REDUCER_KEY}/SET_CATEGORY_DROPDOWN`,

  FETCH_ULB_BY_ID: `${STATE_REDUCER_KEY}/FETCH_ULB_BY_ID_DETAILS`,
  FETCH_ULB_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ULB_BY_ID_REQUEST`,
  FETCH_ULB_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ULB_BY_ID_SUCCESS`,
  FETCH_ULB_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ULB_BY_ID_FAILURE`,

  ADD_ULB: `${STATE_REDUCER_KEY}/ADD_ULB_DETAILS`,
  ADD_ULB_REQUEST: `${STATE_REDUCER_KEY}/ADD_ULB_REQUEST`,
  ADD_ULB_SUCCESS: `${STATE_REDUCER_KEY}/ADD_ULB_SUCCESS`,
  ADD_ULB_FAILURE: `${STATE_REDUCER_KEY}/ADD_ULB_FAILURE`,

  SELECT_ULB_DISTRICT: `${STATE_REDUCER_KEY}/SELECT_ULB_DISTRICT_DETAILS`,
  SELECT_ULB_DISTRICT_REQUEST: `${STATE_REDUCER_KEY}/SELECT_ULB_DISTRICT_REQUEST`,
  SELECT_ULB_DISTRICT_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_ULB_DISTRICT_SUCCESS`,
  SELECT_ULB_DISTRICT_FAILURE: `${STATE_REDUCER_KEY}/SELECT_ULB_DISTRICT_FAILURE`,

  SELECT_BLOCK_PANCHAYATH: `${STATE_REDUCER_KEY}/SELECT_BLOCK_PANCHAYATH_DETAILS`,
  SELECT_BLOCK_PANCHAYATH_REQUEST: `${STATE_REDUCER_KEY}/SELECT_BLOCK_PANCHAYATH_REQUEST`,
  SELECT_BLOCK_PANCHAYATH_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_BLOCK_PANCHAYATH_SUCCESS`,
  SELECT_BLOCK_PANCHAYATH_FAILURE: `${STATE_REDUCER_KEY}/SELECT_BLOCK_PANCHAYATH_FAILURE`,

  SELECT_DISTRICT_PANCHAYATH: `${STATE_REDUCER_KEY}/SELECT_DISTRICT_PANCHAYATH_DETAILS`,
  SELECT_DISTRICT_PANCHAYATH_REQUEST: `${STATE_REDUCER_KEY}/SELECT_DISTRICT_PANCHAYATH_REQUEST`,
  SELECT_DISTRICT_PANCHAYATH_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_DISTRICT_PANCHAYATH_SUCCESS`,
  SELECT_DISTRICT_PANCHAYATH_FAILURE: `${STATE_REDUCER_KEY}/SELECT_DISTRICT_PANCHAYATH_FAILURE`,

  SELECT_LSGI_TYPE: `${STATE_REDUCER_KEY}/SELECT_ULB_CATEGORY_DETAILS`,
  SELECT_LSGI_TYPE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_REQUEST`,
  SELECT_LSGI_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_SUCCESS`,
  SELECT_LSGI_TYPE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_LSGI_TYPE_FAILURE`,

  DELETE_STATE_DETAILS: `${STATE_REDUCER_KEY}/DELETE_STATE_DETAILS`,
  DELETE_STATE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_STATE_DETAILS_REQUEST`,
  DELETE_STATE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_STATE_DETAILS_SUCCESS`,
  DELETE_STATE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_STATE_DETAILS_FAILURE`,

  DELETE_ULB_DETAILS: `${STATE_REDUCER_KEY}/DELETE_ULB_DETAILS`,
  DELETE_ULB_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_ULB_DETAILS_REQUEST`,
  DELETE_ULB_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_ULB_DETAILS_SUCCESS`,
  DELETE_ULB_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_ULB_DETAILS_FAILURE`,

  LIST_STATE_DETAILS: `${STATE_REDUCER_KEY}/LIST_STATE_DETAILS`,
  LIST_STATE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/LIST_STATE_DETAILS_REQUEST`,
  LIST_STATE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_STATE_DETAILS_SUCCESS`,
  LIST_STATE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/LIST_STATE_DETAILS_FAILURE`,

  LIST_ULB_DETAILS: `${STATE_REDUCER_KEY}/LIST_ULB_DETAILS`,
  LIST_ULB_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/LIST_ULB_DETAILS_REQUEST`,
  LIST_ULB_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ULB_DETAILS_SUCCESS`,
  LIST_ULB_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/LIST_ULB_DETAILS_FAILURE`,

  PATCH_STATE_DETAILS: `${STATE_REDUCER_KEY}/PATCH_STATE_DETAILS`,
  PATCH_STATE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_STATE_DETAILS_REQUEST`,
  PATCH_STATE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_STATE_DETAILS_SUCCESS`,
  PATCH_STATE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_STATE_DETAILS_FAILURE`,

  PATCH_ULB_DETAILS: `${STATE_REDUCER_KEY}/PATCH_ULB_DETAILS`,
  PATCH_ULB_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_ULB_DETAILS_REQUEST`,
  PATCH_ULB_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_ULB_DETAILS_SUCCESS`,
  PATCH_ULB_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_ULB_DETAILS_FAILURE`,

  FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
  FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
  FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
  FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,

  FETCH_CODE_LIST: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST`,
  FETCH_CODE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_REQUEST`,
  FETCH_CODE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_SUCCESS`,
  FETCH_CODE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_FAILURE`
};

export const getDistrictDropdown = createAction(ACTION_TYPES.SELECT_ULB_DISTRICT);

export const getBlockPanchayath = createAction(ACTION_TYPES.SELECT_BLOCK_PANCHAYATH);

export const getDistrictPanchayath = createAction(ACTION_TYPES.SELECT_DISTRICT_PANCHAYATH);

export const submitUlbDetails = createAction(ACTION_TYPES.ADD_ULB);

export const getUlbById = createAction(ACTION_TYPES.FETCH_ULB_BY_ID);

export const getLsgiTypeDropdownOption = createAction(ACTION_TYPES.SELECT_LSGI_TYPE);

export const deleteUlbDetails = createAction(ACTION_TYPES.DELETE_ULB_DETAILS);

export const listUlbDetails = createAction(ACTION_TYPES.LIST_ULB_DETAILS);

export const patchUlbDetails = createAction(ACTION_TYPES.PATCH_ULB_DETAILS);

export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);

export const getCodeList = createAction(ACTION_TYPES.FETCH_CODE_LIST);
