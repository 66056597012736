const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./actions");
import _ from "lodash";

const initialState = {
    wasteProcessingSite: {
        requestInProgress: false,
        data: []
    },
    wasteProcessingPlantDetails: {
        requestInProgress: false,
        data: {
            district: "",
            lsgi: "",
            processingSite: "",
            wasteReceived: "",
            wasteProcessed: "",
            endProductQty: "",
            costRecovered: "",
            date: ""
        }
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.WASTE_PROCESSING_SITE_DROPDOWN_REQUEST, (state) => {
                state.wasteProcessingSite.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.WASTE_PROCESSING_SITE_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "wasteProcessingSite.requestInProgress", false);
                _.set(state, "wasteProcessingSite.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.WASTE_PROCESSING_SITE_DROPDOWN_FAILURE, (state) => {
                state.wasteProcessingSite.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_REQUEST, (state) => {
                state.wasteProcessingPlantDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "wasteProcessingPlantDetails.requestInProgress", false);
                _.set(state, "wasteProcessingPlantDetails.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_FAILURE, (state) => {
                state.wasteProcessingPlantDetails.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;

