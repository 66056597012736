import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    SET_USER_GENDER: `${STATE_REDUCER_KEY}/SET_USER_GENDER`,
    FETCH_GENDER_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GENDER_REQUEST`,
    FETCH_GENDER_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GENDER_SUCCESS`,
    FETCH_GENDER_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GENDER_FAILURE`,

    SUBMIT_REGISTER_DETAILS: `${STATE_REDUCER_KEY}/SUBMIT_REGISTER_DETAILS`,
    SUBMIT_REGISTER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_REGISTER_DETAILS_REQUEST`,
    SUBMIT_REGISTER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_REGISTER_DETAILS_SUCCESS`,
    SUBMIT_REGISTER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_REGISTER_DETAILS_FAILURE`,

    FETCH_REGISTER_DETAILS: `${STATE_REDUCER_KEY}/FETCH_REGISTER_DETAILS`,
    FETCH_REGISTER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_REGISTER_DETAILS_REQUEST`,
    FETCH_REGISTER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_REGISTER_DETAILS_SUCCESS`,
    FETCH_REGISTER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_REGISTER_DETAILS_FAILURE`,

    DELETE_REGISTER_DETAILS: `${STATE_REDUCER_KEY}/DELETE_REGISTER_DETAILS`,
    DELETE_REGISTER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_REGISTER_DETAILS_REQUEST`,
    DELETE_REGISTER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_REGISTER_DETAILS_SUCCESS`,
    DELETE_REGISTER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_REGISTER_DETAILS_FAILURE`,

    EDIT_REGISTER_DETAILS: `${STATE_REDUCER_KEY}/EDIT_REGISTER_DETAILS`,
    EDIT_REGISTER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/EDIT_REGISTER_DETAILS_REQUEST`,
    EDIT_REGISTER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_REGISTER_DETAILS_SUCCESS`,
    EDIT_REGISTER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/EDIT_REGISTER_DETAILS_FAILURE`,

    FETCH_REGISTER_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_REGISTER_DETAILS_BY_ID`,
    FETCH_REGISTER_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_REGISTER_DETAILS_BY_ID_REQUEST`,
    FETCH_REGISTER_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_REGISTER_DETAILS_BY_ID_SUCCESS`,
    FETCH_REGISTER_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_REGISTER_DETAILS_BY_ID_FAILURE`,

    SET_ORGANIZATION_DROPDOWN: `${STATE_REDUCER_KEY}/SET_ORGANIZATION_DROPDOWN`,
    FETCH_ORGANIZATION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ORGANIZATION_DROPDOWN_REQUEST`,
    FETCH_ORGANIZATION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ORGANIZATION_DROPDOWN_SUCCESS`,
    FETCH_ORGANIZATION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ORGANIZATION_DROPDOWN_FAILURE`,

    SET_DESIGNATION_DROPDOWN: `${STATE_REDUCER_KEY}/SET_DESIGNATION_DROPDOWN`,
    FETCH_DESIGNATION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_DROPDOWN_REQUEST`,
    FETCH_DESIGNATION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_DROPDOWN_SUCCESS`,
    FETCH_DESIGNATION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_DROPDOWN_FAILURE`,

    UPDATE_USER_PROFILE: `${STATE_REDUCER_KEY}/UPDATE_USER_PROFILE`,
    UPDATE_USER_PROFILE_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_USER_PROFILE_REQUEST`,
    UPDATE_USER_PROFILE_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_USER_PROFILE_SUCCESS`,
    UPDATE_USER_PROFILE_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_USER_PROFILE_FAILURE`,

    SET_USER_TYPE_DROPDOWN: `${STATE_REDUCER_KEY}/SET_USER_TYPE_DROPDOWN`,
    FETCH_USER_TYPE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_TYPE_DROPDOWN_REQUEST`,
    FETCH_USER_TYPE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_TYPE_DROPDOWN_SUCCESS`,
    FETCH_USER_TYPE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_TYPE_DROPDOWN_FAILURE`,

    SET_LOGIN_TYPE_DROPDOWN: `${STATE_REDUCER_KEY}/SET_LOGIN_TYPE_DROPDOWN`,
    FETCH_LOGIN_TYPE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOGIN_TYPE_DROPDOWN_REQUEST`,
    FETCH_LOGIN_TYPE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOGIN_TYPE_DROPDOWN_SUCCESS`,
    FETCH_LOGIN_TYPE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOGIN_TYPE_DROPDOWN_FAILURE`,

    SIGN_UP: `${STATE_REDUCER_KEY}/SIGN_UP`,
    SIGN_UP_REQUEST: `${STATE_REDUCER_KEY}/SIGN_UP_REQUEST`,
    SIGN_UP_SUCCESS: `${STATE_REDUCER_KEY}/SIGN_UP_SUCCESS`,
    SIGN_UP_FAILURE: `${STATE_REDUCER_KEY}/SIGN_UP_FAILURE`,

    CHECK_AVAILABILITY: `${STATE_REDUCER_KEY}/CHECK_AVAILABILITY`,
    CHECK_AVAILABILITY_REQUEST: `${STATE_REDUCER_KEY}/CHECK_AVAILABILITY_REQUEST`,
    CHECK_AVAILABILITY_SUCCESS: `${STATE_REDUCER_KEY}/CHECK_AVAILABILITY_SUCCESS`,
    CHECK_AVAILABILITY_FAILURE: `${STATE_REDUCER_KEY}/CHECK_AVAILABILITY_FAILURE`,

    FETCH_SEARCH_DATA: `${STATE_REDUCER_KEY}/FETCH_SEARCH_DATA`,
    FETCH_SEARCH_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SEARCH_DATA_REQUEST`,
    FETCH_SEARCH_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SEARCH_DATA_SUCCESS`,
    FETCH_SEARCH_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SEARCH_DATA_FAILURE`,

    UPLOAD_PROFILE_IMAGE: `${STATE_REDUCER_KEY}/UPLOAD_PROFILE_IMAGE`,
    UPLOAD_PROFILE_IMAGE_REQUEST: `${STATE_REDUCER_KEY}/UPLOAD_PROFILE_IMAGE_REQUEST`,
    UPLOAD_PROFILE_IMAGE_SUCCESS: `${STATE_REDUCER_KEY}/UPLOAD_PROFILE_IMAGE_SUCCESS`,
    UPLOAD_PROFILE_IMAGE_FAILURE: `${STATE_REDUCER_KEY}/UPLOAD_PROFILE_IMAGE_FAILURE`,

    UPLOAD_SIGNATURE_IMAGE: `${STATE_REDUCER_KEY}/UPLOAD_SIGNATURE_IMAGE`,
    UPLOAD_SIGNATURE_IMAGE_REQUEST: `${STATE_REDUCER_KEY}/UPLOAD_SIGNATURE_IMAGE_REQUEST`,
    UPLOAD_SIGNATURE_IMAGE_SUCCESS: `${STATE_REDUCER_KEY}/UPLOAD_SIGNATURE_IMAGE_SUCCESS`,
    UPLOAD_SIGNATURE_IMAGE_FAILURE: `${STATE_REDUCER_KEY}/UPLOAD_SIGNATURE_IMAGE_FAILURE`,

    FETCH_SEARCH_FIRST_NAMES: `${STATE_REDUCER_KEY}/FETCH_SEARCH_FIRST_NAMES`,
    FETCH_SEARCH_FIRST_NAMES_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SEARCH_FIRST_NAMES_REQUEST`,
    FETCH_SEARCH_FIRST_NAMES_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SEARCH_FIRST_NAMES_SUCCESS`,
    FETCH_SEARCH_FIRST_NAMES_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SEARCH_FIRST_NAMES_FAILURE`,

    CHECK_VERIFY: `${STATE_REDUCER_KEY}/CHECK_VERIFY`,
    CHECK_VERIFY_REQUEST: `${STATE_REDUCER_KEY}/CHECK_VERIFY_REQUEST`,
    CHECK_VERIFY_SUCCESS: `${STATE_REDUCER_KEY}/CHECK_VERIFY_SUCCESS`,
    CHECK_VERIFY_FAILURE: `${STATE_REDUCER_KEY}/CHECK_VERIFY_FAILURE`,

    POST_CHANGE_PASSWORD: `${STATE_REDUCER_KEY}/POST_CHANGE_PASSWORD`,
    POST_CHANGE_PASSWORD_REQUEST: `${STATE_REDUCER_KEY}/POST_CHANGE_PASSWORD_REQUEST`,
    POST_CHANGE_PASSWORD_SUCCESS: `${STATE_REDUCER_KEY}/POST_CHANGE_PASSWORD_SUCCESS`,
    POST_CHANGE_PASSWORD_FAILURE: `${STATE_REDUCER_KEY}/POST_CHANGE_PASSWORD_FAILURE`,

    DOWNLOAD_USER_REPORT_LIST: `${STATE_REDUCER_KEY}/DOWNLOAD_USER_REPORT_LIST`,
    DOWNLOAD_USER_REPORT_LIST_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_USER_REPORT_LIST_REQUEST`,
    DOWNLOAD_USER_REPORT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_USER_REPORT_LIST_SUCCESS`,
    DOWNLOAD_USER_REPORT_LIST_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_USER_REPORT_LIST_FAILURE`,

    ROLES_DROPDOWN: `${STATE_REDUCER_KEY}/ROLES_DROPDOWN`,
    ROLES_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/ROLES_DROPDOWN_REQUEST`,
    ROLES_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/ROLES_DROPDOWN_SUCCESS`,
    ROLES_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/ROLES_DROPDOWN_FAILURE`

};

export const setGender = createAction(ACTION_TYPES.SET_USER_GENDER);
export const submitRegisterDetails = createAction(ACTION_TYPES.SUBMIT_REGISTER_DETAILS);
export const fetchRegisterDetails = createAction(ACTION_TYPES.FETCH_REGISTER_DETAILS);
export const fetchRegisterDetailsById = createAction(ACTION_TYPES.FETCH_REGISTER_DETAILS_BY_ID);
export const editRegisterList = createAction(ACTION_TYPES.EDIT_REGISTER_DETAILS);
export const deleteRegisterList = createAction(ACTION_TYPES.DELETE_REGISTER_DETAILS);
export const setOrganization = createAction(ACTION_TYPES.SET_ORGANIZATION_DROPDOWN);
export const setDesignation = createAction(ACTION_TYPES.SET_DESIGNATION_DROPDOWN);
export const updateProfileDetails = createAction(ACTION_TYPES.UPDATE_USER_PROFILE);
export const setUserTypeDropdown = createAction(ACTION_TYPES.SET_USER_TYPE_DROPDOWN);
export const setLoginTypeDropdown = createAction(ACTION_TYPES.SET_LOGIN_TYPE_DROPDOWN);
export const signUp = createAction(ACTION_TYPES.SIGN_UP);
export const fetchAvailability = createAction(ACTION_TYPES.CHECK_AVAILABILITY);
export const listJSONData = createAction(ACTION_TYPES.FETCH_SEARCH_DATA);
export const uploadProfileImage = createAction(ACTION_TYPES.UPLOAD_PROFILE_IMAGE);
export const uploadSignature = createAction(ACTION_TYPES.UPLOAD_SIGNATURE_IMAGE);
export const listJSONFirstNameData = createAction(ACTION_TYPES.FETCH_SEARCH_FIRST_NAMES);
export const fetchVerify = createAction(ACTION_TYPES.CHECK_VERIFY);
export const postChangePassword = createAction(ACTION_TYPES.POST_CHANGE_PASSWORD);
export const downloadUserReport = createAction(ACTION_TYPES.DOWNLOAD_USER_REPORT_LIST);
export const rolesDropdown = createAction(ACTION_TYPES.ROLES_DROPDOWN);
