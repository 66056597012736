import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";


const initialState = {
    dpmcById: {
        requestInProgress: false,
        data: {
            dpmcSocialExpertDecision: null,
            dpmcEnvExpertDecision: null,
            dpmcFinancialExpertDecision: null,
            dpmcTechnicalExpertDecision: null,
            dpmuSocialExpertDecision: null,
            dpmuEnvExpertDecision: null,
            dpmuFinancialExpertDecision: null,
            dpmuTechnicalExpertDecision: null,
            spmcSocialExpertDecision: null,
            spmcEnvExpertDecision: null,
            spmcFinancialExpertDecision: null,
            spmcTechnicalExpertDecision: null,
            spmuSocialExpertDecision: null,
            spmuEnvExpertDecision: null,
            spmuFinancialExpertDecision: null,
            spmuTechnicalExpertDecision: null,
            dpmcSocialExpertRemarks: "",
            dpmcEnvExpertRemarks: "",
            dpmcFinancialExpertRemarks: "",
            dpmcTechnicalExpertRemarks: "",
            dpmuSocialExpertRemarks: "",
            dpmuEnvExpertRemarks: "",
            dpmuFinancialExpertRemarks: "",
            dpmuTechnicalExpertRemarks: "",
            spmcSocialExpertRemarks: "",
            spmcEnvExpertRemarks: "",
            spmcFinancialExpertRemarks: "",
            spmcTechnicalExpertRemarks: "",
            spmuSocialExpertRemarks: "",
            spmuEnvExpertRemarks: "",
            spmuFinancialExpertRemarks: "",
            spmuTechnicalExpertRemarks: ""
        }
    },
    recommendDropdown: {
        requestInProgress: false,
        data: []
    },
    previousSwmPlanRemarks: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.dpmcById = initialState.dpmcById;
        },
        clearPrevious: (state) => {
            state.previousSwmPlanRemarks = initialState.previousSwmPlanRemarks;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_DPMC_BY_ID_REQUEST, (state) => {
                state.dpmcById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_DPMC_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "dpmcById.requestInProgress", false);
                _.set(state, "dpmcById.data", payload?.content?.length > 0 ? payload?.content[0] : state.dpmcById.data);
            })
            .addCase(ACTION_TYPES.FETCH_DPMC_BY_ID_FAILURE, (state) => {
                state.dpmcById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_DPMC_REQUEST, (state) => {
                state.previousSwmPlanRemarks.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_DPMC_SUCCESS, (state, { payload }) => {
                _.set(state, "previousSwmPlanRemarks.requestInProgress", false);
                _.set(state, "previousSwmPlanRemarks.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_DPMC_FAILURE, (state) => {
                state.previousSwmPlanRemarks.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_RECOMMENDATION_DROPDOWN_REQUEST, (state) => {
                state.recommendDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_RECOMMENDATION_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "recommendDropdown.requestInProgress", false);
                _.set(state, "recommendDropdown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_RECOMMENDATION_DROPDOWN_FAILURE, (state) => {
                state.recommendDropdown.requestInProgress = false;
            });
    }
});

export const { actions, reducer } = slice;
