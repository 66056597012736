import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    TRAINING_CONDUCTED_LIST: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_LIST`,
    TRAINING_CONDUCTED_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_LIST_REQUEST`,
    TRAINING_CONDUCTED_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_LIST_SUCCESS`,
    TRAINING_CONDUCTED_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_LIST_FAILURE`,

    TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST`,
    TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST_REQUEST`,
    TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST_SUCCESS`,
    TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST_FAILURE`,

    TRAINING_PARTICIPANTS_LIST: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_LIST`,
    TRAINING_PARTICIPANTS_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_LIST_REQUEST`,
    TRAINING_PARTICIPANTS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_LIST_SUCCESS`,
    TRAINING_PARTICIPANTS_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_LIST_FAILURE`,

    TRAINING_PARTICIPANTS_LIST_DOWNLOAD: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_LIST_DOWNLOAD`,
    TRAINING_PARTICIPANTS_LIST_DOWNLOAD_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_LIST_DOWNLOAD_REQUEST`,
    TRAINING_PARTICIPANTS_LIST_DOWNLOAD_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_LIST_DOWNLOAD_SUCCESS`,
    TRAINING_PARTICIPANTS_LIST_DOWNLOAD_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_LIST_DOWNLOAD_FAILURE`,

    TRAINING_PARTICIPANTS_ANALYSIS_LIST: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_ANALYSIS_LIST`,
    TRAINING_PARTICIPANTS_ANALYSIS_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_ANALYSIS_LIST_REQUEST`,
    TRAINING_PARTICIPANTS_ANALYSIS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_ANALYSIS_LIST_SUCCESS`,
    TRAINING_PARTICIPANTS_ANALYSIS_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_ANALYSIS_LIST_DOWNLOAD_FAILURE`,

    TRAINING_PARTICIPANTS_ANALYSIS_LIST_DOWNLOAD: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_ANALYSIS_LIST_DOWNLOAD`,
    TRAINING_PARTICIPANTS_ANALYSIS_LIST_DOWNLOAD_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_ANALYSIS_LIST_DOWNLOAD_REQUEST`,
    TRAINING_PARTICIPANTS_ANALYSIS_LIST_DOWNLOAD_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_ANALYSIS_LIST_DOWNLOAD_SUCCESS`,
    TRAINING_PARTICIPANTS_ANALYSIS_LIST_DOWNLOAD_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_ANALYSIS_LIST_DOWNLOAD_FAILURE`,

    TRAINING_SCHEDULE_LIST: `${STATE_REDUCER_KEY}/TRAINING_SCHEDULE_LIST`,
    TRAINING_SCHEDULE_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_SCHEDULE_LIST_REQUEST`,
    TRAINING_SCHEDULE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_SCHEDULE_LIST_SUCCESS`,
    TRAINING_SCHEDULE_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_SCHEDULE_LIST_FAILURE`,

    TRAINING_SCHEDULE_LIST_DOWNLOAD: `${STATE_REDUCER_KEY}/TRAINING_SCHEDULE_LIST_DOWNLOAD`,
    TRAINING_SCHEDULE_LIST_DOWNLOAD_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_SCHEDULE_LIST_DOWNLOAD_REQUEST`,
    TRAINING_SCHEDULE_LIST_DOWNLOAD_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_SCHEDULE_LIST_DOWNLOAD_SUCCESS`,
    TRAINING_SCHEDULE_LIST_DOWNLOAD_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_SCHEDULE_LIST_DOWNLOAD_FAILURE`,

    TRAINING_FILTER_TRAINING_NAME_LIST: `${STATE_REDUCER_KEY}/TRAINING_FILTER_TRAINING_NAME_LIST`,
    TRAINING_FILTER_TRAINING_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_FILTER_TRAINING_NAME_LIST_REQUEST`,
    TRAINING_FILTER_TRAINING_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_FILTER_TRAINING_NAME_LIST_SUCCESS`,
    TRAINING_FILTER_TRAINING_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_FILTER_TRAINING_NAME_LIST_FAILURE`,

    TRAINING_FILTER_BATCH_NAME_LIST: `${STATE_REDUCER_KEY}/TRAINING_FILTER_BATCH_NAME_LIST`,
    TRAINING_FILTER_BATCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_FILTER_BATCH_NAME_LIST_REQUEST`,
    TRAINING_FILTER_BATCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_FILTER_BATCH_NAME_LIST_SUCCESS`,
    TRAINING_FILTER_BATCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_FILTER_BATCH_NAME_LIST_FAILURE`,

    TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST`,
    TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST_REQUEST`,
    TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST_SUCCESS`,
    TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_FILTER_TRAINING_NAME_LIST_FAILURE`,

    TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST`,
    TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST_REQUEST`,
    TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST_SUCCESS`,
    TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST_FAILURE`,

    TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST`,
    TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST_REQUEST`,
    TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST_SUCCESS`,
    TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST_FAILURE`,

    TRAINING_CONDUCTED_LIST_DOWNLOAD: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_LIST_DOWNLOAD`,
    TRAINING_CONDUCTED_LIST_DOWNLOAD_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_LIST_DOWNLOAD_REQUEST`,
    TRAINING_CONDUCTED_LIST_DOWNLOAD_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_LIST_DOWNLOAD_SUCCESS`,
    TRAINING_CONDUCTED_LIST_DOWNLOAD_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_CONDUCTED_LIST_DOWNLOAD_FAILURE`,

    TRAINING_SUMMARY_LIST: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_LIST`,
    TRAINING_SUMMARY_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_LIST_REQUEST`,
    TRAINING_SUMMARY_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_LIST_SUCCESS`,
    TRAINING_SUMMARY_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_LIST_FAILURE`,

    TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST`,
    TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST_REQUEST`,
    TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST_SUCCESS`,
    TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST_FAILURE`,

    TRAINING_SUMMARY_REPORT_DOWNLOAD: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_REPORT_DOWNLOAD`,
    TRAINING_SUMMARY_REPORT_DOWNLOAD_REQUEST: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_REPORT_DOWNLOAD_REQUEST`,
    TRAINING_SUMMARY_REPORT_DOWNLOAD_SUCCESS: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_REPORT_DOWNLOAD_SUCCESS`,
    TRAINING_SUMMARY_REPORT_DOWNLOAD_FAILURE: `${STATE_REDUCER_KEY}/TRAINING_SUMMARY_REPORT_DOWNLOAD_FAILURE`
};

export const listTrainingConductedList = createAction(ACTION_TYPES.TRAINING_CONDUCTED_LIST);
export const listTrainingParticipantsList = createAction(ACTION_TYPES.TRAINING_PARTICIPANTS_LIST);
export const listTrainingParticipantsDownload = createAction(ACTION_TYPES.TRAINING_PARTICIPANTS_LIST_DOWNLOAD);
export const listTrainingParticipantsAnalysis = createAction(ACTION_TYPES.TRAINING_PARTICIPANTS_ANALYSIS_LIST);
export const listTrainingParticipantsAnalysisDownload = createAction(ACTION_TYPES.TRAINING_PARTICIPANTS_ANALYSIS_LIST_DOWNLOAD);
export const listTrainingSchedule = createAction(ACTION_TYPES.TRAINING_SCHEDULE_LIST);
export const listTrainingScheduleDownload = createAction(ACTION_TYPES.TRAINING_SCHEDULE_LIST_DOWNLOAD);
export const trainingFilterList = createAction(ACTION_TYPES.TRAINING_FILTER_TRAINING_NAME_LIST);
export const trainingBatchNameFilterList = createAction(ACTION_TYPES.TRAINING_FILTER_BATCH_NAME_LIST);
export const trainingParticipantsFilterList = createAction(ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST);
export const trainingParticipantsBatchNameFilterList = createAction(ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST);
export const trainingConductedBatchNameFilter = createAction(ACTION_TYPES.TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST);
export const trainingConductedTrainingModeFilter = createAction(ACTION_TYPES.TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST);
export const trainingConductedReportDownload = createAction(ACTION_TYPES.TRAINING_CONDUCTED_LIST_DOWNLOAD);
export const trainingSummaryReportList = createAction(ACTION_TYPES.TRAINING_SUMMARY_LIST);
export const trainingSummaryTrainingFilterList = createAction(ACTION_TYPES.TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST);
export const trainingSummaryReportDownload = createAction(ACTION_TYPES.TRAINING_SUMMARY_REPORT_DOWNLOAD);
