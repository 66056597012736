import { useSelector } from "react-redux";
import { activeRoles } from "./activeRoleResourceList";

export const DisableComponent = (id) => {
    const data = useSelector(state => state.profileDetails.userDetails.data.activeRoleResourceList);
    const activeRole = useSelector(state => state.profileDetails.userDetails.data.activeRole.name);
    let resourceIDs = activeRoles(data);
    if ((resourceIDs.includes(id) || activeRole === "SUPER ADMIN")) {
        //disabled
        return false;
    } else {
        //not disabled
        return true;
    }

};
