import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    visitorsForm: {
        requestInProgress: false,
        data: {
            name: "",
            designation: "",
            companyName: "",
            phone: "",
            email: "",
            companyWebsite: "",
            address: "",
            remark: "",
            country: "",
            productInterest: "",
            otherDetails: ""
        }
    },
    success: {
        data: false
    },
    countryList: {
        requestInProgress: false,
        data: []
    },
    productInterest: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        setStatusDetails: (state, action) => {
            state.success.data = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.ADD_VISITORS_FORM_SUCCESS, (state) => {
            _.set(state, "success.data", true);
        });
        builder.addCase(ACTION_TYPES.LIST_COUNTRY_SUCCESS, (state, action) => {
            _.set(state, "countryList.data", action?.payload?.content);
        });
        builder.addCase(ACTION_TYPES.PRODUCT_INTEREST_SUCCESS, (state, action) => {
            _.set(state, "productInterest.data", action?.payload?.content);
        });
    }
});
export const { actions, reducer } = slice;

