import { getTableProps } from "modules/common/selectors";
import { all, call, fork, select, takeLatest, take, put } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { ACTION_TYPES } from "./actions";
import { successNotify } from "utils/notificationUtils";
import { navigateTo } from "modules/common/actions";
import { I18n } from "common/components";
import { MODULE_PATH } from "modules/paths";
import { GRAND_PATH as PATH } from "../paths";
import { approveByDpmuApi, approveBySpmuApi, districtDropdownApi, fetchAnnualTriggerListApi, fetchAnnualTriggerViewApi, fetchUtilizationListApi, LSGIDropdownApi, returnULBApi, searchULBApi, submitProjectDetailsApi, submitToDPMUApi, submitToSpmuApi } from "./api";

export function* fetchAnnualTriggerList({ payload }) {
    yield fork(handleAPIRequest, fetchAnnualTriggerListApi, { ...payload });
}

export function* fetchUtilizationList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchUtilizationListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_UTILIZATION_LIST_SUCCESS, ACTION_TYPES.FETCH_UTILIZATION_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* submitProjectDetails(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, submitProjectDetailsApi, rest);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_PROJECT_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_PROJECT_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_PROJECT_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("saved", { type: I18n("project_details") }) }));
    }
}

export function* fetchLSGIDropDownFn({ payload }) {
    let { districtId, lsgiTypeId } = payload;
    yield call(handleAPIRequest, LSGIDropdownApi, districtId, lsgiTypeId);
}

export function* fetchDistrictDropDownFn() {
    yield call(handleAPIRequest, districtDropdownApi);
}

export function* fetchSearchUlb({ payload }) {
    yield call(handleAPIRequest, searchULBApi, payload);
}

export function* fetchAnnualTriggerView({ payload: id }) {
    yield call(handleAPIRequest, fetchAnnualTriggerViewApi, { id });
}

export function* submitDpmuApplication({ payload }) {
    yield fork(handleAPIRequest, submitToDPMUApi, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_DPMU_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_DPMU_APPLICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_DPMU_APPLICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("submit_success", { type: I18n("application_submit") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.UTILIZATION_APPLICATION.LIST}`));
    }
}

export function* submitSpmuApplication({ payload }) {
    yield fork(handleAPIRequest, submitToSpmuApi, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_SPMU_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_SPMU_APPLICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_SPMU_APPLICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("submit_success", { type: I18n("application_submit") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.UTILIZATION_APPLICATION.LIST}`));
    }
}

export function* approveDpmuApplication({ payload }) {
    yield fork(handleAPIRequest, approveByDpmuApi, payload);
    const responseAction = yield take([ACTION_TYPES.APPROVE_DPMU_APPLICATION_SUCCESS, ACTION_TYPES.APPROVE_DPMU_APPLICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_DPMU_APPLICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approve_success", { type: I18n("application_approved") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.UTILIZATION_APPLICATION.LIST}`));
    }
}

export function* approveSpmuApplication({ payload }) {
    yield fork(handleAPIRequest, approveBySpmuApi, payload);
    const responseAction = yield take([ACTION_TYPES.APPROVE_SPMU_APPLICATION_SUCCESS, ACTION_TYPES.APPROVE_SPMU_APPLICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_SPMU_APPLICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approve_success", { type: I18n("application_approved") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.UTILIZATION_APPLICATION.LIST}`));
    }
}

export function* rejectUlbApplication({ payload }) {
    yield fork(handleAPIRequest, returnULBApi, payload);
    const responseAction = yield take([ACTION_TYPES.REJECT_APPLICATION_SUCCESS, ACTION_TYPES.REJECT_APPLICATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_APPLICATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("reject_success", { type: I18n("application_rejected") }) }));
        yield put(navigateTo(`${MODULE_PATH.GRAND_MANAGEMENT}/${PATH.UTILIZATION_APPLICATION.LIST}`));
    }
}

export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_ANNUAL_TRIGGER_LIST, fetchAnnualTriggerList),
        takeLatest(ACTION_TYPES.FETCH_UTILIZATION_LIST, fetchUtilizationList),
        takeLatest(ACTION_TYPES.FETCH_LSGI_DROPDOWN, fetchLSGIDropDownFn),
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN, fetchDistrictDropDownFn),
        takeLatest(ACTION_TYPES.FETCH_ANNUAL_TRIGGER_DETAILS, fetchAnnualTriggerView),
        takeLatest(ACTION_TYPES.SUBMIT_DPMU_APPLICATION, submitDpmuApplication),
        takeLatest(ACTION_TYPES.SUBMIT_SPMU_APPLICATION, submitSpmuApplication),
        takeLatest(ACTION_TYPES.SUBMIT_PROJECT_DETAILS, submitProjectDetails),
        takeLatest(ACTION_TYPES.FETCH_ULB_SEARCH, fetchSearchUlb),
        takeLatest(ACTION_TYPES.APPROVE_DPMU_APPLICATION, approveDpmuApplication),
        takeLatest(ACTION_TYPES.APPROVE_SPMU_APPLICATION, approveSpmuApplication),
        takeLatest(ACTION_TYPES.REJECT_APPLICATION, rejectUlbApplication)
    ]);
}
