import { REQUEST_METHOD } from "common/constants";
import { ACTION_TYPES } from "./actions";
import { API_URL } from "./apiUrl";

export const fetchDprTrackerList = (data) => {
    return {
        url: API_URL.DPR_TRACKER.DPR_TRACKER_LIST_LL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_DPR_TRACKER_REQUEST, ACTION_TYPES.LIST_DPR_TRACKER_SUCCESS, ACTION_TYPES.LIST_DPR_TRACKER_FAILURE],
            data
        }
    };
};

export const fetchDocumentType = () => {
    return {
        url: API_URL.DPR_TRACKER.DOCUMENT_TYPE.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_DOCUMENT_TYPE_REQUEST, ACTION_TYPES.LIST_DOCUMENT_TYPE_SUCCESS, ACTION_TYPES.LIST_DOCUMENT_TYPE_FAILURE],
            data: { dropdown: true }
        }
    };
};

export const fetchWorkType = () => {
    return {
        url: API_URL.DPR_TRACKER.WORK_TYPE.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_WORK_TYPE_REQUEST, ACTION_TYPES.LIST_WORK_TYPE_SUCCESS, ACTION_TYPES.LIST_WORK_TYPE_FAILURE],
            data: { dropdown: true }
        }
    };
};

export const fetchEsmfCategory = () => {
    return {
        url: API_URL.DPR_TRACKER.ESMF_CATEGORY.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ESMF_CATEGORY_REQUEST, ACTION_TYPES.LIST_ESMF_CATEGORY_SUCCESS, ACTION_TYPES.LIST_ESMF_CATEGORY_FAILURE],
            data: { dropdown: true }
        }
    };
};

export const fetchDprCategory = () => {
    return {
        url: API_URL.DPR_TRACKER.DPR_CATEGORY.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_DPR_CATEGORY_REQUEST, ACTION_TYPES.LIST_DPR_CATEGORY_SUCCESS, ACTION_TYPES.LIST_DPR_CATEGORY_FAILURE],
            data: { dropdown: true, categoryType: 1 }
        }
    };
};

export const postDprTracker = (data) => {
    return {
        url: API_URL.DPR_TRACKER.SAVE_DPR_TRACKER,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SAVE_DPR_TRACKER_REQUEST, ACTION_TYPES.SAVE_DPR_TRACKER_SUCCESS, ACTION_TYPES.SAVE_DPR_TRACKER_FAILURE],
            data
        }
    };
};

export const patchDprTracker = (data) => {
    return {
        url: API_URL.DPR_TRACKER.PATCH_DPR_TRACKER,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_DPR_TRACKER_REQUEST, ACTION_TYPES.PATCH_DPR_TRACKER_SUCCESS, ACTION_TYPES.PATCH_DPR_TRACKER_FAILURE],
            data
        }
    };
};

export const dprTrackerById = (data) => {
    return {
        url: API_URL.DPR_TRACKER.DPR_TRACKER_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.DPR_TRACKER_BY_ID_REQUEST, ACTION_TYPES.DPR_TRACKER_BY_ID_SUCCESS, ACTION_TYPES.DPR_TRACKER_BY_ID_FAILURE],
            data
        }
    };
};

export const finalSubProjectById = (data) => {
    return {
        url: API_URL.DPR_TRACKER.FINAL_SUB_PROJECT_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.BY_ID_FINAL_SUBPROJECT_MAPPING_REQUEST, ACTION_TYPES.BY_ID_FINAL_SUBPROJECT_MAPPING_SUCCESS, ACTION_TYPES.BY_ID_FINAL_SUBPROJECT_MAPPING_FAILURE],
            data
        }
    };
};

export const deleteDprTracker = (data) => {
    return {
        url: API_URL.DPR_TRACKER.DELETE_DPR_TRACKER.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_DPR_TRACKER_REQUEST, ACTION_TYPES.DELETE_DPR_TRACKER_SUCCESS, ACTION_TYPES.DELETE_DPR_TRACKER_FAILURE]
        }
    };
};

export const fetchSubProjectListAll = (data) => {
    return {
        url: API_URL.DPR_TRACKER.SUB_PROJECT_LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_SUBPROJECT_FULL_DATA_REQUEST, ACTION_TYPES.LIST_SUBPROJECT_FULL_DATA_SUCCESS, ACTION_TYPES.LIST_SUBPROJECT_FULL_DATA_FAILURE],
            data
        }
    };
};

export const postSubProjectMapping = (data) => {
    return {
        url: API_URL.DPR_TRACKER.SAVE_SUB_PROJECT_MAPPING,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SUBPROJECT_MAPPING_REQUEST, ACTION_TYPES.POST_SUBPROJECT_MAPPING_SUCCESS, ACTION_TYPES.POST_SUBPROJECT_MAPPING_FAILURE],
            data
        }
    };
};

export const fetchFinalSubProjectListAll = (data) => {
    return {
        url: API_URL.DPR_TRACKER.FINAL_SUB_PROJECT_LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_FINAL_SUBPROJECT_MAPPING_REQUEST, ACTION_TYPES.LIST_FINAL_SUBPROJECT_MAPPING_SUCCESS, ACTION_TYPES.LIST_FINAL_SUBPROJECT_MAPPING_FAILURE],
            data
        }
    };
};

export const patchFinalSubProject = (data) => {
    return {
        url: API_URL.DPR_TRACKER.FINAL_SUB_PROJECT_PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_FINAL_SUBPROJECT_MAPPING_REQUEST, ACTION_TYPES.PATCH_FINAL_SUBPROJECT_MAPPING_SUCCESS, ACTION_TYPES.PATCH_FINAL_SUBPROJECT_MAPPING_FAILURE],
            data
        }
    };
};

export const saveCostDetails = (data) => {
    return {
        url: API_URL.DPR_TRACKER.SAVE_COST_DETAILS,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SAVE_COST_DETAILS_REQUEST, ACTION_TYPES.SAVE_COST_DETAILS_SUCCESS, ACTION_TYPES.SAVE_COST_DETAILS_FAILURE],
            data
        }
    };
};

export const patchCostDetails = (data) => {
    return {
        url: API_URL.DPR_TRACKER.UPDATE_COST_DETAILS,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_COST_DETAILS_REQUEST, ACTION_TYPES.PATCH_COST_DETAILS_SUCCESS, ACTION_TYPES.PATCH_COST_DETAILS_FAILURE],
            data
        }
    };
};

export const costDetailsById = (data) => {
    return {
        url: API_URL.DPR_TRACKER.COST_DETAILS_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.BY_ID_COST_DETAILS_REQUEST, ACTION_TYPES.BY_ID_COST_DETAILS_SUCCESS, ACTION_TYPES.BY_ID_COST_DETAILS_FAILURE],
            data
        }
    };
};

export const fetchCostDetailsListAll = (data) => {
    return {
        url: API_URL.DPR_TRACKER.COST_DETAILS_LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_COST_DETAILS_REQUEST, ACTION_TYPES.LIST_ALL_COST_DETAILS_SUCCESS, ACTION_TYPES.LIST_ALL_COST_DETAILS_FAILURE],
            data
        }
    };
};

export const saveComment = (data) => {
    return {
        url: API_URL.DPR_TRACKER.SAVE_COMMENT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.ADD_COMMENT_REQUEST, ACTION_TYPES.ADD_COMMENT_SUCCESS, ACTION_TYPES.ADD_COMMENT_FAILURE],
            data
        }
    };
};

export const fetchCommentList = (data, id) => {
    return {
        url: `${API_URL.DPR_TRACKER.LIST_COMMENT}?dprTrackerId=${id}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.VIEW_COMMENT_REQUEST, ACTION_TYPES.VIEW_COMMENT_SUCCESS, ACTION_TYPES.VIEW_COMMENT_FAILURE],
            data
        }
    };
};

export const deleteComment = (data) => {
    return {
        url: API_URL.DPR_TRACKER.DELETE_COMMENT.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_COMMENT_REQUEST, ACTION_TYPES.DELETE_COMMENT_SUCCESS, ACTION_TYPES.DELETE_COMMENT_FAILURE]
        }
    };
};

export const fetchCommentById = (data) => {
    return {
        url: `${API_URL.DPR_TRACKER.COMMENT_BY_ID}?id=${data}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.BY_ID_COMMENT_REQUEST, ACTION_TYPES.BY_ID_COMMENT_SUCCESS, ACTION_TYPES.BY_ID_COMMENT_FAILURE],
            data
        }
    };
};

export const updateComment = (data) => {
    return {
        url: API_URL.DPR_TRACKER.UPDATE_COMMENT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_COMMENT_REQUEST, ACTION_TYPES.UPDATE_COMMENT_SUCCESS, ACTION_TYPES.UPDATE_COMMENT_FAILURE],
            data
        }
    };
};

export const patchSubmitToDistrictLevel = (data) => {
    return {
        url: `${API_URL.DPR_TRACKER.SUBMIT_TO_DISTRICT_LEVEL}?dprTrackerId=${data}`,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.SUBMIT_TO_DISTRICT_LEVEL_REQUEST, ACTION_TYPES.SUBMIT_TO_DISTRICT_LEVEL_SUCCESS, ACTION_TYPES.SUBMIT_TO_DISTRICT_LEVEL_FAILURE],
            data
        }
    };
};

export const fetchApprovalStatus = () => {
    return {
        url: API_URL.DPR_TRACKER.LIST_APPROVAL_STATUS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_APPROVAL_STATUS_REQUEST, ACTION_TYPES.LIST_APPROVAL_STATUS_SUCCESS, ACTION_TYPES.LIST_APPROVAL_STATUS_FAILURE],
            data: { dropdown: true }
        }
    };
};

export const submitDpmcDecision = (data) => {
    return {
        url: API_URL.DPR_TRACKER.SUBMIT_DPMC_DECISION,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_DPMC_DESICION_REQUEST, ACTION_TYPES.SUBMIT_DPMC_DESICION_SUCCESS, ACTION_TYPES.SUBMIT_DPMC_DESICION_FAILURE],
            data
        }
    };
};

export const fetchDpmcDecisionListAll = (data) => {
    return {
        url: `${API_URL.DPR_TRACKER.LIST_DPMC_DECISION}?dprTrackerId=${data}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_DPMC_DESICION_REQUEST, ACTION_TYPES.LIST_ALL_DPMC_DESICION_SUCCESS, ACTION_TYPES.LIST_ALL_DPMC_DESICION_FAILURE]
        }
    };
};

export const fetchCommentLists = (data) => {
    return {
        url: API_URL.DPR_TRACKER.LIST_COMMENT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_COMMENT_TABLE_REQUEST, ACTION_TYPES.LIST_ALL_COMMENT_TABLE_SUCCESS, ACTION_TYPES.LIST_ALL_COMMENT_TABLE_FAILURE],
            data
        }
    };
};

export const saveResponseDetails = (data) => {
    return {
        url: API_URL.DPR_TRACKER.SAVE_RESPONSE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.SUBMIT_RESPONSE_DETAILS_REQUEST, ACTION_TYPES.SUBMIT_RESPONSE_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_RESPONSE_DETAILS_FAILURE],
            data
        }
    };
};

export const patchSubmitToStateLevel = (data) => {
    return {
        url: `${API_URL.DPR_TRACKER.SUBMIT_TO_STATE_LEVEL}?dprTrackerId=${data}`,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.SUBMIT_TO_STATE_LEVEL_REQUEST, ACTION_TYPES.SUBMIT_TO_STATE_LEVEL_SUCCESS, ACTION_TYPES.SUBMIT_TO_STATE_LEVEL_FAILURE],
            data
        }
    };
};

export const patchSubmitToPui = (data) => {
    return {
        url: `${API_URL.DPR_TRACKER.SUBMIT_TO_PUI}?dprTrackerId=${data}`,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.SUBMIT_TO_PUI_REQUEST, ACTION_TYPES.SUBMIT_TO_PUI_SUCCESS, ACTION_TYPES.SUBMIT_TO_PUI_FAILURE]
        }
    };
};

export const patchSubmitToDyDc = (data) => {
    return {
        url: `${API_URL.DPR_TRACKER.SUBMIT_TO_DYDC}?dprTrackerId=${data}`,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.SUBMIT_TO_DYDC_REQUEST, ACTION_TYPES.SUBMIT_TO_DYDC_SUCCESS, ACTION_TYPES.SUBMIT_TO_DYDC_FAILURE],
            data
        }
    };
};

export const patchReturnToTsc = (data) => {
    return {
        url: `${API_URL.DPR_TRACKER.RETURN_TO_TSC}?dprTrackerId=${data}`,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.RETURN_TO_TSC_REQUEST, ACTION_TYPES.RETURN_TO_TSC_SUCCESS, ACTION_TYPES.RETURN_TO_TSC_FAILURE],
            data
        }
    };
};

export const saveDprMcApproval = (data) => {
    return {
        url: API_URL.DPR_TRACKER.SAVE_DPR_MC_APPROVAL,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.SUBMIT_MC_APPROVAL_REQUEST, ACTION_TYPES.SUBMIT_MC_APPROVAL_SUCCESS, ACTION_TYPES.SUBMIT_MC_APPROVAL_FAILURE],
            data
        }
    };
};

export const fetchDprTrackerStatus = () => {
    return {
        url: API_URL.DPR_TRACKER.LIST_DPR_TRACKER_STATUS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_DPR_TRACKER_STATUS_REQUEST, ACTION_TYPES.LIST_DPR_TRACKER_STATUS_SUCCESS, ACTION_TYPES.LIST_DPR_TRACKER_STATUS_FAILURE],
            data: { dropdown: true }
        }
    };
};

export const fetchCommentCategoryList = () => {
    return {
        url: API_URL.DPR_TRACKER.CATEGORY_LIST_IN_COMMENT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.ADD_COMMENT_CATEGORY_LIST_REQUEST, ACTION_TYPES.ADD_COMMENT_CATEGORY_LIST_SUCCESS, ACTION_TYPES.ADD_COMMENT_CATEGORY_LIST_FAILURE],
            data: { dropdown: true, categoryType: 2 }
        }
    };
};

export const generateFs = (data) => {
    return {
        url: API_URL.DPR_TRACKER.GENERATE_FS,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.GENERATE_FS_REQUEST, ACTION_TYPES.GENERATE_FS_SUCCESS, ACTION_TYPES.GENERATE_FS_FAILURE],
            data
        }
    };
};


export const fetchGenerateFSById = (data) => {
    return {
        url: `${API_URL.DPR_TRACKER.COMMENT_BY_ID}?id=${data}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.BY_ID_GENERATE_FS_REQUEST, ACTION_TYPES.BY_ID_GENERATE_FS_SUCCESS, ACTION_TYPES.BY_ID_GENERATE_FS_FAILURE],
            data
        }
    };
};

export const listAllGenerateFS = (data) => {
    return {
        url: `${API_URL.DPR_TRACKER.LIST_ALL_GENERATE_FS}?id=${data}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_ALL_GENERATE_FS_REQUEST, ACTION_TYPES.LIST_ALL_GENERATE_FS_SUCCESS, ACTION_TYPES.LIST_ALL_GENERATE_FS_FAILURE],
            data
        }
    };
};
