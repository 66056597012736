import { I18n, Icons } from "common/components";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { REACT_TABLE_COMMON_OPTIONS, ROWS_PER_PAGE, TABLE_IDS, USER_ROLE_NAME } from "common/constants";
import CommonTable from "modules/common/components/CommonTable";
import { actions as sliceActions } from "modules/common/slice";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "utils/dateUtils";
import { getUserDetails } from "utils/userUtils";
import { deleteReceiptDetails, getReceipt } from "../action";
import { confirmDialog } from "utils/notificationUtils";
import Swal from "sweetalert2";

const { AddCircleOutline, EditIcon, DeleteForever, OpenInNewIcon } = Icons;

const ListReceipt = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: { activeRole = {} } = {} } = getUserDetails();

    const handleDelete = (row) => {
        confirmDialog({
            title: I18n("are_sure_want_delete")
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteReceiptDetails(row?.original.id));
            } else if (result.isDenied) {
                Swal.fire(I18n("action_cancelled"), "", "info");
            }
        });
    };
    const columns = useMemo(
        () => [
            {
                header: I18n("district"),
                accessorFn: (row) => row?.districtId?.name || ""
            },
            {
                header: I18n("ulb"),
                accessorFn: (row) => row?.lsgiId?.name || ""
            },
            {
                header: I18n("date"),
                accessorFn: (row) => dateFormat(row?.asDate) || ""
            },
            {
                header: I18n("amount"),
                accessorFn: (row) => row?.amount || ""
            },
            {
                header: I18n("go_number"),
                accessorFn: (row) => row?.govtOrderNum || ""
            }
        ],
        []
    );

    useEffect(() => {
        dispatch(sliceActions.clearTab());
    }, []);

    const actions = (row) => {
        let customActions = [];
        if (activeRole?.name === USER_ROLE_NAME.SPMU_USER_ROLE) {
            customActions.push({ title: I18n("edit"), icon: <EditIcon fontSize="small" />, handleClick: () => navigate(`${row.original.id}/edit`) });
            customActions.push({ title: "Delete", icon: <DeleteForever fontSize="small" />, disabled: activeRole?.name !== USER_ROLE_NAME.SPMU_USER_ROLE, handleClick: () => handleDelete(row) });
        }
        customActions.push({ title: I18n("view"), icon: <OpenInNewIcon fontSize="small" />, handleClick: () => navigate(`${row.original.id}/view`) });
        return customActions;
    };

    const displayColumnDefOptions = {
        "mrt-row-actions": {
            Cell: ({ row }) => <CustomListMenu customActions={actions(row)} />
        }
    };

    const toolBarActions = [];
    const toolbarPermissions = [0, 1];
    if (toolbarPermissions[1]) {
        toolBarActions.push({ title: "Create", icon: <AddCircleOutline fontSize="medium" />, disabled: activeRole?.name !== USER_ROLE_NAME.SPMU_USER_ROLE, handleClick: () => navigate("create") });
    }
    const options = {
        ...REACT_TABLE_COMMON_OPTIONS,
        enableRowSelection: false,
        enableFilters: true,
        toolBarActions: toolBarActions,
        customPagination: {
            rowsPerPageOptions: ROWS_PER_PAGE
        },
        enableRowActions: true,
        displayColumnDefOptions
    };

    return (
        <>
            <CommonTable
                columns={columns}
                options={options}
                enableVirtualization={false}
                title={I18n("receipt")}
                fetchData={getReceipt}
                enableCustomTableFilter={true}
                tableId={TABLE_IDS.RECEIPT_LIST}
            />
        </>
    );
};

export default ListReceipt;

