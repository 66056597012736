import { I18n } from "common/components";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { approveDpmuByIdApi, approveQaQcApi, fetchPreviousDpmuApi, rejectQaQcApi } from "./api";
import { SUB_PROJECTS_PATH as PATH } from "../paths";
import { MODULE_PATH } from "modules/paths";
import { navigateTo } from "modules/common/actions";

const formatData = (rest) => {
    const { swmPlanId } = rest;
    const data = { planId: Number(swmPlanId) };
    return data;
};

export function* approveQaQc(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, approveQaQcApi, formatData(rest));
    const responseAction = yield take([ACTION_TYPES.APPROVE_QA_QC_SUCCESS, ACTION_TYPES.APPROVE_QA_QC_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_QA_QC_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("approved", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.SWM_PLAN.LIST}`));
    }
}

export function* rejectQaQc(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, rejectQaQcApi, formatData(rest));
    const responseAction = yield take([ACTION_TYPES.REJECT_QA_QC_SUCCESS, ACTION_TYPES.REJECT_QA_QC_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_QA_QC_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("rejected", { type: I18n("draft_plan") }) }));
    }
}

export function* approvalDpmuById({ payload }) {
    yield call(handleAPIRequest, approveDpmuByIdApi, payload);
}

export function* getPreviousDpmu({ payload }) {
    yield call(handleAPIRequest, fetchPreviousDpmuApi, payload);
}

export default function* registerSaga() {
    yield all([
        takeLatest(ACTION_TYPES.APPROVE_QA_QC, approveQaQc),
        takeLatest(ACTION_TYPES.REJECT_QA_QC, rejectQaQc),
        takeLatest(ACTION_TYPES.APPROVE_QA_QC_BY_ID, approvalDpmuById),
        takeLatest(ACTION_TYPES.FETCH_PREVIOUS_DPMU, getPreviousDpmu)
    ]);
}
