import { REQUEST_METHOD } from "common/constants";
import { ACTION_TYPES } from "./actions";
import { API_URL } from "./apiURL";

export const stateDropdownApi = () => {
    return {
        url: API_URL.COMMON.STATE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_STATE_DROPDOWN_REQUEST,
                ACTION_TYPES.FETCH_STATE_DROPDOWN_SUCCESS,
                ACTION_TYPES.FETCH_STATE_DROPDOWN_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};

export const districtDropdownApi = () => {
    return {
        url: API_URL.COMMON.DISTRICT_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_REQUEST,
                ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS,
                ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};

export const LSGIDropdownApi = ({ lsgiTypeId, districtId }) => {
    return {
        url: API_URL.COMMON.LSGI_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST,
                ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS,
                ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE
            ],
            data: { dropDown: true, districtId, lsgiTypeId }
        }
    };
};

export const LSGITypeDropdownApi = () => {
    return {
        url: API_URL.COMMON.LSGI_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_LSGI_TYPE_DROPDOWN_REQUEST,
                ACTION_TYPES.FETCH_LSGI_TYPE_DROPDOWN_SUCCESS,
                ACTION_TYPES.FETCH_LSGI_TYPE_DROPDOWN_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};


export const wardDropdownApi = (id) => {
    return {
        url: API_URL.COMMON.WARD_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_WARD_DROPDOWN_REQUEST,
                ACTION_TYPES.FETCH_WARD_DROPDOWN_SUCCESS,
                ACTION_TYPES.FETCH_WARD_DROPDOWN_FAILURE
            ],
            data: { dropDown: true, lsgiId: id, pageSize: 200 }
        }
    };
};

export const getOtpDetailsApi = (
    { targetNo, otpType, otpProcess }) => {
    return {
        url: API_URL.COMMON.GET_OTP.replace(":otp_type", otpType).replace(":otp_process", otpProcess),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_OTP_DETAILS_REQUEST, ACTION_TYPES.GET_OTP_DETAILS_SUCCESS, ACTION_TYPES.GET_OTP_DETAILS_FAILURE],
            data: { targetNo }
        }
    };
};

export const getSignupOtpDetails = (
    { targetNo, otpType, otpProcess }) => {
    return {
        url: API_URL.COMMON.GET_OTP.replace(":otp_type", otpType).replace(":otp_process", otpProcess),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.GET_SIGNUP_OTP_DETAILS_REQUEST,
                ACTION_TYPES.GET_SIGNUP_OTP_DETAILS_SUCCESS,
                ACTION_TYPES.GET_SIGNUP_OTP_DETAILS_FAILURE
            ],
            data: { targetNo }
        }
    };
};

export const postOtpDetailsApi = (data) => {
    return {
        url: API_URL.COMMON.POST_OTP,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [
                ACTION_TYPES.POST_OTP_DETAILS_REQUEST,
                ACTION_TYPES.POST_OTP_DETAILS_SUCCESS,
                ACTION_TYPES.POST_OTP_DETAILS_FAILURE
            ],
            data
        }
    };
};

export const commonDownloadFile = ({ url, data }) => {
    return {
        url,
        method: REQUEST_METHOD.FILE,
        payload: {
            types: [ACTION_TYPES.COMMON_DOWNLOAD_FILE_REQUEST, ACTION_TYPES.COMMON_DOWNLOAD_FILE_SUCCESS, ACTION_TYPES.COMMON_DOWNLOAD_FILE_FAILURE],
            data
        }
    };
};
export const fileUpload = (data) => {
    return {
        url: API_URL.COMMON.FILE_UPLOAD,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.FILE_UPLOAD_REQUEST, ACTION_TYPES.FILE_UPLOAD_SUCCESS, ACTION_TYPES.FILE_UPLOAD_FAILURE],
            data
        }
    };
};
export const fetchMultimediaList = (ids) => {
    return {
        url: API_URL.COMMON.MULTIMEDIA_LIST + ids.join("&ids="),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.MULTIMEDIA_LIST_REQUEST,
                ACTION_TYPES.MULTIMEDIA_LIST_SUCCESS,
                ACTION_TYPES.MULTIMEDIA_LIST_FAILURE
            ]
        }
    };
};
export const getLogoutUser = (data = {}) => {
    return {
        url: API_URL.COMMON.LOGOUT_PROFILE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [
                ACTION_TYPES.LOG_OUT_USER_REQUEST,
                ACTION_TYPES.LOG_OUT_USER_SUCCESS,
                ACTION_TYPES.LOG_OUT_USER_FAILURE
            ],
            data
        }
    };
};
