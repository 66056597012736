import { I18n } from "common/components";
import AddDetailsForm from "./components/AddDetailsForm";
import Brochure from "./components/Brochure";

const routes = [
    {
        title: I18n("visitors_form"),
        children:
            [{
                path: "",
                element: <AddDetailsForm />
            },
            {
                path: "brouchure",
                element: <Brochure />
            }]
    }];
export { routes };


