import { I18n } from "common/components";
import { navigateTo } from "modules/common/actions";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";

import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { deleteResourceManagementApi, fetchModuleIdApi, fetchParentApi, listResourceManagementApi, resourceManagementGetByIdApi, submitResourceManagementApi, updateResourceManagementApi, filterMenuName } from "./api";
import { actions as commonActions } from "../../common/slice"; import { ADMIN_PATH as PATH } from "../paths";
import { MODULE_PATH } from "../../paths";
import { getPayloadContentDetails } from "utils/apiUtils";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { getTableProps } from "modules/common/selectors";

export function* fetchModuleId(action) {
    yield call(handleAPIRequest, fetchModuleIdApi, action.payload);
}

export function* fetchParent({ payload }) {
    yield call(handleAPIRequest, fetchParentApi, { isParent: true, moduleId: payload });
}

export function* submitResourceManagement(action) {
    let { payload: { id, resourceId, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, updateResourceManagementApi, { id, ...rest });
        const responseAction = yield take([ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_FAILURE]);
        if (responseAction.type === ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("menu_management") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.RESOURCE_MANAGEMENT.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, submitResourceManagementApi, { id: resourceId, ...rest });
        const responseAction = yield take([ACTION_TYPES.SUBMIT_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.SUBMIT_RESOURCE_MANAGEMENT_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_RESOURCE_MANAGEMENT_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("menu_management") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.RESOURCE_MANAGEMENT.LIST}`));
        }
    }
}

export function* resourceManagementGetById({ payload }) {
    yield fork(handleAPIRequest, resourceManagementGetByIdApi, payload);
    const responseAction = yield take([ACTION_TYPES.RESOURCE_MANAGEMENT_CONTACTS_BY_ID_SUCCESS, ACTION_TYPES.RESOURCE_MANAGEMENT_CONTACTS_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.RESOURCE_MANAGEMENT_CONTACTS_BY_ID_SUCCESS) {
        let data = getPayloadContentDetails(responseAction);
        let { moduleId: { id } = {} } = data;
        yield call(fetchParent, { payload: id });
    }
}

export function* listResourceManagement({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    let newPayload = {
        name: filters?.name?.join(","), ...payload
    };
    yield fork(handleAPIRequest, listResourceManagementApi, newPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.LIST_RESOURCE_MANAGEMENT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* deleteResourceManagement({ payload }) {
    yield fork(handleAPIRequest, deleteResourceManagementApi, payload);
    const response = yield take([ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("menu_management") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listResourceManagement, { payload: tablePayload });
    }
}
export function* filterMenuNameDropdown({ payload }) {
    yield call(handleAPIRequest, filterMenuName, payload);
}

export default function* testSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_MODULE_ID, fetchModuleId),
        takeLatest(ACTION_TYPES.FETCH_PARENT_ID, fetchParent),
        takeLatest(ACTION_TYPES.SUBMIT_RESOURCE_MANAGEMENT, submitResourceManagement),
        takeLatest(ACTION_TYPES.LIST_RESOURCE_MANAGEMENT, listResourceManagement),
        takeLatest(ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT, deleteResourceManagement),
        takeLatest(ACTION_TYPES.RESOURCE_MANAGEMENT_CONTACTS_BY_ID, resourceManagementGetById),
        takeLatest(ACTION_TYPES.FILTER_MENU_NAME, filterMenuNameDropdown)
    ]);
}
