import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_WASTE_PROCESSING_LIST: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_LIST`,
    FETCH_WASTE_PROCESSING_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_LIST_REQUEST`,
    FETCH_WASTE_PROCESSING_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_LIST_SUCCESS`,
    FETCH_WASTE_PROCESSING_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_LIST_FAILURE`,

    FETCH_WASTE_PROCESSING_BY_ID: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_BY_ID`,
    FETCH_WASTE_PROCESSING_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_BY_ID_REQUEST`,
    FETCH_WASTE_PROCESSING_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_BY_ID_SUCCESS`,
    FETCH_WASTE_PROCESSING_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WASTE_PROCESSING_BY_ID_FAILURE`,

    POST_WASTE_PROCESSING: `${STATE_REDUCER_KEY}/POST_WASTE_PROCESSING`,
    POST_WASTE_PROCESSING_REQUEST: `${STATE_REDUCER_KEY}/POST_WASTE_PROCESSING_REQUEST`,
    POST_WASTE_PROCESSING_SUCCESS: `${STATE_REDUCER_KEY}/POST_WASTE_PROCESSING_SUCCESS`,
    POST_WASTE_PROCESSING_FAILURE: `${STATE_REDUCER_KEY}/POST_WASTE_PROCESSING_FAILURE`,

    PATCH_WASTE_PROCESSING: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSING`,
    PATCH_WASTE_PROCESSING_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSING_REQUEST`,
    PATCH_WASTE_PROCESSING_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSING_SUCCESS`,
    PATCH_WASTE_PROCESSING_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WASTE_PROCESSING_FAILURE`,

    DELETE_WASTE_PROCESSING: `${STATE_REDUCER_KEY}/DELETE_WASTE_PROCESSING`,
    DELETE_WASTE_PROCESSING_REQUEST: `${STATE_REDUCER_KEY}/DELETE_WASTE_PROCESSING_REQUEST`,
    DELETE_WASTE_PROCESSING_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_WASTE_PROCESSING_SUCCESS`,
    DELETE_WASTE_PROCESSING_FAILURE: `${STATE_REDUCER_KEY}/DELETE_WASTE_PROCESSING_FAILURE`,

    FETCH_SITE_NAME_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_SITE_NAME_DROPDOWN`,
    FETCH_SITE_NAME_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SITE_NAME_DROPDOWN_REQUEST`,
    FETCH_SITE_NAME_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SITE_NAME_DROPDOWN_SUCCESS`,
    FETCH_SITE_NAME_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SITE_NAME_DROPDOWN_FAILURE`
};

export const fetchWasteProcessingList = createAction(ACTION_TYPES.FETCH_WASTE_PROCESSING_LIST);
export const fetchWasteProcessingById = createAction(ACTION_TYPES.FETCH_WASTE_PROCESSING_BY_ID);
export const postWasteProcessing = createAction(ACTION_TYPES.POST_WASTE_PROCESSING);
export const patchWasteProcessing = createAction(ACTION_TYPES.PATCH_WASTE_PROCESSING);
export const deleteWasteProcessing = createAction(ACTION_TYPES.DELETE_WASTE_PROCESSING);
export const fetchSiteNameDropdown = createAction(ACTION_TYPES.FETCH_SITE_NAME_DROPDOWN);
