/* eslint-disable valid-jsdoc */

import { parseInt } from "lodash";

export const getBase64 = file => {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
        let baseURL = "";
        let reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

export const sortByKeys = data => {
    let sorted = Object.keys(data).sort()
        .reduce(function (acc, key) {
            acc[key] = data[key];
            return acc;
        }, {});

    Object.keys(sorted).forEach(key => {
        if (!sorted[key] || sorted[key].length === 0) {
            delete sorted[key];
        }
    });

    return sorted;
};


export const downloadFileAsync = async ({ response, file: { name = "download", ext = "pdf" } = {} }) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name}.${ext}`);
    document.body.appendChild(link);
    link.click();
};

/**
*  Convert Numbers to INR abbreviation
 * @param  {String} value Amount
 * @param  {Number} points Decimal points length
 * @returns {Boolean}
 */
export const inrFormatter = (value, points) => {
    const newVal = parseInt(value);
    if (newVal < 100000) {
        return `₹${newVal.toFixed(points)}`;
    } else {
        const converted = newVal.toLocaleString("en-IN", {
            style: "currency", currency: "INR", notation: "compact", minimumFractionDigits: points,
            roundingIncrement: 5
        });
        return converted;
    }
};

export const numericInputKey = (event) => {
    if (!/[0-9.]/.test(event.key)) {
        event.preventDefault();
    }
};

export const longitudeLatitudeInputKey = (event) => {
    if (!/[0-9.-]/.test(event.key)) {
        event.preventDefault();
    }
};

export const characterInputKey = (event) => {
    if (/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
};
export const numberToWords = (amount) => {
    const ones = [
        "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
    ];

    const teens = [
        "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];

    const tens = [
        "", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
    ];

    function convertLessThanThousand(num) {
        if (num === 0) {
            return "";
        }
        if (num < 10) {
            return ones[num];
        }
        if (num < 20) {
            return teens[num - 10];
        }
        if (num < 100) {
            const tenDigit = tens[Math.floor(num / 10)];
            const oneDigit = ones[num % 10];
            return tenDigit + (oneDigit ? "-" + oneDigit : "");
        }
        const hundredDigit = ones[Math.floor(num / 100)];
        const remainder = num % 100;
        const remainderWords = convertLessThanThousand(remainder);
        return hundredDigit + " Hundred" + (remainderWords ? " " + remainderWords : "");
    }

    if (isNaN(amount)) {
        return "Invalid input";
    }
    if (amount < 0 || amount >= 1e12) {
        return "Out of range";
    }

    let words = "";

    const crore = Math.floor(amount / 1e7);
    if (crore > 0) {
        words += convertLessThanThousand(crore) + " Crore ";
        amount %= 1e7;
    }

    const lakh = Math.floor(amount / 1e5);
    if (lakh > 0) {
        words += convertLessThanThousand(lakh) + " Lakh ";
        amount %= 1e5;
    }

    const thousand = Math.floor(amount / 1e3);
    if (thousand > 0) {
        words += convertLessThanThousand(thousand) + " Thousand ";
        amount %= 1e3;
    }

    if (amount > 0) {
        words += convertLessThanThousand(amount);
    }

    words += " Rupees";

    const paisa = Math.round((amount - Math.floor(amount)) * 100);
    if (paisa > 0) {
        words += " and " + convertLessThanThousand(paisa) + " Paisa";
    }

    return words.trim();
};
