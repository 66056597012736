export const STATE_REDUCER_KEY = "swmPlan";

export const PLAN_TYPE = {
    COMPOSITION_OF_TEAM: "compositionOfTeam",
    GAP_ANALYSIS: "gapAnalysis",
    STAKEHOLDER_CONSULTATION: "stakeHolderConsultation",
    SUB_PROJECTS: "subProjects",
    DRAFT_PLAN: "draftPlan",
    DRAFT_PLAN_REMARKS: "draftPlanRemarks",
    POST_PLAN_CONSULTATION: "postPlanConsultation",
    PROJECT_MAPPING: "projectMapping",
    CREATE_FIVE_YEAR_PLAN: "createFiveYearPlan",
    FIVE_YEAR_PROJECT_MAPPING: "fiveYearProjectMapping",
    FINAL_PLAN: "finalPlan",
    APPROVE_MUNICIPAL: "approveMunicipal",
    SWM_PLAN_DPMC: "swmPlanDpmc",
    SWM_PLAN_QA_QC: "swmPlanQaQc",
    SWM_PLAN_SPMC: "swmPlanSpmc",
    SWM_PLAN_SPMU: "swmPlanSpmu"
};
