import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchStates = () => {
  return {
    url: API_URL.DISTRICT.STATE_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_WARD_STATE_DETAILS_REQUEST, ACTION_TYPES.FETCH_WARD_STATE_DETAILS_SUCCESS, ACTION_TYPES.FETCH_WARD_STATE_DETAILS_FAILURE]
    }
  };
};
export const fetchWards = (data) => {
  return {
    url: API_URL.WARD.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_WARD_DETAILS_REQUEST, ACTION_TYPES.FETCH_WARD_DETAILS_SUCCESS, ACTION_TYPES.FETCH_WARD_DETAILS_FAILURE],
      data
    }
  };
};
export const fetchWardsById = ({ id }) => {
  return {
    url: API_URL.WARD.BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_WARD_DETAILS_BY_ID_REQUEST, ACTION_TYPES.FETCH_WARD_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_WARD_DETAILS_BY_ID_FAILURE],
      data: { id }
    }
  };
};
export const addWard = (data) => {
  return {
    url: API_URL.WARD.CREATE,
    method: REQUEST_METHOD.POST,
    payload: {
      types: [ACTION_TYPES.ADD_WARD_DETAILS_REQUEST, ACTION_TYPES.ADD_WARD_DETAILS_SUCCESS, ACTION_TYPES.ADD_WARD_DETAILS_FAILURE],
      data
    }
  };
};
export const editWard = (data) => {
  return {
    url: API_URL.WARD.EDIT,
    method: REQUEST_METHOD.PATCH,
    payload: {
      types: [ACTION_TYPES.EDIT_WARD_DETAILS_REQUEST, ACTION_TYPES.EDIT_WARD_DETAILS_SUCCESS, ACTION_TYPES.EDIT_WARD_DETAILS_FAILURE],
      data
    }
  };
};
export const deleteWard = ({ id }) => {
  return {
    url: API_URL.WARD.DELETE.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
      types: [ACTION_TYPES.DELETE_WARD_DETAILS_REQUEST, ACTION_TYPES.DELETE_WARD_DETAILS_SUCCESS, ACTION_TYPES.DELETE_WARD_DETAILS_FAILURE]
    }
  };
};
export const LSGIDropdownApi = ({ id, districtId }) => {
  return {
    url: API_URL.ULB.LIST_ULB,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST,
        ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS,
        ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE
      ],
      data: { dropdown: true, districtId: districtId, lsgiTypeId: id }
    }
  };
};

export const wardNameList = (data) => {
  return {
    url: API_URL.WARD.FILTER_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.FETCH_WARD_NAME_LIST_REQUEST,
        ACTION_TYPES.FETCH_WARD_NAME_LIST_SUCCESS,
        ACTION_TYPES.FETCH_WARD_NAME_LIST_FAILURE
      ],
      data
    }
  };
};
export const wardNumberList = (data) => {
  return {
    url: API_URL.WARD.FILTER_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.FETCH_WARD_NUMBER_LIST_REQUEST,
        ACTION_TYPES.FETCH_WARD_NUMBER_LIST_SUCCESS,
        ACTION_TYPES.FETCH_WARD_NUMBER_LIST_FAILURE
      ],
      data
    }
  };
};
export const wardCodeList = (data) => {
  return {
    url: API_URL.WARD.FILTER_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.FETCH_WARD_CODE_LIST_REQUEST,
        ACTION_TYPES.FETCH_WARD_CODE_LIST_SUCCESS,
        ACTION_TYPES.FETCH_WARD_CODE_LIST_FAILURE
      ],
      data
    }
  };
};

export const lsgiTypeDropdownApi = (id) => {
  return {
    url: API_URL.COMMON.LSGI_TYPE_DROPDOWN,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_REQUEST,
        ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_SUCCESS,
        ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_FAILURE
      ],
      data: { dropDown: true, lsgiType: id }
    }
  };
};
export const wardDropdownApi = ({ id, districtId, pageNo, pageSize }) => {
  return {
    url: API_URL.COMMON.WARD,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [
        ACTION_TYPES.SELECT_WARD_DROPDOWN_REQUEST,
        ACTION_TYPES.SELECT_WARD_DROPDOWN_SUCCESS,
        ACTION_TYPES.SELECT_WARD_DROPDOWN_FAILURE
      ],
      data: { dropDown: true, districtId: districtId, lsgiId: id, pageNo, pageSize }
    }
  };
};
export const fetchEscalationDropDownApi = () => {
  return {
    url: API_URL.WARD_ESCALATION.ESCALATION_UNIT,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_ESCALATION_UNIT_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_ESCALATION_UNIT_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_ESCALATION_UNIT_DROPDOWN_FAILURE],
      data: { dropDown: true }
    }
  };
};
export const deleteEscalationApi = (id) => {
  return {
    url: API_URL.WARD_ESCALATION.DELETE_ESCALATION_BY_ID.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
      types: [ACTION_TYPES.DELETE_ESCALATION_REQUEST, ACTION_TYPES.DELETE_ESCALATION_SUCCESS, ACTION_TYPES.DELETE_ESCALATION_FAILURE]
    }
  };
};
export const fetchEscalationLevelApi = ({ payload }) => {
  return {
    url: API_URL.WARD_ESCALATION.GET_ESCALATION_BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID_REQUEST, ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID_SUCCESS, ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID_FAILURE],
      data: { ...payload }
    }
  };
};
export const submitEscalationApi = (data) => {
  return {
    url: API_URL.WARD_ESCALATION.SUBMIT_ESCALATION,
    method: REQUEST_METHOD.POST,
    payload: {
      types: [ACTION_TYPES.SUBMIT_ESCALATION_REQUEST, ACTION_TYPES.SUBMIT_ESCALATION_SUCCESS, ACTION_TYPES.SUBMIT_ESCALATION_FAILURE],
      data
    }
  };
};

export const updateEscalationApi = (data) => {
  return {
    url: API_URL.WARD_ESCALATION.SUBMIT_ESCALATION,
    method: REQUEST_METHOD.PATCH,
    payload: {
      types: [ACTION_TYPES.UPDATE_ESCALATION_REQUEST, ACTION_TYPES.UPDATE_ESCALATION_SUCCESS, ACTION_TYPES.UPDATE_ESCALATION_FAILURE],
      data
    }
  };
};

export const fetchOfficersListApi = (data) => {
  return {
    url: API_URL.WARD_ESCALATION.USER_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_OFFICERS_LIST_REQUEST, ACTION_TYPES.FETCH_OFFICERS_LIST_SUCCESS, ACTION_TYPES.FETCH_OFFICERS_LIST_FAILURE],
      data
    }
  };
};

export const nameList = (data) => {
  return {
    url: API_URL.ROLE.FILTER_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_NAME_LIST_REQUEST, ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, ACTION_TYPES.FETCH_NAME_LIST_FAILURE],
      data
    }
  };
};

export const fetchRoles = (data) => {
  return {
    url: API_URL.ROLE.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_ROLE_DATA_REQUEST, ACTION_TYPES.FETCH_ROLE_DATA_SUCCESS, ACTION_TYPES.FETCH_ROLE_DATA_FAILURE],
      data
    }
  };
};
