import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const saveTrainingBatchAPI = (data) => ({
    url: API_URL.TRAINING_BATCH.SAVE_BATCH,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_BATCH_FORM_REQUEST, ACTION_TYPES.SAVE_BATCH_FORM_SUCCESS, ACTION_TYPES.SAVE_BATCH_FORM_FAILURE],
        data
    }
});

export const listTrainingBatchApi = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.LIST_BATCH,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_BATCH_DETAILS_REQUEST, ACTION_TYPES.LIST_BATCH_DETAILS_SUCCESS, ACTION_TYPES.LIST_BATCH_DETAILS_FAILURE],
            data
        }
    };
};

export const fetchTargetGroupApi = (targetGroupId, planId) => {
    return {
        url: API_URL.TRAINING_BATCH.TARGET_GROUP,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TARGET_GROUP_REQUEST, ACTION_TYPES.FETCH_TARGET_GROUP_SUCCESS, ACTION_TYPES.FETCH_TARGET_GROUP_FAILURE],
            data: { targetGroupId, planId }
        }
    };
};

export const fetchHostIdApi = () => {
    return {
        url: API_URL.TRAINING_BATCH.HOST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_HOST_ID_REQUEST, ACTION_TYPES.FETCH_HOST_ID_SUCCESS, ACTION_TYPES.FETCH_HOST_ID_FAILURE]
        }
    };
};

export const fetchOrganizedByApi = () => {
    return {
        url: API_URL.TRAINING_BATCH.ORGANIZED_BY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ORGANIZED_BY_REQUEST, ACTION_TYPES.FETCH_ORGANIZED_BY_SUCCESS, ACTION_TYPES.FETCH_ORGANIZED_BY_FAILURE]
        }
    };
};

export const fetchPlanIdApi = () => {
    return {
        url: API_URL.TRAINING_BATCH.PLAN_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PLAN_ID_REQUEST, ACTION_TYPES.FETCH_PLAN_ID_SUCCESS, ACTION_TYPES.FETCH_PLAN_ID_FAILURE]
        }
    };
};

export const fetchVenueIdApi = () => {
    return {
        url: API_URL.TRAINING_BATCH.VENUE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_VENUE_ID_REQUEST, ACTION_TYPES.FETCH_VENUE_ID_SUCCESS, ACTION_TYPES.FETCH_VENUE_ID_FAILURE]
        }
    };
};

export const fetchTrainingModeApi = () => {
    return {
        url: API_URL.TRAINING_BATCH.TRAINING_MODE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TRAINING_MODE_REQUEST, ACTION_TYPES.FETCH_TRAINING_MODE_SUCCESS, ACTION_TYPES.FETCH_TRAINING_MODE_FAILURE]
        }
    };
};
export const deleteBatchByIdAPI = (id) => {
    return {
        url: API_URL.TRAINING_BATCH.DELETE_BATCH.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_BATCH_FORM_REQUEST, ACTION_TYPES.DELETE_BATCH_FORM_SUCCESS, ACTION_TYPES.DELETE_BATCH_FORM_FAILURE]
        }
    };
};

export const editBatchFormAPI = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.EDIT_BATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_BATCH_FORM_REQUEST, ACTION_TYPES.EDIT_BATCH_FORM_SUCCESS, ACTION_TYPES.EDIT_BATCH_FORM_FAILURE],
            data
        }
    };
};


export const editBatchFormByIdAPI = ({ id }) => {
    return {
        url: API_URL.TRAINING_BATCH.EDIT_BATCH_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.EDIT_BATCH_FORM_BY_ID_REQUEST, ACTION_TYPES.EDIT_BATCH_FORM_BY_ID_SUCCESS, ACTION_TYPES.EDIT_BATCH_FORM_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const addParticipantFormApi = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.ADD_BATCH,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ADD_PARTICIPANT_REQUEST, ACTION_TYPES.FETCH_ADD_PARTICIPANT_SUCCESS, ACTION_TYPES.FETCH_ADD_PARTICIPANT_FAILURE],
            data
        }
    };
};

export const viewParticipantDetailsApi = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.VIEW,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_REQUEST, ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_SUCCESS, ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_FAILURE],
            data
        }
    };
};
export const confirmParticipantFormApi = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.CONFIRM_PARTICIPANT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.CONFIRM_PARTICIPANT_BY_ID_REQUEST, ACTION_TYPES.CONFIRM_PARTICIPANT_BY_ID_SUCCESS, ACTION_TYPES.CONFIRM_PARTICIPANT_BY_ID_FAILURE],
            data
        }
    };
};

export const addPArticipantByIdApi = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.ADD_PARTICIPANT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.ADD_PARTICIPANT_BY_ID_REQUEST, ACTION_TYPES.ADD_PARTICIPANT_BY_ID_SUCCESS, ACTION_TYPES.ADD_PARTICIPANT_BY_ID_FAILURE],
            data
        }
    };
};

export const fetchCertificateDetailsApi = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.CERTIFICATE_DETAILS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_CERTIFICATE_DETAILS_REQUEST, ACTION_TYPES.FETCH_CERTIFICATE_DETAILS_SUCCESS, ACTION_TYPES.FETCH_CERTIFICATE_DETAILS_FAILURE],
            data
        }
    };
};
export const confirmCertificateIssueApi = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.CONFIRM_CERTIFICATE_ISSUE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.CERTIFICATE_ISSUE_REQUEST, ACTION_TYPES.CERTIFICATE_ISSUE_SUCCESS, ACTION_TYPES.CERTIFICATE_ISSUE_FAILURE],
            data
        }
    };
};

export const deleteParticipantByIdAPI = (id) => {
    return {
        url: API_URL.TRAINING_BATCH.DELETE_PARTICIPANT.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_BATCH_PARTICIPANT_REQUEST, ACTION_TYPES.DELETE_BATCH_PARTICIPANT_SUCCESS, ACTION_TYPES.DELETE_BATCH_PARTICIPANT_FAILURE]
        }
    };
};

export const nameList = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_NAME_LIST_REQUEST,
                ACTION_TYPES.FETCH_NAME_LIST_SUCCESS,
                ACTION_TYPES.FETCH_NAME_LIST_FAILURE
            ],
            data
        }
    };
};
export const designationGetById = (id) => {
    return {
        url: API_URL.TRAINING_BATCH.FILTER_DESIGNATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_DESIGNATION_LIST_REQUEST,
                ACTION_TYPES.FETCH_DESIGNATION_LIST_SUCCESS,
                ACTION_TYPES.FETCH_DESIGNATION_LIST_FAILURE
            ],
            data: { id }

        }
    };
};

export const filterBatchList = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FILTER_BATCH_LIST_REQUEST, ACTION_TYPES.FILTER_BATCH_LIST_SUCCESS, ACTION_TYPES.FILTER_BATCH_LIST_FAILURE],
            data: data
        }
    };
};

export const fetchTrainingCordinatorIdApi = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.TRAINING_CORDINATOR,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TRAINING_CORDINATOR_ID_REQUEST, ACTION_TYPES.FETCH_TRAINING_CORDINATOR_ID_SUCCESS, ACTION_TYPES.FETCH_TRAINING_CORDINATOR_ID_FAILURE],
            data
        }
    };
};


export const lsgiDropdownApi = (districtId) => ({
    url: API_URL.TRAINING_BATCH.LIST_ULB_DROPDOWN,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE],
        data: { districtId }
    }
});

export const fetchDistrict = () => {
    return {
        url: API_URL.TRAINING_BATCH.LIST_DISTRICT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_FAILURE]
        }
    };
};
export const fetchConfirmation = () => {
    return {
        url: API_URL.TRAINING_BATCH.LIST_CONFIRMATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_CONFIRMATION_LIST_REQUEST, ACTION_TYPES.FETCH_CONFIRMATION_LIST_SUCCESS, ACTION_TYPES.FETCH_CONFIRMATION_LIST_REQUEST]
        }
    };
};

export const filterConfirmationList = (data) => {
    return {
        url: API_URL.TRAINING_BATCH.FILTER_CONFIRMATION_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FILTER_CONFIRMATION_LIST_REQUEST, ACTION_TYPES.FILTER_CONFIRMATION_LIST_SUCCESS, ACTION_TYPES.FILTER_CONFIRMATION_LIST_FAILURE],
            data: data
        }
    };
};

export const fetchThematicArea = () => {
    return {
        url: API_URL.TRAINING_BATCH.FETCH_THEMATIC_AREA,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_THEMATIC_AREA_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_THEMATIC_AREA_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_THEMATIC_AREA_DROPDOWN_FAILURE]
        }
    };
};
