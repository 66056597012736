import { createSlice } from "@reduxjs/toolkit";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const initialState = {
    gapAnalysis: {
        requestInProgress: false,
        data: {
           attachmentIds: [],
            remarks: "",
            swmPlanId: "",
            id: ""
        }
    },
    listGApAnalysis: {
         requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.gapAnalysis = initialState.gapAnalysis;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.LIST_GAP_ANALYSIS_REQUEST, (state) => {
            state.listGApAnalysis.requestInProgress = true;
        })
            .addCase(ACTION_TYPES.LIST_GAP_ANALYSIS_SUCCESS, (state, { payload }) => {
                _.set(state, "listGApAnalysis.requestInProgress", false);
                _.set(state, "listGApAnalysis.data", payload.content[0] || {});
            })
            .addCase(ACTION_TYPES.LIST_GAP_ANALYSIS_FAILURE, (state) => {
                state.listGApAnalysis.requestInProgress = false;
                state.error = "Something went wrong";
            })
            .addCase(ACTION_TYPES.GAP_ANALYSIS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "gapAnalysis.requestInProgress", false);
                _.set(state, "gapAnalysis.data", payload||initialState.gapAnalysis);
            });
    }
});

export const { actions, reducer } = slice;
