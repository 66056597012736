import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    nameList: {
        requestInProgress: false,
        data: []
    },
    batchNameList: {
        requestInProgress: false,
        data: []
    },
    trainingMode: {
        requestInProgress: false,
        data: []
    },
    trainingName: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.TRAINING_FILTER_TRAINING_NAME_LIST_REQUEST, (state) => {
                _.set(state, "nameList.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.TRAINING_FILTER_TRAINING_NAME_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "nameList.requestInProgress", false);
                _.set(state, "nameList.data", payload.content);
            })
            .addCase(ACTION_TYPES.TRAINING_FILTER_BATCH_NAME_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "batchNameList.requestInProgress", false);
                _.set(state, "batchNameList.data", payload.content);
            })
            .addCase(ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "nameList.requestInProgress", false);
                _.set(state, "nameList.data", payload.content);
            })
            .addCase(ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "batchNameList.requestInProgress", false);
                _.set(state, "batchNameList.data", payload.content);
            })
            .addCase(ACTION_TYPES.TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "batchNameList.requestInProgress", false);
                _.set(state, "batchNameList.data", payload.content);
            })
            .addCase(ACTION_TYPES.TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "trainingMode.requestInProgress", false);
                _.set(state, "trainingMode.data", payload.content);
            })
            .addCase(ACTION_TYPES.TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "trainingName.requestInProgress", false);
                _.set(state, "trainingName.data", payload.content);
            });
    }
});

export const { actions, reducer } = slice;
