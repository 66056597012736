const { STATE_REDUCER_KEY } = require("./constants");
import { flow } from "lodash";

const treatmentAtSourceData = (key) => key[STATE_REDUCER_KEY];

const wardDropdown = (state) => state.wardDropdown.data;
export const wardDropdownSelector = flow(treatmentAtSourceData, wardDropdown);

const sourceForBdwDetails = (state) => state.sourceForBdwDetails.data;
export const sourceForBdwDetailsSelector = flow(treatmentAtSourceData, sourceForBdwDetails);

const inProgress = (state) => state.sourceForBdwDetails?.requestInProgress;
export const requestInProgressSelector = flow(treatmentAtSourceData, inProgress);
