export const API_URL = {
    GUR_REPORTS: {
        GUR_REPORT_1: "report/api/auth/reports/grants-usage/quarterly/table-view/list"
    },
    QUARTER_DROP_DOWN: "gms/api/auth/gms/reporting-quarter/list/all",
    PROJECT_DETAILS: "report/api/auth/project-details/reports/table-view/list",
    PROJECT_DETAILS_DOWNLOAD: "report/api/auth/project-details/reports/download/list",
    GUR2_REPORT: "report/api/auth/reports/gur2/ulb-level/table-view/list",
    GUR2_DOWNLOAD: "report/api/auth/reports/gur2/ulb-level/download",
    INCENTIVE_REPORT: "report/api/auth/reports/incentive/accessed-vs-utilized/table-view",
    INCENTIVE_GRANT_REPORT_DOWNLOAD: "report/api/auth/reports/incentive/accessed-vs-utilized/download",
    INCENTIVE_GRANT_PRESENT_REPORT: "report/api/auth/reports/access-incentive-grants/present-status/table-view",
    INCENTIVE_GRANT_PRESENT_REPORT_DOWNLOAD: "report/api/auth/reports/access-incentive-grants/present-status/download",
    GUR3_REPORT: "report/api/auth/reports/activity-details/progress/table-view",
    GUR3_DOWNLOAD: "report/api/auth/reports/activity-details/progress/download",
    GUR2_CUMULATIVE_TO_DATE_DOWNLOAD: "report/api/auth/reports/gur2/cumulative-to-date/download",
    GUR2_YEAR_TO_DATE_DOWNLOAD: "report/api/auth/reports/gur2/year-to-date/download",
    GUR2_REPORT_CUMULATIVE: "report/api/auth/reports/gur2/cumulative-to-date/table-view/list",
    GUR2_REPORT_YEAR_TO_DATE: "report/api/auth/reports/gur2/year-to-date/table-view/list",
    GUR1_DOWNLOAD: "report/api/auth/reports/gur1/state-level/download",
    GRANT_ALLOCATION_REPORT_DOWNLOAD: "report/api/auth/reports/grant-allocation-report/download",
    GRANT_ALLOCATION_REPORT: "report/api/auth/reports/grant-allocation-report/table-view"
};
