import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const trainingReport = state => state[STATE_REDUCER_KEY];

const nameList = (details) => details.nameList.data;
export const getNameLists = flow(trainingReport, nameList);

const batchNameList = (details) => details.batchNameList.data;
export const getBatchNameLists = flow(trainingReport, batchNameList);

const trainingNameList = (details) => details.trainingName.data;
export const getTrainingNameList = flow(trainingReport, trainingNameList);
