import { PictureAsPdf } from "@mui/icons-material";
import { IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Components, I18n } from "common/components";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { withFormik } from "formik";
import LoadingCustomOverlay from "modules/common/components/LoadingOverlay";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { downloadIncentiveGrantReport, fetchIncentiveGrant } from "../actions";
import { getIncentiveGrantReport, getIncentiveGrantReportInProgress } from "../selectors";
import { actions as sliceActions } from "../slice";
const { Grid, Box } = Components;

const useStyles = makeStyles(() => ({
    tableContainer: {
        overflowX: "auto",
        maxWidth: "100%"
    },
    table: {
        minWidth: 600,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            fontSize: "18px"
        }
    }
}));

const IncentiveGrantAccessedAndUtilizedUlbLevel = (props) => {

    const { requestInProgress= true, incentiveGrantDetails } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [newPage, setNewPage] = useState(1);

    useEffect(() => {
        dispatch(fetchIncentiveGrant({pageSize: 10, pageNo: 0}));
        return () => {
            dispatch(sliceActions.clearProjectDetails());
        };
    }, []);

    const pagination = (event, value) => {
        setNewPage(value);
        dispatch(fetchIncentiveGrant({pageSize: 10, pageNo: value-1}));
    };

    let customActions = [];

    customActions.push({ title: I18n("download_pdf"), icon: <PictureAsPdf fontSize="small"/>, handleClick: () => dispatch(downloadIncentiveGrantReport({type: "pdf"})) });

    return (
        <Box>
            <LoadingCustomOverlay active={requestInProgress}>
                <Grid item lg={12} md={12} sm={12} sx={{display: "flex", justifyContent: "end", ml: 1}}>
                    <IconButton>
                        <CustomListMenu customActions={customActions} type="download"/>
                    </IconButton>
                </Grid>
            <Grid className={classes.tableContainer}>
                <TableContainer sx={{maxHeight: {lg: "500px", xl: "600px"}}}>
                <Table sx={{ p: 1, minWidth: "300px", textAlign: "left" }} size="small" aria-label="a dense table" className={classes.table}>
                        <TableHead sx={{ fontSize: "18px", fontFamily: "Clash Display" }}>
                            <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                <TableCell sx={{ minWidth: "4em", color: "#FFFF" }} >{I18n("sl_no")}</TableCell>
                                <TableCell sx={{ minWidth: "8em", color: "#FFFF" }} >{I18n("ulb_name")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >{I18n("total_incentive_grant")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >{I18n("incentive_grant_accessed")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >{I18n("percentage_of_incentive_grant_accessed")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >{I18n("incentive_grant_utilized")}</TableCell>
                                <TableCell sx={{ color: "#FFFF" }} >{I18n("percentage_of_incentive_grant_utilized")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ maxHeight: "20em", overflow: scroll }}>
                            {incentiveGrantDetails?.content?.map((data, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell sx={{ minWidth: "1em" }}>{data?.slNo}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.lsgiName}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.totalIncentiveGrantCeiled?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.totalIncentiveGrantAccessed?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.accessedPercentage ?? "----"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.totalGrantUtilized?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em" }}>{data?.utilizedPercentage ?? "----"}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                </Table>
            </TableContainer>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "10px"}}>
                <Pagination count={incentiveGrantDetails?.totalPages} color="primary" page={newPage} onChange={pagination} />
            </Grid>
            </Grid>
            </LoadingCustomOverlay>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    incentiveGrantDetails: getIncentiveGrantReport,
    requestInProgress: getIncentiveGrantReportInProgress
});

const IncentiveGrantAccessedAndUtilizedUlbLevelForm = withFormik({})(IncentiveGrantAccessedAndUtilizedUlbLevel);
export default connect(mapStateToProps, null)(IncentiveGrantAccessedAndUtilizedUlbLevelForm);
