const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./actions");
import _ from "lodash";

const initialState = {
    typeOfWaste: {
        requestInProgress: false,
        data: []
    },
    mcfRrfSiteName: {
        requestInProgress: false,
        data: []
    },
    transferStationInfo: {
        requestInProgress: false,
        data: {
            district: "",
            lsgi: "",
            date: "",
            mcfRrf: "",
            fromDistrict: "",
            fromLsgi: "",
            loadInWasteType: "",
            loadInWeight: "",
            inTransportationProvider: "",
            inTime: "",
            inVehicleNumber: "",
            toLandFill: "",
            loadOutWasteType: "",
            loadOutWeight: "",
            outTransportationProvider: "",
            outTime: "",
            outVehicleNumber: ""
        }
    },
    fromDistrict: {
        requestInProgress: false,
        data: []
    },
    fromLsgi: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.TYPE_OF_WASTE_DROPDOWN_REQUEST, (state) => {
                state.typeOfWaste.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.TYPE_OF_WASTE_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "typeOfWaste.requestInProgress", false);
                _.set(state, "typeOfWaste.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.TYPE_OF_WASTE_DROPDOWN_FAILURE, (state) => {
                state.typeOfWaste.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_MCF_RRF_SITE_NAME_DROPDOWN_REQUEST, (state) => {
                state.mcfRrfSiteName.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_MCF_RRF_SITE_NAME_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "mcfRrfSiteName.requestInProgress", false);
                _.set(state, "mcfRrfSiteName.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.GET_MCF_RRF_SITE_NAME_DROPDOWN_FAILURE, (state) => {
                state.mcfRrfSiteName.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_TRANSFER_STATION_INFORMATION_BY_ID_REQUEST, (state) => {
                state.transferStationInfo.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_TRANSFER_STATION_INFORMATION_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "transferStationInfo.requestInProgress", false);
                _.set(state, "transferStationInfo.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_TRANSFER_STATION_INFORMATION_BY_ID_FAILURE, (state) => {
                state.transferStationInfo.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_FROM_DISTRICT_DROPDOWN_REQUEST, (state) => {
                state.fromDistrict.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_FROM_DISTRICT_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "fromDistrict.requestInProgress", false);
                _.set(state, "fromDistrict.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.GET_FROM_DISTRICT_DROPDOWN_FAILURE, (state) => {
                state.fromDistrict.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_FROM_LSGI_DROPDOWN_REQUEST, (state) => {
                state.fromLsgi.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_FROM_LSGI_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "fromLsgi.requestInProgress", false);
                _.set(state, "fromLsgi.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.GET_FROM_LSGI_DROPDOWN_FAILURE, (state) => {
                state.fromLsgi.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;

