import { PictureAsPdf } from "@mui/icons-material";
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Components, FormController, I18n } from "common/components";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { Form, withFormik } from "formik";
import LoadingCustomOverlay from "modules/common/components/LoadingOverlay";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { downloadGur2CumulativeOrYearReport, fetchGur2CumulativeOrYearReport, quarterDropDownApi } from "../actions";
import { getGetGur2CumulativeToDateInProgress, getGur2CumulativeToDate, getGur2CumulativeToDateTotal, getQuarterDropdown } from "../selectors";
import { actions as sliceActions } from "../slice";
import { getFinancialYearDropdown } from "modules/subProjects/swmSubProjects/actions";
import { getFinancialYearDropDown } from "modules/subProjects/swmSubProjects/selector";
import { getUserDetails } from "utils/userUtils";
import { USER_ROLE_NAME } from "common/constants";
const { Grid, Box, Card, IconButton, Typography } = Components;

const useStyles = makeStyles(() => ({
    tableContainer: {
        overflowX: "auto",
        maxWidth: "100%"
    },
    table: {
        minWidth: 600,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            fontSize: "18px"
        }
    }
}));

const ReportGUR2 = (props) => {

    const { requestInProgress = true, values, quarterDropDown, financialYear, setFieldValue, gur2CumulativeData, gur2Details } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [newPage, setNewPage] = useState(1);
    const { data: { activeRole = {} } = {} } = getUserDetails();

    let gur2DetailsUpdated = [...gur2Details];
    let total = gur2DetailsUpdated?.pop(-1);

    useEffect(() => {
        setFieldValue("financialYear", "");
        dispatch(getFinancialYearDropdown());
        return () => {
            dispatch(sliceActions.clearGur2CumulativeToDate());
        };
    }, []);

    const handleQuarterDropDown = (e) => {
        setFieldValue("quarter", "");
        dispatch(quarterDropDownApi(e));
        dispatch(fetchGur2CumulativeOrYearReport({ pageSize: 10, pageNo: 0, financialYear: e?.name }));
        setNewPage(1);
    };

    const handleQuarterValue = (e) => {
        dispatch(fetchGur2CumulativeOrYearReport({ pageSize: 10, pageNo: 0, financialYear: values?.financialYear?.name, quarter: e?.name }));
        setNewPage(1);
    };

    const pagination = (event, value) => {
        setNewPage(value);
        dispatch(fetchGur2CumulativeOrYearReport({pageSize: 10, pageNo: value-1, financialYear: values?.financialYear?.name, quarter: values?.quarter?.name}));
    };

    let customActions = [];

    customActions.push({ title: I18n("download_pdf"), icon: <PictureAsPdf fontSize="small"/>, handleClick: () => dispatch(downloadGur2CumulativeOrYearReport({financialYear: values?.financialYear?.name, quarter: values?.quarter?.name, type: "pdf"})) });

    return (
        <Box sx={{ maxHeight: "100%", maxWidth: "100%" }}>
            <LoadingCustomOverlay active={requestInProgress}>
            <Grid className={classes.tableContainer} >
                <Card sx={{ m: 2, boxShadow: "none", border: "1px solid #CFE7DE", overflow: "visible" }}>
                    <Grid container >
                        <Grid item xs={12} md={12} sm={12} sx={{ p: 3 }}>
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormController control="select" name="financialYear" options={financialYear || []} label={I18n("financial_year")} onChangeFromController={(e) => handleQuarterDropDown(e)} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <FormController control="select" name="quarter" options={quarterDropDown || []} label={I18n("quarter")} onChangeFromController={(e) => handleQuarterValue(e)} />
                                    </Grid>
                                </Grid>
                            </Form>
                        </Grid>
                    </Grid>
                </Card>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6}>
                        <Typography sx={{fontSize: "18px", fontFamily: "Clash Display", fontWeight: 500}}>{values?.financialYear?.name && values?.quarter?.name? I18n("year_to_date_expenditure") : values?.financialYear?.name && !(values?.quarter?.name) ? I18n("cumulative_to_date_expenditure") : ""}</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} sx={{display: "flex", justifyContent: "end"}}>
                        <IconButton>
                            <CustomListMenu customActions={customActions} type="download"/>
                        </IconButton>
                    </Grid>
                </Grid>
                <TableContainer sx={{maxHeight: {lg: "450px", xl: "600px"}}}>
                        {gur2CumulativeData?.content?.length > 0 ?
                            <Table sx={{ p: 1, minWidth: "300px", textAlign: "left" }} size="small" aria-label="a dense table" className={classes.table}>
                                <TableHead sx={{ fontSize: "18px", fontFamily: "Clash Display" }}>
                                    <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                        <TableCell sx={{ minWidth: "1em", color: "#FFFF" }} >{I18n("sl_no")}</TableCell>
                                        <TableCell sx={{ minWidth: "20em", color: "#FFFF" }} >{I18n("micro_sector")}</TableCell>
                                        {activeRole?.name === USER_ROLE_NAME.SPMU_USER_ROLE && <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("district_name")}</TableCell>}
                                        {(activeRole?.name === USER_ROLE_NAME.DPMU_USER_ROLE) && <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("ulb_name")}</TableCell>}
                                        <TableCell sx={{ color: "#FFFF" }} >{I18n("amount")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ maxHeight: "20em", overflow: scroll }}>
                                    {gur2DetailsUpdated?.map((data, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell sx={{ minWidth: "1em" }}>{data?.slNo}</TableCell>
                                                <TableCell sx={{ minWidth: "20em" }}>{data?.microsector}</TableCell>
                                                {activeRole?.name === USER_ROLE_NAME.SPMU_USER_ROLE && <TableCell sx={{ minWidth: "10em" }}>{data?.districtNamesString}</TableCell>}
                                                {(activeRole?.name === USER_ROLE_NAME.DPMU_USER_ROLE) && <TableCell sx={{ minWidth: "10em" }}>{data?.lsgiNamesString}</TableCell>}
                                                <TableCell sx={{ minWidth: "10em" }}>{data?.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {total &&
                                        <TableRow>
                                            <TableCell colspan={3} sx={{fontWeight: 600}}>{I18n("total")}</TableCell>
                                            <TableCell sx={{ minWidth: "10em", fontWeight: 600 }}>{total?.total ?? "---"}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table> :
                            <Grid xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "20px", marginBottom: "20px", border: "solid #CFE7DE 1px", padding: 3, borderRadius: "12px"}}>
                                <Typography sx={{ fontSize: "14px", fontStyle: "italic", color: "#6B6B6B" }}>{I18n("no_records")}</Typography>
                            </Grid>}
                </TableContainer>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "10px"}}>
                    <Pagination count={gur2CumulativeData?.totalPages} color="primary" page={newPage} onChange={pagination} />
                </Grid>
            </Grid>
            </LoadingCustomOverlay>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    financialYear: getFinancialYearDropDown,
    quarterDropDown: getQuarterDropdown,
    gur2CumulativeData: getGur2CumulativeToDate,
    requestInProgress: getGetGur2CumulativeToDateInProgress,
    gur2Details: getGur2CumulativeToDateTotal
});

const ReportGUR2Form = withFormik({})(ReportGUR2);
export default connect(mapStateToProps, null)(ReportGUR2Form);
