import { I18n } from "common/components";
import { STORAGE_KEYS, TABLE_IDS } from "common/constants";
import _ from "lodash";
import { ADMIN_PATH } from "modules/admin/paths";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { getOtpDetails, getTableProps } from "modules/common/selectors";
import { MODULE_PATH } from "modules/paths";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { getPayloadContent, removeEmptyProperty } from "utils/apiUtils";
import { successNotify } from "utils/notificationUtils";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { toEpoch } from "../../../utils/dateUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES as COMMON_ACTION_TYPES } from "../../common/actions";
import { actions as commonActions } from "../../common/slice";
import { ACTION_TYPES, fetchRegisterDetails, fetchRegisterDetailsById } from "./actions";
import { checkAvailabilityApi, fetchDesignationApi, fetchLoginTypeApi, fetchOrganizationApi, fetchRolesDropdownApi, fetchUserTypeApi, firstNameDropdownApi, genderDropdownApi, postChangePassword, registerDeleteCall, registerFetchAPICall, registerFetchApiCallById, registerPostAPICall, searchDropdownApi, signUpApi, updateUserProfileApi, uploadProfileImagApi, uploadSignatureImagApi } from "./api";
import { actions as sliceAction, actions as sliceActions } from "./slice";
import { getNavigator } from "modules/user/selectors";
import { API_URL } from "../apiUrls";

function* navigateToFn({ payload = "/" }) {
    const navigate = yield select(getNavigator);
    if (navigate instanceof Function) {
        yield navigate(payload);
    } else {
        // eslint-disable-next-line no-console
        console.error("navigate function not found");
    }

}

const formatValues = (values = {}) => {
    if (values.activeProfile.profileEffectiveTo) {
        _.set(values, "activeProfile.profileEffectiveTo", toEpoch(values?.activeProfile?.profileEffectiveTo));
    }
    if (values?.activeProfile?.profileEffectiveFrom) {
        _.set(values, "activeProfile.profileEffectiveFrom", toEpoch(values?.activeProfile?.profileEffectiveFrom));
    }
    if (values.userEffectiveTo) {
        _.set(values, "userEffectiveTo", toEpoch(values?.userEffectiveTo));
    }
    if (values.userEffectiveFrom) {
        _.set(values, "userEffectiveFrom", toEpoch(values?.userEffectiveFrom));
    }
    _.unset(values, "typeOfUser");
    _.unset(values, "confirmPassword");
    return removeEmptyProperty(values);
};

export function* fetchUserDetails({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.REGISTERED_USERS]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, registerFetchAPICall, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_REGISTER_DETAILS_SUCCESS, ACTION_TYPES.FETCH_REGISTER_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.REGISTERED_USERS);
}

export function* setGender() {
    yield call(handleAPIRequest, genderDropdownApi);
}

export function* submitRegisterDetails({ payload }) {
    yield fork(handleAPIRequest, registerPostAPICall, formatValues(_.cloneDeep(payload)));
    const response = yield take([ACTION_TYPES.SUBMIT_REGISTER_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_REGISTER_DETAILS_FAILURE]);
    if (response.type === ACTION_TYPES.SUBMIT_REGISTER_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("user_profile") }) }));
        yield put(navigateTo(`${MODULE_PATH.ADMIN}/${ADMIN_PATH.USER.LIST}`));
        yield put(fetchRegisterDetails());
    }
}

export function* updateUserProfile({ payload: { data, isAdmin = true } = {} }) {
    const { userId = "" } = data;
    yield fork(handleAPIRequest, updateUserProfileApi, removeEmptyProperty(formatValues(data)));
    const response = yield take([ACTION_TYPES.UPDATE_USER_PROFILE_SUCCESS, ACTION_TYPES.UPDATE_USER_PROFILE_FAILURE]);
    if (response.type === ACTION_TYPES.UPDATE_USER_PROFILE_SUCCESS) {
        yield put(fetchRegisterDetailsById(userId));
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("user") }) }));
        if (isAdmin) {
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${ADMIN_PATH.USER.LIST}`));
            yield put(fetchRegisterDetails());
        }
    }
}

export function* deleteRegisterList({ payload }) {
    let id = payload;
    yield fork(handleAPIRequest, registerDeleteCall, id);
    const response = yield take([ACTION_TYPES.DELETE_REGISTER_DETAILS_SUCCESS, ACTION_TYPES.DELETE_REGISTER_DETAILS_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_REGISTER_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("user") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchUserDetails, { payload: tablePayload });
    }
}
export function* fetchRegisterDetailsByIdFn({ payload }) {
    yield call(handleAPIRequest, registerFetchApiCallById, payload);
}

export function* fetchOrganization() {
    yield call(handleAPIRequest, fetchOrganizationApi);
}

export function* fetchDesignation({ payload }) {
    yield call(handleAPIRequest, fetchDesignationApi, payload);
}
export function* fetchUserTypeFn() {
    yield call(handleAPIRequest, fetchUserTypeApi);
}

export function* fetchLoginTypeFn() {
    yield call(handleAPIRequest, fetchLoginTypeApi);
}
export function* signUpFn({ payload }) {
    let newActive = {
        mobileNo: payload.mobileNo,
        emailId: payload.emailId,
        loginType: payload.activeProfile.loginType,
        userType: payload.activeProfile.userType
    };
    let formattedPayload = _.omit(payload, ["mobileNo", "emailId", "otpType", "targetNo", "activeProfile"]);
    let newPayload = {
        activeProfile: {
            ...newActive
        }, ...formattedPayload
    };
    yield fork(handleAPIRequest, signUpApi, newPayload);
    const response = yield take([ACTION_TYPES.SIGN_UP_SUCCESS, ACTION_TYPES.SIGN_UP_FAILURE]);
    if (response.type === ACTION_TYPES.SIGN_UP_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("user") }) }));
        yield put(navigateTo("/login"));
        yield put(sliceActions.clearVerification());
    }
}

export function* checkAvailabilityFn({ payload }) {
    yield call(handleAPIRequest, checkAvailabilityApi, payload);
}
export function* listJSONData({ payload: { value, key } = {} }) {
    let newPayload = {};
    _.set(newPayload, `${key}`, value);
    yield fork(handleAPIRequest, searchDropdownApi, { key: key, value: value });
    const response = yield take([ACTION_TYPES.FETCH_SEARCH_DATA_SUCCESS, ACTION_TYPES.FETCH_SEARCH_DATA_FAILURE]);
    if (response.type === ACTION_TYPES.FETCH_SEARCH_DATA_SUCCESS) {
        yield put(sliceAction.setJSON({ key, value: getPayloadContent(response) }));
    }

}
export function* listJSONFirstNameData({ payload: { value, key } = {} }) {
    let newPayload = {};
    _.set(newPayload, `${key}`, value);
    yield fork(handleAPIRequest, firstNameDropdownApi, { key: key, value: value });
    const response = yield take([ACTION_TYPES.FETCH_SEARCH_FIRST_NAMES_SUCCESS, ACTION_TYPES.FETCH_SEARCH_FIRST_NAMES_FAILURE]);
    if (response.type === ACTION_TYPES.FETCH_SEARCH_FIRST_NAMES_SUCCESS) {
        yield put(sliceAction.setFirstNameJSON({ key, value: getPayloadContent(response) }));
    }

}
export function* uploadProfileImage({ payload }) {
    let { userId } = payload;
    yield fork(handleAPIRequest, uploadProfileImagApi, payload);
    const response = yield take([ACTION_TYPES.UPLOAD_PROFILE_IMAGE_SUCCESS, ACTION_TYPES.UPLOAD_PROFILE_IMAGE_FAILURE]);
    if (response.type === ACTION_TYPES.UPLOAD_PROFILE_IMAGE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("uploaded") }));
        yield put(sliceActions.setOpenProfileModal(false));
        yield put(sliceActions.setCropData(null));
        yield put(fetchRegisterDetailsById(userId));
    }
}

export function* uploadProfileSignatureImage({ payload }) {
    let { userId } = payload;
    yield fork(handleAPIRequest, uploadSignatureImagApi, payload);
    const response = yield take([ACTION_TYPES.UPLOAD_SIGNATURE_IMAGE_SUCCESS, ACTION_TYPES.UPLOAD_SIGNATURE_IMAGE_FAILURE]);
    if (response.type === ACTION_TYPES.UPLOAD_SIGNATURE_IMAGE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("uploaded") }));
        yield put(sliceActions.setOpenSignatureModal(false));
        yield put(fetchRegisterDetailsById(userId));
    }
}

export function* signupMobileSmsVerification() {
    const response = yield take([COMMON_ACTION_TYPES.POST_OTP_DETAILS_SUCCESS, COMMON_ACTION_TYPES.POST_OTP_DETAILS_FAILURE]);
    if (response.type === COMMON_ACTION_TYPES.POST_OTP_DETAILS_SUCCESS) {
        let otpData = yield select(getOtpDetails);
        let { otpType = "" } = otpData;
        yield put(sliceActions.setVerified({ [otpType]: otpData || {} }));
    }
}

export function* postResetP({ payload }) {
    const { values: { confirmPassword = "", newPassword = "", oldPassword = "" } = {}, userId } = payload;
    yield fork(handleAPIRequest, postChangePassword, { confirmPassword, newPassword, oldPassword, userId });
    const response = yield take([ACTION_TYPES.POST_CHANGE_PASSWORD_SUCCESS, ACTION_TYPES.POST_CHANGE_PASSWORD_FAILURE]);
    if (response.type === ACTION_TYPES.POST_CHANGE_PASSWORD_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("change", { type: I18n("password") }) }));
        localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
        localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
        yield navigateToFn({ payload: "/" });
    }
}

export function* getDownloadUserReportList(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { firstName, userName, districtIds, lsgiId, loginType, mobileNo, emailId, wardIds, designationIds, roleIds, isCitizen } = filters;
    yield put(commonFileDownload({
        url: API_URL.REGISTER.DOWNLOAD_USER_REPORT,
        data: { type, firstName, userName, districtId: districtIds, lsgiId, loginType, mobileNo, emailId, wardId: wardIds, designationId: designationIds, roleId: roleIds, isCitizen },
        file: { name: fileName, ext: type }
    }));
}

export function* fetchRolesDropdown({ payload }) {
    yield call(handleAPIRequest, fetchRolesDropdownApi, payload);
}

export default function* registerSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SET_USER_GENDER, setGender),
        takeLatest(ACTION_TYPES.SUBMIT_REGISTER_DETAILS, submitRegisterDetails),
        takeLatest(ACTION_TYPES.FETCH_REGISTER_DETAILS, fetchUserDetails),
        takeLatest(ACTION_TYPES.DELETE_REGISTER_DETAILS, deleteRegisterList),
        takeLatest(ACTION_TYPES.FETCH_REGISTER_DETAILS_BY_ID, fetchRegisterDetailsByIdFn),
        takeLatest(ACTION_TYPES.SET_ORGANIZATION_DROPDOWN, fetchOrganization),
        takeLatest(ACTION_TYPES.SET_DESIGNATION_DROPDOWN, fetchDesignation),
        takeLatest(ACTION_TYPES.UPDATE_USER_PROFILE, updateUserProfile),
        takeLatest(ACTION_TYPES.SET_USER_TYPE_DROPDOWN, fetchUserTypeFn),
        takeLatest(ACTION_TYPES.SET_LOGIN_TYPE_DROPDOWN, fetchLoginTypeFn),
        takeLatest(ACTION_TYPES.SIGN_UP, signUpFn),
        takeLatest(ACTION_TYPES.CHECK_AVAILABILITY, checkAvailabilityFn),
        takeLatest(ACTION_TYPES.FETCH_SEARCH_DATA, listJSONData),
        takeLatest(ACTION_TYPES.UPLOAD_PROFILE_IMAGE, uploadProfileImage),
        takeLatest(ACTION_TYPES.UPLOAD_SIGNATURE_IMAGE, uploadProfileSignatureImage),
        takeLatest(ACTION_TYPES.FETCH_SEARCH_FIRST_NAMES, listJSONFirstNameData),
        takeLatest(ACTION_TYPES.CHECK_VERIFY, signupMobileSmsVerification),
        takeLatest(ACTION_TYPES.POST_CHANGE_PASSWORD, postResetP),
        takeLatest(ACTION_TYPES.DOWNLOAD_USER_REPORT_LIST, getDownloadUserReportList),
        takeLatest(ACTION_TYPES.ROLES_DROPDOWN, fetchRolesDropdown)
    ]);
}
//updateUserProfile
