import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    APPROVE_SPMC_BY_ID: `${STATE_REDUCER_KEY}/APPROVE_SPMC_BY_ID`,
    APPROVE_SPMC_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_SPMC_BY_ID_REQUEST`,
    APPROVE_SPMC_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_SPMC_BY_ID_SUCCESS`,
    APPROVE_SPMC_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_SPMC_BY_ID_FAILURE`,

    FETCH_PREVIOUS_SPMC: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_SPMC`,
    FETCH_PREVIOUS_SPMC_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_SPMC_REQUEST`,
    FETCH_PREVIOUS_SPMC_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_SPMC_SUCCESS`,
    FETCH_PREVIOUS_SPMC_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_SPMC_FAILURE`
};

export const approveSpmcById = createAction(ACTION_TYPES.APPROVE_SPMC_BY_ID);
export const fetchPreviousSpmc = createAction(ACTION_TYPES.FETCH_PREVIOUS_SPMC);
