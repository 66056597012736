import { I18n } from "common/components";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { confirmGapAnalysisApi, deleteFileById, deleteGapAnalysis, draftPlanByIdApi, draftPlanGet, listDraftPlan, saveGapAnalysis } from "./api";
import { setCommonTableData } from "utils/tableUtils";
import { navigateTo } from "modules/common/actions";
import { SUB_PROJECTS_PATH as PATH } from "../paths";
import { MODULE_PATH } from "modules/paths";
// import { getTableProps } from "modules/common/selectors";

const formatData = (rest) => {
    const { attachmentPayload, draftSwmPlanId, draftSwmPlanName, remarks, planId } = rest;
    const data = { planId: Number(planId), draftSwmPlanId, draftSwmPlanName, attachmentPayload, remarks };
    return data;
};

export function* submitDraftPlan(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, saveGapAnalysis, formatData(rest));
    const responseAction = yield take([ACTION_TYPES.SUBMIT_DRAFT_PLAN_SUCCESS, ACTION_TYPES.SUBMIT_DRAFT_PLAN_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_DRAFT_PLAN_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.DRAFT_PLAN_CONSULTATION.LIST}/${rest.planId}`));
    }
}

export function* listDraftPlanForm({ payload: swmPlanId }) {
    yield fork(handleAPIRequest, listDraftPlan, swmPlanId);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_DRAFT_PLAN_SUCCESS, ACTION_TYPES.LIST_DRAFT_PLAN_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* deleteDraftPlanForm({ payload: id }) {
    yield fork(handleAPIRequest, deleteGapAnalysis, id);
    const response = yield take([ACTION_TYPES.DELETE_DRAFT_PLAN_SUCCESS, ACTION_TYPES.DELETE_DRAFT_PLAN_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_DRAFT_PLAN_SUCCESS) {
        if (response.type === ACTION_TYPES.DELETE_DRAFT_PLAN_SUCCESS) {
            window.location.reload();
        }
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("draft_plan") }) }));
    }
}

export function* draftPlanGetById({ payload: id }) {
    yield call(handleAPIRequest, draftPlanByIdApi, id);
}

export function* draftPlanById({ payload: id }) {
    yield call(handleAPIRequest, draftPlanGet, id);
}

export function* deleteFileFn({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, deleteFileById, id, fileId);
}

export function* confirmDraftPlan({ payload }) {
    yield fork(handleAPIRequest, confirmGapAnalysisApi, payload);
    const response = yield take([ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS, ACTION_TYPES.CONFIRM_FORM_SUBMIT_FAILURE]);
    if (response.type === ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("confirm", { type: I18n("draft_plan") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.SWM_PLAN.LIST}`));
    }
}

export default function* registerSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_DRAFT_PLAN, submitDraftPlan),
        takeLatest(ACTION_TYPES.DELETE_DRAFT_PLAN, deleteDraftPlanForm),
        takeLatest(ACTION_TYPES.LIST_DRAFT_PLAN, listDraftPlanForm),
        takeLatest(ACTION_TYPES.DRAFT_PLAN_BY_ID, draftPlanGetById),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFileFn),
        takeLatest(ACTION_TYPES.LIST_DRAFT_PLAN_BY_ID, draftPlanById),
        takeLatest(ACTION_TYPES.CONFIRM_FORM_SUBMIT, confirmDraftPlan)
    ]);
}
