import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { I18n } from "common/components";
import { toEpoch } from "utils/dateUtils";
import { successNotify } from "utils/notificationUtils";
import { setCommonTableData } from "utils/tableUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { ACTION_TYPES as ACTION_TYPES_MILESTONE } from "../../../modules/subProjects/swmSubProjects/actions";
import { deleteSubProjectListApi, fetchAnnualPlanDropDownApi, fetchCategoryTypeApi, fetchEnvCategoryTypeApi, fetchNatureOfWorkListApi, fetchProjectApplicableApi, fetchProjectListDropDownApi, fetchProjectTypeApi, fetchSubCategoryTwoTypeApi, fetchSubCategoryTypeApi, fetchSubProjectListApi, fetchSubProjectListByIdApi, fetchSwmSubProjectByIdApi, fetchTrackListApi, postSubProjectApi, postSwmSubProjectApi, updateSubProjectApi, updateSwmSubProjectApi } from "./api";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "modules/paths";
import { SUB_PROJECTS_PATH } from "../paths";
import { actions as sliceAction } from "./slice";
import { TABLE_IDS } from "common/constants";
import { getTableProps } from "modules/common/selectors";
import { SwmSubProjectsListActivitiesApi } from "../swmSubProjects/api";

export function* fetchTrackList(action) {
  yield call(handleAPIRequest, fetchTrackListApi, action.payload);
}

export function* fetchAnnualPlanDropDown(action) {
  yield call(handleAPIRequest, fetchAnnualPlanDropDownApi, action.payload);
}

export function* fetchProjectListDropDown(action) {
  yield call(handleAPIRequest, fetchProjectListDropDownApi, action.payload);
}

export function* fetchCategoryList(action) {
  yield call(handleAPIRequest, fetchCategoryTypeApi, action?.payload);
}

export function* fetchNatureOfWork(action) {
  yield call(handleAPIRequest, fetchNatureOfWorkListApi, action?.payload);
}

export function* fetchEnvCategoryList(action) {
  yield call(handleAPIRequest, fetchEnvCategoryTypeApi, action.payload);
}

export function* fetchSubCategoryList(action) {
  yield call(handleAPIRequest, fetchSubCategoryTypeApi, action.payload);
}

export function* fetchSubCategoryTwoList(action) {
  yield call(handleAPIRequest, fetchSubCategoryTwoTypeApi, action?.payload);
}

export function* fetchProjectList(action) {
  yield call(handleAPIRequest, fetchProjectTypeApi, action.payload);
}

export function* fetchProjectApplicable(action) {
  yield call(handleAPIRequest, fetchProjectApplicableApi, action.payload);
}

export function* fetchSubProjectList({ payload }) {
  let updatedPayload = { pageNo: payload.pageNo, pageSize: payload.pageSize };
  yield fork(handleAPIRequest, fetchSubProjectListApi, updatedPayload, payload.id);
  const COMPARISON_TYPES = [ACTION_TYPES.LIST_SUB_PROJECT_SUCCESS, ACTION_TYPES.LIST_SUB_PROJECT_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

export function* fetchSubProjectById({ payload: id }) {
  yield fork(handleAPIRequest, fetchSubProjectListByIdApi, id);
}

export function* deleteSubProjectList({ payload: id }) {
  yield fork(handleAPIRequest, deleteSubProjectListApi, id);
  const response = yield take([ACTION_TYPES.DELETE_SUB_PROJECT_LIST_SUCCESS, ACTION_TYPES.DELETE_SUB_PROJECT_LIST_FAILURE]);
  if (response.type === ACTION_TYPES.DELETE_SUB_PROJECT_LIST_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("sub_project") }) }));
    yield call(fetchProjectList);
  }
}

const formatData = (rest) => {
  const { startDate, swmPlanId, endDate, categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, attachmentIds } = rest;
  const data = { swmPlanId: Number(swmPlanId), startDate: toEpoch(startDate), categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, endDate: toEpoch(endDate), attachmentIds };
  if (rest.attachmentIds?.length === 0) {
    delete rest.attachmentIds;
  }
  return data;
};

const formatSwmData = (rest) => {
  const { startDate, swmPlanId, endDate, categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, lsgiId, districtId, attachmentIds, natureOfWork } = rest;
  const data = { swmPlanId: Number(swmPlanId), startDate: toEpoch(startDate), categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, lsgiId, districtId, endDate: toEpoch(endDate), attachmentIds, natureOfWork: natureOfWork };
  if (conceptNoteAvailable !== 1) {
    const newFiles = rest.attachmentPayload?.multimediaList.filter(item => !(item.id));
    if (newFiles && newFiles.length > 0) {
      let attachments = {};
      attachments.multimediaList = newFiles;
      data.attachmentPayload = attachments;
    }
  }
  return data;
};
const formatEditData = (payload) => {
  const { swmPlanId, id, startDate, endDate, categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, attachmentIds } = payload;
  const data = { swmPlanId: Number(swmPlanId), id, startDate: toEpoch(startDate), categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, endDate: toEpoch(endDate), attachmentIds };
  if (payload.attachmentIds?.length === 0) {
    delete payload.attachmentIds;
  }
  return data;
};

const formatWardData = (list, projectId) => {
  const data = list?.map(({ id, name }) => ({ projectId, id, name }));
  return data;
};

const formatSwmEditData = (payload) => {
  const { id, startDate, endDate, categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, lsgiId, districtId, assignedPhysicalWards, assignedServiceWards, briefDescription, longDescription, attachmentIds, natureOfWork } = payload;
  const data = { id, startDate: toEpoch(startDate), categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, lsgiId, districtId, briefDescription, longDescription, assignedPhysicalWards: formatWardData(assignedPhysicalWards, id), assignedServiceWards: formatWardData(assignedServiceWards, id), endDate: toEpoch(endDate), attachmentIds, natureOfWork: natureOfWork };
  if (conceptNoteAvailable !== 1) {
    const newFiles = payload.attachmentPayload?.multimediaList.filter(item => !(item.id));
    if (newFiles && newFiles.length > 0) {
      let attachments = {};
      attachments.multimediaList = newFiles;
      data.attachmentPayload = attachments;
    }
  }
  return data;
};

export function* postSubProject(action) {
  let { payload: { ...rest } = {} } = action;
  yield fork(handleAPIRequest, postSubProjectApi, formatData(rest));
  yield put(sliceAction.setSubProject(formatData(rest)));
  yield put(sliceAction.setSubProjectSave());
  const responseAction = yield take([ACTION_TYPES.POST_SUB_PROJECT_SUCCESS, ACTION_TYPES.POST_SUB_PROJECT_FAILURE]);
  if (responseAction.type === ACTION_TYPES.POST_SUB_PROJECT_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("sub_project") }) }));
    yield put(sliceAction.resetSubProject());
  }
}

export function* postSwmSubProject(action) {
  let { payload: { types, ...rest } = {} } = action;
  if (types) {
    const { annualPlanId, finalListProjectId } = action.payload;
    let annualId = { id: annualPlanId?.id, name: annualPlanId?.name };
    let finalId = finalListProjectId?.id;

    const updatedPayload = { annualPlanId: annualId, finalListProjectId: finalId };
    yield fork(handleAPIRequest, postSwmSubProjectApi, updatedPayload);
  } else {
    yield fork(handleAPIRequest, postSwmSubProjectApi, formatSwmData(rest));
  }
  const responseAction = yield take([ACTION_TYPES.POST_SWM_SUB_PROJECT_SUCCESS, ACTION_TYPES.POST_SWM_SUB_PROJECT_FAILURE]);
  if (responseAction.type === ACTION_TYPES.POST_SWM_SUB_PROJECT_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("sub_project") }) }));
    yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${SUB_PROJECTS_PATH.SWM_SUB_PROJECTS.LIST}`));
  }
}

export function* fetchSwmSubProjectById({ payload: id }) {
  yield fork(handleAPIRequest, fetchSwmSubProjectByIdApi, id);
  const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SWM_SUB_PROJECT_BY_ID_SUCCESS, ACTION_TYPES.FETCH_SWM_SUB_PROJECT_BY_ID_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

export function* updateSubProject({ payload }) {
  yield fork(handleAPIRequest, updateSubProjectApi, formatEditData(payload));
  const responseAction = yield take([ACTION_TYPES.UPDATE_SUB_PROJECT_SUCCESS, ACTION_TYPES.UPDATE_SUB_PROJECT_FAILURE]);
  if (responseAction.type === ACTION_TYPES.UPDATE_SUB_PROJECT_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("sub_project") }) }));
    let updatedPayload = { pageNo: 0, pageSize: 5 };
    yield fork(handleAPIRequest, fetchSubProjectListApi, updatedPayload, Number(payload.swmPlanId));
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_SUB_PROJECT_SUCCESS, ACTION_TYPES.LIST_SUB_PROJECT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
  }
}

export function* updateSwmSubProject({ payload }) {
  yield fork(handleAPIRequest, updateSwmSubProjectApi, formatSwmEditData(payload));
  const responseAction = yield take([ACTION_TYPES.UPDATE_SWM_SUB_PROJECT_SUCCESS, ACTION_TYPES.UPDATE_SWM_SUB_PROJECT_FAILURE]);
  if (responseAction.type === ACTION_TYPES.UPDATE_SWM_SUB_PROJECT_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("sub_project") }) }));
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = getTableProps;
    yield fork(handleAPIRequest, SwmSubProjectsListActivitiesApi, { id: payload?.id, ...filters });
    const COMPARISON_TYPES = [ACTION_TYPES_MILESTONE.LIST_SWM_SUB_PROJECT_ACTIVITIES_SUCCESS, ACTION_TYPES_MILESTONE.LIST_SWM_SUB_PROJECT_ACTIVITIES_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.ACTIVITY_TABLE);
    yield call(handleAPIRequest, fetchSwmSubProjectByIdApi, payload.id);
  }
}

export default function* testSaga() {
  yield all([
    takeLatest(ACTION_TYPES.LIST_SUB_PROJECT, fetchSubProjectList),
    takeLatest(ACTION_TYPES.FETCH_SUB_PROJECT_LIST_BY_ID, fetchSubProjectById),
    takeLatest(ACTION_TYPES.FETCH_TRACK_LIST, fetchTrackList),
    takeLatest(ACTION_TYPES.FETCH_CATEGORY_TYPES, fetchCategoryList),
    takeLatest(ACTION_TYPES.FETCH_ENV_CATEGORY_TYPES, fetchEnvCategoryList),
    takeLatest(ACTION_TYPES.FETCH_SUB_CATEGORY_TYPES, fetchSubCategoryList),
    takeLatest(ACTION_TYPES.FETCH_SUB_CATEGORY_TWO_TYPES, fetchSubCategoryTwoList),
    takeLatest(ACTION_TYPES.FETCH_PROJECT_TYPES, fetchProjectList),
    takeLatest(ACTION_TYPES.POST_SUB_PROJECT, postSubProject),
    takeLatest(ACTION_TYPES.POST_SWM_SUB_PROJECT, postSwmSubProject),
    takeLatest(ACTION_TYPES.FETCH_SWM_SUB_PROJECT_BY_ID, fetchSwmSubProjectById),
    takeLatest(ACTION_TYPES.UPDATE_SUB_PROJECT, updateSubProject),
    takeLatest(ACTION_TYPES.UPDATE_SWM_SUB_PROJECT, updateSwmSubProject),
    takeLatest(ACTION_TYPES.FETCH_PROJECT_APPLICABLE, fetchProjectApplicable),
    takeLatest(ACTION_TYPES.DELETE_SUB_PROJECT_LIST, deleteSubProjectList),
    takeLatest(ACTION_TYPES.FETCH_ANNUAL_PLAN, fetchAnnualPlanDropDown),
    takeLatest(ACTION_TYPES.FETCH_PROJECT_LIST, fetchProjectListDropDown),
    takeLatest(ACTION_TYPES.FETCH_NATURE_OF_WORK_LIST, fetchNatureOfWork)
  ]);
}
