import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { TABLE_IDS } from "common/constants";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { toEpoch } from "utils/dateUtils";
import { navigateTo } from "modules/common/actions";
import { actions as sliceActions } from "./slice";
import { MODULE_PATH } from "modules/paths";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";


export function* fetchPrimaryCollectionAgency({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.PRIMARY_COLLECTION_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchPrimaryCollectionAgencyApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PRIMARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.FETCH_PRIMARY_COLLECTION_AGENCY_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.PRIMARY_COLLECTION_LIST);
}

export function* womenGroupDropdown({ payload }) {
    yield call(handleAPIRequest, Api.womenGroupDropdownApi, payload);
}

const formatData = (payload) => {
    const { collectionDate, ...rest } = payload;
    const data = { collectionDate: toEpoch(collectionDate), ...rest };
    return data;
};

export function* postPrimaryCollectionAgency({ payload }) {
    yield fork(handleAPIRequest, Api.postPrimaryCollectionAgencyApi, formatData(payload));
    yield put(sliceActions.setPrimaryCollectionDetailsById(formatData(payload)));
    const responseActions = yield take([ACTION_TYPES.POST_PRIMARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.POST_PRIMARY_COLLECTION_AGENCY_FAILURE]);
    if (responseActions.type === ACTION_TYPES.POST_PRIMARY_COLLECTION_AGENCY_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: I18n("added", { type: I18n("primary_collection_agency") }) }));
        yield put(sliceActions.resetPrimaryCollectionDetailsById());
        yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.MASTER_INFO}/${PATH.PRIMARY_COLLECTION.LIST}`));
    }
}

export function* patchPrimaryCollectionAgency({ payload }) {
    yield fork(handleAPIRequest, Api.patchPrimaryCollectionAgencyApi, formatData(payload));
    yield put(sliceActions.setPrimaryCollectionDetailsById(formatData(payload)));
    const responseActions = yield take([ACTION_TYPES.PATCH_PRIMARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.PATCH_PRIMARY_COLLECTION_AGENCY_FAILURE]);
    if (responseActions.type === ACTION_TYPES.PATCH_PRIMARY_COLLECTION_AGENCY_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: I18n("updated", { type: I18n("primary_collection_agency") }) }));
        yield put(sliceActions.resetPrimaryCollectionDetailsById());
        yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.MASTER_INFO}/${PATH.PRIMARY_COLLECTION.LIST}`));
    }
}

export function* primaryCollectionById({ payload }) {
    yield call(handleAPIRequest, Api.primaryCollectionByIdApi, payload);
}

export function* deletePrimaryCollectionAgency({ payload }) {
    yield fork(handleAPIRequest, Api.deletePrimaryCollectionAgencyApi, payload);
    const action = yield take([ACTION_TYPES.DELETE_PRIMARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.DELETE_PRIMARY_COLLECTION_AGENCY_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_PRIMARY_COLLECTION_AGENCY_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("deleted_successfully")) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.PRIMARY_COLLECTION_LIST]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchPrimaryCollectionAgencyApi, { pageNo: 0, pageSize: 5, ...filters });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PRIMARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.FETCH_PRIMARY_COLLECTION_AGENCY_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.PRIMARY_COLLECTION_LIST);
    }
}

export function* agencyNameDropdown({ payload }) {
    yield call(handleAPIRequest, Api.agencyNameDropdownApi, payload);
}

export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_PRIMARY_COLLECTION_AGENCY, fetchPrimaryCollectionAgency),
        takeLatest(ACTION_TYPES.WOMEN_WORK_GROUP_DROPDOWN, womenGroupDropdown),
        takeLatest(ACTION_TYPES.POST_PRIMARY_COLLECTION_AGENCY, postPrimaryCollectionAgency),
        takeLatest(ACTION_TYPES.PATCH_PRIMARY_COLLECTION_AGENCY, patchPrimaryCollectionAgency),
        takeLatest(ACTION_TYPES.GET_PRIMARY_COLLECTION_AGENCY_BY_ID, primaryCollectionById),
        takeLatest(ACTION_TYPES.DELETE_PRIMARY_COLLECTION_AGENCY, deletePrimaryCollectionAgency),
        takeLatest(ACTION_TYPES.AGENCY_NAME_DROPDOWN, agencyNameDropdown)
    ]);
}
