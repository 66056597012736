import { I18n } from "common/components";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";

import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { deleteDesignation, listDesignation, designationGetById, submitDesignation, updateDesignation, nameList } from "./api";
import { actions as commonActions } from "../../common/slice"; import { ADMIN_PATH as PATH } from "../paths";
import { MODULE_PATH } from "modules/paths";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { TABLE_IDS } from "common/constants";
import { getTableProps } from "modules/common/selectors";
import { API_URL } from "../apiUrls";

export function* submitDesignationEntry(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, updateDesignation, action.payload);
        const responseAction = yield take([ACTION_TYPES.UPDATE_DESIGNATION_ENTRY_SUCCESS, ACTION_TYPES.UPDATE_DESIGNATION_ENTRY_FAILURE]);
        if (responseAction.type === ACTION_TYPES.UPDATE_DESIGNATION_ENTRY_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("designations") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.DESIGNATION_ENTRY.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, submitDesignation, rest);
        const responseAction = yield take([ACTION_TYPES.SUBMIT_DESIGNATION_ENTRY_SUCCESS, ACTION_TYPES.SUBMIT_DESIGNATION_ENTRY_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_DESIGNATION_ENTRY_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("designations") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.DESIGNATION_ENTRY.LIST}`));
        }
    }
}

export function* designationEntryById({ payload }) {
    yield call(handleAPIRequest, designationGetById, payload);
}

export function* listDesignationEntry({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.DESIGNATION_ENTRY]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listDesignation, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_DESIGNATION_ENTRY_SUCCESS, ACTION_TYPES.LIST_DESIGNATION_ENTRY_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.DESIGNATION_ENTRY);
}

export function* deleteDesignationEntry({ payload: id }) {
    yield fork(handleAPIRequest, deleteDesignation, id);
    const response = yield take([ACTION_TYPES.DELETE_DESIGNATION_ENTRY_SUCCESS, ACTION_TYPES.DELETE_DESIGNATION_ENTRY_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_DESIGNATION_ENTRY_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("designations") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listDesignationEntry, { payload: tablePayload });
    }
}
export function* fetchNameList({ payload }) {
    yield call(handleAPIRequest, nameList, payload);
}
export function* downloadDesignation(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { name } = filters;
    yield put(commonFileDownload({
        url: API_URL.DESIGNATION_ENTRY.DOWNLOAD_DESIGNATION,
        data: { type, designName: name },
        file: { name: fileName, ext: type }
    }));
}
export default function* testSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_DESIGNATION_ENTRY, submitDesignationEntry),
        takeLatest(ACTION_TYPES.LIST_DESIGNATION_ENTRY, listDesignationEntry),
        takeLatest(ACTION_TYPES.DELETE_DESIGNATION_ENTRY, deleteDesignationEntry),
        takeLatest(ACTION_TYPES.DESIGNATION_ENTRY_BY_ID, designationEntryById),
        takeLatest(ACTION_TYPES.FETCH_NAME_LIST, fetchNameList),
        takeLatest(ACTION_TYPES.DOWNLOAD_DESIGNATION_LIST, downloadDesignation)
    ]);
}
