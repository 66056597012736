import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchRegionalDisposalSiteListApi = (data) => {
    return {
        url: API_URL.REGIONAL_DISPOSAL_SITE.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_REGIONAL_DISPOSAL_SITE_LIST_REQUEST, ACTION_TYPES.FETCH_REGIONAL_DISPOSAL_SITE_LIST_SUCCESS, ACTION_TYPES.FETCH_REGIONAL_DISPOSAL_SITE_LIST_FAILURE], data
        }
    };
};

export const submitRegionalDisposalSiteApi = (data) => ({
    url: API_URL.REGIONAL_DISPOSAL_SITE.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_REGIONAL_DISPOSAL_SITE_REQUEST, ACTION_TYPES.POST_REGIONAL_DISPOSAL_SITE_SUCCESS, ACTION_TYPES.POST_REGIONAL_DISPOSAL_SITE_FAILURE],
        data
    }
});

export const updateRegionalDisposalSiteApi = (data) => ({
    url: API_URL.REGIONAL_DISPOSAL_SITE.SAVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_REGIONAL_DISPOSAL_SITE_REQUEST, ACTION_TYPES.PATCH_REGIONAL_DISPOSAL_SITE_SUCCESS, ACTION_TYPES.PATCH_REGIONAL_DISPOSAL_SITE_FAILURE],
        data
    }
});

export const fetchRegionalDisposalSiteByIdApi = (id) => {
    return {
        url: API_URL.REGIONAL_DISPOSAL_SITE.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_REQUEST, ACTION_TYPES.FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_SUCCESS, ACTION_TYPES.FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_FAILURE]
        }
    };
};

export const deleteRegionalDisposalSiteApi = (id) => ({
    url: API_URL.REGIONAL_DISPOSAL_SITE.DELETE.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
        types: [ACTION_TYPES.DELETE_REGIONAL_DISPOSAL_SITE_REQUEST, ACTION_TYPES.DELETE_REGIONAL_DISPOSAL_SITE_SUCCESS, ACTION_TYPES.DELETE_REGIONAL_DISPOSAL_SITE_FAILURE]
    }
});

export const fetchSurroundingDropdownApi = (query = {}) => {
    return {
        url: API_URL.REGIONAL_DISPOSAL_SITE.SURROUNDING_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SURROUNDING_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_SURROUNDING_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_SURROUNDING_DROPDOWN_FAILURE],
            data: query
        }
    };
};
