import { createSlice } from "@reduxjs/toolkit";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    legislativeDetails: {
        requestInProgress: false,
        data: {
            name: "",
            code: "",
            districtId: ""
        }
    },
    districtList: {
        requestInProgress: false,
        data: []
    },
    nameList: {
        requestInProgress: false,
        data: []
    },
    codeList: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.codeList = initialState.codeList;
            state.nameList = initialState.nameList;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_LEGISLATIVE_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
            _.set(state, "legislativeDetails.requestInProgress", false);
            _.set(state, "legislativeDetails.data", payload.content[0]);
        });
        builder.addCase(ACTION_TYPES.FETCH_DISTRICT_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "districtList.requestInProgress", false);
            _.set(state, "districtList.data", payload.content);
        }).addCase(ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "nameList.requestInProgress", false);
            _.set(state, "nameList.data", payload.content);
        }).addCase(ACTION_TYPES.FETCH_CODE_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "codeList.requestInProgress", false);
            _.set(state, "codeList.data", payload.content);
        });
    }
});
export const { reducer, actions } = slice;
