import { ACTION_TYPES } from "./actions";
import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "modules/subProjects/apiUrls";

export const submitDprPreparationData = (data) => ({
    url: API_URL.DPR_FLOW.DPR_PREPARATION.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_DPR_PREPARATION_REQUEST, ACTION_TYPES.POST_DPR_PREPARATION_SUCCESS,
        ACTION_TYPES.POST_DPR_PREPARATION_FAILURE],
        data
    }
});
export const getDprPreparationData = (data) => {
    return {
        url: API_URL.DPR_FLOW.DPR_PREPARATION.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DPR_PREPARATION_REQUEST, ACTION_TYPES.FETCH_DPR_PREPARATION_SUCCESS, ACTION_TYPES.FETCH_DPR_PREPARATION_FAILURE],
            data
        }
    };
};
export const updateDprPreparationData = (data) => ({
    url: API_URL.DPR_FLOW.DPR_PREPARATION.SAVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_DPR_PREPARATION_REQUEST, ACTION_TYPES.PATCH_DPR_PREPARATION_SUCCESS,
        ACTION_TYPES.PATCH_DPR_PREPARATION_FAILURE],
        data
    }
});

export const approveDprPreparationData = (data) => ({
    url: `${API_URL.DPR_FLOW.DPR_PREPARATION.SUBMITTED_TO_MC}?projectId=${data.projectId}`,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.APPROVE_DPR_PREPARATION_REQUEST, ACTION_TYPES.APPROVE_DPR_PREPARATION_SUCCESS,
        ACTION_TYPES.APPROVE_DPR_PREPARATION_FAILURE]
    }
});
export const getNocFromWbByIdApi = (id) => {
    return {
        url: API_URL.DPR_FLOW.NOC.GET_BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_NOC_FROM_WB_REQUEST, ACTION_TYPES.FETCH_NOC_FROM_WB_SUCCESS, ACTION_TYPES.FETCH_NOC_FROM_WB_FAILURE]
        }
    };
};

export const submitNocFromWbApi = (data) => {
    return {
        url: API_URL.DPR_FLOW.NOC.SAVE_UPDATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_NOC_FROM_WB_REQUEST, ACTION_TYPES.POST_NOC_FROM_WB_SUCCESS, ACTION_TYPES.POST_NOC_FROM_WB_FAILURE],
            data
        }
    };
};

export const updateNocFromWbApi = (data) => {
    return {
        url: API_URL.DPR_FLOW.NOC.SAVE_UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_NOC_FROM_WB_REQUEST, ACTION_TYPES.PATCH_NOC_FROM_WB_SUCCESS, ACTION_TYPES.PATCH_NOC_FROM_WB_FAILURE],
            data
        }
    };
};

export const getTechnicalSanctionByIdApi = (id) => {
    return {
        url: API_URL.DPR_FLOW.TECHNICAL_SANCTION.GET_BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TECHNICAL_SANCTION_REQUEST, ACTION_TYPES.FETCH_TECHNICAL_SANCTION_SUCCESS, ACTION_TYPES.FETCH_TECHNICAL_SANCTION_FAILURE]
        }
    };
};

export const submitTechnicalSanctionApi = (data) => {
    return {
        url: API_URL.DPR_FLOW.TECHNICAL_SANCTION.SAVE_UPDATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_TECHNICAL_SANCTION_REQUEST, ACTION_TYPES.POST_TECHNICAL_SANCTION_SUCCESS, ACTION_TYPES.POST_TECHNICAL_SANCTION_FAILURE],
            data
        }
    };
};

export const updateTechnicalSanctionApi = (data) => {
    return {
        url: API_URL.DPR_FLOW.TECHNICAL_SANCTION.SAVE_UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_TECHNICAL_SANCTION_REQUEST, ACTION_TYPES.PATCH_TECHNICAL_SANCTION_SUCCESS, ACTION_TYPES.PATCH_TECHNICAL_SANCTION_FAILURE],
            data
        }
    };
};

export const getFinancialSanctionByIdApi = (id) => {
    return {
        url: API_URL.DPR_FLOW.FINANCIAL_SANCTION.GET_BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_FINANCIAL_SANCTION_REQUEST, ACTION_TYPES.FETCH_FINANCIAL_SANCTION_SUCCESS, ACTION_TYPES.FETCH_FINANCIAL_SANCTION_FAILURE]
        }
    };
};

export const submitFinancialSanctionApi = (data) => {
    return {
        url: API_URL.DPR_FLOW.FINANCIAL_SANCTION.SAVE_UPDATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_FINANCIAL_SANCTION_REQUEST, ACTION_TYPES.POST_FINANCIAL_SANCTION_SUCCESS, ACTION_TYPES.POST_FINANCIAL_SANCTION_FAILURE],
            data
        }
    };
};

export const updateFinancialSanctionApi = (data) => {
    return {
        url: API_URL.DPR_FLOW.FINANCIAL_SANCTION.SAVE_UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_FINANCIAL_SANCTION_REQUEST, ACTION_TYPES.PATCH_FINANCIAL_SANCTION_SUCCESS, ACTION_TYPES.PATCH_FINANCIAL_SANCTION_FAILURE],
            data
        }
    };
};

export const approveQaQcByDpmu = (data) => ({
    url: API_URL.DPR_FLOW.QA_QC_BY_DPMU.APPROVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.APPROVE_QA_QC_BY_DPMU_REQUEST, ACTION_TYPES.APPROVE_QA_QC_BY_DPMU_SUCCESS, ACTION_TYPES.APPROVE_QA_QC_BY_DPMU_FAILURE],
        data
    }
});

export const rejectQaQcByDpmu = (data) => {
    return {
        url: API_URL.DPR_FLOW.QA_QC_BY_DPMU.REJECT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.APPROVE_QA_QC_BY_DPMU_REQUEST, ACTION_TYPES.APPROVE_QA_QC_BY_DPMU_SUCCESS, ACTION_TYPES.APPROVE_QA_QC_BY_DPMU_FAILURE],
            data
        }
    };
};

export const approveBySpmu = (data) => ({
    url: API_URL.DPR_FLOW.SPMU.APPROVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.APPROVE_BY_SPMU_REQUEST, ACTION_TYPES.APPROVE_BY_SPMU_SUCCESS, ACTION_TYPES.APPROVE_BY_SPMU_FAILURE],
        data
    }
});

export const rejectBySpmu = (data) => {
    return {
        url: API_URL.DPR_FLOW.SPMU.REJECT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.APPROVE_BY_SPMU_REQUEST, ACTION_TYPES.APPROVE_BY_SPMU_SUCCESS, ACTION_TYPES.APPROVE_BY_SPMU_FAILURE],
            data
        }
    };
};

export const approveByMunicipal = (data) => {
    return {
        url: API_URL.DPR_FLOW.MUNICIPAL.APPROVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.APPROVE_BY_MUNICIPAL_REQUEST, ACTION_TYPES.APPROVE_BY_MUNICIPAL_SUCCESS, ACTION_TYPES.APPROVE_BY_MUNICIPAL_FAILURE],
            data
        }
    };
};
export const rejectByMunicipal = (data) => {
    return {
        url: API_URL.DPR_FLOW.MUNICIPAL.REJECT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.APPROVE_BY_MUNICIPAL_REQUEST, ACTION_TYPES.APPROVE_BY_MUNICIPAL_SUCCESS, ACTION_TYPES.APPROVE_BY_MUNICIPAL_FAILURE],
            data
        }
    };
};
export const fetchDprListApi = (data) => ({
    url: API_URL.DPR_FLOW.DPR.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_DPR_LIST_REQUEST, ACTION_TYPES.FETCH_DPR_LIST_SUCCESS, ACTION_TYPES.FETCH_DPR_LIST_FAILURE],
        data
    }
});
