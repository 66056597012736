import { REQUEST_METHOD } from "common/constants";
import { ACTION_TYPES } from "./actions";
import { API_URL } from "./apiUrls";

export const fetchQuarterDropDownApi = (data) => {
    return {
        url: API_URL.QUARTER_DROP_DOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_QUARTER_DROP_DOWN_REQUEST, ACTION_TYPES.FETCH_QUARTER_DROP_DOWN_SUCCESS, ACTION_TYPES.FETCH_QUARTER_DROP_DOWN_FAILURE],
            data: { fy: data, dropDown: true }
        }
    };
};

export const fetchGurReport1Api = (data) => {
    return {
        url: API_URL.GUR_REPORTS.GUR_REPORT_1,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GUR_REPORT_1_REQUEST, ACTION_TYPES.FETCH_GUR_REPORT_1_SUCCESS, ACTION_TYPES.FETCH_GUR_REPORT_1_FAILURE],
            data
        }
    };
};

export const fetchProjectDetailsApi = (data) => {
    return {
        url: API_URL.PROJECT_DETAILS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.PROJECT_DETAILS_REPORT_REQUEST, ACTION_TYPES.PROJECT_DETAILS_REPORT_SUCCESS, ACTION_TYPES.PROJECT_DETAILS_REPORT_FAILURE],
            data
        }
    };
};

export const gur2ReportApi = (data) => {
    return {
        url: API_URL.GUR2_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GUR2_REPORT_REQUEST, ACTION_TYPES.FETCH_GUR2_REPORT_SUCCESS, ACTION_TYPES.FETCH_GUR2_REPORT_FAILURE],
            data
        }
    };
};

export const incentiveGrantReportApi = (data) => {
    return {
        url: API_URL.INCENTIVE_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_INCENTIVE_GRANT_REPORT_REQUEST, ACTION_TYPES.FETCH_INCENTIVE_GRANT_REPORT_SUCCESS, ACTION_TYPES.FETCH_INCENTIVE_GRANT_REPORT_FAILURE],
            data
        }
    };
};

export const incentiveGrantPresentStatusReportApi = (data) => {
    return {
        url: API_URL.INCENTIVE_GRANT_PRESENT_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_REQUEST, ACTION_TYPES.FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_SUCCESS, ACTION_TYPES.FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_FAILURE],
            data
        }
    };
};

export const fetchGur3Api = (data) => {
    return {
        url: API_URL.GUR3_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GUR3_REPORT_REQUEST, ACTION_TYPES.FETCH_GUR3_REPORT_SUCCESS, ACTION_TYPES.FETCH_GUR3_REPORT_FAILURE],
            data
        }
    };
};

export const gur2CumulativeToDateReportApi = (data) => {
    let url = "";
    if (data.quarter) {
        url = API_URL.GUR2_REPORT_YEAR_TO_DATE;
    } else {
        url = API_URL.GUR2_REPORT_CUMULATIVE;
    }
    return {
        url: url,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_REQUEST, ACTION_TYPES.FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_SUCCESS, ACTION_TYPES.FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_FAILURE],
            data
        }
    };
};

export const grantAllocationReportApi = (data) => {
    return {
        url: API_URL.GRANT_ALLOCATION_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GRANT_ALLOCATION_REQUEST, ACTION_TYPES.FETCH_GRANT_ALLOCATION_SUCCESS, ACTION_TYPES.FETCH_GRANT_ALLOCATION_FAILURE],
            data
        }
    };
};
