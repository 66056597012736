import { I18n, Icons } from "common/components";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { REACT_TABLE_COMMON_OPTIONS, ROWS_PER_PAGE, TABLE_IDS, USER_ROLE_NAME } from "common/constants";
import CommonTable from "modules/common/components/CommonTable";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { confirmDialog } from "utils/notificationUtils";
import { getUserDetails } from "utils/userUtils";
import { deleteWardDetails, fetchWardDetailsList } from "../actions";

const { AddCircleOutline, EditIcon, DeleteForever, OpenInNewIcon } = Icons;

const WardDetailsInfoList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: { activeRole = {} } = {} } = getUserDetails();

    const handleDelete = (id) => {
        confirmDialog({
            title: I18n("are_sure_want_delete")
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteWardDetails(id));
            } else if (result.isDenied) {
                Swal.fire(I18n("action_cancelled"), "", "info");
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                header: I18n("ward_no"),
                accessorFn: (row) => row?.ward?.wardNumber || ""
            },
            {
                header: I18n("ward_name"),
                accessorFn: (row) => row?.ward?.name || ""
            },
            {
                header: I18n("area_of_ward"),
                accessorFn: (row) => row?.wardArea || ""
            },
            {
                header: I18n("population_ward"),
                accessorFn: (row) => row?.population || ""
            },
            {
                header: I18n("total_road_length"),
                accessorFn: (row) => row?.totalRoadLength || ""
            }
        ],
        []
    );

    const actions = (row) => {
        let customActions = [];
        if (activeRole?.name === USER_ROLE_NAME.SWM_STAFF_USER) {
            customActions.push({ title: I18n("edit"), icon: <EditIcon fontSize="small" />, handleClick: () => navigate(`${row.original.id}/edit`) });
            customActions.push({ title: "Delete", icon: <DeleteForever fontSize="small" />, disabled: activeRole?.name !== USER_ROLE_NAME.SWM_STAFF_USER, handleClick: () => handleDelete(row.original?.id) });
        }
        customActions.push({ title: I18n("view"), icon: <OpenInNewIcon fontSize="small" />, handleClick: () => navigate(`${row.original.id}/view`) });
        return customActions;
    };

    const displayColumnDefOptions = {
        "mrt-row-actions": {
            Cell: ({ row }) => <CustomListMenu customActions={actions(row)} />
        }
    };

    const toolBarActions = [];
    const toolbarPermissions = [0, 1];
    if (toolbarPermissions[1]) {
        toolBarActions.push({ title: "Create", icon: <AddCircleOutline fontSize="medium" />, disabled: activeRole?.name !== USER_ROLE_NAME.SWM_STAFF_USER, handleClick: () => navigate("create") });
    }

    const options = {
        ...REACT_TABLE_COMMON_OPTIONS,
        enableRowSelection: false,
        enableFilters: true,
        toolBarActions: toolBarActions,
        customPagination: {
            rowsPerPageOptions: ROWS_PER_PAGE
        },
        enableRowActions: true,
        displayColumnDefOptions
    };

    return (
        <>
            <CommonTable
                columns={columns}
                options={options}
                enableVirtualization={false}
                title={I18n("ward_details")}
                fetchData={fetchWardDetailsList}
                enableCustomTableFilter={true}
                tableId={TABLE_IDS.WARD_DETAILS}
            />
        </>
    );
};

export default WardDetailsInfoList;
