import { PictureAsPdf } from "@mui/icons-material";
import { IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Components, I18n } from "common/components";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { withFormik } from "formik";
import LoadingCustomOverlay from "modules/common/components/LoadingOverlay";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { downloadIncentiveGrantPresentStatusReport, fetchIncentiveGrantPresentStatus } from "../actions";
import { getIncentiveGrantPresentStatusReport, getIncentiveGrantPresentStatusReportInProgress } from "../selectors";
import { actions as sliceActions } from "../slice";
const { Grid, Box } = Components;

const useStyles = makeStyles(() => ({
    tableContainer: {
        overflowX: "auto",
        maxWidth: "100%"
    },
    table: {
        minWidth: 600,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            fontSize: "18px"
        }
    }
}));

const ULBAccessToIncentiveGrant = (props) => {
    const { requestInProgress = true, incentiveGrantPresentStatusDetails } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [newPage, setNewPage] = useState(1);

    useEffect(() => {
        dispatch(fetchIncentiveGrantPresentStatus({pageSize: 10, pageNo: 0}));
        return () => {
            dispatch(sliceActions.clearIncentiveGrantPresentStatusData());
        };
    }, []);

    const pagination = (event, value) => {
        setNewPage(value);
        dispatch(fetchIncentiveGrantPresentStatus({pageSize: 10, pageNo: value-1}));
    };

    let customActions = [];

    customActions.push({ title: I18n("download_pdf"), icon: <PictureAsPdf fontSize="small" />, handleClick: () => dispatch(downloadIncentiveGrantPresentStatusReport({ type: "pdf" })) });

    return (
        <Box sx={{ maxHeight: "100%", maxWidth: "100%" }}>
            <LoadingCustomOverlay active={requestInProgress}>
                <Grid item lg={12} md={12} sm={12} sx={{display: "flex", justifyContent: "end", ml: 1}}>
                    <IconButton>
                        <CustomListMenu customActions={customActions} type="download"/>
                    </IconButton>
                </Grid>
            <Grid className={classes.tableContainer} >
                <TableContainer sx={{maxHeight: {lg: "550px", xl: "600px"}}}>
                    <Table sx={{ p: 1, minWidth: "300px", textAlign: "left" }} size="small" aria-label="a dense table" className={classes.table}>
                        <TableHead sx={{ fontSize: "18px", fontFamily: "Clash Display" }}>
                            <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                <TableCell sx={{ color: "#FFFF" }} rowSpan={2}>{I18n("sl_no")}</TableCell>
                                <TableCell sx={{ minWidth: "10em", color: "#FFFF" }} rowSpan={2}>{I18n("ulb_name")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF" }} colSpan={3}>{I18n("phase_1")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF", minWidth: "10em" }} rowSpan={2}>{I18n("if_incentive_grant_received")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF" }} colSpan={4}>{I18n("phase_1")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF", minWidth: "10em" }} rowSpan={2}>{I18n("if_incentive_grant_received")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF", minWidth: "10em" }} rowSpan={2}>{I18n("application_id")}</TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                <TableCell sx={{ color: "#FFFF" }}>{I18n("swm_plan")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF" }} >{I18n("swm_bye_law")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF" }} >{I18n("access_to_use_of_facility_for_safe_disposal_of_waste")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF" }}>{I18n("hire_top_level_staff_in_accordance_with_the_structure_approved_by_gok")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF" }}>{I18n("sign_one_or_more_performance_based_contracts_for_solid_waste_collection_and_transportation_services")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF" }}>{I18n("develop_a_plan_for_user_charges_and_o_and_m_budgeting")}</TableCell>
                                <TableCell sx={{ maxWidth: "50%", color: "#FFFF" }}>{I18n("implement_monitoring_and_evaluation_including_grm")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ maxHeight: "20em", overflow: scroll }}>
                            {incentiveGrantPresentStatusDetails?.content?.map((data, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{data?.slNo}</TableCell>
                                        <TableCell>{data?.lsgiName}</TableCell>
                                        <TableCell>{data?.hasPreparedMultiyearPlan ?? "----"}</TableCell>
                                        <TableCell>{data?.hasIssuedSWMBylaws ?? "----"}</TableCell>
                                        <TableCell>{data?.hasConfirmedAccessToFacility ?? "----"}</TableCell>
                                        <TableCell>{data?.incentiveAmount1?.toFixed(3)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                        <TableCell>{data?.hasHiredTopLevelStaff ?? "----"}</TableCell>
                                        <TableCell>{data?.hasSignedContractsForSWM ?? "----"}</TableCell>
                                        <TableCell>{data?.hasDevelopedPlanForUserCharges ?? "----"}</TableCell>
                                        <TableCell>{data?.hasImplementedMonitoringAndEvaluation ?? "----"}</TableCell>
                                        <TableCell>{data?.incentiveAmount2?.toFixed(3)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "----"}</TableCell>
                                        <TableCell>{data?.applicationId ?? "----"}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "10px"}}>
                    <Pagination count={incentiveGrantPresentStatusDetails?.totalPages} color="primary" page={newPage} onChange={pagination} />
                </Grid>
            </Grid>
            </LoadingCustomOverlay>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    incentiveGrantPresentStatusDetails: getIncentiveGrantPresentStatusReport,
    requestInProgress: getIncentiveGrantPresentStatusReportInProgress
});

const ULBAccessToIncentiveGrantForm = withFormik({})(ULBAccessToIncentiveGrant);
export default connect(mapStateToProps, null)(ULBAccessToIncentiveGrantForm);
