export const GRAND_PATH = {
    ULB_POPULATION: {
        LIST: "ulb-population",
        CREATE: "create",
        VIEW: ":id/view",
        EDIT: ":id/edit"
    },
    REQUEST: {
        LIST: "allocation/grant-request-list",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    TOTAL_FUND: {
        CREATE: "create",
        LIST: "allocation/total-fund",
        VIEW: ":id/view"

    },
    GAC_COMPUTATION: {
        LIST: "allocation/gac-computation"
    },
    IVA: {
        LIST: "iva",
        REMARK: "iva/:id/view"
    },
    PLAN_VIEW: {
        ULB_LIST: "plan-view"
    },
    UTILIZATION_APPLICATION: {
        LIST: "utilization-application-list",
        VIEW: "utilization-application-list/:id/edit"
    },
    FETCH_EXPENSE: {
        LIST: "utilization/fetch-expense"
    },
    FINANCIAL_YEAR: {
        LIST: "financial-year",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    BILLS: {
        LIST: "bills",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    RECEIPT: {
        LIST: "receipt",
        CREATE: "create",
        UPDATE: ":id/edit",
        VIEW: ":id/view"
    },
    REPORTS: {
        LIST: "reports"
    }
};
