import { lazy } from "react";
import { REPORT_PATH as PATH } from "./paths";
const ComplaintListingReport = lazy(() => import("modules/reports/complaintReports/components/ComplaintListingReport"));

const routes = [
    {
        title: "Complaint Details Report",
        path: PATH.COMPLAINT_REPORT.LIST,
        element: <ComplaintListingReport />
    }
];

export { routes };
