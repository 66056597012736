import { I18n } from "common/components";
import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { successNotify } from "utils/notificationUtils";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { ACTION_TYPES } from "./actions";
import { actions as commonActions } from "../../common/slice";
import { SUB_PROJECTS_PATH as PATH } from "../paths";
import { deleteFileById, deleteSWMPlanApi, fetchDistricts, fetchPreviousApi, fetchSwmPlanRevisionListApi, listByIdSWMPlanApi, listSWMPlanApi, lsgiDropdownApi, planStatusList, submitSWMPlanApi, ulbSubmittedToMcApi, updateSWMPlanApi, updateSWMPlanAttachmentApi } from "./api";
import { MODULE_PATH } from "modules/paths";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import _ from "lodash";
import { TABLE_IDS } from "common/constants";
import { actions as sliceActions } from "./slice";
import { API_URL } from "../apiUrls";

export function* lsgiDropdown(action) {
    yield call(handleAPIRequest, lsgiDropdownApi, action.payload);
}

export function* submitSWMPlan({ payload }) {
    yield put(sliceActions.setRequestInProgress());
    let { id } = payload;
    let newPayload = _.omit(payload, "lsgiSelectDropdownOption", "fetchSWMPlanListDetails");
    if (id) {
        if (newPayload.attachmentPayload?.multimediaList?.length === 0) {
            delete newPayload?.attachmentPayload;
        }
        yield fork(handleAPIRequest, updateSWMPlanApi, newPayload);
        const responseAction = yield take([ACTION_TYPES.EDIT_SWM_PLAN_SUCCESS, ACTION_TYPES.EDIT_SWM_PLAN_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_SWM_PLAN_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("swm_plan") }) }));
            yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.SWM_PLAN.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, submitSWMPlanApi, newPayload);
        const responseAction = yield take([ACTION_TYPES.SUBMIT_SWM_PLAN_SUCCESS, ACTION_TYPES.SUBMIT_SWM_PLAN_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_SWM_PLAN_SUCCESS) {
            yield put(sliceActions.resetRequestInProgress());
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("swm_plan") }) }));
            yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.SWM_PLAN.LIST}`));
        }
    }
}
export function* listSWMPlan({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.SWM_PLAN_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listSWMPlanApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_SWM_PLAN_SUCCESS, ACTION_TYPES.LIST_SWM_PLAN_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.SWM_PLAN_LIST);
}
export function* listByIdSWMPlan({ payload }) {
    yield call(handleAPIRequest, listByIdSWMPlanApi, payload);
}
export function* deleteSWMPlan({ payload: id }) {
    yield fork(handleAPIRequest, deleteSWMPlanApi, id);
    const response = yield take([ACTION_TYPES.DELETE_SWM_PLAN_SUCCESS, ACTION_TYPES.DELETE_SWM_PLAN_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_SWM_PLAN_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("swm_plan") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listSWMPlan, { payload: tablePayload });
    }
}

export function* districts() {
    yield call(handleAPIRequest, fetchDistricts);
}

export function* deleteFileFn({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, deleteFileById, id, fileId);
}
export function* updateSwmAttachment({ payload }) {
    yield fork(handleAPIRequest, updateSWMPlanAttachmentApi, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_SWM_PLAN_ATTACHMENT_SUCCESS, ACTION_TYPES.UPDATE_SWM_PLAN_ATTACHMENT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_SWM_PLAN_ATTACHMENT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("swm_plan") }) }));
    }
}
export function* ulbSubmittedToMc({ payload }) {
    yield fork(handleAPIRequest, ulbSubmittedToMcApi, { planId: payload?.planId });
    const responseAction = yield take([ACTION_TYPES.ULB_SUBMITTED_TO_MC_SUCCESS, ACTION_TYPES.ULB_SUBMITTED_TO_MC_FAILURE]);
    if (responseAction.type === ACTION_TYPES.ULB_SUBMITTED_TO_MC_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("submitted", { type: I18n("swm_plan") }) }));
        yield call(handleAPIRequest, fetchSwmPlanRevisionListApi, { swmPlanId: payload?.planId, revision: payload?.revision });
        yield call(handleAPIRequest, listByIdSWMPlanApi, payload?.planId);
    }
}
export function* fetchPlanStatusList({ payload }) {
    yield call(handleAPIRequest, planStatusList, payload);
}
export function* getSwmPlanRevision({ payload }) {
    yield call(handleAPIRequest, fetchSwmPlanRevisionListApi, payload);
}

export function* getPrevious({ payload }) {
    yield call(handleAPIRequest, fetchPreviousApi, payload);
}
export function* downloadSwmPlan(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { districtId, lsgiId, planStatus } = filters;
    yield put(commonFileDownload({
        url: API_URL.SWM_PLAN.DOWNLOAD,
        data: { type, districtId, lsgiId, planStatus },
        file: { name: fileName, ext: type }
    }));
}

export default function* swmPlanActivitySaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_LSGI_DROPDOWN, lsgiDropdown),
        takeLatest(ACTION_TYPES.SUBMIT_SWM_PLAN, submitSWMPlan),
        takeLatest(ACTION_TYPES.LIST_SWM_PLAN, listSWMPlan),
        takeLatest(ACTION_TYPES.LIST_BY_ID_SWM_PLAN, listByIdSWMPlan),
        takeLatest(ACTION_TYPES.DELETE_SWM_PLAN, deleteSWMPlan),
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN, districts),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFileFn),
        takeLatest(ACTION_TYPES.UPDATE_SWM_PLAN_ATTACHMENT, updateSwmAttachment),
        takeLatest(ACTION_TYPES.ULB_SUBMITTED_TO_MC, ulbSubmittedToMc),
        takeLatest(ACTION_TYPES.FETCH_STATUS_LIST, fetchPlanStatusList),
        takeLatest(ACTION_TYPES.FETCH_SWM_PLAN_REVISION_LIST, getSwmPlanRevision),
        takeLatest(ACTION_TYPES.FETCH_PREVIOUS, getPrevious),
        takeLatest(ACTION_TYPES.DOWNLOAD_SWM_PLAN, downloadSwmPlan)
    ]);
}
