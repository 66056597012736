import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadContent } from "utils/apiUtils";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    startDate: "",
    endDate: "",
    districts: "",
    lsgiLists: "",
    lsgiType: "",
    wardLists: "",
    complaintStatusDropdownOptions: {
        data: [],
        requestInProgress: false
    },
    complaintTypeDropdown: {
        requestInProgress: false,
        data: []
    },
    complaintsModeDropdown: {
        requestInProgress: false,
        data: []
    },
    complaintCategoryDropdown: {
        requestInProgress: false,
        data: []
    },
    districtsDropdown: {
        requestInProgress: false,
        data: []
    },
    wardDropdowns: {
        requestInProgress: false,
        data: []
    },
    lsgiDropdowns: {
        requestInProgress: false,
        data: []
    },
    lsgiTypeDropdownOption: {
        requestInProgress: false,
        data: []
    },
    complaintReportList: {
        requestInProgress: false,
        data: []
    },
    priorityDropdownOption: {
        requestInProgress: false,
        data: []
    },
    listComplaintHandler: {},
    listComplaintNumber: {}


};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clear: () => initialState,
        clearWardData: (state) => {
            state.complaintClosed = initialState.wardDropdowns;

        },
        setLsgiList: (state, data) => {
            let { data: lsgiList = [], id: lsgiTypeId, districtId } = data.payload;
            state.lsgiDropdowns = {
                requestInProgress: false,
                [districtId]: {
                    [lsgiTypeId]: lsgiList
                }
            };

            state.wardDropdowns = {
                requestInProgress: false
            };

        },
        setWardList: (state, data) => {
            let { data: wardList = [], id: lsgiId, districtId } = data.payload;
            state.wardDropdowns = {
                requestInProgress: false,
                [districtId]: {
                    [lsgiId]: wardList
                }
            };
        },
        setJSON: (state, actions = {}) => {
            const { payload: { key, value } } = actions;
            _.set(state, `listComplaintHandler.${key}`, value);
        },
        setJSONNumber: (state, actions = {}) => {
            const { payload: { key, value } } = actions;
            _.set(state, `listComplaintNumber.${key}`, value);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_COMPLAINT_STATUS_DROPDOWN_REQUEST, (state) => {
            state.complaintStatusDropdownOptions.requestInProgress = true;
        })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_STATUS_DROPDOWN_SUCCESS, (state, action) => {
                state.complaintStatusDropdownOptions = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_STATUS_DROPDOWN_FAILURE, (state) => {
                state.complaintStatusDropdownOptions.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_COMPLAINT_TYPE_SUCCESS, (state, action) => {
                state.complaintTypeDropdown = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_TYPE_FAILURE, (state) => {
                state.complaintTypeDropdown.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_COMPLAINT_MODE_SUCCESS, (state, action) => {
                state.complaintsModeDropdown = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_MODE_FAILURE, (state) => {
                state.complaintsModeDropdown.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REQUEST, (state) => {
                state.complaintCategoryDropdown.requestInProgress = true;
            }).addCase(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_SUCCESS, (state, action) => {

                state.complaintCategoryDropdown = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            }).addCase(ACTION_TYPES.FETCH_DISTRICT_LIST_REQUEST, (state) => {
                state.districtsDropdown.requestInProgress = true;
            }).addCase(ACTION_TYPES.FETCH_DISTRICT_LIST_SUCCESS, (state, action) => {

                state.districtsDropdown = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            }).addCase(ACTION_TYPES.FETCH_WARD_LIST_REQUEST, (state) => {
                state.wardDropdowns.requestInProgress = true;
            }).addCase(ACTION_TYPES.FETCH_WARD_LIST_SUCCESS, (state, action) => {

                state.wardDropdowns = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            }).addCase(ACTION_TYPES.FETCH_LSGI_REQUEST, (state) => {
                state.lsgiDropdowns.requestInProgress = true;
            }).addCase(ACTION_TYPES.FETCH_LSGI_SUCCESS, (state, action) => {

                state.lsgiDropdowns = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            }).addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_REQUEST, (state) => {
                state.lsgiTypeDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_SUCCESS, (state, action) => {
                state.lsgiTypeDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_FAILURE, (state) => {
                state.lsgiTypeDropdownOption.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.SELECT_PRIORITY_DROPDOWN_REQUEST, (state) => {
                state.priorityDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_PRIORITY_DROPDOWN_SUCCESS, (state, action) => {
                state.priorityDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.SELECT_PRIORITY_DROPDOWN_FAILURE, (state) => {
                state.priorityDropdownOption.requestInProgress = false;
                state.error = "Something went wrong";
            });
    }
});

export const { actions, reducer } = slice;
