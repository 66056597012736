import _ from "lodash";

export const STATE_REDUCER_KEY = "grievance/complaints";

export const PRIORITY_TYPE = [
    { id: 1, name: "LOW" },
    { id: 2, name: "MEDIUM" },
    { id: 3, name: "HIGH" }];

export const COMPLAINT_STATUS_LABEL = {
    NEW: "NEW",
    RESOLVED: "RESOLVED",
    CLOSED: "CLOSED",
    NOT_ASSIGNED: "NOT ASSIGNED",
    REASSIGNED: "REASSIGNED",
    REJECTED: "REJECTED",
    IN_PROGRESS: "IN PROGRESS",
    WITHDRAWN: "WITHDRAWN",
    RESOLVE_REQUESTED: "RESOLVE REQUEST",
    REJECT_REQUESTED: "REJECT REQUEST",
    REOPENED: "REOPENED",
    ESCALATED: "ESCALATED",
    PENDING: "PENDING"
};

export const COMPLAINT_STATUS_TYPE = {
    NEW: { id: 1000, name: COMPLAINT_STATUS_LABEL.NEW },
    RESOLVED: { id: 1001, name: COMPLAINT_STATUS_LABEL.RESOLVED },
    CLOSED: { id: 1002, name: COMPLAINT_STATUS_LABEL.CLOSED },
    NOT_ASSIGNED: { id: 1003, name: COMPLAINT_STATUS_LABEL.NOT_ASSIGNED },
    REASSIGNED: { id: 1004, name: COMPLAINT_STATUS_LABEL.REASSIGNED },
    REJECTED: { id: 1005, name: COMPLAINT_STATUS_LABEL.REJECTED },
    IN_PROGRESS: { id: 1006, name: COMPLAINT_STATUS_LABEL.IN_PROGRESS },
    REJECT_REQUESTED: { id: 1007, name: COMPLAINT_STATUS_LABEL.REJECT_REQUESTED },
    REOPENED: { id: 1008, name: COMPLAINT_STATUS_LABEL.REOPENED },
    WITHDRAWN: { id: 1009, name: COMPLAINT_STATUS_LABEL.WITHDRAWN },
    RESOLVE_REQUESTED: { id: 1010, name: COMPLAINT_STATUS_LABEL.RESOLVE_REQUESTED },
    PENDING: { id: 1011, name: COMPLAINT_STATUS_LABEL.PENDING },
    ESCALATED: { id: 1012, name: COMPLAINT_STATUS_LABEL.ESCALATED }
};

export const COMPLAINT_STATUS = _.toArray(COMPLAINT_STATUS_TYPE);

export const MY_APPROVAL_TYPES = {
    PENDING_LIST: "pending",
    REJECTED_LIST: "rejected",
    APPROVED_LIST: "approved"

};

export const MY_COMPLAINTS_TYPES = {
    CREATED_BY_ME: "createdByMe",
    ASSIGNED_TO_ME: "assignedToMe",
    UNASSIGNED: "unassigned",
    ALL: "all"
};

export const acceptedFileTypes = ["image/x-png", "image/png", "image/jpg", "image/jpeg", "image/gif"];

export const APPROVALS_TABLES = {
    MY_APPROVALS_PENDING_LIST: "MY_APPROVALS_PENDING_LIST",
    MY_APPROVALS_REJECTED_LIST: "MY_APPROVALS_REJECTED_LIST",
    MY_APPROVALS_APPROVED_LIST: "MY_APPROVALS_APPROVED_LIST"
};
