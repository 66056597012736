import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_WSS_INFO_LIST: `${STATE_REDUCER_KEY}/FETCH_WSS_INFO_LIST`,
    FETCH_WSS_INFO_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WSS_INFO_LIST_REQUEST`,
    FETCH_WSS_INFO_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WSS_INFO_LIST_SUCCESS`,
    FETCH_WSS_INFO_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WSS_INFO_LIST_FAILURE`,

    FETCH_WSS_INFO_BY_ID: `${STATE_REDUCER_KEY}/FETCH_WSS_INFO_BY_ID`,
    FETCH_WSS_INFO_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WSS_INFO_BY_ID_REQUEST`,
    FETCH_WSS_INFO_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WSS_INFO_BY_ID_SUCCESS`,
    FETCH_WSS_INFO_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WSS_INFO_BY_ID_FAILURE`,

    POST_WSS_INFO: `${STATE_REDUCER_KEY}/POST_WSS_INFO`,
    POST_WSS_INFO_REQUEST: `${STATE_REDUCER_KEY}/POST_WSS_INFO_REQUEST`,
    POST_WSS_INFO_SUCCESS: `${STATE_REDUCER_KEY}/POST_WSS_INFO_SUCCESS`,
    POST_WSS_INFO_FAILURE: `${STATE_REDUCER_KEY}/POST_WSS_INFO_FAILURE`,

    PATCH_WSS_INFO: `${STATE_REDUCER_KEY}/PATCH_WSS_INFO`,
    PATCH_WSS_INFO_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WSS_INFO_REQUEST`,
    PATCH_WSS_INFO_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WSS_INFO_SUCCESS`,
    PATCH_WSS_INFO_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WSS_INFO_FAILURE`,

    DELETE_WSS_INFO: `${STATE_REDUCER_KEY}/DELETE_WSS_INFO`,
    DELETE_WSS_INFO_REQUEST: `${STATE_REDUCER_KEY}/DELETE_WSS_INFO_REQUEST`,
    DELETE_WSS_INFO_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_WSS_INFO_SUCCESS`,
    DELETE_WSS_INFO_FAILURE: `${STATE_REDUCER_KEY}/DELETE_WSS_INFO_FAILURE`,

    FETCH_WARD_NAME_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_WARD_NAME_DROPDOWN`,
    FETCH_WARD_NAME_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_NAME_DROPDOWN_REQUEST`,
    FETCH_WARD_NAME_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_NAME_DROPDOWN_SUCCESS`,
    FETCH_WARD_NAME_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_NAME_DROPDOWN_FAILURE`,

    DOWNLOAD_WSS_INFO: `${STATE_REDUCER_KEY}/DOWNLOAD_WSS_INFO`,
    DOWNLOAD_WSS_INFO_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_WSS_INFO_REQUEST`,
    DOWNLOAD_WSS_INFO_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_WSS_INFO_SUCCESS`,
    DOWNLOAD_WSS_INFO_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_WSS_INFO_FAILURE`
};

export const fetchWssInfoList = createAction(ACTION_TYPES.FETCH_WSS_INFO_LIST);
export const fetchWssInfoById = createAction(ACTION_TYPES.FETCH_WSS_INFO_BY_ID);
export const postWssInfo = createAction(ACTION_TYPES.POST_WSS_INFO);
export const patchWssInfo = createAction(ACTION_TYPES.PATCH_WSS_INFO);
export const deleteWssInfo = createAction(ACTION_TYPES.DELETE_WSS_INFO);
export const fetchWardNameDropdown = createAction(ACTION_TYPES.FETCH_WARD_NAME_DROPDOWN);
export const downloadWssInfo = createAction(ACTION_TYPES.DOWNLOAD_WSS_INFO);
