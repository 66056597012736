import { ACTION_TYPES } from "./actions";
import { handleAPIRequest } from "../../../utils/http";
import { all, call, fork, put, take, takeLatest, select } from "redux-saga/effects";
import { actions as commonActions } from "modules/common/slice";
import { I18n } from "common/components";
import { successNotify } from "utils/notificationUtils";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "../../paths";
import { TRAINING_PATH as PATH } from "../paths";
import * as api from "./api";
import { setCommonTableData, getPaginationPayload } from "utils/tableUtils";
import { TABLE_IDS } from "common/constants";
import { getTableProps } from "modules/common/selectors";

export function* saveCertificate({ payload }) {

    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, api.editCertificateAPI, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_CERTIFICATE_SUCCESS, ACTION_TYPES.EDIT_CERTIFICATE_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_CERTIFICATE_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("training_certificate") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.CERTIFICATE.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, api.saveCertificateAPI, payload);
        const responseAction = yield take([ACTION_TYPES.SAVE_CERTIFICATE_DETAILS_SUCCESS, ACTION_TYPES.SAVE_CERTIFICATE_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SAVE_CERTIFICATE_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("saved", { type: I18n("training_certificate") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.CERTIFICATE.LIST}`));
        }

    }
}

export function* listTrainingCertificate({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listTrainingCertificateApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_CERTIFICATE_DETAILS_SUCCESS, ACTION_TYPES.LIST_CERTIFICATE_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_CERTIFICATE);
}

export function* fetchBatch(action) {
    yield call(handleAPIRequest, api.fetchBatchApi, action.payload);
}
export function* fetchDesignation(action) {
    yield call(handleAPIRequest, api.fetchDesignationApi, action.payload);
}

export function* fetchVenue(action) {
    yield call(handleAPIRequest, api.fetchVenueApi, action.payload);
}
export function* fetchTemplate(action) {
    yield call(handleAPIRequest, api.fetchTemplateApi, action.payload);
}

export function* deleteCertificateById({ payload: id }) {
    yield fork(handleAPIRequest, api.deleteCertificateByIdAPI, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_CERTIFICATE_SUCCESS, ACTION_TYPES.DELETE_CERTIFICATE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_CERTIFICATE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("schedule_row") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listTrainingCertificate, { payload: tablePayload });
    }
}
export function* editCertificateById({ payload }) {
    yield call(handleAPIRequest, api.editCertificateByIdApi, payload);
}

export function* lsgiDropdown(action) {
    yield call(handleAPIRequest, api.lsgiDropdownApi, action.payload);
}

export default function* certificateSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SAVE_CERTIFICATE_DETAILS, saveCertificate),
        takeLatest(ACTION_TYPES.LIST_CERTIFICATE_DETAILS, listTrainingCertificate),
        takeLatest(ACTION_TYPES.FETCH_BATCH, fetchBatch),
        takeLatest(ACTION_TYPES.FETCH_DESIGNATION, fetchDesignation),
        takeLatest(ACTION_TYPES.FETCH_VENUE, fetchVenue),
        takeLatest(ACTION_TYPES.FETCH_TEMPLATE, fetchTemplate),
        takeLatest(ACTION_TYPES.DELETE_CERTIFICATE, deleteCertificateById),
        takeLatest(ACTION_TYPES.EDIT_CERTIFICATE_BY_ID, editCertificateById),
        takeLatest(ACTION_TYPES.FETCH_LSGI_DROPDOWN, lsgiDropdown)
    ]);
}
