import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";

const initialState = {
    roleResources: {
        requestInProgress: false,
        data: []
    },
    roleDetails: {
        requestInProgress: false,
        data:
        {
            name: "",
            description: "",
            roleLevelId: ""
        }
    },
    roleLevelDropdown: {
        requestInProgress: false,
        data: []
    },
    filterRoleName: {
        data: [],
        requestInProgress: false
    },
    nameList: {
        requestInProgress: false,
        data: []
    },
    checkedRoleResources: {
        requestInProgress: false,
        data: []
    }

};
const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.roleDetails = initialState.roleDetails;
            state.filterRoleName = initialState.filterRoleName;
            state.roleLevelDropdown = initialState.roleLevelDropdown;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_ROLE_BY_ID_SUCCESS, (state, { payload }) => {
            _.set(state, "roleDetails.requestInProgress", false);
            _.set(state, "roleDetails.data", payload.content[0]);
        });
        builder.addCase(ACTION_TYPES.FETCH_ROLE_LEVEL_SUCCESS, (state, { payload }) => {
            _.set(state, "roleLevelDropdown.requestInProgress", false);
            _.set(state, "roleLevelDropdown.data", payload.content);
        }).addCase(ACTION_TYPES.FETCH_ROLE_RESOURCES_REQUEST, (state) => {
            state.roleResources.requestInProgress = true;
        }).addCase(ACTION_TYPES.FETCH_ROLE_RESOURCES_SUCCESS, (state, { payload }) => {
            _.set(state, "roleResources.requestInProgress", false);
            _.set(state, "roleResources.data", payload);
        }).addCase(ACTION_TYPES.FETCH_ROLE_RESOURCES_FAILURE, (state) => {
            state.roleResources.requestInProgress = false;
        }).addCase(ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "nameList.requestInProgress", false);
            _.set(state, "nameList.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FILTER_ROLE_NAME_SUCCESS, (state, { payload }) => {
            _.set(state, "filterRoleName.requestInProgress", false);
            _.set(state, "filterRoleName.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.CHECKED_ITEM_ROLE_TREE_SUCCESS, (state, { payload }) => {
            _.set(state, "checkedRoleResources.requestInProgress", false);
            _.set(state, "checkedRoleResources.data", payload.content);
        });
    }
});
export const { reducer, actions } = slice;
