import { createSlice } from "@reduxjs/toolkit";
import { getPayloadContentDetails, getPayloadContent, getPayloadData } from "utils/apiUtils";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import _ from "lodash";


const initialState = {
    vendorCategoryDetails: {
        requestInProgress: false,
        data: {
            name: "",
            description: ""
        }
    },
    vendorContactDetails: {
        requestInProgress: false,
        data: {
            name: "",
            description: "",
            gstIn: "",
            categoryReferences: "",
            address: "",
            contactList: []
        }
    },
    vendorContactFilter: {
        requestInProgress: false,
        data: {
            name: "",
            gstIn: "",
            categoryId: ""
        }
    },
    vendorCategoryFilter: {
        data: {
            name: ""
        }
    },
    contactTypeIdDropdown: {
        data: [],
        requestInProgress: false
    },
    categoryDropdownOptions: {
        data: [],
        requestInProgress: false
    },
    lookupCategoryIdDropdown: {
        data: [],
        requestInProgress: false
    },
    getContactNameDropdown: {
        data: [],
        requestInProgress: false
    },
    getContactGstInDropdown: {
        data: [],
        requestInProgress: false
    },
    filters: {
        name: []
    },
    filterCategoryName: {
        data: [],
        requestInProgress: false
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.vendorContactDetails = initialState.vendorContactDetails;
            state.vendorCategoryDetails = initialState.vendorCategoryDetails;
            state.vendorContactFilter = initialState.vendorContactFilter;
            state.vendorCategoryFilter = initialState.vendorCategoryFilter;
            state.getContactNameDropdown = initialState.getContactNameDropdown;
            state.getContactGstInDropdown = initialState.getContactGstInDropdown;
            state.filterCategoryName = initialState.filterCategoryName;
            state.categoryDropdownOptions = initialState.categoryDropdownOptions;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_USER_CONTACT_TYPE_REQUEST, (state) => {
                state.contactTypeIdDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_USER_CONTACT_TYPE_SUCCESS, (state, action) => {
                state.contactTypeIdDropdown = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_USER_CONTACT_TYPE_FAILURE, (state) => {
                state.contactTypeIdDropdown.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_REQUEST, (state) => {
                state.lookupCategoryIdDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_SUCCESS, (state, action) => {
                state.lookupCategoryIdDropdown = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_BY_ID_REQUEST, (state) => {
                state.vendorContactDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_BY_ID_SUCCESS, (state, action) => {
                state.vendorContactDetails = {
                    requestInProgress: false,
                    data: getPayloadData(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_BY_ID_FAILURE, (state) => {
                state.vendorContactDetails.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_REQUEST, (state) => {
                state.vendorCategoryDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_SUCCESS, (state, action) => {
                state.vendorCategoryDetails = {
                    requestInProgress: false,
                    data: getPayloadContentDetails(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_FAILURE, (state) => {
                state.vendorCategoryDetails.requestInProgress = false;
                state.error = "Something went wrong";
            })
            .addCase(ACTION_TYPES.LIST_VENDOR_CONTACT_NAME_REQUEST, (state) => {
                state.getContactNameDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.LIST_VENDOR_CONTACT_NAME_SUCCESS, (state, action) => {
                state.getContactNameDropdown = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.LIST_VENDOR_CONTACT_NAME_FAILURE, (state) => {
                state.getContactNameDropdown.requestInProgress = false;
                state.error = "Something went wrong";
            })

            .addCase(ACTION_TYPES.LIST_VENDOR_CONTACT_GST_SUCCESS, (state, { payload }) => {
                _.set(state, "filterCategoryName.requestInProgress", false);
                _.set(state, "filterCategoryName.data", payload.content);
            });

        builder.addCase(ACTION_TYPES.FILTER_VENDOR_CATEGORY_NAME_SUCCESS, (state, { payload }) => {
            _.set(state, "filterCategoryName.requestInProgress", false);
            _.set(state, "filterCategoryName.data", payload.content);
        })

            .addCase(ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS, (state, action) => {
                _.set(state, "filters.name", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_FAILURE, (state) => {
                state.lookupCategoryIdDropdown.requestInProgress = false;
                state.error = "Something went wrong";
            }).addCase(ACTION_TYPES.FETCH_CATEGORY_REQUEST, (state) => {
                state.categoryDropdownOptions.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_CATEGORY_SUCCESS, (state, action) => {
                state.categoryDropdownOptions = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_CATEGORY_FAILURE, (state) => {
                state.categoryDropdownOptions.requestInProgress = false;
                state.error = "Something went wrong";
            });
    }
});

export const { actions, reducer } = slice;
