import { I18n } from "common/components";
import { TABLE_IDS } from "common/constants";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { getTableProps } from "modules/common/selectors";
import { MODULE_PATH } from "modules/paths";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { API_URL } from "../apiUrls";

export function* fetchSecondaryTransportation({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.SECONDARY_TRANSPORTATION]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchSecondaryTransportationApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS, ACTION_TYPES.FETCH_SECONDARY_TRANSPORTATION_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.SECONDARY_TRANSPORTATION);
}

export function* modeOfLoadingDropDown({ payload }) {
    yield call(handleAPIRequest, Api.modeOfLoadingDropDownApi, payload);
}

export function* secondaryTransportation({ payload }) {
    yield call(handleAPIRequest, Api.secondaryTransportationApi, payload);
}

export function* submitSecondaryTransportation(action) {
    let { payload: { id } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.patchSecondaryTransportationApi, action?.payload);
        const responseAction = yield take([ACTION_TYPES.PATCH_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS, ACTION_TYPES.PATCH_SECONDARY_TRANSPORTATION_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("secondary_transportation") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.MASTER_INFO}/${PATH.SECONDARY_TRANSPORTATION.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, Api.postSecondaryTransportationApi, action?.payload);
        const responseAction = yield take([ACTION_TYPES.POST_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS, ACTION_TYPES.POST_SECONDARY_TRANSPORTATION_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("secondary_transportation") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.MASTER_INFO}/${PATH.SECONDARY_TRANSPORTATION.LIST}`));
        }
    }
}

export function* deleteSecondaryTransportation({ payload }) {
    yield fork(handleAPIRequest, Api.deleteSecondaryTransportationApi, payload);
    const action = yield take([ACTION_TYPES.DELETE_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS, ACTION_TYPES.DELETE_SECONDARY_TRANSPORTATION_DETAILS_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("secondary_transportation") }) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.SECONDARY_TRANSPORTATION]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchSecondaryTransportationApi, { pageNo: 0, pageSize: 5, ...filters, ...payload });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS, ACTION_TYPES.FETCH_SECONDARY_TRANSPORTATION_DETAILS_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.SECONDARY_TRANSPORTATION);
    }
}

export function* downloadSecondaryTransportation(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.SECONDARY_TRANSPORTATION.DOWNLOAD,
        data: { type },
        file: { name: fileName, ext: type }
    }));
}

export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_SECONDARY_TRANSPORTATION_DETAILS, fetchSecondaryTransportation),
        takeLatest(ACTION_TYPES.MODE_OF_LOADING_DROPDOWN, modeOfLoadingDropDown),
        takeLatest(ACTION_TYPES.GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID, secondaryTransportation),
        takeLatest(ACTION_TYPES.POST_SECONDARY_TRANSPORTATION_DETAILS, submitSecondaryTransportation),
        takeLatest(ACTION_TYPES.PATCH_SECONDARY_TRANSPORTATION_DETAILS, submitSecondaryTransportation),
        takeLatest(ACTION_TYPES.DELETE_SECONDARY_TRANSPORTATION_DETAILS, deleteSecondaryTransportation),
        takeLatest(ACTION_TYPES.DOWNLOAD_SECONDARY_TRANSPORTATION_DETAILS, downloadSecondaryTransportation)
    ]);
}
