const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./actions");
import _ from "lodash";

const initialState = {
    cityLevelSiteName: {
        requestInProgress: false,
        data: []
    },
    cityLevelCellDetails: {
        requestInProgress: false,
        data: {
            district: "",
            lsgi: "",
            cityLevelSite: "",
            wasteDisposed: "",
            wasteDispatched: "",
            date: ""
        }
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.CITY_LEVEL_SITE_DROPDOWN_REQUEST, (state) => {
                state.cityLevelSiteName.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.CITY_LEVEL_SITE_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "cityLevelSiteName.requestInProgress", false);
                _.set(state, "cityLevelSiteName.data", action.payload.content);
            })
            .addCase(ACTION_TYPES.CITY_LEVEL_SITE_DROPDOWN_FAILURE, (state) => {
                state.cityLevelSiteName.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_REQUEST, (state) => {
                state.cityLevelCellDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "cityLevelCellDetails.requestInProgress", false);
                _.set(state, "cityLevelCellDetails.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_FAILURE, (state) => {
                state.cityLevelCellDetails.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;

