import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    trainingStatusReport: {
        requestInProgress: false,
        data: {
            TOTAL: 0,
            NEW: 0,
            SCHEDULED: 0,
            COMPLETED: 0
        }
    }

};

const slice = createSlice({
    initialState: initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_BATCH_REPORT_REQUEST, (state) => {
            state.trainingStatusReport.requestInProgress = true;
        }).addCase(ACTION_TYPES.FETCH_BATCH_REPORT_FAILURE, (state) => {
            state.trainingStatusReport.requestInProgress = false;
        }).addCase(ACTION_TYPES.FETCH_BATCH_REPORT_SUCCESS, (state, { payload }) => {
            _.set(state, "trainingStatusReport.requestInProgress", false);
            _.set(state, "trainingStatusReport.data", payload);
        });

    }
});

export const { actions, reducer } = slice;
