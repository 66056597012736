import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { ACTION_TYPES } from "./actions";
import { addDistrict, codeList, deleteDistrict, editDistrict, fetchDistricts, fetchStates, nameList } from "./api";
import { actions as commonActions } from "../../common/slice";
import { handleAPIRequest } from "../../../utils/http";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { getTableProps } from "modules/common/selectors";

export function* fetchStateDetails() {
  yield call(handleAPIRequest, fetchStates);
}
export function* fetchDistrictDetails({ payload }) {
  let tableProps = yield select(getTableProps);
  let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
  yield fork(handleAPIRequest, fetchDistricts, { ...filters, ...payload });
  const COMPARISON_TYPES = [ACTION_TYPES.FETCH_DISTRICT_DETAILS_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_DETAILS_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}
export function* addDistrictDetails(action) {
  let payload = action.payload;
  yield call(handleAPIRequest, addDistrict, { payload });
}
export function* editDistrictDetails(action) {
  let payload = action.payload;
  yield call(handleAPIRequest, editDistrict, { payload });
}
export function* deleteDistrictDetails({ payload }) {
  const { id } = payload;
  yield call(handleAPIRequest, deleteDistrict, { id });
  const responseAction = yield take([ACTION_TYPES.DELETE_DISTRICT_DETAILS_SUCCESS, ACTION_TYPES.DELETE_DISTRICT_DETAILS_FAILURE]);
  if (responseAction.type === ACTION_TYPES.DELETE_DISTRICT_DETAILS_SUCCESS) {
    yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
    const tablePayload = yield* getPaginationPayload();
    yield call(fetchDistricts, { payload: tablePayload });
  }
} export function* fetchNameList({ payload }) {
  yield call(handleAPIRequest, nameList, payload);
}

export function* fetchCodeList({ payload }) {
  yield call(handleAPIRequest, codeList, payload);
}
export default function* sampleSaga() {
  yield all([
    takeLatest(ACTION_TYPES.FETCH_DISTRICT_STATE_DETAILS, fetchStateDetails),
    takeLatest(ACTION_TYPES.FETCH_DISTRICT_DETAILS, fetchDistrictDetails),
    takeLatest(ACTION_TYPES.ADD_DISTRICT_DETAILS, addDistrictDetails),
    takeLatest(ACTION_TYPES.EDIT_DISTRICT_DETAILS, editDistrictDetails),
    takeLatest(ACTION_TYPES.DELETE_DISTRICT_DETAILS, deleteDistrictDetails),
    takeLatest(ACTION_TYPES.FETCH_NAME_LIST, fetchNameList),
    takeLatest(ACTION_TYPES.FETCH_CODE_LIST, fetchCodeList)
  ]);
}
