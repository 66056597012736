export const STATE_REDUCER_KEY = "dprTracker";

export const PLAN_TYPE = {
    SUB_PROJECT_MAPPING: "SUB_PROJECT_MAPPING",
    DECISIONS: "DECISIONS",
    MC_APPROVAL: "MC_APPROVAL"
};

export const dprYearList = [
    { id: 2024, name: "2024" },
    { id: 2025, name: "2025" },
    { id: 2026, name: "2026" },
    { id: 2027, name: "2027" },
    { id: 2028, name: "2028" },
    { id: 2029, name: "2029" },
    { id: 2030, name: "2030" }
];

export const actionBy = [
    {
        id: 1015,
        name: "TSC"
    },
    {
        id: 1009,
        name: "PIU"
    }
];
