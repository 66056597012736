import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const rejectSpmuApi = (data) => {
    return {
        url: API_URL.APPROVE_SPMU.REJECT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.REJECT_SPMU_REQUEST, ACTION_TYPES.REJECT_SPMU_SUCCESS, ACTION_TYPES.REJECT_SPMU_FAILURE],
            data
        }
    };
};

export const approveSpmuApi = (data) => {
    return {
        url: API_URL.APPROVE_SPMU.APPROVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.APPROVE_SPMU_REQUEST, ACTION_TYPES.APPROVE_SPMU_SUCCESS, ACTION_TYPES.APPROVE_SPMU_FAILURE],
            data
        }
    };
};

export const approveSpmuByIdApi = (data) => {
    return {
        url: API_URL.APPROVE_DPMC.GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.APPROVE_SPMU_BY_ID_REQUEST, ACTION_TYPES.APPROVE_SPMU_BY_ID_SUCCESS, ACTION_TYPES.APPROVE_SPMU_BY_ID_FAILURE],
            data
        }
    };
};

export const fetchPreviousSpmuApi = (data) => {
    return {
        url: API_URL.APPROVE_DPMC.GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PREVIOUS_SPMU_REQUEST, ACTION_TYPES.FETCH_PREVIOUS_SPMU_SUCCESS, ACTION_TYPES.FETCH_PREVIOUS_SPMU_FAILURE],
            data
        }
    };
};
