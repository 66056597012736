import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const listTrainingParticipantsApi = (data) => ({
    url: API_URL.TRAINING_PARTICIPANTS.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_PARTICIPANTS_LIST_REQUEST, ACTION_TYPES.TRAINING_PARTICIPANTS_LIST_SUCCESS, ACTION_TYPES.TRAINING_PARTICIPANTS_LIST_FAILURE],
        data
    }
});

export const listTrainingParticipantsAnalysisApi = (data) => ({
    url: API_URL.TRAINING_PARTICIPANTS_ANALYSIS.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_PARTICIPANTS_ANALYSIS_LIST_REQUEST, ACTION_TYPES.TRAINING_PARTICIPANTS_ANALYSIS_LIST_SUCCESS, ACTION_TYPES.TRAINING_PARTICIPANTS_ANALYSIS_LIST_FAILURE],
        data
    }
});

export const listTrainingParticipantsDownloadApi = (data) => ({
    url: API_URL.TRAINING_PARTICIPANTS.DOWNLOAD,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_PARTICIPANTS_LIST_DOWNLOAD_REQUEST, ACTION_TYPES.TRAINING_PARTICIPANTS_LIST_DOWNLOAD_SUCCESS, ACTION_TYPES.TRAINING_PARTICIPANTS_LIST_DOWNLOAD_FAILURE],
        data
    }
});

export const listTrainingScheduleReportApi = (data) => ({
    url: API_URL.TRAINING_SCHEDULE_REPORT.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_SCHEDULE_LIST_REQUEST, ACTION_TYPES.TRAINING_SCHEDULE_LIST_SUCCESS, ACTION_TYPES.TRAINING_SCHEDULE_LIST_FAILURE],
        data
    }
});

export const listBatchNameTrainingFilterApi = (data) => ({
    url: API_URL.TRAINING_SCHEDULE_REPORT.TRAINING_LIST_FILTER,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_FILTER_BATCH_NAME_LIST_REQUEST, ACTION_TYPES.TRAINING_FILTER_BATCH_NAME_LIST_SUCCESS, ACTION_TYPES.TRAINING_FILTER_BATCH_NAME_LIST_FAILURE],
        data
    }
});

export const listTrainingFilterApi = (data) => ({
    url: API_URL.TRAINING_SCHEDULE_REPORT.TRAINING_LIST_FILTER,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_FILTER_TRAINING_NAME_LIST_REQUEST, ACTION_TYPES.TRAINING_FILTER_TRAINING_NAME_LIST_SUCCESS, ACTION_TYPES.TRAINING_FILTER_TRAINING_NAME_LIST_FAILURE],
        data
    }
});

export const listParticipantsBatchNameTrainingFilterApi = (data) => ({
    url: API_URL.TRAINING_PARTICIPANTS.TRAINING_LIST_FILTER,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST_REQUEST, ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST_SUCCESS, ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_BATCH_NAME_LIST_FAILURE],
        data
    }
});

export const listParticipantsTrainingFilterApi = (data) => ({
    url: API_URL.TRAINING_PARTICIPANTS.TRAINING_LIST_FILTER,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST_REQUEST, ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST_SUCCESS, ACTION_TYPES.TRAINING_PARTICIPANTS_FILTER_TRAINING_NAME_LIST_FAILURE],
        data
    }
});

export const listTrainingConductedReportApi = (data) => ({
    url: API_URL.TRAINING_CONDUCTED_REPORT.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_CONDUCTED_LIST_REQUEST, ACTION_TYPES.TRAINING_CONDUCTED_LIST_SUCCESS, ACTION_TYPES.TRAINING_CONDUCTED_LIST_FAILURE],
        data
    }
});

export const listConductedBatchNameTrainingFilterApi = (data) => ({
    url: API_URL.TRAINING_CONDUCTED_REPORT.TRAINING_LIST_FILTER,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST_REQUEST, ACTION_TYPES.TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST_SUCCESS, ACTION_TYPES.TRAINING_CONDUCTED_FILTER_BATCH_NAME_LIST_FAILURE],
        data
    }
});

export const listConductedTrainingModeFilterApi = (data) => ({
    url: API_URL.TRAINING_CONDUCTED_REPORT.TRAINING_LIST_FILTER,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST_REQUEST, ACTION_TYPES.TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST_SUCCESS, ACTION_TYPES.TRAINING_CONDUCTED_FILTER_TRAINING_MODE_LIST_FAILURE],
        data
    }
});

export const listTrainingSummaryReportApi = (data) => ({
    url: API_URL.TRAINING_SUMMARY_REPORT.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_SUMMARY_LIST_REQUEST, ACTION_TYPES.TRAINING_SUMMARY_LIST_SUCCESS, ACTION_TYPES.TRAINING_SUMMARY_LIST_FAILURE],
        data
    }
});


export const listTrainingNameFilterApiForSummary = (data) => ({
    url: API_URL.TRAINING_SUMMARY_REPORT.TRAINING_LIST_FILTER,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST_REQUEST, ACTION_TYPES.TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST_SUCCESS, ACTION_TYPES.TRAINING_SUMMARY_TRAINING_NAME_FILTER_LIST_FAILURE],
        data
    }
});
