import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchSubProjectListApi = (data, id) => {
  return {
    url: `${API_URL.SUB_PROJECT.SUB_PROJECT_DRAFT_LIST}?swmPlanId=${id}`,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_SUB_PROJECT_LIST_REQUEST, ACTION_TYPES.FETCH_SUB_PROJECT_LIST_SUCCESS, ACTION_TYPES.FETCH_SUB_PROJECT_LIST_FAILURE],
      data
    }
  };
};

export const fetchSubProjectListByIdApi = (data) => {
  return {
    url: API_URL.SUB_PROJECT.SUB_PROJECT_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_SUB_PROJECT_LIST_BY_ID_REQUEST, ACTION_TYPES.FETCH_SUB_PROJECT_LIST_BY_ID_SUCCESS, ACTION_TYPES.FETCH_SUB_PROJECT_LIST_BY_ID_FAILURE],
      data: { id: data }
    }
  };
};

export const fetchTrackListApi = () => {
  return {
    url: API_URL.SUB_PROJECT.SELECT_TRACK_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_TRACK_LIST_REQUEST, ACTION_TYPES.FETCH_TRACK_LIST_SUCCESS, ACTION_TYPES.FETCH_TRACK_LIST_FAILURE],
      data: { dropDown: true }
    }
  };
};

export const fetchCategoryTypeApi = () => {
  return {
    url: API_URL.SUB_PROJECT.SELECT_CATEGORY_TYPE,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_CATEGORY_TYPES_REQUEST, ACTION_TYPES.FETCH_CATEGORY_TYPES_SUCCESS, ACTION_TYPES.FETCH_CATEGORY_TYPES_FAILURE],
      data: { dropDown: true }
    }
  };
};

export const fetchEnvCategoryTypeApi = () => {
  return {
    url: API_URL.SUB_PROJECT.SELECT_ENV_CATEGORY_TYPE,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_ENV_CATEGORY_TYPES_REQUEST, ACTION_TYPES.FETCH_ENV_CATEGORY_TYPES_SUCCESS, ACTION_TYPES.FETCH_ENV_CATEGORY_TYPES_FAILURE],
      data: { dropDown: true }
    }
  };
};

export const fetchSubCategoryTypeApi = () => {
  return {
    url: API_URL.SUB_PROJECT.SELECT_SUB_CATEGORY_TYPE,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_SUB_CATEGORY_TYPES_REQUEST, ACTION_TYPES.FETCH_SUB_CATEGORY_TYPES_SUCCESS, ACTION_TYPES.FETCH_SUB_CATEGORY_TYPES_FAILURE],
      data: { dropDown: true }
    }
  };
};

export const fetchSubCategoryTwoTypeApi = () => {
  return {
    url: API_URL.SUB_PROJECT.SELECT_SUB_CATEGORY_TWO_TYPE,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_SUB_CATEGORY_TWO_TYPES_REQUEST, ACTION_TYPES.FETCH_SUB_CATEGORY_TWO_TYPES_SUCCESS, ACTION_TYPES.FETCH_SUB_CATEGORY_TWO_TYPES_FAILURE],
      data: { dropDown: true }
    }
  };
};

export const fetchProjectTypeApi = () => {
  return {
    url: API_URL.SUB_PROJECT.SELECT_PROJECT_TYPE,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_PROJECT_TYPES_REQUEST, ACTION_TYPES.FETCH_PROJECT_TYPES_SUCCESS, ACTION_TYPES.FETCH_PROJECT_TYPES_FAILURE],
      data: { dropDown: true }
    }
  };
};

export const fetchProjectApplicableApi = () => {
  return {
    url: API_URL.SUB_PROJECT.SELECT_PROJECT_APPLICABLE,
    method: REQUEST_METHOD.GET,
    payload: {
      types: [ACTION_TYPES.FETCH_PROJECT_APPLICABLE_REQUEST, ACTION_TYPES.FETCH_PROJECT_APPLICABLE_SUCCESS, ACTION_TYPES.FETCH_PROJECT_APPLICABLE_FAILURE],
      data: { dropDown: true }
    }
  };
};

export const postSubProjectApi = (data) => {
  return {
    url: API_URL.SUB_PROJECT.POST_SUB_PROJECT_DRAFT,
    method: REQUEST_METHOD.POST,
    payload: {
      types: [ACTION_TYPES.POST_SUB_PROJECT_REQUEST, ACTION_TYPES.POST_SUB_PROJECT_SUCCESS, ACTION_TYPES.POST_SUB_PROJECT_FAILURE],
      data
    }
  };
};

export const updateSubProjectApi = (data) => {
  return {
    url: API_URL.SUB_PROJECT.POST_SUB_PROJECT,
    method: REQUEST_METHOD.PATCH,
    payload: {
      types: [ACTION_TYPES.UPDATE_SUB_PROJECT_REQUEST, ACTION_TYPES.UPDATE_SUB_PROJECT_SUCCESS, ACTION_TYPES.UPDATE_SUB_PROJECT_FAILURE],
      data
    }
  };
};

export const deleteSubProjectListApi = (id) => {
  return {
    url: API_URL.SUB_PROJECT.DELET_SUB_PROJECT_LIST.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
      types: [ACTION_TYPES.DELETE_SUB_PROJECT_LIST_REQUEST, ACTION_TYPES.DELETE_SUB_PROJECT_LIST_SUCCESS, ACTION_TYPES.DELETE_SUB_PROJECT_LIST_FAILURE]
    }
  };
};
