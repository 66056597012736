import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";


export const fetchRoles = (data) => {
    return {
        url: API_URL.ROLE.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ROLE_DATA_REQUEST, ACTION_TYPES.FETCH_ROLE_DATA_SUCCESS, ACTION_TYPES.FETCH_ROLE_DATA_FAILURE],
            data
        }
    };
};
export const fetchRoleByID = ({ id }) => {
    return {
        url: API_URL.ROLE.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ROLE_BY_ID_REQUEST, ACTION_TYPES.FETCH_ROLE_BY_ID_SUCCESS, ACTION_TYPES.FETCH_ROLE_BY_ID_FAILURE],
            data: { id }
        }
    };
};
export const fetchRoleLevel = () => {
    return {
        url: API_URL.ROLE.LEVELS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ROLE_LEVEL_REQUEST, ACTION_TYPES.FETCH_ROLE_LEVEL_SUCCESS, ACTION_TYPES.FETCH_ROLE_LEVEL_FAILURE]
        }
    };
};
export const addRole = (data) => {
    return {
        url: API_URL.ROLE.CREATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.ADD_ROLE_DATA_REQUEST, ACTION_TYPES.ADD_ROLE_DATA_SUCCESS, ACTION_TYPES.ADD_ROLE_DATA_FAILURE],
            data
        }
    };
};
export const editRole = (data) => {
    return {
        url: API_URL.ROLE.EDIT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_ROLE_DATA_REQUEST, ACTION_TYPES.EDIT_ROLE_DATA_SUCCESS, ACTION_TYPES.EDIT_ROLE_DATA_FAILURE],
            data
        }
    };
};
export const deleteRole = (id) => {
    return {
        url: API_URL.ROLE.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_ROLE_DATA_REQUEST, ACTION_TYPES.DELETE_ROLE_DATA_SUCCESS, ACTION_TYPES.DELETE_ROLE_DATA_FAILURE]
        }
    };
};
export const fetchRoleResource = (data) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.ROLE_RESOURCE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ROLE_RESOURCES_REQUEST, ACTION_TYPES.FETCH_ROLE_RESOURCES_SUCCESS, ACTION_TYPES.FETCH_ROLE_RESOURCES_FAILURE],
            data
        }
    };
};
export const filterRoleName = (data) => {
    return {
        url: API_URL.ROLE.FILTER_ROLE_NAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FILTER_ROLE_NAME_REQUEST, ACTION_TYPES.FILTER_ROLE_NAME_SUCCESS, ACTION_TYPES.FILTER_ROLE_NAME_FAILURE],
            data: data
        }
    };
};


export const nameList = (data) => {
    return {
        url: API_URL.ROLE.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_NAME_LIST_REQUEST,
                ACTION_TYPES.FETCH_NAME_LIST_SUCCESS,
                ACTION_TYPES.FETCH_NAME_LIST_FAILURE
            ],
            data
        }
    };
};

export const createRoleTree = (data) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.ROLES_CREATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.CREATE_ROLE_TREE_REQUEST, ACTION_TYPES.CREATE_ROLE_TREE_SUCCESS, ACTION_TYPES.CREATE_ROLE_TREE_FAILURE],
            data
        }
    };
};
export const updateRoleTree = (data) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.ROLES_CREATE,
        method: REQUEST_METHOD.PUT,
        payload: {
            types: [ACTION_TYPES.UPDATE_ROLE_TREE_REQUEST, ACTION_TYPES.UPDATE_ROLE_TREE_SUCCESS, ACTION_TYPES.UPDATE_ROLE_TREE_FAILURE],
            data
        }
    };
};
export const checkedRoleTreeItems = (id) => {
    return {
        url: `${API_URL.RESOURCE_MANAGEMENT.ROLE_CHECKED}?roleId=${id}&pageSize=1000`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.CHECKED_ITEM_ROLE_TREE_REQUEST, ACTION_TYPES.CHECKED_ITEM_ROLE_TREE_SUCCESS, ACTION_TYPES.CHECKED_ITEM_ROLE_TREE_FAILURE]
        }
    };
};
