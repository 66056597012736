import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { ACTION_TYPES } from "./actions";
import { handleAPIRequest } from "utils/http";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { setCommonTableData } from "utils/tableUtils";
import { actions as commonActions } from "../../common/slice";
import { actions as sliceActons } from "../../common/slice";
import { toEpoch } from "utils/dateUtils";
import { confirmPostPlan, deleteFileById, deletePostPlan, listPostPlan, postPlanById, submitPostPlan, updatePostPlan } from "./api";
import { listByIdSWMPlanApi } from "../swmPlan/api";
import { actions as sliceActions } from "./slice";


const formatData = (rest) => {
    const { totalCount, disabledCount, femaleCount, hksMemberCount, kudumbasreeMembersCount, maleCount, oldAgeCount, otherScrapDealerCount, otherWasteWorkerCount, ragPickersCount, transgenderCount, meetingDate, attachmentIds, remarks, meetingVenue, swmPlanId } = rest;
    const data = { totalCount: Number(totalCount), disabledCount: Number(disabledCount), femaleCount: Number(femaleCount), hksMemberCount: Number(hksMemberCount), kudumbasreeMembersCount: Number(kudumbasreeMembersCount), maleCount: Number(maleCount), swmPlanId: Number(swmPlanId), oldAgeCount: Number(oldAgeCount), otherScrapDealerCount: Number(otherScrapDealerCount), otherWasteWorkerCount: Number(otherWasteWorkerCount), ragPickersCount: Number(ragPickersCount), transgenderCount: Number(transgenderCount), meetingDate: toEpoch(meetingDate), attachmentIds, remarks, prePlan: false, meetingVenue };
    return data;
};

const formatEditData = (payload) => {
    const { totalCount, disabledCount, femaleCount, hksMemberCount, kudumbasreeMembersCount, maleCount, oldAgeCount, otherScrapDealerCount, otherWasteWorkerCount, ragPickersCount, transgenderCount, meetingDate, attachmentIds, remarks, meetingVenue, id, swmPlanId } = payload;
    const data = { totalCount: Number(totalCount), disabledCount: Number(disabledCount), femaleCount: Number(femaleCount), hksMemberCount: Number(hksMemberCount), kudumbasreeMembersCount: Number(kudumbasreeMembersCount), maleCount: Number(maleCount), id: Number(id), oldAgeCount: Number(oldAgeCount), otherScrapDealerCount: Number(otherScrapDealerCount), otherWasteWorkerCount: Number(otherWasteWorkerCount), ragPickersCount: Number(ragPickersCount), transgenderCount: Number(transgenderCount), swmPlanId: Number(swmPlanId), meetingDate: toEpoch(meetingDate), attachmentIds, remarks, prePlan: true, meetingVenue };
    return data;
};

export function* submitPostPlanConsultation(action) {
    let { payload: { ...rest } = {} } = action;
    yield put(sliceActions.setPostPlanById(formatData(rest)));
    yield fork(handleAPIRequest, submitPostPlan, formatData(rest));
    const responseAction = yield take([ACTION_TYPES.SUBMIT_POST_PLAN_CONSULTATION_SUCCESS, ACTION_TYPES.SUBMIT_POST_PLAN_CONSULTATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_POST_PLAN_CONSULTATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("post_plan_consultation") }) }));
        yield put(sliceActions.resetPostPlanById());
    }
}

export function* updatePostPlanConsultation({ payload }) {
    if (payload.attachmentIds?.length === 0) {
        delete payload.attachmentIds;
    }
    yield fork(handleAPIRequest, updatePostPlan, formatEditData(payload));
    const responseAction = yield take([ACTION_TYPES.UPDATE_POST_PLAN_CONSULTATION_SUCCESS, ACTION_TYPES.UPDATE_POST_PLAN_CONSULTATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_POST_PLAN_CONSULTATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("post_plan_consultation") }) }));
        let updatedPayload = {
            pageNo: 0, pageSize: 5, prePlan: false
        };
        yield fork(handleAPIRequest, listPostPlan, updatedPayload, Number(payload.swmPlanId));
        const COMPARISON_TYPES = [ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_SUCCESS, ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES);
    }
}

export function* listPostPlanConsultation({ payload }) {
    let updatedPayload = {
        pageNo: payload.pageNo, pageSize: payload.pageSize, prePlan: false
    };
    yield fork(handleAPIRequest, listPostPlan, updatedPayload, payload.id);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_SUCCESS, ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* deletePostPlanConsultation({ payload }) {
    yield fork(handleAPIRequest, deletePostPlan, payload.id);
    const response = yield take([ACTION_TYPES.DELETE_POST_PLAN_CONSULTATION_SUCCESS, ACTION_TYPES.DELETE_POST_PLAN_CONSULTATION_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_POST_PLAN_CONSULTATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("post_plan_consultation") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let updatedPayload = { pageNo: 0, pageSize: 5 };
        yield fork(handleAPIRequest, listPostPlan, updatedPayload, Number(payload.swmPlanId));
        const COMPARISON_TYPES = [ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_SUCCESS, ACTION_TYPES.LIST_POST_PLAN_CONSULTATION_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES);
    }
}

export function* postPlanConsultationById({ payload: id }) {
    yield call(handleAPIRequest, postPlanById, id);
}

export function* deleteFile({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, deleteFileById, id, fileId);
    const response = yield take([ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_FILE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: "file" }) }));
    }
}

export function* confirmPostPlanConsultation({ payload }) {
    yield fork(handleAPIRequest, confirmPostPlan, payload);
    const response = yield take([ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS, ACTION_TYPES.CONFIRM_FORM_SUBMIT_FAILURE]);
    if (response.type === ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("post_plan_consultation") }) }));
        const { swmPlanId } = payload;
        yield call(handleAPIRequest, listByIdSWMPlanApi, swmPlanId);
        yield put(sliceActons.moveTabUp());
    }
}

export default function* testSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_POST_PLAN_CONSULTATION, submitPostPlanConsultation),
        takeLatest(ACTION_TYPES.UPDATE_POST_PLAN_CONSULTATION, updatePostPlanConsultation),
        takeLatest(ACTION_TYPES.DELETE_POST_PLAN_CONSULTATION, deletePostPlanConsultation),
        takeLatest(ACTION_TYPES.LIST_POST_PLAN_CONSULTATION, listPostPlanConsultation),
        takeLatest(ACTION_TYPES.POST_PLAN_CONSULTATION_BY_ID, postPlanConsultationById),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFile),
        takeLatest(ACTION_TYPES.CONFIRM_FORM_SUBMIT, confirmPostPlanConsultation)
    ]);
}
