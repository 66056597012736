import { Components, FormController, I18n, Icons } from "common/components";
import { TABLE_IDS } from "common/constants";
import { Form, withFormik } from "formik";
import { getSearchFilters } from "modules/common/selectors";
import { actions as commonActions } from "modules/common/slice";
import { actions as sliceActions } from "./slice";
import { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getTablePagination } from "utils/tableUtils";
import _ from "lodash";
import { sortByKeys } from "utils/commonUtils";
import { listTrainingSchedule, trainingBatchNameFilterList, trainingFilterList } from "./actions";
import { fetchLsgiDropdown } from "modules/subProjects/swmPlan/actions";
import { getLsgiSelectDropdownOption } from "modules/subProjects/swmPlan/selector";
import { setDesignation, setGender } from "modules/grievance/user/actions";
import { getBatchNameLists, getNameLists } from "./selector";

const { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, CustomCardHeader } = Components;
const { Search, RestartAlt, Close } = Icons;

const TcmParticipantsFilter = (props) => {
    const { handleSubmit, onClose, setFieldValue, values, FilterName, getDropdownFilterList, getDropdownBatchNameFilterList, batchNameFilter } = props;
    const dispatch = useDispatch();

    const [cancel, setCancel] = useState(true);

    const handleSearch = () => {
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST);
        dispatch(listTrainingSchedule({ pageNo: 0, pageSize }));
        dispatch(commonActions.setSearchFilter(props.values));
        onClose();
    };
    const handleReset = () => {
        dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST, reset: true }));
        setFieldValue("trainingName", "");
        setFieldValue("batchName", "");
        setFieldValue("lsgiId", "");
        dispatch(sliceActions.clearAll());
    };

    const handleName = (val) => {
        let filterName = val?.map(i => i.id);
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST);
        if (filterName?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST, filterKey: "trainingName", clearKey: true }));
            dispatch(listTrainingSchedule({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST, filters: { trainingName: filterName } }));
        }
    };

    const handleBatchName = (val) => {
        let filterName = val?.map(i => i.id);
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST);
        if (filterName?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST, filterKey: "batchName", clearKey: true }));
            dispatch(listTrainingSchedule({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST, filters: { batchName: filterName } }));
        }
    };

    const handleUlb = (val) => {
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST);
        if (val?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST, filterKey: "lsgiId", clearKey: true }));
            dispatch(listTrainingSchedule({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SCHEDULE_REPORT_LIST, filters: { lsgiId: val?.id } }));
        }
    };
    const handleInputChange = (e, key) => {
        if (key === "trainingName") {
            const value = e.trim();
            getDropdownFilterList({ key, value });
        } else {
            const value = e.trim();
            getDropdownBatchNameFilterList({ key, value });
        }
    };

    useEffect(() => {
        dispatch(fetchLsgiDropdown());
        dispatch(setGender());
        dispatch(setDesignation({ pageSize: 200 }));
    }, []);

    useEffect(() => {
        setCancel(_.isEqual(sortByKeys(values), sortByKeys(props.reportFilter)));
    }, [values]);

    return (
        <>
            <Card sx={{ overflow: "visible", maxHeight: "90vh" }}>
                <CardHeader sx={{ borderRadius: "10px 10px 0 0", mb: 2 }} title={<CustomCardHeader title={I18n("filter")} />} />
                <CardContent sx={{ ml: 2, mr: 2 }}>
                    <Form onSubmit={handleSubmit} >
                        <Grid container spacing={1} >
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="select" label={I18n("training_name")} name="trainingName" onChangeFromController={handleName} onInputChange={(e) => handleInputChange(e, "trainingName")} options={FilterName || []} multiple />
                            </Grid >
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="select" label={I18n("batch_name")} name="batchName" onChangeFromController={handleBatchName} onInputChange={(e) => handleInputChange(e, "batchName")} options={batchNameFilter || []} multiple />
                            </Grid >
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control='select' label={I18n("ulb")} name='lsgiId' options={props.ulbList || []} onChangeFromController={handleUlb} />
                            </Grid>
                        </Grid>
                    </Form>
                </CardContent>
                <Divider />
                <CardActions>
                    <Grid item xs={12} container justifyContent="center">
                        <Grid> <Button onClick={() => handleSearch()} startIcon={<Search />} variant="contained">{I18n("search")}</Button> </Grid>
                        <Grid> <Button onClick={() => handleReset()} startIcon={<RestartAlt />} variant="contained">{I18n("reset")}</Button> </Grid>
                        <Grid>{cancel && <Button onClick={() => onClose()} startIcon={<Close />} variant="contained">{I18n("close")}</Button>}</Grid>
                    </Grid>
                </CardActions>
            </Card >
        </>
    );
};
const mapDispatchToProps = (dispatch) => ({
    getDropdownFilterList: ({ key, value }) => {
        if (value.length > 2) {
            dispatch(trainingFilterList({ key, value }));
        }
    },
    getDropdownBatchNameFilterList: ({ key, value }) => {
        if (value.length > 2) {
            dispatch(trainingBatchNameFilterList({ key, value }));
        }
    }
});

const mapStateToProps = createStructuredSelector({
    reportFilter: getSearchFilters,
    ulbList: getLsgiSelectDropdownOption,
    FilterName: getNameLists,
    batchNameFilter: getBatchNameLists

});

const filter = withFormik({
    enableReinitialize: false,
    mapPropsToValues: (props) => {
        return props.reportFilter;
    },
    handleSubmit: (values, { setSubmitting }) => {
        setSubmitting(false);
    },
    displayName: "TcmParticipantsFilter"
})(TcmParticipantsFilter);

export default connect(mapStateToProps, mapDispatchToProps)(filter);
