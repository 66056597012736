import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchFYDropdownDetails = () => {
    return {
        url: API_URL.FETCH_EXPENSES.FY_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_FY_LIST_REQUEST, ACTION_TYPES.FETCH_FY_LIST_SUCCESS, ACTION_TYPES.FETCH_FY_LIST_FAILURE],
            data: { dropdown: true, yearCodeAvailable: true }
        }
    };
};

export const fetchUlbDropdownDetails = () => {
    return {
        url: API_URL.FETCH_EXPENSES.ULB_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ULB_LIST_REQUEST, ACTION_TYPES.FETCH_ULB_LIST_SUCCESS, ACTION_TYPES.FETCH_ULB_LIST_FAILURE],
            data: { dropdown: true }
        }
    };
};

export const FetchExpenses = (data) => {
    return {
        url: API_URL.FETCH_EXPENSES.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EXPENSE_REQUEST, ACTION_TYPES.FETCH_EXPENSE_SUCCESS, ACTION_TYPES.FETCH_EXPENSE_FAILURE],
            data
        }
    };
};
export const FetchExistExpenseList = (data) => {
    const { id, fy } = data;
    return {
        url: API_URL.FETCH_EXPENSES.LIST_EXIST.replace(":id", id).replace(":idFy", fy),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EXIST_VALUES_REQUEST, ACTION_TYPES.FETCH_EXIST_VALUES_SUCCESS, ACTION_TYPES.FETCH_EXIST_VALUES_FAILURE]
        }
    };
};
export const saveExpenseApi = (data) => {


    return {
        url: API_URL.FETCH_EXPENSES.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SAVE_EXPENSE_REQUEST, ACTION_TYPES.SAVE_EXPENSE_SUCCESS, ACTION_TYPES.SAVE_EXPENSE_FAILURE],
            data
        }
    };
};
export const submitExpenseApi = (data) => {
    return {
        url: API_URL.FETCH_EXPENSES.SUBMIT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_EXPENSE_REQUEST, ACTION_TYPES.SUBMIT_EXPENSE_SUCCESS, ACTION_TYPES.SUBMIT_EXPENSE_FAILURE],
            data
        }
    };
};

export const FetchFYList = (data) => {
    return {
        url: API_URL.FINANCIAL_YEAR.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_FY_DETAILS_REQUEST, ACTION_TYPES.FETCH_FY_DETAILS_SUCCESS, ACTION_TYPES.FETCH_FY_DETAILS_FAILURE],
            data
        }
    };
};

export const DeleteFYList = (id) => {
    return {
        url: API_URL.FINANCIAL_YEAR.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_FY_REQUEST, ACTION_TYPES.DELETE_FY_SUCCESS, ACTION_TYPES.DELETE_FY_FAILURE]
        }
    };
};
export const submitFYApi = (data) => {
    return {
        url: API_URL.FINANCIAL_YEAR.SUBMIT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_FY_REQUEST, ACTION_TYPES.SUBMIT_FY_SUCCESS, ACTION_TYPES.SUBMIT_FY_FAILURE],
            data
        }
    };
};
export const fetchFyById = (id) => {
    return {
        url: API_URL.FINANCIAL_YEAR.FETCH_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_FY_BY_ID_REQUEST, ACTION_TYPES.FETCH_FY_BY_ID_SUCCESS, ACTION_TYPES.FETCH_FY_BY_ID_FAILURE],
            data: { id }
        }
    };
};
export const editFyApi = (data) => {
    return {
        url: API_URL.FINANCIAL_YEAR.EDIT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_FY_REQUEST, ACTION_TYPES.EDIT_FY_SUCCESS, ACTION_TYPES.EDIT_FY_FAILURE],
            data
        }
    };
};

export const FetchBillsList = (data) => {
    return {
        url: API_URL.BILLS.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BILLS_DETAILS_REQUEST, ACTION_TYPES.FETCH_BILLS_DETAILS_SUCCESS, ACTION_TYPES.FETCH_BILLS_DETAILS_FAILURE],
            data
        }
    };
};
export const DeleteBillsList = (id) => {
    return {
        url: API_URL.BILLS.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_BILLS_REQUEST, ACTION_TYPES.DELETE_BILLS_SUCCESS, ACTION_TYPES.DELETE_BILLS_FAILURE]
        }
    };
};
export const submitBillsApi = (data) => {
    return {
        url: API_URL.BILLS.SUBMIT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_BILLS_REQUEST, ACTION_TYPES.SUBMIT_BILLS_SUCCESS, ACTION_TYPES.SUBMIT_BILLS_FAILURE],
            data
        }
    };
};
export const fetchBillsByIdApi = (id) => {
    return {
        url: API_URL.BILLS.FETCH_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BILLS_BY_ID_REQUEST, ACTION_TYPES.FETCH_BILLS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_BILLS_BY_ID_FAILURE],
            data: { id }
        }
    };
};
export const editBillsApi = (data) => {
    return {
        url: API_URL.BILLS.EDIT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_BILLS_REQUEST, ACTION_TYPES.EDIT_BILLS_SUCCESS, ACTION_TYPES.EDIT_BILLS_FAILURE],
            data
        }
    };
};
export const LSGIDropdownApi = ({ id, districtId }) => {
    return {
        url: API_URL.BILLS.LSGI,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE
            ],
            data: { dropdown: true, districtId: districtId, lsgiTypeId: id }

        }
    };
};
export const fetchDistrict = (data) => {
    return {
        url: API_URL.BILLS.DISTRICTS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_FAILURE],
            data
        }
    };
};
export const lsgiTypeDropdownApi = () => {
    return {
        url: API_URL.BILLS.LSGI_TYPES,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_REQUEST, ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_SUCCESS, ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};
export const billStatusDropdownApi = () => {
    return {
        url: API_URL.BILLS.BILL_STATUS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.BILL_STATUS_DROPDOWN_REQUEST, ACTION_TYPES.BILL_STATUS_DROPDOWN_SUCCESS, ACTION_TYPES.BILL_STATUS_DROPDOWN_FAILURE],
            data: { dropdown: true }
        }
    };
};
export const projectsDropdownApi = () => {
    return {
        url: API_URL.BILLS.PROJECTS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.PROJECTS_DROPDOWN_REQUEST, ACTION_TYPES.PROJECTS_DROPDOWN_SUCCESS, ACTION_TYPES.PROJECTS_DROPDOWN_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const fetchBillsSankhya = (data) => {
    return {
        url: API_URL.BILLS.FETCH_BILLS_FROM_SANKHYA,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_REQUEST, ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_SUCCESS, ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_FAILURE],
            data
        }
    };
};

export const fetchBillsSankhyaListAll = (data) => {
    return {
        url: API_URL.BILLS.LIST_ALL_FETCH_BILL_FROM_SANKHYA,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_LIST_ALL_REQUEST, ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_LIST_ALL_SUCCESS, ACTION_TYPES.FETCH_BILL_FROM_SANKHYA_LIST_ALL_FAILURE],
            data
        }
    };
};

export const fetchTotalFromSankhya = (data) => {
    return {
        url: API_URL.BILLS.FETCH_TOTAL_EXPENSES_FROM_SANKHYA,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EXPENSES_FROM_SANKHYA_REQUEST, ACTION_TYPES.FETCH_EXPENSES_FROM_SANKHYA_SUCCESS, ACTION_TYPES.FETCH_EXPENSES_FROM_SANKHYA_FAILURE],
            data
        }
    };
};
