import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_TOTAL_FUND_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_DROPDOWN`,
    FETCH_TOTAL_FUND_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_DROPDOWN_REQUEST`,
    FETCH_TOTAL_FUND_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_DROPDOWN_SUCCESS`,
    FETCH_TOTAL_FUND_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOTAL_FUND_DROPDOWN_FAILURE`,

    FETCH_GUR_REPORT_1: `${STATE_REDUCER_KEY}/FETCH_GUR_REPORT_1`,
    FETCH_GUR_REPORT_1_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GUR_REPORT_1_REQUEST`,
    FETCH_GUR_REPORT_1_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GUR_REPORT_1_SUCCESS`,
    FETCH_GUR_REPORT_1_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GUR_REPORT_1_FAILURE`,

    FETCH_QUARTER_DROP_DOWN: `${STATE_REDUCER_KEY}/FETCH_QUARTER_DROP_DOWN`,
    FETCH_QUARTER_DROP_DOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_QUARTER_DROP_DOWN_REQUEST`,
    FETCH_QUARTER_DROP_DOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_QUARTER_DROP_DOWN_SUCCESS`,
    FETCH_QUARTER_DROP_DOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_QUARTER_DROP_DOWN_FAILURE`,

    PROJECT_DETAILS_REPORT: `${STATE_REDUCER_KEY}/PROJECT_DETAILS_REPORT`,
    PROJECT_DETAILS_REPORT_REQUEST: `${STATE_REDUCER_KEY}/PROJECT_DETAILS_REPORT_REQUEST`,
    PROJECT_DETAILS_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/PROJECT_DETAILS_REPORT_SUCCESS`,
    PROJECT_DETAILS_REPORT_FAILURE: `${STATE_REDUCER_KEY}/PROJECT_DETAILS_REPORT_FAILURE`,

    DOWNLOAD_PROJECT_DETAILS_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_PROJECT_DETAILS_REPORT`,
    DOWNLOAD_PROJECT_DETAILS_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_PROJECT_DETAILS_REPORT_REQUEST`,
    DOWNLOAD_PROJECT_DETAILS_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_PROJECT_DETAILS_REPORT_SUCCESS`,
    DOWNLOAD_PROJECT_DETAILS_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_PROJECT_DETAILS_REPORT_FAILURE`,

    FETCH_GUR2_REPORT: `${STATE_REDUCER_KEY}/FETCH_GUR2_REPORT`,
    FETCH_GUR2_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GUR2_REPORT_REQUEST`,
    FETCH_GUR2_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GUR2_REPORT_SUCCESS`,
    FETCH_GUR2_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GUR2_REPORT_FAILURE`,

    DOWNLOAD_GUR2_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR2_REPORT`,
    DOWNLOAD_GUR2_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR2_REPORT_REQUEST`,
    DOWNLOAD_GUR2_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR2_REPORT_SUCCESS`,
    DOWNLOAD_GUR2_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR2_REPORT_FAILURE`,

    FETCH_INCENTIVE_GRANT_REPORT: `${STATE_REDUCER_KEY}/FETCH_INCENTIVE_GRANT_REPORT`,
    FETCH_INCENTIVE_GRANT_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_INCENTIVE_GRANT_REPORT_REQUEST`,
    FETCH_INCENTIVE_GRANT_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_INCENTIVE_GRANT_REPORT_SUCCESS`,
    FETCH_INCENTIVE_GRANT_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_INCENTIVE_GRANT_REPORT_FAILURE`,

    DOWNLOAD_INCENTIVE_GRANT_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_INCENTIVE_GRANT_REPORT`,
    DOWNLOAD_INCENTIVE_GRANT_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_INCENTIVE_GRANT_REPORT_REQUEST`,
    DOWNLOAD_INCENTIVE_GRANT_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_INCENTIVE_GRANT_REPORT_SUCCESS`,
    DOWNLOAD_INCENTIVE_GRANT_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_INCENTIVE_GRANT_REPORT_FAILURE`,

    FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT: `${STATE_REDUCER_KEY}/FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT`,
    FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_REQUEST`,
    FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_SUCCESS`,
    FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT_FAILURE`,

    DOWNLOAD_INCENTIVE_GRANT_PRESENT_STATUS: `${STATE_REDUCER_KEY}/DOWNLOAD_INCENTIVE_GRANT_PRESENT_STATUS`,
    DOWNLOAD_INCENTIVE_GRANT_PRESENT_STATUS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_INCENTIVE_GRANT_PRESENT_STATUS_REQUEST`,
    DOWNLOAD_INCENTIVE_GRANT_PRESENT_STATUS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_INCENTIVE_GRANT_PRESENT_STATUS_SUCCESS`,
    DOWNLOAD_INCENTIVE_GRANT_PRESENT_STATUS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_INCENTIVE_GRANT_PRESENT_STATUS_FAILURE`,

    FETCH_GUR3_REPORT: `${STATE_REDUCER_KEY}/FETCH_GUR3_REPORT`,
    FETCH_GUR3_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GUR3_REPORT_REQUEST`,
    FETCH_GUR3_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GUR3_REPORT_SUCCESS`,
    FETCH_GUR3_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GUR3_REPORT_FAILURE`,

    DOWNLOAD_GUR3_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR3_REPORT`,
    DOWNLOAD_GUR3_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR3_REPORT_REQUEST`,
    DOWNLOAD_GUR3_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR3_REPORT_SUCCESS`,
    DOWNLOAD_GUR3_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR3_REPORT_FAILURE`,

    FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT: `${STATE_REDUCER_KEY}/FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT`,
    FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_REQUEST`,
    FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_SUCCESS`,
    FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT_FAILURE`,

    DOWNLOAD_GUR2_CUMULATIVE_OR_YEAR_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR2_CUMULATIVE_OR_YEAR_REPORT`,
    DOWNLOAD_GUR2_CUMULATIVE_OR_YEAR_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR2_CUMULATIVE_OR_YEAR_REPORT_REQUEST`,
    DOWNLOAD_GUR2_CUMULATIVE_OR_YEAR_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR2_CUMULATIVE_OR_YEAR_REPORT_SUCCESS`,
    DOWNLOAD_GUR2_CUMULATIVE_OR_YEAR_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR2_CUMULATIVE_OR_YEAR_REPORT_FAILURE`,

    DOWNLOAD_GUR1_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR1_REPORT`,
    DOWNLOAD_GUR1_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR1_REPORT_REQUEST`,
    DOWNLOAD_GUR1_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR1_REPORT_SUCCESS`,
    DOWNLOAD_GUR1_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_GUR1_REPORT_FAILURE`,

    FETCH_GRANT_ALLOCATION: `${STATE_REDUCER_KEY}/FETCH_GRANT_ALLOCATION`,
    FETCH_GRANT_ALLOCATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GRANT_ALLOCATION_REQUEST`,
    FETCH_GRANT_ALLOCATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GRANT_ALLOCATION_SUCCESS`,
    FETCH_GRANT_ALLOCATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GRANT_ALLOCATION_FAILURE`,

    DOWNLOAD_GRANT_ALLOCATION_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_GRANT_ALLOCATION_REPORT`,
    DOWNLOAD_GRANT_ALLOCATION_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_GRANT_ALLOCATION_REPORT_REQUEST`,
    DOWNLOAD_GRANT_ALLOCATION_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_GRANT_ALLOCATION_REPORT_SUCCESS`,
    DOWNLOAD_GRANT_ALLOCATION_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_GRANT_ALLOCATION_REPORT_FAILURE`
};

export const fetchGurReport1 = createAction(ACTION_TYPES.FETCH_GUR_REPORT_1);
export const quarterDropDownApi = createAction(ACTION_TYPES.FETCH_QUARTER_DROP_DOWN);
export const projectDetailsReport = createAction(ACTION_TYPES.PROJECT_DETAILS_REPORT);
export const downloadProjectDetails = createAction(ACTION_TYPES.DOWNLOAD_PROJECT_DETAILS_REPORT);
export const fetchGur2Report = createAction(ACTION_TYPES.FETCH_GUR2_REPORT);
export const downloadGur2Report = createAction(ACTION_TYPES.DOWNLOAD_GUR2_REPORT);
export const fetchIncentiveGrant = createAction(ACTION_TYPES.FETCH_INCENTIVE_GRANT_REPORT);
export const downloadIncentiveGrantReport = createAction(ACTION_TYPES.DOWNLOAD_INCENTIVE_GRANT_REPORT);
export const fetchIncentiveGrantPresentStatus = createAction(ACTION_TYPES.FETCH_INCENTIVE_GRANT_PRESENT_STATUS_REPORT);
export const downloadIncentiveGrantPresentStatusReport = createAction(ACTION_TYPES.DOWNLOAD_INCENTIVE_GRANT_PRESENT_STATUS);
export const fetchGur3Report = createAction(ACTION_TYPES.FETCH_GUR3_REPORT);
export const downloadGur3Report = createAction(ACTION_TYPES.DOWNLOAD_GUR3_REPORT);
export const fetchGur2CumulativeOrYearReport = createAction(ACTION_TYPES.FETCH_GUR2_CUMULATIVE_OR_YEAR_REPORT);
export const downloadGur2CumulativeOrYearReport = createAction(ACTION_TYPES.DOWNLOAD_GUR2_CUMULATIVE_OR_YEAR_REPORT);
export const downloadGur1Report = createAction(ACTION_TYPES.DOWNLOAD_GUR1_REPORT);
export const fetchGrantAllocationReport = createAction(ACTION_TYPES.FETCH_GRANT_ALLOCATION);
export const downloadGrantAllocationReport = createAction(ACTION_TYPES.DOWNLOAD_GRANT_ALLOCATION_REPORT);
