import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    SUBMIT_DRAFT_REMARKS: `${STATE_REDUCER_KEY}/SUBMIT_DRAFT_REMARKS`,
    SUBMIT_DRAFT_REMARKS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_DRAFT_REMARKS_REQUEST`,
    SUBMIT_DRAFT_REMARKS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_DRAFT_REMARKS_SUCCESS`,
    SUBMIT_DRAFT_REMARKS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_DRAFT_REMARKS_FAILURE`,

    GET_DRAFT_REMARKS_LIST: `${STATE_REDUCER_KEY}/GET_DRAFT_REMARKS_LIST`,
    GET_DRAFT_REMARKS_LIST_REQUEST: `${STATE_REDUCER_KEY}/GET_DRAFT_REMARKS_LIST_REQUEST`,
    GET_DRAFT_REMARKS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/GET_DRAFT_REMARKS_LIST_SUCCESS`,
    GET_DRAFT_REMARKS_LIST_FAILURE: `${STATE_REDUCER_KEY}/GET_DRAFT_REMARKS_LIST_FAILURE`
};

export const submitDraftRemarks = createAction(ACTION_TYPES.SUBMIT_DRAFT_REMARKS);
export const getDraftRemarksList = createAction(ACTION_TYPES.GET_DRAFT_REMARKS_LIST);

