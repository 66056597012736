export const STATE_REDUCER_KEY = "grandAllocation";

export const APPLICATION_STATUS = {
    DRAFTED_BY_ULB: 1,
    SUBMITTED_TO_DPMU: 2,
    RETURNED_TO_ULB: 3,
    SUBMITTED_TO_IVA: 4,
    IVA_VERIFIED: 5,
    SUBMITTED_TO_SPMU: 6,
    SPMU_APPROVED: 7,
    SPMU_REJECTED: 8,
    APPROVED_BY_DPMU: 9
};
