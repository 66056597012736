import { I18n } from "common/components";
import { Yup } from "utils/yupUtils";

export const ReceiptSchema = Yup.object({
    asDate: Yup.string()
        .required(I18n("is_required", { type: I18n("date") })),
    districtId: Yup.object()
        .required(I18n("is_required", { type: I18n("district") })),
    amount: Yup.string()
        .required(I18n("is_required", { type: I18n("amount") })),
    attachments: Yup.array()
        .required(I18n("is_required", { type: I18n("attachments") }))
});


