import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { fetchTechnicalFormById, deleteProjectActivity, patchProjectActivity, postProjectActivity, projectMonitoringList, technicalTableList, updateTechnicalForm, fetchProjectActivitiesListApi, fetchContractorList, paymentScheduleByIdApi, paymentScheduleList, paymentScheduleSave, paymentScheduleUpdate, projectActivitiesListById, projectActivityUpdate, projectMonitoringDelete, contractorListByIdApi, fetchActivityNameApi, fetchTechnicalListAll, paymentScheduleDataApi, fetchTechnicalViewForm } from "./api";
import { } from "./api";
import { ACTION_TYPES } from "./actions";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { actions as commonActions } from "../../common/slice";
import { actions as sliceActions } from "./slice";
import { toEpoch } from "utils/dateUtils";
import { TABLE_IDS } from "common/constants";

export function* fetchProjectActivitesList({ payload }) {
  let updatedPayload = { projectId: payload.id, pageNo: payload.pageNo, pageSize: payload.pageSize };
  yield fork(handleAPIRequest, fetchProjectActivitiesListApi, updatedPayload, payload.id);
  const COMPARISON_TYPES = [ACTION_TYPES.PROJECT_ACTIVITIES_LIST_SUCCESS, ACTION_TYPES.PROJECT_ACTIVITIES_LIST_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.PROJECT_ACTIVITY_LIST);
}

export function* saveProjectActivity({ payload }) {
  const { projectId } = payload;
  const { vendor, activityName } = payload.values;
  let updatedPayload = { projectId: projectId, vendor: vendor, activityName: activityName };
  yield fork(handleAPIRequest, postProjectActivity, updatedPayload);
  const responseAction = yield take([ACTION_TYPES.POST_PROJECT_ACTIVITY_SUCCESS, ACTION_TYPES.POST_PROJECT_ACTIVITY_FAILURE]);
  if (responseAction.type === ACTION_TYPES.POST_PROJECT_ACTIVITY_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("project_activity") }) }));
    yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
    yield put(sliceActions.closeModal());
    let tablePayload = { pageNo: 0, pageSize: 5, id: payload.projectId };
    yield call(fetchProjectActivitesList, { payload: tablePayload });
  }
}

export function* updateProjectActivity({ payload }) {
  let updatedPayload = { projectId: payload.id, pageNo: payload.pageNo, pageSize: payload.pageSize };
  yield fork(handleAPIRequest, patchProjectActivity, updatedPayload, payload.id);
  const COMPARISON_TYPES = [ACTION_TYPES.PROJECT_ACTIVITIES_LIST_SUCCESS, ACTION_TYPES.PROJECT_ACTIVITIES_LIST_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

export function* deleteProjectAcitivityApi({ payload }) {
  yield fork(handleAPIRequest, deleteProjectActivity, payload.idd);
  const response = yield take([ACTION_TYPES.DELETE_PROJECT_ACTIVITY_SUCCESS, ACTION_TYPES.DELETE_PROJECT_ACTIVITY_FAILURE]);
  if (response.type === ACTION_TYPES.DELETE_PROJECT_ACTIVITY_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("project_activity") }) }));
    yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
    let tablePayload = { pageNo: 0, pageSize: 5, id: payload.id };
    yield call(fetchProjectActivitesList, { payload: tablePayload });
  }
}

export function* getContractorList({ payload }) {
  yield fork(handleAPIRequest, fetchContractorList, payload);
}

export function* listTechnicalTable({ payload }) {
  if (payload.id) {
    let updatedPayload = { pageNo: payload.pageNo, pageSize: payload.pageSize, activityContractId: payload.id };
    yield fork(handleAPIRequest, technicalTableList, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.TECHNICAL_TABLE_LIST_SUCCESS, ACTION_TYPES.TECHNICAL_TABLE_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TECHNICAL_TABLE);
  }
}

export function* listProjectMonitoring({ payload }) {
  let tableProps = yield select(getTableProps);
  let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
  yield fork(handleAPIRequest, projectMonitoringList, { ...filters, ...payload });
  const COMPARISON_TYPES = [ACTION_TYPES.PROJECT_MONITORING_LIST_SUCCESS, ACTION_TYPES.PROJECT_MONITORING_LIST_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

export function* deleteProjectMonitoring({ payload: id }) {
  yield fork(handleAPIRequest, projectMonitoringDelete, id);
  const response = yield take([ACTION_TYPES.DELETE_PROJECT_MONITORING_SUCCESS, ACTION_TYPES.DELETE_PROJECT_MONITORING_FAILURE]);
  if (response.type === ACTION_TYPES.DELETE_PROJECT_MONITORING_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("sub_project_monitoring") }) }));
    yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
    const tablePayload = yield* getPaginationPayload();
    yield call(listProjectMonitoring, { payload: tablePayload });
  }
}

const formatTechnicalData = (rest) => {
  const { recordedDate, currentQuantity, remarks, attachmentIds, id } = rest;
  const data = {
    recordedDate: toEpoch(recordedDate), currentQuantity: Number(currentQuantity), remarks, activityContractDetailsId: Number(id), attachmentIds
  };
  return data;
};

export function* updateTechnicalFormData(action) {
  let { payload: { ...rest } = {} } = action;
  yield fork(handleAPIRequest, updateTechnicalForm, formatTechnicalData(rest));
  const responseAction = yield take([ACTION_TYPES.PATCH_TECHNICAL_FORM_SUCCESS, ACTION_TYPES.PATCH_TECHNICAL_FORM_FAILURE]);
  if (responseAction.type === ACTION_TYPES.PATCH_TECHNICAL_FORM_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("technical") }) }));
    let updatedPayload = { pageNo: 0, pageSize: 5, activityContractId: rest.activityContractId };
    yield fork(handleAPIRequest, technicalTableList, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.TECHNICAL_TABLE_LIST_SUCCESS, ACTION_TYPES.TECHNICAL_TABLE_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TECHNICAL_TABLE);
  }
}

export function* fetchTechnicalFormData({ payload }) {
  yield call(handleAPIRequest, fetchTechnicalFormById, { id: payload });
}

export function* fetchTechnicalView({ payload }) {
  yield call(handleAPIRequest, fetchTechnicalViewForm, { activityContractDetailsId: payload });
}

export function* listPaymentSchedule({ payload }) {
  let updatedPayload = { pageNo: payload.pageNo, pageSize: payload.pageSize };
  yield fork(handleAPIRequest, paymentScheduleList, updatedPayload, payload.id);
  const COMPARISON_TYPES = [ACTION_TYPES.LIST_PAYMENT_SCHEDULE_SUCCESS, ACTION_TYPES.LIST_PAYMENT_SCHEDULE_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

const formatData = (rest) => {
  const { plannedDate, milestoneId, milestoneTitle, amount, scheduledDescription, projectId } = rest;
  const data = { plannedDate: toEpoch(plannedDate), milestoneId, milestoneTitle, amount: Number(amount), scheduledDescription, projectId: Number(projectId) };
  return data;
};

const formatEditData = (rest) => {
  const { plannedDate, milestoneId, milestoneTitle, amount, scheduledDescription, projectId, id } = rest;
  const data = { plannedDate: toEpoch(plannedDate), milestoneId, milestoneTitle, amount: Number(amount), scheduledDescription, projectId: Number(projectId), id };
  return data;
};

export function* submitPaymentSchedule(action) {
  let { payload: { ...rest } = {} } = action;
  yield fork(handleAPIRequest, paymentScheduleSave, formatData(rest));
  const responseAction = yield take([ACTION_TYPES.SUBMIT_PAYMENT_SCHEDULE_SUCCESS, ACTION_TYPES.SUBMIT_PAYMENT_SCHEDULE_FAILURE]);
  if (responseAction.type === ACTION_TYPES.SUBMIT_PAYMENT_SCHEDULE_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("payment_schedule") }) }));
    let updatedPayload = { pageNo: 0, pageSize: 5 };
    yield fork(handleAPIRequest, paymentScheduleList, updatedPayload, rest.projectId);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_PAYMENT_SCHEDULE_SUCCESS, ACTION_TYPES.LIST_PAYMENT_SCHEDULE_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
  }
}

export function* updatePaymentSchedule({ payload }) {
  yield fork(handleAPIRequest, paymentScheduleUpdate, formatEditData(payload));
  const responseAction = yield take([ACTION_TYPES.UPDATE_PAYMENT_SCHEDULE_SUCCESS, ACTION_TYPES.UPDATE_PAYMENT_SCHEDULE_FAILURE]);
  if (responseAction.type === ACTION_TYPES.UPDATE_PAYMENT_SCHEDULE_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("payment_schedule") }) }));
    let updatedPayload = { pageNo: 0, pageSize: 5 };
    yield fork(handleAPIRequest, paymentScheduleList, updatedPayload, payload.projectId);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_PAYMENT_SCHEDULE_SUCCESS, ACTION_TYPES.LIST_PAYMENT_SCHEDULE_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
  }
}

export function* paymentScheduleById({ payload: id }) {
  yield call(handleAPIRequest, paymentScheduleByIdApi, id);
}

export function* paymentScheduleData({ payload: id }) {
  yield call(handleAPIRequest, paymentScheduleDataApi, id);
}

export function* listProjectActivitesListById({ payload }) {
  let updatedPayload = { activityContractId: payload.id, pageNo: payload.pageNo, pageSize: payload.pageSize };
  yield fork(handleAPIRequest, projectActivitiesListById, updatedPayload, payload.id);
  const COMPARISON_TYPES = [ACTION_TYPES.PROJECT_ACTIVITIES_LIST_BY_ID_SUCCESS, ACTION_TYPES.PROJECT_ACTIVITIES_LIST_BY_ID_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

const formatDatas = (rest) => {
  const { scheduledEndDate, scheduledStartDate, work, uom, scheduledQuantity, activityContractId } = rest;
  const data = { scheduledEndDate: toEpoch(scheduledEndDate), scheduledStartDate: toEpoch(scheduledStartDate), work, uom, scheduledQuantity: Number(scheduledQuantity), activityContractId: Number(activityContractId) };
  return data;
};
export function* updateProjectActivityById(action) {
  let { payload: { ...rest } = {} } = action;
  yield fork(handleAPIRequest, projectActivityUpdate, formatDatas(rest));
  const responseAction = yield take([ACTION_TYPES.UPDATE_PROJECT_ACTIVITY_SUCCESS, ACTION_TYPES.UPDATE_PROJECT_ACTIVITY_FAILURE]);
  if (responseAction.type === ACTION_TYPES.UPDATE_PROJECT_ACTIVITY_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("project_activity") }) }));
    let updatedPayload = { pageNo: 0, pageSize: 5, activityContractId: rest.activityContractId };
    yield fork(handleAPIRequest, projectActivitiesListById, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.PROJECT_ACTIVITIES_LIST_BY_ID_SUCCESS, ACTION_TYPES.PROJECT_ACTIVITIES_LIST_BY_ID_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
  }
}

export function* contractorListById({ payload: id }) {
  yield call(handleAPIRequest, contractorListByIdApi, id);
}

export function* fetchActivityName({ payload }) {
  yield call(handleAPIRequest, fetchActivityNameApi, payload);
}

export function* listTechnicalAll({ payload }) {
  yield call(handleAPIRequest, fetchTechnicalListAll, { id: payload });
}

export default function* testSaga() {
  yield all([
    takeLatest(ACTION_TYPES.PROJECT_ACTIVITIES_LIST, fetchProjectActivitesList),
    takeLatest(ACTION_TYPES.POST_PROJECT_ACTIVITY, saveProjectActivity),
    takeLatest(ACTION_TYPES.PATCH_PROJECT_ACTIVITY, updateProjectActivity),
    takeLatest(ACTION_TYPES.DELETE_PROJECT_ACTIVITY, deleteProjectAcitivityApi),
    takeLatest(ACTION_TYPES.FETCH_CONTRACTOR_LIST, getContractorList),
    takeLatest(ACTION_TYPES.TECHNICAL_TABLE_LIST, listTechnicalTable),
    takeLatest(ACTION_TYPES.PROJECT_MONITORING_LIST, listProjectMonitoring),
    takeLatest(ACTION_TYPES.PATCH_TECHNICAL_FORM, updateTechnicalFormData),
    takeLatest(ACTION_TYPES.TECHNICAL_FORM_BY_ID, fetchTechnicalFormData),
    takeLatest(ACTION_TYPES.LIST_PAYMENT_SCHEDULE, listPaymentSchedule),
    takeLatest(ACTION_TYPES.SUBMIT_PAYMENT_SCHEDULE, submitPaymentSchedule),
    takeLatest(ACTION_TYPES.UPDATE_PAYMENT_SCHEDULE, updatePaymentSchedule),
    takeLatest(ACTION_TYPES.LIST_PAYMENT_SCHEDULE_BY_ID, paymentScheduleById),
    takeLatest(ACTION_TYPES.PROJECT_ACTIVITIES_LIST_BY_ID, listProjectActivitesListById),
    takeLatest(ACTION_TYPES.UPDATE_PROJECT_ACTIVITY, updateProjectActivityById),
    takeLatest(ACTION_TYPES.DELETE_PROJECT_MONITORING, deleteProjectMonitoring),
    takeLatest(ACTION_TYPES.CONTRACTOR_LIST_BY_ID, contractorListById),
    takeLatest(ACTION_TYPES.SELECT_ACTIVITY_NAME, fetchActivityName),
    takeLatest(ACTION_TYPES.PAYMENT_SCHEDULE_BY_ID, paymentScheduleData),
    takeLatest(ACTION_TYPES.TECHNICAL_FORM_VIEW, fetchTechnicalView),
    takeLatest(ACTION_TYPES.LIST_TECHNICAL_ALL, listTechnicalAll)
  ]);
}
