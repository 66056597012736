
import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    FETCH_DISTRICT_LIST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST`,
    FETCH_DISTRICT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_REQUEST`,
    FETCH_DISTRICT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_SUCCESS`,
    FETCH_DISTRICT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_LIST_FAILURE`,

    FETCH_LOKSABHA_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_LOKSABHA_DETAILS_BY_ID`,
    FETCH_LOKSABHA_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOKSABHA_DETAILS_BY_ID_REQUEST`,
    FETCH_LOKSABHA_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOKSABHA_DETAILS_BY_ID_SUCCESS`,
    FETCH_LOKSABHA_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOKSABHA_DETAILS_BY_ID_FAILURE`,

    FETCH_LOKSABHA_DATA: `${STATE_REDUCER_KEY}/FETCH_LOKSABHA_DATA`,
    FETCH_LOKSABHA_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOKSABHA_DATA_REQUEST`,
    FETCH_LOKSABHA_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOKSABHA_DATA_SUCCESS`,
    FETCH_LOKSABHA_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOKSABHA_DATA_FAILURE`,

    ADD_LOKSABHA_DATA: `${STATE_REDUCER_KEY}/ADD_LOKSABHA_DATA`,
    ADD_LOKSABHA_DATA_REQUEST: `${STATE_REDUCER_KEY}/ADD_LOKSABHA_DATA_REQUEST`,
    ADD_LOKSABHA_DATA_SUCCESS: `${STATE_REDUCER_KEY}/ADD_LOKSABHA_DATA_SUCCESS`,
    ADD_LOKSABHA_DATA_FAILURE: `${STATE_REDUCER_KEY}/ADD_LOKSABHA_DATA_FAILURE`,

    EDIT_LOKSABHA_DATA: `${STATE_REDUCER_KEY}/EDIT_LOKSABHA_DATA`,
    EDIT_LOKSABHA_DATA_REQUEST: `${STATE_REDUCER_KEY}/EDIT_LOKSABHA_DATA_REQUEST`,
    EDIT_LOKSABHA_DATA_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_LOKSABHA_DATA_SUCCESS`,
    EDIT_LOKSABHA_DATA_FAILURE: `${STATE_REDUCER_KEY}/EDIT_LOKSABHA_DATA_FAILURE`,

    DELETE_LOKSABHA_DATA: `${STATE_REDUCER_KEY}/DELETE_LOKSABHA_DATA`,
    DELETE_LOKSABHA_DATA_REQUEST: `${STATE_REDUCER_KEY}/DELETE_LOKSABHA_DATA_REQUEST`,
    DELETE_LOKSABHA_DATA_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_LOKSABHA_DATA_SUCCESS`,
    DELETE_LOKSABHA_DATA_FAILURE: `${STATE_REDUCER_KEY}/DELETE_LOKSABHA_DATA_FAILURE`,

    SUBMIT_LOKSABHA_DATA: `${STATE_REDUCER_KEY}/SUBMIT_LOKSABHA_DATA`,
    SUBMIT_LOKSABHA_DATA_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_LOKSABHA_DATA_REQUEST`,
    SUBMIT_LOKSABHA_DATA_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_LOKSABHA_DATA_SUCCESS`,
    SUBMIT_LOKSABHA_DATA_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_LOKSABHA_DATA_FAILURE`,

    FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
    FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
    FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
    FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,

    FETCH_CODE_LIST: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST`,
    FETCH_CODE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_REQUEST`,
    FETCH_CODE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_SUCCESS`,
    FETCH_CODE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_FAILURE`
};

export const getDistrictDetails = createAction(ACTION_TYPES.FETCH_DISTRICT_LIST);

export const getLoksabhaValuesById = createAction(ACTION_TYPES.FETCH_LOKSABHA_DETAILS_BY_ID);

export const getLoksabhaDetails = createAction(ACTION_TYPES.FETCH_LOKSABHA_DATA);

export const submitLoksabhaDetails = createAction(ACTION_TYPES.SUBMIT_LOKSABHA_DATA);

export const deleteLoksabhaDetails = createAction(ACTION_TYPES.DELETE_LOKSABHA_DATA);

export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);

export const getCodeList = createAction(ACTION_TYPES.FETCH_CODE_LIST);
