import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const vendorCategoryPostAPICall = (data) => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CATEGORY_CREATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_VENDOR_CATEGORY_DETAILS_REQUEST, ACTION_TYPES.SUBMIT_VENDOR_CATEGORY_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_VENDOR_CATEGORY_DETAILS_FAILURE],
            data
        }
    };
};
export const vendorCategoryFetchAPICall = (data) => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CATEGORY_TABLE_DATA,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_REQUEST, ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_SUCCESS, ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_FAILURE],
            data
        }
    };
};
export const vendorCategoryDeleteCall = (id) => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CATEGORY_DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_VENDOR_CATEGORY_DETAILS_REQUEST, ACTION_TYPES.DELETE_VENDOR_CATEGORY_DETAILS_SUCCESS, ACTION_TYPES.DELETE_VENDOR_CATEGORY_DETAILS_FAILURE]
        }
    };
};
export const vendorCategoryEditCall = (data) => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CATEGORY_EDIT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_VENDOR_CATEGORY_DETAILS_REQUEST, ACTION_TYPES.EDIT_VENDOR_CATEGORY_DETAILS_SUCCESS, ACTION_TYPES.EDIT_VENDOR_CATEGORY_DETAILS_FAILURE],
            data
        }
    };
};
export const vendorCategoryFetchApiCallById = (id) => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CATEGORY_TABLE_DATA_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_REQUEST, ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_VENDOR_CATEGORY_DETAILS_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const vendorContactPostAPICall = (data) => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CONTACT_CREATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_VENDOR_CONTACT_DETAILS_REQUEST, ACTION_TYPES.SUBMIT_VENDOR_CONTACT_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_VENDOR_CONTACT_DETAILS_FAILURE],
            data
        }
    };
};
export const vendorContactFetchAPICall = (data) => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CONTACT_TABLE_DATA,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_REQUEST, ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_SUCCESS, ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_FAILURE],
            data
        }
    };
};
export const vendorContactDeleteCall = (id) => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CONTACT_DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_VENDOR_CONTACT_DETAILS_REQUEST, ACTION_TYPES.DELETE_VENDOR_CONTACT_DETAILS_SUCCESS, ACTION_TYPES.DELETE_VENDOR_CONTACT_DETAILS_FAILURE]
        }
    };
};
export const vendorContactEditCall = (data) => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CONTACT_EDIT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_VENDOR_CONTACT_DETAILS_REQUEST, ACTION_TYPES.EDIT_VENDOR_CONTACT_DETAILS_SUCCESS, ACTION_TYPES.EDIT_VENDOR_CONTACT_DETAILS_FAILURE],
            data
        }
    };
};
export const vendorContactFetchApiCallById = (id) => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CONTACT_TABLE_DATA_BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_BY_ID_REQUEST, ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_VENDOR_CONTACT_DETAILS_BY_ID_FAILURE]
        }
    };
};

export const vendorContactTypeDropdown = () => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CONTACT_TYPE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_USER_CONTACT_TYPE_REQUEST, ACTION_TYPES.FETCH_USER_CONTACT_TYPE_SUCCESS, ACTION_TYPES.FETCH_USER_CONTACT_TYPE_FAILURE],
            data: { dropdown: true }
        }
    };
};

export const fetchLookupCategoryIdDropdown = () => {
    return {
        url: API_URL.VENDOR_DETAILS.LOOKUP_CATEGORY_ID_TYPE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_REQUEST, ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_SUCCESS, ACTION_TYPES.FETCH_LOOKUP_CATEGORY_ID_FAILURE],
            data: { dropdown: true, pageSize: 300 }
        }
    };
};
export const fetchCategoryDropdownDetails = () => {
    return {
        url: API_URL.VENDOR_DETAILS.VENDOR_CONTACT_CATEGORY_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_CATEGORY_REQUEST, ACTION_TYPES.FETCH_CATEGORY_SUCCESS, ACTION_TYPES.FETCH_CATEGORY_FAILURE],
            data: { dropdown: true, pageSize: 300 }
        }
    };
};
export const fetchContactDropdownName = (data) => {
    return {
        url: API_URL.VENDOR_DETAILS.LIST_VENDOR_CONTACT_NAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_VENDOR_CONTACT_NAME_REQUEST, ACTION_TYPES.LIST_VENDOR_CONTACT_NAME_SUCCESS, ACTION_TYPES.LIST_VENDOR_CONTACT_NAME_FAILURE],
            data: data
        }
    };
};
export const fetchContactDropdownGstIn = (data) => {
    return {
        url: API_URL.VENDOR_DETAILS.LIST_VENDOR_CONTACT_GST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_VENDOR_CONTACT_GST_REQUEST, ACTION_TYPES.LIST_VENDOR_CONTACT_GST_SUCCESS, ACTION_TYPES.LIST_VENDOR_CONTACT_GST_FAILURE],
            data: data
        }
    };
};

export const filterCategoryName = (data) => {
    return {
        url: API_URL.VENDOR_DETAILS.FILTER_CATEGORY_NAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FILTER_VENDOR_CATEGORY_NAME_REQUEST, ACTION_TYPES.FILTER_VENDOR_CATEGORY_NAME_SUCCESS, ACTION_TYPES.FILTER_VENDOR_CATEGORY_NAME_FAILURE],
            data: data
        }
    };
};
