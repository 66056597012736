import { Grid, InputLabel, Typography } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import "./style.css";

import { FORM_CONTROL_STYLE, useStyles } from "./style";
import TextError from "./TextError";
import { useEffect, useState } from "react";


function Textarea(props) {

  const [fontsize, setFontSize] = useState("16px");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setFontSize("14px");
      } else {
        setFontSize("18px");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { label, name, onChangeFromController, errorName = "", statusError = false, isMandatory = false, disabled = false, placeholder = "", ...rest } = props;
  const classes = useStyles();
  const style = {
    border: "1px solid #C0E1EC",
    outline: "none",
    minHeight: "36px",
    font: "inherit",
    borderRadius: "10px",
    fontWeight: 400,
    fontSize: fontsize,
    color: "#000",
    backgroundColor: disabled ? "hsl(0, 0%, 95%)" : "#fff",
    padding: "8px",
    "::placeholder": {
      marginLeft: "15px",
      color: "#999",
      fontSize: fontsize,
      fontStyle: "italic"
    },
    resize: "vertical",
    maxHeight: "500px"
  };

  return (
    <Grid sx={FORM_CONTROL_STYLE}>
      <InputLabel className={classes.label} htmlFor={name}>{label} {isMandatory && <span style={{ color: "red", fontSize: "14px" }}> *</span>}</InputLabel>
      <Field
        onKeyUp={onChangeFromController}
        className="formik-textarea"
        style={style}
        as='textarea'
        id={name}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        {...rest}
      />
      {statusError ?
        <Typography variant="p" sx={{ color: "red", mt: 1, lineHeight: 0 }}>{errorName}</Typography> :
        <ErrorMessage component={TextError} name={name} />
      }
    </Grid>
  );
}

export default Textarea;
