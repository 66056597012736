import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_LSGI_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN`,
    FETCH_LSGI_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_REQUEST`,
    FETCH_LSGI_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_SUCCESS`,
    FETCH_LSGI_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_FAILURE`,

    SUBMIT_ANNUAL_PLAN: `${STATE_REDUCER_KEY}/SUBMIT_ANNUAL_PLAN`,
    SUBMIT_ANNUAL_PLAN_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_ANNUAL_PLAN_REQUEST`,
    SUBMIT_ANNUAL_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_ANNUAL_PLAN_SUCCESS`,
    SUBMIT_ANNUAL_PLAN_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_ANNUAL_PLAN_FAILURE`,

    LIST_ANNUAL_PLAN: `${STATE_REDUCER_KEY}/LIST_ANNUAL_PLAN`,
    LIST_ANNUAL_PLAN_REQUEST: `${STATE_REDUCER_KEY}/LIST_ANNUAL_PLAN_REQUEST`,
    LIST_ANNUAL_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ANNUAL_PLAN_SUCCESS`,
    LIST_ANNUAL_PLAN_FAILURE: `${STATE_REDUCER_KEY}/LIST_ANNUAL_PLAN_FAILURE`,

    LIST_BY_ID_ANNUAL_PLAN: `${STATE_REDUCER_KEY}/LIST_BY_ID_ANNUAL_PLAN`,
    LIST_BY_ID_ANNUAL_PLAN_REQUEST: `${STATE_REDUCER_KEY}/LIST_BY_ID_ANNUAL_PLAN_REQUEST`,
    LIST_BY_ID_ANNUAL_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/LIST_BY_ID_ANNUAL_PLAN_SUCCESS`,
    LIST_BY_ID_ANNUAL_PLAN_FAILURE: `${STATE_REDUCER_KEY}/LIST_BY_ID_ANNUAL_PLAN_FAILURE`,

    EDIT_ANNUAL_PLAN: `${STATE_REDUCER_KEY}/EDIT_ANNUAL_PLAN`,
    EDIT_ANNUAL_PLAN_REQUEST: `${STATE_REDUCER_KEY}/EDIT_ANNUAL_PLAN_REQUEST`,
    EDIT_ANNUAL_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_ANNUAL_PLAN_SUCCESS`,
    EDIT_ANNUAL_PLAN_FAILURE: `${STATE_REDUCER_KEY}/EDIT_ANNUAL_PLAN_FAILURE`,

    DELETE_ANNUAL_PLAN: `${STATE_REDUCER_KEY}/DELETE_ANNUAL_PLAN`,
    DELETE_ANNUAL_PLAN_REQUEST: `${STATE_REDUCER_KEY}/DELETE_ANNUAL_PLAN_REQUEST`,
    DELETE_ANNUAL_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_ANNUAL_PLAN_SUCCESS`,
    DELETE_ANNUAL_PLAN_FAILURE: `${STATE_REDUCER_KEY}/DELETE_ANNUAL_PLAN_FAILURE`,

    FETCH_DISTRICT_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN`,
    FETCH_DISTRICT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_REQUEST`,
    FETCH_DISTRICT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_SUCCESS`,
    FETCH_DISTRICT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_FAILURE`,

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    ULB_SUBMITTED_TO_MC: `${STATE_REDUCER_KEY}/ULB_SUBMITTED_TO_MC`,
    ULB_SUBMITTED_TO_MC_REQUEST: `${STATE_REDUCER_KEY}/ULB_SUBMITTED_TO_MC_REQUEST`,
    ULB_SUBMITTED_TO_MC_SUCCESS: `${STATE_REDUCER_KEY}/ULB_SUBMITTED_TO_MC_SUCCESS`,
    ULB_SUBMITTED_TO_MC_FAILURE: `${STATE_REDUCER_KEY}/ULB_SUBMITTED_TO_MC_FAILURE`,

    APPROVE_QA_QC: `${STATE_REDUCER_KEY}/APPROVE_QA_QC`,
    APPROVE_QA_QC_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_REQUEST`,
    APPROVE_QA_QC_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_SUCCESS`,
    APPROVE_QA_QC_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_FAILURE`,

    REJECT_QA_QC: `${STATE_REDUCER_KEY}/REJECT_QA_QC`,
    REJECT_QA_QC_REQUEST: `${STATE_REDUCER_KEY}/REJECT_QA_QC_REQUEST`,
    REJECT_QA_QC_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_QA_QC_SUCCESS`,
    REJECT_QA_QC_FAILURE: `${STATE_REDUCER_KEY}/REJECT_QA_QC_FAILURE`,

    APPROVE_SPMU: `${STATE_REDUCER_KEY}/APPROVE_SPMU`,
    APPROVE_SPMU_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_SPMU_REQUEST`,
    APPROVE_SPMU_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_SPMU_SUCCESS`,
    APPROVE_SPMU_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_SPMU_FAILURE`,

    REJECT_SPMU: `${STATE_REDUCER_KEY}/REJECT_SPMU`,
    REJECT_SPMU_REQUEST: `${STATE_REDUCER_KEY}/REJECT_SPMU_REQUEST`,
    REJECT_SPMU_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_SPMU_SUCCESS`,
    REJECT_SPMU_FAILURE: `${STATE_REDUCER_KEY}/REJECT_SPMU_FAILURE`,

    APPROVE_MUNICIPAL: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL`,
    APPROVE_MUNICIPAL_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL_REQUEST`,
    APPROVE_MUNICIPAL_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL_SUCCESS`,
    APPROVE_MUNICIPAL_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_MUNICIPAL_FAILURE`,

    REJECT_MUNICIPAL: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL`,
    REJECT_MUNICIPAL_REQUEST: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL_REQUEST`,
    REJECT_MUNICIPAL_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL_SUCCESS`,
    REJECT_MUNICIPAL_FAILURE: `${STATE_REDUCER_KEY}/REJECT_MUNICIPAL_FAILURE`,

    FETCH_FIVE_YEAR_LIST: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_LIST`,
    FETCH_FIVE_YEAR_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_LIST_REQUEST`,
    FETCH_FIVE_YEAR_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_LIST_SUCCESS`,
    FETCH_FIVE_YEAR_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_LIST_FAILURE`,

    FETCH_ANNUAL_PLAN_LIST: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN_LIST`,
    FETCH_ANNUAL_PLAN_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN_LIST_REQUEST`,
    FETCH_ANNUAL_PLAN_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN_LIST_SUCCESS`,
    FETCH_ANNUAL_PLAN_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN_LIST_FAILURE`,

    SUBMIT_PROJECT_LIST: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_LIST`,
    SUBMIT_PROJECT_LIST_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_LIST_REQUEST`,
    SUBMIT_PROJECT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_LIST_SUCCESS`,
    SUBMIT_PROJECT_LIST_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_PROJECT_LIST_FAILURE`,

    FETCH_STATUS_LIST: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST`,
    FETCH_STATUS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST_REQUEST`,
    FETCH_STATUS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST_SUCCESS`,
    FETCH_STATUS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_STATUS_LIST_FAILURE`,

    FETCH_FIVE_YEAR_PLAN_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_PLAN_DROPDOWN`,
    FETCH_FIVE_YEAR_PLAN_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_PLAN_DROPDOWN_REQUEST`,
    FETCH_FIVE_YEAR_PLAN_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_PLAN_DROPDOWN_SUCCESS`,
    FETCH_FIVE_YEAR_PLAN_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FIVE_YEAR_PLAN_DROPDOWN_FAILURE`,

    DOWNLOAD_ANNUAL_PLAN_LIST: `${STATE_REDUCER_KEY}/DOWNLOAD_ANNUAL_PLAN_LIST`,
    DOWNLOAD_ANNUAL_PLAN_LIST_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_ANNUAL_PLAN_LIST_REQUEST`,
    DOWNLOAD_ANNUAL_PLAN_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_ANNUAL_PLAN_LIST_SUCCESS`,
    DOWNLOAD_ANNUAL_PLAN_LIST_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_ANNUAL_PLAN_LIST_FAILURE`

};

export const fetchDistrictDropDown = createAction(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN);
export const fetchLsgiDropdown = createAction(ACTION_TYPES.FETCH_LSGI_DROPDOWN);
export const submitAnnualPlan = createAction(ACTION_TYPES.SUBMIT_ANNUAL_PLAN);
export const listAnnualPlan = createAction(ACTION_TYPES.LIST_ANNUAL_PLAN);
export const annualPlanById = createAction(ACTION_TYPES.LIST_BY_ID_ANNUAL_PLAN);
export const updateAnnualPlan = createAction(ACTION_TYPES.EDIT_ANNUAL_PLAN);
export const deleteAnnualPlan = createAction(ACTION_TYPES.DELETE_ANNUAL_PLAN);
export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const ulbSubmittedToMc = createAction(ACTION_TYPES.ULB_SUBMITTED_TO_MC);
export const rejectQaQc = createAction(ACTION_TYPES.REJECT_QA_QC);
export const approveQaQc = createAction(ACTION_TYPES.APPROVE_QA_QC);
export const rejectSpmu = createAction(ACTION_TYPES.REJECT_SPMU);
export const approveSpmu = createAction(ACTION_TYPES.APPROVE_SPMU);
export const rejectMunicipal = createAction(ACTION_TYPES.REJECT_MUNICIPAL);
export const approvedMunicipal = createAction(ACTION_TYPES.APPROVE_MUNICIPAL);
export const listFiveYearProject = createAction(ACTION_TYPES.FETCH_FIVE_YEAR_LIST);
export const listAnnualProject = createAction(ACTION_TYPES.FETCH_ANNUAL_PLAN_LIST);
export const submitProjectList = createAction(ACTION_TYPES.SUBMIT_PROJECT_LIST);
export const fetchStatusList = createAction(ACTION_TYPES.FETCH_STATUS_LIST);
export const fetchFiveYearPlanDropDown = createAction(ACTION_TYPES.FETCH_FIVE_YEAR_PLAN_DROPDOWN);
export const downloadAnnualPlanList = createAction(ACTION_TYPES.DOWNLOAD_ANNUAL_PLAN_LIST);
