import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { addLegislative, codeList, deleteLegislative, editLegislative, fetchDistricts, fetchLegislative, fetchLegislativeById, nameList } from "./api";
import { actions as commonActions } from "../../common/slice";
import { navigateTo } from "modules/common/actions";
import { ADMIN_PATH as PATH } from "../paths";
import { MODULE_PATH } from "../../paths";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { getTableProps } from "modules/common/selectors";

export function* fetchLegislativeDetails({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchLegislative, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_LEGISLATIVE_DATA_SUCCESS, ACTION_TYPES.FETCH_LEGISLATIVE_DATA_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}
export function* fetchDistrictDetails() {
    yield call(handleAPIRequest, fetchDistricts);
}
export function* fetchLegislativeFormById({ payload: id }) {
    yield call(handleAPIRequest, fetchLegislativeById, { id });
}
export function* submitLegislativeFormValue({ payload }) {
    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, editLegislative, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_LEGISLATIVE_DATA_SUCCESS, ACTION_TYPES.EDIT_LEGISLATIVE_DATA_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_LEGISLATIVE_DATA_SUCCESS) {
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.LEGISLATIVE.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, addLegislative, payload);
        const responseAction = yield take([ACTION_TYPES.ADD_LEGISLATIVE_DATA_SUCCESS, ACTION_TYPES.ADD_LEGISLATIVE_DATA_FAILURE]);
        if (responseAction.type === ACTION_TYPES.ADD_LEGISLATIVE_DATA_SUCCESS) {
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.LEGISLATIVE.LIST}`));
        }

    }
}

export function* deleteLegislativeDetails({ payload }) {
    const { id } = payload;
    yield fork(handleAPIRequest, deleteLegislative, { id });
    const responseAction = yield take([ACTION_TYPES.DELETE_LEGISLATIVE_DATA_SUCCESS, ACTION_TYPES.DELETE_LEGISLATIVE_DATA_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_LEGISLATIVE_DATA_SUCCESS) {
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchLegislativeDetails, { payload: tablePayload });
    }

}
export function* fetchNameList({ payload }) {
    yield call(handleAPIRequest, nameList, payload);
}

export function* fetchCodeList({ payload }) {
    yield call(handleAPIRequest, codeList, payload);
}
export default function* LegislativeSaga() {
    yield all([takeLatest(ACTION_TYPES.FETCH_LEGISLATIVE_DATA, fetchLegislativeDetails),
    takeLatest(ACTION_TYPES.FETCH_LEGISLATIVE_DETAILS_BY_ID, fetchLegislativeFormById),
    takeLatest(ACTION_TYPES.FETCH_DISTRICT_LIST, fetchDistrictDetails),
    takeLatest(ACTION_TYPES.DELETE_LEGISLATIVE_DATA, deleteLegislativeDetails),
    takeLatest(ACTION_TYPES.SUBMIT_LEGISLATIVE_DATA, submitLegislativeFormValue),
    takeLatest(ACTION_TYPES.FETCH_NAME_LIST, fetchNameList),
    takeLatest(ACTION_TYPES.FETCH_CODE_LIST, fetchCodeList)
    ]);
}
