import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { setCommonTableData } from "utils/tableUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "modules/paths";
import { ADMIN_PATH as PATH } from "../paths";
import { lsgiTypeApi, deleteStateApi, deleteUlbApi, districtDropdownApi, getUlbByIdApi, listUlbApi, patchUlbApi, ulbSubmitApi, fetchBlockPanchayathApi, fetchDistrictPanchayathApi, nameList, codeList } from "./api";
import { I18n } from "common/components";
import { getPayloadContentDetails } from "utils/apiUtils";
import { getTableProps } from "modules/common/selectors";

export function* dropdownDistrict(action) {
  yield call(handleAPIRequest, districtDropdownApi, action.payload);
}

export function* lsgiTypeDropdown(action) {
  yield call(handleAPIRequest, lsgiTypeApi, action.payload);
}

export function* fetchBlockPanchayath({ payload }) {
  yield call(handleAPIRequest, fetchBlockPanchayathApi, { districtId: payload, dropDown: true });
}

export function* fetchDistrictPanchayath({ payload }) {
  yield call(handleAPIRequest, fetchDistrictPanchayathApi, { districtId: payload, dropDown: true });
}

export function* getUlb({ payload }) {
  yield call(handleAPIRequest, getUlbByIdApi, payload);
  const responseAction = yield take([ACTION_TYPES.FETCH_ULB_BY_ID_SUCCESS, ACTION_TYPES.FETCH_ULB_BY_ID_FAILURE]);
  if (responseAction.type === ACTION_TYPES.FETCH_ULB_BY_ID_SUCCESS) {
    let data = getPayloadContentDetails(responseAction);
    let { districtId: { id } = {} } = data;
    yield call(fetchBlockPanchayath, { payload: id });
    yield call(fetchDistrictPanchayath, { payload: id });
  }
}
export function* deleteState() {
  yield call(handleAPIRequest, deleteStateApi);
}
export function* deleteUlb() {
  yield call(handleAPIRequest, deleteUlbApi);
}
export function* listUlb({ payload }) {
  let tableProps = yield select(getTableProps);
  let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
  yield fork(handleAPIRequest, listUlbApi, { ...filters, ...payload });
  const COMPARISON_TYPES = [ACTION_TYPES.LIST_ULB_DETAILS_SUCCESS, ACTION_TYPES.LIST_ULB_DETAILS_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES);
}

export function* addUlbDetails(action) {
  let { payload: { id, ...rest } = {} } = action;
  if (id) {
    yield fork(handleAPIRequest, patchUlbApi, action.payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_ULB_DETAILS_SUCCESS, ACTION_TYPES.PATCH_ULB_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_ULB_DETAILS_SUCCESS) {
      yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("ulb") }) }));
      yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.LSGI.LIST}`));
    }
  } else {
    yield fork(handleAPIRequest, ulbSubmitApi, rest);
    const responseAction = yield take([ACTION_TYPES.ADD_ULB_SUCCESS, ACTION_TYPES.ADD_ULB_FAILURE]);
    if (responseAction.type === ACTION_TYPES.ADD_ULB_SUCCESS) {
      yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("ulb") }) }));
      yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.LSGI.LIST}`));
    }
  }
}
export function* fetchNameList({ payload }) {
  yield call(handleAPIRequest, nameList, payload);
}

export function* fetchCodeList({ payload }) {
  yield call(handleAPIRequest, codeList, payload);
}
export default function* masterSaga() {
  yield all([
    takeLatest(ACTION_TYPES.ADD_ULB, addUlbDetails),
    takeLatest(ACTION_TYPES.SELECT_BLOCK_PANCHAYATH, fetchBlockPanchayath),
    takeLatest(ACTION_TYPES.SELECT_DISTRICT_PANCHAYATH, fetchDistrictPanchayath),
    takeLatest(ACTION_TYPES.FETCH_ULB_BY_ID, getUlb),
    takeLatest(ACTION_TYPES.SELECT_ULB_DISTRICT, dropdownDistrict),
    takeLatest(ACTION_TYPES.SELECT_LSGI_TYPE, lsgiTypeDropdown),
    takeLatest(ACTION_TYPES.DELETE_ULB_DETAILS, deleteUlbApi),
    takeLatest(ACTION_TYPES.LIST_ULB_DETAILS, listUlb),
    takeLatest(ACTION_TYPES.PATCH_ULB_DETAILS, patchUlbApi),
    takeLatest(ACTION_TYPES.FETCH_NAME_LIST, fetchNameList),
    takeLatest(ACTION_TYPES.FETCH_CODE_LIST, fetchCodeList)
  ]);
}
