import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import { getPayloadContent, getPayloadContentDetails } from "../../../utils/apiUtils";

const initialState = {
    certificateForm: {
        requestInProgress: false,
        data: {}
    },
    venueId: [],
    batch: [],
    template: [],
    lsgi: []
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.table = initialState.table;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.SAVE_CERTIFICATE_DETAILS, (state) => {
            _.set(state, "certificateForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.SAVE_CERTIFICATE_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "certificateForm.data", action.payload);
            _.set(state, "certificateForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.LIST_CERTIFICATE_DETAILS_REQUEST, (state) => {
            _.set(state, "certificateForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.LIST_CERTIFICATE_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "certificateForm.data", getPayloadContent(action));
            _.set(state, "certificateForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.FETCH_BATCH_SUCCESS, (state, action) => {
            _.set(state, "batch", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_DESIGNATION_SUCCESS, (state, action) => {
            _.set(state, "designation", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_TEMPLATE_SUCCESS, (state, action) => {
            _.set(state, "template", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_VENUE_SUCCESS, (state, action) => {
            _.set(state, "venueId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.EDIT_CERTIFICATE_SUCCESS, (state, action) => {
            _.set(state, "certificateForm.data", getPayloadContentDetails(action));
        });
        builder.addCase(ACTION_TYPES.DELETE_CERTIFICATE_SUCCESS, (state, action) => {
            _.set(state, "certificateForm.data", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.EDIT_CERTIFICATE_BY_ID_SUCCESS, (state, action) => {
            _.set(state, "certificateForm.data", getPayloadContentDetails(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, (state, action) => {
            _.set(state, "lsgi", getPayloadContent(action));
        });

    }
});
export const { actions, reducer } = slice;
