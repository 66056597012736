import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_REGIONAL_DISPOSAL_SITE_LIST: `${STATE_REDUCER_KEY}/FETCH_REGIONAL_DISPOSAL_SITE_LIST`,
    FETCH_REGIONAL_DISPOSAL_SITE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_REGIONAL_DISPOSAL_SITE_LIST_REQUEST`,
    FETCH_REGIONAL_DISPOSAL_SITE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_REGIONAL_DISPOSAL_SITE_LIST_SUCCESS`,
    FETCH_REGIONAL_DISPOSAL_SITE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_REGIONAL_DISPOSAL_SITE_LIST_FAILURE`,

    FETCH_REGIONAL_DISPOSAL_SITE_BY_ID: `${STATE_REDUCER_KEY}/FETCH_REGIONAL_DISPOSAL_SITE_BY_ID`,
    FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_REQUEST`,
    FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_SUCCESS`,
    FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_FAILURE`,

    POST_REGIONAL_DISPOSAL_SITE: `${STATE_REDUCER_KEY}/POST_REGIONAL_DISPOSAL_SITE`,
    POST_REGIONAL_DISPOSAL_SITE_REQUEST: `${STATE_REDUCER_KEY}/POST_REGIONAL_DISPOSAL_SITE_REQUEST`,
    POST_REGIONAL_DISPOSAL_SITE_SUCCESS: `${STATE_REDUCER_KEY}/POST_REGIONAL_DISPOSAL_SITE_SUCCESS`,
    POST_REGIONAL_DISPOSAL_SITE_FAILURE: `${STATE_REDUCER_KEY}/POST_REGIONAL_DISPOSAL_SITE_FAILURE`,

    PATCH_REGIONAL_DISPOSAL_SITE: `${STATE_REDUCER_KEY}/PATCH_REGIONAL_DISPOSAL_SITE`,
    PATCH_REGIONAL_DISPOSAL_SITE_REQUEST: `${STATE_REDUCER_KEY}/PATCH_REGIONAL_DISPOSAL_SITE_REQUEST`,
    PATCH_REGIONAL_DISPOSAL_SITE_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_REGIONAL_DISPOSAL_SITE_SUCCESS`,
    PATCH_REGIONAL_DISPOSAL_SITE_FAILURE: `${STATE_REDUCER_KEY}/PATCH_REGIONAL_DISPOSAL_SITE_FAILURE`,

    DELETE_REGIONAL_DISPOSAL_SITE: `${STATE_REDUCER_KEY}/DELETE_REGIONAL_DISPOSAL_SITE`,
    DELETE_REGIONAL_DISPOSAL_SITE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_REGIONAL_DISPOSAL_SITE_REQUEST`,
    DELETE_REGIONAL_DISPOSAL_SITE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_REGIONAL_DISPOSAL_SITE_SUCCESS`,
    DELETE_REGIONAL_DISPOSAL_SITE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_REGIONAL_DISPOSAL_SITE_FAILURE`,

    FETCH_SURROUNDING_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_SURROUNDING_DROPDOWN`,
    FETCH_SURROUNDING_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SURROUNDING_DROPDOWN_REQUEST`,
    FETCH_SURROUNDING_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SURROUNDING_DROPDOWN_SUCCESS`,
    FETCH_SURROUNDING_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SURROUNDING_DROPDOWN_FAILURE`
};

export const fetchRegionalDisposalSiteList = createAction(ACTION_TYPES.FETCH_REGIONAL_DISPOSAL_SITE_LIST);
export const fetchRegionalDisposalSiteById = createAction(ACTION_TYPES.FETCH_REGIONAL_DISPOSAL_SITE_BY_ID);
export const postRegionalDisposalSite = createAction(ACTION_TYPES.POST_REGIONAL_DISPOSAL_SITE);
export const patchRegionalDisposalSite = createAction(ACTION_TYPES.PATCH_REGIONAL_DISPOSAL_SITE);
export const deleteRegionalDisposalSite = createAction(ACTION_TYPES.DELETE_REGIONAL_DISPOSAL_SITE);
export const fetchSurroundingDropdown = createAction(ACTION_TYPES.FETCH_SURROUNDING_DROPDOWN);
