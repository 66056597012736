import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { fetchMarketApproachApi, fetchProcessStatusApi, fetchProcurementCategoryApi, fetchProcurementMethodApi, fetchReviewTypeApi, fetchVendorApi, listProjectApi, patchProcurementActivity, postProcurementActivity, procurementActivitiesListApi, procurementListingByIdApi, projectListingByIdApi, updateImortedDataApi } from "./api";
import { } from "./api";
import { ACTION_TYPES } from "./actions";
import { setCommonTableData } from "utils/tableUtils";
import { successNotify } from "utils/notificationUtils";
import { I18n } from "common/components";
import { actions as commonActions } from "../../common/slice";
import { actions as sliceActions } from "./slice";
import { getTableProps } from "modules/common/selectors";
import { TABLE_IDS } from "common/constants";
import { toEpoch } from "utils/dateUtils";

export function* procurementActivitiesList({ payload }) {
  let projectId = payload.id;
  let pageNo = payload.pageNo;
  let pageSize = payload.pageSize;
  let tableProps = yield select(getTableProps);
  let { [TABLE_IDS.PROCUREMENT_ACTIVITIES]: { filters = {} } = {} } = tableProps;
  yield fork(handleAPIRequest, procurementActivitiesListApi, {
    ...filters, pageNo, pageSize, projectId
  });
  const COMPARISON_TYPES = [ACTION_TYPES.LIST_PROCUREMENT_ACTIVITIES_SUCCESS, ACTION_TYPES.LIST_PROCUREMENT_ACTIVITIES_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.PROCUREMENT_ACTIVITIES);
}

export function* submitProcurementActivity({ payload }) {
  yield fork(handleAPIRequest, postProcurementActivity, payload);
  yield put(sliceActions.setCreateProcurementActivity(payload));
  const responseAction = yield take([ACTION_TYPES.SAVE_PROCUREMENT_ACTIVITIES_SUCCESS, ACTION_TYPES.SAVE_PROCUREMENT_ACTIVITIES_FAILURE]);
  if (responseAction.type === ACTION_TYPES.SAVE_PROCUREMENT_ACTIVITIES_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("procurement") }) }));
    yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
    yield put(sliceActions.closeModal());
    yield put(sliceActions.disableOnSuccess());
    let tablePayload = { pageNo: 0, pageSize: 5, id: payload.projectId };
    yield call(procurementActivitiesList, { payload: tablePayload });
    yield put(sliceActions.resetCreateProcurementActivity());
  }
}

export function* listProject({ payload }) {
  let tableProps = yield select(getTableProps);
  let { [TABLE_IDS.PROJECT_LIST]: { filters = {} } = {} } = tableProps;
  yield fork(handleAPIRequest, listProjectApi, { ...filters, ...payload });
  const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PROJECT_LISTING_SUCCESS, ACTION_TYPES.FETCH_PROJECT_LISTING_FAILURE];
  yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.PROJECT_LIST);
}

export function* projectListingById({ payload: id }) {
  yield call(handleAPIRequest, projectListingByIdApi, id);
}

export function* fetchProcessStatus() {
  yield call(handleAPIRequest, fetchProcessStatusApi);
}

export function* fetchProcurementMethod() {
  yield call(handleAPIRequest, fetchProcurementMethodApi);
}

export function* fetchReviewType() {
  yield call(handleAPIRequest, fetchReviewTypeApi);
}

export function* fetchProcurementCategory() {
  yield call(handleAPIRequest, fetchProcurementCategoryApi);
}

export function* fetchMarketApproach() {
  yield call(handleAPIRequest, fetchMarketApproachApi);
}

export function* fetchVendor() {
  yield call(handleAPIRequest, fetchVendorApi);
}

export function* procurementActivitiesById({ payload: id }) {
  yield call(handleAPIRequest, procurementListingByIdApi, id);
}

export function* updateImportedData({ payload }) {
  const updatedPayload = {
    id: payload.id,
    activityQuestionnaire: payload.activityQuestionnaire,
    totalAmountPaid: payload?.totalAmountPaid,
    envCategoryId: payload?.envCategoryId,
    bidAttachments: payload?.bidAttachments,
    loaAttachments: payload?.loaAttachments,
    contractAttachments: payload?.contractAttachments,
    woOrPoDate: toEpoch(payload?.woOrPoDate),
    woOrPoNumber: payload?.woOrPoNumber,
    woPoValue: payload?.woPoValue
  };
  updatedPayload.activityQuestionnaire = updatedPayload.activityQuestionnaire.filter(item => item.id);
  const updatedActivityQuestionnaire = updatedPayload.activityQuestionnaire.map(item => ({
    ...item,
    plannedDate: item?.plannedDate ? toEpoch(item.plannedDate) : null,
    actualDate: item?.actualDate ? toEpoch(item.actualDate) : null
  }));
  updatedPayload.activityQuestionnaire = updatedActivityQuestionnaire;
  yield fork(handleAPIRequest, updateImortedDataApi, updatedPayload);
  const responseAction = yield take([ACTION_TYPES.UPDATE_IMPORTED_DATA_SUCCESS, ACTION_TYPES.UPDATE_IMPORTED_DATA_FAILURE]);
  if (responseAction.type === ACTION_TYPES.UPDATE_IMPORTED_DATA_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("procurement_activities") }) }));
    yield call(procurementActivitiesById, { payload: payload.id });
  }
}

export function* updateProcurementActivityList({ payload }) {
  yield fork(handleAPIRequest, patchProcurementActivity, payload);
  const responseAction = yield take([ACTION_TYPES.UPDATE_PROCUREMENT_ACTIVITIES_SUCCESS, ACTION_TYPES.UPDATE_PROCUREMENT_ACTIVITIES_FAILURE]);
  if (responseAction.type === ACTION_TYPES.UPDATE_PROCUREMENT_ACTIVITIES_SUCCESS) {
    yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("procurement") }) }));
    yield call(handleAPIRequest, procurementListingByIdApi, payload?.id);
    yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
    yield put(sliceActions.closeModal());
    let tablePayload = { pageNo: 0, pageSize: 5, id: payload.projectId };
    yield call(procurementActivitiesList, { payload: tablePayload });
  }
}

export default function* testSaga() {
  yield all([
    takeLatest(ACTION_TYPES.SAVE_PROCUREMENT_ACTIVITIES, submitProcurementActivity),
    takeLatest(ACTION_TYPES.LIST_PROCUREMENT_ACTIVITIES, procurementActivitiesList),
    takeLatest(ACTION_TYPES.FETCH_PROJECT_LISTING, listProject),
    takeLatest(ACTION_TYPES.PROJECT_LISTING_BY_ID, projectListingById),
    takeLatest(ACTION_TYPES.SELECT_PROCESS_STATUS, fetchProcessStatus),
    takeLatest(ACTION_TYPES.SELECT_PROCUREMENT_METHOD, fetchProcurementMethod),
    takeLatest(ACTION_TYPES.SELECT_REVIEW_TYPE, fetchReviewType),
    takeLatest(ACTION_TYPES.SELECT_MARKET_APPROACH, fetchMarketApproach),
    takeLatest(ACTION_TYPES.SELECT_CATEGORY, fetchProcurementCategory),
    takeLatest(ACTION_TYPES.SELECT_VENDOR, fetchVendor),
    takeLatest(ACTION_TYPES.PROCUREMENT_ACTIVITIES_LISTING_BY_ID, procurementActivitiesById),
    takeLatest(ACTION_TYPES.UPDATE_IMPORTED_DATA, updateImportedData),
    takeLatest(ACTION_TYPES.UPDATE_PROCUREMENT_ACTIVITIES, updateProcurementActivityList)
  ]);
}
