import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_TRANSFER_STATION_DETAILS: `${STATE_REDUCER_KEY}/FETCH_TRANSFER_STATION_DETAILS`,
    FETCH_TRANSFER_STATION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TRANSFER_STATION_DETAILS_REQUEST`,
    FETCH_TRANSFER_STATION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TRANSFER_STATION_DETAILS_SUCCESS`,
    FETCH_TRANSFER_STATION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TRANSFER_STATION_DETAILS_FAILURE`,

    TYPE_OF_WASTE_DROPDOWN: `${STATE_REDUCER_KEY}/TYPE_OF_WASTE_DROPDOWN`,
    TYPE_OF_WASTE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/TYPE_OF_WASTE_DROPDOWN_REQUEST`,
    TYPE_OF_WASTE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/TYPE_OF_WASTE_DROPDOWN_SUCCESS`,
    TYPE_OF_WASTE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/TYPE_OF_WASTE_DROPDOWN_FAILURE`,

    GET_MCF_RRF_SITE_NAME_DROPDOWN: `${STATE_REDUCER_KEY}/GET_MCF_RRF_SITE_NAME_DROPDOWN`,
    GET_MCF_RRF_SITE_NAME_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_MCF_RRF_SITE_NAME_DROPDOWN_REQUEST`,
    GET_MCF_RRF_SITE_NAME_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_MCF_RRF_SITE_NAME_DROPDOWN_SUCCESS`,
    GET_MCF_RRF_SITE_NAME_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_MCF_RRF_SITE_NAME_DROPDOWN_FAILURE`,

    GET_TRANSFER_STATION_INFORMATION_BY_ID: `${STATE_REDUCER_KEY}/GET_TRANSFER_STATION_INFORMATION_BY_ID`,
    GET_TRANSFER_STATION_INFORMATION_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_TRANSFER_STATION_INFORMATION_BY_ID_REQUEST`,
    GET_TRANSFER_STATION_INFORMATION_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_TRANSFER_STATION_INFORMATION_BY_ID_SUCCESS`,
    GET_TRANSFER_STATION_INFORMATION_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_TRANSFER_STATION_INFORMATION_BY_ID_FAILURE`,

    POST_TRANSFER_STATION_INFORMATION: `${STATE_REDUCER_KEY}/POST_TRANSFER_STATION_INFORMATION`,
    POST_TRANSFER_STATION_INFORMATION_REQUEST: `${STATE_REDUCER_KEY}/POST_TRANSFER_STATION_INFORMATION_REQUEST`,
    POST_TRANSFER_STATION_INFORMATION_SUCCESS: `${STATE_REDUCER_KEY}/POST_TRANSFER_STATION_INFORMATION_SUCCESS`,
    POST_TRANSFER_STATION_INFORMATION_FAILURE: `${STATE_REDUCER_KEY}/POST_TRANSFER_STATION_INFORMATION_FAILURE`,

    PATCH_TRANSFER_STATION_INFORMATION: `${STATE_REDUCER_KEY}/PATCH_TRANSFER_STATION_INFORMATION`,
    PATCH_TRANSFER_STATION_INFORMATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_TRANSFER_STATION_INFORMATION_REQUEST`,
    PATCH_TRANSFER_STATION_INFORMATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_TRANSFER_STATION_INFORMATION_SUCCESS`,
    PATCH_TRANSFER_STATION_INFORMATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_TRANSFER_STATION_INFORMATION_FAILURE`,

    DELETE_TRANSFER_STATION_INFORMATION: `${STATE_REDUCER_KEY}/DELETE_TRANSFER_STATION_INFORMATION`,
    DELETE_TRANSFER_STATION_INFORMATION_REQUEST: `${STATE_REDUCER_KEY}/DELETE_TRANSFER_STATION_INFORMATION_REQUEST`,
    DELETE_TRANSFER_STATION_INFORMATION_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_TRANSFER_STATION_INFORMATION_SUCCESS`,
    DELETE_TRANSFER_STATION_INFORMATION_FAILURE: `${STATE_REDUCER_KEY}/DELETE_TRANSFER_STATION_INFORMATION_FAILURE`,

    GET_FROM_DISTRICT_DROPDOWN: `${STATE_REDUCER_KEY}/GET_FROM_DISTRICT_DROPDOWN`,
    GET_FROM_DISTRICT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_FROM_DISTRICT_DROPDOWN_REQUEST`,
    GET_FROM_DISTRICT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_FROM_DISTRICT_DROPDOWN_SUCCESS`,
    GET_FROM_DISTRICT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_FROM_DISTRICT_DROPDOWN_FAILURE`,

    GET_FROM_LSGI_DROPDOWN: `${STATE_REDUCER_KEY}/GET_FROM_LSGI_DROPDOWN`,
    GET_FROM_LSGI_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_FROM_LSGI_DROPDOWN_REQUEST`,
    GET_FROM_LSGI_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_FROM_LSGI_DROPDOWN_SUCCESS`,
    GET_FROM_LSGI_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_FROM_LSGI_DROPDOWN_FAILURE`,

    DOWNLOAD_TRANSFER_STATION_DETAILS: `${STATE_REDUCER_KEY}/DOWNLOAD_TRANSFER_STATION_DETAILS`,
    DOWNLOAD_TRANSFER_STATION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_TRANSFER_STATION_DETAILS_REQUEST`,
    DOWNLOAD_TRANSFER_STATION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_TRANSFER_STATION_DETAILS_SUCCESS`,
    DOWNLOAD_TRANSFER_STATION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_TRANSFER_STATION_DETAILS_FAILURE`
};

export const fetchTransferStation = createAction(ACTION_TYPES.FETCH_TRANSFER_STATION_DETAILS);
export const typeOfWasteDropdown = createAction(ACTION_TYPES.TYPE_OF_WASTE_DROPDOWN);
export const getMcfRrfSiteNameDropdown = createAction(ACTION_TYPES.GET_MCF_RRF_SITE_NAME_DROPDOWN);
export const getTransferStationInformationById = createAction(ACTION_TYPES.GET_TRANSFER_STATION_INFORMATION_BY_ID);
export const postTransferStation = createAction(ACTION_TYPES.POST_TRANSFER_STATION_INFORMATION);
export const patchTransferStation = createAction(ACTION_TYPES.PATCH_TRANSFER_STATION_INFORMATION);
export const deleteTransferStationInformation = createAction(ACTION_TYPES.DELETE_TRANSFER_STATION_INFORMATION);
export const getFromDistrictDropdown = createAction(ACTION_TYPES.GET_FROM_DISTRICT_DROPDOWN);
export const getFromLsgiDropdown = createAction(ACTION_TYPES.GET_FROM_LSGI_DROPDOWN);
export const downloadTransferStation = createAction(ACTION_TYPES.DOWNLOAD_TRANSFER_STATION_DETAILS);


