import { createSlice } from "@reduxjs/toolkit";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import _ from "lodash";
import { getPayloadContent } from "utils/apiUtils";

const COMPOSITION_TEAM_DETAILS = {
    memberName: "",
    mobileNumber: "",
    designation: "",
    areaOfExpertise: ""
};

const initialState = {
    fetchCompositionOfTeamById: {
        requestInProgress: false,
        data: {
            ...COMPOSITION_TEAM_DETAILS
        }
    },
    fetchCompositionOfTeamList: {
        requestInProgress: false,
        data: {
            ...COMPOSITION_TEAM_DETAILS
        }
    }
};
const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clear: () => initialState,
        clearValue: (state) => {
            state.fetchCompositionOfTeamById = initialState.fetchCompositionOfTeamById;
        },
        setDetails: (state, action) => {
            state.fetchCompositionOfTeamById.data = action.payload;
        },
        resetDetails: (state) => {
            state.fetchCompositionOfTeamById.data = initialState.fetchCompositionOfTeamById.data;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_REQUEST, (state) => {
            state.fetchCompositionOfTeamList.requestInProgress = true;
        })
            .addCase(ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_SUCCESS, (state, action) => {
                state.fetchCompositionOfTeamList = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_FAILURE, (state) => {
                state.fetchCompositionOfTeamList.requestInProgress = false;
                state.error = "Something went wrong";
            })
            .addCase(ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "fetchCompositionOfTeamById.requestInProgress", false);
                _.set(state, "fetchCompositionOfTeamById.data", payload.content[0]);
            });
    }
});

export const { actions, reducer } = slice;
