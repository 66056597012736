import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";


const initialState = {
    natureOfWorkDropdown: {
        requestInProgress: false,
        data: []
    },
    wagePeriodDropdown: {
        requestInProgress: false,
        data: []
    },
    dayOfHolidayDropdown: {
        requestInProgress: false,
        data: []
    },
    districtDropdown: {
        requestInProgress: false,
        data: []
    },
    ulbDropdown: {
        requestInProgress: false,
        data: []
    },
    basicDetailsById: {
        requestInProgress: false,
        data: {
            id: "",
            name: "",
            projectId: "",
            projectName: "",
            houseNo: "",
            streetNo: "",
            town: "",
            districtId: null,
            lsgiId: null,
            pincode: "",
            natureOfWork: null,
            commencementDate: null,
            wagePeriod: null,
            holiday: null,
            isWeekoffWageConsider: false,
            adolescentRestHours: "",
            adolescentWorkHours: "",
            adultWorkHours: ""
        }
    },
    setProjectName: {
        requestInProgress: false,
        data: {
            projectName: ""
        }
    },
    setProjectId: {
        requestInProgress: false,
        data: {
            projectId: ""
        }
    },
    projectManagerDetails: {
        requestInProgress: false,
        data: {
            id: "",
            managerName: "",
            mobileNumber: "",
            telephoneNumber: "",
            email: "",
            houseNo: "",
            streetNo: "",
            town: "",
            districtId: null,
            pincode: ""
        }
    },
    projectManagerList: {
        requestInProgress: false,
        data: {
            id: "",
            managerName: "",
            mobileNumber: "",
            telephoneNumber: "",
            email: "",
            houseNo: "",
            streetNo: "",
            town: "",
            districtId: null,
            pincode: ""
        }
    },
    minimumWagesList: {
        requestInProgress: false,
        data: []
    },
    previousMinimumWagesList: {
        requestInProgress: false,
        data: []
    },
    genderDropDown: {
        requestInProgress: false,
        data: []
    },
    nationalityDropDown: {
        requestInProgress: false,
        data: []
    },
    educationDropDown: {
        requestInProgress: false,
        data: []
    },
    designationDropDown: {
        requestInProgress: false,
        data: []
    },
    categoryDropDown: {
        requestInProgress: false,
        data: []
    },
    employmentDropDown: {
        requestInProgress: false,
        data: []
    },
    subContractDropDown: {
        requestInProgress: false,
        data: []
    },
    workmanRadio: {
        requestInProgress: false,
        data: []
    },
    employeeDetails: {
        requestInProgress: false,
        data: {
            employeeCode: "",
            firstName: "",
            surname: "",
            gender: "",
            fatherName: "",
            maritalStatus: "",
            dateOfBirth: "",
            presentAddress: "",
            permanentAddress: "",
            nationality: "",
            educationalLevel: "",
            dateOfJoin: "",
            designation: "",
            catagory: "",
            typeOfEmployment: "",
            workmanInterstate: false,
            setOfwork: "",
            mobile: "",
            uan: "",
            panNum: "",
            esic: "",
            basicRate: "",
            daRate: "",
            overTimeRate: "",
            lwf: "",
            identification: "",
            bank: false,
            acNum: "",
            confirmAcNum: "",
            bankName: "",
            ifscNum: "",
            permanentCheckbox: "",
            shram: "",
            aadhaarNum: "",
            rationCardNum: "",
            naEsic: false,
            naLwf: false,
            naAadhaar: false,
            photo: []
        }
    },
    maritalRadio: {
        requestInProgress: false,
        data: []
    },
    registrationNumber: {
        requestInProgress: false,
        data: []
    },
    registrationNumberById: {
        requestInProgress: false,
        data: {
            id: "",
            siteId: "",
            licenseId: "",
            licenseName: "",
            licenseNumber: "",
            commencedOnDate: null,
            expiryDate: null
        }
    },
    basicFacilitiesById: {
        requestInProgress: false,
        data: {
            id: "",
            canteen: "",
            restrooms: "",
            drinkingWater: "",
            creches: "",
            firstaid: ""
        }
    },
    grievanceCommitteeById: {
        requestInProgress: false,
        data: {
            id: "",
            siteId: "",
            firstName: "",
            lastName: "",
            attachments: []
        }
    },
    noticeBoardById: {
        requestInProgress: false,
        data: {
            id: "",
            minimumWagesAct: "",
            paymentOfWagesAct: "",
            employeeCompensationAct: "",
            recsAct: "",
            contractLabourAct: "",
            childAndAdolescentLabourAct: "",
            maternityBenefitAct: "",
            attachmentIds: []
        }
    },
    complaintDesignationDropDown: {
        requestInProgress: false,
        data: []
    },
    complaintCommitteeById: {
        requestInProgress: false,
        data: {
            id: "",
            siteId: "",
            firstName: "",
            lastName: "",
            contact: "",
            designation: "",
            attachments: []
        }
    },
    wageDetails: {
        requestInProgress: false,
        data: {
            startDate: "",
            amount: "",
            overTimeRate: ""
        }
    },
    siteWageEmployeeDetails: {
        requestInProgress: false,
        data: {
            wageRate: "",
            overTimeRate: "",
            daysWorked: "",
            overTimeHours: "",
            basicPay: "",
            specialBasicPay: "",
            da: "",
            basicOverTimePay: "",
            hra: "",
            otherBasicPay: "",
            pf: "",
            esic: "",
            insurance: "",
            society: "",
            incomeTax: "",
            otherDeductions: "",
            recoveries: "",
            totalBasic: "",
            totalDeduction: "",
            netPayment: "",
            completionStatus: ""
        }
    },
    cardCountInWageRegister: {
        requestInProgress: false,
        data: []
    },
    verification: {
        requestInProgress: false,
        data: {
            employeeVerificationDto: []
        }
    },
    employeeNameDropdown: {
        requestInProgress: false,
        data: []
    },
    employeeDetailsById: {
        requestInProgress: false,
        data: []
    },
    fatalDropdown: {
        requestInProgress: false,
        data: []
    },
    workplaceAccidentDetails: {
        requestInProgress: false,
        data: {
            employeeGeneralDetails: "",
            dateTime: "",
            locationOfAccident: "",
            indexEmployeeYesOrNo: "",
            summerisedDetails: ""
        }
    },
    accordionDetails: {
        requestInProgress: false,
        data: {
            whetherSubmitted: "",
            submissionDate: "",
            attachments: []
        }
    },
    responseDetails: {
        requestInProgress: false,
        data: {
            whetherSubmitted: "",
            submissionDate: "",
            attachments: []
        }
    },
    complaintDetails: {
        requestInProgress: false,
        data: {
            whetherSubmitted: "",
            submissionDate: "",
            attachments: []
        }
    },
    hearingDetails: {
        requestInProgress: false,
        data: {
            whetherSubmitted: "",
            submissionDate: "",
            attachments: []
        }
    },
    harassmentDetailsById: {
        requestInProgress: false,
        data: {
            icc: "",
            dateTime: "",
            dateOfReceipt: "",
            location: ""
        }
    },
    cardCountInAttendanceRegister: {
        requestInProgress: false,
        data: []
    },
    employeeAttendanceList: {
        requestInProgress: false,
        data: []
    },
    typeDropdown: {
        requestInProgress: false,
        data: []
    },
    attendanceById: {
        requestInProgress: false,
        data: {
            attendanceType: "",
            inTime: "",
            outTime: "",
            overTime: ""
        }
    },
    newPage: 1,
    employeeName: {
        requestInProgress: false,
        data: []
    },
    recoveryType: {
        requestInProgress: false,
        data: []
    },
    incidentEmployeeDetails: {
        requestInProgress: false,
        data: []
    },
    recoveryDetails: {
        requestInProgress: false,
        data: {
            employee: "",
            age: "",
            esic: "",
            employeeAddress: "",
            recoveryType: "",
            amount: "",
            showCausedIssue: {},
            presenceOf: "",
            particulars: ""
        }
    },
    causedIssue: {
        requestInProgress: false,
        data: []
    },
    maternityType: {
        requestInProgress: false,
        data: []
    },
    maternityDetails: {
        requestInProgress: false,
        data: {
            employeeGeneralDetails: "",
            indexTypesOfMaternity: "",
            startDate: "",
            endDate: ""
        }
    },
    currentTab: 0,
    verificationStatusDropdown: {
        requestInProgress: false,
        data: []
    },
    eventsTracked: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        setBasicDetailsById: (state, action) => {
            state.basicDetailsById.data = action.payload;
        },
        clearBasicDetailsById: (state) => {
            state.basicDetailsById.data = initialState.basicDetailsById.data;
        },
        setProjectName: (state, action) => {
            state.setProjectName.data = action.payload;
        },
        setProjectId: (state, action) => {
            state.setProjectId.data = action.payload;
        },
        setNewPage: (state, action) => {
            state.newPage = action.payload;
        },
        clearSetNewPage: (state) => {
            state.newPage = initialState.newPage;
        },
        clearProjectManagerDetailsById: (state) => {
            state.projectManagerDetails.data = initialState.projectManagerDetails.data;
        },
        clearProjectManagerList: (state) => {
            state.projectManagerList.data = initialState.projectManagerList.data;
        },
        setEmployeeDetails: (state, action) => {
            state.employeeDetails.data = action.payload;
        },
        resetEmployeeDetails: (state) => {
            state.employeeDetails.data = initialState.employeeDetails.data;
        },
        clearRegistrationNumber: (state) => {
            state.registrationNumber.data = initialState.registrationNumber.data;
        },
        clearRegistrationNumberById: (state) => {
            state.registrationNumberById = initialState.registrationNumberById;
        },
        clearBasicFacilitiesById: (state) => {
            state.basicFacilitiesById.data = initialState.basicFacilitiesById.data;
        },
        setGrievanceCommitteeById: (state, action) => {
            state.grievanceCommitteeById.data = action?.payload;
        },
        clearGrievanceCommitteeById: (state) => {
            state.grievanceCommitteeById.data = initialState.grievanceCommitteeById.data;
        },
        clearNoticeBoardById: (state) => {
            state.noticeBoardById.data = initialState.noticeBoardById.data;
        },
        setComplaintCommitteeById: (state, action) => {
            state.complaintCommitteeById.data = action?.payload;
        },
        clearComplaintCommitteeById: (state) => {
            state.complaintCommitteeById = initialState.complaintCommitteeById;
        },
        clearSiteWageEmployeeDetails: (state) => {
            state.siteWageEmployeeDetails.data = initialState.siteWageEmployeeDetails.data;
        },
        clearAttendanceById: (state) => {
            state.attendanceById.data = initialState.attendanceById.data;
        },
        clearEmployeeAttendanceList: (state) => {
            state.employeeAttendanceList.data = initialState.employeeAttendanceList.data;
        },
        clearRecoveryDetails: (state) => {
            state.recoveryDetails = initialState.recoveryDetails;
        },
        clearIncidentEmployeeDetails: (state) => {
            state.incidentEmployeeDetails = initialState.incidentEmployeeDetails;
        },
        clearMaternityTypesDetails: (state) => {
            state.maternityDetails = initialState.maternityDetails;
        },
        clearEmployeeVerification: (state) => {
            state.verification = initialState.verification;
        },
        setCurrentTab: (state, action) => {
            state.currentTab = action?.payload;
        },
        clearHarassmentDetails: (state) => {
            state.harassmentDetailsById = initialState.harassmentDetailsById;
        },
        clearCurrentTab: (state) => {
            state.currentTab = initialState.currentTab;
        },
        clearEventTrack: (state) => {
            state.eventsTracked.data = initialState.eventsTracked.data;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_NATURE_OF_WORK_DROPDOWN_REQUEST, (state) => {
                state.natureOfWorkDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_NATURE_OF_WORK_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "natureOfWorkDropdown.requestInProgress", false);
                _.set(state, "natureOfWorkDropdown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_NATURE_OF_WORK_DROPDOWN_FAILURE, (state) => {
                state.natureOfWorkDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_WAGE_PERIOD_DROPDOWN_REQUEST, (state) => {
                state.wagePeriodDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_WAGE_PERIOD_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "wagePeriodDropdown.requestInProgress", false);
                _.set(state, "wagePeriodDropdown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_WAGE_PERIOD_DROPDOWN_FAILURE, (state) => {
                state.wagePeriodDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_DAY_OF_HOLIDAY_DROPDOWN_REQUEST, (state) => {
                state.dayOfHolidayDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_DAY_OF_HOLIDAY_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "dayOfHolidayDropdown.requestInProgress", false);
                _.set(state, "dayOfHolidayDropdown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_DAY_OF_HOLIDAY_DROPDOWN_FAILURE, (state) => {
                state.dayOfHolidayDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_REQUEST, (state) => {
                state.districtDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "districtDropdown.requestInProgress", false);
                _.set(state, "districtDropdown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_FAILURE, (state) => {
                state.districtDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_ULB_DROPDOWN_REQUEST, (state) => {
                state.ulbDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_ULB_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "ulbDropdown.requestInProgress", false);
                _.set(state, "ulbDropdown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_ULB_DROPDOWN_FAILURE, (state) => {
                state.ulbDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_BASIC_DETAILS_BY_ID_REQUEST, (state) => {
                state.basicDetailsById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_BASIC_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "basicDetailsById.requestInProgress", false);
                _.set(state, "basicDetailsById.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_BASIC_DETAILS_BY_ID_FAILURE, (state) => {
                state.basicDetailsById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_PROJECT_MANAGER_DETAILS_BY_ID_REQUEST, (state) => {
                state.projectManagerDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_PROJECT_MANAGER_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "projectManagerDetails.requestInProgress", false);
                _.set(state, "projectManagerDetails.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_PROJECT_MANAGER_DETAILS_BY_ID_FAILURE, (state) => {
                state.projectManagerDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_PROJECT_MANAGER_LIST_REQUEST, (state) => {
                state.projectManagerList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_PROJECT_MANAGER_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "projectManagerList.requestInProgress", false);
                _.set(state, "projectManagerList.data", payload?.content?.length > 0 ? payload?.content[0] : initialState?.projectManagerList?.data);
            })
            .addCase(ACTION_TYPES.FETCH_PROJECT_MANAGER_LIST_FAILURE, (state) => {
                state.projectManagerList.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_MINIMUM_WAGE_REQUEST, (state) => {
                state.minimumWagesList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_MINIMUM_WAGE_SUCCESS, (state, { payload }) => {
                _.set(state, "minimumWagesList.requestInProgress", false);
                _.set(state, "minimumWagesList.data", payload?.content[payload?.content?.length - 1]);
                _.set(state, "previousMinimumWagesList.requestInProgress", false);
                _.set(state, "previousMinimumWagesList.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_MINIMUM_WAGE_FAILURE, (state) => {
                state.minimumWagesList.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_GENDER_DROPDOWN_REQUEST, (state) => {
                state.genderDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_GENDER_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "genderDropDown.requestInProgress", false);
                _.set(state, "genderDropDown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_GENDER_DROPDOWN_FAILURE, (state) => {
                state.genderDropDown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_NATIONALITY_DROPDOWN_REQUEST, (state) => {
                state.nationalityDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_NATIONALITY_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "nationalityDropDown.requestInProgress", false);
                _.set(state, "nationalityDropDown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_NATIONALITY_DROPDOWN_FAILURE, (state) => {
                state.nationalityDropDown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EDUCATION_LEVEL_DROPDOWN_REQUEST, (state) => {
                state.educationDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EDUCATION_LEVEL_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "educationDropDown.requestInProgress", false);
                _.set(state, "educationDropDown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_EDUCATION_LEVEL_DROPDOWN_FAILURE, (state) => {
                state.educationDropDown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN_REQUEST, (state) => {
                state.designationDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "designationDropDown.requestInProgress", false);
                _.set(state, "designationDropDown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN_FAILURE, (state) => {
                state.designationDropDown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_CATEGORY_DROPDOWN_REQUEST, (state) => {
                state.categoryDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_CATEGORY_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "categoryDropDown.requestInProgress", false);
                _.set(state, "categoryDropDown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_CATEGORY_DROPDOWN_FAILURE, (state) => {
                state.categoryDropDown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYMENT_DROPDOWN_REQUEST, (state) => {
                state.employmentDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYMENT_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "employmentDropDown.requestInProgress", false);
                _.set(state, "employmentDropDown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYMENT_DROPDOWN_FAILURE, (state) => {
                state.employmentDropDown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_SUB_CONTRACTOR_DROPDOWN_REQUEST, (state) => {
                state.subContractDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_SUB_CONTRACTOR_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "subContractDropDown.requestInProgress", false);
                _.set(state, "subContractDropDown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_SUB_CONTRACTOR_DROPDOWN_FAILURE, (state) => {
                state.subContractDropDown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_WORKMAN_RADIO_BUTTON_REQUEST, (state) => {
                state.workmanRadio.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_WORKMAN_RADIO_BUTTON_SUCCESS, (state, { payload }) => {
                _.set(state, "workmanRadio.requestInProgress", false);
                _.set(state, "workmanRadio.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_WORKMAN_RADIO_BUTTON_FAILURE, (state) => {
                state.workmanRadio.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.EMPLOYEE_DETAILS_BY_ID_REQUEST, (state) => {
                state.employeeDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.EMPLOYEE_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "employeeDetails.requestInProgress", false);
                _.set(state, "employeeDetails.data", payload);
            })
            .addCase(ACTION_TYPES.EMPLOYEE_DETAILS_BY_ID_FAILURE, (state) => {
                state.employeeDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_MARITAL_STATUS_RADIO_BUTTON_REQUEST, (state) => {
                state.maritalRadio.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_MARITAL_STATUS_RADIO_BUTTON_SUCCESS, (state, { payload }) => {
                _.set(state, "maritalRadio.requestInProgress", false);
                _.set(state, "maritalRadio.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_MARITAL_STATUS_RADIO_BUTTON_FAILURE, (state) => {
                state.maritalRadio.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_REGISTRATION_NUMBER_REQUEST, (state) => {
                state.registrationNumber.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_REGISTRATION_NUMBER_SUCCESS, (state, { payload }) => {
                _.set(state, "registrationNumber.requestInProgress", false);
                _.set(state, "registrationNumber.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_REGISTRATION_NUMBER_FAILURE, (state) => {
                state.registrationNumber.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_REGISTRATION_NUMBER_BY_ID_REQUEST, (state) => {
                state.registrationNumberById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_REGISTRATION_NUMBER_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "registrationNumberById.requestInProgress", false);
                _.set(state, "registrationNumberById.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_REGISTRATION_NUMBER_BY_ID_FAILURE, (state) => {
                state.registrationNumberById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_BASIC_FACILITIES_BY_ID_REQUEST, (state) => {
                state.basicFacilitiesById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_BASIC_FACILITIES_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "basicFacilitiesById.requestInProgress", false);
                _.set(state, "basicFacilitiesById.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_BASIC_FACILITIES_BY_ID_FAILURE, (state) => {
                state.basicFacilitiesById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_BY_ID_REQUEST, (state) => {
                state.grievanceCommitteeById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "grievanceCommitteeById.requestInProgress", false);
                _.set(state, "grievanceCommitteeById.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_BY_ID_FAILURE, (state) => {
                state.grievanceCommitteeById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_NOTICE_BOARD_BY_ID_REQUEST, (state) => {
                state.noticeBoardById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_NOTICE_BOARD_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "noticeBoardById.requestInProgress", false);
                _.set(state, "noticeBoardById.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_NOTICE_BOARD_BY_ID_FAILURE, (state) => {
                state.noticeBoardById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_REQUEST, (state) => {
                state.complaintDesignationDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "complaintDesignationDropDown.requestInProgress", false);
                _.set(state, "complaintDesignationDropDown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_FAILURE, (state) => {
                state.complaintDesignationDropDown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_REQUEST, (state) => {
                state.complaintCommitteeById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "complaintCommitteeById.requestInProgress", false);
                _.set(state, "complaintCommitteeById.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_FAILURE, (state) => {
                state.complaintCommitteeById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_WAGE_DETAILS_BY_ID_REQUEST, (state) => {
                state.wageDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_WAGE_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "wageDetails.requestInProgress", false);
                _.set(state, "wageDetails.data", payload);
            })
            .addCase(ACTION_TYPES.GET_WAGE_DETAILS_BY_ID_FAILURE, (state) => {
                state.wageDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_REQUEST, (state) => {
                state.siteWageEmployeeDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "siteWageEmployeeDetails.requestInProgress", false);
                _.set(state, "siteWageEmployeeDetails.data", payload);
            })
            .addCase(ACTION_TYPES.GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_FAILURE, (state) => {
                state.siteWageEmployeeDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_CARD_COUNT_IN_WAGE_REGISTER_REQUEST, (state) => {
                state.cardCountInWageRegister.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_CARD_COUNT_IN_WAGE_REGISTER_SUCCESS, (state, { payload }) => {
                _.set(state, "cardCountInWageRegister.requestInProgress", false);
                _.set(state, "cardCountInWageRegister.data", payload);
            })
            .addCase(ACTION_TYPES.GET_CARD_COUNT_IN_WAGE_REGISTER_FAILURE, (state) => {
                state.cardCountInWageRegister.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYEE_VERIFICATION_REQUEST, (state) => {
                state.verification.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYEE_VERIFICATION_SUCCESS, (state, { payload }) => {
                _.set(state, "verification.requestInProgress", false);
                _.set(state, "verification.data.employeeVerificationDto", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYEE_VERIFICATION_FAILURE, (state) => {
                state.verification.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_EMPLOYEE_NAME_WITH_CODE_REQUEST, (state) => {
                state.employeeNameDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_EMPLOYEE_NAME_WITH_CODE_SUCCESS, (state, { payload }) => {
                _.set(state, "employeeNameDropdown.requestInProgress", false);
                _.set(state, "employeeNameDropdown.data", payload.content);
            })
            .addCase(ACTION_TYPES.GET_EMPLOYEE_NAME_WITH_CODE_FAILURE, (state) => {
                state.employeeNameDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_EMPLOYEE_DETAILS_BY_ID_REQUEST, (state) => {
                state.employeeDetailsById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_EMPLOYEE_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "employeeDetailsById.requestInProgress", false);
                _.set(state, "employeeDetailsById.data", payload);
            })
            .addCase(ACTION_TYPES.GET_EMPLOYEE_DETAILS_BY_ID_FAILURE, (state) => {
                state.employeeDetailsById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_FATAL_DROPDOWN_REQUEST, (state) => {
                state.fatalDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_FATAL_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "fatalDropdown.requestInProgress", false);
                _.set(state, "fatalDropdown.data", payload.content);
            })
            .addCase(ACTION_TYPES.GET_FATAL_DROPDOWN_FAILURE, (state) => {
                state.fatalDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_REQUEST, (state) => {
                state.workplaceAccidentDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "workplaceAccidentDetails.requestInProgress", false);
                _.set(state, "workplaceAccidentDetails.data", payload);
            })
            .addCase(ACTION_TYPES.GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_FAILURE, (state) => {
                state.workplaceAccidentDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.ACCORDION_DETAILS_BY_ID_REQUEST, (state) => {
                state.accordionDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.ACCORDION_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "accordionDetails.requestInProgress", false);
                _.set(state, "accordionDetails.data", payload);
            })
            .addCase(ACTION_TYPES.ACCORDION_DETAILS_BY_ID_FAILURE, (state) => {
                state.accordionDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.RESPONSE_ACCORDION_BY_ID_REQUEST, (state) => {
                state.responseDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.RESPONSE_ACCORDION_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "responseDetails.requestInProgress", false);
                _.set(state, "responseDetails.data", payload);
            })
            .addCase(ACTION_TYPES.RESPONSE_ACCORDION_BY_ID_FAILURE, (state) => {
                state.responseDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.COMPLAINT_ACCORDION_BY_ID_REQUEST, (state) => {
                state.complaintDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.COMPLAINT_ACCORDION_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "complaintDetails.requestInProgress", false);
                _.set(state, "complaintDetails.data", payload);
            })
            .addCase(ACTION_TYPES.COMPLAINT_ACCORDION_BY_ID_FAILURE, (state) => {
                state.complaintDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.HEARING_ACCORDION_BY_ID_REQUEST, (state) => {
                state.hearingDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.HEARING_ACCORDION_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "hearingDetails.requestInProgress", false);
                _.set(state, "hearingDetails.data", payload);
            })
            .addCase(ACTION_TYPES.HEARING_ACCORDION_BY_ID_FAILURE, (state) => {
                state.hearingDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.HARASSMENT_COMPLAINT_BY_ID_REQUEST, (state) => {
                state.harassmentDetailsById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.HARASSMENT_COMPLAINT_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "harassmentDetailsById.requestInProgress", false);
                _.set(state, "harassmentDetailsById.data", payload);
            })
            .addCase(ACTION_TYPES.HARASSMENT_COMPLAINT_BY_ID_FAILURE, (state) => {
                state.harassmentDetailsById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_REQUEST, (state) => {
                state.cardCountInAttendanceRegister.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_SUCCESS, (state, { payload }) => {
                _.set(state, "cardCountInAttendanceRegister.requestInProgress", false);
                _.set(state, "cardCountInAttendanceRegister.data", payload);
            })
            .addCase(ACTION_TYPES.GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_FAILURE, (state) => {
                state.cardCountInAttendanceRegister.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYEE_ATTENDANCE_LIST_REQUEST, (state) => {
                state.employeeAttendanceList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYEE_ATTENDANCE_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "employeeAttendanceList.requestInProgress", false);
                _.set(state, "employeeAttendanceList.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYEE_ATTENDANCE_LIST_FAILURE, (state) => {
                state.employeeAttendanceList.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_ATTENDANCE_TYPE_DROPDOWN_REQUEST, (state) => {
                state.typeDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_ATTENDANCE_TYPE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "typeDropdown.requestInProgress", false);
                _.set(state, "typeDropdown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.GET_ATTENDANCE_TYPE_DROPDOWN_FAILURE, (state) => {
                state.typeDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_REQUEST, (state) => {
                state.attendanceById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "attendanceById.requestInProgress", false);
                _.set(state, "attendanceById.data", payload);
            })
            .addCase(ACTION_TYPES.GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_FAILURE, (state) => {
                state.attendanceById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYEE_DROPDOWN_REQUEST, (state) => {
                state.employeeName.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYEE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "employeeName.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_EMPLOYEE_DROPDOWN_FAILURE, (state) => {
                state.employeeName.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_RECOVERY_TYPE_DROPDOWN_REQUEST, (state) => {
                state.recoveryType.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_RECOVERY_TYPE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "recoveryType.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_RECOVERY_TYPE_DROPDOWN_FAILURE, (state) => {
                state.recoveryType.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.INCIDENCE_EMPLOYEE_DETAILS_BY_ID_REQUEST, (state) => {
                state.incidentEmployeeDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.INCIDENCE_EMPLOYEE_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "incidentEmployeeDetails.requestInProgress", false);
                _.set(state, "incidentEmployeeDetails.data", payload);
            })
            .addCase(ACTION_TYPES.INCIDENCE_EMPLOYEE_DETAILS_BY_ID_FAILURE, (state) => {
                state.incidentEmployeeDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.RECOVERY_DETAILS_BY_ID_REQUEST, (state) => {
                state.recoveryDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.RECOVERY_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "recoveryDetails.requestInProgress", false);
                _.set(state, "recoveryDetails.data", payload);
            })
            .addCase(ACTION_TYPES.RECOVERY_DETAILS_BY_ID_FAILURE, (state) => {
                state.recoveryDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_CAUSED_ISSUE_DROPDOWN_REQUEST, (state) => {
                state.causedIssue.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_CAUSED_ISSUE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "causedIssue.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_CAUSED_ISSUE_DROPDOWN_FAILURE, (state) => {
                state.causedIssue.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_MATERNITY_TYPE_DROPDOWN_REQUEST, (state) => {
                state.maternityType.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_MATERNITY_TYPE_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "maternityType.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_MATERNITY_TYPE_DROPDOWN_FAILURE, (state) => {
                state.maternityType.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.MATERNITY_DETAILS_BY_ID_REQUEST, (state) => {
                state.maternityDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.MATERNITY_DETAILS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "maternityDetails.requestInProgress", false);
                _.set(state, "maternityDetails.data", payload);
            })
            .addCase(ACTION_TYPES.MATERNITY_DETAILS_BY_ID_FAILURE, (state) => {
                state.maternityDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_VERIFICATION_STATUS_DROPDOWN_REQUEST, (state) => {
                state.verificationStatusDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_VERIFICATION_STATUS_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "verificationStatusDropdown.requestInProgress", false);
                _.set(state, "verificationStatusDropdown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.GET_VERIFICATION_STATUS_DROPDOWN_FAILURE, (state) => {
                state.verificationStatusDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_MINIMUM_WAGE_REQUEST, (state) => {
                state.eventsTracked.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_MINIMUM_WAGE_SUCCESS, (state, { payload }) => {
                _.set(state, "eventsTracked.requestInProgress", false);
                _.set(state, "eventsTracked.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_MINIMUM_WAGE_FAILURE, (state) => {
                state.eventsTracked.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_BASIC_FACILITIES_REQUEST, (state) => {
                state.eventsTracked.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_BASIC_FACILITIES_SUCCESS, (state, { payload }) => {
                _.set(state, "eventsTracked.requestInProgress", false);
                _.set(state, "eventsTracked.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_BASIC_FACILITIES_FAILURE, (state) => {
                state.eventsTracked.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_DETAILS_REQUEST, (state) => {
                state.eventsTracked.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_DETAILS_SUCCESS, (state, { payload }) => {
                _.set(state, "eventsTracked.requestInProgress", false);
                _.set(state, "eventsTracked.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_DETAILS_FAILURE, (state) => {
                state.eventsTracked.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_REGISTER_REQUEST, (state) => {
                state.eventsTracked.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_REGISTER_SUCCESS, (state, { payload }) => {
                _.set(state, "eventsTracked.requestInProgress", false);
                _.set(state, "eventsTracked.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_REGISTER_FAILURE, (state) => {
                state.eventsTracked.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_REQUEST, (state) => {
                state.eventsTracked.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_SUCCESS, (state, { payload }) => {
                _.set(state, "eventsTracked.requestInProgress", false);
                _.set(state, "eventsTracked.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_FAILURE, (state) => {
                state.eventsTracked.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_REQUEST, (state) => {
                state.eventsTracked.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_SUCCESS, (state, { payload }) => {
                _.set(state, "eventsTracked.requestInProgress", false);
                _.set(state, "eventsTracked.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_FAILURE, (state) => {
                state.eventsTracked.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_REQUEST, (state) => {
                state.eventsTracked.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_SUCCESS, (state, { payload }) => {
                _.set(state, "eventsTracked.requestInProgress", false);
                _.set(state, "eventsTracked.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_FAILURE, (state) => {
                state.eventsTracked.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_REQUEST, (state) => {
                state.eventsTracked.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_SUCCESS, (state, { payload }) => {
                _.set(state, "eventsTracked.requestInProgress", false);
                _.set(state, "eventsTracked.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_FAILURE, (state) => {
                state.eventsTracked.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_REQUEST, (state) => {
                state.eventsTracked.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_SUCCESS, (state, { payload }) => {
                _.set(state, "eventsTracked.requestInProgress", false);
                _.set(state, "eventsTracked.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_FAILURE, (state) => {
                state.eventsTracked.requestInProgress = false;
            });
    }
});

export const { actions, reducer } = slice;
