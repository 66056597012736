import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    SUBMIT_FINAL_PLAN: `${STATE_REDUCER_KEY}/SUBMIT_FINAL_PLAN`,
    SUBMIT_FINAL_PLAN_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_FINAL_PLAN_REQUEST`,
    SUBMIT_FINAL_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_FINAL_PLAN_SUCCESS`,
    SUBMIT_FINAL_PLAN_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_FINAL_PLAN_FAILURE`,

    LIST_FINAL_PLAN_BY_ID: `${STATE_REDUCER_KEY}/LIST_FINAL_PLAN_BY_ID`,
    LIST_FINAL_PLAN_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/LIST_FINAL_PLAN_BY_ID_REQUEST`,
    LIST_FINAL_PLAN_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/LIST_FINAL_PLAN_BY_ID_SUCCESS`,
    LIST_FINAL_PLAN_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/LIST_FINAL_PLAN_BY_ID_FAILURE`,

    FINAL_PLAN_BY_ID: `${STATE_REDUCER_KEY}/FINAL_PLAN_BY_ID`,
    FINAL_PLAN_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FINAL_PLAN_BY_ID_REQUEST`,
    FINAL_PLAN_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FINAL_PLAN_BY_ID_SUCCESS`,
    FINAL_PLAN_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FINAL_PLAN_BY_ID_FAILURE`,

    SUBMIT_SUB_PROJECT: `${STATE_REDUCER_KEY}/SUBMIT_SUB_PROJECT`,
    SUBMIT_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_SUB_PROJECT_REQUEST`,
    SUBMIT_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_SUB_PROJECT_SUCCESS`,
    SUBMIT_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_SUB_PROJECT_FAILURE`,

    LIST_SUB_PROJECT: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT`,
    LIST_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT_REQUEST`,
    LIST_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT_SUCCESS`,
    LIST_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT_FAILURE`,

    LIST_SUB_PROJECT_FINAL_BY_ID: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT_FINAL_BY_ID`,
    LIST_SUB_PROJECT_FINAL_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT_FINAL_BY_ID_REQUEST`,
    LIST_SUB_PROJECT_FINAL_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT_FINAL_BY_ID_SUCCESS`,
    LIST_SUB_PROJECT_FINAL_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT_FINALs_BY_ID_FAILURE`,

    LIST_FINAL_SUB_PROJECT: `${STATE_REDUCER_KEY}/LIST_FINAL_SUB_PROJECT`,
    LIST_FINAL_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/LIST_FINAL_SUB_PROJECT_REQUEST`,
    LIST_FINAL_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_FINAL_SUB_PROJECT_SUCCESS`,
    LIST_FINAL_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/LIST_FINAL_SUB_PROJECT_FAILURE`,

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    DELETE_FINAL_SUB_PROJECT: `${STATE_REDUCER_KEY}/DELETE_FINAL_SUB_PROJECT`,
    DELETE_FINAL_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FINAL_SUB_PROJECT_REQUEST`,
    DELETE_FINAL_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FINAL_SUB_PROJECT_SUCCESS`,
    DELETE_FINAL_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FINAL_SUB_PROJECT_FAILURE`,

    UPDATE_FINAL_SUB_PROJECT: `${STATE_REDUCER_KEY}/UPDATE_FINAL_SUB_PROJECT`,
    UPDATE_FINAL_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_FINAL_SUB_PROJECT_REQUEST`,
    UPDATE_FINAL_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_FINAL_SUB_PROJECT_SUCCESS`,
    UPDATE_FINAL_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_FINAL_SUB_PROJECT_FAILURE`,

    SELECTED_PLAN_LIST: `${STATE_REDUCER_KEY}/SELECTED_PLAN_LIST`,
    SELECTED_PLAN_LIST_REQUEST: `${STATE_REDUCER_KEY}/SELECTED_PLAN_LIST_REQUEST`,
    SELECTED_PLAN_LIST_SUCCESS: `${STATE_REDUCER_KEY}/SELECTED_PLAN_LIST_SUCCESS`,
    SELECTED_PLAN_LIST_FAILURE: `${STATE_REDUCER_KEY}/SELECTED_PLAN_LIST_FAILURE`
};

export const submitFinalPlan = createAction(ACTION_TYPES.SUBMIT_FINAL_PLAN);
export const finalPlanListById = createAction(ACTION_TYPES.LIST_FINAL_PLAN_BY_ID);
export const finalPlanById = createAction(ACTION_TYPES.FINAL_PLAN_BY_ID);
export const updateFinalSubProject = createAction(ACTION_TYPES.UPDATE_FINAL_SUB_PROJECT);
export const submitSubProject = createAction(ACTION_TYPES.SUBMIT_SUB_PROJECT);
export const listSubProjects = createAction(ACTION_TYPES.LIST_SUB_PROJECT);
export const listSubProjectFinalById = createAction(ACTION_TYPES.LIST_SUB_PROJECT_FINAL_BY_ID);
export const listFinalSubProject = createAction(ACTION_TYPES.LIST_FINAL_SUB_PROJECT);
export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const deleteFinalSubProject = createAction(ACTION_TYPES.DELETE_FINAL_SUB_PROJECT);
export const selectedPlanList = createAction(ACTION_TYPES.SELECTED_PLAN_LIST);

