import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    LIST_POST_PLAN_CONSULTATION: `${STATE_REDUCER_KEY}/LIST_POST_PLAN_CONSULTATION`,
    LIST_POST_PLAN_CONSULTATION_REQUEST: `${STATE_REDUCER_KEY}/LIST_POST_PLAN_CONSULTATION_REQUEST`,
    LIST_POST_PLAN_CONSULTATION_SUCCESS: `${STATE_REDUCER_KEY}/LIST_POST_PLAN_CONSULTATION_SUCCESS`,
    LIST_POST_PLAN_CONSULTATION_FAILURE: `${STATE_REDUCER_KEY}/LIST_POST_PLAN_CONSULTATION_FAILURE`,

    SUBMIT_POST_PLAN_CONSULTATION: `${STATE_REDUCER_KEY}/SUBMIT_POST_PLAN_CONSULTATION`,
    SUBMIT_POST_PLAN_CONSULTATION_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_POST_PLAN_CONSULTATION_REQUEST`,
    SUBMIT_POST_PLAN_CONSULTATION_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_POST_PLAN_CONSULTATION_SUCCESS`,
    SUBMIT_POST_PLAN_CONSULTATION_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_POST_PLAN_CONSULTATION_FAILURE`,

    POST_PLAN_CONSULTATION_BY_ID: `${STATE_REDUCER_KEY}/POST_PLAN_CONSULTATION_BY_ID`,
    POST_PLAN_CONSULTATION_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/POST_PLAN_CONSULTATION_BY_ID_REQUEST`,
    POST_PLAN_CONSULTATION_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/POST_PLAN_CONSULTATION_BY_ID_SUCCESS`,
    POST_PLAN_CONSULTATION_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/POST_PLAN_CONSULTATION_BY_ID_FAILURE`,

    UPDATE_POST_PLAN_CONSULTATION: `${STATE_REDUCER_KEY}/UPDATE_POST_PLAN_CONSULTATION`,
    UPDATE_POST_PLAN_CONSULTATION_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_POST_PLAN_CONSULTATION_REQUEST`,
    UPDATE_POST_PLAN_CONSULTATION_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_POST_PLAN_CONSULTATION_SUCCESS`,
    UPDATE_POST_PLAN_CONSULTATION_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_POST_PLAN_CONSULTATION_FAILURE`,

    DELETE_POST_PLAN_CONSULTATION: `${STATE_REDUCER_KEY}/DELETE_POST_PLAN_CONSULTATION`,
    DELETE_POST_PLAN_CONSULTATION_REQUEST: `${STATE_REDUCER_KEY}/DELETE_POST_PLAN_CONSULTATION_REQUEST`,
    DELETE_POST_PLAN_CONSULTATION_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_POST_PLAN_CONSULTATION_SUCCESS`,
    DELETE_POST_PLAN_CONSULTATION_FAILURE: `${STATE_REDUCER_KEY}/DELETE_POST_PLAN_CONSULTATION_FAILURE`,

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    CONFIRM_FORM_SUBMIT: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT`,
    CONFIRM_FORM_SUBMIT_REQUEST: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_REQUEST`,
    CONFIRM_FORM_SUBMIT_SUCCESS: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_SUCCESS`,
    CONFIRM_FORM_SUBMIT_FAILURE: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_FAILURE`
};

export const postPlanList = createAction(ACTION_TYPES.LIST_POST_PLAN_CONSULTATION);
export const postPostPlan = createAction(ACTION_TYPES.SUBMIT_POST_PLAN_CONSULTATION);
export const postPlanById = createAction(ACTION_TYPES.POST_PLAN_CONSULTATION_BY_ID);
export const deletePostPlan = createAction(ACTION_TYPES.DELETE_POST_PLAN_CONSULTATION);
export const updatePostPlan = createAction(ACTION_TYPES.UPDATE_POST_PLAN_CONSULTATION);
export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const confirmPostPlan = createAction(ACTION_TYPES.CONFIRM_FORM_SUBMIT);
