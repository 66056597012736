import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";
import { REQUEST_METHOD } from "common/constants";


export const saveCompositionOfTeamApi = (data) => ({
    url: API_URL.COMPOSITION_OF_TEAM.SAVE_COMPOSITION_OF_TEAM,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SUBMIT_COMPOSITION_OF_TEAM_REQUEST, ACTION_TYPES.SUBMIT_COMPOSITION_OF_TEAM_SUCCESS, ACTION_TYPES.SUBMIT_COMPOSITION_OF_TEAM_FAILURE],
        data
    }
});
export const fetchCompositionOfTeamApi = (data, id) => ({
    url: `${API_URL.COMPOSITION_OF_TEAM.LIST_COMPOSITION_OF_TEAM}?swmPlanId=${id}`,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_REQUEST, ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_SUCCESS, ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_FAILURE],
        data
    }
});
export const fetchCompositionOfTeamByIdApi = (data) => ({
    url: API_URL.COMPOSITION_OF_TEAM.LIST_COMPOSITION_OF_TEAM_BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID_REQUEST, ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID_SUCCESS, ACTION_TYPES.FETCH_COMPOSITION_OF_TEAM_LIST_BY_ID_FAILURE],
        data: { id: data }
    }
});
export const updateCompositionOfTeamApi = (data) => ({
    url: API_URL.COMPOSITION_OF_TEAM.UPDATE_COMPOSITION_OF_TEAM,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_COMPOSITION_OF_TEAM_REQUEST, ACTION_TYPES.UPDATE_COMPOSITION_OF_TEAM_SUCCESS, ACTION_TYPES.UPDATE_COMPOSITION_OF_TEAM_FAILURE],
        data
    }
});
export const deleteCompositionOfTeamApi = (id) => ({
    url: API_URL.COMPOSITION_OF_TEAM.DELETE_COMPOSITION_OF_TEAM.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
        types: [ACTION_TYPES.DELETE_COMPOSITION_OF_TEAM_REQUEST, ACTION_TYPES.DELETE_COMPOSITION_OF_TEAM_SUCCESS, ACTION_TYPES.DELETE_COMPOSITION_OF_TEAM_FAILURE]
    }
});
export const confirmCompositionApi = (data) => {
    return {
        url: API_URL.COMPOSITION_OF_TEAM.CONFIRM_UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.CONFIRM_FORM_SUBMIT_REQUEST, ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS, ACTION_TYPES.CONFIRM_FORM_SUBMIT_FAILURE],
            data
        }
    };
};
