import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import { getPayloadContent, getPayloadContentDetails } from "utils/apiUtils";

const initialState = {
    districtDropdown: {
        requestInProgress: false,
        data: []
    },
    ulbDropdown: {
        requestInProgress: false,
        data: []
    },
    ulbPopulation: {
        requestInProgress: false,
        data: {
            districtId: "",
            lsgiId: "",
            lsgiTypeId: "",
            population: "",
            refYear: "",
            remarks: ""
        }
    },
    censusYear: {
        requestInProgress: false,
        data: []
    },
    totalFund: {
        requestInProgress: false,
        data: []
    },
    totalFundDropdown: {
        requestInProgress: false,
        data: []
    },
    gacComputation: {
        requestInProgress: false,
        data: [],
        totalPopulation: 0,
        totalFund: "",
        year: ""
    },
    totalFundDetail: {
        data: {
            fundInUsd: "",
            exchangeRate: "",
            fundInInr: "",
            exchangeRateAsOn: "",
            remarks: "Dummy Remarks"
        },
        requestInProgress: false
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clearULB: (state) => {
            state.ulbDropdown = initialState.ulbDropdown;
        },
        clearDistrict: (state) => {
            state.districtDropdown = initialState.districtDropdown;
        },
        setGACDetails: (state, { payload: { key, value } }) => {
            _.set(state, `gacComputation.${key}`, value);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_DISTRICT_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "districtDropdown.requestInProgress", false);
            _.set(state, "districtDropdown.data", payload.content);
        })
            .addCase(ACTION_TYPES.FETCH_ULB_DETAILS_SUCCESS, (state, { payload }) => {
                _.set(state, "ulbDropdown.requestInProgress", false);
                _.set(state, "ulbDropdown.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_ULB_POPULATION_BY_ID_SUCCESS, (state, action) => {
                let newContent = getPayloadContentDetails(action);
                const newPayload = {
                    id: newContent?.id,
                    population: newContent?.population,
                    refYear: newContent?.refYear,
                    remarks: newContent?.remarks,
                    lsgiId: newContent?.lsgiId,
                    lsgiTypeId: newContent?.lsgiId.lsgiTypeId,
                    districtId: newContent?.lsgiId.districtId
                };
                _.set(state, "ulbPopulation.requestInProgress", false);
                _.set(state, "ulbPopulation.data", newPayload);
            })
            .addCase(ACTION_TYPES.FETCH_ULB_SEARCH_SUCCESS, (state, { payload }) => {
                _.set(state, "ulbDropdown.requestInProgress", false);
                _.set(state, "ulbDropdown.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_CENSUS_YEAR_SUCCESS, (state, { payload }) => {
                _.set(state, "censusYear.requestInProgress", false);
                _.set(state, "censusYear.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_TOTAL_POPULATION_SUCCESS, (state, { payload }) => {
                if (typeof payload === "object") {
                    _.set(state, "gacComputation.totalPopulation", 0);
                } else {
                    _.set(state, "gacComputation.totalPopulation", payload);
                }
            }).addCase(ACTION_TYPES.FETCH_TOTAL_FUND_BY_ID_REQUEST, (state) => {
                state.totalFundDetail.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_TOTAL_FUND_BY_ID_SUCCESS, (state, action) => {
                state.totalFundDetail = {
                    requestInProgress: false,
                    data: getPayloadContentDetails(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_TOTAL_FUND_DROPDOWN_SUCCESS, (state, action) => {
                state.totalFundDropdown.requestInProgress = false;
                const newPayload = getPayloadContent(action, []);
                state.totalFundDropdown.data = newPayload.map(item => {
                    return { name: item.fundInInr, ...item };
                });
            })
            .addCase(ACTION_TYPES.FETCH_TOTAL_FUND_BY_ID_FAILURE, (state) => {
                state.totalFundDetail.requestInProgress = false;
                state.error = "Something went wrong";
            });
    }
});

export const { actions, reducer } = slice;
