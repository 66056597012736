import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const submitCourseDetailsAPI = (data) => {
    return {
        url: API_URL.TRAINING_COURSE.SAVE_COURSE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SAVE_COURSE_DETAILS_REQUEST, ACTION_TYPES.SAVE_COURSE_DETAILS_SUCCESS, ACTION_TYPES.SAVE_COURSE_DETAILS_FAILURE],
            data
        }
    };
};

export const submitQuestionDetailsAPI = (data) => {
    return {
        url: API_URL.TRAINING_COURSE.SAVE_QUESTION,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SAVE_QUESTION_DETAILS_REQUEST, ACTION_TYPES.SAVE_QUESTION_DETAILS_SUCCESS, ACTION_TYPES.SAVE_QUESTION_DETAILS_FAILURE],
            data
        }
    };
};


export const listCourseDetailsAPI = (data) => {
    return {
        url: API_URL.TRAINING_COURSE.LIST_COURSE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_COURSE_DETAILS_REQUEST, ACTION_TYPES.LIST_COURSE_DETAILS_SUCCESS, ACTION_TYPES.LIST_COURSE_DETAILS_FAILURE],
            data
        }
    };
};


export const editCourseDetailsAPI = (data) => {
    return {
        url: API_URL.TRAINING_COURSE.EDIT_COURSE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_COURSE_DETAILS_REQUEST, ACTION_TYPES.EDIT_COURSE_DETAILS_SUCCESS, ACTION_TYPES.EDIT_COURSE_DETAILS_FAILURE],
            data
        }
    };
};

export const deleteCourseByIdAPI = ({ id }) => {
    return {
        url: API_URL.TRAINING_COURSE.DELETE_COURSE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_COURSE_DETAILS_REQUEST, ACTION_TYPES.DELETE_COURSE_DETAILS_SUCCESS, ACTION_TYPES.DELETE_COURSE_DETAILS_FAILURE]
        }
    };
};
export const updateCourseByIdAPI = (id) => {
    return {
        url: API_URL.TRAINING_COURSE.EDIT_COURSE_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.EDIT_COURSE_BY_ID_REQUEST, ACTION_TYPES.EDIT_COURSE_BY_ID_SUCCESS, ACTION_TYPES.EDIT_COURSE_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const fetchSessionIdApi = ({ courseId }) => {
    return {
        url: API_URL.TRAINING_COURSE.SESSION_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SESSION_ID_REQUEST, ACTION_TYPES.FETCH_SESSION_ID_SUCCESS, ACTION_TYPES.FETCH_SESSION_ID_FAILURE],
            data: { courseId }
        }
    };
};

export const fetchCourseIdApi = ({ id }) => {
    return {
        url: API_URL.TRAINING_COURSE.COURSE_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COURSE_ID_REQUEST, ACTION_TYPES.FETCH_COURSE_ID_SUCCESS, ACTION_TYPES.FETCH_COURSE_ID_FAILURE],
            data: { id }
        }
    };
};

export const getFileById = (id) => {
    return {
        url: API_URL.TRAINING_COURSE.GET_UPLOADED_FILE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_FILE_REQUEST, ACTION_TYPES.FETCH_FILE_SUCCESS, ACTION_TYPES.FETCH_FILE_FAILURE],
            data: { id }
        }
    };
};
export const deleteFileById = (id, fileId) => {
    let DELETE_API_URL = API_URL.TRAINING_COURSE.DELETE_FILE_BY_ID;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":file_id", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_FILE_REQUEST, ACTION_TYPES.DELETE_FILE_SUCCESS, ACTION_TYPES.DELETE_FILE_REQUEST]
        }
    };
};


export const fetchcourseDetails = () => {
    return {
        url: API_URL.TRAINING_COURSE.COURSE_FILTER,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COURSE_LIST_REQUEST, ACTION_TYPES.FETCH_COURSE_LIST_SUCCESS, ACTION_TYPES.FETCH_COURSE_LIST_FAILURE]
        }
    };
};

