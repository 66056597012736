import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./action";

export const fetchReceiptListApi = (data) => {
    return {
        url: API_URL.RECEIPT_GENERATE.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_RECEIPT_LIST_REQUEST, ACTION_TYPES.FETCH_RECEIPT_LIST_SUCCESS, ACTION_TYPES.FETCH_RECEIPT_LIST_FAILURE], data
        }
    };
};

export const postReceiptDataApi = (data) => {
    return {
        url: API_URL.RECEIPT_GENERATE.ADD_ALL,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_RECEIPT_DETAILS_REQUEST, ACTION_TYPES.POST_RECEIPT_DETAILS_SUCCESS, ACTION_TYPES.POST_RECEIPT_DETAILS_FAILURE], data
        }
    };
};

export const patchReceiptDataApi = (data) => {
    return {
        url: API_URL.RECEIPT_GENERATE.POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_RECEIPT_DETAILS_REQUEST, ACTION_TYPES.PATCH_RECEIPT_DETAILS_SUCCESS, ACTION_TYPES.PATCH_RECEIPT_DETAILS_FAILURE], data
        }
    };
};

export const getReceptDetailsApi = (data) => {
    return {
        url: API_URL.RECEIPT_GENERATE.GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_RECEIPT_DETAILS_BY_ID_REQUEST, ACTION_TYPES.GET_RECEIPT_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_RECEIPT_DETAILS_BY_ID_FAILURE], data
        }
    };
};

export const deleteReceiptApi = (data) => {
    return {
        url: API_URL.RECEIPT_GENERATE.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_RECEIPT_DETAILS_REQUEST, ACTION_TYPES.DELETE_RECEIPT_DETAILS_SUCCESS, ACTION_TYPES.DELETE_RECEIPT_DETAILS_FAILURE]
        }
    };
};
