import { I18n } from "common/components";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { approveDpmcApi, fetchDpmcByIdApi, fetchPreviousDpmcApi, fetchRecommendationDropdownApi } from "./api";

const formatData = (rest) => {
    const { swmPlanId, dpmcSocialExpertDecision, dpmcEnvExpertDecision, dpmcFinancialExpertDecision, dpmcTechnicalExpertDecision, dpmuSocialExpertDecision, dpmuEnvExpertDecision, dpmuFinancialExpertDecision, dpmuTechnicalExpertDecision, spmcSocialExpertDecision, spmcEnvExpertDecision, spmcFinancialExpertDecision, spmcTechnicalExpertDecision, spmuSocialExpertDecision, spmuEnvExpertDecision, spmuFinancialExpertDecision, spmuTechnicalExpertDecision, dpmcTechnicalExpertRemarks, dpmcSocialExpertRemarks, dpmcEnvExpertRemarks, dpmcFinancialExpertRemarks, dpmuTechnicalExpertRemarks, dpmuSocialExpertRemarks, dpmuEnvExpertRemarks, dpmuFinancialExpertRemarks, spmcTechnicalExpertRemarks, spmcSocialExpertRemarks, spmcEnvExpertRemarks, spmcFinancialExpertRemarks, spmuTechnicalExpertRemarks, spmuSocialExpertRemarks, spmuEnvExpertRemarks, spmuFinancialExpertRemarks } = rest;
    const data = { swmPlanId: Number(swmPlanId), dpmcSocialExpertDecision, dpmcEnvExpertDecision, dpmcFinancialExpertDecision, dpmcTechnicalExpertDecision, dpmuSocialExpertDecision, dpmuEnvExpertDecision, dpmuFinancialExpertDecision, dpmuTechnicalExpertDecision, spmcSocialExpertDecision, spmcEnvExpertDecision, spmcFinancialExpertDecision, spmcTechnicalExpertDecision, spmuSocialExpertDecision, spmuEnvExpertDecision, spmuFinancialExpertDecision, spmuTechnicalExpertDecision, dpmcTechnicalExpertRemarks, dpmcSocialExpertRemarks, dpmcEnvExpertRemarks, dpmcFinancialExpertRemarks, dpmuTechnicalExpertRemarks, dpmuSocialExpertRemarks, dpmuEnvExpertRemarks, dpmuFinancialExpertRemarks, spmcTechnicalExpertRemarks, spmcSocialExpertRemarks, spmcEnvExpertRemarks, spmcFinancialExpertRemarks, spmuTechnicalExpertRemarks, spmuSocialExpertRemarks, spmuEnvExpertRemarks, spmuFinancialExpertRemarks };
    return data;
};

export function* approveDpmc(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, approveDpmcApi, formatData(rest));

    const responseAction = yield take([ACTION_TYPES.APPROVE_DPMC_SUCCESS, ACTION_TYPES.APPROVE_DPMC_FAILURE]);
    if (responseAction.type === ACTION_TYPES.APPROVE_DPMC_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("comments_and_recommendations_added") }));
        yield call(handleAPIRequest, fetchDpmcByIdApi, {revision: rest.swmRevisionId, swmPlanId: rest.swmPlanId});
    }
}

export function* approvalDpmuById({ payload }) {
    yield call(handleAPIRequest, fetchDpmcByIdApi, payload);
}

export function* recommendationDropdown(payload = {}) {
    yield call(handleAPIRequest, fetchRecommendationDropdownApi, payload);
}

export function* getPreviousDpmc({ payload }) {
    yield call(handleAPIRequest, fetchPreviousDpmcApi, payload);
}

export default function* registerSaga() {
    yield all([
        takeLatest(ACTION_TYPES.APPROVE_DPMC, approveDpmc),
        takeLatest(ACTION_TYPES.FETCH_DPMC_BY_ID, approvalDpmuById),
        takeLatest(ACTION_TYPES.FETCH_RECOMMENDATION_DROPDOWN, recommendationDropdown),
        takeLatest(ACTION_TYPES.FETCH_PREVIOUS_DPMC, getPreviousDpmc)
    ]);
}
