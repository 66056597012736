import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchVenueDetailsAPI = (data) => ({
    url: API_URL.TRAINING_VENUE.SAVE_VENUE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.CREATE_VENUE_FORM_REQUEST, ACTION_TYPES.CREATE_VENUE_FORM_SUCCESS, ACTION_TYPES.CREATE_VENUE_FORM_FAILURE],
        data
    }
});

export const fetchDistrictDropDownDetailsAPI = () => {
    return {
        url: API_URL.TRAINING_VENUE.DISTRICT_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_FAILURE]
        }
    };
};
export const listTrainingVenueAPI = (data) => {
    return {
        url: API_URL.TRAINING_VENUE.LIST_VENUE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_VENUE_FORM_REQUEST, ACTION_TYPES.LIST_VENUE_FORM_SUCCESS, ACTION_TYPES.LIST_VENUE_FORM_FAILURE],
            data
        }
    };
};

export const deleteVenueFormByIdAPI = ({ id }) => {
    return {
        url: API_URL.TRAINING_VENUE.DELETE_VENUE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_VENUE_FORM_BY_ID_REQUEST, ACTION_TYPES.DELETE_VENUE_FORM_BY_ID_SUCCESS, ACTION_TYPES.DELETE_VENUE_FORM_BY_ID_FAILURE]
        }
    };
};

export const editVenueFormAPI = (data) => {
    return {
        url: API_URL.TRAINING_VENUE.EDIT_VENUE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.EDIT_VENUE_FORM_REQUEST, ACTION_TYPES.EDIT_VENUE_FORM_SUCCESS, ACTION_TYPES.EDIT_VENUE_FORM_FAILURE],
            data
        }
    };
};


export const editVenueFormByIdAPI = ({ id }) => {
    return {
        url: API_URL.TRAINING_VENUE.EDIT_VENUE_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.EDIT_VENUE_FORM_BY_ID_REQUEST, ACTION_TYPES.EDIT_VENUE_FORM_BY_ID_SUCCESS, ACTION_TYPES.EDIT_VENUE_FORM_BY_ID_FAILURE],
            data: { id }
        }
    };
};

export const fetchVenueIdApi = ({ id }) => {
    return {
        url: API_URL.TRAINING_VENUE.VENUE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_VENUE_ID_REQUEST, ACTION_TYPES.FETCH_VENUE_ID_SUCCESS, ACTION_TYPES.FETCH_VENUE_ID_FAILURE],
            data: { id }
        }
    };
};
