import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { addLoksabha, codeList, deleteLoksabha, editLoksabha, fetchDistricts, fetchLoksabhas, fetchLoksabhasById, nameList } from "./api";
import { actions as commonActions } from "../../common/slice";
import { navigateTo } from "modules/common/actions";
import { ADMIN_PATH as PATH } from "../paths";
import { MODULE_PATH } from "../../paths";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { getTableProps } from "modules/common/selectors";

export function* fetchLoksabhaDetails({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchLoksabhas, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_LOKSABHA_DATA_SUCCESS, ACTION_TYPES.FETCH_LOKSABHA_DATA_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}
export function* fetchDistrictDetails() {
    yield call(handleAPIRequest, fetchDistricts);
}
export function* fetchLoksabhaFormById({ payload: id }) {
    yield call(handleAPIRequest, fetchLoksabhasById, { id });
}
export function* submitLoksabhaDetails({ payload }) {
    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, editLoksabha, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_LOKSABHA_DATA_SUCCESS, ACTION_TYPES.EDIT_LOKSABHA_DATA_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_LOKSABHA_DATA_SUCCESS) {
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.LOKSABHA.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, addLoksabha, payload);
        const responseAction = yield take([ACTION_TYPES.ADD_LOKSABHA_DATA_SUCCESS, ACTION_TYPES.ADD_LOKSABHA_DATA_FAILURE]);
        if (responseAction.type === ACTION_TYPES.ADD_LOKSABHA_DATA_SUCCESS) {
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.LOKSABHA.LIST}`));
        }
    }
}

export function* deleteLoksabhaDetails({ payload }) {
    const { id } = payload;
    yield fork(handleAPIRequest, deleteLoksabha, { id });
    const responseAction = yield take([ACTION_TYPES.DELETE_LOKSABHA_DATA_SUCCESS, ACTION_TYPES.DELETE_LOKSABHA_DATA_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_LOKSABHA_DATA_SUCCESS) {
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchLoksabhaDetails, { payload: tablePayload });
    }
}
export function* fetchNameList({ payload }) {
    yield call(handleAPIRequest, nameList, payload);
}

export function* fetchCodeList({ payload }) {
    yield call(handleAPIRequest, codeList, payload);
}
export default function* sampleSaga() {
    yield all([takeLatest(ACTION_TYPES.FETCH_LOKSABHA_DATA, fetchLoksabhaDetails),
    takeLatest(ACTION_TYPES.FETCH_LOKSABHA_DETAILS_BY_ID, fetchLoksabhaFormById),
    takeLatest(ACTION_TYPES.FETCH_DISTRICT_LIST, fetchDistrictDetails),
    takeLatest(ACTION_TYPES.SUBMIT_LOKSABHA_DATA, submitLoksabhaDetails),
    takeLatest(ACTION_TYPES.DELETE_LOKSABHA_DATA, deleteLoksabhaDetails),
    takeLatest(ACTION_TYPES.FETCH_NAME_LIST, fetchNameList),
    takeLatest(ACTION_TYPES.FETCH_CODE_LIST, fetchCodeList)
    ]);
}
