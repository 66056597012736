import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchSecondaryTransportationApi = (data) => {
    return {
        url: API_URL.SECONDARY_TRANSPORTATION.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SECONDARY_TRANSPORTATION_DETAILS_REQUEST, ACTION_TYPES.FETCH_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS, ACTION_TYPES.FETCH_SECONDARY_TRANSPORTATION_DETAILS_FAILURE], data
        }
    };
};

export const modeOfLoadingDropDownApi = (data) => {
    return {
        url: API_URL.SECONDARY_TRANSPORTATION.MODE_OF_LOADING,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.MODE_OF_LOADING_DROPDOWN_REQUEST, ACTION_TYPES.MODE_OF_LOADING_DROPDOWN_SUCCESS, ACTION_TYPES.MODE_OF_LOADING_DROPDOWN_FAILURE],
            data
        }
    };
};

export const secondaryTransportationApi = (data) => {
    return {
        url: API_URL.SECONDARY_TRANSPORTATION.BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_REQUEST, ACTION_TYPES.GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_FAILURE]
        }
    };
};

export const postSecondaryTransportationApi = (data) => {
    return {
        url: API_URL.SECONDARY_TRANSPORTATION.POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SECONDARY_TRANSPORTATION_DETAILS_REQUEST, ACTION_TYPES.POST_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS, ACTION_TYPES.POST_SECONDARY_TRANSPORTATION_DETAILS_FAILURE], data
        }
    };
};

export const patchSecondaryTransportationApi = (data) => {
    return {
        url: API_URL.SECONDARY_TRANSPORTATION.POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_SECONDARY_TRANSPORTATION_DETAILS_REQUEST, ACTION_TYPES.PATCH_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS, ACTION_TYPES.PATCH_SECONDARY_TRANSPORTATION_DETAILS_FAILURE], data
        }
    };
};

export const deleteSecondaryTransportationApi = (data) => {
    return {
        url: API_URL.SECONDARY_TRANSPORTATION.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_SECONDARY_TRANSPORTATION_DETAILS_REQUEST, ACTION_TYPES.DELETE_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS, ACTION_TYPES.DELETE_SECONDARY_TRANSPORTATION_DETAILS_FAILURE]
        }
    };
};
