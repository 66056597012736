import { FormLabel, Grid, Typography } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import Select from "react-select";
import { FORM_CONTROL_STYLE, useStyles } from "./style";
import TextError from "./TextError";
import { useEffect, useState } from "react";


function CustomSelect(props) {
  const classes = useStyles();
  const { name = "", options = [], multiple = false, label = "", errorName = "", statusError = false, defaultValue = false, onChangeFromController, disabled = false, isMandatory = false, labelStyle = {}, ...rest } = props;

  const [style, setStyle] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setStyle({ fontSize: "14px", minHeight: "40px" });
      } else {
        setStyle({ fontSize: "18px", minHeight: "50px" });
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Grid sx={FORM_CONTROL_STYLE}>
      <FormLabel className={classes.label} style={{ ...labelStyle }} htmlFor={name}>{label} {isMandatory && <span style={{ color: "red", fontSize: "14px" }}> *</span>}</FormLabel>
      <Field
        as='select'
        name={name}
      >
        {
          ({ field, form }) => {
            return (
              <>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      ...style,
                      border: state.isFocused ? "1px solid #009A93" : "1px solid #C0E1EC",
                      boxShadow: "none",
                      color: "#000",
                      fontWeight: 400,
                      borderRadius: "10px",
                      "&:hover": {
                        borderColor: "#009A93"
                      },
                      ...(multiple && { maxHeight: "100px", overflowY: "auto" })
                    })
                  }}
                  {...field}
                  {...rest}
                  id={name}
                  options={options}
                  onChange={value => {
                    onChangeFromController && onChangeFromController(value);
                    form.setFieldValue(field.name, value);
                  }}
                  isDisabled={disabled}
                  defaultValue={defaultValue}
                  dropdownHeight={600}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isMulti={multiple}
                  name={name}
                />
                {statusError ? <Grid>
                  <Typography variant="p" sx={{ color: "red", mt: 1, lineHeight: 0, fontSize: "14px" }}>{errorName}</Typography>
                </Grid> :
                  <ErrorMessage component={TextError} name={name} />}
              </>
            );
          }
        }
      </Field>
    </Grid>
  );
}

export default CustomSelect;
