const { makeStyles } = require("@mui/styles");

export const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  toolbarText: {
    fontSize: "14px",
    height: "23px"
  },
  label: {
    fontSize: "13px",
    fontWeight: "bold"
  },
  "@media (min-width: 900px)": {
    toolbarText: {
      fontSize: "18px",
      height: "33px"
    },
    label: {
      fontSize: "12px"
    }
  }
});

export const FORM_CONTROL_STYLE = { display: "flex", flexDirection: "column", position: "relative", pb: { xs: 0, md: 1.5 } };
