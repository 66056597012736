import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    SUBMIT_DRAFT_PLAN: `${STATE_REDUCER_KEY}/SUBMIT_DRAFT_PLAN`,
    SUBMIT_DRAFT_PLAN_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_DRAFT_PLAN_REQUEST`,
    SUBMIT_DRAFT_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_DRAFT_PLAN_SUCCESS`,
    SUBMIT_DRAFT_PLAN_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_DRAFT_PLAN_FAILURE`,

    UPDATE_DRAFT_PLAN: `${STATE_REDUCER_KEY}/UPDATE_DRAFT_PLAN`,
    UPDATE_DRAFT_PLAN_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_DRAFT_PLAN_REQUEST`,
    UPDATE_DRAFT_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_DRAFT_PLAN_SUCCESS`,
    UPDATE_DRAFT_PLAN_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_DRAFT_PLAN_FAILURE`,

    DELETE_DRAFT_PLAN: `${STATE_REDUCER_KEY}/DELETE_DRAFT_PLAN`,
    DELETE_DRAFT_PLAN_REQUEST: `${STATE_REDUCER_KEY}/DELETE_DRAFT_PLAN_REQUEST`,
    DELETE_DRAFT_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_DRAFT_PLAN_SUCCESS`,
    DELETE_DRAFT_PLAN_FAILURE: `${STATE_REDUCER_KEY}/DELETE_DRAFT_PLAN_FAILURE`,

    CONFIRM_FORM_SUBMIT: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT`,
    CONFIRM_FORM_SUBMIT_REQUEST: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_REQUEST`,
    CONFIRM_FORM_SUBMIT_SUCCESS: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_SUCCESS`,
    CONFIRM_FORM_SUBMIT_FAILURE: `${STATE_REDUCER_KEY}/CONFIRM_FORM_SUBMIT_FAILURE`,

    LIST_DRAFT_PLAN: `${STATE_REDUCER_KEY}/LIST_DRAFT_PLAN`,
    LIST_DRAFT_PLAN_REQUEST: `${STATE_REDUCER_KEY}/LIST_DRAFT_PLAN_REQUEST`,
    LIST_DRAFT_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/LIST_DRAFT_PLAN_SUCCESS`,
    LIST_DRAFT_PLAN_FAILURE: `${STATE_REDUCER_KEY}/LIST_DRAFT_PLAN_FAILURE`,

    LIST_DRAFT_PLAN_BY_ID: `${STATE_REDUCER_KEY}/LIST_DRAFT_PLAN_BY_ID`,
    LIST_DRAFT_PLAN_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/LIST_DRAFT_PLAN_BY_ID_REQUEST`,
    LIST_DRAFT_PLAN_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/LIST_DRAFT_PLAN_BY_ID_SUCCESS`,
    LIST_DRAFT_PLAN_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/LIST_DRAFT_PLAN_BY_ID_FAILURE`,

    DRAFT_PLAN_BY_ID: `${STATE_REDUCER_KEY}/DRAFT_PLAN_BY_ID`,
    DRAFT_PLAN_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/DRAFT_PLAN_BY_ID_REQUEST`,
    DRAFT_PLAN_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/DRAFT_PLAN_BY_ID_SUCCESS`,
    DRAFT_PLAN_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/DRAFT_PLAN_BY_ID_FAILURE`
};

export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const submitDraftPlan = createAction(ACTION_TYPES.SUBMIT_DRAFT_PLAN);
export const confirmDraftPlan = createAction(ACTION_TYPES.CONFIRM_FORM_SUBMIT);
export const draftPlanList = createAction(ACTION_TYPES.LIST_DRAFT_PLAN);
export const draftPlanListById = createAction(ACTION_TYPES.LIST_DRAFT_PLAN_BY_ID);
export const draftPlanById = createAction(ACTION_TYPES.DRAFT_PLAN_BY_ID);
export const deleteDraftPlan = createAction(ACTION_TYPES.DELETE_DRAFT_PLAN);
export const updateDraftPlan = createAction(ACTION_TYPES.UPDATE_DRAFT_PLAN);
