import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import { COMMON_TABLE_PAGINATION } from "modules/common/constants";
import { getPayloadContent } from "../../../utils/apiUtils";

const initialState = {
    tablePagination: { ...COMMON_TABLE_PAGINATION },
    trainingNeedForm: {
        requestInProgress: false,
        data: {}
    },
    targetGroup: [],
    topic: [],
    knowledgeBase: [],
    designation: [],
    attachmentPayload: { multimediaList: [] }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.table = initialState.table;
        },
        setAttachImages: (state, action) => {
            state.attachmentImages.push(action.payload);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.SAVE_TRAINING_REQUEST, (state) => {
            _.set(state, "trainingNeedForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.SAVE_TRAINING_SUCCESS, (state, action) => {
            _.set(state, "trainingNeedForm.data", action.payload);
            _.set(state, "trainingNeedForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.LIST_TRAINING_REQUEST, (state) => {
            _.set(state, "trainingNeedForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.LIST_TRAINING_SUCCESS, (state, action) => {
            _.set(state, "trainingNeedForm.data", getPayloadContent(action));
            _.set(state, "trainingNeedForm.requestInProgress", false);

        });
        builder.addCase(ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY_SUCCESS, (state, action) => {
            _.set(state, "targetGroup", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_TRAINING_TOPIC_SUCCESS, (state, action) => {
            _.set(state, "topic", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_DESIGNATION_SUCCESS, (state, action) => {
            _.set(state, "designation", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_KNOWLEDGE_BASE_SUCCESS, (state, action) => {
            _.set(state, "knowledgeBase", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.EDIT_NEED_FORM_CATEGORY_SUCCESS, (state, action) => {

            _.set(state, "trainingNeedForm.requestInProgress", false);
            _.set(state, "trainingNeedForm.data", action.payload);
            if (action.payload.attachmentPayload) {
                _.set(state, "attachmentImages", action.payload.attachmentPayload?.multimediaList);
            }
        });
        builder.addCase(ACTION_TYPES.DELETE_NEED_FORM_BY_ID_SUCCESS, (state, action) => {
            _.set(state, "trainingNeedForm.data", getPayloadContent(action));
        });
    }
});
export const { actions, reducer } = slice;
