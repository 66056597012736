import { I18n } from "common/components";
import { TABLE_IDS } from "common/constants";
import { navigateTo } from "modules/common/actions";
import { getTableProps } from "modules/common/selectors";
import { MODULE_PATH } from "modules/paths";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { toEpoch } from "utils/dateUtils";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";
import { ACTION_TYPES } from "./action";
import * as Api from "./api";


export function* fetchMcfRrfDetails({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.MCF_RRF_DETAILS]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchMcfRrfDetailsApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.FETCH_MCF_RRF_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.MCF_RRF_DETAILS);
}

export function* forwardLinkingDropdown({ payload }) {
    yield call(handleAPIRequest, Api.forwardLinkingDropdownApi, payload);
}

const formatData = (payload) => {
    const { date, ...rest } = payload;
    const data = { date: toEpoch(date), ...rest };
    return data;
};

export function* submitMcfRrfDetails(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.patchMcfRrfDetailsApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.PATCH_MCF_RRF_DETAILS_SUCCESS]);
        if (responseAction.type === ACTION_TYPES.PATCH_MCF_RRF_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("mcf_and_rrf") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.MASTER_INFO}/${PATH.MCF_RRF.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, Api.postMcfRrfDetailsApi, formatData(rest));
        const responseAction = yield take([ACTION_TYPES.POST_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.POST_MCF_RRF_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_MCF_RRF_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("mcf_and_rrf") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.MASTER_INFO}/${PATH.MCF_RRF.LIST}`));
        }
    }
}

export function* mcfRrfDetailsById({ payload }) {
    yield call(handleAPIRequest, Api.mcfRrfDetailsByIdApi, payload);
}

export function* deleteMcfRrfDetails({ payload }) {
    yield fork(handleAPIRequest, Api.deleteMcfRrfDetailsApi, payload);
    const action = yield take([ACTION_TYPES.DELETE_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.DELETE_MCF_RRF_DETAILS_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_MCF_RRF_DETAILS_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("deleted_successfully")) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.MCF_RRF_DETAILS]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchMcfRrfDetailsApi, { pageNo: 0, pageSize: 5, ...filters, ...payload });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.FETCH_MCF_RRF_DETAILS_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.MCF_RRF_DETAILS);
    }
}

export function* typeDropdown({ payload }) {
    yield call(handleAPIRequest, Api.typeDropdownApi, payload);
}

export function* mcfRrfNameDropdown({ payload }) {
    yield call(handleAPIRequest, Api.mcfRrfNameDropdownApi, payload);
}

export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_MCF_RRF_DETAILS, fetchMcfRrfDetails),
        takeLatest(ACTION_TYPES.FORWARD_LINKING_DROPDOWN, forwardLinkingDropdown),
        takeLatest(ACTION_TYPES.POST_MCF_RRF_DETAILS, submitMcfRrfDetails),
        takeLatest(ACTION_TYPES.PATCH_MCF_RRF_DETAILS, submitMcfRrfDetails),
        takeLatest(ACTION_TYPES.GET_MCF_RRF_DETAILS_BY_ID, mcfRrfDetailsById),
        takeLatest(ACTION_TYPES.DELETE_MCF_RRF_DETAILS, deleteMcfRrfDetails),
        takeLatest(ACTION_TYPES.TYPE_OF_DEPARTMENT_DROPDOWN, typeDropdown),
        takeLatest(ACTION_TYPES.MCF_RRF_NAME_DROPDOWN, mcfRrfNameDropdown)
    ]);
}
