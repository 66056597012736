import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchContactTypeApi = () => {
    return {
        url: API_URL.COMMON.CONTACT_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_CONTACT_TYPE_REQUEST, ACTION_TYPES.FETCH_CONTACT_TYPE_SUCCESS, ACTION_TYPES.FETCH_CONTACT_TYPE_FAILURE],
            data: { dropDown: true }
        }
    };
};
export const lookupCategoryApi = () => {
    return {
        url: API_URL.COMMON.LOOKUP_CATEGORY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LOOKUP_CATEGORY_REQUEST, ACTION_TYPES.FETCH_LOOKUP_CATEGORY_SUCCESS, ACTION_TYPES.FETCH_LOOKUP_CATEGORY_FAILURE],
            data: { dropDown: true, pageSize: 300 }
        }
    };
};
export const listShoNgoApi = (data) => {
    return {
        url: API_URL.SHO_NGO_TOM.LIST_SHO_NGO,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_SHO_NGO_CONTACTS_REQUEST, ACTION_TYPES.LIST_SHO_NGO_CONTACTS_SUCCESS, ACTION_TYPES.LIST_SHO_NGO_CONTACTS_FAILURE],
            data
        }
    };
};
export const updateShoNgoApi = (data) => {
    return {
        url: API_URL.SHO_NGO_TOM.UPDATE_SHO_NGO_FORM,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_SHO_NGO_REQUEST, ACTION_TYPES.UPDATE_SHO_NGO_SUCCESS, ACTION_TYPES.UPDATE_SHO_NGO_FAILURE],
            data
        }
    };
};
export const submitShoNgoApi = (data) => {
    return {
        url: API_URL.SHO_NGO_TOM.SUBMIT_SHO_NGO,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_SHO_NGO_CONTACTS_REQUEST, ACTION_TYPES.SUBMIT_SHO_NGO_CONTACTS_SUCCESS, ACTION_TYPES.SUBMIT_SHO_NGO_CONTACTS_FAILURE],
            data
        }
    };
};
export const deleteShoNgoApi = (id) => {
    return {
        url: API_URL.SHO_NGO_TOM.DELETE_SHO_NGO_FORM.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_SHO_NGO_CONTACTS_REQUEST, ACTION_TYPES.DELETE_SHO_NGO_CONTACTS_SUCCESS, ACTION_TYPES.DELETE_SHO_NGO_CONTACTS_FAILURE]
        }
    };
};
export const shoNgoGetByIdApi = (id) => {
    return {
        url: API_URL.SHO_NGO_TOM.SHO_NGO_BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SHO_NGO_CONTACTS_BY_ID_REQUEST, ACTION_TYPES.SHO_NGO_CONTACTS_BY_ID_SUCCESS, ACTION_TYPES.SHO_NGO_CONTACTS_BY_ID_FAILURE]
        }
    };
};
export const submitTrainingOrganizationApi = (data) => {
    return {
        url: API_URL.SHO_NGO_TOM.SUBMIT_TRAINING_ORGANIZATION,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_TRAINING_ORGANIZATION_REQUEST, ACTION_TYPES.SUBMIT_TRAINING_ORGANIZATION_SUCCESS, ACTION_TYPES.SUBMIT_TRAINING_ORGANIZATION_FAILURE],
            data
        }
    };
};
export const listTrainingOrganizationApi = (data) => {
    return {
        url: API_URL.SHO_NGO_TOM.LIST_TRAINING_ORGANIZATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_TRAINING_ORGANIZATION_REQUEST, ACTION_TYPES.LIST_TRAINING_ORGANIZATION_SUCCESS, ACTION_TYPES.LIST_TRAINING_ORGANIZATION_FAILURE],
            data
        }
    };
};
export const updateTrainingOrganizationApi = (data) => {
    return {
        url: API_URL.SHO_NGO_TOM.UPDATE_TRAINING_ORGANIZATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_TRAINING_ORGANIZATION_REQUEST, ACTION_TYPES.UPDATE_TRAINING_ORGANIZATION_SUCCESS, ACTION_TYPES.UPDATE_TRAINING_ORGANIZATION_FAILURE],
            data
        }
    };
};
export const deleteTrainingOrganizationApi = (id) => {
    return {
        url: API_URL.SHO_NGO_TOM.DELETE_TRAINING_ORGANIZATION_FORM.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_TRAINING_ORGANIZATION_REQUEST, ACTION_TYPES.DELETE_TRAINING_ORGANIZATION_SUCCESS, ACTION_TYPES.DELETE_TRAINING_ORGANIZATION_FAILURE]
        }
    };
};
export const trainingOrganizationGetByIdApi = (id) => {
    return {
        url: API_URL.SHO_NGO_TOM.TRAINING_ORGANIZATION_BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.TRAINING_ORGANIZATION_BY_ID_REQUEST, ACTION_TYPES.TRAINING_ORGANIZATION_BY_ID_SUCCESS, ACTION_TYPES.TRAINING_ORGANIZATION_BY_ID_FAILURE]
        }
    };
};
export const filterShoNgoName = (data) => {
    return {
        url: API_URL.SHO_NGO_TOM.FILTER_SHO_NGO_NAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FILTER_SHO_NGO_NAME_REQUEST, ACTION_TYPES.FILTER_SHO_NGO_NAME_SUCCESS, ACTION_TYPES.FILTER_SHO_NGO_NAME_FAILURE],
            data: data
        }
    };
};


export const nameList = (data) => {
    return {
        url: API_URL.SHO_NGO_TOM.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_NAME_LIST_REQUEST,
                ACTION_TYPES.FETCH_NAME_LIST_SUCCESS,
                ACTION_TYPES.FETCH_NAME_LIST_FAILURE
            ],
            data
        }
    };
};
export const addressList = (data) => {
    return {
        url: API_URL.SHO_NGO_TOM.FILTER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_ADDRESS_LIST_REQUEST,
                ACTION_TYPES.FETCH_ADDRESS_LIST_SUCCESS,
                ACTION_TYPES.FETCH_ADDRESS_LIST_FAILURE
            ],
            data
        }
    };
};
