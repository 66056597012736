import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { TABLE_IDS } from "common/constants";
import { successNotify } from "utils/notificationUtils";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { I18n } from "common/components";
import { MODULE_PATH } from "modules/paths";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";
import { toEpoch } from "utils/dateUtils";
import { mcfRrfNameDropdownApi } from "../McfRrf/api";
import { API_URL } from "../apiUrls";

const formatData = (payload) => {
    const { date, ...rest } = payload;
    const data = { date: toEpoch(date), ...rest };
    return data;
};

export function* getMcfRrfDetailsList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.MCF_RRF_DETAILS_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchMcfRrfDetailsListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_MCF_RRF_DETAILS_LIST_SUCCESS, ACTION_TYPES.FETCH_MCF_RRF_DETAILS_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.MCF_RRF_DETAILS_LIST);
}

export function* submitMcfRrfDetails(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.updateMcfRrfDetailsApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.PATCH_MCF_RRF_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_MCF_RRF_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("mcf_rrf_details_information") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.MCF_RRF_DETAILS.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, Api.submitMcfRrfDetailsApi, formatData(rest));
        const responseAction = yield take([ACTION_TYPES.POST_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.POST_MCF_RRF_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_MCF_RRF_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("mcf_rrf_details_information") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.MCF_RRF_DETAILS.LIST}`));
        }
    }
}

export function* getMcfRrfDetailsById({ payload }) {
    yield fork(handleAPIRequest, Api.fetchMcfRrfDetailsByIdApi, payload);
    const responseAction = yield take([ACTION_TYPES.FETCH_MCF_RRF_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_MCF_RRF_DETAILS_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.FETCH_MCF_RRF_DETAILS_BY_ID_SUCCESS) {
        if (responseAction?.payload?.districtId && responseAction?.payload?.lsgiId) {
            yield call(handleAPIRequest, mcfRrfNameDropdownApi, { lsgiId: responseAction?.payload?.lsgiId?.id, type: responseAction?.payload?.type?.id, pageNo: 0, pageSize: 500 });
        }
    }
}

export function* deleteMcfRrfDetails({ payload: id }) {
    yield fork(handleAPIRequest, Api.deleteMcfRrfDetailsApi, id);
    const response = yield take([ACTION_TYPES.DELETE_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.DELETE_MCF_RRF_DETAILS_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_MCF_RRF_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("mcf_rrf_details_information") }) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.MCF_RRF_DETAILS_LIST]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchMcfRrfDetailsListApi, { pageNo: 0, pageSize: 5, ...filters });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_MCF_RRF_DETAILS_LIST_SUCCESS, ACTION_TYPES.FETCH_MCF_RRF_DETAILS_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.MCF_RRF_DETAILS_LIST);
    }
}

export function* typeDropdown({ payload }) {
    yield call(handleAPIRequest, Api.fetchTypeDropdownApi, payload);
}

export function* downloadMcfRrfDetails(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { fromDate, toDate } = filters;
    yield put(commonFileDownload({
        url: API_URL.MCF_RRF_DETAILS.DOWNLOAD,
        data: { type, fromDate, toDate },
        file: { name: fileName, ext: type }
    }));
}

export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_MCF_RRF_DETAILS_LIST, getMcfRrfDetailsList),
        takeLatest(ACTION_TYPES.POST_MCF_RRF_DETAILS, submitMcfRrfDetails),
        takeLatest(ACTION_TYPES.PATCH_MCF_RRF_DETAILS, submitMcfRrfDetails),
        takeLatest(ACTION_TYPES.FETCH_MCF_RRF_DETAILS_BY_ID, getMcfRrfDetailsById),
        takeLatest(ACTION_TYPES.DELETE_MCF_RRF_DETAILS, deleteMcfRrfDetails),
        takeLatest(ACTION_TYPES.FETCH_TYPE_DROPDOWN, typeDropdown),
        takeLatest(ACTION_TYPES.DOWNLOAD_MCF_RRF_DETAILS_LIST, downloadMcfRrfDetails)
    ]);
}
