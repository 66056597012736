import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchWasteDisposalRegionalListApi = (data) => {
    return {
        url: API_URL.WASTE_DISPOSAL_REGIONAL_SITE.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_LIST_REQUEST, ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_LIST_SUCCESS, ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_LIST_FAILURE], data
        }
    };
};

export const submitWasteDisposalRegionalApi = (data) => ({
    url: API_URL.WASTE_DISPOSAL_REGIONAL_SITE.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_WASTE_DISPOSAL_REGIONAL_REQUEST, ACTION_TYPES.POST_WASTE_DISPOSAL_REGIONAL_SUCCESS, ACTION_TYPES.POST_WASTE_DISPOSAL_REGIONAL_FAILURE],
        data
    }
});

export const updateWasteDisposalRegionalApi = (data) => ({
    url: API_URL.WASTE_DISPOSAL_REGIONAL_SITE.SAVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_WASTE_DISPOSAL_REGIONAL_REQUEST, ACTION_TYPES.PATCH_WASTE_DISPOSAL_REGIONAL_SUCCESS, ACTION_TYPES.PATCH_WASTE_DISPOSAL_REGIONAL_FAILURE],
        data
    }
});

export const fetchWasteDisposalRegionalByIdApi = (id) => {
    return {
        url: API_URL.WASTE_DISPOSAL_REGIONAL_SITE.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_REQUEST, ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_SUCCESS, ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_FAILURE]
        }
    };
};

export const deleteWasteDisposalRegionalApi = (id) => ({
    url: API_URL.WASTE_DISPOSAL_REGIONAL_SITE.DELETE.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
        types: [ACTION_TYPES.DELETE_WASTE_DISPOSAL_REGIONAL_REQUEST, ACTION_TYPES.DELETE_WASTE_DISPOSAL_REGIONAL_SUCCESS, ACTION_TYPES.DELETE_WASTE_DISPOSAL_REGIONAL_FAILURE]
    }
});

export const fetchNameDropdownApi = (query = {}) => {
    return {
        url: API_URL.WASTE_DISPOSAL_REGIONAL_SITE.NAME_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_NAME_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_NAME_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_NAME_DROPDOWN_FAILURE],
            data: query
        }
    };
};
