

import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    FETCH_ROLE_BY_ID: `${STATE_REDUCER_KEY}/FETCH_ROLE_DETAILS_BY_ID`,
    FETCH_ROLE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ROLE_DETAILS_BY_ID_REQUEST`,
    FETCH_ROLE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ROLE_DETAILS_BY_ID_SUCCESS`,
    FETCH_ROLE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ROLE_DETAILS_BY_ID_FAILURE`,

    FETCH_ROLE_DATA: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA`,
    FETCH_ROLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_REQUEST`,
    FETCH_ROLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_SUCCESS`,
    FETCH_ROLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_FAILURE`,

    FETCH_ROLE_LEVEL: `${STATE_REDUCER_KEY}/FETCH_ROLE_LEVEL`,
    FETCH_ROLE_LEVEL_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ROLE_LEVEL_REQUEST`,
    FETCH_ROLE_LEVEL_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ROLE_LEVEL_SUCCESS`,
    FETCH_ROLE_LEVEL_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ROLE_LEVEL_FAILURE`,

    ADD_ROLE_DATA: `${STATE_REDUCER_KEY}/ADD_ROLE_DATA`,
    ADD_ROLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/ADD_ROLE_DATA_REQUEST`,
    ADD_ROLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/ADD_ROLE_DATA_SUCCESS`,
    ADD_ROLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/ADD_ROLE_DATA_FAILURE`,

    EDIT_ROLE_DATA: `${STATE_REDUCER_KEY}/EDIT_ROLE_DATA`,
    EDIT_ROLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/EDIT_ROLE_DATA_REQUEST`,
    EDIT_ROLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_ROLE_DATA_SUCCESS`,
    EDIT_ROLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/EDIT_ROLE_DATA_FAILURE`,


    SUBMIT_ROLE_DATA: `${STATE_REDUCER_KEY}/SUBMIT_ROLE_DATA`,
    SUBMIT_ROLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_ROLE_DATA_REQUEST`,
    SUBMIT_ROLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_ROLE_DATA_SUCCESS`,
    SUBMIT_ROLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_ROLE_DATA_FAILURE`,

    DELETE_ROLE_DATA: `${STATE_REDUCER_KEY}/DELETE_ROLE_DATA`,
    DELETE_ROLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/DELETE_ROLE_DATA_REQUEST`,
    DELETE_ROLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_ROLE_DATA_SUCCESS`,
    DELETE_ROLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/DELETE_ROLE_DATA_FAILURE`,

    FETCH_ROLE_RESOURCES: `${STATE_REDUCER_KEY}/FETCH_ROLE_RESOURCES`,
    FETCH_ROLE_RESOURCES_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ROLE_RESOURCES_REQUEST`,
    FETCH_ROLE_RESOURCES_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ROLE_RESOURCES_SUCCESS`,
    FETCH_ROLE_RESOURCES_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ROLE_RESOURCES_FAILURE`,

    FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
    FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
    FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
    FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,

    FILTER_ROLE_NAME: `${STATE_REDUCER_KEY}FILTER_ROLE_NAME`,
    FILTER_ROLE_NAME_REQUEST: `${STATE_REDUCER_KEY}FILTER_ROLE_NAME_REQUEST`,
    FILTER_ROLE_NAME_SUCCESS: `${STATE_REDUCER_KEY}FILTER_ROLE_NAME_SUCCESS`,
    FILTER_ROLE_NAME_FAILURE: `${STATE_REDUCER_KEY}FILTER_ROLE_NAME_FAILURE`,

    CREATE_ROLE_TREE: `${STATE_REDUCER_KEY}CREATE_ROLE_TREE`,
    CREATE_ROLE_TREE_REQUEST: `${STATE_REDUCER_KEY}CREATE_ROLE_TREE_REQUEST`,
    CREATE_ROLE_TREE_SUCCESS: `${STATE_REDUCER_KEY}CREATE_ROLE_TREE_SUCCESS`,
    CREATE_ROLE_TREE_FAILURE: `${STATE_REDUCER_KEY}CREATE_ROLE_TREE_FAILURE`,

    UPDATE_ROLE_TREE: `${STATE_REDUCER_KEY}UPDATE_ROLE_TREE`,
    UPDATE_ROLE_TREE_REQUEST: `${STATE_REDUCER_KEY}UPDATE_ROLE_TREE_REQUEST`,
    UPDATE_ROLE_TREE_SUCCESS: `${STATE_REDUCER_KEY}UPDATE_ROLE_TREE_SUCCESS`,
    UPDATE_ROLE_TREE_FAILURE: `${STATE_REDUCER_KEY}UPDATE_ROLE_TREE_FAILURE`,

    CHECKED_ITEM_ROLE_TREE: `${STATE_REDUCER_KEY}CHECKED_ITEM_ROLE_TREE`,
    CHECKED_ITEM_ROLE_TREE_REQUEST: `${STATE_REDUCER_KEY}CHECKED_ITEM_ROLE_TREE_REQUEST`,
    CHECKED_ITEM_ROLE_TREE_SUCCESS: `${STATE_REDUCER_KEY}CHECKED_ITEM_ROLE_TREE_SUCCESS`,
    CHECKED_ITEM_ROLE_TREE_FAILURE: `${STATE_REDUCER_KEY}CHECKED_ITEM_ROLE_TREE_FAILURE`,

    DOWNLOAD_ROLES_LIST: `${STATE_REDUCER_KEY}/DOWNLOAD_ROLES_LIST`,
    DOWNLOAD_ROLES_LIST_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_ROLES_LIST_REQUEST`,
    DOWNLOAD_ROLES_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_ROLES_LIST_SUCCESS`,
    DOWNLOAD_ROLES_LIST_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_ROLES_LIST_FAILURE`
};

export const getRoleById = createAction(ACTION_TYPES.FETCH_ROLE_BY_ID);

export const getRoles = createAction(ACTION_TYPES.FETCH_ROLE_DATA);

export const submitRole = createAction(ACTION_TYPES.SUBMIT_ROLE_DATA);

export const deleteRole = createAction(ACTION_TYPES.DELETE_ROLE_DATA);

export const roleLevels = createAction(ACTION_TYPES.FETCH_ROLE_LEVEL);

export const roleResources = createAction(ACTION_TYPES.FETCH_ROLE_RESOURCES);

export const filterRoleName = createAction(ACTION_TYPES.FILTER_ROLE_NAME);

export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);

export const createRoleTree = createAction(ACTION_TYPES.CREATE_ROLE_TREE);

export const updateRoleTree = createAction(ACTION_TYPES.UPDATE_ROLE_TREE);

export const checkedRoleTreeItems = createAction(ACTION_TYPES.CHECKED_ITEM_ROLE_TREE);

export const downloadRoles = createAction(ACTION_TYPES.DOWNLOAD_ROLES_LIST);

