import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { TABLE_IDS } from "common/constants";
import { successNotify } from "utils/notificationUtils";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { I18n } from "common/components";
import { MODULE_PATH } from "modules/paths";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";
import { toEpoch } from "utils/dateUtils";
import { API_URL } from "../apiUrls";

const formatData = (payload) => {
    const { date, ...rest } = payload;
    const data = { date: toEpoch(date), ...rest };
    return data;
};

export function* getWssInfoList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.WSS_INFO_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchWssInfoListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WSS_INFO_LIST_SUCCESS, ACTION_TYPES.FETCH_WSS_INFO_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WSS_INFO_LIST);
}

export function* submitWssInfo(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.updateWssInfoApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_WSS_INFO_SUCCESS, ACTION_TYPES.PATCH_WSS_INFO_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_WSS_INFO_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("waste_segregation_storage_info") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.WSS_INFO.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, Api.submitWssInfoApi, formatData(rest));
        const responseAction = yield take([ACTION_TYPES.POST_WSS_INFO_SUCCESS, ACTION_TYPES.POST_WSS_INFO_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_WSS_INFO_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("waste_segregation_storage_info") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.WSS_INFO.LIST}`));
        }
    }
}

export function* wasteProcessingById({ payload }) {
    yield fork(handleAPIRequest, Api.fetchWssInfoByIdApi, payload);
    const responseAction = yield take([ACTION_TYPES.FETCH_WSS_INFO_BY_ID_SUCCESS, ACTION_TYPES.FETCH_WSS_INFO_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.FETCH_WSS_INFO_BY_ID_SUCCESS) {
        if (responseAction?.payload?.districtId && responseAction?.payload?.lsgiId) {
            yield call(handleAPIRequest, Api.fetchWardNameDropdownApi, { lsgiId: responseAction?.payload?.lsgiId?.id, pageNo: 0, pageSize: 500 });
        }
    }
}

export function* deleteWasteProcessing({ payload: id }) {
    yield fork(handleAPIRequest, Api.deleteWssInfoApi, id);
    const response = yield take([ACTION_TYPES.DELETE_WSS_INFO_SUCCESS, ACTION_TYPES.DELETE_WSS_INFO_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_WSS_INFO_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("waste_segregation_storage_info") }) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.WSS_INFO_LIST]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchWssInfoListApi, { pageNo: 0, pageSize: 5, ...filters });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WSS_INFO_LIST_SUCCESS, ACTION_TYPES.FETCH_WSS_INFO_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WSS_INFO_LIST);
    }
}

export function* wardNameDropdown({ payload }) {
    yield call(handleAPIRequest, Api.fetchWardNameDropdownApi, payload);
}

export function* downloadWssInfo(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.WSS_INFO.DOWNLOAD,
        data: { type},
        file: { name: fileName, ext: type }
    }));
}
export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_WSS_INFO_LIST, getWssInfoList),
        takeLatest(ACTION_TYPES.POST_WSS_INFO, submitWssInfo),
        takeLatest(ACTION_TYPES.PATCH_WSS_INFO, submitWssInfo),
        takeLatest(ACTION_TYPES.FETCH_WSS_INFO_BY_ID, wasteProcessingById),
        takeLatest(ACTION_TYPES.DELETE_WSS_INFO, deleteWasteProcessing),
        takeLatest(ACTION_TYPES.FETCH_WARD_NAME_DROPDOWN, wardNameDropdown),
        takeLatest(ACTION_TYPES.DOWNLOAD_WSS_INFO, downloadWssInfo)
    ]);
}
