/* eslint-disable camelcase */
export default {
    //privacy
    privacy_policy_body: "This privacy policy governs your use of the application Training MIS for android devices that is owned by Keltron. Training MIS is a mobile based cloud software for training officials. Training MIS is owned by Keltron.",
    information_we_collect: "Information we collect",
    information_we_collect_body: "We use your Personal Information for the following purposes:",
    information_we_collect_list_01: "to create your user ID;",
    information_we_collect_list_02: "to identify you once you register on our mobile app;",
    information_we_collect_list_03: "to contact you and respond to your complaints or requests; and",
    information_we_collect_list_04: "to provide access to desirable content based on your preferences",
    information_we_share: "Information we share",
    information_we_share_body: "Keltron does not share your Personal Information with third parties for their direct marketing purposes.",
    information_we_share_body_02: "We share your Personal Information within",
    information_we_share_list_01: "Authorized users within the application",
    information_we_share_list_02: "Service vendors;",
    information_we_share_list_03: "Authorized third-party agents;",
    information_security: "Information Security",
    information_security_body: "We review the information that is collected by us periodically and backup measures are in place. When this privacy policy applies Our privacy policy applies for services offered through this application only. What are my opt-out rights? You can stop all services by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.",
    changes: "Changes",
    changes_body: "This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.",
    contact_us: "Contact us",
    contact_us_body: "If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email keltron.org",
    // Privacy Policy ends
    terms_conditions: "Terms Conditions",
    welcome: "Welcome",
    table: "Sample Table",
    submit: "Submit",
    update: "Update",
    success: "Success",
    failed: "Failed",
    title: "Title",
    create: "Create",
    login: "Login",
    sign_in: "Sign In",
    home: "Home",
    save: "Save",
    edit: "Edit",
    add: "Add",
    cancel: "Cancel",
    profile: "Profile",
    user: "User",
    successfully: "Successfully",
    verified: "Verified",
    account_created: "Account created successfully",
    resubmit: "Resubmit",
    active_role: "Active role",
    type_of_facilities: "Type of facilities",

    //Admin Module
    district: "District",
    state: "State",
    album_id: "Album Id",
    id: "Id",
    code: "Code",
    name: "Name",
    details: "Details",
    user_name: "User Name",
    user_types: "User Types",
    ulb_name: "ULB Name",
    ulb_category: "ULB Category",
    ward_number: "Ward Number",
    ward_name: "Ward Name",
    delete: "Delete",
    nav_title: "Home",
    view_profile: "view profile",
    phone_number: "Phone Number",
    phone_number_required: "Phone number is required",
    invalid_phone_number: "Invalid phone number",
    alternate_phone_number_required: "Alternate Phone number is required",
    district_required: "District is required",
    municipality_required: "Municipality is required",
    ward_required: "Ward is required",
    invalid_email: "Invalid email",
    email: "Email",
    enter_username_password: "Please enter your username and password",
    create_account: "Create Account",
    virtualised: "Example Virtualised Table",
    contact_name: "Contact Name",
    address: "Address",
    mobile_number: "Mobile Number",
    alternate_number: "Alternate Mobile Number",
    email_id: "Email ID",
    contact_type: "Contact Type",
    email_required: "Email required",
    virtualized: "Example Virtualized Table",
    description: "Description ",
    iea: "IEA",
    contacts: "Contacts",
    gst_no: "GST No",
    category: "Category",
    contactname: "Contact Name",
    mobile: "Mobile",
    alternateNo: "Alternate Number",
    contacttype: "Contact Type",
    lookup_category: "Lookup Category",
    gst_required: "Gst No required",
    required: "Required",
    are_you_sure: "Are you sure",
    are_sure_want_delete: "Are you sure want to delete?",
    look_up: "Lookup Value",
    lookup_category_id: "Lookup Category",
    changes_are_not_saved: "Changes are not saved",
    action_cancelled: "Action Cancelled",
    districts: "Districts",
    districts_details: "District Details",
    delete_success: "{{type}} successfully deleted",
    delete_failed: "{{type}} failed to delete ",
    register_account: "Register Account",
    lookup_category_name: "Lookup  Category Name",
    loksabha_details: "Loksabha Constituency Details",
    Loksabha_constituency: "Loksabha Constituency",
    legislative_list: "Legislative",
    legislative_assembly: "Legislative Assembly",
    legislative_details: "Legislative Details",
    lookup: "Lookup",
    tentative_date: "Tentative Date",
    training: "Training",
    plan_name: "Plan Name",
    from_date: "From Date",
    to_date: "To Date",
    topic: "Topic",
    trainer_type: "Trainer Type",
    training_plan_list: "Training Plan List",
    target_group: "Target Group",
    expected_count: "Expected Count of Participants",
    priority: "Priority",
    tna_id: "TNA ID",
    contact_list: "Contact List",
    training_organization_table: "Training Organization Table",
    view: "View",
    sho_ngo_table: "SHO NGO TABLE",
    sho_ngo: "SHO NGO",
    training_organization: "Training Organizations",
    recycle: "recycle",
    kswmp: "KSWMP",
    login_desc: " To make Kerala's development journey sustainable and to make Kerala's cities cleaner, healthier, and more liveable",
    welcome_back: "Welcome Back...",
    account_login: "Account Login",
    password: "Password",
    password_mismatch: "Password Mismatch",
    remember_me: "Remember Me",
    forgot_password: "Forgot Password?",
    sign_up: "Sign Up",
    wards: "Wards",
    ward_details: "Ward Details",
    uib_number: "UIB Number",
    uib_name: "UIB Name",
    back: "Back",
    reset_password: "Reset Password",
    to_home: "Back to home",
    confirm_otp: "Confirm OTP",
    confirm: "Confirm",
    new_password: "New Password",
    enter_new_password: "Enter New Password",
    symbols_at_least: "8 Symbols at least",
    confirm_password: "Confirm Password",
    re_enter_password: "Re-enter Password",
    enter_email_or_phone: "Enter Email Or Phone",
    send: "Send",
    View: "View",
    send_otp: "Send OTP",
    copyright: "© 2023. All Rights Reserved KSWMP",
    privacy_policy: "Privacy Policy",
    terms_of_service: "Terms Of Service",
    version: "v1.110",
    settings: "Settings",
    logout: "Logout",
    time_remaining: "Time Remaining : ",
    state_code: "State Code",
    state_name: "State Name",
    confirmation: "Confirmation",
    ulb_code: "ULB Code",
    complaint: "Complaint",
    complaint_by: "Complaint Registered for",
    complaint_number: "Complaint Number",
    complaint_category: "Complaint Category",
    complaint_mode: "Complaint Mode",
    complaint_type: "Complaint Type",
    complaint_types: "Complaint {{type}}",
    requests: "Request {{type}}",
    complaint_date: "Complaint Date",
    complaint_details: "Complaint Details",
    complaint_details_required: "Complaint details required",
    complaint_status: "Complaint Status",
    my_complaints: "My Complaints",
    concerned_organization: "Concerned Organization",
    description_required: "Description required",
    vendor_category: "Vendor Category",
    vendor_contact: "Vendor Contact",
    complaint_date_time: "Complaint Date and Time",
    location: "Location",
    process_complaint: "Process Complaint",
    location_address: "Location/Address",
    organization_type: "Organization Type",
    organization_name: "Organization Name",
    category_name: "Category Name",
    other_departments: "Other Departments",
    contact: "Contact",
    user_role_mapping: "User Role Mapping",
    unassign_success: "Unassign Success",
    parent: "Parent",
    is_parent: "Is Parent",
    role_mapping: "Role Mapping",
    roles_unassigned: "Unassigned Roles",
    roles_assigned: "Assigned Roles",
    please_choose_role_level: "Please choose role level",
    status_name: "Status Name",
    lsgi_types: "Types",
    complaint_escalation: "Complaint Escalation",
    escalation: "Escalation",
    target_level: "Target Level",
    select: "Select",
    select_officers: "Select Officers",
    officers: "Officers",
    ok: "Ok",
    select_ward: "Select Ward",
    escalation_level: "Escalation Level",
    escalation_unit: "Escalation Unit",
    escalation_threshold: "Escalation Threshold",
    officers_name: "Officer Name",
    officers_list: "Officers List",
    officers_details: "Officer Details",
    assigned_to: "Assigned To",
    attachment_remark: "Attachment Remark",
    action_remark: "Actions Remark",
    officer_remark: "Officer Remarks",
    complaint_action: "Complaint Action",
    reassign: "Re-Assign",
    reassigned: "Re-Assigned",
    officer_remark_required: "Officer Remark Required",
    signature: "Signature",
    uploaded: "Uploaded",
    crop_image_title: "Crop and Upload Image",
    files_not_allowed: "This file is not allowed. Only images are allowed",
    file_size_not_allowed: "File size is too Large. {{size}} MB!, more than 10 MB not allowed",
    allowed_characters: "Allowed Characters '_'",
    reassignment: "Reassignment",
    feedback: "Feedback",
    remark_required: "Remark required",
    organization_setup: "Organization Setup",
    general_setup: "General Setup",
    lookup_configuration: "Lookup Configuration",
    admin: "Admin",
    grievance: "Grievance",
    lookup_value: "Lookup Value",
    dashboard: "Dashboard",
    miscellaneous: "Miscellaneous",
    external_organization: "External Organization",
    role_management: "Role Management",
    user_management: "User Management",
    thanks_for_your_feedback: "Thanks for your feedback",
    role_level: "Role Level",
    gst_number: "GST Number",
    example: "Example",
    grand_management: "Grant Management",
    operational_module: "Operational Module",
    complaints: "Complaints",
    download_excel: "Download Excel",
    download_pdf: "Download Pdf",
    name_of_wards: "Name of Ward",
    total_dwelling_units: "Total Dwelling Units",

    //Grievance Module
    user_register: "Users",
    municipality: "Municipality",
    ward: "Ward",
    gender: "Gender",
    organization: "Organization",
    designation: "Designation",
    first_name: "First Name",
    middle_name: "Middle Name",
    last_name: "Last Name",
    effective_from: "{{type}} Effective From Date",
    effective_to: "{{type}} Effective To Date",
    login_type: "Login Type",
    user_type: "User Type",
    enter_complaint_category: "Please enter complaint category",
    category_description: "Please enter category description",
    category_status: "Category Status",
    status_description: "Status Description",
    type_description: "Type Description",
    enter_complaint_status: "Please enter complaint status",
    complaint_status_description: "Please enter complaint description",
    enter_complaint_type: "Please enter complaint type",
    complaint_type_description: "Please enter complaint description",
    please_select_an_option: "Please select an option",
    display_name: "Display Name",
    lsgi_type: "Type",
    lsgi_name: "LSGI Name",
    lsgi_details: "ULB Details",
    register_training_org: "Add Trainer",
    user_profile: "User Profile",
    end_date_start_date: "Should be greater than Effective from Date",
    category_type: "Category Type",
    lsgi: "ULB",
    ulb_list: "ULB List",
    action_occurred: "Action Occurred",
    officer: "Officer",
    my_approvals: "My Approvals",
    customer_no: "Customer No",
    customer_name: "Customer Name",
    approval_status: "Approval Status",
    requested_user: "Requested User",
    requester_remarks: "Requester Remarks",
    requested_time: "Requested Time",
    processed_time: "Processed Time",
    approver_remarks: "Approver Remarks",
    requested_user_details: "Requested User Details",
    view_complaint: "View Current Complaint",
    complaint_id: "Complaint ID",
    complaint_no: "Complaint Number",
    my_approvals_details: "My Approval Details",
    user_details: "User Details",
    action_processed: "Action Processed",
    my_approval_pending: "Pending List",
    my_approval_rejected: "Rejected List",
    my_approval_approved: "Approved List",
    pending: "Pending",
    rejected: "Rejected",
    approved: "Approved",
    actions: "Actions",
    status: "Status",
    ulb: "ULB",
    type: "Type",
    request_type: "Request Type",
    user_register_route: "User Register",
    choose_user: "Choose User",
    //reports
    start_date: "Start Date",
    end_date: "End Date",
    complaint_report: "Complaint reports",
    complaint_handler: "Complaint Handler",
    complaint_registered_by: "Complaint Registered By",
    escalation_report: "Escalation Report",
    complainer_name: "Complainer Name",
    complaint_sub_category: "Complaint Sub Category",
    number_of_times_escalated: "Number of Times Escalated",
    currently_with_officer: "Currently with (officer)",
    //templates
    invalid_type: "Invalid {{type}}",
    invalid_format: "Invalid {{type}} format",
    must_be: "{{type}} must be at least {{count}} length",
    should_not_be: "{{type}} should not greater than {{count}} length",
    is_required: "{{type}} is required",
    list: "Lists {{type}}",
    level: "{{type}} Level",
    is_unique: "{{type}} Already Exists",
    must_be_greater: "{{type}} must be greater than {{count}}",

    // Messages
    no_account: "Don't have an account yet?",
    try_again: "Try Again",
    requested_url_not_found: "Requested URL Not Found !",
    access_denied: "Access Denied",
    something_went_wrong: "Something Went Wrong",
    lets_go: "Lets Go",
    please_choose_option: "Please choose an option",
    successfully_deleted: "Successfully Deleted!!",
    plan: "Training Plan",
    roles: "Roles",
    user_role_mapping_form: "User Role Mapping Form",
    date: "Date",
    unassign: "Unassign",
    unassign_role: "Show Unassigned Roles",
    assign: "Assign",
    user_roles: "User Roles",
    vendors: "Vendors",
    created: "{{type}} added successfully",
    creat: "{{type}} submitted successfully",
    updated: "{{type}} Updated Successfully",
    training_plan: "Training Plan",
    training_need_table: "Training Need Table",
    training_need_assessment: "Training Need Assessment",
    training_need: "Training Need",
    user_register_official: "Add Officer",
    role: "Role",
    parent_type: "Parent {{type}}",
    parent_or_not: "Parent or Not",
    role_details: "Role Details",
    menu_management: "Menu Management",
    module_id: "Module Id",
    registration_details: "Registration Details",
    module: "Module",
    resource_id: "Resource Id",
    profile_details: "Profile Details",
    profile_image: "Profile Image",
    upload_image: "Upload Profile Image",
    upload_signature: "Upload Signature",
    update_account: "Update Account",
    basic_details: "Basic Details",
    data_access: "Data Access",
    training_venue: "Training Venue",
    training_venue_list: "Training Venue List",
    venue_code: "Venue Code",
    venue_name: "Venue Name",
    need_table_row: "Need Table Row",
    training_course: "Training Course",
    course_page: "Training Courses",
    longitude: "Longitude",
    latitude: "Latitude",
    session: "Session",
    reference_urls: "Reference Urls",
    available_modes: "Available Modes",
    training_course_table: "Training Course Table",
    training_batch: "Training Batch",
    add_question: "Add Question",
    questionnaire_page: "Questionnaire Page",
    course_name: "Course Name",
    question_name: "Questionnaire Name",
    question: "Question",
    answer: "Answer",
    are_sure_want_unassign: "Are you sure want to unassign?",
    attachment: "Attachment",
    attachments: "Attachments",
    remarks: "Remarks",
    designation_entry: "Designations",
    designations: "Designations",
    capital_only: "Name must be capitalized",
    block_panchayath: "Block Panchayath",
    district_panchayath: "District Panchayath",
    filter: "Filter",
    option: "option",
    tna_matrix: "TNA Matrix",
    tna_name: "TNA Name",
    target_group_category: "Target Group Category",
    knowledge_base: " Knowledge Base",
    course: "Course",
    course_plan_matrix: "Course Plan Matrix",
    required_field: "Required Field",
    batch_name: "Batch Name",
    host: "Host",
    venue: "Venue",
    training_mode: "Training Mode",
    training_batch_table: "Training Batch Table",
    course_plan: "Course Plan",
    otp_type: "OTP Type",
    email_mobile: "Email or Mobile Number",
    invalid_email_mobile: "Invalid Email or Mobile Number",
    view_participants: "View Participants",
    participants_list: "Participants List",
    add_participants: "Add Participants",
    add_batch: "Add Batch",
    add_questionnaire: "Add Questionnaire",
    optionA: "Option A",
    optionB: "Option B",
    optionC: "Option C",
    optionD: "Option D",
    correct_answer: "Correct Answer",
    questionnaire: "Questionnaire",
    training_schedule: "Training Schedule",
    training_schedule_table: "Training Schedule Table",
    actual_date: "Actual Date",
    from_time: "From Time",
    to_time: "To Time",
    course_id: "Course Id",
    batch_id: "Batch Id",
    session_id: "Session Id",
    trainer_id: "Trainer Id",
    training_organization_id: "Training Organization Id",
    training_certificate: "Training Certificate",
    training_certificate_table: "Training Certificate Table",
    template: "Template",
    issued_by: "Issued By",
    content: "Content",
    batch: "Batch",
    additional_session: "Additional Session",
    question_type: "Question Type",
    training_status: "Training Status",
    verify: "Verify",
    resend: "Resend",
    withdraw: "Withdraw",
    withdrawn: "Withdrawn",
    registered_by: "Registered By",
    ulb_population: "ULB Population",

    //Allocations & Requests
    sl_no: "Sl No",
    requested_date: "Requested Date",
    requested_status: "Requested Status",
    ulb_requests: "Grant Request ULB",
    dpmu_requests: "Grant Request DPMU",
    spmu_requests: "Grant Request SPMU",
    grant_requests: "Grant Request",
    ulb_allocation: "Grant Allocation ULB ",
    dpmu_allocation: "Grant Allocation DPMU",
    spmu_allocation: "Grant Allocation SPMU",
    application_id: "Application ID",
    total_grant_allocated: "Total Grant Allocated",
    incentive_grant1_allocated: "Incentive Grant 1 Allocated",
    incentive_grant2_allocated: "Incentive Grant 2 Allocated",
    criteria_met: "Criteria met",
    grant_allocation: "Grant Allocation",
    grant_allocation_ceiling: "Grant Allocation Ceiling",
    eligibility_criteria: "Eligibility Criteria",
    compliance_y_n: "Compliance",
    compliance: "Compliance",
    proof_attachment: "Proof Attachment",
    iva_remarks: "IVA Remarks",
    iva: "IVA",
    plan_view: "Plan View",
    total_amount: "Total Amount",
    search: "Search",
    reset: "Reset",
    training_conducted: "Training Conducted",
    training_scheduled: "Training Scheduled",
    tcm_training_conducted: "Tcm Training Conducted",
    tcm_training_scheduled: "Tcm Training Scheduled",
    participants_registry: "Participants Registry",
    training_name: "Training Name",
    trainers_name: "Trainer's Name",
    date_of_training: "Date of Training",
    no_of_participants: "Number of Participants",
    no_of_attendees: "Number of Attendees",
    proposed_date_of_training: "Proposed Date of Training",
    training_summary: "Training Summary",
    training_participants: "Training Participants",
    tna: "TNA",
    number_of_batch: "No. of Batch",
    actual_partcipants: "Actual Participants",
    proposed_start_date: "Proposed Start Date",
    proposed_end_date: "Proposed End Date",
    actual_start_date: "Actual Start Date",
    actual_end_date: "Actual End Date",
    planned_participants: "Planned Participants",
    actual_participants: "Actual Participants",
    participants_name: "Participants Name",
    attendance: "Attendance",
    training_participants_analysis: "Training Participants Analysis",


    //grant management module
    forex_exchange: "Forex Exchange Rate As On",
    exchange_rate: "Exchange Rate",
    total_fund: "Total Fund",
    in_inr: "In INR",
    in_usd: "In USD",
    strength: "Strength",
    primary_collection: "Primary Collection",
    primary_collection_agency: "Primary Collection Agency",
    agency_code: "Agency Code",
    name_of_agency: "Name Of Agency",
    women_group: "Women Group",
    collection_in_per_day: "Collection Per Day",
    _sq_km: "Area",
    male: "Male",
    female: "Female",
    transgender: "Transgender",
    storage_facility: "Storage Facility",
    collection_date: "Collection Date",
    total: "Total",
    participants: "Participants",
    sc: "SC",
    st: "ST",
    old_age: "Old Age",
    senior_citizen: "Senior Citizen",
    disabled: "Disabled",
    population: "Population",
    census_year: "Census Year",
    not_assigned: "Not Assigned",
    new: "New",
    in_progress: "In-Progress",
    closed: "Closed",
    resolved: "Resolved",
    escalated: "Escalated",
    reopen: "Reopen",
    reopened: "Reopened",
    close: "Close",
    resolve: "Resolve",
    reject: "Reject",
    are_sure_want_add: "Add Confirmation",
    approve: "Approve",
    gac_computation: "GAC Computation",
    gac_ceiling: "GAC Ceiling",
    total_grant: "Total Grant",
    basic_grant: "Basic Grant",
    incentive_grant: "Incentive Grant",
    year: "Year",
    total_population: "Total Population",
    total_population_2023: "Total Population 2023",
    calculate: "Calculate",
    check_availability: "Check Availability",
    available: "Available",
    please_verify: "Verify {{type}}",
    downloading_file: "Downloading {{file}}",
    downloaded: "Downloaded",
    create_user: "Create User",
    fetch_expenses: "Fetch Expenses",
    fetch: "Fetch From Sankhya",
    expense_amount: "Expense Amount",
    as_on_date: "As on Date",
    micro_sector_code: "Micro Sector Code",
    sub_project: "Sub Projects",
    plans: "Plans",
    number: "Sl No",
    comments: "Comment",
    fy: "FY",
    project_name: "Project Name",
    as_date: "As on Date",
    project_id: "Project Id",
    financial_year: "Financial Year",
    bills: "Bills",
    bill_date: "Bill Date",
    bill_no: "Bill No",
    bill_issued_by: "Bill Issued By",
    bill_status: "Bill Status",
    remark: "Remarks",
    bill_amount: "Bill Amount",
    sanctioned_amount: "Sanctioned Amount",
    project: "Project",
    lsgi_types_select: "LSGI Types",
    fetch_expenses_from_sankhya: "Fetch Expenses from Sankhya",
    questionnaire_name: "Questionnaire Name",
    show_checkbox: "Show Checkbox",
    groupe_type: "Group Type",
    session_type: "Session Type",
    annual_trigger: "Annual Trigger",
    utilization_request: "Utilization Request",
    at_points: "5 At Points",
    remarks_by_dpmu: "Remarks by DPMU",
    eligible_amount: "Eligible Amount",
    suspended_amount: "Suspended Amount",
    annual_plan: "Annual Plan",
    annual_trigger_review: "Annual Trigger Review",
    remarks_by_ulb: "Remarks by ULB",
    dpmu_remarks: "DPMU Remarks",
    ulb_remarks: "ULB Remarks",
    spmu_remarks: "SPMU Remarks",
    save_project_details: "Save Project Details",
    grand_utilization: "Grant Utilization",
    utilization: "Utilization",
    allocation: "Allocation",
    request: "Request",
    dpmu_remarks_to_ulb: "DPMU Remarks To ULB",
    dpmu_remarks_to_spmu: "DPMU Remarks To SPMU",
    dpmu_remarks_to_iva: "DPMU Remarks To IVA",
    project_details: "Project Details",
    application_status: "Application Status",
    comment: "Comment",
    eligibility_amount: "Eligibility Amount",
    grant_eligibility: "Grant Eligibility",
    ulb_application: "Application {{type}}",
    grant_request: "Grant Request",
    annual_plan_year: "Annual Plan Year",
    grant_approval: "Grant Approval",
    iva_status: "IVA Status",
    iva_attachments: "IVA Attachments",
    to_dpmu: "Submit to DPMU",
    to_iva: "Submit to IVA",
    to_spmu: "Submit to SPMU",
    to_ulb: "Return to ULB",
    saved: "Saved",
    dpmu: "DPMU",
    spmu: "SPMU",
    application_submit: "Application Submitted",
    application_updated: "Application Updated",
    application_rejected: "Application Rejected",
    application_verified: "Application Verified",
    application_approved: "Application Approved",
    bill_number: "Bill Number",
    count: "Count",
    confirm_add_participants: "Are You Sure To Add These Participants ",
    refresher_training: "Refresher Training",
    issue_certificate: "Issue Certificate",
    certification_issue_list: "Certification Issue Table",
    attendance_list: "Attendance List",
    MarkAttendance: "Mark Attendance",
    submitted: "{{type}} successfully",
    submit_success: "{{type}} successfully",
    reject_success: "{{type}} successfully",
    verified_success: "{{type}} successfully",
    update_success: "{{type}} successfully",
    approve_success: "{{type}} successfully",
    complaintApprovalDetails: "Complaint Approval Details",
    action_list: "Action List",
    disposal_remarks: "Disposal Remarks",
    assigned_to_me: "Assigned To Me",
    created_by_me: "Created By Me",
    unassigned: "Unassigned",
    escalation_levels: "Escalation Levels",
    ward_escalation: "Ward Escalation",
    full_name: "Full Name",
    added: "{{type}} successfully",
    greater_than_start_date: "Should be greater than Start Date",
    assigned: "Assigned",
    financial_year_starting: "Financial year starting",
    accept: "Accept",
    yes: "Yes",
    no: "No",
    suspended_amount_rs: "Suspended Amount Rs",
    confirmation_message: "is not accepted by ULB. Do you still want to proceed with this amount?",
    schedule: "Schedule",
    rating: "Rating",
    course_content_rating: "Course Content Rating",
    faculty_performance_rating: "Faculty Performance Rating",
    usefulness: "Usefulness",
    arrangements_rating: "Arrangements Rating",
    video_tutorial: "Video Tutorial",
    discussion_and_approval: "Discussion and Approval",

    //Sub-module
    swm_plan: "SWM Plan",
    swm_plan_id: "SWM Plan Id",
    swm_plan_name: "SWM Plan Name",
    plan_status: "Plan Status",
    old_password: "Enter Old Password",
    change_password: "Change Password",
    change: "{{type}} changed successfully",
    forgot: "{{type}} reset successfully",
    check_in: "Activate Check In",
    check_out: "Activate Check Out",
    organized_by: "Organized By",
    composition_of_team: "Composition of Team",
    area_of_expertise: "Area of Expertise",
    add_team_members: "Add Team members",
    team_member: "Team Member",
    assessment_gap_analysis: "Assessment Current Status",
    is_this_activity_completed: "Is this activity completed?",
    stakeholders_consultation: "Stakeholder Consultations",
    hks_members_count: "HKS Members count",
    other_waste_workers_count: "Other waste workers count",
    kudumbashree_members_count: "Kudumbashree Members count",
    rag_pickers_count: "Rag Pickers count",
    other_scrap_dealers_count: "Other scrap dealers count",
    old_age_count: "Old Age count",
    male_count: "Male count",
    female_count: "Female count",
    transgender_count: "Transgender count",
    disabled_count: "Disabled count",
    total_participants: "Total participants",
    sub_projects: "Sub Projects",
    project_category: "Project Category",
    project_sub_category: "Project Sub Category",
    sub_project_spmu_dpmu: "Sub Project List for SPMU/DPMU",
    projects: "Project",
    amount: "Amount",
    sub_project_for_dpmu_spmu: "Sub Project for DPMU/SPMU",
    sub_projects_spmu: "SubProjectsSPMU",
    team_composition: "Team composition",
    gap_analysis: "Current Status",
    create_gap_analysis: "Create Current Status",
    update_gap_analysis: "Update Version",
    version_name: "Version Name",
    gap_analysis_date: "Current Status Date",
    pre_plan_consultation: "Pre plan consultation",
    draft_plan: "Draft plan",
    post_plan_consultation: "Post plan consultation",
    swm_plan_finalization: "SWM plan finalization",
    plan_id: "Plan id",
    draft_plan_id: "Draft plan id",
    draft_plan_name: "Draft plan name",
    estimate: "Estimate",
    project_temporary_code: "Temporary Project Code / Project Code",
    track: "Track",
    project_concept_note: "Project Concept Note",
    preliminary_cost_estimate: "Preliminary Cost Estimate",
    preliminary_feasibility_analysis: "Preliminary Feasibility Analysis",
    sub_category: "Sub Category",
    sub_category_two: "Sub Category Two",
    types_of_projects: "Types of Projects",
    environmental_category: "Environmental Category",
    sub_project_draft: "Sub Project Draft",
    post_plan_stakeholder: "Post Plan Stakeholder",
    swm_plan_finalize: "SWM Plan Finalize",
    swm_final_plan: "SWM Final Plan",
    sub_project_draft_plan: "Sub Project - Draft Plan",
    sub_project_final_plan: "Sub Project Final Plan",
    council_resolution_number: "Council resolution number",
    approval_from_mc: "Approval from Municipal council",
    mc: "MC",
    remark_mc: "Remark by Municipal council",
    remark_by_dpmu: "Remark by DPMU",
    final_sub_project_plan: "Final Sub-Project Plan",
    final_plan: "Upload Final Plan",
    final_sub_project: "Final Sub-Project",
    sub_project_list: "Sub-Project List",
    submitted_dpmu: "Submit To DPMU",
    project_mapping: "Project Mapping",
    previous_swm_plans: "Previous SWM Plans",
    swm_plan_attachment: "SWM Plan Attachment",
    submit_mc: "Submit To MC",
    previous_mc_remarks: "Previous MC Remarks",
    previous_dpmu_remarks: "Previous DPMU Remarks",
    previous_spmu_remarks: "Previous SPMU Remarks",
    attachment_is_required: "Attachment is required",
    no_previous_plans: "No Previous Plans",
    dpmc: "DPMC",
    previous_dpmc_remarks: "Previous DPMC Remarks",
    previous_spmc_remarks: "Previous SPMC Remarks",
    spmc: "SPMC",
    spmc_social_expert: "SPMC Social Expert",
    spmc_env_expert: "SPMC Environmental Expert",
    spmc_fin_expert: "SPMC Financial Expert",
    spmc_tech_expert: "SPMC Technical Expert",
    dpmc_social_expert: "DPMC Social Expert",
    dpmc_env_expert: "DPMC Environmental Expert",
    dpmc_fin_expert: "DPMC Financial Expert",
    dpmc_tech_expert: "DPMC Technical Expert",
    dpmu_social_expert: "DPMU Social Expert",
    dpmu_env_expert: "DPMU Environmental Expert",
    dpmu_fin_expert: "DPMU Financial Expert",
    dpmu_tech_expert: "DPMU Technical Expert",
    draft_plan_remarks: "Draft Plan Remarks",
    meeting_date: "Meeting Date",
    meeting_venue: "Meeting Venue",
    hks_member_count: "HKS Member Count",
    other_waste_worker_count: "Other Waste Workers Count",
    kudumbasree_members_count: "Kudumbasree Members Count",
    other_scrap_dealer_count: "Other Scrap Dealers Count",
    remarks_isRequired: "Remarks is required",
    //Five year plan
    five_year_plan: "Five Year Plan",
    swm_sub_projects: "Sub Projects",
    five_year_plan_code: "Plan Code",
    five_year_plan_name: "Plan Name",
    from_year: "From Year",
    to_year: "To Year",
    remove: "Remove",
    assigned_five_year_project: "Assigned five year project",
    permission: "Permission",
    date_of_screening: "Date of Screening",
    date_of_completion: "Date of Completion",
    // swm sub projects
    swm_sub_project_details: "Details",
    swm_sub_project_screening_spmu: "Screening SPMU",
    swm_sub_project_submit_to_screening: "Submit to Screening - By SPMU",
    swm_sub_project_group_discussion: "Working Group Discussion",
    swm_sub_project_ward_sabha: "Ward Sabha",
    swm_sub_project_vikasana_seminar: "Vikasana Seminar",
    swm_sub_project_hsc_approval: "HSC Approval",
    swm_sub_project_mc_approval: "MC Approval",
    swm_sub_project_sulekha_information: "Sulekha Information",
    swm_sub_project_dpc_approval: "DPC Approval",
    swm_sub_project_safeguard_report: "Safeguard Report",
    working_group_discussion: "Working Group Discussion",
    has_screening_done: "Has Screening Done",
    consent_date: "Consent Date",
    consent_received_hsc: "Consent Received from HSC",
    consent_received_mc: "Consent Received from MC",
    sub_project_details: "Sub Project - Details",
    brief_description: "Brief Description",
    long_description: "Long Description",
    physical_location: "physical Location",
    service_ward: "Service Ward",
    physical_location_ward: "Physical Location (Ward)",
    sulekha_project_code: "Sulekha Project Code",
    ward_sabha_discusion: "Ward Sabha Discussion",
    project_level_data: "Project Level Data",
    safeguard_compliance: "Safeguard Compliance",
    community_consultation: "Community Consultation",
    identified_data: "Identified Data",
    remarks_by_tsc: "Remarks By TSC",
    action: "Action",
    attachments_mom_and_photograph: "Attachments: MoM & Photograph",
    revert_sulekha: "Revert Sulekha Information",
    revert_sulekha_successfully: "Revert Sulekha Information Successfully",
    //subProjectActivity
    activity_name: "Activity Name",
    uom: "Unit of Measurement",
    unity: "Unit",
    unity_cost: "Unit Cost",
    new_milestones: "New Milestones",
    milestones: "Milestones",
    annual_plan_qa_qc: "Annual plan QA/QC",
    annual_plan_upload: "Annual plan upload",
    project_list: "Project list",
    crux: "Crux",
    plan_code: "Plan code",
    verify_mc: "Verify MC",
    verify_dpmu: "Verify DPMU",
    verify_spmu: "Verify SPMU",
    assigned_annual_project: "Assigned annual project",
    spmu_approval: "SPMU Approval",
    dpmu_approval: "DPMU Approval",
    municipal_approval: "Municipal Approval",
    mc_remarks: "MC Remarks",
    update_milestones: "Update Milestones",
    vikasana_seminar: "Vikasana Seminar",
    has_vikasana_seminar_completed: " Has Vikasana Seminar Completed?",
    seminar_agenda: "Seminar Agenda",
    photo_attachment: "Photo Attachment",
    minutes_of_meeting: "Minutes of Meeting",
    date_of_vikasana_seminar: "Date of vikasana seminar (if completed)",
    ward_sabha: "Ward Sabha",
    is_discussion_completed: "Is Discussion Completed",
    date_of_discussion: "Date of Discussion",
    meeting_agenda: "Meeting Agenda",
    transgenger: "Transgenger",
    hks: "HKS",
    kudumbasree: "Kudumbasree",
    rag_pickers: "Rag Pickers",
    other_scrap_dealer: "Other Scrap Dealer",
    other_workers: "Other Waste Workers",
    edit_ward_sabha: "Edit Ward Sabha",
    view_ward_sabha: "View Ward Sabha",
    dpc_approval_date: "DPC Approval Date",
    received_dpc_approval: "Received DPC Approval",
    env_factors_attachment: "Environmental Factors Attachment",
    social_factor_attachment: "Social Factors Attachment",
    mom_attachments: "MOM Attachments",
    hks_count: "HKS Count",
    are_sure_check_out: "Are you sure activate checkout?",
    working_group_discussion_completed: "Working Group Discussion Complete",
    nature_of_work: "Nature of work",
    // swm dpr
    dpr_preparation: "DPR Preparation",
    dpr: "DPR",
    noc_from_wb: "NOC from WB",
    noc_from_world_bank: "NOC from World Bank",
    technical_sanction: "Technical Sanction",
    financial_sanction: "Financial Sanction",
    estimate_cost: "Estimate Cost",
    estimate_cost_for: "Estimate Cost (Including Social and Environment Component Cost)",
    social_component_cost: "Social Component Cost",
    env_component_cost: "Environment Component Cost",
    tribal_dev_plan: "Tribal Development Plan",
    livelihood_action_plan: "Livelihood Action Plan",
    business_dev_plan: "Business Development Plan",
    labour_influx: "Labour Influx",
    resettlement_policy: "Resettlement Policy ",
    gender_based_violence: "Gender Based Violence Management Plan",
    host_community_mgmtPlan: "Host Community Management Plan",
    resettlement__policy: "Resettlement Policy ",
    social_mgmt_framework: "Social Management Framework",
    envCategoryId: "Environment Category",
    date_of_screening1: "Date of Screening 1",
    date_of_screening2: "Date of Screening 2",
    date_of_review: "Date Of Review",
    date_of_site_recommaissance: "Date Of Site Reconnaissance",
    esmf_agency_name: "ESMF Agency Name",
    esmf_agency_mobile_no: "ESMF Agency Mobile No",
    esmf_agency_email: "ESMF Agency Email",
    esmf_agency_other_info: "ESMF Agency Other Info",
    dpr_attachments: "DPR Attachments",
    received_noc: "Received NOC from WB",
    received_technical_sanction: "Received Technical Sanction",
    date_of_receipt: "Date of Receipt",
    qa_qc_by_dpmu: "QA/QC by DPMU",
    mc_approval: "Municipal Council Approval",
    send_dydc: "Send MC Approved DPR to DYDC",
    review_by_spmu: "Review by SPMU",
    received_financial_sanction: "Received Financial Sanction",
    sanctioned_project_cost: "Sanctioned Project Cost",
    sanctioned_project_cost_for: "Sanctioned Project Cost(including Soc.and Env.Component Cost)",
    sanctioned_social_component_cost: "Sanctioned Social Component Cost",
    sanctioned_env_componentCost: "Sanctioned Env Component Cost",
    cost: "Cost",
    file_size_exceeds_the_maximum_allowed_size_of_10MB: "File size exceeds the maximum allowed size of 10MB.",
    //repors
    reports: "Reports",
    pending_reports: "Pending Report",
    pending_days: "Pending Days",
    reopened_complaints: "Reopened Complaints",
    grievance_summary: "Grievance Summary",
    grievance_listing: "Grievance Listing",
    date_of_complaint: "Date of Complaint",
    resolution_date: "Resolution Date",
    resolution_period: "Resolution Period",
    resolution_remarks: "Resolution Remarks",
    incident_resolution_period: "Incident Resolution Period",
    mode_of_complaint: "Mode of Complaint",
    reopned: "Reopned",
    at_least_one_attachment_is_required: "At least one attachment is required",
    sub_project_monitoring: "Sub Project Monitoring",
    project_monitoring_activity: "Project Activity",
    project_monitoring_payment_schedule: "Payment Schedule",
    project_monitoring_technical: "Project Monitoring Schedule",
    current_progress_number_type: "Current progress must be a number",

    no_white_space_at_beginning_or_end: "No Space Allowed",
    register_new_complaint: "Register New Complaint",
    attachments_with_size: "Attachments(Max Size 5 MB)",
    approved_user: "Approved User",
    update_status: "Update Status",
    update_complaint: "Update Complaint",
    view_complaints: "View Complaint",
    preparation: "Project Preparation",
    requirement: "Requirement",
    monitoring: "Monitoring",
    milestone_id: "Milestone ID",
    milestone_name: "Milestone Name",
    target_date: "Target Date",
    applicable_plan_policy: "Applicable Plan Policy",
    gst: "GST",
    total_cost: "Total Cost",
    total_cost_for: "Total Cost (including GST and Total Estimate Cost )",
    sanctioned_gst: "Sanctioned GST",
    sanctioned_total_cost: "Sanctioned Total Cost",
    sanctioned_total_cost_for: "Sanctioned Total Cost (including GST and Total Project Cost)",
    payment_schedule: "Payment Schedule",
    template_for_payment_schedule: "Template for Payment Schedule",
    milestone_title: "Milestone Title",
    scheduled_description: "Description",
    planned_date: "Planned Date",
    select_file_to_upload: "Select file to Upload",
    file_size_exceeds_the_maximum_allowed_size_of_50MB: "File size exceeds the maximum allowed size of 50MB.",
    file_size_exceeds_the_maximum_allowed_size_of_5MB: "File size exceeds the maximum allowed size of 5MB.",
    edit_project_monitoring_technical: "Edit Technical ",
    project_code: "Project Code",
    contractor_name: "Contractor Name",
    work_task: "Work / Task",
    scheduled_start_date: "Scheduled Start Date",
    scheduled_end_date: "Scheduled End Date",
    scheduled_quality: "Scheduled Quantity",
    previous_completion_status: "Previous Completion Status",
    current_progress: "Current Progress",
    project_monitoring: "Project Monitoring",
    create_project_activity: "Create Project Activity",
    project_activity: "Project Activity",
    activities: "Activities",
    contractor: "Contractor",
    work: "Work",
    quantity: "Quantity",
    technical: "Technical",
    project_status_update: "Project Status Update",
    completed_quantity: "Completed Quantity",
    scheduled_quantity: "Scheduled Quantity",
    progress: "Progress",
    approval_details: "Approval Details",
    resolution_number: "Resolution Number",
    user_name_message: "Invalid format",
    password_message: "Invalid format",
    invalid_captcha: "Invalid Captcha",
    captcha: "Captcha",
    check_the_availability: "Check Availability of User Name, Mobile Number/Email",
    lok_sabha_constituency: "Lok Sabha Constituency",

    //procurement module
    procurement: "Procurement",
    project_listing: "Project Listing",
    sub_project_procurement: "Sub Project Procurement",
    procurement_activities: "Procurement Activities",
    activities_name: "Activities Name",
    process_status: "Process Status",
    procurement_method: "Procurement Method",
    market_approach: "Market Approach",
    review_type: "Review Type",
    procurement_activities_details: "Procurement Details",
    imported_data: "Important Dates",
    planed_date: "Planed Date",
    completed: "Completed",
    questions: "Questions",
    major_category: "Major Category",
    area_or_sector_of_work: "Area or Sector of Work",
    sub_minor_category: "Sub/Minor Category",
    social_safeguard: "Social Safeguard",
    environmental_safeguard: "Environmental Safeguard",
    dpc_approval_amount: "DPC Approval Amount",
    ceiled_amount: "Ceiled Amount",
    return: "Return",
    view_project_monitoring_technical: "View Technical",
    previous_quantity: "Previous Quantity",
    alt_number_cannot_be_same_as_mobile_number: "Cannot be same as mobile number",
    current_quantity: "Current Quantity",
    five_year_project_mapping: "Five Year Project Mapping",
    five_year_attachments: "Five Year Attachments",
    swm_plan_attachments: "SWM Plan Attachments",
    closure_date: "Closure Date",
    closure_remarks: "Closure Remarks",
    level_of_project: "Level Of Project",
    closure_remarks_reopened: "Closure Remarks(Feedback from Complainant)",
    warranty_or_guarantee_end_date: "Warranty or Guarantee End Date",
    amount_paid: "Amount Paid(Final Cumulative total)",
    user_manual: "User Manual",

    //gap analysis tab
    profile_of_localbody: "Profile Of Local Body",
    swm_facilities_present: "SWM Facilities Present",
    land_details: "Land Details",
    waste_generated: "Waste Generated",
    waste_generators: "Waste Generators",
    waste_collected: "Waste collected",
    waste_processed: "Waste Processed",
    forward_linked: "Forward Linkage",
    income_from_waste: "Income From Waste",
    transportation: "Transportation",
    involvement_of_hks: "Involvement Of Hks",
    payment_to_staffs_and_expenses_in_swm: "Payment To Staffs And Expenses In SWM",
    street_sweeping_and_drains: "Street Sweeping And Drain Cleaning",
    top_grievance_category: "Top Grievance Category",
    complaints_count_recieved: "Number of Complaints received",
    reopened_count: "Reopened Count",
    bid_document: "Bid Document Attachments",
    letter_of_award: "Letter of Award (LOA) – Attachments",
    copy_of_signed_contract: "Copy of Signed Contract – Attachments",
    add_new_non_bio_facilities: "Add Non Bio Facilities",


    //profileoflocalbodyadd
    local_body_name: "Local body name ",
    local_body_type: "Local body type",
    number_of_wards: "Number of wards",
    residential_area: "Residential area (Sqkm)",
    agricultural_area: "Agricultural area (Sqkm)",
    commercial_area: "Commercial area (Sqkm)",
    industrial_area: "Industrial area (Sqkm)",
    other_land_area: "Other land area (Sqkm)",
    total_area: "Total area(Sqkm)",
    total_area_in_sqm: "Total area(Sqm)",
    ulb_type: "ULB Type",

    //Non bio facilities
    non_bio_facilities: "Non Bio Facilities",
    non_bio_facility_equipments: "Non Bio Facility Equipments",
    total_mcf: "Total MCF",
    total_rrf: "Total RRF",
    total_mini_mcf: "Total Mini MCF",
    total_bottle_booths: "Total Bottle Booths",
    temporary_storage: "Temporary Storage",
    total_building_area: "Total Building Area",
    waste_capacity: "Waste Capacity",
    other_equipment_count: "No. of Other Equipments",
    name_of_nonbio_facilities: "Name of NonbioFacility",

    //Non bio Facility Equipments
    weighing_machines: "No. of Weighing Machines",
    sorting_table: "No. of Sorting Tables",
    bailing_machine: "No. of Bailing Machines",
    shredding_machine: "No. of Shredding Machines",
    pelletiser: "No. of Pelletisers",
    jcb_and_forklift: "No. of Jcb and Forklift",
    pushcart_and_trolly: "No. of Push cart and Trolleys",
    reel_splitter: "No. of Reel Splitters",
    bag_opener: "No.of Bag Openers",
    deduster: "No. of Dedusters",
    segregation_boxes: "No. of Segregation Boxes",
    fire_extenguishers: "No. of Fire Extenguishers",
    pushcart_and_trolly_report: "Push cart and Trolleys",
    jcb_and_forklift_report: "Jcb and Forklifts",
    washing_machines: "No. of Washing Machines",
    incinerator: "No. of Incinerators",
    waste_capacity_in_tdp: "Waste Capacity in Tonnes/Day",
    approx_total_building_cost: "Approx total building cost in ₹",
    total_capacity: "Total Carry Capacity In Tonnes",
    total_approximate_price: "Total Approximate Cost",
    own_facility: "Own Facility",
    other_non_bio_facility: "Other Non Bio Facilities",
    approximate_unit_cost: "Approximate Unit Cost In ₹",

    //other non bio facilities
    building_area_sqm: "Building Area in SQM",

    //bio Facility
    bio_facilities: "Bio Facilities",
    add_bio_facilities: "Add Bio Facilities",

    //bio facility equipments
    bio_facility_equipments: "Bio Facility Equipments",
    total_waste_capacity_in_tdp: "Total Waste Capacity In Tonnes Per Day",
    total_waste_capacity: "Total Waste Capacity(TPD)",
    total_waste_generated: "Total Waste Generated(TPD)",

    ulb_wise: "ULB Wise",
    ward_wise: "Ward Wise",
    district_wise: "District Wise",

    in_acres: "In Acres",
    in_ward: "In Ward",
    recorded_date: "Recorded Date",

    //Waste Generated
    per_capita_bio_waste_generation: "Per capita Bio waste generation",
    per_capita_non_bio_waste_generation: "Per Capita NonBio waste generation",
    total_bio_waste: "Total BioWaste",
    total_non_bio_waste: "Total Non BioWaste",
    total_sanitary: "Total Sanitary",
    total_ewaste: "Total eWaste",
    total_c_and_d: "Total C&D",
    total_hazardous: "Total Hazardous",
    total_inert: "Total Inert",
    total_waste_generation: "Total Waste Generation",
    per_capita_waste_generation: "Per Capita Waste Generation",
    other_land_area_tooltip: "(Forest Land, Water Logged, Barren Land, Hills, etc)",

    //Transportation
    number_of_vehicles: "Number Of Vehicles",
    number_of_trips_per_day: "Number Of Trips / Day",
    total_approximate_cost_of_vehicle: "Total Approximate Cost Of Vehicle",

    //Waste Processed
    bdw_household: "Bdw-Household",
    bdw_institutional: "Bdw-Institutional",
    bdw_community_level: "Bdw-Community/Ulb Level",
    total_bdw_processed_per_day: "Total BDW Processed Per Day",
    ulb_supplied: "Ulb Supplied",
    functional: "Functional",
    capacity_in_tdp: "Capacity in TPD",
    waste_processed_in_tdp: "Waste Processed In TPD",
    total_house_hold_units: "Total HouseHold Units",
    total_institutions_unit: "Total Institutions Units",

    //Forward linked
    total_tonnes: "Total Tonnes",
    tdp: "Tonnes Per Day",
    numbers: "Numbers",
    gms: "(gms)",
    agency_involved: "Agency Involved",

    //Street Sweeping And Drains
    total_road_length: "Total Road Length in ULB",
    total_national_highway_length_in_ulb: "Total National Highway Length in ULB",
    total_state_highway_length: "Total State Highway Length in ULB",
    total_other_pwd_road_length: "Total Other PWD Managed Road Length in ULB",
    total_ulb_road_length: "Total ULB Managed Road Length ",
    total_ulb_other_road_length: "Total Other Managed Road Length (Forest,Airport etc) in ULB ",
    avg_road_length_sweeped_manual: "Avg road length sweeped per day/per swm staff (manual)",
    avg_road_length_sweeped_automatic: "Avg road length sweeped per day/per swm staff (automated)",
    avg_person_road_sweeped_manual: "Avg Persons deployed for Road Sweeping (Manual) per day",
    avg_person_road_sweeped_automatic: "Avg Persons deployed for Road Sweeping (Automated) per day",
    avg_road_length_sweeped_manuals: "Average Road Length Sweeped Manual Per Day",
    avg_road_length_sweeped_automatics: "Average Road Length Sweeped Automatic Per Day",
    gap_in_road_sweeping: "Gap In Road Sweeping Per Day",
    total_open_drain_length: "Total Open Drain Length",
    total_closed_drain_length: "Total Closed Drain Length",
    total_drain_length: "Total Drain Length in ULB",
    avg_drain_length_cleaned: "Avg drain length cleaned per day/per swm staff",
    avg_person_drain_length_cleaned: "Avg persons deployed for Drain Cleaning per day",
    avg_drain_cleaned: "Avg Drain Length Cleaned / Day",
    gap_in_drain_cleaning: "Gap In Drain Cleaning",
    gap: "Gap",

    //Involvement of HKS
    no_of_active_hks_member: "No of active HKS members",
    total_number_of_hks_needed: "Total Number Of HKS Needed",

    //BWD community level
    avg_bwd_processed_in_windrow_plant: "Avg Bio Waste Processed in Windrow Plant / Day",
    avg_bwd_processed_in_biogas_plant: "Avg Bio Waste Processed in Biogas Plant / Day",
    avg_bwd_processed_in_owc_plant: "Avg BDW Processed in OWC Plant",
    avg_bwd_processed_in_wte_day: "Avg Bio Waste Processed in WTE/Day",
    avg_bio_waste_processed_in_bio_cng_plant: "Avg Bio Waste Processed in BioCNG Plant/Day",
    avg_bio_waste_processed_in_thumboormuzhi: "Avg Bio Waste Processed in Thumboormuzhi/Day",

    avg_bio_waste_processed_in_other_bio_facilities: "Avg Bio Waste Processed in Other Bio Facilities/Day",
    total_bwd_processed_per_day: "Total BDW Processed Per Day",
    avg_bwd_recieved_in_windrow_plant: "Avg Bio Waste Recieved in Windrow Plant / Day",
    avg_bwd_recieved_in_biogas_plant: "Avg Bio Waste Recieved in Biogas Plant / Day",
    avg_bwd_recieved_in_owc_plant: "Avg BDW Recieved in OWC Plant",
    avg_bwd_recieved_in_wte_day: "Avg Bio Waste Recieved in WTE/Day",
    avg_bio_waste_recieved_in_bio_cng_plant: "Avg Bio Waste Recieved in BioCNG Plant/Day",
    avg_bio_waste_recieved_in_other_bio_facilities: "Avg Bio Waste Recieved in Other Bio Facilities/Day",
    total_gap_recieved_in_community_level: "Total Gap in Bio Degradable Waste Recieved to collected",
    total_bdw_recieved_in_community_level: "Total BDW Recieved / Day",
    waste_recieved: "Waste Recieved",

    average_nbdw_processed_in_mcf: "Average NBDW Processed in MCF",
    average_nbdw_processed_in_rrf: "Average NBDW Processed in RRF",
    nbdw_processed_per_day: "NBDW Processed Per Day",
    total_nbdw_processed_per_day: "Total NBDW Processed Per Day",
    average_nbdw_processed_in_other: "Average NBDW Processed In Other Facilities",
    total_nbdw_recieved_to_collected: " Gap In NBDW Recieved To Collected / Day",
    till_now: "Till Now",

    sieving_machine: "Sieving Machine",
    chopper: "Chopper",
    conveyor_belt: "No. of Conveyor Belts",
    dryer: "Dryer",

    //Waste Generators
    household: "Household",
    institutions: "Institutions",
    total_buildings: "Total Buildings",
    total_occupants: "Total Occupants",
    total_units: "Total Units",
    building: "Building Area(sqkm)",

    //Payment to swm staff and expenses
    total_wages_hks_member: "Total Wages To Hks Member (Rs)",
    total_wages_sanitation_work: "Total Wages T0 Clr/Sanitation Workers (Rs)",
    total_wages_contract_staff: "Total Wages To Contract Staff(HI,JHI) (Rs)",
    total_wages_harithasahayasthapanam: "Total Wages To Harithasahayasthapanam (Rs)",

    total_expense_iec_awareness: "Total Expenses For Iec, Awareness, etc (Rs)",
    total_expense_ict_technology_qrcode: "Total Expense For ICT , TECHNOLOGY , QRCODE (Rs)",

    total_amount_consultants: "Total Amount Paid To Consultants (Rs)",
    total_amount_spend_for_training_refreshment: "Total Amount Spend For Training/ Refreshment (Rs)",
    total_amount_spend_for_fuel: "Total Amount Spend For Fuel (Rs)",
    total_amount_spend_for_vehicle_maintenance: "Total Amount Spend For Vehicle Maintenance (Rs)",

    total_amount_medical_health_aspects_insurance: "Total Amount For Medical/Health Aspects or Insurance (Rs)",
    total_amount_ppe_uniforms: "Total Amount for PPE / Uniforms for SWM workers (Rs)",
    total_expense_electricity_for_all_swm_facilities: "Total Expenses For Electricity For All SWM Facilities (Rs)",

    total_expenses_handling_waste_rejects: "Total Expenses Handling Waste Rejects (Rs)",
    total_bdw_waste: "Total Income from all BDW Waste in Rs",
    total_nbdw_waste: "Total Income from all NBDW Waste Recycled in Rs",
    total_income_from_user: "Total Income from User Fee in Rs",
    total_income_from_scrap: "Total Income from scrap Sale in Rs",
    total_income_from_all_waste: "Total Income from all Waste in Rs",
    total_bdw_managed: "Total BDW Managed in Tonnes",
    total_nbdw_managed: "Total NBDW Managed in Tonnes",
    total_waste_managed: "Total Waste Managed in Tonnes",

    //population
    residential_population_1991: "Residential Population (1991)",
    residential_population_2001: "Residential Population (2001)",
    residential_population_2011: "Residential Population (2011)",
    residential_population_2023: "Residential Population (2023)",
    residential_population_2028: "Projected Residential Population (2028)",
    floating_population: "Floating Population",
    other_population: "Other Population (Migrant Workers)",
    non_bio_degradable_waste: "Non Bio Degradable Waste",
    avg_nbdw_received_in_mcf_per_day: "Avg NBDW Received in MCF per Day",
    avg_nbdw_received_in_rrf_per_day: "Avg NBDW Received in RRF per Day",
    avg_total_nbdw_received_per_day: "Avg Total NBDW Received per Day",
    bio_degradable_waste: "Bio Degradable Waste",
    no_of_households_covered_by_ulb: "No.of households covered by ULB",
    per_day_capacity: "Per Day Capacity (TPD)",
    save_draft: "Save as Draft",
    view_milestones: "View Milestones",
    complaints_dashboard: "Complaints Dashboard",
    ward_wise_population: "Ward Wise Population",
    population_density: "Population Density",
    floating_population_tooltip: "(Offices, Schools, Shops, Bustand, Auditorium, Malls, Tourist Spots, Place of Worship, Public Gatherings etc)",

    //swm data report
    swm_data_report: "SWM Data Report",
    population_vs_land_area: "Population Vs Land Area",
    gap_analysis_reports: "Current Status Reports",
    land_area: "Land Area",
    population_vs_house_hold: "Population Vs HouseHold",
    land_use: "Land Use",
    land_area_currently_used_for_swm_facility: "Currently Used For Swm Facilities(Acres)",
    land_area_available_for_new_construction: "Available For New Construction(Acres)",
    govt_land_area_available: "Govt Land Area Available(Acres)",
    pvt_land_area_identified_for_future_swm_facilities: "Pvt Land Area Identified For SWM (Acres)",
    land_area_free_in_the_used_swm: "Land Area Free In Used SWM(Acres)",
    total_land_area: "Total Land Area(Acres)",
    nbdw_equipments_report: "NBDW Equipments Reports",
    bdw_equipments_report: "BDW Equipments Reports",
    nbdw_generated_vs_capacity: "NBDW Generated Vs Capacity",
    bdw_generated_vs_capacity: "BDW Generated Vs Capacity",
    nbdw_facilities: "NBDW Facilities",
    bdw_facilities: "BDW Facilities",
    capital_cost_in_nbdw: "Capital Cost In Nbdw",
    capital_cost_in_bdw: "Capital Cost In Bdw",
    forward_linking_report: "Forward Linking Report",
    nbdw_forwarded_by_ckcl_or_agency: "Nbdw Forwarded By Ckcl / Agency",

    weighing_machines_report: "Weighing Machine",
    sorting_table_report: "Sorting Table",
    bailing_machine_report: "Bailing Machine",
    shredding_machine_report: "Shredding Machine",
    deduster_report: "Deduster",
    conveyor_belt_report: "Conveyor Belt",
    segregation_boxes_report: "Segregation Boxes",
    fire_extenguishers_report: "Fire Extinguisher",
    washing_machines_report: "Washing Machines",
    pelletiser_report: "Pelletiser",
    others: "Others",

    sieving_machine_report: "Sieving Machine",
    chopper_report: "Chopper",
    dryer_report: "Dryer",
    jcb_and_other_vehicles_report: "Jcb And Other Vehicles",
    crusher_report: "Crusher",
    mixer_report: "Mixer",
    facility_cost: "Facility Cost",
    equipment_cost: "Equipment Cost",

    income_from_bdw_and_nbdw: "Income From Bdw And Nbdw",
    income_from_bdw: "Income From Bdw",
    income_from_nbdw: "Income From Nbdw",
    total_income: "Total Income",
    expenditure: "Expenditure",
    expenditure_report: "Expenditure Report",

    income_of_hks_from_bdw_and_nbdw: "Income Of Hks From Bdw And Nbdw",
    base_line_data: "Baseline Data",
    baseline_data_completed: "Baseline Data completed",
    added_successfully: "Added successfully",
    updated_successfully: "Updated successfully",

    scheme_cycle_activity: "Scheme Cyclic Activities",
    scheme_cycle_activity_details: "Scheme Cyclic Activities Details",
    activity: "Activity",
    safeguards_compliance: "Safeguards Compliance",
    vaccant_land: "Vaccant Land(Sqkm)",
    quantity_of_nbdw_remaining_to_lifted: "Quantity of NBDW Remaining to be Lifted in the current in Financial year as on the date in MCF",

    //dpr tracker
    dpr_tracker: "DPR/FR List",
    dpr_tracker_list: "DPR/FR List",
    type_of_document: "Type Of Document",
    title_of_dpr: "Title",
    type_of_work: "Type Of Work",
    esmf: "E S M F",
    category_of_dpr: "Category",
    create_dpr_tracker: "Create DPR/FR List",
    update_dpr_tracker: "Update DPR/FR List",
    sub_project_mapping: "Subproject Mapping",
    project_listing_table: "Project Listing Table",

    //Environmental Safeguard
    project_data: "Project Data",
    compendium: "Compendium",
    type_of_activity: "Type of Activity",
    phase_one_screening: "Phase 1 Screening: Is Exclusion Applicable",
    phase_two_esg_screening: "Phase 2 ESG Screening Done",
    date_of_esg_screening: "Date of ESG Screening",
    date_of_updated_esg_screening: "Date of Updated ESG Screening",
    esg_screening_responsibility: "ESG Screening Responsibility",
    esg_instrument_selected: "ESG Instrument Selected",
    national_state_due_diligence_required: "National / State Level Due Diligence Required?",
    national_state_due_diligence_required_type: "National/State Level Due Diligence EIA-A,B,CRZ type",
    reason_on_if_cat_b_become_a: "Reason if Cat B became A under EIA Notification",
    type_of_national_state_due_diligence_required: "Type of National / State Level Due Diligence Required",
    esg_document_preparation_responsibility: "ESG Document Preparation Responsibility",
    esg_document_preparation_agency_name: "ESG Doc Preparation Agency Name",
    esg_document_preparation_external_consultant_cnt_no: "ESG Doc Preparation External Consultants Contact No:",
    esg_document_preparation_external_consultant_email_id: "ESG Doc Preparation External Consultant Email id",
    date_of_esg_instrument_preparation_initiation: "Date of ESG Instrument Preparation Initiation",
    draft_final_esg_prepared_submitted: "Draft Final ESG Prepared & Submitted?",
    date_of_first_consultation: "Date of First Consultations",
    date_of_second_consultation: "Date of Second Consultations",
    date_of_draft_esg_document: "Date of Disclosure of Draft ESG Document",
    disclosed_draft_final_esg_document: "Disclosed Draft Final ESG Document",
    final_esg_instrument_preparation: "Final EMF ESG Approval Responsibility",
    final_esg_approval_date: "Final ESG Approval Date",
    date_of_disclosure_of_final_esg_document: "Date of Disclosure of Final ESG Document",
    disclosed_final_esg_document: "Disclosed Final ESG Document",
    date_of_incorporation: "Date of Incorporation of ESMP & ESHS in Bid Doc",
    date_of_review_of_esmp_eshs: "Date of review of ESMP & ESHS in Bid Doc",
    date_of_floating_rip_bidding: "Date of floating RfP/Bidding",
    date_of_implementation_initiation: "Date of Implementation initiation",
    implementation_ends_in: "Implementation ends in",
    responsibility_of_esg_supervision: "Responsibility for ESG Supervision",
    date_first_esg_quarterly_report: "Date of First ESG Quarterly Report Submitted",
    date_first_esg_quarterly_report_document: "First ESG Quarterly Report Document",
    date_first_esg_quarterly_report_submitted: "Date of Latest Quarterly Report Submitted",
    latest_esg_quarterly_report_document: "Latest ESG Quarterly Report Document",
    no_of_quarterly_report_document_submitted_till_date: "No: of Qrtly Reports Submitted till date",

    //compendium

    unique_project_id: "Unique Project ID",
    types_of_waste_managed: "Types of Waste Managed",
    waste_managed_in_the_sub_project: "Waste Managed in the subproject (in TPD)",
    types_of_management_of_waste: "Type of management of Waste",
    arrangement_for_recycling: "Arrangement for Recycling, Disposal of rejects and inerts from the facility funded",
    environmental_due_diligence: "Environmental Due Diligence",
    edd_issue_identified: "EDD Issues identified",
    category_of_edd_issues: "Category of EDD issue",
    source_of_edd_issue_identification: "Source of EDD issue Identification",
    date_of_identification: "Date of Identification",
    status_of_edd_issue_resolution: "Status of EDD issue resolution",
    associated_facilities: "Associated Facilities",
    disclosed_final_dsg_document: "Disclosed Final ESG Document",
    date_of_review_of_bid_doc: "Date of review of ESMP & ESHS in Bid Doc",
    implementation_end_in: "Implementation ends in",
    responsibility_for_esg_supervision: "Responsibility for ESG Supervision",
    date_of_first_esg_quarterly_report_submitted: "Date of First ESG Quarterly Report Submitted",
    first_esg_quarterly_report_document: "First ESG Quarterly Report Document",
    date_of_latest_esg_quarterly_report_submitted: "Date of Latest Quarterly Report Submitted",
    no_of_doc_submitted_till_date: "No: of Qrtly Reports Submitted till date",
    progress_of_works: "Progress of Works (Physical)",
    esg_issue_affecting_the_progress: "ESG Issue affecting the progress?",
    permits_consents_required: "Permits, Consents Required",
    responsible_agency_dept: "Responsible Agency/Dept to issue Consents/Permits",
    date_of_application_submission: "Date of Application Submission for Permit/Consent",
    status_of_permit_consent: "Status of Permit/Consent",
    reasons_for_delay_in_consent: "Reasons for Delay in Consent/Permit if any",
    any_remarks_on_permits_consents: "Any Remarks on Permits/Consents",
    incident_id: "Incident ID",
    incident_date_and_time: "Incident Date",
    incident_type: "Incident Type",
    injuries: "Injuries",
    deaths: "Deaths",
    incident_classification: "Incident Classification",
    incident_investigation_date: "Incident Investigation Date",
    status_of_compensation_insurance_disbursed: "Status of Compensation/Insurance?",
    incident_scap_formulation_date: "Incident SCAP formulation Date",
    incident_scap_implementation_status: "Incident SCAP Implementation Status",
    name_type_of_training_received: "Name/Type of Training received on ESG",
    attendee_profile: "Attendee profile",
    incident_time: "Incident Time",

    //training_master_sheet

    training_master_sheet: "Training Master Sheet",
    training_id: "Training ID",
    state_locality_ulb_names: "State/Locality, ULBs names, Cluster name/id",
    training_type: "Training Type",
    trainer_profile: "Trainer Profile",
    whether_green_protocol_followed: "Whether Green Protocol Followed?",
    safe_disposal_of_wastes_arranged: "Safe Disposal of Wastes arranged? Write Details.",
    training_report_prepared_by: "Training Report Prepared by, and Ref?",
    remarks_suggestions: "Remarks/Suggestions",
    permits_and_consent: "Permits and Consents",
    incidents: "Incidents",
    Trainings: "Trainings",
    in_percentage: "In percentage",

    other_expenses: "Other Expenses",
    gst_esmf_and_other_expenses_enter_manually: "GST , ESMF , Other Expenses",
    environment_and_social_safeguard_dpr: "Environment and Social safeguard",
    if_added_all_items_row: "If added all items Add GST Amount(This value will be a single entry for all items)",
    total_project_cost_in_rs: "Total Project Cost(in Rs)",
    save_esmf_gst_oe: "Save GST,ESMF,OE",
    dpr_file: "DPR",
    fr_file: "FR",
    estimate_file: "Estimate File",
    drawings: "Drawings",
    kml_attachment: "KML Attachment",
    mapped: "Mapped",
    cost_details: "Cost Details",
    file_upload: "File Upload",
    project_cost_update: "Project Cost Update",
    authority: "Authority",
    page_no: "Page No",
    chapter: "Chapter",
    view_comments: "View comments",
    add_comment: "Add Comment",
    update_comment: "Update Comment",
    company_name: "Company name",
    company_website: "Company website",
    please_fill_the_form: "Visitors Form",

    decisions: "Decisions",
    submit_to_district_level: "Submit to District level",
    district_level: "District Level",
    dpmc_decision: "DPMC Decision",
    dpmu_decision: "DPMU Decision",
    spmu_decision: "SPMU Decision",
    spmc_decision: "SPMC Decision",
    dy_dc_response: "DyDc Response",
    tsc_response: "TSC Response",
    dydc_and_tsc_response: "DyDc And Tsc Response",
    add_response: "Add Response",
    forward_to_spmc: "Forwarded to StateLevel",
    return_to_tsc: "Return to Tsc",
    submit_to_piu_for_mc: "Approve & Send to PIU",
    previous: "Previous Versions",
    state_level: "State Level",
    piu: "PIU",
    to_tsc: "Return to TSC",
    send_mc: "Send MC Approved DPR to DPMU",
    no_previous_versions: "No Previous Versions Available",
    response_details: "Response",


    recommented_by_social_experts: "Recommented By Social Experts",
    recommented_by_technical_experts: "Recommented By Technical Experts",
    recommented_by_financial_experts: "Recommented By Financial Experts",
    recommented_by_environmental_experts: "Recommented By Environmental Experts",
    country: "Country",
    requirements_or_feed_back: "Requirement/ Feedback",
    product_and_solution_interest: "Product and Solution Interest",
    date_of_approval: "Date of Approval",
    trainingCordinator: "Training Cordinator",
    session_name: "Session Name",
    training_date: "Training Date",
    feed_back_recieved_count: "Feedback Recieved Count",

    spmu_social_expert: "SPMU Social Expert",
    spmu_env_expert: "SPMU Environmental Expert",
    spmu_fin_expert: "SPMU Financial Expert",
    spmu_tech_expert: "SPMU Technical Expert",
    training_need_area_name: "Training Need Area Name",
    training_plan_name: "Training Plan Name",
    no_of_batches: "No. of Batches",
    dpr_or_fr_page_no: "DPR/FR Page No",
    dpr_or_fr_chapter: "DPR/FR Chapter",
    generate_dpr_fr_id: "Generate DPR/FR ID",
    spmc_remarks: "SPMC Remarks",
    dpmc_remarks: "DPMU Remarks",
    comments_and_recommendations_added: "Commends and Recommendations Added",
    remarks_are_required: "Remarks are Required",
    invalid_value_for_percentage: "Invalid value. It should be in percentage",
    annual_development_plan: "Annual Development Plan(ADP)",
    current_status: "Current Status",
    action_by: "Action By",
    ownership: "Ownership",
    generate_fs: "Generate Fs",
    reference: "Reference",
    generate: "Generate",
    order_number: "Order Number",
    add_references: "Add References",
    download_fs: "Download Fs",
    facility_status: "Facility Status",
    bio_facility_name: "Bio Facility Name",
    plan_name_is_required: "Plan name is required",

    // labour management

    labour_management: "Labour Management",
    project_sites: "Project Sites",
    site_name: "Site Name",
    house_no: "House No.",
    street_plot_no: "Street/Plot No.",
    town: "Town",
    pin_code: "PIN Code",
    commencement_date: "Commencement Date",
    wage_period: "Wage Period",
    day_of_weekly_holiday: "Day Of Weekly Holiday",
    consider_weekly_off: "Consider Weekly Off For Wage Calculation",
    hours_of_rest: "Hours Of Rest (Adolescent) - Refer To The Child Labour Handbook",
    no_of_daily_hours_adolescent: "No. Of Daily Hours (Adolescent) - Refer To The Child Labour Handbook And KIT CLA- 1",
    no_of_daily_hours_adult: "No. Of Daily Hours (Adults)",
    adolescent_rest_tooltip: "It is suggested as per law, an adolescent(14-18 years old) should have rest of atleast 1 hour for every 3 hours of work",
    adolescent_daily_hours_tooltip: "Ordinary number of working hours (including breaks) for each labourer of age 14-18 years. As per law, an adolescent can only work for upto 6 hours a day",
    commencement_date_tooltip: "Date on which work started in your project",
    wage_period_tooltip: "Choose after how many days of work is wage paid to the labourer",
    day_of_weekly_holiday_tooltip: "Weekly rest day designated by the employer on project site (if not decided, mention Sunday)",
    no_of_daily_hours_adult_tooltip: "Ordinary number of working hours (including breaks) for each labourer daily",
    holiday: "Holiday",
    street_no: "Street No.",
    pincode: "PIN Code",
    project_manager_details: "Project Manager Details",
    minimum_wages: "Minimum Wages",
    registration_license: "Registration/License",
    mobile_no: "Mobile No.",
    manager_name: "Manager Name",
    telephone: "Telephone",
    add_minimum_wages: "Add Minimum Wages",
    date_of_vikasana_seminar_required: "Date of vikasana seminar",
    invalid_email_id: "Invalid Email Id",
    invalid_phone: "Invalid Contact Number",
    fetch_sankhya: "Fetch Sankhya",
    add_employee: "Add Labours",
    employee_code: "Employee Code",
    employee_name: "Employee Name",
    date_of_joining: "Date of Joining",
    type_of_employment: "Type Of Employment",
    verification_status: "Verification Status",
    employees: "Labourers",
    general_details: "General Details",
    sur_name: "Surname",
    father_or_spouse_name: "Father's or Spouse Name",
    marital_status: "Marital Status",
    date_of_birth: "Date of Birth",
    present_address: "Present Address",
    permanent_address: "Permanent Address",
    nationality: "Nationality",
    same_as_present_address: "Same as Present Address",
    education_level: "Education Level",
    workman_under: "Workman Under Interstate Migrant Workman Act",
    sub_contractor: "Sub Contractor",
    set_of_work: "Set of Work",
    uan: "UAN",
    pan: "PAN",
    esic: "ESIC",
    not_applicable: "Not Applicable",
    basic_rate: "Basic Rate (Per Day)",
    da_rate: "DA Rate (Per Date)",
    overtime_rate: "Overtime Rate (Per Hour)",
    lwf: "LWF",
    aadhaar_number: "Aadhaar Number",
    mark_of_identification: "Mark of Identification",
    e_shram_id: "E-Shram ID",
    ration_card_number: "Ration Card Number",
    photo: "Photo",
    bank_details: "Bank Details",
    employee_bank_account: "Employee Bank Account",
    bank_account_number: "Bank Account Number",
    confirm_bank_account_number: "Confirm Bank Account Number",
    bank_name: "Bank Name",
    branch_ifsc: "Branch Ifsc",
    date_of_join_tooltip: "Date of joining the contractor for this subproject",
    type_of_employment_tooltip: "Choose type of labourer. Permanent - if employed by your sub-contractor (mandatory to put name of your subcontractor below) job or time period.",
    workman_under_tooltip: "Mark 'yes' if labourer is from a different state or out of state",
    sub_contractor_tooltip: "If subcontractor  is not on the list, please add subcontractor",
    uan_tooltip: "The universal account number Issued by the employee provident fund organization to the employee",
    esic_tooltip: "Insured person no. Issured by ESIC",
    overtime_rate_tooltip: "As per, overtime rate should be twice the rate of daily wage on hourly basis",
    lwf_tooltip: "Labour Welfare Fund",
    aadhaar_number_tooltip: "If Aadhaar card is applicable",
    does_employee_have_bank_account: "Does employee have bank account",
    employee_details: "Employee Details",
    minimum_basic_highly_skilled: "Minimum Basic For Highly Skilled",
    minimum_basic_skilled: "Minimum Basic For Skilled",
    minimum_basic_semi_skilled: "Minimum Basic For Semi Skilled",
    minimum_basic_un_skilled: "Minimum Basic For Unskilled",
    daily_allowance_highly_skilled: "Daily Allowance For Highly Skilled",
    daily_allowance_skilled: "Daily Allowance For Skilled",
    daily_allowance_semi_skilled: "Daily Allowance For Semi Skilled",
    daily_allowance_un_skilled: "Daily Allowance For Unskilled",
    over_time_highly_skilled: "Over Time For Highly Skilled",
    over_time_skilled: "Over Time For Skilled",
    over_time_semi_skilled: "Over Time For Semi Skilled",
    over_time_un_skilled: "Over Time For Unskilled",
    registration_number: "Registration Number",
    license_number: "License Number",
    license_name: "License Name",
    commenced_on: "Commenced On",
    expiring_on: "Expiring On",
    edit_registration_number: "Edit Registration/License Number",
    establishment_details: "Establishment Details",
    requirements: "Requirements",
    contractor_information: "Contractor Information",
    basic_facilities: "Basic Facilities",
    grievance_redressal_committee: "Grievance Redressal Committee",
    notice_board: "Notice Board",
    internal_complaint_committee: "Internal Complaint Committee",
    canteen: "Canteen",
    rest_rooms: "Rest Rooms",
    drinking_water: "Drinking Water",
    creches: "Creches (Refer to the kit CLRA-3)",
    first_aid: "First Aid",
    compulsory_when_tooltip: "Compulsory when 20 or more female employees are employed",
    as_per: "As per BOCW, have the following basic facilities been provided? (Refer to the Labour Welfare Handbook)",
    add_member: "Add New Member",
    member: "Member",
    edit_member: "Edit Member",
    first_name_required: "First Name Is Required",
    last_name_required: "Last Name Is Required",
    invalid_bank_account_number: "Invalid bank account number",
    invalid_branch_ifsc: "Invalid Ifsc code",
    invalid_pan: "Invalid Pan Number",
    invalid_aadhaar_number: "Invalid Aadhaar Number",
    notice_board_question: "Have the following notices been posted on the Notice Board?",
    notice_board_note: "Download the KIT EST-1 to print the Abstracts mentioned below",
    minimum_wages_act: "Abstract: The Minimum Wages Act",
    payment_of_wages: "Abstract: The Payment of Wages Act",
    employee_compensation: "Abstract: The Employee Compensation Act",
    building_and_other: "Abstract: The Building and Other Construction Workers (RECS) Act",
    contract_labour: "Abstract: The Contract Labour (A&R) Act",
    child_and_adolescent: "Abstract: The Child and Adolescent Labour (P&R) Act",
    maternity_benefit: "Abstract: The Maternity Benefit Act",
    upload_photo_showing_notice: "Upload photo showing all notices posted on the notice board",
    project_list_labour: "Project List - Labour Management",
    member_details: "Member Details",
    minimum_members_to_be_four: "Minimum members to be four. The Presiding Member should be a senior female employee. Refer to the KIT PSH-1",
    designation_required: "Designation Is Required",
    contact_required: "Contact Is Required",
    license_number_required: "License Number is Required",
    commenced_on_required: "Commenced On is Required",
    expiring_on_required: "Expiring On is Required",
    edit_labour: "Edit Labour Details",
    previous_wages: "Previous Wages",
    previous_wage: "Previous Wage",
    wage_details: "Wage Details",
    wage_start_date: "Wage Start Date",
    wage_amount: "Wage Amount",
    wage_details_successfully: "Wage Details Successfully",
    update_wage_details: "Update Wage Details",
    add_wage_details: "Add Wage Details",
    invalid_wage_amount: "Invalid Data Entry For Wage Amount",
    invalid_overtime_rate: "Invalid Data Entry For Overtime Rate",
    termination_process: "Termination Process",
    exit_date: "Exit Date",
    reason: "Reason for Exit",
    employee_name_code: "Employee Name With Code",
    termination_list: "Termination List",
    employee_terminated_successfully: "Employee Terminated Successfully",
    wage_register_list: "Wage Register List (Form B)",
    total_net_payment: "Total Net Payment",
    entries_completed: "Entries Completed",
    add_new_record: "Add New Record",
    wage_register_details: "Wage Register Details",
    total_basic: "Total Basic",
    total_deductions: "Total Deductions",
    net_payment: "Net Payment",
    total_basic_pay: "TOTAL BASIC PAY",
    total_deduction: "TOTAL DEDUCTION",
    net_payment_card: "NET PAYMENT",
    wage_end_date: "Wage End Date",
    rate_of_wage: "Rate of Wage",
    rate_of_overtime: "Rate of Overtime",
    no_of_days_worked: "No of Days Worked",
    overtime_hours_worked: "Overtime Hours Worked",
    basic: "Basic",
    special_basic: "Special Basic",
    da: "DA",
    payments_overtime: "Payments Overtime",
    hra: "HRA",
    pf: "PF",
    society: "Society",
    income_tax: "Income Tax",
    insurance: "Insurance",
    recoveries: "Recoveries",
    total_employee: "Total Employee",
    total_wage_paid: "Total Wage Paid",
    the_wage_register_completed: "The wage register has been successfully completed",
    wage_details_added_successfully: "Wage Details Updated Successfully",
    new_record_added_successfully: "New Record Added Successfully",
    basic_payment: "Basic Payment",
    registration_date: "Registration Date",
    wage_details_deleted_successfully: "Wage Details Deleted Successfully",
    attendance_register: "Attendance Register",
    overtime: "Overtime",
    present: "Present",
    rest: "Rest",
    absent: "Absent",
    work_off: "Weekly Off",
    choose_date: "Choose Date",
    overtime_hours: "Overtime (Hours)",
    invalid_entry_for_overtime_hour: "Invalid entry for overtime hours",
    tsc: "TSC",
    events_tracked: "Events Tracked",
    recorded_data: "Recorded Data",
    event_status: "Event Status",
    verification: "Verification",

    //receipt

    receipt_generator: "Receipt Generator",
    receipt: "Receipts",
    receipt_details: "Add Receipt Details",
    go_number: "Government Order Number",
    edit_receipt_details: "Edit Receipt Details",
    view_receipt_details: "View Receipt Details",
    deleted_successfully: "Deleted Successfully",

    //sulekha information
    select_sulekha_project: "Select Sulekha Project",
    project_name_in_malayalam: "Project Name in Malayalam",
    project_aim: "Project Aim",
    old_ref_no: "Old Ref No if Project Started in Previous years",
    main_sector: "Main Sector",
    micro_sector: "Micro Sector",
    project_number: "Project Number",
    project_type: "Project Type",
    sector: "Sector",
    sub_sector: "Sub Sector",
    implementing_officer_code_and_name: "Implementing Officer code ,Name",
    implementation_mode: "Implementation Mode",
    micro_sector_name: "Micro Sector Name",
    resource_source_code: "Resource Source Code",
    resource_source_name: "Resource Source Name",
    resource_details: "Resource Details",
    fetch_sulekha: "Fetch Sulekha",
    unit_count: "Unit Count",
    benefits: "Benifits",
    micro_sec_id: "Micro Sec ID",

    // operation module

    secondary_collection_agency: "Secondary Collection Agency",
    requested_amount: "Requested Amount",
    treasury_name: "Treasury Name",
    beneficiary_name: "Beneficiary Name",
    beneficiary_amount: "Beneficiary Amount",
    expenses: "Expenses",

    //operationModule

    segregation_facility: "Segregation Facility",
    waste_processing: "Waste Processing",
    waste_processing_sites_list: "Waste Processing Sites List",
    waste_processing_site_details: "Waste Processing Site Details",
    name_of_waste_pro_site: "Name of Waste Processing Site",
    capacity_in_tonnes: "Capacity (Treatment in Tons/Day)",
    facility: "Facility",
    area_sq_km: "Area(Sq Km)",
    site_area: "Site Area (Sq Mtr)",
    build_up_area: "Build Up Area (Sq Mtr)",
    type_of_access_mcf: "Type of access to MCF/RRF",
    width_of_road: "Width of Road (In Meter)",
    waste_storage_area: "Waste storage area",
    bi_product_storage: "Bi-product storage",
    cow_dung_other_storage: "Innoculum/Cow-dung/other storage",
    leachate_management_system: "Leachate management system",
    odour_management_system: "Odour management system",
    fly_management_system: "Fly management system",
    methods_of_treatment: "Methods of treatment",
    capacity_each_method: "Capacity of each method",
    input_material_details: "Input material details",
    out_put_product_details: "Out put product details",
    forward_linking_marketing: "Forward linking / Marketing",
    water_availability: "Water availability",
    electricity_availability: "Electricity availability",
    sanitation_facility: "Sanitation Facility",
    house_keeping: "Housekeeping",
    officers_in_charge: "Officers in charge Name",
    issues: "Issues",
    additions_proposed: "Additions proposed",
    data_collection_date: "Data Collection Date",
    capacity_in_tonne: "Capacity (Tonnes)",

    mcf_rrf: "MCF - RRF",
    mcf_and_rrf: "MCF & RRF Details",
    capacity: "Capacity (Tonnes)",
    cast_wise: "Cast Wise",
    capacity_collection_in_tons: "Capacity (Collection in Tons/Day)",
    service_area: "Service Area(Sq Km)",
    building_area: "Building Area (Sq Mtr)",
    no_of_floors: "No. of Floors (Sq Mtr)",
    type_of_building: "Type of Building",
    type_of_access: "Type of access to MCF/RRF",
    access_to_all_sides: "Access to all sides by fire fighting equipment",
    storage_area: "Storage Area",
    forward_linking: "Forward linking / Marketing",
    details_of_materials_stored: "Details of Materials Stored",
    sanitation_facilities: "Sanitation Facilities",
    frequency_of_housekeeping: "Frequency of Housekeeping",
    issues_if_any: "Issues if any ",
    list_of_machineries: "List of Machineries and other accessories in the facility",
    whether_fire_fighting: "Whether fire fighting system is provided in the facility",
    data_collection: "Data Collection Date",
    contact_no: "Contact Number",
    name_of_mcf_rrf: "Name of MCF/RRF",
    city_level_disposal_site_list: "City Level Disposal Site List",
    city_or_ward: "City or Ward",
    name_of_waste_processing_site: "Name of Waste Processing Site",
    capacity_disposal_in_tons: "Capacity (Disposal in Tons/Day)",
    area_already_covered: "Area Already Covered (Sq Mtr)",
    qty_of_waste_already_deposited: "Qty of Waste Already Deposited",
    distance_from_city: "Distance from City (Km)",
    distance_to_closest_sensitive_receptor: "Distance to Closest Sensitive Receptor (mts)",
    nature_of_the_surrounding_area: "Nature of the Surrounding Area",
    Statutory_approval_of_disposal_site_if_any: "Statutiry approval of disposal site if any",
    city_level_disposal_site: "City Level Disposal Site",
    regional_disposal_site: "Regional Disposal Site",
    regional_disposal_sites_list: "Regional Disposal Sites List",
    regional_disposal_site_details: "Regional Disposal Site Detail",
    name_of_disposal_site: "Name Of Disposal Site",
    treatment_at_source_for_bdw: "Treatment At Source For BDW",
    treatment_at_source_for_bdw_information: "Treatment At Source For BDW Information",
    house_with_treatment_at_source: "Houses with Treatment at Source",
    captive_consumption_of_compost: "Captive Consumption of compost / biogas  kg/day",
    sale_or_use_of_compost: "Sale or Use of Compost/Biogas",
    no_of_existing_biogas_plants: "No. of Existing Biogas Plants Issued to HH",
    capacity_of_biogas_plants: "Capacity of biogas plants",
    total_no_of_biogas_plants: "Total No of Biogas Plants (with capacity) Owned by HHs",
    method_of_utilization_of_compost: "Method of utilisation of Compost",
    if_the_compost_is_sold_income: "If the Compost is Sold, Income from Sale",
    method_of_utilization_of_biogas: "Method of utilisation of  Biogas",
    if_the_biogas_is_sold_income_from_sale: "If the Biogas is Sold , Income from Sale",
    primary_collection_bdw: "Primary collection BDW",
    primary_collection_bdw_list: "Primary collection BDW List",
    no_of_household_to_be_covered: "No. of Household to be Covered for Door-to-Door Collection",
    no_of_household_covered: "No. of Household Covered",
    qty_of_waste_collected_tons: "Qty of Waste Collected (Tons)",
    no_of_establishment_to_be_covered: "No. of Establishment to be Covered",
    number_of_establishments_covered: "Number of Establishments Covered",
    no_of_public_bins_to_be_covered: "No. of public bins to be covered",
    no_of_public_bins_covered: "No. of public bins covered",
    no_of_public_markets_to_be_covered: "No. of public Markets to be covered",
    no_of_public_markets_covered: "No. of public Markets covered",
    no_of_household: "No. of household covered",
    road_length: "Road length covered by sweeping (km)",
    house_hold_to_be_covered: "House Hold to be Covered",
    household_covered: "Household Covered",
    establishments_to_be_covered: "Establishments to be Covered",
    establishments_covered: "Establishments covered",
    public_bins_to_be_covered: "Public Bins to be Covered",
    public_bins_covered: "Public Bins covered",
    road_sweeping: "Road sweeping (Km)",
    waste_segregation_storage_info: "Waste Segregation & Storage Info",
    wss_info: "WSS Info",
    ward_no: "Ward No",
    bins_proc: "Bins Procured",
    bins_dist: "Bins Distributed",
    house_without_bins: "Household without Bins",
    name_of_ward: "Name Of Ward",
    bin_proc: "3 Bins set Procured for segregation",
    bin_dist: "3 Bins set Distributed to HH for segregation",
    houseHold: "Household without Bins",
    collection_frequency: "Collection Frequency",
    site: "Site",
    secondary_transportation: "Secondary Transportation",
    mode_of_loading: "Mode of Loading",
    mode_of_transportation: "Mode of Transportation",
    mode_of_unloading: "Mode of Unloading",
    type_of_vehicles: "Type of Vehicles",
    details_of_gps_facility: "Details of GPS facility",
    mcf_rrf_details_information: "MCF/RRF Details Information",
    mcf_rrf_details_information_add: "MCF/RRF Detail Information",
    waste_received: "Waste Received",
    waste_recycled: "Waste Recycled",
    waste_dispatched: "Waste Dispatched",
    nbdw_received: "NBDW received (In Kg)",
    plastic_waste_seg: "Plastic Waste Segregated (In Kg)",
    plastic_waste_shred: "Segregated Pastic waste shredded (In Kg)",
    plastic_waste_disposal: "Segregated plastic waste for disposal to recyclers",
    seg_inert_waste: "Segregated inert waste (Kg)",
    income_seg_plastic: "Income from Segregated plastic waste",
    waste_processing_plant_details: "Waste Processing Plant Details",
    biodegradable_waste_received: "Biodegradable Waste Received (In Ton)",
    biodegradable_waste_processed: "Biodegradable Waste Processed (In Ton)",
    end_product_qty: "End Product Qty",
    cost_recovered: "Cost recovered",
    waste_processing_site: "Waste Processing Site",
    city_level_disposal_cell_information: "City Level Disposal Cell Information",
    city_level_waste_disposal_site: "City Level Waste Disposal Site",
    waste_disposed: "Waste Disposed",
    waste_disposal_regional_site: "Waste Disposal At Regional Site",
    waste_received_regional_landfills: "Waste received at regional landfil",
    cumulative_waste_landfills: "Cumulative waste in land fill",
    transfer_station: "Transfer Station Information",
    mcf_rrf_site_name: "Mcf Rrf Site Name",
    load_in: "Load In",
    type_of_waste: "Type of Waste",
    weight_in_ton: "Weight in Ton",
    transportation_provider: "Transportation Provider",
    in_time: "In Time",
    vehicle_number: "Vehicle Number",
    load_out: "Load Out",
    to_land_fill: "To Land Fill",
    from_ulb: "From ULB",
    out_time: "Out Time",
    from_district: "From District",
    input_waste: "Input Waste",
    output_waste: "Output Waste",
    area_of_ward: "Area of Ward",
    population_ward: "Population of Ward",
    no_of_household_and_establishment: "No of Household and Establishment",
    no_of_hh_where_treating_machinery: "No of Household/Establishment where Treating Machinery Installed",
    capacity_of_machinery: "Capacity of Machinery Installed",
    houses_with_bins: "Household without Bins",
    area: "Area",
    invalid_waste_generated: "Invalid Data Entry For Waste Generated",
    invalid_waste_processed: "Invalid Data Entry For Waste Processed",
    invalid_latitude: "Invalid Latitude",
    invalid_longitude: "Invalid Longitude",
    invalid_area: "Invalid Data Entry For Area",
    invalid_collection_per_day: "Invalid Data Entry For Collection Per Day",
    invalid_capacity_collection_in_tons: "Invalid Data Entry For Capacity(Collection in Tons/Day)",
    invalid_service_area: "Invalid Data Entry For Service Area(Sq Km)",
    invalid_site_area: "Invalid Data Entry For Site Area (Sq Mtr)",
    invalid_building_area: "Invalid Data Entry For Building Area(Sq Mtr)",
    invalid_no_of_floors: "Invalid Data Entry For No of Floors(Sq Mtr)",
    invalid_storage_area: "Invalid Data Entry For Storage Area(In Days)",
    invalid_capacity_in_tonnes: "Invalid Data Entry For Capacity (Treatment in Tons/Day)",
    invalid_build_up_area: "Invalid Data Entry For Build Up Area (Sq Mtr) ",
    invalid_width_of_road: "Invalid Data Entry For Width Of Road (In Mtr)",
    invalid_capacity_disposal_in_tons: "Invalid Data Entry For Capacity (Disposal in Tons/Day)",
    invalid_area_already_covered: "Invalid Data Entry For Area Already Covered (Sq Mtr)",
    invalid_qty_of_waste_already_deposited: "Invalid Data Entry For Qty of Waste Already Deposited",
    invalid_distance_from_city: "Invalid Data Entry For Qty of Waste Already Deposited",
    invalid_distance_to_closest_sensitive_receptor: "Invalid Data Entry For Distance to Closest Sensitive Receptor (mts)",
    invalid_weight_in_ton: "Invalid Data Entry For Weight in Ton",
    invalid_qty_of_waste_collected_tons: "Invalid Data Entry For Qty of Waste Collected (Tons)",
    invalid_road_length: "Invalid Data Entry For Road length covered by sweeping (km) ",
    invalid_nbdw_received: "Invalid Data Entry For NBDW received (In Kg) ",
    invalid_plastic_waste_seg: "Invalid Data Entry For Plastic Waste Segregated (In Kg)",
    invalid_plastic_waste_shred: "Invalid Data Entry For Segregated Pastic waste shredded (In Kg)  ",
    invalid_plastic_waste_disposal: "Invalid Data Entry For Segregated plastic waste for disposal to recyclers (In Kg) ",
    invalid_seg_inert_waste: "Invalid Data Entry For Segregated inert waste (Kg)",
    invalid_income_seg_plastic: "Invalid Data Entry For Income from Segregated plastic waste",
    invalid_biodegradable_waste_received: "Invalid Data Entry For Biodegradable Waste Received (In Ton)",
    invalid_biodegradable_waste_processed: "Invalid Data Entry For Biodegradable Waste Processed (In Ton) ",
    invalid_end_product_qty: "Invalid Data Entry For End Product Qty",
    invalid_cost_recovered: "Invalid Data Entry For Cost recovered",
    invalid_waste_disposed: "Invalid Data Entry For Waste Disposed",
    invalid_waste_dispatched: "Invalid Data Entry For Waste Dispatched",
    invalid_waste_received_regional_landfills: "Invalid Data Entry For Waste received at regional landfil",
    invalid_cumulative_waste_landfills: "Invalid Data Entry For Cumulative waste in land fill",
    invalid_area_of_ward: "Invalid Data Entry For Area of Ward ",
    invalid_total_road_length: "Invalid Data Entry For Total Road Length in ULB",
    at_request: "AT Request",
    gap_analys: "Gap Analysis",
    date_of_join_must_be_less: "Date of join must be after Date of Birth",
    is_citizen: "Is Citizen",
    master_info: "Master Info",
    operational_data: "Operational Data",

    //procurement
    work_order_number: "Work Order Number",
    work_order_date: "Work Order Date",
    work_order_amount: "Work Order Amount",

    //GRANT MANAGEMENT MODULE REPORTS
    grand_utilization_request_report_1: "Grant Utilization Report (GUR 1)",
    grand_utilization_request_report_2: "Grant Utilization Report (GUR 2)",
    grand_utilization_request_report_3: "Grant Utilization Report (GUR 3)",

    month_and_year: "Month & Year",
    grants_recieved: "Grants Recieved(INR)",
    grants_spent: "Grants Spent",
    grants_unspent: "Grants Unspent",
    grants_withheld: "Grants Withheld",
    during_the_reporting_quater: "During the reporting quater",
    year_to_date: "Year to Date",
    cumilative_to_date: "Cumilative to date",
    routing_public_space_cleaning_or_sanitization: "Routine public space cleaning/sanitization and other waste management activities related to COVID19 like procurement of protective gears and equipment for sanitation",
    financial_support_to_existing_women: "Financial support to existing women self-help groups engaged in ongoing waste collection",
    payment_to_c_and_t_contractors: "Payment to C&T contractors",
    operation_and_maintenance_support_or_tipping_fee: "Operation and maintenance support or tipping fee for regional",
    purchase_of_primary_collection_and_transport: "Purchase of primary collection & transportation handcart/ tricycles and vehicles and secondary Transportation vehicles",
    purchase_of_equipments_for_house_hold_or_community_level: "Purchase of Equipment for Household/community level SWM",
    project_expenditure_on_setting_up_bio_degradable_waste: "Project expenditure on setting up Biodegradable Waste Treatment Plants",
    project_expenditure_in_participating_in_shared_regional_facilities: "Project expenditure on participating in shared regional",
    project_expenditure_on_dumpsite_remediation: "Project expenditure on dumpsite remediation/closure",
    project_expenditure_on_upgrading_or_setting_up_mcf_rrf: "Project expenditure on upgrading/setting up MCF, RRF,MRF",
    other_eligible_expenditure: "Other Eligible Expenditure",
    sanitary_waste_disposal_facility: "Sanitary waste disposal facility",
    ulb_access_to_incentive_grant_present_status: "ULB access to incentive grant - Present status",
    phase_1: "Phase 1",
    if_incentive_grant_received: "If incentive grant received amount",
    phase_2: "Phase 2",
    swm_bye_law: "SWM Byelaw",
    access_to_use_of_facility_for_safe_disposal_of_waste: "Access to / use of facility for safe disposal of waste",
    hire_top_level_staff_in_accordance_with_the_structure_approved_by_gok: "Hire top-level staff in accordance with the structure approved by GoK",
    sign_one_or_more_performance_based_contracts_for_solid_waste_collection_and_transportation_services: "Sign one or more performance-based contracts for solid waste collecton and transportation services",
    develop_a_plan_for_user_charges_and_o_and_m_budgeting: "Develop a plan for user charges and O & M budgetng",
    implement_monitoring_and_evaluation_including_grm: "Implement monitoring and evaluation including GRM",
    present_stage_access_to_incentive_grant: "Present stage – access to incentive grant(criteria pending level) *",
    incentive_grant_recieved: "Incentive grant received",
    reason_for_delay: "Reason for delay(List PIU, DPMU comments)",
    ulb_access_to_incentive_grant_delay_report: "ULB access to incentive grant - Delay Report",
    grant_allocation_report: "Grant Allocation Report",
    share_of_the_ulb_in_component_2_outlay_based_on_2011_census_population: "Share of the ULB in Component 2 outlay based on 2011 Census Population",
    in_fund_available_to_all_ulbs: "In Fund available to all ULBs",
    in_fund_earmarked_for_muncipalities_only: "In Fund earmarked for municipalities only",
    in_overall_outlay_for_component_2: "In overall outlay for component 2",
    rs_crores: "Rs Crores",
    project_details_report: "Project Details Report",
    project_type_bio_or_non_bio: "Project type (Bio / Non bio)",
    fs_amount: "FS Amount",
    percentage_of_variation_in_dpc_approved_and_fs_amount: "Percentage of variation in DPC approved and FS amount",
    total_project_count: "Total Project Count",
    project_amount: "Project Amount",
    dpc_approved_amount: "DPC Approved Amount",
    status_of_approval_of_revision: "Status of approval of revision",
    date_of_approval_of_revision: "Date of approval of revision",
    total_incentive_grant: "Total Incentive Grant",
    incentive_grant_accessed: "Incentive grant accessed",
    percentage_of_incentive_grant_accessed: "Percentage of incentive grant accessed",
    incentive_grant_utilized: "Incentive grant utilised",
    percentage_of_incentive_grant_utilized: "Percentage of incentive grant utilized",
    details_of_incentive_grant_accessed_and_utilized: "Details of incentive grant accessed & utilized",
    name_of_the_works_or_procurement_expenditure: "Name of the Works /Procurement /Expenditure",
    current_annual_plan: "Current Annual Plan",
    previous_years_annual_plans: "Previous Years Annual Plans",
    wo_or_po_number: "WO /PO No",
    amount_paid_in_reporting_quarter: "Amount Paid in the Reporting quarter",
    total_amount_paid_during_the_financial_year: "Total Amount paid during the financial year",
    cumulative_paid_as_at_the_end_of_reporting_month: "Cumulative paid as at the end of reporting month(since beginning of KSWMP/ sub-project)",
    balance_amount_to_be_paid: "Balance Amount to be Paid",
    percentage_of_work_done: "% of work done",
    percentage_of_work_pending_to_be_done: "% of work pending to be done",
    quarter: "Quarter",
    district_name: "District Name",
    capital_only_with_special_letter: "Name must be capitalized (Only / _  ,  - These Special Characters are Supported)",
    description_of_expenditure: "Description of Expenditure",
    reporting_quarter: "Reporting Quarter",
    cumulative_to_date: "Cumulative to Date",
    no_records: "No Records To Display",
    wo_or_po_date: "WO/PO Date",
    total_value_of_wo_or_po: "Total Value of WO/PO",
    year_to_date_expenditure: "Year to Date Expenditure",
    cumulative_to_date_expenditure: "Cumulative to Date Expenditure",
    form_name: "Form Name",
    uploaded_form: "Uploaded Form",
    verification_due_on: "Verification Due on",
    uploaded_successfully: "Uploaded Successfully",
    date_of_submission: "Date of Submission",

    //incidents
    workplace_accident: "Workplace Accident",
    harassment_complaint: "Harassment Complaint",
    recovery_registry: "Recovery Registry",
    maternity_leave: "Maternity Leave",
    record_accident: "Record Accident",
    date_and_time_of_incident: "Date & Time of Incident",
    incident_details: "Incident Details",
    employee_name_with_code: "Employee Name With Code",
    age: "Age",
    employee_address: "Employee Address",
    date_and_time_of_accident: "Date and Time of Accident",
    location_of_the_accident_site: "Location of the Accident Site",
    was_it_fatal: "Was It Fatal",
    summarized_details_of_the_accident: "Summarized Details of the Accident",
    fatal_accident_report: "Fatal Accident Report",
    investigation_report: "Investigation Report",
    work_place_accident: "Workplace Accident",

    //Harassment Complaint
    icc_complaint_code_number: "ICC Complaint Code Number",
    record_harassment: "Record Harassment",
    date_of_receipt_of_complaint: "Date of Receipt Of Complaint",
    notice_to_respondent: "Notice to Respondent",
    response_from_respondent: "Response from Respondent",
    complaint_settlement: "Complaint Settlement",
    hearing: "Hearing",
    whether_submitted: "Whether Submitted",
    edit_work_place_accident: "Edit Workplace Accident",
    workplace_accident_details_added_successfully: "Workplace Accident Details Added Successfully",
    workplace_accident_details: "Workplace Accident Details",
    notice_respondent_added_successfully: "Notice Respondent Added Successfully",
    response_respondent_added_successfully: "Response from Respondent Added Successfully",
    complaint_settlement_added_successfully: "Complaint Settlement Added Successfully",
    hearing_details_added_successfully: "Hearing Details Added Successfully",
    edit_harassment_details: "Edit Harassment Details",
    download: "Download",
    notice_respondent_updated_successfully: "Notice Respondent Updated Successfully",
    response_respondent_updated_successfully: "Response Respondent Updated Successfully",
    complaint_settlement_updated_successfully: "Complaint Settlement Updated Successfully",
    hearing_details_updated_successfully: "Hearing Details Updated Successfully",
    harassment_complaint_updated_successfully: "Hearing Complaint Updated Successfully",
    harassment_complaint_added_successfully: "Harassment Complaint Added Successfully",
    harassment_details: "Harassment Details",
    recovery_type: "Recovery Type",
    record_recoveries: "Record Recoveries",
    generate_form_c: "Generate Form C",
    weather_show_caused_issue: "Whether Show Caused Issue",
    explanation_heard_in_presence_of: "Explanation Heard In Presence Of",
    type_of_maternity_leave: "Type Of Maternity Leave",
    recovery_register: "Recovery Register",
    maternity_detail: "Maternity Details",
    particulars: "Particulars",
    add_record: "Add Record",

    // land acquisitions

    land_acquisitions: "Land Acquisitions",
    project_list_land_acquisitions: "Project List - Land Acquisitions",
    section_under_the_act: "Section Under The Act(RFCTLARR)",
    person_responsible: "Person Responsible",
    whether_applicable_to_sub_project: "Whether Applicable to Sub Project",
    proposed_date: "Proposed Date",
    status_of_implementation: "Status of Implementation",
    whether_received: "Whether Received",
    project_site_deleted_successfully: "Project Site Deleted Successfully",

    //Sub project reports

    swm_sub_project_reports: "Swm Sub project Reports",
    approved_plans: "Approved Plans",
    gap_analyzed: "Gap Analyzed",
    post_plan_consulted: "Post Plan Consulted",
    draft_plan_completed: "Draft Plan Completed",
    cost_interventions: "Cost Interventions",
    cost_interventions_year_wise: "Cost Interventions Year Wise",
    no_of_projects: "Number of Projects",
    approved_date: "Approved Date",
    total_cost_in_rupee: "Total Cost in Rupee",
    status_summary: "Status Summary",
    year1_no_of_project: "Year 1 No of Project",
    year1_total_cost: "Year 1 Total Cost",
    year2_no_of_project: "Year 2 No of Project",
    year2_total_cost: "Year 2 Total Cost",
    year3_no_of_project: "Year 3 No of Project",
    year3_total_cost: "Year 3 Total Cost",
    year4_no_of_project: "Year 4 No of Project",
    year4_total_cost: "Year 4 Total Cost",
    year5_no_of_project: "Year 5 No of Project",
    year5_total_cost: "Year 5 Total Cost",
    dpmu_remarks_required: "Dpmu Remarks Required",
    location_and_facilities: "Location And Facilities",
    name_of_the_facility: "Name of the Facilities",
    thematic_area: "Thematic Area",
    time_line: "Time Line",
    tsc_remarks: "TSC Remarks",
    proposed_date_of_completion: "Proposed Date Of Completion",
    actual_date_of_completion: "Actual Date of Completion",
    data_source: "Data Source",
    scheme_cycle_activities_for_sub_project: "Scheme Cycle Activiteis for Sub Project",
    social_risk_categorization: "Social risk categorization",
    social_management_activities: "Social Management Avtivities",
    count_of_document: "Count (To provide the number of document,plan prepared & Implemented)",
    pre_planning_phase: "Pre-Planning Phase: Prioritization of Sub-Projects",
    planning_for_prioritized_sub_projects: "Planning for prioritized sub-projects – develop action plans to include in DPR",
    planning_for_behaviour: "Planning for Behaviour Communication and change",
    implementation_phase: "Implementation phase: Action Plans",
    post_implementation_monitoring: "Post Implementation Monitoring and Exit",
    output: "Output",

    //sub project reports

    swm_plan_reports: "Swm Plan Reports",
    team_composition_status: "Team Composition Status",
    details_of_team: "Details of Team",
    gap_analysis_of_waste_generation: "Gap Analysis of Waste Generation",
    status_of_team_composition: "Status of Team Composition",
    team_member_name: "Team Member Name",
    total_waste: "Total Waste",
    bio_waste: "Bio Waste",
    non_bio: "Non Bio Waste",
    status_of_team: "Status Of Team"
};
