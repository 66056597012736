import { FormLabel, Grid } from "@mui/material";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond/dist/filepond.min.css";
import { ErrorMessage, Field } from "formik";
import { FilePond, registerPlugin } from "react-filepond";
import { getBase64 } from "utils/commonUtils";

import { FORM_CONTROL_STYLE } from "./style";
import TextError from "./TextError";

const FileUploader = (props) => {
    const { name, label, ...rest } = props;
    registerPlugin(FilePondPluginImagePreview);

    return (
        <Grid sx={FORM_CONTROL_STYLE}>
            <Field>
                {
                    ({ field, form }) => {
                        const { setFieldValue } = form;
                        const handleFileInputChange = (files) => {
                            getBase64(files)
                                .then(result => {
                                    files.base64 = result;
                                    let multimediaList = {};
                                    const regex = /data:.*base64,/;
                                    multimediaList.base64EncodedData = files.base64.replace(regex, "");
                                    multimediaList.contentType = files?.type;
                                    multimediaList.fileSizeKb = files?.size;
                                    multimediaList.resourceName = files?.name;
                                    multimediaList.fileExtn = files?.name.split(".").pop();
                                    setFieldValue(name, multimediaList);
                                });
                        };
                        const { value = "" } = field;
                        return <>
                            <FormLabel htmlFor={name}>{label}</FormLabel>
                            <FilePond
                                {...rest}
                                {...field}
                                allowDrop={false}
                                allowPaste={false}
                                credits={false}
                                allowMultiple={false}
                                allowReplace={false}
                                name={name}
                                id={name}
                                value={value}
                                onupdatefiles={fileItems => {
                                    handleFileInputChange(fileItems[0]?.file);
                                }}
                                labelIdle="<Typography class='filepond--label-action'>Browse</Typography>"
                            />
                            <ErrorMessage component={TextError} name={name} />
                        </>;
                    }
                }
            </Field>
        </Grid>
    );
};

export default FileUploader;
