import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions.js";
import { getPayloadContent } from "utils/apiUtils";
import _ from "lodash";

const initialState = {
    lsgiSelectDropdownOption: {
        requestInProgress: false,
        data: []
    },
    districtDropdown: {
        requestInProgress: false,
        data: []
    },
    fiveYearPlanDropdown: {
        requestInProgress: false,
        data: []
    },
    annualPlanById: {
        requestInProgress: false,
        data: {
            districtId: "",
            fiveYearPlan: "",
            lsgiId: "",
            planCode: "",
            planName: "",
            remarks: "",
            fromYear: "",
            toYear: "",
            assignedProjects: [],
            cruxList: [],
            attachmentIds: []
        }
    },
    approvalByQaQc: {
        requestInProgress: false,
        data: {
            dpmuRemarks: ""
        }
    },
    approvalBySpmu: {
        requestInProgress: false,
        data: {
            spmuRemarks: ""
        }
    },
    approvalByMc: {
        requestInProgress: false,
        data: {
            mcResolutionNumber: "",
            mcResolutionDate: "",
            mcRemarks: ""
        }
    },
    fiveYearProjectListDetails: {
        requestInProgress: false,
        data: {}
    },
    annualProjectNewDetails: {
        requestInProgress: false,
        data: {}
    },
    planStatusList: {
        requestInProgress: false,
        data: []
    }
};
const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clear: () => initialState,
        clearModal: (state) => {
            state.approvalByQaQc.data = initialState.approvalByQaQc.data;
            state.approvalBySpmu.data = initialState.approvalBySpmu.data;
            state.approvalByMc.data = initialState.approvalByMc.data;
        },
        clearStatus: (state) => {
            state.planStatusList.data = initialState.planStatusList.data;
        },
        clearDistrict: (state) => {
            state.districtDropdown.data = initialState.districtDropdown.data;
        },
        clearLsgi: (state) => {
            state.lsgiSelectDropdownOption.data = initialState.lsgiSelectDropdownOption.data;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "districtDropdown.requestInProgress", false);
                _.set(state, "districtDropdown.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_FIVE_YEAR_PLAN_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "fiveYearPlanDropdown.requestInProgress", false);
                _.set(state, "fiveYearPlanDropdown.data", payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST, (state) => {
                state.lsgiSelectDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS, (state, action) => {
                state.lsgiSelectDropdownOption = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE, (state) => {
                state.lsgiSelectDropdownOption.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.LIST_BY_ID_ANNUAL_PLAN_REQUEST, (state) => {
                state.annualPlanById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.LIST_BY_ID_ANNUAL_PLAN_SUCCESS, (state, { payload }) => {
                _.set(state, "annualPlanById.data", payload);
                _.set(state, "annualPlanById.data.assignedProjects", payload?.assignedProjects);
                _.set(state, "annualPlanById.data.cruxList", payload?.cruxList);
                _.set(state, "annualPlanById.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_BY_ID_ANNUAL_PLAN_FAILURE, (state) => {
                state.annualPlanById.requestInProgress = false;
                state.error = "Something went wrong";
            })
            .addCase(ACTION_TYPES.FETCH_FIVE_YEAR_LIST_SUCCESS, (state, action) => {
                _.set(state, "fiveYearProjectListDetails.requestInProgress", false);
                _.set(state, "fiveYearProjectListDetails.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.FETCH_ANNUAL_PLAN_LIST_SUCCESS, (state, action) => {
                _.set(state, "annualProjectNewDetails.requestInProgress", false);
                _.set(state, "annualProjectNewDetails.data", getPayloadContent(action));
            }).addCase(ACTION_TYPES.FETCH_STATUS_LIST_SUCCESS, (state, action) => {
                _.set(state, "planStatusList.requestInProgress", false);
                _.set(state, "planStatusList.data", getPayloadContent(action));
            });
    }
});
export const { actions, reducer } = slice;
