import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchMcfRrfDetailsListApi = (data) => {
    return {
        url: API_URL.MCF_RRF_DETAILS.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_MCF_RRF_DETAILS_LIST_REQUEST, ACTION_TYPES.FETCH_MCF_RRF_DETAILS_LIST_SUCCESS, ACTION_TYPES.FETCH_MCF_RRF_DETAILS_LIST_FAILURE], data
        }
    };
};

export const submitMcfRrfDetailsApi = (data) => ({
    url: API_URL.MCF_RRF_DETAILS.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_MCF_RRF_DETAILS_REQUEST, ACTION_TYPES.POST_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.POST_MCF_RRF_DETAILS_FAILURE],
        data
    }
});

export const updateMcfRrfDetailsApi = (data) => ({
    url: API_URL.MCF_RRF_DETAILS.SAVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_MCF_RRF_DETAILS_REQUEST, ACTION_TYPES.PATCH_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.PATCH_MCF_RRF_DETAILS_FAILURE],
        data
    }
});

export const fetchMcfRrfDetailsByIdApi = (id) => {
    return {
        url: API_URL.MCF_RRF_DETAILS.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_MCF_RRF_DETAILS_BY_ID_REQUEST, ACTION_TYPES.FETCH_MCF_RRF_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_MCF_RRF_DETAILS_BY_ID_FAILURE]
        }
    };
};

export const deleteMcfRrfDetailsApi = (id) => ({
    url: API_URL.MCF_RRF_DETAILS.DELETE.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
        types: [ACTION_TYPES.DELETE_MCF_RRF_DETAILS_REQUEST, ACTION_TYPES.DELETE_MCF_RRF_DETAILS_SUCCESS, ACTION_TYPES.DELETE_MCF_RRF_DETAILS_FAILURE]
    }
});

export const fetchTypeDropdownApi = (query = {}) => {
    return {
        url: API_URL.MCF_RRF_DETAILS.TYPE_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TYPE_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_TYPE_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_TYPE_DROPDOWN_FAILURE],
            data: query
        }
    };
};
