import { useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ButtonBase } from "@mui/material";
import { Components } from "../../../common/components";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ProfileMenu from "./ProfileMenu";
import MenuIcon from "@mui/icons-material/Menu";
import Profile from "./Profile";
import SwitchRoles from "./SwitchRole";

const { Box, Grid } = Components;

const Header = ({ showSidebar, setShowSidebar }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let rotate = open === false ? "rotate(-90deg)" : "rotate(-270deg)";

    return (
        <Grid
            component="header"
            sx={{ width: "100%", height: "82px", position: "sticky", top: 0, zIndex: 100, backgroundColor: "secondary.main", display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
            <MenuIcon sx={{ pl: 6, color: "white.main", display: { xs: "flex", md: "none" } }} onClick={() => {
                setShowSidebar(!showSidebar);
            }} />
            <Box sx={{ width: "240px", display: { xs: "none", md: "block" }, pl: 6 }}>
                <Profile />
            </Box>
            <span />
            <Box sx={{ width: "900px", display: { xs: "none", md: "flex", lg: "flex", xl: "flex" }, justifyContent: { xs: "none", md: "end", lg: "end", xl: "end" } }}>
                <SwitchRoles />
            </Box>
            <Box
                sx={{
                    display: "flex", justifyContent: "end", alignItems: "center", mr: 5, borderRadius: "10px", p: 1, position: "relative", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" }
                }}
                variant="contained" component="div"
            >
                <ButtonBase sx={{}} onClick={handleClick}>
                    <SettingsOutlinedIcon sx={{ width: "30px", height: "33px", color: "white.main" }} />
                    <ChevronLeftOutlinedIcon sx={{ color: "white.main", transform: rotate }} />
                </ButtonBase>
            </Box>
            <ProfileMenu open={open} handleClose={handleClose} anchorEl={anchorEl} />
        </Grid >
    );
};

export default Header;
