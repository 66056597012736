import { ACTION_TYPES } from "./actions";
import _ from "lodash";
import { saveTrainingBatchAPI, editBatchFormByIdAPI, fetchTrainingModeApi, fetchHostIdApi, fetchPlanIdApi, fetchVenueIdApi, fetchTargetGroupApi, editBatchFormAPI, deleteBatchByIdAPI, deleteParticipantByIdAPI } from "./api";
import { handleAPIRequest } from "../../../utils/http";
import { all, call, fork, put, take, takeLatest, select } from "redux-saga/effects";
import { actions as commonActions } from "modules/common/slice";
import { I18n } from "common/components";
import { successNotify } from "utils/notificationUtils";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "../../paths";
import { TRAINING_PATH as PATH } from "../paths";
import * as api from "./api";
import { getAdditionalFilters, setCommonTableData, getPaginationPayload, getSelectedRows } from "utils/tableUtils";
import { TABLE_IDS } from "common/constants";
import { getPayloadContent } from "utils/apiUtils";
import { getTableProps } from "modules/common/selectors";
import { actions as sliceAction } from "./slice";
import { API_URL } from "../apiUrls";
import { commonFileDownload } from "modules/common/actions";
// import { batch } from "react-redux";

const formatValue = (userId = []) => {
    let employees = [];
    _.forEach(userId, ({ id }) => {
        employees.push({ id });
    });
    return { employees };
};

const formatAdd = (users = [], batchId) => {

    let employees = [];
    _.forEach(users, ({ userId, firstName, lastName, activeProfile }) => {
        employees.push({
            userId: {
                userId: userId, firstName: firstName, lastName: lastName, activeProfile: activeProfile
            }, batchId: { id: batchId }
        });
    });
    return { employees };
};


export function* saveTrainingBatch({ payload }) {

    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, editBatchFormAPI, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_BATCH_FORM_SUCCESS, ACTION_TYPES.EDIT_BATCH_FORM_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_BATCH_FORM_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("training_batch") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.BATCH.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, saveTrainingBatchAPI, payload);
        const responseAction = yield take([ACTION_TYPES.SAVE_BATCH_FORM_SUCCESS, ACTION_TYPES.SAVE_BATCH_FORM_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SAVE_BATCH_FORM_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("saved", { type: I18n("training_batch") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.BATCH.LIST}`));
        }

    }
}

export function* listTrainingBatch(action) {
    let additionalFilters = yield* getAdditionalFilters(TABLE_IDS.TRAINING_BATCH);
    yield fork(handleAPIRequest, api.listTrainingBatchApi, { ...action.payload, ...additionalFilters });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_BATCH_DETAILS_SUCCESS, ACTION_TYPES.LIST_BATCH_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_BATCH);
}

export function* fetchTargetGroup({ payload }) {
    const { targetGroupId, planId } = payload;
    yield call(handleAPIRequest, fetchTargetGroupApi, targetGroupId, planId);
}
export function* fetchHostId(action) {
    yield call(handleAPIRequest, fetchHostIdApi, action.payload);
}
export function* fetchOrganizedBy(action) {
    yield call(handleAPIRequest, api.fetchOrganizedByApi, action.payload);
}
export function* fetchPlanId(action) {
    yield call(handleAPIRequest, fetchPlanIdApi, action.payload);
}

export function* fetchVenueId(action) {
    yield call(handleAPIRequest, fetchVenueIdApi, action.payload);
}
export function* fetchTrainingMode(action) {
    yield call(handleAPIRequest, fetchTrainingModeApi, action.payload);
}

export function* deleteBatchById({ payload: id }) {
    yield fork(handleAPIRequest, deleteBatchByIdAPI, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_BATCH_FORM_SUCCESS, ACTION_TYPES.DELETE_BATCH_FORM_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_BATCH_FORM_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("batch_row") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listTrainingBatch, { payload: tablePayload });
    }
}
export function* editBatchFormById({ payload: id }) {
    yield call(handleAPIRequest, editBatchFormByIdAPI, { id });
}
export function* addParticipantForm({ payload }) {
    let tableProps = yield select(getTableProps);
    let {[TABLE_IDS.ADD_BATCH]: { filters = {} } = {} } = tableProps;
    let updatedPayload = { batchId: payload.id, pageNo: payload.pageNo, pageSize: 25 };
    yield fork(handleAPIRequest, api.addParticipantFormApi, { ...filters, ...updatedPayload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_ADD_PARTICIPANT_SUCCESS, ACTION_TYPES.FETCH_ADD_PARTICIPANT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.ADD_BATCH);
}
export function* viewParticipantDetails({ payload }) {
    let updatedPayload = { batchId: payload.id, pageNo: payload.pageNo, pageSize: 100 };
    yield fork(handleAPIRequest, api.viewParticipantDetailsApi, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_SUCCESS, ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.VIEW_BATCH);

}

export function* confirmParticipantForm(id) {
    const { selectedData } = yield* getSelectedRows(TABLE_IDS.VIEW_BATCH);
    if (id) {
        let emp = formatValue(selectedData, id);
        yield fork(handleAPIRequest, api.confirmParticipantFormApi, emp);
        const responseAction = yield take([ACTION_TYPES.CONFIRM_PARTICIPANT_BY_ID_SUCCESS, ACTION_TYPES.CONFIRM_PARTICIPANT_BY_ID_FAILURE]);
        if (responseAction.type === ACTION_TYPES.CONFIRM_PARTICIPANT_BY_ID_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("training_batch") }) }));
            window.location.reload();

        }
    }
}


export function* addParticipantById({ payload: batchId }) {
    const { selectedData } = yield* getSelectedRows(TABLE_IDS.ADD_BATCH);
    if (batchId) {
        let emp = formatAdd(selectedData, batchId);
        yield fork(handleAPIRequest, api.addPArticipantByIdApi, emp);
        const responseAction = yield take([ACTION_TYPES.ADD_PARTICIPANT_BY_ID_SUCCESS, ACTION_TYPES.ADD_PARTICIPANT_BY_ID_FAILURE]);
        if (responseAction.type === ACTION_TYPES.ADD_PARTICIPANT_BY_ID_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("training_batch") }) }));
            window.location.reload();

        }
    }
}

export function* fetchCertificateDetails({ payload }) {
    let updatedPayload = { batchId: payload.id, pageNo: payload.pageNo, pageSize: payload.pageSize };
    yield fork(handleAPIRequest, api.fetchCertificateDetailsApi, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_CERTIFICATE_DETAILS_SUCCESS, ACTION_TYPES.FETCH_CERTIFICATE_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.CERTIFICATE_ISSUE);
}

export function* issueCertificateConfirmation(id) {
    const { selectedData } = yield* getSelectedRows(TABLE_IDS.CERTIFICATE_ISSUE);
    if (id) {
        let emp = formatValue(selectedData, id);
        yield fork(handleAPIRequest, api.confirmCertificateIssueApi, emp);
        const responseAction = yield take([ACTION_TYPES.CERTIFICATE_ISSUE_SUCCESS, ACTION_TYPES.CERTIFICATE_ISSUE_FAILURE]);
        if (responseAction.type === ACTION_TYPES.CERTIFICATE_ISSUE_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("training_certificate") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.BATCH.LIST}`));
        }
    }
}

export function* deleteParticipantById({ payload: id }) {
    yield fork(handleAPIRequest, deleteParticipantByIdAPI, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_BATCH_PARTICIPANT_SUCCESS, ACTION_TYPES.DELETE_BATCH_PARTICIPANT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_BATCH_PARTICIPANT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("batch_row") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
    }
}


export function* fetchNameList({ payload: { value, key, batchId } = {} }) {
    let newPayload = {};
    _.set(newPayload, `${key}`, value);
    yield fork(handleAPIRequest, api.nameList, { key: key, value: value, batchId: batchId });
    const response = yield take([ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, ACTION_TYPES.FETCH_NAME_LIST_FAILURE]);
    if (response.type === ACTION_TYPES.FETCH_NAME_LIST_SUCCESS) {
        yield put(sliceAction.setUserNameJSON({ key, value: getPayloadContent(response) }));
    }

}

export function* filterBatchListDropdown({ payload }) {
    yield call(handleAPIRequest, api.filterBatchList, payload);
}


export function* fetchDesignationList({ payload }) {
    yield call(handleAPIRequest, api.designationGetById, payload);
}
export function* fetchTrainingCordinatorId({ payload }) {
    yield call(handleAPIRequest, api.fetchTrainingCordinatorIdApi, payload);
}

export function* lsgiDropdown(action) {
    yield call(handleAPIRequest, api.lsgiDropdownApi, action.payload);
}

export function* fetchDistrictDropDown(action) {
    yield call(handleAPIRequest, api.fetchDistrict, action.payload);
}

export function* fetchConfirmationList(action) {
    yield call(handleAPIRequest, api.fetchConfirmation, action.payload);
}

export function* viewParticipantsDownload(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { batchId } = action.payload;
    //const { filters } = action.pypeayload;
    // console.log("type is" + type);
    // console.log("batch id is" + batchId);
    //console.log(action.payload);
    yield put(commonFileDownload({
        url: API_URL.TRAINING_BATCH.DOWNLOAD,
        data: { type, batchId },
        file: { name: fileName, ext: type }
    }));
}
export function* filterConfirmation({ payload }) {

    yield call(handleAPIRequest, api.filterConfirmationList, payload);
}

export function* fetchThematicArea({ payload }) {

    yield call(handleAPIRequest, api.fetchThematicArea, payload);
}

export default function* batchSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SAVE_BATCH_FORM, saveTrainingBatch),
        takeLatest(ACTION_TYPES.LIST_BATCH_DETAILS, listTrainingBatch),
        takeLatest(ACTION_TYPES.FETCH_TARGET_GROUP, fetchTargetGroup),
        takeLatest(ACTION_TYPES.FETCH_HOST_ID, fetchHostId),
        takeLatest(ACTION_TYPES.FETCH_PLAN_ID, fetchPlanId),
        takeLatest(ACTION_TYPES.FETCH_VENUE_ID, fetchVenueId),
        takeLatest(ACTION_TYPES.FETCH_TRAINING_MODE, fetchTrainingMode),
        takeLatest(ACTION_TYPES.DELETE_BATCH_FORM, deleteBatchById),
        takeLatest(ACTION_TYPES.EDIT_BATCH_FORM_BY_ID, editBatchFormById),
        takeLatest(ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS, viewParticipantDetails),
        takeLatest(ACTION_TYPES.FETCH_ADD_PARTICIPANT, addParticipantForm),
        takeLatest(ACTION_TYPES.CONFIRM_PARTICIPANT_BY_ID, confirmParticipantForm),
        takeLatest(ACTION_TYPES.ADD_PARTICIPANT_BY_ID, addParticipantById),
        takeLatest(ACTION_TYPES.FETCH_CERTIFICATE_DETAILS, fetchCertificateDetails),
        takeLatest(ACTION_TYPES.CERTIFICATE_ISSUE, issueCertificateConfirmation),
        takeLatest(ACTION_TYPES.FETCH_ORGANIZED_BY, fetchOrganizedBy),
        takeLatest(ACTION_TYPES.DELETE_BATCH_PARTICIPANT, deleteParticipantById),
        takeLatest(ACTION_TYPES.FETCH_NAME_LIST, fetchNameList),
        takeLatest(ACTION_TYPES.FILTER_BATCH_LIST, filterBatchListDropdown),
        takeLatest(ACTION_TYPES.FETCH_DESIGNATION_LIST, fetchDesignationList),
        takeLatest(ACTION_TYPES.FETCH_TRAINING_CORDINATOR_ID, fetchTrainingCordinatorId),
        takeLatest(ACTION_TYPES.FETCH_LSGI_DROPDOWN, lsgiDropdown),
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN, fetchDistrictDropDown),
        takeLatest(ACTION_TYPES.VIEW_PARTICIPANTS_LIST_DOWNLOAD, viewParticipantsDownload),
        takeLatest(ACTION_TYPES.FETCH_CONFIRMATION_LIST, fetchConfirmationList),
        takeLatest(ACTION_TYPES.FILTER_CONFIRMATION_LIST, filterConfirmation),
        takeLatest(ACTION_TYPES.FETCH_THEMATIC_AREA_DROPDOWN, fetchThematicArea)

    ]);
}
