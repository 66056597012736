export const STATE_REDUCER_KEY = "labourManagement";

export const considerWeekOff = [
    {
        id: 1,
        name: "Yes",
        value: true
    },
    {
        id: 2,
        name: "No",
        value: false
    }
];

export const PLAN_TYPE = {
    ESTABLISHMENT_DETAILS: "ESTABLISHMENT_DETAILS",
    REQUIREMENTS: "REQUIREMENTS",
    WORKPLACE_ACCIDENT: "WORKPLACE_ACCIDENT",
    HARASSMENT_COMPLAINT: "HARASSMENT_COMPLAINT",
    RECOVERY_REGISTRY: "RECOVERY_REGISTRY",
    MATERNITY_LEAVE: "MATERNITY_LEAVE"
};
