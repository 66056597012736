export const OPERATIONAL_MODULE_PATH = {
    MASTER_INFO: "master-info",
    OPERATIONAL_DATA: "operational-data",
    PRIMARY_COLLECTION: {
        LIST: "primary-collection",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"

    },
    SECONDARY_COLLECTION: {
        LIST: "secondary-collection",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    WASTE_PROCESSING: {
        LIST: "waste-processing-sites-list",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    MCF_RRF: {
        LIST: "mcf-rrf",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    DISPOSAL_CITY_LEVEL: {
        LIST: "city-level-disposal",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    REGIONAL_DISPOSAL_SITE: {
        LIST: "regional-disposal-site",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    TREATMENT_AT_SOURCE_FOR_BDW: {
        LIST: "treatment-at-source-for-bdw",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    PRIMARY_COLLECTION_BDW: {
        LIST: "primary-collection-bdw",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    WSS_INFO: {
        LIST: "waste-segregation-and-storage-info",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    SECONDARY_TRANSPORTATION: {
        LIST: "secondary-transportation",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    MCF_RRF_DETAILS: {
        LIST: "mcf-rrf-details-information",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    WASTE_PROCESSING_PLANT_DETAILS: {
        LIST: "waste-processing-plant-details",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    CITY_LEVEL_DISPOSAL_CELL_INFO: {
        LIST: "city-level-disposal-cell-info",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    WASTE_DISPOSAL_AT_REGIONAL_SITE: {
        LIST: "waste-disposal-at-regional-site",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    TRANSFER_STATION: {
        LIST: "transfer-station",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    },
    WARD_DETAILS: {
        LIST: "ward-details",
        CREATE: "create",
        EDIT: ":id/edit",
        VIEW: ":id/view"
    }
};
