import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS: `${STATE_REDUCER_KEY}/FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS`,
    FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST`,
    FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS`,
    FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE`,

    CITY_LEVEL_SITE_DROPDOWN: `${STATE_REDUCER_KEY}/CITY_LEVEL_SITE_DROPDOWN`,
    CITY_LEVEL_SITE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/CITY_LEVEL_SITE_DROPDOWN_REQUEST`,
    CITY_LEVEL_SITE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/CITY_LEVEL_SITE_DROPDOWN_SUCCESS`,
    CITY_LEVEL_SITE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/CITY_LEVEL_SITE_DROPDOWN_FAILURE`,

    GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID`,
    GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_REQUEST`,
    GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_SUCCESS`,
    GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID_FAILURE`,

    POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS: `${STATE_REDUCER_KEY}/POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS`,
    POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST`,
    POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS`,
    POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE`,

    PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS: `${STATE_REDUCER_KEY}/PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS`,
    PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST`,
    PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS`,
    PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE`,

    DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS: `${STATE_REDUCER_KEY}/DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS`,
    DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST`,
    DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS`,
    DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE`,

    DOWNLOAD_CITY_LEVEL_DISPOSAL_CELL_DETAILS: `${STATE_REDUCER_KEY}/DOWNLOAD_CITY_LEVEL_DISPOSAL_CELL_DETAILS`,
    DOWNLOAD_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_CITY_LEVEL_DISPOSAL_CELL_DETAILS_REQUEST`,
    DOWNLOAD_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_CITY_LEVEL_DISPOSAL_CELL_DETAILS_SUCCESS`,
    DOWNLOAD_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_CITY_LEVEL_DISPOSAL_CELL_DETAILS_FAILURE`
};

export const fetchCityLevelDisposalCellDetails = createAction(ACTION_TYPES.FETCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS);
export const cityLevelSiteDropdown = createAction(ACTION_TYPES.CITY_LEVEL_SITE_DROPDOWN);
export const getCityLevelDisposalCellDetailsById = createAction(ACTION_TYPES.GET_CITY_LEVEL_DISPOSAL_CELL_DETAILS_BY_ID);
export const postCityLevelDisposalCellDetails = createAction(ACTION_TYPES.POST_CITY_LEVEL_DISPOSAL_CELL_DETAILS);
export const patchCityLevelDisposalCellDetails = createAction(ACTION_TYPES.PATCH_CITY_LEVEL_DISPOSAL_CELL_DETAILS);
export const deleteCityLevelDisposalCellDetails = createAction(ACTION_TYPES.DELETE_CITY_LEVEL_DISPOSAL_CELL_DETAILS);
export const downloadCityLevelDisposalCellDetails = createAction(ACTION_TYPES.DOWNLOAD_CITY_LEVEL_DISPOSAL_CELL_DETAILS);


