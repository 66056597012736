import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";
import { REQUEST_METHOD } from "common/constants";

export const listProjectApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.PROJECT_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_PROJECT_LIST_REQUEST, ACTION_TYPES.FETCH_PROJECT_LIST_SUCCESS, ACTION_TYPES.FETCH_PROJECT_LIST_FAILURE],
        data
    }
});

export const listProjectSitesApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.PROJECT_SITES_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_PROJECT_SITE_LIST_REQUEST, ACTION_TYPES.FETCH_PROJECT_SITE_LIST_SUCCESS, ACTION_TYPES.FETCH_PROJECT_SITE_LIST_FAILURE],
        data
    }
});

export const fetchNatureOfWorkDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.NATURE_OF_WORK_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_NATURE_OF_WORK_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_NATURE_OF_WORK_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_NATURE_OF_WORK_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchWagePeriodDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.WAGE_PERIOD_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WAGE_PERIOD_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_WAGE_PERIOD_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_WAGE_PERIOD_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchDayOfHolidayDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.DAY_OF_HOLIDAY_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DAY_OF_HOLIDAY_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_DAY_OF_HOLIDAY_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_DAY_OF_HOLIDAY_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchDistrictDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.DISTRICT_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchUlbDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.LSGI_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ULB_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_ULB_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_ULB_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchBasicDetailsByIdApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.BASIC_DETAILS_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BASIC_DETAILS_BY_ID_REQUEST, ACTION_TYPES.FETCH_BASIC_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_BASIC_DETAILS_BY_ID_FAILURE],
            data
        }
    };
};

export const submitBasicDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.POST_BASIC_DETAILS,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_BASIC_DETAILS_REQUEST, ACTION_TYPES.POST_BASIC_DETAILS_SUCCESS, ACTION_TYPES.POST_BASIC_DETAILS_FAILURE],
            data
        }
    };
};

export const updateBasicDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.POST_BASIC_DETAILS,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_BASIC_DETAILS_REQUEST, ACTION_TYPES.PATCH_BASIC_DETAILS_SUCCESS, ACTION_TYPES.PATCH_BASIC_DETAILS_FAILURE],
            data
        }
    };
};

export const fetchProjectManagerByIdApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.PROJECT_MANAGER_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PROJECT_MANAGER_DETAILS_BY_ID_REQUEST, ACTION_TYPES.FETCH_PROJECT_MANAGER_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_PROJECT_MANAGER_DETAILS_BY_ID_FAILURE],
            data
        }
    };
};

export const submitProjectManagerDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.POST_PROJECT_MANAGER_DETAILS,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_PROJECT_MANAGER_DETAILS_REQUEST, ACTION_TYPES.POST_PROJECT_MANAGER_DETAILS_SUCCESS, ACTION_TYPES.POST_PROJECT_MANAGER_DETAILS_FAILURE],
            data
        }
    };
};

export const fetchProjectManagerListApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.PROJECT_MANAGER_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PROJECT_MANAGER_LIST_REQUEST, ACTION_TYPES.FETCH_PROJECT_MANAGER_LIST_SUCCESS, ACTION_TYPES.FETCH_PROJECT_MANAGER_LIST_FAILURE],
            data
        }
    };
};

export const updateProjectManagerDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.POST_PROJECT_MANAGER_DETAILS,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_PROJECT_MANAGER_DETAILS_REQUEST, ACTION_TYPES.PATCH_PROJECT_MANAGER_DETAILS_SUCCESS, ACTION_TYPES.PATCH_PROJECT_MANAGER_DETAILS_FAILURE],
            data
        }
    };
};

export const fetchMinimumWagesApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.MINIMUM_WAGES_GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_MINIMUM_WAGE_REQUEST, ACTION_TYPES.FETCH_MINIMUM_WAGE_SUCCESS, ACTION_TYPES.FETCH_MINIMUM_WAGE_FAILURE],
            data
        }
    };
};

export const fetchMinimumWagesByIdApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.MINIMUM_WAGES_GET_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_MINIMUM_WAGE_BY_ID_REQUEST, ACTION_TYPES.FETCH_MINIMUM_WAGE_BY_ID_SUCCESS, ACTION_TYPES.FETCH_MINIMUM_WAGE_BY_ID_FAILURE],
            data
        }
    };
};

export const postMinimumWagesApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.MINIMUM_WAGES_POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_MINIMUM_WAGE_REQUEST, ACTION_TYPES.POST_MINIMUM_WAGE_SUCCESS, ACTION_TYPES.POST_MINIMUM_WAGE_FAILURE],
            data
        }
    };
};

export const fetchRegistrationNumberApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.REGISTRATION_NUMBER_GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_REGISTRATION_NUMBER_REQUEST, ACTION_TYPES.FETCH_REGISTRATION_NUMBER_SUCCESS, ACTION_TYPES.FETCH_REGISTRATION_NUMBER_FAILURE],
            data
        }
    };
};

export const fetchEmployeeListApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.EMPLOYEE_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_EMPLOYEE_LIST_REQUEST, ACTION_TYPES.FETCH_EMPLOYEE_LIST_SUCCESS, ACTION_TYPES.FETCH_EMPLOYEE_LIST_FAILURE],
        data
    }
});

export const fetchGenderDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.GENDER_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GENDER_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_GENDER_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_GENDER_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchNationalityDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.NATIONALITY_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_NATIONALITY_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_NATIONALITY_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_NATIONALITY_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchEducationLevelDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EDUCATION_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BASIC_DETAILS_BY_ID_REQUEST, ACTION_TYPES.FETCH_EDUCATION_LEVEL_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_EDUCATION_LEVEL_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchDesignationDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.DESIGNATION_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchCategoryDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.CATEGORY_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_CATEGORY_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_CATEGORY_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_CATEGORY_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchEmploymentDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EMPLOYMENT_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EMPLOYMENT_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_EMPLOYMENT_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_EMPLOYMENT_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchSubContractDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.SUB_CONTRACT_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SUB_CONTRACTOR_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_SUB_CONTRACTOR_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_SUB_CONTRACTOR_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchWorkmanRadioButtonApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.WORKMAN_RADIO_BUTTON,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WORKMAN_RADIO_BUTTON_REQUEST, ACTION_TYPES.FETCH_WORKMAN_RADIO_BUTTON_SUCCESS, ACTION_TYPES.FETCH_WORKMAN_RADIO_BUTTON_FAILURE],
            data: query
        }
    };
};

export const fetchEmployeeDetailsApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EMPLOYEE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.EMPLOYEE_DETAILS_BY_ID_REQUEST, ACTION_TYPES.EMPLOYEE_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.EMPLOYEE_DETAILS_BY_ID_FAILURE],
            data: query
        }
    };
};

export const fetchMaritalStatusRadioButtonApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.MARITAL_RADIO_BUTTON,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_MARITAL_STATUS_RADIO_BUTTON_REQUEST, ACTION_TYPES.FETCH_MARITAL_STATUS_RADIO_BUTTON_SUCCESS, ACTION_TYPES.FETCH_MARITAL_STATUS_RADIO_BUTTON_FAILURE],
            data: query
        }
    };
};

export const submitEmployeeApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.POST_EMPLOYEE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_EMPLOYEE_DETAILS_REQUEST, ACTION_TYPES.POST_EMPLOYEE_DETAILS_SUCCESS, ACTION_TYPES.POST_EMPLOYEE_DETAILS_FAILURE], data
        }
    };
};

export const fetchRegistrationNumberByIdApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.REGISTRATION_NUMBER_GET_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_REGISTRATION_NUMBER_BY_ID_REQUEST, ACTION_TYPES.FETCH_REGISTRATION_NUMBER_BY_ID_SUCCESS, ACTION_TYPES.FETCH_REGISTRATION_NUMBER_BY_ID_FAILURE],
            data
        }
    };
};

export const patchEmployeeApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.POST_EMPLOYEE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_EMPLOYEE_DETAILS_REQUEST, ACTION_TYPES.PATCH_EMPLOYEE_DETAILS_SUCCESS, ACTION_TYPES.PATCH_EMPLOYEE_DETAILS_FAILURE], data
        }
    };
};

export const patchRegistrationNumberApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.REGISTRATION_NUMBER_PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_REGISTRATION_NUMBER_REQUEST, ACTION_TYPES.PATCH_REGISTRATION_NUMBER_SUCCESS, ACTION_TYPES.PATCH_REGISTRATION_NUMBER_FAILURE],
            data
        }
    };
};

export const deleteEmployeeDetailsApi = (id) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.DELETE_EMPLOYEE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_EMPLOYEE_DETAILS_REQUEST, ACTION_TYPES.DELETE_EMPLOYEE_DETAILS_SUCCESS, ACTION_TYPES.DELETE_EMPLOYEE_DETAILS_FAILURE]
        }
    };
};

export const fetchBasicFacilitiesByIdApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.BASIC_FACILITIES_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BASIC_FACILITIES_BY_ID_REQUEST, ACTION_TYPES.FETCH_BASIC_FACILITIES_BY_ID_SUCCESS, ACTION_TYPES.FETCH_BASIC_FACILITIES_BY_ID_FAILURE],
            data
        }
    };
};

export const patchBasicFacilitiesApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.BASIC_FACILITIES_PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_BASIC_FACILITIES_REQUEST, ACTION_TYPES.PATCH_BASIC_FACILITIES_SUCCESS, ACTION_TYPES.PATCH_BASIC_FACILITIES_FAILURE],
            data
        }
    };
};

export const fetchGrievanceCommitteeListApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.GRIEVANCE_REDRESSAL_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST_REQUEST, ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST_FAILURE],
            data
        }
    };
};

export const fetchGrievanceCommitteeByIdApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.GRIEVANCE_REDRESSAL_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_BY_ID_REQUEST, ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_BY_ID_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_BY_ID_FAILURE],
            data
        }
    };
};

export const postGrievanceCommitteeApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.GRIEVANCE_REDRESSAL_POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_GRIEVANCE_COMMITTEE_REQUEST, ACTION_TYPES.POST_GRIEVANCE_COMMITTEE_SUCCESS, ACTION_TYPES.POST_GRIEVANCE_COMMITTEE_FAILURE],
            data
        }
    };
};

export const patchGrievanceCommitteeApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.GRIEVANCE_REDRESSAL_POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_REQUEST, ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_SUCCESS, ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_FAILURE],
            data
        }
    };
};

export const fetchNoticeBoardByIdApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.NOTICE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_NOTICE_BOARD_BY_ID_REQUEST, ACTION_TYPES.FETCH_NOTICE_BOARD_BY_ID_SUCCESS, ACTION_TYPES.FETCH_NOTICE_BOARD_BY_ID_FAILURE],
            data
        }
    };
};

export const patchNoticeBoardApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.NOTICE_PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_NOTICE_BOARD_REQUEST, ACTION_TYPES.PATCH_NOTICE_BOARD_SUCCESS, ACTION_TYPES.PATCH_NOTICE_BOARD_FAILURE],
            data
        }
    };
};

export const fetchInternalComplaintCommitteeListApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.INTERNAL_COMPLAINT_COMMITTEE_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_REQUEST, ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_SUCCESS, ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_FAILURE],
            data
        }
    };
};

export const fetchComplaintCommitteeDesignationDropdownApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.INTERNAL_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const fetchInternalComplaintCommitteeByIdApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.INTERNAL_COMPLAINT_COMMITTEE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_REQUEST, ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_SUCCESS, ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_FAILURE],
            data
        }
    };
};

export const postComplaintCommitteeApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.INTERNAL_COMPLAINT_COMMITTEE_SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_COMPLAINT_COMMITTEE_MEMBER_REQUEST, ACTION_TYPES.POST_COMPLAINT_COMMITTEE_MEMBER_SUCCESS, ACTION_TYPES.POST_COMPLAINT_COMMITTEE_MEMBER_FAILURE],
            data
        }
    };
};

export const patchComplaintCommitteeApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.INTERNAL_COMPLAINT_COMMITTEE_SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_MEMBER_REQUEST, ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_MEMBER_SUCCESS, ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_MEMBER_FAILURE],
            data
        }
    };
};

export const fetchWageDetailsApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.WAGE_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_WAGE_DETAILS_LIST_REQUEST, ACTION_TYPES.FETCH_WAGE_DETAILS_LIST_SUCCESS, ACTION_TYPES.FETCH_WAGE_DETAILS_LIST_SUCCESS],
        data
    }
});

export const submitWageDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.WAGE_SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_WAGE_DETAILS_REQUEST, ACTION_TYPES.POST_WAGE_DETAILS_SUCCESS, ACTION_TYPES.POST_WAGE_DETAILS_FAILURE],
            data
        }
    };
};

export const updateWageDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.WAGE_SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_WAGE_DETAILS_REQUEST, ACTION_TYPES.PATCH_WAGE_DETAILS_SUCCESS, ACTION_TYPES.PATCH_WAGE_DETAILS_FAILURE],
            data
        }
    };
};

export const getWageDetailsByIdApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.WAGE_BY_ID.replace(":id", data.id),
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.GET_WAGE_DETAILS_BY_ID_REQUEST, ACTION_TYPES.GET_WAGE_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_WAGE_DETAILS_BY_ID_FAILURE]
    }
});

export const deleteWageDetailsApi = (id) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.DELETE_WAGE_DETAILS.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_WAGE_DETAILS_REQUEST, ACTION_TYPES.DELETE_WAGE_DETAILS_SUCCESS, ACTION_TYPES.DELETE_WAGE_DETAILS_FAILURE]
        }
    };
};


export const fetchEmployeeVerificationListApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.EMPLOYEE_VERIFICATION_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_EMPLOYEE_VERIFICATION_REQUEST, ACTION_TYPES.FETCH_EMPLOYEE_VERIFICATION_SUCCESS, ACTION_TYPES.FETCH_EMPLOYEE_VERIFICATION_FAILURE], data
    }
});

export const submitEmployeeVerificationApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.VERIFICATION_SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_EMPLOYEE_VERIFICATION_REQUEST, ACTION_TYPES.PATCH_EMPLOYEE_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_EMPLOYEE_VERIFICATION_FAILURE], data
        }
    };
};

export const postEmployeeTerminatedProcessApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EMPLOYEE_TERMINATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.POST_EMPLOYEE_TERMINATION_PROCESS_REQUEST, ACTION_TYPES.POST_EMPLOYEE_TERMINATION_PROCESS_SUCCESS, ACTION_TYPES.POST_EMPLOYEE_TERMINATION_PROCESS_FAILURE],
            data
        }
    };
};

export const fetchSiteWageRegisterApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.SITE_WAGE_REGISTER_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_LIST_REQUEST, ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_LIST_SUCCESS, ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_LIST_FAILURE],
        data
    }
});

export const postSiteWageRegisterApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.SITE_WAGE_REGISTER_POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SITE_WAGE_REGISTER_REQUEST, ACTION_TYPES.POST_SITE_WAGE_REGISTER_SUCCESS, ACTION_TYPES.POST_SITE_WAGE_REGISTER_FAILURE],
            data
        }
    };
};

export const fetchSiteWageRegisterEmployeeApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.SITE_WAGE_REGISTER_EMPLOYEE_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_REQUEST, ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_SUCCESS, ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_FAILURE],
        data
    }
});

export const getSiteWageRegisterEmployeeDetailsApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.SITE_WAGE_BY_ID.replace(":id", data.id),
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_REQUEST, ACTION_TYPES.GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_FAILURE]
    }
});

export const patchSiteWageEmployeeDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.SITE_WAGE_REGISTER_EMPLOYEE_PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_SITE_WAGE_EMPLOYEE_DETAILS_REQUEST, ACTION_TYPES.PATCH_SITE_WAGE_EMPLOYEE_DETAILS_SUCCESS, ACTION_TYPES.PATCH_SITE_WAGE_EMPLOYEE_DETAILS_FAILURE],
            data
        }
    };
};

export const getCardCountInWageRegisterApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.CARD_COUNT_IN_WAGE_REGISTER.replace(":id", data.id),
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.GET_CARD_COUNT_IN_WAGE_REGISTER_REQUEST, ACTION_TYPES.GET_CARD_COUNT_IN_WAGE_REGISTER_SUCCESS, ACTION_TYPES.GET_CARD_COUNT_IN_WAGE_REGISTER_FAILURE]
    }
});

export const deleteWageRegisterDetailsApi = (id) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.DELETE_WAGE_REGISTER.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_WAGE_REGISTER_REQUEST, ACTION_TYPES.DELETE_WAGE_REGISTER_SUCCESS, ACTION_TYPES.DELETE_WAGE_REGISTER_FAILURE]
        }
    };
};

export const getWorkplaceAccidentListApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.WORKPLACE_ACCIDENT_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.WORKPLACE_ACCIDENT_DETAILS_LIST_REQUEST, ACTION_TYPES.WORKPLACE_ACCIDENT_DETAILS_LIST_SUCCESS, ACTION_TYPES.WORKPLACE_ACCIDENT_DETAILS_LIST_FAILURE],
            data
        }
    };
};

export const getEmployeeNameDropdownApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EMPLOYEE_NAME_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_EMPLOYEE_NAME_WITH_CODE_REQUEST, ACTION_TYPES.GET_EMPLOYEE_NAME_WITH_CODE_SUCCESS, ACTION_TYPES.GET_EMPLOYEE_NAME_WITH_CODE_FAILURE],
            data
        }
    };
};

export const getEmployeeDetailsByIdApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EMPLOYEE_DETAILS_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_EMPLOYEE_DETAILS_BY_ID_REQUEST, ACTION_TYPES.GET_EMPLOYEE_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_EMPLOYEE_DETAILS_BY_ID_FAILURE],
            data
        }
    };
};

export const fetchFatalDropdownApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.FATAL_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_FATAL_DROPDOWN_REQUEST, ACTION_TYPES.GET_FATAL_DROPDOWN_SUCCESS, ACTION_TYPES.GET_FATAL_DROPDOWN_FAILURE],
            data
        }
    };
};

export const saveWorkplaceAccidentDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.POST_WORKPLACE_ACCIDENT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_WORKPLACE_ACCIDENT_DETAILS_REQUEST, ACTION_TYPES.POST_WORKPLACE_ACCIDENT_DETAILS_SUCCESS, ACTION_TYPES.POST_WORKPLACE_ACCIDENT_DETAILS_FAILURE],
            data
        }
    };
};
export const postEmployeeAttendanceApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EMPLOYEE_ATTENDANCE_POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_EMPLOYEE_ATTENDANCE_REQUEST, ACTION_TYPES.POST_EMPLOYEE_ATTENDANCE_SUCCESS, ACTION_TYPES.POST_EMPLOYEE_ATTENDANCE_FAILURE],
            data
        }
    };
};

export const fetchWorkplaceAccidentDetailsByIdApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.GET_WORKPLACE_ACCIDENT_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_REQUEST, ACTION_TYPES.GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_FAILURE],
            data
        }
    };
};

export const updateWorkplaceAccidentDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.PATCH_WORKPLACE_ACCIDENT_BY_ID,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_DETAILS_REQUEST, ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_DETAILS_SUCCESS, ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_DETAILS_FAILURE],
            data
        }
    };
};
export const getCardCountInAttendanceRegisterApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.CARD_COUNT_IN_ATTENDANCE_REGISTER,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_REQUEST, ACTION_TYPES.GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_SUCCESS, ACTION_TYPES.GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_FAILURE], data
    }
});

export const fetchEmployeeAttendanceListApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.EMPLOYEE_ATTENDANCE_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_EMPLOYEE_ATTENDANCE_LIST_REQUEST, ACTION_TYPES.FETCH_EMPLOYEE_ATTENDANCE_LIST_SUCCESS, ACTION_TYPES.FETCH_EMPLOYEE_ATTENDANCE_LIST_FAILURE], data
    }
});

export const getAttendanceTypeDropdownApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.TYPE_DROPDOWN,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.GET_ATTENDANCE_TYPE_DROPDOWN_REQUEST, ACTION_TYPES.GET_ATTENDANCE_TYPE_DROPDOWN_SUCCESS, ACTION_TYPES.GET_ATTENDANCE_TYPE_DROPDOWN_FAILURE], data
    }
});

export const patchEmployeeAttendanceDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.PATCH_EMPLOYEE_ATTENDANCE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_EMPLOYEE_ATTENDANCE_REQUEST, ACTION_TYPES.PATCH_EMPLOYEE_ATTENDANCE_SUCCESS, ACTION_TYPES.PATCH_EMPLOYEE_ATTENDANCE_FAILURE],
            data
        }
    };
};

export const fetchHarassmentDetailApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.FETCH_HARASSMENT_DETAILS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_HARASSMENT_DETAILS_LIST_REQUEST, ACTION_TYPES.FETCH_HARASSMENT_DETAILS_LIST_SUCCESS, ACTION_TYPES.FETCH_HARASSMENT_DETAILS_LIST_FAILURE],
            data
        }
    };
};

export const submitNoticeRespondentApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.SUBMIT_NOTICE_RESPONDENT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_NOTICE_RESPONDENT_REQUEST, ACTION_TYPES.SUBMIT_NOTICE_RESPONDENT_SUCCESS, ACTION_TYPES.SUBMIT_NOTICE_RESPONDENT_FAILURE],
            data
        }
    };
};

export const submitResponseRespondentApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.SUBMIT_NOTICE_RESPONDENT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_RESPONSE_FROM_RESPONDENT_REQUEST, ACTION_TYPES.SUBMIT_RESPONSE_FROM_RESPONDENT_SUCCESS, ACTION_TYPES.SUBMIT_RESPONSE_FROM_RESPONDENT_FAILURE],
            data
        }
    };
};

export const submitComplaintSettlementApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.SUBMIT_NOTICE_RESPONDENT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_COMPLAINT_SETTLEMENT_REQUEST, ACTION_TYPES.SUBMIT_COMPLAINT_SETTLEMENT_SUCCESS, ACTION_TYPES.SUBMIT_COMPLAINT_SETTLEMENT_FAILURE],
            data
        }
    };
};

export const submitHearingApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.SUBMIT_NOTICE_RESPONDENT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_HEARING_DETAILS_REQUEST, ACTION_TYPES.SUBMIT_HEARING_DETAILS_SUCCESS, ACTION_TYPES.SUBMIT_HEARING_DETAILS_FAILURE],
            data
        }
    };
};

export const getAccordionDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.GET_ACCORDION_BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.ACCORDION_DETAILS_BY_ID_REQUEST, ACTION_TYPES.ACCORDION_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.ACCORDION_DETAILS_BY_ID_FAILURE],
            data
        }
    };
};

export const listRecoveryRegisterApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.RECOVERY_REGISTER_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_RECOVERY_REGISTER_LIST_REQUEST, ACTION_TYPES.FETCH_RECOVERY_REGISTER_LIST_SUCCESS, ACTION_TYPES.FETCH_RECOVERY_REGISTER_LIST_FAILURE],
        data
    }
});

export const getEmployeeDropdownApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EMPLOYEE_DROPDOWN_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EMPLOYEE_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_EMPLOYEE_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_EMPLOYEE_DROPDOWN_FAILURE],
            data
        }
    };
};

export const getRecoveryDropdownApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.RECOVERY_TYPE_DROPDOWN_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_RECOVERY_TYPE_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_RECOVERY_TYPE_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_RECOVERY_TYPE_DROPDOWN_FAILURE],
            data
        }
    };
};


export const getIncidentEmployeeDetailApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.INCIDENT_EMPLOYEE_DETAIL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.INCIDENCE_EMPLOYEE_DETAILS_BY_ID_REQUEST, ACTION_TYPES.INCIDENCE_EMPLOYEE_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.INCIDENCE_EMPLOYEE_DETAILS_BY_ID_FAILURE],
            data: query
        }
    };
};

export const postHarassmentComplaintApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.POST_HARASSMENT_COMPLAINTS,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_HARASSMENT_COMPLAINTS_REQUEST, ACTION_TYPES.POST_HARASSMENT_COMPLAINTS_SUCCESS, ACTION_TYPES.POST_HARASSMENT_COMPLAINTS_FAILURE], data
        }
    };
};

export const postRecoveryRegisterApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.RECOVERY_REGISTER_POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_RECOVERY_DETAILS_REQUEST, ACTION_TYPES.POST_RECOVERY_DETAILS_SUCCESS, ACTION_TYPES.POST_RECOVERY_DETAILS_FAILURE],
            data
        }
    };
};

export const getResponseAccordionApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.GET_ACCORDION_BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.RESPONSE_ACCORDION_BY_ID_REQUEST, ACTION_TYPES.RESPONSE_ACCORDION_BY_ID_SUCCESS, ACTION_TYPES.RESPONSE_ACCORDION_BY_ID_FAILURE],
            data
        }
    };
};

export const getRecoveryDetailApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.RECOVERY_DETAIL_BY_ID.replace(":id", data.id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.RECOVERY_DETAILS_BY_ID_REQUEST, ACTION_TYPES.RECOVERY_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.RECOVERY_DETAILS_BY_ID_FAILURE]
        }
    };
};

export const getComplaintAccordionDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.GET_ACCORDION_BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.COMPLAINT_ACCORDION_BY_ID_REQUEST, ACTION_TYPES.COMPLAINT_ACCORDION_BY_ID_SUCCESS, ACTION_TYPES.COMPLAINT_ACCORDION_BY_ID_FAILURE],
            data
        }
    };
};

export const getHearingAccordionDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.GET_ACCORDION_BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.HEARING_ACCORDION_BY_ID_REQUEST, ACTION_TYPES.HEARING_ACCORDION_BY_ID_SUCCESS, ACTION_TYPES.HEARING_ACCORDION_BY_ID_FAILURE],
            data
        }
    };
};

export const getHarassmentComplaintByIdApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.GET_HARASSMENT_BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.HARASSMENT_COMPLAINT_BY_ID_REQUEST, ACTION_TYPES.HARASSMENT_COMPLAINT_BY_ID_SUCCESS, ACTION_TYPES.HARASSMENT_COMPLAINT_BY_ID_FAILURE]
        }
    };
};

export const getCausedIssueDropdownApi = () => ({
    url: API_URL.LABOUR_MANAGEMENT.CAUSED_ISSUE_DROPDOWN_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_CAUSED_ISSUE_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_CAUSED_ISSUE_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_CAUSED_ISSUE_DROPDOWN_FAILURE]
    }
});

export const getMaternityTypesDropdownApi = () => ({
    url: API_URL.LABOUR_MANAGEMENT.MATERNITY_TYPE_DROPDOWN_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_MATERNITY_TYPE_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_MATERNITY_TYPE_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_MATERNITY_TYPE_DROPDOWN_FAILURE]
    }
});

export const getMaternityDetailApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.MATERNITY_DETAIL_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.MATERNITY_DETAILS_BY_ID_REQUEST, ACTION_TYPES.MATERNITY_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.MATERNITY_DETAILS_BY_ID_FAILURE],
            data: query
        }
    };
};

export const patchNoticeRespondentApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.PATCH_ACCORDION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_NOTICE_RESPONDENT_REQUEST, ACTION_TYPES.PATCH_NOTICE_RESPONDENT_SUCCESS, ACTION_TYPES.PATCH_NOTICE_RESPONDENT_FAILURE],
            data
        }
    };
};

export const patchResponseRespondentApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.PATCH_ACCORDION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_RESPONSE_FROM_RESPONDENT_REQUEST, ACTION_TYPES.PATCH_RESPONSE_FROM_RESPONDENT_SUCCESS, ACTION_TYPES.PATCH_REGISTRATION_NUMBER_FAILURE],
            data
        }
    };
};

export const patchComplaintSettlementApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.PATCH_ACCORDION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_COMPLAINT_SETTLEMENT_REQUEST, ACTION_TYPES.PATCH_COMPLAINT_SETTLEMENT_SUCCESS, ACTION_TYPES.PATCH_COMPLAINT_SETTLEMENT_FAILURE], data
        }
    };
};

export const listMaternityLeaveApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.MATERNITY_LEAVE_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_MATERNITY_LEAVE_LIST_REQUEST, ACTION_TYPES.FETCH_MATERNITY_LEAVE_LIST_SUCCESS, ACTION_TYPES.FETCH_MATERNITY_LEAVE_LIST_FAILURE],
        data
    }
});

export const postMaternityRegisterApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.MATERNITY_REGISTER_POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_MATERNITY_DETAILS_REQUEST, ACTION_TYPES.POST_MATERNITY_DETAILS_SUCCESS, ACTION_TYPES.POST_MATERNITY_DETAILS_FAILURE],
            data
        }
    };
};

export const patchHearingApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.PATCH_ACCORDION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_HEARING_DETAILS_REQUEST, ACTION_TYPES.PATCH_HEARING_DETAILS_SUCCESS, ACTION_TYPES.PATCH_HEARING_DETAILS_FAILURE], data
        }
    };
};

export const patchRecoveryRegisterApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.RECOVERY_REGISTER_POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_RECOVERY_DETAILS_REQUEST, ACTION_TYPES.PATCH_RECOVERY_DETAILS_SUCCESS, ACTION_TYPES.PATCH_RECOVERY_DETAILS_FAILURE],
            data
        }
    };
};

export const patchHarassmentComplaintApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.PATCH_HARASSMENT_DETAILS,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_HARASSMENT_COMPLAINTS_REQUEST, ACTION_TYPES.PATCH_HARASSMENT_COMPLAINTS_SUCCESS, ACTION_TYPES.PATCH_HARASSMENT_COMPLAINTS_FAILURE], data
        }
    };
};

export const patchMaternityDetailApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.MATERNITY_REGISTER_POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_MATERNITY_DETAILS_REQUEST, ACTION_TYPES.PATCH_MATERNITY_DETAILS_SUCCESS, ACTION_TYPES.PATCH_MATERNITY_DETAILS_FAILURE],
            data
        }
    };
};

export const getEmployeeAttendanceByIdApi = (data) => ({
    url: API_URL.LABOUR_MANAGEMENT.ATTENDANCE_BY_ID,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_REQUEST, ACTION_TYPES.GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_SUCCESS, ACTION_TYPES.GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_FAILURE], data
    }
});

export const deleteProjectSiteDetailsApi = (id) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.DELETE_SITE_DETAILS.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_PROJECT_SITE_DETAILS_REQUEST, ACTION_TYPES.DELETE_PROJECT_SITE_DETAILS_SUCCESS, ACTION_TYPES.DELETE_PROJECT_SITE_DETAILS_FAILURE]
        }
    };
};

export const getVerificationStatusApi = (query = {}) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.VERIFICATION_STATUS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_VERIFICATION_STATUS_DROPDOWN_REQUEST, ACTION_TYPES.GET_VERIFICATION_STATUS_DROPDOWN_SUCCESS, ACTION_TYPES.GET_VERIFICATION_STATUS_DROPDOWN_FAILURE],
            data: query
        }
    };
};

export const patchWageVerificationApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.WAGE_VERIFICATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_WAGE_VERIFICATION_REQUEST, ACTION_TYPES.PATCH_WAGE_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_WAGE_VERIFICATION_FAILURE],
            data
        }
    };
};

export const fetchEventsTrackedInMinWageApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EVENTS_TRACKED_MIN_WAGE.replace(":id", data?.id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EVENTS_TRACKED_MINIMUM_WAGE_REQUEST, ACTION_TYPES.FETCH_EVENTS_TRACKED_MINIMUM_WAGE_SUCCESS, ACTION_TYPES.FETCH_EVENTS_TRACKED_MINIMUM_WAGE_FAILURE]
        }
    };
};

export const patchBasicFacilitiesVerificationApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.BASIC_FACILITY_VERIFICATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_BASIC_FACILITIES_VERIFICATION_REQUEST, ACTION_TYPES.PATCH_BASIC_FACILITIES_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_BASIC_FACILITIES_VERIFICATION_FAILURE],
            data
        }
    };
};

export const fetchEventsTrackedInBasicFacilitiesApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EVENTS_TRACKED_BASIC_FACILITY.replace(":id", data?.id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EVENTS_TRACKED_BASIC_FACILITIES_REQUEST, ACTION_TYPES.FETCH_EVENTS_TRACKED_BASIC_FACILITIES_SUCCESS, ACTION_TYPES.FETCH_EVENTS_TRACKED_BASIC_FACILITIES_FAILURE]
        }
    };
};

export const patchWageDetailsVerificationApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.WAGE_DETAILS_VERIFICATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_WAGE_DETAILS_VERIFICATION_REQUEST, ACTION_TYPES.PATCH_WAGE_DETAILS_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_WAGE_DETAILS_VERIFICATION_FAILURE],
            data
        }
    };
};

export const fetchEventsTrackedInWageDetailsApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EVENTS_TRACKED_WAGE_DETAILS.replace(":id", data?.id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_DETAILS_REQUEST, ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_DETAILS_SUCCESS, ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_DETAILS_FAILURE]
        }
    };
};

export const patchWageRegisterVerificationApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.WAGE_REGISTER_VERIFICATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_WAGE_REGISTER_VERIFICATION_REQUEST, ACTION_TYPES.PATCH_WAGE_REGISTER_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_WAGE_REGISTER_VERIFICATION_FAILURE],
            data
        }
    };
};

export const fetchEventsTrackedInWageRegisterApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EVENTS_TRACKED_WAGE_REGISTER.replace(":id", data?.id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_REGISTER_REQUEST, ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_REGISTER_SUCCESS, ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_REGISTER_FAILURE]
        }
    };
};

export const patchAttendanceRegisterVerificationApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.ATTENDANCE_REGISTER_VERIFICATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_ATTENDANCE_REGISTER_VERIFICATION_REQUEST, ACTION_TYPES.PATCH_ATTENDANCE_REGISTER_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_ATTENDANCE_REGISTER_VERIFICATION_FAILURE],
            data
        }
    };
};

export const fetchEventsTrackedInAttendanceRegisterApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EVENTS_TRACKED_ATTENDANCE_REGISTER.replace(":id", data?.id).replace(":date", data?.entryDate),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_REQUEST, ACTION_TYPES.FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_SUCCESS, ACTION_TYPES.FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_FAILURE]
        }
    };
};

export const patchMaternityLeaveVerificationApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.MATERNITY_VERIFICATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_MATERNITY_LEAVE_VERIFICATION_REQUEST, ACTION_TYPES.PATCH_MATERNITY_LEAVE_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_MATERNITY_LEAVE_VERIFICATION_FAILURE],
            data
        }
    };
};

export const fetchEventsTrackedInMaternityApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EVENTS_TRACKED_MATERNITY.replace(":id", data?.id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_REQUEST, ACTION_TYPES.FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_SUCCESS, ACTION_TYPES.FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_FAILURE]
        }
    };
};

export const patchRecoveryRegisterVerificationApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.RECOVERY_VERIFICATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_RECOVERY_REGISTER_VERIFICATION_REQUEST, ACTION_TYPES.PATCH_RECOVERY_REGISTER_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_RECOVERY_REGISTER_VERIFICATION_FAILURE],
            data
        }
    };
};

export const fetchEventsTrackedInRecoveryRegisterApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EVENTS_TRACKED_RECOVERY.replace(":id", data?.id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_REQUEST, ACTION_TYPES.FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_SUCCESS, ACTION_TYPES.FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_FAILURE]
        }
    };
};

export const patchWorkPlaceAccidentVerificationApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.WORKPLACE_VERIFICATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_VERIFICATION_REQUEST, ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_VERIFICATION_FAILURE],
            data
        }
    };
};

export const fetchEventsTrackedInWorkplaceAccidentApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EVENTS_TRACKED_WORKPLACE.replace(":id", data?.id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_REQUEST, ACTION_TYPES.FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_SUCCESS, ACTION_TYPES.FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_FAILURE]
        }
    };
};

export const patchHarassmentComplaintVerificationApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.HARASSMENT_VERIFICATION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_HARASSMENT_COMPLAINT_VERIFICATION_REQUEST, ACTION_TYPES.PATCH_HARASSMENT_COMPLAINT_VERIFICATION_SUCCESS, ACTION_TYPES.PATCH_HARASSMENT_COMPLAINT_VERIFICATION_FAILURE],
            data
        }
    };
};

export const fetchEventsTrackedInHarassmentComplaintApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.EVENTS_TRACKED_HARASSMENT.replace(":id", data?.id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_REQUEST, ACTION_TYPES.FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_SUCCESS, ACTION_TYPES.FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_FAILURE]
        }
    };
};

export const patchDpmuRemarksApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.DPMU_REMARKS_NOTICE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_NOTICE_BOARD_DPMU_REMARKS_REQUEST, ACTION_TYPES.PATCH_NOTICE_BOARD_DPMU_REMARKS_SUCCESS, ACTION_TYPES.PATCH_NOTICE_BOARD_DPMU_REMARKS_FAILURE],
            data
        }
    };
};

export const patchGrievanceCommitteeDpmuRemarksApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.DPMU_REMARKS_GRIEVANCE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_REQUEST, ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_SUCCESS, ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_FAILURE],
            data
        }
    };
};

export const patchComplaintCommitteeDpmuRemarksApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.DPMU_REMARKS_COMPLAINT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_REQUEST, ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_SUCCESS, ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_FAILURE],
            data
        }
    };
};

export const patchEmployeeDpmuRemarksApi = (data) => {
    return {
        url: API_URL.LABOUR_MANAGEMENT.DPMU_REMARKS_EMPLOYEE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_EMPLOYEE_DPMU_REMARKS_REQUEST, ACTION_TYPES.PATCH_EMPLOYEE_DPMU_REMARKS_SUCCESS, ACTION_TYPES.PATCH_EMPLOYEE_DPMU_REMARKS_FAILURE],
            data
        }
    };
};
