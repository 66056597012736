export const API_URL = {
    COMMON: {
        COMPLAINT_CATEGORY: "grm/api/auth/grm/complaint-categories/list/all",
        COMPLAINT_TYPE: "grm/api/auth/grm/complaint-type/list/all",
        COMPLAINT_MODE: "grm/api/auth/grm/complaint-mode/list/all",
        CONCERNED_ORGANIZATION: "admin/api/auth/master/lsgi-details/list/all",
        SELECT_GENDER_DROPDOWN: "admin/api/auth/master/gender/list/all",
        ORGANIZATION_TYPE: "admin/api/auth/master/lsgi-type/list/all",
        WARD: "admin/api/auth/master/ward/list/all",
        STATUS_LIST: "grm/api/auth/grm/complaint-status/list/all"
    },
    COMPLAINT: {
        LIST_COMPLAINT: "testList",
        SUBMIT_COMPLAINT: "testSubmit",
        DELETE_COMPLAINT: "testDelete/:id",
        UPDATE_FORM: "testSubmit/:id",
        LIST: "grm/api/auth/grm/complaints/list/all",
        ADD: "grm/api/auth/grm/complaints/register",
        DELETE: "grm/api/auth/grm/complaints/delete/:id",
        UPDATE: "grm/api/auth/grm/complaints/update",
        BY_ID: "grm/api/auth/grm/complaints/list/by-id",
        SUBMIT_STATUS: "grm/api/auth/grm/complaint-status/save",
        SUBMIT_TYPE: "grm/api/auth/grm/complaint-type/save",
        GET_UPLOADED_FILE_BY_ID: "resource/api/auth/multimedia/download",
        DELETE_FILE_BY_ID: "grm/api/auth/grm/complaints/delete/:id/user-attachment/:file_id",
        HISTORY: "fetchHistory",
        UPDATE_ACTION: "grm/api/auth/grm/complaints/actions/save",
        LIST_ACTION: "grm/api/auth/grm/complaint-actions/list/all",
        DELETE_ACTION_FILE_BY_ID: "grm/api/auth/grm/complaints/delete/:id/officer-attachment/:file_id",
        USER_LIST: "admin/api/auth/idm/user/list/all",
        REASSIGN: "grm/api/auth/grm/complaints/reassign",
        FILTER: "grm/api/auth/grm/complaints/filter-list",
        REJECT: "grm/api/auth/grm/complaints/reject",
        RESOLVE: "grm/api/auth/grm/complaints/resolve",
        LIST_MY_APPROVAL: "grm/api/auth/grm/complaints/approvals/list/all",
        MY_APPROVAL_DETAILS: "grm/api/auth/grm/complaints/approvals/list/all",
        PROCESS_MY_APPROVAL: "grm/api/auth/grm/complaints/approvals/process",
        ADD_REMARK: "grm/api/auth/grm/complaints/reopen",
        ADD_CLOSE_FORM: "grm/api/auth/grm/complaints/close",
        ADD_WITHDRAW: "grm/api/auth/grm/complaints/withdraw",
        GRIEVANCE_SUMMARY: "report/api/auth/reports/complaints/table-view/list",
        GRIEVANCE_LISTING: "report/api/auth/reports/complaints-summary/table-view/list",
        ESCALATION_REPORT: "report/api/auth/reports/complaints-escalation/table-view/list",
        PENDING_REPORT: "report/api/auth/reports/complaints-pending/table-view/list",
        REOPEN_COMPLAINT: "report/api/auth/reports/complaints-reopened/table-view/list",
        TOP_GRIEVANCE_CATEGORY: "report/api/auth/reports/complaints-category-wise/table-view/list",
        MOBILE_NO: "grm/api/auth/grm/complaints/filter-list"
    },
    REGISTER: {
        CREATE: "admin/api/auth/idm/user/register",
        DELETE: "admin/api/auth/idm/user/delete/:id",
        PROFILE_BY_ID: "admin/api/auth/idm/user/list/all",
        LIST: "admin/api/auth/idm/user/list/all",
        EDIT_PROFILE: "admin/api/auth/idm/user/register",

        GENDER_DROPDOWN: "admin/api/auth/master/gender/list/all",
        DISTRICT_DROPDOWN: "admin/api/auth/master/district/list/all",
        MUNICIPALITY_DROPDOWN: "admin/api/auth/master/ulb-details/list/all",
        LSGI_TYPE_DROPDOWN: "admin/api/auth/master/lsgi-type/list/all",
        LSGI_DROPDOWN: "admin/api/auth/master/lsgi-details/list/all",
        WARD_DROPDOWN: "admin/api/auth/master/ward/list/all",
        ORGANIZATION_DROPDOWN: "admin/api/auth/master/training-organization/list/all",
        DESIGNATION_DROPDOWN: "admin/api/auth/master/designation/list/all?dropdown=true",
        LOGIN_TYPE_DROPDOWN: "admin/api/auth/master/user-login-type/list/all",
        USER_TYPE_DROPDOWN: "admin/api/auth/master/user-type/list/all",
        SIGN_UP: "admin/api/no-auth/idm/user/register",
        CHECK_AVAILABILITY: "admin/api/no-auth/idm/user/check-user-name",
        FILTER_LIST: "admin/api/auth/idm/user/profile-filter-list",
        UPLOAD_PROFILE_IMAGE: "admin/api/auth/idm/user/:userId/biometric",
        FIRST_NAME: "admin/api/auth/idm/user/filter-list",
        CHANGE_PASSWORD: "admin/api/auth/idm/user/change-password",
        DOWNLOAD_USER_REPORT: "report/api/auth/reports/users/list",
        ROLES_DROP_DOWN: "admin/api/auth/idm/roles/list/all?dropDown=true"
    },
    DISTRICT: { LIST_DISTRICT: "admin/api/auth/master/district/list/all" },
    REPORT: {
        COMPLAINT_STATUS: "report/api/auth/reports/complaints-status/card",
        COMPLAINT_STATUS_DISTRICT: "report/api/auth/reports/complaints/districts/status-wise/count",
        COMPLAINT_STATUS_CATEGORY: "report/api/auth/reports/complaints/districts/category-wise/count"
    }
};
