import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_SECONDARY_TRANSPORTATION_DETAILS: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_TRANSPORTATION_DETAILS`,
    FETCH_SECONDARY_TRANSPORTATION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_TRANSPORTATION_DETAILS_REQUEST`,
    FETCH_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS`,
    FETCH_SECONDARY_TRANSPORTATION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_TRANSPORTATION_DETAILS_FAILURE`,

    MODE_OF_LOADING_DROPDOWN: `${STATE_REDUCER_KEY}/MODE_OF_LOADING_DROPDOWN`,
    MODE_OF_LOADING_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/MODE_OF_LOADING_DROPDOWN_REQUEST`,
    MODE_OF_LOADING_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/MODE_OF_LOADING_DROPDOWN_SUCCESS`,
    MODE_OF_LOADING_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/MODE_OF_LOADING_DROPDOWN_FAILURE`,

    GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID`,
    GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_REQUEST`,
    GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_SUCCESS`,
    GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID_FAILURE`,

    POST_SECONDARY_TRANSPORTATION_DETAILS: `${STATE_REDUCER_KEY}/POST_SECONDARY_TRANSPORTATION_DETAILS`,
    POST_SECONDARY_TRANSPORTATION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_SECONDARY_TRANSPORTATION_DETAILS_REQUEST`,
    POST_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS`,
    POST_SECONDARY_TRANSPORTATION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_SECONDARY_TRANSPORTATION_DETAILS_FAILURE`,

    PATCH_SECONDARY_TRANSPORTATION_DETAILS: `${STATE_REDUCER_KEY}/PATCH_SECONDARY_TRANSPORTATION_DETAILS`,
    PATCH_SECONDARY_TRANSPORTATION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SECONDARY_TRANSPORTATION_DETAILS_REQUEST`,
    PATCH_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS`,
    PATCH_SECONDARY_TRANSPORTATION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SECONDARY_TRANSPORTATION_DETAILS_FAILURE`,

    DELETE_SECONDARY_TRANSPORTATION_DETAILS: `${STATE_REDUCER_KEY}/DELETE_SECONDARY_TRANSPORTATION_DETAILS`,
    DELETE_SECONDARY_TRANSPORTATION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_SECONDARY_TRANSPORTATION_DETAILS_REQUEST`,
    DELETE_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS`,
    DELETE_SECONDARY_TRANSPORTATION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_SECONDARY_TRANSPORTATION_DETAILS_FAILURE`,

    DOWNLOAD_SECONDARY_TRANSPORTATION_DETAILS: `${STATE_REDUCER_KEY}/DOWNLOAD_SECONDARY_TRANSPORTATION_DETAILS`,
    DOWNLOAD_SECONDARY_TRANSPORTATION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_SECONDARY_TRANSPORTATION_DETAILS_REQUEST`,
    DOWNLOAD_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_SECONDARY_TRANSPORTATION_DETAILS_SUCCESS`,
    DOWNLOAD_SECONDARY_TRANSPORTATION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_SECONDARY_TRANSPORTATION_DETAILS_FAILURE`
};

export const getSecondaryTransportation = createAction(ACTION_TYPES.FETCH_SECONDARY_TRANSPORTATION_DETAILS);
export const modeOfLoadingDropdown = createAction(ACTION_TYPES.MODE_OF_LOADING_DROPDOWN);
export const getSecondaryTransportationById = createAction(ACTION_TYPES.GET_SECONDARY_TRANSPORTATION_DETAILS_BY_ID);
export const postSecondaryTransportation = createAction(ACTION_TYPES.POST_SECONDARY_TRANSPORTATION_DETAILS);
export const patchSecondaryTransportation = createAction(ACTION_TYPES.PATCH_SECONDARY_TRANSPORTATION_DETAILS);
export const deleteSecondaryTransportation = createAction(ACTION_TYPES.DELETE_SECONDARY_TRANSPORTATION_DETAILS);
export const downloadSecondaryTransportation = createAction(ACTION_TYPES.DOWNLOAD_SECONDARY_TRANSPORTATION_DETAILS);


