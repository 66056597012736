import { I18n } from "common/components";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { confirmGapAnalysisApi, deleteFileById, deleteGapAnalysis, draftPlanByIdApi, draftPlanGet, listDraftPlan, saveDraftPlan, updateDraftPlanApi } from "./api";
import { setCommonTableData } from "utils/tableUtils";
import { actions as commonActions } from "../../common/slice";
import { actions as sliceActons } from "../../common/slice";
import { listByIdSWMPlanApi } from "../swmPlan/api";

const formatData = (rest) => {
    const { attachmentIds, draftSwmPlanId, draftSwmPlanName, remarks, swmPlanId } = rest;
    const data = { swmPlanId: Number(swmPlanId), draftSwmPlanId, draftSwmPlanName, attachmentIds, remarks };
    return data;
};

export function* submitDraftPlan(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, saveDraftPlan, formatData(rest));
    const responseAction = yield take([ACTION_TYPES.SUBMIT_DRAFT_PLAN_SUCCESS, ACTION_TYPES.SUBMIT_DRAFT_PLAN_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_DRAFT_PLAN_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("draft_plan") }) }));
    }
}

export function* listDraftPlanForm({ payload }) {
    let updatedPayload = { pageNo: payload?.pageNo, pageSize: payload?.pageSize, swmPlanId: payload?.id };
    yield fork(handleAPIRequest, listDraftPlan, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_DRAFT_PLAN_SUCCESS, ACTION_TYPES.LIST_DRAFT_PLAN_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* updateDraftPlan(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, updateDraftPlanApi, formatData(rest));
    const responseAction = yield take([ACTION_TYPES.UPDATE_DRAFT_PLAN_SUCCESS, ACTION_TYPES.UPDATE_DRAFT_PLAN_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_DRAFT_PLAN_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("draft_plan") }) }));
        yield call(handleAPIRequest, draftPlanByIdApi, rest?.swmPlanId);
        yield fork(handleAPIRequest, listDraftPlan, { pageNo: 0, pageSize: 5, swmPlanId: rest?.swmPlanId });
        const COMPARISON_TYPES = [ACTION_TYPES.LIST_DRAFT_PLAN_SUCCESS, ACTION_TYPES.LIST_DRAFT_PLAN_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES);
    }
}

export function* deleteDraftPlanForm({ payload }) {
    yield fork(handleAPIRequest, deleteGapAnalysis, payload.id);
    const response = yield take([ACTION_TYPES.DELETE_DRAFT_PLAN_SUCCESS, ACTION_TYPES.DELETE_DRAFT_PLAN_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_DRAFT_PLAN_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("draft_plan") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let updatedPayload = { pageNo: 0, pageSize: 5 };
        yield fork(handleAPIRequest, listDraftPlan, updatedPayload, Number(payload.swmPlanId));
        const COMPARISON_TYPES = [ACTION_TYPES.LIST_DRAFT_PLAN_SUCCESS, ACTION_TYPES.LIST_DRAFT_PLAN_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES);
    }
}

export function* draftPlanGetById({ payload: id }) {
    yield call(handleAPIRequest, draftPlanByIdApi, id);
}

export function* draftPlanById({ payload: id }) {
    yield call(handleAPIRequest, draftPlanGet, id);
}

export function* deleteFileFn({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, deleteFileById, id, fileId);
}

export function* confirmDraftPlan({ payload }) {
    yield fork(handleAPIRequest, confirmGapAnalysisApi, payload);
    const response = yield take([ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS, ACTION_TYPES.CONFIRM_FORM_SUBMIT_FAILURE]);
    if (response.type === ACTION_TYPES.CONFIRM_FORM_SUBMIT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("draft_plan") }) }));
        const { swmPlanId } = payload;
        yield call(handleAPIRequest, listByIdSWMPlanApi, swmPlanId);
        yield put(sliceActons.moveTabUp());
    }
}

export default function* registerSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_DRAFT_PLAN, submitDraftPlan),
        takeLatest(ACTION_TYPES.UPDATE_DRAFT_PLAN, updateDraftPlan),
        takeLatest(ACTION_TYPES.DELETE_DRAFT_PLAN, deleteDraftPlanForm),
        takeLatest(ACTION_TYPES.LIST_DRAFT_PLAN, listDraftPlanForm),
        takeLatest(ACTION_TYPES.DRAFT_PLAN_BY_ID, draftPlanGetById),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFileFn),
        takeLatest(ACTION_TYPES.LIST_DRAFT_PLAN_BY_ID, draftPlanById),
        takeLatest(ACTION_TYPES.CONFIRM_FORM_SUBMIT, confirmDraftPlan)
    ]);
}
