import { I18n, Components } from "../../../common/components";
import { Link as MUILink } from "@mui/material";


const { Grid, Typography } = Components;
const listStyle = {
    px: 2,
    borderRight: "1px solid #009992",
    color: "#fff",
    fontSize: "12px",
    display: {
        xs: "none",
        md: "block"
    }
};

const Footer = () => {
    return (
        <Grid sx={{ display: { xs: "none", md: "flex" }, justifyContent: "end", width: "100%", pr: { md: "80px", sm: "40px", xs: "20px" }, color: "white.main", py: 2, backgroundColor: "secondary.main" }}>
            <MUILink href="https://kswmp.org/copyright-policy/" target="_blank" rel="noopener noreferrer" sx={listStyle} > {I18n("terms_of_service")} </MUILink>
            <MUILink href="https://kswmp.org/copyright-policy/" target="_blank" rel="noopener noreferrer" sx={listStyle} > {I18n("privacy_policy")} </MUILink>
            <Typography sx={{ pl: 2, fontSize: "12px" }}>{I18n("copyright")}</Typography>
        </Grid >
    );
};

export default Footer;
