import { put, all, takeLatest, call, select } from "redux-saga/effects";

import { ACTION_TYPES, setAge, setUsers } from "./actions";
import { testAPICall, testTableAPICall } from "./api";
import { handleAPIRequest } from "../../../utils/http";
import { errorNotify } from "utils/notificationUtils";
import { actions as sliceActions } from "./slice";
import { getSelectedRows, getTableProps } from "./selectors";


export function* setUserAge(action) {
    let age = action.payload;
    yield put(setAge(action.payload));
    yield call(handleAPIRequest, testAPICall, { age });
}

export function* fetchUserDetails(action) {
    let age = action.payload;
    yield call(handleAPIRequest, testAPICall, { age });
}
export function* fetchUsers() {
    yield put(sliceActions.setLoading());
    let tableProps = yield select(getTableProps);
    try {
        const response = yield call(testTableAPICall, tableProps);
        yield put(setUsers(response));
    } catch (err) {
        yield put(errorNotify({ title: err.name, message: err?.message }));
        yield put(sliceActions.setError(err.message));

    }

}
export function* assignUser() {
    let selectedRows = yield select(getSelectedRows);
    yield call(handleAPIRequest, testAPICall, { selectedRows });
}

export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_USER_DETAILS, fetchUserDetails),
        takeLatest(ACTION_TYPES.INITIATE_USER_AGE_CHANGE, setUserAge),
        takeLatest(ACTION_TYPES.FETCH_USERS, fetchUsers),
        takeLatest(ACTION_TYPES.ASSIGN_USER, assignUser)
    ]);
}
