import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

  FETCH_WARD_SABHA_LIST: `${STATE_REDUCER_KEY}/FETCH_WARD_SABHA_LIST`,
  FETCH_WARD_SABHA_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_SABHA_LIST_REQUEST`,
  FETCH_WARD_SABHA_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_SABHA_LIST_SUCCESS`,
  FETCH_WARD_SABHA_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_SABHA_LIST_FAILURE`,

  FETCH_WARD_SABHA_BY_ID: `${STATE_REDUCER_KEY}/FETCH_WARD_SABHA_BY_ID`,
  FETCH_WARD_SABHA_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_SABHA_BY_ID_REQUEST`,
  FETCH_WARD_SABHA_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_SABHA_BY_ID_SUCCESS`,
  FETCH_WARD_SABHA_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_SABHA_BY_ID_FAILURE`,

  SAVE_WARD_SABHA: `${STATE_REDUCER_KEY}/SAVE_WARD_SABHA`,
  SAVE_WARD_SABHA_REQUEST: `${STATE_REDUCER_KEY}/SAVE_WARD_SABHA_REQUEST`,
  SAVE_WARD_SABHA_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_WARD_SABHA_SUCCESS`,
  SAVE_WARD_SABHA_FAILURE: `${STATE_REDUCER_KEY}/SAVE_WARD_SABHA_FAILURE`,

  UPDATE_WARD_SABHA: `${STATE_REDUCER_KEY}/UPDATE_WARD_SABHA`,
  UPDATE_WARD_SABHA_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_WARD_SABHA_REQUEST`,
  UPDATE_WARD_SABHA_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_WARD_SABHA_SUCCESS`,
  UPDATE_WARD_SABHA_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_WARD_SABHA_FAILURE`,

  DELETE_WARD_SABHA: `${STATE_REDUCER_KEY}/DELETE_WARD_SABHA`,
  DELETE_WARD_SABHA_REQUEST: `${STATE_REDUCER_KEY}/DELETE_WARD_SABHA_REQUEST`,
  DELETE_WARD_SABHA_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_WARD_SABHA_SUCCESS`,
  DELETE_WARD_SABHA_FAILURE: `${STATE_REDUCER_KEY}/DELETE_WARD_SABHA_FAILURE`,

  COMPLETE_WARD_SABHA: `${STATE_REDUCER_KEY}/COMPLETE_WARD_SABHA`,
  COMPLETE_WARD_SABHA_REQUEST: `${STATE_REDUCER_KEY}/COMPLETE_WARD_SABHA_REQUEST`,
  COMPLETE_WARD_SABHA_SUCCESS: `${STATE_REDUCER_KEY}/COMPLETE_WARD_SABHA_SUCCESS`,
  COMPLETE_WARD_SABHA_FAILURE: `${STATE_REDUCER_KEY}/COMPLETE_WARD_SABHA_FAILURE`,

  FETCH_WARD_LIST: `${STATE_REDUCER_KEY}/FETCH_WARD_LIST`,
  FETCH_WARD_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WARD_LIST_REQUEST`,
  FETCH_WARD_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WARD_LIST_SUCCESS`,
  FETCH_WARD_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WARD_LIST_FAILURE`,

  FILTER_WARD_LIST: `${STATE_REDUCER_KEY}/FILTER_WARD_LIST`,
  FILTER_WARD_LIST_REQUEST: `${STATE_REDUCER_KEY}/FILTER_WARD_LIST_REQUEST`,
  FILTER_WARD_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_WARD_LIST_SUCCESS`,
  FILTER_WARD_LIST_FAILURE: `${STATE_REDUCER_KEY}/FILTER_WARD_LIST_FAILURE`,

  FETCH_ENV_SAFEGUARD_LIST: `${STATE_REDUCER_KEY}/FETCH_ENV_SAFEGUARD_LIST`,
  FETCH_ENV_SAFEGUARD_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ENV_SAFEGUARD_LIST_REQUEST`,
  FETCH_ENV_SAFEGUARD_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ENV_SAFEGUARD_LIST_SUCCESS`,
  FETCH_ENV_SAFEGUARD_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ENV_SAFEGUARD_LIST_FAILURE`


};

export const getWardSabhaList = createAction(ACTION_TYPES.FETCH_WARD_SABHA_LIST);
export const getWardSabhaListById = createAction(ACTION_TYPES.FETCH_WARD_SABHA_BY_ID);
export const saveWardSabha = createAction(ACTION_TYPES.SAVE_WARD_SABHA);
export const updateWardSabha = createAction(ACTION_TYPES.UPDATE_WARD_SABHA);
export const deleteWardSabha = createAction(ACTION_TYPES.DELETE_WARD_SABHA);
export const completeWardSabha = createAction(ACTION_TYPES.COMPLETE_WARD_SABHA);
export const getWardName = createAction(ACTION_TYPES.FETCH_WARD_LIST);
export const fiterWardList = createAction(ACTION_TYPES.FILTER_WARD_LIST);
export const fetchEnvList = createAction(ACTION_TYPES.FETCH_ENV_SAFEGUARD_LIST);
