export const API_URL = {
    SECONDARY_COLLECTION_AGENCY: {
        WOMEN_GROUP_DROPDOWN: "admin/api/auth/opm/index-choice/list/all",
        LIST: "admin/api/auth/opm/secondary-collection/list/all",
        SAVE: "admin/api/auth/opm/secondary-collection/save",
        BY_ID: "admin/api/auth/opm/secondary-collection/list/by-id/:id",
        DELETE: "admin/api/auth/opm/secondary-collection/soft-delete/:id",
        AGENCY_NAME_DROPDOWN: "admin/api/auth/opm/secondary-collection/list/all?dropDown=true "
    },
    PRIMARY_COLLECTION_AGENCY: {
        LIST: "admin/api/auth/opm/primary-collection/list/all",
        WOMEN_GROUP: "admin/api/auth/opm/index-choice/list/all",
        POST: "admin/api/auth/opm/primary-collection/save",
        BY_ID: "admin/api/auth/opm/primary-collection/list/by-id/:id",
        DELETE: "admin/api/auth/opm/primary-collection/soft-delete/:id",
        AGENCY_NAME: "admin/api/auth/opm/primary-collection/list/all?dropDown=true"
    },
    WASTE_PROCESSING: {
        LIST: "admin/api/auth/opm/waste-processing-site/list/all",
        SAVE: "admin/api/auth/opm/waste-processing-site/save",
        BY_ID: "admin/api/auth/opm/waste-processing-site/list/by-id/:id",
        DELETE: "admin/api/auth/opm/waste-processing-site/soft-delete/:id",
        SITE_NAME_DROPDOWN: "admin/api/auth/opm/waste-processing-site/list/all?dropDown=true"
    },
    MCF_RRF: {
        LIST: "admin/api/auth/opm/mcf-rrf-details/list/all",
        FORWARD_LINKING: "admin/api/auth/opm/index-company/list/all",
        POST: "admin/api/auth/opm/mcf-rrf-details/save",
        GET_BY_ID: "admin/api/auth/opm/mcf-rrf-details/list/by-id/:id",
        DELETE: "admin/api/auth/opm/mcf-rrf-details/soft-delete/:id",
        TYPE: "admin/api/auth/opm/index-mcf-rrf-names/list/all",
        MCF_RRF_NAME: "admin/api/auth/opm/mcf-rrf-details/list/all?dropDown=true"
    },
    DISPOSAL_SITE_CITY: {
        LIST: "admin/api/auth/opm/citylevel-disposal-site-details/list/all",
        GET_BY_ID: "admin/api/auth/opm/citylevel-disposal-site-details/list/by-id/:id",
        POST: "admin/api//auth/opm/citylevel-disposal-site-details/save",
        DELETE: "admin/api/auth/opm/citylevel-disposal-site-details/soft-delete/:id",
        DISPOSAL_SITE_NAME: "admin/api/auth/opm/index-company/list/all",
        NATURE_OF_AREA: "admin/api/auth/opm/index-zone-types/list/all"
    },
    SOURCE_FOR_BDW: {
        LIST: "admin/api/auth/opm/treatment-at-source-bdw/list/all",
        WARD: "admin/api/auth/master/ward/list/all?dropDown=true",
        POST: "admin/api/auth/opm/treatment-at-source-bdw/save",
        GET_BY_ID: "admin/api/auth/opm/treatment-at-source-bdw/list/by-id/:id",
        DELETE: "admin/api/auth/opm/treatment-at-source-bdw/soft-delete/:id",
        DOWNLOAD: "report/api/auth/opm/reports/treatment-at-source-for-bdw/report/list"
    },
    PRIMARY_COLLECTION_BDW: {
        LIST: "admin/api/auth/opm/primary-collection-bdw/list/all",
        POST: "admin/api/auth/opm/primary-collection-bdw/save",
        BY_ID: "admin/api/auth/opm/primary-collection-bdw/list/by-id/:id",
        DELETE: "admin/api/auth/opm/primary-collection-bdw/soft-delete/:id",
        DOWNLOAD: "report/api/auth/opm/reports/primary-collection-bdw/report/list"
    },
    REGIONAL_DISPOSAL_SITE: {
        LIST: "admin/api/auth/opm/regional-disposal-site-details/list/all",
        BY_ID: "admin/api/auth/opm/regional-disposal-site-details/list/by-id/:id",
        SAVE: "admin/api/auth/opm/regional-disposal-site-details/save",
        DELETE: "admin/api/auth/opm/regional-disposal-site-details/soft-delete/:id",
        SURROUNDING_DROPDOWN: "admin/api/auth/opm/index-zone-types/list/all"
    },
    WSS_INFO: {
        LIST: "admin/api/auth/opm/waste_segregation_and_storage_details/list/all",
        BY_ID: "admin/api/auth/opm/waste_segregation_and_storage_details/list/by-id/:id",
        SAVE: "admin/api/auth/opm/waste_segregation_and_storage_details/save",
        DELETE: "admin/api/auth/opm/waste_segregation_and_storage_details/soft-delete/:id",
        WARD_NAME_DROPDOWN: "admin/api/auth/master/ward/list/all?dropDown=true",
        DOWNLOAD: "report/api/auth/opm/reports/waste-segregation/report/list"
    },
    SECONDARY_TRANSPORTATION: {
        LIST: "admin/api/auth/opm/secondary-transportation-agency/list/all",
        MODE_OF_LOADING: "admin/api/auth/opm/index-abstract-controller/list/all?dropDown=true",
        BY_ID: "admin/api/auth/opm/secondary-transportation-agency/list/by-id/:id",
        POST: "admin/api/auth/opm/secondary-transportation-agency/save",
        DELETE: "admin/api/auth/opm/secondary-transportation-agency/delete/:id",
        DOWNLOAD: "report/api/auth/opm/reports/secondary-transportation/report/list"
    },
    MCF_RRF_DETAILS: {
        LIST: "admin/api/auth/opm/mcf-rrf-waste-details/list/all",
        BY_ID: "admin/api/auth/opm/mcf-rrf-waste-details/list/by-id/:id",
        SAVE: "admin/api/auth/opm/mcf-rrf-waste-details/save",
        DELETE: "admin/api/auth/opm/mcf-rrf-waste-details/soft-delete/:id",
        TYPE_DROPDOWN: "admin/api/auth/opm/index-mcf-rrf-names/list/all",
        DOWNLOAD: "report/api/auth/opm/reports/mcf-rrf-details/report/list"
    },
    WASTE_PROCESSING_PLANT_DETAILS: {
        LIST: "admin/api/auth/opm/waste-processing-plant-date-details/list/all",
        WASTE_PROCESSING: "admin/api/auth/opm/waste-processing-site/list/all?dropDown=true",
        BY_ID: "admin/api/auth/opm/waste-processing-plant-date-details/list/by-id/:id",
        POST: "admin/api/auth/opm/waste-processing-plant-date-details/save",
        DELETE: "admin/api/auth/opm/waste-processing-plant-date-details/soft-delete/:id",
        DOWNLOAD: "report/api/auth/opm/reports/waste-processing-plant/report/list"
    },
    CITY_LEVEL_DISPOSAL_CELL: {
        LIST: "admin//api/auth/opm/city-level-site-daily-info/list/all",
        CITY_LEVEL_DROPDOWN: "admin/api//auth/opm/citylevel-disposal-site-details/list/all?dropDown=true",
        POST: "admin/api/auth/opm/city-level-site-daily-info/save",
        BY_ID: "admin/api/auth/opm/city-level-site-daily-info/list/by-id/:id",
        DELETE: "admin/api/auth/opm/city-level-site-daily-info/soft-delete/:id",
        DOWNLOAD: "report/api/auth/opm/reports/city-level-disposal-cell/report/list"
    },
    WASTE_DISPOSAL_REGIONAL_SITE: {
        LIST: "admin/api/auth/opm/regional-disposal-site-daily-info/list/all",
        BY_ID: "admin/api/auth/opm/regional-disposal-site-daily-info/list/by-id/:id",
        SAVE: "admin/api/auth/opm/regional-disposal-site-daily-info/save",
        DELETE: "admin/api/auth/opm/regional-disposal-site-daily-info/soft-delete/:id",
        NAME_DROPDOWN: "admin/api/auth/opm/regional-disposal-site-details/list/all?dropDown=true",
        DOWNLOAD: "report/api/auth/opm/reports/regional-site/report/list"
    },
    TRANSFER_STATION: {
        LIST: "admin/api/auth/opm/transfer-station/list/all",
        BY_ID: "admin/api/auth/opm/transfer-station/list/by-id/:id",
        SAVE: "admin/api/auth/opm/transfer-station/save",
        DELETE: "admin/api/auth/opm/transfer-station/soft-delete/:id",
        TYPE_OF_WASTE: "admin/api/auth/opm/index-type-of-waste/list/all",
        MCF_RRF_SITE_NAME: "admin/api/auth/opm/mcf-rrf-details/list/all?dropDown=true",
        FROM_DISTRICT: "admin/api/auth/master/district/list/all?applyDataAccess=false&dropDown=true",
        FROM_LSGI: "admin/api/auth/master/lsgi-details/list/all?applyDataAccess=false&dropDown=true",
        DOWNLOAD: "report/api/auth/opm/reports/transfer-station/report/list"
    },
    WARD_DETAILS: {
        LIST: "admin/api/auth/opm/ward-details/list/all",
        BY_ID: "admin/api/auth/opm/ward-details/list/by-id/:id",
        SAVE: "admin/api/auth/opm/ward-details/save",
        DELETE: "admin/api/auth/opm/ward-details/soft-delete/:id"
    }
};
