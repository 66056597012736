import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_PROJECT_LIST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LIST`,
    FETCH_PROJECT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LIST_REQUEST`,
    FETCH_PROJECT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LIST_SUCCESS`,
    FETCH_PROJECT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LIST_FAILURE`,

    FETCH_PROJECT_SITE_LIST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_SITE_LIST`,
    FETCH_PROJECT_SITE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_SITE_LIST_REQUEST`,
    FETCH_PROJECT_SITE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROJECT_SITE_LIST_SUCCESS`,
    FETCH_PROJECT_SITE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROJECT_SITE_LIST_FAILURE`,

    FETCH_NATURE_OF_WORK_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_NATURE_OF_WORK_DROPDOWN`,
    FETCH_NATURE_OF_WORK_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NATURE_OF_WORK_DROPDOWN_REQUEST`,
    FETCH_NATURE_OF_WORK_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NATURE_OF_WORK_DROPDOWN_SUCCESS`,
    FETCH_NATURE_OF_WORK_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NATURE_OF_WORK_DROPDOWN_FAILURE`,

    FETCH_WAGE_PERIOD_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_WAGE_PERIOD_DROPDOWN`,
    FETCH_WAGE_PERIOD_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WAGE_PERIOD_DROPDOWN_REQUEST`,
    FETCH_WAGE_PERIOD_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WAGE_PERIOD_DROPDOWN_SUCCESS`,
    FETCH_WAGE_PERIOD_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WAGE_PERIOD_DROPDOWN_FAILURE`,

    FETCH_DAY_OF_HOLIDAY_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_DAY_OF_HOLIDAY_DROPDOWN`,
    FETCH_DAY_OF_HOLIDAY_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DAY_OF_HOLIDAY_DROPDOWN_REQUEST`,
    FETCH_DAY_OF_HOLIDAY_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DAY_OF_HOLIDAY_DROPDOWN_SUCCESS`,
    FETCH_DAY_OF_HOLIDAY_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DAY_OF_HOLIDAY_DROPDOWN_FAILURE`,

    FETCH_DISTRICT_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN`,
    FETCH_DISTRICT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_REQUEST`,
    FETCH_DISTRICT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_SUCCESS`,
    FETCH_DISTRICT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DROPDOWN_FAILURE`,

    FETCH_ULB_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_ULB_DROPDOWN`,
    FETCH_ULB_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ULB_DROPDOWN_REQUEST`,
    FETCH_ULB_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ULB_DROPDOWN_SUCCESS`,
    FETCH_ULB_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ULB_DROPDOWN_FAILURE`,

    FETCH_BASIC_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_BASIC_DETAILS_BY_ID`,
    FETCH_BASIC_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BASIC_DETAILS_BY_ID_REQUEST`,
    FETCH_BASIC_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BASIC_DETAILS_BY_ID_SUCCESS`,
    FETCH_BASIC_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BASIC_DETAILS_BY_ID_FAILURE`,

    POST_BASIC_DETAILS: `${STATE_REDUCER_KEY}/POST_BASIC_DETAILS`,
    POST_BASIC_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_BASIC_DETAILS_REQUEST`,
    POST_BASIC_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_BASIC_DETAILS_SUCCESS`,
    POST_BASIC_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_BASIC_DETAILS_FAILURE`,

    PATCH_BASIC_DETAILS: `${STATE_REDUCER_KEY}/PATCH_BASIC_DETAILS`,
    PATCH_BASIC_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_BASIC_DETAILS_REQUEST`,
    PATCH_BASIC_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_BASIC_DETAILS_SUCCESS`,
    PATCH_BASIC_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_BASIC_DETAILS_FAILURE`,

    FETCH_PROJECT_MANAGER_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/FETCH_PROJECT_MANAGER_DETAILS_BY_ID`,
    FETCH_PROJECT_MANAGER_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_MANAGER_DETAILS_BY_ID_REQUEST`,
    FETCH_PROJECT_MANAGER_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROJECT_MANAGER_DETAILS_BY_ID_SUCCESS`,
    FETCH_PROJECT_MANAGER_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROJECT_MANAGER_DETAILS_BY_ID_FAILURE`,

    POST_PROJECT_MANAGER_DETAILS: `${STATE_REDUCER_KEY}/POST_PROJECT_MANAGER_DETAILS`,
    POST_PROJECT_MANAGER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_PROJECT_MANAGER_DETAILS_REQUEST`,
    POST_PROJECT_MANAGER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_PROJECT_MANAGER_DETAILS_SUCCESS`,
    POST_PROJECT_MANAGER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_PROJECT_MANAGER_DETAILS_FAILURE`,

    FETCH_PROJECT_MANAGER_LIST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_MANAGER_LIST`,
    FETCH_PROJECT_MANAGER_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_MANAGER_LIST_REQUEST`,
    FETCH_PROJECT_MANAGER_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROJECT_MANAGER_LIST_SUCCESS`,
    FETCH_PROJECT_MANAGER_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROJECT_MANAGER_LIST_FAILURE`,

    PATCH_PROJECT_MANAGER_DETAILS: `${STATE_REDUCER_KEY}/PATCH_PROJECT_MANAGER_DETAILS`,
    PATCH_PROJECT_MANAGER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_PROJECT_MANAGER_DETAILS_REQUEST`,
    PATCH_PROJECT_MANAGER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_PROJECT_MANAGER_DETAILS_SUCCESS`,
    PATCH_PROJECT_MANAGER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_PROJECT_MANAGER_DETAILS_FAILURE`,

    FETCH_MINIMUM_WAGE: `${STATE_REDUCER_KEY}/FETCH_MINIMUM_WAGE`,
    FETCH_MINIMUM_WAGE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MINIMUM_WAGE_REQUEST`,
    FETCH_MINIMUM_WAGE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MINIMUM_WAGE_SUCCESS`,
    FETCH_MINIMUM_WAGE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MINIMUM_WAGE_FAILURE`,

    FETCH_MINIMUM_WAGE_BY_ID: `${STATE_REDUCER_KEY}/FETCH_MINIMUM_WAGE_BY_ID`,
    FETCH_MINIMUM_WAGE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MINIMUM_WAGE_BY_ID_REQUEST`,
    FETCH_MINIMUM_WAGE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MINIMUM_WAGE_BY_ID_SUCCESS`,
    FETCH_MINIMUM_WAGE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MINIMUM_WAGE_BY_ID_FAILURE`,

    POST_MINIMUM_WAGE: `${STATE_REDUCER_KEY}/POST_MINIMUM_WAGE`,
    POST_MINIMUM_WAGE_REQUEST: `${STATE_REDUCER_KEY}/POST_MINIMUM_WAGE_REQUEST`,
    POST_MINIMUM_WAGE_SUCCESS: `${STATE_REDUCER_KEY}/POST_MINIMUM_WAGE_SUCCESS`,
    POST_MINIMUM_WAGE_FAILURE: `${STATE_REDUCER_KEY}/POST_MINIMUM_WAGE_FAILURE`,

    FETCH_REGISTRATION_NUMBER: `${STATE_REDUCER_KEY}/FETCH_REGISTRATION_NUMBER`,
    FETCH_REGISTRATION_NUMBER_REQUEST: `${STATE_REDUCER_KEY}/FETCH_REGISTRATION_NUMBER_REQUEST`,
    FETCH_REGISTRATION_NUMBER_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_REGISTRATION_NUMBER_SUCCESS`,
    FETCH_REGISTRATION_NUMBER_FAILURE: `${STATE_REDUCER_KEY}/FETCH_REGISTRATION_NUMBER_FAILURE`,

    FETCH_EMPLOYEE_LIST: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_LIST`,
    FETCH_EMPLOYEE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_LIST_REQUEST`,
    FETCH_EMPLOYEE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_LIST_SUCCESS`,
    FETCH_EMPLOYEE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_LIST_FAILURE`,

    FETCH_GENDER_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_GENDER_DROPDOWN`,
    FETCH_GENDER_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GENDER_DROPDOWN_REQUEST`,
    FETCH_GENDER_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GENDER_DROPDOWN_SUCCESS`,
    FETCH_GENDER_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GENDER_DROPDOWN_FAILURE`,

    FETCH_NATIONALITY_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_NATIONALITY_DROPDOWN`,
    FETCH_NATIONALITY_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NATIONALITY_DROPDOWN_REQUEST`,
    FETCH_NATIONALITY_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NATIONALITY_DROPDOWN_SUCCESS`,
    FETCH_NATIONALITY_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NATIONALITY_DROPDOWN_FAILURE`,

    FETCH_EDUCATION_LEVEL_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_EDUCATION_LEVEL_DROPDOWN`,
    FETCH_EDUCATION_LEVEL_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EDUCATION_LEVEL_DROPDOWN_REQUEST`,
    FETCH_EDUCATION_LEVEL_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EDUCATION_LEVEL_DROPDOWN_SUCCESS`,
    FETCH_EDUCATION_LEVEL_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EDUCATION_LEVEL_DROPDOWN_FAILURE`,

    FETCH_DESIGNATION_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_DROPDOWN`,
    FETCH_DESIGNATION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_DROPDOWN_REQUEST`,
    FETCH_DESIGNATION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_DROPDOWN_SUCCESS`,
    FETCH_DESIGNATION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_DROPDOWN_FAILURE`,

    FETCH_CATEGORY_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_CATEGORY_DROPDOWN`,
    FETCH_CATEGORY_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CATEGORY_DROPDOWN_REQUEST`,
    FETCH_CATEGORY_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CATEGORY_DROPDOWN_SUCCESS`,
    FETCH_CATEGORY_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CATEGORY_DROPDOWN_FAILURE`,

    FETCH_EMPLOYMENT_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_EMPLOYMENT_DROPDOWN`,
    FETCH_EMPLOYMENT_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EMPLOYMENT_DROPDOWN_REQUEST`,
    FETCH_EMPLOYMENT_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EMPLOYMENT_DROPDOWN_SUCCESS`,
    FETCH_EMPLOYMENT_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EMPLOYMENT_DROPDOWN_FAILURE`,

    FETCH_SUB_CONTRACTOR_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_SUB_CONTRACTOR_DROPDOWN`,
    FETCH_SUB_CONTRACTOR_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SUB_CONTRACTOR_DROPDOWN_REQUEST`,
    FETCH_SUB_CONTRACTOR_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SUB_CONTRACTOR_DROPDOWN_SUCCESS`,
    FETCH_SUB_CONTRACTOR_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SUB_CONTRACTOR_DROPDOWN_FAILURE`,

    FETCH_WORKMAN_RADIO_BUTTON: `${STATE_REDUCER_KEY}/FETCH_WORKMAN_RADIO_BUTTON`,
    FETCH_WORKMAN_RADIO_BUTTON_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WORKMAN_RADIO_BUTTON_REQUEST`,
    FETCH_WORKMAN_RADIO_BUTTON_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WORKMAN_RADIO_BUTTON_SUCCESS`,
    FETCH_WORKMAN_RADIO_BUTTON_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WORKMAN_RADIO_BUTTON_FAILURE`,

    EMPLOYEE_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/EMPLOYEE_DETAILS_BY_ID`,
    EMPLOYEE_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/EMPLOYEE_DETAILS_BY_ID_REQUEST`,
    EMPLOYEE_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/EMPLOYEE_DETAILS_BY_ID_SUCCESS`,
    EMPLOYEE_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/EMPLOYEE_DETAILS_BY_ID_FAILURE`,

    FETCH_MARITAL_STATUS_RADIO_BUTTON: `${STATE_REDUCER_KEY}/FETCH_MARITAL_STATUS_RADIO_BUTTON`,
    FETCH_MARITAL_STATUS_RADIO_BUTTON_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MARITAL_STATUS_RADIO_BUTTON_REQUEST`,
    FETCH_MARITAL_STATUS_RADIO_BUTTON_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MARITAL_STATUS_RADIO_BUTTON_SUCCESS`,
    FETCH_MARITAL_STATUS_RADIO_BUTTON_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MARITAL_STATUS_RADIO_BUTTON_FAILURE`,

    POST_EMPLOYEE_DETAILS: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_DETAILS`,
    POST_EMPLOYEE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_DETAILS_REQUEST`,
    POST_EMPLOYEE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_DETAILS_SUCCESS`,
    POST_EMPLOYEE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_DETAILS_FAILURE`,

    PATCH_EMPLOYEE_DETAILS: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_DETAILS`,
    PATCH_EMPLOYEE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_DETAILS_REQUEST`,
    PATCH_EMPLOYEE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_DETAILS_SUCCESS`,
    PATCH_EMPLOYEE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_DETAILS_FAILURE`,

    DELETE_EMPLOYEE_DETAILS: `${STATE_REDUCER_KEY}/DELETE_EMPLOYEE_DETAILS`,
    DELETE_EMPLOYEE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_EMPLOYEE_DETAILS_REQUEST`,
    DELETE_EMPLOYEE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_EMPLOYEE_DETAILS_SUCCESS`,
    DELETE_EMPLOYEE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_EMPLOYEE_DETAILS_FAILURE`,

    FETCH_REGISTRATION_NUMBER_BY_ID: `${STATE_REDUCER_KEY}/FETCH_REGISTRATION_NUMBER_BY_ID`,
    FETCH_REGISTRATION_NUMBER_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_REGISTRATION_NUMBER_BY_ID_REQUEST`,
    FETCH_REGISTRATION_NUMBER_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_REGISTRATION_NUMBER_BY_ID_SUCCESS`,
    FETCH_REGISTRATION_NUMBER_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_REGISTRATION_NUMBER_BY_ID_FAILURE`,

    PATCH_REGISTRATION_NUMBER: `${STATE_REDUCER_KEY}/PATCH_REGISTRATION_NUMBER`,
    PATCH_REGISTRATION_NUMBER_REQUEST: `${STATE_REDUCER_KEY}/PATCH_REGISTRATION_NUMBER_REQUEST`,
    PATCH_REGISTRATION_NUMBER_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_REGISTRATION_NUMBER_SUCCESS`,
    PATCH_REGISTRATION_NUMBER_FAILURE: `${STATE_REDUCER_KEY}/PATCH_REGISTRATION_NUMBER_FAILURE`,

    FETCH_BASIC_FACILITIES_BY_ID: `${STATE_REDUCER_KEY}/FETCH_BASIC_FACILITIES_BY_ID`,
    FETCH_BASIC_FACILITIES_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BASIC_FACILITIES_BY_ID_REQUEST`,
    FETCH_BASIC_FACILITIES_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BASIC_FACILITIES_BY_ID_SUCCESS`,
    FETCH_BASIC_FACILITIES_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BASIC_FACILITIES_BY_ID_FAILURE`,

    PATCH_BASIC_FACILITIES: `${STATE_REDUCER_KEY}/PATCH_BASIC_FACILITIES`,
    PATCH_BASIC_FACILITIES_REQUEST: `${STATE_REDUCER_KEY}/PATCH_BASIC_FACILITIES_REQUEST`,
    PATCH_BASIC_FACILITIES_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_BASIC_FACILITIES_SUCCESS`,
    PATCH_BASIC_FACILITIES_FAILURE: `${STATE_REDUCER_KEY}/PATCH_BASIC_FACILITIES_FAILURE`,

    FETCH_GRIEVANCE_COMMITTEE_LIST: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_COMMITTEE_LIST`,
    FETCH_GRIEVANCE_COMMITTEE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_COMMITTEE_LIST_REQUEST`,
    FETCH_GRIEVANCE_COMMITTEE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_COMMITTEE_LIST_SUCCESS`,
    FETCH_GRIEVANCE_COMMITTEE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_COMMITTEE_LIST_FAILURE`,

    FETCH_GRIEVANCE_COMMITTEE_BY_ID: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_COMMITTEE_BY_ID`,
    FETCH_GRIEVANCE_COMMITTEE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_COMMITTEE_BY_ID_REQUEST`,
    FETCH_GRIEVANCE_COMMITTEE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_COMMITTEE_BY_ID_SUCCESS`,
    FETCH_GRIEVANCE_COMMITTEE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GRIEVANCE_COMMITTEE_BY_ID_FAILURE`,

    POST_GRIEVANCE_COMMITTEE: `${STATE_REDUCER_KEY}/POST_GRIEVANCE_COMMITTEE`,
    POST_GRIEVANCE_COMMITTEE_REQUEST: `${STATE_REDUCER_KEY}/POST_GRIEVANCE_COMMITTEE_REQUEST`,
    POST_GRIEVANCE_COMMITTEE_SUCCESS: `${STATE_REDUCER_KEY}/POST_GRIEVANCE_COMMITTEE_SUCCESS`,
    POST_GRIEVANCE_COMMITTEE_FAILURE: `${STATE_REDUCER_KEY}/POST_GRIEVANCE_COMMITTEE_FAILURE`,

    PATCH_GRIEVANCE_COMMITTEE: `${STATE_REDUCER_KEY}/PATCH_GRIEVANCE_COMMITTEE`,
    PATCH_GRIEVANCE_COMMITTEE_REQUEST: `${STATE_REDUCER_KEY}/PATCH_GRIEVANCE_COMMITTEE_REQUEST`,
    PATCH_GRIEVANCE_COMMITTEE_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_GRIEVANCE_COMMITTEE_SUCCESS`,
    PATCH_GRIEVANCE_COMMITTEE_FAILURE: `${STATE_REDUCER_KEY}/PATCH_GRIEVANCE_COMMITTEE_FAILURE`,

    FETCH_NOTICE_BOARD_BY_ID: `${STATE_REDUCER_KEY}/FETCH_NOTICE_BOARD_BY_ID`,
    FETCH_NOTICE_BOARD_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NOTICE_BOARD_BY_ID_REQUEST`,
    FETCH_NOTICE_BOARD_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NOTICE_BOARD_BY_ID_SUCCESS`,
    FETCH_NOTICE_BOARD_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NOTICE_BOARD_BY_ID_FAILURE`,

    PATCH_NOTICE_BOARD: `${STATE_REDUCER_KEY}/PATCH_NOTICE_BOARD`,
    PATCH_NOTICE_BOARD_REQUEST: `${STATE_REDUCER_KEY}/PATCH_NOTICE_BOARD_REQUEST`,
    PATCH_NOTICE_BOARD_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_NOTICE_BOARD_SUCCESS`,
    PATCH_NOTICE_BOARD_FAILURE: `${STATE_REDUCER_KEY}/PATCH_NOTICE_BOARD_FAILURE`,

    FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST: `${STATE_REDUCER_KEY}/FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST`,
    FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_REQUEST`,
    FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_SUCCESS`,
    FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST_FAILURE`,

    FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN`,
    FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_REQUEST`,
    FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_SUCCESS`,
    FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN_FAILURE`,

    FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID: `${STATE_REDUCER_KEY}/FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID`,
    FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_REQUEST`,
    FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_SUCCESS`,
    FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID_FAILURE`,

    POST_COMPLAINT_COMMITTEE_MEMBER: `${STATE_REDUCER_KEY}/POST_COMPLAINT_COMMITTEE_MEMBER`,
    POST_COMPLAINT_COMMITTEE_MEMBER_REQUEST: `${STATE_REDUCER_KEY}/POST_COMPLAINT_COMMITTEE_MEMBER_REQUEST`,
    POST_COMPLAINT_COMMITTEE_MEMBER_SUCCESS: `${STATE_REDUCER_KEY}/POST_COMPLAINT_COMMITTEE_MEMBER_SUCCESS`,
    POST_COMPLAINT_COMMITTEE_MEMBER_FAILURE: `${STATE_REDUCER_KEY}/POST_COMPLAINT_COMMITTEE_MEMBER_FAILURE`,

    PATCH_COMPLAINT_COMMITTEE_MEMBER: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_COMMITTEE_MEMBER`,
    PATCH_COMPLAINT_COMMITTEE_MEMBER_REQUEST: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_COMMITTEE_MEMBER_REQUEST`,
    PATCH_COMPLAINT_COMMITTEE_MEMBER_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_COMMITTEE_MEMBER_SUCCESS`,
    PATCH_COMPLAINT_COMMITTEE_MEMBER_FAILURE: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_COMMITTEE_MEMBER_FAILURE`,

    FETCH_WAGE_DETAILS_LIST: `${STATE_REDUCER_KEY}/FETCH_WAGE_DETAILS_LIST`,
    FETCH_WAGE_DETAILS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WAGE_DETAILS_LIST_REQUEST`,
    FETCH_WAGE_DETAILS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WAGE_DETAILS_LIST_SUCCESS`,
    FETCH_WAGE_DETAILS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WAGE_DETAILS_LIST_FAILURE`,

    POST_WAGE_DETAILS: `${STATE_REDUCER_KEY}/POST_WAGE_DETAILS`,
    POST_WAGE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_WAGE_DETAILS_REQUEST`,
    POST_WAGE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_WAGE_DETAILS_SUCCESS`,
    POST_WAGE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_WAGE_DETAILS_FAILURE`,

    PATCH_WAGE_DETAILS: `${STATE_REDUCER_KEY}/PATCH_WAGE_DETAILS`,
    PATCH_WAGE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WAGE_DETAILS_REQUEST`,
    PATCH_WAGE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WAGE_DETAILS_SUCCESS`,
    PATCH_WAGE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WAGE_DETAILS_FAILURE`,

    GET_WAGE_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/GET_WAGE_DETAILS_BY_ID`,
    GET_WAGE_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_WAGE_DETAILS_BY_ID_REQUEST`,
    GET_WAGE_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_WAGE_DETAILS_BY_ID_SUCCESS`,
    GET_WAGE_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_WAGE_DETAILS_BY_ID_FAILURE`,

    DELETE_WAGE_DETAILS: `${STATE_REDUCER_KEY}/DELETE_WAGE_DETAILS`,
    DELETE_WAGE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_WAGE_DETAILS_REQUEST`,
    DELETE_WAGE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_WAGE_DETAILS_SUCCESS`,
    DELETE_WAGE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_WAGE_DETAILS_FAILURE`,

    FETCH_EMPLOYEE_VERIFICATION: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_VERIFICATION`,
    FETCH_EMPLOYEE_VERIFICATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_VERIFICATION_REQUEST`,
    FETCH_EMPLOYEE_VERIFICATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_VERIFICATION_SUCCESS`,
    FETCH_EMPLOYEE_VERIFICATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_VERIFICATION_FAILURE`,

    PATCH_EMPLOYEE_VERIFICATION: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_VERIFICATION`,
    PATCH_EMPLOYEE_VERIFICATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_VERIFICATION_REQUEST`,
    PATCH_EMPLOYEE_VERIFICATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_VERIFICATION_SUCCESS`,
    PATCH_EMPLOYEE_VERIFICATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_VERIFICATION_FAILURE`,

    POST_EMPLOYEE_TERMINATION_PROCESS: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_TERMINATION_PROCESS`,
    POST_EMPLOYEE_TERMINATION_PROCESS_REQUEST: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_TERMINATION_PROCESS_REQUEST`,
    POST_EMPLOYEE_TERMINATION_PROCESS_SUCCESS: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_TERMINATION_PROCESS_SUCCESS`,
    POST_EMPLOYEE_TERMINATION_PROCESS_FAILURE: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_TERMINATION_PROCESS_FAILURE`,

    FETCH_SITE_WAGE_REGISTER_LIST: `${STATE_REDUCER_KEY}/FETCH_SITE_WAGE_REGISTER_LIST`,
    FETCH_SITE_WAGE_REGISTER_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SITE_WAGE_REGISTER_LIST_REQUEST`,
    FETCH_SITE_WAGE_REGISTER_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SITE_WAGE_REGISTER_LIST_SUCCESS`,
    FETCH_SITE_WAGE_REGISTER_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SITE_WAGE_REGISTER_LIST_FAILURE`,

    POST_SITE_WAGE_REGISTER: `${STATE_REDUCER_KEY}/POST_SITE_WAGE_REGISTER`,
    POST_SITE_WAGE_REGISTER_REQUEST: `${STATE_REDUCER_KEY}/POST_SITE_WAGE_REGISTER_REQUEST`,
    POST_SITE_WAGE_REGISTER_SUCCESS: `${STATE_REDUCER_KEY}/POST_SITE_WAGE_REGISTER_SUCCESS`,
    POST_SITE_WAGE_REGISTER_FAILURE: `${STATE_REDUCER_KEY}/POST_SITE_WAGE_REGISTER_FAILURE`,

    FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST: `${STATE_REDUCER_KEY}/FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST`,
    FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_REQUEST`,
    FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_SUCCESS`,
    FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST_FAILURE`,

    GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID`,
    GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_REQUEST`,
    GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_SUCCESS`,
    GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID_FAILURE`,

    PATCH_SITE_WAGE_EMPLOYEE_DETAILS: `${STATE_REDUCER_KEY}/PATCH_SITE_WAGE_EMPLOYEE_DETAILS`,
    PATCH_SITE_WAGE_EMPLOYEE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SITE_WAGE_EMPLOYEE_DETAILS_REQUEST`,
    PATCH_SITE_WAGE_EMPLOYEE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SITE_WAGE_EMPLOYEE_DETAILS_SUCCESS`,
    PATCH_SITE_WAGE_EMPLOYEE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SITE_WAGE_EMPLOYEE_DETAILS_FAILURE`,

    GET_CARD_COUNT_IN_WAGE_REGISTER: `${STATE_REDUCER_KEY}/GET_CARD_COUNT_IN_WAGE_REGISTER`,
    GET_CARD_COUNT_IN_WAGE_REGISTER_REQUEST: `${STATE_REDUCER_KEY}/GET_CARD_COUNT_IN_WAGE_REGISTER_REQUEST`,
    GET_CARD_COUNT_IN_WAGE_REGISTER_SUCCESS: `${STATE_REDUCER_KEY}/GET_CARD_COUNT_IN_WAGE_REGISTER_SUCCESS`,
    GET_CARD_COUNT_IN_WAGE_REGISTER_FAILURE: `${STATE_REDUCER_KEY}/GET_CARD_COUNT_IN_WAGE_REGISTER_FAILURE`,

    DELETE_WAGE_REGISTER: `${STATE_REDUCER_KEY}/DELETE_WAGE_REGISTER`,
    DELETE_WAGE_REGISTER_REQUEST: `${STATE_REDUCER_KEY}/DELETE_WAGE_REGISTER_REQUEST`,
    DELETE_WAGE_REGISTER_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_WAGE_REGISTER_SUCCESS`,
    DELETE_WAGE_REGISTER_FAILURE: `${STATE_REDUCER_KEY}/DELETE_WAGE_REGISTER_FAILURE`,

    DOWNLOAD_EMPLOYEE_PAYSLIP: `${STATE_REDUCER_KEY}/DOWNLOAD_EMPLOYEE_PAYSLIP`,
    DOWNLOAD_EMPLOYEE_PAYSLIP_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_EMPLOYEE_PAYSLIP_REQUEST`,
    DOWNLOAD_EMPLOYEE_PAYSLIP_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_EMPLOYEE_PAYSLIP_SUCCESS`,
    DOWNLOAD_EMPLOYEE_PAYSLIP_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_EMPLOYEE_PAYSLIP_FAILURE`,

    WORKPLACE_ACCIDENT_DETAILS_LIST: `${STATE_REDUCER_KEY}/WORKPLACE_ACCIDENT_DETAILS_LIST`,
    WORKPLACE_ACCIDENT_DETAILS_LIST_REQUEST: `${STATE_REDUCER_KEY}/WORKPLACE_ACCIDENT_DETAILS_LIST_REQUEST`,
    WORKPLACE_ACCIDENT_DETAILS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/WORKPLACE_ACCIDENT_DETAILS_LIST_SUCCESS`,
    WORKPLACE_ACCIDENT_DETAILS_LIST_FAILURE: `${STATE_REDUCER_KEY}/WORKPLACE_ACCIDENT_DETAILS_LIST_FAILURE`,

    GET_EMPLOYEE_NAME_WITH_CODE: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_NAME_WITH_CODE`,
    GET_EMPLOYEE_NAME_WITH_CODE_REQUEST: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_NAME_WITH_CODE_REQUEST`,
    GET_EMPLOYEE_NAME_WITH_CODE_SUCCESS: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_NAME_WITH_CODE_SUCCESS`,
    GET_EMPLOYEE_NAME_WITH_CODE_FAILURE: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_NAME_WITH_CODE_FAILURE`,

    GET_EMPLOYEE_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_DETAILS_BY_ID`,
    GET_EMPLOYEE_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_DETAILS_BY_ID_REQUEST`,
    GET_EMPLOYEE_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_DETAILS_BY_ID_SUCCESS`,
    GET_EMPLOYEE_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_DETAILS_BY_ID_FAILURE`,

    GET_FATAL_DROPDOWN: `${STATE_REDUCER_KEY}/GET_FATAL_DROPDOWN`,
    GET_FATAL_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_FATAL_DROPDOWN_REQUEST`,
    GET_FATAL_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_FATAL_DROPDOWN_SUCCESS`,
    GET_FATAL_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_FATAL_DROPDOWN_FAILURE`,

    POST_WORKPLACE_ACCIDENT_DETAILS: `${STATE_REDUCER_KEY}/POST_WORKPLACE_ACCIDENT_DETAILS`,
    POST_WORKPLACE_ACCIDENT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_WORKPLACE_ACCIDENT_DETAILS_REQUEST`,
    POST_WORKPLACE_ACCIDENT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_WORKPLACE_ACCIDENT_DETAILS_SUCCESS`,
    POST_WORKPLACE_ACCIDENT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_WORKPLACE_ACCIDENT_DETAILS_FAILURE`,

    GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID`,
    GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_REQUEST`,
    GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_SUCCESS`,
    GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID_FAILURE`,

    PATCH_WORKPLACE_ACCIDENT_DETAILS: `${STATE_REDUCER_KEY}/PATCH_WORKPLACE_ACCIDENT_DETAILS`,
    PATCH_WORKPLACE_ACCIDENT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WORKPLACE_ACCIDENT_DETAILS_REQUEST`,
    PATCH_WORKPLACE_ACCIDENT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WORKPLACE_ACCIDENT_DETAILS_SUCCESS`,
    PATCH_WORKPLACE_ACCIDENT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WORKPLACE_ACCIDENT_DETAILS_FAILURE`,

    FETCH_HARASSMENT_DETAILS_LIST: `${STATE_REDUCER_KEY}/FETCH_HARASSMENT_DETAILS_LIST`,
    FETCH_HARASSMENT_DETAILS_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_HARASSMENT_DETAILS_LIST_REQUEST`,
    FETCH_HARASSMENT_DETAILS_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_HARASSMENT_DETAILS_LIST_SUCCESS`,
    FETCH_HARASSMENT_DETAILS_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_HARASSMENT_DETAILS_LIST_FAILURE`,

    SUBMIT_NOTICE_RESPONDENT: `${STATE_REDUCER_KEY}/SUBMIT_NOTICE_RESPONDENT`,
    SUBMIT_NOTICE_RESPONDENT_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_NOTICE_RESPONDENT_REQUEST`,
    SUBMIT_NOTICE_RESPONDENT_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_NOTICE_RESPONDENT_SUCCESS`,
    SUBMIT_NOTICE_RESPONDENT_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_NOTICE_RESPONDENT_FAILURE`,

    SUBMIT_RESPONSE_FROM_RESPONDENT: `${STATE_REDUCER_KEY}/SUBMIT_RESPONSE_FROM_RESPONDENT`,
    SUBMIT_RESPONSE_FROM_RESPONDENT_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_RESPONSE_FROM_RESPONDENT_REQUEST`,
    SUBMIT_RESPONSE_FROM_RESPONDENT_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_RESPONSE_FROM_RESPONDENT_SUCCESS`,
    SUBMIT_RESPONSE_FROM_RESPONDENT_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_RESPONSE_FROM_RESPONDENT_FAILURE`,

    SUBMIT_COMPLAINT_SETTLEMENT: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_SETTLEMENT`,
    SUBMIT_COMPLAINT_SETTLEMENT_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_SETTLEMENT_REQUEST`,
    SUBMIT_COMPLAINT_SETTLEMENT_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_SETTLEMENT_SUCCESS`,
    SUBMIT_COMPLAINT_SETTLEMENT_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_COMPLAINT_SETTLEMENT_FAILURE`,

    SUBMIT_HEARING_DETAILS: `${STATE_REDUCER_KEY}/SUBMIT_HEARING_DETAILS`,
    SUBMIT_HEARING_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_HEARING_DETAILS_REQUEST`,
    SUBMIT_HEARING_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_HEARING_DETAILS_SUCCESS`,
    SUBMIT_HEARING_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_HEARING_DETAILS_FAILURE`,

    ACCORDION_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/ACCORDION_DETAILS_BY_ID`,
    ACCORDION_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/ACCORDION_DETAILS_BY_ID_REQUEST`,
    ACCORDION_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/ACCORDION_DETAILS_BY_ID_SUCCESS`,
    ACCORDION_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/ACCORDION_DETAILS_BY_ID_FAILURE`,

    RESPONSE_ACCORDION_BY_ID: `${STATE_REDUCER_KEY}/RESPONSE_ACCORDION_BY_ID`,
    RESPONSE_ACCORDION_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/RESPONSE_ACCORDION_BY_ID_REQUEST`,
    RESPONSE_ACCORDION_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/RESPONSE_ACCORDION_BY_ID_SUCCESS`,
    RESPONSE_ACCORDION_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/RESPONSE_ACCORDION_BY_ID_FAILURE`,

    COMPLAINT_ACCORDION_BY_ID: `${STATE_REDUCER_KEY}/COMPLAINT_ACCORDION_BY_ID`,
    COMPLAINT_ACCORDION_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/COMPLAINT_ACCORDION_BY_ID_REQUEST`,
    COMPLAINT_ACCORDION_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/COMPLAINT_ACCORDION_BY_ID_SUCCESS`,
    COMPLAINT_ACCORDION_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/COMPLAINT_ACCORDION_BY_ID_FAILURE`,

    HEARING_ACCORDION_BY_ID: `${STATE_REDUCER_KEY}/HEARING_ACCORDION_BY_ID`,
    HEARING_ACCORDION_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/HEARING_ACCORDION_BY_ID_REQUEST`,
    HEARING_ACCORDION_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/HEARING_ACCORDION_BY_ID_SUCCESS`,
    HEARING_ACCORDION_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/HEARING_ACCORDION_BY_ID_FAILURE`,

    POST_HARASSMENT_COMPLAINTS: `${STATE_REDUCER_KEY}/POST_HARASSMENT_COMPLAINTS`,
    POST_HARASSMENT_COMPLAINTS_REQUEST: `${STATE_REDUCER_KEY}/POST_HARASSMENT_COMPLAINTS_REQUEST`,
    POST_HARASSMENT_COMPLAINTS_SUCCESS: `${STATE_REDUCER_KEY}/POST_HARASSMENT_COMPLAINTS_SUCCESS`,
    POST_HARASSMENT_COMPLAINTS_FAILURE: `${STATE_REDUCER_KEY}/POST_HARASSMENT_COMPLAINTS_FAILURE`,

    HARASSMENT_COMPLAINT_BY_ID: `${STATE_REDUCER_KEY}/HARASSMENT_COMPLAINT_BY_ID`,
    HARASSMENT_COMPLAINT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/HARASSMENT_COMPLAINT_BY_ID_REQUEST`,
    HARASSMENT_COMPLAINT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/HARASSMENT_COMPLAINT_BY_ID_SUCCESS`,
    HARASSMENT_COMPLAINT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/HARASSMENT_COMPLAINT_BY_ID_FAILURE`,

    PATCH_NOTICE_RESPONDENT: `${STATE_REDUCER_KEY}/PATCH_NOTICE_RESPONDENT`,
    PATCH_NOTICE_RESPONDENT_REQUEST: `${STATE_REDUCER_KEY}/PATCH_NOTICE_RESPONDENT_REQUEST`,
    PATCH_NOTICE_RESPONDENT_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_NOTICE_RESPONDENT_SUCCESS`,
    PATCH_NOTICE_RESPONDENT_FAILURE: `${STATE_REDUCER_KEY}/PATCH_NOTICE_RESPONDENT_FAILURE`,

    PATCH_RESPONSE_FROM_RESPONDENT: `${STATE_REDUCER_KEY}/PATCH_RESPONSE_FROM_RESPONDENT`,
    PATCH_RESPONSE_FROM_RESPONDENT_REQUEST: `${STATE_REDUCER_KEY}/PATCH_RESPONSE_FROM_RESPONDENT_REQUEST`,
    PATCH_RESPONSE_FROM_RESPONDENT_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_RESPONSE_FROM_RESPONDENT_SUCCESS`,
    PATCH_RESPONSE_FROM_RESPONDENT_FAILURE: `${STATE_REDUCER_KEY}/PATCH_RESPONSE_FROM_RESPONDENT_FAILURE`,

    PATCH_COMPLAINT_SETTLEMENT: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_SETTLEMENT`,
    PATCH_COMPLAINT_SETTLEMENT_REQUEST: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_SETTLEMENT_REQUEST`,
    PATCH_COMPLAINT_SETTLEMENT_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_SETTLEMENT_SUCCESS`,
    PATCH_COMPLAINT_SETTLEMENT_FAILURE: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_SETTLEMENT_FAILURE`,

    PATCH_HEARING_DETAILS: `${STATE_REDUCER_KEY}/PATCH_HEARING_DETAILS`,
    PATCH_HEARING_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_HEARING_DETAILS_REQUEST`,
    PATCH_HEARING_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_HEARING_DETAILS_SUCCESS`,
    PATCH_HEARING_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_HEARING_DETAILS_FAILURE`,

    PATCH_HARASSMENT_COMPLAINTS: `${STATE_REDUCER_KEY}/PATCH_HARASSMENT_COMPLAINTS`,
    PATCH_HARASSMENT_COMPLAINTS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_HARASSMENT_COMPLAINTS_REQUEST`,
    PATCH_HARASSMENT_COMPLAINTS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_HARASSMENT_COMPLAINTS_SUCCESS`,
    PATCH_HARASSMENT_COMPLAINTS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_HARASSMENT_COMPLAINTS_FAILURE`,

    POST_EMPLOYEE_ATTENDANCE: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_ATTENDANCE`,
    POST_EMPLOYEE_ATTENDANCE_REQUEST: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_ATTENDANCE_REQUEST`,
    POST_EMPLOYEE_ATTENDANCE_SUCCESS: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_ATTENDANCE_SUCCESS`,
    POST_EMPLOYEE_ATTENDANCE_FAILURE: `${STATE_REDUCER_KEY}/POST_EMPLOYEE_ATTENDANCE_FAILURE`,

    GET_CARD_COUNT_IN_ATTENDANCE_REGISTER: `${STATE_REDUCER_KEY}/GET_CARD_COUNT_IN_ATTENDANCE_REGISTER`,
    GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_REQUEST: `${STATE_REDUCER_KEY}/GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_REQUEST`,
    GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_SUCCESS: `${STATE_REDUCER_KEY}/GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_SUCCESS`,
    GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_FAILURE: `${STATE_REDUCER_KEY}/GET_CARD_COUNT_IN_ATTENDANCE_REGISTER_FAILURE`,

    FETCH_EMPLOYEE_ATTENDANCE_LIST: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_ATTENDANCE_LIST`,
    FETCH_EMPLOYEE_ATTENDANCE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_ATTENDANCE_LIST_REQUEST`,
    FETCH_EMPLOYEE_ATTENDANCE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_ATTENDANCE_LIST_SUCCESS`,
    FETCH_EMPLOYEE_ATTENDANCE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_ATTENDANCE_LIST_FAILURE`,

    GET_ATTENDANCE_TYPE_DROPDOWN: `${STATE_REDUCER_KEY}/GET_ATTENDANCE_TYPE_DROPDOWN`,
    GET_ATTENDANCE_TYPE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_ATTENDANCE_TYPE_DROPDOWN_REQUEST`,
    GET_ATTENDANCE_TYPE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_ATTENDANCE_TYPE_DROPDOWN_SUCCESS`,
    GET_ATTENDANCE_TYPE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_ATTENDANCE_TYPE_DROPDOWN_FAILURE`,

    PATCH_EMPLOYEE_ATTENDANCE: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_ATTENDANCE`,
    PATCH_EMPLOYEE_ATTENDANCE_REQUEST: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_ATTENDANCE_REQUEST`,
    PATCH_EMPLOYEE_ATTENDANCE_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_ATTENDANCE_SUCCESS`,
    PATCH_EMPLOYEE_ATTENDANCE_FAILURE: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_ATTENDANCE_FAILURE`,

    GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID`,
    GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_REQUEST`,
    GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_SUCCESS`,
    GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID_FAILURE`,

    DOWNLOAD_ATTENDANCE_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_ATTENDANCE_REPORT`,
    DOWNLOAD_ATTENDANCE_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_ATTENDANCE_REPORT_REQUEST`,
    DOWNLOAD_ATTENDANCE_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_ATTENDANCE_REPORT_SUCCESS`,
    DOWNLOAD_ATTENDANCE_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_ATTENDANCE_REPORT_FAILURE`,

    FETCH_RECOVERY_REGISTER_LIST: `${STATE_REDUCER_KEY}/FETCH_RECOVERY_REGISTER_LIST`,
    FETCH_RECOVERY_REGISTER_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_RECOVERY_REGISTER_LIST_REQUEST`,
    FETCH_RECOVERY_REGISTER_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_RECOVERY_REGISTER_LIST_SUCCESS`,
    FETCH_RECOVERY_REGISTER_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_RECOVERY_REGISTER_LIST_FAILURE`,

    DOWNLOAD_RECOVERY_REGISTER: `${STATE_REDUCER_KEY}/DOWNLOAD_RECOVERY_REGISTER`,
    DOWNLOAD_RECOVERY_REGISTER_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_RECOVERY_REGISTER_REQUEST`,
    DOWNLOAD_RECOVERY_REGISTER_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_RECOVERY_REGISTER_SUCCESS`,
    DOWNLOAD_RECOVERY_REGISTER_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_RECOVERY_REGISTER_FAILURE`,

    FETCH_MATERNITY_LEAVE_LIST: `${STATE_REDUCER_KEY}/FETCH_MATERNITY_LEAVE_LIST`,
    FETCH_MATERNITY_LEAVE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MATERNITY_LEAVE_LIST_REQUEST`,
    FETCH_MATERNITY_LEAVE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MATERNITY_LEAVE_LIST_SUCCESS`,
    FETCH_MATERNITY_LEAVE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MATERNITY_LEAVE_LIST_FAILURE`,

    FETCH_EMPLOYEE_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_DROPDOWN`,
    FETCH_EMPLOYEE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_DROPDOWN_REQUEST`,
    FETCH_EMPLOYEE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_DROPDOWN_SUCCESS`,
    FETCH_EMPLOYEE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EMPLOYEE_DROPDOWN_FAILURE`,

    FETCH_RECOVERY_TYPE_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_RECOVERY_TYPE_DROPDOWN`,
    FETCH_RECOVERY_TYPE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_RECOVERY_TYPE_DROPDOWN_REQUEST`,
    FETCH_RECOVERY_TYPE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_RECOVERY_TYPE_DROPDOWN_SUCCESS`,
    FETCH_RECOVERY_TYPE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_RECOVERY_TYPE_DROPDOWN_FAILURE`,

    POST_RECOVERY_DETAILS: `${STATE_REDUCER_KEY}/POST_RECOVERY_DETAILS`,
    POST_RECOVERY_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_RECOVERY_DETAILS_REQUEST`,
    POST_RECOVERY_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_RECOVERY_DETAILS_SUCCESS`,
    POST_RECOVERY_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_RECOVERY_DETAILS_FAILURE`,

    INCIDENCE_EMPLOYEE_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/INCIDENCE_EMPLOYEE_DETAILS_BY_ID`,
    INCIDENCE_EMPLOYEE_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/INCIDENCE_EMPLOYEE_DETAILS_BY_ID_REQUEST`,
    INCIDENCE_EMPLOYEE_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/INCIDENCE_EMPLOYEE_DETAILS_BY_ID_SUCCESS`,
    INCIDENCE_EMPLOYEE_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/INCIDENCE_EMPLOYEE_DETAILS_BY_ID_FAILURE`,

    RECOVERY_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/RECOVERY_DETAILS_BY_ID`,
    RECOVERY_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/RECOVERY_DETAILS_BY_ID_REQUEST`,
    RECOVERY_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/RECOVERY_DETAILS_BY_ID_SUCCESS`,
    RECOVERY_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/RECOVERY_DETAILS_BY_ID_FAILURE`,

    FETCH_CAUSED_ISSUE_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_CAUSED_ISSUE_DROPDOWN`,
    FETCH_CAUSED_ISSUE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CAUSED_ISSUE_DROPDOWN_REQUEST`,
    FETCH_CAUSED_ISSUE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CAUSED_ISSUE_DROPDOWN_SUCCESS`,
    FETCH_CAUSED_ISSUE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CAUSED_ISSUE_DROPDOWN_FAILURE`,

    FETCH_MATERNITY_TYPE_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_MATERNITY_TYPE_DROPDOWN`,
    FETCH_MATERNITY_TYPE_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MATERNITY_TYPE_DROPDOWN_REQUEST`,
    FETCH_MATERNITY_TYPE_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MATERNITY_TYPE_DROPDOWN_SUCCESS`,
    FETCH_MATERNITY_TYPE_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MATERNITY_TYPE_DROPDOWN_FAILURE`,

    MATERNITY_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/MATERNITY_DETAILS_BY_ID`,
    MATERNITY_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/MATERNITY_DETAILS_BY_ID_REQUEST`,
    MATERNITY_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/MATERNITY_DETAILS_BY_ID_SUCCESS`,
    MATERNITY_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/MATERNITY_DETAILS_BY_ID_FAILURE`,

    POST_MATERNITY_DETAILS: `${STATE_REDUCER_KEY}/POST_MATERNITY_DETAILS`,
    POST_MATERNITY_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_MATERNITY_DETAILS_REQUEST`,
    POST_MATERNITY_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_MATERNITY_DETAILS_SUCCESS`,
    POST_MATERNITY_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_MATERNITY_DETAILS_FAILURE`,

    PATCH_RECOVERY_DETAILS: `${STATE_REDUCER_KEY}/PATCH_RECOVERY_DETAILS`,
    PATCH_RECOVERY_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_RECOVERY_DETAILS_REQUEST`,
    PATCH_RECOVERY_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_RECOVERY_DETAILS_SUCCESS`,
    PATCH_RECOVERY_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_RECOVERY_DETAILS_FAILURE`,

    PATCH_MATERNITY_DETAILS: `${STATE_REDUCER_KEY}/PATCH_MATERNITY_DETAILS`,
    PATCH_MATERNITY_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_MATERNITY_DETAILS_REQUEST`,
    PATCH_MATERNITY_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_MATERNITY_DETAILS_SUCCESS`,
    PATCH_MATERNITY_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_MATERNITY_DETAILS_FAILURE`,

    DELETE_PROJECT_SITE_DETAILS: `${STATE_REDUCER_KEY}/DELETE_PROJECT_SITE_DETAILS`,
    DELETE_PROJECT_SITE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_PROJECT_SITE_DETAILS_REQUEST`,
    DELETE_PROJECT_SITE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_PROJECT_SITE_DETAILS_SUCCESS`,
    DELETE_PROJECT_SITE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_PROJECT_SITE_DETAILS_FAILURE`,

    GET_VERIFICATION_STATUS_DROPDOWN: `${STATE_REDUCER_KEY}/GET_VERIFICATION_STATUS_DROPDOWN`,
    GET_VERIFICATION_STATUS_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_VERIFICATION_STATUS_DROPDOWN_REQUEST`,
    GET_VERIFICATION_STATUS_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_VERIFICATION_STATUS_DROPDOWN_SUCCESS`,
    GET_VERIFICATION_STATUS_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_VERIFICATION_STATUS_DROPDOWN_FAILURE`,

    PATCH_WAGE_VERIFICATION: `${STATE_REDUCER_KEY}/PATCH_WAGE_VERIFICATION`,
    PATCH_WAGE_VERIFICATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WAGE_VERIFICATION_REQUEST`,
    PATCH_WAGE_VERIFICATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WAGE_VERIFICATION_SUCCESS`,
    PATCH_WAGE_VERIFICATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WAGE_VERIFICATION_FAILURE`,

    FETCH_EVENTS_TRACKED_MINIMUM_WAGE: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_MINIMUM_WAGE`,
    FETCH_EVENTS_TRACKED_MINIMUM_WAGE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_MINIMUM_WAGE_REQUEST`,
    FETCH_EVENTS_TRACKED_MINIMUM_WAGE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_MINIMUM_WAGE_SUCCESS`,
    FETCH_EVENTS_TRACKED_MINIMUM_WAGE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_MINIMUM_WAGE_FAILURE`,

    PATCH_BASIC_FACILITIES_VERIFICATION: `${STATE_REDUCER_KEY}/PATCH_BASIC_FACILITIES_VERIFICATION`,
    PATCH_BASIC_FACILITIES_VERIFICATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_BASIC_FACILITIES_VERIFICATION_REQUEST`,
    PATCH_BASIC_FACILITIES_VERIFICATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_BASIC_FACILITIES_VERIFICATION_SUCCESS`,
    PATCH_BASIC_FACILITIES_VERIFICATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_BASIC_FACILITIES_VERIFICATION_FAILURE`,

    FETCH_EVENTS_TRACKED_BASIC_FACILITIES: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_BASIC_FACILITIES`,
    FETCH_EVENTS_TRACKED_BASIC_FACILITIES_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_BASIC_FACILITIES_REQUEST`,
    FETCH_EVENTS_TRACKED_BASIC_FACILITIES_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_BASIC_FACILITIES_SUCCESS`,
    FETCH_EVENTS_TRACKED_BASIC_FACILITIES_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_BASIC_FACILITIES_FAILURE`,

    PATCH_WAGE_DETAILS_VERIFICATION: `${STATE_REDUCER_KEY}/PATCH_WAGE_DETAILS_VERIFICATION`,
    PATCH_WAGE_DETAILS_VERIFICATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WAGE_DETAILS_VERIFICATION_REQUEST`,
    PATCH_WAGE_DETAILS_VERIFICATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WAGE_DETAILS_VERIFICATION_SUCCESS`,
    PATCH_WAGE_DETAILS_VERIFICATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WAGE_DETAILS_VERIFICATION_FAILURE`,

    FETCH_EVENTS_TRACKED_WAGE_DETAILS: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WAGE_DETAILS`,
    FETCH_EVENTS_TRACKED_WAGE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WAGE_DETAILS_REQUEST`,
    FETCH_EVENTS_TRACKED_WAGE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WAGE_DETAILS_SUCCESS`,
    FETCH_EVENTS_TRACKED_WAGE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WAGE_DETAILS_FAILURE`,

    PATCH_WAGE_REGISTER_VERIFICATION: `${STATE_REDUCER_KEY}/PATCH_WAGE_REGISTER_VERIFICATION`,
    PATCH_WAGE_REGISTER_VERIFICATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WAGE_REGISTER_VERIFICATION_REQUEST`,
    PATCH_WAGE_REGISTER_VERIFICATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WAGE_REGISTER_VERIFICATION_SUCCESS`,
    PATCH_WAGE_REGISTER_VERIFICATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WAGE_REGISTER_VERIFICATION_FAILURE`,

    FETCH_EVENTS_TRACKED_WAGE_REGISTER: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WAGE_REGISTER`,
    FETCH_EVENTS_TRACKED_WAGE_REGISTER_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WAGE_REGISTER_REQUEST`,
    FETCH_EVENTS_TRACKED_WAGE_REGISTER_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WAGE_REGISTER_SUCCESS`,
    FETCH_EVENTS_TRACKED_WAGE_REGISTER_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WAGE_REGISTER_FAILURE`,

    PATCH_ATTENDANCE_REGISTER_VERIFICATION: `${STATE_REDUCER_KEY}/PATCH_ATTENDANCE_REGISTER_VERIFICATION`,
    PATCH_ATTENDANCE_REGISTER_VERIFICATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_ATTENDANCE_REGISTER_VERIFICATION_REQUEST`,
    PATCH_ATTENDANCE_REGISTER_VERIFICATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_ATTENDANCE_REGISTER_VERIFICATION_SUCCESS`,
    PATCH_ATTENDANCE_REGISTER_VERIFICATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_ATTENDANCE_REGISTER_VERIFICATION_FAILURE`,

    FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER`,
    FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_REQUEST`,
    FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_SUCCESS`,
    FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER_FAILURE`,

    PATCH_MATERNITY_LEAVE_VERIFICATION: `${STATE_REDUCER_KEY}/PATCH_MATERNITY_LEAVE_VERIFICATION`,
    PATCH_MATERNITY_LEAVE_VERIFICATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_MATERNITY_LEAVE_VERIFICATION_REQUEST`,
    PATCH_MATERNITY_LEAVE_VERIFICATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_MATERNITY_LEAVE_VERIFICATION_SUCCESS`,
    PATCH_MATERNITY_LEAVE_VERIFICATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_MATERNITY_LEAVE_VERIFICATION_FAILURE`,

    FETCH_EVENTS_TRACKED_MATERNITY_LEAVE: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_MATERNITY_LEAVE`,
    FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_REQUEST`,
    FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_SUCCESS`,
    FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_MATERNITY_LEAVE_FAILURE`,

    PATCH_RECOVERY_REGISTER_VERIFICATION: `${STATE_REDUCER_KEY}/PATCH_RECOVERY_REGISTER_VERIFICATION`,
    PATCH_RECOVERY_REGISTER_VERIFICATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_RECOVERY_REGISTER_VERIFICATION_REQUEST`,
    PATCH_RECOVERY_REGISTER_VERIFICATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_RECOVERY_REGISTER_VERIFICATION_SUCCESS`,
    PATCH_RECOVERY_REGISTER_VERIFICATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_RECOVERY_REGISTER_VERIFICATION_FAILURE`,

    FETCH_EVENTS_TRACKED_RECOVERY_REGISTER: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_RECOVERY_REGISTER`,
    FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_REQUEST`,
    FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_SUCCESS`,
    FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_RECOVERY_REGISTER_FAILURE`,

    PATCH_WORKPLACE_ACCIDENT_VERIFICATION: `${STATE_REDUCER_KEY}/PATCH_WORKPLACE_ACCIDENT_VERIFICATION`,
    PATCH_WORKPLACE_ACCIDENT_VERIFICATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WORKPLACE_ACCIDENT_VERIFICATION_REQUEST`,
    PATCH_WORKPLACE_ACCIDENT_VERIFICATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WORKPLACE_ACCIDENT_VERIFICATION_SUCCESS`,
    PATCH_WORKPLACE_ACCIDENT_VERIFICATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WORKPLACE_ACCIDENT_VERIFICATION_FAILURE`,

    FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT`,
    FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_REQUEST`,
    FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_SUCCESS`,
    FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT_FAILURE`,

    PATCH_HARASSMENT_COMPLAINT_VERIFICATION: `${STATE_REDUCER_KEY}/PATCH_HARASSMENT_COMPLAINT_VERIFICATION`,
    PATCH_HARASSMENT_COMPLAINT_VERIFICATION_REQUEST: `${STATE_REDUCER_KEY}/PATCH_HARASSMENT_COMPLAINT_VERIFICATION_REQUEST`,
    PATCH_HARASSMENT_COMPLAINT_VERIFICATION_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_HARASSMENT_COMPLAINT_VERIFICATION_SUCCESS`,
    PATCH_HARASSMENT_COMPLAINT_VERIFICATION_FAILURE: `${STATE_REDUCER_KEY}/PATCH_HARASSMENT_COMPLAINT_VERIFICATION_FAILURE`,

    FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT`,
    FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_REQUEST`,
    FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_SUCCESS`,
    FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT_FAILURE`,

    PATCH_NOTICE_BOARD_DPMU_REMARKS: `${STATE_REDUCER_KEY}/PATCH_NOTICE_BOARD_DPMU_REMARKS`,
    PATCH_NOTICE_BOARD_DPMU_REMARKS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_NOTICE_BOARD_DPMU_REMARKS_REQUEST`,
    PATCH_NOTICE_BOARD_DPMU_REMARKS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_NOTICE_BOARD_DPMU_REMARKS_SUCCESS`,
    PATCH_NOTICE_BOARD_DPMU_REMARKS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_NOTICE_BOARD_DPMU_REMARKS_FAILURE`,

    PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS: `${STATE_REDUCER_KEY}/PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS`,
    PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_REQUEST`,
    PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_SUCCESS`,
    PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS_FAILURE`,

    PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS`,
    PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_REQUEST`,
    PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_SUCCESS`,
    PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS_FAILURE`,

    PATCH_EMPLOYEE_DPMU_REMARKS: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_DPMU_REMARKS`,
    PATCH_EMPLOYEE_DPMU_REMARKS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_DPMU_REMARKS_REQUEST`,
    PATCH_EMPLOYEE_DPMU_REMARKS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_DPMU_REMARKS_SUCCESS`,
    PATCH_EMPLOYEE_DPMU_REMARKS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_EMPLOYEE_DPMU_REMARKS_FAILURE`

};

export const fetchProjectList = createAction(ACTION_TYPES.FETCH_PROJECT_LIST);
export const fetchProjectSiteList = createAction(ACTION_TYPES.FETCH_PROJECT_SITE_LIST);
export const fetchNatureOfWorkDropdown = createAction(ACTION_TYPES.FETCH_NATURE_OF_WORK_DROPDOWN);
export const fetchWagePeriodDropdown = createAction(ACTION_TYPES.FETCH_WAGE_PERIOD_DROPDOWN);
export const fetchDayOfHolidayDropdown = createAction(ACTION_TYPES.FETCH_DAY_OF_HOLIDAY_DROPDOWN);
export const fetchDistrictDropdown = createAction(ACTION_TYPES.FETCH_DISTRICT_DROPDOWN);
export const fetchUlbDropdown = createAction(ACTION_TYPES.FETCH_ULB_DROPDOWN);
export const fetchBasicDetailsById = createAction(ACTION_TYPES.FETCH_BASIC_DETAILS_BY_ID);
export const postBasicDetails = createAction(ACTION_TYPES.POST_BASIC_DETAILS);
export const patchBasicDetails = createAction(ACTION_TYPES.PATCH_BASIC_DETAILS);
export const fetchProjectManagerDetailsById = createAction(ACTION_TYPES.FETCH_PROJECT_MANAGER_DETAILS_BY_ID);
export const postProjectManagerDetails = createAction(ACTION_TYPES.POST_PROJECT_MANAGER_DETAILS);
export const fetchProjectManagerList = createAction(ACTION_TYPES.FETCH_PROJECT_MANAGER_LIST);
export const patchProjectManagerList = createAction(ACTION_TYPES.PATCH_PROJECT_MANAGER_DETAILS);
export const fetchMinimumWages = createAction(ACTION_TYPES.FETCH_MINIMUM_WAGE);
export const fetchMinimumWagesById = createAction(ACTION_TYPES.FETCH_MINIMUM_WAGE_BY_ID);
export const postMinimumWages = createAction(ACTION_TYPES.POST_MINIMUM_WAGE);
export const fetchRegistrationNumber = createAction(ACTION_TYPES.FETCH_REGISTRATION_NUMBER);
export const fetchEmployeeList = createAction(ACTION_TYPES.FETCH_EMPLOYEE_LIST);
export const fetchGenderDropdown = createAction(ACTION_TYPES.FETCH_GENDER_DROPDOWN);
export const fetchNationalityDropdown = createAction(ACTION_TYPES.FETCH_NATIONALITY_DROPDOWN);
export const fetchEducationLevelDropdown = createAction(ACTION_TYPES.FETCH_EDUCATION_LEVEL_DROPDOWN);
export const fetchDesignationDropdown = createAction(ACTION_TYPES.FETCH_DESIGNATION_DROPDOWN);
export const fetchCategoryDropdown = createAction(ACTION_TYPES.FETCH_CATEGORY_DROPDOWN);
export const fetchEmploymentDropdown = createAction(ACTION_TYPES.FETCH_EMPLOYMENT_DROPDOWN);
export const fetchSubContractorDropdown = createAction(ACTION_TYPES.FETCH_SUB_CONTRACTOR_DROPDOWN);
export const fetchWorkmanRadioButton = createAction(ACTION_TYPES.FETCH_WORKMAN_RADIO_BUTTON);
export const fetchEmployeeDetails = createAction(ACTION_TYPES.EMPLOYEE_DETAILS_BY_ID);
export const fetchMaritalStatusRadioButton = createAction(ACTION_TYPES.FETCH_MARITAL_STATUS_RADIO_BUTTON);
export const postEmployee = createAction(ACTION_TYPES.POST_EMPLOYEE_DETAILS);
export const patchEmployee = createAction(ACTION_TYPES.PATCH_EMPLOYEE_DETAILS);
export const deleteEmployee = createAction(ACTION_TYPES.DELETE_EMPLOYEE_DETAILS);
export const fetchRegistrationNumberById = createAction(ACTION_TYPES.FETCH_REGISTRATION_NUMBER_BY_ID);
export const patchRegistrationNumber = createAction(ACTION_TYPES.PATCH_REGISTRATION_NUMBER);
export const fetchBasicFacilitiesById = createAction(ACTION_TYPES.FETCH_BASIC_FACILITIES_BY_ID);
export const patchBasicFacilities = createAction(ACTION_TYPES.PATCH_BASIC_FACILITIES);
export const fetchGrievanceCommitteeList = createAction(ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_LIST);
export const fetchGrievanceCommitteeById = createAction(ACTION_TYPES.FETCH_GRIEVANCE_COMMITTEE_BY_ID);
export const postGrievanceCommittee = createAction(ACTION_TYPES.POST_GRIEVANCE_COMMITTEE);
export const patchGrievanceCommittee = createAction(ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE);
export const fetchNoticeBoardById = createAction(ACTION_TYPES.FETCH_NOTICE_BOARD_BY_ID);
export const patchNoticeBoard = createAction(ACTION_TYPES.PATCH_NOTICE_BOARD);
export const fetchInternalComplaintCommitteeList = createAction(ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_LIST);
export const fetchComplaintCommitteeDesignationDropdown = createAction(ACTION_TYPES.FETCH_COMPLAINT_COMMITTEE_DESIGNATION_DROPDOWN);
export const fetchInternalComplaintCommitteeById = createAction(ACTION_TYPES.FETCH_INTERNAL_COMPLAINT_COMMITTEE_BY_ID);
export const postComplaintCommitteeMember = createAction(ACTION_TYPES.POST_COMPLAINT_COMMITTEE_MEMBER);
export const patchComplaintCommitteeMember = createAction(ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_MEMBER);
export const fetchWageDetailsList = createAction(ACTION_TYPES.FETCH_WAGE_DETAILS_LIST);
export const postWageDetails = createAction(ACTION_TYPES.POST_WAGE_DETAILS);
export const patchWageDetails = createAction(ACTION_TYPES.PATCH_WAGE_DETAILS);
export const getWageDetailsById = createAction(ACTION_TYPES.GET_WAGE_DETAILS_BY_ID);
export const deleteWageDetails = createAction(ACTION_TYPES.DELETE_WAGE_DETAILS);
export const fetchEmployeeVerificationList = createAction(ACTION_TYPES.FETCH_EMPLOYEE_VERIFICATION);
export const patchEmployeeVerification = createAction(ACTION_TYPES.PATCH_EMPLOYEE_VERIFICATION);
export const postEmployeeTerminationProcess = createAction(ACTION_TYPES.POST_EMPLOYEE_TERMINATION_PROCESS);
export const fetchSiteWageRegister = createAction(ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_LIST);
export const postSiteWageRegister = createAction(ACTION_TYPES.POST_SITE_WAGE_REGISTER);
export const fetchSiteWageRegisterEmployee = createAction(ACTION_TYPES.FETCH_SITE_WAGE_REGISTER_EMPLOYEE_LIST);
export const getSiteWageRegisterEmployeeDetails = createAction(ACTION_TYPES.GET_SITE_WAGE_REGISTER_EMPLOYEE_DETAILS_BY_ID);
export const patchSiteWageEmployeeDetails = createAction(ACTION_TYPES.PATCH_SITE_WAGE_EMPLOYEE_DETAILS);
export const getCardCountInWageRegister = createAction(ACTION_TYPES.GET_CARD_COUNT_IN_WAGE_REGISTER);
export const deleteWageRegister = createAction(ACTION_TYPES.DELETE_WAGE_REGISTER);
export const downloadEmployeePayslip = createAction(ACTION_TYPES.DOWNLOAD_EMPLOYEE_PAYSLIP);
export const workPlaceAccidentList = createAction(ACTION_TYPES.WORKPLACE_ACCIDENT_DETAILS_LIST);
export const employeeNameWithCode = createAction(ACTION_TYPES.GET_EMPLOYEE_NAME_WITH_CODE);
export const employeeDetailsById = createAction(ACTION_TYPES.GET_EMPLOYEE_DETAILS_BY_ID);
export const getFatalDropdown = createAction(ACTION_TYPES.GET_FATAL_DROPDOWN);
export const postWorkplaceAccident = createAction(ACTION_TYPES.POST_WORKPLACE_ACCIDENT_DETAILS);
export const workplaceAccidentById = createAction(ACTION_TYPES.GET_WORKPLACE_ACCIDENT_DETAILS_BY_ID);
export const patchWorkplaceAccident = createAction(ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_DETAILS);
export const fetchHarassmentDetails = createAction(ACTION_TYPES.FETCH_HARASSMENT_DETAILS_LIST);
export const submitNoticeRespondent = createAction(ACTION_TYPES.SUBMIT_NOTICE_RESPONDENT);
export const submitResponse = createAction(ACTION_TYPES.SUBMIT_RESPONSE_FROM_RESPONDENT);
export const submitComplaintSettlement = createAction(ACTION_TYPES.SUBMIT_COMPLAINT_SETTLEMENT);
export const submitHearing = createAction(ACTION_TYPES.SUBMIT_HEARING_DETAILS);
export const accordionDetailsById = createAction(ACTION_TYPES.ACCORDION_DETAILS_BY_ID);
export const postHarassmentComplaints = createAction(ACTION_TYPES.POST_HARASSMENT_COMPLAINTS);
export const responseAccordionById = createAction(ACTION_TYPES.RESPONSE_ACCORDION_BY_ID);
export const complaintAccordionById = createAction(ACTION_TYPES.COMPLAINT_ACCORDION_BY_ID);
export const hearingAccordionById = createAction(ACTION_TYPES.HEARING_ACCORDION_BY_ID);
export const harassmentComplaintById = createAction(ACTION_TYPES.HARASSMENT_COMPLAINT_BY_ID);
export const patchNoticeDetails = createAction(ACTION_TYPES.PATCH_NOTICE_RESPONDENT);
export const patchResponseDetails = createAction(ACTION_TYPES.PATCH_RESPONSE_FROM_RESPONDENT);
export const patchComplaintDetails = createAction(ACTION_TYPES.PATCH_COMPLAINT_SETTLEMENT);
export const patchHearingDetails = createAction(ACTION_TYPES.PATCH_HEARING_DETAILS);
export const patchHarassmentDetails = createAction(ACTION_TYPES.PATCH_HARASSMENT_COMPLAINTS);
export const employeeAttendancePost = createAction(ACTION_TYPES.POST_EMPLOYEE_ATTENDANCE);
export const getCardCountInAttendanceRegister = createAction(ACTION_TYPES.GET_CARD_COUNT_IN_ATTENDANCE_REGISTER);
export const fetchEmployeeAttendance = createAction(ACTION_TYPES.FETCH_EMPLOYEE_ATTENDANCE_LIST);
export const getAttendanceTypeDropdown = createAction(ACTION_TYPES.GET_ATTENDANCE_TYPE_DROPDOWN);
export const patchEmployeeAttendanceDetails = createAction(ACTION_TYPES.PATCH_EMPLOYEE_ATTENDANCE);
export const getEmployeeAttendanceById = createAction(ACTION_TYPES.GET_EMPLOYEE_ATTENDANCE_ATTENDANCE_BY_ID);
export const downloadAttendanceReport = createAction(ACTION_TYPES.DOWNLOAD_ATTENDANCE_REPORT);
export const fetchRecoveryRegisterList = createAction(ACTION_TYPES.FETCH_RECOVERY_REGISTER_LIST);
export const downloadRecoveryRegister = createAction(ACTION_TYPES.DOWNLOAD_RECOVERY_REGISTER);
export const fetchEmployeeDropdown = createAction(ACTION_TYPES.FETCH_EMPLOYEE_DROPDOWN);
export const fetchRecoveryTypesDropdown = createAction(ACTION_TYPES.FETCH_RECOVERY_TYPE_DROPDOWN);
export const postRecoveryDetail = createAction(ACTION_TYPES.POST_RECOVERY_DETAILS);
export const fetchIncidentEmployeeDetailsById = createAction(ACTION_TYPES.INCIDENCE_EMPLOYEE_DETAILS_BY_ID);
export const fetchRecoveryDetailsById = createAction(ACTION_TYPES.RECOVERY_DETAILS_BY_ID);
export const fetchCausedIssueDropdown = createAction(ACTION_TYPES.FETCH_CAUSED_ISSUE_DROPDOWN);
export const fetchMaternityTypesDropdown = createAction(ACTION_TYPES.FETCH_MATERNITY_TYPE_DROPDOWN);
export const fetchMaternityDetailById = createAction(ACTION_TYPES.MATERNITY_DETAILS_BY_ID);
export const fetchMaternityLeaveList = createAction(ACTION_TYPES.FETCH_MATERNITY_LEAVE_LIST);
export const postMaternityDetail = createAction(ACTION_TYPES.POST_MATERNITY_DETAILS);
export const patchRecoveryDetail = createAction(ACTION_TYPES.PATCH_RECOVERY_DETAILS);
export const patchMaternityDetail = createAction(ACTION_TYPES.PATCH_MATERNITY_DETAILS);
export const deleteProjectSiteDetails = createAction(ACTION_TYPES.DELETE_PROJECT_SITE_DETAILS);
export const getVerificationDropdown = createAction(ACTION_TYPES.GET_VERIFICATION_STATUS_DROPDOWN);
export const patchWageVerification = createAction(ACTION_TYPES.PATCH_WAGE_VERIFICATION);
export const fetchEventsTrackedMinWage = createAction(ACTION_TYPES.FETCH_EVENTS_TRACKED_MINIMUM_WAGE);
export const patchBasicFacilitiesVerification = createAction(ACTION_TYPES.PATCH_BASIC_FACILITIES_VERIFICATION);
export const fetchEventsTrackedBasicFacilities = createAction(ACTION_TYPES.FETCH_EVENTS_TRACKED_BASIC_FACILITIES);
export const patchWgeDetailsVerification = createAction(ACTION_TYPES.PATCH_WAGE_DETAILS_VERIFICATION);
export const fetchEventsTrackedWageDetails = createAction(ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_DETAILS);
export const patchWgeRegisterVerification = createAction(ACTION_TYPES.PATCH_WAGE_REGISTER_VERIFICATION);
export const fetchEventsTrackedWageRegister = createAction(ACTION_TYPES.FETCH_EVENTS_TRACKED_WAGE_REGISTER);
export const patchAttendanceRegisterVerification = createAction(ACTION_TYPES.PATCH_ATTENDANCE_REGISTER_VERIFICATION);
export const fetchEventsTrackedAttendanceRegister = createAction(ACTION_TYPES.FETCH_EVENTS_TRACKED_ATTENDANCE_REGISTER);
export const patchMaternityVerification = createAction(ACTION_TYPES.PATCH_MATERNITY_LEAVE_VERIFICATION);
export const fetchEventsTrackedMaternityLeave = createAction(ACTION_TYPES.FETCH_EVENTS_TRACKED_MATERNITY_LEAVE);
export const patchRecoveryRegisterVerification = createAction(ACTION_TYPES.PATCH_RECOVERY_REGISTER_VERIFICATION);
export const fetchEventsTrackedRecoveryRegister = createAction(ACTION_TYPES.FETCH_EVENTS_TRACKED_RECOVERY_REGISTER);
export const patchWorkPlaceAccidentVerification = createAction(ACTION_TYPES.PATCH_WORKPLACE_ACCIDENT_VERIFICATION);
export const fetchEventsTrackedWorkplaceAccident = createAction(ACTION_TYPES.FETCH_EVENTS_TRACKED_WORKPLACE_ACCIDENT);
export const patchHarassmentComplaintVerification = createAction(ACTION_TYPES.PATCH_HARASSMENT_COMPLAINT_VERIFICATION);
export const fetchEventsTrackedHarassmentComplaint = createAction(ACTION_TYPES.FETCH_EVENTS_TRACKED_HARASSMENT_COMPLAINT);
export const patchNoticeBoardDpmuRemarks = createAction(ACTION_TYPES.PATCH_NOTICE_BOARD_DPMU_REMARKS);
export const patchGrievanceCommitteeDpmuRemarks = createAction(ACTION_TYPES.PATCH_GRIEVANCE_COMMITTEE_DPMU_REMARKS);
export const patchComplaintCommitteeDpmuRemarks = createAction(ACTION_TYPES.PATCH_COMPLAINT_COMMITTEE_DPMU_REMARKS);
export const patchEmployeeDpmuRemarks = createAction(ACTION_TYPES.PATCH_EMPLOYEE_DPMU_REMARKS);
