import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const initialState = {
    womenGroupDropdown: {
        requestInProgress: false,
        data: []
    },
    secondaryCollectionById: {
        requestInProgress: false,
        data: {
            id: "",
            districtId: "",
            lsgiId: "",
            nameOfAgency: "",
            agencyCode: "",
            location: "",
            latitude: "",
            longitude: "",
            womenGroup: "",
            collectionPerDay: "",
            area: "",
            storageFacility: "",
            segregationFacility: "",
            collectionDate: null,
            total: "",
            male: "",
            female: "",
            transgender: "",
            sc: "",
            st: "",
            disabled: "",
            seniorCitizen: ""
        }
    },
    agencyNameDropdown: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        setSecondaryCollection: (state, action) => {
            state.secondaryCollectionById.data = action.payload;
        },
        clearSecondaryCollection: (state) => {
            state.secondaryCollectionById.data = initialState.secondaryCollectionById.data;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_WOMEN_GROUP_DROPDOWN_REQUEST, (state) => {
                state.womenGroupDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_WOMEN_GROUP_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "womenGroupDropdown.requestInProgress", false);
                _.set(state, "womenGroupDropdown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_WOMEN_GROUP_DROPDOWN_FAILURE, (state) => {
                state.womenGroupDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_SECONDARY_COLLECTION_BY_ID_REQUEST, (state) => {
                state.secondaryCollectionById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_SECONDARY_COLLECTION_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "secondaryCollectionById.requestInProgress", false);
                _.set(state, "secondaryCollectionById.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_SECONDARY_COLLECTION_BY_ID_FAILURE, (state) => {
                state.secondaryCollectionById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.AGENCY_NAME_DROPDOWN_REQUEST, (state) => {
                state.agencyNameDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.AGENCY_NAME_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "agencyNameDropdown.requestInProgress", false);
                _.set(state, "agencyNameDropdown.data", payload?.content);
            })
            .addCase(ACTION_TYPES.AGENCY_NAME_DROPDOWN_FAILURE, (state) => {
                state.agencyNameDropdown.requestInProgress = false;
            });
    }

});

export const { actions, reducer } = slice;
