import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchWasteProcessingListApi = (data) => {
    return {
        url: API_URL.WASTE_PROCESSING.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WASTE_PROCESSING_LIST_REQUEST, ACTION_TYPES.FETCH_WASTE_PROCESSING_LIST_SUCCESS, ACTION_TYPES.FETCH_WASTE_PROCESSING_LIST_FAILURE], data
        }
    };
};

export const submitWasteProcessingApi = (data) => ({
    url: API_URL.WASTE_PROCESSING.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_WASTE_PROCESSING_REQUEST, ACTION_TYPES.POST_WASTE_PROCESSING_SUCCESS, ACTION_TYPES.POST_WASTE_PROCESSING_FAILURE],
        data
    }
});

export const updateWasteProcessingApi = (data) => ({
    url: API_URL.WASTE_PROCESSING.SAVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_WASTE_PROCESSING_REQUEST, ACTION_TYPES.PATCH_WASTE_PROCESSING_SUCCESS, ACTION_TYPES.PATCH_WASTE_PROCESSING_FAILURE],
        data
    }
});

export const fetchWasteProcessingByIdApi = (id) => {
    return {
        url: API_URL.WASTE_PROCESSING.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WASTE_PROCESSING_BY_ID_REQUEST, ACTION_TYPES.FETCH_WASTE_PROCESSING_BY_ID_SUCCESS, ACTION_TYPES.FETCH_WASTE_PROCESSING_BY_ID_FAILURE]
        }
    };
};

export const deleteWasteProcessingApi = (id) => ({
    url: API_URL.WASTE_PROCESSING.DELETE.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
        types: [ACTION_TYPES.DELETE_WASTE_PROCESSING_REQUEST, ACTION_TYPES.DELETE_WASTE_PROCESSING_SUCCESS, ACTION_TYPES.DELETE_WASTE_PROCESSING_FAILURE]
    }
});

export const fetchSiteNameDropdownApi = () => {
    return {
        url: API_URL.WASTE_PROCESSING.SITE_NAME_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_SITE_NAME_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_SITE_NAME_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_SITE_NAME_DROPDOWN_FAILURE]
        }
    };
};
