import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadContentDetails } from "utils/apiUtils";

import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const DESIGNATION_ENTRY_DETAILS = {
    name: "",
    description: ""
};

const initialState = {
    designationEntryGetById: {
        requestInProgress: false,
        data: {
            ...DESIGNATION_ENTRY_DETAILS
        }
    },
    updateDesignationEntry: {
        requestInProgress: false,
        data: []
    },
    nameList: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.designationEntryGetById = initialState.designationEntryGetById;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.DESIGNATION_ENTRY_BY_ID_REQUEST, (state) => {
                state.designationEntryGetById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.DESIGNATION_ENTRY_BY_ID_SUCCESS, (state, action) => {
                let data = getPayloadContentDetails(action);
                _.set(state, "designationEntryGetById.requestInProgress", false);
                _.set(state, "designationEntryGetById.data", data);
            })
            .addCase(ACTION_TYPES.DESIGNATION_ENTRY_BY_ID_FAILURE, (state) => {
                state.resourceManagementGetById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "nameList.requestInProgress", false);
                _.set(state, "nameList.data", payload.content);
            });
    }
});

export const { actions, reducer } = slice;
