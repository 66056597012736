import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_WASTE_DISPOSAL_REGIONAL_LIST: `${STATE_REDUCER_KEY}/FETCH_WASTE_DISPOSAL_REGIONAL_LIST`,
    FETCH_WASTE_DISPOSAL_REGIONAL_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WASTE_DISPOSAL_REGIONAL_LIST_REQUEST`,
    FETCH_WASTE_DISPOSAL_REGIONAL_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WASTE_DISPOSAL_REGIONAL_LIST_SUCCESS`,
    FETCH_WASTE_DISPOSAL_REGIONAL_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WASTE_DISPOSAL_REGIONAL_LIST_FAILURE`,

    FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID: `${STATE_REDUCER_KEY}/FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID`,
    FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_REQUEST`,
    FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_SUCCESS`,
    FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID_FAILURE`,

    POST_WASTE_DISPOSAL_REGIONAL: `${STATE_REDUCER_KEY}/POST_WASTE_DISPOSAL_REGIONAL`,
    POST_WASTE_DISPOSAL_REGIONAL_REQUEST: `${STATE_REDUCER_KEY}/POST_WASTE_DISPOSAL_REGIONAL_REQUEST`,
    POST_WASTE_DISPOSAL_REGIONAL_SUCCESS: `${STATE_REDUCER_KEY}/POST_WASTE_DISPOSAL_REGIONAL_SUCCESS`,
    POST_WASTE_DISPOSAL_REGIONAL_FAILURE: `${STATE_REDUCER_KEY}/POST_WASTE_DISPOSAL_REGIONAL_FAILURE`,

    PATCH_WASTE_DISPOSAL_REGIONAL: `${STATE_REDUCER_KEY}/PATCH_WASTE_DISPOSAL_REGIONAL`,
    PATCH_WASTE_DISPOSAL_REGIONAL_REQUEST: `${STATE_REDUCER_KEY}/PATCH_WASTE_DISPOSAL_REGIONAL_REQUEST`,
    PATCH_WASTE_DISPOSAL_REGIONAL_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_WASTE_DISPOSAL_REGIONAL_SUCCESS`,
    PATCH_WASTE_DISPOSAL_REGIONAL_FAILURE: `${STATE_REDUCER_KEY}/PATCH_WASTE_DISPOSAL_REGIONAL_FAILURE`,

    DELETE_WASTE_DISPOSAL_REGIONAL: `${STATE_REDUCER_KEY}/DELETE_WASTE_DISPOSAL_REGIONAL`,
    DELETE_WASTE_DISPOSAL_REGIONAL_REQUEST: `${STATE_REDUCER_KEY}/DELETE_WASTE_DISPOSAL_REGIONAL_REQUEST`,
    DELETE_WASTE_DISPOSAL_REGIONAL_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_WASTE_DISPOSAL_REGIONAL_SUCCESS`,
    DELETE_WASTE_DISPOSAL_REGIONAL_FAILURE: `${STATE_REDUCER_KEY}/DELETE_WASTE_DISPOSAL_REGIONAL_FAILURE`,

    FETCH_NAME_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_NAME_DROPDOWN`,
    FETCH_NAME_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_DROPDOWN_REQUEST`,
    FETCH_NAME_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_DROPDOWN_SUCCESS`,
    FETCH_NAME_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_DROPDOWN_FAILURE`,

    DOWNLOAD_WASTE_DISPOSAL_REGIONAL_LIST: `${STATE_REDUCER_KEY}/DOWNLOAD_WASTE_DISPOSAL_REGIONAL_LIST`,
    DOWNLOAD_WASTE_DISPOSAL_REGIONAL_LIST_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_WASTE_DISPOSAL_REGIONAL_LIST_REQUEST`,
    DOWNLOAD_WASTE_DISPOSAL_REGIONAL_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_WASTE_DISPOSAL_REGIONAL_LIST_SUCCESS`,
    DOWNLOAD_WASTE_DISPOSAL_REGIONAL_LIST_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_WASTE_DISPOSAL_REGIONAL_LIST_FAILURE`
};

export const fetchWasteDisposalRegionalList = createAction(ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_LIST);
export const fetchWasteDisposalRegionalById = createAction(ACTION_TYPES.FETCH_WASTE_DISPOSAL_REGIONAL_BY_ID);
export const postWasteDisposalRegional = createAction(ACTION_TYPES.POST_WASTE_DISPOSAL_REGIONAL);
export const patchWasteDisposalRegional = createAction(ACTION_TYPES.PATCH_WASTE_DISPOSAL_REGIONAL);
export const deleteWasteDisposalRegional = createAction(ACTION_TYPES.DELETE_WASTE_DISPOSAL_REGIONAL);
export const fetchNameDropdown = createAction(ACTION_TYPES.FETCH_NAME_DROPDOWN);
export const downloadWasteDisposalRegionalList = createAction(ACTION_TYPES.DOWNLOAD_WASTE_DISPOSAL_REGIONAL_LIST);
