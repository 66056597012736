import { I18n } from "common/components";
import { lazy } from "react";
const Login = lazy(() => import("./register/components/Login"));
const Stepper = lazy(() => import("./register/components/stepper"));
const PrivacyAndPolicy = lazy(() => import("./privacyAndPolicy/components/PrivacyAndPolicy"));
const TermsAndConditions = lazy(() => import("./privacyAndPolicy/components/TermsAndConditions"));

let completed = [true, false, false];

export const routes = [

    {
        title: I18n("login"),
        path: "login",
        element: <Login />
    },
    {
        path: "reset-password",
        element: <Stepper completed={completed} />
    },
    {
        path: "sign-up",
        element: <Stepper completed={completed} />
    },
    {
        path: "privacy",
        element: <PrivacyAndPolicy />
    },
    {
        path: "terms-conditions",
        element: <TermsAndConditions />
    }
];
