import { ACTION_TYPES } from "./actions";
import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "modules/subProjects/apiUrls";

export const lsgiDropdownApi = (districtId) => ({
    url: API_URL.COMMON.LIST_ULB_DROPDOWN,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [
            ACTION_TYPES.FETCH_LSGI_DROPDOWN_REQUEST,
            ACTION_TYPES.FETCH_LSGI_DROPDOWN_SUCCESS,
            ACTION_TYPES.FETCH_LSGI_DROPDOWN_FAILURE
        ],
        data: { districtId }
    }
});
export const submitSWMPlanApi = (data) => ({
    url: API_URL.SWM_PLAN.SUBMIT_SWM_PLAN,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [
            ACTION_TYPES.SUBMIT_SWM_PLAN_REQUEST,
            ACTION_TYPES.SUBMIT_SWM_PLAN_SUCCESS,
            ACTION_TYPES.SUBMIT_SWM_PLAN_FAILURE
        ],
        data
    }
});
export const listSWMPlanApi = (data) => ({
    url: API_URL.SWM_PLAN.LIST_SWM_PLAN,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [
            ACTION_TYPES.LIST_SWM_PLAN_REQUEST,
            ACTION_TYPES.LIST_SWM_PLAN_SUCCESS,
            ACTION_TYPES.LIST_SWM_PLAN_FAILURE
        ], data
    }
});
export const listByIdSWMPlanApi = (id) => {
    return {
        url: API_URL.SWM_PLAN.LIST_SWM_PLAN_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.LIST_BY_ID_SWM_PLAN_REQUEST,
                ACTION_TYPES.LIST_BY_ID_SWM_PLAN_SUCCESS,
                ACTION_TYPES.LIST_BY_ID_SWM_PLAN_FAILURE
            ],
            data: { id }

        }
    };
};
export const updateSWMPlanApi = (data) => ({
    url: API_URL.SWM_PLAN.EDIT_SWM_PLAN,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [
            ACTION_TYPES.EDIT_SWM_PLAN_REQUEST,
            ACTION_TYPES.EDIT_SWM_PLAN_SUCCESS,
            ACTION_TYPES.EDIT_SWM_PLAN_FAILURE
        ], data
    }
});
export const deleteSWMPlanApi = (id) => ({
    url: API_URL.SWM_PLAN.DELETE_SWM_PLAN.replace(":id", id),
    method: REQUEST_METHOD.DELETE,
    payload: {
        types: [
            ACTION_TYPES.DELETE_SWM_PLAN_REQUEST,
            ACTION_TYPES.DELETE_SWM_PLAN_SUCCESS,
            ACTION_TYPES.DELETE_SWM_PLAN_FAILURE
        ]
    }
});

export const deleteFileById = (id, fileId) => {
    let DELETE_API_URL = API_URL.SWM_PLAN.DELETE_FILE_BY_ID;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":file_id", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [
                ACTION_TYPES.DELETE_FILE_REQUEST,
                ACTION_TYPES.DELETE_FILE_SUCCESS,
                ACTION_TYPES.DELETE_FILE_REQUEST
            ]
        }
    };
};
export const fetchDistricts = () => {
    return {
        url: API_URL.DISTRICT.LIST_DISTRICT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_REQUEST,
                ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_SUCCESS,
                ACTION_TYPES.FETCH_DISTRICT_DROPDOWN_FAILURE
            ]
        }
    };
};
export const updateSWMPlanAttachmentApi = (data) => ({
    url: API_URL.SWM_PLAN.EDIT_SWM_PLAN,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [
            ACTION_TYPES.UPDATE_SWM_PLAN_ATTACHMENT_REQUEST,
            ACTION_TYPES.UPDATE_SWM_PLAN_ATTACHMENT_SUCCESS,
            ACTION_TYPES.UPDATE_SWM_PLAN_ATTACHMENT_FAILURE
        ], data
    }
});
export const ulbSubmittedToMcApi = (data) => ({
    url: API_URL.SWM_PLAN.SUBMITTED_TO_MC,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.ULB_SUBMITTED_TO_MC_REQUEST, ACTION_TYPES.ULB_SUBMITTED_TO_MC_SUCCESS, ACTION_TYPES.ULB_SUBMITTED_TO_MC_FAILURE],
        data
    }
});
export const planStatusList = () => {
    return {
        url: API_URL.SWM_PLAN.STATUS_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_STATUS_LIST_REQUEST,
                ACTION_TYPES.FETCH_STATUS_LIST_SUCCESS,
                ACTION_TYPES.FETCH_STATUS_LIST_FAILURE
            ],
            data: { dropDown: true }
        }
    };
};

export const fetchSwmPlanRevisionListApi = (data) => {
    return {
        url: API_URL.SWM_PLAN.LIST_SWM_PLAN_REVISION_BY_SWM_PLAN_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_SWM_PLAN_REVISION_LIST_REQUEST,
                ACTION_TYPES.FETCH_SWM_PLAN_REVISION_LIST_SUCCESS,
                ACTION_TYPES.FETCH_SWM_PLAN_REVISION_LIST_FAILURE
            ],
            data
        }
    };
};

export const fetchPreviousApi = (data) => {
    return {
        url: API_URL.SWM_PLAN.LIST_SWM_PLAN_REVISION_BY_SWM_PLAN_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [
                ACTION_TYPES.FETCH_PREVIOUS_REQUEST,
                ACTION_TYPES.FETCH_PREVIOUS_SUCCESS,
                ACTION_TYPES.FETCH_PREVIOUS_FAILURE
            ],
            data
        }
    };
};
