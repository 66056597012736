import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    LIST_POPULATION_VS_LAND_AREA_REPORT: `${STATE_REDUCER_KEY}/LIST_POPULATION_VS_LAND_AREA_REPORT`,
    LIST_POPULATION_VS_LAND_AREA_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_POPULATION_VS_LAND_AREA_REPORT_REQUEST`,
    LIST_POPULATION_VS_LAND_AREA_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_POPULATION_VS_LAND_AREA_REPORT_SUCCESS`,
    LIST_POPULATION_VS_LAND_AREA_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_POPULATION_VS_LAND_AREA_REPORT_FAILURE`,

    DOWNLOAD_POPULATION_VS_LAND_AREA_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_POPULATION_VS_LAND_AREA_REPORT`,
    DOWNLOAD_POPULATION_VS_LAND_AREA_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_POPULATION_VS_LAND_AREA_REPORT_REQUEST`,
    DOWNLOAD_POPULATION_VS_LAND_AREA_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_POPULATION_VS_LAND_AREA_REPORT_SUCCESS`,
    DOWNLOAD_POPULATION_VS_LAND_AREA_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_POPULATION_VS_LAND_AREA_REPORT_FAILURE`,

    LIST_POPULATION_VS_HOUSE_HOLD_REPORT: `${STATE_REDUCER_KEY}/LIST_POPULATION_VS_HOUSE_HOLD_REPORT`,
    LIST_POPULATION_VS_HOUSE_HOLD_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_POPULATION_VS_HOUSE_HOLD_REPORT_REQUEST`,
    LIST_POPULATION_VS_HOUSE_HOLD_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_POPULATION_VS_HOUSE_HOLD_REPORT_SUCCESS`,
    LIST_POPULATION_VS_HOUSE_HOLD_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_POPULATION_VS_HOUSE_HOLD_REPORT_FAILURE`,

    DOWNLOAD_POPULATION_VS_HOUSE_HOLD_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_POPULATION_VS_HOUSE_HOLD_REPORT`,
    DOWNLOAD_POPULATION_VS_HOUSE_HOLD_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_POPULATION_VS_HOUSE_HOLD_REPORT_REQUEST`,
    DOWNLOAD_POPULATION_VS_HOUSE_HOLD_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_POPULATION_VS_HOUSE_HOLD_REPORT_SUCCESS`,
    DOWNLOAD_POPULATION_VS_HOUSE_HOLD_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_POPULATION_VS_HOUSE_HOLD_REPORT_FAILURE`,

    LIST_LAND_USE: `${STATE_REDUCER_KEY}/LIST_LAND_USE`,
    LIST_LAND_USE_REQUEST: `${STATE_REDUCER_KEY}/LIST_LAND_USE_REQUEST`,
    LIST_LAND_USE_SUCCESS: `${STATE_REDUCER_KEY}/LIST_LAND_USE_SUCCESS`,
    LIST_LAND_USE_FAILURE: `${STATE_REDUCER_KEY}/LIST_LAND_USE_FAILURE`,

    DOWNLOAD_LAND_USE_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_LAND_USE_REPORT`,
    DOWNLOAD_LAND_USE_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_LAND_USE_REPORT_REQUEST`,
    DOWNLOAD_LAND_USE_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_LAND_USE_REPORT_SUCCESS`,
    DOWNLOAD_LAND_USE_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_LAND_USE_REPORT_FAILURE`,

    LIST_NBDW_EQUIPMENTS_REPORT: `${STATE_REDUCER_KEY}/LIST_NBDW_EQUIPMENTS_REPORT`,
    LIST_NBDW_EQUIPMENTS_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_NBDW_EQUIPMENTS_REPORT_REQUEST`,
    LIST_NBDW_EQUIPMENTS_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_NBDW_EQUIPMENTS_REPORT_SUCCESS`,
    LIST_NBDW_EQUIPMENTS_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_NBDW_EQUIPMENTS_REPORT_FAILURE`,

    DOWNLOAD_NBDW_EQUIPMENTS_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_EQUIPMENTS_REPORT`,
    DOWNLOAD_NBDW_EQUIPMENTS_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_EQUIPMENTS_REPORT_REQUEST`,
    DOWNLOAD_NBDW_EQUIPMENTS_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_EQUIPMENTS_REPORT_SUCCESS`,
    DOWNLOAD_NBDW_EQUIPMENTS_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_EQUIPMENTS_REPORT_FAILURE`,

    LIST_BDW_EQUIPMENTS_REPORT: `${STATE_REDUCER_KEY}/LIST_BDW_EQUIPMENTS_REPORT`,
    LIST_BDW_EQUIPMENTS_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_BDW_EQUIPMENTS_REPORT_REQUEST`,
    LIST_BDW_EQUIPMENTS_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_BDW_EQUIPMENTS_REPORT_SUCCESS`,
    LIST_BDW_EQUIPMENTS_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_BDW_EQUIPMENTS_REPORT_FAILURE`,

    DOWNLOAD_BDW_EQUIPMENTS_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_EQUIPMENTS_REPORT`,
    DOWNLOAD_BDW_EQUIPMENTS_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_EQUIPMENTS_REPORT_REQUEST`,
    DOWNLOAD_BDW_EQUIPMENTS_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_EQUIPMENTS_REPORT_SUCCESS`,
    DOWNLOAD_BDW_EQUIPMENTS_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_EQUIPMENTS_REPORT_FAILURE`,

    LIST_NBDW_GENERATED_VS_CAPACITY_REPORT: `${STATE_REDUCER_KEY}/LIST_NBDW_GENERATED_VS_CAPACITY_REPORT`,
    LIST_NBDW_GENERATED_VS_CAPACITY_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_NBDW_GENERATED_VS_CAPACITY_REPORT_REQUEST`,
    LIST_NBDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_NBDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS`,
    LIST_NBDW_GENERATED_VS_CAPACITY_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_NBDW_GENERATED_VS_CAPACITY_REPORT_FAILURE`,

    DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT`,
    DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT_REQUEST`,
    DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS`,
    DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT_FAILURE`,

    LIST_BDW_GENERATED_VS_CAPACITY_REPORT: `${STATE_REDUCER_KEY}/LIST_BDW_GENERATED_VS_CAPACITY_REPORT`,
    LIST_BDW_GENERATED_VS_CAPACITY_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_BDW_GENERATED_VS_CAPACITY_REPORT_REQUEST`,
    LIST_BDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_BDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS`,
    LIST_BDW_GENERATED_VS_CAPACITY_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_BDW_GENERATED_VS_CAPACITY_REPORT_FAILURE`,

    DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT`,
    DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT_REQUEST`,
    DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS`,
    DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT_FAILURE`,

    LIST_NBDW_FACILITIES_REPORT: `${STATE_REDUCER_KEY}/LIST_NBDW_FACILITIES_REPORT`,
    LIST_NBDW_FACILITIES_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_NBDW_FACILITIES_REPORT_REQUEST`,
    LIST_NBDW_FACILITIES_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_NBDW_FACILITIES_REPORT_SUCCESS`,
    LIST_NBDW_FACILITIES_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_NBDW_FACILITIES_REPORT_FAILURE`,

    DOWNLOAD_NBDW_FACILITIES_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_FACILITIES_REPORT`,
    DOWNLOAD_NBDW_FACILITIES_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_FACILITIES_REPORT_REQUEST`,
    DOWNLOAD_NBDW_FACILITIES_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_FACILITIES_REPORT_SUCCESS`,
    DOWNLOAD_NBDW_FACILITIES_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_NBDW_FACILITIES_REPORT_FAILURE`,

    LIST_BDW_FACILITIES_REPORT: `${STATE_REDUCER_KEY}/LIST_BDW_FACILITIES_REPORT`,
    LIST_BDW_FACILITIES_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_BDW_FACILITIES_REPORT_REQUEST`,
    LIST_BDW_FACILITIES_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_BDW_FACILITIES_REPORT_SUCCESS`,
    LIST_BDW_FACILITIES_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_BDW_FACILITIES_REPORT_FAILURE`,

    DOWNLOAD_BDW_FACILITIES_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_FACILITIES_REPORT`,
    DOWNLOAD_BDW_FACILITIES_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_FACILITIES_REPORT_REQUEST`,
    DOWNLOAD_BDW_FACILITIES_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_FACILITIES_REPORT_SUCCESS`,
    DOWNLOAD_BDW_FACILITIES_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_BDW_FACILITIES_REPORT_FAILURE`,

    LIST_CAPITAL_COST_IN_NBDW_REPORT: `${STATE_REDUCER_KEY}/LIST_CAPITAL_COST_IN_NBDW_REPORT`,
    LIST_CAPITAL_COST_IN_NBDW_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_CAPITAL_COST_IN_NBDW_REPORT_REQUEST`,
    LIST_CAPITAL_COST_IN_NBDW_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_CAPITAL_COST_IN_NBDW_REPORT_SUCCESS`,
    LIST_CAPITAL_COST_IN_NBDW_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_CAPITAL_COST_IN_NBDW_REPORT_FAILURE`,

    DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT`,
    DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT_REQUEST`,
    DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT_SUCCESS`,
    DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT_FAILURE`,

    LIST_CAPITAL_COST_IN_BDW_REPORT: `${STATE_REDUCER_KEY}/LIST_CAPITAL_COST_IN_BDW_REPORT`,
    LIST_CAPITAL_COST_IN_BDW_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_CAPITAL_COST_IN_BDW_REPORT_REQUEST`,
    LIST_CAPITAL_COST_IN_BDW_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_CAPITAL_COST_IN_BDW_REPORT_SUCCESS`,
    LIST_CAPITAL_COST_IN_BDW_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_CAPITAL_COST_IN_BDW_REPORT_FAILURE`,

    DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT`,
    DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT_REQUEST`,
    DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT_SUCCESS`,
    DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT_FAILURE`,

    LIST_FORWARD_LINKING_REPORT: `${STATE_REDUCER_KEY}/LIST_FORWARD_LINKING_REPORT`,
    LIST_FORWARD_LINKING_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_FORWARD_LINKING_REPORT_REQUEST`,
    LIST_FORWARD_LINKING_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_FORWARD_LINKING_REPORT_SUCCESS`,
    LIST_FORWARD_LINKING_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_FORWARD_LINKING_REPORT_FAILURE`,

    DOWNLOAD_FORWARD_LINKING_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_FORWARD_LINKING_REPORT`,
    DOWNLOAD_FORWARD_LINKING_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_FORWARD_LINKING_REPORT_REQUEST`,
    DOWNLOAD_FORWARD_LINKING_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_FORWARD_LINKING_REPORT_SUCCESS`,
    DOWNLOAD_FORWARD_LINKING_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_FORWARD_LINKING_REPORT_FAILURE`,

    LIST_INCOME_FROM_BDW_AND_NBDW_REPORT: `${STATE_REDUCER_KEY}/LIST_INCOME_FROM_BDW_AND_NBDW_REPORT`,
    LIST_INCOME_FROM_BDW_AND_NBDW_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_INCOME_FROM_BDW_AND_NBDW_REPORT_REQUEST`,
    LIST_INCOME_FROM_BDW_AND_NBDW_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_INCOME_FROM_BDW_AND_NBDW_REPORT_SUCCESS`,
    LIST_INCOME_FROM_BDW_AND_NBDW_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_INCOME_FROM_BDW_AND_NBDW_REPORT_FAILURE`,

    DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT`,
    DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT_REQUEST`,
    DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT_SUCCESS`,
    DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT_FAILURE`,

    LIST_EXPENDITURE_REPORT: `${STATE_REDUCER_KEY}/LIST_EXPENDITURE_REPORT`,
    LIST_EXPENDITURE_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_EXPENDITURE_REPORT_REQUEST`,
    LIST_EXPENDITURE_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_EXPENDITURE_REPORT_SUCCESS`,
    LIST_EXPENDITURE_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_EXPENDITURE_REPORT_FAILURE`,

    DOWNLOAD_EXPENDITURE_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_EXPENDITURE_REPORT`,
    DOWNLOAD_EXPENDITURE_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_EXPENDITURE_REPORT_REQUEST`,
    DOWNLOAD_EXPENDITURE_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_EXPENDITURE_REPORT_SUCCESS`,
    DOWNLOAD_EXPENDITURE_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_EXPENDITURE_REPORT_FAILURE`,

    LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT: `${STATE_REDUCER_KEY}/LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT`,
    LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_REQUEST: `${STATE_REDUCER_KEY}/LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_REQUEST`,
    LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_SUCCESS`,
    LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_FAILURE: `${STATE_REDUCER_KEY}/LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_FAILURE`,

    DOWNLOAD_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT`,
    DOWNLOAD_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_REQUEST`,
    DOWNLOAD_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_SUCCESS`,
    DOWNLOAD_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_FAILURE`,

    FETCH_ANNUAL_PLAN_REPORT: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN_REPORT`,
    FETCH_ANNUAL_PLAN_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN_REPORT_REQUEST`,
    FETCH_ANNUAL_PLAN_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN_REPORT_SUCCESS`,
    FETCH_ANNUAL_PLAN_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN_REPORT_FAILURE`,

    DOWNLOAD_ANNUAL_PLAN_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_ANNUAL_PLAN_REPORT`,
    DOWNLOAD_ANNUAL_PLAN_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_ANNUAL_PLAN_REPORT_REQUEST`,
    DOWNLOAD_ANNUAL_PLAN_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_ANNUAL_PLAN_REPORT_SUCCESS`,
    DOWNLOAD_ANNUAL_PLAN_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_ANNUAL_PLAN_REPORT_FAILURE`,

    FETCH_COST_INTERVENTION_LIST: `${STATE_REDUCER_KEY}/FETCH_COST_INTERVENTION_LIST`,
    FETCH_COST_INTERVENTION_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COST_INTERVENTION_LIST_REQUEST`,
    FETCH_COST_INTERVENTION_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COST_INTERVENTION_LIST_SUCCESS`,
    FETCH_COST_INTERVENTION_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COST_INTERVENTION_LIST_FAILURE`,

    DOWNLOAD_COST_INTERVENTION_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_COST_INTERVENTION_REPORT`,
    DOWNLOAD_COST_INTERVENTION_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_COST_INTERVENTION_REPORT_REQUEST`,
    DOWNLOAD_COST_INTERVENTION_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_COST_INTERVENTION_REPORT_SUCCESS`,
    DOWNLOAD_COST_INTERVENTION_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_COST_INTERVENTION_REPORT_FAILURE`,

    FETCH_STATUS_SUMMARY_LIST: `${STATE_REDUCER_KEY}/FETCH_STATUS_SUMMARY_LIST`,
    FETCH_STATUS_SUMMARY_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_STATUS_SUMMARY_LIST_REQUEST`,
    FETCH_STATUS_SUMMARY_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_STATUS_SUMMARY_LIST_SUCCESS`,
    FETCH_STATUS_SUMMARY_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_STATUS_SUMMARY_LIST_FAILURE`,

    DOWNLOAD_STATUS_SUMMARY_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_STATUS_SUMMARY_REPORT`,
    DOWNLOAD_STATUS_SUMMARY_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_STATUS_SUMMARY_REPORT_REQUEST`,
    DOWNLOAD_STATUS_SUMMARY_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_STATUS_SUMMARY_REPORT_SUCCESS`,
    DOWNLOAD_STATUS_SUMMARY_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_STATUS_SUMMARY_REPORT_FAILURE`,

    FETCH_COST_INTERVENTION_LIST_YEAR_WISE: `${STATE_REDUCER_KEY}/FETCH_COST_INTERVENTION_LIST_YEAR_WISE`,
    FETCH_COST_INTERVENTION_LIST_YEAR_WISE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COST_INTERVENTION_LIST_YEAR_WISE_REQUEST`,
    FETCH_COST_INTERVENTION_LIST_YEAR_WISE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COST_INTERVENTION_LIST_YEAR_WISE_SUCCESS`,
    FETCH_COST_INTERVENTION_LIST_YEAR_WISE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COST_INTERVENTION_LIST_YEAR_WISE_FAILURE`,

    DOWNLOAD_COST_INTERVENTION_LIST_YEAR_WISE: `${STATE_REDUCER_KEY}/DOWNLOAD_COST_INTERVENTION_LIST_YEAR_WISE`,
    DOWNLOAD_COST_INTERVENTION_LIST_YEAR_WISE_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_COST_INTERVENTION_LIST_YEAR_WISE_REQUEST`,
    DOWNLOAD_COST_INTERVENTION_LIST_YEAR_WISE_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_COST_INTERVENTION_LIST_YEAR_WISE_SUCCESS`,
    DOWNLOAD_COST_INTERVENTION_LIST_YEAR_WISE_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_COST_INTERVENTION_LIST_YEAR_WISE_FAILURE`,

    FETCH_DETAILS_OF_TEAM_REPORT: `${STATE_REDUCER_KEY}/FETCH_DETAILS_OF_TEAM_REPORT`,
    FETCH_DETAILS_OF_TEAM_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DETAILS_OF_TEAM_REPORT_REQUEST`,
    FETCH_DETAILS_OF_TEAM_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DETAILS_OF_TEAM_REPORT_SUCCESS`,
    FETCH_DETAILS_OF_TEAM_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DETAILS_OF_TEAM_REPORT_FAILURE`,

    DOWNLOAD_DETAILS_OF_TEAM_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_DETAILS_OF_TEAM_REPORT`,
    DOWNLOAD_DETAILS_OF_TEAM_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_DETAILS_OF_TEAM_REPORT_REQUEST`,
    DOWNLOAD_DETAILS_OF_TEAM_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_DETAILS_OF_TEAM_REPORT_SUCCESS`,
    DOWNLOAD_DETAILS_OF_TEAM_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_DETAILS_OF_TEAM_REPORT_FAILURE`,

    FETCH_TEAM_COMPOSITION_REPORT: `${STATE_REDUCER_KEY}/FETCH_TEAM_COMPOSITION_REPORT`,
    FETCH_TEAM_COMPOSITION_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TEAM_COMPOSITION_REPORT_REQUEST`,
    FETCH_TEAM_COMPOSITION_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TEAM_COMPOSITION_REPORT_SUCCESS`,
    FETCH_TEAM_COMPOSITION_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TEAM_COMPOSITION_REPORT_FAILURE`,

    DOWNLOAD_TEAM_COMPOSITION_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_TEAM_COMPOSITION_REPORT`,
    DOWNLOAD_TEAM_COMPOSITION_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_TEAM_COMPOSITION_REPORT_REQUEST`,
    DOWNLOAD_TEAM_COMPOSITION_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_TEAM_COMPOSITION_REPORT_SUCCESS`,
    DOWNLOAD_TEAM_COMPOSITION_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_TEAM_COMPOSITION_REPORT_FAILURE`,

    FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT`,
    FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_REQUEST`,
    FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_SUCCESS`,
    FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_FAILURE`,

    DOWNLOAD_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT: `${STATE_REDUCER_KEY}/DOWNLOAD_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT`,
    DOWNLOAD_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_REQUEST`,
    DOWNLOAD_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_SUCCESS`,
    DOWNLOAD_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_FAILURE`
};

export const populationVsLandAreaList = createAction(ACTION_TYPES.LIST_POPULATION_VS_LAND_AREA_REPORT);
export const downloadPopulationVsLandArea = createAction(ACTION_TYPES.DOWNLOAD_POPULATION_VS_LAND_AREA_REPORT);
export const populationVsHouseHoldList = createAction(ACTION_TYPES.LIST_POPULATION_VS_HOUSE_HOLD_REPORT);
export const downloadPopulationVsHousehold = createAction(ACTION_TYPES.DOWNLOAD_POPULATION_VS_HOUSE_HOLD_REPORT);
export const landUseList = createAction(ACTION_TYPES.LIST_LAND_USE);
export const downloadLandUse = createAction(ACTION_TYPES.DOWNLOAD_LAND_USE_REPORT);
export const nbdwEquipmentsReportList = createAction(ACTION_TYPES.LIST_NBDW_EQUIPMENTS_REPORT);
export const downloadNbdwEquipmentsReport = createAction(ACTION_TYPES.DOWNLOAD_NBDW_EQUIPMENTS_REPORT);
export const bdwEquipmentsReportList = createAction(ACTION_TYPES.LIST_BDW_EQUIPMENTS_REPORT);
export const downloadBdwEquipmentsReport = createAction(ACTION_TYPES.DOWNLOAD_BDW_EQUIPMENTS_REPORT);
export const nbdwGeneratedVsCapacityReportList = createAction(ACTION_TYPES.LIST_NBDW_GENERATED_VS_CAPACITY_REPORT);
export const downloadNbdwGeneratedVsCapacityReport = createAction(ACTION_TYPES.DOWNLOAD_NBDW_GENERATED_VS_CAPACITY_REPORT);
export const bdwGeneratedVsCapacityReportList = createAction(ACTION_TYPES.LIST_BDW_GENERATED_VS_CAPACITY_REPORT);
export const downloadBdwGeneratedVsCapacityReport = createAction(ACTION_TYPES.DOWNLOAD_BDW_GENERATED_VS_CAPACITY_REPORT);
export const nbdwFacilitiesReport = createAction(ACTION_TYPES.LIST_NBDW_FACILITIES_REPORT);
export const downloadNbdwFacilityReport = createAction(ACTION_TYPES.DOWNLOAD_NBDW_FACILITIES_REPORT);
export const bdwFacilitiesReport = createAction(ACTION_TYPES.LIST_BDW_FACILITIES_REPORT);
export const downloadBdwFacilityReport = createAction(ACTION_TYPES.DOWNLOAD_BDW_FACILITIES_REPORT);
export const capitalCostInNbdwReportList = createAction(ACTION_TYPES.LIST_CAPITAL_COST_IN_NBDW_REPORT);
export const downloadCapitalCostInNbdwReport = createAction(ACTION_TYPES.DOWNLOAD_CAPITAL_COST_IN_NBDW_REPORT);
export const capitalCostInBdwReportList = createAction(ACTION_TYPES.LIST_CAPITAL_COST_IN_BDW_REPORT);
export const downloadCapitalCostInBdwReport = createAction(ACTION_TYPES.DOWNLOAD_CAPITAL_COST_IN_BDW_REPORT);
export const forwardLinkingReportList = createAction(ACTION_TYPES.LIST_FORWARD_LINKING_REPORT);
export const downloadForwardLinkingReport = createAction(ACTION_TYPES.DOWNLOAD_FORWARD_LINKING_REPORT);
export const incomeFromBdwAndNbdwReportList = createAction(ACTION_TYPES.LIST_INCOME_FROM_BDW_AND_NBDW_REPORT);
export const downloadIncomeFromBdwAndNbdw = createAction(ACTION_TYPES.DOWNLOAD_INCOME_FROM_BDW_AND_NBDW_REPORT);
export const expenditureReportList = createAction(ACTION_TYPES.LIST_EXPENDITURE_REPORT);
export const downloadExpenditureReportList = createAction(ACTION_TYPES.DOWNLOAD_EXPENDITURE_REPORT);
export const incomeOfHksFromBdwAndNbdwReportList = createAction(ACTION_TYPES.LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT);
export const downloadIncomeOfHksFromBdwAndNbdwReportList = createAction(ACTION_TYPES.DOWNLOAD_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT);
export const fetchAnnualPlanReport = createAction(ACTION_TYPES.FETCH_ANNUAL_PLAN_REPORT);
export const downloadAnnualPlanReport = createAction(ACTION_TYPES.DOWNLOAD_ANNUAL_PLAN_REPORT);
export const fetchCostInterventionList = createAction(ACTION_TYPES.FETCH_COST_INTERVENTION_LIST);
export const downloadCostInterventionReport = createAction(ACTION_TYPES.DOWNLOAD_COST_INTERVENTION_REPORT);
export const fetchStatusSummaryReport = createAction(ACTION_TYPES.FETCH_STATUS_SUMMARY_LIST);
export const downloadStatusSummaryReport = createAction(ACTION_TYPES.DOWNLOAD_STATUS_SUMMARY_REPORT);
export const fetchCostInterventionYearWise = createAction(ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_YEAR_WISE);
export const downloadCostInterventionYearWiseReport = createAction(ACTION_TYPES.DOWNLOAD_COST_INTERVENTION_LIST_YEAR_WISE);
export const fetchDetailsOfTeamReport = createAction(ACTION_TYPES.FETCH_DETAILS_OF_TEAM_REPORT);
export const downloadDetailsOfTeamReport = createAction(ACTION_TYPES.DOWNLOAD_DETAILS_OF_TEAM_REPORT);
export const fetchTeamCompositionReport = createAction(ACTION_TYPES.FETCH_TEAM_COMPOSITION_REPORT);
export const downloadTeamCompositionReport = createAction(ACTION_TYPES.DOWNLOAD_TEAM_COMPOSITION_REPORT);
export const fetchGapAnalysisOfWasteGenerationReport = createAction(ACTION_TYPES.FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT);
export const downloadGapAnalysisOfWasteGenerationReport = createAction(ACTION_TYPES.DOWNLOAD_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT);
