import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { TABLE_IDS } from "common/constants";
import { successNotify } from "utils/notificationUtils";
import { navigateTo } from "modules/common/actions";
import { I18n } from "common/components";
import { MODULE_PATH } from "modules/paths";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";
import { toEpoch } from "utils/dateUtils";

const formatData = (payload) => {
    const { date, ...rest } = payload;
    const data = { date: toEpoch(date), ...rest };
    return data;
};

export function* getWasteProcessingList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.WASTE_PROCESSING_SITES_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchWasteProcessingListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WASTE_PROCESSING_LIST_SUCCESS, ACTION_TYPES.FETCH_WASTE_PROCESSING_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WASTE_PROCESSING_SITES_LIST);
}

export function* submitWasteProcessing(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.updateWasteProcessingApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_WASTE_PROCESSING_SUCCESS, ACTION_TYPES.PATCH_WASTE_PROCESSING_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_WASTE_PROCESSING_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("waste_processing_site_details") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.MASTER_INFO}/${PATH.WASTE_PROCESSING.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, Api.submitWasteProcessingApi, formatData(rest));
        const responseAction = yield take([ACTION_TYPES.POST_WASTE_PROCESSING_SUCCESS, ACTION_TYPES.POST_WASTE_PROCESSING_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_WASTE_PROCESSING_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("waste_processing_site_details") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.MASTER_INFO}/${PATH.WASTE_PROCESSING.LIST}`));
        }
    }
}

export function* wasteProcessingById({ payload }) {
    yield call(handleAPIRequest, Api.fetchWasteProcessingByIdApi, payload);
}

export function* deleteWasteProcessing({ payload: id }) {
    yield fork(handleAPIRequest, Api.deleteWasteProcessingApi, id);
    const response = yield take([ACTION_TYPES.DELETE_WASTE_PROCESSING_SUCCESS, ACTION_TYPES.DELETE_WASTE_PROCESSING_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_WASTE_PROCESSING_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("waste_processing_site_details") }) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.WASTE_PROCESSING_SITES_LIST]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchWasteProcessingListApi, { pageNo: 0, pageSize: 5, ...filters });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WASTE_PROCESSING_LIST_SUCCESS, ACTION_TYPES.FETCH_WASTE_PROCESSING_LIST_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WASTE_PROCESSING_SITES_LIST);
    }
}

export function* siteNameDropdown({ payload }) {
    yield call(handleAPIRequest, Api.fetchSiteNameDropdownApi, payload);
}

export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_WASTE_PROCESSING_LIST, getWasteProcessingList),
        takeLatest(ACTION_TYPES.POST_WASTE_PROCESSING, submitWasteProcessing),
        takeLatest(ACTION_TYPES.PATCH_WASTE_PROCESSING, submitWasteProcessing),
        takeLatest(ACTION_TYPES.FETCH_WASTE_PROCESSING_BY_ID, wasteProcessingById),
        takeLatest(ACTION_TYPES.DELETE_WASTE_PROCESSING, deleteWasteProcessing),
        takeLatest(ACTION_TYPES.FETCH_SITE_NAME_DROPDOWN, siteNameDropdown)
    ]);
}
