import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadContent, getPayloadData } from "../../../utils/apiUtils";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    otherDepartmentsDetails: {
        requestInProgress: false,
        data: {
            name: "",
            description: "",
            address: "",
            contactList: []
        }
    },
    ieaContactsDetails: {
        requestInProgress: false,
        data: {
            name: "",
            description: "",
            address: "",
            contactList: []
        }
    },
    otherDepartmentsContactTypeDropdownOption: {
        requestInProgress: false,
        data: []
    },
    ieaContactTypeDropdownOption: {
        requestInProgress: false,
        data: []
    },
    otherDepartmentsLookupCategoryDropdownOption: {
        requestInProgress: false,
        data: []
    },
    ieaLookupCategoryDropdownOption: {
        requestInProgress: false,
        data: []
    },
    deleteOtherDepartments: {
        requestInProgress: false,
        data: {
        }
    },
    deleteIeaContacts: {
        requestInProgress: false,
        data: {
        }
    },
    filterIeaName: {
        requestInProgress: false,
        data: []
    },
    nameList: {
        requestInProgress: false,
        data: []
    },
    addressList: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clearOtherDepartmentsDetails: (state) => {
            state.otherDepartmentsDetails = initialState.otherDepartmentsDetails;
        },
        clear: (state) => {
            state.nameList = initialState.nameList;
            state.addressList = initialState.addressList;
        },
        clearIeaContacts: (state) => {
            state.ieaContactsDetails = initialState.ieaContactsDetails;
            state.filterIeaName = initialState.filterIeaName;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.SUBMIT_OTHER_DEPARTMENTS_DETAILS_REQUEST, (state) => {
                state.otherDepartmentsDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SUBMIT_OTHER_DEPARTMENTS_DETAILS_FAILURE, (state) => {
                state.otherDepartmentsDetails.requestInProgress = false;
            })

            .addCase(ACTION_TYPES.SUBMIT_IEA_CONTACTS_DETAILS_REQUEST, (state) => {
                state.ieaContactsDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SUBMIT_IEA_CONTACTS_DETAILS_FAILURE, (state) => {
                state.ieaContactsDetails.requestInProgress = false;
            })

            .addCase(ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_REQUEST, (state) => {
                state.otherDepartmentsContactTypeDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_SUCCESS, (state, action) => {
                _.set(state, "otherDepartmentsContactTypeDropdownOption.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_CONTACT_TYPE_FAILURE, (state) => {
                state.otherDepartmentsContactTypeDropdownOption.requestInProgress = false;
            })

            .addCase(ACTION_TYPES.SELECT_IEA_CONTACT_TYPE_REQUEST, (state) => {
                state.ieaContactTypeDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_IEA_CONTACT_TYPE_SUCCESS, (state, action) => {
                _.set(state, "ieaContactTypeDropdownOption.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_IEA_CONTACT_TYPE_FAILURE, (state) => {
                state.ieaContactTypeDropdownOption.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_REQUEST, (state) => {
                state.otherDepartmentsLookupCategoryDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_SUCCESS, (state, action) => {
                _.set(state, "otherDepartmentsLookupCategoryDropdownOption.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_OTHER_DEPARTMENTS_LOOKUP_CATEGORY_FAILURE, (state) => {
                state.otherDepartmentsLookupCategoryDropdownOption.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.SELECT_IEA_LOOKUP_CATEGORY_REQUEST, (state) => {
                state.ieaLookupCategoryDropdownOption.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_IEA_LOOKUP_CATEGORY_SUCCESS, (state, action) => {
                _.set(state, "ieaLookupCategoryDropdownOption.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_IEA_LOOKUP_CATEGORY_FAILURE, (state) => {
                state.ieaLookupCategoryDropdownOption.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.DELETE_OTHER_DEPARTMENTS_DETAILS_REQUEST, (state) => {
                state.deleteOtherDepartments.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.DELETE_OTHER_DEPARTMENTS_DETAILS_SUCCESS, (state, action) => {
                _.set(state, "deleteOtherDepartments.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.DELETE_OTHER_DEPARTMENTS_DETAILS_FAILURE, (state) => {
                state.deleteOtherDepartments.requestInProgress = false;
            })

            .addCase(ACTION_TYPES.DELETE_IEA_CONTACTS_DETAILS_REQUEST, (state) => {
                state.deleteIeaContacts.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.DELETE_IEA_CONTACTS_DETAILS_SUCCESS, (state, action) => {
                _.set(state, "deleteIeaContacts.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.DELETE_IEA_CONTACTS_DETAILS_FAILURE, (state) => {
                state.deleteIeaContacts.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.UPDATE_OTHER_DEPARTMENTS_DETAILS_REQUEST, (state) => {
                state.otherDepartmentsDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.UPDATE_OTHER_DEPARTMENTS_DETAILS_FAILURE, (state) => {
                state.otherDepartmentsDetails.requestInProgress = false;
            })

            .addCase(ACTION_TYPES.UPDATE_IEA_CONTACTS_DETAILS_REQUEST, (state) => {
                state.ieaContactsDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.UPDATE_IEA_CONTACTS_DETAILS_FAILURE, (state) => {
                state.ieaContactsDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_OTHER_DEPARTMENTS_BY_ID_REQUEST, (state) => {
                state.otherDepartmentsDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_OTHER_DEPARTMENTS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "otherDepartmentsDetails.requestInProgress", false);
                _.set(state, "otherDepartmentsDetails.data", getPayloadData(action));
            })
            .addCase(ACTION_TYPES.FETCH_OTHER_DEPARTMENTS_BY_ID_FAILURE, (state) => {
                state.otherDepartmentsDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_IEA_CONTACTS_BY_ID_REQUEST, (state) => {
                state.ieaContactsDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_IEA_CONTACTS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "ieaContactsDetails.requestInProgress", false);
                _.set(state, "ieaContactsDetails.data", getPayloadData(action));
            })
            .addCase(ACTION_TYPES.FETCH_IEA_CONTACTS_BY_ID_FAILURE, (state) => {
                state.ieaContactsDetails.requestInProgress = false;
            }).addCase(ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "nameList.requestInProgress", false);
                _.set(state, "nameList.data", payload.content);
            }).addCase(ACTION_TYPES.FETCH_ADDRESS_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "addressList.requestInProgress", false);
                _.set(state, "addressList.data", payload.content);
            });
        builder.addCase(ACTION_TYPES.FILTER_IEA_NAME_SUCCESS, (state, { payload }) => {
            _.set(state, "filterIeaName.requestInProgress", false);
            _.set(state, "filterIeaName.data", payload.content);
        });
    }
});
export const { actions, reducer } = slice;

