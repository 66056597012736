import { I18n } from "common/components";
import { TABLE_IDS } from "common/constants";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { getTableProps } from "modules/common/selectors";
import { MODULE_PATH } from "modules/paths";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { toEpoch } from "utils/dateUtils";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { API_URL } from "../apiUrls";


const formatData = (payload) => {
    const { collectionDate, ...rest } = payload;
    const data = { collectionDate: toEpoch(collectionDate), ...rest };
    return data;
};

export function* fetchSourceForBdwList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.TREATMENT_AT_SOURCE_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchSourceForBdwListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SOURCE_FOR_BDW_DETAILS_SUCCESS, ACTION_TYPES.FETCH_SOURCE_FOR_BDW_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TREATMENT_AT_SOURCE_LIST);
}

export function* wardDropdown({ payload }) {
    yield call(handleAPIRequest, Api.wardDropdownApi, payload);
}

export function* submitSourceForBdwDetails(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.patchSourceForBdwDetailsApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_SOURCE_FOR_BDW_DETAILS_SUCCESS, ACTION_TYPES.PATCH_SOURCE_FOR_BDW_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_SOURCE_FOR_BDW_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("treatment_at_source_for_bdw_information") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.TREATMENT_AT_SOURCE_FOR_BDW.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, Api.postSourceForBdwDetailsApi, formatData(rest));
        const responseAction = yield take([ACTION_TYPES.POST_SOURCE_FOR_BDW_DETAILS_SUCCESS, ACTION_TYPES.POST_SOURCE_FOR_BDW_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_SOURCE_FOR_BDW_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("treatment_at_source_for_bdw_information") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.TREATMENT_AT_SOURCE_FOR_BDW.LIST}`));
        }
    }
}

export function* getSourceForBdwByIdDetails({ payload }) {
    yield fork(handleAPIRequest, Api.getSourceForBdwDetailsByIdApi, payload);
    const responseAction = yield take([ACTION_TYPES.GET_SOURCE_FOR_BDW_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_SOURCE_FOR_BDW_DETAILS_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.GET_SOURCE_FOR_BDW_DETAILS_BY_ID_SUCCESS) {
        if (responseAction?.payload?.district && responseAction?.payload?.lsgi) {
            yield call(handleAPIRequest, Api.wardDropdownApi, { lsgiId: responseAction?.payload?.lsgi?.id, pageNo: 0, pageSize: 500 });
        }
    }
}

export function* deleteSourceForBdwDetails({ payload }) {
    yield fork(handleAPIRequest, Api.deleteSourceForBdwDetailsApi, payload);
    const action = yield take([ACTION_TYPES.DELETE_SOURCE_FOR_BDW_DETAILS_SUCCESS, ACTION_TYPES.DELETE_SOURCE_FOR_BDW_DETAILS_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_SOURCE_FOR_BDW_DETAILS_SUCCESS) {
        yield put(successNotify({ title: (I18n("success")), message: (I18n("deleted_successfully")) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.TREATMENT_AT_SOURCE_LIST]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchSourceForBdwListApi, { pageNo: 0, pageSize: 5, ...filters, ...payload });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_SOURCE_FOR_BDW_DETAILS_SUCCESS, ACTION_TYPES.FETCH_SOURCE_FOR_BDW_DETAILS_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TREATMENT_AT_SOURCE_LIST);
    }
}

export function* downloadTreatmentAtSourceBdwDetails(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.SOURCE_FOR_BDW.DOWNLOAD,
        data: { type},
        file: { name: fileName, ext: type }
    }));
}

export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_SOURCE_FOR_BDW_DETAILS, fetchSourceForBdwList),
        takeLatest(ACTION_TYPES.GET_WARD_DROPDOWN, wardDropdown),
        takeLatest(ACTION_TYPES.POST_SOURCE_FOR_BDW_DETAILS, submitSourceForBdwDetails),
        takeLatest(ACTION_TYPES.PATCH_SOURCE_FOR_BDW_DETAILS, submitSourceForBdwDetails),
        takeLatest(ACTION_TYPES.GET_SOURCE_FOR_BDW_DETAILS_BY_ID, getSourceForBdwByIdDetails),
        takeLatest(ACTION_TYPES.DELETE_SOURCE_FOR_BDW_DETAILS, deleteSourceForBdwDetails),
        takeLatest(ACTION_TYPES.DOWNLOAD_SOURCE_FOR_BDW_DETAILS, downloadTreatmentAtSourceBdwDetails)
    ]);
}
