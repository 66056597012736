import { Box, Modal } from "@mui/material";
import React from "react";

const modalStyle = {
    position: "absolute",
    top: {xs: "50%", md: "50%"},
    left: {xs: "10%", md: "50%"},
    transform: {xs: "translate(-5%, -50%)", md: "translate(-50%, -50%)"},
    width: {xs: "90%", md: "55%"},
    bgcolor: "background.while",
    border: "none",
    p: { xs: 0, md: 4 },
    overFlow: "auto",
    maxHeight: "90vh"
};


const TableFilterContainer = (props) => {
    return (
        <Modal
            open={props.open}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{...modalStyle}} >
                {props.children}
            </Box>
        </Modal>
    );
};

export default TableFilterContainer;
