import { REACT_TABLE_COMMON_OPTIONS, ROWS_PER_PAGE, TABLE_IDS } from "common/constants";
import { withFormik } from "formik";
import _ from "lodash";
import CustomListMenu from "common/components/custom/CustomListMenu";
import CommonTable from "modules/common/components/CommonTable";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { confirmDialog } from "utils/notificationUtils";
import { getCertificateIssueForm } from "../selectors";
import { Components, I18n } from "../../../../common/components";
import { fetchCertificateDetails, issueCertificate } from "../actions";
import { actions as sliceActions } from "../slice";
import { createStructuredSelector } from "reselect";
import { actions as commonSlice } from "modules/common/slice";
import { getTableProps } from "modules/common/selectors";
import { getBatchId } from "modules/tcm/schedule/selectors";

const { Button, Grid } = Components;
const CertificateIssue = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { table } = props;
    const { [TABLE_IDS.CERTIFICATE_ISSUE]: { data = [], rowSelected = {} } = {} } = table;

    const handleDelete = () => {
        confirmDialog({
            title: I18n("are_sure_want_add"),
            showDenyButton: true,
            confirmButtonText: I18n("confirm"),
            denyButtonText: I18n("cancel")
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(issueCertificate(id));
            }
        });
    };
    const handleRowSelection = (tableProps) => {
        let items = tableProps(rowSelected);
        dispatch(commonSlice.setRowSelection({ key: TABLE_IDS.CERTIFICATE_ISSUE, items }));
        let payload = [];
        Object.keys(items).forEach((i) => payload.push(data[i]));
        dispatch(sliceActions.setRowSelectionData(payload));
    };

    const bottomActions = () => {
        return (
            <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Grid>
                    <Button disabled={!_.values(rowSelected).length} onClick={() => handleDelete()} > {I18n("issue_certificate")}</Button>
                </Grid>

            </Grid>);
    };


    const actions = (row) => {
        let customActions = [];
        customActions.push({ title: I18n("delete"), button: <Button> {I18n("delete")} </Button>, handleClick: () => handleDelete(row.original.id) });
        return customActions;
    };

    const displayColumnDefOptions = {
        "mrt-row-actions": {
            Cell: ({ row }) => <CustomListMenu customActions={actions(row)} />

        }
    };


    const options = {
        ...REACT_TABLE_COMMON_OPTIONS,
        enableRowSelection: true,
        enableFilters: true,
        state: {
            rowSelection: rowSelected
        },
        customPagination: {
            rowsPerPageOptions: ROWS_PER_PAGE
        },
        displayColumnDefOptions,
        handleRowSelection,
        enableBottomToolbar: true,
        renderBottomToolbarCustomActions: bottomActions,
        enableRowActions: true,
        enableSelectAll: true
    };

    const columns = [
        {
            id: "name",
            header: I18n("name"),
            accessorKey: "userName",
            accessorFn: ({ userId = {} }) => userId ? (userId.firstName + " " + userId.lastName) : "",
            size: 250
        },
        {
            id: "email",
            header: I18n("email"),
            accessorFn: (row) => row?.userId?.activeProfile?.emailId,
            size: 250
        },
        {
            id: "mobile",
            header: I18n("mobile"),
            accessorFn: (row) => row?.userId?.activeProfile?.mobileNo,
            size: 250
        },
        {
            id: "issueCertificate",
            header: I18n("issue_certificate"),
            accessorFn: (row) => row?.issueCertificate,
            size: 250
        }
    ];


    return (
        <>
            <CommonTable
                columns={columns}
                options={options}
                tableId={TABLE_IDS.CERTIFICATE_ISSUE}
                title={I18n("certification_issue_list")}
                fetchData={fetchCertificateDetails}
                id={id}
            />
        </>
    );
};
const mapStateToProps = createStructuredSelector({
    attendanceForm: getCertificateIssueForm,
    table: getTableProps,
    batchId: getBatchId
});
const certificateIssue = withFormik({
    enableReinitialize: true,
    handleSubmit: (values, { props }) => {
        props.submit(values);
    }
})(CertificateIssue);

export default connect(mapStateToProps, null)(certificateIssue);
