export const STATE_REDUCER_KEY = "user";

export const USER_TYPE = {
    OFFICERS: { id: 1, name: "Official" },
    CITIZEN: { id: 2, name: "Customers" },
    TRAINER: { id: 3, name: "Trainer" },
    OTHERS: { id: 4, name: "Others" }
};

export const LOGIN_TYPE = {
    WEB: { id: 1, name: "Web" }
};

export const USER_TYPE_KEYS = ["Official", "Trainer", "Others"];

export const GENDER_LIST = [
    { id: 1, name: "MALE" },
    { id: 2, name: "FEMALE" },
    { id: 3, name: "TRANSGENDER" }
];

