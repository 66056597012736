import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";
import { REQUEST_METHOD } from "common/constants";

export const fetchComplaintStatusReport = () => ({
    url: API_URL.REPORT.COMPLAINT_STATUS,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_COMPLAINT_STATUS_REPORT_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_STATUS_REPORT_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_STATUS_REPORT_FAILURE]
    }
});

export const fetchComplaintDistrictReport = () => ({
    url: API_URL.REPORT.COMPLAINT_STATUS_DISTRICT,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_COMPLAINT_DISTRICT_REPORT_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_DISTRICT_REPORT_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_DISTRICT_REPORT_FAILURE]
    }
});

export const fetchComplaintCategoryReport = () => ({
    url: API_URL.REPORT.COMPLAINT_STATUS_CATEGORY,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REPORT_REQUEST, ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REPORT_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REPORT_FAILURE]
    }
});
