import { STORAGE_KEYS } from "common/constants";
import React from "react";
import { useLocation } from "react-router";
import Navigate from "./Navigate";
export const PrivateRoute = ({ children }) => {

    let hasToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
    const location = useLocation();
    return hasToken ? (
        <>{children}</>
    ) : (
        <Navigate
            replace={true}
            to="/login"
            state={{ from: `${location.pathname}${location.search}` }
            }
        />
    );
};
