import { flow } from "lodash";

const { STATE_REDUCER_KEY } = require("./constants");

const getSample = (state) => state[STATE_REDUCER_KEY];

const sampleForm = (sample) => sample.sampleForm;
export const getSampleForm = flow(getSample, sampleForm);

const tableProps = (sample) => sample.tablePagination;
export const getTableProps = flow(getSample, tableProps);

const selectedRows = (sample) => sample.table.rowSelection;
export const getSelectedRows = flow(getSample, selectedRows);
