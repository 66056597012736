export const STATE_REDUCER_KEY = "swmSubProjects";

export const SWM_SUB_PROJECT_TYPE = {
    SWM_SUB_PROJECT_DETAILS: "swmSubProjectDetails",
    SWM_SUB_PROJECT_SCREENING: "swmSubProjectScreening",
    SWM_SUB_PROJECT_GROUP_DISCUSSION: "swmSubProjectGroupDiscussion",
    SWM_SUB_PROJECT_WARD_SABHA: "swmSubProjectWardSabha",
    SWM_SUB_PROJECT_VIKASANA_SEMINAR: "swmSubProjectVikasanaSeminar",
    SWM_SUB_PROJECT_HSC_APPROVAL: "swmSubProjectHscApproval",
    SWM_SUB_PROJECT_MC_APPROVAL: "swmSubProjectMcApproval",
    SWM_SUB_PROJECT_SULEKHA_INFORMATION: "swmSubProjectSulekhaInformation",
    SWM_SUB_PROJECT_DPC_APPROVAL: "swmSubProjectDpcApproval",
    SWM_SUB_PROJECT_SAFEGUARD_REPORT: "swmSubProjectSafeguardReport",
    SWM_SUB_PROJECT_SOCIAL_SAFEGUARD: "swmSubProjectSocialSafeguard",
    SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD: "swmSubProjectEnvironmentalSafeguard",
    SWM_SUB_PROJECT_DPR_PREPARATION: "swmSubProjectDprPreparation",
    SWM_SUB_PROJECT_MC: "swmSubProjectMc",
    SWM_SUB_PROJECT_DPMU: "swmSubProjectDpmu",
    SWM_SUB_PROJECT_SPMU: "swmSubProjectSpmu",
    SWM_SUB_PROJECT_NOC_FROM_WB: "swmSubProjectNocFromWb",
    SWM_SUB_PROJECT_TECHNICAL_SANCTION: "swmSubProjectTechnicalSanction",
    SWM_SUB_PROJECT_FINANCIAL_SANCTION: "swmSubProjectFinancialSanction",
    SWM_SUB_PROJECT_PROJECT_SOCIAL_SAFEGUARD_WRAPPER: "swmSubProjectProjectSocialSafeGuardWrapper",
    SWM_SUB_PROJECT_PROJECT_LEVEL_DATA: "swmSubProjectProjectLevelData",
    SWM_SUB_PROJECT_SAFEGUARD_COMPLIANCE: "swmSubProjectSafeguardCompliance",
    SWM_SUB_PROJECT_SCHEME_CYCLIC_ACTIVITIES: "swmSubProjectSchemeCyclicActivities",
    SWM_SUB_PROJECT_ENVIRONMENTAL_SAFEGUARD_WRAPPER: "EnvironmentalSafeguardWrapper",
    ENVIRONMENTAL_SAFEGUARD_PROJECT_DATA: "ProjectDataEnvironmentalSafeguard",
    ENVIRONMENTAL_SAFEGUARD_COMPENDIUM: "CompendiumEnvironmentalSafeguard",
    ENVIRONMENTAL_SAFEGUARD_TRAINING_MASTER_SHEET: "TrainingMasterSheet",
    PREPLAN_PHASE: "prePlanPhase",
    PLANNING_PRIORITIZED_PROJECT: "planningPrioritizedProject",
    PLANNING_FOR_BEHAVIOUR: "planningForBehaviour",
    IMPLEMENTATION_PHASE: "implementationPhase",
    POST_IMPLEMENTATION: "postImplementation"
};
