import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchPrimaryCollectionAgencyApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_AGENCY.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PRIMARY_COLLECTION_AGENCY_REQUEST, ACTION_TYPES.FETCH_PRIMARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.FETCH_PRIMARY_COLLECTION_AGENCY_FAILURE], data
        }
    };
};

export const womenGroupDropdownApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_AGENCY.WOMEN_GROUP,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.WOMEN_WORK_GROUP_DROPDOWN_REQUEST, ACTION_TYPES.WOMEN_WORK_GROUP_DROPDOWN_SUCCESS, ACTION_TYPES.WOMEN_WORK_GROUP_DROPDOWN_FAILURE],
            data
        }
    };
};

export const postPrimaryCollectionAgencyApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_AGENCY.POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_PRIMARY_COLLECTION_AGENCY_REQUEST, ACTION_TYPES.POST_PRIMARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.POST_PRIMARY_COLLECTION_AGENCY_FAILURE], data
        }
    };
};

export const patchPrimaryCollectionAgencyApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_AGENCY.POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_PRIMARY_COLLECTION_AGENCY_REQUEST, ACTION_TYPES.PATCH_PRIMARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.PATCH_PRIMARY_COLLECTION_AGENCY_FAILURE], data
        }
    };
};

export const primaryCollectionByIdApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_AGENCY.BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_PRIMARY_COLLECTION_AGENCY_BY_ID_REQUEST, ACTION_TYPES.GET_PRIMARY_COLLECTION_AGENCY_BY_ID_SUCCESS, ACTION_TYPES.GET_PRIMARY_COLLECTION_AGENCY_BY_ID_FAILURE]
        }
    };
};

export const deletePrimaryCollectionAgencyApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_AGENCY.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_PRIMARY_COLLECTION_AGENCY_REQUEST, ACTION_TYPES.DELETE_PRIMARY_COLLECTION_AGENCY_SUCCESS, ACTION_TYPES.DELETE_PRIMARY_COLLECTION_AGENCY_FAILURE]
        }
    };
};

export const agencyNameDropdownApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_AGENCY.AGENCY_NAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.AGENCY_NAME_DROPDOWN_REQUEST, ACTION_TYPES.AGENCY_NAME_DROPDOWN_SUCCESS, ACTION_TYPES.AGENCY_NAME_DROPDOWN_FAILURE],
            data
        }
    };
};
