import { createSlice } from "@reduxjs/toolkit";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";
import { getPayloadContent } from "utils/apiUtils";

const SUB_PROJECT_DETAILS = {
    projectCode: "",
    projectName: "",
    startDate: "",
    endDate: "",
    trackId: "",
    conceptNoteAvailable: "",
    estimateAmount: "",
    feasibilityStudyDone: "",
    categoryId: "",
    subCategoryId: "",
    subCategoryId2: "",
    typeId: "",
    envCategoryId: "",
    attachmentPayload: {
        multimediaList: []
    }
};
const initialState = {

    subProjectNewDetails: {
        requestInProgress: false,
        data: {
            ...SUB_PROJECT_DETAILS
        }
    },
    subProjectListDetails: {
        requestInProgress: false,
        data: {
            ...SUB_PROJECT_DETAILS
        }
    },
    subProjectFinalListById: {
        requestInProgress: false,
        data: {
            ...SUB_PROJECT_DETAILS
        }
    },
    finalSelectedList: {
        requestInProgress: false,
        data: {
            planId: "",
            planName: ""
        }
    }
};


const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.finalPlanById = initialState.finalPlanById;
        },
        clearSubProject: (state) => {
            state.subProjectFinalListById = initialState.subProjectFinalListById;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.LIST_SUB_PROJECT_SUCCESS, (state, action) => {
                state.subProjectListDetails = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.LIST_SUB_PROJECT_FAILURE, (state) => {
                state.subProjectListDetails.requestInProgress = false;
                state.error = "Something went wrong";

            }).addCase(ACTION_TYPES.LIST_FINAL_SUB_PROJECT_SUCCESS, (state, { payload }) => {
                _.set(state, "subProjectNewDetails.requestInProgress", false);
                _.set(state, "subProjectNewDetails.data", payload);

            })
            .addCase(ACTION_TYPES.LIST_FINAL_SUB_PROJECT_FAILURE, (state) => {
                state.subProjectNewDetails.requestInProgress = false;
                state.error = "Something went wrong";
            })
            .addCase(ACTION_TYPES.LIST_SUB_PROJECT_FINAL_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "subProjectFinalListById.requestInProgress", false);
                _.set(state, "subProjectFinalListById.data", payload);

            }).addCase(ACTION_TYPES.SELECTED_PLAN_LIST_SUCCESS, (state, action) => {
                state.finalSelectedList = {
                    requestInProgress: false,
                    data: getPayloadContent(action)
                };
            })
            .addCase(ACTION_TYPES.SELECTED_PLAN_LIST_FAILURE, (state) => {
                state.finalSelectedList.requestInProgress = false;
                state.error = "Something went wrong";

            });
    }

});

export const { actions, reducer } = slice;
