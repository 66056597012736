import { I18n } from "common/components";
import { navigateTo } from "modules/common/actions";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";

import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { addressList, deleteShoNgoApi, deleteTrainingOrganizationApi, fetchContactTypeApi, filterShoNgoName, listShoNgoApi, listTrainingOrganizationApi, lookupCategoryApi, nameList, shoNgoGetByIdApi, submitShoNgoApi, submitTrainingOrganizationApi, trainingOrganizationGetByIdApi, updateShoNgoApi, updateTrainingOrganizationApi } from "./api";
import { actions as commonActions } from "../../common/slice";
import { ADMIN_PATH as PATH } from "../paths";
import { MODULE_PATH } from "modules/paths";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { getTableProps } from "modules/common/selectors";

export function* fetchContactType(action) {
    yield call(handleAPIRequest, fetchContactTypeApi, action.payload);
}

export function* fetchLookupCategory(action) {
    yield call(handleAPIRequest, lookupCategoryApi, action.payload);
}

export function* submitShoNgoForm(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, updateShoNgoApi, action.payload);
        const responseAction = yield take([ACTION_TYPES.UPDATE_SHO_NGO_SUCCESS, ACTION_TYPES.UPDATE_SHO_NGO_FAILURE]);
        if (responseAction.type === ACTION_TYPES.UPDATE_SHO_NGO_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("sho_ngo") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.SHO_NGO.DETAILS}`));
        }
    } else {
        yield fork(handleAPIRequest, submitShoNgoApi, rest);
        const responseAction = yield take([ACTION_TYPES.SUBMIT_SHO_NGO_CONTACTS_SUCCESS, ACTION_TYPES.SUBMIT_SHO_NGO_CONTACTS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_SHO_NGO_CONTACTS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("sho_ngo") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.SHO_NGO.DETAILS}`));
        }
    }
}

export function* shoNgoGetById({ payload: id }) {
    yield call(handleAPIRequest, shoNgoGetByIdApi, id);
}

export function* listShoNgoForm({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listShoNgoApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_SHO_NGO_CONTACTS_SUCCESS, ACTION_TYPES.LIST_SHO_NGO_CONTACTS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* deleteShoNgoForm({ payload: id }) {
    yield fork(handleAPIRequest, deleteShoNgoApi, id);
    const response = yield take([ACTION_TYPES.DELETE_SHO_NGO_CONTACTS_SUCCESS, ACTION_TYPES.DELETE_SHO_NGO_CONTACTS_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_SHO_NGO_CONTACTS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("sho_ngo") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listShoNgoForm, { payload: tablePayload });
    }
}

export function* submitTrainingOrganizationForm(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, updateTrainingOrganizationApi, action.payload);
        const responseAction = yield take([ACTION_TYPES.UPDATE_TRAINING_ORGANIZATION_SUCCESS, ACTION_TYPES.UPDATE_TRAINING_ORGANIZATION_FAILURE]);
        if (responseAction.type === ACTION_TYPES.UPDATE_TRAINING_ORGANIZATION_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("training_organization") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.TRAINING_ORGANIZATION.DETAILS}`));
        }
    } else {
        yield fork(handleAPIRequest, submitTrainingOrganizationApi, rest);
        const responseAction = yield take([ACTION_TYPES.SUBMIT_TRAINING_ORGANIZATION_SUCCESS, ACTION_TYPES.SUBMIT_TRAINING_ORGANIZATION_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SUBMIT_TRAINING_ORGANIZATION_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("training_organization") }) }));
            yield put(navigateTo(`${MODULE_PATH.ADMIN}/${PATH.TRAINING_ORGANIZATION.DETAILS}`));
        }
    }
}

export function* trainingOrganizationGetById({ payload: id }) {
    yield call(handleAPIRequest, trainingOrganizationGetByIdApi, id);
}

export function* listTrainingOrganizationForm({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    let newPayload = {
        address: filters.address?.join(","),
        name: filters.name?.join(","),
        ...payload
    };
    yield fork(handleAPIRequest, listTrainingOrganizationApi, newPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_TRAINING_ORGANIZATION_SUCCESS, ACTION_TYPES.LIST_TRAINING_ORGANIZATION_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* deleteTrainingOrganizationForm({ payload: id }) {
    yield fork(handleAPIRequest, deleteTrainingOrganizationApi, id);
    const response = yield take([ACTION_TYPES.DELETE_TRAINING_ORGANIZATION_SUCCESS, ACTION_TYPES.DELETE_TRAINING_ORGANIZATION_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_TRAINING_ORGANIZATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("training_organization") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listTrainingOrganizationForm, { payload: tablePayload });
    }
}
export function* filterShoNgoNameDropdown({ payload }) {
    yield call(handleAPIRequest, filterShoNgoName, payload);
}

export function* fetchNameList({ payload }) {
    yield call(handleAPIRequest, nameList, payload);
}
export function* fetchAddressList({ payload }) {
    yield call(handleAPIRequest, addressList, payload);
}
export default function* testSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_CONTACT_TYPE, fetchContactType),
        takeLatest(ACTION_TYPES.FETCH_LOOKUP_CATEGORY, fetchLookupCategory),
        takeLatest(ACTION_TYPES.SUBMIT_SHO_NGO_CONTACTS, submitShoNgoForm),
        takeLatest(ACTION_TYPES.LIST_SHO_NGO_CONTACTS, listShoNgoForm),
        takeLatest(ACTION_TYPES.DELETE_SHO_NGO_CONTACTS, deleteShoNgoForm),
        takeLatest(ACTION_TYPES.SHO_NGO_CONTACTS_BY_ID, shoNgoGetById),
        takeLatest(ACTION_TYPES.SUBMIT_TRAINING_ORGANIZATION, submitTrainingOrganizationForm),
        takeLatest(ACTION_TYPES.LIST_TRAINING_ORGANIZATION, listTrainingOrganizationForm),
        takeLatest(ACTION_TYPES.DELETE_TRAINING_ORGANIZATION, deleteTrainingOrganizationForm),
        takeLatest(ACTION_TYPES.TRAINING_ORGANIZATION_BY_ID, trainingOrganizationGetById),
        takeLatest(ACTION_TYPES.FILTER_SHO_NGO_NAME, filterShoNgoNameDropdown),
        takeLatest(ACTION_TYPES.FETCH_NAME_LIST, fetchNameList),
        takeLatest(ACTION_TYPES.FETCH_ADDRESS_LIST, fetchAddressList)
    ]);
}
