import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const getComplaint = (state) => state[STATE_REDUCER_KEY];

const complaintDetails = (complaint) => complaint.complaintDetails.data;
export const getComplaintDetails = flow(getComplaint, complaintDetails);

const complaintActions = (complaint) => complaint.complaintActions.data;
export const setComplaintActions = flow(getComplaint, complaintActions);

const officerStatus = (complaint) => complaint.officerStatus.data;
export const setOfficerStatus = flow(getComplaint, officerStatus);

const districtDropdown = (state) => state.districtDropdown.data;
export const getDistrictDropdown = flow(getComplaint, districtDropdown);

const wardsDropdown = (state) => state.wards.data;
export const getWardsDropdown = flow(getComplaint, wardsDropdown);

const organizationTypeDropdown = (state) => state.organizationTypeDropdown.data;
export const getOrganizationTypeDropdown = flow(getComplaint, organizationTypeDropdown);

const organizationDropdown = (state) => state.organizationDropdown.data;
export const getOrganizationDropdown = flow(getComplaint, organizationDropdown);

const complaintsModeDropdown = (state) => state.complaintsModeDropdown.data;
export const getComplaintsModeDropdown = flow(getComplaint, complaintsModeDropdown);

const complaintCategoryDropdown = (state) => state.complaintCategoryDropdown.data;
export const getComplaintCategoryDropdown = flow(getComplaint, complaintCategoryDropdown);

const complaintTypeDropdown = (state) => state.complaintTypeDropdown.data;
export const getComplaintTypeDropdown = flow(getComplaint, complaintTypeDropdown);

const concernedOrganizationDropdown = (complaint) => complaint.concernedOrganizationDropdown.data;
export const getConcernedOrganizationDropdown = flow(getComplaint, concernedOrganizationDropdown);

const filters = (state) => state.filters.complaintNo;
export const getFilterComplaintNo = flow(getComplaint, filters);

const complaintStatusDropdown = (state) => state.complaintStatusDropdown?.data;
export const getComplaintStatusDropdown = flow(getComplaint, complaintStatusDropdown);

const officersList = (state) => state.officersList;
export const getOfficersList = flow(getComplaint, officersList);

const additionalOfficersFilters = (state) => state.officersList.additionalFilters;
export const getAdditionalOfficersFilter = flow(getComplaint, additionalOfficersFilters);

const searchFields = (complaint) => complaint.searchFields?.data;
export const getSearchFields = flow(getComplaint, searchFields);

const table = (complaint) => complaint.officersList;
export const getTableProps = flow(getComplaint, table);

const isVisible = (complaint) => complaint.complaintActions?.isVisible;
export const getIsVisible = flow(getComplaint, isVisible);

const operationType = (complaint) => complaint.operationType;
export const getOperationType = flow(getComplaint, operationType);

const toggleComplaintAction = (complaint) => complaint.toggleComplaintAction;
export const getToggleComplaintAction = flow(getComplaint, toggleComplaintAction);

const myApprovalDetails = (complaint) => complaint.complaintApprovalDetails.data;
export const getMyApprovalDetails = flow(getComplaint, myApprovalDetails);

const customerActions = (complaint) => complaint.customerActions;
export const getCustomerActions = flow(getComplaint, customerActions);

const complaintRemarks = (complaint) => complaint.complaintRemarks.data;
export const getComplaintRemarks = flow(getComplaint, complaintRemarks);

const complaintClosed = (complaint) => complaint.complaintClosed.data;
export const getComplaintClosed = flow(getComplaint, complaintClosed);

const complaintWithdraw = (complaint) => complaint.complaintWithdraw.data;
export const getComplaintWithdraw = flow(getComplaint, complaintWithdraw);

const myApprovalSelectedRow = (complaint) => complaint.myApprovalSelectedRow;
export const getMyApprovalSelectedRow = flow(getComplaint, myApprovalSelectedRow);

const createUser = (complaint) => complaint.createUser.data;
export const getCreateUserForm = flow(getComplaint, createUser);

const mobileNo = (state) => state.mobileNo.data;
export const mobileNoSelector = flow(getComplaint, mobileNo);
