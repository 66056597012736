import { createSlice } from "@reduxjs/toolkit";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const APPROVAL_QA_QC_DETAILS = {
    dpmuSocialExpertDecision: null,
    dpmuEnvExpertDecision: null,
    dpmuFinancialExpertDecision: null,
    dpmuTechnicalExpertDecision: null,
    dpmuSocialExpertRemarks: "",
    dpmuEnvExpertRemarks: "",
    dpmuFinancialExpertRemarks: "",
    dpmuTechnicalExpertRemarks: ""
};

const initialState = {
    approvalByQaQc: {
        requestInProgress: false,
        data: {
            ...APPROVAL_QA_QC_DETAILS
        }
    },
    previousDpmu: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.approvalByQaQc = initialState.approvalByQaQc;
        },
        clearPrevious: (state) => {
            state.previousDpmu = initialState.previousDpmu;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.APPROVE_QA_QC_BY_ID_REQUEST, (state) => {
                state.approvalByQaQc.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.APPROVE_QA_QC_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "approvalByQaQc.requestInProgress", false);
                _.set(state, "approvalByQaQc.data", payload?.content?.length > 0 ? payload?.content[0] : state.approvalByQaQc.data);
            })
            .addCase(ACTION_TYPES.APPROVE_QA_QC_BY_ID_FAILURE, (state) => {
                state.approvalByQaQc.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_DPMU_REQUEST, (state) => {
                state.previousDpmu.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_DPMU_SUCCESS, (state, { payload }) => {
                _.set(state, "previousDpmu.requestInProgress", false);
                _.set(state, "previousDpmu.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_DPMU_FAILURE, (state) => {
                state.previousDpmu.requestInProgress = false;
            });
    }
});

export const { actions, reducer } = slice;
