import { ACTION_TYPES } from "./actions";
import { deleteScheduleByIdAPI, editScheduleByIdApi, editScheduleFormAPI, fetchTrainingOrganizationApi, fetchSessionIdApi, fetchTrainerIdApi } from "./api";
import { handleAPIRequest } from "../../../utils/http";
import { all, call, fork, put, take, select, takeLatest } from "redux-saga/effects";
import { actions as commonActions } from "modules/common/slice";
import { I18n } from "common/components";
import { successNotify } from "utils/notificationUtils";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "../../paths";
import { TRAINING_PATH as PATH } from "../paths";
import * as api from "./api";
import { setCommonTableData, getPaginationPayload } from "utils/tableUtils";
import { TABLE_IDS } from "common/constants";
import { getTableProps } from "modules/common/selectors";
import { getAdditionalFilters } from "utils/tableUtils";
import moment from "moment";
import { toEpoch } from "utils/dateUtils";

export function* saveTrainingSchedule({ payload }) {

    let { id } = payload;
    if (id) {
        yield fork(handleAPIRequest, editScheduleFormAPI, payload);
        const responseAction = yield take([ACTION_TYPES.EDIT_SCHEDULE_FORM_SUCCESS, ACTION_TYPES.EDIT_SCHEDULE_FORM_FAILURE]);
        if (responseAction.type === ACTION_TYPES.EDIT_SCHEDULE_FORM_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("training_schedule") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.SCHEDULE.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, api.saveTrainingScheduleAPI, payload);
        const responseAction = yield take([ACTION_TYPES.SAVE_SCHEDULE_FORM_SUCCESS, ACTION_TYPES.SAVE_SCHEDULE_FORM_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SAVE_SCHEDULE_FORM_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("saved", { type: I18n("training_schedule") }) }));
            yield put(navigateTo(`${MODULE_PATH.TRAINING}/${PATH.SCHEDULE.LIST}`));
        }

    }
}

export function* listTrainingSchedule({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listTrainingScheduleApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_SCHEDULE_DETAILS_SUCCESS, ACTION_TYPES.LIST_SCHEDULE_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TRAINING_SCHEDULE);
}

export function* fetchCourseId({ payload: id }) {
    yield call(handleAPIRequest, api.fetchCourseIdApi, { id });
}
export function* fetchSessionId({ payload: courseId }) {
    yield call(handleAPIRequest, fetchSessionIdApi, { courseId });
}

export function* fetchBatchId() {
    yield call(handleAPIRequest, api.fetchBatchIdApi);
}
export function* fetchTrainerId({ payload: trainingOrganizationId }) {
    yield call(handleAPIRequest, fetchTrainerIdApi, { trainingOrganizationId });
}

export function* fetchTrainingOrganization(action) {
    yield call(handleAPIRequest, fetchTrainingOrganizationApi, action.payload);
}

export function* fetchQuestionId(action) {
    yield call(handleAPIRequest, api.fetchQuestionIdApi, action.payload);
}
export function* editScheduleById({ payload: id }) {
    yield call(handleAPIRequest, editScheduleByIdApi, { id });
}

export function* deleteScheduleById({ payload: id }) {
    yield fork(handleAPIRequest, deleteScheduleByIdAPI, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_SCHEDULE_FORM_SUCCESS, ACTION_TYPES.DELETE_SCHEDULE_FORM_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_SCHEDULE_FORM_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("schedule_row") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listTrainingSchedule, { payload: tablePayload });
    }
}

export function* editBatchFormById({ payload: id }) {
    yield call(handleAPIRequest, api.editBatchFormByIdAPI, { id });
}

export function* fetchSessionType({ payload: id }) {
    yield call(handleAPIRequest, api.fetchSessionTypeApi, { id });
}

export function* viewParticipantDetails({ payload }) {
    const { scheduleId, batchId } = payload;
    let additionalFilters = yield* getAdditionalFilters(TABLE_IDS.MARK_ATTENDANCE);
    yield fork(handleAPIRequest, api.attendanceDetailsApi, scheduleId, batchId, { ...additionalFilters });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_SUCCESS, ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.MARK_ATTENDANCE);
}

export function* fetchActivateCheckIn({ payload: id }) {
    let date = toEpoch(moment()
        .utcOffset("+05:30")
        .format("YYYY-MM-DD hh:mm:ss a"));
    let checkInTime = date;
    let activatePayload = new FormData();
    let scheduleId = { id };
    activatePayload.scheduleId = scheduleId;
    activatePayload.date = date;
    activatePayload.checkInTime = checkInTime;
    yield call(handleAPIRequest, api.activateCheckInByIdApi, activatePayload);
}
export function* fetchActivateCheckOut({ payload: id }) {
    let date = toEpoch(moment()
        .utcOffset("+05:30")
        .format("YYYY-MM-DD hh:mm:ss a"));
    let checkOutTime = date;

    let activatePayload = new FormData();
    activatePayload.id = id;
    activatePayload.checkOutTime = checkOutTime;

    yield call(handleAPIRequest, api.activateCheckOutByIdApi, activatePayload);
}


export function* filterScheduleBatchDropdown({ payload }) {
    yield call(handleAPIRequest, api.filterScheduleBatch, payload);
}
export default function* scheduleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SAVE_SCHEDULE_FORM, saveTrainingSchedule),
        takeLatest(ACTION_TYPES.LIST_SCHEDULE_DETAILS, listTrainingSchedule),
        takeLatest(ACTION_TYPES.FETCH_COURSE_ID, fetchCourseId),
        takeLatest(ACTION_TYPES.FETCH_SESSION_ID, fetchSessionId),
        takeLatest(ACTION_TYPES.FETCH_BATCH_ID, fetchBatchId),
        takeLatest(ACTION_TYPES.FETCH_QUESTION_ID, fetchQuestionId),
        takeLatest(ACTION_TYPES.FETCH_TRAINER_ID, fetchTrainerId),
        takeLatest(ACTION_TYPES.FETCH_TRAINING_ORGANIZATION, fetchTrainingOrganization),
        takeLatest(ACTION_TYPES.DELETE_SCHEDULE_FORM, deleteScheduleById),
        takeLatest(ACTION_TYPES.EDIT_SCHEDULE_FORM_BY_ID, editScheduleById),
        takeLatest(ACTION_TYPES.FILTER_SCHEDULE_BATCH, filterScheduleBatchDropdown),
        takeLatest(ACTION_TYPES.FETCH_FILTER_REQUEST, editBatchFormById),
        takeLatest(ACTION_TYPES.FETCH_SESSION_TYPE, fetchSessionType),
        takeLatest(ACTION_TYPES.FETCH_PARTICIPANTS_DETAILS, viewParticipantDetails),
        takeLatest(ACTION_TYPES.ACTIVATE_CHECK_IN, fetchActivateCheckIn),
        takeLatest(ACTION_TYPES.ACTIVATE_CHECK_OUT, fetchActivateCheckOut)


    ]);
}

