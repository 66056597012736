import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    SAVE_CERTIFICATE_DETAILS: `${STATE_REDUCER_KEY}/SAVE_CERTIFICATE_DETAILS`,
    SAVE_CERTIFICATE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SAVE_CERTIFICATE_DETAILS_REQUEST`,
    SAVE_CERTIFICATE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_CERTIFICATE_DETAILS_SUCCESS`,
    SAVE_CERTIFICATE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SAVE_CERTIFICATE_DETAILS_FAILURE`,

    LIST_CERTIFICATE_DETAILS: `${STATE_REDUCER_KEY}/LIST_CERTIFICATE_DETAILS`,
    LIST_CERTIFICATE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/LIST_CERTIFICATE_DETAILS_REQUEST`,
    LIST_CERTIFICATE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_CERTIFICATE_DETAILS_SUCCESS`,
    LIST_CERTIFICATE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/LIST_CERTIFICATE_DETAILS_FAILURE`,

    FETCH_TEMPLATE: `${STATE_REDUCER_KEY}/FETCH_TEMPLATE`,
    FETCH_TEMPLATE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TEMPLATE_REQUEST`,
    FETCH_TEMPLATE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TEMPLATE_SUCCESS`,
    FETCH_TEMPLATE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TEMPLATE_FAILURE`,

    FETCH_VENUE: `${STATE_REDUCER_KEY}/FETCH_VENUE`,
    FETCH_VENUE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_VENUE_REQUEST`,
    FETCH_VENUE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_VENUE_SUCCESS`,
    FETCH_VENUE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_VENUE_FAILURE`,

    FETCH_BATCH: `${STATE_REDUCER_KEY}/FETCH_BATCH`,
    FETCH_BATCH_REQUEST: `${STATE_REDUCER_KEY}/FETCH_BATCH_REQUEST`,
    FETCH_BATCH_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_BATCH_SUCCESS`,
    FETCH_BATCH_FAILURE: `${STATE_REDUCER_KEY}/FETCH_BATCH_FAILURE`,

    FETCH_DESIGNATION: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION`,
    FETCH_DESIGNATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_REQUEST`,
    FETCH_DESIGNATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_SUCCESS`,
    FETCH_DESIGNATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DESIGNATION_FAILURE`,

    DELETE_CERTIFICATE: `${STATE_REDUCER_KEY}/DELETE_CERTIFICATE`,
    DELETE_CERTIFICATE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_CERTIFICATE_REQUEST`,
    DELETE_CERTIFICATE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_CERTIFICATE_SUCCESS`,
    DELETE_CERTIFICATE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_CERTIFICATE_FAILURE`,

    EDIT_CERTIFICATE: `${STATE_REDUCER_KEY}/EDIT_CERTIFICATE`,
    EDIT_CERTIFICATE_REQUEST: `${STATE_REDUCER_KEY}/EDIT_CERTIFICATE_REQUEST`,
    EDIT_CERTIFICATE_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_CERTIFICATE_SUCCESS`,
    EDIT_CERTIFICATE_FAILURE: `${STATE_REDUCER_KEY}/EDIT_CERTIFICATE_FAILURE`,

    EDIT_CERTIFICATE_BY_ID: `${STATE_REDUCER_KEY}/EDIT_CERTIFICATE_BY_ID`,
    EDIT_CERTIFICATE_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/EDIT_CERTIFICATE_BY_ID_REQUEST`,
    EDIT_CERTIFICATE_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_CERTIFICATE_BY_ID_SUCCESS`,
    EDIT_CERTIFICATE_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/EDIT_CERTIFICATE_BY_ID_FAILURE`,

    FETCH_LSGI_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN`,
    FETCH_LSGI_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_REQUEST`,
    FETCH_LSGI_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_SUCCESS`,
    FETCH_LSGI_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LSGI_DROPDOWN_FAILURE`
};
export const saveCertificate = createAction(ACTION_TYPES.SAVE_CERTIFICATE_DETAILS);
export const listCertificateForm = createAction(ACTION_TYPES.LIST_CERTIFICATE_DETAILS);
export const fetchTemplate = createAction(ACTION_TYPES.FETCH_TEMPLATE);
export const fetchVenue = createAction(ACTION_TYPES.FETCH_VENUE);
export const fetchBatch = createAction(ACTION_TYPES.FETCH_BATCH);
export const fetchDesignation = createAction(ACTION_TYPES.FETCH_DESIGNATION);
export const deleteCertificate = createAction(ACTION_TYPES.DELETE_CERTIFICATE);
export const editCertificate = createAction(ACTION_TYPES.EDIT_CERTIFICATE);
export const editCertificateById = createAction(ACTION_TYPES.EDIT_CERTIFICATE_BY_ID);
export const fetchLsgiDropdown = createAction(ACTION_TYPES.FETCH_LSGI_DROPDOWN);
