import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadContent } from "utils/apiUtils";
import { COMMON_TABLE_PAGINATION } from "../../common/constants";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {

    complaintCategoryDropdown: {
        requestInProgress: false,
        data: []
    },
    complaintCategory: {
        requestInProgress: false,
        data: []
    },
    complaintCategoryDetails: {
        requestInProgress: false,
        data: {
            name: "",
            description: ""
        }
    },
    complaintTypeDetails: {
        requestInProgress: false,
        data: {
            name: "",
            description: "",
            categoryId: ""
        }
    },
    complaintStatusDetails: {
        requestInProgress: false,
        data: {
            name: "",
            description: ""
        }
    },
    tableFilter: {},
    searchKey: {},
    tablePagination: { ...COMMON_TABLE_PAGINATION },
    escalationUnitDropDown: {
        requestInProgress: false,
        data: []
    },
    targetRoleLevelDropDown: {
        requestInProgress: false,
        data: []
    },
    officersList: {
        requestInProgress: false,
        data: [],
        rowSelection: {},
        rowSelectionState: {},
        additionalFilters: {}
    },
    targetLevelDetails: { targetLevel: {}, levelIndex: -1, officerIndex: 0 },
    complaintEscalationDetails: {
        requestInProgress: false,
        data: {
            totalRecords: 0,
            totalPage: 0,
            pageNo: 0,
            pageSize: 2,
            escalationLevelDetails: [{
                escalationLevel: "",
                complaintTypeId: "",
                stateId: "",
                districtId: "",
                lsgiType: "",
                lsgiId: "",
                wardId: "",
                isSubmit: true,
                escalationMatrix: [
                    {
                        escalationLevel: "",
                        escalationTargetLevel: "",
                        escalationUserId: "",
                        escalationUnit: "",
                        escalationThreshold: ""
                    }
                ]
            }]
        }
    },
    searchFields: {
        requestInProgress: false,
        data: {
            escalationTargetLevel: "",
            escalationUserId: "",
            escalationUnit: "",
            escalationThreshold: "",
            complaintTypeId: "",
            stateId: { id: 1, name: "Kerala" },
            districtId: "",
            lsgiType: "",
            lsgiId: "",
            wardId: ""
        }
    },
    isSubmit: true,
    lsgiID: "",
    districtID: "",
    filters: {
        name: []
    },
    listCategoryName: {}
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.complaintTypeDetails = initialState.complaintTypeDetails;
            state.complaintCategoryDetails = initialState.complaintCategoryDetails;
            state.complaintStatusDetails = initialState.complaintStatusDetails;
        },
        setPagination: (state, action) => {
            _.set(state, "tablePagination", action.payload);
        },
        setRegistrationDetails: (state, action) => {
            state.complaintTypeDetails.data = action.payload;
        },
        setCategoryDetails: (state, action) => {
            state.complaintCategoryDetails.data = action.payload;
        },
        setStatusDetails: (state, action) => {
            state.complaintStatusDetails.data = action.payload;
        },
        resetPagination: (state) => {
            _.set(state, "tablePagination", initialState.tablePagination);
        },
        clearFilterTable: (state) => {
            state.officersList = initialState.officersList;
        },
        setLevel: (state, { payload: { index } } = {}) => {
            _.set(state, "targetLevelDetails.levelIndex", index);
        },
        setFullLevel: (state, { payload: { level, index } } = {}) => {
            _.set(state, "targetLevelDetails.levelIndex", level);
            _.set(state, "targetLevelDetails.officerIndex", index);
        },
        setLevelFields: (state, { payload = {} }) => {
            let index = _.get(state, "targetLevelDetails.levelIndex", 0);
            _.set(state, `complaintEscalationDetails.data.escalationLevelDetails[${index}]`, payload);
            _.set(state, `complaintEscalationDetails.data.escalationLevelDetails[${index}].isSubmit`, true);
            _.set(state, `complaintEscalationDetails.data.escalationLevelDetails[${index}].escalationMatrix[0]`, {
                escalationTargetLevel: "",
                escalationUserId: "",
                escalationUnit: "",
                escalationThreshold: ""
            });
        },
        setRowSelectionState: (state, { payload = {} }) => {
            _.set(state, "officersList.rowSelectionState", payload);
        },
        setRowSelection: (state, { payload: { escalationUserId } = {} }) => {
            let index = _.get(state, "targetLevelDetails.levelIndex", 0);
            let offIdx = _.get(state, "targetLevelDetails.officerIndex", 0);
            _.set(state, "officersList.rowSelection", escalationUserId);
            _.set(state, `complaintEscalationDetails.data.escalationLevelDetails[${index}].escalationMatrix[${offIdx}].escalationUserId`, escalationUserId);
            _.set(state, `complaintEscalationDetails.data.escalationLevelDetails[${index}].escalationMatrix[${offIdx}].escalationLevel`, offIdx + 1);
            _.set(state, `complaintEscalationDetails.data.escalationLevelDetails[${index}].escalationMatrix[${offIdx}].escalationThreshold`, "");
            _.set(state, `complaintEscalationDetails.data.escalationLevelDetails[${index}].escalationMatrix[${offIdx}].escalationUnit`, "");
        },
        setEscalationDetails: (state) => {
            state.complaintEscalationDetails.data.escalationLevelDetails.push(initialState.complaintEscalationDetails.data.escalationLevelDetails[0]);
        },
        removeEscalationDetails: (state, { payload }) => {
            state.complaintEscalationDetails.data.escalationLevelDetails.splice(payload, 1);
        },
        setEscalationLevel: (state, { payload: { index } }) => {
            if (state.complaintEscalationDetails.data.escalationLevelDetails[index].escalationMatrix) {
                state.complaintEscalationDetails.data.escalationLevelDetails[index].escalationMatrix.push(initialState.complaintEscalationDetails.data.escalationLevelDetails[0].escalationMatrix[0]);
            } else {
                state.complaintEscalationDetails.data.escalationLevelDetails[index].escalationMatrix = initialState.complaintEscalationDetails.data.escalationLevelDetails[0].escalationMatrix;

            }
        },
        removeEscalationLevel: (state, { payload: { index, result } }) => {
            _.set(state, `complaintEscalationDetails.data.escalationLevelDetails[${index}].escalationMatrix`, result);
        },
        setArrayValue: (state, { payload: { value, index, idx, fieldKey } = {} }) => {
            _.set(state, `complaintEscalationDetails.data.escalationLevelDetails[${index}].escalationMatrix[${idx}].${fieldKey}`, value);
        },
        setAdditionalFilter: (state, action) => {
            _.set(state, "officersList.additionalFilters", action.payload);
        },
        setCategoryName: (state, { payload: { key, value } = {} }) => {
            _.set(state, `listCategoryName.${key}`, value);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_SUCCESS, (state, { payload }) => {
            _.set(state, "complaintCategoryDropdown.requestInProgress", false);
            _.set(state, "complaintCategoryDropdown.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FETCH_COMPLAINT_TYPE_BY_ID_SUCCESS, (state, { payload }) => {
            _.set(state, "complaintTypeDetails.requestInProgress", false);
            _.set(state, "complaintTypeDetails.data", payload.content[0]);
        });
        builder.addCase(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_BY_ID_SUCCESS, (state, { payload }) => {
            _.set(state, "complaintCategoryDetails.requestInProgress", false);
            _.set(state, "complaintCategoryDetails.data", payload.content[0]);
        });
        builder.addCase(ACTION_TYPES.FETCH_COMPLAINT_STATUS_BY_ID_SUCCESS, (state, { payload }) => {
            _.set(state, "complaintStatusDetails.requestInProgress", false);
            _.set(state, "complaintStatusDetails.data", payload.content[0]);
        })
            .addCase(ACTION_TYPES.FETCH_ESCALATION_UNIT_DROPDOWN_REQUEST, (state) => {
                state.escalationUnitDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_ESCALATION_UNIT_DROPDOWN_SUCCESS, (state, action) => {
                state.escalationUnitDropDown.requestInProgress = false;
                state.escalationUnitDropDown.data = getPayloadContent(action);
            })
            .addCase(ACTION_TYPES.FETCH_TARGET_LEVEL_DROPDOWN_REQUEST, (state) => {
                state.targetRoleLevelDropDown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_TARGET_LEVEL_DROPDOWN_SUCCESS, (state, action) => {
                state.targetRoleLevelDropDown.requestInProgress = false;
                state.targetRoleLevelDropDown.data = getPayloadContent(action);
            })
            .addCase(ACTION_TYPES.FETCH_OFFICERS_LIST_REQUEST, (state) => {
                state.officersList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_OFFICERS_LIST_SUCCESS, (state, action) => {
                state.showTable = true;
                state.officersList.requestInProgress = false;
                state.officersList.data = getPayloadContent(action);
            })
            .addCase(ACTION_TYPES.FETCH_OFFICERS_LIST_FAILURE, (state) => {
                state.showTable = false;
                state.officersList.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID_REQUEST, (state) => {
                state.complaintEscalationDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FILTER_COMPLAINT_TYPE_SUCCESS, (state, action) => {
                _.set(state, "filters.name", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.FETCH_ESCALATION_LEVEL_BY_ID_SUCCESS, (state, action) => {
                let originalPayload = getPayloadContent(action);
                const payloadData = originalPayload.map(item => {
                    const reData = {
                        id: item.id,
                        complaintTypeId: item.complaintTypeId,
                        escalationMatrix: item.escalationMatrix,
                        wardId: item.wardId,
                        lsgiId: item.wardId.lsgiId,
                        lsgiType: item.wardId.lsgiId.lsgiTypeId,
                        districtId: item.wardId.lsgiId.districtId,
                        stateId: item.wardId.lsgiId.districtId.stateId,
                        isSubmit: false
                    };
                    return reData;
                });
                state.complaintEscalationDetails.requestInProgress = false;
                if (originalPayload.length > 0) {
                    state.isSubmit = false;
                    state.complaintEscalationDetails.data.escalationLevelDetails = payloadData;
                    _.set(state, "complaintEscalationDetails.data.totalRecords", action.payload.totalRecords);
                    _.set(state, "complaintEscalationDetails.data.totalPage", action.payload.totalPages);
                }
            });

}
});

export const { actions, reducer } = slice;
