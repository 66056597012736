import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    SAVE_PLAN_DETAILS: `${STATE_REDUCER_KEY}/SAVE_PLAN_DETAILS`,
    SAVE_PLAN_REQUEST: `${STATE_REDUCER_KEY}/SAVE_PLAN_REQUEST`,
    SAVE_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_PLAN_SUCCESS`,
    SAVE_PLAN_FAILURE: `${STATE_REDUCER_KEY}/SAVE_PLAN_FAILURE`,

    FETCH_PLAN_DETAILS: `${STATE_REDUCER_KEY}/FETCH_PLAN_DETAILS`,
    FETCH_PLAN_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PLAN_DETAILS_REQUEST`,
    FETCH_PLAN_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PLAN_DETAILS_SUCCESS`,
    FETCH_PLAN_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PLAN_DETAILS_FAILURE`,

    FETCH_TRAINER_NEED_ID: `${STATE_REDUCER_KEY}/FETCH_TRAINER_NEED_ID`,
    FETCH_TRAINER_NEED_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TRAINER_NEED_ID_REQUEST`,
    FETCH_TRAINER_NEED_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TRAINER_NEED_ID_SUCCESS`,
    FETCH_TRAINER_NEED_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TRAINER_NEED_ID_FAILURE`,

    DELETE_TRAINING_PLAN_BY_ID: `${STATE_REDUCER_KEY}/DELETE_TRAINING_PLAN_BY_ID`,
    DELETE_TRAINING_PLAN_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/DELETE_TRAINING_PLAN_BY_ID_REQUEST`,
    DELETE_TRAINING_PLAN_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_TRAINING_PLAN_BY_ID_SUCCESS`,
    DELETE_TRAINING_PLAN_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/DELETE_TRAINING_PLAN_BY_ID_FAILURE`,

    EDIT_PLAN_FORM: `${STATE_REDUCER_KEY}/EDIT_PLAN_FORM`,
    EDIT_PLAN_FORM_REQUEST: `${STATE_REDUCER_KEY}/EDIT_PLAN_FORM_REQUEST`,
    EDIT_PLAN_FORM_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_PLAN_FORM_SUCCESS`,
    EDIT_PLAN_FORM_FAILURE: `${STATE_REDUCER_KEY}/EDIT_PLAN_FORM_FAILURE`,

    EDIT_PLAN_BY_ID: `${STATE_REDUCER_KEY}/EDIT_PLAN_BY_ID`,
    EDIT_PLAN_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/EDIT_PLAN_BY_ID_REQUEST`,
    EDIT_PLAN_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_PLAN_BY_ID_SUCCESS`,
    EDIT_PLAN_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/EDIT_PLAN_BY_ID_FAILURE`,


    FETCH_PLAN_BY_ID: `${STATE_REDUCER_KEY}/FETCH_PLAN_BY_ID`,
    FETCH_PLAN_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PLAN_BY_ID_REQUEST`,
    FETCH_PLAN_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PLAN_BY_ID_SUCCESS`,
    FETCH_PLAN_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PLAN_BY_ID_FAILURE`,

    FETCH_PLAN_DESIGNATION: `${STATE_REDUCER_KEY}/FETCH_PLAN_DESIGNATION`,
    FETCH_PLAN_DESIGNATION_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PLAN_DESIGNATION_REQUEST`,
    FETCH_PLAN_DESIGNATION_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PLAN_DESIGNATION_SUCCESS`,
    FETCH_PLAN_DESIGNATION_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PLAN_DESIGNATION_FAILURE`,

    FETCH_COURSE_PLAN: `${STATE_REDUCER_KEY}/FETCH_COURSE_PLAN`,
    FETCH_COURSE_PLAN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_COURSE_PLAN_REQUEST`,
    FETCH_COURSE_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_COURSE_PLAN_SUCCESS`,
    FETCH_COURSE_PLAN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_COURSE_PLAN_FAILURE`,

    FETCH_TARGET_GROUP_CATEGORY: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP_CATEGORY`,
    FETCH_TARGET_GROUP_CATEGORY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP_CATEGORY_REQUEST`,
    FETCH_TARGET_GROUP_CATEGORY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP_CATEGORY_SUCCESS`,
    FETCH_TARGET_GROUP_CATEGORY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TARGET_GROUP_CATEGORY_FAILURE`,

    DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
    DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
    DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
    DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

    FETCH_FILE: `${STATE_REDUCER_KEY}/FETCH_FILE`,
    FETCH_FILE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_FILE_REQUEST`,
    FETCH_FILE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_FILE_SUCCESS`,
    FETCH_FILE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_FILE_FAILURE`

};

export const saveTrainingPlanDetails = createAction(ACTION_TYPES.SAVE_PLAN_DETAILS);
export const loadTrainingPlan = createAction(ACTION_TYPES.FETCH_PLAN_DETAILS);
export const getTrainingNeedId = createAction(ACTION_TYPES.FETCH_TRAINER_NEED_ID);
export const deleteTrainingPlan = createAction(ACTION_TYPES.DELETE_TRAINING_PLAN_BY_ID);
export const getPlanDesignation = createAction(ACTION_TYPES.FETCH_PLAN_DESIGNATION);
export const getCoursePlan = createAction(ACTION_TYPES.FETCH_COURSE_PLAN);
export const getTargetGroupCategory = createAction(ACTION_TYPES.FETCH_TARGET_GROUP_CATEGORY);
export const editTrainingPlan = createAction(ACTION_TYPES.EDIT_PLAN_FORM);
export const editPlanById = createAction(ACTION_TYPES.EDIT_PLAN_BY_ID);
export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const getFile = createAction(ACTION_TYPES.FETCH_FILE);


