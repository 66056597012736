import { I18n } from "common/components";
import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { deleteFileById, deleteFinalSubProject, finalPlanByIdApi, finalPlanGet, finalSelectedPlanList, listFinalSubProject, listSubProjectById, saveFinalPlan, saveSubProject, subProjectFinalById, updateFinalSubProject } from "./api";
import { SUB_PROJECTS_PATH as PATH } from "../paths";
import { toEpoch } from "utils/dateUtils";
import { setCommonTableData } from "utils/tableUtils";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "modules/paths";
import { actions as sliceActons } from "../../common/slice";

export function* fetchSelectedPlanList({ payload }) {
    let updatedPayload = { pageNo: payload.pageNo, pageSize: payload.pageSize };
    yield fork(handleAPIRequest, finalSelectedPlanList, updatedPayload, payload?.id);
    const COMPARISON_TYPES = [ACTION_TYPES.SELECTED_PLAN_LIST_SUCCESS, ACTION_TYPES.SELECTED_PLAN_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}
export function* fetchFinalSubProject({ payload }) {
    yield call(handleAPIRequest, listFinalSubProject, payload);
}
export function* fetchSubProject({ payload }) {
    let newPayload = {
        isProjectsExclude: true,
        ...payload
    };
    yield call(handleAPIRequest, listSubProjectById, newPayload);
}
export function* submitFinalPlan({ payload }) {
    yield fork(handleAPIRequest, saveFinalPlan, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_FINAL_PLAN_SUCCESS, ACTION_TYPES.SUBMIT_FINAL_PLAN_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_FINAL_PLAN_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("final_sub_project_plan") }) }));
        let createdPayload = { swmPlanId: payload?.swmPlanId };
        yield call(handleAPIRequest, listFinalSubProject, createdPayload);
        let newPayload = { isProjectsExclude: true, swmPlanId: payload?.swmPlanId };
        yield call(handleAPIRequest, listSubProjectById, newPayload);
        yield put(sliceActons.moveTabUp());
    }
}

export function* finalPlanGetById({ payload: id }) {
    yield call(handleAPIRequest, finalPlanByIdApi, id);
}

export function* finalPlanById({ payload: id }) {
    yield call(handleAPIRequest, finalPlanGet, id);
}

const formatSubProjectData = (rest) => {
    const { startDate, swmPlanId, endDate, categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, attachmentIds } = rest;
    const data = { swmPlanId: Number(swmPlanId), startDate: toEpoch(startDate), categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, endDate: toEpoch(endDate), attachmentIds };
    if (rest.attachmentIds?.length === 0) {
        delete rest.attachmentIds;
    }
    return data;
};
const formatSubProjectEditData = (payload) => {
    const { swmPlanId, id, startDate, endDate, categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, attachmentIds } = payload;
    const data = { swmPlanId: Number(swmPlanId), finalListId: Number(id), startDate: toEpoch(startDate), categoryId, conceptNoteAvailable, envCategoryId, estimateAmount, feasibilityStudyDone, projectCode, projectName, subCategoryId, subCategoryId2, trackId, typeId, endDate: toEpoch(endDate), attachmentIds };
    if (payload.attachmentIds?.length === 0) {
        delete payload.attachmentIds;
    }
    return data;
};
export function* submitSubProject(action) {
    let { payload: { ...rest } = {} } = action;
    yield fork(handleAPIRequest, saveSubProject, formatSubProjectData(rest));
    const responseAction = yield take([ACTION_TYPES.SUBMIT_SUB_PROJECT_SUCCESS, ACTION_TYPES.SUBMIT_SUB_PROJECT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_SUB_PROJECT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("final_sub_project") }) }));
        let createdPayload = { swmPlanId: rest.swmPlanId };
        yield call(handleAPIRequest, listFinalSubProject, createdPayload);
        let newPayload = { isProjectsExclude: true, swmPlanId: action.payload?.swmPlanId };
        yield call(handleAPIRequest, listSubProjectById, newPayload);
    }
}

export function* deleteFileFn({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, deleteFileById, id, fileId);
}

export function* deleteFinalSubProjectForm({ payload }) {
    yield fork(handleAPIRequest, deleteFinalSubProject, payload.id);
    const response = yield take([ACTION_TYPES.DELETE_FINAL_SUB_PROJECT_SUCCESS, ACTION_TYPES.DELETE_FINAL_SUB_PROJECT_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_FINAL_SUB_PROJECT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("final_sub_project") }) }));
        yield put(navigateTo(`${MODULE_PATH.SUB_PROJECTS}/${PATH.SWM_PLAN.LIST}`));
    }
}

export function* subProjectListFinalById({ payload: id }) {
    yield call(handleAPIRequest, subProjectFinalById, id);
}

export function* updateSubProjectListFinal({ payload }) {
    yield fork(handleAPIRequest, updateFinalSubProject, formatSubProjectEditData(payload));
    const responseAction = yield take([ACTION_TYPES.UPDATE_FINAL_SUB_PROJECT_SUCCESS, ACTION_TYPES.UPDATE_FINAL_SUB_PROJECT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_FINAL_SUB_PROJECT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("final_sub_project") }) }));
        let createdPayload = {
            swmPlanId: payload?.swmPlanId
        };
        yield call(handleAPIRequest, listFinalSubProject, createdPayload);
    }
}

export default function* registerSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_FINAL_PLAN, submitFinalPlan),
        takeLatest(ACTION_TYPES.FINAL_PLAN_BY_ID, finalPlanGetById),
        takeLatest(ACTION_TYPES.LIST_FINAL_PLAN_BY_ID, finalPlanById),
        takeLatest(ACTION_TYPES.SUBMIT_SUB_PROJECT, submitSubProject),
        takeLatest(ACTION_TYPES.LIST_FINAL_SUB_PROJECT, fetchFinalSubProject),
        takeLatest(ACTION_TYPES.LIST_SUB_PROJECT, fetchSubProject),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFileFn),
        takeLatest(ACTION_TYPES.DELETE_FINAL_SUB_PROJECT, deleteFinalSubProjectForm),
        takeLatest(ACTION_TYPES.LIST_SUB_PROJECT_FINAL_BY_ID, subProjectListFinalById),
        takeLatest(ACTION_TYPES.UPDATE_FINAL_SUB_PROJECT, updateSubProjectListFinal),
        takeLatest(ACTION_TYPES.SELECTED_PLAN_LIST, fetchSelectedPlanList)
    ]);
}
