const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./actions");
import _ from "lodash";

const initialState = {
    landAcquisition: {
        requestInProgress: false,
        data: {
            landAcquisitionsDto: []
        }
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_LAND_ACQUISITION_DATA_REQUEST, (state) => {
                state.landAcquisition.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_LAND_ACQUISITION_DATA_SUCCESS, (state, action) => {
                _.set(state, "landAcquisition.requestInProgress", false);
                _.set(state, "landAcquisition.data.landAcquisitionsDto", action.payload.content);
            })
            .addCase(ACTION_TYPES.FETCH_LAND_ACQUISITION_DATA_FAILURE, (state) => {
                state.landAcquisition.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;

