import { flow } from "lodash";


const { STATE_REDUCER_KEY } = require("./constants");

const getUserRegisterDetails = (state) => state[STATE_REDUCER_KEY];

const userRegistrationDetails = (user) => user.userRegistrationDetails.data;
export const getUserRegistrationDetails = flow(getUserRegisterDetails, userRegistrationDetails);

const userGender = (user) => user.genderDropdownOptions.data;
export const getGender = flow(getUserRegisterDetails, userGender);

const organizationList = (user) => user.organizationDropdownOptions.data;
export const getOrganizationList = flow(getUserRegisterDetails, organizationList);

const designationList = (user) => user.designationDropdownOptions.data;
export const getDesignationList = flow(getUserRegisterDetails, designationList);

const profileDetails = (user) => user.userRegistrationDetails.data;
export const getProfileDetails = flow(getUserRegisterDetails, profileDetails);

const loginType = (user) => user.loginTypeDropdownOptions.data;
export const getLoginTypeList = flow(getUserRegisterDetails, loginType);

const userType = (user) => user.userTypeDropdownOptions.data;
export const getUserTypeList = flow(getUserRegisterDetails, userType);

const typeOfUser = (user) => user.typeOfUser;
export const getTypeOfUser = flow(getUserRegisterDetails, typeOfUser);

const signUpForm = (user) => user.signUpForm;
export const getSignUpForm = flow(getUserRegisterDetails, signUpForm);

const listJSON = (user) => user.listJSONDropdown;
export const getListJSON = flow(getUserRegisterDetails, listJSON);

const open = (user) => user.modal.openProfileModal;
export const getModalOpen = flow(getUserRegisterDetails, open);

const openSignatureModal = (user) => user.modal.openSignatureModal;
export const getSignatureModal = flow(getUserRegisterDetails, openSignatureModal);

const cropData = (user) => user.cropData;
export const getCropData = flow(getUserRegisterDetails, cropData);

const filterFields = (user) => user.filterFields;
export const getFilterFields = flow(getUserRegisterDetails, filterFields);

const listFirstNameJSON = (user) => user.listJSONDropdownName;
export const getListFirstNameJSON = flow(getUserRegisterDetails, listFirstNameJSON);

const changePasswordForm = (user) => user.changePassword.data;
export const getChangePasswordFormForm = flow(getUserRegisterDetails, changePasswordForm);

const rolesName = (state) => state.rolesName.data;
export const rolesNameSelector = flow(getUserRegisterDetails, rolesName);
