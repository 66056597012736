import { FormController, I18n, Components } from "../../../../common/components";
import { Form, withFormik } from "formik";
import { useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { getCertificateForm, getTemplate, getBatch, getLsgiSelectDropdown, getVenueId } from "../selectors";
import { React, useEffect, useState } from "react";
import { validationSchema } from "../validate";
import { editCertificateById, saveCertificate, fetchBatch, fetchLsgiDropdown, fetchVenue, fetchTemplate } from "../actions";
import { useParams, useLocation } from "react-router-dom";
import { actions as sliceActions } from "../slice";

const { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Typography } = Components;

const TrainingCertificate = (props) => {
    const [view, setView] = useState(false);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { id = 0 } = useParams();
    const { setFieldValue } = props;

    useEffect(() => {
        dispatch(fetchBatch());
        dispatch(fetchTemplate());
        dispatch(fetchVenue());
        dispatch(fetchLsgiDropdown());


        if (pathname.includes("/view")) {
            setView(true);
        }
        if (id) {
            setFieldValue("id", id);
        }
        if (id) {
            setFieldValue("id", id);
            dispatch(editCertificateById(id));
        }
        return () => {
            dispatch(sliceActions.clear());
        };
    }, [id]);
    const handleBatchId = (value) => {
        dispatch(fetchVenue(value?.id));

    };

    return (
        <Card sx={{ m: 2 }} >
            <Form >
                <CardHeader title={I18n("training_certificate")} component={Typography} />
                <Divider />
                <CardContent sx={{ ml: 2, mr: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4} >
                            <FormController disabled={view} control="select" placeholder="--Select--" label={I18n("template")} name="template" options={props.template || []} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} >
                            <FormController disabled={view} control="select" placeholder="--Select--" label={I18n("batch")} name="batch" onChangeFromController={handleBatchId} options={props.batch || []} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} >
                            <FormController disabled={view} control="select" placeholder="--Select--" label={I18n("venue")}
                                name="venueId" options={props.venueId || []} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} >
                            <FormController disabled={view} control="select" placeholder="--Select--" label={I18n("ulb")}
                                name="lsgi" options={props.lsgi || []} />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                    <Grid container justifyContent="center">
                        {!view ?
                            <Button type="submit"> {id ? I18n("update") : I18n("save")}</Button>
                            : null}
                    </Grid>
                </CardActions>
            </Form >
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    certificateForm: getCertificateForm,
    template: getTemplate,
    batch: getBatch,
    venueId: getVenueId,
    lsgi: getLsgiSelectDropdown

});
const mapDispatchToProps = (dispatch) => ({
    submit: (data) => dispatch(saveCertificate(data))
});

const trainingCertificate = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => {
        return props.certificateForm.data;
    },
    validationSchema,
    handleSubmit: (values, { props: { submit } }) => {
        submit(values);
    },

    displayName: "TrainingCertificate"
})(TrainingCertificate);

export default connect(mapStateToProps, mapDispatchToProps)(trainingCertificate);
