import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchDistricts = () => {
    return {
        url: API_URL.COMMON.DISTRICT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISTRICT_LIST_REQUEST, ACTION_TYPES.FETCH_DISTRICT_LIST_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_LIST_FAILURE]
        }
    };
};

export const fetchGrandAllocationListApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.REQUEST_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GRAND_ALLOCATION_LIST_REQUEST, ACTION_TYPES.FETCH_GRAND_ALLOCATION_LIST_SUCCESS, ACTION_TYPES.FETCH_GRAND_ALLOCATION_LIST_FAILURE],
            data
        }
    };
};

export const ieaDropdownApi = () => {
    return {
        url: API_URL.COMMON.IEA_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_IEA_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_IEA_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_IEA_DROPDOWN_FAILURE]
        }
    };
};

export const ulbDropdownApi = (data) => {
    return {
        url: API_URL.COMMON.ULB,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ULB_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_ULB_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_ULB_DROPDOWN_FAILURE],
            data
        }
    };
};

export const searchULBApi = (data) => {
    return {
        url: API_URL.COMMON.ULB_FILTER,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ULB_SEARCH_REQUEST, ACTION_TYPES.FETCH_ULB_SEARCH_SUCCESS, ACTION_TYPES.FETCH_ULB_SEARCH_FAILURE],
            data
        }
    };
};

export const fetchGrandAllocationView = ({ id }) => {
    return {
        url: API_URL.GRAND_REQUEST.VIEW,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GRAND_ALLOCATION_DETAILS_REQUEST, ACTION_TYPES.FETCH_GRAND_ALLOCATION_DETAILS_SUCCESS, ACTION_TYPES.FETCH_GRAND_ALLOCATION_DETAILS_FAILURE],
            data: { id }
        }
    };
};


export const fetchIvaListApi = (data) => ({
    url: API_URL.IVA.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_IVA_LIST_REQUEST, ACTION_TYPES.FETCH_IVA_LIST_SUCCESS, ACTION_TYPES.FETCH_IVA_LIST_FAILURE],
        data
    }
});

export const submitIvaRemarksApi = (data) => {
    return {
        url: API_URL.IVA.REMARKS,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_IVA_REMARKS_REQUEST, ACTION_TYPES.SUBMIT_IVA_REMARKS_SUCCESS, ACTION_TYPES.SUBMIT_IVA_REMARKS_FAILURE],
            data
        }
    };
};

export const fetchEligibilityCriteriaApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.CRITERIA_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ELIGIBILITY_CRITERIA_REQUEST, ACTION_TYPES.FETCH_ELIGIBILITY_CRITERIA_SUCCESS, ACTION_TYPES.FETCH_ELIGIBILITY_CRITERIA_FAILURE],
            data
        }
    };
};

export const fetchPlanViewApi = (data) => {
    return {
        url: API_URL.PLAN_VIEW.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PLAN_VIEW_REQUEST, ACTION_TYPES.FETCH_PLAN_VIEW_SUCCESS, ACTION_TYPES.FETCH_PLAN_VIEW_FAILURE],
            data
        }
    };
};

export const fetchGroupedCriteriaApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.GROUPED_CRITERIA,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GROUPED_CRITERIA_REQUEST, ACTION_TYPES.FETCH_GROUPED_CRITERIA_SUCCESS, ACTION_TYPES.FETCH_GROUPED_CRITERIA_FAILURE],
            data
        }
    };
};

export const fetchCriteriaOptionApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.CRITERIA_OPTION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_CRITERIA_OPTIONS_REQUEST, ACTION_TYPES.FETCH_CRITERIA_OPTIONS_SUCCESS, ACTION_TYPES.FETCH_CRITERIA_OPTIONS_FAILURE],
            data
        }
    };
};

export const fetchCeilingListApi = (data) => {
    return {
        url: API_URL.GRAND_ALLOCATION.GAC_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_CEILING_LIST_REQUEST, ACTION_TYPES.FETCH_CEILING_LIST_SUCCESS, ACTION_TYPES.FETCH_CEILING_LIST_FAILURE],
            data
        }
    };
};

export const submitApplicationApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.SUBMIT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_APPLICATION_REQUEST, ACTION_TYPES.SUBMIT_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_APPLICATION_FAILURE],
            data
        }
    };
};

export const updateApplicationApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_APPLICATION_REQUEST, ACTION_TYPES.UPDATE_APPLICATION_SUCCESS, ACTION_TYPES.UPDATE_APPLICATION_FAILURE],
            data
        }
    };
};

export const submitToDPMUApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.SUBMIT_TO_DPMU,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_DPMU_APPLICATION_REQUEST, ACTION_TYPES.SUBMIT_DPMU_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_DPMU_APPLICATION_FAILURE],
            data
        }
    };
};

export const submitToIvaApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.SUBMIT_TO_IVA,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_IVA_APPLICATION_REQUEST, ACTION_TYPES.SUBMIT_IVA_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_IVA_APPLICATION_FAILURE],
            data
        }
    };
};

export const rejectByDpmuApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.DPMU_REJECT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.REJECT_DPMU_APPLICATION_REQUEST, ACTION_TYPES.REJECT_DPMU_APPLICATION_SUCCESS, ACTION_TYPES.REJECT_DPMU_APPLICATION_FAILURE],
            data
        }
    };
};

export const ivaVerifyApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.IVA_VERIFY,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.IVA_VERIFY_REQUEST, ACTION_TYPES.IVA_VERIFY_SUCCESS, ACTION_TYPES.IVA_VERIFY_FAILURE],
            data
        }
    };
};

export const submitToSpmuApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.SUBMIT_TO_SPMU,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_SPMU_APPLICATION_REQUEST, ACTION_TYPES.SUBMIT_SPMU_APPLICATION_SUCCESS, ACTION_TYPES.SUBMIT_SPMU_APPLICATION_FAILURE],
            data
        }
    };
};

export const approveBySpmuApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.SPMU_APPROVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.APPROVE_SPMU_APPLICATION_REQUEST, ACTION_TYPES.APPROVE_SPMU_APPLICATION_SUCCESS, ACTION_TYPES.APPROVE_SPMU_APPLICATION_FAILURE],
            data
        }
    };
};

export const rejectBySpmuApi = (data) => {
    return {
        url: API_URL.GRAND_REQUEST.SPMU_REJECT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.REJECT_SPMU_APPLICATION_REQUEST, ACTION_TYPES.REJECT_SPMU_APPLICATION_SUCCESS, ACTION_TYPES.REJECT_SPMU_APPLICATION_FAILURE],
            data
        }
    };
};

export const deleteUlbFileApi = (id, fileId) => {
    let DELETE_API_URL = API_URL.GRAND_REQUEST.DELETE_ULB_FILE;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":fileId", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_ULB_FILE_REQUEST, ACTION_TYPES.DELETE_ULB_FILE_SUCCESS, ACTION_TYPES.DELETE_ULB_FILE_FAILURE]
        }
    };
};

export const deleteIvaFileApi = (id, fileId) => {
    let DELETE_API_URL = API_URL.GRAND_REQUEST.DELETE_IVA_FILE;
    DELETE_API_URL = DELETE_API_URL.replace(":id", id);
    DELETE_API_URL = DELETE_API_URL.replace(":fileId", fileId);
    return {
        url: DELETE_API_URL,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_IVA_FILE_REQUEST, ACTION_TYPES.DELETE_IVA_FILE_SUCCESS, ACTION_TYPES.DELETE_IVA_FILE_FAILURE]
        }
    };
};
