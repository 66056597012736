import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const saveDraftPlanRemarksApi = (data) => {
    return {
        url: API_URL.DRAFT_PLAN_REMARKS.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_DRAFT_REMARKS_REQUEST, ACTION_TYPES.SUBMIT_DRAFT_REMARKS_SUCCESS, ACTION_TYPES.SUBMIT_DRAFT_REMARKS_FAILURE],
            data
        }
    };
};

export const getDraftPlanRemarksApi = (data) => {
    return {
        url: API_URL.DRAFT_PLAN.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_DRAFT_REMARKS_LIST_REQUEST, ACTION_TYPES.GET_DRAFT_REMARKS_LIST_SUCCESS, ACTION_TYPES.GET_DRAFT_REMARKS_LIST_FAILURE],
            data
        }
    };
};

