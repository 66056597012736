import { all, takeLatest, call } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { ACTION_TYPES } from "./actions";
import { fetchComplaintStatusReport, fetchComplaintDistrictReport, fetchComplaintCategoryReport } from "./api";

export function* fetchComplaintStatusDetails() {
    yield call(handleAPIRequest, fetchComplaintStatusReport);
}

export function* fetchComplaintDistrictDetails() {
    yield call(handleAPIRequest, fetchComplaintDistrictReport);
}

export function* fetchComplaintCategoryDetails() {
    yield call(handleAPIRequest, fetchComplaintCategoryReport);
}

export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_STATUS_REPORT, fetchComplaintStatusDetails),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_DISTRICT_REPORT, fetchComplaintDistrictDetails),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY_REPORT, fetchComplaintCategoryDetails)
    ]);
}
