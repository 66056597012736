import { I18n } from "common/components";
import { lazy } from "react";
import { ADMIN_PATH as PATH } from "./paths";
import { RESOURCE_ID } from "modules/resources";
const VendorContact = lazy(() => import("./vendordetails/components/VendorContact"));
const VendorContactList = lazy(() => import("./vendordetails/components/VendorContactList"));
const VendorCategory = lazy(() => import("./vendordetails/components/VendorCategory"));
const VendorCategoryList = lazy(() => import("./vendordetails/components/VendorCategoryList"));
const LookUpCategoryType = lazy(() => import("./lookup/components/LookUpCategoryType"));
const LookupForm = lazy(() => import("./lookup/components/LookUpFormList"));
const CreateLookup = lazy(() => import("./lookup/components/CreateLookUpForm"));
const CreateLookUpCategoryType = lazy(() => import("./lookup/components/CreateLookUpCategoryType"));
const DistrictList = lazy(() => import("./districtMaster/components/DistrictList"));
const DistrictForm = lazy(() => import("./districtMaster/components/DistrictForm"));
const ShoAndNgoAdd = lazy(() => import("./shoNgoTom/components/ShoAndNgoAdd"));
const ShoAndNgoList = lazy(() => import("./shoNgoTom/components/ShoAndNgoList"));
const TrainingOrganizationAdd = lazy(() => import("./shoNgoTom/components/TrainingOrganizationAdd"));
const TrainingOrganizationList = lazy(() => import("./shoNgoTom/components/TrainingOrganizationList"));
const WardList = lazy(() => import("./wardMaster/components/WardList"));
const WardForm = lazy(() => import("./wardMaster/components/WardForm"));
const LoksabhaList = lazy(() => import("./loksabhaConstituency/components/LoksabhaList"));
const LoksabhaForm = lazy(() => import("./loksabhaConstituency/components/LoksabhaForm"));
const OtherDepartmentsList = lazy(() => import("./contacts/components/OtherDepartmentsList"));
const OtherDepartmentsAdd = lazy(() => import("./contacts/components/OtherDepartmentsAdd"));
const IeaAndContactsAdd = lazy(() => import("./contacts/components/IeaAndContactsAdd"));
const IeaAndContactsList = lazy(() => import("./contacts/components/IeaAndContactsList"));
const LegislativeList = lazy(() => import("./legislative-constituency/components/LegislativeList"));
const LegislativeForm = lazy(() => import("./legislative-constituency/components/LegislativeForm"));
const UserRoleMappingForm = lazy(() => import("./userRoleMapping/components/UserRoleMappingForm"));
const UserRoleMappingList = lazy(() => import("./userRoleMapping/components/UserRoleMappingList"));
const ResourceManagementList = lazy(() => import("./resourceManagement/components/resourceManagementList"));
const ResourceManagementAdd = lazy(() => import("./resourceManagement/components/resourceManagementAdd"));
const RoleResource = lazy(() => import("./role/components/RoleResource"));
const RoleList = lazy(() => import("./role/components/RoleList"));
const RoleForm = lazy(() => import("./role/components/RoleForm"));
const UserDataAccessList = lazy(() => import("./userRoleMapping/components/UserDataAccessList"));
const DataAccessForm = lazy(() => import("./userRoleMapping/components/DataAccessForm"));
const DesignationList = lazy(() => import("./designation/components/designationEntryList"));
const DesignationForm = lazy(() => import("./designation/components/designationEntryForm"));
const ComplaintCategory = lazy(() => import("./complaint/components/ComplaintCategoryForm"));
const ComplaintStatus = lazy(() => import("./complaint/components/ComplaintStatusForm"));
const ComplaintType = lazy(() => import("./complaint/components/ComplaintTypeForm"));
const ListComplaintType = lazy(() => import("./complaint/components/ListComplaintType"));
const ListComplaintCategory = lazy(() => import("./complaint/components/ListComplaintCategory"));
const ListComplaintStatus = lazy(() => import("./complaint/components/ListComplaintStatus"));
const ComplaintEscalation = lazy(() => import("./complaint/components/ComplaintEscalationForm"));
const Register = lazy(() => import("../grievance/user/components/Register"));
const RegisterList = lazy(() => import("../grievance/user/components/RegisterList"));
const ViewEditUser = lazy(() => import("../grievance/user/components/ViewEditUser"));
const ProfileView = lazy(() => import("../grievance/user/components/ProfileView"));
const UlbMasterDetailsAdd = lazy(() => import("./masterConfiguration/components/UlbMasterDetailsAdd"));
const UlbMasterList = lazy(() => import("./masterConfiguration/components/UlbMasterList"));
const ChangeProfilePassword = lazy(() => import("../grievance/user/components/ResetProfilePassword"));
const WardEscalation = lazy(() => import("./wardMaster/components/WardEscalationForm"));

const routes = [
  {
    title: I18n("organization_setup"),
    id: RESOURCE_ID.ADMIN.ORGANIZATION.ROUTE,
    children: [
      {
        title: I18n("districts"),
        path: PATH.DISTRICT_LIST,
        id: RESOURCE_ID.ADMIN.ORGANIZATION.DISTRICT,
        children: [
          {
            path: "",
            element: <DistrictList />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.DISTRICT
          },
          {
            path: `${PATH.DISTRICT_DETAILS}/:id`,
            element: <DistrictForm />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.DISTRICT
          }
        ]
      },
      {
        title: I18n("legislative_assembly"),
        path: PATH.LEGISLATIVE.LIST,
        id: RESOURCE_ID.ADMIN.ORGANIZATION.LEGISLATIVE,
        children: [
          {
            path: "",
            element: <LegislativeList />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.LEGISLATIVE
          },
          {
            path: ":id",
            element: <LegislativeForm />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.LEGISLATIVE
          },
          {
            path: "create",
            element: <LegislativeForm />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.LEGISLATIVE
          }
        ]
      },
      {
        title: I18n("lok_sabha_constituency"),
        path: PATH.LOKSABHA.LIST,
        id: RESOURCE_ID.ADMIN.ORGANIZATION.LOKSABHA,
        children: [
          {
            path: "",
            element: <LoksabhaList />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.LOKSABHA
          },
          {
            path: ":id",
            element: <LoksabhaForm />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.LOKSABHA
          },
          {
            path: "create",
            element: <LoksabhaForm />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.LOKSABHA
          }
        ]
      },
      {
        title: I18n("lsgi_details"),
        path: PATH.LSGI.LIST,
        id: RESOURCE_ID.ADMIN.ORGANIZATION.ULB,
        children: [
          {
            path: "",
            element: <UlbMasterList />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.ULB
          },
          {
            path: `${PATH.LSGI.CREATE}`,
            element: <UlbMasterDetailsAdd />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.ULB
          },
          {
            path: `${PATH.LSGI.EDIT}`,
            element: <UlbMasterDetailsAdd />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.ULB
          },
          {
            path: `${PATH.LSGI.DETAILS}`,
            element: <UlbMasterDetailsAdd />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.ULB
          }
        ]
      },
      {
        title: I18n("wards"),
        path: PATH.WARD.LIST,
        id: RESOURCE_ID.ADMIN.ORGANIZATION.WARD.LIST,
        children: [
          {
            path: "",
            element: <WardList />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.WARD.LIST
          },
          {
            path: "create",
            element: <WardForm />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.WARD.LIST
          },
          {
            path: ":id/escalation",
            element: <WardEscalation />,
            id: RESOURCE_ID.ADMIN.ORGANIZATION.WARD.ESCALATION
          }
        ]
      }
    ]
  },
  {
    title: I18n("general_setup"),
    id: RESOURCE_ID.ADMIN.GENERAL_SETUP.ROUTE,
    children: [
      {
        title: I18n("lookup_configuration"),
        id: RESOURCE_ID.ADMIN.GENERAL_SETUP.LOOKUP_CONFIGURATION.ROUTE,
        children: [
          {
            title: I18n("lookup_category"),
            path: PATH.LOOK_UP_CATEGORY_TYPE.LIST,
            id: RESOURCE_ID.ADMIN.GENERAL_SETUP.LOOKUP_CONFIGURATION.LOOKUP.CATEGORY.LIST,
            children: [
              {
                path: "",
                element: <LookUpCategoryType />
              },
              {
                path: "create",
                element: <CreateLookUpCategoryType />,
                id: RESOURCE_ID.ADMIN.GENERAL_SETUP.LOOKUP_CONFIGURATION.LOOKUP.CATEGORY.ACTIONS.ADD
              },
              {
                path: ":id/edit",
                element: <CreateLookUpCategoryType />,
                id: RESOURCE_ID.ADMIN.GENERAL_SETUP.LOOKUP_CONFIGURATION.LOOKUP.CATEGORY.ACTIONS.EDIT
              }
            ]
          },
          {
            title: I18n("look_up"),
            path: PATH.LOOK_UP_FORM.LIST,
            id: RESOURCE_ID.ADMIN.GENERAL_SETUP.LOOKUP_CONFIGURATION.LOOKUP.VALUE.LIST,
            children: [
              {
                path: "",
                element: <LookupForm />
              },
              {
                path: "create",
                element: <CreateLookup />,
                id: RESOURCE_ID.ADMIN.GENERAL_SETUP.LOOKUP_CONFIGURATION.LOOKUP.VALUE.ACTIONS.ADD
              },
              {
                path: ":id/edit",
                element: <CreateLookup />,
                id: RESOURCE_ID.ADMIN.GENERAL_SETUP.LOOKUP_CONFIGURATION.LOOKUP.VALUE.ACTIONS.EDIT
              }]
          }

        ]
      }
    ]
  },
  {
    title: I18n("role_management"),
    id: RESOURCE_ID.ADMIN.ROLE_MANAGEMENT.ROUTE,
    children: [
      {
        title: I18n("roles"),
        path: PATH.ROLE.LIST,
        id: RESOURCE_ID.ADMIN.ROLE_MANAGEMENT.ROLES.ACTIONS.EDIT,
        children: [{
          path: "",
          element: <RoleList />
        },
        {
          path: ":id/edit",
          element: <RoleForm />,
          id: RESOURCE_ID.ADMIN.ROLE_MANAGEMENT.ROLES.ACTIONS.EDIT
        },
        {
          path: "create",
          element: <RoleForm />,
          id: RESOURCE_ID.ADMIN.ROLE_MANAGEMENT.ROLES.ACTIONS.ADD
        },
        {
          path: ":id/view",
          element: <RoleForm />,
          id: RESOURCE_ID.ADMIN.ROLE_MANAGEMENT.ROLES.ACTIONS.VIEW
        },
        {
          path: ":id/resource",
          element: <RoleResource />
        }
        ]
      },
      {
        path: PATH.USER_ROLE_MAPPING.LIST,
        element: <UserRoleMappingList />
      },
      {
        path: PATH.USER_ROLE_MAPPING.CREATE,
        element: <UserRoleMappingForm />
      },
      {
        path: PATH.USER_ROLE_MAPPING.DATA_ACCESS_LIST,
        element: <UserDataAccessList />
      },
      {
        path: PATH.USER_ROLE_MAPPING.DATA_ACCESS,
        element: <UserDataAccessList />
      },
      {
        path: PATH.USER_ROLE_MAPPING.DATA_ACCESS_CREATE,
        element: <DataAccessForm />
      },
      {
        path: PATH.USER_ROLE_MAPPING.EDIT,
        element: <DataAccessForm />
      },

      {
        title: I18n("menu_management"),
        path: PATH.RESOURCE_MANAGEMENT.LIST,
        id: RESOURCE_ID.ADMIN.ROLE_MANAGEMENT.MENU_MANAGEMENT.LIST,
        children: [
          {
            path: "",
            element: <ResourceManagementList />
          },
          {
            path: `${PATH.RESOURCE_MANAGEMENT.CREATE}`,
            element: <ResourceManagementAdd />,
            id: RESOURCE_ID.ADMIN.ROLE_MANAGEMENT.MENU_MANAGEMENT.ACTIONS.ADD
          },
          {
            path: `${PATH.RESOURCE_MANAGEMENT.EDIT}`,
            element: <ResourceManagementAdd />,
            id: RESOURCE_ID.ADMIN.ROLE_MANAGEMENT.MENU_MANAGEMENT.ACTIONS.EDIT
          },
          {
            path: `${PATH.RESOURCE_MANAGEMENT.DETAILS}`,
            element: <ResourceManagementAdd />,
            id: RESOURCE_ID.ADMIN.ROLE_MANAGEMENT.MENU_MANAGEMENT.ACTIONS.VIEW
          }
        ]
      }

    ]
  },
  {
    title: I18n("user_register_route"),
    path: PATH.USER.LIST,
    id: RESOURCE_ID.ADMIN.USER_REGISTER.LIST,
    children: [
      {
        path: "",
        element: <RegisterList />,
        id: RESOURCE_ID.ADMIN.USER_REGISTER.LIST
      },
      {
        path: PATH.USER.CREATE,
        element: <Register />,
        id: RESOURCE_ID.ADMIN.USER_REGISTER.ACTIONS.ADD
      },
      {
        path: PATH.USER.EDIT,
        element: <ViewEditUser />,
        id: RESOURCE_ID.ADMIN.USER_REGISTER.ACTIONS.EDIT

      },
      {
        path: PATH.USER.VIEW,
        element: <ViewEditUser />,
        id: RESOURCE_ID.ADMIN.USER_REGISTER.ACTIONS.VIEW
      }
    ]
  },

  {
    path: PATH.PROFILE.VIEW,
    element: <ProfileView />
  },
  {
    path: PATH.PROFILE.SETTINGS,
    element: <ChangeProfilePassword />
  },
  {
    title: I18n("external_organization"),
    id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.ROUTE,
    children: [
      {
        title: I18n("other_departments"),
        path: PATH.OTHER_DEPARTMENT.LIST,
        id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.OTHER_DEPARTMENT.LIST,
        children: [
          {
            path: "",
            element: <OtherDepartmentsList />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.OTHER_DEPARTMENT.LIST
          },
          {
            path: "create",
            element: <OtherDepartmentsAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.OTHER_DEPARTMENT.ACTIONS.CREATE
          },
          {
            path: ":id/edit",
            element: <OtherDepartmentsAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.OTHER_DEPARTMENT.ACTIONS.EDIT
          },
          {
            path: ":id/view",
            element: <OtherDepartmentsAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.OTHER_DEPARTMENT.ACTIONS.VIEW
          }
        ]
      },
      {
        title: I18n("iea"),
        path: PATH.IEA.LIST,
        id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.IEA.LIST,
        children: [
          {
            path: "",
            element: <IeaAndContactsList />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.IEA.LIST
          },
          {
            path: "create",
            element: <IeaAndContactsAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.IEA.ACTIONS.ADD
          },
          {
            path: ":id/edit",
            element: <IeaAndContactsAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.IEA.ACTIONS.EDIT
          },
          {
            path: ":id/view",
            element: <IeaAndContactsAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.IEA.ACTIONS.VIEW
          }
        ]
      },
      {
        title: I18n("vendors"),
        path: PATH.VENDOR_CONTACT.LIST,
        id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.VENDORS.LIST,
        children: [
          {
            path: "",
            element: <VendorContactList />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.VENDORS.LIST
          },
          {
            path: PATH.VENDOR_CONTACT.CREATE,
            element: <VendorContact />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.VENDORS.ACTIONS.ADD
          },
          {
            path: PATH.VENDOR_CONTACT.EDIT,
            element: <VendorContact />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.VENDORS.ACTIONS.EDIT
          },
          {
            path: PATH.VENDOR_CONTACT.DETAILS,
            element: <VendorContact />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.VENDORS.ACTIONS.VIEW
          }
        ]
      },
      {
        title: I18n("sho_ngo"),
        path: PATH.SHO_NGO.DETAILS,
        id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.SHO_NGO.LIST,
        children: [
          {
            path: "",
            element: <ShoAndNgoList />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.SHO_NGO.LIST

          },
          {
            path: ":id/edit",
            element: <ShoAndNgoAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.SHO_NGO.ACTIONS.EDIT
          },
          {
            path: ":id/view",
            element: <ShoAndNgoAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.SHO_NGO.ACTIONS.VIEW
          },
          {
            path: "create",
            element: <ShoAndNgoAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.SHO_NGO.ACTIONS.ADD
          }
        ]
      },
      {
        title: I18n("training_organization"),
        path: PATH.TRAINING_ORGANIZATION.DETAILS,
        id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.TRAINING_ORGANIZATION.LIST,
        children: [
          {
            path: "",
            element: <TrainingOrganizationList />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.TRAINING_ORGANIZATION.LIST
          },
          {
            path: ":id/edit",
            element: <TrainingOrganizationAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.TRAINING_ORGANIZATION.ACTIONS.EDIT
          },
          {
            path: ":id/view",
            element: <TrainingOrganizationAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.TRAINING_ORGANIZATION.ACTIONS.VIEW
          },
          {
            path: "create",
            element: <TrainingOrganizationAdd />,
            id: RESOURCE_ID.ADMIN.EXTERNAL_ORGANIZATION.TRAINING_ORGANIZATION.ACTIONS.ADD
          }
        ]
      }
    ]
  },
  {
    title: I18n("miscellaneous"),
    id: RESOURCE_ID.ADMIN.MISCELLANEOUS.ROUTE,
    children: [
      {
        title: I18n("vendor_category"),
        path: PATH.VENDOR_CATEGORY.LIST,
        id: RESOURCE_ID.ADMIN.MISCELLANEOUS.VENDOR_CATEGORY.LIST,
        children: [
          {
            path: "",
            element: <VendorCategoryList />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.VENDOR_CATEGORY.LIST
          },
          {
            path: PATH.VENDOR_CATEGORY.CREATE,
            element: <VendorCategory />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.VENDOR_CATEGORY.ACTIONS.ADD
          },
          {
            path: PATH.VENDOR_CATEGORY.EDIT,
            element: <VendorCategory />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.VENDOR_CATEGORY.ACTIONS.EDIT
          },
          {
            path: PATH.VENDOR_CATEGORY.DETAILS,
            element: <VendorCategory />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.VENDOR_CATEGORY.ACTIONS.VIEW
          }
        ]
      },

      {
        title: I18n("designations"),
        path: PATH.DESIGNATION_ENTRY.LIST,
        id: RESOURCE_ID.ADMIN.MISCELLANEOUS.DESIGNATION.LIST,
        children: [
          {
            path: "",
            element: <DesignationList />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.DESIGNATION.LIST
          },
          {
            path: `${PATH.DESIGNATION_ENTRY.CREATE}`,
            element: <DesignationForm />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.DESIGNATION.ACTIONS.ADD
          },
          {
            path: `${PATH.DESIGNATION_ENTRY.EDIT}`,
            element: <DesignationForm />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.DESIGNATION.ACTIONS.EDIT
          },
          {
            path: `${PATH.DESIGNATION_ENTRY.DETAILS}`,
            element: <DesignationForm />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.DESIGNATION.ACTIONS.VIEW
          }
        ]
      },
      {
        title: I18n("complaint_status"),
        path: PATH.COMPLAINT.STATUS,
        id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_STATUS.LIST,
        children: [{
          path: "",
          element: <ListComplaintStatus />,
          id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_STATUS.LIST
        },
        {
          path: ":id/edit",
          element: <ComplaintStatus />,
          id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_STATUS.ACTIONS.EDIT
        },
        {
          path: "create",
          element: <ComplaintStatus />,
          id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_STATUS.ACTIONS.ADD
        },
        {
          path: ":id/view",
          element: <ComplaintStatus />,
          id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_STATUS.ACTIONS.VIEW
        }

        ]
      },
      {
        title: I18n("complaint_category"),
        path: PATH.COMPLAINT.CATEGORY,
        id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_CATEGORY.LIST,
        children: [
          {
            path: "",
            element: <ListComplaintCategory />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_CATEGORY.LIST
          },
          {
            path: ":id/edit",
            element: <ComplaintCategory />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_CATEGORY.ACTIONS.EDIT
          },
          {
            path: "create",
            element: <ComplaintCategory />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_CATEGORY.ACTIONS.ADD
          },
          {
            path: ":id/view",
            element: <ComplaintCategory />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_CATEGORY.ACTIONS.VIEW
          }
        ]
      },
      {
        path: `${PATH.COMPLAINT.TYPE}/:id/escalation`,
        element: <ComplaintEscalation />
      },
      {
        title: I18n("complaint_type"),
        path: PATH.COMPLAINT.TYPE,
        id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_TYPE.LIST,
        children: [
          {
            path: "",
            element: <ListComplaintType />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_TYPE.LIST
          },
          {
            path: ":id/edit",
            element: <ComplaintType />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_TYPE.ACTIONS.EDIT
          },
          {
            path: "create",
            element: <ComplaintType />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_TYPE.ACTIONS.ADD
          },
          {
            path: ":id/view",
            element: <ComplaintType />,
            id: RESOURCE_ID.ADMIN.MISCELLANEOUS.COMPLAINT_TYPE.ACTIONS.VIEW
          }
        ]
      }
    ]
  }

];
export { routes };

