import { createSlice } from "@reduxjs/toolkit";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const initialState = {
    approvalBySpmc: {
        requestInProgress: false,
        data: {
            spmcSocialExpertDecision: null,
            spmcEnvExpertDecision: null,
            spmcFinancialExpertDecision: null,
            spmcTechnicalExpertDecision: null,
            spmcSocialExpertRemarks: "",
            spmcEnvExpertRemarks: "",
            spmcFinancialExpertRemarks: "",
            spmcTechnicalExpertRemarks: ""
        }
    },
    previousBySpmc: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.approvalBySpmc = initialState.approvalBySpmc;
        },
        clearPrevious: (state) => {
            state.previousBySpmc = initialState.previousBySpmc;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.APPROVE_SPMC_BY_ID_REQUEST, (state) => {
                state.approvalBySpmc.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.APPROVE_SPMC_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "approvalBySpmc.requestInProgress", false);
                _.set(state, "approvalBySpmc.data", payload?.content?.length > 0 ? payload?.content[0] : state.approvalBySpmc.data);
            })
            .addCase(ACTION_TYPES.APPROVE_SPMC_BY_ID_FAILURE, (state) => {
                state.approvalBySpmc.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_SPMC_REQUEST, (state) => {
                state.previousBySpmc.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_SPMC_SUCCESS, (state, { payload }) => {
                _.set(state, "previousBySpmc.requestInProgress", false);
                _.set(state, "previousBySpmc.data", payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_PREVIOUS_SPMC_FAILURE, (state) => {
                state.previousBySpmc.requestInProgress = false;
            });
    }
});

export const { actions, reducer } = slice;
