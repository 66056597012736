import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Components, I18n, FormController } from "common/components";
import { Form, withFormik } from "formik";
import { getFinancialYearDropdown } from "modules/subProjects/swmSubProjects/actions";
import { getFinancialYearDropDown } from "modules/subProjects/swmSubProjects/selector";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { downloadGur1Report, fetchGurReport1, quarterDropDownApi } from "../actions";
import { getGur1Report, getGur1ReportRequestInProgress, getGur1TotalTotal, getQuarterDropdown } from "../selectors";
import { actions as sliceActions } from "../slice";
import LoadingCustomOverlay from "modules/common/components/LoadingOverlay";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { PictureAsPdf } from "@mui/icons-material";

const { Grid, Card, Box, IconButton, Typography } = Components;

const useStyles = makeStyles(() => ({
    tableContainer: {
        overflowX: "auto",
        maxWidth: "100%"
    },
    table: {
        minWidth: 600,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            fontSize: "18px"
        }
    }
}));

const ReportGUR1StateLevel = (props) => {
    let { values, gurReportData, financialYear, quarterDropDown, requestInProgress= true, setFieldValue, gur1Report } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [newPage, setNewPage] = useState(1);

    let gur1ReportUpdated = [...gur1Report];
    let total = gur1ReportUpdated?.pop(-1);

    useEffect(() => {
        dispatch(getFinancialYearDropdown());
        return () => {
            dispatch(sliceActions.clearQuarter());
            dispatch(sliceActions.clearGur1());
        };
    }, []);

    const handleQuarterDropDown = (e) => {
        setFieldValue("quarter", "");
        dispatch(sliceActions.clearGur1());
        dispatch(quarterDropDownApi(e));
    };

    const handleQuarterValue = (e) => {
        dispatch(fetchGurReport1({ pageSize: 10, pageNo: 0, financialYear: values?.financialYear?.name, quarter: e?.name }));
        setNewPage(1);
    };

    const pagination = (event, value) => {
        setNewPage(value);
        dispatch(fetchGurReport1({ pageSize: 10, pageNo: value - 1, financialYear: values?.financialYear?.name, quarter: values?.quarter?.name }));
    };

    let customActions = [];

    customActions.push({ title: I18n("download_pdf"), icon: <PictureAsPdf fontSize="small"/>, handleClick: () => dispatch(downloadGur1Report({financialYear: values?.financialYear?.name, quarter: values?.quarter?.name, type: "pdf"})) });

    return (
        <>
           <Card sx={{ m: 2, boxShadow: "none", border: "1px solid #CFE7DE", overflow: "visible" }}>
                <Grid container >
                    <Grid item xs={12} md={12} sm={12} sx={{ p: 3 }}>
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <FormController control='select' name="financialYear" options={financialYear || []} label={I18n("financial_year")} onChangeFromController={(e) => handleQuarterDropDown(e)} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <FormController control="select" name="quarter" options={quarterDropDown || []} label={I18n("quarter")} onChangeFromController={(e) => handleQuarterValue(e)} />
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>
                </Grid>
            </Card>
            <Box sx={{ maxHeight: "100%", maxWidth: "100%"}}>
                <LoadingCustomOverlay active={requestInProgress}>
                <Grid item lg={12} md={12} sm={12} sx={{display: "flex", justifyContent: "end", ml: 1}}>
                    <IconButton>
                        <CustomListMenu customActions={customActions} type="download"/>
                    </IconButton>
                </Grid>
                <Grid className={classes.tableContainer} >
                    <TableContainer sx={{maxHeight: {lg: "450px", xl: "600px"}}} >
                        {gurReportData?.content?.length > 0 ?
                        <Table sx={{ p: 1, minWidth: "300px", textAlign: "left" }} size="small" aria-label="a dense table" className={classes.table}>
                            <TableHead sx={{ fontSize: "18px", fontFamily: "Clash Display" }}>
                                <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                    <TableCell rowSpan={2} sx={{ minWidth: "4em", color: "#FFFF" }} >{I18n("sl_no")}</TableCell>
                                    <TableCell rowSpan={2} sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("district")}</TableCell>
                                    <TableCell colSpan={3} sx={{ maxWidth: "50%", color: "#FFFF" }}>{I18n("grants_recieved")}</TableCell>
                                    <TableCell colSpan={3} sx={{ maxWidth: "50%", color: "#FFFF" }}>{I18n("grants_spent")}</TableCell>
                                    <TableCell colSpan={3} sx={{ maxWidth: "50%", color: "#FFFF" }}>{I18n("grants_unspent")}</TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: "#008FBE" }}>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("during_the_reporting_quater")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("year_to_date")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("cumilative_to_date")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("during_the_reporting_quater")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("year_to_date")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("cumilative_to_date")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("during_the_reporting_quater")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("year_to_date")}</TableCell>
                                    <TableCell sx={{ minWidth: "10em", color: "#FFFF" }}>{I18n("cumilative_to_date")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ maxHeight: "20em", overflow: scroll }}>
                                {gur1ReportUpdated?.map((data, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell sx={{ minWidth: "1em" }}>{data?.slNo}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.districtName ?? "----"}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.grantsRecvdreportingQuarter}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.grantsRecvdYearToDateSum}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.grantsRecvdCumulativeSum}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.grantsSpentreportingQuarter}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.grantsSpentYearToDateSum}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.grantsSpentCumulativeSum}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.grantsUnSpentreportingQuarter}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.grantsUnspentYearToDateSum}</TableCell>
                                            <TableCell sx={{ minWidth: "10em" }}>{data?.grantsUnspentCumulativeSum}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {total &&
                                    <TableRow>
                                        <TableCell colSpan={2} sx={{fontWeight: 600}}>{I18n("total")}</TableCell>
                                        <TableCell sx={{ minWidth: "10em", fontWeight: 600 }}>{total?.grantsReceievedRepQuarterTotal ?? "---"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em", fontWeight: 600 }}>{total?.grantsReceievedYearToDateTotal ?? "---"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em", fontWeight: 600 }}>{total?.grantsReceievedCumulativeTotal ?? "---"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em", fontWeight: 600 }}>{total?.grantsSpentRepQuarterTotal ?? "---"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em", fontWeight: 600 }}>{total?.grantsSpentYearToDateTotal ?? "---"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em", fontWeight: 600 }}>{total?.grantsSpentCumulativeTotal ?? "---"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em", fontWeight: 600 }}>{total?.grantsUnSpentRepQuarterTotal ?? "---"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em", fontWeight: 600 }}>{total?.grantsUnSpentYearToDateTotal ?? "---"}</TableCell>
                                        <TableCell sx={{ minWidth: "10em", fontWeight: 600 }}>{total?.grantsUnSpentCumulativeTotal ?? "---"}</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>:
                            <Grid xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "20px", marginBottom: "20px", border: "solid #CFE7DE 1px", padding: 3, borderRadius: "12px"}}>
                                <Typography sx={{ fontSize: "14px", fontStyle: "italic", color: "#6B6B6B" }}>{I18n("no_records")}</Typography>
                            </Grid>}
                    </TableContainer>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "10px"}}>
                    <Pagination count={gurReportData?.totalPages} color="primary" page={newPage} onChange={pagination} />
                </Grid>
                </LoadingCustomOverlay>
            </Box>

        </>
    );
};

const mapStateToProps = createStructuredSelector({
    financialYear: getFinancialYearDropDown,
    quarterDropDown: getQuarterDropdown,
    gurReportData: getGur1Report,
    requestInProgress: getGur1ReportRequestInProgress,
    gur1Report: getGur1TotalTotal

});

const ReportGUR1FormStateLevel = withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => {
        return {
            financialYear: "",
            quarter: ""
        };
    },
    handleSubmit: (values, { props }) => {
        return props.submitValues(values);
    },
    displayName: "Report GUR 1 State Level"
})(ReportGUR1StateLevel);

export default connect(mapStateToProps, null)(ReportGUR1FormStateLevel);
