import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchModuleIdApi = () => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.FETCH_MODULE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_MODULE_ID_REQUEST, ACTION_TYPES.FETCH_MODULE_ID_SUCCESS, ACTION_TYPES.FETCH_MODULE_ID_FAILURE],
            data: { dropDown: true }
        }
    };
};
export const fetchParentApi = ({ isParent, moduleId }) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.FETCH_PARENT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PARENT_ID_REQUEST, ACTION_TYPES.FETCH_PARENT_ID_SUCCESS, ACTION_TYPES.FETCH_PARENT_ID_FAILURE],
            data: { isParent, moduleId }
        }
    };
};
export const listResourceManagementApi = (data) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.LIST_RESOURCE_MANAGEMENT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_RESOURCE_MANAGEMENT_REQUEST, ACTION_TYPES.LIST_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.LIST_RESOURCE_MANAGEMENT_FAILURE],
            data: data
        }
    };
};
export const updateResourceManagementApi = (data) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.UPDATE_RESOURCE_MANAGEMENT,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_REQUEST, ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_FAILURE],
            data
        }
    };
};
export const submitResourceManagementApi = (data) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.SUBMIT_RESOURCE_MANAGEMENT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_RESOURCE_MANAGEMENT_REQUEST, ACTION_TYPES.SUBMIT_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.SUBMIT_RESOURCE_MANAGEMENT_FAILURE],
            data
        }
    };
};
export const deleteResourceManagementApi = ({ rid, mid }) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.DELETE_RESOURCE_MANAGEMENT,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_REQUEST, ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_FAILURE],
            data: { rid, mid }
        }
    };
};
export const resourceManagementGetByIdApi = (id) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.RESOURCE_MANAGEMENT_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.RESOURCE_MANAGEMENT_CONTACTS_BY_ID_REQUEST, ACTION_TYPES.RESOURCE_MANAGEMENT_CONTACTS_BY_ID_SUCCESS, ACTION_TYPES.RESOURCE_MANAGEMENT_CONTACTS_BY_ID_FAILURE],
            data: { id }
        }
    };
};
export const filterMenuName = (data) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.FILTER_NAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FILTER_MENU_NAME_REQUEST, ACTION_TYPES.FILTER_MENU_NAME_SUCCESS, ACTION_TYPES.FILTER_MENU_NAME_FAILURE],
            data: data
        }
    };
};
