import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";

const initialState = {
    wssInfoById: {
        requestInProgress: false,
        data: {
            id: "",
            districtId: "",
            lsgiId: "",
            nameOfWard: "",
            procuredSegregation: "",
            distributedSegregation: "",
            houseHold: "",
            collectionFrequency: "",
            date: null
        }
    },
    wardNameDropdown: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        setWssInfoById: (state, action) => {
            state.wssInfoById.data = action.payload;
        },
        clearWssInfoById: (state) => {
            state.wssInfoById.data = initialState.wssInfoById.data;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_WSS_INFO_BY_ID_REQUEST, (state) => {
                state.wssInfoById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_WSS_INFO_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "wssInfoById.requestInProgress", false);
                _.set(state, "wssInfoById.data", payload);
            })
            .addCase(ACTION_TYPES.FETCH_WSS_INFO_BY_ID_FAILURE, (state) => {
                state.wssInfoById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_WARD_NAME_DROPDOWN_SUCCESS, (state, { payload }) => {
                _.set(state, "wardNameDropdown.requestInProgress", false);
                _.set(state, "wardNameDropdown.data", payload?.content);
            });
    }

});

export const { actions, reducer } = slice;
