import Button from "./custom/Button";
import FormController from "./custom/FormController";
import ReactNotifications from "./custom/ReactNotifications";
import RootBoundary from "./custom/RootBoundary";
import Loader from "./custom/Loader";
import CustomCardHeader from "./custom/CustomCardHeader";
import CardWithHeader from "./custom/CardWithHeader";

import { Icons } from "./material/Icons";
import { DefaultComponents } from "./material/Components";
import i18n from "i18n";

const I18n = i18n.t;
const Components = {
    ...DefaultComponents,
    Button,
    Loader,
    CustomCardHeader,
    CardWithHeader
};

export { Components, I18n, RootBoundary, ReactNotifications, FormController, Icons };
