import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import { getPayloadContent } from "utils/apiUtils";
import _ from "lodash";

const initialState = {
  subProjectAddDetails: {
    requestInProgress: false,
    data: {
      projectCode: "",
      projectName: "",
      startDate: "",
      endDate: "",
      trackId: "",
      conceptNoteAvailable: "",
      estimateAmount: "",
      feasibilityStudyDone: "",
      categoryId: "",
      subCategoryId: "",
      subCategoryId2: "",
      typeId: "",
      envCategoryId: "",
      attachmentPayload: {
        multimediaList: []
      }
    }
  },
  trackList: {
    requestInProgress: false,
    data: []
  },
  categoryList: {
    requestInProgress: false,
    data: []
  },
  subCategoryList: {
    requestInProgress: false,
    data: []
  },
  subEnvCategoryList: {
    requestInProgress: false,
    data: []
  },
  subCategoryTwoList: {
    requestInProgress: false,
    data: []
  },
  subProjectList: {
    requestInProgress: false,
    data: []
  },
  projectConceptNote: {
    requestInProgress: false,
    data: []
  },
  subProjectMainList: {
    requestInProgress: false,
    data: []
  }
};

const slice = createSlice({
  initialState,
  name: STATE_REDUCER_KEY,
  reducers: {
    clearAll: () => initialState,
    clear: (state) => {
      state.subProjectAddDetails = initialState.subProjectAddDetails;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ACTION_TYPES.FETCH_TRACK_LIST_REQUEST, (state) => {
        state.trackList.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.FETCH_TRACK_LIST_SUCCESS, (state, action) => {
        _.set(state, "trackList.requestInProgress", false);
        _.set(state, "trackList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_TRACK_LIST_FAILURE, (state) => {
        state.trackList.requestInProgress = false;
      })
      .addCase(ACTION_TYPES.FETCH_CATEGORY_TYPES_REQUEST, (state) => {
        state.categoryList.requestInProgress = true;
      })
      .addCase(ACTION_TYPES.FETCH_CATEGORY_TYPES_SUCCESS, (state, action) => {
        _.set(state, "categoryList.requestInProgress", false);
        _.set(state, "categoryList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_CATEGORY_TYPES_FAILURE, (state) => {
        state.categoryList.requestInProgress = false;
      })
      .addCase(ACTION_TYPES.FETCH_ENV_CATEGORY_TYPES_SUCCESS, (state, action) => {
        _.set(state, "subEnvCategoryList.requestInProgress", false);
        _.set(state, "subEnvCategoryList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_SUB_CATEGORY_TYPES_SUCCESS, (state, action) => {
        _.set(state, "subCategoryList.requestInProgress", false);
        _.set(state, "subCategoryList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_SUB_CATEGORY_TWO_TYPES_SUCCESS, (state, action) => {
        _.set(state, "subCategoryTwoList.requestInProgress", false);
        _.set(state, "subCategoryTwoList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_PROJECT_TYPES_SUCCESS, (state, action) => {
        _.set(state, "subProjectList.requestInProgress", false);
        _.set(state, "subProjectList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_PROJECT_APPLICABLE_SUCCESS, (state, action) => {
        _.set(state, "projectConceptNote.requestInProgress", false);
        _.set(state, "projectConceptNote.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_SUB_PROJECT_LIST_SUCCESS, (state, action) => {
        _.set(state, "subProjectMainList.requestInProgress", false);
        _.set(state, "subProjectMainList.data", getPayloadContent(action));
      })
      .addCase(ACTION_TYPES.FETCH_SUB_PROJECT_LIST_BY_ID_SUCCESS, (state, { payload }) => {
        payload.content.map((name) => {
          _.set(state, "subProjectAddDetails.data.projectCode", name?.projectCode);
          _.set(state, "subProjectAddDetails.data.projectName", name?.projectName);
          _.set(state, "subProjectAddDetails.data.startDate", name?.startDate);
          _.set(state, "subProjectAddDetails.data.endDate", name?.endDate);
          _.set(state, "subProjectAddDetails.data.trackId", name?.trackId);
          _.set(state, "subProjectAddDetails.data.conceptNoteAvailable", name?.conceptNoteAvailable);
          _.set(state, "subProjectAddDetails.data.estimateAmount", name?.estimateAmount);
          _.set(state, "subProjectAddDetails.data.feasibilityStudyDone", name?.feasibilityStudyDone);
          _.set(state, "subProjectAddDetails.data.categoryId", name?.categoryId);
          _.set(state, "subProjectAddDetails.data.subCategoryId", name?.subCategoryId);
          _.set(state, "subProjectAddDetails.data.subCategoryId2", name?.subCategoryId2);
          _.set(state, "subProjectAddDetails.data.typeId", name?.typeId);
          _.set(state, "subProjectAddDetails.data.envCategoryId", name?.envCategoryId);
          if (payload.attachmentPayload) {
            _.set(state, "attachmentImages", payload.attachmentPayload?.multimediaList);
          }
          _.set(state, "subProjectAddDetails.requestInProgress", false);
        });
      }
      );
  }
});

export const { actions, reducer } = slice;
