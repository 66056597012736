import { Form, withFormik } from "formik";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Components, FormController, I18n } from "../../../../common/components";
import { checkUser } from "../actions";
import { getUserDetails, getUserTypes } from "../selectors";
import { UserDetailsSchema } from "../validate";
import { actions as sliceActions } from "../slice";
const { Button } = Components;

export const CheckboxDetails = (props) => {
    const { handleSubmit, userDetails: { data: { checkValue = true } = {} } = {} } = props;
    const dispatch = useDispatch();

    return (
        <Form onSubmit={handleSubmit}>
            <FormController control="checkbox" checked={checkValue} label={I18n("user_name")} name="checkValue" onClick={() => dispatch(sliceActions.setCheckValue(checkValue))} />
            <Button type="submit"> {I18n("submit")}</Button>
        </Form >
    );
};

const mapStateToProps = createStructuredSelector({
    userDetails: getUserDetails,
    userTypes: getUserTypes
});

const mapDispatchToProps = (dispatch) => ({
    submit: (data) => dispatch(checkUser(data))
});

const CheckboxForm = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => {
        return props.userDetails;
    },
    validationSchema: UserDetailsSchema,
    handleSubmit: (values, { props: { submit } }) => {
        submit(values);
    },
    displayName: "User"
})(CheckboxDetails);

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxForm);

