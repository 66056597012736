import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    SUBMIT_DESIGNATION_ENTRY: `${STATE_REDUCER_KEY}/SUBMIT_DESIGNATION_ENTRY`,
    SUBMIT_DESIGNATION_ENTRY_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_DESIGNATION_ENTRY_REQUEST`,
    SUBMIT_DESIGNATION_ENTRY_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_DESIGNATION_ENTRY_SUCCESS`,
    SUBMIT_DESIGNATION_ENTRY_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_DESIGNATION_ENTRY_FAILURE`,

    UPDATE_DESIGNATION_ENTRY: `${STATE_REDUCER_KEY}/UPDATE_DESIGNATION_ENTRY`,
    UPDATE_DESIGNATION_ENTRY_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_DESIGNATION_ENTRY_REQUEST`,
    UPDATE_DESIGNATION_ENTRY_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_DESIGNATION_ENTRY_SUCCESS`,
    UPDATE_DESIGNATION_ENTRY_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_DESIGNATION_ENTRY_FAILURE`,

    LIST_DESIGNATION_ENTRY: `${STATE_REDUCER_KEY}/LIST_DESIGNATION_ENTRY`,
    LIST_DESIGNATION_ENTRY_REQUEST: `${STATE_REDUCER_KEY}/LIST_DESIGNATION_ENTRY_REQUEST`,
    LIST_DESIGNATION_ENTRY_SUCCESS: `${STATE_REDUCER_KEY}/LIST_DESIGNATION_ENTRY_SUCCESS`,
    LIST_DESIGNATION_ENTRY_FAILURE: `${STATE_REDUCER_KEY}/LIST_DESIGNATION_ENTRY_FAILURE`,

    DELETE_DESIGNATION_ENTRY: `${STATE_REDUCER_KEY}/DELETE_DESIGNATION_ENTRY`,
    DELETE_DESIGNATION_ENTRY_REQUEST: `${STATE_REDUCER_KEY}/DELETE_DESIGNATION_ENTRY_REQUEST`,
    DELETE_DESIGNATION_ENTRY_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_DESIGNATION_ENTRY_SUCCESS`,
    DELETE_DESIGNATION_ENTRY_FAILURE: `${STATE_REDUCER_KEY}/DELETE_DESIGNATION_ENTRY_FAILURE`,

    DESIGNATION_ENTRY_BY_ID: `${STATE_REDUCER_KEY}/DESIGNATION_ENTRY_BY_ID`,
    DESIGNATION_ENTRY_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/DESIGNATION_ENTRY_BY_ID_REQUEST`,
    DESIGNATION_ENTRY_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/DESIGNATION_ENTRY_BY_ID_SUCCESS`,
    DESIGNATION_ENTRY_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/DESIGNATION_ENTRY_BY_ID_FAILURE`,

    FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
    FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
    FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
    FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,

    DOWNLOAD_DESIGNATION_LIST: `${STATE_REDUCER_KEY}/DOWNLOAD_DESIGNATION_LIST`,
    DOWNLOAD_DESIGNATION_LIST_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_DESIGNATION_LIST_REQUEST`,
    DOWNLOAD_DESIGNATION_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_DESIGNATION_LIST_SUCCESS`,
    DOWNLOAD_DESIGNATION_LIST_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_DESIGNATION_LIST_FAILURE`
};

export const updateDesignationEntry = createAction(ACTION_TYPES.UPDATE_DESIGNATION_ENTRY);
export const postDesignationEntry = createAction(ACTION_TYPES.SUBMIT_DESIGNATION_ENTRY);
export const deleteDesignationEntry = createAction(ACTION_TYPES.DELETE_DESIGNATION_ENTRY);
export const designationEntryById = createAction(ACTION_TYPES.DESIGNATION_ENTRY_BY_ID);
export const listDesignationEntry = createAction(ACTION_TYPES.LIST_DESIGNATION_ENTRY);
export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);
export const downLoadDesignation = createAction(ACTION_TYPES.DOWNLOAD_DESIGNATION_LIST);

