import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { ACTION_TYPES } from "./actions";
import { COMMON_TABLE_PAGINATION, STATE_REDUCER_KEY } from "./constants";
const ADDITIONAL_PROPS = { rowsPerPage: 25 };
const initialState = {
    userDetails: {
        requestInProgress: false,
        data: {
            userName: "",
            CheckValue: true,
            male: true,
            radioOptions: { id: "option1", name: "value1" },
            radioOptions2: 1
        }
    },
    userType: {
        requestInProgress: false,
        data: []
    },
    tableFilter: {},
    searchKey: {},
    tablePagination: {
        ...COMMON_TABLE_PAGINATION
    },
    table: {
        requestInProgress: false,
        data: [],
        requestStatus: "",
        rowSelection: [],
        rowSelectionState: {},
        tableProps: ADDITIONAL_PROPS
    },
    exampleTable: {
        requestInProgress: false,
        data: [],
        requestStatus: "",
        rowSelection: [],
        rowSelectionState: {},
        tableProps: ADDITIONAL_PROPS
    },
    radioOptions: [
        { id: "option1", name: "value1" },
        { id: "option2", name: "value2" },
        { id: "option3", name: "value3" }
    ]


};


const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.table = initialState.table;
            state.exampleTable = initialState.exampleTable;
        },
        setLoading: (state) => {
            _.set(state, "table.requestInProgress", true);
            _.set(state, "exampleTable.requestInProgress", true);
        },
        setPagination: (state, action) => {
            _.set(state, "tablePagination", action.payload);
        },
        setRowSelection: (state, action) => {
            _.set(state, "table.rowSelection", action.payload, []);
        },
        setRowSelectionState: (state, action) => {
            _.set(state, "table.rowSelectionState", action.payload, {});
        },
        setRowSelectionV2: (state, action) => {
            _.set(state, "exampleTable.rowSelection", action.payload, []);
        },
        setRowSelectionStateV2: (state, action) => {
            _.set(state, "exampleTable.rowSelectionState", action.payload, {});
        },
        setSampleFormData: (state, action) => {
            _.set(state, "sampleForm.data.userName", action.payload);
        },
        setCheckValue: (state, action) => {
            state.userDetails.data.CheckValue = !action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.FETCH_USER_DETAILS_REQUEST, (state) => {
            _.set(state, "table.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.FETCH_USER_DETAILS_SUCCESS, (state) => {
            _.set(state, "table.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.SET_USERS, (state, action) => {
            _.set(state, "table.requestInProgress", false);
            _.set(state, "table.data", action.payload);
            _.set(state, "pageCount", action.payload.length, 0);
        });
        builder.addCase(ACTION_TYPES.SET_USERS_V2, (state, action) => {
            _.set(state, "exampleTable.requestInProgress", false);
            _.set(state, "exampleTable.data", action.payload);
            _.set(state, "pageCount", action.payload.length, 0);
        });
    }
});

export const { actions, reducer } = slice;
