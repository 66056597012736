import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const createUser = (data) => {
    return {
        url: "",
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_USER_DETAILS_REQUEST, ACTION_TYPES.POST_USER_DETAILS_SUCCESS, ACTION_TYPES.POST_USER_DETAILS_FAILURE], data
        }

    };
};
export const getUser = (data) => {
    return {
        url: "",
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_USER_DETAILS_REQUEST, ACTION_TYPES.GET_USER_DETAILS_SUCCESS, ACTION_TYPES.GET_USER_DETAILS_FAILURE], data
        }

    };
};
export const getAuthDetails = (
    { targetNo, otpType, otpProcess }) => {
    return {
        url: API_URL.USER_MANAGEMENT.GET_OTP.replace(":otp_type", otpType).replace(":otp_process", otpProcess),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.POST_AUTHENTICATION_DETAILS_REQUEST, ACTION_TYPES.POST_AUTHENTICATION_DETAILS_SUCCESS, ACTION_TYPES.POST_AUTHENTICATION_DETAILS_FAILURE],
            data: { targetNo }
        }

    };
};
export const postOtp = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.POST_OTP,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_OTP_REQUEST, ACTION_TYPES.POST_OTP_SUCCESS, ACTION_TYPES.POST_OTP_FAILURE],
            data
        }
    };
};
export const postResetPassword = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.RESET_PASSWORD,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_RESET_PASSWORD_REQUEST, ACTION_TYPES.POST_RESET_PASSWORD_SUCCESS, ACTION_TYPES.POST_RESET_PASSWORD_FAILURE], data
        }
    };
};

export const authenticateUser = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.AUTH_TOKEN,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.AUTHENTICATE_USER_REQUEST, ACTION_TYPES.AUTHENTICATE_USER_SUCCESS, ACTION_TYPES.AUTHENTICATE_USER_FAILURE],
            // eslint-disable-next-line camelcase
            data: { ...data, grant_type: "password", client_id: "web-app" }
        }
    };
};


export const getUserProfile = (data = {}) => {
    return {
        url: API_URL.USER_MANAGEMENT.USER_PROFILE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.FETCH_USER_PROFILE_REQUEST, ACTION_TYPES.FETCH_USER_PROFILE_SUCCESS, ACTION_TYPES.FETCH_USER_PROFILE_FAILURE],
            data
        }
    };
};
export const getOtp = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.GET_OTP,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_OTP_REQUEST, ACTION_TYPES.GET_OTP_SUCCESS, ACTION_TYPES.GET_OTP_FAILURE], data
        }

    };
};
