import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

  FETCH_SUB_PROJECT_LIST: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST`,
  FETCH_SUB_PROJECT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST_REQUEST`,
  FETCH_SUB_PROJECT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST_SUCCESS`,
  FETCH_SUB_PROJECT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST_FAILURE`,

  FETCH_NATURE_OF_WORK_LIST: `${STATE_REDUCER_KEY}/FETCH_NATURE_OF_WORK_LIST`,
  FETCH_NATURE_OF_WORK_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NATURE_OF_WORK_LIST_REQUEST`,
  FETCH_NATURE_OF_WORK_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NATURE_OF_WORK_LIST_SUCCESS`,
  FETCH_NATURE_OF_WORK_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NATURE_OF_WORK_LIST_FAILURE`,

  FETCH_SUB_PROJECT_LIST_BY_ID: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST_BY_ID_`,
  FETCH_SUB_PROJECT_LIST_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST_BY_ID_REQUEST`,
  FETCH_SUB_PROJECT_LIST_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST_BY_ID_SUCCESS`,
  FETCH_SUB_PROJECT_LIST_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SUB_PROJECT_LIST_BY_ID_FAILURE`,

  FETCH_TRACK_LIST: `${STATE_REDUCER_KEY}/FETCH_TRACK_LIST`,
  FETCH_TRACK_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TRACK_LIST_REQUEST`,
  FETCH_TRACK_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TRACK_LIST_SUCCESS`,
  FETCH_TRACK_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TRACK_LIST_FAILURE`,

  FETCH_CATEGORY_TYPES: `${STATE_REDUCER_KEY}/FETCH_CATEGORY_TYPES`,
  FETCH_CATEGORY_TYPES_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CATEGORY_TYPES_REQUEST`,
  FETCH_CATEGORY_TYPES_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CATEGORY_TYPES_SUCCESS`,
  FETCH_CATEGORY_TYPES_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CATEGORY_TYPES_FAILURE`,

  FETCH_ENV_CATEGORY_TYPES: `${STATE_REDUCER_KEY}/FETCH_ENV_CATEGORY_TYPES`,
  FETCH_ENV_CATEGORY_TYPES_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ENV_CATEGORY_TYPES_REQUEST`,
  FETCH_ENV_CATEGORY_TYPES_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ENV_CATEGORY_TYPES_SUCCESS`,
  FETCH_ENV_CATEGORY_TYPES_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ENV_CATEGORY_TYPES_FAILURE`,

  FETCH_SUB_CATEGORY_TYPES: `${STATE_REDUCER_KEY}/FETCH_SUB_CATEGORY_TYPES`,
  FETCH_SUB_CATEGORY_TYPES_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SUB_CATEGORY_TYPES_REQUEST`,
  FETCH_SUB_CATEGORY_TYPES_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SUB_CATEGORY_TYPES_SUCCESS`,
  FETCH_SUB_CATEGORY_TYPES_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SUB_CATEGORY_TYPES_FAILURE`,

  FETCH_SUB_CATEGORY_TWO_TYPES: `${STATE_REDUCER_KEY}/FETCH_SUB_CATEGORY_TWO_TYPES`,
  FETCH_SUB_CATEGORY_TWO_TYPES_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SUB_CATEGORY_TYPES_TWO_REQUEST`,
  FETCH_SUB_CATEGORY_TWO_TYPES_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SUB_CATEGORY_TYPES_TWO_SUCCESS`,
  FETCH_SUB_CATEGORY_TWO_TYPES_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SUB_CATEGORY_TYPES_TWO_FAILURE`,

  FETCH_PROJECT_TYPES: `${STATE_REDUCER_KEY}/FETCH_PROJECT_TYPES`,
  FETCH_PROJECT_TYPES_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_TYPES_REQUEST`,
  FETCH_PROJECT_TYPES_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROJECT_TYPES_SUCCESS`,
  FETCH_PROJECT_TYPES_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROJECT_TYPES_FAILURE`,

  FETCH_PROJECT_APPLICABLE: `${STATE_REDUCER_KEY}/FETCH_PROJECT_APPLICABLE`,
  FETCH_PROJECT_APPLICABLE_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_APPLICABLE_REQUEST`,
  FETCH_PROJECT_APPLICABLE_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROJECT_APPLICABLE_SUCCESS`,
  FETCH_PROJECT_APPLICABLE_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROJECT_APPLICABLE_FAILURE`,

  DELETE_FILE: `${STATE_REDUCER_KEY}/DELETE_FILE`,
  DELETE_FILE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_FILE_REQUEST`,
  DELETE_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_FILE_SUCCESS`,
  DELETE_FILE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_FILE_FAILURE`,

  POST_SUB_PROJECT: `${STATE_REDUCER_KEY}/POST_SUB_PROJECT`,
  POST_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/POST_SUB_PROJECT_REQUEST`,
  POST_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/POST_SUB_PROJECT_SUCCESS`,
  POST_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/POST_SUB_PROJECT_FAILURE`,

  POST_SWM_SUB_PROJECT: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT`,
  POST_SWM_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_REQUEST`,
  POST_SWM_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_SUCCESS`,
  POST_SWM_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/POST_SWM_SUB_PROJECT_FAILURE`,

  FETCH_SWM_SUB_PROJECT_BY_ID: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_BY_ID`,
  FETCH_SWM_SUB_PROJECT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_BY_ID_REQUEST`,
  FETCH_SWM_SUB_PROJECT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_BY_ID_SUCCESS`,
  FETCH_SWM_SUB_PROJECT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SWM_SUB_PROJECT_BY_ID_FAILURE`,

  LIST_SUB_PROJECT: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT`,
  LIST_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT_REQUEST`,
  LIST_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT_SUCCESS`,
  LIST_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/LIST_SUB_PROJECT_FAILURE`,

  UPDATE_SUB_PROJECT: `${STATE_REDUCER_KEY}/UPDATE_SUB_PROJECT`,
  UPDATE_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_SUB_PROJECT_REQUEST`,
  UPDATE_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_SUB_PROJECT_SUCCESS`,
  UPDATE_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_SUB_PROJECT_FAILURE`,

  UPDATE_SWM_SUB_PROJECT: `${STATE_REDUCER_KEY}/UPDATE_SWM_SUB_PROJECT`,
  UPDATE_SWM_SUB_PROJECT_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_SWM_SUB_PROJECT_REQUEST`,
  UPDATE_SWM_SUB_PROJECT_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_SWM_SUB_PROJECT_SUCCESS`,
  UPDATE_SWM_SUB_PROJECT_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_SWM_SUB_PROJECT_FAILURE`,

  DELETE_SUB_PROJECT_LIST: `${STATE_REDUCER_KEY}/DELETE_SUB_PROJECT_LIST`,
  DELETE_SUB_PROJECT_LIST_REQUEST: `${STATE_REDUCER_KEY}/DELETE_SUB_PROJECT_LIST_REQUEST`,
  DELETE_SUB_PROJECT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_SUB_PROJECT_LIST_SUCCESS`,
  DELETE_SUB_PROJECT_LIST_FAILURE: `${STATE_REDUCER_KEY}/DELETE_SUB_PROJECT_LIST_FAILURE`,

  FETCH_ANNUAL_PLAN: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN`,
  FETCH_ANNUAL_PLAN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN_REQUEST`,
  FETCH_ANNUAL_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN_SUCCESS`,
  FETCH_ANNUAL_PLAN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ANNUAL_PLAN_FAILURE`,

  FETCH_PROJECT_LIST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LIST`,
  FETCH_PROJECT_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LIST_REQUEST`,
  FETCH_PROJECT_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LIST_SUCCESS`,
  FETCH_PROJECT_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROJECT_LIST_FAILURE`

};

export const getSubProjectList = createAction(ACTION_TYPES.FETCH_SUB_PROJECT_LIST);
export const listSubProjectList = createAction(ACTION_TYPES.LIST_SUB_PROJECT);
export const getSubProjectListById = createAction(ACTION_TYPES.FETCH_SUB_PROJECT_LIST_BY_ID);
export const getTrackList = createAction(ACTION_TYPES.FETCH_TRACK_LIST);
export const getCategoryTypes = createAction(ACTION_TYPES.FETCH_CATEGORY_TYPES);
export const getSubCategoryTypes = createAction(ACTION_TYPES.FETCH_SUB_CATEGORY_TYPES);
export const getSubCategoryTwoTypes = createAction(ACTION_TYPES.FETCH_SUB_CATEGORY_TWO_TYPES);
export const getProjectTypes = createAction(ACTION_TYPES.FETCH_PROJECT_TYPES);
export const getProjectApplicable = createAction(ACTION_TYPES.FETCH_PROJECT_APPLICABLE);
export const getEnvCategoryTypes = createAction(ACTION_TYPES.FETCH_ENV_CATEGORY_TYPES);
export const deleteFile = createAction(ACTION_TYPES.DELETE_FILE);
export const saveSubProject = createAction(ACTION_TYPES.POST_SUB_PROJECT);
export const saveSwmSubProject = createAction(ACTION_TYPES.POST_SWM_SUB_PROJECT);
export const getSwmSubProjectById = createAction(ACTION_TYPES.FETCH_SWM_SUB_PROJECT_BY_ID);
export const updateSubProject = createAction(ACTION_TYPES.UPDATE_SUB_PROJECT);
export const updateSwmSubProject = createAction(ACTION_TYPES.UPDATE_SWM_SUB_PROJECT);
export const deleteSubProjectList = createAction(ACTION_TYPES.DELETE_SUB_PROJECT_LIST);
export const fetchAnnualPlanDropDown = createAction(ACTION_TYPES.FETCH_ANNUAL_PLAN);
export const getProjectList = createAction(ACTION_TYPES.FETCH_PROJECT_LIST);
export const getNatureOfWorkList = createAction(ACTION_TYPES.FETCH_NATURE_OF_WORK_LIST);
