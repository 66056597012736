import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { I18n } from "common/components";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import { fetchLsgiApi, listAllProfileOfLocalBody, listGapAnalysis, listGapAnalysisById, listProfileOfLocalBodyById, patchGapAnalysis, patchProfileOfLocalBody, postGapAnalysis, listWasteGeneratedApi, patchWasteGenerated, postWasteGenerated, wasteGeneratedByIdApi, listTransportationById, patchTransportation, listByIdWasteGeneratorsHouseHold, postWasteGeneratorsHouseHold, nonBioFacilitiesByIdApi, nonBioFacilitiesEquipmentByIdApi, updateNonBioFacilitiesEqupmentApi, listByIdWasteGeneratorsInstitution, postWasteGeneratorsInstitutions, listByIdForwardLinked, postForwardLinked, listByIdInvolvementOfHks, postInvolvementOfHks, updateBioFacilitiesApi, bioFacilitiesByIdApi, bioFacilitiesEquipmentByIdApi, updateBioFacilitiesEqupmentApi, listAllStreetSweepingAndDrains, postStreepSweepingAndDrains, patchStreepSweepingAndDrains, streetSweepingAndDrainsById, landDetailsByIdApi, updateLandDetailsApi, postStaffPayment, patchStaffPayment, staffPaymentListAll, staffPaymentById, otherNonBioFacilitiesById, patchOtherNonBioFacilities, listIncomeFromWasteApi, incomeFromWasteByIdApi, patchIncomeFromWaste, postIncomeFromWaste, WasteProcessedHouseHoldById, patchWasteProcessedHouseHold, wasteProcessedInstitutionsById, patchWasteProcessedInstitutions, bdwCommunityLevelListAll, postBDWCommunityLevel, bdwCommunityLevelById, patchBDWCommunityLevel } from "./api";
import { toEpoch } from "utils/dateUtils";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { TABLE_IDS } from "common/constants";
import { getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import * as api from "./api";
import { actions as commonActions } from "../../common/slice";
import { API_URL } from "../apiUrls";

const formatData = (payload) => {
    if (payload?.id) {
        const { id, recordedDate, version, districtId, lsgiId } = payload;
        const data = { id: id, version: version, recordedDate: toEpoch(recordedDate), lsgiId: lsgiId, districtId: districtId };
        return data;
    } else {
        const { recordedDate, version, districtId, lsgiId } = payload;
        const data = { version: version, recordedDate: toEpoch(recordedDate), lsgiId: lsgiId, districtId: districtId };
        return data;
    }
};

const formatDataForProfileOfLocalBody = (payload) => {
    if (payload.id) {
        const { id, agricultralArea, commercialArea, districtId, industrialArea, otherArea, lsgiId, residentialArea, wardCount, gapAnalysisId, stateId, version, lsgiTypeId, totalArea, vaccantLandArea } = payload;
        const data = { id: id, agricultralArea: Number(agricultralArea), commercialArea: commercialArea, districtId: districtId, industrialArea: industrialArea, otherArea: otherArea, lsgiId: lsgiId, residentialArea: residentialArea, vaccantLandArea: vaccantLandArea, wardCount: wardCount, gapAnalysisId: gapAnalysisId, stateId: stateId, version: version, lsgiTypeId: lsgiTypeId, totalArea: totalArea };
        return data;
    }
};

export function* submitGapAnalysisVersion(action) {
    let { payload } = action;
    if (payload.id) {
        yield fork(handleAPIRequest, patchGapAnalysis, formatData(payload));
        const responseAction = yield take([ACTION_TYPES.POST_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.POST_GAP_ANALYSIS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_GAP_ANALYSIS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("gap_analysis") }) }));
            yield put(navigateTo(-1));
        }
    } else {
        yield fork(handleAPIRequest, postGapAnalysis, formatData(payload));
        const responseAction = yield take([ACTION_TYPES.POST_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.POST_GAP_ANALYSIS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_GAP_ANALYSIS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("gap_analysis") }) }));
            yield put(navigateTo(-1));
        }
    }
}

export function* fetchGapAnalysisList(action) {
    yield fork(handleAPIRequest, listGapAnalysis, action.payload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.FETCH_GAP_ANALYSIS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.GAP_ANALYSIS_PROFILE_OF_LOCALBODY);
}

export function* fetchGapAnalysisListById({ payload: id }) {
    yield call(handleAPIRequest, listGapAnalysisById, id);
}

export function* submitWasteGenerated({ payload }) {
    yield fork(handleAPIRequest, postWasteGenerated, payload);
    const responseAction = yield take([ACTION_TYPES.SAVE_WASTE_GENERATED_SUCCESS, ACTION_TYPES.SAVE_WASTE_GENERATED_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_WASTE_GENERATED_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("waste_generated") }) }));
    }
}

export function* updateWasteGenerated({ payload }) {
    yield fork(handleAPIRequest, patchWasteGenerated, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_WASTE_GENERATED_SUCCESS, ACTION_TYPES.UPDATE_WASTE_GENERATED_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_WASTE_GENERATED_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("waste_generated") }) }));
    }
}

export function* listWasteGenerated(action) {
    yield fork(handleAPIRequest, listWasteGeneratedApi, action.payload);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_WASTE_GENERATED_SUCCESS, ACTION_TYPES.LIST_WASTE_GENERATED_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* wasteGeneratedById({ payload: id }) {
    yield call(handleAPIRequest, wasteGeneratedByIdApi, id);
}

export function* submitProfileOfLocalBody(action) {
    let { payload } = action;
    if (payload.id) {
        yield fork(handleAPIRequest, patchProfileOfLocalBody, formatDataForProfileOfLocalBody(payload));
        const responseAction = yield take([ACTION_TYPES.POST_PROFILE_OF_LOCAL_BODY_SUCCESS, ACTION_TYPES.POST_PROFILE_OF_LOCAL_BODY_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_PROFILE_OF_LOCAL_BODY_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("profile_of_localbody") }) }));
        }
    }
}

export function* fetchLsgiDropDown({ payload }) {
    yield call(handleAPIRequest, fetchLsgiApi, { lsgiTypeId: payload });
}

export function* listProfileOfLocalBody({ payload }) {
    yield call(handleAPIRequest, listAllProfileOfLocalBody, payload);

}

export function* fetchProfileOfLocalBodyById({ payload }) {
    yield call(handleAPIRequest, listProfileOfLocalBodyById, payload);
}

export function* nonBioFacilitiesById({ payload: id }) {
    yield call(handleAPIRequest, nonBioFacilitiesByIdApi, id);
}

export function* fetchTransportationByID({ payload }) {
    yield call(handleAPIRequest, listTransportationById, payload);
}

export function* submitTransportation({ payload }) {
    let { id } = payload;
    yield fork(handleAPIRequest, patchTransportation, payload);
    const responseAction = yield take([ACTION_TYPES.POST_TRANSPORTATION_SUCCESS, ACTION_TYPES.POST_TRANSPORTATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_TRANSPORTATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("transportation") }) }));
        yield call(handleAPIRequest, listTransportationById, id);
    }
}
export function* submitWasteGenerationHouseHold({ payload }) {
    yield fork(handleAPIRequest, postWasteGeneratorsHouseHold, payload);
    const responseAction = yield take([ACTION_TYPES.POST_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS, ACTION_TYPES.POST_WASTE_GENERATORS_HOUSE_HOLD_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS) {
        yield call(handleAPIRequest, api.fetchTotalHouseHoldApi, payload?.id);
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("household") }) }));
    }
}

export function* submitWasteGenerationInstitutions({ payload }) {
    yield fork(handleAPIRequest, postWasteGeneratorsInstitutions, payload);
    const responseAction = yield take([ACTION_TYPES.POST_WASTE_GENERATORS_INSTITUTIONS_SUCCESS, ACTION_TYPES.POST_WASTE_GENERATORS_INSTITUTIONS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_WASTE_GENERATORS_INSTITUTIONS_SUCCESS) {
        yield call(handleAPIRequest, api.fetchTotalInstitutionApi, payload?.id);
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("institutions") }) }));
    }
}
export function* fetchWasteGeneratorsHouseHold({ payload }) {
    yield call(handleAPIRequest, listByIdWasteGeneratorsHouseHold, payload);
}

export function* fetchWasteGeneratorsInstitutions({ payload }) {
    yield call(handleAPIRequest, listByIdWasteGeneratorsInstitution, payload);
}

export function* fetchForwardLinkedById({ payload }) {
    yield call(handleAPIRequest, listByIdForwardLinked, payload);
}

export function* fetchInvolvementOfHksById({ payload }) {
    yield call(handleAPIRequest, listByIdInvolvementOfHks, payload);
}

export function* submitForwardLinked({ payload }) {
    const { id, gapAnalysisForwardLinkedDto, version } = payload;
    const newArray = gapAnalysisForwardLinkedDto?.map(obj => {
        let newObj = {
            ...obj,
            gapAnalysisId: id,
            isCkcl: gapAnalysisForwardLinkedDto[0]?.forwardLinkedId?.isCkcl,
            version: version
        };
        return newObj;
    });
    yield fork(handleAPIRequest, postForwardLinked, { gapAnalysisForwardLinkedDto: newArray, id });
    const responseAction = yield take([ACTION_TYPES.POST_FORWARD_LINKED_BY_ID_SUCCESS, ACTION_TYPES.POST_FORWARD_LINKED_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_FORWARD_LINKED_BY_ID_SUCCESS) {
        yield call(handleAPIRequest, listByIdForwardLinked, { id: id, isCkcl: payload?.gapAnalysisForwardLinkedDto[0]?.forwardLinkedId?.isCkcl });
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("forward_linked") }) }));
    }
}

export function* submitInvolvementOfHks({ payload }) {
    yield fork(handleAPIRequest, postInvolvementOfHks, payload);
    const responseAction = yield take([ACTION_TYPES.POST_INVOLVEMENT_OF_HKS_BY_ID_SUCCESS, ACTION_TYPES.POST_INVOLVEMENT_OF_HKS_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_INVOLVEMENT_OF_HKS_BY_ID_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("involvement_of_hks") }) }));
    }
}

export function* nonBioFacilitiesEquipmentById({ payload: id }) {
    yield call(handleAPIRequest, nonBioFacilitiesEquipmentByIdApi, id);
}

export function* updateNonBioFacilitiesEquipment({ payload }) {
    let { gapAnalysisNonBioFacilityEquipmentsDto, id } = payload;
    gapAnalysisNonBioFacilityEquipmentsDto = gapAnalysisNonBioFacilityEquipmentsDto?.map(obj => {
        let newObj = {
            ...obj,
            approxTotalPrice: obj?.count * obj?.totalCapacity || 0
        };
        return newObj;
    });
    yield fork(handleAPIRequest, updateNonBioFacilitiesEqupmentApi, { gapAnalysisNonBioFacilityEquipmentsDto, id });
    const responseAction = yield take([ACTION_TYPES.UPDATE_NON_BIO_FACILITIES_EQUIPMENT_SUCCESS, ACTION_TYPES.UPDATE_NON_BIO_FACILITIES_EQUIPMENT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_NON_BIO_FACILITIES_EQUIPMENT_SUCCESS) {
        yield call(handleAPIRequest, nonBioFacilitiesEquipmentByIdApi, payload?.id);
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("non_bio_facility_equipments") }) }));
    }
}

export function* updateBioFacilities({ payload }) {
    const { gapAnalysisId } = payload;
    yield fork(handleAPIRequest, updateBioFacilitiesApi, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_BIO_FACILITIES_SUCCESS, ACTION_TYPES.UPDATE_BIO_FACILITIES_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_BIO_FACILITIES_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("bio_facilities") }) }));
        yield call(handleAPIRequest, bioFacilitiesByIdApi, gapAnalysisId);
    }
}

export function* bioFacilitiesById({ payload: id }) {
    yield call(handleAPIRequest, bioFacilitiesByIdApi, id);
}

export function* bioFacilitiesEquipmentById({ payload: id }) {
    yield call(handleAPIRequest, bioFacilitiesEquipmentByIdApi, id);
}

export function* updateBioFacilitiesEquipment({ payload }) {
    yield fork(handleAPIRequest, updateBioFacilitiesEqupmentApi, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_BIO_FACILITIES_EQUIPMENT_SUCCESS, ACTION_TYPES.UPDATE_BIO_FACILITIES_EQUIPMENT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_BIO_FACILITIES_EQUIPMENT_SUCCESS) {
        yield call(handleAPIRequest, bioFacilitiesEquipmentByIdApi, payload?.id);
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("bio_facility_equipments") }) }));
    }
}

export function* listAllStreetSweeping({ payload }) {
    yield call(handleAPIRequest, listAllStreetSweepingAndDrains, payload);
}

export function* fetchStreetSweepingById({ payload }) {
    yield call(handleAPIRequest, streetSweepingAndDrainsById, payload);

}

export function* postStreetSweeping(action) {
    let { payload } = action;
    if (payload.id) {
        yield fork(handleAPIRequest, patchStreepSweepingAndDrains, payload);
        const responseAction = yield take([ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_SUCCESS, ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("street_sweeping_and_drains") }) }));
        }
    } else {
        yield fork(handleAPIRequest, postStreepSweepingAndDrains, payload);
        const responseAction = yield take([ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_SUCCESS, ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("street_sweeping_and_drains") }) }));
        }
    }
}

export function* landDetailsById({ payload: id }) {
    yield call(handleAPIRequest, landDetailsByIdApi, id);
}

export function* updateLandDetails({ payload }) {
    yield fork(handleAPIRequest, updateLandDetailsApi, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_LAND_DETAILS_SUCCESS, ACTION_TYPES.UPDATE_LAND_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_LAND_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("land_details") }) }));
    }
}

export function* fetchStaffPaymentListAll({ payload }) {
    yield fork(handleAPIRequest, staffPaymentListAll, payload);
    const COMPARISON_TYPES = [ACTION_TYPES.STAFF_PAYMENT_LIST_ALL_SUCCESS, ACTION_TYPES.STAFF_PAYMENT_LIST_ALL_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.GAP_ANALYSIS_PAYMENT_TO_STAFF_AND_EXPENSES);
}

export function* submitStaffPayment({ payload }) {
    const updatedPayload = { year: payload?.year.name, gapAnalysisId: payload?.gapAnalysisId, totalAmountConsultants: payload?.totalAmountConsultants, totalAmountFuel: payload?.totalAmountFuel, totalAmountMedical: payload?.totalAmountMedical, totalAmountPpeUniforms: payload?.totalAmountPpeUniforms, totalAmountTraining: payload?.totalAmountTraining, totalAmountVehicleMaintenance: payload?.totalAmountVehicleMaintenance, totalExpenseElectricity: payload?.totalExpenseElectricity, totalExpenseIctTechnologyQrcode: payload?.totalExpenseIctTechnologyQrcode, totalExpenseIecAwareness: payload?.totalExpenseIecAwareness, totalExpenseWasteRejects: payload?.totalExpenseWasteRejects, totalWagesContractStaff: payload?.totalWagesContractStaff, totalWagesHarithasahayasthapanam: payload?.totalWagesHarithasahayasthapanam, totalWagesHksMember: payload?.totalWagesHksMember, totalWagesSanitationWorker: payload?.totalWagesSanitationWorker, version: payload?.version };
    yield fork(handleAPIRequest, postStaffPayment, updatedPayload);
    const responseAction = yield take([ACTION_TYPES.SAVE_STAFF_PAYMENT_SUCCESS, ACTION_TYPES.SAVE_STAFF_PAYMENT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_STAFF_PAYMENT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("payment_to_staffs_and_expenses_in_swm") }) }));
    }
}

export function* updateStaffPayment({ payload }) {
    const updatedPayload = { id: payload?.id, year: payload?.year.name, gapAnalysisId: payload?.gapAnalysisId, totalAmountConsultants: payload?.totalAmountConsultants, totalAmountFuel: payload?.totalAmountFuel, totalAmountMedical: payload?.totalAmountMedical, totalAmountPpeUniforms: payload?.totalAmountPpeUniforms, totalAmountTraining: payload?.totalAmountTraining, totalAmountVehicleMaintenance: payload?.totalAmountVehicleMaintenance, totalExpenseElectricity: payload?.totalExpenseElectricity, totalExpenseIctTechnologyQrcode: payload?.totalExpenseIctTechnologyQrcode, totalExpenseIecAwareness: payload?.totalExpenseIecAwareness, totalExpenseWasteRejects: payload?.totalExpenseWasteRejects, totalWagesContractStaff: payload?.totalWagesContractStaff, totalWagesHarithasahayasthapanam: payload?.totalWagesHarithasahayasthapanam, totalWagesHksMember: payload?.totalWagesHksMember, totalWagesSanitationWorker: payload?.totalWagesSanitationWorker, version: payload?.version };
    yield fork(handleAPIRequest, patchStaffPayment, updatedPayload);
    const responseAction = yield take([ACTION_TYPES.PATCH_STAFF_PAYMENT_SUCCESS, ACTION_TYPES.PATCH_STAFF_PAYMENT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_STAFF_PAYMENT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("payment_to_staffs_and_expenses_in_swm") }) }));
    }
}

export function* fetchStaffPaymentById({ payload: id }) {
    yield call(handleAPIRequest, staffPaymentById, id);
}

export function* fetchOtherNonBioFacilitiesById({ payload: id }) {
    yield call(handleAPIRequest, otherNonBioFacilitiesById, id);
}

export function* updateOtherNonBioFacilities({ payload }) {
    yield fork(handleAPIRequest, patchOtherNonBioFacilities, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_OTHER_NON_BIO_FACILITIES_SUCCESS, ACTION_TYPES.PATCH_OTHER_NON_BIO_FACILITIES_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_OTHER_NON_BIO_FACILITIES_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("other_non_bio_facility") }) }));
    }
}

export function* fetchWasteProcessedHouseHoldById({ payload: id }) {
    yield call(handleAPIRequest, WasteProcessedHouseHoldById, id);
}

export function* updateWasteProcessedHouseHoldById({ payload }) {
    let { id } = payload;
    yield fork(handleAPIRequest, patchWasteProcessedHouseHold, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_WASTE_PROCESSED_HOUSE_HOLD_SUCCESS, ACTION_TYPES.PATCH_WASTE_PROCESSED_HOUSE_HOLD_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_WASTE_PROCESSED_HOUSE_HOLD_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("bdw_household") }) }));
        yield call(handleAPIRequest, WasteProcessedHouseHoldById, id);
    }
}

export function* submitIncomeFromWaste({ payload }) {
    const updatedPayload = { year: payload?.year.name, gapAnalysisId: payload?.gapAnalysisId, totalBdwManaged: payload?.totalBdwManaged, totalIncomeBdwWaste: payload?.totalIncomeBdwWaste, totalIncomeNbdwWaste: payload?.totalIncomeNbdwWaste, totalIncomeScrapSale: payload?.totalIncomeScrapSale, totalIncomeUserFee: payload?.totalIncomeUserFee, totalIncomeWaste: payload?.totalIncomeWaste, totalNbdwManaged: payload?.totalNbdwManaged, totalWasteManaged: payload?.totalWasteManaged, version: payload?.version };
    yield fork(handleAPIRequest, postIncomeFromWaste, updatedPayload);
    const responseAction = yield take([ACTION_TYPES.SAVE_INCOME_FROM_WASTE_SUCCESS, ACTION_TYPES.SAVE_INCOME_FROM_WASTE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_INCOME_FROM_WASTE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("income_from_waste") }) }));
    }
}

export function* updateIncomeFromWaste({ payload }) {
    const updatedPayload = { year: payload?.year.name, gapAnalysisId: payload?.gapAnalysisId, totalBdwManaged: payload?.totalBdwManaged, totalIncomeBdwWaste: payload?.totalIncomeBdwWaste, totalIncomeNbdwWaste: payload?.totalIncomeNbdwWaste, totalIncomeScrapSale: payload?.totalIncomeScrapSale, totalIncomeUserFee: payload?.totalIncomeUserFee, totalIncomeWaste: payload?.totalIncomeWaste, totalNbdwManaged: payload?.totalNbdwManaged, totalWasteManaged: payload?.totalWasteManaged, version: payload?.version, id: payload?.id };
    yield fork(handleAPIRequest, patchIncomeFromWaste, updatedPayload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_INCOME_FROM_WASTE_SUCCESS, ACTION_TYPES.UPDATE_INCOME_FROM_WASTE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_INCOME_FROM_WASTE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("income_from_waste") }) }));
    }
}

export function* listIncomeFromWaste(action) {
    yield fork(handleAPIRequest, listIncomeFromWasteApi, action.payload);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_INCOME_FROM_WASTE_SUCCESS, ACTION_TYPES.LIST_INCOME_FROM_WASTE_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.LIST_OF_INCOME_FROM_WASTE);

}

export function* incomeFromWasteById({ payload: id }) {
    yield call(handleAPIRequest, incomeFromWasteByIdApi, id);
}

export function* fetchWasteProcessedInstitutionsById({ payload: id }) {
    yield call(handleAPIRequest, wasteProcessedInstitutionsById, id);
}

export function* updateWasteProcessedInstitutionsById({ payload }) {
    let { id } = payload;
    yield fork(handleAPIRequest, patchWasteProcessedInstitutions, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_WASTE_PROCESSED_INSTITUTIONS_SUCCESS, ACTION_TYPES.PATCH_WASTE_PROCESSED_INSTITUTIONS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_WASTE_PROCESSED_INSTITUTIONS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("bdw_institutional") }) }));
        yield call(handleAPIRequest, wasteProcessedInstitutionsById, id);
    }
}

export function* fetchBDWCommunityLevelListAll({ payload: id }) {
    yield call(handleAPIRequest, bdwCommunityLevelListAll, id);
}

export function* postBdwCommunityLevel({ payload }) {
    yield fork(handleAPIRequest, postBDWCommunityLevel, payload);
    const responseAction = yield take([ACTION_TYPES.POST_BDW_COMMUNITY_ULB_LEVEL_LIST_SUCCESS, ACTION_TYPES.POST_BDW_COMMUNITY_ULB_LEVEL_LIST_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_BDW_COMMUNITY_ULB_LEVEL_LIST_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("bdw_community_level") }) }));
    }
}

export function* fetchBDWCommunityLevelById({ payload: id }) {
    yield call(handleAPIRequest, bdwCommunityLevelById, id);
}

export function* updateBdwCommunityLevel({ payload }) {
    yield fork(handleAPIRequest, patchBDWCommunityLevel, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_SUCCESS, ACTION_TYPES.PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("bdw_community_level") }) }));
    }
}

export function* submitBdwProcessedPerDay({ payload }) {
    yield fork(handleAPIRequest, api.postBdwProcessedPerDay, payload);
    const responseAction = yield take([ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_SUCCESS, ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("total_bdw_processed_per_day") }) }));
    }
}

export function* updateBdwProcessedPerDay({ payload }) {
    yield fork(handleAPIRequest, api.patchBdwProcessedPerDay, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_BDW_PROCESSED_PER_DAY_SUCCESS, ACTION_TYPES.UPDATE_BDW_PROCESSED_PER_DAY_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_BDW_PROCESSED_PER_DAY_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("total_bdw_processed_per_day") }) }));
    }
}

export function* listBdwProcessedPerDay(action) {
    yield fork(handleAPIRequest, api.listBdwProcessedPerDayApi, action.payload);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_BDW_PROCESSED_PER_DAY_SUCCESS, ACTION_TYPES.LIST_BDW_PROCESSED_PER_DAY_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* bdwProcessedPerDayById({ payload: id }) {
    yield call(handleAPIRequest, api.bdwProcessedPerDayByIdApi, id);
}

export function* submitPopulation({ payload }) {
    yield fork(handleAPIRequest, api.postPopulation, payload);
    const responseAction = yield take([ACTION_TYPES.SAVE_POPULATION_SUCCESS, ACTION_TYPES.SAVE_POPULATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_POPULATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("population") }) }));
    }
}

export function* updatePopulation({ payload }) {
    yield fork(handleAPIRequest, api.patchPopulation, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_POPULATION_SUCCESS, ACTION_TYPES.UPDATE_POPULATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_POPULATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("population") }) }));
        yield call(handleAPIRequest, api.populationByIdApi, payload.id);
    }
}

export function* listPopulation(action) {
    yield fork(handleAPIRequest, api.listPopulationApi, action.payload);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_POPULATION_SUCCESS, ACTION_TYPES.LIST_POPULATION_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* populationById({ payload: id }) {
    yield call(handleAPIRequest, api.populationByIdApi, id);
}

export function* bdwCommunityLevelUlbById({ payload: id }) {
    yield call(handleAPIRequest, api.bdwCommunityLevelByIdApi, id);
}

export function* submitBdwCommunityLevel({ payload }) {
    let { gapAnalysisId } = payload;
    yield fork(handleAPIRequest, api.postBdwCommunityLevel, payload);
    const responseAction = yield take([ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_SUCCESS, ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("bdw_community_level") }) }));
        yield call(handleAPIRequest, api.bdwCommunityLevelByIdApi, gapAnalysisId);
        yield fork(handleAPIRequest, api.listBdwCommunityLevelApi, gapAnalysisId);
    }
}

export function* updateBdwCommunityLevelUlb({ payload }) {
    yield fork(handleAPIRequest, api.patchBdwCommunityLevel, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_BDW_COMMUNITY_LEVEL_SUCCESS, ACTION_TYPES.UPDATE_BDW_COMMUNITY_LEVEL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_BDW_COMMUNITY_LEVEL_SUCCESS) {
        yield call(handleAPIRequest, api.bdwCommunityLevelByIdApi, payload?.gapAnalysisId);
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("bdw_community_level") }) }));
    }
}

export function* listBdwCommunityLevel(action) {
    yield fork(handleAPIRequest, api.listBdwCommunityLevelApi, action.payload);
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_BDW_COMMUNITY_LEVEL_SUCCESS, ACTION_TYPES.LIST_BDW_COMMUNITY_LEVEL_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* submitNonBioDegradable({ payload }) {
    let { gapAnalysisId } = payload;
    yield fork(handleAPIRequest, api.postNonBioDegradable, payload);
    const responseAction = yield take([ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_SUCCESS, ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("non_bio_degradable_waste") }) }));
        yield call(handleAPIRequest, api.listNonBioDegradableApi, { gapAnalysisId: gapAnalysisId });

    }
}

export function* updateNonBioDegradable({ payload }) {
    let { gapAnalysisId } = payload;
    yield fork(handleAPIRequest, api.patchNonBioDegradable, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_SUCCESS, ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("non_bio_degradable_waste") }) }));
        yield call(handleAPIRequest, api.nonBioDegradableByIdApi, gapAnalysisId);

    }
}

export function* listNonBioDegradable(action) {
    yield call(handleAPIRequest, api.listNonBioDegradableApi, action.payload);

}

export function* nonBioDegradableById({ payload: id }) {
    yield call(handleAPIRequest, api.nonBioDegradableByIdApi, id);
}

export function* bioDegradableById({ payload: id }) {
    yield call(handleAPIRequest, api.bioDegradableByIdApi, id);
}

export function* updateBioDegradable({ payload }) {
    let { id } = payload;
    yield fork(handleAPIRequest, api.updateBioDegradableApi, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_BIO_DEGRADABLE_SUCCESS, ACTION_TYPES.UPDATE_BIO_DEGRADABLE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_BIO_DEGRADABLE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("bio_degradable_waste") }) }));
        yield call(handleAPIRequest, api.bioDegradableByIdApi, id);
    }
}

export function* nonBioDegradableListById({ payload: id }) {
    yield call(handleAPIRequest, api.nonBioDegradableListByIdApi, id);
}

export function* updateNonBioDegradableList({ payload }) {
    let { id } = payload;
    yield fork(handleAPIRequest, api.updateNonBioDegradableApi, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_LIST_SUCCESS, ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_LIST_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_LIST_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("non_bio_degradable_waste") }) }));
        yield call(handleAPIRequest, api.nonBioDegradableListByIdApi, id);
    }
}

export function* submitNonBioFacilities({ payload }) {
    let { gapAnalysisId, version, gapAnalysisNonBioFacilitiesDto } = payload;
    yield fork(handleAPIRequest, api.submitNonBioFacilitiesApi, { ...gapAnalysisNonBioFacilitiesDto, version, gapAnalysisId });
    const responseAction = yield take([ACTION_TYPES.SUBMIT_NON_BIO_FACILITIES_SUCCESS, ACTION_TYPES.SUBMIT_NON_BIO_FACILITIES_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_NON_BIO_FACILITIES_SUCCESS) {
        yield call(handleAPIRequest, nonBioFacilitiesByIdApi, payload?.gapAnalysisId);
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("non_bio_facilities") }) }));
        yield call(handleAPIRequest, api.fetchTotalNonBioFacilitiesApi, payload?.gapAnalysisId);
        yield call(handleAPIRequest, nonBioFacilitiesEquipmentByIdApi, payload?.gapAnalysisId);
    }
}

export function* fetchNonBioFacilitiesTotal({ payload: id }) {
    yield call(handleAPIRequest, api.fetchTotalNonBioFacilitiesApi, id);
}

export function* fetchHouseHoldTotal({ payload: id }) {
    yield call(handleAPIRequest, api.fetchTotalHouseHoldApi, id);
}

export function* fetchInstitutionsTotal({ payload: id }) {
    yield call(handleAPIRequest, api.fetchTotalInstitutionApi, id);
}

export function* fetchNonBioFacilitiesType() {
    yield call(handleAPIRequest, api.listTypeOfNonBioFacilitiesApi);
}

export function* fetchCurrentStatus() {
    yield call(handleAPIRequest, api.fetchCurrentStatusApi);
}

export function* fetchOwnershipList() {
    yield call(handleAPIRequest, api.fetchOwnershipList);
}

export function* bioFacilities({ payload }) {
    yield call(handleAPIRequest, api.bioFacilitiesDropdownApi, payload);
}

export function* fetchWardWisePopulationListAll({ payload }) {
    yield fork(handleAPIRequest, api.fetchWardWisePopulationListAll, payload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WARD_WISE_POPULATION_SUCCESS, ACTION_TYPES.FETCH_WARD_WISE_POPULATION_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WARD_WISE_POPULATION_TABLE);
}

export function* submitWardWisePopulation({ payload }) {
    yield fork(handleAPIRequest, api.postWardWisePopulation, payload);
    const responseAction = yield take([ACTION_TYPES.SAVE_WARD_WISE_POPULATION_SUCCESS, ACTION_TYPES.SAVE_WARD_WISE_POPULATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_WARD_WISE_POPULATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("ward_wise_population") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchWardWisePopulationListAll, { payload: { id: payload?.gapAnalysisId, ...tablePayload } });
    }
}

export function* patchWardWisePopulation({ payload }) {
    yield fork(handleAPIRequest, api.patchWardWisePopulation, payload);
    const responseAction = yield take([ACTION_TYPES.PATCH_WARD_WISE_POPULATION_SUCCESS, ACTION_TYPES.PATCH_WARD_WISE_POPULATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_WARD_WISE_POPULATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("ward_wise_population") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchWardWisePopulationListAll, { payload: { id: payload?.gapAnalysisId, ...tablePayload } });
    }
}

export function* wardWisePopulationById({ payload }) {
    yield call(handleAPIRequest, api.wardWisePopulationByIdApi, payload);
}

export function* deleteWardWisePopulation({ payload }) {
    yield fork(handleAPIRequest, api.deleteWardWisePopulation, payload);
    const responseAction = yield take([ACTION_TYPES.DELETE_WARD_WISE_POPULATION_SUCCESS, ACTION_TYPES.DELETE_WARD_WISE_POPULATION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_WARD_WISE_POPULATION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("ward_wise_population") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(fetchWardWisePopulationListAll, { payload: { id: payload?.gapAnalysisId, ...tablePayload } });
    }
}

export function* downloadCurrentStatus(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.GAP_ANALYSIS.DOWNLOAD,
        data: { type },
        file: { name: fileName, ext: type }
    }));
}

export default function* testSaga() {
    yield all([
        takeLatest(ACTION_TYPES.POST_GAP_ANALYSIS, submitGapAnalysisVersion),
        takeLatest(ACTION_TYPES.FETCH_GAP_ANALYSIS, fetchGapAnalysisList),
        takeLatest(ACTION_TYPES.FETCH_GAP_ANALYSIS_BY_ID, fetchGapAnalysisListById),
        takeLatest(ACTION_TYPES.SAVE_WASTE_GENERATED, submitWasteGenerated),
        takeLatest(ACTION_TYPES.UPDATE_WASTE_GENERATED, updateWasteGenerated),
        takeLatest(ACTION_TYPES.LIST_WASTE_GENERATED, listWasteGenerated),
        takeLatest(ACTION_TYPES.SAVE_WASTE_GENERATED_BY_ID, wasteGeneratedById),
        takeLatest(ACTION_TYPES.POST_PROFILE_OF_LOCAL_BODY, submitProfileOfLocalBody),
        takeLatest(ACTION_TYPES.SELECT_LSGI_DROPDOWN, fetchLsgiDropDown),
        takeLatest(ACTION_TYPES.FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY, listProfileOfLocalBody),
        takeLatest(ACTION_TYPES.FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY, fetchProfileOfLocalBodyById),
        takeLatest(ACTION_TYPES.NON_BIO_FACILITIES_BY_ID, nonBioFacilitiesById),
        takeLatest(ACTION_TYPES.FETCH_TRANSPORTATION_BY_ID, fetchTransportationByID),
        takeLatest(ACTION_TYPES.POST_TRANSPORTATION, submitTransportation),
        takeLatest(ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD, fetchWasteGeneratorsHouseHold),
        takeLatest(ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS, fetchWasteGeneratorsInstitutions),
        takeLatest(ACTION_TYPES.POST_WASTE_GENERATORS_HOUSE_HOLD, submitWasteGenerationHouseHold),
        takeLatest(ACTION_TYPES.POST_WASTE_GENERATORS_INSTITUTIONS, submitWasteGenerationInstitutions),
        takeLatest(ACTION_TYPES.NON_BIO_FACILITIES_EQUIPMENT_BY_ID, nonBioFacilitiesEquipmentById),
        takeLatest(ACTION_TYPES.UPDATE_NON_BIO_FACILITIES_EQUIPMENT, updateNonBioFacilitiesEquipment),
        takeLatest(ACTION_TYPES.FETCH_FORWARD_LINKED_BY_ID, fetchForwardLinkedById),
        takeLatest(ACTION_TYPES.POST_FORWARD_LINKED_BY_ID, submitForwardLinked),
        takeLatest(ACTION_TYPES.FETCH_INVOLVEMENT_OF_HKS_BY_ID, fetchInvolvementOfHksById),
        takeLatest(ACTION_TYPES.POST_INVOLVEMENT_OF_HKS_BY_ID, submitInvolvementOfHks),
        takeLatest(ACTION_TYPES.BIO_FACILITIES_BY_ID, bioFacilitiesById),
        takeLatest(ACTION_TYPES.UPDATE_BIO_FACILITIES, updateBioFacilities),
        takeLatest(ACTION_TYPES.BIO_FACILITIES_EQUIPMENT_BY_ID, bioFacilitiesEquipmentById),
        takeLatest(ACTION_TYPES.UPDATE_BIO_FACILITIES_EQUIPMENT, updateBioFacilitiesEquipment),
        takeLatest(ACTION_TYPES.FETCH_LIST_ALL_STREET_SWEEPING, listAllStreetSweeping),
        takeLatest(ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS, postStreetSweeping),
        takeLatest(ACTION_TYPES.FETCH_BY_ID_STREET_SWEEPING, fetchStreetSweepingById),
        takeLatest(ACTION_TYPES.LAND_DETAILS_BY_ID, landDetailsById),
        takeLatest(ACTION_TYPES.UPDATE_LAND_DETAILS, updateLandDetails),
        takeLatest(ACTION_TYPES.SAVE_STAFF_PAYMENT, submitStaffPayment),
        takeLatest(ACTION_TYPES.PATCH_STAFF_PAYMENT, updateStaffPayment),
        takeLatest(ACTION_TYPES.STAFF_PAYMENT_LIST_ALL, fetchStaffPaymentListAll),
        takeLatest(ACTION_TYPES.STAFF_PAYMENT_BY_ID, fetchStaffPaymentById),
        takeLatest(ACTION_TYPES.OTHER_NON_BIO_FACILITIES_ID, fetchOtherNonBioFacilitiesById),
        takeLatest(ACTION_TYPES.PATCH_OTHER_NON_BIO_FACILITIES, updateOtherNonBioFacilities),
        takeLatest(ACTION_TYPES.WASTE_PROCESSED_HOUSE_HOLD_BY_ID, fetchWasteProcessedHouseHoldById),
        takeLatest(ACTION_TYPES.PATCH_WASTE_PROCESSED_HOUSE_HOLD, updateWasteProcessedHouseHoldById),
        takeLatest(ACTION_TYPES.SAVE_INCOME_FROM_WASTE, submitIncomeFromWaste),
        takeLatest(ACTION_TYPES.UPDATE_INCOME_FROM_WASTE, updateIncomeFromWaste),
        takeLatest(ACTION_TYPES.LIST_INCOME_FROM_WASTE, listIncomeFromWaste),
        takeLatest(ACTION_TYPES.SAVE_INCOME_FROM_WASTE_BY_ID, incomeFromWasteById),
        takeLatest(ACTION_TYPES.WASTE_PROCESSED_INSTITUTIONS_BY_ID, fetchWasteProcessedInstitutionsById),
        takeLatest(ACTION_TYPES.PATCH_WASTE_PROCESSED_INSTITUTIONS, updateWasteProcessedInstitutionsById),
        takeLatest(ACTION_TYPES.POST_BDW_COMMUNITY_ULB_LEVEL_LIST, postBdwCommunityLevel),
        takeLatest(ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_ALL, fetchBDWCommunityLevelListAll),
        takeLatest(ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID, fetchBDWCommunityLevelById),
        takeLatest(ACTION_TYPES.PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST, updateBdwCommunityLevel),
        takeLatest(ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY, submitBdwProcessedPerDay),
        takeLatest(ACTION_TYPES.UPDATE_BDW_PROCESSED_PER_DAY, updateBdwProcessedPerDay),
        takeLatest(ACTION_TYPES.LIST_BDW_PROCESSED_PER_DAY, listBdwProcessedPerDay),
        takeLatest(ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_BY_ID, bdwProcessedPerDayById),
        takeLatest(ACTION_TYPES.SAVE_POPULATION, submitPopulation),
        takeLatest(ACTION_TYPES.UPDATE_POPULATION, updatePopulation),
        takeLatest(ACTION_TYPES.LIST_POPULATION, listPopulation),
        takeLatest(ACTION_TYPES.SAVE_POPULATION_BY_ID, populationById),
        takeLatest(ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL, submitBdwCommunityLevel),
        takeLatest(ACTION_TYPES.UPDATE_BDW_COMMUNITY_LEVEL, updateBdwCommunityLevelUlb),
        takeLatest(ACTION_TYPES.LIST_BDW_COMMUNITY_LEVEL, listBdwCommunityLevel),
        takeLatest(ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_BY_ID, bdwCommunityLevelUlbById),
        takeLatest(ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE, submitNonBioDegradable),
        takeLatest(ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE, updateNonBioDegradable),
        takeLatest(ACTION_TYPES.LIST_NON_BIO_DEGRADABLE, listNonBioDegradable),
        takeLatest(ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_BY_ID, nonBioDegradableById),
        takeLatest(ACTION_TYPES.BIO_DEGRADABLE_BY_ID, bioDegradableById),
        takeLatest(ACTION_TYPES.UPDATE_BIO_DEGRADABLE, updateBioDegradable),
        takeLatest(ACTION_TYPES.NON_BIO_DEGRADABLE_BY_ID, nonBioDegradableListById),
        takeLatest(ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_LIST, updateNonBioDegradableList),
        takeLatest(ACTION_TYPES.SUBMIT_NON_BIO_FACILITIES, submitNonBioFacilities),
        takeLatest(ACTION_TYPES.FETCH_TOTAL_NON_BIO_FACILITIES, fetchNonBioFacilitiesTotal),
        takeLatest(ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD, fetchHouseHoldTotal),
        takeLatest(ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS, fetchInstitutionsTotal),
        takeLatest(ACTION_TYPES.LIST_NON_BIO_FACILITIES_TYPE, fetchNonBioFacilitiesType),
        takeLatest(ACTION_TYPES.FETCH_CURRENT_STATUS, fetchCurrentStatus),
        takeLatest(ACTION_TYPES.FETCH_OWNERSHIP_LIST, fetchOwnershipList),
        takeLatest(ACTION_TYPES.GET_BIO_FACILITIES_DROPDOWN, bioFacilities),
        takeLatest(ACTION_TYPES.FETCH_WARD_WISE_POPULATION, fetchWardWisePopulationListAll),
        takeLatest(ACTION_TYPES.SAVE_WARD_WISE_POPULATION, submitWardWisePopulation),
        takeLatest(ACTION_TYPES.PATCH_WARD_WISE_POPULATION, patchWardWisePopulation),
        takeLatest(ACTION_TYPES.BY_ID_WARD_WISE_POPULATION, wardWisePopulationById),
        takeLatest(ACTION_TYPES.DELETE_WARD_WISE_POPULATION, deleteWardWisePopulation),
        takeLatest(ACTION_TYPES.DOWNLOAD_CURRENT_STATUS_LIST, downloadCurrentStatus)
    ]);
}
