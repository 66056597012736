const { createSlice } = require("@reduxjs/toolkit");
const { STATE_REDUCER_KEY } = require("./constants");
const { ACTION_TYPES } = require("./actions");
import _ from "lodash";

const initialState = {
    regionalDisposalSiteById: {
        requestInProgress: false,
        data: {
            lsgiId: "",
            districtId: "",
            name: "",
            location: "",
            latitude: "",
            longitude: "",
            total: "",
            male: "",
            female: "",
            transgender: "",
            sc: "",
            st: "",
            disabled: "",
            womenGroup: "",
            seniorCitizen: "",
            capacity: "",
            serviceArea: "",
            facility: "",
            siteArea: "",
            areaCovered: "",
            qtyOfWasteDeposited: "",
            distanceFromCity: "",
            distanceFromSensitiveReceptor: "",
            leachateManagement: "",
            odourManagement: "",
            flyManagement: "",
            natureOfArea: "",
            anyStatutirySiteApproval: "",
            dateOfCollection: ""
        }
    },
    surroundingDropdown: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({

    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_REQUEST, (state) => {
                state.regionalDisposalSiteById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "regionalDisposalSiteById.requestInProgress", false);
                _.set(state, "regionalDisposalSiteById.data", action.payload);
            })
            .addCase(ACTION_TYPES.FETCH_REGIONAL_DISPOSAL_SITE_BY_ID_FAILURE, (state) => {
                state.regionalDisposalSiteById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_SURROUNDING_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "surroundingDropdown.requestInProgress", false);
                _.set(state, "surroundingDropdown.data", action.payload.content);
            });
    }

});

export const { reducer, actions } = slice;
