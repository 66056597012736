import { flow } from "lodash";

const { STATE_REDUCER_KEY } = require("./constants");

const getUserRoleMappingDetails = (state) => state[STATE_REDUCER_KEY];


const userRoleMappingDetails = (userRoleMapping) => userRoleMapping.userRoleMappingDetails.data;
export const getUserRoleMapping = flow(getUserRoleMappingDetails, userRoleMappingDetails);

const userRoleMappingList = (userRoleMapping) => userRoleMapping.userRoleMappingDetails.data;
export const getUserRoleMappingList = flow(getUserRoleMappingDetails, userRoleMappingList);

const rolesSelectedData = (userRoleMapping) => userRoleMapping.userRoleMappingDetails.data;
export const getRolesSelectedData = flow(getUserRoleMappingDetails, rolesSelectedData);

const roleSelectedRows = (userRoleMapping) => userRoleMapping.userRoleMappingDetails.data.roleMappings;
export const getRoleSelectedRows = flow(getUserRoleMappingDetails, roleSelectedRows);

const assign = (userRoleMapping) => userRoleMapping.assignedRoles;
export const getAssignedRoles = flow(getUserRoleMappingDetails, assign);

const idUser = (userRoleMapping) => userRoleMapping.userId;
export const getUserId = flow(getUserRoleMappingDetails, idUser);

const roleUser = (userRoleMapping) => userRoleMapping.roleId;
export const getRoleId = flow(getUserRoleMappingDetails, roleUser);

const userDataAccess = (userRoleMapping) => userRoleMapping.userRoleDataAccessList.data;
export const getUserDataAccess = flow(getUserRoleMappingDetails, userDataAccess);

const districtSelectDropdownOption = (userRoleMapping) => userRoleMapping.districtSelectDropdownOption.data;
export const getDistrictSelectDropdownOption = flow(getUserRoleMappingDetails, districtSelectDropdownOption);

const lsgiSelectDropdownOption = (userRoleMapping) => userRoleMapping.lsgiSelectDropdownOption;
export const getLsgiSelectDropdownOption = flow(getUserRoleMappingDetails, lsgiSelectDropdownOption);

const wardSelectDropdownOption = (userRoleMapping) => userRoleMapping.wardSelectDropdownOption;
export const getWardSelectDropdownOption = flow(getUserRoleMappingDetails, wardSelectDropdownOption);

const stateSelectDropdownOption = (userRoleMapping) => userRoleMapping.stateSelectDropdownOption.data;
export const getStateSelectDropdownOption = flow(getUserRoleMappingDetails, stateSelectDropdownOption);

const trainingOrganizationDropdownOption = (userRoleMapping) => userRoleMapping.trainingOrganizationDropdownOption.data;
export const getTrainingOrganizationSelectDropdownOption = flow(getUserRoleMappingDetails, trainingOrganizationDropdownOption);

const vendorSelectDropdownOption = (userRoleMapping) => userRoleMapping.vendorSelectDropdownOption.data;
export const getVendorSelectDropdownOption = flow(getUserRoleMappingDetails, vendorSelectDropdownOption);

const userRoleDataAccessDetails = (userRoleMapping) => userRoleMapping.userRoleDataAccessDetails.data;
export const getUserRoleDataAccessDetails = flow(getUserRoleMappingDetails, userRoleDataAccessDetails);

const roleLevelSelectDropdownOption = (userRoleMapping) => userRoleMapping.dataAccessRoleLevelDropdown.data;
export const getRoleLevelSelectDropdownOption = flow(getUserRoleMappingDetails, roleLevelSelectDropdownOption);

const userRolesListById = (userRoleMapping) => userRoleMapping.userRoleListById.data;
export const getUserRoleListById = flow(getUserRoleMappingDetails, userRolesListById);

const lsgiTypeDropdown = (userRoleMapping) => userRoleMapping.lsgiTypeDropdownOption.data;
export const getLsgiTypeDropdownOption = flow(getUserRoleMappingDetails, lsgiTypeDropdown);

const ivaDropdown = (userRoleMapping) => userRoleMapping.ivaDropdownOption.data;
export const getIvaDropdownOption = flow(getUserRoleMappingDetails, ivaDropdown);

const userRoleListAdditionalFilter = (userRoleMapping) => userRoleMapping.userRoleListAdditionalFilters.data;
export const getUserRoleListAdditionalFilter = flow(getUserRoleMappingDetails, userRoleListAdditionalFilter);
