import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const postGapAnalysis = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.SAVE_VERSION,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_GAP_ANALYSIS_REQUEST, ACTION_TYPES.POST_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.POST_GAP_ANALYSIS_FAILURE],
            data
        }
    };

};
export const patchGapAnalysis = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.SAVE_VERSION,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.POST_GAP_ANALYSIS_REQUEST, ACTION_TYPES.POST_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.POST_GAP_ANALYSIS_FAILURE],
            data
        }
    };
};

export const listGapAnalysis = (data) => ({
    url: API_URL.GAP_ANALYSIS.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_GAP_ANALYSIS_REQUEST, ACTION_TYPES.FETCH_GAP_ANALYSIS_SUCCESS, ACTION_TYPES.FETCH_GAP_ANALYSIS_FAILURE],
        data

    }
});

export const listGapAnalysisById = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GAP_ANALYSIS_BY_ID_REQUEST, ACTION_TYPES.FETCH_GAP_ANALYSIS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_GAP_ANALYSIS_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const postWasteGenerated = (data) => ({
    url: API_URL.GAP_ANALYSIS.WASTE_GENERATED.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_WASTE_GENERATED_REQUEST, ACTION_TYPES.SAVE_WASTE_GENERATED_SUCCESS, ACTION_TYPES.SAVE_WASTE_GENERATED_FAILURE],
        data
    }
});

export const wasteGeneratedByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.WASTE_GENERATED.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SAVE_WASTE_GENERATED_BY_ID_REQUEST, ACTION_TYPES.SAVE_WASTE_GENERATED_BY_ID_SUCCESS, ACTION_TYPES.SAVE_WASTE_GENERATED_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const patchWasteGenerated = (data) => ({
    url: API_URL.GAP_ANALYSIS.WASTE_GENERATED.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_WASTE_GENERATED_REQUEST, ACTION_TYPES.UPDATE_WASTE_GENERATED_SUCCESS, ACTION_TYPES.UPDATE_WASTE_GENERATED_FAILURE],
        data
    }
});

export const listWasteGeneratedApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.WASTE_GENERATED.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_WASTE_GENERATED_REQUEST, ACTION_TYPES.LIST_WASTE_GENERATED_SUCCESS, ACTION_TYPES.LIST_WASTE_GENERATED_FAILURE],
        data
    }
});

export const patchProfileOfLocalBody = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.PROFILE_OF_LOCAL_BODY.UPDATE_LOCAL_BODY,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.POST_PROFILE_OF_LOCAL_BODY_REQUEST, ACTION_TYPES.POST_PROFILE_OF_LOCAL_BODY_SUCCESS, ACTION_TYPES.POST_PROFILE_OF_LOCAL_BODY_FAILURE],
            data
        }
    };
};

export const listProfileOfLocalBodyById = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.PROFILE_OF_LOCAL_BODY.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY_REQUEST, ACTION_TYPES.FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY_SUCCESS, ACTION_TYPES.FETCH_LIST_BY_ID_PROFILE_OF_LOCAL_BODY_FAILURE],
            data: { id: data }
        }
    };
};

export const listAllProfileOfLocalBody = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.PROFILE_OF_LOCAL_BODY.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY_REQUEST, ACTION_TYPES.FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY_SUCCESS, ACTION_TYPES.FETCH_LIST_ALL_PROFILE_OF_LOCAL_BODY_FAILURE],
            data
        }
    };
};

export const listTransportationById = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.TRANSPORTATION.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TRANSPORTATION_BY_ID_REQUEST, ACTION_TYPES.FETCH_TRANSPORTATION_BY_ID_SUCCESS, ACTION_TYPES.FETCH_TRANSPORTATION_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const patchTransportation = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.TRANSPORTATION.SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.POST_TRANSPORTATION_REQUEST, ACTION_TYPES.POST_TRANSPORTATION_SUCCESS, ACTION_TYPES.POST_TRANSPORTATION_FAILURE],
            data
        }
    };
};

export const listByIdWasteGeneratorsHouseHold = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.WASTE_GENERATORS.BY_ID_HOUSE_HOLD,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD_REQUEST, ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS, ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_HOUSE_HOLD_FAILURE],
            data: { id: data }
        }
    };
};


export const postWasteGeneratorsHouseHold = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.WASTE_GENERATORS.SAVE_HOUSE_HOLD,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.POST_WASTE_GENERATORS_HOUSE_HOLD_REQUEST, ACTION_TYPES.POST_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS, ACTION_TYPES.POST_WASTE_GENERATORS_HOUSE_HOLD_FAILURE],
            data
        }
    };
};

export const listByIdWasteGeneratorsInstitution = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.WASTE_GENERATORS.BY_ID_INSTITUTIONS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS_REQUEST, ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS_SUCCESS, ACTION_TYPES.FETCH_BY_ID_WASTE_GENERATORS_INSTITUTIONS_FAILURE],
            data: { id: data }
        }
    };
};

export const postWasteGeneratorsInstitutions = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.WASTE_GENERATORS.SAVE_INSTITUTIONS,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.POST_WASTE_GENERATORS_INSTITUTIONS_REQUEST, ACTION_TYPES.POST_WASTE_GENERATORS_INSTITUTIONS_SUCCESS, ACTION_TYPES.POST_WASTE_GENERATORS_INSTITUTIONS_FAILURE],
            data
        }
    };
};

export const listByIdForwardLinked = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.FORWARD_LINKED.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_FORWARD_LINKED_BY_ID_REQUEST, ACTION_TYPES.FETCH_FORWARD_LINKED_BY_ID_SUCCESS, ACTION_TYPES.FETCH_FORWARD_LINKED_BY_ID_FAILURE],
            data
        }
    };
};
export const postForwardLinked = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.FORWARD_LINKED.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_FORWARD_LINKED_BY_ID_REQUEST, ACTION_TYPES.POST_FORWARD_LINKED_BY_ID_SUCCESS, ACTION_TYPES.POST_FORWARD_LINKED_BY_ID_FAILURE],
            data
        }
    };
};

export const listByIdInvolvementOfHks = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.INVOLVEMENT_OF_HKS.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_INVOLVEMENT_OF_HKS_BY_ID_REQUEST, ACTION_TYPES.FETCH_INVOLVEMENT_OF_HKS_BY_ID_SUCCESS, ACTION_TYPES.FETCH_INVOLVEMENT_OF_HKS_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const postInvolvementOfHks = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.INVOLVEMENT_OF_HKS.SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.POST_INVOLVEMENT_OF_HKS_BY_ID_REQUEST, ACTION_TYPES.POST_INVOLVEMENT_OF_HKS_BY_ID_SUCCESS, ACTION_TYPES.POST_INVOLVEMENT_OF_HKS_BY_ID_FAILURE],
            data
        }
    };
};

export const fetchLsgiApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.LSGI_DROPDOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_LSGI_DROPDOWN_REQUEST, ACTION_TYPES.SELECT_LSGI_DROPDOWN_SUCCESS, ACTION_TYPES.SELECT_LSGI_DROPDOWN_FAILURE],
            data
        }
    };
};

export const nonBioFacilitiesByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.NON_BIO_FACILITIES.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.NON_BIO_FACILITIES_BY_ID_REQUEST, ACTION_TYPES.NON_BIO_FACILITIES_BY_ID_SUCCESS, ACTION_TYPES.NON_BIO_FACILITIES_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const updateNonBioFacilitiesApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.NON_BIO_FACILITIES.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_NON_BIO_FACILITIES_REQUEST, ACTION_TYPES.UPDATE_NON_BIO_FACILITIES_SUCCESS, ACTION_TYPES.UPDATE_NON_BIO_FACILITIES_FAILURE],
        data
    }
});

export const updateNonBioFacilitiesEqupmentApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.NON_BIO_FACILITIE_EQUIPMENT.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_NON_BIO_FACILITIES_EQUIPMENT_REQUEST, ACTION_TYPES.UPDATE_NON_BIO_FACILITIES_EQUIPMENT_SUCCESS, ACTION_TYPES.UPDATE_NON_BIO_FACILITIES_EQUIPMENT_FAILURE],
        data
    }
});

export const nonBioFacilitiesEquipmentByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.NON_BIO_FACILITIE_EQUIPMENT.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.NON_BIO_FACILITIES_EQUIPMENT_BY_ID_REQUEST, ACTION_TYPES.NON_BIO_FACILITIES_EQUIPMENT_BY_ID_SUCCESS, ACTION_TYPES.NON_BIO_FACILITIES_EQUIPMENT_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const bioFacilitiesByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.BIO_FACILITIES.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.BIO_FACILITIES_BY_ID_REQUEST, ACTION_TYPES.BIO_FACILITIES_BY_ID_SUCCESS, ACTION_TYPES.BIO_FACILITIES_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const updateBioFacilitiesApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.BIO_FACILITIES.UPDATE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.UPDATE_BIO_FACILITIES_REQUEST, ACTION_TYPES.UPDATE_BIO_FACILITIES_SUCCESS, ACTION_TYPES.UPDATE_BIO_FACILITIES_FAILURE],
        data
    }
});

export const bioFacilitiesEquipmentByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.BIO_FACILITY_EQUIPMENT.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.BIO_FACILITIES_EQUIPMENT_BY_ID_REQUEST, ACTION_TYPES.BIO_FACILITIES_EQUIPMENT_BY_ID_SUCCESS, ACTION_TYPES.BIO_FACILITIES_EQUIPMENT_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const updateBioFacilitiesEqupmentApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.BIO_FACILITY_EQUIPMENT.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_BIO_FACILITIES_EQUIPMENT_REQUEST, ACTION_TYPES.UPDATE_BIO_FACILITIES_EQUIPMENT_SUCCESS, ACTION_TYPES.UPDATE_BIO_FACILITIES_EQUIPMENT_FAILURE],
        data
    }
});

export const listAllStreetSweepingAndDrains = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.STREET_SWEEPING_AND_DRAINS.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_LIST_ALL_STREET_SWEEPING_REQUEST, ACTION_TYPES.FETCH_LIST_ALL_STREET_SWEEPING_SUCCESS, ACTION_TYPES.FETCH_LIST_ALL_STREET_SWEEPING_FAILURE],
            data
        }
    };
};

export const streetSweepingAndDrainsById = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.STREET_SWEEPING_AND_DRAINS.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_BY_ID_STREET_SWEEPING_REQUEST, ACTION_TYPES.FETCH_BY_ID_STREET_SWEEPING_SUCCESS, ACTION_TYPES.FETCH_BY_ID_STREET_SWEEPING_FAILURE],
            data: { id: data }
        }
    };
};

export const updateLandDetailsApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.LAND_DETAILS.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_LAND_DETAILS_REQUEST, ACTION_TYPES.UPDATE_LAND_DETAILS_SUCCESS, ACTION_TYPES.UPDATE_LAND_DETAILS_FAILURE],
        data
    }
});

export const landDetailsByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.LAND_DETAILS.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LAND_DETAILS_BY_ID_REQUEST, ACTION_TYPES.LAND_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.LAND_DETAILS_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const postStreepSweepingAndDrains = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.STREET_SWEEPING_AND_DRAINS.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_REQUEST, ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_SUCCESS, ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_FAILURE],
            data
        }
    };
};

export const patchStreepSweepingAndDrains = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.STREET_SWEEPING_AND_DRAINS.UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_REQUEST, ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_SUCCESS, ACTION_TYPES.POST_STREET_SWEEPING_AND_DRAINS_FAILURE],
            data
        }
    };
};

export const staffPaymentListAll = (data) => {
    let pageNo = data?.pageNo;
    let pageSize = data?.pageSize;
    let gapAnalysisId = data?.id;
    return {
        url: API_URL.GAP_ANALYSIS.STAFF_PAYMENTS.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.STAFF_PAYMENT_LIST_ALL_REQUEST, ACTION_TYPES.STAFF_PAYMENT_LIST_ALL_SUCCESS, ACTION_TYPES.STAFF_PAYMENT_LIST_ALL_FAILURE],
            data: { gapAnalysisId, pageNo, pageSize }
        }
    };
};

export const staffPaymentById = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.STAFF_PAYMENTS.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.STAFF_PAYMENT_BY_ID_REQUEST, ACTION_TYPES.STAFF_PAYMENT_BY_ID_SUCCESS, ACTION_TYPES.STAFF_PAYMENT_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const postStaffPayment = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.STAFF_PAYMENTS.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SAVE_STAFF_PAYMENT_REQUEST, ACTION_TYPES.SAVE_STAFF_PAYMENT_SUCCESS, ACTION_TYPES.SAVE_STAFF_PAYMENT_FAILURE],
            data
        }
    };
};

export const patchStaffPayment = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.STAFF_PAYMENTS.UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_STAFF_PAYMENT_REQUEST, ACTION_TYPES.PATCH_STAFF_PAYMENT_SUCCESS, ACTION_TYPES.PATCH_STAFF_PAYMENT_FAILURE],
            data
        }
    };
};

export const patchOtherNonBioFacilities = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.OTHER_NO_BIO_FACILITIES.UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_OTHER_NON_BIO_FACILITIES_REQUEST, ACTION_TYPES.PATCH_OTHER_NON_BIO_FACILITIES_SUCCESS, ACTION_TYPES.PATCH_OTHER_NON_BIO_FACILITIES_FAILURE],
            data
        }
    };
};

export const otherNonBioFacilitiesById = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.OTHER_NO_BIO_FACILITIES.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.OTHER_NON_BIO_FACILITIES_ID_REQUEST, ACTION_TYPES.OTHER_NON_BIO_FACILITIES_ID_SUCCESS, ACTION_TYPES.OTHER_NON_BIO_FACILITIES_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const listIncomeFromWasteApi = (data) => {
    let pageNo = data?.pageNo;
    let pageSize = data?.pageSize;
    let gapAnalysisId = data?.id;
    return {
        url: API_URL.GAP_ANALYSIS.INCOME_FROM_WASTE.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_INCOME_FROM_WASTE_REQUEST, ACTION_TYPES.LIST_INCOME_FROM_WASTE_SUCCESS, ACTION_TYPES.LIST_INCOME_FROM_WASTE_FAILURE],
            data: { gapAnalysisId, pageNo, pageSize }
        }
    };
};

export const incomeFromWasteByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.INCOME_FROM_WASTE.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SAVE_INCOME_FROM_WASTE_BY_ID_REQUEST, ACTION_TYPES.SAVE_INCOME_FROM_WASTE_BY_ID_SUCCESS, ACTION_TYPES.SAVE_INCOME_FROM_WASTE_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const WasteProcessedHouseHoldById = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.WASTE_PROCESSED_HOUSE_HOLD.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.WASTE_PROCESSED_HOUSE_HOLD_BY_ID_REQUEST, ACTION_TYPES.WASTE_PROCESSED_HOUSE_HOLD_BY_ID_SUCCESS, ACTION_TYPES.WASTE_PROCESSED_HOUSE_HOLD_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const patchWasteProcessedHouseHold = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.WASTE_PROCESSED_HOUSE_HOLD.UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_WASTE_PROCESSED_HOUSE_HOLD_REQUEST, ACTION_TYPES.PATCH_WASTE_PROCESSED_HOUSE_HOLD_SUCCESS, ACTION_TYPES.PATCH_WASTE_PROCESSED_HOUSE_HOLD_FAILURE],
            data
        }
    };
};
export const patchIncomeFromWaste = (data) => ({
    url: API_URL.GAP_ANALYSIS.INCOME_FROM_WASTE.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_INCOME_FROM_WASTE_REQUEST, ACTION_TYPES.UPDATE_INCOME_FROM_WASTE_SUCCESS, ACTION_TYPES.UPDATE_INCOME_FROM_WASTE_FAILURE],
        data
    }
});

export const postIncomeFromWaste = (data) => ({
    url: API_URL.GAP_ANALYSIS.INCOME_FROM_WASTE.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_INCOME_FROM_WASTE_REQUEST, ACTION_TYPES.SAVE_INCOME_FROM_WASTE_SUCCESS, ACTION_TYPES.SAVE_INCOME_FROM_WASTE_FAILURE],
        data
    }
});

export const wasteProcessedInstitutionsById = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.WASTE_PROCESSED_INSTITUTIONS.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.WASTE_PROCESSED_INSTITUTIONS_BY_ID_REQUEST, ACTION_TYPES.WASTE_PROCESSED_INSTITUTIONS_BY_ID_SUCCESS, ACTION_TYPES.WASTE_PROCESSED_INSTITUTIONS_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const patchWasteProcessedInstitutions = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.WASTE_PROCESSED_INSTITUTIONS.UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_WASTE_PROCESSED_INSTITUTIONS_REQUEST, ACTION_TYPES.PATCH_WASTE_PROCESSED_INSTITUTIONS_SUCCESS, ACTION_TYPES.PATCH_WASTE_PROCESSED_INSTITUTIONS_FAILURE],
            data
        }
    };
};


export const postBDWCommunityLevel = (data) => ({
    url: API_URL.GAP_ANALYSIS.BDW.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.POST_BDW_COMMUNITY_ULB_LEVEL_LIST_REQUEST, ACTION_TYPES.POST_BDW_COMMUNITY_ULB_LEVEL_LIST_SUCCESS, ACTION_TYPES.POST_BDW_COMMUNITY_ULB_LEVEL_LIST_FAILURE],
        data
    }
});

export const bdwCommunityLevelListAll = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.BDW.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_ALL_REQUEST, ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_ALL_SUCCESS, ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_ALL_FAILURE],
            data: { gapAnalysisId: data }
        }
    };
};

export const bdwCommunityLevelById = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.BDW.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID_REQUEST, ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID_SUCCESS, ACTION_TYPES.BDW_COMMUNITY_ULB_LEVEL_LIST_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const patchBDWCommunityLevel = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.BDW.UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_REQUEST, ACTION_TYPES.PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_SUCCESS, ACTION_TYPES.PATCH_BDW_COMMUNITY_ULB_LEVEL_LIST_FAILURE],
            data
        }
    };
};
export const postBdwProcessedPerDay = (data) => ({
    url: API_URL.GAP_ANALYSIS.BDW_PROCESSED_PER_DAY.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_REQUEST, ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_SUCCESS, ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_FAILURE],
        data
    }
});

export const bdwProcessedPerDayByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.BDW_PROCESSED_PER_DAY.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_BY_ID_REQUEST, ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_BY_ID_SUCCESS, ACTION_TYPES.SAVE_BDW_PROCESSED_PER_DAY_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const patchBdwProcessedPerDay = (data) => ({
    url: API_URL.GAP_ANALYSIS.BDW_PROCESSED_PER_DAY.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_BDW_PROCESSED_PER_DAY_REQUEST, ACTION_TYPES.UPDATE_BDW_PROCESSED_PER_DAY_SUCCESS, ACTION_TYPES.UPDATE_BDW_PROCESSED_PER_DAY_FAILURE],
        data
    }
});

export const listBdwProcessedPerDayApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.BDW_PROCESSED_PER_DAY.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_BDW_PROCESSED_PER_DAY_REQUEST, ACTION_TYPES.LIST_BDW_PROCESSED_PER_DAY_SUCCESS, ACTION_TYPES.LIST_BDW_PROCESSED_PER_DAY_FAILURE],
        data
    }
});

export const postPopulation = (data) => ({
    url: API_URL.GAP_ANALYSIS.POPULATION.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_POPULATION_REQUEST, ACTION_TYPES.SAVE_POPULATION_SUCCESS, ACTION_TYPES.SAVE_POPULATION_FAILURE],
        data
    }
});

export const populationByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.POPULATION.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SAVE_POPULATION_BY_ID_REQUEST, ACTION_TYPES.SAVE_POPULATION_BY_ID_SUCCESS, ACTION_TYPES.SAVE_POPULATION_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const patchPopulation = (data) => ({
    url: API_URL.GAP_ANALYSIS.POPULATION.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_POPULATION_REQUEST, ACTION_TYPES.UPDATE_POPULATION_SUCCESS, ACTION_TYPES.UPDATE_POPULATION_FAILURE],
        data
    }
});

export const listPopulationApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.POPULATION.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_POPULATION_REQUEST, ACTION_TYPES.LIST_POPULATION_SUCCESS, ACTION_TYPES.LIST_POPULATION_FAILURE],
        data
    }
});

export const postBdwCommunityLevel = (data) => ({
    url: API_URL.GAP_ANALYSIS.BDW_COMMUNITY_LEVEL.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_REQUEST, ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_SUCCESS, ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_FAILURE],
        data
    }
});

export const submitNonBioFacilitiesApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.NON_BIO_FACILITIES.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SUBMIT_NON_BIO_FACILITIES_REQUEST, ACTION_TYPES.SUBMIT_NON_BIO_FACILITIES_SUCCESS, ACTION_TYPES.SUBMIT_NON_BIO_FACILITIES_FAILURE],
        data
    }
});

export const bdwCommunityLevelByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.BDW_COMMUNITY_LEVEL.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_BY_ID_REQUEST, ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_BY_ID_SUCCESS, ACTION_TYPES.SAVE_BDW_COMMUNITY_LEVEL_BY_ID_FAILURE],
            data: { gapAnalysisId: data }
        }
    };
};

export const patchBdwCommunityLevel = (data) => ({
    url: API_URL.GAP_ANALYSIS.BDW_COMMUNITY_LEVEL.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_BDW_COMMUNITY_LEVEL_REQUEST, ACTION_TYPES.UPDATE_BDW_COMMUNITY_LEVEL_SUCCESS, ACTION_TYPES.UPDATE_BDW_COMMUNITY_LEVEL_FAILURE],
        data
    }
});

export const listBdwCommunityLevelApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.BDW_COMMUNITY_LEVEL.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_BDW_COMMUNITY_LEVEL_REQUEST, ACTION_TYPES.LIST_BDW_COMMUNITY_LEVEL_SUCCESS, ACTION_TYPES.LIST_BDW_COMMUNITY_LEVEL_FAILURE],
        data
    }
});

export const listTypeOfNonBioFacilitiesApi = () => ({
    url: API_URL.GAP_ANALYSIS.NON_BIO_FACILITIES.NON_BIO_FACILITIES_TYPE,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_NON_BIO_FACILITIES_TYPE_REQUEST, ACTION_TYPES.LIST_NON_BIO_FACILITIES_TYPE_SUCCESS, ACTION_TYPES.LIST_NON_BIO_FACILITIES_TYPE_FAILURE],
        data: { dropDown: true }
    }
});

export const postNonBioDegradable = (data) => ({
    url: API_URL.GAP_ANALYSIS.NON_BIO_DEGRADABLE.SAVE,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_REQUEST, ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_SUCCESS, ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_FAILURE],
        data
    }
});

export const nonBioDegradableByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.NON_BIO_DEGRADABLE.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_BY_ID_REQUEST, ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_BY_ID_SUCCESS, ACTION_TYPES.SAVE_NON_BIO_DEGRADABLE_BY_ID_FAILURE],
            data: { gapAnalysisId: data }
        }
    };
};

export const patchNonBioDegradable = (data) => ({
    url: API_URL.GAP_ANALYSIS.NON_BIO_DEGRADABLE.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_REQUEST, ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_SUCCESS, ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_FAILURE],
        data
    }
});

export const listNonBioDegradableApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.NON_BIO_DEGRADABLE.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_NON_BIO_DEGRADABLE_REQUEST, ACTION_TYPES.LIST_NON_BIO_DEGRADABLE_SUCCESS, ACTION_TYPES.LIST_NON_BIO_DEGRADABLE_FAILURE],
        data
    }
});

export const updateBioDegradableApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.BIO_DEGRADABLE.UPDATE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_BIO_DEGRADABLE_REQUEST, ACTION_TYPES.UPDATE_BIO_DEGRADABLE_SUCCESS, ACTION_TYPES.UPDATE_BIO_DEGRADABLE_FAILURE],
        data
    }
});

export const bioDegradableByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.BIO_DEGRADABLE.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.BIO_DEGRADABLE_BY_ID_REQUEST, ACTION_TYPES.BIO_DEGRADABLE_BY_ID_SUCCESS, ACTION_TYPES.BIO_DEGRADABLE_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const updateNonBioDegradableApi = (data) => ({
    url: API_URL.GAP_ANALYSIS.NON_BIO_DEGRADABLE_LIST.SAVE,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_LIST_REQUEST, ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_LIST_SUCCESS, ACTION_TYPES.UPDATE_NON_BIO_DEGRADABLE_LIST_FAILURE],
        data
    }
});

export const nonBioDegradableListByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.NON_BIO_DEGRADABLE_LIST.BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.NON_BIO_DEGRADABLE_BY_ID_REQUEST, ACTION_TYPES.NON_BIO_DEGRADABLE_BY_ID_SUCCESS, ACTION_TYPES.NON_BIO_DEGRADABLE_BY_ID_FAILURE],
            data: { id: data }
        }
    };
};

export const fetchTotalNonBioFacilitiesApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.NON_BIO_FACILITIES.TOTAL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TOTAL_NON_BIO_FACILITIES_REQUEST, ACTION_TYPES.FETCH_TOTAL_NON_BIO_FACILITIES_SUCCESS, ACTION_TYPES.FETCH_TOTAL_NON_BIO_FACILITIES_FAILURE],
            data: { id: data }
        }
    };
};

export const fetchTotalHouseHoldApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.WASTE_GENERATORS.TOTAL_HOUSE_HOLD,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD_REQUEST, ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD_SUCCESS, ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_HOUSE_HOLD_FAILURE],
            data: { id: data }
        }
    };
};

export const fetchTotalInstitutionApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.WASTE_GENERATORS.TOTAL_INSTITUTIONS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS_REQUEST, ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS_SUCCESS, ACTION_TYPES.FETCH_TOTAL_WASTE_GENERATORS_INSTITUTIONS_FAILURE],
            data: { id: data }
        }
    };
};

export const fetchCurrentStatusApi = () => {
    return {
        url: API_URL.GAP_ANALYSIS.NON_BIO_FACILITIES.CURRENT_STATUS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_CURRENT_STATUS_REQUEST, ACTION_TYPES.FETCH_CURRENT_STATUS_SUCCESS, ACTION_TYPES.FETCH_CURRENT_STATUS_FAILURE],
            data: { dropDown: true }
        }

    };
};

export const fetchOwnershipList = () => {
    return {
        url: API_URL.GAP_ANALYSIS.NON_BIO_FACILITIES.OWNERSHIP_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_OWNERSHIP_LIST_REQUEST, ACTION_TYPES.FETCH_OWNERSHIP_LIST_SUCCESS, ACTION_TYPES.FETCH_OWNERSHIP_LIST_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const bioFacilitiesDropdownApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.BIO_FACILITIES.TYPES_DROP_DOWN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_BIO_FACILITIES_DROPDOWN_REQUEST, ACTION_TYPES.GET_BIO_FACILITIES_DROPDOWN_SUCCESS, ACTION_TYPES.GET_BIO_FACILITIES_DROPDOWN_FAILURE], data
        }
    };
};

export const fetchWardWisePopulationListAll = (data) => {
    let pageNo = data?.pageNo;
    let pageSize = data?.pageSize;
    let gapAnalysisId = data?.id;
    return {
        url: API_URL.GAP_ANALYSIS.POPULATION.WARD_WISE_LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_WARD_WISE_POPULATION_REQUEST, ACTION_TYPES.FETCH_WARD_WISE_POPULATION_SUCCESS, ACTION_TYPES.FETCH_WARD_WISE_POPULATION_FAILURE],
            data: { gapAnalysisId, pageNo, pageSize }
        }
    };
};

export const postWardWisePopulation = (data) => ({
    url: API_URL.GAP_ANALYSIS.POPULATION.SAVE_WARD_WISE_DATA,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.SAVE_WARD_WISE_POPULATION_REQUEST, ACTION_TYPES.SAVE_WARD_WISE_POPULATION_SUCCESS, ACTION_TYPES.SAVE_WARD_WISE_POPULATION_FAILURE],
        data
    }
});

export const patchWardWisePopulation = (data) => ({
    url: API_URL.GAP_ANALYSIS.POPULATION.PATCH_WARD_WISE_DATA,
    method: REQUEST_METHOD.PATCH,
    payload: {
        types: [ACTION_TYPES.PATCH_WARD_WISE_POPULATION_REQUEST, ACTION_TYPES.PATCH_WARD_WISE_POPULATION_SUCCESS, ACTION_TYPES.PATCH_WARD_WISE_POPULATION_FAILURE],
        data
    }
});

export const wardWisePopulationByIdApi = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.POPULATION.WARD_WISE_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.BY_ID_WARD_WISE_POPULATION_REQUEST, ACTION_TYPES.BY_ID_WARD_WISE_POPULATION_SUCCESS, ACTION_TYPES.BY_ID_WARD_WISE_POPULATION_FAILURE],
            data: { id: data }
        }
    };
};

export const deleteWardWisePopulation = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.POPULATION.DELETE_WARD_WISE_DATA.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_WARD_WISE_POPULATION_REQUEST, ACTION_TYPES.DELETE_WARD_WISE_POPULATION_SUCCESS, ACTION_TYPES.DELETE_WARD_WISE_POPULATION_FAILURE]
        }
    };
};

