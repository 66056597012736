import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
  FETCH_DISTRICT_STATE_DETAILS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_STATE_DETAILS`,
  FETCH_DISTRICT_STATE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_STATE_DETAILS_REQUEST`,
  FETCH_DISTRICT_STATE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_STATE_DETAILS_SUCCESS`,
  FETCH_DISTRICT_STATE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_STATE_DETAILS_FAILURE`,

  FETCH_DISTRICT_DETAILS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DETAILS`,
  FETCH_DISTRICT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DETAILS_REQUEST`,
  FETCH_DISTRICT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DETAILS_SUCCESS`,
  FETCH_DISTRICT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DETAILS_FAILURE`,

  ADD_DISTRICT_DETAILS: `${STATE_REDUCER_KEY}/ADD_DISTRICT_DETAILS`,
  ADD_DISTRICT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/ADD_DISTRICT_DETAILS_REQUEST`,
  ADD_DISTRICT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/ADD_DISTRICT_DETAILS_SUCCESS`,
  ADD_DISTRICT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/ADD_DISTRICT_DETAILS_FAILURE`,

  EDIT_DISTRICT_DETAILS: `${STATE_REDUCER_KEY}/EDIT_DISTRICT_DETAILS`,
  EDIT_DISTRICT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/EDIT_DISTRICT_DETAILS_REQUEST`,
  EDIT_DISTRICT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_DISTRICT_DETAILS_SUCCESS`,
  EDIT_DISTRICT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/EDIT_DISTRICT_DETAILS_FAILURE`,

  DELETE_DISTRICT_DETAILS: `${STATE_REDUCER_KEY}/DELETE_DISTRICT_DETAILS`,
  DELETE_DISTRICT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_DISTRICT_DETAILS_REQUEST`,
  DELETE_DISTRICT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_DISTRICT_DETAILS_SUCCESS`,
  DELETE_DISTRICT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_DISTRICT_DETAILS_FAILURE`,

  FETCH_NAME_LIST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST`,
  FETCH_NAME_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_REQUEST`,
  FETCH_NAME_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_SUCCESS`,
  FETCH_NAME_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_NAME_LIST_FAILURE`,

  FETCH_CODE_LIST: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST`,
  FETCH_CODE_LIST_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_REQUEST`,
  FETCH_CODE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_SUCCESS`,
  FETCH_CODE_LIST_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CODE_LIST_FAILURE`
};

export const getStateDetails = createAction(
  ACTION_TYPES.FETCH_DISTRICT_STATE_DETAILS
);
export const getDistrictDetails = createAction(
  ACTION_TYPES.FETCH_DISTRICT_DETAILS
);

export const addDistrictDetails = createAction(
  ACTION_TYPES.ADD_DISTRICT_DETAILS
);
export const editDistrictDetails = createAction(
  ACTION_TYPES.EDIT_DISTRICT_DETAILS);
export const deleteDistrictDetails = createAction(
  ACTION_TYPES.DELETE_DISTRICT_DETAILS);
export const getNameList = createAction(ACTION_TYPES.FETCH_NAME_LIST);

export const getCodeList = createAction(ACTION_TYPES.FETCH_CODE_LIST);

