import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    FETCH_SOCIAL_SAFEGUARD_TABLE_DATA: `${STATE_REDUCER_KEY}/FETCH_SOCIAL_SAFEGUARD_TABLE_DATA`,
    FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_REQUEST`,
    FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_SUCCESS`,
    FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_FAILURE`,

    GET_DISTRICT_DROP_DOWN: `${STATE_REDUCER_KEY}/GET_DISTRICT_DROP_DOWN`,
    GET_DISTRICT_DROP_DOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_DISTRICT_DROP_DOWN_REQUEST`,
    GET_DISTRICT_DROP_DOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_DISTRICT_DROP_DOWN_SUCCESS`,
    GET_DISTRICT_DROP_DOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_DISTRICT_DROP_DOWN_FAILURE`,

    GET_ULB_DROP_DOWN: `${STATE_REDUCER_KEY}/GET_ULB_DROP_DOWN`,
    GET_ULB_DROP_DOWN_REQUEST: `${STATE_REDUCER_KEY}/GET_ULB_DROP_DOWN_REQUEST`,
    GET_ULB_DROP_DOWN_SUCCESS: `${STATE_REDUCER_KEY}/GET_ULB_DROP_DOWN_SUCCESS`,
    GET_ULB_DROP_DOWN_FAILURE: `${STATE_REDUCER_KEY}/GET_ULB_DROP_DOWN_FAILURE`,

    POST_BASE_LINE_DATA: `${STATE_REDUCER_KEY}/POST_BASE_LINE_DATA`,
    POST_BASE_LINE_DATA_REQUEST: `${STATE_REDUCER_KEY}/POST_BASE_LINE_DATA_REQUEST`,
    POST_BASE_LINE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/POST_BASE_LINE_DATA_SUCCESS`,
    POST_BASE_LINE_DATA_FAILURE: `${STATE_REDUCER_KEY}/POST_BASE_LINE_DATA_FAILURE`,

    PATCH_BASE_LINE_DATA: `${STATE_REDUCER_KEY}/PATCH_BASE_LINE_DATA`,
    PATCH_BASE_LINE_DATA_REQUEST: `${STATE_REDUCER_KEY}/PATCH_BASE_LINE_DATA_REQUEST`,
    PATCH_BASE_LINE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_BASE_LINE_DATA_SUCCESS`,
    PATCH_BASE_LINE_DATA_FAILURE: `${STATE_REDUCER_KEY}/PATCH_BASE_LINE_DATA_FAILURE`,

    GET_BASE_LINE_DATA: `${STATE_REDUCER_KEY}/GET_BASE_LINE_DATA`,
    GET_BASE_LINE_DATA_REQUEST: `${STATE_REDUCER_KEY}/GET_BASE_LINE_DATA_REQUEST`,
    GET_BASE_LINE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/GET_BASE_LINE_DATA_SUCCESS`,
    GET_BASE_LINE_DATA_FAILURE: `${STATE_REDUCER_KEY}/GET_BASE_LINE_DATA_FAILURE`,

    POST_SOCIAL_SAFEGUARD_DETAILS: `${STATE_REDUCER_KEY}/POST_SOCIAL_SAFEGUARD_DETAILS`,
    POST_SOCIAL_SAFEGUARD_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_SOCIAL_SAFEGUARD_DETAILS_REQUEST`,
    POST_SOCIAL_SAFEGUARD_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_SOCIAL_SAFEGUARD_DETAILS_SUCCESS`,
    POST_SOCIAL_SAFEGUARD_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_SOCIAL_SAFEGUARD_DETAILS_FAILURE`,

    GET_BASELINE_DATA_ULB_DETAILS: `${STATE_REDUCER_KEY}/GET_BASELINE_DATA_ULB_DETAILS`,
    GET_BASELINE_DATA_ULB_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/GET_BASELINE_DATA_ULB_DETAILS_REQUEST`,
    GET_BASELINE_DATA_ULB_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/GET_BASELINE_DATA_ULB_DETAILS_SUCCESS`,
    GET_BASELINE_DATA_ULB_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/GET_BASELINE_DATA_ULB_DETAILS_FAILURE`,

    GET_BASELINE_CONTENTS_DETAILS: `${STATE_REDUCER_KEY}/GET_BASELINE_CONTENTS_DETAILS`,
    GET_BASELINE_CONTENTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/GET_BASELINE_CONTENTS_DETAILS_REQUEST`,
    GET_BASELINE_CONTENTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/GET_BASELINE_CONTENTS_DETAILS_SUCCESS`,
    GET_BASELINE_CONTENTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/GET_BASELINE_CONTENTS_DETAILS_FAILURE`,

    GET_SCHEME_CYCLIC_DETAILS: `${STATE_REDUCER_KEY}/GET_SCHEME_CYCLIC_DETAILS`,
    GET_SCHEME_CYCLIC_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/GET_SCHEME_CYCLIC_DETAILS_REQUEST`,
    GET_SCHEME_CYCLIC_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/GET_SCHEME_CYCLIC_DETAILS_SUCCESS`,
    GET_SCHEME_CYCLIC_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/GET_SCHEME_CYCLIC_DETAILS_FAILURE`,

    POST_SCHEME_CYCLIC_DATA: `${STATE_REDUCER_KEY}/POST_SCHEME_CYCLIC_DATA`,
    POST_SCHEME_CYCLIC_DATA_REQUEST: `${STATE_REDUCER_KEY}/POST_SCHEME_CYCLIC_DATA_REQUEST`,
    POST_SCHEME_CYCLIC_DATA_SUCCESS: `${STATE_REDUCER_KEY}/POST_SCHEME_CYCLIC_DATA_SUCCESS`,
    POST_SCHEME_CYCLIC_DATA_FAILURE: `${STATE_REDUCER_KEY}/POST_SCHEME_CYCLIC_DATA_FAILURE`,

    PATCH_SCHEME_CYCLIC_DATA: `${STATE_REDUCER_KEY}/PATCH_SCHEME_CYCLIC_DATA`,
    PATCH_SCHEME_CYCLIC_DATA_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SCHEME_CYCLIC_DATA_REQUEST`,
    PATCH_SCHEME_CYCLIC_DATA_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SCHEME_CYCLIC_DATA_SUCCESS`,
    PATCH_SCHEME_CYCLIC_DATA_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SCHEME_CYCLIC_DATA_FAILURE`,

    GET_SAFEGUARD_COMPLAINTS_DETAILS: `${STATE_REDUCER_KEY}/GET_SAFEGUARD_COMPLAINTS_DETAILS`,
    GET_SAFEGUARD_COMPLAINTS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/GET_SAFEGUARD_COMPLAINTS_DETAILS_REQUEST`,
    GET_SAFEGUARD_COMPLAINTS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/GET_SAFEGUARD_COMPLAINTS_DETAILS_SUCCESS`,
    GET_SAFEGUARD_COMPLAINTS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/GET_SAFEGUARD_COMPLAINTS_DETAILS_FAILURE`,

    POST_SAFEGUARD_COMPLAINTS_DATA: `${STATE_REDUCER_KEY}/POST_SAFEGUARD_COMPLAINTS_DATA`,
    POST_SAFEGUARD_COMPLAINTS_DATA_REQUEST: `${STATE_REDUCER_KEY}/POST_SAFEGUARD_COMPLAINTS_DATA_REQUEST`,
    POST_SAFEGUARD_COMPLAINTS_DATA_SUCCESS: `${STATE_REDUCER_KEY}/POST_SAFEGUARD_COMPLAINTS_DATA_SUCCESS`,
    POST_SAFEGUARD_COMPLAINTS_DATA_FAILURE: `${STATE_REDUCER_KEY}/POST_SAFEGUARD_COMPLAINTS_DATA_FAILURE`,

    PATCH_SAFEGUARD_COMPLAINTS_DATA: `${STATE_REDUCER_KEY}/PATCH_SAFEGUARD_COMPLAINTS_DATA`,
    PATCH_SAFEGUARD_COMPLAINTS_DATA_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SAFEGUARD_COMPLAINTS_DATA_REQUEST`,
    PATCH_SAFEGUARD_COMPLAINTS_DATA_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SAFEGUARD_COMPLAINTS_DATA_SUCCESS`,
    PATCH_SAFEGUARD_COMPLAINTS_DATA_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SAFEGUARD_COMPLAINTS_DATA_FAILURE`,

    DOWNLOAD_SOCIAL_SAFEGUARD_LIST: `${STATE_REDUCER_KEY}/DOWNLOAD_SOCIAL_SAFEGUARD_LIST`,
    DOWNLOAD_SOCIAL_SAFEGUARD_LIST_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_SOCIAL_SAFEGUARD_LIST_REQUEST`,
    DOWNLOAD_SOCIAL_SAFEGUARD_LIST_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_SOCIAL_SAFEGUARD_LIST_SUCCESS`,
    DOWNLOAD_SOCIAL_SAFEGUARD_LIST_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_SOCIAL_SAFEGUARD_LIST_FAILURE`
};


export const socialSafeguardTableData = createAction(ACTION_TYPES.FETCH_SOCIAL_SAFEGUARD_TABLE_DATA);
export const districtDropdown = createAction(ACTION_TYPES.GET_DISTRICT_DROP_DOWN);
export const ulbDropdown = createAction(ACTION_TYPES.GET_ULB_DROP_DOWN);
export const postBaseLineData = createAction(ACTION_TYPES.POST_BASE_LINE_DATA);
export const patchBaseLineData = createAction(ACTION_TYPES.PATCH_BASE_LINE_DATA);
export const getBaseLineData = createAction(ACTION_TYPES.GET_BASE_LINE_DATA);
export const postSocialSafeguard = createAction(ACTION_TYPES.POST_SOCIAL_SAFEGUARD_DETAILS);
export const getBaseLineDataUlbDetails = createAction(ACTION_TYPES.GET_BASELINE_DATA_ULB_DETAILS);
export const getBaseLineContent = createAction(ACTION_TYPES.GET_BASELINE_CONTENTS_DETAILS);
export const getSchemeCyclic = createAction(ACTION_TYPES.GET_SCHEME_CYCLIC_DETAILS);
export const postSchemeCyclicData = createAction(ACTION_TYPES.POST_SCHEME_CYCLIC_DATA);
export const patchSchemeCyclicData = createAction(ACTION_TYPES.PATCH_SCHEME_CYCLIC_DATA);
export const getSafeguardComplaints = createAction(ACTION_TYPES.GET_SAFEGUARD_COMPLAINTS_DETAILS);
export const postSafeguardComplaints = createAction(ACTION_TYPES.POST_SAFEGUARD_COMPLAINTS_DATA);
export const patchSafeguardComplaints = createAction(ACTION_TYPES.PATCH_SAFEGUARD_COMPLAINTS_DATA);
export const downloadSocialSafeguardList = createAction(ACTION_TYPES.DOWNLOAD_SOCIAL_SAFEGUARD_LIST);

