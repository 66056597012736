import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchPrimaryCollectionBdwApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_BDW.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_PRIMARY_COLLECTION_BDW_REQUEST, ACTION_TYPES.FETCH_PRIMARY_COLLECTION_BDW_SUCCESS, ACTION_TYPES.FETCH_PRIMARY_COLLECTION_BDW_FAILURE], data
        }
    };
};

export const postPrimaryCollectionBdwApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_BDW.POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_PRIMARY_COLLECTION_BDW_REQUEST, ACTION_TYPES.POST_PRIMARY_COLLECTION_BDW_SUCCESS, ACTION_TYPES.POST_PRIMARY_COLLECTION_BDW_FAILURE], data
        }
    };
};

export const patchPrimaryCollectionBdwApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_BDW.POST,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_PRIMARY_COLLECTION_BDW_REQUEST, ACTION_TYPES.PATCH_PRIMARY_COLLECTION_BDW_SUCCESS, ACTION_TYPES.PATCH_PRIMARY_COLLECTION_BDW_FAILURE], data
        }
    };
};

export const primaryCollectionBdwByIdApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_BDW.BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_PRIMARY_COLLECTION_BDW_BY_ID_REQUEST, ACTION_TYPES.GET_PRIMARY_COLLECTION_BDW_BY_ID_SUCCESS, ACTION_TYPES.GET_PRIMARY_COLLECTION_BDW_BY_ID_FAILURE]
        }
    };
};

export const deletePrimaryCollectionBdwApi = (data) => {
    return {
        url: API_URL.PRIMARY_COLLECTION_BDW.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_PRIMARY_COLLECTION_BDW_REQUEST, ACTION_TYPES.DELETE_PRIMARY_COLLECTION_BDW_SUCCESS, ACTION_TYPES.DELETE_PRIMARY_COLLECTION_BDW_FAILURE]
        }
    };
};
