import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchDistricts = () => {
    return {
        url: API_URL.COMMON.DISTRICT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DISTRICT_LIST_REQUEST, ACTION_TYPES.FETCH_DISTRICT_LIST_SUCCESS, ACTION_TYPES.FETCH_DISTRICT_LIST_FAILURE]
        }
    };
};

export const fetchWards = (districtId, lsgiTypeId) => {
    return {
        url: API_URL.COMMON.ULB,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ULB_DETAILS_REQUEST, ACTION_TYPES.FETCH_ULB_DETAILS_SUCCESS, ACTION_TYPES.FETCH_ULB_DETAILS_FAILURE],
            data: { districtId, lsgiTypeId }
        }
    };
};

export const addULBPopulation = (data) => {
    return {
        url: API_URL.GRAND_ALLOCATION.ULB_CREATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SUBMIT_ULB_POPULATION_REQUEST, ACTION_TYPES.SUBMIT_ULB_POPULATION_SUCCESS, ACTION_TYPES.SUBMIT_ULB_POPULATION_FAILURE],
            data
        }
    };
};

export const fetchULBPopulation = (data) => {
    return {
        url: API_URL.GRAND_ALLOCATION.ULB_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ULB_POPULATION_REQUEST, ACTION_TYPES.FETCH_ULB_POPULATION_SUCCESS, ACTION_TYPES.FETCH_ULB_POPULATION_FAILURE],
            data
        }
    };
};

export const fetchULBPopulationById = (id) => {
    return {
        url: API_URL.GRAND_ALLOCATION.ULB_BY_ID,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ULB_POPULATION_BY_ID_REQUEST, ACTION_TYPES.FETCH_ULB_POPULATION_BY_ID_SUCCESS, ACTION_TYPES.FETCH_ULB_POPULATION_BY_ID_FAILURE],
            data: { id }
        }
    };
};
export const editULBPopulation = (data) => {
    return {
        url: API_URL.GRAND_ALLOCATION.ULB_UPDATE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_ULB_POPULATION_REQUEST, ACTION_TYPES.UPDATE_ULB_POPULATION_SUCCESS, ACTION_TYPES.UPDATE_ULB_POPULATION_FAILURE],
            data
        }
    };
};

export const deleteULBPopulation = (id) => {
    return {
        url: API_URL.GRAND_ALLOCATION.ULB_DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_ULB_POPULATION_REQUEST, ACTION_TYPES.DELETE_ULB_POPULATION_SUCCESS, ACTION_TYPES.DELETE_ULB_POPULATION_FAILURE]
        }
    };
};

export const searchULBApi = (data) => {
    return {
        url: API_URL.COMMON.ULB_FILTER,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ULB_SEARCH_REQUEST, ACTION_TYPES.FETCH_ULB_SEARCH_SUCCESS, ACTION_TYPES.FETCH_ULB_SEARCH_FAILURE],
            data
        }
    };
};

export const computeGACCeilingApi = (data) => {
    return {
        url: API_URL.GRAND_ALLOCATION.GAC_COMPUTE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.COMPUTE_GAC_CEILING_REQUEST, ACTION_TYPES.COMPUTE_GAC_CEILING_SUCCESS, ACTION_TYPES.COMPUTE_GAC_CEILING_FAILURE],
            data
        }
    };
};

export const fetchGACComputation = (data) => {
    return {
        url: API_URL.GRAND_ALLOCATION.GAC_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GAC_COMPUTATION_REQUEST, ACTION_TYPES.FETCH_GAC_COMPUTATION_SUCCESS, ACTION_TYPES.FETCH_GAC_COMPUTATION_FAILURE],
            data
        }
    };
};

export const fetchTotalPopulationApi = (data) => {
    return {
        url: API_URL.GRAND_ALLOCATION.TOTAL_POPULATION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TOTAL_POPULATION_REQUEST, ACTION_TYPES.FETCH_TOTAL_POPULATION_SUCCESS, ACTION_TYPES.FETCH_TOTAL_POPULATION_FAILURE],
            data
        }
    };
};

export const fetchCensusYearApi = (data) => {
    return {
        url: API_URL.GRAND_ALLOCATION.CENSUS_YEAR,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_CENSUS_YEAR_REQUEST, ACTION_TYPES.FETCH_CENSUS_YEAR_SUCCESS, ACTION_TYPES.FETCH_CENSUS_YEAR_FAILURE],
            data
        }
    };
};

export const fetchTotalFundApi = (data) => {
    return {
        url: API_URL.GRAND_ALLOCATION.FUND_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TOTAL_FUND_REQUEST, ACTION_TYPES.FETCH_TOTAL_FUND_SUCCESS, ACTION_TYPES.FETCH_TOTAL_FUND_FAILURE],
            data
        }
    };
};

export const totalFundDropDownApi = (data) => {
    return {
        url: API_URL.GRAND_ALLOCATION.FUND_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TOTAL_FUND_DROPDOWN_REQUEST, ACTION_TYPES.FETCH_TOTAL_FUND_DROPDOWN_SUCCESS, ACTION_TYPES.FETCH_TOTAL_FUND_DROPDOWN_FAILURE],
            data: { ...data, dropdown: true }
        }
    };
};

export const updateTotalFundApi = (data) => {
    return {
        url: API_URL.GRAND_ALLOCATION.FUND_UPDATE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.UPDATE_TOTAL_FUND_REQUEST, ACTION_TYPES.UPDATE_TOTAL_FUND_SUCCESS, ACTION_TYPES.UPDATE_TOTAL_FUND_FAILURE],
            data
        }
    };
};
export const totalFundById = (id) => {
    return {
        url: API_URL.GRAND_ALLOCATION.FUND_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TOTAL_FUND_BY_ID_REQUEST, ACTION_TYPES.FETCH_TOTAL_FUND_BY_ID_SUCCESS, ACTION_TYPES.FETCH_TOTAL_FUND_BY_ID_FAILURE],
            data: { id }
        }
    };
};
