import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const listPopulationVsLandArea = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.POPULATION_VS_LAND_AREA,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_POPULATION_VS_LAND_AREA_REPORT_REQUEST, ACTION_TYPES.LIST_POPULATION_VS_LAND_AREA_REPORT_SUCCESS, ACTION_TYPES.LIST_POPULATION_VS_LAND_AREA_REPORT_FAILURE],
            data
        }
    };
};

export const listPopulationVsHouseHold = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.POPULATION_VS_HOUSE_HOLD,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_POPULATION_VS_HOUSE_HOLD_REPORT_REQUEST, ACTION_TYPES.LIST_POPULATION_VS_HOUSE_HOLD_REPORT_SUCCESS, ACTION_TYPES.LIST_POPULATION_VS_HOUSE_HOLD_REPORT_FAILURE],
            data
        }
    };
};

export const listLandUse = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.LAND_USE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_LAND_USE_REQUEST, ACTION_TYPES.LIST_LAND_USE_SUCCESS, ACTION_TYPES.LIST_LAND_USE_FAILURE],
            data
        }
    };
};

export const listNbdwEquipmentsReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.NBDW_EQUIPMENTS_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_NBDW_EQUIPMENTS_REPORT_REQUEST, ACTION_TYPES.LIST_NBDW_EQUIPMENTS_REPORT_SUCCESS, ACTION_TYPES.LIST_NBDW_EQUIPMENTS_REPORT_FAILURE],
            data
        }
    };
};

export const listBdwEquipmentsReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.BDW_EQUIPMENTS_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_BDW_EQUIPMENTS_REPORT_REQUEST, ACTION_TYPES.LIST_BDW_EQUIPMENTS_REPORT_SUCCESS, ACTION_TYPES.LIST_BDW_EQUIPMENTS_REPORT_FAILURE],
            data
        }
    };
};

export const listNbdwWasteGeneratedVsCapacityReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.NBDW_GENERATED_VS_CAPACITY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_NBDW_GENERATED_VS_CAPACITY_REPORT_REQUEST, ACTION_TYPES.LIST_NBDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS, ACTION_TYPES.LIST_NBDW_GENERATED_VS_CAPACITY_REPORT_FAILURE],
            data
        }
    };
};

export const listBdwWasteGeneratedVsCapacityReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.BDW_GENERATED_VS_CAPACITY,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_BDW_GENERATED_VS_CAPACITY_REPORT_REQUEST, ACTION_TYPES.LIST_BDW_GENERATED_VS_CAPACITY_REPORT_SUCCESS, ACTION_TYPES.LIST_BDW_GENERATED_VS_CAPACITY_REPORT_FAILURE],
            data
        }
    };
};

export const listNbdwFacilityReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.NBDW_FACILITY_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_NBDW_FACILITIES_REPORT_REQUEST, ACTION_TYPES.LIST_NBDW_FACILITIES_REPORT_SUCCESS, ACTION_TYPES.LIST_NBDW_FACILITIES_REPORT_FAILURE],
            data
        }
    };
};

export const listBdwFacilityReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.BDW_FACILITY_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_BDW_FACILITIES_REPORT_REQUEST, ACTION_TYPES.LIST_BDW_FACILITIES_REPORT_SUCCESS, ACTION_TYPES.LIST_BDW_FACILITIES_REPORT_FAILURE],
            data
        }
    };
};

export const listCapitalCostInNbdwReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.CAPITAL_COST_IN_NBDW_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_CAPITAL_COST_IN_NBDW_REPORT_REQUEST, ACTION_TYPES.LIST_CAPITAL_COST_IN_NBDW_REPORT_SUCCESS, ACTION_TYPES.LIST_CAPITAL_COST_IN_NBDW_REPORT_FAILURE],
            data
        }
    };
};

export const listCapitalCostInBdwReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.CAPITAL_COST_IN_BDW_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_CAPITAL_COST_IN_BDW_REPORT_REQUEST, ACTION_TYPES.LIST_CAPITAL_COST_IN_BDW_REPORT_SUCCESS, ACTION_TYPES.LIST_CAPITAL_COST_IN_BDW_REPORT_FAILURE],
            data
        }
    };
};

export const listForwardLinkingReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.FORWARD_LINKING_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_FORWARD_LINKING_REPORT_REQUEST, ACTION_TYPES.LIST_FORWARD_LINKING_REPORT_SUCCESS, ACTION_TYPES.LIST_FORWARD_LINKING_REPORT_FAILURE],
            data
        }
    };
};

export const listIncomeFromBdwAndNbdwReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.INCOME_FROM_BDW_AND_NBDW_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_INCOME_FROM_BDW_AND_NBDW_REPORT_REQUEST, ACTION_TYPES.LIST_INCOME_FROM_BDW_AND_NBDW_REPORT_SUCCESS, ACTION_TYPES.LIST_INCOME_FROM_BDW_AND_NBDW_REPORT_FAILURE],
            data
        }
    };
};

export const listExpenditureReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.EXPENDITURE_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_EXPENDITURE_REPORT_REQUEST, ACTION_TYPES.LIST_EXPENDITURE_REPORT_SUCCESS, ACTION_TYPES.LIST_EXPENDITURE_REPORT_FAILURE],
            data
        }
    };
};

export const listIncomeForHksFromBdwAndNbdwReport = (data) => {
    return {
        url: API_URL.GAP_ANALYSIS.REPORT.INCOME_FOR_HKS_FROM_BDW_AND_NBDW_REPORT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_REQUEST, ACTION_TYPES.LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_SUCCESS, ACTION_TYPES.LIST_INCOME_OF_HKS_FROM_BDW_AND_NBDW_REPORT_FAILURE],
            data
        }
    };
};

export const fetchAnnualPlanReportApi = (data) => {
    return {
        url: API_URL.SWM_PLAN_REPORT.ANNUAL_PLAN.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_ANNUAL_PLAN_REPORT_REQUEST, ACTION_TYPES.FETCH_ANNUAL_PLAN_REPORT_SUCCESS, ACTION_TYPES.FETCH_ANNUAL_PLAN_REPORT_FAILURE], data
        }
    };
};

export const fetchCostInterventionListApi = (data) => {
    return {
        url: API_URL.SWM_PLAN_REPORT.COST_INTERVENTION.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_REQUEST, ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_SUCCESS, ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_FAILURE], data
        }
    };
};

export const fetchStatusSummaryReportApi = (data) => {
    return {
        url: API_URL.SWM_PLAN_REPORT.STATUS_SUMMARY.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_STATUS_SUMMARY_LIST_REQUEST, ACTION_TYPES.FETCH_STATUS_SUMMARY_LIST_SUCCESS, ACTION_TYPES.FETCH_STATUS_SUMMARY_LIST_FAILURE], data
        }
    };
};

export const fetchCostInterventionYearWiseApi = (data) => {
    return {
        url: API_URL.SWM_PLAN_REPORT.COST_INTERVENTION_YEAR_WISE.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_YEAR_WISE_REQUEST, ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_YEAR_WISE_SUCCESS, ACTION_TYPES.FETCH_COST_INTERVENTION_LIST_YEAR_WISE_FAILURE], data
        }
    };
};

export const fetchDetailsOfTeamApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECT_REPORT.DETAILS_OF_TEAM.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DETAILS_OF_TEAM_REPORT_REQUEST, ACTION_TYPES.FETCH_DETAILS_OF_TEAM_REPORT_SUCCESS, ACTION_TYPES.FETCH_DETAILS_OF_TEAM_REPORT_FAILURE], data
        }
    };
};

export const fetchTeamCompositionApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECT_REPORT.TEAM_COMPOSITION.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_TEAM_COMPOSITION_REPORT_REQUEST, ACTION_TYPES.FETCH_TEAM_COMPOSITION_REPORT_SUCCESS, ACTION_TYPES.FETCH_TEAM_COMPOSITION_REPORT_FAILURE], data
        }
    };
};

export const fetchGapAnalysisOfWasteGenerationApi = (data) => {
    return {
        url: API_URL.SWM_SUB_PROJECT_REPORT.GAP_ANALYSIS.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_REQUEST, ACTION_TYPES.FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_SUCCESS, ACTION_TYPES.FETCH_GAP_ANALYSIS_OF_WASTE_GENERATION_REPORT_FAILURE], data
        }
    };
};

