import { TABLE_IDS } from "common/constants";
import _ from "lodash";
import { commonFileDownload } from "modules/common/actions";
import { getTableProps } from "modules/common/selectors";
import { all, call, fork, put, takeLatest, take, select } from "redux-saga/effects";
import { getPayloadContent } from "utils/apiUtils";
import { setCommonTableData } from "utils/tableUtils";
import { handleAPIRequest } from "../../../utils/http";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";
import { complaintHandlerDropdownApi, complaintNumberDropdownApi, fetchComplaintCategory, fetchComplaintListApi, fetchComplaintMode, fetchComplaintType, fetchDistricts, fetchEscalationReportListApi, fetchGrievanceListingApi, fetchGrievanceSummaryApi, fetchGrievanceSummaryUlbApi, fetchGrievanceSummaryWardApi, fetchLSGIList, fetchPendingReportListApi, fetchReopenComplaintListApi, fetchTopGrievanceCategoryListApi, fetchWards, lsgiTypeDropdownApi, priorityDropdownApi, statusDropdownApi } from "./api";
import { actions as sliceActions } from "./slice";

export function* setComplaintStatusDropdown() {
    yield call(handleAPIRequest, statusDropdownApi);
}
export function* complaintTypes() {
    yield call(handleAPIRequest, fetchComplaintType);
}
export function* complaintModes() {
    yield call(handleAPIRequest, fetchComplaintMode);
}
export function* getComplaintCategory() {
    yield call(handleAPIRequest, fetchComplaintCategory);
}
export function* getDistricts(action) {
    yield call(handleAPIRequest, fetchDistricts, action.payload);
}

export function* getWard(action) {
    const { id, districtId } = action.payload;
    yield fork(handleAPIRequest, fetchWards, id);
    const response = yield take([ACTION_TYPES.FETCH_WARD_LIST_SUCCESS, ACTION_TYPES.FETCH_WARD_LIST_FAILURE]);

    if (response.type === ACTION_TYPES.FETCH_WARD_LIST_SUCCESS) {
        const wardListResponse = getPayloadContent(response, []);
        yield put(sliceActions.setWardList({ data: wardListResponse, id, districtId }));
    }
}

export function* getLSGI(action) {
    const { id, districtId } = action.payload;
    yield fork(handleAPIRequest, fetchLSGIList, { id, districtId });

    const response = yield take([ACTION_TYPES.FETCH_LSGI_SUCCESS, ACTION_TYPES.FETCH_LSGI_FAILURE]);

    if (response.type === ACTION_TYPES.FETCH_LSGI_SUCCESS) {
        const lsgiListResponse = getPayloadContent(response, []);
        yield put(sliceActions.setLsgiList({ data: lsgiListResponse, id, districtId }));
    }
}
export function* lsgiTypeSelectDropdown(action) {
    yield call(handleAPIRequest, lsgiTypeDropdownApi, action.payload);
}

export function* getDownloadReports(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    yield put(commonFileDownload({
        url: API_URL.COMPLAINT_REPORT.DOWNLOAD_REPORTS,
        data: { type },
        file: { name: fileName, ext: type }
    }));
}

export function* getDownloadSummaryReports(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { complaintCategory, complaintMode, startDate, endDate } = filters;
    yield put(commonFileDownload({
        url: API_URL.COMPLAINT_REPORT.DOWNLOAD_SUMMARY_REPORTS,
        data: { type, complaintMode, startDate, endDate, categoryId: complaintCategory },
        file: { name: fileName, ext: type }
    }));
}

export function* getDownloadSummaryLsgiReports(action) {
    const { type, districtId } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { complaintCategory, complaintMode, startDate, endDate } = filters;
    if (districtId) {
        yield put(commonFileDownload({
            url: API_URL.COMPLAINT_REPORT.DOWNLOAD_SUMMARY_LSGI_REPORTS,
            data: { type, districtId, categoryId: complaintCategory, complaintMode, startDate, endDate },
            file: { name: fileName, ext: type }
        }));
    } else {
        yield put(commonFileDownload({
            url: API_URL.COMPLAINT_REPORT.DOWNLOAD_SUMMARY_LSGI_REPORTS,
            data: { type, categoryId: complaintCategory, complaintMode, startDate, endDate },
            file: { name: fileName, ext: type }
        }));
    }
}

export function* getDownloadSummaryWardReports(action) {
    const { type, lsgiId } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { complaintCategory, complaintMode, startDate, endDate } = filters;
    if (lsgiId) {
        yield put(commonFileDownload({
            url: API_URL.COMPLAINT_REPORT.DOWNLOAD_SUMMARY_WARD_REPORTS,
            data: { type, lsgiId, categoryId: complaintCategory, complaintMode, startDate, endDate },
            file: { name: fileName, ext: type }
        }));
    } else {
        yield put(commonFileDownload({
            url: API_URL.COMPLAINT_REPORT.DOWNLOAD_SUMMARY_WARD_REPORTS,
            data: { type, lsgiId, categoryId: complaintCategory, complaintMode, startDate, endDate },
            file: { name: fileName, ext: type }
        }));
    }
}

export function* getDownloadListingReports(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { complaintCategory, complaintMode, districtId, lsgiId, startDate, endDate, complaintStatus, wardId } = filters;
    yield put(commonFileDownload({
        url: API_URL.COMPLAINT_REPORT.DOWNLOAD_LISTING_REPORTS,
        data: { type, categoryId: complaintCategory, complaintMode, complaintStatus, startDate, endDate, lsgiId, districtId, wardId },
        file: { name: fileName, ext: type }
    }));
}

export function* getDownloadComplaintList(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { categoryId, complaintMode, districtId, typeId, complaintNo, complaintStatus, startDate, endDate, mobileNos } = filters;
    yield put(commonFileDownload({
        url: API_URL.COMPLAINT_REPORT.DOWNLOAD_COMPLAINT_LIST,
        data: { type, categoryId, complaintMode, districtId, typeId, complaintNo, complaintStatus, startDate, endDate, mobileNos },
        file: { name: fileName, ext: type }
    }));
}

export function* listComplaint({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.COMPLAINT_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchComplaintListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_COMPLAINT_LIST_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.COMPLAINT_REPORT);
}

export function* priorityDropdown() {
    yield call(handleAPIRequest, priorityDropdownApi);
}

export function* listComplaintHandler({ payload: { value, key } = {} }) {
    let newPayload = {};
    _.set(newPayload, `${key}`, value);
    yield fork(handleAPIRequest, complaintHandlerDropdownApi, { key: "firstName", value: value });
    const response = yield take([ACTION_TYPES.SELECT_COMPLAINT_HANDLER_DROPDOWN_SUCCESS, ACTION_TYPES.SELECT_COMPLAINT_HANDLER_DROPDOWN_FAILURE]);
    if (response.type === ACTION_TYPES.SELECT_COMPLAINT_HANDLER_DROPDOWN_SUCCESS) {
        yield put(sliceActions.setJSON({ key, value: getPayloadContent(response) }));
    }
}

export function* listJSONComplaintNumberData({ payload: { value, key } = {} }) {
    let newPayload = {};
    _.set(newPayload, `${key}`, value);
    yield fork(handleAPIRequest, complaintNumberDropdownApi, { key: key, value: value });
    const response = yield take([ACTION_TYPES.SELECT_COMPLAINT_NUMBER_DROPDOWN_SUCCESS, ACTION_TYPES.SELECT_COMPLAINT_NUMBER_DROPDOWN_FAILURE]);
    if (response.type === ACTION_TYPES.SELECT_COMPLAINT_NUMBER_DROPDOWN_SUCCESS) {
        yield put(sliceActions.setJSONNumber({ key, value: getPayloadContent(response) }));
    }
}

export function* listGrievanceSummary({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchGrievanceSummaryApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* listGrievanceSummaryWard({ payload }) {
    let updatedPayload = { selectedLsgiId: payload?.id, pageNo: payload?.pageNo, pageSize: payload?.pageSize };
    yield fork(handleAPIRequest, fetchGrievanceSummaryWardApi, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_WARD_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_WARD_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* listGrievanceReportList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.GRIEVANCE_LISTING]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchGrievanceListingApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GRIEVANCE_REPORT_LIST_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_REPORT_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.GRIEVANCE_LISTING);
}

export function* listEscalationReportList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.ESCALATION_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchEscalationReportListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_ESCALATION_REPORT_SUCCESS, ACTION_TYPES.FETCH_ESCALATION_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.ESCALATION_REPORT);
}

export function* listPendingReportList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.PENDING_REPORT]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchPendingReportListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_PENDING_REPORT_SUCCESS, ACTION_TYPES.FETCH_PENDING_REPORT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.PENDING_REPORT);
}

export function* listReopenedComplaintList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.REOPENED_COMPLAINTS]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchReopenComplaintListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_REOPENED_COMPLAINTS_SUCCESS, ACTION_TYPES.FETCH_REOPENED_COMPLAINTS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.REOPENED_COMPLAINTS);
}

export function* listTopGrievanceCategoryList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.TOP_GRIEVANCE_CATEGORY]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, fetchTopGrievanceCategoryListApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_TOP_GRIEVANCE_CATEGORY_SUCCESS, ACTION_TYPES.FETCH_TOP_GRIEVANCE_CATEGORY_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.TOP_GRIEVANCE_CATEGORY);
}

export function* listGrievanceSummaryUlb({ payload }) {
    let updatedPayload = { selectedDistrictId: payload?.id, pageNo: payload?.pageNo, pageSize: payload?.pageSize };
    yield fork(handleAPIRequest, fetchGrievanceSummaryUlbApi, updatedPayload);
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_ULB_SUCCESS, ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_ULB_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* getDownloadEscalationReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { complaintCategory, complaintMode, districtId, lsgiId, startDate, endDate, complaintStatus, wardId, complaintType } = filters;
    yield put(commonFileDownload({
        url: API_URL.COMPLAINT_REPORT.DOWNLOAD_ESCALATION_REPORT,
        data: { type, categoryId: complaintCategory, complaintMode, complaintStatus, startDate, endDate, lsgiId, districtId, wardId, complaintType },
        file: { name: fileName, ext: type }
    }));
}

export function* getDownloadPendingReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { complaintCategory, complaintMode, districtId, lsgiId, startDate, endDate, complaintStatus, wardId, complaintType } = filters;
    yield put(commonFileDownload({
        url: API_URL.COMPLAINT_REPORT.DOWNLOAD_PENDING_REPORT,
        data: { type, categoryId: complaintCategory, complaintMode, complaintStatus, startDate, endDate, lsgiId, districtId, wardId, complaintType },
        file: { name: fileName, ext: type }
    }));
}

export function* getDownloadReopenReport(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { complaintCategory, complaintMode, districtId, lsgiId, startDate, endDate, complaintStatus, wardId, complaintType } = filters;
    yield put(commonFileDownload({
        url: API_URL.COMPLAINT_REPORT.DOWNLOAD_REOPENED_REPORT,
        data: { type, categoryId: complaintCategory, complaintMode, complaintStatus, startDate, endDate, lsgiId, districtId, wardId, complaintType },
        file: { name: fileName, ext: type }
    }));
}

export function* getDownloadTopGrievanceCategory(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { complaintCategory, complaintMode, districtId, lsgiId, startDate, endDate, complaintStatus, wardId, complaintType } = filters;
    yield put(commonFileDownload({
        url: API_URL.COMPLAINT_REPORT.DOWNLOAD_TOP_GRIEVANCE_CATEGORY,
        data: { type, categoryId: complaintCategory, complaintMode, complaintStatus, startDate, endDate, lsgiId, districtId, wardId, complaintType },
        file: { name: fileName, ext: type }
    }));
}

export default function* reportSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SET_COMPLAINT_STATUS_DROPDOWN, setComplaintStatusDropdown),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_TYPE, complaintTypes),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_MODE, complaintModes),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY, getComplaintCategory),
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_LIST, getDistricts),
        takeLatest(ACTION_TYPES.FETCH_WARD_LIST, getWard),
        takeLatest(ACTION_TYPES.FETCH_LSGI, getLSGI),
        takeLatest(ACTION_TYPES.SELECT_LSGI_TYPE_DROPDOWN, lsgiTypeSelectDropdown),
        takeLatest(ACTION_TYPES.DOWNLOAD_REPORTS, getDownloadReports),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_LIST, listComplaint),
        takeLatest(ACTION_TYPES.SELECT_PRIORITY_DROPDOWN, priorityDropdown),
        takeLatest(ACTION_TYPES.SELECT_COMPLAINT_HANDLER_DROPDOWN, listComplaintHandler),
        takeLatest(ACTION_TYPES.SELECT_COMPLAINT_NUMBER_DROPDOWN, listJSONComplaintNumberData),
        takeLatest(ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY, listGrievanceSummary),
        takeLatest(ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_ULB, listGrievanceSummaryUlb),
        takeLatest(ACTION_TYPES.FETCH_GRIEVANCE_SUMMARY_WARD, listGrievanceSummaryWard),
        takeLatest(ACTION_TYPES.FETCH_GRIEVANCE_REPORT_LIST, listGrievanceReportList),
        takeLatest(ACTION_TYPES.DOWNLOAD_SUMMARY_REPORTS, getDownloadSummaryReports),
        takeLatest(ACTION_TYPES.DOWNLOAD_LISTING_REPORTS, getDownloadListingReports),
        takeLatest(ACTION_TYPES.DOWNLOAD_SUMMARY_LSGI_REPORTS, getDownloadSummaryLsgiReports),
        takeLatest(ACTION_TYPES.DOWNLOAD_SUMMARY_WARD_REPORTS, getDownloadSummaryWardReports),
        takeLatest(ACTION_TYPES.DOWNLOAD_COMPLAINT_LIST, getDownloadComplaintList),
        takeLatest(ACTION_TYPES.FETCH_ESCALATION_REPORT, listEscalationReportList),
        takeLatest(ACTION_TYPES.FETCH_PENDING_REPORT, listPendingReportList),
        takeLatest(ACTION_TYPES.FETCH_REOPENED_COMPLAINTS, listReopenedComplaintList),
        takeLatest(ACTION_TYPES.FETCH_TOP_GRIEVANCE_CATEGORY, listTopGrievanceCategoryList),
        takeLatest(ACTION_TYPES.DOWNLOAD_ESCALATION_REPORT, getDownloadEscalationReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_PENDING_REPORT, getDownloadPendingReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_REOPENED_REPORT, getDownloadReopenReport),
        takeLatest(ACTION_TYPES.DOWNLOAD_TOP_GRIEVANCE_CATEGORY, getDownloadTopGrievanceCategory)

    ]);
}
