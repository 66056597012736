import { flow } from "lodash";

const { STATE_REDUCER_KEY } = require("./constants");

const getUser = (state) => state[STATE_REDUCER_KEY];

const userDetails = (user) => user.userDetails.data;
export const getUserDetails = flow(getUser, userDetails);

const userTypes = (user) => user.userType.data;
export const getUserTypes = flow(getUser, userTypes);

const getSample = (state) => state[STATE_REDUCER_KEY];

const sampleForm = (sample) => sample.sampleForm;
export const getSampleForm = flow(getSample, sampleForm);

const tableProps = (sample) => sample.tablePagination;
export const getTableProps = flow(getSample, tableProps);

const selectedRows = (sample) => sample.table.rowSelection;
export const getSelectedRows = flow(getSample, selectedRows);

const radio = (sample) => sample.radioOptions;
export const getRadioOptions = flow(getSample, radio);

