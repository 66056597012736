import { REACT_TABLE_COMMON_OPTIONS, ROWS_PER_PAGE, TABLE_IDS } from "common/constants";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "modules/common/components/CommonTable";
import { STATE_REDUCER_KEY } from "modules/common/constants";
import { useMemo } from "react";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { Components, I18n, Icons } from "common/components";
import { trainingSummaryReportDownload, trainingSummaryReportList } from "./actions";
import TrainingSummaryFilter from "./TrainingSummaryFilter";

const { ListAlt, PictureAsPdf, FilterAltRounded } = Icons;
const { Badge } = Components;
const TcmSummary = () => {
    const dispatch = useDispatch();
    const tableData = useSelector(state => state[STATE_REDUCER_KEY]);
    const { table: { TRAINING_SUMMARY: { filters = {} } = {} } = {} } = tableData;
    let customActions = [];
    customActions.push({
        title: I18n("download_excel"), icon: <ListAlt fontSize="small" />, handleClick: () => dispatch(trainingSummaryReportDownload({ type: "xls", filters: filters }))
    });
    customActions.push({ title: I18n("download_pdf"), icon: <PictureAsPdf fontSize="small" />, handleClick: () => dispatch(trainingSummaryReportDownload({ type: "pdf", filters: filters })) });
    const toolBarActions = [];
    toolBarActions.push({ key: "download", component: <CustomListMenu customActions={customActions} type="download" /> });

    if (toolBarActions) {
        toolBarActions.push({
            title: "Open Filter", icon: <Badge badgeContent={Object.keys(filters).length} color="primary"><FilterAltRounded fontSize="medium" /></Badge>, key: "customFilter"
        });
    }
    const options = {
        ...REACT_TABLE_COMMON_OPTIONS,
        enableRowSelection: false,
        enableFilters: true,
        toolBarActions: toolBarActions,
        customPagination: {
            rowsPerPageOptions: ROWS_PER_PAGE
        },
        enableRowActions: false
    };

    const columns = useMemo(
        () => [
            // {
            //     id: "lsgiName",
            //     header: I18n("lsgi_name"),
            //     accessorKey: "lsgiName"
            // },
            {
                id: "trainingNeedAreaName",
                header: I18n("training_need_area_name"),
                accessorKey: "trainingNeedAreaName"
            },
            {
                id: "trainingPlanName",
                header: I18n("training_plan_name"),
                accessorKey: "trainingPlanName"
            },
            {
                id: "noOfBatches",
                header: I18n("no_of_batches"),
                accessorKey: "noOfBatches"
            },
            {
                id: "startDate",
                header: I18n("start_date"),
                accessorKey: "startDate"
            },
            {
                id: "endDate",
                header: I18n("end_date"),
                accessorKey: "endDate"
            },
            {
                id: "plannedParticipants",
                header: I18n("planned_participants"),
                accessorKey: "plannedParticipants"
            },
            {
                id: "actualParticipants",
                header: I18n("actual_participants"),
                accessorKey: "actualParticipants"
            }
        ],
        []
    );

    return (
        <>
            <CommonTable
                columns={columns}
                options={options}
                enableVirtualization={false}
                title={I18n("training_summary")}
                fetchData={trainingSummaryReportList}
                enableCustomTableFilter={true}
                filterComponent={({ setOpen }) => <TrainingSummaryFilter onClose={setOpen} />}
                tableId={TABLE_IDS.TRAINING_SUMMARY}
            />
        </>
    );
};

export default TcmSummary;
