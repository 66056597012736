import { REQUEST_METHOD } from "common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const SocialSafeGuardTableDataApi = (data) => ({
    url: API_URL.SOCIAL_SAFEGUARD.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_REQUEST, ACTION_TYPES.FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_SUCCESS, ACTION_TYPES.FETCH_SOCIAL_SAFEGUARD_TABLE_DATA_FAILURE],
        data
    }
});

export const getDistrictDropdownApi = (query = {}) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.DISTRICT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_DISTRICT_DROP_DOWN_REQUEST, ACTION_TYPES.GET_DISTRICT_DROP_DOWN_SUCCESS, ACTION_TYPES.GET_DISTRICT_DROP_DOWN_FAILURE],
            data: query
        }
    };
};

export const getUlbDropdownApi = (query = {}) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.ULB,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_ULB_DROP_DOWN_REQUEST, ACTION_TYPES.GET_ULB_DROP_DOWN_SUCCESS, ACTION_TYPES.GET_ULB_DROP_DOWN_FAILURE],
            data: query
        }
    };
};

export const postBaselineDataApi = (data) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.BASELINE_DATA_POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_BASE_LINE_DATA_REQUEST, ACTION_TYPES.POST_BASE_LINE_DATA_SUCCESS, ACTION_TYPES.POST_BASE_LINE_DATA_FAILURE], data
        }
    };
};

export const patchBaselineDataApi = (data) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.BASELINE_DATA_PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_BASE_LINE_DATA_REQUEST, ACTION_TYPES.PATCH_BASE_LINE_DATA_SUCCESS, ACTION_TYPES.PATCH_BASE_LINE_DATA_FAILURE], data
        }
    };
};

export const getBaselineDataFormApi = (query = {}) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.BASELINE_DATA_GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_BASE_LINE_DATA_REQUEST, ACTION_TYPES.GET_BASE_LINE_DATA_SUCCESS, ACTION_TYPES.GET_BASE_LINE_DATA_FAILURE],
            data: query
        }
    };
};

export const postSocialSafeguardApi = (data) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.SOCIAL_SAFEGUARD_POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SOCIAL_SAFEGUARD_DETAILS_REQUEST, ACTION_TYPES.POST_SOCIAL_SAFEGUARD_DETAILS_SUCCESS, ACTION_TYPES.POST_SOCIAL_SAFEGUARD_DETAILS_FAILURE], data
        }
    };
};

export const getBaselineDataUlbDetailsApi = (query = {}) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.ULB_DETAILS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_BASELINE_DATA_ULB_DETAILS_REQUEST, ACTION_TYPES.GET_BASELINE_DATA_ULB_DETAILS_SUCCESS, ACTION_TYPES.GET_BASELINE_DATA_ULB_DETAILS_FAILURE],
            data: query
        }
    };
};

export const getBaselineContentApi = (query = {}) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.BASELINE_CONTENT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_BASELINE_CONTENTS_DETAILS_REQUEST, ACTION_TYPES.GET_BASELINE_CONTENTS_DETAILS_SUCCESS, ACTION_TYPES.GET_BASELINE_CONTENTS_DETAILS_FAILURE],
            data: query
        }
    };
};

export const getSchemeCyclicDetailsApi = (query = {}) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.SCHEME_CYCLIC_GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_SCHEME_CYCLIC_DETAILS_REQUEST, ACTION_TYPES.GET_SCHEME_CYCLIC_DETAILS_SUCCESS, ACTION_TYPES.GET_SCHEME_CYCLIC_DETAILS_FAILURE],
            data: query
        }
    };
};

export const postSocialSchemeCyclicApi = (data) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.SCHEME_CYCLIC_POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SCHEME_CYCLIC_DATA_REQUEST, ACTION_TYPES.POST_SCHEME_CYCLIC_DATA_SUCCESS, ACTION_TYPES.POST_SCHEME_CYCLIC_DATA_FAILURE], data
        }
    };
};

export const patchSocialSchemeCyclicApi = (data) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.SCHEME_CYCLIC_PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_SCHEME_CYCLIC_DATA_REQUEST, ACTION_TYPES.PATCH_SCHEME_CYCLIC_DATA_SUCCESS, ACTION_TYPES.PATCH_SCHEME_CYCLIC_DATA_FAILURE], data
        }
    };
};

export const getSafeguardComplaintsApi = (query = {}) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.SAFEGUARD_COMPLAINTS_GET,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_SAFEGUARD_COMPLAINTS_DETAILS_REQUEST, ACTION_TYPES.GET_SAFEGUARD_COMPLAINTS_DETAILS_SUCCESS, ACTION_TYPES.GET_SAFEGUARD_COMPLAINTS_DETAILS_FAILURE],
            data: query
        }
    };
};

export const postSafeguardComplaintsApi = (data) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.SAFEGUARD_POST,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_SAFEGUARD_COMPLAINTS_DATA_REQUEST, ACTION_TYPES.POST_SAFEGUARD_COMPLAINTS_DATA_SUCCESS, ACTION_TYPES.POST_SAFEGUARD_COMPLAINTS_DATA_FAILURE], data
        }
    };
};

export const patchSafeguardComplaintsApi = (data) => {
    return {
        url: API_URL.SOCIAL_SAFEGUARD.SAFEGUARD_PATCH,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.PATCH_SAFEGUARD_COMPLAINTS_DATA_REQUEST, ACTION_TYPES.PATCH_SAFEGUARD_COMPLAINTS_DATA_SUCCESS, ACTION_TYPES.PATCH_SAFEGUARD_COMPLAINTS_DATA_FAILURE], data
        }
    };
};
