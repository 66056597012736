import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_SECONDARY_COLLECTION_AGENCY: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_COLLECTION_AGENCY`,
    FETCH_SECONDARY_COLLECTION_AGENCY_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_COLLECTION_AGENCY_REQUEST`,
    FETCH_SECONDARY_COLLECTION_AGENCY_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_COLLECTION_AGENCY_SUCCESS`,
    FETCH_SECONDARY_COLLECTION_AGENCY_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_COLLECTION_AGENCY_FAILURE`,

    FETCH_WOMEN_GROUP_DROPDOWN: `${STATE_REDUCER_KEY}/FETCH_WOMEN_GROUP_DROPDOWN`,
    FETCH_WOMEN_GROUP_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_WOMEN_GROUP_DROPDOWN_REQUEST`,
    FETCH_WOMEN_GROUP_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_WOMEN_GROUP_DROPDOWN_SUCCESS`,
    FETCH_WOMEN_GROUP_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_WOMEN_GROUP_DROPDOWN_FAILURE`,

    POST_SECONDARY_COLLECTION_AGENCY: `${STATE_REDUCER_KEY}/POST_SECONDARY_COLLECTION_AGENCY`,
    POST_SECONDARY_COLLECTION_AGENCY_REQUEST: `${STATE_REDUCER_KEY}/POST_SECONDARY_COLLECTION_AGENCY_REQUEST`,
    POST_SECONDARY_COLLECTION_AGENCY_SUCCESS: `${STATE_REDUCER_KEY}/POST_SECONDARY_COLLECTION_AGENCY_SUCCESS`,
    POST_SECONDARY_COLLECTION_AGENCY_FAILURE: `${STATE_REDUCER_KEY}/POST_SECONDARY_COLLECTION_AGENCY_FAILURE`,

    PATCH_SECONDARY_COLLECTION_AGENCY: `${STATE_REDUCER_KEY}/PATCH_SECONDARY_COLLECTION_AGENCY`,
    PATCH_SECONDARY_COLLECTION_AGENCY_REQUEST: `${STATE_REDUCER_KEY}/PATCH_SECONDARY_COLLECTION_AGENCY_REQUEST`,
    PATCH_SECONDARY_COLLECTION_AGENCY_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_SECONDARY_COLLECTION_AGENCY_SUCCESS`,
    PATCH_SECONDARY_COLLECTION_AGENCY_FAILURE: `${STATE_REDUCER_KEY}/PATCH_SECONDARY_COLLECTION_AGENCY_FAILURE`,

    FETCH_SECONDARY_COLLECTION_BY_ID: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_COLLECTION_BY_ID`,
    FETCH_SECONDARY_COLLECTION_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_COLLECTION_BY_ID_REQUEST`,
    FETCH_SECONDARY_COLLECTION_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_COLLECTION_BY_ID_SUCCESS`,
    FETCH_SECONDARY_COLLECTION_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SECONDARY_COLLECTION_BY_ID_FAILURE`,

    DELETE_SECONDARY_COLLECTION_AGENCY: `${STATE_REDUCER_KEY}/DELETE_SECONDARY_COLLECTION_AGENCY`,
    DELETE_SECONDARY_COLLECTION_AGENCY_REQUEST: `${STATE_REDUCER_KEY}/DELETE_SECONDARY_COLLECTION_AGENCY_REQUEST`,
    DELETE_SECONDARY_COLLECTION_AGENCY_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_SECONDARY_COLLECTION_AGENCY_SUCCESS`,
    DELETE_SECONDARY_COLLECTION_AGENCY_FAILURE: `${STATE_REDUCER_KEY}/DELETE_SECONDARY_COLLECTION_AGENCY_FAILURE`,

    AGENCY_NAME_DROPDOWN: `${STATE_REDUCER_KEY}/AGENCY_NAME_DROPDOWN`,
    AGENCY_NAME_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/AGENCY_NAME_DROPDOWN_REQUEST`,
    AGENCY_NAME_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/AGENCY_NAME_DROPDOWN_SUCCESS`,
    AGENCY_NAME_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/AGENCY_NAME_DROPDOWN_FAILURE`
};

export const getSecondaryCollectionAgency = createAction(ACTION_TYPES.FETCH_SECONDARY_COLLECTION_AGENCY);
export const getFetchWomenGroup = createAction(ACTION_TYPES.FETCH_WOMEN_GROUP_DROPDOWN);
export const postSecondaryCollectionAgency = createAction(ACTION_TYPES.POST_SECONDARY_COLLECTION_AGENCY);
export const patchSecondaryCollectionAgency = createAction(ACTION_TYPES.PATCH_SECONDARY_COLLECTION_AGENCY);
export const fetchSecondaryCollectionById = createAction(ACTION_TYPES.FETCH_SECONDARY_COLLECTION_BY_ID);
export const deleteSecondaryCollection = createAction(ACTION_TYPES.DELETE_SECONDARY_COLLECTION_AGENCY);
export const agencyNameDropdown = createAction(ACTION_TYPES.AGENCY_NAME_DROPDOWN);
