import { Components, FormController, I18n } from "common/components";
import { Form, withFormik } from "formik";
import LoadingCustomOverlay from "modules/common/components/LoadingOverlay";
import { USER_ROLE } from "modules/resources";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { getUserDetails } from "utils/userUtils";
import { districtDropdown, ulbDropdown } from "../../../subProjects/socialSafeGuard/actions";
import { getDistrictInfo, getUlbInfo } from "../../../subProjects/socialSafeGuard/selectors";
import { getReceiptById, patchReceipt, postReceipt } from "../action";
import { getReceiptDetails, requestInProgressSelector } from "../selector";
import { ReceiptSchema } from "../validate";
import { actions as sliceActions } from "../slice";
import { numericInputKey } from "utils/commonUtils";
const { Button, CardContent, Grid, Divider, Card, CustomCardHeader, CardHeader } = Components;


const CreateReceipt = (props) => {
    const { handleSubmit, receiptData, districtDropDown, ulbDropDown, setFieldValue, requestInProgress = true, values } = props;
    const dispatch = useDispatch();
    const { id } = useParams();
    const { pathname } = useLocation();
    const [view, setView] = useState(false);
    const [create, setCreate] = useState(false);
    const [lsgiIdError, setLsgiId] = useState(false);
    const { data: { activeRole = {} } = {} } = getUserDetails();
    const disableField = activeRole.id === USER_ROLE.SPMU ? false : true;

    useEffect(() => {
        if (pathname.includes("/view")) {
            setView(true);
        }
        if (pathname.includes("/create")) {
            setCreate(true);
        }
        if (id) {
            dispatch(getReceiptById({id}));
        }
        dispatch(districtDropdown());
        return () => {
            dispatch(sliceActions.clearAll());
        };
    }, [id]);

    const handleChange = (value) => {
        setFieldValue("lsgiId", "");
        dispatch(ulbDropdown({districtId: value.id}));
    };

    const handlePost = () => {
        if (!values?.lsgiId) {
            setLsgiId("Ulb is required");
        } else {
            setLsgiId("");
        }
    };

    return (
        <>
            <Card sx={{ pt: 2, overflow: "visible", margin: 2 }}>
                <LoadingCustomOverlay active={requestInProgress} >
                <CardHeader sx={{ borderRadius: "10px 10px 0 0", mb: 2 }} title={<CustomCardHeader title={!id ? I18n("receipt_details") : id && view ? I18n("view_receipt_details") : I18n("edit_receipt_details")} />} />
                <Divider />
                <Form onSubmit={handleSubmit}>
                    <CardContent sx={{ ml: 2, mr: 2 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={4} lg={4}>
                                <FormController control="select" label={I18n("district")} name="districtId" options={districtDropDown || []} onChangeFromController={(value) => handleChange(value)} isMandatory={true} disabled={disableField || view}/>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <FormController control="select" label={I18n("ulb")} name="lsgiId" options={ulbDropDown || []} isMandatory={true} disabled={disableField || view} multiple={create ? true : false} statusError={(values?.lsgiId !== "" || values?.lsgiId?.length !== 0) ? false : true} errorName={lsgiIdError}/>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <FormController control="date" mode="Date" label={I18n("date")} name="asDate" isMandatory={true} maxDate={new Date()} disabled= {disableField || view}/>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <FormController control="input" rows={1} label={I18n("amount")} name="amount" isMandatory={true} options={props.remarks} disabled={disableField || view} onKeyPress={numericInputKey} digitsOnly={true} />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <FormController control="input" rows={1} label={I18n("go_number")} name="govtOrderNum" options={props.remarks} disabled={disableField || view} />
                            </Grid>
                            <Grid item xs={12} lg={12} md={12}>
                                <FormController control="attachments" mode="pdf" name="attachments" label={I18n("attachments")} disabled={disableField || view} isMandatory={true} />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                                {!view && <Button variant="contained" type="submit" onClick={()=>handlePost()} disabled={disableField}>
                                    {receiptData?.id ? I18n("update") : I18n("save")}
                                </Button>}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Form>
                </LoadingCustomOverlay>
            </Card>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    districtDropDown: getDistrictInfo,
    ulbDropDown: getUlbInfo,
    receiptData: getReceiptDetails,
    requestInProgress: requestInProgressSelector

});

const mapDispatchToProps = (dispatch) => ({
    submitValues: (data, hasValues) => {
        if (data?.lsgiId !== "" && data?.lsgiId?.length !== 0) {
            hasValues ? dispatch(patchReceipt(data)) : dispatch(postReceipt(data));
        }
    }
});

const CreateReceiptForm = withFormik({
    enableReinitialize: true,
    validationSchema: ReceiptSchema,
    mapPropsToValues: (props) => props.receiptData,
    handleSubmit: (values, {props: {submitValues, receiptData}}) => {
        submitValues(values, receiptData?.id ? true : false);
    },
    displayName: "CreateReceiptForm"
})(CreateReceipt);

export default connect(mapStateToProps, mapDispatchToProps)(CreateReceiptForm);

