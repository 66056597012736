import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    APPROVE_QA_QC: `${STATE_REDUCER_KEY}/APPROVE_QA_QC`,
    APPROVE_QA_QC_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_REQUEST`,
    APPROVE_QA_QC_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_SUCCESS`,
    APPROVE_QA_QC_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_FAILURE`,

    REJECT_QA_QC: `${STATE_REDUCER_KEY}/REJECT_QA_QC`,
    REJECT_QA_QC_REQUEST: `${STATE_REDUCER_KEY}/REJECT_QA_QC_REQUEST`,
    REJECT_QA_QC_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_QA_QC_SUCCESS`,
    REJECT_QA_QC_FAILURE: `${STATE_REDUCER_KEY}/REJECT_QA_QC_FAILURE`,

    APPROVE_QA_QC_BY_ID: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_BY_ID`,
    APPROVE_QA_QC_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_BY_ID_REQUEST`,
    APPROVE_QA_QC_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_BY_ID_SUCCESS`,
    APPROVE_QA_QC_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/APPROVE_QA_QC_BY_ID_FAILURE`,

    FETCH_PREVIOUS_DPMU: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_DPMU`,
    FETCH_PREVIOUS_DPMU_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_DPMU_REQUEST`,
    FETCH_PREVIOUS_DPMU_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_DPMU_SUCCESS`,
    FETCH_PREVIOUS_DPMU_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PREVIOUS_DPMU_FAILURE`
};

export const rejectQaQc = createAction(ACTION_TYPES.REJECT_QA_QC);
export const approveQaQc = createAction(ACTION_TYPES.APPROVE_QA_QC);
export const approveDpmuById = createAction(ACTION_TYPES.APPROVE_QA_QC_BY_ID);
export const fetchPreviousDpmu = createAction(ACTION_TYPES.FETCH_PREVIOUS_DPMU);
