import { I18n } from "common/components";
import { TABLE_IDS } from "common/constants";
import { commonFileDownload, navigateTo } from "modules/common/actions";
import { getTableProps } from "modules/common/selectors";
import { MODULE_PATH } from "modules/paths";
import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { toEpoch } from "utils/dateUtils";
import { successNotify } from "utils/notificationUtils";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { OPERATIONAL_MODULE_PATH as PATH } from "../paths";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { API_URL } from "../apiUrls";

const formatData = (payload) => {
    const { date, ...rest } = payload;
    const data = { date: toEpoch(date), ...rest };
    return data;
};
export function* fetchWasteProcessingPlant({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.WASTE_PROCESSING_PLANT_DETAILS]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.fetchWasteProcessingPlantApi, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS, ACTION_TYPES.FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WASTE_PROCESSING_PLANT_DETAILS);
}

export function* wasteProcessingSiteDropDown({ payload }) {
    yield call(handleAPIRequest, Api.wasteProcessingSiteDropDownApi, payload);
}

export function* wasteProcessingPlantDetailsById({ payload }) {
    yield fork(handleAPIRequest, Api.wasteProcessingPlantDetailsByIdApi, payload);
    const responseAction = yield take([ACTION_TYPES.GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_FAILURE]);
    if (responseAction.type === ACTION_TYPES.GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID_SUCCESS) {
        if (responseAction?.payload?.district && responseAction?.payload?.lsgi) {
            yield call(handleAPIRequest, Api.wasteProcessingSiteDropDownApi, { lsgiId: responseAction?.payload?.lsgi?.id, pageNo: 0, pageSize: 500 });
        }
    }
}

export function* submitWasteProcessingDetails(action) {
    let { payload: { id } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.patchWasteProcessingDetailsApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS, ACTION_TYPES.PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("waste_processing_plant_details") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.WASTE_PROCESSING_PLANT_DETAILS.LIST}`));
        }
    } else {
        yield fork(handleAPIRequest, Api.postWasteProcessingDetailsApi, formatData(action?.payload));
        const responseAction = yield take([ACTION_TYPES.POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS, ACTION_TYPES.POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("waste_processing_plant_details") }) }));
            yield put(navigateTo(`${MODULE_PATH.OPERATIONAL_MODULE}/${PATH.OPERATIONAL_DATA}/${PATH.WASTE_PROCESSING_PLANT_DETAILS.LIST}`));
        }
    }
}

export function* deleteWasteProcessingDetails({ payload }) {
    yield fork(handleAPIRequest, Api.deleteWasteProcessingDetailsApi, payload);
    const action = yield take([ACTION_TYPES.DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS, ACTION_TYPES.DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE]);
    if (action.type === ACTION_TYPES.DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("waste_processing_plant_details") }) }));
        let tableProps = yield select(getTableProps);
        let { [TABLE_IDS.WASTE_PROCESSING_PLANT_DETAILS]: { filters = {} } = {} } = tableProps;
        yield fork(handleAPIRequest, Api.fetchWasteProcessingPlantApi, { ...filters, pageNo: 0, pageSize: 5, ...payload });
        const COMPARISON_TYPES = [ACTION_TYPES.FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_SUCCESS, ACTION_TYPES.FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_FAILURE];
        yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.WASTE_PROCESSING_PLANT_DETAILS);
    }
}

export function* downloadWasteProcessingPlantDetails(action) {
    const { type } = action.payload;
    const { fileName } = action.payload;
    const { filters } = action.payload;
    const { fromDate, toDate } = filters;
    yield put(commonFileDownload({
        url: API_URL.WASTE_PROCESSING_PLANT_DETAILS.DOWNLOAD,
        data: { type, fromDate, toDate },
        file: { name: fileName, ext: type }
    }));
}

export default function* operationModuleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS, fetchWasteProcessingPlant),
        takeLatest(ACTION_TYPES.WASTE_PROCESSING_SITE_DROPDOWN, wasteProcessingSiteDropDown),
        takeLatest(ACTION_TYPES.GET_WASTE_PROCESSING_PLANT_DETAILS_DETAILS_BY_ID, wasteProcessingPlantDetailsById),
        takeLatest(ACTION_TYPES.POST_WASTE_PROCESSING_PLANT_DETAILS_DETAILS, submitWasteProcessingDetails),
        takeLatest(ACTION_TYPES.PATCH_WASTE_PROCESSING_PLANT_DETAILS_DETAILS, submitWasteProcessingDetails),
        takeLatest(ACTION_TYPES.DELETE_WASTE_PROCESSING_PLANT_DETAILS_DETAILS, deleteWasteProcessingDetails),
        takeLatest(ACTION_TYPES.DOWNLOAD_WASTE_PROCESSING_PLANT_DETAILS_DETAILS, downloadWasteProcessingPlantDetails)
    ]);
}
