import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_MODULE_ID: `${STATE_REDUCER_KEY}/FETCH_MODULE_ID`,
    FETCH_MODULE_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MODULE_ID_REQUEST`,
    FETCH_MODULE_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MODULE_ID_SUCCESS`,
    FETCH_MODULE_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MODULE_ID_FAILURE`,

    FETCH_PARENT_ID: `${STATE_REDUCER_KEY}/FETCH_PARENT_ID`,
    FETCH_PARENT_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PARENT_ID_REQUEST`,
    FETCH_PARENT_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PARENT_ID_SUCCESS`,
    FETCH_PARENT_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PARENT_ID_FAILURE`,

    SUBMIT_RESOURCE_MANAGEMENT: `${STATE_REDUCER_KEY}/SUBMIT_RESOURCE_MANAGEMENT`,
    SUBMIT_RESOURCE_MANAGEMENT_REQUEST: `${STATE_REDUCER_KEY}/SUBMIT_RESOURCE_MANAGEMENT_REQUEST`,
    SUBMIT_RESOURCE_MANAGEMENT_SUCCESS: `${STATE_REDUCER_KEY}/SUBMIT_RESOURCE_MANAGEMENT_SUCCESS`,
    SUBMIT_RESOURCE_MANAGEMENT_FAILURE: `${STATE_REDUCER_KEY}/SUBMIT_RESOURCE_MANAGEMENT_FAILURE`,

    UPDATE_RESOURCE_MANAGEMENT: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_MANAGEMENT`,
    UPDATE_RESOURCE_MANAGEMENT_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_MANAGEMENT_REQUEST`,
    UPDATE_RESOURCE_MANAGEMENT_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_MANAGEMENT_SUCCESS`,
    UPDATE_RESOURCE_MANAGEMENT_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_MANAGEMENT_FAILURE`,

    LIST_RESOURCE_MANAGEMENT: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT`,
    LIST_RESOURCE_MANAGEMENT_REQUEST: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT_REQUEST`,
    LIST_RESOURCE_MANAGEMENT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT_SUCCESS`,
    LIST_RESOURCE_MANAGEMENT_FAILURE: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT_FAILURE`,

    DELETE_RESOURCE_MANAGEMENT: `${STATE_REDUCER_KEY}/DELETE_RESOURCE_MANAGEMENT`,
    DELETE_RESOURCE_MANAGEMENT_REQUEST: `${STATE_REDUCER_KEY}/DELETE_RESOURCE_MANAGEMENT_REQUEST`,
    DELETE_RESOURCE_MANAGEMENT_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_RESOURCE_MANAGEMENT_SUCCESS`,
    DELETE_RESOURCE_MANAGEMENT_FAILURE: `${STATE_REDUCER_KEY}/DELETE_RESOURCE_MANAGEMENT_FAILURE`,

    RESOURCE_MANAGEMENT_CONTACTS_BY_ID: `${STATE_REDUCER_KEY}/RESOURCE_MANAGEMENT_CONTACTS_BY_ID`,
    RESOURCE_MANAGEMENT_CONTACTS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/RESOURCE_MANAGEMENT_CONTACTS_BY_ID_REQUEST`,
    RESOURCE_MANAGEMENT_CONTACTS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/RESOURCE_MANAGEMENT_CONTACTS_BY_ID_SUCCESS`,
    RESOURCE_MANAGEMENT_CONTACTS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/RESOURCE_MANAGEMENT_CONTACTS_BY_ID_FAILURE`,

    LIST_RESOURCE_MANAGEMENT_NAME: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT_NAME`,
    LIST_RESOURCE_MANAGEMENT_NAME_REQUEST: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT_NAME_REQUEST`,
    LIST_RESOURCE_MANAGEMENT_NAME_SUCCESS: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT_NAME_SUCCESS`,
    LIST_RESOURCE_MANAGEMENT_NAME_FAILURE: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT_NAME_FAILURE`,

    FILTER_MENU_NAME: `${STATE_REDUCER_KEY}/FILTER_MENU_NAME`,
    FILTER_MENU_NAME_REQUEST: `${STATE_REDUCER_KEY}/FILTER_MENU_NAME_REQUEST`,
    FILTER_MENU_NAME_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_MENU_NAME_SUCCESS`,
    FILTER_MENU_NAME_FAILURE: `${STATE_REDUCER_KEY}/FILTER_MENU_NAME_FAILURE`
};

export const getModuleId = createAction(ACTION_TYPES.FETCH_MODULE_ID);
export const getParent = createAction(ACTION_TYPES.FETCH_PARENT_ID);
export const updateResourceManagement = createAction(ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT);
export const postResourceManagement = createAction(ACTION_TYPES.SUBMIT_RESOURCE_MANAGEMENT);
export const deleteResourceManagement = createAction(ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT);
export const listResourceManagement = createAction(ACTION_TYPES.LIST_RESOURCE_MANAGEMENT);
export const resourceManagementContactsById = createAction(ACTION_TYPES.RESOURCE_MANAGEMENT_CONTACTS_BY_ID);
export const filterMenuName = createAction(ACTION_TYPES.FILTER_MENU_NAME);
