import { Typography } from "@mui/material";
import { Components, FormController, I18n, Icons } from "common/components";
import { TABLE_IDS } from "common/constants";
import { Form, withFormik } from "formik";
import { getSearchFilters } from "modules/common/selectors";
import { actions as commonActions } from "modules/common/slice";
import { actions as sliceActions } from "./slice";
import { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { toEpoch } from "utils/dateUtils";
import { getTablePagination } from "utils/tableUtils";
import _ from "lodash";
import { sortByKeys } from "utils/commonUtils";
import { trainingSummaryReportList, trainingSummaryTrainingFilterList } from "./actions";
import { getTrainingNameList } from "./selector";

const { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, CustomCardHeader } = Components;
const { Search, RestartAlt, Close } = Icons;

const TrainingSummaryFilter = (props) => {
    const { handleSubmit, onClose, setFieldValue, values, getDropdownFilterList, FilterName } = props;
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [cancel, setCancel] = useState(true);
    let endDate = "";
    let startDate = "";

    const handleSearch = () => {
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_SUMMARY);
        if ((start !== "" && start !== null && end === "")) {
            setError("End date required");
        } else if (start && end !== "") {
            dispatch(trainingSummaryReportList({ pageNo: 0, pageSize }));
            dispatch(commonActions.setSearchFilter(props.values));
            setError("");
            onClose();
        } else {
            dispatch(trainingSummaryReportList({ pageNo: 0, pageSize }));
            dispatch(commonActions.setSearchFilter(props.values));
            setError("");
            onClose();
        }
    };
    const handleReset = () => {
        dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SUMMARY, reset: true }));
        setFieldValue("trainingPlanName", "");
        setFieldValue("startDate", null);
        setFieldValue("endDate", null);
        setStart("");
        setEnd("");
        dispatch(sliceActions.clearAll());

    };

    const handleStartDate = (val) => {
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_SUMMARY);
        startDate = toEpoch(val);
        setStart(startDate);
        if (startDate?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SUMMARY, filterKey: "startDate", clearKey: true }));
            dispatch(trainingSummaryReportList({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SUMMARY, filters: { startDate: startDate } }));
        }
    };

    const handleEndDate = (val) => {
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_SUMMARY);
        endDate = toEpoch(val);
        setEnd(endDate);
        if (endDate?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SUMMARY, filterKey: "endDate", clearKey: true }));
            dispatch(trainingSummaryReportList({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SUMMARY, filters: { endDate: endDate } }));
        }
    };

    const handleName = (val) => {
        let filterName = val?.map(i => i.id);
        let { pageSize } = getTablePagination(TABLE_IDS.TRAINING_SUMMARY);
        if (filterName?.length < 1) {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SUMMARY, filterKey: "trainingPlanName", clearKey: true }));
            dispatch(trainingSummaryReportList({ pageNo: 0, pageSize }));
        } else {
            dispatch(commonActions.setFilters({ key: TABLE_IDS.TRAINING_SUMMARY, filters: { trainingPlanName: filterName } }));
        }
    };

    const handleInputChange = (e, key) => {
        if (key === "trainingPlanName") {
            const value = e.trim();
            getDropdownFilterList({ key, value });
        }
    };

    useEffect(() => {
        setCancel(_.isEqual(sortByKeys(values), sortByKeys(props.reportFilter)));
    }, [values]);

    return (
        <>
            <Card sx={{ overflow: "visible", maxHeight: "90vh" }}>
                <CardHeader sx={{ borderRadius: "10px 10px 0 0", mb: 2 }} title={<CustomCardHeader title={I18n("filter")} />} />
                <CardContent sx={{ ml: 2, mr: 2 }}>
                    <Form onSubmit={handleSubmit} >
                        <Grid container spacing={1} >
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="date" mode="Date" onChangeFromController={handleStartDate} label={I18n("start_date")} name="startDate" maxDate={props?.values?.endDate} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="date" mode="Date" label={I18n("end_date")} onChangeFromController={handleEndDate} name="endDate" minDate={props?.values?.startDate} />
                                {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormController control="select" label={I18n("training_plan_name")} name="trainingPlanName" onChangeFromController={handleName} onInputChange={(e) => handleInputChange(e, "trainingPlanName")} options={FilterName || []} multiple />
                            </Grid >
                        </Grid>
                    </Form>
                </CardContent>
                <Divider />
                <CardActions>
                    <Grid item xs={12} container justifyContent="center">
                        <Grid> <Button onClick={() => handleSearch()} startIcon={<Search />} variant="contained">{I18n("search")}</Button> </Grid>
                        <Grid> <Button onClick={() => handleReset()} startIcon={<RestartAlt />} variant="contained">{I18n("reset")}</Button> </Grid>
                        <Grid>{cancel && <Button onClick={() => onClose()} startIcon={<Close />} variant="contained">{I18n("close")}</Button>}</Grid>
                    </Grid>
                </CardActions>
            </Card >
        </>
    );
};
const mapDispatchToProps = (dispatch) => ({
    getDropdownFilterList: ({ key, value }) => {
        if (value.length > 2) {
            dispatch(trainingSummaryTrainingFilterList({ key, value }));
        }
    }
});

const mapStateToProps = createStructuredSelector({
    reportFilter: getSearchFilters,
    FilterName: getTrainingNameList
});

const filter = withFormik({
    enableReinitialize: false,
    mapPropsToValues: (props) => {
        return props.reportFilter;
    },
    handleSubmit: ({ setSubmitting }) => {
        setSubmitting(false);
    },
    displayName: "TrainingSummaryFilter"
})(TrainingSummaryFilter);

export default connect(mapStateToProps, mapDispatchToProps)(filter);
