import { createSlice } from "@reduxjs/toolkit";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import _ from "lodash";
import { COMMON_TABLE_PAGINATION } from "modules/common/constants";

const initialState = {
  tablePagination: { ...COMMON_TABLE_PAGINATION },
  districtDetails: {
    requestInProgress: false,
    data: []
  },
  stateList: {
    requestInProgress: false,
    data: []
  },
  nameList: {
    requestInProgress: false,
    data: []
  },
  codeList: {
    requestInProgress: false,
    data: []
  }
};

const slice = createSlice({
  name: STATE_REDUCER_KEY,
  initialState: initialState,
  reducers: {
    clearAll: () => initialState,
    clear: (state) => {
      state.districtDetails = initialState.districtDetails;
      state.stateList = initialState.stateList;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(ACTION_TYPES.FETCH_DISTRICT_STATE_DETAILS_REQUEST, (state) => {
      state.stateList.requestInProgress = true;
    }).addCase(ACTION_TYPES.FETCH_DISTRICT_STATE_DETAILS_FAILURE, (state) => {
      state.stateList.requestInProgress = false;
    }).addCase(ACTION_TYPES.FETCH_DISTRICT_DETAILS_SUCCESS, (state, { payload }) => {
      _.set(state, "districtDetails.requestInProgress", false);
      _.set(state, "districtDetails.data", payload.content);
    }).addCase(ACTION_TYPES.FETCH_NAME_LIST_SUCCESS, (state, { payload }) => {
      _.set(state, "nameList.requestInProgress", false);
      _.set(state, "nameList.data", payload.content);
    }).addCase(ACTION_TYPES.FETCH_CODE_LIST_SUCCESS, (state, { payload }) => {
      _.set(state, "codeList.requestInProgress", false);
      _.set(state, "codeList.data", payload.content);
    });
  }
});

export const { reducer, actions } = slice;
