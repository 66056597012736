import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";


const initialState = {
  dprPreparation: {
    requestInProgress: false,
    data: {
      estimateCost: "",
      projectId: "",
      mcApprovalDate: "",
      mcRemarks: "",
      mcResolutionNumber: "",
      mcAttachmentIds: [],
      dpmuApprovalDate: "",
      dpmuRemarks: "",
      dpmuAttachmentIds: [],
      spmuApprovalDate: "",
      spmuRemarks: "",
      spmuAttachmentIds: [],
      gst: "",
      totalCost: "",
      envComponentCost: "",
      socialComponentCost: ""
    }
  },
  nocFromWb: {
    requestInProgress: false,
    data: {
      attachmentIds: [],
      dateOfReceipt: "",
      nocComments: "",
      remarks: "",
      receivedNoc: false,
      defaultRemarks: ""
    }
  },
  financialSanction: {
    requestInProgress: false,
    data: {
      attachmentIds: [],
      dateOfFs: "",
      fsComments: "",
      remarks: "",
      sanctionedProjectCost: "",
      sanctionedSocialComponentCost: "",
      sanctionedEnvComponentCost: "",
      sanctionedGst: "",
      sanctionedTotalCost: "",
      receivedFs: false
    }
  },
  technicalSanction: {
    requestInProgress: false,
    data: {
      attachmentIds: [],
      dateOfTs: "",
      tsComments: "",
      remarks: "",
      sanctionedProjectCost: "",
      sanctionedSocialComponentCost: "",
      sanctionedEnvComponentCost: "",
      sanctionedGst: "",
      sanctionedTotalCost: "",
      receivedTs: false
    }
  },
  dprList: {
    requestInProgress: false,
    data: []
  }
};

const slice = createSlice({
  initialState,
  name: STATE_REDUCER_KEY,
  reducers: {
    clearAll: () => initialState,
    clearValue: (state) => {
      state.nocFromWb = initialState.nocFromWb;
      state.technicalSanction = initialState.technicalSanction;
      state.financialSanction = initialState.financialSanction;
    },
    clear: (state) => {
      state.dprPreparation = initialState.dprPreparation;
    },
    clearDprList: (state) => {
      state.dprList = initialState.dprList;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(ACTION_TYPES.FETCH_DPR_PREPARATION_SUCCESS, (state, { payload }) => {
      _.set(state, "dprPreparation.requestInProgress", false);
      _.set(state, "dprPreparation.data", payload);
    });
    builder
      .addCase(ACTION_TYPES.FETCH_NOC_FROM_WB_SUCCESS, (state, { payload }) => {
        _.set(state, "nocFromWb.requestInProgress", false);
        _.set(state, "nocFromWb.data", { ...state.nocFromWb.data, ...payload.content[0] });
      })
      .addCase(ACTION_TYPES.FETCH_FINANCIAL_SANCTION_SUCCESS, (state, { payload }) => {
        _.set(state, "financialSanction.requestInProgress", false);
        _.set(state, "financialSanction.data", { ...state.financialSanction.data, ...payload.content[0] });
      })
      .addCase(ACTION_TYPES.FETCH_TECHNICAL_SANCTION_SUCCESS, (state, { payload }) => {
        _.set(state, "technicalSanction.requestInProgress", false);
        _.set(state, "technicalSanction.data", { ...state.technicalSanction.data, ...payload.content[0] });
      });
    builder
      .addCase(ACTION_TYPES.FETCH_DPR_LIST_SUCCESS, (state, { payload }) => {
        _.set(state, "dprList.requestInProgress", false);
        _.set(state, "dprList.data", payload.content);
      });
  }
});

export const { actions, reducer } = slice;
