import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    SET_USER_AGE: `${STATE_REDUCER_KEY}/SET_USER_AGE`,
    INITIATE_USER_AGE_CHANGE: `${STATE_REDUCER_KEY}/INITIATE_USER_AGE_CHANGE`,

    FETCH_USER_DETAILS: `${STATE_REDUCER_KEY}/FETCH_USER_DETAILS`,
    FETCH_USER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_DETAILS_REQUEST`,
    FETCH_USER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_DETAILS_SUCCESS`,
    FETCH_USER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_DETAILS_FAILURE`,

    FETCH_USERS: `${STATE_REDUCER_KEY}/FETCH_USERS`,
    SET_USERS: `${STATE_REDUCER_KEY}/SET_USERS`,
    ASSIGN_USER: `${STATE_REDUCER_KEY}/ASSIGN_USER`

};

export const setAge = createAction(ACTION_TYPES.SET_USER_AGE);

export const setAgeSagaCall = createAction(ACTION_TYPES.INITIATE_USER_AGE_CHANGE);

export const getUserDetails = createAction(ACTION_TYPES.FETCH_USER_DETAILS);

export const loadUsers = createAction(ACTION_TYPES.FETCH_USERS);

export const setUsers = createAction(ACTION_TYPES.SET_USERS);

export const assignUser = createAction(ACTION_TYPES.ASSIGN_USER);

