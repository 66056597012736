import { all, takeLatest, call } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { ACTION_TYPES } from "./actions";
import { fetchBatchDetailsApi } from "./api";

export function* fetchBatchDetails() {
    yield call(handleAPIRequest, fetchBatchDetailsApi);
}


export default function* sampleSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_BATCH_REPORT, fetchBatchDetails)
    ]);
}
