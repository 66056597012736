import { createSlice } from "@reduxjs/toolkit";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import _ from "lodash";

const initialState = {
    createDprTracker: {
        requestInProgress: false,
        data: {
            documentType: [],
            workType: [],
            esmfCategory: [],
            dprCategory: [],
            byIdData: {
                id: "",
                documentType: "",
                districtId: "",
                lsgiId: "",
                title: "",
                typeId: "",
                esmfCategory: "",
                dprCategory: "",
                year: ""
            }
        }
    },
    subProjectFullList: {
        requestInProgress: false,
        data: []
    },
    finalSubprojectList: {
        requestInProgress: false,
        data: []
    },
    finalSubprojectListById: {
        requestInProgress: false,
        data: {
            districtId: "",
            lsgiId: "",
            projectCode: "",
            projectName: "",
            startDate: "",
            endDate: "",
            trackId: "",
            conceptNoteAvailable: "",
            estimateAmount: "",
            feasibilityStudyDone: "",
            categoryId: "",
            subCategoryId: "",
            subCategoryId2: "",
            typeId: "",
            envCategoryId: "",
            briefDescription: "",
            longDescription: "",
            assignedPhysicalWards: [],
            assignedServiceWards: [],
            attachmentIds: [],
            finalListProjectId: "",
            annualPlanId: "",
            natureOfWork: "",
            annualPlanYear: ""
        }
    },
    projectListingTableData: {
        requestInProgress: false,
        data: {
            otherExpenses: "",
            esmfAmount: "",
            gst: "",
            totalCost: "",
            dprTrackerId: "",
            dprAttachment: [],
            estimateAttachment: [],
            planAttachment: [],
            frAttachment: [],
            kmlAttachment: []
        }
    },
    costDetailsListAll: {
        requestInProgress: false,
        data: []
    },
    addComment: {
        requestInProgress: false,
        data: {
            modal: false,
            dprTrackerId: "",
            authority: "",
            category: "",
            pageNo: "",
            chapter: "",
            comments: "",
            id: "",
            actionBy: ""
        }
    },
    approvalStatusList: {
        requestInProgress: false,
        data: []
    },

    commentList: {
        requestInProgress: false,
        data: []
    },

    dpmcDecision: {
        requestInProgress: false,
        data: {
            dprTrackerId: "",
            dpmcSocialExpertDecision: null,
            dpmcTechnicalExpertDecision: null,
            dpmcFinancialExpertDecision: null,
            dpmcEnvExpertDecision: null

        }
    },
    dpmuDecision: {
        requestInProgress: false,
        data: {
            dprTrackerId: "",
            dpmuSocialExpertDecision: null,
            dpmuTechnicalExpertDecision: null,
            dpmuFinancialExpertDecision: null,
            dpmuEnvExpertDecision: null

        }
    },
    spmuDecision: {
        requestInProgress: false,
        data: {
            dprTrackerId: "",
            spmuSocialExpertDecision: null,
            spmuTechnicalExpertDecision: null,
            spmuFinancialExpertDecision: null,
            spmuEnvExpertDecision: null

        }
    },
    spmcDecision: {
        requestInProgress: false,
        data: {
            dprTrackerId: "",
            spmcSocialExpertDecision: null,
            spmcTechnicalExpertDecision: null,
            spmcFinancialExpertDecision: null,
            spmcEnvExpertDecision: null

        }
    },
    responseModalData: {
        requestInProgress: false,
        data: {
            dydcResponse: "",
            tscResponse: ""
        }
    },
    dprStatus: {
        requestInProgress: false,
        status: ""
    },
    dprMcApproval: {
        requestInProgress: false,
        data: {
            id: "",
            mcApprovalDate: "",
            mcRemarks: "",
            mcResolutionNumber: "",
            mcAttachmentIds: []
        }
    },
    dprTrackerStatusList: {
        requestInProgress: false,
        data: []
    },
    addCommentCategoryList: {
        requestInProgress: false,
        data: []
    },
    viewComments: {
        requestInProgress: false,
        totalRecords: ""
    },
    generateFs: {
        requestInProgress: false,
        references: [],
        track: "",
        year: ""
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        setModal: (state) => {
            state.addComment.data.modal = initialState.addComment.data.modal;
        },
        clearComment: (state) => {
            state.addComment.data = initialState.addComment.data;
        },
        clearDprTrackerById: (state) => {
            state.createDprTracker.data.byIdData = initialState.createDprTracker.data.byIdData;
        },
        clearProjectCostData: (state) => {
            state.projectListingTableData.data = initialState.projectListingTableData.data;
        },
        clearResponseModal: (state) => {
            state.responseModalData.data = initialState.responseModalData.data;
        },
        setAddCommentById: (state, action) => {
            state.addComment.data = action.payload;
        },
        resetAddCommentById: (state) => {
            state.addComment.data = initialState.addComment.data;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.LIST_DOCUMENT_TYPE_REQUEST, (state) => {
                _.set(state, "createDprTracker.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_DOCUMENT_TYPE_SUCCESS, (state, action) => {
                _.set(state, "createDprTracker.data.documentType", action?.payload?.content);
                _.set(state, "createDprTracker.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_DOCUMENT_TYPE_FAILURE, (state) => {
                _.set(state, "createDprTracker.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_WORK_TYPE_REQUEST, (state) => {
                _.set(state, "createDprTracker.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_WORK_TYPE_SUCCESS, (state, action) => {
                _.set(state, "createDprTracker.data.workType", action?.payload?.content);
                _.set(state, "createDprTracker.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_WORK_TYPE_FAILURE, (state) => {
                _.set(state, "createDprTracker.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_ESMF_CATEGORY_REQUEST, (state) => {
                _.set(state, "createDprTracker.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_ESMF_CATEGORY_SUCCESS, (state, action) => {
                _.set(state, "createDprTracker.data.esmfCategory", action?.payload?.content);
                _.set(state, "createDprTracker.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_ESMF_CATEGORY_FAILURE, (state) => {
                _.set(state, "createDprTracker.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_DPR_CATEGORY_REQUEST, (state) => {
                _.set(state, "createDprTracker.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_DPR_CATEGORY_SUCCESS, (state, action) => {
                _.set(state, "createDprTracker.data.dprCategory", action?.payload?.content);
                _.set(state, "createDprTracker.requestInProgress", false);

            })
            .addCase(ACTION_TYPES.LIST_DPR_CATEGORY_FAILURE, (state) => {
                _.set(state, "createDprTracker.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.DPR_TRACKER_BY_ID_REQUEST, (state) => {
                _.set(state, "createDprTracker.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.DPR_TRACKER_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "createDprTracker.data.byIdData", action?.payload);
                _.set(state, "dprStatus.status", action?.payload?.dprStatus?.name);
                _.set(state, "dprMcApproval.data", action?.payload);
                _.set(state, "createDprTracker.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.DPR_TRACKER_BY_ID_FAILURE, (state) => {
                _.set(state, "createDprTracker.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_SUBPROJECT_FULL_DATA_REQUEST, (state) => {
                _.set(state, "subProjectFullList.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_SUBPROJECT_FULL_DATA_SUCCESS, (state, action) => {
                _.set(state, "subProjectFullList.data", action?.payload?.content);
                _.set(state, "subProjectFullList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_SUBPROJECT_FULL_DATA_FAILURE, (state) => {
                _.set(state, "subProjectFullList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_FINAL_SUBPROJECT_MAPPING_REQUEST, (state) => {
                _.set(state, "finalSubprojectList.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_FINAL_SUBPROJECT_MAPPING_SUCCESS, (state, action) => {
                _.set(state, "finalSubprojectList.data", action?.payload?.content);
                _.set(state, "finalSubprojectList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_FINAL_SUBPROJECT_MAPPING_FAILURE, (state) => {
                _.set(state, "finalSubprojectList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.BY_ID_FINAL_SUBPROJECT_MAPPING_REQUEST, (state) => {
                _.set(state, "finalSubprojectListById.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.BY_ID_FINAL_SUBPROJECT_MAPPING_SUCCESS, (state, action) => {
                _.set(state, "finalSubprojectListById.data", action?.payload);
                _.set(state, "finalSubprojectListById.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.BY_ID_FINAL_SUBPROJECT_MAPPING_FAILURE, (state) => {
                _.set(state, "finalSubprojectListById.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.BY_ID_COST_DETAILS_REQUEST, (state) => {
                _.set(state, "projectListingTableData.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.BY_ID_COST_DETAILS_SUCCESS, (state, action) => {
                _.set(state, "projectListingTableData.data", action?.payload?.content[0] || initialState.projectListingTableData.data);
                _.set(state, "projectListingTableData.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.BY_ID_COST_DETAILS_FAILURE, (state) => {
                _.set(state, "projectListingTableData.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_ALL_COST_DETAILS_REQUEST, (state) => {
                _.set(state, "costDetailsListAll.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_ALL_COST_DETAILS_SUCCESS, (state, action) => {
                _.set(state, "costDetailsListAll.data", action?.payload);
                _.set(state, "costDetailsListAll.requestInProgress", false);

            })
            .addCase(ACTION_TYPES.LIST_ALL_COST_DETAILS_FAILURE, (state) => {
                _.set(state, "costDetailsListAll.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.ADD_COMMENT_SUCCESS, (state) => {
                _.set(state, "addComment.modal", true);
            })
            .addCase(ACTION_TYPES.BY_ID_COMMENT_REQUEST, (state) => {
                _.set(state, "responseModalData.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.BY_ID_COMMENT_SUCCESS, (state, action) => {
                _.set(state, "addComment.data", action?.payload);
                _.set(state, "responseModalData.data", action?.payload);
                _.set(state, "responseModalData.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.BY_ID_COMMENT_FAILURE, (state) => {
                _.set(state, "responseModalData.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_APPROVAL_STATUS_REQUEST, (state) => {
                _.set(state, "approvalStatusList.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_APPROVAL_STATUS_SUCCESS, (state, action) => {
                _.set(state, "approvalStatusList.data", action?.payload?.content);
                _.set(state, "approvalStatusList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_APPROVAL_STATUS_FAILURE, (state) => {
                _.set(state, "approvalStatusList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_ALL_DPMC_DESICION_REQUEST, (state) => {
                _.set(state, "dpmcDecision.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_ALL_DPMC_DESICION_SUCCESS, (state, action) => {
                _.set(state, "dpmcDecision.data", action?.payload?.content[0] || initialState.dpmcDecision.data);
                _.set(state, "dpmuDecision.data", action?.payload?.content[0] || initialState.dpmcDecision.data);
                _.set(state, "spmcDecision.data", action?.payload?.content[0] || initialState.spmcDecision.data);
                _.set(state, "spmuDecision.data", action?.payload?.content[0] || initialState.spmcDecision.data);
                _.set(state, "dpmcDecision.requestInProgress", false);

            })
            .addCase(ACTION_TYPES.LIST_ALL_DPMC_DESICION_FAILURE, (state) => {
                _.set(state, "dpmcDecision.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_ALL_COMMENT_TABLE_REQUEST, (state) => {
                _.set(state, "commentList.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_ALL_COMMENT_TABLE_SUCCESS, (state, action) => {
                _.set(state, "commentList.data", action?.payload?.content);
                _.set(state, "commentList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_ALL_COMMENT_TABLE_FAILURE, (state) => {
                _.set(state, "commentList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_DPR_TRACKER_STATUS_REQUEST, (state) => {
                _.set(state, "dprTrackerStatusList.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_DPR_TRACKER_STATUS_SUCCESS, (state, action) => {
                _.set(state, "dprTrackerStatusList.data", action?.payload?.content);
                _.set(state, "dprTrackerStatusList.requestInProgress", false);

            })
            .addCase(ACTION_TYPES.LIST_DPR_TRACKER_STATUS_FAILURE, (state) => {
                _.set(state, "dprTrackerStatusList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.ADD_COMMENT_CATEGORY_LIST_REQUEST, (state) => {
                _.set(state, "addCommentCategoryList.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.ADD_COMMENT_CATEGORY_LIST_SUCCESS, (state, action) => {
                _.set(state, "addCommentCategoryList.data", action?.payload?.content);
                _.set(state, "addCommentCategoryList.requestInProgress", false);

            })
            .addCase(ACTION_TYPES.ADD_COMMENT_CATEGORY_LIST_FAILURE, (state) => {
                _.set(state, "addCommentCategoryList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.VIEW_COMMENT_REQUEST, (state) => {
                _.set(state, "totalRecords.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.VIEW_COMMENT_SUCCESS, (state, action) => {
                _.set(state, "totalRecords.data", action?.payload?.totalRecords);
                _.set(state, "totalRecords.requestInProgress", false);

            })
            .addCase(ACTION_TYPES.VIEW_COMMENT_FAILURE, (state) => {
                _.set(state, "totalRecords.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.GENERATE_FS_REQUEST, (state) => {
                _.set(state, "generateFs.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.GENERATE_FS_SUCCESS, (state) => {
                _.set(state, "generateFs.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.GENERATE_FS_FAILURE, (state) => {
                _.set(state, "generateFs.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIST_ALL_GENERATE_FS_REQUEST, (state) => {
                _.set(state, "generateFs.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.LIST_ALL_GENERATE_FS_SUCCESS, (state, action) => {
                _.set(state, "generateFs.references", action?.payload?.content);
                _.set(state, "generateFs.requestInProgress", false);

            })
            .addCase(ACTION_TYPES.LIST_ALL_GENERATE_FS_FAILURE, (state) => {
                _.set(state, "generateFs.requestInProgress", false);
            });
    }
});
export const { actions, reducer } = slice;
