import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { successNotify } from "utils/notificationUtils";
import { ACTION_TYPES, getComplaintValuesById, listMyApprovalPending } from "./actions";
import {
    fetchComplaintCategory, fetchComplaintType, fetchOrganization, editComplaint, fetchComplaintMode, addComplaint, deleteComplaint,
    fetchComplaintsById, fetchOrganizationType, fetchDistricts, listComplaint, fetchWards, listMyApproval, filterApi, fetchComplaintsByForm
} from "./api";
import { navigateTo } from "modules/common/actions";
import { MODULE_PATH } from "../../paths";
import { GRIEVANCE_PATH as PATH } from "../paths";
import { I18n } from "common/components";
import * as api from "./api";
import { TABLE_IDS } from "common/constants";
import { getAdditionalFilters, getPaginationPayload, setCommonTableData } from "utils/tableUtils";
import { actions as commonActions } from "../../common/slice";
import { getTableProps } from "modules/common/selectors";
import _ from "lodash";
import { APPROVALS_TABLES } from "./constants";
import { actions as sliceActions } from "./slice";

export function* listComplaintForm({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, listComplaint, { ...filters, ...payload, listMode: "ALL" });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_COMPLAINT_FORM_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_FORM_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* filterComplaintList({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listComplaints, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FILTER_COMPLAINT_LISTS_SUCCESS, ACTION_TYPES.FILTER_COMPLAINT_LISTS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* listComplaintAssignedToMe({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listComplaintAssigned, { ...filters, ...payload, listMode: "ASSIGNED_TO_ME" });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_COMPLAINT_ASSIGNED_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_ASSIGNED_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* listComplaintCreatedByMe({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listComplaintCreated, { ...filters, ...payload, listMode: "CREATED_BY_ME" });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_COMPLAINT_CREATED_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_CREATED_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* listComplaintUnassigned({ payload }) {
    let tableProps = yield select(getTableProps);
    let { ["DEFAULT_TABLE"]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, api.listComplaintUnassignedApi, { ...filters, ...payload, listMode: "UNASSIGNED", complaintStatus: "1003" });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_COMPLAINT_UNASSIGNED_SUCCESS, ACTION_TYPES.LIST_COMPLAINT_UNASSIGNED_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES);
}

export function* complaintType(action) {
    yield call(handleAPIRequest, fetchComplaintType, action.payload);
}
export function* complaintMode() {
    yield call(handleAPIRequest, fetchComplaintMode);
}
export function* complaintCategory() {
    yield call(handleAPIRequest, fetchComplaintCategory);
}
export function* submitComplaintForm({ payload }) {
    let { id, lsgiId, wardId } = payload;
    let complaintPayload = {};
    complaintPayload.complaintMode = payload.complaintMode;
    complaintPayload.categoryId = payload.categoryId;
    complaintPayload.typeId = payload.typeId;
    complaintPayload.complaintDescription = payload.complaintDescription;
    complaintPayload.districtId = payload.districtId;
    complaintPayload.complaintLocation = payload.complaintLocation;
    complaintPayload.id = payload.id;
    if (payload.complaintRegisteredBy) {
        complaintPayload.complaintRegisteredBy = payload.complaintRegisteredBy;
    }
    const newFiles = payload?.attachmentIds?.filter((attachment) => attachment?.id);
    if (newFiles && newFiles.length > 0) {
        let attachments = {};
        attachments = newFiles;
        complaintPayload.attachmentIds = attachments;
    }

    if (!_.isEmpty(lsgiId)) {
        complaintPayload.lsgiId = lsgiId;
    }
    if (!_.isEmpty(wardId)) {
        complaintPayload.wardId = wardId;
    }

    if (id) {
        yield fork(handleAPIRequest, editComplaint, complaintPayload);
        const responseAction = yield take([ACTION_TYPES.UPDATE_COMPLAINT_BY_ID_SUCCESS, ACTION_TYPES.UPDATE_COMPLAINT_BY_ID_FAILURE]);
        if (responseAction.type === ACTION_TYPES.UPDATE_COMPLAINT_BY_ID_SUCCESS) {
            yield put(getComplaintValuesById(id));
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("complaint") }) }));
        }
    } else {

        yield fork(handleAPIRequest, addComplaint, complaintPayload);
        const responseAction = yield take([ACTION_TYPES.ADD_COMPLAINT_FORM_SUCCESS, ACTION_TYPES.ADD_COMPLAINT_FORM_FAILURE]);
        if (responseAction.type === ACTION_TYPES.ADD_COMPLAINT_FORM_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("complaint") }) }));
            yield put(navigateTo(`${MODULE_PATH.GRIEVANCE}/${PATH.COMPLAINT.LIST}`));
        }
    }
}

export function* deleteComplaintForm({ payload: id }) {
    yield fork(handleAPIRequest, deleteComplaint, id);
    const responseAction = yield take([ACTION_TYPES.DELETE_COMPLAINT_FORM_SUCCESS, ACTION_TYPES.DELETE_COMPLAINT_FORM_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_COMPLAINT_FORM_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("complaint") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        const tablePayload = yield* getPaginationPayload();
        yield call(listComplaintForm, { payload: tablePayload });
    }
}

export function* fetchComplaintFormById({ payload }) {
    yield call(handleAPIRequest, fetchComplaintsById, payload);
}
export function* fetchComplaintForm({ payload }) {
    yield call(handleAPIRequest, fetchComplaintsByForm, payload);
}
export function* organization({ payload }) {
    const { districtId, lsgiTypeId } = payload;
    yield call(handleAPIRequest, fetchOrganization, districtId, lsgiTypeId);
}
export function* organizationType() {
    yield call(handleAPIRequest, fetchOrganizationType);
}
export function* districts() {
    yield call(handleAPIRequest, fetchDistricts);
}

export function* wardById({ payload }) {
    yield call(handleAPIRequest, fetchWards, { lsgiId: payload.id, pageSize: payload.pageSize });
}

export function* fetchFilesById({ payload }) {
    yield call(handleAPIRequest, api.getFileById, payload);
}
export function* deleteFile({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, api.deleteFileById, id, fileId);
}
export function* fetchComplaintHistory(action) {
    let additionalFilters = yield* getAdditionalFilters(TABLE_IDS.COMPLAINT_HISTORY);
    yield fork(handleAPIRequest, api.fetchComplaintHistoryApi, { ...action.payload, ...additionalFilters });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_COMPLAINT_HISTORY_SUCCESS, ACTION_TYPES.FETCH_COMPLAINT_HISTORY_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.COMPLAINT_HISTORY);
}
export function* filterComplaint({ payload: { value, key } = {} }) {
    let newPayload = {};
    _.set(newPayload, "key", key);
    _.set(newPayload, "value", value);
    yield call(handleAPIRequest, filterApi, { ...newPayload });
}

export function* fetchComplaintStatusFn() {
    yield call(handleAPIRequest, api.fetchComplaintStatusApi);
}

export function* updateComplaintAction({ payload }) {
    let complaintPayload = {};
    complaintPayload.complaintId = payload.complaintId;
    complaintPayload.officerRemarks = payload.officerRemarks || "";
    complaintPayload.currentStatus = payload.currentStatus || {};
    complaintPayload.complaintPriority = payload.complaintPriority || {};
    complaintPayload.complaintHandler = payload.complaintHandler;

    const newFiles = payload?.officerAttachmentIds?.filter((attachment) => attachment?.id);
    if (newFiles?.length > 0) {
        let attachments = {};
        attachments = newFiles;
        complaintPayload.officerAttachmentIds = attachments;
    }

    yield fork(handleAPIRequest, api.updateComplaintActionApi, complaintPayload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_COMPLAINT_ACTION_SUCCESS, ACTION_TYPES.UPDATE_COMPLAINT_ACTION_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_COMPLAINT_ACTION_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("complaint") }) }));
        yield put(getComplaintValuesById(payload.complaintId));
    }
}

export function* deleteActionFile({ payload }) {
    const { id, fileId } = payload;
    yield fork(handleAPIRequest, api.deleteActionFileById, id, fileId);
}

export function* fetchOfficersList(action) {
    let additionalFilters = yield* getAdditionalFilters(TABLE_IDS.OFFICERS_LIST);
    yield fork(handleAPIRequest, api.fetchOfficersListApi, { ...action.payload, ...additionalFilters });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_OFFICERS_LIST_SUCCESS, ACTION_TYPES.FETCH_OFFICERS_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.OFFICERS_LIST);
}

export function* submitReassignOfficer({ payload }) {
    yield fork(handleAPIRequest, api.addReassignOfficerApi, payload);
    const responseAction = yield take([ACTION_TYPES.REASSIGN_OFFICER_SUCCESS, ACTION_TYPES.REASSIGN_OFFICER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REASSIGN_OFFICER_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("complaint_types", { type: I18n("reassigned") }) }));
        yield put(getComplaintValuesById(payload?.complaintId));
    }
}

export function* listMyApprovalFn({ payload }) {
    yield fork(handleAPIRequest, listMyApproval, { ...payload, approvalStatus: "PENDING_APPROVAL" });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_MY_APPROVAL_SUCCESS, ACTION_TYPES.FETCH_MY_APPROVAL_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, APPROVALS_TABLES.MY_APPROVALS_PENDING_LIST);
}

export function* submitResolveStatus({ payload }) {
    yield fork(handleAPIRequest, api.addResolveStatusApi, payload);
    const responseAction = yield take([ACTION_TYPES.RESOLVE_COMPLAINT_SUCCESS, ACTION_TYPES.RESOLVE_COMPLAINT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.RESOLVE_COMPLAINT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("requests", { type: I18n("resolved") }) }));
        yield put(getComplaintValuesById(payload?.complaintId));
    }
}
export function* submitRejectStatus({ payload }) {
    yield fork(handleAPIRequest, api.addRejectStatusApi, payload);
    const responseAction = yield take([ACTION_TYPES.REJECT_COMPLAINT_SUCCESS, ACTION_TYPES.REJECT_COMPLAINT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.REJECT_COMPLAINT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("requests", { type: I18n("rejected") }) }));
        yield put(getComplaintValuesById(payload?.complaintId));
    }
}

export function* fetchComplaintApprovalDetailsFn({ payload }) {
    yield call(handleAPIRequest, api.fetchComplaintApprovalDetailsApi, payload);
}

export function* processMyApproval({ payload }) {
    yield fork(handleAPIRequest, api.processMyApprovalApi, payload);
    const responseAction = yield take([ACTION_TYPES.PROCESS_MY_APPROVAL_SUCCESS, ACTION_TYPES.PROCESS_MY_APPROVAL_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PROCESS_MY_APPROVAL_SUCCESS) {
        if (payload.approvalStatus === "APPROVED") {
            yield put(successNotify({ title: I18n("success"), message: I18n("requests", { type: I18n("approved") }) }));
        }
        if (payload.approvalStatus === "REJECTED") {
            yield put(successNotify({ title: I18n("success"), message: I18n("requests", { type: I18n("rejected") }) }));
        }
        yield put(listMyApprovalPending());
    }
}
export function* listRejectedMyApprovalFn({ payload }) {
    yield fork(handleAPIRequest, api.listRejectedMyApprovalApi, { ...payload, approvalStatus: "REJECTED" });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_MY_APPROVAL_REJECTED_SUCCESS, ACTION_TYPES.FETCH_MY_APPROVAL_REJECTED_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, APPROVALS_TABLES.MY_APPROVALS_REJECTED_LIST);
}

export function* submitRemarkForm({ payload }) {
    yield fork(handleAPIRequest, api.addRemark, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_REMARK_FORM_SUCCESS, ACTION_TYPES.SUBMIT_REMARK_FORM_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_REMARK_FORM_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("complaint_types", { type: I18n("reopened") }) }));
        yield put(sliceActions.setOpen(false));
        yield put(getComplaintValuesById(payload?.complaintId));
    }
}
export function* submitCloseForm({ payload }) {
    yield fork(handleAPIRequest, api.addCloseForm, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_CLOSE_FORM_SUCCESS, ACTION_TYPES.SUBMIT_CLOSE_FORM_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_CLOSE_FORM_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("complaint_types", { type: I18n("closed") }) }));
        yield put(sliceActions.setOpenComplaint(false));
        yield put(getComplaintValuesById(payload?.complaintId));


    }
}
export function* submitWithdrawForm({ payload }) {
    yield fork(handleAPIRequest, api.addWithdrawForm, payload);
    const responseAction = yield take([ACTION_TYPES.SUBMIT_WITHDRAW_FORM_SUCCESS, ACTION_TYPES.SUBMIT_WITHDRAW_FORM_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SUBMIT_WITHDRAW_FORM_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("complaint_types", { type: I18n("withdrawn") }) }));
        yield put(sliceActions.setOpenWithdraw(false));
        yield put(getComplaintValuesById(payload?.complaintId));

    }
}
export function* listApprovedMyApprovalFn({ payload }) {
    yield fork(handleAPIRequest, api.listApprovedMyApprovalApi, { ...payload, approvalStatus: "APPROVED" });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_MY_APPROVAL_APPROVED_SUCCESS, ACTION_TYPES.FETCH_MY_APPROVAL_APPROVED_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, APPROVALS_TABLES.MY_APPROVALS_APPROVED_LIST);
}

export function* fetchUserFn(action) {
    yield fork(handleAPIRequest, api.fetchUserApi, { ...action.payload });
    const COMPARISON_TYPES = [ACTION_TYPES.FETCH_USER_SUCCESS, ACTION_TYPES.FETCH_USER_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.OFFICERS_LIST);
}

export function* createUserFn({ payload }) {
    let newPayload = {};
    if (_.isEmpty(payload?.emailId)) {
        newPayload = {
            activeProfile: {
                ...payload.activeProfile,
                mobileNo: payload?.mobileNo
            },
            userName: payload.userName,
            userPassword: payload.userPassword,
            confirmPassword: payload.confirmPassword,
            firstName: payload.firstName,
            lastName: payload.lastName,
            genderId: payload.genderId
        };
    } else {
        newPayload = {
            activeProfile: {
                ...payload.activeProfile,
                mobileNo: payload?.mobileNo,
                emailId: payload?.emailId
            },
            userName: payload.userName,
            userPassword: payload.userPassword,
            confirmPassword: payload.confirmPassword,
            firstName: payload.firstName,
            lastName: payload.lastName,
            genderId: payload.genderId
        };
    }
    yield fork(handleAPIRequest, api.createUserApi, newPayload);
    const response = yield take([ACTION_TYPES.CREATE_USER_SUCCESS, ACTION_TYPES.CREATE_USER_FAILURE]);
    if (response.type === ACTION_TYPES.CREATE_USER_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("user") }) }));
    }
}

export function* fetchMobileDropdown({ payload }) {
    yield call(handleAPIRequest, api.fetchMobileDropdownApi, payload);
}

export default function* testSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_CATEGORY, complaintCategory),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_TYPE, complaintType),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_MODE, complaintMode),
        takeLatest(ACTION_TYPES.SUBMIT_COMPLAINT_FORM, submitComplaintForm),
        takeLatest(ACTION_TYPES.LIST_COMPLAINT_FORM, listComplaintForm),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY_ID, fetchComplaintFormById),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_DETAILS_BY, fetchComplaintForm),
        takeLatest(ACTION_TYPES.UPDATE_COMPLAINT_BY_ID, editComplaint),
        takeLatest(ACTION_TYPES.FETCH_ORGANIZATION, organization),
        takeLatest(ACTION_TYPES.FETCH_ORGANIZATION_TYPE, organizationType),
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_LIST, districts),
        takeLatest(ACTION_TYPES.DELETE_COMPLAINT_FORM, deleteComplaintForm),
        takeLatest(ACTION_TYPES.FETCH_WARD_DETAILS, wardById),
        takeLatest(ACTION_TYPES.FETCH_FILE, fetchFilesById),
        takeLatest(ACTION_TYPES.DELETE_FILE, deleteFile),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_HISTORY, fetchComplaintHistory),
        takeLatest(ACTION_TYPES.FILTER_COMPLAINT_LIST, filterComplaint),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_STATUS, fetchComplaintStatusFn),
        takeLatest(ACTION_TYPES.UPDATE_COMPLAINT_ACTION, updateComplaintAction),
        takeLatest(ACTION_TYPES.DELETE_ACTION_FILE, deleteActionFile),
        takeLatest(ACTION_TYPES.FETCH_OFFICERS_LIST, fetchOfficersList),
        takeLatest(ACTION_TYPES.REASSIGN_OFFICER, submitReassignOfficer),
        takeLatest(ACTION_TYPES.RESOLVE_COMPLAINT, submitResolveStatus),
        takeLatest(ACTION_TYPES.REJECT_COMPLAINT, submitRejectStatus),
        takeLatest(ACTION_TYPES.FETCH_MY_APPROVAL, listMyApprovalFn),
        takeLatest(ACTION_TYPES.FETCH_COMPLAINT_APPROVAL_DETAILS, fetchComplaintApprovalDetailsFn),
        takeLatest(ACTION_TYPES.PROCESS_MY_APPROVAL, processMyApproval),
        takeLatest(ACTION_TYPES.FETCH_MY_APPROVAL_REJECTED, listRejectedMyApprovalFn),
        takeLatest(ACTION_TYPES.SUBMIT_REMARK_FORM, submitRemarkForm),
        takeLatest(ACTION_TYPES.SUBMIT_CLOSE_FORM, submitCloseForm),
        takeLatest(ACTION_TYPES.SUBMIT_WITHDRAW_FORM, submitWithdrawForm),
        takeLatest(ACTION_TYPES.FETCH_MY_APPROVAL_APPROVED, listApprovedMyApprovalFn),
        takeLatest(ACTION_TYPES.FETCH_USER, fetchUserFn),
        takeLatest(ACTION_TYPES.CREATE_USER, createUserFn),
        takeLatest(ACTION_TYPES.LIST_COMPLAINT_ASSIGNED, listComplaintAssignedToMe),
        takeLatest(ACTION_TYPES.LIST_COMPLAINT_CREATED, listComplaintCreatedByMe),
        takeLatest(ACTION_TYPES.LIST_COMPLAINT_UNASSIGNED, listComplaintUnassigned),
        takeLatest(ACTION_TYPES.FILTER_COMPLAINT_LISTS, filterComplaintList),
        takeLatest(ACTION_TYPES.MOBILE_NO_DROPDOWN, fetchMobileDropdown)
    ]);
}

