import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getPayloadContent } from "utils/apiUtils";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";


const initialState = {
    courseForm: {
        requestInProgress: false,
        data: {}
    },
    courseList: {
        requestInProgress: false,
        data: []
    },

    courseId: {
        requestInProgress: false,
        data: []
    },
    sessionId: [],
    tableFilter: {},
    searchKey: {},
    correctAnswer: [
        { id: "1", name: "A" },
        { id: "2", name: "B" },
        { id: "3", name: "C" },
        { id: "4", name: "D" }
    ]
};


const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.table = initialState.table;
        },
        clearCourseId: (state) => {
            state.courseId = initialState.courseId;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.SAVE_COURSE_DETAILS_REQUEST, (state) => {
            _.set(state, "courseForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.SAVE_COURSE_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "courseForm.data", action.payload);
            _.set(state, "courseForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.SAVE_QUESTION_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "courseForm.data", action.payload);
            _.set(state, "courseForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.LIST_COURSE_DETAILS_REQUEST, (state) => {
            _.set(state, "courseForm.requestInProgress", true);
        });
        builder.addCase(ACTION_TYPES.LIST_COURSE_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "courseForm.data", getPayloadContent(action));
            _.set(state, "courseForm.requestInProgress", false);
        });
        builder.addCase(ACTION_TYPES.DELETE_COURSE_DETAILS_SUCCESS, (state, action) => {
            _.set(state, "courseForm.data", getPayloadContent(action));
        });
        // builder.addCase(ACTION_TYPES.FETCH_COURSE_ID_SUCCESS, (state, action) => {
        //     _.set(state, "courseId", getPayloadContent(action));
        // });
        builder.addCase(ACTION_TYPES.FETCH_COURSE_ID_SUCCESS, (state, { payload }) => {
            _.set(state, "courseId.requestInProgress", false);
            _.set(state, "courseId.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.FETCH_SESSION_ID_SUCCESS, (state, action) => {
            _.set(state, "sessionId", getPayloadContent(action));
        });
        builder.addCase(ACTION_TYPES.FETCH_COURSE_LIST_SUCCESS, (state, { payload }) => {
            _.set(state, "courseId.requestInProgress", false);
            _.set(state, "courseId.data", payload.content);
        });
        builder.addCase(ACTION_TYPES.EDIT_COURSE_BY_ID_SUCCESS, (state, action) => {

            _.set(state, "courseForm.requestInProgress", false);
            _.set(state, "courseForm.data", action.payload);
            if (action.payload.attachmentPayload) {
                _.set(state, "attachmentImages", action.payload.attachmentPayload?.multimediaList);
            }
        });
    }
});

export const { actions, reducer } = slice;
