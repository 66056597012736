import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { deleteCertificate, listCertificateForm } from "../actions";
import { I18n, Icons } from "../../../../common/components";
import { actions as sliceActions } from "../slice";
import { TRAINING_PATH as PATH } from "../../paths";
import { MODULE_PATH } from "modules/paths";
import CustomListMenu from "common/components/custom/CustomListMenu";
import { useDispatch } from "react-redux";
import { actions as commonActions } from "modules/common/slice";
import Swal from "sweetalert2";
import { REACT_TABLE_COMMON_OPTIONS, ROWS_PER_PAGE, TABLE_IDS } from "common/constants";
import CommonTable from "modules/common/components/CommonTable";
import { RESOURCE_ID } from "modules/resources";
import { DisableComponent } from "utils/disableComponent";

const { AddCircleOutline, DeleteIcon, EditIcon, OpenInNewIcon } = Icons;
const CertificateList = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setPageChange, setRowsPerPage } = props;
    const columns = useMemo(
        () => [

            {
                id: "template",
                header: I18n("template"),
                accessorFn: ({ template = {} }) => template?.name || "",
                size: 250
            },
            {
                id: "batch",
                header: I18n("batch"),
                accessorFn: ({ batch = {} }) => batch?.name || "",
                size: 250
            },
            {
                id: "venueId",
                header: I18n("venue"),
                accessorFn: ({ venueId = {} }) => venueId?.name || "",
                size: 250
            },
            {
                id: "lsgi",
                header: I18n("ulb"),
                accessorFn: ({ lsgi = {} }) => lsgi?.name || "",
                size: 250
            }
        ],
        []
    );
    const handleDelete = (id) => {
        Swal.fire({
            title: I18n("are_sure_want_delete"),
            showDenyButton: true,
            confirmButtonText: I18n("delete"),
            denyButtonText: I18n("cancel")
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCertificate(id));
            } else if (result.isDenied) {
                Swal.fire(I18n("action_cancelled"), "", "info");
            }
        });
    };
    const actions = (row) => {
        let customActions = [];
        customActions.push({ title: I18n("edit"), icon: <EditIcon fontSize="small" />, disabled: DisableComponent(RESOURCE_ID.TRAINING.TRAINING_CERTIFICATES.EDIT), handleClick: () => navigate(`${PATH.CERTIFICATE.LIST}/${row?.original?.id}`) });
        customActions.push({ title: I18n("delete"), icon: <DeleteIcon fontSize="small" />, disabled: DisableComponent(RESOURCE_ID.TRAINING.TRAINING_CERTIFICATES.DELETE), handleClick: () => handleDelete(row.original.id) });
        customActions.push({ title: I18n("view"), icon: < OpenInNewIcon fontSize="small" />, disabled: DisableComponent(RESOURCE_ID.TRAINING.TRAINING_CERTIFICATES.VIEW), handleClick: () => navigate(`${PATH.CERTIFICATE.LIST}/${row.original.id}/view`) });
        return customActions;
    };
    const toolBarActions = [];
    toolBarActions.push({
        title: I18n("create"), icon: <AddCircleOutline fontSize="medium" />, disabled: DisableComponent(RESOURCE_ID.TRAINING.TRAINING_CERTIFICATES.ADD), handleClick: () => navigate(`/${MODULE_PATH.TRAINING}/${PATH.CERTIFICATE.LIST}/${PATH.CERTIFICATE.SAVE}`)
    });

    const displayColumnDefOptions = {
        "mrt-row-actions": {
            Cell: ({ row }) => <CustomListMenu customActions={actions(row)} />

        }
    };
    const handleChangePage = (e, newPage) => setPageChange(e, newPage);
    const handleChangeRowsPerPage = (e) => setRowsPerPage(e);
    const options = {
        ...REACT_TABLE_COMMON_OPTIONS,
        enableRowSelection: false,
        toolBarActions: toolBarActions,
        enableFilters: true,
        customPagination: {
            handleChangePage,
            handleChangeRowsPerPage,
            rowsPerPageOptions: ROWS_PER_PAGE
        },
        enableRowActions: true,
        displayColumnDefOptions

    };
    useEffect(() => {
        dispatch(listCertificateForm());
        dispatch(commonActions.setAdditionalFilters({ key: TABLE_IDS.TRAINING_CERTIFICATE }));
        return (() => dispatch(sliceActions.clearAll()));
    }, []);

    return (
        <>
            <CommonTable
                columns={columns}
                options={options}
                tableId={TABLE_IDS.TRAINING_CERTIFICATE}
                title={I18n("training_certificate_table")}
                fetchData={listCertificateForm} />
        </>

    );
};
export default CertificateList;
