
import Footer from "modules/common/footer/Footer";
import { Box } from "@mui/material";
import Header from "modules/common/header/Header";
import SideBar from "modules/common/horizontal-menu/SideBar";
import { useState } from "react";

const DashboardLayout = ({ children }) => {
        const [showSidebar, setShowSidebar] = useState(false);
    return (
        <Box
            sx={{ bgcolor: "secondary.main", minHeight: "100vh", width: 1 }}
        >
            <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
            <Box sx={{ display: "flex", justifyContent: "space-between ", height: "100%" }}>
                <SideBar showSidebar={showSidebar} setShowSidebar={setShowSidebar}/>
                <Box sx={{ flexGrow: 1, overflowX: "auto", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "calc(100vh - 82px) !important ", overflowY: "auto", width: "100vw", pr: {xs: 0, md: 1}, ml: {xs: 0, md: 1}}}>
                    <Box
                        sx={{ bgcolor: "white.main", borderRadius: {xs: 0, md: "10px"}, flexGrow: 1 }}
                    >{children}</Box>
                    <Footer sx={{display: "none"}} />
                </Box>
            </Box>
        </Box>
    );
};

export default DashboardLayout;

